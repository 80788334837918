import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { Box, Stack, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { updateFlow } from "../../services/Flows/api";
import useFlow from "../../services/Flows/useFlow";
import { VisibilityType } from "../../enums";
import { LoadableButton } from "../../components/LoadableButton";
import { prettifyString } from "../../utils";

const FlowVisibilityToggle = ({ flowId }: { flowId: string }) => {
  const { data, isLoading, refetch } = useFlow(flowId);
  const deployDashboardMutation = useMutation((visibility: VisibilityType) =>
    updateFlow(flowId, { visibility })
  );
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Tooltip
      title={
        <Stack>
          <span>Visibility: {data?.visibility}</span>
          <span>Click to toggle flow visibility</span>
        </Stack>
      }
    >
      <Box>
        <LoadableButton
          isLoading={isLoading || deployDashboardMutation.isLoading}
          startIcon={
            !data ? null : data.visibility === VisibilityType.WORKSPACE ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )
          }
          onClick={() => {
            if (data) {
              deployDashboardMutation
                .mutateAsync(
                  data.visibility === VisibilityType.WORKSPACE
                    ? VisibilityType.PRIVATE
                    : VisibilityType.WORKSPACE
                )
                .then(() => {
                  enqueueSnackbar("Flow visibility changed successfully", {
                    variant: "success",
                  });
                  return refetch();
                })
                .catch(() =>
                  enqueueSnackbar("Failed to update flow visibility", {
                    variant: "error",
                  })
                );
            }
          }}
        >
          Flow Visibility {prettifyString(data?.visibility || "")}
        </LoadableButton>
      </Box>
    </Tooltip>
  );
};

export default FlowVisibilityToggle;
