import { v4 } from "uuid";
import { InsightRead } from "./types";

export const dummyInsight = (
  overrides: Partial<InsightRead> = {}
): InsightRead => ({
  id: v4(), // "884e2680-88da-42d9-8b03-e2bbc8ad7e0f",
  type: "AD_BOOSTER",
  embeddedQuestionUrl: "https://boo",
  imageUrl: "https://m.media-amazon.com/images/I/61nghmq8GPL._AC_SX679_.jpg",
  strategy: "Increase market share",
  brand: "Man",
  date: new Date(),
  cardText:
    "Untapped **keywords available** with <span class='color:red;'>**high-ROAS potential** </span> for products in the category",
  categories: [
    "Health & Household",
    "Oral Care",
    "Toothbrushes & Accessories",
    "Manual Toothbrushes",
  ],
  actionText: "Add Keywords",
  actionUrl:
    "/ad-booster/45ae3331-0dfd-41e7-bab2-706260a66e9b?categories=Health+%26+Household%7COral+Care%7CToothbrushes+%26+Accessories%7CManual+Toothbrushes",
  revenueValue: 55555,
  stars: 5,
  drawerText:
    "Noogata identified **1 high-potential** ROAS keywords **for 1 products** in a category you don't target. Add these keywords to your advertising campaigns to boost revenue.",
  dashboardLinkText: "Review Keywords",
  dashboardLinkUrl: "/dashboards/",
  widgetName: "test",
  embeddedQuestionName: "test",
  archived: false,
  done: false,
  assignees: [],
  ...overrides,
});

export const DUMMY_INSIGHTS = [
  dummyInsight(),
  dummyInsight({
    strategy: "Pottential Market Share",
    revenueValue: 19000,
    imageUrl: "https://m.media-amazon.com/images/I/81AeCBITQuL._AC_SL1500_.jpg",
  }),
  dummyInsight({
    revenueValue: 190000,
    imageUrl: "https://m.media-amazon.com/images/I/61Zh467pKjL._AC_SX679_.jpg",
  }),
  dummyInsight({
    revenueValue: 43222,
    strategy: "Pottential Market Share",
    stars: 1,
    imageUrl:
      "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/71pSooYfwQL._AC_SX679_.jpg",
  }),
  dummyInsight({
    revenueValue: 47839,
    stars: 2,
    imageUrl:
      "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/71OTtrj7N-L._SX679_.jpg",
  }),
  dummyInsight({
    imageUrl:
      "https://m.media-amazon.com/images/I/716RPfY3ajL.__AC_SX300_SY300_QL70_FMwebp_.jpg",
    strategy: "Pottential Market Share",
    cardText:
      "Untapped **keywords available** with <span class='color:red;'>**high-ROAS potential** </span> for products in the category",
    categories: ["Sleep", "Woman"],
    actionText: "Some Other",
    revenueValue: 1000,
    stars: 3,
    drawerText:
      "Noogata identified **1 high-potential** ROAS keywords **for 1 products** in a category you don't target. Add these keywords to your advertising campaigns to boost revenue.",
  }),
];

export const DUMMY_SINGLE_INSIGHT = {
  id: "884e2680-88da-42d9-8b03-e2bbc8ad7e0f",
  type: "AD_BOOSTER",
  embeddedQuestionName: "boo",
  imageUrl: "https://m.media-amazon.com/images/I/61nghmq8GPL._AC_SX679_.jpg",
  strategy: "Increase market share",
  brand: "Man",
  date: new Date(),
  cardText:
    "Untapped **keywords available** with <span class='color:red;'>**high-ROAS potential** </span> for products in the category",
  categories: ["Pet Supplies", "Cats", "Apparel"],
  actionText: "Add Keywords",
  actionUrl:
    "/ad-booster/45ae3331-0dfd-41e7-bab2-706260a66e9b?categories=Pet+Supplies%7CCats%7CApparel",
  revenueValue: 55555.0,
  stars: 5.0,
  drawerText:
    "Noogata identified **1 high-potential** ROAS keywords **for 1 products** in a category you don't target. Add these keywords to your advertising campaigns to boost revenue.",
  dashboardLinkText: "Review Keywords",
  dashboardLinkUrl: "/dashboards/",
  widgetName: "Man Share in Category",
  embeddedQuestionUrl:
    "https://dev.noogata.com/api/v1/metabase/embed/question/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZSI6eyJxdWVzdGlvbiI6NDA5fSwicGFyYW1zIjp7ImNhdGVnb3JpZXMiOlsiUGV0IFN1cHBsaWVzfENhdHN8QXBwYXJlbCJdfSwiZXhwIjoxNjgzNjQ5MjkyfQ.Voi0GXN9d4o8UjXGw0v4J2bZk0GMu3feShwFVmDV0A4#titled=false&bordered=false",
  archived: false,
  done: false,
  assignees: [],
};
