import {
  Dispatch,
  FC,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import {
  Button,
  Chip,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMutation } from "react-query";
import { enqueueSnackbar, useSnackbar } from "notistack";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddEditAsinsProductFilter from "./AddEditAsinsProductFilter";
import { useAmazonProductContext } from "./AmazonProductProvider";
import {
  deleteProductsFilter,
  handleProductEnrichment,
  updateProductsFilter,
} from "../../../services/ecommerce/api";
import useProductsFilter from "../../../services/ecommerce/useProductsFilters";
import { PlatformRole } from "../../../enums";
import { AuthorizedContent } from "../../../components/AuthorizedContent";
import { ProductFilter } from "../../../types/ecommerce";
import { PlatformRole as PlatformRoleUser } from "@noogata/types";
import { useAuthUser } from "@frontegg/react";
import ImportBulkProductFilters from "./ImportBulkProductFilters.tsx";

const MenuItemMenu = ({
  filter,
  children,
  value,
  setIsAsinsModalOpen,
  setFilterToUpdate,
  ...otherProps
}: {
  children: ReactNode;
  value: string;
  filter: ProductFilter;
  setIsAsinsModalOpen?: Dispatch<SetStateAction<boolean>>;
  setFilterToUpdate?: Dispatch<SetStateAction<ProductFilter | undefined>>;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const mutation = useMutation((id: string) => deleteProductsFilter(id));
  const updateMutation = useMutation((filter: ProductFilter) =>
    updateProductsFilter(filter)
  );
  const { refetch } = useProductsFilter();
  const snackbar = useSnackbar();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuItem {...otherProps} sx={{ "&:hover .editIconDiv": { opacity: 1 } }}>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" gap="8px">
          {children}
          {!!filter.default && (
            <Chip
              size="small"
              label="default"
              variant="outlined"
              color="secondary"
            />
          )}
          {!!filter.hide && (
            <VisibilityOffIcon sx={{ width: "16px", height: "16px" }} />
          )}
        </Stack>
        {filter.id !== "ALL_PRODUCTS" && (
          <Stack direction="row" alignItems="center" gap="8px">
            {!!filter.configuration.asins?.length && (
              <Button
                variant="text"
                sx={{
                  opacity: 0,
                  maxWidth: "16px",
                  maxHeight: "16px",
                  minWidth: "16px",
                  minHeight: "16px",
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                  padding: "4px",
                }}
                className="editIconDiv"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (setIsAsinsModalOpen && setFilterToUpdate) {
                    setIsAsinsModalOpen(true);
                    setFilterToUpdate(filter);
                  }
                }}
              >
                <EditIcon sx={{ width: "16px", height: "16px" }} />
              </Button>
            )}
            <AuthorizedContent
              requiredRoles={[PlatformRole.NoogataAdministrator]}
            >
              <Button
                variant="text"
                id={`sub-menu-button-${value}`}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  maxWidth: "16px",
                  maxHeight: "16px",
                  minWidth: "16px",
                  minHeight: "16px",
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                  padding: "4px",
                }}
              >
                <MoreVertIcon sx={{ width: "16px", height: "16px" }} />
              </Button>
              <Menu
                id={`sub-menu-${value}`}
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                MenuListProps={{
                  "aria-labelledby": `sub-menu-button-${value}`,
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    updateMutation
                      .mutateAsync({ ...filter, default: !filter.default })
                      .then(() => {
                        snackbar.enqueueSnackbar(
                          `Filter ${filter.name} marked as default`,
                          { variant: "success" }
                        );
                        return refetch();
                      })
                      .catch((e) => {
                        snackbar.enqueueSnackbar(
                          `Failed to mark filter: ${e.message} as default`,
                          {
                            variant: "error",
                          }
                        );
                      });
                  }}
                >
                  {filter.default ? "Unset" : "Set"} as default group
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    updateMutation
                      .mutateAsync({ ...filter, hide: !filter?.hide })
                      .then(() => {
                        snackbar.enqueueSnackbar(
                          `Filter ${filter.name} hided successfully`,
                          { variant: "success" }
                        );
                        return refetch();
                      })
                      .catch((e) => {
                        snackbar.enqueueSnackbar(
                          `Failed to hide filter: ${e.message}`,
                          {
                            variant: "error",
                          }
                        );
                      });
                  }}
                >
                  {filter?.hide ? "Show in" : "Hide from"} &apos;Product
                  Groups&apos; filter
                </MenuItem>
                <MenuItem
                  sx={{ color: (theme) => theme.palette.error.dark }}
                  onClick={(e) => {
                    e.stopPropagation();
                    mutation
                      .mutateAsync(filter.id)
                      .then(() => {
                        snackbar.enqueueSnackbar(
                          `Filter ${filter.name} removed successfully`,
                          { variant: "success" }
                        );
                        return refetch();
                      })
                      .catch((e) => {
                        snackbar.enqueueSnackbar(
                          `Failed to remove filter: ${e.message}`,
                          {
                            variant: "error",
                          }
                        );
                      });
                  }}
                >
                  Delete list
                </MenuItem>
              </Menu>
            </AuthorizedContent>
          </Stack>
        )}
      </Stack>
    </MenuItem>
  );
};

export const FiltersSelect = () => {
  const { currentFilter, setFilter, productsFilters, allFilters } =
    useAmazonProductContext();
  const [isAsinsModalOpen, setIsAsinsModalOpen] = useState<boolean>(false);
  const [filterToUpdate, setFilterToUpdate] = useState<
    ProductFilter | undefined
  >();
  const filter = currentFilter || allFilters;

  const user = useAuthUser();
  const { roles } = user;
  const hasNoogataAdmin = roles.find(
    (role: any) => role.key === PlatformRoleUser.NoogataAdministrator
  );
  const isAdmin = hasNoogataAdmin && hasNoogataAdmin.toString().length > 0;

  const handleForceEnrich = async () => {
    const data = await handleProductEnrichment();
    if (data?.status === "ok") {
      enqueueSnackbar(`Request sent successfully`, {
        variant: "success",
      });
    }
  };
  const FilterLabel: FC<{ filter: ProductFilter }> = ({ filter }) => (
    <Stack spacing={1} direction="row" alignItems="center">
      <Typography>{filter.name}</Typography>
      {filter?.total != null && (
        <Typography variant="body4">{`(${filter.total})`}</Typography>
      )}
    </Stack>
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center" mt="8px">
      <Select
        value={filter.id}
        renderValue={() => <FilterLabel filter={filter} />}
        sx={{ minWidth: "225px" }}
        onChange={(e) => {
          const fil = productsFilters.filter(
            (item: ProductFilter) => item.id === e.target.value
          );
          setFilter(fil[0]);
        }}
        MenuProps={{
          autoFocus: false,
          MenuListProps: { sx: { maxHeight: "240px", minWidth: "345px" } },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        <MenuItemMenu value={allFilters.id} filter={allFilters}>
          <FilterLabel filter={allFilters} />
        </MenuItemMenu>
        {productsFilters.map((item: ProductFilter) => (
          <MenuItemMenu
            filter={item}
            value={item.id}
            key={item.id}
            setIsAsinsModalOpen={setIsAsinsModalOpen}
            setFilterToUpdate={setFilterToUpdate}
          >
            <FilterLabel filter={item} />
          </MenuItemMenu>
        ))}
      </Select>
      <AddEditAsinsProductFilter
        filterToUpdate={filterToUpdate}
        isAsinsModalOpen={isAsinsModalOpen}
        setIsAsinsModalOpen={setIsAsinsModalOpen}
        setFilterToUpdate={setFilterToUpdate}
      />
      {isAdmin && (
        <>
          <ImportBulkProductFilters setFilterToUpdate={setFilterToUpdate} />
          <Button
            color="primary"
            size="medium"
            variant="contained"
            style={{ marginLeft: 0 }}
            onClick={handleForceEnrich}
          >
            Enrich Now
          </Button>
        </>
      )}
    </Stack>
  );
};
export default FiltersSelect;
