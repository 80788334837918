import {CellProps, Column, Row, usePagination, useSortBy, useTable,} from "react-table";
import {FC, useMemo} from "react";
import {useHistory, useParams} from "react-router-dom";
import useListTriggers from "../../services/triggers/useListTriggers";
import {
    Board,
    createTriggersTree,
    getTriggerPathFromNode,
    TriggerNode,
} from "../Circuitboards/BoardsTable/BoardsTable";
import {CircuitBoard, Schedule} from "../../types";
import UncontrolledTable from "../../components/UncontrolledTable";
import {useInstallableBoards} from "../../services/boards";
import {BlockListItem} from "./BlockListItem";
import {TriggerCell} from "../Circuitboards/BoardsTable/TriggerCell";
import RunStatus from "../Run/RunV2/RunHeader/RunStatus";
import useFlow from "../../services/Flows/useFlow";

export const sortByScheduledBlocks = (
    rowA: Row<CircuitBoard>,
    rowB: Row<CircuitBoard>
) => {
    const a = rowA.values.scheduled?.interval.toString();
    const b = rowB.values.scheduled?.interval.toString();
    return a > b ? 1 : -1;
};

export const FlowBlocksTable: FC<object> = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { data: flow, isLoading, error: rqError } = useFlow(flowId);
  const history = useHistory();
  const blocks = flow?.blocks;
  const error = rqError?.message ? new Error(rqError.message) : null;
  const { data: triggers = {} } = useListTriggers<Record<string, TriggerNode>>({
    select: createTriggersTree,
  });
  const { data: boardsTemplates = [] } = useInstallableBoards({
    enabled: blocks && blocks.length > 0,
  });
  const columns = useMemo<Column<CircuitBoard>[]>(
    () => [
      {
        Header: "Block",
        id: "Blocks",
        accessor: (block) => block,
        Cell: ({ value: block }: { value: Board }) => (
          <BlockListItem
            listItemProps={{
              sx: {
                ".MuiListItemButton-root:hover": {
                  backgroundColor: "transparent",
                },
              },
            }}
            block={block}
          />
        ),
      },
      {
        id: "scheduled",
        Header: "Trigger",
          // @ts-ignore
        isSortedDesc: true,
        maxWidth: 70,
        sortType: sortByScheduledBlocks,
        accessor: (b: any) => (b.schedules[0] ? b.schedules[0] : ""),
        Cell: ({ value, row }: CellProps<Board, Schedule>) => {
          const path = getTriggerPathFromNode(
            triggers[row?.original.identifier]
          ).map(
            (boardId) =>
              blocks?.find((b) => b.identifier === boardId)?.displayName ||
              boardId
          );
          return <TriggerCell path={path} schedule={value} />;
        },
      },
      {
        Header: "Status",
        id: "Status",
        accessor: "last_run",
          Cell: ({value: run}) =>
              run && <RunStatus run={run}/>,
      },
    ],
    [blocks]
  );
  const BlockWithIsLatest = useMemo(
    () =>
      (blocks || []).map((b) => ({
        ...b,
        isLatest:
          boardsTemplates.find((bt) => bt.id === b.template_id)
            ?.latest_version === b.version,
      })),
    [blocks]
  );

  const instance = useTable(
    {
      columns,
      data: BlockWithIsLatest,
        // @ts-ignore
      initialState: { pageSize: blocks?.length || 10 },
      autoResetSortBy: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  return (
    <UncontrolledTable
      disablePagination
      tableRowPropsGetter={(row) => ({
        sx: {
          height: 84,
          ".MuiTableCell-root": {
            padding: 0,
          },
        },
        onClick: () => {
          const block = row.original as CircuitBoard;
          history.push(`/boards/${block.identifier}`);
        },
        p: 0,
      })}
      columns={columns}
      data={BlockWithIsLatest}
      isLoading={isLoading}
      error={error}
      instance={instance}
    />
  );
};
