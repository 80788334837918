import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";
import { columnWidths, InsightColors } from "./constants";

const VALUE_CHIPS = {
  borderRadius: "4px",
  padding: "0px 8px",
};
export const useInsightsStyles = makeStyles((theme: Theme) => {
  const productImage = {
    layout: {
      border: `1px solid ${theme.palette.borders.lines}`,
      background: "#fff",
      borderRadius: "8px",
      padding: "7px",
      width: columnWidths.image,
      height: "96px",
    },
    container: {
      maxWidth: "80px",
      maxHeight: "80px",
      width: "100%",
      height: "auto",
    },
  };
  const productImageSmall = {
    layout: {
      ...productImage.layout,
      borderRadius: "4px",
      padding: "6px",
      width: "48px",
      height: "48px",
    },
    container: {
      ...productImage.container,
      maxWidth: "40px",
      maxHeight: "40px",
    },
  };

  const productImageMedium = {
    layout: {
      ...productImage.layout,
      borderRadius: "8px",
      padding: "8px",
      width: "64px",
      height: "64px",
    },
    container: {
      ...productImage.container,
      maxWidth: "56px",
      maxHeight: "56px",
    },
  };

  const styles = {
    productImageLayout: productImage.layout,
    productImageContainer: productImage.container,
    productImageLayoutMedium: productImageMedium.layout,
    productImageContainerMedium: productImageMedium.container,
    productImageLayoutSmall: productImageSmall.layout,
    productImageContainerSmall: productImageSmall.container,
    nomargin: { "& p": { margin: 0 } },
    lineClamp: {
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 2,
      overflow: "hidden",
    },
    insightsBody: {
      overflow: "hidden",
      height: "100vh",
    },
    listContainer: {
      flex: "1",
      overflow: "auto",
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.backgrounds.secondary,
      paddingRight: "30px",
    },
    mainList: {
      flex: "1",
      overflow: "auto",
      height: "100%",
      width: "100%",
      "& li": {
        padding: "0 32px", // maxWidth: "1400px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    row: {
      spacing: 2,
      width: "100%",
      p: 3,
      my: 2.5,
      mx: 1.25,
      borderRadius: "16px",
      boxShadow:
        "0px 8px 20px -6px rgba(44, 34, 76, 0.05), 0px 12px 45px -4px rgba(44, 34, 76, 0.05)",
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.background.default}`,

      "&.active": {
        border: `1px solid ${theme.palette.borders.active}`,
      },
    },
    rowAvatarChip: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "16px",
      borderRadius: "100px",
      gap: "4px",
    },
    chipPotential: {
      ...VALUE_CHIPS,
      "&.compact": {
        borderRadius: theme.spacing(1),
      },
      backgroundColor: InsightColors.success.light,
      color: InsightColors.success.main,
    },
    chipLossProtection: {
      ...VALUE_CHIPS,
      backgroundColor: InsightColors.warning.light,
      color: theme.palette.warning.main,
    },
  };

  return createStyles(styles);
});
