import { Export as ExportV2 } from "../../types/exports";
import apiRequest from "../apiRequest";
import { CreateExportRequest } from "../../types";

export const createExportV2 = (body: CreateExportRequest): Promise<ExportV2> =>
  apiRequest<ExportV2>("exports", "POST", undefined, {
    board_id: body.boardId,
    run_id: body.runId,
    destination_type: body.type,
    destination_id: body.destinationId,
    destination_directory: body.name,
    outputs: body.outputs,
  });
