import { Dispatch, SetStateAction } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { APIError } from "../../services/apiRequest";
import { ProductsFilter } from "../../types/ecommerce";

export type RollingDataType = {
  asin: string;
  title: string;
  brand: string;
  image_url: string;
  categories: string;

  sales_7d: number;
  sales_7d_previous: number;
  sales_28d: number;
  sales_28d_previous: number;

  ad_spend_7d: number;
  ad_spend_7d_previous: number;
  ad_spend_28d: number;
  ad_spend_28d_previous: number;

  units_sold_7d: number;
  units_sold_7d_previous: number;
  units_sold_28d: number;
  units_sold_28d_previous: number;

  units_sales_1w: number;
  units_sales_2w: number;
  units_sales_3w: number;
  units_sales_4w: number;
  units_sales_5w: number;
  units_sales_6w: number;
  units_sales_7w: number;
  units_sales_8w: number;
  units_sales_9w: number;
  units_sales_start_date: string;

  share_of_voice_7d: number;
  share_of_voice_change_7d: number;
  share_of_voice_28d: number;
  share_of_voice_change_28d: number;

  category_traffic_7d: number;
  category_traffic_change_7d: number;
  category_traffic_28d: number;
  category_traffic_change_28d: number;

  conversion_rate_7d: number;
  conversion_rate_7d_previous: number;
  conversion_rate_28d: number;
  conversion_rate_28d_previous: number;

  avg_price_7d: number;
  avg_price_7d_previous: number;
  avg_price_28d: number;
  avg_price_28d_previous: number;

  competitive_traffic_7d: number;
  competitive_traffic_change_7d: number;
  competitive_traffic_28d: number;
  competitive_traffic_change_28d: number;
};

export type GraphDataType = {
  change: string;
  date: string;
  label: string;
  value: number;
};

export type SalesAssistantRead = {
  asin: string;
  title: string;
  brand: string;
  categories: string;
  id: string;
  image_url: string;
  status?: string;
  graph_data: GraphDataType;
  data: RollingDataType;
  start_week_text: string;
  end_week_text: string;
  tracked: boolean;
  alerts: any;
  sales_7d: number;
  sales_7d_change: number;
  sales_7d_percentage: number;
  sales_7d_previous: number;
  sales_28d: number;
  sales_28d_change: number;
  sales_28d_percentage: number;
  sales_28d_previous: number;
};
export type StatsPeriods = "7 days" | "28 days";

export type FilterColumns = "brand" | "categoryId" | "unitSales";

export type TransformedInsight = SalesAssistantRead & {
  categoryId?: string;
  isChecked: boolean;
};

export class InsightReadImpl implements SalesAssistantRead {
  asin: string;

  brand: string;

  categories: string;

  id: string;

  image_url: string;

  status?: string;

  graph_data: GraphDataType;

  data: RollingDataType;

  start_week_text: string;

  end_week_text: string;

  tracked: boolean;

  alerts: any;

  sales_7d: number;

  sales_7d_change: number;

  sales_7d_percentage: number;

  sales_7d_previous: number;

  sales_28d: number;

  sales_28d_change: number;

  sales_28d_percentage: number;

  sales_28d_previous: number;

  title: string;

  constructor(params: SalesAssistantRead) {
    this.brand = params.brand;
    this.categories = params.categories;
    this.id = params.id;
    this.image_url = params.image_url;
    this.asin = params.asin;
    this.status = params.status;
    this.graph_data = params.graph_data;
    this.data = params.data;
    this.start_week_text = params.start_week_text;
    this.end_week_text = params.end_week_text;
    this.tracked = params.tracked;
    this.alerts = params.alerts;
    this.sales_7d = params.sales_7d;
    this.sales_7d_change = params.sales_7d_change;
    this.sales_7d_percentage = params.sales_7d_percentage;
    this.sales_7d_previous = params.sales_7d_previous;
    this.sales_28d = params.sales_28d;
    this.sales_28d_change = params.sales_28d_change;
    this.sales_28d_percentage = params.sales_28d_percentage;
    this.sales_28d_previous = params.sales_28d_previous;
    this.title = params.title;
  }
}

export type FilterBase = {
  values: Record<string, boolean>;
  column: FilterColumns;
};

export type FilterUI = FilterBase & {
  displayName: string;
};

export type NewSortOptions = "sales" | "change" | "percentage";

export type Sort = {
  column: keyof SalesAssistantRead | NewSortOptions;
  ascending: boolean;
  isDirty: boolean;
  days: string;
};
export type Filter = Record<string, boolean>;

export type FilterMapping = Record<FilterColumns, Filter>;

export type FilterData = {
  categories: [];
  brands: [];
};

export type State = {
  setFilterOut: Dispatch<SetStateAction<FilterMapping>>;
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
  filterOut: FilterMapping;
  sort: Sort;
  setSort: Dispatch<SetStateAction<Sort>>;
  setSelectedInsights: Dispatch<SetStateAction<string[]>>;
  selectedInsights: string[];
  salesAssistantData: any;
  isLoadingInsights: boolean;
  isFetchingInsights: boolean;
  isDrawerEnabled: boolean;
  enableDrawer: Dispatch<SetStateAction<boolean>>;
  SalesMetaData: any;
  refetchInsights: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<SalesAssistantRead[], APIError>>;
  refetchSalesMetadata: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, APIError>>;
  metadataLoading: boolean;
  selectedProductGroup: ProductsFilter | undefined | null;
  setSelectedProductGroup: Dispatch<
    SetStateAction<ProductsFilter | null | undefined>
  >;
  statsPeriod: StatsPeriods;
  setStatsPeriod: Dispatch<SetStateAction<StatsPeriods>>;
  filterData: FilterData | null;
  setBrandFilter: Dispatch<SetStateAction<any>>;
  brandFilter: string;
  categoryFilter: string;
  setCategoryFilter: Dispatch<SetStateAction<any>>;
  refetchSalesDrawer: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<SalesAssistantRead[], APIError>>;
  setValue: Dispatch<SetStateAction<string>>;
  selectedValue: string;
  sortDays: string;
  setSortDays: Dispatch<SetStateAction<string>>;
  setCategorySearchValue: Dispatch<SetStateAction<string>>;
  categorySearchValue: string;
  setBrandSearchValue: Dispatch<SetStateAction<string>>;
  brandSearchValue: string;
  allSorts: any;
  setAllSorts: React.Dispatch<React.SetStateAction<any>>;
};

export enum InsightTypes {
  AD_BOOSTER = "AD_BOOSTER",
}

export abstract class BaseInsightData {}

export type Insight = {
  id: string;
  type: InsightTypes;
  date: Date;
  image_url: string;
  data: BaseInsightData;
  done: boolean;
  archived: boolean;
  usdValue: number;
};

export abstract class BaseInsight implements Insight {
  data: BaseInsightData;

  date: Date;

  id: string;

  image_url: string;

  type: InsightTypes;

  archived: boolean;

  done: boolean;

  usdValue: number;

  constructor(
    data: BaseInsightData,
    date: Date,
    id: string,
    image_url: string,
    type: InsightTypes.AD_BOOSTER,
    archived: boolean,
    done: boolean,
    usdValue: number
  ) {
    this.archived = archived;
    this.done = done;
    this.data = data;
    this.date = date;
    this.id = id;
    this.image_url = image_url;
    this.type = type;
    this.usdValue = usdValue;
  }
}

export type FlexInsightData = Record<string, any>;

export class FlexInsight implements BaseInsight {
  data: FlexInsightData;

  date: Date;

  id: string;

  image_url: string;

  type: InsightTypes;

  archived: boolean;

  done: boolean;

  usdValue: number;

  constructor(
    data: FlexInsightData,
    date: Date,
    id: string,
    image_url: string,
    type: InsightTypes,
    archived: boolean,
    done: boolean,
    usdValue: number
  ) {
    this.archived = archived;
    this.done = done;
    this.data = data;
    this.date = date;
    this.id = id;
    this.image_url = image_url;
    this.type = type;
    this.usdValue = usdValue;
  }

  static fromObject(obj: Insight): FlexInsight {
    return new FlexInsight(
      obj.data,
      obj.date,
      obj.id,
      obj.image_url,
      obj.type,
      obj.archived,
      obj.done,
      obj.usdValue
    );
  }
}

export type addTaskTypes = {
  [key: string]: string;
};
export type FrontEggTeamMember = {
  id: string;
  email: string;
  name: string;
  profileImageUrl: string;
  profileImage: string;
  roleIds: string[];
};
