import {useAuthUserOrNull} from "@frontegg/react-hooks";
import LockIcon from "@mui/icons-material/Lock";
import PeopleIcon from "@mui/icons-material/People";
import {LoadingButton} from "@mui/lab";
import {Menu} from "@mui/material";
import {useSnackbar} from "notistack";

import {useMutation, useQueryClient} from "react-query";
import {VisibilityMenu} from "../../components/VisibilityMenu";
import {PlatformRole, VisibilityOptions, VisibilityType} from "../../enums";
import {updateBoard} from "../../services/circuitboards";
import {CircuitBoard} from "../../types";
import {FC, MouseEvent, useState} from "react"

const VisibilityButton: FC<{
  board: CircuitBoard;
}> = ({board}) => {
  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();
  const user = useAuthUserOrNull();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (value: VisibilityType) => {
    setAnchorEl(null);
    onSubmitBasic(value);
  };

  const mutationBasic = useMutation(updateBoard, {
    onSuccess: (data) => {
      enqueueSnackbar(
        `Updated visibility to: ${
          data.visibility === VisibilityType.WORKSPACE
            ? VisibilityOptions.WORKSPACE
            : VisibilityOptions.PRIVATE
        }`,
        {
          variant: "success",
        }
      );
      queryClient.invalidateQueries(["board", board.identifier]);
    },
    onError: () => {
      enqueueSnackbar("Failed to update the block visibility", {
        variant: "error",
      });
    },
  });

  const onSubmitBasic = (value: VisibilityType) => {
    mutationBasic.mutate({
      id: board.identifier,
      visibility: value,
    });
  };

  const notMyBoard = user?.id !== board.created_by_id;
  const hasNoogataAdmin = !!user?.roles?.find(
    (role: any) => role.key === PlatformRole.NoogataAdministrator
  );
  const shouldDisable = notMyBoard && !hasNoogataAdmin;

  return (
    <>
      {board.visibility === VisibilityType.WORKSPACE ? (
        <LoadingButton
          variant="outlined"
          size="large"
          onClick={handleClickOpen}
          id="visButton"
        >
          <PeopleIcon sx={{ mr: 1 }} /> Workspace visible
        </LoadingButton>
      ) : (
        <LoadingButton
          variant="outlined"
          size="large"
          onClick={handleClickOpen}
        >
          <LockIcon sx={{ mr: 1 }} /> Private
        </LoadingButton>
      )}

      <Menu
        sx={{ pt: 0 }}
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
      >
        <VisibilityMenu
          handler={handleListItemClick}
          shouldDisable={shouldDisable}
        />
      </Menu>
    </>
  );
};

export default VisibilityButton;
