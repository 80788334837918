import { useAuthUser } from "@frontegg/react";
import { useTreatments } from "@splitsoftware/splitio-react";
import { Split } from "../FeatureFlags/enums";

const environment = window.location.hostname.split(".")[0];
export default function useSplitEnabled(
  split: Split,
  attributes?: any
): boolean {
  const user = useAuthUser();
  const response = useTreatments([split], {
    ...attributes,
    tenant_id: user.tenantId,
    user_email: user.email,
    user_id: user.id,
    environment,
  });
  return response[split].treatment === "on";
}
