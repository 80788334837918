import UnarchiveIcon from "@mui/icons-material/Unarchive";
import {CircularProgress, ListItemIcon, MenuItem} from "@mui/material";

import {AuthorizedContent} from "../../../components/AuthorizedContent";
import {PlatformPermission} from "../../../enums";
import useUnarchive from "../../../services/runs/useUnarchive";
import {RunMenuItemProps} from "./types";
import {FC} from "react"

const UnarchiveMenuItem: FC<RunMenuItemProps> = ({
                                                     boardId,
                                                     runId,
                                                     onClick,
                                                 }) => {
    const mutation = useUnarchive(boardId, runId);
    return (
        // @ts-ignore
        <AuthorizedContent
            requiredPermissions={[PlatformPermission.RUNS_CREATE]}
            render={(isAuthz) => (
                <MenuItem
                    disabled={!isAuthz}
                    onClick={async () => {
                        onClick();
                        await mutation.mutateAsync();
                    }}
                >
                    <ListItemIcon>
                        {mutation.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <UnarchiveIcon />
            )}
          </ListItemIcon>
          Unarchive
        </MenuItem>
      )}
    />
  );
};

export default UnarchiveMenuItem;
