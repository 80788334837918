import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import SVG from "react-inlinesvg";
import { FC } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    svg: {
      mr: 1.5,
    },
  })
);

const HeaderTitle: FC<{
  title?: string;
  image?: string;
  onBack?: () => void;
  children?: JSX.Element;
}> = ({ title, image, onBack, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Box
        order={2}
        display="flex"
        alignItems="center"
        sx={{ minHeight: (t) => t.spacing(5), maxHeight: (t) => t.spacing(11) }}
      >
        {onBack && (
          <IconButton
            onClick={onBack}
            size="small"
            sx={{ backgroundColor: "grey[200]", mr: 2 }}
          >
            <ChevronLeftIcon sx={{ color: "common.black" }} />
          </IconButton>
        )}

        {image && (
          <SVG src={image} width={30} height="auto" className={classes.svg} />
        )}
        {title ? (
          <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
            {title}
          </Typography>
        ) : (
          <Skeleton variant="text" width={200} />
        )}
        {children}
      </Box>
    </div>
  );
};

export default HeaderTitle;
