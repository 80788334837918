import { Skeleton } from "@mui/material";

const CustomLoadingCellRenderer = () => {
  return (
    <Skeleton
        animation="wave"
        sx={{
          height: "12px",
          borderRadius: "5px",
          transform: "unset",
          marginTop: "1rem"
        }}
      />
  );
};

export default CustomLoadingCellRenderer
