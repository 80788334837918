import {Box, TextField, Typography} from "@mui/material";
import {FC, useEffect} from "react";

const WalmartForm: FC<{
    formMethods: any;
}> = ({formMethods}) => {
    useEffect(() => {
        formMethods.register("clientId", {
            required: true,
        });
        formMethods.setValue("clientId", "", {
            shouldValidate: false,
            shouldDirty: false,
        });
        formMethods.register("clientSecret", {
            required: true,
        });
        formMethods.setValue("clientSecret", "", {
            shouldValidate: false,
            shouldDirty: false,
        });
    }, [true]);

  return (
    <Box sx={{ mt: 4, width: "650px" }}>
      <Typography
        variant="body1"
        flexDirection="column"
        display="flex"
        sx={{ ml: 2, mr: 1, mb: 0.5 }}
      >
        Client ID
      </Typography>

      <TextField
        id="clientId"
        variant="outlined"
        sx={{ ml: 2, mb: 3, width: "66%" }}
        {...formMethods.register('clientId', {
          required: true,
        })}
      />

      <Typography
        variant="body1"
        flexDirection="column"
        display="flex"
        sx={{ ml: 2, mr: 1, mb: 0.5 }}
      >
        Client Secret
      </Typography>
      <TextField
        id="clientSecret"
        variant="outlined"
        sx={{ ml: 2, mb: 3, width: "66%" }}
        {...formMethods.register('clientSecret', {
          required: true,
        })}
      />
    </Box>
  );
};

export default WalmartForm;
