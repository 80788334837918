import { useQuery } from "react-query";
import { APIError } from "../../../services/apiRequest";
import { BoardDocs } from "../../../types";

export default function useFetchDocs(
  boardDocs: BoardDocs | undefined,
  isBoardDocsLoading: boolean
) {
  return useQuery<string, APIError>(
    ["fetchDocs", boardDocs?.changelog_md],
    async () =>
      fetch((boardDocs as BoardDocs).changelog_md).then((res) => res.text()),
    {
      enabled: !isBoardDocsLoading && !!boardDocs?.changelog_md,
      retry: (_, response) =>
        response.statusCode !== 404 && response.statusCode !== 500,
      refetchOnWindowFocus: false,
    }
  );
}
