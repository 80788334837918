import {Box} from "@mui/material";
import {FC} from "react"

type WrapperProps = {
    left: JSX.Element;
    right: JSX.Element;
};

const Wrapper: FC<WrapperProps> = ({left, right}) => (
    <Box display="flex">
        <Box width={500} minWidth={370}>
            {left}
    </Box>
    <Box width="100%">{right}</Box>
  </Box>
);

export default Wrapper;
