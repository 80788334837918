import { useAuthUserOrNull } from "@frontegg/react";
import { User } from "@frontegg/redux-store";
import { PlatformRole } from "../enums";

export const useUserHasNoogataAdminRole = (): boolean => {
  const user = useAuthUserOrNull();
  if (!user) return false;
  const { roles } = user;
  return !!roles.find(
    (role: any) => role.key === PlatformRole.NoogataAdministrator
  );
};

export const getUserMostPrivilegeRole = (user: User) =>
  user.roles.reduce(
    (maxLevelItem: any, item: any) =>
      item.level < maxLevelItem.level ? item : maxLevelItem,
    user.roles[0]
  );
