import {Box, Checkbox, FormControlLabel, FormGroup, SvgIconProps, Typography,} from "@mui/material";
import {Lock, People, PeopleOutline} from "@mui/icons-material";
import {User} from "@frontegg/redux-store";
import {PlatformRole, VisibilityType} from "../../../enums";
import {CircuitBoard} from "../../../types";
import {Filters} from "../types";
import {FilterOption} from "./BoardFilters";
import {AuthorizedContent} from "../../../components/AuthorizedContent";
import {FC} from "react"

export type VisibilityFilterOptions = Omit<FilterOption, "name"> & {
    name: VisibilityType;
    icon: FC<SvgIconProps>;
    roles?: string[];
};

export const visibilityFilters: VisibilityFilterOptions[] = [
    {
        label: "Private",
    icon: (props) => <Lock {...props} />,
    name: VisibilityType.PRIVATE,
  },
  {
    label: "Workspace",
    icon: (props) => <People {...props} />,
    name: VisibilityType.WORKSPACE,
  },
  {
    label: "All Private Blocks",
    icon: (props) => <PeopleOutline {...props} />,
    name: VisibilityType.ALL,
    roles: [PlatformRole.NoogataAdministrator],
  },
];

export const toVisibilityType = (i: string[] | string = []): VisibilityType[] =>
  (Array.isArray(i) ? i : [i]).filter((a) =>
    Object.values(VisibilityType).includes(a as VisibilityType)
  ) as VisibilityType[];

export const filterBoardsByVisibility =
  (user: User) => (props: { search: Filters }) => (b: CircuitBoard) => {
    const checkedVisibilitiesList = props?.search?.visibilities || [];

    if (
      checkedVisibilitiesList.includes(VisibilityType.ALL) &&
      b.visibility === VisibilityType.PRIVATE
    ) {
      return true;
    }
    if (
      checkedVisibilitiesList.includes(VisibilityType.PRIVATE) &&
      b.visibility === VisibilityType.PRIVATE &&
      b.created_by_id !== user.id
    ) {
      return false;
    }
    return checkedVisibilitiesList.includes(b.visibility);
  };

export const VisibilityFilters: FC<{
  onChange: (visibilities: Set<VisibilityType>) => void;
  visibilities: VisibilityType[];
}> = ({ onChange, visibilities }) => {
  const selectedVisibilitiesSet = new Set<VisibilityType>(visibilities);
  return (
    <FormGroup>
      <Typography variant="subtitle2">Visibility</Typography>
      {visibilityFilters.map((visibilityFilter, index) => {
        const IconComp = (
          visibilityFilters.find((v) => v.name === visibilityFilter.name) ||
          visibilityFilters[1]
        ).icon;

        return (
          <AuthorizedContent requiredRoles={visibilityFilter.roles} key={index}>
            <FormControlLabel
              key={visibilityFilter.name}
              control={
                <Checkbox
                  disableRipple
                  color="primary"
                  name={visibilityFilter.name}
                  checked={selectedVisibilitiesSet.has(visibilityFilter.name)}
                  onChange={(_, value) => {
                    const nextVisibilities = new Set<VisibilityType>(
                      selectedVisibilitiesSet
                    );
                    if (value) {
                      nextVisibilities.add(visibilityFilter.name);
                    } else {
                      nextVisibilities.delete(visibilityFilter.name);
                    }
                    if (nextVisibilities.size > 0) {
                      onChange(nextVisibilities);
                    }
                  }}
                />
              }
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconComp
                    sx={{
                      mr: 1,
                      width: 18,
                      height: 18,
                      color: (t) => t.palette.grey[500],
                    }}
                  />
                  <Typography>{visibilityFilter.label}</Typography>
                </Box>
              }
            />
          </AuthorizedContent>
        );
      })}
    </FormGroup>
  );
};
