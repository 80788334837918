import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  ListSubheader,
  Stack,
} from "@mui/material";
import { Search } from "../../components/SearchInput/Search";
import { useCategoryFilteredData, useSalesContext } from "./hooks/selectors";
import { useTenantsState } from "@frontegg/react-hooks/auth";

export const SelectHeaderSearch = () => {
  const { setCategorySearchValue } = useSalesContext();
  const handleSearch = (value: string) => {
    setCategorySearchValue(value);
  };
  return (
    <>
      <ListSubheader sx={{ padding: 0, height: "40px" }}>
        <Search
          fullWidth
          placeholder="Search"
          sx={{ height: "100%", fontSize: "15px" }}
          onChange={(e: any) => handleSearch(e.target.value)}
        />
      </ListSubheader>
    </>
  );
};

type CategoryProps = {
  handleSelect: any;
  categoryFilter: string;
  filterData: any;
};
const FilterOptions: FC<CategoryProps> = ({
  filterData,
  handleSelect,
  categoryFilter,
}) => {
  return (
    <Box sx={{ maxHeight: "232px", height: "100%", overflow: "auto" }}>
      {filterData?.map((el: any, key: number) => (
        <Stack
          key={key}
          direction="row"
          spacing={0}
          alignItems="center"
          onClick={() => handleSelect(el)}
          sx={{ padding: "8px 0px", cursor: "pointer" }}
        >
          <Checkbox
            disableRipple
            sx={{ padding: "0px 8px 0px 0px" }}
            onChange={() => handleSelect(el)}
            checked={el === categoryFilter}
          />

          <ListItemText
            primaryTypographyProps={{
              variant: "body3",
              color: (theme) => theme.palette.text.primary,
            }}
            primary={`${el.split("|")[0]} | ... | ${el.split("|").pop()}`}
          />
        </Stack>
      ))}
    </Box>
  );
};

const CategoryFilter = () => {
  const { categoryFilter, setCategoryFilter } = useSalesContext();
  const { activeTenant } = useTenantsState();
  const filterData = useCategoryFilteredData();


  const handleSelect = (value: any) => {
    if (categoryFilter === value) {
      setCategoryFilter("");
      localStorage.setItem("categoryFilter", "");
    } else {
      const categoryFilterData = {
        value: value,
        tenentID: activeTenant?.id,
      };
      setCategoryFilter(value);
      localStorage.setItem(
        "categoryFilter",
        JSON.stringify(categoryFilterData)
      );
    }
  };
  return (
    <FormControl variant="outlined" sx={{ padding: 0, width: "100%" }}>
      <SelectHeaderSearch />
      {/* <Box sx={{ display: "flex", gap: "12px" }}>
        <Typography
          variant="body4"
          sx={{
            height: "32px",
            padding: "6px 0px",
            textDecoration: "underline",
          }}
        >
          Select All
        </Typography>
        <Typography
          variant="body4"
          sx={{
            height: "32px",
            padding: "6px 0px",
            textDecoration: "underline",
          }}
        >
          Deselect All
        </Typography>
      </Box> */}
      <FilterOptions
        filterData={filterData}
        handleSelect={handleSelect}
        categoryFilter={categoryFilter}
      />
    </FormControl>
  );
};

export default CategoryFilter;
