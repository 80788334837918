import { useQuery, UseQueryOptions } from "react-query";
import { Flow } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

async function getFlow(flowId: string): Promise<Flow> {
  return await apiRequest<Flow>(`v1/flows/${flowId}`, "GET");
}

export default function useFlow(
  flowId: string,
  options?: UseQueryOptions<Flow, APIError>
) {
  return useQuery<Flow, APIError>(
    ["useFlow", flowId],
    () => getFlow(flowId).then(Flow.fromObject),
    {
      ...options,
    }
  );
}
