import { UseQueryResult } from "react-query";
import { useLocation } from "react-router-dom";
import { InsightsQueryParams } from "../pages/InsightsV2/constants";
import { InsightRead } from "../pages/InsightsV2/types";
import {
  useDoneInsightV2,
  useInsightV2,
} from "../pages/InsightsV2/useInsights";
import { APIError } from "../services/apiRequest";
import useQueryParams from "./useQueryParams";
import { useSalesDrawer } from "../pages/SalesAssistant/useSalesAssistant";

export const useLocalInsightV2 = (): UseQueryResult<InsightRead, APIError> => {
  const { pathname } = useLocation();
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  if (pathname.search("/done") > -1) {
    return useDoneInsightV2(insightId);
  }
  return useInsightV2(insightId);
};

export const useLocalSalesDrawer = (): UseQueryResult<any, APIError> => {
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  return useSalesDrawer(insightId);
};
