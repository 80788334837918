import { FC, FocusEvent } from "react";
import { AutocompleteRenderInputParams, TextFieldProps } from "@mui/material";

import { Search } from "../../components/SearchInput/Search";

export const AutoCompleteRenderInput: FC<{
  params: Partial<AutocompleteRenderInputParams & TextFieldProps>;
  inputProps?: {
    value: string;
    onChange: TextFieldProps["onChange"];
    onFocus?: (event: FocusEvent) => void;
  };
  onKeyDown?: TextFieldProps["onKeyDown"];
}> = ({ params, onKeyDown = () => {}, inputProps = {} }) => {
  const { InputProps: InputPropsFull, ...rest } = params;

  const { ...InputProps } = InputPropsFull || {};
  const inputPropsMerged = {
    ...(InputProps || {}),
    ...inputProps,
    sx: {
      height: "40px",
      minHeight: 0,
      input: {
        padding: " 0 8px !important",
      },
    },
  };
  return (
    <Search onKeyDown={onKeyDown} InputProps={inputPropsMerged} {...rest} />
  );
};
