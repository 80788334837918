import {TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import {FC} from "react"

const IntervalTypeInput: FC<{
    control: any
    setValue?: any
}> = ({control,setValue}) => (
    <Controller
        name="interval.value"
        control={control}
        shouldUnregister={false}
        render={({ value}: any) => (
            <TextField
                hiddenLabel
                id="interval-value"
                type="number"
                value={value}
                onChange={(e) => setValue("interval.value", e.target.value)}
                variant="outlined"
                size="small"
                sx={{width: 80}}
                inputProps={{min: 0}}
            />
    )}
  />
);

export default IntervalTypeInput;
