import { Card, Stack, Typography } from "@mui/material";
import { SkeletonRow } from "../../../../../components";
import { useBoard } from "../../../../../services/boards";
import TriggerCard from "./TriggerCard";
import { FC } from "react";

const BoardTriggersCard: FC<{ boardId: string }> = ({ boardId }) => {
  const { data, isLoading } = useBoard(boardId);
  return (
    <Card
      sx={(theme) => ({
        borderTopWidth: theme.spacing(0.5),
        borderTopColor: "grey.500",
      })}
    >
      <Stack>
        {isLoading ? (
          <SkeletonRow index={0} />
        ) : data && data.triggers.length > 0 ? (
          data.triggers.map((trigger) => (
            <TriggerCard boardId={trigger.board_id} />
          ))
        ) : (
          <Typography padding={2}>
            This block doesn&apos;t have triggers
          </Typography>
        )}
      </Stack>
    </Card>
  );
};
export default BoardTriggersCard;
