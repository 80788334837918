import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { useAssortmentIntelligence } from "./AssortmentIntelligenceProvider";
import theme from "../../themes/light";
import Table from "./components/Table/Table";
import TableSkeleton from "./components/Table/TableSkeleton";
import { isAttributeNumerical } from "./utils";
import PricingDistribution from "./components/PricingDistribution/PricingDistribution";
import PricingDistributionSkeleton from "./components/PricingDistribution/PricingDistributionSkeleton";

const PricingStrategy = () => {
  const {
    pricingStrategyAttribute,
    setPricingStrategyAttribute,
    spaceAttributes,
    pricingDistributionData,
    pricingDistributionLoading,
    revenueShareData,
    revenueShareLoading,
  } = useAssortmentIntelligence();

  const { distribution_data } = pricingDistributionData ?? {};
  const { table_data, table_schema } = revenueShareData ?? {};

  return (
    <Stack
      sx={{
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          padding: "16px",
          gap: "8px",
          height: "450px",
        }}
      >
        <Typography
          variant="body4"
          display="flex"
          sx={{
            fontSize: "14px",
            color: theme.palette.grey[400],
          }}
        >
          Products Sales Distribution By Price Range
        </Typography>
        {distribution_data && !pricingDistributionLoading ? (
          <PricingDistribution data={distribution_data} />
        ) : (
          <PricingDistributionSkeleton />
        )}
      </Stack>
      <Stack
        sx={{
          width: "100%",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          padding: "5px 16px 16px",
          gap: "8px",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "4px" }}>
          <Typography
            variant="body4"
            sx={{
              fontSize: "14px",
              color: theme.palette.grey[400],
            }}
          >
            {`Share of Revenue for Price Range and `}
          </Typography>
          <Select
            key={pricingStrategyAttribute}
            variant="standard"
            value={pricingStrategyAttribute}
            onChange={(e) => setPricingStrategyAttribute(e.target.value)}
            sx={{
              fontSize: "14px",
              color: theme.palette.text.primary,
            }}
          >
            {spaceAttributes
              .filter((attribute) => isAttributeNumerical(attribute))
              .map((attribute) => (
                <MenuItem
                  key={`pricing-strategy-${attribute.attribute}`}
                  value={attribute.attribute}
                >
                  {attribute.attribute}
                </MenuItem>
              ))}
          </Select>
        </Stack>
        {table_data && table_schema && !revenueShareLoading ? (
          <Table data={table_data} schema={table_schema} isHeatmap={true}/>
        ) : (
          <TableSkeleton columnsNumber={9} rowsNumber={8} variant="full" />
        )}
      </Stack>
    </Stack>
  );
};

export default PricingStrategy;
