import { Box } from "@mui/material";

import { ConnectorType } from "../enums";
import { ConnectorLogoMapping } from "../mappings";
import { FC } from "react";

type ConnectorTypeIconProps = {
  type: ConnectorType;
  iconClass: string;
};

const ConnectorTypeIcon: FC<ConnectorTypeIconProps> = ({ type, iconClass }) => (
  <Box className={iconClass}>
    <img alt={type as string} src={`/${ConnectorLogoMapping[type]}`} />
  </Box>
);

export default ConnectorTypeIcon;
