import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../apiRequest";
import {
  EcommerceProduct,
  PerfectContentProduct,
  ProductsFilterConfiguration,
} from "../../types/ecommerce";

export type GetProductsResponse = {
  total: number;
  items: EcommerceProduct[];
};

export type GetPerfectContentResponse = {
  total: number;
  data: PerfectContentProduct[];
};

const getEcommerceProducts = (
  offset: number,
  limit: number,
  filters?: ProductsFilterConfiguration,
): Promise<GetProductsResponse> =>
  apiRequest<GetProductsResponse>(
    "v1/onboarding/ecommerce/products/search",
    "POST",
    {
      offset,
      limit,
    },
    filters,
  );
const useEcommerceProducts = (
  offset: number = 0,
  limit: number = 50,
  options?: UseQueryOptions<GetProductsResponse, APIError>,
  filters?: ProductsFilterConfiguration,
): UseQueryResult<GetProductsResponse, APIError> =>
  useQuery<GetProductsResponse, APIError>(
    ["EcommerceProducts", offset, limit, filters],
    () => getEcommerceProducts(offset, limit, filters),
    options,
  );

export default useEcommerceProducts;
