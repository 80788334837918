import ScoreCard from "./ScoreCard.tsx";
import { Button, Stack } from "@mui/material";
import theme from "../../themes/light.ts";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { usePerfectContentV2 } from "./PerfectContnetProviderV2.tsx";

const ScoreBar = () => {
  const { productData } = usePerfectContentV2();
  return (
    <Stack direction="row" alignItems="center" width="100%">
      <ScoreCard title="Content Rank" baseScore={productData?.combined_score} />
      <ScoreCard title="Title" baseScore={productData?.title_score} />
      <ScoreCard title="About" baseScore={productData?.about_score} />
      <ScoreCard
        title="Description"
        baseScore={productData?.description_score}
      />
      <Stack direction="row" flex={1} justifyContent="end">
        <Button
          variant="text"
          startIcon={<AutoAwesomeIcon />}
          sx={{
            color: theme.palette.secondary.main,
            padding: "0 1rem 0 1.3rem",
            ":focus": {
              background: "unset",
            },
          }}
        >
          Optimize
        </Button>
      </Stack>
    </Stack>
  );
};

export default ScoreBar;
