import { FilterColumns, FilterMapping } from "./types";

export const columnWidths = {
  checkbox: "12px",
  image: "96px",
  info: "40%",
  action: "15%",
  rating: "15%",
  social: "110px",
};

export const Column2Display: Record<FilterColumns, string> = {
  strategy: "Opportunity",
  brand: "Brand",
  categoryId: "Category",
  assignedTo: "Assigned To",
} as const;

export enum InsightsQueryParams {
  InsightId = "insightId",
  EditTask = "editTask",
  DisplayImpactPotential = "displayImpactPotential",
}
export const INSIGHT_TYPES = {
  lossProtection: "Increase market share",
  potential: "Pottential Market Share",
} as const;

export type StatusFilter = {
  Todo: boolean;
  Completed: boolean;
  Dismissed: boolean;
};

export const EMPTY_FILTERS: FilterMapping = {
  strategy: {},
  brand: {},
  categoryId: {},
  assignedTo: {},
};

export const InsightColors = {
  success: {
    main: "#07A254",
    light: "#E7F7EF",
  },
  warning: {
    light: "#FDF4E3",
  },
};

export const ByValueFilters = ["strategy", "brand", "categoryId", "actionText"];
