import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Status } from "../enums";
import InputsDropdown from "../pages/Run/RunV2/OverviewSection/InputsDropdown";
import { useBoard } from "../services/boards";
import { MergedInput, RunInput, RunInputRow } from "../types";
import InputRow from "./InputRow";
import SkeletonRow from "./SkeletonRow";
import { FC } from "react";

const InputsTable: FC<{
  boardId: string;
  inputs?: RunInput[];
  run_inputs?: RunInputRow[];
  run_status?: string;
  onClick?: (i: { input: MergedInput }) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}> = ({ boardId, inputs, run_inputs, run_status, onClick, open, setOpen }) => {
  const { data: board } = useBoard(boardId);

  const displayNames: Record<string, string> | undefined =
    board &&
    board.pipelines[0].inputs.reduce(
      (obj, x) => Object.assign(obj, { [x.name]: x.display_name }),
      {}
    );

  const inputDict =
    inputs && Object.assign({}, ...inputs.map((x: any) => ({ [x.name]: x })));
  const runInputDict =
    run_inputs &&
    Object.assign({}, ...run_inputs.map((x: any) => ({ [x.name]: x })));
  const inputKeys = inputDict && Object.keys(inputDict);
  const mergedInputs: MergedInput[] = [];

  if (inputKeys) {
    inputKeys.reduce(
      (_value: MergedInput[], currentKey: string) =>
        mergedInputs.push({
          ...inputDict[currentKey],
          ...runInputDict[currentKey],
        }),
      mergedInputs
    );
  }

  const isFinished =
    run_status === Status.SUCCEEDED || run_status === Status.FAILED;

  return (
    <TableContainer sx={{ mb: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pt: 0, pl: 0 }}>
              {open && setOpen && (
                <InputsDropdown inputs={inputs} open={open} setOpen={setOpen} />
              )}
            </TableCell>
            <TableCell>Data source</TableCell>
            <TableCell>Rows</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mergedInputs && displayNames
            ? mergedInputs
                .filter((input) => !!input.value?.configuration)
                .map((input) => (
                  <InputRow
                    input={{
                      ...input,
                      displayName: displayNames[input.name]!,
                    }}
                    isFinished={isFinished}
                    onClick={() => isFinished && onClick && onClick({ input })}
                  />
                ))
            : [...Array(3).keys()].map((index) => (
                <SkeletonRow index={index} />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InputsTable;
