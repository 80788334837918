import {Box, ListItemIcon, MenuItem, Select, SelectChangeEvent, Typography,} from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ConnectorTypeIcon from "../../../../components/ConnectorTypeIcon";
import {RunInputValue} from "../../../../types";
import {ConnectorOption} from "../../../../types/connectorsV2";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
    createStyles({
        selectBoxChild: {
            margin: theme.spacing(0.6),
        },
        icon: {
            height: theme.spacing(3.1),
            width: theme.spacing(3.1),
            display: "flex",
      justifyContent: "center",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  })
);

type ConnectorOptionSelectProps = {
  inputValue: RunInputValue;
  connectorOptions: ConnectorOption[];
  onChange: (event: SelectChangeEvent) => void;
};

const ConnectorOptionSelect: FC<ConnectorOptionSelectProps> = ({
  inputValue,
  connectorOptions,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <Select
      value={inputValue.type}
      renderValue={(type: any) => {
        const option = connectorOptions.find((option) => option.type === type);
        return (
          <Box display="flex" alignItems="center">
            <Typography className={classes.selectBoxChild} variant="inherit">
              Connect to:
            </Typography>
            <ConnectorTypeIcon type={type} iconClass={classes.icon} />
            <Typography variant="inherit" className={classes.selectBoxChild}>
              {option?.title || type}
            </Typography>
          </Box>
        );
      }}
      disableUnderline
      onChange={onChange}
    >
      {connectorOptions &&
        connectorOptions.map((connectorOption) => (
          <MenuItem key={connectorOption.type} value={connectorOption.type}>
            <ListItemIcon>
              <ConnectorTypeIcon
                type={connectorOption.type}
                iconClass={classes.icon}
              />
            </ListItemIcon>
            <Typography variant="inherit">{connectorOption.title}</Typography>
          </MenuItem>
        ))}
    </Select>
  );
};

export default ConnectorOptionSelect;
