import { useQuery, UseQueryOptions } from "react-query";
import { InstallableCircuitboard } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

export async function getInstallableBoards(): Promise<
  InstallableCircuitboard[]
> {
  return await apiRequest<InstallableCircuitboard[]>("marketplace", "GET");
}

export default function useInstallableBoards(
  options?: UseQueryOptions<InstallableCircuitboard[], APIError>
) {
  return useQuery<InstallableCircuitboard[], APIError>(
    "installableBoards",
    () => getInstallableBoards(),
    { ...options }
  );
}
