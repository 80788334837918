export enum GridType {
  INPUT = "input",
  OUTPUT = "output",
}

export enum DownloadFileType {
  CSV = "CSV",
  PARQUET = "Apache Parquet File",
  EXCEL = "Microsoft Excel",
}
