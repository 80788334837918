import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2GroupTransition,
  TreeItem2Label,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import useProductsCategories from "../../services/ecommerce/useCategories";
import { TreeViewBaseItem, useTreeViewApiRef } from "@mui/x-tree-view";
import { CircularProgress, FormControlLabel } from "@mui/material";
import { useEffect } from "react";

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  display: "flex",
  alignItems: "center",
}));

type SelectionState = "none" | "partial" | "all";

export const CATEGORIES_SEPARATOR = "|";

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {
  selectionState: SelectionState;
  onToggle: (itemId: string, isSelected: boolean) => void;
}

type TreeNode = {
  id: string;
  label: string;
  children?: TreeNode[];
  name?: string;
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    selectionState,
    onToggle,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const isSelected = selectionState !== "all";
    onToggle(itemId, isSelected);
  };

  const handleCheckboxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)} sx={{ background: "unset" }}>
        <CustomTreeItemContent
          {...getContentProps()}
          sx={{
            background: "unset",
            gap: "5px",
            ":hover": {
              background: "unset",
            },
            ":focus": {
              background: "unset",
            },
          }}>
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <Box onClick={handleCheckboxClick}>
            <Checkbox
              checked={selectionState === "all"}
              indeterminate={selectionState === "partial"}
              onChange={handleCheckboxChange}
              size="small"
              sx={{
                padding: "5px",
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: "flex", gap: 1 }}>
            <TreeItem2Label {...getLabelProps()} />
          </Box>
        </CustomTreeItemContent>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export function buildTree(arr: [number, string][]): TreeViewBaseItem[] {
  const result: TreeViewBaseItem[] = [];

  arr.forEach(([count, path]) => {
    const keys = path.split(CATEGORIES_SEPARATOR);

    let currentLevel = result;
    keys.forEach((key, index) => {
      const existingNode = currentLevel.find((node) => node.id === key);
      if (existingNode) {
        const existingCount = existingNode.label.match(/\d+/);
        const updatedCount =
          (existingCount ? parseInt(existingCount[0]) : 0) + count;
        existingNode.label = `${key} (${updatedCount})`;

        if (!existingNode.children) {
          existingNode.children = [];
        }
        currentLevel = existingNode.children;
      } else {
        const newNode: TreeNode = {
          id: key,
          label: `${key} (${count})`,
          children: index < keys.length - 1 ? [] : undefined,
          name: key,
        };
        currentLevel.push(newNode);
        currentLevel = newNode.children!;
      }
    });
  });

  return result;
}

export const generateUniqueIds = (
  nodes: TreeViewBaseItem[],
  parentId: string = ""
): TreeViewBaseItem[] => {
  return nodes.map((node, index) => {
    const newId = `${parentId}${parentId ? "-" : ""}${index}`;
    return {
      ...node,
      id: newId,
      children: node.children
        ? generateUniqueIds(node.children, newId)
        : undefined,
    };
  });
};

function getItemDescendantsIds(item: TreeViewBaseItem) {
  const ids: string[] = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

const findItemById = (id: string, items: TreeNode[]): any | undefined => {
  for (const item of items) {
    if (item.id === id) return item;
    if (item.children) {
      const found = findItemById(id, item.children);
      if (found) return found;
    }
  }
  return undefined;
};

const findPaths = (nodes: any, selectedItems: string[]): string[][] => {
  const paths: string[][] = [];

  nodes.forEach((node: any) => {
    if (selectedItems.includes(node.id)) {
      if (node.children) {
        const childPaths = findPaths(node.children, selectedItems);
        if (childPaths.length) {
          childPaths.forEach((childPath) => {
            paths.push([node.name, ...childPath]);
          });
        } else {
          paths.push([node.name]);
        }
      } else {
        paths.push([node.name]);
      }
    } else if (node.children) {
      const childPaths = findPaths(node.children, selectedItems);
      childPaths.forEach((childPath) => {
        paths.push([node.name, ...childPath]);
      });
    }
  });

  return paths;
};

export function getLeafNodeIds(node: TreeNode): Set<string> {
  const leafNodeIds = new Set<string>();

  function traverse(currentNode: TreeNode) {
    if (currentNode.children && currentNode.children.length > 0) {
      for (const child of currentNode.children) {
        traverse(child); // Recurse into children
      }
    } else {
      leafNodeIds.add(currentNode.id); // No children, so it's a leaf node
    }
  }

  traverse(node);
  return leafNodeIds;
}

const FilterCategoriesV3 = ({
  setSelectedCategories,
  selectedItems,
  setSelectedItems,
  selectedCategories,
  onChange,
  isDataLabCatgeory
}: {
  selectedCategories?: string[][];
  setSelectedCategories: (selectedCategories: string[][]) => void;
  selectedItems: Set<string>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
  onChange?: (categories: string[][]) => void;
  isDataLabCatgeory?: boolean;
}) => {
  const toggledItemRef = React.useRef<{ [itemId: string]: boolean }>({});
  const apiRef = useTreeViewApiRef();
  const { data: datas = [], isLoading } = useProductsCategories({});
  const updatedList = buildTree(datas);
  const data = generateUniqueIds(updatedList);

  const getAllNodeIds = (nodes: TreeViewBaseItem[]): string[] => {
    return nodes.reduce<string[]>((acc, node) => {
      acc.push(node.id);
      if (node.children) {
        acc.push(...getAllNodeIds(node.children));
      }
      return acc;
    }, []);
  };

  const convertDataFormat = (data: [number, string][]): string[][] => {
    return data.map((item) => item[1].split(CATEGORIES_SEPARATOR));
  };

  const handleSelectAllCategories = () => {
    const tempSelected: Set<string> = new Set();
      data?.forEach(currentItem =>  {
        const ids = Array.from(getLeafNodeIds(currentItem));
        ids.forEach((e) => {
          tempSelected.add(e);
        });
      })
      const convertedData = convertDataFormat(datas);
      setSelectedCategories(convertedData);
      setSelectedItems(tempSelected);
      return convertedData;
  }

  useEffect(() => {
    if(isDataLabCatgeory && data?.length > 0) {
      handleSelectAllCategories();
    }
  }, [isDataLabCatgeory, JSON.stringify(data)])
  

  const handleToggle = (itemId: string, isSelected: boolean) => {
    const currentItem = findItemById(itemId, data);
    const tempSelected = new Set(selectedItems);
    const ids = Array.from(getLeafNodeIds(currentItem));
    ids.forEach((e) => {
      if (isSelected) {
        tempSelected.add(e);
      } else {
        tempSelected.delete(e);
      }
    });

    const itemsToSelect: string[] = [];
    const itemsToUnSelect: { [itemId: string]: boolean } = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current!.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });
    const selectedPaths = findPaths(data, Array.from(tempSelected));
    setSelectedCategories(selectedPaths);
    setSelectedItems(tempSelected);
    if(isDataLabCatgeory && !!onChange) {
      onChange(selectedPaths)
    }
  };

  // Helper function to find an item by its ID in the data structure

  const getSelectionState = (
    itemId: string,
    children?: TreeNode[]
  ): SelectionState => {
    const isSelected = selectedItems.has(itemId);

    if (children) {
      const childStates = children.map((child: any) =>
        getSelectionState(child.id, child.children)
      );

      if (childStates.every((state) => state === "all")) {
        return "all";
      }
      if (childStates.every((state) => state === "none")) {
        return isSelected ? "all" : "none";
      }
      return "partial";
    }

    return isSelected ? "all" : "none";
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const convertedData = handleSelectAllCategories();
      if(isDataLabCatgeory && !!onChange) {
        onChange(convertedData)
      }
    } else {
      setSelectedCategories([]);
      setSelectedItems(new Set());
      if(isDataLabCatgeory && !!onChange) {
        onChange([])
      }
    }
  };

  const renderTreeItems = (items: TreeNode[]) =>
    items.map((item: any) => (
      <CustomTreeItem
        key={item.id}
        itemId={item.id}
        label={item.label}
        selectionState={getSelectionState(item.id, item.children)}
        onToggle={handleToggle}>
        {item.children && renderTreeItems(item.children)}
      </CustomTreeItem>
    ));

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flex: 1,
          paddingBottom: "4rem",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: 200,
        minWidth: 250,
        height: "100%",
        overflow: "auto",
      }}>
      <FormControlLabel
        label="Select all"
        sx={{
          "& .MuiFormControlLabel-label": {
            fontFamily:
              "Inter, Roboto, Helvetica, Arial, sans-serif !important",
            fontSize: "16px !important",
            fontWeight: 400,
          },
          marginLeft: 0,
        }}
        control={
          <Checkbox
            size="small"
            value="ALL"
            checked={selectedCategories?.length === datas?.length}
            indeterminate={
              !!(
                selectedCategories?.length !== datas.length &&
                selectedCategories?.length
              )
            }
            onChange={handleSelectAllChange}
            sx={{
              padding: "5px",
            }}
          />
        }
      />
      <SimpleTreeView apiRef={apiRef}>{renderTreeItems(data)}</SimpleTreeView>
    </Box>
  );
}

export default FilterCategoriesV3
