import {Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";

import ParametersDropdown from "../pages/Run/RunV2/OverviewSection/ParametersDropdown";
import {RunParameter} from "../types";
import {FC} from "react"

const ParametersTable: FC<{
  parameters: RunParameter[];
  onClick?: (e: any) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}> = ({parameters, onClick, open, setOpen}) => {
  const filteredParameters = parameters.filter((parameter) => !!parameter.name);
  return (
      <TableContainer sx={{mb: 4}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pt: 0, pl: 0 }}>
              {open && setOpen ? (
                <ParametersDropdown open={open} setOpen={setOpen} />
              ) : (
                <Stack direction="row">
                  <Typography ml={2} sx={{ fontWeight: 600, fontSize: "18px" }}>
                    Parameters
                  </Typography>
                  <>
                    <Typography variant="h6" sx={{ mx: 1, color: "grey.500" }}>
                      •
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "grey.500",
                      }}
                    >
                      {filteredParameters.length}
                    </Typography>
                  </>
                </Stack>
              )}
            </TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredParameters.map((parameter) => (
            <TableRow
              key={parameter.name}
              onClick={onClick}
              sx={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {parameter.name}
              </TableCell>
              <TableCell>{parameter.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ParametersTable;
