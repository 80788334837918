import makeStyles from "@mui/styles/makeStyles";

import {useQuery} from "react-query";
import {Column, usePagination, useSortBy, useTable} from "react-table";
import {MetricRead, Run} from "../../types";
import {APIError} from "../../services/apiRequest";
import {getMetrics} from "../../services/tracking";
import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";
import {UncontrolledTable} from "../../components";
import {FC, useMemo} from "react"

export interface MetricsTableProps {
    run: Run | null;
}

const useStyles = makeStyles(() => ({
    container: {
        maxHeight: "100%",
    },
}));

const MetricsTable: FC<MetricsTableProps> = ({ run }) => {
  const { data, isLoading, error } = useQuery<MetricRead[], APIError>(
    ["metrics", run?.id || ""],
    () => getMetrics(run?.id || ""),
    { enabled: !!run }
  );

  const columns = useMemo(
    () => [
      {
        id: "key",
        Header: "Key",
        accessor: (metric: MetricRead) => metric.key,
      },
      {
        id: "value",
        Header: "Value",
        accessor: (metric: MetricRead) => metric.value,
      },
      {
        id: "step_display_name",
        Header: "Step",
        accessor: (metric: MetricRead) => metric.step_display_name,
      },
    ],
    []
  );

  const instance = useTable(
    {
      columns,
      data: data || [],
      autoResetSortBy: false,
      autoResetPage: false,
      initialState: { pageSize: 50 },
    },
    useSortBy,
    usePagination
  );
  const classes = useStyles();
  return (
    <>
      {!isLoading && data?.length === 0 ? (
        <IllustrationPlaceholder
          imageHeight={300}
          imageWidth={300}
          text="No metrics were recorded for this run."
        />
      ) : (
        <UncontrolledTable
          rowsPerPageOptions={[50]}
          tableContainerProps={{ className: classes.container }}
          tableProps={{
            stickyHeader: true,
            size: "small",
            "aria-label": "sticky table",
          }}
          columns={columns as Column<object>[]}
          data={data || []}
          instance={instance}
          isLoading={isLoading}
          error={error}
        />
      )}
    </>
  );
};

export default MetricsTable;
