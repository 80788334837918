import {Stack} from "@mui/material";
import ProductGroupFilter from "./ProductGroupFilter";
import {useSplitEnabled} from "../../hooks";
import {Split} from "../../FeatureFlags/enums";
import FilterAutoComplete from "./FilterAutoComplete";
import {Column2Display} from "./constants";
import {FilterColumns} from "./types";
import {FC} from "react"

export const Filters: FC = () => {
  const showProductGroupFilter = useSplitEnabled(
      Split.SHOW_PRODUCT_GROUP_FILTER
  );
  return (
      <Stack
          data-testid="insights-filter"
          direction="row"
          justifyContent="space-between"
      alignItems="flex-start"
      sx={{ padding: "4px 32px", gap: "24px" }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {showProductGroupFilter && <ProductGroupFilter />}
        {Object.keys(Column2Display).map((name) => (
          <FilterAutoComplete
            name={name as FilterColumns}
            key={`filter-${name}`}
          />
        ))}
      </Stack>
    </Stack>
  );
};
