import { useHistory } from "react-router";
import React, { Dispatch, SetStateAction } from "react";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { TaskIconV2 } from "../../../assets";

export const Tasks: React.FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  // const pageMatch = useRouteMatch({ path: ["/tasks" || "/archieve-task"] });
  const history = useHistory();
  // const location = useLocation();
  return (
    <LeftNavBarItem
      selected={tab === "tasks"}
      icon={<TaskIconV2 />}
      onClick={() => {
        setTab("tasks");
        history.push("/tasks");
      }}
      label="Tasks"
    />
  );
};
