import { FC, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useAuthUserOrNull } from "@frontegg/react-hooks";
import { ExportListItem } from "./ExportsListItem";
import GridOptionsList, { GridOptionsListProps } from "../GridOptionsList";
import { useExportEvents } from "../../../../../hooks/metrics/exportEvents";
import { FinalStatuses } from "../../../../../types/exports";
import { useLatestV2Exports } from "../../../../../services/exportsV2";

const ResultsList: FC<Partial<GridOptionsListProps>> = ({
  onExpand = () => {},
  open: propOpen,
}) => {
  const [stateOpen, setStateOpen] = useState(true);
  const user = useAuthUserOrNull();
  const match = useRouteMatch<{ boardId: string; runId: string }>(
    "/boards/:boardId/runs/:runId"
  );
  const { runId, boardId } = match?.params || {};

  const open = propOpen != null ? propOpen : stateOpen;

  const [refetchInterval, setRefetchInterval] = useState<number>(10);

  const { data = [] } = useLatestV2Exports(
    {
      run_id: runId!,
      created_by_id: user!.id,
    },
    {
      enabled: !!(open && runId && user),
      refetchInterval: refetchInterval * 1000,
      onSuccess: (data) =>
        data.some((e) => !FinalStatuses.has(e.status))
          ? setRefetchInterval(2)
          : setRefetchInterval(10),
    }
  );
  const isExportsEmpty = data.length === 0;

  const { downloadOutputEvent } = useExportEvents();
  return (
    <GridOptionsList
      open={isExportsEmpty ? false : open}
      onExpand={() => {
        setStateOpen(!stateOpen);
        onExpand(!propOpen);
      }}
      title="Results"
      disabled={isExportsEmpty}
      tooltip={isExportsEmpty ? "No export results" : ""}
      options={data.slice(0, 5).map((e) => ({
        node: (
          <ExportListItem
            key={e.id}
            export={e}
            onClick={
              e?.result?.indexOf("http") === 0
                ? () => {
                    downloadOutputEvent({
                      boardId,
                      type: e.destination_type,
                    });
                    window.open(e.result);
                  }
                : undefined
            }
          />
        ),
      }))}
    />
  );
};
export default ResultsList;
