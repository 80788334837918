import {
  //Autocomplete,
  Box,
  Button,
  Checkbox,
  //Chip,
  // Chip,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../themes/light.ts";
import { Controller, useForm } from "react-hook-form";
import AsinsListTextarea from "../EcommerceProducts/Amazon/AsinsListTextarea.tsx";
import { validateAsinsList } from "../EcommerceProducts/Amazon/AddProducts.tsx";
import { LoadLocalCsvFile } from "../EcommerceProducts/LoadLocalCsvFile.tsx";
import { getAsinsFromCSVFile } from "../EcommerceProducts/csvStreamUtils.ts";
import { useSpace } from "./SpaceProvider.tsx";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
// import { useMutation } from "react-query";
// import { createSpace } from "./useSpaces.ts";
import { Link, useHistory } from "react-router-dom";
// import CancelIcon from "@mui/icons-material/Cancel";
import SpacesHeader from "./SpacesHeader.tsx";
//import ProductTags from "./ProductTags.tsx";
import { getApiResponse } from "../../services/apiRequest.ts";

interface DataType {
  space_id: string;
}

import { SPACES_ROUTE } from '../../pages/Applications/Router'

const AddSpace = () => {
  const history = useHistory();
  const { spacesListRefetch } = useSpace();
  const snackbar = useSnackbar();
  const [autoEnrichVal, setAutoEnrichVal] = useState(true);
  const [rawAsins, setRawAsins] = useState<string>("");
  const [fileDroppedLoading, setFileDroppedLoading] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  // const commaSeperatedAsinsListRegex =
  //   /^[0-9A-Z]{10}(,[ ]?[0-9A-Z]{10})*(,[ ]?[0-9A-Z]{10})?$/;
  // const spaceSeperatedAsinsListRegex =
  //   /^[0-9A-Z]{10}([ ][0-9A-Z]{10})*([ ][0-9A-Z]{10})?$/;
  // const newlineSeperatedAsinsListRegex =
  //   /^[0-9A-Z]{10}(\n[0-9A-Z]{10})*(\n[0-9A-Z]{10})?$/;
  const { control, formState, handleSubmit, watch, setValue, register } =
    useForm<{
      name: string;
      asinsList: string[];
      asins: string[];
      autoEnrich: boolean;
    }>({
      mode: "onBlur",
      defaultValues: { name: "", asinsList: [], autoEnrich: true },
    });
  // const [currentSelection, setCurrentSelection] = useState<string[]>([]);
  useEffect(() => {
    register("name", { required: true });
    register("asinsList", { required: true });
    register("autoEnrich");
  }, []);
  const { errors, isDirty } = formState;
  const name = watch("name");

  const parsedAsinsList = watch("asinsList");

  // const mutation = useMutation(
  //   (values: { name: string; asins: string[]; autoEnrich: boolean }) =>
  //     createSpace(values)
  // );

  // const handleDelete = (asinIndex: number) => {
  //   const isCommaSeperatedString = commaSeperatedAsinsListRegex.test(rawAsins);
  //   const isSpaceSeperatedString = spaceSeperatedAsinsListRegex.test(rawAsins);
  //   const isNewlineSeperatedString =
  //     newlineSeperatedAsinsListRegex.test(rawAsins);

  //   const filteredAsinList = parsedAsinsList?.filter(
  //     (_, idx) => idx !== asinIndex
  //   );

  //   const updatedAsinsString = isCommaSeperatedString
  //     ? filteredAsinList?.join(", ")
  //     : isSpaceSeperatedString
  //       ? filteredAsinList?.join(" ")
  //       : isNewlineSeperatedString
  //         ? filteredAsinList?.join("\n")
  //         : rawAsins;

  //   setRawAsins(updatedAsinsString);

  //   setValue("asinsList", filteredAsinList, {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  //   setValue("asins", filteredAsinList, {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  // };

  // const handleDeleteAll = () => {
  //   setRawAsins("");

  //   setValue("asinsList", [], {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  //   setValue("asins", [], {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  // };

  const onSubmit = useCallback(
    async (values: any) => {
      console.warn(values);
      const response = await getApiResponse(
        "v1/spaces/sse",
        "POST",
        undefined,
        {
          asins: values.asins,
          name: values.name,
          autoEnrich: autoEnrichVal,
        }
      );
      if (!response.body) {
        return;
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let id: string = "";
     // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (value) {
          const matches = value.matchAll(/^.*"status":."(.*?)".*$/gm);
          for (const match of matches) {
            const jsonString = match[0].replace("data: ", "");
            const data: DataType = JSON.parse(jsonString);
            if (data.space_id) {
              id = data.space_id
            }
            setMessage(match[1]);
          }
        }
        if (done) {
          snackbar.enqueueSnackbar("Space successfully created", {
            variant: "success",
          });
          spacesListRefetch();
          if (id) {
            history.push(`${SPACES_ROUTE}/${id}`);
          } else {
            history.push(SPACES_ROUTE);
          }

          break;
        }
      }
    },
    [autoEnrichVal]
  );

  return (
    <Stack height={"100vh"}>
      <SpacesHeader />
      <Box
        sx={{
          flex: 1,
          background: theme.palette.grey[50],
          padding: 4,
          height: "100%",
          overflow: "auto",
        }}>
        {/* <form
          onSubmit={handleSubmit((values) =>
            mutation
              .mutateAsync(values)
              .then((res) => {
                setIsDrawerOpen(false);
                snackbar.enqueueSnackbar("Space successfully created", {
                  variant: "success",
                });
                spacesListRefetch();
                if (res?.id) {
                  history.push(`${SPACES_ROUTE}/${res?.id}`);
                } else {
                  history.push(SPACES_ROUTE);
                }
              })
              .catch((e) => {
                snackbar.enqueueSnackbar("Something went wrong: " + e, {
                  variant: "error",
                });
              })
          )}> */}
        <form onSubmit={handleSubmit((values) => onSubmit(values))}>

          <Container
            sx={{
              background: "white",
              borderRadius: "12px",
              padding: "24px",
              boxShadow:
                "0px 2px 10px -6px rgba(44, 34, 76, 0.05), 0px 2px 5px -4px rgba(44, 34, 76, 0.08)",
            }}>
            <Typography variant="h5" mb="16px">
              Build your space
            </Typography>

            <Stack spacing="24px">
              <Stack spacing={1} mt="16px">
                <Typography variant="body4">Give your space a name:</Typography>
                <TextField
                  variant="outlined"
                  {...register("name", { required: true })}
                  error={isDirty && !name}
                />

                {isDirty && !name && (
                  <Typography variant="caption" color="error">
                    Name is mandatory field
                  </Typography>
                )}
              </Stack>
              <Divider />
              <Stack spacing={1} direction="row" flex={1}>
                <Box flex={1} maxWidth="50%">
                  <Controller
                    control={control}
                    name="asinsList"
                    render={({ _onChange, ...props }: any) => {
                      return (
                        <Box>
                          <AsinsListTextarea
                            placeholder={`Type or paste a list of ASINs to add to your space.\nSeparate ASINs by space or comma.`}
                            onChange={(e) => {
                              setRawAsins(e.target.value);
                              setValue(
                                "asinsList",
                                validateAsinsList(e.target.value).asins,
                                {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                }
                              );
                              setValue(
                                "asins",
                                validateAsinsList(e.target.value).asins,
                                {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                }
                              );
                            }}
                            value={rawAsins}
                            errors={errors?.asinsList?.message}
                            {...props}
                          />
                          {/* <TextField
                            multiline
                            fullWidth
                            rows={5}
                            variant="filled"
                            value={rawAsins}
                            error={!!errors?.asinsList?.message}
                            helperText={errors?.asinsList?.message}
                            placeholder={
                              "Type or paste a list of ASINs to add to your training set.\nSeparate ASINs by space or comma."
                            }
                            {...props}
                            onChange={(e) => {
                              setRawAsins(e.target.value);
                              setValue(
                                "asinsList",
                                validateAsinsList(e.target.value).asins,
                                {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                }
                              );
                              setValue(
                                "asins",
                                validateAsinsList(e.target.value).asins,
                                {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                }
                              );
                            }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {parsedAsinsList?.length > 0 && (
                                    <ProductTags
                                      productList={parsedAsinsList}
                                      handleDelete={handleDelete}
                                      handleDeleteAll={handleDeleteAll}
                                    />
                                  )}
                                </>
                              ),
                              sx: {
                                padding: "8px 16px",
                                "& textarea": {
                                  padding: "0 !important",
                                  fontSize: "15px",
                                },
                                flexDirection: "column",
                              },
                            }}
                            sx={{
                              "& p.Mui-focused": { border: "0 none" },
                            }}
                          /> */}
                          {/* <Autocomplete
                            value={
                              parsedAsinsList.length ? parsedAsinsList : []
                            }
                            multiple
                            id="tags-filled"
                            options={[]}
                            defaultValue={[]}
                            freeSolo
                            sx={{
                              ".MuiInputBase-root": {
                                paddingTop: 0,
                              },
                              "& .MuiFilledInput-root": {
                                minHeight: "120px",
                                alignItems: "unset"
                              }
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  color="primary"
                                  variant="filled"
                                  size="small"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={(e: any, options, action) => {
                              e.preventDefault();
                              if (
                                action === "removeOption" ||
                                action === "clear"
                              ) {
                                // setCurrentSelection(options);
                                // onChange(options as string[]);
                                setValue("asinsList", options, {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                });
                                setValue("asins", options, {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                });
                              } else {
                                const list = validateAsinsList(
                                  options[options?.length - 1]
                                ).asins;
                                const updatedOptionList = [...options];
                                updatedOptionList.splice(-1, 1);
                                if (list && list?.length > 0) {
                                  const updatedList = [
                                    ...updatedOptionList,
                                    ...list,
                                  ];
                                  setValue("asinsList", updatedList, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  });
                                  setValue("asins", updatedList, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  });
                                }
                              }
                            }}
                            renderInput={({ inputProps, ...params }) => (
                              <TextField
                                {...params}
                                multiline={parsedAsinsList?.length === 0}
                                variant="filled"
                                // sx={{
                                //   overflow: "hidden",
                                //   textOverflow: "ellipsis",
                                //   whiteSpace: "nowrap",
                                // }}
                                placeholder={"Type or paste a list of ASINs to add to your training set.\nSeparate ASINs by space or comma."}
                                inputProps={{
                                  ...inputProps,
                                  onChange: (e: any) => {
                                    if (inputProps?.onChange) {
                                      inputProps?.onChange(e);
                                    }
                                  },
                                }}
                              />
                            )}
                          /> */}
                          {/* {!!errors?.asinsList?.message && (
                            <Typography variant="caption" color="error">
                              {errors?.asinsList?.message}
                            </Typography>
                          )} */}
                        </Box>
                      );
                    }}
                  />
                </Box>
                <Box alignSelf="stretch">
                  <Divider orientation="vertical">OR</Divider>
                </Box>
                <Box flex={1}>
                  <LoadLocalCsvFile
                    onFileDropped={(dropped_file) => {
                      setFileDroppedLoading(true);
                      getAsinsFromCSVFile(dropped_file)
                        .then((data) => {
                          setValue("asinsList", data, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          setValue("asins", data, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        })
                        .catch((e) =>
                          snackbar.enqueueSnackbar(e.message, {
                            variant: "error",
                          })
                        )
                        .finally(() => setFileDroppedLoading(false));
                    }}
                  />
                </Box>
              </Stack>
              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="autoEnrichVal"
                      sx={{ mx: 0 }}
                      checked={autoEnrichVal}
                      onChange={(e) => {
                        setAutoEnrichVal(e.target.checked);
                      }}
                    />
                  }
                  label="Let Noogata AI automatically identify your competitors"
                />
              </Stack>
            </Stack>
          </Container>
          <Container sx={{ padding: "0 !important" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              mt={3}
              spacing={1}
              sx={{ fontWeight: "bold" }}>
              <Typography variant="body3" color={theme.palette.primary.main}>
                {parsedAsinsList.length} ASINs were selected
              </Typography>
              <Link to={SPACES_ROUTE}>
                <Button variant="outlined">Cancel</Button>
              </Link>
              <Button
                variant="contained"
                disabled={
                  !formState.isValid ||
                  formState.isSubmitting ||
                  fileDroppedLoading
                }
                type="submit"
                startIcon={
                  (formState.isSubmitting || fileDroppedLoading) && (
                    <CircularProgress
                      size="small"
                      sx={{
                        height: (t) => t.spacing(2),
                        width: (t) => t.spacing(2),
                        color: (t) => t.palette.grey[300],
                      }}
                    />
                  )
                }>
                Create
              </Button>
            </Stack>
            <Typography color={theme.palette.primary.main} align="right" mt={2}>
              {message}
            </Typography>
          </Container>
        </form>
      </Box>
    </Stack>
  );
};

export default AddSpace;
