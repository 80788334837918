import { ReactNode, useMemo } from "react";
import { Box, Stack, Chip } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { SxProps } from "@mui/system";
import theme from "../../themes/light";
import { Icon } from "../../components/Icon";

const tabs = [
  { url: "/insights", icon: "insights/home-fill.svg", label: "Feed" },
  {
    url: "/insights/my-items",
    icon: "insights/my-items.svg",
    label: "My Items",
  },
  {
    url: "/insights/done",
    icon: "insights/check-fill.svg",
    label: "Done",
  },
  {
    url: "/insights/dismissed",
    label: "Dismissed",
    additionalSx: { marginLeft: "auto" },
  },
];

const BoxProps = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "8px",
  sx: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    borderBottom: "2px solid white",
  },
};
const ActiveProps = {
  color: theme.palette.text.link!, // fill: theme.palette.text.link!,
  borderBottom: `2px solid ${theme.palette.text.link!}`,
};
const Tab = ({
  url,
  icon,
  label,
  additionalSx,
  chip,
}: {
  url: string;
  icon?: string;
  label: string;
  additionalSx?: SxProps;
  chip?: ReactNode;
}) => {
  const history = useHistory();
  const location = useLocation();

  const sx = useMemo<SxProps>(() => {
    const currentPath = location.pathname.replace(/\/$/, "");
    const active = currentPath === url;

    let sx: SxProps = { ...BoxProps.sx };
    if (additionalSx) {
      sx = { ...sx, ...additionalSx };
    }
    if (active) {
      sx = { ...sx, ...ActiveProps };
    }
    return sx;
  }, [location.pathname, url, additionalSx]);

  return (
    <Box
      onClick={() => history.push(url)}
      {...BoxProps}
      sx={sx}
      minHeight="42px"
    >
      {icon && <Icon src={icon} width="16px" height="16px" />} {label}
      {chip}
    </Box>
  );
};
const HeaderTabs = ({
  newItems,
  myItems,
  isLoading,
}: {
  newItems?: number;
  myItems?: number;
  isLoading?: boolean;
}) => (
  <Stack direction="row" alignItems="center" gap="24px" marginTop="8px">
    {tabs.map((tab) => {
      let myChip = null;
      if (tab.label === "Feed" && !isLoading && !!newItems) {
        myChip = (
          <Chip
            label={`${newItems} New`}
            size="small"
            color="secondary"
            sx={{
              borderRadius: "500px",
              padding: "4px",
              background: theme.palette.backgrounds.active,
              fontSize: "13px",
            }}
          />
        );
      } else if (tab.label === "My Items" && !isLoading) {
        myChip = (
          <Chip
            label={myItems}
            size="small"
            sx={{
              borderRadius: "500px",
              padding: "4px",
              background: theme.palette.backgrounds.secondary,
              fontSize: "13px",
            }}
          />
        );
      }
      return <Tab key={tab.url} {...tab} chip={myChip} />;
    })}
  </Stack>
);

export default HeaderTabs;
