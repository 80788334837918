import { BlockType } from "@noogata/types";
import { ConnectorType, DestinationType } from "./enums";
import { DataSourceType } from "./types/datasources";

export const ConnectorTitleMapping: Partial<Record<ConnectorType, string>> = {
  Bigquery: "Google Bigquery Connector",
  SQL: "SQL Connector",
  S3: "S3 Connector",
  GCS: "Google Cloud Storage Connector",
  GOOGLE_SHEETS: "Google Sheets Connector",
  BOARD: "Noogata board",
  AMAZON_PRODUCTS: "Amazon Products",
};
export const ConnectorLogoMapping: Partial<Record<ConnectorType, string>> = {
  Bigquery: "connector-google-bigquery-icon.svg",
  SQL: "connector-sql-icon.svg",
  S3: "connector-aws-s3-icon.svg",
  GCS: "connector-google-cloud-storage-icon.svg",
  GOOGLE_SHEETS: "connector-google-sheets-icon.svg",
  Local: "connector-csv-icon.svg",
  BOARD: "connector-board-icon.svg",
  AMAZON_PRODUCTS: "connector-my-data-amazon.svg",
  SPACE: "blocks/enrichment.svg",
};

export const DestinationsLogoMapping: Partial<Record<DestinationType, string>> =
  {
    Bigquery: "connector-google-bigquery-icon.svg",
    SQL: "connector-sql-icon.svg",
    S3: "connector-aws-s3-icon.svg",
    GCS: "connector-google-cloud-storage-icon.svg",
    CSV: "csv-svgrepo-com.svg",
    EXCEL: "destination-microsoft-excel.svg",
    PARQUET: "parquet3.svg",
    GOOGLE_SHEETS: "destinations-google-sheets.png",
  };

export const DestinationTitleMapping: Partial<Record<DestinationType, string>> =
  {
    Bigquery: "Google Bigquery",
    SQL: "SQL",
    S3: "Amazon S3",
    GCS: "Google Cloud Storage",
    CSV: "CSV File",
    EXCEL: "Microsoft Excel File",
    PARQUET: "Apache Parquet File",
    GOOGLE_SHEETS: "Google Sheets",
  };

export const DataSourceLogoMapping: Partial<Record<DataSourceType, string>> = {
  amazon_north_america: "amazon-icon.svg",
  shopify_public: "shopify-icon.svg",
  amazon_ads_north_america: "amazon-ads-icon.svg",
  shopify: "shopify-icon.svg",
  walmart_seller_central: "walmart-icon.svg",
};

export const BlockTypeToIcon: Record<BlockType, string> = {
  [BlockType.CLASSIFICATION]: `/blocks/classific‎ation.svg‎`,
  [BlockType.CLUSTERING]: `/blocks/clustering.svg`,
  [BlockType.ENRICHMENT]: `/blocks/enrichment.svg`,
  [BlockType.FORECASTING]: `/blocks/forecasting.svg`,
  [BlockType.GENERAL]: `/blocks/general.svg`,
  [BlockType.PREDICTION]: `/blocks/predications.svg`,
  [BlockType.RECOMMENDATION]: `/blocks/recommendation.svg`,
};
