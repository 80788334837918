import { FC } from "react";

import { Redirect, Route } from "react-router-dom";
import { Box, Divider, ThemeProvider } from "@mui/material";
import theme from "../../themev2";
import BoardsHeader from "../Circuitboards/BoardsHeader";
import Circuitboards from "../Circuitboards";
import { WorkspaceTabs } from "./WorkspaceTabs";
import Flows from "../Flows";

export enum PageSections {
  Block = "boards",
  Flow = "flows",
}

const Workspace: FC<object> = () => (
  <ThemeProvider theme={theme}>
    <BoardsHeader />
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WorkspaceTabs />
      <Divider />
      <Route path="/" exact>
        <Redirect to={`/${PageSections.Block}`} />
      </Route>
      <Route path={`/${PageSections.Block}`} component={Circuitboards} />
      <Route path={`/${PageSections.Flow}`} component={Flows} />
    </Box>
  </ThemeProvider>
);

export default Workspace;
