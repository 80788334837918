import { ColDef } from "@ag-grid-community/core";
import { formatDateString } from "../../../../../utils";
import { ReactNode } from "react";

const isImage: (value: string) => boolean = (value) =>
  /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|svg))/.test(value);

const isUrl: (value: string) => boolean = (value) => /^http(s)?/.test(value);

const isASIN: (value: string) => boolean = (value) =>
  /^[0-9A-Z]{10}$/.test(value);

const isBoolean: (value: any) => boolean = (value) =>
  typeof value === "boolean";

const imageRenderer: (value: string) => ReactNode = (value) => (
  <a href={value} target="_blank" rel="noreferrer" style={{ display: "flex" }}>
    <img width="20" height="20" src={value} alt="asin" />
  </a>
);

const urlRenderer: (value: string) => ReactNode = (value) => (
  <a href={value} target="_blank" rel="noreferrer">
    ${value}
  </a>
);

const ASINRenderer: (value: string) => ReactNode = (value) => (
  <a href={`https://amazon.com/dp/${value}`} target="_blank" rel="noreferrer">
    {value}
  </a>
);

const renderOptions = [
  { conditionFunc: isImage, renderFunc: imageRenderer },
  { conditionFunc: isUrl, renderFunc: urlRenderer },
  { conditionFunc: isASIN, renderFunc: ASINRenderer },
  { conditionFunc: isBoolean, renderFunc: (value: any) => value.toString() },
];

export const cellRenderer: (params: any) => string = (params) => {
  const renderOption = renderOptions.find((option) =>
    option.conditionFunc(params.value)
  );
  if (renderOption) {
    return renderOption.renderFunc(params.value) || null;
  }
  if (
    params.colDef.valueFormatter &&
    typeof params.colDef.valueFormatter !== "string"
  ) {
    return params.colDef.valueFormatter(params) || null;
  }
  return params.value || null;
};

const numberFormatter = (params: any) =>
  params.value && typeof params.value === "number"
    ? params.value.toFixed(2)
    : params.value;

const dateFormatter = (params: any) =>
  params.value ? formatDateString(params.value) : params.value;

const noFormat = (params: any) => params.value;

export const getFormatter = (type: string) => {
  switch (type) {
    case "number":
      return numberFormatter;
    case "time":
      return dateFormatter;

    default:
      return noFormat;
  }
};

export const indexColumnsParams: ColDef = {
  valueGetter: "node.rowIndex + 1",
  width: 60,
  sortable: false,
  suppressMenu: true,
  pinned: "left",
};
