export interface Log {
  timestamp: Date;
  message: string;
}

export const logsToExclude = [
  "Kubernetes",
  "Pod has status",
  "Completed submission of flow run",
  "Downloading flow code from storage",
  "Created task run",
  "Created subflow run",
  "immediately...",
];


export enum EnrichmentFlowState {
  Running = "RUNNING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Crashed = "CRASHED",
  Pending = "PENDING",
  Scheduled = "SCHEDULED",
  Cancelled = "CANCELLED",
}

export enum CatalogEnrichmentTab {
  IDLE= -1,
  OVERVIEW = 0,
  LOGS = 1,
  LIST = 2,
}

export enum CatalogEnrichmentSteps {
  VALIDATE_RAW_INPUT = "validate_raw_input",
  VALIDATE_CAD = "validate_cad",
  SECONDARY_DATA_COLLECTION = "secondary_data_collection",
  PERFORMING_OCR = "performing_ocr",
  PREPROCESS_FOR_ATTRIBUTE_EXTRACTION = "preprocess_for_attribute_extraction",
  ATTRIBUTE_EXTRACTION = "attribute-extraction",
  POST_PROCESS_EXTRACTED_ATTRIBUTES = "post_process_extracted_attributes",
  SAVE_EXTRACTED_ATTRIBUTES = "save_extracted_attributes",
}
