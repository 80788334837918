import { FC } from "react";
import { Stack } from "@mui/material";
import { useQueryParams } from "../../../../hooks";
import { InsightsQueryParams } from "../../constants";
import { useInsightAssignees } from "../../hooks/selectors";
import { Team } from "../SocialAndActions";
import { TransformedInsight } from "../../types";

export const Collaboration: FC<{
  insight: TransformedInsight;
  max: number;
}> = ({ insight, max }) => {
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  const insightAssignees = useInsightAssignees(insightId || undefined);
  return (
    <Stack direction="row" alignItems="start" gap={2} flexGrow={0} mt={0}>
      {insightId && (
        <Team
          members={insightAssignees}
          insight={insight}
          origin="Drawer"
          max={max}
        />
      )}
    </Stack>
  );
};
