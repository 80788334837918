import { FC, useState } from "react";
import {
  Button,
  InputAdornment,
  OutlinedTextFieldProps,
  Paper,
  TextField,
} from "@mui/material";
import AceEditor, { IAceEditorProps } from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import { minifyJsonObject, prettifyJson } from "../utils";

export interface EditorFieldProps {
  value: string;
  onChange: any;
  onBlur?: any;
  textFieldProps?: OutlinedTextFieldProps;
  editorProps?: IAceEditorProps;
  error?: string;
}

const EditorField: FC<EditorFieldProps> = ({
  value,
  onChange,
  onBlur,
  textFieldProps,
  editorProps,
  error,
}) => {
  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);

  const handleToggleEditorClick = () => {
    try {
      const displayValue = JSON.parse(value || "");
      if (isEditorOpen) {
        onBlur(prettifyJson(displayValue) || "");
      }
    } catch (e) {
      console.debug(`Could not parse value ${value} as JSON`);
    }

    setIsEditorOpen(!isEditorOpen);
  };

  return (
    <>
      <TextField
        error={!!error}
        helperText={error}
        onBlur={(e) => onBlur && onBlur(minifyJsonObject(e.target.value) || "")}
        value={value}
        onChange={(e) =>
          onChange && onChange(minifyJsonObject(e.target.value) || "")
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleToggleEditorClick}>
              <Button color="secondary">
                {isEditorOpen ? "Close editor" : "Open editor"}
              </Button>
            </InputAdornment>
          ),
          readOnly: false,
        }}
        disabled={isEditorOpen}
        {...textFieldProps}
      />
      {isEditorOpen && (
        <Paper variant="outlined">
          <AceEditor
            value={prettifyJson(value)}
            onChange={(newValue: string) => {
              onChange(minifyJsonObject(newValue));
            }}
            onBlur={(_e, code) => {
              if (onBlur && value !== code?.getValue()) {
                onBlur(minifyJsonObject(code?.getValue() || ""));
              }
            }}
            {...editorProps}
          />
        </Paper>
      )}
    </>
  );
};

export default EditorField;
