import { Stack } from "@mui/material";
import { useAssortmentIntelligence } from "./AssortmentIntelligenceProvider";
import Table from "./components/Table/Table";
import TableSkeleton from "./components/Table/TableSkeleton";

const ProductsReview = () => {
  const { productsReviewData, productsReviewLoading } =
    useAssortmentIntelligence();
  const { table_schema, table_data } =
    productsReviewData ?? {}

  return (
    <Stack
      sx={{
        width: "100%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "16px",
        gap: "8px",
      }}
    >
      {table_data && table_schema && !productsReviewLoading ? (
        <Table
          data={table_data}
          schema={table_schema}
          defaultSortBy={"Impressions"}
          variant={"double"}
        />
      ) : (
        <TableSkeleton columnsNumber={10} rowsNumber={10} />
      )}
    </Stack>
  );
};

export default ProductsReview;
