import {Box, Button, Fade, Grid, Typography} from "@mui/material";

import {Link, useHistory} from "react-router-dom";
import {Status} from "../../../../enums";
import RunCard from "./RunCard";
import {useRuns} from "../../../../services/runs";
import {FC} from "react"

const INITIAL_LIMIT = 3;

const RecentRuns: FC<{
    boardId: string;
}> = ({boardId}) => {
    const history = useHistory();
    const {data: runs} = useRuns(boardId, {limit: 20});
    const finishedRuns = runs?.filter((run) =>
        [Status.FAILED, Status.SUCCEEDED].includes(run.status)
  );
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        marginBottom={1}
      >
        <Typography variant="h6">Recent Runs</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          to="runs"
          disabled={!(runs && runs.length > 0)}
        >
          VIEW ALL RUNS
        </Button>
      </Box>
      <Grid container direction="column" spacing={3}>
        {runs && runs.length === 0 ? (
          <Typography p={5}>No runs took place yet for this block</Typography>
        ) : finishedRuns && finishedRuns.length === 0 ? (
          <Typography p={5}>No runs have finished for this block</Typography>
        ) : (
          finishedRuns &&
          finishedRuns.slice(0, INITIAL_LIMIT).map((run) => (
            <Fade in>
              <Grid item>
                <RunCard
                  run={run}
                  key={run.id}
                  onClick={() => history.push(`runs/${run.id}`)}
                />
              </Grid>
            </Fade>
          ))
        )}
      </Grid>
    </>
  );
};

export default RecentRuns;
