import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import AddProducts from "./AddProducts";
import ESCComponent from "../ESCComponent";
import {FC} from "react"

const AddProductsDrawer: FC<{ onClose: () => void }> = ({
                                                            onClose = () => {
                                                            },
                                                        }) => {
    const snackbar = useSnackbar();
    const client = useQueryClient();
    return (
        <Box
            sx={{
                mx: 4,
                mt: 3,
                mb: 2,
                flex: 1,
                width: (t) => t.spacing(65),
            }}
        >
            <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 4 }}
      >
        <ESCComponent />
        <IconButton color="default" size="small" onClick={() => onClose()}>
          <Close />
        </IconButton>
      </Box>
      <AddProducts
          onError={(e: any) => {
              onClose();
              snackbar.enqueueSnackbar(`Failed to add products. ${e.message}`, {
                  variant: "error",
              });
          }}
          onSuccess={(res: any) => {
              client.refetchQueries(["EcommerceProducts"]);
              client.refetchQueries(["onboardingStatus"]);
              onClose();
              snackbar.enqueueSnackbar(
                  `Successfully added ${res.num_items_added} products.\n${res.num_of_duplicate_items} duplicate products, ${res.num_of_existing_items} existing products.`,
                  {variant: "success"}
              );
          }}
          onCancel={() => onClose()}
      />
    </Box>
  );
};

export default AddProductsDrawer;
