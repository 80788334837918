import { Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Cancel } from "@mui/icons-material";
import { Box } from "@mui/system";
import {
  addProductToTracked,
  removeProductFromTracking,
} from "../../../../services/runs/api";
import { SalesAssistantRead } from "../../types";
import { Icon } from "../../../../components/Icon";

const SaveToMyItemsBtn = ({
  outlined,
  insight,
  setIsClicked,
}: {
  outlined?: boolean;
  insight: SalesAssistantRead;
  setIsClicked: any;
}) => {
  const theme = useTheme();

  const handleProductTracked = async (id: string) => {
    setIsClicked(false);
    await addProductToTracked(id);
    setIsClicked(true);
  };

  const handleRemoveProductFromTracking = async (id: string) => {
    setIsClicked(false);
    await removeProductFromTracking(id);
    setIsClicked(true);
  };

  const handleTrackButton = () => {
    if (!insight?.tracked) {
      handleClick("Product moved to tracking List");
      handleProductTracked(insight?.id);
    } else if (insight?.tracked) {
      handleClick("Item removed from tracking List");
      handleRemoveProductFromTracking(insight?.id);
    }
  };

  const handleUndo = (snackbarId: any) => {
    closeSnackbar(snackbarId);
  };
  const action = (snackbarId: any) => (
    <>
      <Typography variant="body4" sx={{ color: "white", cursor: "pointer" }}>
        Undo
      </Typography>
      <Typography
        variant="body4"
        sx={{
          color: "white",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => handleUndo(snackbarId)}
      >
        <Cancel />
      </Typography>
    </>
  );
  const handleClick = (value: any) => {
    enqueueSnackbar("Custom snackbar message", {
      variant: "success",
      content: (key: any) => (
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          sx={{
            background: theme.palette.text.primary,
            minHeight: " 70px",
            padding: "4px 0px",
            borderRadius: "4px",
          }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            gap="8px"
            sx={{ padding: "8px 20px" }}
          >
            <Box>
              <Typography
                variant="body4"
                display="block"
                sx={{
                  fontSize: "15px",
                  color: "white",
                  margin: 0,
                  lineHeight: "24px",
                }}
              >
                {value}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            gap="16px"
            alignItems="center"
            sx={{ paddingRight: "16px" }}
          >
            {action(key)}
          </Box>
        </Box>
      ),
    });
  };

  return (
    <Button
      size="small"
      variant={outlined ? "outlined" : "text"}
      onClick={() => handleTrackButton()}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          color: theme.palette.text.primary,
          minHeight: "18px",
          padding: "3px",
          fontSize: "14px",
          fontWeight: "600",
          maxHeight: "24px",
          height: "24px",
        }}
      >
        <Icon src="insights/add-to-my-items.svg" width="16px" height="16px" />
        {/* Save to My Items */}
        {insight?.tracked ? "Untrack" : "Track"}
      </Stack>
    </Button>
  );
};

export default SaveToMyItemsBtn;
