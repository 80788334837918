import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTabsButtons } from "./useTabsButtons";

type Props = {
  insightIdForDialog: string;
  setInsightIdForDialog: (str: string) => void;
};

const DismissDialog = ({
  insightIdForDialog,
  setInsightIdForDialog,
}: Props) => {
  const { setDismissed } = useTabsButtons();

  return (
    <Dialog
      open={!!insightIdForDialog}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontWeight: 600, maxWidth: "420px", padding: "20px" }}
      >
        Dismissing this insight will remove it from your items
      </DialogTitle>
      <DialogContent
        sx={{
          maxWidth: "420px",
          padding: "20px",
        }}
      >
        <DialogContentText
          id="alert-dialog-description"
          sx={{ marginBottom: "24px" }}
        >
          Are you sure you want to dismiss it?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setInsightIdForDialog("");
          }}
        >
          Keep in My Items
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setDismissed(insightIdForDialog, 1);
            setInsightIdForDialog("");
          }}
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DismissDialog;
