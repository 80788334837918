import { Stack } from "@mui/material";
import { useSalesAssistant } from "../../SalesAssistantProvider.tsx";
import { Dispatch, SetStateAction } from "react";
import Tab from "./Tab.tsx";
import { tabsConfig } from "./enums.ts";

const Tabs = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}) => {
  const { selectedProduct } = useSalesAssistant();
  return (
    <Stack flex={2} gap="16px">
      {tabsConfig.map((tab) => (
        <Tab
          key={`drawer-tabs-${tab.value_name}`}
          title={tab.title}
          unit={tab.unit}
          setActiveTab={setActiveTab}
          active={activeTab === tab.value_name}
          value_name={tab.value_name}
          selectedProduct={selectedProduct}
        />
      ))}
    </Stack>
  );
};

export default Tabs;
