import {Divider, Tab, Tabs} from "@mui/material";

import {TabDefinition} from ".";
import {FC} from "react"

export const AdministrationHeader: FC<{
    tabs: TabDefinition[];
    value: string;
}> = ({tabs = [], value}) => (
    <>
        <Tabs value={value}>
            {tabs.map(({children, permissions, roles, ...props}, i) => (
                <Tab key={i} sx={{width: 170}} {...props} />
            ))}
    </Tabs>
    <Divider />
  </>
);

export default AdministrationHeader;
