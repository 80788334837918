const AsinRegex = /^[0-9A-Z]{10}$/;
const newLineRegex = /\r\n|\n/;

export const validateFirstCSVChunk = async ({ done, value }: any) => {
  if (done) {
    return [];
  }
  const data = new TextDecoder().decode(value).split(newLineRegex);
  const [firstLine, ...lines] = data;
  const maybe_columns = firstLine.split(",");
  if (maybe_columns.length > 1) {
    throw new Error("CSV must have only 1 column");
  }
  return [firstLine, ...lines]
    .map((a) => a.trim())
    .filter((line) => AsinRegex.test(line));
};

export const consumeAndFilterCSV =
  (reader: ReadableStreamDefaultReader<Uint8Array>, res: string[] = []) =>
  async ({ done, value }: any): Promise<string[]> => {
    if (done) {
      return res;
    }
    const data = new TextDecoder()
      .decode(value)
      .split(newLineRegex)
      .map((a) => a.trim());
    return reader
      .read()
      .then(
        consumeAndFilterCSV(reader, [
          ...res,
          ...data.filter((l) => AsinRegex.test(l)),
        ])
      );
  };

export const getAsinsFromCSVFile = async (file: File): Promise<string[]> => {
  const reader: ReadableStreamDefaultReader<Uint8Array> = file
    .stream()
    .getReader();
  const firstRowProm = reader.read().then(validateFirstCSVChunk);
  return Promise.all([
    firstRowProm,
    reader.read().then(consumeAndFilterCSV(reader)),
  ]).then((resultList) =>
    resultList.reduce((acc, item) => acc.concat(item), [])
  );
};

export const getProductGroupsFromCSVFile = async (file: File): Promise<any> => {
  const reader: ReadableStreamDefaultReader<Uint8Array> = file
    .stream()
    .getReader();
  return reader
    .read()
    .then(({ done, value }: any) => {
      if (done) {
        return [];
      }
      const data = new TextDecoder().decode(value).split(newLineRegex);
      const [firstLine, ...lines] = data;
      const maybe_columns = firstLine.split(",");
      if (maybe_columns.length !== 2) {
        throw new Error(
          "CSV must have exactly 2 columns (first: product group name, second: ASIN)"
        );
      }
      return [firstLine, ...lines]
        .map((a) => a.trim())
        .filter((line) => {
          const columns = line.split(",");
          return (
            columns[0].trim() &&
            columns[1].trim() &&
            AsinRegex.test(columns[1].trim())
          );
        });
    })
    .then((resultList) =>
      resultList.reduce((acc: any, item: any) => {
        const columns = item.split(",");
        if (acc[columns[0]] == undefined) {
          acc[columns[0]] = [];
        }
        acc[columns[0]].push(columns[1]);
        return acc;
      }, {})
    );
};
