import {Button, Stack} from "@mui/material";

import SearchBar from "../../../components/SearchInput/SearchBar";
import useDebouncedCallback from "../../../hooks/UseDebouncedCallback";
import {SearchParam} from "../../../hooks/useSearchParams";
import {BoardTypesFilters} from "./BoardTypesFilters";
import {CustomFilters} from "./CustomFilters";
import LibraryMenu from "./LibraryMenu";
import {toVisibilityType, VisibilityFilters} from "./VisibilityFilters";
import {FC} from "react"

export type FilterOption = {
  label: string;
  name: string;
};

const BoardFilters: FC<{
  onChange: (filters: Record<string, any>) => void;
  filters?: Record<string, SearchParam>;
  onFilterReset: () => void;
}> = ({ onChange, filters = {}, onFilterReset }) => {
  const isAllFiltersDisabled =
    Object.values(filters || {}).length === 1 &&
    Object.keys(filters)[0] === "visibilities" &&
    Object.values(filters)[0].toString().split(",").length === 2;

  const onSearchChange = useDebouncedCallback((_, text: string) => {
    onChange({ ...filters, text });
  }, 300);

  return (
    <Stack
      spacing={2}
      marginBottom={2.8}
      sx={{ position: "sticky", top: 0, paddingTop: 40 / 8 }}
    >
      <Button
        size="medium"
        onClick={onFilterReset}
        disabled={isAllFiltersDisabled}
        variant="outlined"
        fullWidth
      >
        All blocks
      </Button>

      <SearchBar size="small" options={[]} onInputChange={onSearchChange} />
      <VisibilityFilters
        visibilities={toVisibilityType(filters?.visibilities as string[])}
        onChange={(visibilities) =>
          onChange({ ...filters, visibilities: [...visibilities] })
        }
      />
      <CustomFilters onChange={onChange} filters={filters} />
      <BoardTypesFilters
        onChange={(typeName) => {
          const { library: _library, ...nextFilters } = filters || {};
          onChange({
            ...nextFilters,
            type: typeName === nextFilters.type ? "" : typeName,
          });
        }}
      />
      <LibraryMenu
        defaultValue={filters?.library as any}
        onChange={(library) => {
          const { type: _type, ...nextFilters } = filters || {};
          onChange({
            ...nextFilters,
            library: library === filters?.library ? "" : library,
          });
        }}
      />
    </Stack>
  );
};

export default BoardFilters;
