import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Stack } from "@mui/material";
import { Header } from "./Header";
import { InsightsList } from "./List/List";
import { useQueryParams } from "../../hooks";
import { Filters } from "./Filters";
import { InsightDrawer as SalesInsightDrawer } from "./List/SalesUnitDrawer/InsightDrawer";
import { InsightDrawer } from "./List/Drawer/InsightDrawer";
import { InsightsQueryParams } from "./constants";
import { BulkAction } from "./BulkAction";
import { useDisplayedSelectedInsights } from "./hooks/selectors";
import { EditTaskJson } from "./EditTaskJson";
import { useInsightsStyles } from "./useInsightsStyles";
import DismissDialog from "./List/TabButtons/DismissDialog";
import { useAppContext } from "../../ApplicationContext";

export const Insights: FC = () => {
  const queryParams = useQueryParams();
  const { insightsBody } = useInsightsStyles();
  const history = useHistory();
  const selectedCount = useDisplayedSelectedInsights().size;
  const [insightIdForDialog, setInsightIdForDialog] = useState("");
  const { insightType } = useAppContext();

  return (
    <>
      <Stack className={insightsBody} data-id="test">
        <Header />
        <Filters />
        <InsightsList setInsightIdForDialog={setInsightIdForDialog} />
        {insightType === "UNIT_SALES_DECREASE" ? (
          <SalesInsightDrawer
            onClose={() => {
              queryParams.delete(InsightsQueryParams.InsightId);
              history.replace({
                search: queryParams.toString(),
              });
            }}
            open={queryParams.has(InsightsQueryParams.InsightId)}
            setInsightIdForDialog={setInsightIdForDialog}
          />
        ) : (
          <InsightDrawer
            onClose={() => {
              queryParams.delete(InsightsQueryParams.InsightId);
              history.replace({
                search: queryParams.toString(),
              });
            }}
            open={queryParams.has(InsightsQueryParams.InsightId)}
            setInsightIdForDialog={setInsightIdForDialog}
          />
        )}
        {!!selectedCount && <BulkAction />}
        <EditTaskJson
          isOpen={queryParams.has(InsightsQueryParams.EditTask)}
          onClose={() => {
            queryParams.delete(InsightsQueryParams.EditTask);
            history.replace({
              search: queryParams.toString(),
            });
          }}
        />
      </Stack>
      <DismissDialog
        insightIdForDialog={insightIdForDialog}
        setInsightIdForDialog={setInsightIdForDialog}
      />
    </>
  );
};
