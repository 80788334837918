import {Autocomplete, Chip, TextField} from "@mui/material";
import {ParameterListFieldProps} from "./types";
import ParameterListFieldExtendedDialog from "./ParameterListFieldExtendedDialog";
import {FC, useState} from "react"

const parseJson = (value: string) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return [];
    }
};

const ParameterListField: FC<ParameterListFieldProps> = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const renderLimitTagsText = (more: number) => (
    <Chip
      variant="outlined"
      label={`+${more} more`}
      color="primary"
      clickable
      onClick={() => handleClickOpen()}
    />
  );
  const parsedValue = parseJson(props.value);
  const isInputValid = Array.isArray(parsedValue);
  return (
    <div>
      <Autocomplete
        multiple
        limitTags={2}
        getLimitTagsText={renderLimitTagsText}
        options={[]}
        value={isInputValid ? parsedValue : []}
        onChange={(_, value: string[]) => {
          props.onChange(JSON.stringify(value));
        }}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={
              isInputValid ? props.helperText : "Value must be a string array"
            }
            variant="outlined"
            margin="dense"
            fullWidth
            error={!isInputValid}
            label={props.label}
            placeholder="Enter item"
          />
        )}
        freeSolo
      />

      <ParameterListFieldExtendedDialog
        label={props.label}
        open={open}
        onClose={handleClose}
        values={isInputValid ? parsedValue : []}
        onChange={(value) => props.onChange(JSON.stringify(value))}
      />
    </div>
  );
};

export default ParameterListField;
