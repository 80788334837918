import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Cell, Row } from "react-table";
import { Box } from "@mui/material";
import cn from "classnames";

interface ColapsibleRowProps<T extends object = object> {
  row: Row<T>;
  className: string;
}

export const COLLAPSIBLE_KEY = "__colapse_content";

export default function <T extends object = object>({
  row,
  ...props
}: ColapsibleRowProps<T>) {
  const [open, setOpen] = useState(false);
  const colapsableCell = row.cells.find(
    (cell) => cell.column.id === COLLAPSIBLE_KEY
  );
  const rowProps = row.getRowProps();
  const className = cn(rowProps.className, props.className, {
    clickable: (props as any)?.onClick,
  });

  return (
    <>
      <TableRow
        {...rowProps}
        {...props}
        {...(colapsableCell && { onClick: () => setOpen(!open) })}
        className={className}
      >
        {colapsableCell && (
          <TableCell {...colapsableCell.getCellProps()}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {row.cells
          .filter((cell) => cell.column.id !== "__colapse_content")
          .map((cell: Cell<T>) => (
            <TableCell {...cell.getCellProps()}>
              {cell.render("Cell")}
            </TableCell>
          ))}
      </TableRow>
      {}
      {row.values.__colapse_content && (
        <TableRow className="collapsible-row">
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              ...(!open && { border: "none" }),
            }}
            colSpan={colapsableCell?.row.cells.length}
          >
            <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
              <Box>{colapsableCell?.render("Cell")}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
