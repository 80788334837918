import {
  Box,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../themes/light";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEffect, useRef, useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { CloneIconDark, RefreshIconDark, ShareTitle } from "../../assets";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  // getStoryBoardGroupData,
  useInsightChatContext,
} from "./hooks/selectors";
import LoadingSpinner from "../../components/LoadingSpinner";
interface ChatListRowProps {
  question: any;
  setSession: (id: string) => void;
  handleOpenShareDialog: (title: string, link: string) => void;
  handleOpenSyncDialog: (id: string) => any;
  copyStoryboard: (id: string) => any;
}

const ChatListRow = (props: ChatListRowProps) => {
  const { updateStoryboard, deleteStoryboard, storyboardId } =
    useInsightChatContext();
  const {
    question,
    setSession,
    handleOpenShareDialog,
    handleOpenSyncDialog,
    copyStoryboard,
  } = props;
  // const [storyboardID, setStoryboardID] = useState<string>("");
  const [showMore, setShowMore] = useState<boolean>(false);
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [copyLoading, setCopyLoading] = useState(false);
  const [title, setTitle] = useState<string>(question?.title || "");

  const anchorRef = useRef<HTMLButtonElement>(null);
  const handleMore = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  const handleEditMessage = async () => {
    const body = {
      title: title,
      share_link: question?.share_link,
    };
    const res = await updateStoryboard(question?.id, body);
    if (!res) {
      setTitle(question?.title);
    }
  };

  useEffect(() => {
    if (question?.title) {
      setTitle(question?.title);
    }
  }, [question?.title]);

  useEffect(() => {
    if (inEditMode === false && title !== question?.title) {
      handleEditMessage();
    }
  }, [inEditMode]);

  // useEffect(() => {
  //   setStoryboardID(question?.story_points[0]?.storyboard_id);
  // }, [question]);

  function handleListKeyDown(event: React.KeyboardEvent) {
    event.stopPropagation();
    if (event.key === "Tab") {
      event.preventDefault();
      setShowMore(false);
    } else if (event.key === "Escape") {
      setShowMore(false);
    }
  }

  const handleClose = async (
    event: Event | React.SyntheticEvent,
    actionMode: string
  ) => {
    event.stopPropagation();
    if (actionMode === "copy") {
      if (copyLoading) return;
      setCopyLoading(true);
      await copyStoryboard(question?.id).finally(() => setCopyLoading(false));
    }
    if (actionMode === "sync") {
      handleOpenSyncDialog(question?.id);
    }
    if (actionMode === "delete") {
      await deleteStoryboard(question?.id);
    }
    if (actionMode === "share") {
      handleOpenShareDialog(question?.title, question?.share_link);
    }
    if (actionMode === "rename") {
      setInEditMode(!inEditMode);
    }
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setShowMore(false);
  };

  const disablePropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleClickAwayListener = () => {
    setShowMore(false);
    setInEditMode(false);
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTitle(value);
  };

  const setSessionHandler = () => {
    if (!inEditMode) {
      setSession(question.id);
    }
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      setInEditMode(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayListener}>
      <Box
        sx={{
          padding: "4px 8px",
          cursor: "pointer",
          position: "relative",
          borderRadius: "8px",
          width: "100%",
          background:
            question.id === storyboardId ? theme.palette.primary.light : "",
          "&:hover": {
            background: theme.palette.primary.light,
            "& .MuiTypography-root": {
              color: theme.palette.primary.dark,
            },
            "& .MuiBox-root": {
              display: "flex",
            },
          },
          ...(inEditMode && {
            background: theme.palette.primary.light,
          }),
        }}
        onClick={setSessionHandler}
      >
        {inEditMode ? (
          <TextField
            id="outlined-textarea"
            value={title}
            onChange={handleChangeTitle}
            onKeyPress={handleKeyPress}
            sx={{
              // border: "8px",
              width: "100%",
              // padding: "0px !important",
              borderColor: "none",
              "& .MuiInputBase-input": {
                border: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.secondary.main,
                height: "24px !important",
                fontSize: "12px",
                fontWeight: "500",
                // lineHeight: "28px",
                padding: "6px 4px",
                borderRadius: "4px !important",
                "&:focus": {
                  backgroundColor: theme.palette.background.default,
                },
              },
              "& .MuiTextField-root": {
                borderRadius: "4px !important",
              },
              "& .MuiInputBase-root": {
                padding: "0",
                borderRadius: "4px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                outline: "none",
              },
            }}
          />
        ) : (
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              whiteSpace: "nowrap",
              overflow: "hidden",
              padding: "6px 4px",
            }}
          >
            {title}
          </Typography>
        )}
        {!inEditMode && (
          <Box
            sx={{
              display: "none",
              position: "absolute",
              top: "0",
              right: "0",
              width: "62.446px",
              height: "100%",
              justifyContent: "end",
              paddingRight: "5px",
              alignItems: "center",
              borderRadius: "8px",
              background: `linear-gradient(90deg, rgba(238, 229, 254, 0.00) 0%, ${theme.palette.primary.light} 42.5%)`,
            }}
          >
            <Tooltip
              placement="top"
              title="More"
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -5],
                      },
                    },
                  ],
                },
              }}
            >
              <Box ref={anchorRef}>
                <MoreHorizIcon
                  sx={{
                    "&:hover": {
                      color: theme.palette.secondary.main,
                    },
                  }}
                  onClick={handleMore}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
        <Popper
          open={showMore}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          sx={{
            zIndex: 1,
          }}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper onClick={disablePropagation}>
                <ClickAwayListener onClickAway={handleClickAwayListener}>
                  <MenuList
                    autoFocusItem={showMore}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) => handleClose(e, "sync")}
                      disableRipple
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                        padding: "5px",
                      }}
                    >
                      <RefreshIconDark />
                      Sync
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleClose(e, "copy")}
                      disableRipple
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: copyLoading ? "15px" : "10px",
                        padding: "5px",
                      }}
                    >
                      <CloneIconDark />
                      {copyLoading ? (
                        <LoadingSpinner size={24} color="black" />
                      ) : (
                        "Copy"
                      )}
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleClose(e, "share")}
                      disableRipple
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                        padding: "5px",
                      }}
                    >
                      <ShareTitle />
                      Share
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleClose(e, "rename")}
                      disableRipple
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "7px",
                        padding: "5px",
                      }}
                    >
                      <EditIcon sx={{ color: theme.palette.secondary.main }} />
                      Rename
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleClose(e, "delete")}
                      disableRipple
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "7px",
                        padding: "5px",

                        color: theme.palette.error.main,
                      }}
                    >
                      <DeleteIcon sx={{ color: theme.palette.error.main }} />
                      Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
export default ChatListRow;
