import { FC, useEffect, useState } from "react";
import {
  Skeleton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Done, Edit } from "@mui/icons-material";

export const EditableText: FC<{
  typographyProps: TypographyProps;
  textFieldProps: TextFieldProps;
  onSubmit?: (value: any) => void;
  isLoading?: boolean;
  value: string;
}> = ({
  typographyProps,
  textFieldProps,
  onSubmit,
  value,
  isLoading,
  ...props
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<string>(value || "");
  useEffect(() => {
    setTextFieldValue(value);
  }, [value]);
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isLoading ? (
        <Skeleton width={80} />
      ) : onSubmit && isEditMode ? (
        <TextField
          {...textFieldProps}
          {...props}
          onChange={(e) => {
            setTextFieldValue(e.target.value);
          }}
          sx={{ cursor: "pointer" }}
          value={textFieldValue}
        />
      ) : (
        <Typography {...typographyProps} {...props}>
          {textFieldValue}
        </Typography>
      )}
      {onSubmit && isHover && !isEditMode && (
        <Edit
          sx={{ width: 20, height: 20, cursor: "pointer" }}
          onClick={() => setIsEditMode(true)}
        />
      )}
      {isEditMode && (
        <Done
          sx={{ width: 20, height: 20, cursor: "pointer" }}
          onClick={() => {
            setIsEditMode(false);
            setIsHover(false);
            if (onSubmit) {
              onSubmit(textFieldValue);
            }
          }}
        />
      )}
    </Stack>
  );
};
