import { Schedule } from "../../../../../types";
import {
  formatDateString,
  formatTimeString,
  getDayOfWeek,
} from "../../../../../utils";
import { RepeatType } from "../../../../CreateNewRun/Schedule/hooks/useScheduleForm";

export const getScheduleDescription = (schedule: Schedule) => {
  if (!schedule.interval) {
    return `Scheduled at ${formatDateString(schedule.start_date)}`;
  }
  const prefix =
    schedule.interval.value === 1
      ? `Scheduled every ${schedule.interval.type}`
      : `Scheduled every ${schedule.interval.value} ${schedule.interval.type}s`;
  return schedule.interval.type === RepeatType.DAY
    ? `${prefix} at ${formatTimeString(schedule.start_date)}`
    : `${prefix} on ${getDayOfWeek(schedule.start_date)} at ${formatTimeString(
        schedule.start_date
      )}`;
};
