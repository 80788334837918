import makeStyles from "@mui/styles/makeStyles";
import { alpha } from "@mui/material/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  selectedItem: {
    color: theme.palette.primary.main,
    backgroundColor: `${alpha(theme.palette.primary.light, 0.1)} !important`,
  },
  item: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 4,
    borderRadius: theme.shape.borderRadius,
  },
  logo: {
    cursor: "pointer",
    marginBottom: theme.spacing(4),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      flex: `0 0 calc(${theme.spacing(9)} + 1px)`,
    },
    "& .MuiDrawer-paper": {
      padding: theme.spacing(0.875),
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    flex: `0 0 ${drawerWidth}px`,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
    boxShadow: "inset -6px 0 5px -7px rgb(0 0 0 / 21%)",
    overflow: "hidden",
  },
  userSection: {
    padding: "8px 2px",
    overflow:"hidden",
    '& .MuiCardHeader-content': {
      overflow: "hidden"
    }
  },
}));
