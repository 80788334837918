import { DrawerQuestion } from "../../types.ts";
import { Box, Stack, Typography } from "@mui/material";
import { Icon } from "../../../../components/Icon.tsx";
import { palette } from "../../../../themes/light.ts";
import { useMemo } from "react";
import EmbeddedQuestion from "./EmbeddedQuestion.tsx";

const Question = ({ question }: { question: DrawerQuestion }) => {
  const iconUrl = useMemo(() => {
    if (question.closeable && question.default_closed) {
      return "insights/collapse.svg";
    } else if (question.closeable) {
      return "insights/addIcon.svg";
    } else {
      return "insights/expand.svg";
    }
  }, [question]);
  return (
    <Stack gap="16px">
      <Stack direction="row" alignItems="center" gap="16px">
        <Box sx={{ minWidth: "16px" }}>
          <Icon src={iconUrl} width="16px" height="16px" />
        </Box>
        <Typography variant="body2" color={palette.text.primary}>
          {question.title}
        </Typography>
      </Stack>
      <EmbeddedQuestion drawerQuestion={question} />
    </Stack>
  );
};

export default Question;
