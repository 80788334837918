import {Button, Stack, TextField} from "@mui/material";
import {FC} from "react"

export const FiltersSaveForm: FC<{
  nameInputDisabled: boolean;
  saveDisabled: boolean;
  onNameChange: (name: string) => void;
  onSave: () => void;
  name?: string;
}> = ({saveDisabled, nameInputDisabled, onSave, onNameChange, name = ""}) => (
    <Stack
        direction="row"
    spacing={1}
    alignItems="center"
    style={{ marginBottom: 16 }}
  >
    <TextField
      placeholder="Type list name"
      size="small"
      sx={{ flex: 1 }}
      variant="filled"
      disabled={nameInputDisabled}
      onChange={(e) => onNameChange(e.target.value)}
      value={name}
    />
    <Button
      variant="contained"
      size="small"
      disabled={saveDisabled}
      onClick={onSave}
    >
      Save list
    </Button>
  </Stack>
);
