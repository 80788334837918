import "ag-grid-enterprise";
import {Dialog, DialogTitle} from "@mui/material";
import {AgGridEvent, CellValueChangedEvent, GridApi,} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import {FC, useState} from "react"

const newValueMsg = "(Enter new value)";

type ParameterListFieldExtendedDialogProps = {
    label: string;
    open: boolean;
    values: Array<string>;
    onClose: () => void;
    onChange: (value: string[]) => void;
};

const ParameterListFieldExtendedDialog: FC<ParameterListFieldExtendedDialogProps> =
  ({ label, onClose, open, values, onChange }) => {
      const [_gridApi, setGridApi] = useState<GridApi | null>(null);

    const tabledRenderedRows = [
      ...(values ? values.map((x) => ({ value: x })) : []),
      { value: newValueMsg },
    ];

    const onGridReady = (params: AgGridEvent) => {
      setGridApi(params.api);
    };

    const onCellValueChanged = (event: CellValueChangedEvent) => {
      const { rowIndex, data } = event;
      if (rowIndex !== null) {
        let newValues: string[] = [...values];
        newValues[rowIndex] = data.value;
        newValues = newValues.filter((n) => n);
        onChange(newValues);
      }
    };

    const sendToClipboard = (params: any) => {
      navigator.clipboard.writeText(params.data.replace(newValueMsg, ""));
    };

    const statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
      ],
    };

    const columnDefs = [
      {
        field: "value",
        headerName: "Value",
        editable: true,
        width: 442,
        aggFunc: "sum",
      },
    ];

    return (
      <Dialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
        <DialogTitle>{`Edit ${label} values`}</DialogTitle>
        <div
          style={{ height: 550, width: "100%" }}
          className="ag-theme-material"
        >
          <AgGridReact
            rowData={tabledRenderedRows}
            statusBar={statusBar}
            onCellValueChanged={onCellValueChanged}
            animateRows
            rowMultiSelectWithClick
            onGridReady={onGridReady}
            enableRangeSelection
            sendToClipboard={sendToClipboard}
            columnDefs={columnDefs}
          />
            {/* <AgGridColumn
              field="value"
              headerName="Value"
              editable
              width={339}
              aggFunc="sum"
            />
          </AgGridReact> */}
        </div>
      </Dialog>
    );
  };

export default ParameterListFieldExtendedDialog;
