import {MenuItem, Select} from "@mui/material";
import {RunInputValue} from "../../../../types";
import {Connector} from "../../../../types/connectorsV2";
import {FC} from "react"

type ConnectorSelectProps = {
    inputValue: RunInputValue;
    setInputValue: (runInputValue: RunInputValue) => void;
    connectors: Connector[];
};

const ConnectorSelect: FC<ConnectorSelectProps> = ({
                                                       inputValue,
                                                       setInputValue,
                                                       connectors,
                                                   }) => (
  <Select
    onChange={(event) =>
      setInputValue({
        ...inputValue,
        connector_id: event.target.value as string,
      })
    }
    value={inputValue?.connector_id || ""}
    disabled={connectors && connectors.length === 0}
  >
    {connectors &&
      connectors.length > 0 &&
      connectors.map((connector) => (
        <MenuItem key={connector.id} value={connector.id}>
          {connector.name}
        </MenuItem>
      ))}
  </Select>
);

export default ConnectorSelect;
