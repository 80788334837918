import {Skeleton, Stack, Typography} from "@mui/material";
import {useAllProductsCount} from "../useAllProductsCount";
import {FC} from "react"

const HarmonizedSummary: FC = () => {
    const {totalInHarmonized, isLoading: isTotalProductsLoading} =
        useAllProductsCount();

    return (
        <Stack direction="row" spacing={0.5}>
            <Typography>The system found</Typography>
            {!isTotalProductsLoading ? (
                <Typography>{totalInHarmonized?.total_products || 0}</Typography>
      ) : (
        <Skeleton width={24} />
      )}
      <Typography>products, and matched</Typography>
      {!isTotalProductsLoading ? (
        <Typography>{totalInHarmonized?.products_in_groups || 0}</Typography>
      ) : (
        <Skeleton width={24} />
      )}
      <Typography>products into</Typography>
      {!isTotalProductsLoading ? (
        <Typography>{totalInHarmonized?.number_of_groups || 0}</Typography>
      ) : (
        <Skeleton width={24} />
      )}
      <Typography>groups.</Typography>
    </Stack>
  );
};

export default HarmonizedSummary;
