import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../../../services/apiRequest";
import { GetProductsResponse } from "./types";
import { ChannelTypes } from "../../../enums";

const getHarmonizedProducts = (
  per_page: number,
  page: number,
  searchText?: string,
  channels?: ChannelTypes[]
): Promise<GetProductsResponse> =>
  apiRequest<GetProductsResponse>("v1/harmonization/products", "GET", {
    per_page,
    page,
    q: searchText,
    channels,
  });

const getHarmonizedProductsCountByChannelType = (): Promise<
  GroupCountByChannelType[]
> =>
  apiRequest<GroupCountByChannelType[]>(
    "v1/harmonization/products/channels/count",
    "GET"
  );

export const useHarmonizationProducts = ({
  page,
  per_page,
  searchText,
  options,
  channels,
}: {
  per_page: number;
  page: number;
  searchText?: string;
  channels?: ChannelTypes[];
  options?: UseQueryOptions<GetProductsResponse, APIError>;
}): UseQueryResult<GetProductsResponse, APIError> =>
  useQuery<GetProductsResponse, APIError>(
    [
      "HarmonizationProducts",
      per_page || 50,
      page || 1,
      searchText,
      channels && channels.sort().join("_"),
    ],
    () =>
      getHarmonizedProducts(per_page || 50, page || 1, searchText, channels),
    options
  );

export type GroupCountByChannelType = {
  channel_type: ChannelTypes;
  count: number;
};
export const useProductsGroupCountByChannelType = (props?: {
  options?: UseQueryOptions<GroupCountByChannelType[], APIError>;
}): UseQueryResult<GroupCountByChannelType[], APIError> =>
  useQuery<GroupCountByChannelType[], APIError>(
    ["ProductsGroupCountByChannelType"],
    () => getHarmonizedProductsCountByChannelType(),
    props?.options
  );
