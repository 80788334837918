import {
  Button,
  Card,
  CircularProgress,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ConnectorID } from "../../../../enums";
import { useBoard, useBoards } from "../../../../services/boards";
import { useRuns } from "../../../../services/runs";
import { prettifyString } from "../../../../utils";
import usePublishedRun from "../../../Run/RunV2/hooks/usePublishedRun";
import ConnectorOptionHeader from "../ConnectorOptionHeader";
import { ConnectionConfigurationProps } from "../types";
import { Artifact, PipelineOutput } from "../../../../types";
import { FC } from "react";

type BoardOutputForm = {
  board_id: string;
  run_id: string;
  output_id: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      "& > *": {
        marginBottom: theme.spacing(1),
      },
    },
  })
);

export const BoardConnectionConfiguration: FC<ConnectionConfigurationProps> = ({
  input,
  inputValue,
  setInputValue,
  onCompletion,
  resetInputValue,
}) => {
  const classes = useStyles();
  const { boardId }: { boardId?: any } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isValid, isSubmitting },
    watch,
  } = useForm<BoardOutputForm>({
    mode: "onChange",
    defaultValues: inputValue.configuration as BoardOutputForm,
  });
  const {
    board_id: formChosenBoardId,
    run_id: formChosenRunId,
    output_id: formChosenOutputId,
  } = watch();

  const { data: allBoards = [], isLoading: isBoardsLoading } = useBoards();
  const { data: board } = useBoard(boardId);
  const boards = allBoards
    .filter((b) => !board?.flow?.id || b?.flow?.id === board?.flow?.id)
    .filter((b) => b.identifier !== board?.identifier);
  const { data: runs, isLoading: isRunsLoading } = useRuns(
    formChosenBoardId,
    { status: "succeeded" },
    {
      enabled: !!formChosenBoardId,
    }
  );

  const { data: publishedRun } = usePublishedRun(formChosenBoardId);

  const onFormSubmit = (formValues: BoardOutputForm) => {
    setInputValue({
      ...inputValue,
      configuration: formValues,
      mapping: {},
      connector_id: ConnectorID.BOARD,
    });
    onCompletion();
  };

  const currentChosenBoard =
    formChosenBoardId &&
    boards &&
    boards.length > 0 &&
    boards.find((b) => b.identifier === formChosenBoardId);

  return (
    <>
      <Typography variant="h6">
        {`2. Choose a block as a source for ${prettifyString(
          input.name
        )} input`}
      </Typography>
      <Card variant="elevation" elevation={0}>
        <ConnectorOptionHeader
          connectorType={inputValue.type}
          text="Fetch data from a block output"
        />
        <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="block-label">Block</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  label="Block"
                  labelId="block-label"
                  disabled={isBoardsLoading}
                  {...register("board_id", { required: true })}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("board_id", e.target.value, {
                      shouldValidate: true,
                    });
                    setValue("run_id", "");
                    setValue("output_id", "");
                  }}
                >
                 {boards
                ?.filter((board) => board.identifier !== boardId)
                .map((board) => (
                  <MenuItem key={board.identifier} value={board.identifier}>
                    {board.displayName}
                  </MenuItem>
                ))}
                </Select>
              )}
              name="board_id"
              control={control}
              rules={{ required: true }}
            />
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="run-label">Run</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  label="Run"
                  labelId="run-label"
                  disabled={!formChosenBoardId || isRunsLoading}
                  {...field}
                  {...register("run_id", { required: true })}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("run_id", e.target.value, {
                      shouldValidate: true,
                    });
                    setValue("output_id", "");
                  }}
                >
                 <MenuItem key="latest" value="latest">
                Latest
              </MenuItem>
              {publishedRun && (
                <MenuItem key="published" value="published">
                  Current published run
                </MenuItem>
              )}
              {runs?.map((run) => (
                <MenuItem key={run.id} value={run.id}>
                  {run.name}
                </MenuItem>
              ))}
                </Select>
              )}
              name="run_id"
              control={control}
              rules={{ required: true }}
            />
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="output-label">Output ID</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  label="Output ID"
                  labelId="output-label"
                  disabled={!formChosenRunId}
                  {...field}
                  value={formChosenOutputId}
                  {...register("output_id", { required: true })}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("output_id", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                >
                  {currentChosenBoard &&
                currentChosenBoard.pipelines[0].outputs
                  ?.reduce(
                    (
                      previousValue: Artifact[],
                      currentValue: PipelineOutput
                    ) => [...previousValue, ...currentValue.artifacts],
                    []
                  )
                  .map((artifact: Artifact) => (
                    <MenuItem
                      key={artifact.export_name}
                      value={artifact.export_name}
                    >
                      {artifact.display_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="output_id"
              control={control}
              rules={{ required: true }}
            />
          </FormControl>

          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={resetInputValue}
              autoFocus
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                "Continue To Mapping"
              )}
            </Button>
          </DialogActions>
        </form>
      </Card>
    </>
  );
};
