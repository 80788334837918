import { Stack } from "@mui/material";
import AssortmentIntelligenceProvider, {
  useAssortmentIntelligence,
} from "./AssortmentIntelligenceProvider";
import Header from "./Header";
import MainSection from "./MainSection";
import EmptyState from "./EmptyState";

const Index = () => {
  const { spaceId, spaceAttributes, spaceAttributesLoading } =
    useAssortmentIntelligence();

  return (
    <Stack
      sx={{
        height: "100vh",
        width: `calc(100% - 50px)`,
      }}
    >
      <Header />
      {spaceId && (spaceAttributesLoading || spaceAttributes.length > 0) ? (
        <MainSection />
      ) : (
        <EmptyState />
      )}
    </Stack>
  );
};

export default () => (
  <AssortmentIntelligenceProvider>
    <Index />
  </AssortmentIntelligenceProvider>
);
