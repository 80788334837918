import { Box, Paper } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ImageIcon from "@mui/icons-material/Image";
import theme from "../../../../themes/light";

export const ImageComponent = ({ size = "small", url }: { size: string, url?: string }) =>
  url ? (
    <Box
      component="img"
      alt="Product for the opportunity"
      src={url}
      sx={{
        border: `1px solid ${theme.palette.borders.lines}`,
        background: "#fff",
        borderRadius: "8px",
        padding: "7px",
        width: size === "small" ? "45px" : size === "medium" ? "100px" : "100%",
        height: size === "small" ? "45px" : size === "medium" ? "100px" : "100%",
      }}
    />
  ) : (
    <ImageIcon
      sx={{
        height: "auto",
        width: "auto",
        objectFit: "contain",
        padding: 10,
      }}
    />
  );


const ProductImage = ({ url, size }: { url?: string, size?: string }) => {
  
  return (
    <Tooltip
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
          },
        },
      }}
      title={
        <Paper variant="elevation" elevation={5}>
          <ImageComponent size="large" url={url} />
        </Paper>
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ImageComponent size={size || "small"} url={url} />
      </Box>
    </Tooltip>
  );
};

export default ProductImage;
