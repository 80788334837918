import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import useProductsBrands from "../../services/ecommerce/useProductsBrands";
import { FC } from "react";

export const FilterBrands: FC<{
  selectedBrands: string[];
  onChange: (brands: string[]) => void;
}> = ({ onChange, selectedBrands }) => {
  const { data = [], isLoading } = useProductsBrands();

  return (
    <Box>
      {isLoading ? (
        <Stack height={325} overflow="scroll" display= "flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <FormGroup
          onChange={(e: any) => {
            const currentValue = e.target.value;
            if (currentValue === "ALL") {
              const isAllSelected = selectedBrands.length === data.length;
              onChange(
                isAllSelected ? [] : data.map(([_count, brand]) => brand)
              );
            } else {
              const isValueSelected = selectedBrands.find(
                (sb) => sb === currentValue
              );
              onChange(
                isValueSelected
                  ? selectedBrands.filter((sb) => sb !== currentValue)
                  : [...selectedBrands, currentValue]
              );
            }
          }}
          // sx={{ ml: 1 }}
        >
          {data?.length && (
            <FormControlLabel
              label="Select all"
              control={
                <Checkbox
                  size="small"
                  value="ALL"
                  checked={data.length === selectedBrands.length}
                />
              }
              sx={{
                margin: 0
              }}
            />
          )}
          <Stack height={325} overflow="scroll" paddingLeft="11px">
            {data
              .sort((a, b) => a[1].localeCompare(b[1]))
              .map(([count, brand]) => (
                <FormControlLabel
                  label={`${brand} (${count})`}
                  key={brand}
                  control={
                    <Checkbox
                      size="small"
                      value={brand}
                      checked={
                        !!selectedBrands.find(
                          (selectedBrand) => selectedBrand === brand
                        )
                      }
                    />
                  }
                />
              ))}
          </Stack>
        </FormGroup>
      )}
    </Box>
  );
};
