import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
// import "@ag-grid-community/core/dist/styles/ag-grid.css";
// import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import {ColDef, GridApi, ModuleRegistry, RowNode} from "@ag-grid-community/core";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {RowGroupingModule} from "@ag-grid-enterprise/row-grouping";
import {Box, Checkbox, FormControlLabel, useTheme} from "@mui/material";
// import {GridApi} from "@ag-grid-community/core/dist/cjs/es5/gridApi";
import {useAllProductsFilter} from "./AmazonProducts";
import {CollapsableBox} from "../../../components/CollapsableBox";
import useProductsCategories, {CategoryInformation,} from "../../../services/ecommerce/useCategories";

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);

export type CategoryTree = {
    name: string;
    children: CategoryTree[];
    total: number;
};

export const CATEGORIES_SEPARATOR = "|";

const calculateCategoryFromNode = (
  node: RowNode | null,
  path: string[] = []
): string[] => {
  if (node?.data) {
    return [node?.data[1]];
  }
  if (!node?.parent) {
    return path;
  }
    return calculateCategoryFromNode(node.parent, [node?.key as string, ...path]);
};

const getCategoryCount = (node: RowNode, data: CategoryInformation[]) =>
  data.reduce(
    (acc, [count, categoryStr]) =>
      categoryStr.startsWith(calculateCategoryFromNode(node).join("|"))
        ? count + acc
        : acc,
    0
  );

const FilterCategories: FC<{
  onChange: (a: string[]) => void;
  selected: string[][];
}> = ({ onChange, selected }) => {
  const [api, setApi] = useState<GridApi | null>(null);
  const allFilters = useAllProductsFilter();
  const { data = [] } = useProductsCategories({});
  const allConf = allFilters?.configuration;
  useEffect(() => {
    if (selected && api) {
      api.deselectAll();
      api.forEachNode((node: any) => {
        if (
          selected.find(
            (s) =>
              s.join(CATEGORIES_SEPARATOR) ===
              calculateCategoryFromNode(node, []).join(CATEGORIES_SEPARATOR)
          )
        ) {
          node.setSelected(true);
        }
      });
    }
  }, [selected.length, api]);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
    }),
    []
  );
  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: "",
      cellStyle: { width: "100%" },
      cellRendererParams: {
        innerRenderer: ({ node }: { node: RowNode }) =>
          `${node.key} (${getCategoryCount(node, data)})`,
        suppressCount: true,
        checkbox: true,
      },
    }),
    []
  );
  const getDataPath = useCallback(
    ([_count, categoryStr]: CategoryInformation) =>
      categoryStr.split(CATEGORIES_SEPARATOR),
    []
  );
  const { typography } = useTheme();
  const selectedRows = api?.getSelectedRows() || [];
  return (
    <CollapsableBox label="Categories">
      <Box sx={{ ml: 1 }}>
        <FormControlLabel
          label="Select all"
          control={
            <Checkbox
              size="small"
              value="ALL"
              checked={selectedRows.length === allConf?.categories?.length}
              indeterminate={
                !!(
                  selectedRows.length !== allConf?.categories?.length &&
                  selectedRows.length
                )
              }
              onChange={() => {
                if (selectedRows.length !== allConf?.categories?.length) {
                  api?.selectAll();
                } else {
                  api?.deselectAll();
                }
                onChange(
                  selectedRows.map(
                    ([_count, categories]: CategoryInformation) => categories
                  )
                );
              }}
            />
          }
        />
        <Box
          sx={{
            display: "flex",
            height: Math.min(
              300,
              new Set(data.map(getDataPath).map((i) => i[0])).size * 42
            ),
            flex: 1,
            ".ag-header": {
              display: "none",
            },
            ".ag-theme-alpine .ag-row ": {
              ...typography.body1,
            },
            ".ag-theme-alpine .ag-root-wrapper, .ag-theme-alpine .ag-row": {
              border: "none",
              background: "white",
            },
            ".ag-cell-value, .ag-group-value": {
              overflow: "visible",
            },
            ".ag-theme-alpine .ag-header-cell-menu-button:hover, .ag-theme-alpine .ag-side-button-button:hover, .ag-theme-alpine .ag-tab:hover, .ag-theme-alpine .ag-panel-title-bar-button:hover, .ag-theme-alpine .ag-header-expand-icon:hover, .ag-theme-alpine .ag-column-group-icons:hover, .ag-theme-alpine .ag-group-expanded .ag-icon:hover, .ag-theme-alpine .ag-group-contracted .ag-icon:hover, .ag-theme-alpine .ag-chart-settings-prev:hover, .ag-theme-alpine .ag-chart-settings-next:hover, .ag-theme-alpine .ag-group-title-bar-icon:hover, .ag-theme-alpine .ag-column-select-header-icon:hover, .ag-theme-alpine .ag-floating-filter-button-button:hover, .ag-theme-alpine .ag-filter-toolpanel-expand:hover, .ag-theme-alpine .ag-chart-menu-icon:hover":
              {
                color: (t) => t.palette.primary.main,
              },
            ".ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after, .ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after,":
              {
                color: (t) => t.palette.primary.main,
              },
            ".ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected)":
              {
                borderColor: (t) => t.palette.primary.main,
              },
            ".ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group":
              {
                paddingLeft: 0,
                paddingRight: 0,
              },
            ".ag-theme-alpine .ag-checkbox-input-wrapper": {
              fontSize: `${15}px`,
              lineHeight: `${15}px`,
            },
            ".ag-theme-alpine .ag-checkbox-input-wrapper::after": {
              borderColor: (t) => t.palette.grey[200],
            },
          }}
        >
          <Box className="ag-theme-alpine" sx={{ display: "flex", flex: 1 }}>
            <AgGridReact
              containerStyle={{ flex: 1 }}
              treeData
              animateRows
              groupSelectsChildren
              rowData={data}
              suppressRowClickSelection
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              rowSelection="multiple"
              getDataPath={getDataPath}
              onSelectionChanged={({ api }) => {
                const selectedCategories = api
                  .getSelectedRows()
                  .map(
                    ([_count, categories]: CategoryInformation) => categories
                  );
                if (
                  selectedCategories.length !== selected.length ||
                  selectedCategories.some(
                    (item, index) =>
                      item !== selected[index]?.join(CATEGORIES_SEPARATOR)
                  )
                ) {
                  onChange(
                    api
                      .getSelectedRows()
                      .map(
                        ([_count, categories]: CategoryInformation) =>
                          categories
                      )
                  );
                }
              }}
              onGridReady={(p) => setApi(p.api)}
            />
          </Box>
        </Box>
      </Box>
    </CollapsableBox>
  );
};

export default FilterCategories;
