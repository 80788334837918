import {alpha, Box, ListItemButton, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import {PipelineInput} from "../../../types";
import {InputListProps} from "./InputList";
import InputListItemIcon from "./InputListItemIcon";
import {FC} from "react"

const listItemStyles = (theme: Theme) => ({
  root: {
    justifyContent: "flex-start",
    width: "100%",
    "&$selected": {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  selected: {},
});

const StyledListItemButton = withStyles(listItemStyles, { withTheme: true })(
  ListItemButton
);

interface InputListItemProps extends InputListProps {
  input: PipelineInput;
}

const InputListItem: FC<InputListItemProps> = ({
  input,
  selectedInput,
  setSelectedInput,
  isInputValid,
}) => {
  const selected = input.name === selectedInput?.name;
  const valid = isInputValid(input.name);
  return (
    <StyledListItemButton
      key={input.name}
      selected={selected}
      onClick={() => setSelectedInput(input)}
      alignItems="flex-start"
    >
      <Box display="flex" paddingX={2} paddingY={1}>
        <InputListItemIcon selected={selected} valid={valid} />
        <Box flexDirection="column">
          <Typography
            variant="h6"
            color={
              selected
                ? "primary.main"
                : valid
                ? "success.dark"
                : "text.disabled"
            }
          >
            {input.display_name}
          </Typography>
          <Typography variant="caption" display="block" color="text.secondary">
            {input.description}
          </Typography>
          <Typography
            variant="overline"
            fontWeight={700}
            color="text.secondary"
          >
            {!input.required && "Optional"}
          </Typography>
        </Box>
      </Box>
    </StyledListItemButton>
  );
};

export default InputListItem;
