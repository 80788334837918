import { FC } from "react";

import {
  LoadMethodOptions,
  Query as CubeJsQuery,
  TCubeMemberType,
} from "@cubejs-client/core";
import { User } from "@frontegg/redux-store";
import { JSONSchema4 } from "json-schema";

import {
  BoardLibrary,
  ConnectorType,
  Currency,
  DestinationType,
  DownloadArtifactType,
  ExportStatus,
  OutputFormat,
  ParameterType,
  Status,
  VisibilityType,
  SpaceStatuses,
} from "./enums";
import { RepeatType } from "./pages/CreateNewRun/Schedule/hooks/useScheduleForm";

export type APIErrorResponse = {
  error: {
    code: string;
    message: string;
    type: string;
  };
};

export type Parameter = {
  name: string;
  json_schema?: string;
  description?: string;
  value: string | string[];
  default?: string | string[];
  is_advanced: boolean;
  type?: ParameterType;
};

export type Maintainer = {
  name: string;
  email?: string;
  url?: string;
};

export type Application = {
  name: string;
};

export type ConnectorSchema = JSONSchema4 & { data: Record<string, string>[] };

export type PipelineInput = {
  name: string;
  display_name: string;
  description: string;
  type: string;
  required: boolean;
  schema: ConnectorSchema;
};

export declare type Artifact = {
  post_processing?: string;
  display_name: string;
  pipeline_name: string;
  export_name: string;
  type: string;
  schema: JSONSchema4;
};

export declare class PipelineOutput {
  artifacts: Array<Artifact>;

  step: string;
}

export type Pipeline = {
  name: string;
  parameters: Array<Parameter>;
  inputs: Array<PipelineInput>;
  outputs?: Array<PipelineOutput>;
};

type BoardQuery = {
  id: string;
  name: string;
  description: string;
  query: CubeJsQuery;
};

type Analytics = {
  queries: BoardQuery[];
};

export type CircuitBoard = {
  identifier: string;
  displayName: string;
  description: string;
  version: string;
  maintainers: Array<Maintainer>;
  applications: Array<Application>;
  pipelines: Array<Pipeline>;
  image?: string;
  templateName?: string;
  template_description: string;
  template_id: string;
  latest_run?: string;
  analytics: Analytics;
  library: BoardLibrary;
  tags: string[];
  visibility: VisibilityType;
  created_by_id: string;
  created_by: Partial<User>;
  last_run?: Run;
  last_successful_run?: Run;
  runs: Run[];
  published_run_id?: string;
  published_by_id?: string;
  published_by_email?: string;
  bigquery_warehouse_export_enabled?: boolean;
  autoPublishLastRun: boolean;
  schedules?: Schedule[];
  triggers: Trigger[];
  triggered_by: Trigger[];
  flow?: Flow;
};

type FlowBlock = CircuitBoard & {
  flow: string;
};

type MaybeBlock = CircuitBoard | null | undefined;

export enum FlowTypes {
  DIGITAL_SHELF = "digital_shelf",
  AD_BOOSTER = "ad_booster",
}

export type FlowData = {
  id: string;

  type: FlowTypes;

  display_name: string;

  description: string;

  visibility: VisibilityType;

  status: Status;

  dashboards?: string[];

  bigquery_warehouse_export_enabled: boolean;

  blocks: FlowBlock[];

  triggeredBy?: Trigger;
};

export class Flow implements FlowData {
  id: string;

  type: FlowTypes;

  display_name: string;

  description: string;

  visibility: VisibilityType;

  status: Status;

  dashboards?: string[];

  bigquery_warehouse_export_enabled: boolean;

  blocks: FlowBlock[];

  triggeredBy?: Trigger;
  short_id: string;

  constructor(
    id: string,
    type: FlowTypes,
    display_name: string,
    description: string,
    visibility: VisibilityType,
    status: Status,
    dashboards: string[],
    bigquery_warehouse_export_enabled: boolean,
    blocks: FlowBlock[],
    triggeredBy: Trigger,
    short_id: string
  ) {
    this.id = id;
    this.type = type;
    this.display_name = display_name;
    this.description = description;
    this.visibility = visibility;
    this.status = status;
    this.dashboards = dashboards;
    this.bigquery_warehouse_export_enabled = bigquery_warehouse_export_enabled;
    this.blocks = blocks;
    this.triggeredBy = triggeredBy;
    this.short_id = short_id;
  }

  static fromObject(obj: any): Flow {
    return new Flow(
      obj.id,
      obj.type,
      obj.display_name,
      obj.description,
      obj.visibility,
      obj.status,
      obj.dashboards,
      obj.bigquery_warehouse_export_enabled,
      obj.blocks,
      obj.triggeredBy,
      obj.short_id
    );
  }

  isAllBlocksSucceed(): boolean {
    return this.blocks.every((b) => b.last_run?.status === Status.SUCCEEDED);
  }

  getLastSyncDate(): string | null {
    return this.isAllBlocksSucceed()
      ? (this.getFlowLastBlock()?.last_run?.finished_at as string)
      : null;
  }

  getFlowLastBlock(): MaybeBlock {
    return this?.blocks
      .filter(({ last_run }) => last_run)
      .reduce((lastBlock: MaybeBlock, block): MaybeBlock => {
        if (!lastBlock) {
          return block;
        }
        const lastBlockDate = new Date(lastBlock.latest_run!);
        const blockDate = new Date(block.latest_run!);
        if (lastBlockDate > blockDate) {
          return lastBlock;
        }
        return block;
      }, null);
  }
}

export type BoardExists = {
  exists: boolean;
};

export type BoardDocs = {
  doc_md: string;
  changelog_md: string;
  carousel: string[];
};

export type AcknowledgedResponse = {
  acknowledged: boolean;
};

export type RunMetric = {
  name: string;
  node_id: string;
  number_value: number;
  format: "UNSPECIFIED" | "RAW" | "PERCENTAGE";
};

export type RunOutput = {
  id: string;
  step_name: string;
  artifact_name: string;
  artifact_url?: string;
  export_name: string;
  total_rows: string;
  bigquery_warehouse_export_result?: string;
};

export type RunTrackingStatistics = {
  total_metrics: number;
  total_messages: number;
  message_severities: {
    info: number;
    warning: number;
    error: number;
  };
};

export type Run = {
  id: string;
  circuitboard_id: string;
  board_version: string;
  pipeline_name: string;
  user_name?: string;
  name: string;
  description: string;
  parameters: Parameter[];
  inputs: RunInput[];
  created_at: string;
  updated_at: string;
  started_at: string;
  finished_at: string;
  status: Status;
  kfp_run_id: string;
  error: string | null;
  metrics: RunMetric[];
  created_by_id: string;
  created_by_email: string;
  updated_by_id?: string;
  updated_by_email?: string;
  outputs: RunOutput[];
  failed_steps?: string[];
  tracking_statistics: RunTrackingStatistics;
  archived: boolean;
  schedule_id?: string;
  run_inputs: RunInputRow[];
  user_inf: Partial<User>;
};

export type UpdateRun = {
  boardId: string;
  runId: string;
  name?: string;
  description?: string;
  status?: Status.RUNNING;
};

export type Connector = {
  id: string;
  name: string;
  circuitboard_id: string;
  connector_type: ConnectorType;
};

export type ExportCreate = {
  boardId: string;
  runId: string;
  destinationDirectory: string;
  destinationIds: string[];
};

type ExportStatuses = {
  ExportStatus?: number;
};

export type Export = {
  id: string;
  circuitboard_id: string;
  run_id: string;
  connector_id: string;
  storage_link?: string;
  status: ExportStatus;
  statuses: ExportStatuses;
  started_at?: string;
  ended_at?: string;
  error_type?: string;
  error_message?: string;
};

export type CreateExportRequest = {
  boardId: string;
  runId: string;
  type: DestinationType;
  destinationId?: string;
  name: string;
  outputs: string[];
};

export type LatestExportsRequest = {
  run_id: string;
  created_by_id: string;
};

export type GeneratePresignToken = {
  circuitboardId: string;
  artifactUrl: string;
  outputFormat: OutputFormat;
  artifactType: DownloadArtifactType;
};

export type PublishedRun = {
  id: string;
  created_at: string;
  published_by?: string;
  user_id: string;
  run_id: string;
  run: Run;
  user_email?: string;
  user_name?: string;
};

export type DeleteFileRequest = {
  boardId: string;
  inputName: string;
  fileName: string;
};

export type GoogleSheetsCreate = {
  boardId: string;
  runId: string;
  accessToken: string;
  outputs?: string[];
};

export type GoogleSheetsResponse = {
  url: string;
};

export type UploadFileCreate = {
  circuitboardId: string;
  input_name: string;
  files: File[];
};

export type UploadFileResponse = {
  id: string;
  name: string;
  etag: string;
  created_by_id: string;
  created_by_email: string;
  content_type: string;
  size: number;
  last_modified: string;
  latest_schema: boolean;
  staged_file?: string;
};

export class RunParameter {
  name: string;

  value: string | null;

  constructor(name: string, value: string | null = null) {
    this.name = name;
    this.value = value;
  }
}

export type RunInput = {
  name: string;
  value?: RunInputValue;
};

export type RunInputRow = {
  name: string;
  id: string;
  total_rows: number;
};

export type RunInputValue = {
  type: ConnectorType;
  configuration?: Record<string, any>;
  connector_id?: string;
  mapping?: Record<string, string>;
};

export class RunCreate {
  name: string;

  description: string;

  parameters: RunParameter[];

  inputs: RunInput[];

  constructor(props: {
    name: string;
    description: string;
    parameters: RunParameter[];
    inputs: RunInput[];
  }) {
    this.name = props.name;
    this.description = props.description;
    this.parameters = props.parameters;
    this.inputs = props.inputs;
  }
}

export type Interval = {
  value: number;
  type: RepeatType;
};

export type ScheduleEnd = {
  value: Date | number | null;
  type?: "date" | "occurrences";
};

export type ScheduleForm = {
  start_date: Date;
  end: ScheduleEnd | null;
  interval?: Interval;
};

export type ScheduleCreate = ScheduleForm & {
  board_id: string;
  data: RunCreate;
};

export type Schedule = ScheduleCreate &
  ScheduleForm & {
    id: string;
    ends_at?: Date;
  };

export type TriggerCreate = {
  board_id: string;
  data: RunCreate;
};

export type Trigger = {
  id: string;
  board_id: string;
  triggered_by_ids: string[];
  data: RunCreate;
};

export type DownloadableRunOutput = {
  pre_signed_token: string;
};

export type InstallableCircuitboardVersion = {
  id: string;
  version: string;
  created_at: string;
};

export type InstallableCircuitboard = {
  id: string;
  template_name: string;
  description: string;
  tags: string[];
  versions: InstallableCircuitboardVersion[];
  image: string;
  library: BoardLibrary;
  managed: boolean;
  latest_version: string;
};

export type MetricRead = {
  circuitboard_id: string;
  run_id: string;
  step_id: string;
  step_display_name: string;
  timestamp: string;
  key: string;
  value: number;
  formatting: string;
};

export type Severity = "error" | "info" | "warning";

export type MessageRead = {
  circuitboard_id: string;
  run_id: string;
  step_id: string;
  step_display_name: string;
  timestamp: string;
  severity: Severity;
  message: string;
  code: string;
  formatting: "raw" | "markdown";
};

export type BoardUpdate = {
  id: string;
  description?: string;
  displayName?: string;
  visibility?: VisibilityType;
  bigqueryWarehouseExportEnabled?: boolean;
  autoPublishLastRun?: boolean;
};

export type OutputQueryRequest = {
  circuitboardId: string;
  runId: string;
  query: CubeJsQuery;
  options?: LoadMethodOptions | undefined;
};

export type BoardCreate = {
  id: string;
  template_id: string;
  version: string;
  values: string;
  description?: string;
  displayName?: string;
  visibility?: VisibilityType;
  flowId?: string;
};

export type FlowCreate = {
  description?: string;
  displayName?: string;
  visibility?: VisibilityType;
};

export type BoardVersionUpgrade = {
  id: string;
  template_id: string;
  version: string;
  values: string;
};

export type BoardEmailSubscriptionRead = {
  id: string;
  circuitboard_id: string;
  email: string;
};

export type BoardEmailSubscriptionCreate = {
  email: string;
};

export type OauthCreationRead = {
  accessToken: string;
  scopes?: string[];
};

export type accessTokenFromAuthCode = {
  accessToken: string;
};

/**
 * Reference: https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2authorizeconfig
 */
export type GoogleAuthorizeConfig = {
  client_id: string;
  scope: string;
  response_type?: string;
  prompt?: string;
  cookie_policy?: string;
  hosted_domain?: string;
  login_hint?: string;
  app_package_name?: string;
  openid_realm?: string;
  include_granted_scopes?: boolean;
};

/**
 * Reference: https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2authorizeresponse
 */
export type GoogleAuthorizeResponse = {
  access_token: string;
  id_token: string;
  code: string;
  scope: string;
  expires_in: number;
  first_issued_at: number;
  expires_at: number;
  error: string;
  error_subtype: string;
};

export type Dimension = {
  name: string;
  title: string;
  type: TCubeMemberType;
  shortTitle: string;
  suggestFilterValues: boolean;
};

export type Cube = {
  name: string;
  title: string;
  description: string;
  dimensions: Dimension[];
  measures: object[];
  segments: object[];
};

export type BoardAndRunIds = {
  boardId: string;
  runId: string;
};

export type HeaderBreadcrumb = {
  title?: string;
  url?: string;
  onSubmit?: (value: any) => void;
  component?: FC<{ last: boolean; breadcrumb: HeaderBreadcrumb }>;
};

export type InputDataRequest = BoardAndRunIds & {
  inputName: string;
  limit: number;
  offset: number;
};

export type MergedInput = RunInput & {
  total_rows: number;
};

export type FrontEggRole = {
  id: string;
  name: string;
  key: string;
};

export type FrontEggTeamMember = {
  id: string;
  email: string;
  name: string;
  profileImageUrl: string;
  profileImage: string;
  roleIds: string[];
};

export type MemberWithRoles = FrontEggTeamMember & {
  roles: FrontEggRole[];
};

export type Insight = {
  id: string;
  insightType: string;
  timeframe: string;
  brand: string;
  asin: string;
  categories: string[];
  flowId: string;
  paidImpShareOld: number;
  paidImpShareNew: number;
  paidDeltaNumber: number;
  changeOfPaidShare: number;
  paidSignificance: number;
  paidScore: number;
  orgImpShareOld: number;
  orgImpShareNew: number;
  orgDeltaNumber: number;
  changeOfOrgShare: number;
  orgSignificance: number;
  orgScore: number;
  insightText: string;
  action: string;
  valueOfInsight: number;
  insightCurrency: Currency;
  stars: number;
  insightDate: Date;
  imageUrl: string;
  assignees: string[];
  done: boolean;
  archived: boolean;
  actionDeepLinks?: Record<string, string>;
};

export type Space = {
  id: string;
  name: string;
  status: SpaceStatuses;
  updated_at?: string;
  created_at: string;
  total_asins?: number;
  total_suggested?: number;
};
