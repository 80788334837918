import {Button, Skeleton, Switch} from "@mui/material";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "react-query";
import {Box} from "@mui/system";
import {useParams} from "react-router-dom";
import PublishIcon from "@mui/icons-material/Publish";
import {updateBoard} from "../../services/circuitboards";
import {useBoard} from "../../services/boards";
import {FC} from "react"

const AutoPublishButton: FC<object> = () => {
    const {boardId}: { boardId?: any } = useParams();
    const {data: board, refetch} = useBoard(boardId);
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const mutation = useMutation(updateBoard, {
        onSuccess: (data) => {
            queryClient.setQueryData(["board", board?.identifier], data);
            enqueueSnackbar(
        `Automatic Publish is ${
          data.autoPublishLastRun ? "enabled" : "disabled"
        }`,
        { variant: "success" }
      );
      refetch();
    },
    onError: () => {
      enqueueSnackbar("Unable to set Automatic Publish configuration", {
        variant: "error",
      });
    },
  });
  if (!board) return <Skeleton height="100%" width="100%" />;
  return (
    <Box>
      <Button
        variant="outlined"
        size="large"
        startIcon={<PublishIcon />}
        endIcon={<Switch checked={board.autoPublishLastRun} />}
        onClick={() =>
          mutation.mutate({
            id: board.identifier,
            autoPublishLastRun: !board.autoPublishLastRun,
          })
        }
        disabled={mutation.isLoading}
        sx={{ paddingY: 0 }}
      >
        Automatic Publish
      </Button>
    </Box>
  );
};

export default AutoPublishButton;
