import Skeleton from "@mui/material/Skeleton";

const SkeletonContent = () => {
  return (
    <>
      <Skeleton
        animation="wave"
        style={{
          marginBottom: "10px",
        }}
        variant="text"
        width="100%"
        height={20}
        sx={{ marginBottom: "4px" }}
      />
      <Skeleton animation="wave" variant="text" width={400} height={20} />
    </>
  );
};

export default SkeletonContent;
