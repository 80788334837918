import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAppContext } from "../../../ApplicationContext";
import { LeftNavBarItem } from "./LeftNavBarItem";

export const LogoutNavItem = () => {
  const history = useHistory();
  const { setApplicationMode } = useAppContext();
  return (
    <Tooltip title="Logout">
      <>
        <LeftNavBarItem
          onClick={() => {
            setApplicationMode(false);
            history.push("/account/logout");
          }}
          selected={false}
          icon={<ExitToAppIcon />}
          label="Logout"
        />
      </>
    </Tooltip>
  );
};
