import { useHistory } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";

export const InsightsChatNavItem: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ setTab, tab }) => {
  const history = useHistory();

  // const label = true ? "Stories" : "Research Assistant"
  const label = "AI Stories";

  return (
    <LeftNavBarItem
      selected={tab === "chat"}
      icon={
        <SVG src={"/sales/sparkle.svg"} fill="inherit" width={20} height={20} />
      }
      onClick={() => {
        history.push("/chat");
        setTab("chat");
      }}
      label={label}
    />
  );
};
