import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../../services/apiRequest";
import { DataSourceOption } from "../../types/datasources";

const getDataSourcesOption = (): Promise<DataSourceOption[]> =>
  apiRequest<DataSourceOption[]>("v1/datasources", "OPTIONS");

const useDataSourcesOption = (
  options?: UseQueryOptions<DataSourceOption[], APIError>
): UseQueryResult<DataSourceOption[], APIError> =>
  useQuery<DataSourceOption[], APIError>(
    ["DataSourcesOption"],
    () => getDataSourcesOption(),
    { ...options }
  );

export default useDataSourcesOption;
