import { DestinationType } from "../../enums";
import {
  Destination,
  DestinationCreate,
  DestinationOption,
} from "../../types/destinations";
import apiRequest from "../apiRequest";

export function getDestinationOptions(): Promise<
  Record<DestinationType, DestinationOption>
> {
  return apiRequest<Record<DestinationType, DestinationOption>>(
    "destinations",
    "OPTIONS"
  );
}

export function createDestination(
  data: DestinationCreate
): Promise<Destination> {
  return apiRequest<Destination>("destinations", "POST", undefined, data);
}
