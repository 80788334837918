import {
  Avatar,
  Box,
  CircularProgress,
  CircularProgressProps,
  Link,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SvgIconProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC } from "react";
import ErrorOutlineIcon from "@mui/icons-material/Error";
import { Downloading } from "@mui/icons-material";
import dayjs, { capitaliseFirst } from "../../../../../utils";
import { Export as ExportV2, ExportStatus } from "../../../../../types/exports";
import { destinationTypeToImagePath } from "./utils";

const Loading: FC<Partial<CircularProgressProps>> = (props) => (
  <CircularProgress size={24} {...props} />
);

export const ExportStatusToIcon: Record<ExportStatus, FC<any>> = {
  [ExportStatus.PENDING]: Loading,
  [ExportStatus.RUNNING]: Loading,
  [ExportStatus.PROCESSING]: Loading,
  [ExportStatus.UPLOADING]: Loading,
  [ExportStatus.COMPRESSING]: Loading,
  [ExportStatus.FAILED]: (props: Partial<SvgIconProps>) => (
    <ErrorOutlineIcon
      fontSize="small"
      sx={{ color: "error.main" }}
      {...props}
    />
  ),
  [ExportStatus.SUCCEEDED]: (props: Partial<SvgIconProps>) => (
    <Downloading fontSize="small" sx={{ color: "success.main" }} {...props} />
  ),
  [ExportStatus.PARTIAL]: (props: Partial<SvgIconProps>) => (
    <Downloading fontSize="small" sx={{ color: "warning.light" }} {...props} />
  ),
};

export const ExportListItem: FC<{
  export: ExportV2;
  onClick?: () => void;
}> = ({ export: exportV2, onClick }) => {
  const IconCom = ExportStatusToIcon[exportV2.status] || (() => null);
  const ListComponent: FC<{ onClick?: () => void; disabled?: boolean }> =
    onClick ? ListItemButton : ListItem;
  const isExpired = dayjs.utc(exportV2.expired_at).isSameOrBefore(dayjs());
  const ExportInfoText = (
    <Stack spacing={0.5}>
      <Box>
        Type:
        {exportV2.destination_type}
      </Box>
      <Box>
        Outputs:
        {exportV2.outputs.join(", ")}
      </Box>
      <Box>
        Status:
        {capitaliseFirst(exportV2.status)}
      </Box>
      <Box>
        Created:
        {dayjs.utc(exportV2.created_at).from(dayjs())}
      </Box>
      {(exportV2.status === ExportStatus.FAILED ||
        exportV2.status === ExportStatus.PARTIAL) &&
        exportV2.error_message && (
          <Box>
            Message:
            {exportV2.error_message}
          </Box>
        )}
      {exportV2.status === ExportStatus.SUCCEEDED && exportV2.result && (
        <>
          {exportV2.expired_at && (
            <Box>
              Expired:
              {dayjs.utc(exportV2.expired_at).from(dayjs())}
            </Box>
          )}
          {!isExpired && (
            <Link
              href={exportV2.result}
              noWrap
              variant="inherit"
              sx={{ color: "grey.100" }}
              target="_blank"
            >
              Result: {exportV2.result}
            </Link>
          )}
        </>
      )}
    </Stack>
  );

  return (
    <Tooltip title={ExportInfoText} placement="right">
      <Box>
        {/*// @ts-ignore*/}
        <ListComponent onClick={onClick} disabled={isExpired}>
          <Avatar
            variant="rounded"
            sx={{ width: 20, height: 20, marginRight: 1.5 }}
            src={destinationTypeToImagePath(exportV2.destination_type)}
          />
          <ListItemText
            primary={
              <Typography noWrap>
                {exportV2.destination_directory ||
                  `${exportV2.destination_type}-${exportV2.outputs.join(",")}`}
              </Typography>
            }
            secondary={
              <Typography noWrap variant="body2" color="grey.600">
                {capitaliseFirst(exportV2.status)},{" "}
                {dayjs.utc(exportV2.updated_at).from(dayjs())}
              </Typography>
            }
          />
          <Box sx={{ width: 15, height: 15, marginLeft: 1.5 }}>
            <IconCom size={15} />
          </Box>
        </ListComponent>
      </Box>
    </Tooltip>
  );
};

export default ExportListItem;
