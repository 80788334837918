import { useTheme } from "@mui/material";
import { Status } from "../enums";

export const useStatusColor = (
  status?: Status | null,
  warning: boolean = false
): string => {
  const theme = useTheme();
  switch (status) {
    case Status.READY_TO_EXPORT:
    case Status.PENDING:
      return theme.palette.primary.light;
    case Status.GATHERING:
      return theme.palette.primary.light;
    case Status.RUNNING:
      return theme.palette.primary.light;
    case Status.EXPORTING:
      return theme.palette.primary.light;
    case Status.SUCCEEDED:
      return warning
        ? theme.palette.warning.light
        : theme.palette.success.light;
    case Status.TERMINATED:
    case Status.FAILED:
      return theme.palette.error.light;
    case Status.UNKNOWN:
      return theme.palette.text.disabled;
    default:
      return theme.palette.text.disabled;
  }
};

export default useStatusColor;
