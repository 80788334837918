import { FC } from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import { ActionOrigins, InsightActionButton } from "../ActionButton";
import { useLocalInsightV2 } from "../../../../hooks/useLocalInsightV2";
import { useInsightsContext } from "../../hooks/selectors";

export const RecommendedActionSection: FC = () => {
  const { data: insight } = useLocalInsightV2();
  const { insights } = useInsightsContext();
  const transformedInsight = insights.find(({ id }) => id === insight?.id);
  if (!insight || !transformedInsight) {
    return <Skeleton width="100%" height="400px" />;
  }
  return (
    <Stack spacing={1}>
      <Stack spacing={2} alignItems="start">
        <Stack direction="row" justifyContent="space-between" width="100%">
          {![
            "PRODUCT_RANKING_FOR_NEW_KEYWORDS",
            "EMERGING_KEYWORDS_IN_CATEGORY",
          ].includes(insight?.type as string) && (
            <Stack alignItems="start" gap={2}>
              <Typography variant="body2" color="text.secondary">
                Recommended action
              </Typography>
              <InsightActionButton
                insight={insight}
                origin={ActionOrigins.Drawer}
              />
            </Stack>
          )}
        </Stack>
        <Typography
          variant="headline4"
          data-testid="drawer-insight-text"
          mt={0}
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw as any]}>
            {insight.drawerText}
          </ReactMarkdown>
        </Typography>
      </Stack>
    </Stack>
  );
};
