import {Board} from "@noogata/types";

import LibraryMenu, {LibraryMenuProps,} from "../../../../components/LibraryMenuV2/LibraryMenu";
import {useBoards} from "../../../../services/boards";
import {BoardLibrary} from "../../../../enums";
import {FC} from "react"

export const getLibrariesFromBoards = (boards: Board[]): Set<BoardLibrary> =>
    new Set(boards.map((b) => b.library));

const LibraryMenuManager: FC<
    {
      defaultValue?: BoardLibrary;
      onChange: (library: BoardLibrary) => void;
    } & Partial<LibraryMenuProps>
> = (props) => {
  const { data, isLoading } = useBoards();
  const availableLibraries = data?.map((b) => b.library) || [];

  const libs = Object.values(BoardLibrary);
  const libSet = new Set(availableLibraries || []);
  const filteredList =
    libSet.size > 0 ? libs.filter((lib) => libSet.has(lib)) : libs;

  return (
    <LibraryMenu {...props} libraries={filteredList} isLoading={isLoading} />
  );
};

export default LibraryMenuManager;
