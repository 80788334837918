import { Box, ListItem, Skeleton, Stack } from "@mui/material";
import cn from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { useSalesAssistantStyles } from "../useSalesAssistantStyles";
import { salesColumnWidths } from "../constants";

const useStyles = makeStyles(() => ({
  trackImg: {
    opacity: 0,
  },
  productList: {
    "&:hover": {
      "& .trackImg": {
        opacity: 1,
      },
    },
  },
}));
const SekeltonList = () => {
  const { row } = useSalesAssistantStyles();
  const classes = useStyles();
  return (
    <ListItem
      sx={{
        height: "auto",
        opacity: 1,
        transition: "all 0.3s linear",
        overflow: "hidden",
        position: "relative",
        padding: "8px",
        "&.hidden": {
          paddingY: 0,
        },
      }}
    >
      <Stack
        data-testid="insights-list-row"
        className={cn(row)}
        position="relative"
        height="128px"
      >
        <Box
          position="relative"
          sx={{
            "&:hover": {
              "& .MuiBox-root": {
                opacity: 1,
              },
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            padding="16px 20px"
            gap="20px"
            justifyContent="space-between"
            position="relative"
            className={classes.productList}
            sx={{
              "&:hover": {
                "& .MuiBox-root": {
                  opacity: 1,
                },
              },
            }}
          >
            <Box
              sx={{ display: "flex", gap: "16px", alignItems: "center" }}
              width={salesColumnWidths.info}
            >
              <Box minWidth={salesColumnWidths.image}>
                <Skeleton
                  width="96px"
                  height="96px"
                  sx={{ transform: "scale(1)" }}
                />
              </Box>
              <Box sx={{ height: "96px", width: "100%" }}>
                <Skeleton
                  width="100%"
                  height="100%"
                  sx={{ transform: "scale(1)" }}
                />
              </Box>
            </Box>
            <Box
              width={salesColumnWidths.unitSale}
              sx={{
                height: "96px",
                flexDirection: "column",
                display: "flex",
                gap: "10px",
              }}
            >
              <Skeleton
                width="100%"
                height="100%"
                sx={{ transform: "scale(1)" }}
              />
              <Skeleton
                width="100%"
                height="100%"
                sx={{ transform: "scale(1)" }}
              />
            </Box>
            <Box
              width={salesColumnWidths.rating}
              sx={{
                height: "96px",
                flexDirection: "column",
                display: "flex",
                gap: "10px",
              }}
            >
              <Skeleton
                width="100%"
                height="100%"
                sx={{ transform: "scale(1)" }}
              />
              <Skeleton
                width="100%"
                height="100%"
                sx={{ transform: "scale(1)" }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </ListItem>
  );
};
export default SekeltonList;
