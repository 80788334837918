import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";

import SVG from "react-inlinesvg";
import { match, useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { sortBy } from "lodash";
import { useBoards } from "../../../services/boards";
import { CircuitBoard } from "../../../types";
import { BoardsMenu } from "./BoardsMenu";
import { ExpendableList, ExpendableListHeader } from "./ExpendableList";
import { StyledTooltip } from "./StyledTooltip";
import { ApplicationType } from "./types";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import theme from "../../../themes/light";
import { PerfectContentNavItem } from "./PerfectContentNavItem";


const PERFECT_CONTENT_V3 = "asins-content-optimization-v3"

export const PerfectContentIcon = (props: any) => (
  <SVG
    src={"/perfect-content.svg"}
    width={20}
    height={20}
    fill="inherit"
    {...props}
  />
);

export const usePerfectContentBoardsWithPublishedRun = () =>
  useBoards(undefined, {
    select: (blocks) =>
      filterPerfectContentV3
        (sortBy(
          blocks.filter(
            (b) =>
              b.template_id.search("asins-content-optimization") > -1 &&
              b.published_run_id
          ),
          "displayName"
        )
        ),
  });

const filterPerfectContentV3 = (blocks: CircuitBoard[]) => {
  const pcv3 = blocks.find(b => b.template_id == PERFECT_CONTENT_V3);
  return pcv3 ? [pcv3] : blocks
}

export const PerfectContentList: FC<{
  open: boolean;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ open, tab, setTab }) => {
  const [openList, setOpenList] = useState(false);
  const [v3Mode, setV3Mode] = useState(false)
  // const pageMatch = useRouteMatch(
  //   ["", ApplicationType.PERFECT_CONTENT].join("/")
  // );
  const blockMatch = useRouteMatch<{ boardId: string }>(
    ["", ApplicationType.PERFECT_CONTENT, ":boardId"].join("/")
  );
  const history = useHistory();

  const { data: blocks = [] as CircuitBoard[], isLoading } =
    usePerfectContentBoardsWithPublishedRun();

  const handlePerfectContentMenu = () => {
    setOpenList(!openList);
    setTab("perfectContent");
  };

  useEffect(() => {
    setV3Mode(blocks.length == 1 && blocks[0].template_id == PERFECT_CONTENT_V3)
  }, [blocks])

  if (isLoading) {
    return (
      <ListItemButton>
        <Skeleton height="100%" width="100%" />
      </ListItemButton>
    );
  }

  if (!blocks.length) {
    return null;
  }

  const selected = tab === "perfectContent"
  if (!open) {
    return (
      <StyledTooltip
        title={
          !v3Mode && <BoardsMenu
            blocks={blocks}
            prefix={ApplicationType.PERFECT_CONTENT}
          />
        }
      >
        <ListItemButton
          onClick={() => {
            history.push(["", ApplicationType.PERFECT_CONTENT, blocks[0].identifier].join("/"));
            setTab("perfectContent");
          }}
          selected={selected}
          sx={{
            maxHeight: "32px",
            background: selected ? theme.palette.primaryPartial[600] : "",
            ":hover": {
              background: selected ? theme.palette.primaryPartial[600] : ""
            },
            ":focus": {
              background: selected ? theme.palette.primaryPartial[600] : ""
            },
            color: theme.palette.background.default,
            fontWeight: "400",
            fontSize: "14px",
            padding: "8px",
            marginBottom: "8px",
          }}
        >
          <ListItemIcon>
            <PerfectContentIcon />
          </ListItemIcon>
          <ListItemText
            primary="Perfect Content"
            sx={{ whiteSpace: "nowrap" }}
          />
        </ListItemButton>
      </StyledTooltip>
    );
  }

  return (v3Mode ?
    <PerfectContentNavItem tab={tab} setTab={setTab} block_id={blocks[0].identifier} />
    :
    <ExpendableList
      open={tab === "perfectContent"}
      label={(props: any) => (
        <ExpendableListHeader
          icon={<PerfectContentIcon />}
          onClick={handlePerfectContentMenu}
          label="Perfect Content"
          {...props}
        />
      )}
    >
      {blocks.map((b, i) => (
        <ListItemButton
          key={i}
          selected={b.identifier === blockMatch?.params.boardId}
          onClick={() => {
            history.push(
              ["", ApplicationType.PERFECT_CONTENT, b.identifier].join("/")
            );
            setTab("perfectContent");
          }}
        >
          {b.displayName}
        </ListItemButton>
      ))}
    </ExpendableList>
  );
};
