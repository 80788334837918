import { FC, useState } from "react";
import { useCatalogEnrichmentContext } from "./selectors";
import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../themes/light";
import { InfoIconV2 } from "../../assets";
import { EnrichmentFlowState } from "./types";

const SidebarInput = (props: any) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      width: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        width: "fit-content",
      }}
    >
      <Typography variant="caption" sx={{ textWrap: "nowrap" }}>
        {props.label}
      </Typography>
      <Box display="inline-flex">
        <Tooltip
          title={
            <Typography
              sx={{
                ...theme.typography.body5,
                color: theme.palette.background.default,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {props.tooltip}
              <Link
                sx={{
                  color: "#fff",
                  width: "fit-content",
                }}
                href={props.example}
                target="_blank"
                rel="noopener noreferrer"
              >
                Sample
              </Link>
            </Typography>
          }
          placement="right"
          arrow
        >
          <Box display="inline-flex" sx={{ cursor: "pointer" }}>
            <InfoIconV2 />
          </Box>
        </Tooltip>
      </Box>
    </Box>
    <TextField
      variant="outlined"
      sx={{
        width: "100%",
        background: theme.palette.background.default,
      }}
      value={props.value}
      onChange={props.onChange}
    />
  </Box>
);

const CatalogEnrichmentSidebar: FC = () => {
  const { isEnrichmentFlowRunning, startEnrichmentFlow, flowState } =
    useCatalogEnrichmentContext();

  const [rawDataPath, setRawDataPath] = useState<string>("");
  const [cadFilePath, setCadFilePath] = useState<string>("");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        overflow: "auto",
        height: "100%",
        minWidth: "20%",
        maxWidth: "20%",
        padding: "1rem 2rem",
        background: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          fontSize={16}
          sx={{ fontFamily: "headline4" }}
        >
          Inputs
        </Typography>
        <SidebarInput
          label="Input Products"
          value={rawDataPath}
          onChange={(e: any) => setRawDataPath(e.target.value)}
          example={
            "https://docs.google.com/spreadsheets/d/1YOhE3xRGAia3fVumzqTCK8z7lXWNPwaKZn7_KYZuSTY/edit?gid=626211229#gid=626211229"
          }
          tooltip=" This file should contain all product details necessary for processing."
        />
        <SidebarInput
          label="Catalog Attributes Definition"
          value={cadFilePath}
          onChange={(e: any) => setCadFilePath(e.target.value)}
          example={
            "https://docs.google.com/spreadsheets/d/1rG_lCbgZKHk395SwNFvzVP_V9GYZpyDV6cAYtOG-DS8/edit?gid=0#gid=0"
          }
          tooltip="This file should contain all the attributes that need to be enriched."
        />
        <Button
          variant={"contained"}
          sx={{
            fontSize: "1rem",
          }}
          onClick={() => startEnrichmentFlow(rawDataPath, cadFilePath)}
          disabled={isEnrichmentFlowRunning || !rawDataPath || !cadFilePath}
        >
          Run Enrichment
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          fontSize={16}
          sx={{ fontFamily: "headline4" }}
        >
          Outputs
        </Typography>
        {flowState !== EnrichmentFlowState.Completed && (
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.secondary }}
          >
            Enrichment output will be shown here once the process is completed.
          </Typography>
        )}
        {flowState === EnrichmentFlowState.Completed && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              padding: "2rem 4rem",
            }}
          >
            <Button
              sx={{
                fontSize: "1rem",
                color: theme.palette.text.secondary,
                width: "fit-content",
              }}
              href={
                "https://docs.google.com/spreadsheets/d/1AXbFsyMqSKqBe-_Gqa-15gaipGxViu0gXQtFpCwI1tY/edit?gid=0#gid=0"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button
              sx={{
                fontSize: "1rem",
                color: theme.palette.text.secondary,
                width: "fit-content",
              }}
              href={
                "https://storage.googleapis.com/noogata-public-assets/product-catalog-enrichment/outputs/enriched_catalog.csv"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CatalogEnrichmentSidebar;
