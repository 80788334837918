import { Box, Skeleton } from "@mui/material";

const ChatFilterSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap="1.2rem" padding="1rem 0">
      {/* <Box display="flex" flexDirection="column" gap="6px">
        <Skeleton
          animation="wave"
          width={182}
          sx={{
            height: "16px",
            borderRadius: "8px",
            transform: "unset",
          }}
        />
        <Skeleton
          animation="wave"
          width={100}
          sx={{
            height: "16px",
            borderRadius: "8px",
            transform: "unset",
          }}
        />
      </Box> */}
      <Box display="flex" flexDirection="column" gap="1.2rem">
        {[...Array(2).keys()].map((ind) => (
          <Box
            display="flex"
            // flexDirection="column"
            gap="1rem"
            key={`ske3-${ind}`}>
            <Box
              width="50%"
              display="flex"
              flexDirection="column"
              gap="0.5rem"
            >
              <Skeleton
                animation="wave"
                width={100}
                sx={{
                  height: "10px",
                  borderRadius: "6px",
                  transform: "unset",
                }}
              />
              <Skeleton
                animation="wave"
                width="100%"
                sx={{
                  height: "32px",
                  borderRadius: "4px",
                  transform: "unset",
                }}
              />
            </Box>
            <Box
              width="50%"
              display="flex"
              flexDirection="column"
              gap="0.5rem"
            >
              <Skeleton
                animation="wave"
                width={100}
                sx={{
                  height: "10px",
                  borderRadius: "6px",
                  transform: "unset",
                }}
              />
              <Skeleton
                animation="wave"
                width="100%"
                sx={{
                  height: "32px",
                  borderRadius: "4px",
                  transform: "unset",
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton
          animation="wave"
          width={100}
          sx={{
            height: "10px",
            borderRadius: "8px",
            transform: "unset",
          }}
        />
        <Skeleton
          animation="wave"
          width={32}
          sx={{
            height: "32px",
            borderRadius: "50%",
            transform: "unset",
          }}
        />
      </Box> */}
    </Box>
  );
};
export default ChatFilterSkeleton;
