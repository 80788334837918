import { Box } from '@mui/material';
import React, { FC } from 'react'

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <Box sx={{ p: 2, height: "100%" ,paddingTop : 0 }}>{children}</Box>}
      </Box>
    );
}

export default CustomTabPanel