import { useQuery, UseQueryOptions } from "react-query";
import apiRequest, { APIError } from "../apiRequest";
import { InputSchema } from "../connectorsV2";

function createSchema(connectorId: string, data: object): Promise<InputSchema> {
  return apiRequest<InputSchema>(
    `connectors/${connectorId}/schema`,
    "POST",
    undefined,
    data
  );
}

export default function useCreateSchema(
  connectorId: string,
  data: object,
  options?: UseQueryOptions<InputSchema, APIError>
) {
  return useQuery<InputSchema, APIError>(
    [connectorId, data],
    () => createSchema(connectorId, data),
    { ...options }
  );
}
