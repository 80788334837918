import { Box } from "@mui/material";

import { DataSourceLogoMapping } from "../mappings";
import { DataSourceType } from "../types/datasources";
import { FC } from "react";

export const DataSourceImg: FC<{
  type: DataSourceType;
  height?: string;
  width?: string;
}> = ({ type, height, width }) => (
  <img
    src={`/${DataSourceLogoMapping[type]}`}
    alt={type}
    width={width || 30}
    height={height || 30}
  />
);

export const DataSourceTypeIcon: FC<{ type: DataSourceType }> = ({ type }) => (
  <Box
    width={56}
    height={56}
    sx={{
      cursor: "pointer",
      border: (t) => `1px solid ${t.palette.borders.default}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 1,
    }}
  >
    <DataSourceImg height="24px" width="24px" type={type} />
  </Box>
);
