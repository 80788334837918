import { AdBoosterFilter, AdBoosterType } from "./types";

export const InsightTypeToFilters: Record<AdBoosterType, AdBoosterFilter> = {
  PRODUCT_LOSING_MARKET_SHARE: {
    targeting_strategy: ["unbranded", "defensive"],
  },
  KEYWORDS_UNDER_ATTACK: { targeting_strategy: ["defensive"] },
  TOP_ORGANIC_RANK: { keyword_type: "targeted" },
  AD_BOOSTER: {
    targeting_strategy: ["unbranded", "defensive"],
    keyword_type: "untapped",
    score: ["high"],
  },
};
