import { useQuery, UseQueryOptions } from "react-query";
import { ConnectorType } from "../../enums";
import { Connector } from "../../types/connectorsV2";
import apiRequest, { APIError } from "../apiRequest";

function getConnectors(
  boardId: string,
  type: ConnectorType
): Promise<Connector[]> {
  return apiRequest<Connector[]>("connectors", "GET", {
    circuitboard_id: boardId,
    connector_type: type,
  });
}

export default function useConnectors(
  boardId: string,
  type: ConnectorType,
  options?: UseQueryOptions<Connector[], APIError>
) {
  return useQuery<Connector[], APIError>(
    ["boardConnectors", boardId, type],
    () => getConnectors(boardId, type),
    { ...options }
  );
}
