import {TextField, TextFieldProps} from "@mui/material";
import {FC} from "react"

const integerRegex = /^(-)?([0-9]+)?$/;

const ParameterIntegerField: FC<
    Omit<TextFieldProps, "onChange" | "error"> & {
    onChange: (value: number | "-") => void;
    error?: { message: string };
}
> = (props) => (
    <TextField
    {...props}
    error={!!props.error?.message}
    helperText={props.error?.message}
    value={props.value || ""}
    margin="dense"
    variant="outlined"
    type="integer"
    fullWidth
    onChange={(e) => {
      if (
        props.onChange &&
        (integerRegex.test(e.target.value) || !e.target.value)
      ) {
        return props.onChange(
          e.target.value === "-"
            ? e.target.value
            : Number.parseInt(e.target.value, 10)
        );
      }
      return null;
    }}
  />
);

export default ParameterIntegerField;
