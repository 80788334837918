import { OnboardingGatheringStatusEnum, OnboardingStatusEnum } from "../enums";

export type PerAccountStatus = {
  count: number;
  updated_at: string;
  data_source_id: string;
};

export type OnboardingStatus = {
  status: OnboardingStatusEnum;
  total_products?: number;
  per_account_status?: PerAccountStatus[];
  covered_products?: number;
  dismissed: boolean;
};

export type OnboardingGatheringStatus = {
  status: OnboardingGatheringStatusEnum;
};

export type EcommerceProduct = {
  asin: string;
  title?: string;
  created_at: string;
  brand?: string;
  image_url?: string;
};

export type PerfectContentProduct = {
  asin: string;
  image_url?: string;
  title?: string;
  description?: string;
  about?: string;

  mine?: boolean;
  brand?: string;
  bought_past_month?: string;

  title_score?: number;
  description_score?: number;
  about_score?: number;
  combined_score?: number;
  combined_rank?: number;
};

export type AddProductResponse = {
  num_items_added: number;
  num_of_duplicate_items: number;
  num_of_existing_items: number;
  new_products: string[];
};

export class ProductsFilterConfiguration {
  data_sources_ids?: string[];

  categories?: string[];

  brands?: string[];

  titles?: string[];

  asins?: string[];

  logic_op?: "and" | "or" = "and";

  constructor(
    data_sources_ids: string[],
    brands: string[],
    titles: string[],
    categories: string[],
    asins: string[],
  ) {
    this.data_sources_ids = data_sources_ids;
    this.brands = brands;
    this.titles = titles;
    this.categories = categories;
    this.asins = asins;
  }

  static defaultConfig({
    data_sources_ids,
    titles,
    brands,
    categories,
    asins,
  }: {
    data_sources_ids?: string[];
    brands?: string[];
    titles?: string[];
    categories?: string[];
    asins?: string[];
  }): ProductsFilterConfiguration {
    return new ProductsFilterConfiguration(
      data_sources_ids || [],
      brands || [],
      titles || [],
      categories || [],
      asins || [],
    );
  }

  static isEqual(
    a: ProductsFilterConfiguration,
    b: ProductsFilterConfiguration,
  ): boolean {
    return (
      (a?.data_sources_ids || []).every(
        (i) => !!b?.data_sources_ids?.find((j) => i === j),
      ) &&
      b?.data_sources_ids?.length === a?.data_sources_ids?.length &&
      (a?.titles || []).every((i) => !!b?.titles?.find((j) => i === j)) &&
      b?.titles?.length === a?.titles?.length &&
      (a?.categories || []).every(
        (i) => !!b?.categories?.find((j) => i === j),
      ) &&
      b?.categories?.length === a?.categories?.length &&
      (a?.brands || []).every((i) => !!b?.brands?.find((j) => i === j)) &&
      b?.brands?.length === a?.brands?.length
    );
  }

  static configHasEmptyFields(a: ProductsFilterConfiguration): boolean {
    return (
      !a?.data_sources_ids?.length &&
      !a?.categories?.length &&
      !a?.brands?.length &&
      !a?.asins?.length
    );
  }

  static merge(
    a: ProductsFilterConfiguration,
    b: ProductsFilterConfiguration,
  ): ProductsFilterConfiguration {
    return {
      ...a,
      ...b,
      data_sources_ids: [
        ...new Set([
          ...(a?.data_sources_ids || []),
          ...(b?.data_sources_ids || []),
        ]),
      ],
      categories: [
        ...new Set<string>([
          ...(a?.categories || []),
          ...(b?.categories || []),
        ]),
      ],
    };
  }
}
export interface ProductFilter extends ProductsFilter {
  default?: boolean;
  hide?: boolean;
  total?: number;
}
export class ProductsFilter {
  id: string;
  filter_id?: string;
  asins?: number;
  configuration: ProductsFilterConfiguration;

  name: string;

  constructor(
    id: string,
    filter_id: string,
    name: string,
    asins: number,
    configuration: ProductsFilterConfiguration,
  ) {
    this.id = id;
    this.name = name;
    this.configuration = configuration;
    this.filter_id = filter_id;
    this.asins = asins;
  }

  static mergeConfig(
    a: ProductsFilter,
    config: ProductsFilterConfiguration,
  ): ProductsFilter {
    return {
      ...a,
      configuration: ProductsFilterConfiguration.merge(a.configuration, config),
    };
  }
}
