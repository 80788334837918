import WarningIcon from "@mui/icons-material/Warning";
import { Button, Card, CardHeader } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useRunEvents } from "../../../../hooks/metrics/runEvents";

const FailedRunBox: FC<{ boardId: string; runId: string }> = ({
  boardId,
  runId,
}) => {
  const { beginFlowEvent } = useRunEvents();
  return (
    <Card
      sx={{
        m: 2,
        backgroundColor: "grey.50",
        minHeight: "100px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CardHeader
        title="Run Failed"
        subheader="No output has been generated."
        avatar={<WarningIcon color="error" />}
        action={
          <Button
            variant="contained"
            onClick={() => beginFlowEvent({ boardId, runId, isClone: true })}
            component={Link}
            to={`/boards/${boardId}/runs/new?runId=${runId}`}
            sx={{
              backgroundColor: "white",
              color: "grey.800",
              fontWeight: 600,
              border: "1px solid",
              borderColor: "grey.300",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "primary.100",
              },
            }}
          >
            Try Again
          </Button>
        }
        sx={{
          width: "100%",
          "& .MuiCardHeader-title": {
            color: "primary.text",
            fontWeight: 800,
          },
          "& .MuiCardHeader-subheader": { color: "grey.500" },
          "& .MuiCardHeader-avatar": {
            backgroundColor: "white",
            p: 2,
            variant: "rounded",
            borderRadius: "4px",
          },
          "& .MuiCardHeader-action": { m: 0, alignSelf: "center" },
        }}
      />
    </Card>
  );
};

export default FailedRunBox;
