import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {
  ProductsFilter,
  ProductsFilterConfiguration,
} from "../../../types/ecommerce";
import useDataSources from "../../../services/datasources/useDataSources";
import { FilterDataSources } from "./FilterDataSources";
// import FilterCategories from "./FilterCategories";
import useProductsCategories from "../../../services/ecommerce/useCategories";
import { FilterBrands } from "./FilterBrands";
import useProductsBrands from "../../../services/ecommerce/useProductsBrands";
import { FilterTitles } from "./FilterTitles";
import { FiltersSaveForm } from "./FiltersSaveForm";
import useDataSourcesProductsCount from "../../../services/datasources/useDataSourceProductsCount";
import { FC, useState } from "react";
import FilterCategoriesV3, {
  buildTree,
  generateUniqueIds,
  getLeafNodeIds,
} from "../../SpacesV2/FilterCategoriesV3";
import { CollapsableBox } from "../../../components/CollapsableBox";

const indicatorTooltipMsg = `Product lists can be used as input to block runs.
This is a convenient way to analyze subsets of products of interest from the entire product catalog.`;

export const CURRENT_SELECTION_FILTER = {
  id: "CURRENT_SELECTION",
  name: "Current selection",
  configuration: {},
};

const Filters: FC<
  Omit<Partial<StackProps>, "onChange"> & {
    onChange: (filters: ProductsFilter) => void;
    onAll: () => void;
    onSave: (filters: Omit<ProductsFilter, "id">) => void;
    defaultFilter: ProductsFilter;
    isCurrentSelectionAllProducts: (f: ProductsFilter) => boolean;
    isLoading: boolean;
  }
> = ({
  defaultFilter,
  isCurrentSelectionAllProducts,
  onChange,
  onSave,
  onAll,
  isLoading,
  ...props
}) => {
  const config = defaultFilter?.configuration;
  const [filterName, setFilterName] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<string[][]>([]);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const { data: dataSources = [] } = useDataSourcesProductsCount();
  const { data: productsCategories = [] } = useProductsCategories({});
  const { data: productsBrands } = useProductsBrands();
  const { data: productsDataSources } = useDataSources();
  const updatedCategoryList = buildTree(productsCategories);
  const categoriesData = generateUniqueIds(updatedCategoryList);

  const convertDataFormat = (data: [number, string][]): string[][] => {
    return data.map((item) => item[1].split("|"));
  };

  const handleSelectAllCategories = () => {
    const tempSelected: Set<string> = new Set();
    categoriesData?.forEach((currentItem) => {
      const ids = Array.from(getLeafNodeIds(currentItem));
      ids.forEach((e) => {
        tempSelected.add(e);
      });
    });
    const convertedData = convertDataFormat(productsCategories);
    setSelectedCategories(convertedData);
    setSelectedItems(tempSelected);
    return convertedData;
  };

  const isBrandsFilterEnabled = !!(productsBrands || []).length;
  const isCategoryFilterEnabled = !!productsCategories.length;
  const isDataSourceEnabled = (productsDataSources || []).length > 1;
  const selectedDsIds = config?.data_sources_ids;

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleSelectAllCategories();
    } else {
      setSelectedCategories([]);
      setSelectedItems(new Set());
    }
    onAll();
  };

  return !isDataSourceEnabled &&
    !isCategoryFilterEnabled &&
    !isBrandsFilterEnabled ? null : (
    <Stack
      sx={{
        width: (t) => t.spacing(30),
        mb: 2,
      }}
      spacing={2}
      {...props}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2_v2">Save filter as product list</Typography>
        <Tooltip title={indicatorTooltipMsg}>
          <HelpIcon
            fontSize="small"
            sx={{ color: (t) => t.palette.grey[300] }}
          />
        </Tooltip>
      </Stack>
      <Box>
        <FiltersSaveForm
          name={filterName}
          onSave={() => {
            setFilterName("");
            onSave({
              name: filterName,
              configuration: config
                ? {
                    ...config,
                    brands:
                      config.brands?.length == productsBrands?.length
                        ? []
                        : config.brands,
                    categories:
                      config.categories?.length == productsCategories?.length
                        ? []
                        : config.categories,
                    data_sources_ids:
                      config.data_sources_ids?.length ==
                      productsDataSources?.length
                        ? []
                        : config.data_sources_ids,
                  }
                : {},
            });
          }}
          nameInputDisabled={
            !config || defaultFilter?.id !== CURRENT_SELECTION_FILTER.id
          }
          saveDisabled={
            !filterName ||
            defaultFilter?.id !== CURRENT_SELECTION_FILTER.id ||
            !defaultFilter?.configuration?.data_sources_ids?.length ||
            isLoading
          }
          onNameChange={setFilterName}
        />
        <FormControlLabel
          label="Select all"
          control={
            <Checkbox
              size="small"
              value="ALL"
              checked={isCurrentSelectionAllProducts(defaultFilter)}
              onChange={handleAllChange}
            />
          }
        />
      </Box>
      <FilterTitles
        onChange={(titles) =>
          onChange({
            ...defaultFilter,
            configuration: {
              ...defaultFilter.configuration,
              titles,
            },
          })
        }
        value={defaultFilter?.configuration?.titles || []}
      />
      {isDataSourceEnabled ? (
        <>
          <FilterDataSources
            selectedDsIds={selectedDsIds}
            onAll={(checked: any) => {
              const nextDSconfig = checked
                ? {
                    data_sources_ids: dataSources.map(([_count, dsId]) => dsId),
                  }
                : {
                    data_sources_ids: [],
                  };
              onChange(
                ProductsFilter.mergeConfig(CURRENT_SELECTION_FILTER, {
                  ...defaultFilter.configuration,
                  ...nextDSconfig,
                })
              );
            }}
            onChange={({ checked, dsId }: any) => {
              const mergedConfig: ProductsFilterConfiguration = checked
                ? ProductsFilterConfiguration.merge(
                    defaultFilter.configuration,
                    { data_sources_ids: [dsId] }
                  )
                : {
                    ...defaultFilter.configuration,
                    data_sources_ids:
                      defaultFilter.configuration.data_sources_ids?.filter(
                        (ds) => dsId !== ds
                      ),
                  };
              onChange(
                ProductsFilter.mergeConfig(
                  CURRENT_SELECTION_FILTER,
                  mergedConfig
                )
              );
            }}
          />
        </>
      ) : null}
      {isCategoryFilterEnabled && (
        // <FilterCategories
        //     selected={
        //         defaultFilter?.configuration?.categories?.map((a) =>
        //             a.split("|")
        //         ) || []
        //     }
        //     onChange={(categories: any) => {
        //         onChange({
        //             ...CURRENT_SELECTION_FILTER,
        //             configuration: {
        //                 ...defaultFilter.configuration,
        //                 categories,
        //             },
        //         });
        //     }}
        // />
        <CollapsableBox label="Categories">
          <FilterCategoriesV3
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onChange={(categoriesPaths: string[][]) => {
              const categories = categoriesPaths?.map(
                (categoryArray: string[]) => categoryArray.join("|")
              );
              onChange({
                ...CURRENT_SELECTION_FILTER,
                configuration: {
                  ...defaultFilter.configuration,
                  categories,
                },
              });
            }}
            isDataLabCatgeory
          />
        </CollapsableBox>
      )}
      {isBrandsFilterEnabled && (
        <FilterBrands
          selectedBrands={defaultFilter?.configuration?.brands || []}
          onChange={(brands: any) =>
            onChange({
              ...defaultFilter,
              configuration: {
                ...defaultFilter.configuration,
                brands,
              },
            })
          }
        />
      )}
    </Stack>
  );
};

export default Filters;
