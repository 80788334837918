import { Route, Switch } from "react-router-dom";
import PublicPage from "./pages/ResearchAssistant/PublicPage.tsx";
import { WithFronteggProvider } from "./withFrontegg.tsx";
import EventsProvider from "./EventsProvider.tsx";
import { ApplicationContextProvider } from "./ApplicationContext.tsx";
import App from "./App.tsx";

const Router = () => {
  return (
    <Switch>
      {window.location.pathname.search("/storyboard") > -1 ? (
        <Route path="/storyboard/:token" component={PublicPage}></Route>
      ) : (
        <Route path="*">
          <WithFronteggProvider>
            <EventsProvider>
              <ApplicationContextProvider>
                <App />
              </ApplicationContextProvider>
            </EventsProvider>
          </WithFronteggProvider>
        </Route>
      )}
    </Switch>
  );
};

export default Router;
