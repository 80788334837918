import { CardHeader, styled } from "@mui/material";

const StyledCardHeader = styled(CardHeader)(() => ({
  width: "100%",
  "& .MuiCardHeader-title": { color: "white", fontWeight: 600 },
  "& .MuiCardHeader-subheader": { color: "white", maxWidth: "500px" },
  "& .MuiCardHeader-action": { marginRight: 2, alignSelf: "center" },
}));

export default StyledCardHeader;
