import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {useRunMessages} from "../../services/tracking";
import {FC} from "react"

const FlowAlert: FC<{
  runId: string;
  errorPrefix?: string;
  navigateTo?: string;
}> = ({runId, errorPrefix = "", navigateTo}) => {
  const {data: messages} = useRunMessages(runId);
  if (!messages || !messages.length) return null;
  const errorMessage = messages && messages.slice(-1)[0]?.message;
  const message = `${errorPrefix} Run failed: ${errorMessage}`;
  return (
    <Alert
      severity="error"
      action={
        navigateTo ? (
          <Button
            component={Link}
            size="small"
            to={{ pathname: navigateTo }}
            color="inherit"
          >
            View Run
          </Button>
        ) : undefined
      }
    >
      {message}
    </Alert>
  );
};

export default FlowAlert;
