import { Box, Typography } from "@mui/material";
// import { useTheme } from "@mui/styles";
// import { useSalesAssistantStyles } from "../useSalesAssistantStyles";
import { useSalesContext } from "../hooks/selectors";
import FilterVirtualList from "./FilterVirtualList";
import { FC, useEffect, useState } from "react";
// import { SalesListingHead } from "./SalesListingHead";

// interface SalesFilterListProps {
//   onTabChange: (event: React.SyntheticEvent, newValue: string) => void;
//   activeTab: string;
// }

const newSortCopy = {
  column: "",
  ascending: false,
  isDirty: false,
  days: "7d",
};
const SalesFilterList: FC = () => {
  // const { onTabChange, activeTab } = props;

  const [filteredList, setFilteredList] = useState<any>([]);
  const {
    sort,
    allSorts,
    setAllSorts,
    producGroupData,
    isFetchingProductGroup: isFetching,
    isFetchingInsights,
  } = useSalesContext();

  // const theme = useTheme();
  // const { mainList } = useSalesAssistantStyles();
  // const listStylesOverrides = {
  //   "& .MuiListItem-root": {
  //     width: "100%",
  //     cursor: "pointer",
  //     paddingBottom: "8px",
  //     paddingTop: "8px",
  //   },
  // };

  useEffect(() => {
    if (
      producGroupData &&
      producGroupData?.length >= 0 &&
      newSortCopy?.column === sort?.column
    ) {
      if (producGroupData?.length > 0 && !isFetching) {
        if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
          setAllSorts((old: any) => [...old, sort]);
        }
        setFilteredList([...producGroupData]);
        return;
      }
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
        setAllSorts((old: any) => [...old, sort]);
        if (allSorts.length > 0) {
          return;
        }
      }
      if (
        producGroupData?.length !== 0 &&
        newSortCopy?.column === sort?.column
      ) {
        setFilteredList([...producGroupData]);
      }
      return;
    }
    if (producGroupData && producGroupData?.length > 0 && !isFetching) {
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
        setAllSorts((old: any) => [...old, sort]);
      }
      setFilteredList([...producGroupData]);
    }
  }, [producGroupData?.length, sort?.column, isFetching]);

  const updatedFilteredList = filteredList?.filter(
    (productGroup: any) => productGroup.title.toLowerCase() !== "all products"
  );

  return (
    <>
      {updatedFilteredList?.length > 0 ? (
        <FilterVirtualList />
      ) : isFetchingInsights && filteredList?.length === 0 ? (
        <></>
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "215px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {/* <Box> */}
          <img
            src="/sales/product-empty.svg"
            alt="At the moment your selection does not contain any products"
          />
          <Typography variant="body1" fontWeight="600" marginTop="20px">
            At the moment your selection does not contain any groups
          </Typography>
          {/* </Box> */}
        </Box>
      )}
    </>
  );
};
export default SalesFilterList;
