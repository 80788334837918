import { Box, Typography } from "@mui/material";
import theme from "../../../../themes/light";
import ProductImage from "./ProductImage";
import { RowData } from "../../types";

const ProductInfo = ({ data }: { data?: RowData }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
      width: "200px",
    }}
  >
    <ProductImage url={data?.image_url} />
    <Typography
      variant="body5"
      sx={{
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: 400,
        "&:hover": {
          textDecoration: "underline",
          textDecorationColor: theme.palette.primary.main,
        },
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "20px",
      }}
    >
      <a
        href={`https://amazon.com/dp/${data?.title}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.primary.main, textDecoration: "none" }}
        onClick={(e) => e.stopPropagation()}
      >
        {data?.title}
      </a>
    </Typography>
  </Box>
);

export default ProductInfo;
