import {ChangeEvent, MouseEvent, useEffect, useState} from "react";
import {
  Box,
  LinearProgress,
  Popover,
  Stack,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {Row, TableInstance} from "react-table";
import {FilterList as FilterListIcon} from "@mui/icons-material";
import ColapsableRow from "./CollapsableRow";
import {TableProps} from "./types";

const useStyles = makeStyles(() =>
    createStyles({
      progressBar: {
        padding: 0,
      },
    })
);

export type UncontrolledTableProps<T extends object> = TableProps<T> & {
  instance: TableInstance<any>;
  disablePagination?: boolean;
};

const FilterTableHeader = ({ column }: { column: any }) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  return (
    <>
      <TableCell
        {...(column.id === "selection"
          ? column.getHeaderProps()
          : column.getHeaderProps(column.getSortByToggleProps()))}
        {...{
          style: {
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
          },
        }}
        key={column.id}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          onClick={(e) => column.Filter && setAnchorEl(e.target)}
        >
          {column.render("Header")}
          <Stack direction="row" spacing={1}>
            {column.Filter && (
              <FilterListIcon
                sx={{
                  color: (t) =>
                    anchorEl
                      ? t.palette.primary.main
                        // @ts-ignore
                      : t.palette.borders.default,
                  cursor: "pointer",
                }}
              />
            )}
            {column.id !== "selection" && !column.disableSortBy ? (
              <TableSortLabel
                active={column.isSorted}
                // react-table has a unsorted state which is not treated here
                direction={column.isSortedDesc ? "desc" : "asc"}
              />
            ) : null}
          </Stack>
        </Stack>
      </TableCell>
      {column.Filter && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {column.Filter}
        </Popover>
      )}
    </>
  );
};

function UncontrolledTable<T extends object>({
  instance,
  data,
  columns,
  isLoading,
  error,
  tableProps,
  tableContainerProps,
  onTableStateChange,
  rowsPerPageOptions,
  rowClassName,
  tableRowPropsGetter,
  disablePagination,
}: UncontrolledTableProps<T>) {
  const classes = useStyles();
  const {
    getTableProps,
    headerGroups,
    // @ts-ignore
    page,
    prepareRow,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    state,
    // @ts-ignore
    manualPagination,
  } = instance;

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPageSize(Number(event.target.value));
  };

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    if (onTableStateChange) {
      onTableStateChange(state);
    }
  }, [state]);

  return (
    <>
      <TableContainer {...tableContainerProps}>
        <MUITable {...getTableProps()} {...tableProps}>
          <TableHead sx={{ userSelect: "none" }}>
            {headerGroups.map((headerGroup, i) => (
              <TableRow
                sx={{ verticalAlign: "top" }}
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id || i}
              >
                {headerGroup.headers.map((column, index) => (
                  <FilterTableHeader column={column} key={index} />
                ))}
              </TableRow>
            ))}
            {isLoading && (
              <tr>
                <th
                  colSpan={columns ? columns.length : 1}
                  className={classes.progressBar}
                  aria-label="LinearProgress"
                >
                  <LinearProgress
                    sx={isLoading ? undefined : { visibility: "hidden" }}
                  />
                </th>
              </tr>
            )}
          </TableHead>
          <TableBody>
            {error && (
              <TableRow>
                <TableCell colSpan={columns ? columns.length : 1}>
                  <Typography variant="h6" align="center">
                    Could not fetch data from servers
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    {error.message}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {page.map((row: Row<T>) => {
              prepareRow(row);
              return (
                <ColapsableRow
                  key={row.id}
                  row={row}
                  className={rowClassName!}
                  {...(tableRowPropsGetter && tableRowPropsGetter(row))}
                />
              );
            })}
          </TableBody>
        </MUITable>
      </TableContainer>
      {!disablePagination && (
        <Box>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25, 50]}
            component="div"
            count={
              manualPagination
                  // @ts-ignore
                ? data.length < state.pageSize
                      // @ts-ignore
                  ? state.pageIndex * state.pageSize + data.length
                  : -1
                : data.length
            }
              // @ts-ignore
            rowsPerPage={state.pageSize}
              // @ts-ignore
            page={state.pageIndex}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </>
  );
}

export default UncontrolledTable;
