import HistoryIcon from "@mui/icons-material/History";
import { Box, Skeleton, Typography } from "@mui/material";
import dayjs from "../../utils.ts";
import { FC, ReactNode } from "react";

import useDataSources from "../../services/datasources/useDataSources";
import { PerAccountStatus } from "../../types/ecommerce";
import { useOnboardingStatus } from "../../services/ecommerce";
import { AuthorizedContent } from "../../components/AuthorizedContent";
import { PlatformRole } from "../../enums";

const OnboardingStatus = () => {
  const { data: onboardingStatus } = useOnboardingStatus();
  const totalUncovered =
    (onboardingStatus?.total_products || 0) -
    (onboardingStatus?.covered_products || 0);
  return onboardingStatus && totalUncovered ? (
    <Typography variant="caption">
      This account have <b>{totalUncovered}</b> not covered product by Noogata
      models.
    </Typography>
  ) : null;
};

const ProductsHeader: FC<{
  data: PerAccountStatus[];
  total_products: number;
  isLoading: boolean;
}> = ({ data, total_products, isLoading }) => {
  const { data: dataSources } = useDataSources();
  const totalProductsText = total_products ? (
    <Typography component="div">
      <b>{total_products}</b> products were retrieved from {data.length} data
      sources.
    </Typography>
  ) : (
    "No products found by the system"
  );

  const productsPerAccount = data;

  const productPerAccount = productsPerAccount.length
    ? productsPerAccount.map((status) => (
        <Box display="flex" key={status.data_source_id}>
          <Typography>
            {"• "}
            <b>{status.count}</b>
            {status.count > 1 ? " were " : " was "}
            {"retrieved from "}
            <b>
              {dataSources?.find((ds) => ds.id === status.data_source_id)
                ?.name || status.data_source_id}
              .
            </b>
          </Typography>
          {status.updated_at && (
            <Box
              display="inline-flex"
              alignItems="center"
              sx={{
                color: (t: any) => t.palette.grey[400],
              }}
            >
              <HistoryIcon fontSize="small" sx={{ mr: 0.5, ml: 1.5 }} />
              <Typography color="inherit" variant="caption">
                {/*// @ts-ignore*/}
                {dayjs.utc(status.updated_at).fromNow()}
              </Typography>
            </Box>
          )}
        </Box>
      ))
    : "";

  return (
    <SummaryBox>
      <Box>
        {isLoading ? (
          <Skeleton variant="text" width={600} />
        ) : data ? (
          <Box display="flex" flexDirection="column">
            <Box display="flex">{totalProductsText} </Box>
            {productPerAccount}
          </Box>
        ) : (
          ""
        )}
        <AuthorizedContent requiredRoles={[PlatformRole.NoogataAdministrator]}>
          <OnboardingStatus />
        </AuthorizedContent>
      </Box>
    </SummaryBox>
  );
};

export const SummaryBox = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      borderRadius: 2,
      pt: 1,
      pb: 1,
      bgcolor: "primary.light",
    }}
  >
    <Box mt={2} ml={3} mr={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 2,
          width: 44,
          height: 44,
          background: (t) => t.palette.background.paper,
        }}
      >
        <img alt="data-base-icon" src={"/db-icon.svg"} />
      </Box>
    </Box>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/*// @ts-ignore*/}
      <Typography variant="headline3" sx={{ mb: 0.5 }}>
        Summary
      </Typography>
      {children}
    </Box>
  </Box>
);

export default ProductsHeader;
