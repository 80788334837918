import { FC } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Stack, Typography } from "@mui/material";
import { RunInput } from "../../../../types";

const InputsDropdown: FC<{
  inputs?: RunInput[];
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ inputs, open, setOpen }) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{ display: "flex", alignItems: "center", height: "54px" }}
  >
    {open ? (
      <ArrowDropDownIcon onClick={() => setOpen(!open)} />
    ) : (
      <ArrowRightIcon onClick={() => setOpen(!open)} />
    )}
    <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>Inputs</Typography>
    {inputs && (
      <>
        <Typography variant="h6" sx={{ mr: 0.5, color: "grey.500" }}>
          •
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "grey.500",
          }}
        >
          {inputs.length}
        </Typography>
      </>
    )}
  </Stack>
);

export default InputsDropdown;
