import { useTenantsState } from "@frontegg/react";
import { User } from "@frontegg/redux-store";
import { ITenantsResponse } from "@frontegg/rest-api";

export const useUserTenant = (user?: User): ITenantsResponse | undefined => {
  const { tenants } = useTenantsState();
  const userTenant = tenants.find(
    (tenant) => tenant.tenantId === user?.tenantId
  );

  return userTenant;
};

export default useUserTenant;
