import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Button, ListItemText, Menu, MenuItem} from "@mui/material";

import {useHistory} from "react-router";
import {AuthorizedContent} from "../../components/AuthorizedContent";
import {PlatformPermission} from "../../enums";
import {MouseEvent, useState} from "react"

const AddBoardButton = () => {
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        // @ts-ignore
        <AuthorizedContent
            requiredPermissions={[PlatformPermission.CIRCUITBOARDS_CREATE]}
            render={(isAuthorized: boolean) => (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowDropDownIcon/>}
                        onClick={handleClickOpen}
                        disabled={!isAuthorized}
            size="medium"
          >
            Add
          </Button>
          <Menu
            sx={{ pt: 0 }}
            onClose={handleClose}
            open={open}
            anchorEl={anchorEl}
          >
            <MenuItem
              sx={{ whiteSpace: "normal", width: 337 }}
              onClick={() => {
                history.push("/flows/create");
              }}
            >
              <ListItemText
                primary="New Flow"
                secondary="Ongoing use cases synced to the Data Warehouse"
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/marketplace");
              }}
              sx={{ whiteSpace: "normal", width: 337 }}
            >
              <ListItemText
                primary="New Block"
                secondary="A standalone block from our library "
              />
            </MenuItem>
          </Menu>
        </>
      )}
    />
  );
};
export default AddBoardButton;
