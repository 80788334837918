import {Stack, Typography} from "@mui/material";
import IntervalTypeInput from "./IntervalTypeInput";
import IntervalValueInput from "./IntervalValueInput";
import {FC} from "react"

const Repeat: FC<{
    control: any
    setValue:any
}> = ({ control, setValue }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography>Repeat every</Typography>
    <IntervalValueInput control={control} setValue={setValue} />
    <IntervalTypeInput control={control} setValue={setValue} />
  </Stack>
);

export default Repeat;
