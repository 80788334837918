import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

const ParametersDropdown: FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{ display: "flex", alignItems: "center", height: "54px" }}
  >
    {open ? (
      <ArrowDropDownIcon onClick={() => setOpen(!open)} />
    ) : (
      <ArrowRightIcon onClick={() => setOpen(!open)} />
    )}
    <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
      Parameters
    </Typography>
  </Stack>
);

export default ParametersDropdown;
