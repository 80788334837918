import { useAuthUser } from "@frontegg/react";
import { Box, Stack, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { FC } from "react";
import { PlatformRole } from "../../../enums";
import { BoardAndRunIds } from "../../../types";
import { AppButton, CloneButton, PublishButton } from "./Buttons";
import ArchiveButton from "./Buttons/ArchiveButton";
import ExportButton from "./Buttons/ExportButton";
import KubeflowButton from "./Buttons/KubeflowButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonLabel: {
      color: theme.palette.grey[700],
      flexDirection: "column",
    },
    buttonLabelDisabled: {
      color: theme.palette.text.disabled,
      flexDirection: "column",
    },
    startIcon: {
      margin: 0,
    },
  })
);

const Actions: FC<BoardAndRunIds> = (props) => {
  const buttonProps = { ...props, classes: useStyles() };
  const user = useAuthUser();
  const { roles } = user;
  const hasNoogataAdmin = roles.find(
    (role: any) => role.key === PlatformRole.NoogataAdministrator
  );
  return (
    <Box marginRight={2}>
      <Stack direction="row" spacing={1}>
        {hasNoogataAdmin && <KubeflowButton {...buttonProps} />}
        {hasNoogataAdmin && <ExportButton {...buttonProps} />}
        <CloneButton {...buttonProps} />
        <PublishButton {...buttonProps} />
        <ArchiveButton {...buttonProps} />
        <AppButton {...buttonProps} />
      </Stack>
    </Box>
  );
};

export default Actions;
