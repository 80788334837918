import "ag-grid-enterprise";
import {
  Box,
  Checkbox,
  IconButton,
  Link,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import theme from "../../themes/light";
import { AgGridReact } from "ag-grid-react";
import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  // useRef,
  useState,
} from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { ModuleRegistry } from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { getEcommerceProducts } from "./useSpaces";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { CopyIcon } from "../../assets";
import { enqueueSnackbar } from "notistack";
import {
  GridReadyEvent,
  SizeColumnsToFitGridStrategy,
  RowClickedEvent,
} from "ag-grid-enterprise";
import dayjs from "dayjs";
import { ProductData } from "./CatalogView";
import { GetProductsResponse } from "../../services/ecommerce/useEcommerceProducts";
import CustomHeader from "./CustomHeader";
import { UseFormSetValue } from "react-hook-form";
import { AsinFormData } from "./AddSpaceV2";
import CustomPagination from "./Pagination";
import CustomLoadingCellRenderer from "./CustomLoadingCellRenderer";
import "./spaces.css";

ModuleRegistry.registerModules([ServerSideRowModelModule]);

interface ProductListProps {
  selectedProducts: string[];
  setSelectedProducts: (asins: string[]) => void;
  gridRef: RefObject<AgGridReact<any>>;
  asinsFromGrid: ProductData;
  setAsinsFromGrid: (data: ProductData) => void;
  search: string;
  selectedBrands: string[];
  selectedCategories: string[][];
  deSelectAll: () => void;
  selectAgGrid: () => void;
  setValue: UseFormSetValue<AsinFormData>;
}

const ProductList: FC<ProductListProps> = (props) => {
  const {
    gridRef,
    selectedProducts,
    setSelectedProducts,
    asinsFromGrid,
    setAsinsFromGrid,
    search,
    selectedBrands,
    selectedCategories,
    // deSelectAll,
    selectAgGrid,
    setValue,
  } = props;

  const [disableRowClick, setDisableRowClick] = useState<boolean>(false);
  // const [gridApi, setGridApi] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [sortState, setSortState] = useState<any>([
    { colId: "created_at", sort: "desc", sortIndex: 0 },
  ]);

  const pageSize = 100;

  // const onPaginationChanged = () => {
  //   setSelectedProducts([]);
  //   deSelectAll();
  // };
  const parse_categories_tooltip = (categories: string) => {
    const categories_arr = categories ? categories?.split("|") : [];
    return (
      <>
        {categories_arr.map((category: string, index: number) => (
          <Typography color="inherit" key={category}>
            {index + 1}. {category}
          </Typography>
        ))}
      </>
    );
  };

  const parse_categories = (categories: string) => {
    const categories_arr = categories ? categories?.split("|") : [];
    if (categories_arr.length > 0) {
      return `${categories_arr[0]}/.../${
        categories_arr[categories_arr.length - 1]
      }`;
    }

    return "";
  };
  // const onPaginationChanged = (params: PaginationChangedEvent<any, any>) => {
  //   setSelectedProducts([]);
  //   deSelectAll();
  // };

  const cellRenderers: { [key: string]: (p: any) => JSX.Element } = {
    product: (p: any) => {
      const { image_url, asin } = p.value;
      return (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "1rem",
              height: "100%",
              paddingLeft: "2.2rem",
              position: "relative",
            }}
          >
            <Checkbox
              sx={{
                position: "absolute",
                top: 3,
                left: -10,
                mx: 0,
                zIndex: "4",
                display: selectedProducts?.includes(asin)
                  ? "inline-flex"
                  : "none",
              }}
              checked={selectedProducts?.includes(asin)}
            />
            <Tooltip
              placement="top-start"
              title={
                image_url ? (
                  <Box
                    component={"img"}
                    src={image_url}
                    alt="image"
                    sx={{
                      height: "130px",
                      width: "100px",
                      borderRadius: "4px",
                    }}
                  />
                ) : (
                  asin
                )
              }
            >
              <Box
                sx={{
                  height: "40px",
                  width: "40px",
                  minWidth: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "0.496px solid rgba(0, 0, 0, 0.10)",
                  borderRadius: "4px",
                }}
              >
                {image_url ? (
                  <Box
                    component={"img"}
                    src={image_url}
                    alt="image"
                    sx={{
                      height: "100%",
                      objectFit: "contain",
                      maxWidth: "100%",
                    }}
                  />
                ) : (
                  <CropOriginalIcon
                    style={{ color: "#8E4FF6", fontSize: "20px" }}
                  />
                )}
              </Box>
            </Tooltip>
            {/* <Box>{asin}</Box> */}
            <Link
              underline={"hover"}
              target={"_blank"}
              href={asin ? `https://amazon.com/d/${asin}` : ""}
              sx={{
                color: "rgba(44, 34, 76, 0.70)",
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {asin}
            </Link>

            <Box
              sx={{
                zIndex: "1000",
                display: "none",
              }}
              id="action-container"
            >
              <Tooltip placement="top" title="Copy ASIN to clipboard" arrow>
                <IconButton
                  onClick={(e) => handleCopy(e, asin)}
                  onMouseEnter={() => setDisableRowClick(true)}
                  onMouseLeave={() => setDisableRowClick(false)}
                  sx={{
                    background: "unset",
                    "&:hover": {
                      background: "unset",
                    },
                    padding: "7px",
                    "& svg": {
                      height: "15px",
                      width: "15px",
                      fill: theme.palette.secondary.main,
                    },
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </>
      );
    },
    category: ({ value = "" }: { value: string }) => (
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Tooltip
          title={parse_categories_tooltip(value) || ""}
          placement="top-start"
          arrow
        >
          <Typography
            noWrap
            variant="body2"
            sx={{ textOverflow: "unset !important" }}
          >
            {parse_categories(value)}
          </Typography>
        </Tooltip>
      </Box>
    ),
  };

  const valueFormatters: { [key: string]: (p: any) => string } = {
    currency: (p) => "$" + Number(p.value).toLocaleString(),
    // growth: (p) => p.value.toFixed(2) + "%",
    number: (p) => Number(p.value).toLocaleString(),
    date: (p) => dayjs(p?.value).format("MMMM D, YYYY"),
  };

  const defaultColDef = {
    // flex: 1,
    suppressMenu: true,
    suppressHeaderFilterButton: true,
    sortable: true,
  };

  const colDefs = [
    {
      // headerName: "Product",
      field: "product",
      minWidth: 250,
      // flex: 1.2,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.product,
      sortable: false,
      headerComponent: CustomHeader,
      headerComponentParams: {
        showAgGrid: true,
        selectAgGrid: selectAgGrid,
        displayName: "Product",
      },
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "Title",
      field: "title",
      flex: 2,
      valueFormatter: undefined,
      cellRenderer: undefined,
      suppressMenu: true,
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "Brand",
      field: "brand",
      // flex: 0.8,
      valueFormatter: undefined,
      cellRenderer: undefined,
      suppressMenu: true,
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "Category",
      field: "categories",
      flex: 2,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.category,
      suppressMenu: true,
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "Date Added",
      field: "created_at",
      // flex: 0.7,
      valueFormatter: valueFormatters.date,
      cellRenderer: undefined,
      suppressMenu: true,
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
  ];

  const setProductsLength = useCallback(
    (res: GetProductsResponse, search?: string) => {
      if (
        asinsFromGrid?.asins?.length !== selectedProducts?.length ||
        asinsFromGrid?.asins?.length === 0
      ) {
        const asins = res?.items?.map((product) => product?.asin);
        setAsinsFromGrid({
          asins,
          total: res?.total,
          isDataReady: !search || false,
        });
      }
    },
    []
  );

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>, asin: string) => {
    e.stopPropagation();
    navigator.clipboard.writeText(asin).then(() =>
      enqueueSnackbar("Asin copied successfully", {
        variant: "success",
      })
    );
  };

  const createDataSource = (
    page: number = 1,
    // limit: number,
    search?: string,
    selectedBrands?: string[],
    selectedCategories?: string[][],
    currentSortState?: string[]
  ) => ({
    getRows: (params: any) => {
      const {
        request: { filterModel },
        success,
        fail,
      } = params;

      const queryParams: any = {};
      const limit = pageSize;
      const startRow = (page - 1) * pageSize;
      const filters: any = {};

      if (currentSortState && currentSortState.length) {
        queryParams.sort_by = currentSortState.map((sortObj: any) =>
          sortObj.sort === "desc" ? `-${sortObj.colId}` : sortObj.colId
        );
      }

      if (!search && limit) {
        queryParams.limit = limit;
      }
      if (startRow) {
        queryParams.offset = startRow;
      }
      if (search) {
        const searchText = search.replaceAll(/\s/g, "");
        if (searchText?.includes(",")) {
          const titles = searchText?.split(",");
          filters.titles = [...titles];
        } else {
          filters.titles = [search];
        }
      } else {
        if (typeof filterModel === "object" && !!filterModel.search_term) {
          queryParams.text_filter = filterModel?.search_term?.filter;
        }
      }
      if (selectedBrands) {
        filters.brands = selectedBrands;
      }
      if (selectedCategories) {
        const formattedCategory = selectedCategories.map((categoryArray) =>
          categoryArray.join("|")
        );
        filters.categories = formattedCategory;
      }
      getEcommerceProducts(
        queryParams?.offset,
        queryParams?.limit,
        filters,
        queryParams?.sort_by
      )
        .then((res) => {
          // setSelectedProducts([]);
          const asins = res?.items;
          setProductsLength(res, search);
          const parsedData = asins?.map((asin) => {
            return {
              ...asin,
              product: { image_url: asin.image_url, asin: asin.asin },
              title: asin?.title
                ? asin?.title?.charAt(0)?.toUpperCase() +
                  asin?.title?.slice(1)?.toLowerCase()
                : "",
              brand: asin?.brand
                ? asin?.brand?.charAt(0)?.toUpperCase() +
                  asin?.brand?.slice(1)?.toLowerCase()
                : "",
            };
          });
          setTotalRows(res?.total);
          success({
            rowData: parsedData,
            rowCount: parsedData.length,
          });
        })
        .catch((error) => {
          console.error(error);
          fail();
        });
    },
  });

  const debouncedOnChange = useCallback(
    debounce((search) => {
      const datasource = createDataSource(
        undefined,
        search,
        selectedBrands,
        selectedCategories,
        sortState
      );
      gridRef?.current!.api.setGridOption("serverSideDatasource", datasource);
    }, 500),
    []
  );
  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (gridRef && gridRef?.current?.api) {
      debouncedOnChange(search);
    }
  }, [search]);

  useEffect(() => {
    if (gridRef && gridRef?.current?.api) {
      const datasource = createDataSource(
        currentPage,
        search,
        selectedBrands,
        selectedCategories,
        sortState
      );
      gridRef?.current!.api.setGridOption("serverSideDatasource", datasource);
    }
  }, [selectedBrands, selectedCategories, currentPage, sortState]);

  // const onSelectionChanged = (params: SelectionChangedEvent<any, any>) => {
  //   if (params?.source === "rowClicked") {
  //     const selectedNodes = gridRef?.current!.api.getSelectedNodes();
  //     const selectedAsins = selectedNodes.map((node: any) => node.data.asin);
  //     if (selectedAsins?.length > 0) {
  //       setValue("asins", [], {
  //         shouldDirty: true,
  //         shouldValidate: true,
  //       });
  //     }
  //     setSelectedProducts(selectedAsins);
  //   }
  // };

  const rowClickedListener = useCallback(
    (event: RowClickedEvent<any, any>) => {
      if (disableRowClick) {
        return;
      }
      const selectedAsin = event?.data?.asin;
      if (Array.isArray(selectedProducts)) {
        setValue("asins", [], {
          shouldDirty: true,
          shouldValidate: true,
        });
        const prevProducts = [...selectedProducts];
        const isAsinPresent = prevProducts?.some(
          (asin) => asin === selectedAsin
        );
        if (isAsinPresent) {
          const updatedProductsList = prevProducts?.filter(
            (asin) => asin !== selectedAsin
          );
          setSelectedProducts([...updatedProductsList]);
        } else {
          prevProducts.push(selectedAsin);
          setSelectedProducts([...prevProducts]);
        }
      }
    },
    [selectedProducts, disableRowClick]
  );

  const onModelUpdated = () => {
    if (gridRef && gridRef?.current?.api) {
      if (selectedProducts?.length > 0) {
        gridRef?.current!.api.forEachNode((node) => {
          if (
            selectedProducts?.some(
              (selectedAsin) => node?.data?.asin === selectedAsin
            )
          ) {
            node.setSelected(true);
          }
        });
      }
      // if(selectedBrands?.length > 0 || selectedCategories?.length > 0 || search) {
      //   let allAsins: string[] = [];
      //   gridRef?.current!.api.forEachNode((node) => {
      //     allAsins?.push(node?.data?.asin);
      //   });
      //   setAsinsFromGrid({
      //     ...asinsFromGrid,
      //     asins: allAsins,
      //   });
      // }
      const allAsins: string[] = [];
      gridRef?.current!.api.forEachNode((node) => {
        allAsins?.push(node?.data?.asin);
      });
      setAsinsFromGrid({
        ...asinsFromGrid,
        asins: allAsins,
      });
    }
  };

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const datasource = createDataSource(currentPage);
      params.api!.setGridOption("serverSideDatasource", datasource);
    },
    [currentPage]
  );

  const onSortChanged = () => {
    const newSortState = gridRef.current?.api
      ?.getColumnState()
      .filter((s: any) => s.sort != null)
      .map((s: any) => ({
        colId: s.colId,
        sort: s.sort,
        sortIndex: s.sortIndex,
      }));

    setSortState(
      newSortState || [{ colId: "created_at", sort: "desc", sortIndex: 0 }]
    );
  };

  const getRowId = useCallback((params: any) => params.data.asin, []);

  const autoSizeStrategy: SizeColumnsToFitGridStrategy = {
    type: "fitGridWidth",
  };

  return (
    <>
      <Box
        className={"ag-theme-quartz"}
        sx={{
          padding: "2px 0",
          width: "100%",
          position: "relative",
          height: "100%",
          "& .ag-cell": {
            color: "rgba(44, 34, 76, 0.70)",
            fontFamily:
              "Inter, Roboto, Helvetica, Arial, sans-serif !important",
          },
          // "& .ag-header-cell": {
          //   ":nth-child(1)": {
          //     paddingLeft: "3.2rem",
          //   },
          // },
          "& .ag-row": {
            cursor: "pointer",
            ":hover": {
              background: theme.palette.grey[50],
              "& .MuiCheckbox-root": {
                display: "inline-flex",
              },
              ":hover": {
                "#action-container": {
                  display: "inline-flex",
                },
              },
            },
          },
          // "& .ag-row-hover": {
          //     background: "unset !important"
          // },
          "& .ag-row-selected": {
            background: theme.palette.primary.light,
            borderColor: "#D7D5DB",
            ":hover": {
              background: theme.palette.primary.light,
            },
          },
          // "& .ag-header-cell-comp-wrapper": {
          //   marginRight : "100px"
          // },
          "& .ag-header-cell-menu-button": {
            padding: "4px",
            ":hover": {
              boxShadow: "unset !important",
              borderRadius: "3px !important",
            },
          },
          "& .ag-header-cell-text": {
            fontFamily:
              "Inter, Roboto, Helvetica, Arial, sans-serif !important",
          },
          "& .ag-paging-row-summary-panel": {
            fontFamily:
              "Inter, Roboto, Helvetica, Arial, sans-serif !important",
          },
          "& .ag-paging-page-summary-panel": {
            fontFamily:
              "Inter, Roboto, Helvetica, Arial, sans-serif !important",
          },
        }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowHeight={50}
          pagination
          paginationPageSizeSelector={false}
          paginationPageSize={pageSize}
          suppressPaginationPanel
          rowModelType="serverSide"
          onGridReady={onGridReady}
          onSortChanged={onSortChanged}
          // onSelectionChanged={onSelectionChanged}
          onRowClicked={rowClickedListener}
          // onPaginationChanged={onPaginationChanged}
          maxBlocksInCache={2}
          cacheBlockSize={100}
          infiniteInitialRowCount={1}
          suppressRowClickSelection={disableRowClick}
          rowSelection="multiple"
          rowMultiSelectWithClick={true}
          onModelUpdated={onModelUpdated}
          getRowId={getRowId}
          suppressServerSideFullWidthLoadingRow={true}
          suppressContextMenu
          // serverSideEnableClientSideSort={true}
          autoSizeStrategy={autoSizeStrategy}
        />
      </Box>
      <CustomPagination
        totalRows={totalRows}
        currentPage={currentPage}
        handleChange={handlePageChange}
        type="ProductList"
      />
    </>
  );
};

export default ProductList;
