import {useHistory, useRouteMatch} from "react-router";

import SVG from "react-inlinesvg";
import {LeftNavBarItem} from "./LeftNavBarItem";
import {FC} from "react"

export const Tracking: FC = () => {
    const pageMatch = useRouteMatch({path: ["/tracking"]});
    const history = useHistory();
    return (
        <LeftNavBarItem
            selected={!!pageMatch}
            icon={
                <SVG
                    src={"/sales/tracking-icon.svg"}
          fill="inherit"
          width={20}
          height={20}
        />
      }
      onClick={() => history.push("/tracking")}
      label="Tracking"
    />
  );
};
