import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

export const HeaderContent: FC<{ children: ReactNode }> = ({ children }) => (
  <Box order={1} marginX={2}>
    {children}
  </Box>
);

export const HeaderActions: FC<{ children: ReactNode }> = ({ children }) => (
  <Box order={2}>{children}</Box>
);

const Header: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    marginX={2}
    marginY={1}
  >
    {children}
  </Box>
);

export default Header;
