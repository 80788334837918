import {Box, Typography} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import {ConnectorType} from "../../../../enums";
import {ConnectorLogoMapping} from "../../../../mappings";
import {FC} from "react"

const useStyles = makeStyles((theme) => ({
    image: {
        margin: theme.spacing(4),
    },
}));

type UploadBoxProps = {
    isDragActive: boolean;
};

const UploadBox: FC<UploadBoxProps> = ({ isDragActive }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <img
        alt="Local"
        src={`/${ConnectorLogoMapping[ConnectorType.LOCAL]}`}
        height="80px"
        width="80px"
        className={classes.image}
      />
      <Typography variant="subtitle2">
        {isDragActive
          ? "Drop the files here..."
          : "Drag a file to upload (CSV and Parquet files are supported)"}
      </Typography>
    </Box>
  );
};

export default UploadBox;
