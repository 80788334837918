import CloseIcon from "@mui/icons-material/Close";
import {Box, Chip, Drawer, IconButton, MenuItem, Select, Stack, Typography,} from "@mui/material";
import {FC, useState} from "react";
import {DataSourceImg} from "../../components/dataSourceTypeIcon";
import {Split} from "../../FeatureFlags/enums";
import {useSplitEnabled} from "../../hooks";
import useDataSourcesOptions from "../../services/datasources/useDataSourcesOptions";
import {DataSourceType} from "../../types/datasources";
import DataSourceSchemaForm from "./DataSourceSchemaForm";

const DataSourceSelection: FC<{
  open: boolean;
  onClose: (open: boolean) => void;
}> = ({open, onClose}) => {
  const {data: dataSourceOptions} = useDataSourcesOptions();

  const toggleDrawer = () => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    onClose(false);
  };

  const [dataSourceType, setDataSourceType] = useState<DataSourceType>(
    DataSourceType.AMAZON_NORTH_AMERICA
  );

  const isWalmartOn = useSplitEnabled(Split.WALMART);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer()}
      PaperProps={{ sx: { width: 520 } }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box margin={2}>
          <Stack direction="row">
            <Chip
              size="small"
              variant="outlined"
              label="esc"
              sx={{ borderRadius: 1, boxShadow: 1 }}
            />
            <Typography ml={1} mt={0.2}>
              to close
            </Typography>
          </Stack>
        </Box>
        <IconButton
          aria-label="close"
          size="small"
          sx={{ margin: 2 }}
          onClick={() => onClose(false)}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography
        variant="h5"
        flexDirection="column"
        display="flex"
        sx={{ ml: 2, mb: 3 }}
      >
        Set up data source
      </Typography>

      <Typography
        variant="body1"
        flexDirection="column"
        display="flex"
        sx={{ ml: 2 }}
      >
        Source Type
      </Typography>

      <Select
        sx={{ ml: 2, width: "82%" }}
        defaultValue={dataSourceType}
        value={dataSourceType}
        renderValue={(type: DataSourceType) => {
          const option = dataSourceOptions?.find(
            (option) => option.type === type
          );
          return (
            <Box display="flex" alignItems="center">
              <DataSourceImg type={type} height="20px" width="20px" />
              <Typography variant="inherit" sx={{ ml: 1 }}>
                {option?.title || type}
              </Typography>
            </Box>
          );
        }}
        disableUnderline
      >
        {dataSourceOptions &&
          dataSourceOptions
            .filter(
              (dsOption) =>
                dsOption.type !== DataSourceType.WALMART || isWalmartOn
            )
            .map((dsOption) => (
              <MenuItem
                key={dsOption.type}
                value={dsOption.type}
                onClick={() =>
                  setDataSourceType(dsOption.type as DataSourceType)
                }
              >
                <Box sx={{ mt: 0.5, mr: 1 }}>
                  <DataSourceImg
                    height="20px"
                    width="20px"
                    type={dsOption.type}
                  />
                </Box>
                <Typography variant="inherit">{dsOption.title}</Typography>
              </MenuItem>
            ))}
      </Select>

      {dataSourceOptions && (
        <Box sx={{ ml: 2, width: "82%" }}>
          <DataSourceSchemaForm
            type={dataSourceType}
            onClose={() => onClose(false)}
            schema={
              dataSourceOptions
                ? dataSourceOptions.find(
                    (option) => option.type === dataSourceType
                  )!.input_schema
                : {}
            }
            submitLabel="Add Data source"
            actionButtonProps={{ variant: "contained" }}
          />
        </Box>
      )}
    </Drawer>
  );
};

export default DataSourceSelection;
