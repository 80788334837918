import { createContext, useEffect, useState } from "react";
import { CatalogEnrichmentTab, EnrichmentFlowState, Log, logsToExclude } from "./types";
import {
  getEnrichmentFlowGraphApi,
  getEnrichmentFlowLogsApi,
  getEnrichmentFlowStateApi,
  startEnrichmentFlowApi,
} from "./useCatalogEnrichmentApi";

export const CatalogEnrichmentContext = createContext<any>({});
export const CatalogEnrichmentProvider = ({ children }: any) => {
  const [selectedTab, setSelectedTab] = useState<CatalogEnrichmentTab>(CatalogEnrichmentTab.IDLE);
  const [flowRunId, setFlowRunId] = useState<string>("");
  const [flowRunName, setFlowRunName] = useState<string>("");
  const [flowGraph, setFlowGraph] = useState<any>({});
  const [logs, setLogs] = useState<Log[]>([]);
  const [flowState, setFlowState] = useState<string>(
    EnrichmentFlowState.Pending
  );
  const [isEnrichmentFlowRunning, setIsEnrichmentFlowRunning] =
    useState<boolean>(false);

  const startEnrichmentFlow = async (
    rawDataPath: string,
    cadFilePath: string
  ) => {
    if (isEnrichmentFlowRunning) return;
    if (!rawDataPath || !cadFilePath) return;

    setFlowRunId("");
    setFlowRunName("");
    setSelectedTab(0);
    setLogs([
      {
        timestamp: new Date(),
        message: "Waiting for the enrichment flow to start...",
      },
    ]);
    setFlowState(EnrichmentFlowState.Pending);
    setFlowGraph({});

    setIsEnrichmentFlowRunning(true);

    return await startEnrichmentFlowApi(rawDataPath, cadFilePath)
      .then((response) => {
        const { id, name, state_type } = response;
        setFlowRunId(id);
        setFlowRunName(name);
        setFlowState(state_type);
        return response;
      })
      .catch((error) => {
        setIsEnrichmentFlowRunning(false);
        throw error;
      });
  };
  const getEnrichmentFlowState = async () =>
    await getEnrichmentFlowStateApi(flowRunId)
      .then((response) => {
        setFlowState(response.state);
      })
      .catch((error) => {
        console.error("Error getting enrichment flow state", error);
      });

  const updateLogs = (fetchedLogs: Log[]) => {
    setLogs((prevLogs) => {
      if (prevLogs.length === 0) return fetchedLogs;
      if (fetchedLogs.length > prevLogs.length) {
        return [...prevLogs, ...fetchedLogs.slice(prevLogs.length)];
      }
      return prevLogs;
    });
  };

  const getEnrichmentFlowLogs = async () =>
    await getEnrichmentFlowLogsApi(flowRunId)
      .then((response) => {
        return updateLogs(
          response.logs
            .map((log: any) => ({
              timestamp: new Date(log.timestamp),
              message: log.message,
            }))
            .filter((log: Log) => {
              return !logsToExclude.some((exclude: string) =>
                log.message.includes(exclude)
              );
            })
        );
      })
      .catch((error) => {
        console.error("Error getting enrichment flow logs", error);
      });

  const getEnrichmentFlowGraph = async () =>
    await getEnrichmentFlowGraphApi(flowRunId)
      .then((response) => {
        setFlowGraph(response.graph);
      })
      .catch((error) => {
        console.error("Error getting enrichment flow graph", error);
      });

  useEffect(() => {
    if (isEnrichmentFlowRunning && flowRunId) {
      const interval = setInterval(() => {
        getEnrichmentFlowLogs();
        getEnrichmentFlowState();
        getEnrichmentFlowGraph();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isEnrichmentFlowRunning, flowRunId]);

  useEffect(() => {
    if (
      flowState === EnrichmentFlowState.Failed ||
      flowState === EnrichmentFlowState.Crashed
    ) {
      getEnrichmentFlowLogs();
      setIsEnrichmentFlowRunning(false);
    }
  }, [flowState]);

  // Completed flow
  useEffect(() => {
    const exitMessage = logs.find((log) =>
      log.message.includes(`Process for flow run '${flowRunName}' exited`)
    );
    if (exitMessage) {
      setIsEnrichmentFlowRunning(false);
    }
  }, [logs]);

  const state = {
    selectedTab,
    setSelectedTab,
    flowRunId,
    setFlowRunId,
    flowRunName,
    setFlowRunName,
    flowGraph,
    logs,
    setLogs,
    flowState,
    setFlowState,
    isEnrichmentFlowRunning,
    setIsEnrichmentFlowRunning,
    startEnrichmentFlow,
    getEnrichmentFlowState,
    getEnrichmentFlowLogs,
    getEnrichmentFlowGraph,
  };
  return (
    <CatalogEnrichmentContext.Provider value={{ ...state }}>
      {children}
    </CatalogEnrichmentContext.Provider>
  );
};
