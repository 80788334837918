import { Divider, Tab, Tabs, ThemeProvider } from "@mui/material";
import { ChangeEvent, FC } from "react";
import {
  Redirect,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useRouteMetrics } from "../../../hooks/metrics/routeEvents";
import { useBoard } from "../../../services/boards";
import { useRun } from "../../../services/runs";
import theme from "../../../themev2";
import DataSection from "./DataSection";
import LogsSection from "./LogsSection";
import OverviewSection from "./OverviewSection";
import PublishedRunBox from "./PublishedRunBox";
import RunHeader from "./RunHeader/RunHeader";
import { Run } from "../../../types";
import { Status } from "../../../enums";

enum PageSections {
  Overview = "overview",
  Data = "data",
  Analytics = "analytics",
  Logs = "logs",
}

const RunPageV2: FC = () => {
  const history = useHistory();
  const { boardId, runId }: { boardId?: any; runId?: any } = useParams();
  const { url, path } = useRouteMatch();

  const { data: board } = useBoard(boardId);
  const { data: run }: { data?: Run } = useRun(boardId, runId, {
    enabled: !!(boardId && runId),
  });
  const isFinished =
    run?.status === Status.SUCCEEDED || run?.status === Status.FAILED;

  const handleTabChange = (
    _event: ChangeEvent<object>,
    newValue: PageSections
  ) => {
    history.push(newValue);
  };
  useRouteMetrics([`${path}/:section`], { scope: ["run"] });
  return (
    <>
      <ThemeProvider theme={theme}>
        <RunHeader />
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={history.location.pathname.split("/").slice(0, 6).join("/")}
          onChange={handleTabChange}
        >
          <Tab
            value={`${url}/${PageSections.Overview}`}
            label={<div>Overview</div>}
          />
          <Tab
            value={`${url}/${PageSections.Data}`}
            label={<div>Data</div>}
            disabled={!isFinished}
          />
          <Tab value={`${url}/${PageSections.Logs}`} label={<div>Logs</div>} />
        </Tabs>
      </ThemeProvider>
      <Divider />
      {board?.published_run_id === runId && (
        <PublishedRunBox boardId={boardId} />
      )}
      <Route path={url} exact>
        <Redirect to={`${url}/${PageSections.Overview}`} />
      </Route>
      <Route path={`${url}/${PageSections.Overview}`}>
        <OverviewSection
          boardId={boardId}
          runId={runId}
          status={run?.status || Status.UNKNOWN}
        />
      </Route>
      <Route path={`${url}/${PageSections.Data}`}>
        <DataSection boardId={boardId} runId={runId} />
      </Route>
      <Route path={`${url}/${PageSections.Logs}`}>
        <LogsSection boardId={boardId} runId={runId} />
      </Route>
    </>
  );
};
export default RunPageV2;
