import { useAmplitude } from "react-amplitude-hooks";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useSearchParams from "../useSearchParams";
import { TransformedInsight } from "../../pages/InsightsV2/types";

type InsightEventData = {
  insight: Pick<TransformedInsight, "type" | "id">;
  action: string;
  origin?: string;
};
export const useInsightFeedEvent = () => {
  const location = useLocation();
  const { search } = useSearchParams();
  const { logEvent } = useAmplitude((inheritedProps: any = {}) => ({
    ...inheritedProps,
    scope: ["platform"],
  }));
  return useCallback(
    ({ insight, action, origin = "Insight Card" }: InsightEventData) => {
      logEvent("platform.funnel", {
        queryParams: search,
        url: document.URL,
        insightType: insight.type,
        insightId: insight.id,
        origin,
        action,
        ...location,
      });
    },
    [logEvent, search, location]
  );
};
