import { useQuery, UseQueryOptions } from "react-query";
import { ConnectorOption } from "../../types/connectorsV2";
import apiRequest, { APIError } from "../apiRequest";

function getConnectorOptions(): Promise<ConnectorOption[]> {
  return apiRequest<ConnectorOption[]>("connectors", "OPTIONS");
}

export default function useConnectorOptions(
  options?: UseQueryOptions<ConnectorOption[], APIError>
) {
  return useQuery<ConnectorOption[], APIError>(
    ["connectorOptions"],
    () => getConnectorOptions(),
    { ...options }
  );
}
