import {useHistory, useLocation} from "react-router-dom";
import {useMemo} from "react";

export type SearchParam = string | boolean | string[];
export type SearchParams = Record<string, SearchParam>;
export const parseStringParams = (v: string) =>
    v === "false"
        ? false
        : v === "true"
            ? true
            : v.indexOf(",") > -1
                ? v.split(",")
    : v;

const useSearchParams = (
  parse: boolean = true
): {
  search?: SearchParams;
  setSearchParams: (params: Record<string, any>) => void;
} => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(
    () =>
        // @ts-ignore
      Object.fromEntries(
          // @ts-ignore
        [...new URLSearchParams(location.search).entries()].map(([k, v]) => [
          k,
          parse ? parseStringParams(v) : v,
        ])
      ),
    [location.search]
  );
  return {
    search: searchParams,
    setSearchParams: (value) => {
      const sp = new URLSearchParams(value);
      history.push({ search: sp.toString() });
    },
  };
};

export default useSearchParams;
