import {Stack, Typography} from "@mui/material";
import {ReactNode} from "react"

export const TableHeader = ({
                              subTitle,
                              title,
                            }: {
  subTitle: ReactNode;
  title: ReactNode;
}) => (
    <Stack spacing={0.5} alignItems="center" direction="row">
      <Typography fontWeight={600}>{title}</Typography>
    {!subTitle ? null : (
      <>
        <Typography sx={{ color: (t) => t.palette.grey[200] }}>•</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: (t) => t.palette.grey[300],
            display: "flex",
          }}
        >
          {subTitle}
        </Typography>
      </>
    )}
  </Stack>
);
