import "ag-grid-enterprise";
import { useState } from "react";
import {
  BinaryFilter,
  BinaryOperator,
  Filter,
  Query,
  TCubeMemberType,
} from "@cubejs-client/core";
import {
  ColDef,
  GridApi,
  ICombinedSimpleModel,
  IGetRowsParams,
  GridReadyEvent,
  ISimpleFilterModel,
  IDatasource,
} from "@ag-grid-community/core";
import { useMutation } from "react-query";
import { toSnakeCase } from "../../../../../utils";
import { Dimension, OutputQueryRequest } from "../../../../../types";
import { loadQuery } from "../../../../../services/query/api";
import { cellRenderer, getFormatter, indexColumnsParams } from "./utils";
import { AgGridHookResult } from "./types";

type FilterType = "text" | "number" | "date";
type PossibleFiltersModel = ISimpleFilterModel & {
  filterType: FilterType;
  filter?: string;
  filterTo?: string;
  dateTo?: string;
  dateFrom?: string;
};
const sharedFilterOptions = ["equals", "notEqual"];
const textFilterOptions = ["contains", "notContains"];
const dateFilterOptions = ["inRange"];
const numberDateFilterOptions = [
  "lessThan",
  "lessThanOrEqual",
  "greaterThan",
  "greaterThanOrEqual",
];

const getFilterOptionsByType = (type: TCubeMemberType): string[] | null => {
  switch (type) {
    case "string":
      return [...sharedFilterOptions, ...textFilterOptions];
    case "number":
      return [...sharedFilterOptions, ...numberDateFilterOptions];
    case "time":
      return [
        ...sharedFilterOptions,
        ...numberDateFilterOptions,
        ...dateFilterOptions,
      ];
    default:
      return null;
  }
};

const translateAgGridOperatorToCubeJsOperator = (operator: string): string => {
  switch (operator) {
    case "notEqual":
      return "notEquals";
    case "inRange":
      return "inDateRange";
    case "greaterThan":
      return "gt";
    case "greaterThanOrEqual":
      return "gte";
    case "lessThan":
      return "lt";
    case "lessThanOrEqual":
      return "lte";
    default:
      return operator;
  }
};
const parseQuery = (name: string, filter: any): any => {
  if (isJoinQuery(filter)) {
    return parseJoinQuery(name, filter);
  }
  return parseFilterQuery(name, filter);
};

const parseJoinQuery = (
  name: string,
  filter: ICombinedSimpleModel<PossibleFiltersModel>
): Record<"or" & "and", Filter[]> => {
  const fil1 = parseQuery(name, filter.conditions[0]);
  const fil2 = parseQuery(name, filter.conditions[1]);
  return { [filter.operator.toLowerCase()]: [fil1, fil2] };
};

const isJoinQuery = (filter: { operator?: string }): boolean =>
  filter.operator === "OR" || filter.operator === "AND";

const parseFilterQuery = (
  name: string,
  filter: PossibleFiltersModel
): BinaryFilter => {
  const operator = translateAgGridOperatorToCubeJsOperator(
    filter.type || ""
  ) as BinaryOperator;
  let values: any[];
  switch (filter.filterType) {
    case "text":
      values = [filter.filter?.toString()];
      break;
    case "number":
      values = [filter.filter?.toString(), filter.filterTo?.toString()];
      break;
    case "date":
      values = [filter.dateFrom, filter.dateTo];
      break;
    default:
      values = [];
  }
  return { operator, values, member: name };
};

const useCubeJsDimensionsGrid = (
  dimensions: Dimension[],
  boardId: string,
  runId: string,
  onError?: (error: any) => void,
  initialQuery?: Query
): AgGridHookResult => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const mutation = useMutation(loadQuery);
  const errorMessage = mutation.error
    ? mutation.error instanceof Error
      ? mutation.error.message
      : "We are sorry but we are not able to complete the request"
    : null;

  const columns: ColDef[] = [
    indexColumnsParams,
    ...dimensions.map((dimension) => ({
      headerName: toSnakeCase(dimension.shortTitle),
      field: dimension.name,
      sortable: true,
      filterParams: {
        buttons: ["reset"],
        filterOptions: getFilterOptionsByType(dimension.type),
      },
      filter:
        dimension.type === "number"
          ? "agNumberColumnFilter"
          : dimension.type === "time"
          ? "agDateColumnFilter"
          : "agTextColumnFilter",
      valueFormatter: getFormatter(dimension.type),
      cellRenderer,
    })),
  ];
  const query =
    initialQuery ||
    ({
      dimensions: dimensions.map((dimension) => dimension.name) || [],
      ungrouped: true,
      limit: 100,
    } as Query);

  const createDataSource = ():IDatasource => ({
    getRows: (params: IGetRowsParams) => {
      mutation
        .mutateAsync({
          circuitboardId: boardId,
          runId,
          query: {
            ...query,
            offset: params.startRow,
            order: params.sortModel.reduce(
              (acc: object, item: any) => ({
                ...acc,
                [item.colId]: item.sort,
              }),
              {}
            ),
            filters: Object.entries<any>(params.filterModel).map(([k, v]) =>
              parseQuery(k, v)
            ),
          },
        } as OutputQueryRequest)
        .then((response) => {
          const data = response.rawData();
          setRowData(data);
          const allRows: any = [];
          gridApi?.forEachNode(node => allRows.push(node.data));
          if (data.length === 0 && params.startRow === 0) {
            gridApi?.applyTransaction({ remove: allRows });
            gridApi?.showNoRowsOverlay();
          } else if (data.length < 100) {
            return params.successCallback(data);
          }
          return params.successCallback(data);
        })
        .catch((error) => {
          if (onError) onError(error);
          params.failCallback();
        });
    },
  })

  const reset = () => {
    mutation.reset();
    const datasource = createDataSource();
    gridApi?.setGridOption("datasource",datasource);
  };

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    const datasource = createDataSource();
    params.api!.setGridOption("datasource", datasource);
  };

  return {
    onGridReady: onReady,
    columns,
    rowData,
    isLoading: mutation.isLoading,
    errorMessage,
    reset,
  };
};
export default useCubeJsDimensionsGrid;
