import { Box, keyframes } from "@mui/material";
import { FC } from "react";
import theme from "../themes/light";

interface CustomDotLoaderProps {
  size?: number;
}

const CustomDotLoader: FC<CustomDotLoaderProps> = (props) => {
  const { size } = props;

  return (
    <Box
      sx={{
        position: "relative",
        left: "-9999px",
        width: size || "20px",
        height: size || "20px",
        margin: "20px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        boxShadow: "9999px 0 0 -5px",
        animation: `${keyframes({
          "0%": { boxShadow: "9999px 0 0 -5px" },
          "30%": { boxShadow: "9999px 0 0 2px" },
          "60%, 100%": { boxShadow: "9999px 0 0 -5px" },
        })} 1.5s infinite linear`,
        animationDelay: "0.25s",
        "&::before, &::after": {
          content: '""',
          display: "inline-block",
          position: "absolute",
          top: "0",
          width: size || "20px",
          height: size || "20px",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        },
        "&::before": {
          boxShadow: "9984px 0 0 -5px",
          animation: `${keyframes({
            "0%": { boxShadow: "9984px 0 0 -5px" },
            "30%": { boxShadow: "9984px 0 0 2px" },
            "60%, 100%": { boxShadow: "9984px 0 0 -5px" },
          })} 1.5s infinite linear`,
          animationDelay: "0s",
          right: "10px",
        },
        "&::after": {
          boxShadow: "10014px 0 0 -5px",
          animation: `${keyframes({
            "0%": { boxShadow: "10014px 0 0 -5px" },
            "30%": { boxShadow: "10014px 0 0 2px" },
            "60%, 100%": { boxShadow: "10014px 0 0 -5px" },
          })} 1.5s infinite linear`,
          animationDelay: "0.5s",
          left: "10px",
        },
      }}
    ></Box>
  );
};

export default CustomDotLoader;
