import {
  Chip,
  ChipProps,
  Stack,
  StackProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import DateParser from "../DateParser";
import theme from "../../../themes/light";
import { SalesAssistantRead } from "../types";
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";

export const InsightDate: FC<{ date: Date }> = ({ date }) => (
  <Typography variant="caption" color="text.secondary">
    {DateParser(dayjs(date))}
  </Typography>
);

export const InsightCategoryText: FC<{ categories: any }> = ({
  categories,
}) => (
  <Tooltip title={categories}>
    <Typography variant="caption" color="text.secondary">
      {`${categories?.split("|")[0]} | ... | ${categories?.split("|").pop()}`}
    </Typography>
  </Tooltip>
);

const InfoText: FC<{
  insight: SalesAssistantRead;
  hideCategories?: boolean;
}> = ({ insight, hideCategories }) => (
  <>
    <Stack direction="column" alignItems="flex-start" gap="8px">
      {insight.brand && (
        <Chip
          label={insight.brand}
          size="small"
          sx={{
            borderRadius: "4px",
            padding: "4px",
            background: theme.palette.backgrounds.secondary,
            textTransform: "capitalize",
          }}
        />
      )}
      {!hideCategories && insight.categories && (
        <InsightCategoryText categories={insight.categories} />
      )}
    </Stack>
  </>
);

export const Info: FC<
  StackProps & {
    insight: SalesAssistantRead;
    hideCategories?: boolean;
    chipProps?: ChipProps;
  }
> = ({ insight, hideCategories, ...props }) => {
  const theme = useTheme();
  const showAlerts = useSplitEnabled(Split.SALES_ASSISTANT_PRODUCTS_ALERTS);
  return (
    <Stack direction="column" spacing={1} alignItems="space-between" {...props}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          variant="headline3"
        >
          {insight?.asin}{" "}
          {insight?.alerts && showAlerts && (
            <Chip
              label="Alert"
              size="small"
              color="secondary"
              sx={{
                borderRadius: "500px",
                padding: "4px",
                background: "rgba(199, 14, 36, 0.10);",
                fontSize: "13px",
                color: theme.palette.error.main,
              }}
            />
          )}
        </Typography>
      </Stack>
      <InfoText insight={insight} hideCategories={hideCategories} />
    </Stack>
  );
};
