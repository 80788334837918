import { useAuthUserOrNull } from "@frontegg/react";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import AceEditor from "react-ace";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useUserTenant } from "../../hooks";
import { minifyJsonObject, prettifyJson } from "../../utils";
import {
  CreateTenantConfigRequers,
  createTenantCongifuration,
  useTenantConfiguration,
} from "../../services/tenants/useTenantConfiguration";
import { TenantConfiguration } from "../../types/tenants";
import { APIError } from "../../services/apiRequest";
import LoadableTypograpy from "../../components/LoadableTypography";
import { useUserAuthorization } from "../../hooks/useUserAuthorization";
import { BoardLibrary, PlatformPermission } from "../../enums";

export const Configuration: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useAuthUserOrNull();
  const tenant = useUserTenant(user || undefined);
  const { setValue, formState, watch, register, handleSubmit, reset } =
    useForm<{
      configuration: string;
      description: string;
    }>({
      mode: "onChange",
      defaultValues: { configuration: JSON.stringify({ libraries: [] }) },
    });

  const { data, isLoading } = useTenantConfiguration(tenant?.tenantId || "", {
    enabled: !!tenant?.tenantId,
    refetchOnWindowFocus: false,
    onSuccess: (config) =>
      setValue("configuration", minifyJsonObject(config.configuration), {
        shouldDirty: false,
      }),
  });

  const { isDirty, isSubmitting, isValid, isValidating, errors } = formState;

  const mutate = useMutation<
    TenantConfiguration,
    APIError,
    CreateTenantConfigRequers
  >(createTenantCongifuration);

  const onSubmit = handleSubmit((formValues) => {
    mutate
      .mutateAsync({
        tenantId: tenant?.tenantId || "",
        data: {
          description: formValues.description,
          configuration: JSON.parse(formValues.configuration),
        },
      })
      .then((res) => {
        enqueueSnackbar("Workspace Configuration saved successfully.", {
          variant: "success",
        });
        reset({
          configuration: minifyJsonObject(res?.configuration),
          description: "",
        });
      })
      .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
  });

  useEffect(() => {
    register("description", { required: "This field is required" });
    register("configuration", {
      validate: (val) => {
        try {
          JSON.parse(val);
          return true;
        } catch (e) {
          return "Value must be a valid JSON";
        }
      },
    });
  }, [register]);

  const hasTenantCreatePermission = useUserAuthorization({
    permissions: [PlatformPermission.TENANT_ADMIN_CREATE],
  });

  const isConfigurationDirty =
    minifyJsonObject(data?.configuration) !==
    minifyJsonObject(watch("configuration"));

  return (
    <Box flex={1} mt={4} mb={4}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex">
          <Typography variant="h5" mr={1}>
            Configuration for Workspace
          </Typography>
          <LoadableTypograpy
            color="primary"
            variant="h5"
            fontWeight="bold"
            sx={{ minWidth: 130 }}
            isLoading={!tenant?.name}
          >
            {tenant?.name}
          </LoadableTypograpy>
        </Box>
        <Box display="flex" mt={2} alignItems="center">
          <Typography variant="body2">
            Change configuration for workspace{" "}
          </Typography>
          <LoadableTypograpy
            variant="body2"
            isLoading={!tenant?.name}
            sx={{ minWidth: (t) => t.spacing(78 / 8), mr: 0.5, ml: 0.5 }}
          >
            <b>{tenant?.name}</b>
          </LoadableTypograpy>{" "}
          <Typography variant="body2">using editor</Typography>
        </Box>
        <Typography variant="caption" sx={{ mb: 2 }}>
          For example:{" "}
          {JSON.stringify({ libraries: Object.values(BoardLibrary) }, null, 2)}
        </Typography>
        <Paper variant="outlined" sx={{ flex: 1 }}>
          <AceEditor
            debounceChangePeriod={500}
            name="configuration"
            readOnly={isLoading && !data}
            showPrintMargin={false}
            value={isLoading ? "" : prettifyJson(watch("configuration") || "")}
            style={{ height: "50vh", width: "100%" }}
            onChange={(text) => {
              setValue("configuration", minifyJsonObject(text), {
                shouldValidate: true,
                shouldDirty:
                  minifyJsonObject(text) !==
                  minifyJsonObject(data?.configuration),
              });
            }}
          />
        </Paper>
        {errors.configuration && (
          <Typography sx={{ mt: 1 }} variant="caption" color="error">
            {errors?.configuration?.message}
          </Typography>
        )}

        <TextField
          sx={{ mt: 2 }}
          label="Reason for change"
          defaultValue=""
          value={watch("description")}
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          error={!!errors?.description}
          helperText={
            errors?.description?.message ||
            "Enter the business motivation for the change. It will appear in the history tab for future reference."
          }
          InputLabelProps={{ shrink: !!watch("description") }}
          required
          onChange={(e) =>
            setValue("description", e.target.value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        />

        <Box display="flex" justifyContent="end" mt={2}>
          <Button
            sx={{ mr: 2 }}
            onClick={() => {
              reset({
                configuration: minifyJsonObject(data?.configuration),
                description: "",
              });
            }}
          >
            Reset
          </Button>
          <Button
            disabled={
              !isDirty ||
              !isValid ||
              isSubmitting ||
              isValidating ||
              !hasTenantCreatePermission ||
              !isConfigurationDirty
            }
            variant="contained"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Configuration;
