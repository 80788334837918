import { Box, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useInsightsContext } from "../../hooks/selectors";
import theme from "../../../../theme";
import { InsightRead } from "../../types";

interface PropTypes {
  insight: InsightRead;
}

export const RecommendedActionSection = ({ insight }: PropTypes) => {
  const { insights } = useInsightsContext();
  const transformedInsight = insights.find(({ id }) => id === insight?.id);
  if (!insight || !transformedInsight) {
    return <Skeleton width="100%" height="400px" />;
  }
  return (
    <Stack spacing={1}>
      <Stack spacing={2} alignItems="start">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="headline3" color="text.primary">
            Insight details
          </Typography>
          <Typography
            variant="caption"
            data-testid="drawer-insight-text"
            mt={0}
            color={theme.palette.grey[400]}
          >
            {moment(insight.date).format("MMM DD YYYY")}
          </Typography>
        </Box>
        <Typography variant="body3" data-testid="drawer-insight-text" mt={0}>
          {insight?.cardText}
        </Typography>
      </Stack>
    </Stack>
  );
};
