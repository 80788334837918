import { FC, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Checkbox,
  Chip,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "../../../utils";
import cn from "classnames";
import { StatsPeriods, TransformedInsight } from "../types";
import { useToggleSelected } from "../hooks/setters";
import { useQueryParams, useSplitEnabled } from "../../../hooks";
import { useInsightsContext } from "../hooks/selectors";
import { columnWidths } from "../constants";
import { ProductImage } from "./ProductImage";
import { Info } from "./Info";
import { ActionOrigins, InsightActionButton } from "./ActionButton";
import { Rating } from "./Rating";
import { SocialAndActions } from "./SocialAndActions";
import { onClickRow } from "./List";
import { useInsightFeedEvent } from "../../../hooks/metrics/useInsightEvent";
import { useInsightsStyles } from "../useInsightsStyles";
import TabsButtons from "./TabButtons";
import DateParser from "../DateParser";
import { Icon } from "../../../components/Icon";
import { Split } from "../../../FeatureFlags/enums";
import SummaryV2 from "../SummaryV2";
import { useAppContext } from "../../../ApplicationContext";

export const mapRevenue = (
  revenue: number,
  sales7D: number | undefined,
  sales30D: number | undefined,
  statsPeriod: StatsPeriods,
  revenueOverTimeEnabled: boolean
): number => {
  if (!revenueOverTimeEnabled) return revenue;
  if (statsPeriod === "7 days") return sales7D as number;
  if (statsPeriod === "30 days") return sales30D as number;
  throw Error("wrong revenue value mapping");
};

export const InsightsListItem: FC<{
  insight: TransformedInsight;
  setInsightIdForDialog: (str: string) => void;
}> = ({ insight, setInsightIdForDialog }) => {
  const { setInsightType } = useAppContext();
  const toggleSelected = useToggleSelected();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { isDrawerEnabled, statsPeriod } = useInsightsContext();
  const logEvent = useInsightFeedEvent();
  const theme = useTheme();
  const { row } = useInsightsStyles();
  const { pathname } = useLocation();
  const showCheckbox = useMemo(
    () => !(pathname.search("done") > -1 || pathname.search("dismissed") > -1),
    [pathname]
  );
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);
  return (
    <>
      <ListItem
        key={insight.id}
        id={`insight-id-${insight.id}`}
        sx={{
          height: "auto",
          opacity: 1,
          transition: "all 0.3s linear",
          overflow: "hidden",
          "&.hidden": {
            paddingY: 0,
          },
        }}
      >
        <Stack
          data-testid="insights-list-row"
          onClick={() => {
            onClickRow(
              isDrawerEnabled,
              queryParams,
              history,
              insight,
              logEvent
            );
            setInsightType(insight.type);
          }}
          className={cn(row, { active: insight.isChecked })}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              borderBottom: `1px solid ${theme.palette.borders.lines}`,
              padding: "0 20px",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap="8px"
              minHeight="42px"
            >
              {showCheckbox && (
                <Box>
                  <Checkbox
                    data-testid={`insight-checkbox-${insight.id}`}
                    sx={{ width: columnWidths.checkbox }}
                    disableRipple
                    checked={insight.isChecked}
                    onChange={() => toggleSelected(insight.id)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Box>
              )}
              {pathname.search("done") > -1 && (
                <Box
                  sx={{ color: theme.palette.text.success }}
                  maxHeight="24px"
                  padding="3px 0"
                >
                  <Icon
                    src="insights/check-fill.svg"
                    width="16px"
                    height="16px"
                  />
                </Box>
              )}
              <Box>
                {insight?.status === "new" && (
                  <Chip
                    label="New"
                    size="small"
                    color="secondary"
                    sx={{
                      borderRadius: "500px",
                      padding: "4px",
                      background: theme.palette.backgrounds.active,
                    }}
                  />
                )}
                {insight?.status === "updated" && (
                  <Chip
                    label="Updated"
                    size="small"
                    sx={{
                      borderRadius: "500px",
                      padding: "4px",
                      background: theme.palette.backgrounds.info,
                      color: theme.palette.info.contrastText,
                    }}
                  />
                )}
              </Box>
              <Box>
                <Typography
                  variant="body5"
                  color={theme.palette.text.secondary}
                >
                  {DateParser(dayjs(insight.date))}
                </Typography>
              </Box>
            </Stack>
            <TabsButtons
              insightId={insight.id}
              setInsightIdForDialog={setInsightIdForDialog}
              canUndo={insight?.canUndo}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            padding="16px 20px"
            gap="20px"
          >
            <Box minWidth={columnWidths.image}>
              <ProductImage insight={insight} useTooltip />
            </Box>
            <Box width={columnWidths.info}>
              <Info insight={insight} />
            </Box>
            <Stack
              alignItems="center"
              justifyContent="center"
              width={columnWidths.action}
              minWidth="186px"
            >
              <InsightActionButton
                insight={insight}
                origin={ActionOrigins.ListItem}
              />
            </Stack>
            <Box width={columnWidths.rating}>
              {revenueOverTimeEnabled ? (
                <SummaryV2
                  total={mapRevenue(
                    insight.revenueValue,
                    insight.sales7D,
                    insight.sales30D,
                    statsPeriod,
                    revenueOverTimeEnabled
                  )}
                  numberChange7D={insight.numberChange7D}
                  percentageChange7D={insight.percentageChange7D}
                  numberChange30D={insight.numberChange30D}
                  percentageChange30D={insight.percentageChange30D}
                />
              ) : (
                <Rating {...insight} />
              )}
            </Box>
            <Box width={columnWidths.social} flex={1}>
              <SocialAndActions insight={insight} />
            </Box>
          </Stack>
        </Stack>
      </ListItem>
    </>
  );
};
