import {
  addWeeks,
  //endOfYear,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";

import { DefinedRange } from "../../components/DateRangePicker/types";

export const getDefaultDateRange = () => {
  const date = new Date();
  return {
    startDate: subDays(date, 90),
    endDate: date,
    label: "Last 90 Days",
  };
};

export const getDefaultCompareDateRange = () => {
  const currentDate = new Date();

  const endDateOfLastYear = subYears(currentDate, 1);

  const startDateOfCurrentYear = subDays(currentDate, 90);

  const startDateOfLastYear = subYears(startDateOfCurrentYear, 1);

  return {
    startDate: startDateOfLastYear,
    endDate: endDateOfLastYear,
    label: "Previous year",
  };
};

export const getDateRanges = (
  date: Date,
  options: any | null
): DefinedRange[] => {
  const defaults = [
    {
      label: "Last 7 Days",
      startDate: addWeeks(date, -1),
      endDate: date,
    },
    {
      label: "Last 30 Days",
      startDate: subDays(date, 30),
      endDate: date,
    },
    {
      label: "Last 90 Days",
      startDate: subDays(date, 90),
      endDate: date,
    },
    {
      label: "Year to date",
      startDate: startOfYear(date),
      endDate: date,
    },
  ];

  const definedRangeOptions = options?.map((option: any) => {
    return {
      label: option.label,
      startDate: option.startDate,
      endDate: option.endDate,
    };
  })

  return definedRangeOptions ? [...defaults, ...definedRangeOptions] : defaults;
};

export const getCompareDateRanges = (date: DefinedRange): DefinedRange[] => [
  {
    label: "Previous year",
    startDate: subYears(date.startDate, 1),
    endDate: subYears(date.endDate, 1),
  },
];
