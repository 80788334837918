export const convertNumberToShortFormat = (
  value: string | number,
  percentage: boolean = false
) => {
  const number = percentage ? 100 * Number(value) : Number(value);
  const thresholds = [
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  const threshold = thresholds.find((threshold) => number >= threshold.value || number <= -threshold.value);

  const formattedNumber = threshold
    ? (number / threshold.value).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })
    : number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });

  return `${formattedNumber}${threshold ? threshold.symbol : ""}${percentage ? "%" : ""}`;
};

export const toTitleCase = (str: string) =>
  str?.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );

export const formatChatTemplate = (template: string) => {
  switch (template) {
    case "executive_summary":
      return "Executive Summary";
    case "sales_report":
      return "Weekly Sales Report";
    case "keywords_opportunities":
      return "Keywords Opportunities";
    default:
      return template;
  }
};

export const getBaseLabel = (column: string, substr: string) =>
  column
    .replace(substr, "")
    .replace(substr.toLowerCase(), "")
    .replace(toTitleCase(substr), "")
    .trim();


export const parseJsonRecursive = (json: string) => {
  try {
    const parsed = JSON.parse(json);
    for (const key in parsed) {
      if (typeof parsed[key] === "string") {
        parsed[key] = parseJsonRecursive(parsed[key]);
      }
    }
    return parsed;
  } catch (error) {
    return json;
  }
}
