import {Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Stack, TextField,} from "@mui/material";

import {Controller, useForm} from "react-hook-form";
import {DataSource} from "../../types/datasources";
import {FC} from "react"

export type DataSourceEditFields = {
  name?: string;
  description?: string;
};

export const EditDataSourceDialogContent: FC<{
  dataSource: DataSource;
  onCancel: () => void;
  onSubmit: (props: DataSourceEditFields) => void;
}> = ({ onCancel, onSubmit, dataSource }) => {
  const {handleSubmit, control, formState} = useForm({
    mode: "onChange",
    defaultValues: {
      name: dataSource.name,
      description: dataSource.description,
    },
  });
  const {errors} = formState
  return (
    <>
      <DialogTitle>Edit Data Source</DialogTitle>
      <DialogContent sx={{ minWidth: (t) => t.breakpoints.values.sm }}>
        <form
          onSubmit={handleSubmit(async (a) => {
            await onSubmit(a);
          })}
        >
          <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1, mb: 1 }}
          >
            <Controller
              control={control}
              name="name"
              rules={{
                maxLength: { message: "50 chars limit exceeded", value: 50 },
                required: "This field is required",
              }}
              render={(props) => (
                <TextField
                  fullWidth
                  placeholder="Name*"
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  {...props}
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={(props) => (
                <TextField
                  fullWidth
                  placeholder="Description"
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  {...props}
                />
              )}
            />
          </Stack>
          <DialogActions>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                onClick={() => onCancel()}
                disabled={formState.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  formState.isSubmitting ||
                  !formState.isDirty ||
                  !formState.isValid
                }
              >
                {" "}
                {!formState.isSubmitting ? (
                  "Submit"
                ) : (
                  <CircularProgress
                    size={20}
                    sx={{ color: (t) => t.palette.background.paper }}
                  />
                )}
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </DialogContent>
    </>
  );
};
