import { Box, Typography } from "@mui/material";
import theme from "../../themes/light";
import { useAssortmentIntelligence } from "./AssortmentIntelligenceProvider";
import SpaceList from "./components/SpaceList";

const Header = () => {
  const { selectedSpace } = useAssortmentIntelligence();

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.borders.lines}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Typography
          variant="headline3"
          display="flex"
          sx={{ color: theme.palette.text.primary }}
        >
          Assortment Intelligence
        </Typography>
        {selectedSpace && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography variant="headline3">/</Typography>
            <SpaceList />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
