import {useParams} from "react-router-dom";
import Header, {HeaderActions, HeaderContent} from "../../components/Header";
import Actions from "./Actions";
import BoardTitle from "./BoardTitle";
import {FC} from "react"

const BoardHeader: FC = () => {
    const {boardId}: { boardId?: any } = useParams();
    return (
        <Header>
            <HeaderContent>
                <BoardTitle blockId={boardId}/>
      </HeaderContent>
      <HeaderActions>
        <Actions />
      </HeaderActions>
    </Header>
  );
};

export default BoardHeader;
