import { ConnectorType } from "../../../enums";
import { PipelineInput, RunInputValue } from "../../../types";

export function allInputsValid(
  pipelineInputs: PipelineInput[],
  formInputValues: Record<string, RunInputValue | null>
): boolean {
  const requiredOrFilledInputs = pipelineInputs.filter(
    (pipelineInput) =>
      pipelineInput.required ||
      (formInputValues && formInputValues[pipelineInput.name])
  );
  if (!requiredOrFilledInputs) {
    return true;
  }
  if (!formInputValues) {
    return false;
  }
  return requiredOrFilledInputs.every((pipelineInput) =>
    isInputValueValid(formInputValues[pipelineInput.name])
  );
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty
export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export function isInputValueValid(
  inputValue: RunInputValue | null,
  required?: boolean
): boolean {
  if (!inputValue && !required) {
    return true;
  }
  if (inputValue?.type === ConnectorType.AMAZON_PRODUCTS) {
    return !!Object.keys(inputValue?.configuration || {}).find(
      (k) => k === "filter_id"
    );
  }
  if (isEmpty(inputValue?.configuration)) {
    return false;
  }
  if (isEmpty(inputValue?.mapping)) {
    return false;
  }
  if (
    inputValue?.type !== ConnectorType.LOCAL &&
    inputValue?.type !== ConnectorType.GOOGLE_SHEETS &&
    !inputValue?.connector_id
  ) {
    return false;
  }
  return true;
}

export const validateJsonField = (value: string) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};
