import { useQuery, UseQueryOptions } from "react-query";
import { PublishedRun } from "../../../../types";
import { APIError } from "../../../../services/apiRequest";
import { getPublishedRun } from "../../../../services/runs/api";

export default function usePublishedRun(
  boardId?: string,
  options?: UseQueryOptions<PublishedRun, APIError>
) {
  return useQuery<PublishedRun, APIError>(
    ["publishedRun", boardId],
    () => getPublishedRun(boardId as string),
    { enabled: !!boardId, ...options }
  );
}
