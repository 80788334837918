import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { FC } from "react";
import { Run } from "../../../../types";

export const CreatedByUser: FC<{ run?: Run }> = ({ run }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    {run?.user_inf?.profilePictureUrl ? (
      <Avatar
        sx={{ width: "25px", height: "25px", mr: 2 }}
        src={run?.user_inf?.profilePictureUrl}
      >
        {run?.user_inf?.name?.charAt(0)}
      </Avatar>
    ) : (
      <Skeleton variant="circular" width={25} height={25} sx={{ mr: 2 }} />
    )}
    {run?.created_by_id ? (
      <Typography variant="body2">Run by {run?.user_inf?.name}</Typography>
    ) : (
      <Skeleton variant="text" width={210} height={25} />
    )}
  </Box>
);
