import { useAmplitude } from "react-amplitude-hooks";

export const useBoardEvents = (globalProperties: any = {}) => {
  const { logEvent } = useAmplitude((inheritedProps: any = {}) => ({
    ...inheritedProps,
    ...globalProperties,
    scope: [
      "boards",
      ...(globalProperties.scope || []),
      ...(inheritedProps.scope || []),
    ],
  }));
  return {
    deleteBoardEvent: (properties?: object) =>
      logEvent("board.delete", properties),
    applicationLaunchedEvent: (properties?: object) =>
      logEvent("board.application.launch", properties),
  };
};
