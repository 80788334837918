import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../../../services/apiRequest";

export type GetProductsResponse = {
  total_products: number;
  products_in_groups: number;
  number_of_groups: number;
};

const getProductsInGroups = (
  offset: number,
  limit: number
): Promise<GetProductsResponse> =>
  apiRequest<GetProductsResponse>(
    "v1/harmonization/products/groupCount",
    "GET",
    {
      offset,
      limit,
    }
  );
const useHarmonizedProductsInGroup = (
  offset: number,
  limit: number,
  options?: UseQueryOptions<GetProductsResponse, APIError>
): UseQueryResult<GetProductsResponse, APIError> =>
  useQuery<GetProductsResponse, APIError>(
    ["ProductsInGroups", offset, limit],
    () => getProductsInGroups(offset, limit),
    options
  );

export default useHarmonizedProductsInGroup;
