import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Chip,
  Drawer,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useQuery } from "react-query";
import { APIError } from "../../../../services/apiRequest";
import { getMessages } from "../../../../services/tracking";
import { BoardAndRunIds, MessageRead } from "../../../../types";
import ErrorMessageBox from "./ErrorMessageBox";

const RunMessages: FC<BoardAndRunIds> = ({ runId }) => {
  const { data, isLoading } = useQuery<MessageRead[], APIError>(
    ["messages", runId || ""],
    () => getMessages(runId || ""),
    { enabled: true }
  );

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const toggleDrawer = () => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(false);
  };

  const messages = data?.reduce(
    (acc, item) => {
      if (item.severity === "error") {
        acc.errorCount += 1;
        acc.errorMessage = item.message;
      } else if (item.severity === "warning") {
        acc.warningCount += 1;
        acc.warningMessage = item.message;
      } else {
        acc.summaryMessage = item.message;
      }
      return acc;
    },
    {
      errorCount: 0,
      warningCount: 0,
      errorMessage: "",
      warningMessage: "",
      summaryMessage: "",
    }
  );

  return (
    <>
      {messages?.summaryMessage && (
        <Paper
          sx={{
            height: 400,
            backgroundColor: "white",
            margin: 3,
            p: 2,
            boxShadow: 1,
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            Summary
          </Typography>
          <Paper
            elevation={0}
            sx={{
              maxHeight: (t) => t.spacing(300 / 8),
              p: 2,
              overflowY: "hidden",
            }}
          >
            <Typography variant="body1" flexDirection="column" display="flex">
              {isLoading ? (
                <Skeleton variant="rectangular" />
              ) : (
                <ReactMarkdown>{messages?.summaryMessage}</ReactMarkdown>
              )}
            </Typography>
          </Paper>
          <Button
            variant="outlined"
            sx={{
              mt: 1,
              textTransform: "none",
              color: "primary.dark",
              border: "solid 1px",
              borderColor: "grey.300",
            }}
            onClick={() => setOpenDrawer(true)}
          >
            Read more
          </Button>
        </Paper>
      )}

      {messages?.errorMessage && (
        <ErrorMessageBox
          type="Error"
          message={messages?.errorMessage}
          count={messages?.errorCount}
        />
      )}

      {messages?.warningMessage && (
        <ErrorMessageBox
          type="Warning"
          message={messages?.warningMessage}
          count={messages?.warningCount}
        />
      )}

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer()}
        PaperProps={{ sx: { width: 520 } }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Box margin={2}>
            <Stack direction="row">
              <Chip
                size="small"
                variant="outlined"
                label="esc"
                sx={{ borderRadius: 1, boxShadow: 1 }}
              />
              <Typography ml={1} mt={0.2}>
                to close
              </Typography>
            </Stack>
          </Box>
          <IconButton
            aria-label="close"
            size="small"
            sx={{ margin: 2 }}
            onClick={() => setOpenDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography
          variant="body1"
          flexDirection="column"
          display="flex"
          margin={3}
        >
          <ReactMarkdown>{messages?.summaryMessage || ""}</ReactMarkdown>
        </Typography>
      </Drawer>
    </>
  );
};
export default RunMessages;
