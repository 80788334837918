import { FC } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../themes/light";

interface CatalogEnrichmentHeaderProps {
  handleBack?: () => void;
  handleSave?: () => void;
}

const CatalogEnrichmentHeader: FC<CatalogEnrichmentHeaderProps> = () => {
  //   const { handleBack, handleSave } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "1rem",
        padding: "0 2rem",
        height: "4rem",
        borderBottom: `1px solid ${theme.palette.borders.lines}`,
        background: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {/* {handleBack && <BackButton onClick={handleBack} />} */}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {/* {flowRunName || "Catalog Enrichment"} */}
          Catalog Enrichment
        </Typography>
      </Box>
      {/* {handleSave && <SaveButton onClick={handleSave} />} */}
    </Box>
  );
};

export default CatalogEnrichmentHeader;
