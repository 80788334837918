import ArchiveIcon from "@mui/icons-material/Archive";
import {CircularProgress, ListItemIcon, MenuItem} from "@mui/material";

import {AuthorizedContent} from "../../../components/AuthorizedContent";
import {PlatformPermission} from "../../../enums";
import {useRun} from "../../../services/runs";
import useArchive from "../../../services/runs/useArchive";
import {RunMenuItemProps} from "./types";
import UnarchiveMenuItem from "./UnarchiveMenuItem";
import {FC} from "react"

const ArchiveMenuItem: FC<RunMenuItemProps> = (props) => {
    const {data: run} = useRun(props.boardId, props.runId);
    const isArchived = !!run?.archived;
    const mutation = useArchive(
        props.boardId,
        props.runId,
        run && `You are about to archive run "${run!.name}"`
    );
    return isArchived ? (
        <UnarchiveMenuItem {...props} />
    ) : (
        // @ts-ignore
        <AuthorizedContent
            requiredPermissions={[PlatformPermission.RUNS_CREATE]}
            render={(isAuthz) => (
        <MenuItem
          disabled={!isAuthz}
          onClick={async () => {
            await mutation.mutateAsync();
            props.onClick();
          }}
        >
          <ListItemIcon>
            {mutation.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <ArchiveIcon />
            )}
          </ListItemIcon>
          Archive
        </MenuItem>
      )}
    />
  );
};

export default ArchiveMenuItem;
