import { FC } from "react";
import { Features } from "../../types";
import { FeatureField } from "../types";
import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFeaturesExtraction } from "../FeaturesExtractionProvider";
import { AttributeTextFieldRenderer } from "../AttributeTextFieldRenderer";

interface FeatureFieldFormatterProps {
  field: FeatureField;
  item: Features;
  index: number;
}

const FeatureFieldFormatter: FC<FeatureFieldFormatterProps> = ({
  field,
  item,
  index,
}) => {
  const { currentAttributes, setCurrentAttributes } = useFeaturesExtraction();
  if (!currentAttributes) {
    return null;
  }

  //@ts-ignore
  const currentValue = item[field];

  switch (field) {
    case FeatureField.Attribute:
      return (
        <AttributeTextFieldRenderer
          rowIndex={index}
          field="attribute"
          type={item.type}
          value={currentValue as string}
          setValue={(value: string) => {
            const newAttributes = [...currentAttributes];
            newAttributes[index].attribute = value
              ? value.trim()
              : (currentValue as string);
            setCurrentAttributes(newAttributes);
          }}
        />
      );

    case FeatureField.Type:
      return (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "start",
            height: "100%",
            padding: "10px",
          }}
        >
          <Select
            value={currentValue}
            onChange={(e) => {
              const newAttributes = [...currentAttributes];
              newAttributes[index].type = e.target.value as
                | "string"
                | "int"
                | "float"
                | "boolean";
              if (e.target.value === "boolean") {
                newAttributes[index].closed_list = true;
                newAttributes[index].accepted_values = ["True", "False"];
              } else {
                newAttributes[index].closed_list = false;
                newAttributes[index].accepted_values = [];
              }
              setCurrentAttributes(newAttributes);
            }}
            sx={{
              width: "120px",
              "& .MuiStack-root": {},
              "& .MuiSelect-select": {
                padding: "15px",
                fontSize: "14px",
                fontWeight: 500,
              },
            }}
          >
            <MenuItem value="string">STRING</MenuItem>
            <MenuItem value="int">INTEGER</MenuItem>
            <MenuItem value="float">FLOAT</MenuItem>
            <MenuItem value="boolean">BOOLEAN</MenuItem>
          </Select>
        </Stack>
      );
    case FeatureField.Description:
      return (
        <AttributeTextFieldRenderer
          rowIndex={index}
          field="description"
          type={item.type}
          value={currentValue as string}
          setValue={(value: string) => {
            const newAttributes = [...currentAttributes];
            newAttributes[index].description = value
              ? value.trim()
              : (currentValue as string);
            setCurrentAttributes(newAttributes);
          }}
        />
      );
    case FeatureField.ClosedList:
      return (
        <Tooltip
          title={
            currentAttributes[index].type === "boolean"
              ? "Boolean type attributes are always closed list"
              : ""
          }
          arrow
          placement="top"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              height: "100%",
              width: "100%",
              padding: "10px",
            }}
          >
            <Checkbox
              sx={{
                "&.Mui-disabled": {
                  opacity: 0.35,
                },
              }}
              value={currentValue}
              disabled={currentAttributes[index].type === "boolean"}
              checked={currentValue as boolean}
              onChange={(e) => {
                const newAttributes = [...currentAttributes];
                newAttributes[index].closed_list = e.target.checked;
                if (!e.target.checked) {
                  newAttributes[index].accepted_values = [];
                } else {
                  setTimeout(
                    () =>
                      document
                        .getElementById(
                          `attribute-text-accepted_values-${index}`
                        )
                        ?.click(),
                    0
                  );
                }
                setCurrentAttributes(newAttributes);
              }}
            />
          </Box>
        </Tooltip>
      );
    case FeatureField.AcceptedValues:
      return (
        <AttributeTextFieldRenderer
          rowIndex={index}
          field="accepted_values"
          type={item.type}
          value={currentValue as string[] | number[]}
          setValue={(value: string[] | number[]) => {
            const newAttributes = [...currentAttributes];
            newAttributes[index].accepted_values = value;
            setCurrentAttributes(newAttributes);
          }}
          disable={
            currentAttributes[index].type === "boolean" ||
            !currentAttributes[index].closed_list
          }
        />
      );

    default:
      return (
        <Typography>
          {
            //@ts-ignore
            currentValue
          }
        </Typography>
      );
  }
};

export default FeatureFieldFormatter;
