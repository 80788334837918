import {FC, useEffect, useRef} from "react";
import {ThemeProvider} from "@mui/material";
import {useRouteMatch} from "react-router";
import {useHistory} from "react-router-dom";
import theme from "../../themev2";
import {useBoards} from "../../services/boards";
import {ApplicationType} from "../Applications/LeftNavbar/types";

const BreakoutTrends: FC<object> = () => {
  const history = useHistory();

  const match = useRouteMatch<{ boardId: string }>(
      `/${ApplicationType.TRENDSPOTTING}/:boardId`
  );
  const iFrameRef = useRef<any>(null);

  const {data = []} = useBoards(undefined, {
    refetchOnWindowFocus: false,
    select: (boards) =>
      boards.filter(
        (b) => b.template_id === "breakout-trends" && b.published_run_id
      ),
  });

  const block = data.find((b) => b.identifier === match?.params.boardId);

  useEffect(() => {
    if (block?.published_run_id) {
      // reload the page, using reload for iframe caused issue
      // eslint-disable-next-line no-self-assign
      iFrameRef.current.src = iFrameRef.current.src;
    }
    if (block && !block.published_run_id) {
      history.push("/");
    }
  }, [block]);

  useEffect(() => {
    if (!match && data[0]) {
      history.push(`/${ApplicationType.TRENDSPOTTING}/${data[0].identifier}`);
    }
  }, [match, data]);

  return (
    <ThemeProvider theme={theme}>
      {block?.published_run_id && (
        <iframe
          ref={iFrameRef}
          title={block.identifier}
          src={`/apps/${block.identifier}/dashboard/#?runId=${block.published_run_id}`}
          frameBorder="0"
          width="100%"
          height="100%"
          style={{background: 'transparent'}}
          // allowTransparency
        />
      )}
    </ThemeProvider>
  );
};
export default BreakoutTrends;
