import { useHistory } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";

export const SalesInsightsNavItem: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  const history = useHistory();
  const isGroup = useSplitEnabled(Split.SALES_ASSISTANT_GROUPS);
  const isV2 = useSplitEnabled(Split.SALES_ASSISTANT_V2)
  // const label = true ? "Sales Feed" : "Sales Assistant"
  const label = "Sales Feed";
  const tabName = isV2 ? "sales-assistant-v2" : "sales-assistant"
  return (
    <LeftNavBarItem
      selected={tab === tabName}
      icon={<SVG src={"/sales/insights-new.svg"} width={20} height={20} />}
      // onClick={() => history.push(url)}
      onClick={() => {
        history.push(
          isGroup ? `/${tabName}/home` : `/${tabName}/products`
        );
        setTab(tabName);
      }}
      label={label}
    />
  );
};
