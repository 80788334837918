import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import {
  addStoryPointToStoryboardApi,
  getStoryboardApi,
  useChatSessionsList,
  updateStoryboardApi,
  deleteStoryPointApi,
  updateStoryPointApi,
  deleteStoryboardApi,
  syncStoryboardApi,
  copyStoryboardApi,
  exportStoryPointApi,
  getChatQuestionList,
} from "./useChatApi";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";
import { useSpacesQuery } from "../SpacesV2/useSpaces";
import { Space } from "../SpacesV2/types";
import { formatChatTemplate } from "./utils";

const MY_CATALOG = { id: "", name: "My Catalog" };
const LOADER_STORY_POINT = { id: v4(), isLoader: true };

export const InsightsChatContext = createContext<any>({});
export const InsightChatProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const snackbar = useSnackbar();

  const [digitalShelfName, setDigitalShelfName] = useState<string>("");
  const [digitalShelfShortId, setDigitalShelfShortId] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [toggle, setToggle] = useState(true);

  // Graph
  const [graphData, setGraphData] = useState<any>({});
  const graphDataRef = useRef<any>({});
  const sessionPendingRef = useRef<boolean>(false);

  // Space
  const [spaceId, setSpaceId] = useState<string>("");
  const [selectedSpace, setSelectedSpace] = useState<Space | any>();

  // Spaces list
  const {
    data: fetchedSpacesList,
    isLoading: spacesListLoading,
    isFetching: spacesListFetching,
    error: spacesListError,
    refetch: spacesListRefetch,
  } = useSpacesQuery();

  // Spaces list with My Catalog
  const [spacesList, setSpacesList] = useState([
    MY_CATALOG,
    ...(fetchedSpacesList || []),
  ]);
  useEffect(() => {
    if (fetchedSpacesList) {
      setSpacesList([MY_CATALOG, ...fetchedSpacesList]);
    }
  }, [fetchedSpacesList]);

  useEffect(() => {
    if (Array.isArray(spacesList)) {
      if (spaceId) {
        const selectedSpace = spacesList.find((space) => space.id === spaceId);
        if (selectedSpace) {
          setSelectedSpace({ ...selectedSpace });
        } else {
          setSelectedSpace(MY_CATALOG);
        }
      } else {
        setSelectedSpace(MY_CATALOG);
      }
    }
  }, [spacesList?.length, spaceId]);

  // Story boards list
  const {
    data: fetchedStoryboardList = [],
    refetch: refetchStoryboardList,
    isFetching: storyboardListPending,
  } = useChatSessionsList();
  const [newStoryboardToListPending, setNewStoryboardToListPending] =
    useState(false);
  const [storyboardList, setStoryboardList] = useState<any[]>(
    fetchedStoryboardList
  );
  useEffect(() => {
    if (fetchedStoryboardList) {
      setStoryboardList(fetchedStoryboardList);
    }
  }, [fetchedStoryboardList]);

  // storyboardId
  const location = useLocation();
  const storyboardId = location.search.startsWith("?")
    ? location.search.slice(1)
    : "";

  useEffect(() => {
    if (
      storyboardId && // Fetch only if storyboardId is present
      graphData?.id != storyboardId // If different session (e.g. on copy/select)
    ) {
      fetchSessionData(storyboardId); // Fetch storyboard data, spaceId, chatTemplate etc.
    }
  }, [storyboardId]);

  // Questions list
  const [chatTemplate, setChatTemplate] = useState<string>("");
  const [questionList, setQuestionList] = useState<any>([]);
  const [questionListFetching, setQuestionListFetching] = useState(false);

  const handleChatTemplateSelection = (template: string) => {
    if (chatTemplate === template || questionListFetching) return;
    setQuestionListFetching(true);
    setChatTemplate(template);
  };

  const fetchQuestionList = async (tags: string[]) => {
    return await getChatQuestionList({
      tags,
      space_id: selectedSpace?.id,
    })
      .then((res: any) => {
        return res;
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to fetch questions", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (chatTemplate) {
      fetchQuestionList([chatTemplate])
        .then((res: any) => setQuestionList(res))
        .finally(() => setQuestionListFetching(false));
    } else {
      setQuestionList([]);
    }
  }, [chatTemplate]);

  // Session functions
  const handleBack = async () => {
    setChatTemplate("");
    emptyCurrentSession();
    history.push(`/chat?`);
    sessionPendingRef.current = false;
    setNewStoryboardToListPending(false);
    await refetchStoryboardList();
  };

  const setSession = (id: string) => {
    if (storyboardId === id) {
      return;
    }
    history.push(`/chat?${id}`);
  };

  // fetch => setGraph
  const fetchSessionData = async (id: string) => {
    setLoaderInGraphData(-1); // -1 means clear graphData and show only loader
    return await getStoryboardApi(id)
      .then(async (res: any) => {
        updateSessionData(res);
        await refetchStoryboardList();
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to fetch story board", {
          variant: "error",
        });
        removeLoaderFromGraphData();
        handleBack();
      });
  };

  // setGraph
  const updateSessionData = async (
    newGraphData: any,
    animate: boolean = false
  ) => {
    if (!graphDataRef.current) {
      graphDataRef.current = newGraphData;
      setGraphData(graphDataRef.current);
      return;
    }

    const previousStoryPoints = graphDataRef.current.story_points;

    const updatedStoryPoints = newGraphData.story_points
      .sort((a: any, b: any) => a.order - b.order) // Sort by order
      .filter((storyPoint: any) => !storyPoint.isLoader) // Remove loaders
      .map((storyPoint: any) => {
        // Animation mapping
        const previousStoryPoint = previousStoryPoints?.find(
          (prevStoryPoint: any) => prevStoryPoint.id === storyPoint.id
        );
        if (
          storyPoint.type !== "simple-text" &&
          (!previousStoryPoint || animate)
        ) {
          return { ...storyPoint, showAnimation: true };
        }
        return storyPoint;
      });

    if (updatedStoryPoints.length) {
      setSpaceId(searchSpaceId(updatedStoryPoints));
      setChatTemplate(searchChatTemplate(updatedStoryPoints));
    }

    graphDataRef.current = {
      ...newGraphData,
      story_points: updatedStoryPoints,
    };
    setGraphData(graphDataRef.current);
  };

  const searchChatTemplate = (storyPoints: any[]) => {
    return (
      storyPoints.find(
        (storyPoint) => storyPoint.tags && storyPoint.tags.length
      )?.tags?.[0] || ""
    );
  };

  const searchSpaceId = (storyPoints: any[]) => {
    return storyPoints.find((storyPoint) => storyPoint.space_id)?.space_id || "";
  }

  // Storyboard functions
  const setStoryPoint = async (storyPoint: any) => {
    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: graphDataRef.current.story_points.map((sp: any) =>
        sp.id === storyPoint.id ? { ...storyPoint } : sp
      ),
    };
    setGraphData(graphDataRef.current);
  };

  const deleteStoryPoint = async (storyID: string) => {
    sessionPendingRef.current = true;

    // UI Optimization
    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: graphDataRef.current.story_points?.map(
        (storyPoint: any) => {
          if (storyPoint.id === storyID) {
            return { ...storyPoint, isDeleting: true };
          }
          return storyPoint;
        }
      ),
    };
    setGraphData(graphDataRef.current);

    // API call
    setTimeout(
      async () =>
        await deleteStoryPointApi(storyID)
          .then((res: any) => {
            if (sessionPendingRef.current) updateSessionData(res);
          })
          .then(async () => {
            if (graphDataRef.current.story_points?.length === 0) {
              // Delete storyboard if no story points left
              await deleteStoryboard(graphDataRef.current.id);
            }
          })
          .catch(() => {
            if (sessionPendingRef.current) {
              snackbar.enqueueSnackbar("Failed to delete story point", {
                variant: "error",
              });

              // Rollback UI changes
              graphDataRef.current = {
                ...graphDataRef.current,
                story_points: graphDataRef.current.story_points?.map(
                  (storyPoint: any) => {
                    if (storyPoint.id === storyID) {
                      return { ...storyPoint, isDeleting: false };
                    }
                    return storyPoint;
                  }
                ),
              };

              setGraphData(graphDataRef.current);
            }
          })
          .finally(() => {
            sessionPendingRef.current = false;
          }),
      300
    );
  };

  const setLoaderInGraphData = (index: number | null) => {
    const storyPoints = graphDataRef.current?.story_points;
    const storyPointsWithLoader =
      !storyPoints || index === -1
        ? // Show only loader
          [LOADER_STORY_POINT]
        : index !== null
          ? // Add loader at specific index
            [
              ...storyPoints.slice(0, index),
              LOADER_STORY_POINT,
              ...storyPoints.slice(index),
            ]
          : // Add loader at the end
            [...storyPoints, LOADER_STORY_POINT];

    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: storyPointsWithLoader,
    };
    setGraphData(graphDataRef.current);
  };

  const removeLoaderFromGraphData = (index: number | null = null) => {
    const updatedArray = !index
      ? // Remove all loaders
        graphDataRef.current?.story_points?.filter(
          (storyPoint: any) => !storyPoint.isLoader
        )
      : // Remove specific loader
        graphDataRef.current?.story_points?.filter(
          (_storyPoint: any, i: number) => i !== index
        );

    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: updatedArray,
    };
    setGraphData(graphDataRef.current);
  };

  const deleteStoryboard = async (sId: string) => {
    sessionPendingRef.current = true;

    // UI Optimization
    if (sId === storyboardId) {
      handleBack();
    }
    setStoryboardList(
      storyboardList?.filter((storyboard) => storyboard.id !== sId)
    );

    return await deleteStoryboardApi(sId)
      .then(async (res: any) => {
        if (sessionPendingRef.current) {
          if (res?.id !== storyboardId) {
            await refetchStoryboardList();
          }
        }
      })
      .catch(async () => {
        if (sessionPendingRef.current) {
          snackbar.enqueueSnackbar("Failed to delete story point", {
            variant: "error",
          });

          // Rollback UI changes
          await refetchStoryboardList();
        }
      })
      .finally(() => {
        sessionPendingRef.current = false;
      });
  };

  const addStoryPointToStoryboard = async (
    storyPointType: string,
    settingsParams: any = [],
    index: number | null
  ) => {
    sessionPendingRef.current = true;

    // UI Optimization
    storyPointType !== "simple-text" && setLoaderInGraphData(index);
    if (!storyboardId) setNewStoryboardToListPending(true);

    // Add space_id to settingsParams
    const paramsWithSpaceId = settingsParams.find(
      (param: any) => param.id === "space_id"
    )
      ? settingsParams
      : [...settingsParams, { id: "space_id", value: selectedSpace?.id }];

    return await addStoryPointToStoryboardApi(
      storyPointType,
      storyboardId,
      paramsWithSpaceId,
      !storyboardId
        ? `${selectedSpace?.name} ${formatChatTemplate(chatTemplate)}`
        : undefined,
      index
    )
      .then(async (res: any) => {
        if (sessionPendingRef.current) {
          if (!storyboardId) {
            // New storyboard
            history.push(`/chat?${res.id}`); // Without fetching (prevStoryboardId = null)
            updateSessionData(res, true);

            // UI Optimization
            setStoryboardList([res, ...storyboardList]);
            setNewStoryboardToListPending(false);

            await refetchStoryboardList();
          } else {
            // Existing storyboard
            updateSessionData(res);
          }
        }
      })
      .catch((e) => {
        if (sessionPendingRef.current) {
          if (e.message.includes("There is no data for this story point")) {
            snackbar.enqueueSnackbar("The question returned no data", {
              variant: "warning",
            });
          } else {
            snackbar.enqueueSnackbar("Unable to create new story board", {
              variant: "error",
            });
          }
        }
      })
      .finally(() => {
        if (sessionPendingRef.current) {
          removeLoaderFromGraphData();
          setNewStoryboardToListPending(false);
          sessionPendingRef.current = false;
        }
      });
  };

  const updateStoryPoint = async (storyPointId: string, body: any) => {
    sessionPendingRef.current = true;

    return await updateStoryPointApi(storyPointId, body)
      .then((res: any) => {
        if (sessionPendingRef.current) setStoryPoint(res);
      })
      .catch(() => {
        if (sessionPendingRef.current) {
          snackbar.enqueueSnackbar("Failed to update story point", {
            variant: "error",
          });
        }
      })
      .finally(() => {
        sessionPendingRef.current = false;
      });
  };

  const exportStoryPoint = async (storyPointId: string) => {
    sessionPendingRef.current = true;

    return await exportStoryPointApi(storyPointId)
      .then((res: any) => {
        if (sessionPendingRef.current) {
          snackbar.enqueueSnackbar("Story point exported successfully", {
            variant: "success",
          });
          setStoryPoint(res);
          return res;
        }
      })
      .catch(() => {
        if (sessionPendingRef.current) {
          snackbar.enqueueSnackbar("Failed to export story point", {
            variant: "error",
          });
        }
      })
      .finally(() => {
        sessionPendingRef.current = false;
      });
  };

  const updateStoryboard = async (storyboardId: string, body: any) => {
    sessionPendingRef.current = true;

    return await updateStoryboardApi(body, storyboardId)
      .then(async (res: any) => {
        if (sessionPendingRef.current) {
          updateSessionData(res);
          await refetchStoryboardList();
        }
      })
      .catch(() => {
        if (sessionPendingRef.current) {
          snackbar.enqueueSnackbar("Failed to update story point", {
            variant: "error",
          });
        }
      })
      .finally(() => {
        sessionPendingRef.current = false;
      });
  };

  const emptyCurrentSession = async () => {
    sessionPendingRef.current = false;
    graphDataRef.current = {};
    setGraphData(graphDataRef.current);
  };

  const syncStoryboard = async (storyboardId: string) => {
    sessionPendingRef.current = true;

    return await syncStoryboardApi(storyboardId)
      .then((res: any) => {
        if (sessionPendingRef.current) {
          updateSessionData(res, true);
        }
      })
      .catch(() => {
        if (sessionPendingRef.current) {
          snackbar.enqueueSnackbar("Failed to sync story board", {
            variant: "error",
          });
          throw new Error("Failed to sync story board");
        }
      })
      .finally(() => {
        sessionPendingRef.current = false;
      });
  };

  const copyStoryboard = async (storyboardId: string) => {
    sessionPendingRef.current = true;

    await copyStoryboardApi(storyboardId)
      .then((res: any) => {
        if (sessionPendingRef.current) {
          setSession(res.id);
        }
      })
      .catch(() => {
        if (sessionPendingRef.current) {
          snackbar.enqueueSnackbar("Failed to copy story board", {
            variant: "error",
          });
          throw new Error("Failed to copy story board");
        }
      })
      .finally(() => {
        sessionPendingRef.current = false;
      });
  };

  const state = {
    digitalShelfName,
    setDigitalShelfName,
    digitalShelfShortId,
    setDigitalShelfShortId,
    loading,
    chatTemplate,
    setChatTemplate,
    questionList,
    questionListFetching,
    fetchQuestionList,
    handleChatTemplateSelection,
    graphData: graphDataRef.current,
    setGraphData,
    storyboardList,
    storyboardListPending,
    newStoryboardToListPending,
    setLoading,
    spaceId,
    setSpaceId,
    spacesList,
    spacesListLoading,
    spacesListFetching,
    spacesListError,
    spacesListRefetch,
    selectedSpace,
    setSelectedSpace,
    toggle,
    setToggle,
    deleteStoryPoint,
    addStoryPointToStoryboard,
    handleBack,
    updateStoryPoint,
    exportStoryPoint,
    updateStoryboard,
    deleteStoryboard,
    setSession,
    storyboardId,
    syncStoryboard,
    copyStoryboard,
  };
  return (
    <InsightsChatContext.Provider value={{ ...state }}>
      {children}
    </InsightsChatContext.Provider>
  );
};
