import { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Icon } from "../../../components/Icon";
import { useSalesContext } from "../hooks/selectors";
import { useTenantsState } from "@frontegg/react-hooks/auth";

const RevenueSortDropdown = () => {
  const { sort, setSort, setValue, selectedValue, sortDays, setSortDays } =
    useSalesContext();
  const { activeTenant } = useTenantsState();
  const [isClose, setIsClose] = useState<boolean>(true);
  const theme = useTheme();
  const handleSort = (val: string) => {
    setValue(val);
  };

  const handleSortFilter = (key: string, value: string | boolean) => {
    const data = {
      ...sort,
      [key]: value,
      isDirty: true,
      days: sortDays,
    };
    const sortFilterData = {
      data: data,
      tenentID: activeTenant?.id,
    };
    setSort(data);
    localStorage.setItem("sortFilter", JSON.stringify(sortFilterData));
  };

  const handleSortDays = (value: string) => {
    setSortDays(value);
    const sortDaysValue = {
      value: value,
      tenentID: activeTenant?.id,
    };
    localStorage.setItem("sortDays", JSON.stringify(sortDaysValue));
  };

  return (
    <FormControl
      variant="standard"
      sx={{
        m: 1,
        minWidth: 286,
        flexDirection: "row",
        alignItems: "center",
        gap: "16px",
        "& .MuiInputBase-formControl": {
          "&:focus": {
            backgroundColor: "transparent !important",
          },
        },
        "& .MuiInput-input": {
          "&:focus": {
            backgroundColor: "transparent !important",
          },
        },
      }}
    >
      <Select
        size="small"
        onOpen={() => {
          setIsClose((old) => !old);
        }}
        onClose={() => {
          setIsClose((old) => !old);
        }}
        IconComponent={() => (
          <Box
            sx={{
              position: "absolute",
              right: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isClose ? (
              <Icon src="sales/drop-down.svg" width="16px" height="16px" />
            ) : (
              <Icon src="sales/drop-up.svg" width="16px" height="16px" />
            )}
          </Box>
        )}
        renderValue={() => (
          <Stack
            direction="row"
            gap="4px"
            alignItems="center"
            justifyContent="end"
            minWidth="248px"
          >
            <Typography
              variant="body4"
              color={
                isClose ? theme.palette.text.secondary : theme.palette.text.link
              }
            >
              Sort by:
            </Typography>
            <Typography
              variant="body2"
              fontWeight={600}
              color={
                isClose ? theme.palette.text.primary : theme.palette.text.link
              }
            >
              {sort.column === "percentage"
                ? "Change %"
                : sort.column === "change"
                ? "Change $"
                : "Sales"}
            </Typography>
          </Stack>
        )}
        sx={{
          minWidth: 248,
          "& .MuiInputBase-formControl": {
            "&:focus": {
              backgroundColor: "transparent !important",
            },
          },
        }}
        value={selectedValue}
        onChange={(e) => handleSort(e.target.value)}
      >
        <ListSubheader sx={{ padding: 0, minWidth: 166 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ padding: "8px 8px 8px 16px" }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              color={theme.palette.secondary.main}
            >
              Sort by
            </Typography>
          </Stack>
        </ListSubheader>
        <MenuItem
          dense
          value="percentage"
          onClick={() => handleSortFilter("column", "percentage")}
        >
          <Typography variant="body3">Change %</Typography>
        </MenuItem>
        <MenuItem
          dense
          value="change"
          onClick={() => handleSortFilter("column", "change")}
        >
          <Typography variant="body3">Change $</Typography>
        </MenuItem>
        <MenuItem
          dense
          value="sales"
          onClick={() => handleSortFilter("column", "sales")}
        >
          <Typography variant="body3">Sales</Typography>
        </MenuItem>
      </Select>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        size="small"
        sx={{
          height: "31px",
          "& .MuiButtonGroup-grouped": {
            minWidth: "43px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 8px",
            height: "31px",
            minHeight: "31px",
          },
        }}
      >
        <Button
          variant="outlined"
          onClick={() => handleSortFilter("ascending", !sort.ascending)}
          sx={{
            background: "unset",
            "&:hover": {
              background: "unset",
              border: "none"
            },
            "&[disabled]": {
              background: theme.palette.backgrounds.default,
            },
            "> svg": {
              color: "#2C224C",
            },
            "&[disabled] > svg": {
              color: theme.palette.icons.default,
            },
            border: "none"
          }}
          >
            <Icon
              src={`insights/sort-${
                sort?.ascending ? "descending" : "ascending"
              }.svg`}
              width="16px"
              height="16px"
            />
        </Button>
      </ButtonGroup>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        size="small"
        sx={{
          height: "31px",
          "& .MuiButtonGroup-grouped": {
            minWidth: "43px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 8px",
            height: "31px",
            minHeight: "31px",
          },
        }}
      >
        <Button
          onClick={() => (sortDays === "7d" ? {} : handleSortDays("7d"))}
          sx={{
            background: sortDays === "7d" ? "#2C224C" : theme.palette.backgrounds.white,
            cursor:  sortDays === "7d" ? "default" : "pointer",
            border: sortDays === "7d" ? "1px solid #2C224C !important" : "1px solid #D7D5DB !important",
            // opacity: active7d ? 0.5 : 1,
            "&[disabled]": {
              background: theme.palette.backgrounds.default,
            },
            "&:hover": {
              border: "1px solid #2C224C !important",
              background: sortDays === "7d" ? "#2C224C" : theme.palette.backgrounds.white,
            },
            fontSize: "13px",
            lineHeight: "24px",
            color: sortDays === "7d" ? "#FFFFFF" : theme.palette.secondary.main
          }}>
            7d
        </Button>
        <Button
          onClick={() => (sortDays === "28d" ? {} : handleSortDays("28d"))}
          sx={{
            background: sortDays === "28d" ? "#2C224C" : theme.palette.backgrounds.white,
            cursor: sortDays === "28d" ? "default" : "pointer",
            border: sortDays === "28d" ? "1px solid #2C224C !important" : "1px solid #D7D5DB !important",
            // opacity: active28d ? 0.5 : 1,
            "&:hover": {
              border: "1px solid #2C224C !important",
              background: sortDays === "28d" ? "#2C224C" : theme.palette.backgrounds.white,
            },
            "&[disabled]": {
              background: theme.palette.backgrounds.default,
            },
            fontSize: "13px",
            lineHeight: "24px",
            color: sortDays === "28d" ? "#FFFFFF" : theme.palette.secondary.main
          }}
        >
          28d
        </Button>
      </ButtonGroup>
    </FormControl>
  );
};

export default RevenueSortDropdown;
