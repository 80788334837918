import { useQuery } from "react-query";
import { useMemo } from "react";
import { useAuthUser } from "@frontegg/react";
import { FrontEggRole, FrontEggTeamMember, MemberWithRoles } from "../types";
import apiRequest from "../services/apiRequest";

export function useUsers() {
  return useQuery<FrontEggTeamMember[]>(
    ["useUsers"],
    () =>
      apiRequest<{ items: FrontEggTeamMember[] }>(
        "frontegg/team/resources/members/v1?pageOffset=0&pageSize=100"
      )
        .then((t) => t.items)
        .catch(() => Promise.reject(Error("Failed to fetch users."))),
    { staleTime: 120000 }
  );
}

export function useRoles() {
  return useQuery<FrontEggRole[]>(
    ["useRoles"],
    () =>
      apiRequest<{ items: FrontEggRole[] }>("frontegg/team/resources/roles/v1?")
        .then((t) => t.items)
        .catch(() => Promise.reject(Error("Failed to fetch roles."))),
    { staleTime: 120000 }
  );
}

const useUsersWithRoles = (): MemberWithRoles[] => {
  const { data: users = [] } = useUsers();
  const { data: roles = [] } = useRoles();

  const findRole = (roleId: string): FrontEggRole =>
    roles.find(({ id }) => id === roleId) as FrontEggRole;

  return useMemo(() => {
    if (!users.length || !roles.length) {
      return [];
    }
    return users.map((user) => ({
      ...user,
      roles: user.roleIds.map(findRole).filter((me) => me),
    }));
  }, [users, roles]);
};

export const useCurrentUser = (): MemberWithRoles => {
  // frontegg library is not up-to-date with the response with get from their api. this hook is  a type override.
  const currentUser = useAuthUser();
  return currentUser as unknown as MemberWithRoles;
};

const adminRoles: string[] = ["Noogata Super User", "Noogata Administrator"];

export const useAuthorizedUsers = (): MemberWithRoles[] => {
  // filter users to display only team users (admin can see admins).
  const currentUser = useCurrentUser();
  const members = useUsersWithRoles();
  return useMemo(() => {
    if (!members.length || !currentUser) {
      return [];
    }

    const isUserAdmin = (user: MemberWithRoles) =>
      adminRoles.some((adminRole) =>
        user.roles!.map(({ name }) => name).includes(adminRole)
      );

    const isCurrentUserAdmin = isUserAdmin(currentUser);

    const filteredUsers = members.filter(
      (user) =>
        user.id !== currentUser.id && (isCurrentUserAdmin || !isUserAdmin(user))
    );
    return [
      currentUser,
      ...filteredUsers.sort((a, b) => a.name.localeCompare(b.name)),
    ];
  }, [currentUser, members]);
};
