import { SyntheticEvent, useState } from "react";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Icon } from "../../components/Icon";
import CategoryFilter from "./CategoryFilter";
import BrandFilter from "./BrandFilter";

const Filter = ({ handleShowFilter }: any) => {
  const [expanded, setExpanded] = useState<string | false>("panel3");

  const handleChange =
    (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  // const handleApply = () => {
  //   setApplyFilter(true);
  // };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          boxShadow:
            "-8px 0px 32px 0px rgba(44, 34, 76, 0.10), -1px 0px 3px 0px rgba(44, 34, 76, 0.05)",
          width: "400px",
          padding: "24px 20px 0px 20px",
          alignItems: "flex-start",
          gap: "8px",
          flexDirection: "column",
          position: "absolute",
          top: "34px",
          right: "0",
          background: "#fff",
          zIndex: "10",
          "& .MuiAccordion-root": {
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          },
          "& .MuiAccordion-root.Mui-expanded": {
            paddingBottom: "20px",
          },
          "& .Mui-expanded.MuiAccordion-gutters": {
            margin: 0,
          },
          "& .MuiAccordionSummary-content": {
            margin: "20px 0px",
          },
          "& .MuiAccordionSummary-gutters": {
            padding: 0,
          },
          "& .MuiAccordionDetails-root": {
            padding: "0rem 0",
          },
          "& .MuiAccordionSummary-root.Mui-expandedt": {
            margin: "0px !important",
            minHeight: "auto !important",
          },
          "& .MuiFormControl-root": {
            display: "flex",
            "& .Mui-checked": {
              svg: {
                fill: "#8E4FF6",
              },
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="headline3">Filter</Typography>
          <Box onClick={handleShowFilter} sx={{ cursor: "pointer" }}>
            <Icon src="sales/close-icon.svg" width="16px" height="16px" />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingBottom: "35px",
            "& .MuiAccordion-root": {
              borderBottom: "1px solid #D7D5DB",
              "&:last-child": {
                borderBottom: "none",
              },
            },
          }}
        >
          {/* <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Products</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProductFilter />
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Brands</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BrandFilter />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Categories</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CategoryFilter />
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* <Box
          sx={{
            padding: "20px 0px 24px 0px",
            width: "100%",
          }}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              sx={{
                padding: "10px 16px",
                backgroundColor: "transparent",
                color: "#A6A3AF",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Clear
            </Button>
            <Button
              variant="primary"
              sx={{
                backgroundColor: "#8E4FF6",
                padding: "10px 16px",
                fontSize: "14px",
                fontWeight: 600,
              }}
              onClick={handleApply}
            >
              Apply
            </Button>
          </Stack>
        </Box> */}
      </Box>
    </>
  );
};
export default Filter;
