export interface TableColumn {
  name: string;
  field: string;
  description?: string;
  width?: number;
}

export enum FeatureField {
  Attribute = "attribute",
  Type = "type",
  Description = "description",
  ClosedList = "closed_list",
  AcceptedValues = "accepted_values",
}
