import { useAuthUser } from "@frontegg/react";
import { useEffect } from "react";
import { IntercomContextValues, useIntercom } from "react-use-intercom";
import useUserTenant from "./useUserTenant";

export const useAuthenticatedIntercom = (): IntercomContextValues => {
  const intercom = useIntercom();
  const user = useAuthUser();
  const tenant = useUserTenant(user);
  const environment = window.location.hostname.split(".")[0];

  useEffect(() => {
    if (user && tenant) {
      intercom.update({
        email: user.email,
        userId: user.id,
        name: user.name,
        phone: user.phoneNumber,
        customAttributes: {
          environment,
        },
        company: {
          companyId: user.tenantId,
          name: tenant?.name,
          createdAt: tenant?.createdAt?.toString(),
          customAttributes: {
            address: tenant?.address,
            timezone: tenant?.timezone,
            currency: tenant?.currency,
          },
        },
      });
    }
  }, [user, tenant]);

  return intercom;
};

export default useAuthenticatedIntercom;
