import { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, ListItem, Stack, Typography, useTheme } from "@mui/material";
import cn from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Cancel } from "@mui/icons-material";
import { StatsPeriods } from "../types";
import { useSalesContext } from "../hooks/selectors";
import { ProductImage } from "./ProductImage";
import { useSalesAssistantStyles } from "../useSalesAssistantStyles";
import { salesFilterColumnWidths } from "../constants";
import UnitSale from "../UnitSale";
// import { Icon } from "../../../components/Icon";
import { formatCurrencyOnlyNumber } from "../../InsightsV2/SummaryV2";
import { removeProductFromTracking } from "../../../services/runs/api";
import { CategoryInfo } from "./CategoryInfo";
import { TrendsDown, TrendsUp } from "../../../assets";
import { BackButton } from "../../../components/BackButton";

const useStyles = makeStyles(() => ({
  trackImg: {
    opacity: 0,
  },
  productList: {
    "&:hover": {
      "& .trackImg": {
        opacity: 1,
      },
    },
  },
}));

export const mapRevenue = (
  revenue: number,
  sales7D: number | undefined,
  sales28D: number | undefined,
  statsPeriod: StatsPeriods,
  revenueOverTimeEnabled: boolean
): number => {
  if (!revenueOverTimeEnabled) return revenue;
  if (statsPeriod === "7 days") return sales7D as number;
  if (statsPeriod === "28 days") return sales28D as number;
  throw Error("wrong revenue value mapping");
};

export const FilterSalesListItem: FC<{
  insight: any;
  setIsUpdatedList?: any;
  headerList?: boolean;
 
}> = ({ insight, setIsUpdatedList, headerList }) => {

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { sortDays, setSelectedProductGroup , groupSearch} = useSalesContext();
  const { row } = useSalesAssistantStyles();
  const [totalSales, setTotalSales] = useState<number>(0);
  const [totalSalesChange, setTotalSalesChange] = useState<number>(0);
  const [totalSalesPercentage, setTotalSalesPercentage] = useState<number>(0);

  
  
  const handleRemoveProductFromTracking = async () => {
    setIsUpdatedList(false);
    await removeProductFromTracking(insight?.id);
    setIsUpdatedList(true);
  };
  const handleCancel = (snackbarId: any) => {
    closeSnackbar(snackbarId);
  };
  const handleUndo = () => {
    handleClick("Item removed from tracking List");
    handleRemoveProductFromTracking();
  };
  const action = (snackbarId: any) => (
    <>
      {!insight?.tracked && (
        <Typography
          variant="body4"
          sx={{ color: "white", cursor: "pointer" }}
          onClick={() => handleUndo()}
        >
          Undo
        </Typography>
      )}
      <Typography
        variant="body4"
        sx={{
          color: "white",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => handleCancel(snackbarId)}
      >
        <Cancel />
      </Typography>
    </>
  );
  const handleClick = (value: any) => {
    enqueueSnackbar("Custom snackbar message", {
      variant: "success",
      content: (key) => (
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          sx={{
            background: theme.palette.text.primary,
            minHeight: " 70px",
            padding: "4px 0px",
            borderRadius: "4px",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            gap="8px"
            sx={{ padding: "8px 20px" }}
          >
            <Box>
              <Typography
                variant="body4"
                display="block"
                sx={{
                  fontSize: "15px",
                  color: "white",
                  margin: 0,
                  lineHeight: "24px",
                }}
              >
                {value}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            gap="16px"
            alignItems="center"
            sx={{ paddingRight: "16px" }}
          >
            {action(key)}
          </Box>
        </Box>
      ),
    });
  };
  
  
  const handleFilterListing = (productGroup: any) => {
    setSelectedProductGroup(productGroup);

    if(insight?.filter_id) {
      if (!groupSearch.trim()) {
      
        history.push(`/sales-assistant/products?groupId=${insight?.filter_id}`);
        
      } else {
       
        history.push(`/sales-assistant/products?groupId=${insight?.filter_id}&asinId=${groupSearch}`);
       
      }
    }
};
 

  const handleCalcSales7d = () => {
    const salesDay = sortDays === "7d" ? "sales_7d" : "sales_28d";
    const salesPercentage =
      sortDays === "7d" ? "sales_7d_percentage" : "sales_28d_percentage";
    const salesChange =
      sortDays === "7d" ? "sales_7d_change" : "sales_28d_change";
    if (insight) {
      setTotalSalesPercentage(insight[salesPercentage]);
      setTotalSalesChange(insight[salesChange]);
      setTotalSales(insight[salesDay]);
    }
  };
  useEffect(() => {
    handleCalcSales7d();
  }, [insight?.filter_id, sortDays]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0px",
        position: "relative",
      }}
    >
      {headerList && location.pathname !== "/sales-assistant/home" ? (
        <Box sx={{ position: "absolute", left: "-30px" }} onClick={() => history.push("/sales-assistant/home")}>
          <BackButton />
        </Box>
      ) : (
        ""
      )}
      <ListItem
        key={insight?.id}
        id={`insight-id-${insight?.id}`}
        sx={{
          height: "auto",
          opacity: 1,
          transition: "all 0.3s linear",
          overflow: "hidden",
          position: "relative",
          paddingTop: headerList ? "0px" : "8px",
          paddingBottom: headerList ? "0px" : "8px",
          "&.hidden": {
            paddingY: 0,
          },
          "& .MuiStack-root": {
            boxShadow: "none",
          },
        }}
      >
        <Stack
          data-testid="insights-list-row"
          className={cn(row, { active: insight?.isChecked })}
          position="relative"
        >
          <Box
            position="relative"
            sx={{
              "&:hover": {
                "& .MuiBox-root": {
                  opacity: 1,
                },
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              padding="12px 10px"
              gap="20px"
              justifyContent="space-between"
              position="relative"
              className={classes.productList}
              sx={{
                "&:hover": {
                  "& .MuiBox-root": {
                    opacity: 1,
                  },
                  cursor: (location.pathname === "/sales-assistant/home" && insight?.filter_id) ? "pointer" : "default"
                },
              }}
              onClick={() => handleFilterListing(insight)}
            >
              <Box
                sx={{ display: "flex", gap: "16px", alignItems: "center" }}
                width={salesFilterColumnWidths.info}
              >
                <Box minWidth={salesFilterColumnWidths?.image}>
                  <ProductImage insight={insight} useTooltip />
                </Box>
                <Box>
                  <CategoryInfo insight={insight} />
                </Box>
              </Box>
              <Box width={salesFilterColumnWidths?.unitSale}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="caption" color={theme.palette.text.link}>
                    {insight?.start_week_text || ""}
                  </Typography>
                  <Typography variant="caption" color={theme.palette.text.link}>
                    {insight?.end_week_text || ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: "56px",
                      "& .apexcharts-toolbar": {
                        display: "none",
                      },
                    }}
                  >
                    {insight?.graph_data?.length > 0 && (
                      <UnitSale data={insight.graph_data} />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                width={salesFilterColumnWidths.rating}
                sx={{ display: "flex", flexDirection: "column", gap: "14px" }}
              >
                <Stack
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(totalSales)}
                  </Typography>
                  <Box display="flex" gap="8px">
                    <>
                      {totalSalesPercentage >= 0 ? (
                        <Box display="flex" gap="2px" alignItems="center">
                          <TrendsUp />
                          <Typography
                            variant="body4"
                            sx={{ color: theme.palette.text.success }}
                          >
                            {Math.abs(totalSalesPercentage)
                              .toFixed(1)
                              .replaceAll(/\.0(0)?/g, "")}
                            %
                          </Typography>
                        </Box>
                      ) : (
                        <Box display="flex" alignItems="center" gap="2px">
                          <TrendsDown />
                          <Typography
                            variant="body4"
                            sx={{ color: theme.palette.error.main }}
                          >
                            {Math.abs(totalSalesPercentage)
                              .toFixed(1)
                              .replaceAll(/\.0(0)?/g, "")}
                            %
                          </Typography>
                        </Box>
                      )}
                    </>
                    <Typography
                      variant="body4"
                      sx={{
                        color:
                          totalSalesPercentage >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main,
                      }}
                    >
                      <>
                        {"("}${totalSalesChange > 0 && ""}
                        {formatCurrencyOnlyNumber(totalSalesChange)}
                        {")"}
                      </>
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </ListItem>
    </Box>
  );
};
