import { useMemo } from "react";
import { useTheme } from "@mui/styles";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import Dismissed from "./Dismissed";
import Alerts from "./alerts";
import MyItems from "./MyItems";
import Feed from "./Feed";
import DismissedTrackItem from "./DismissedTrackItem";
import TrackingItem from "./TrackingItem";

const Index = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const Component = useMemo(() => {
    if (pathname.includes("sales-assistant")) {
      return MyItems;
    }
    if (pathname.includes("/sales-assistant/alerts")) {
      return Alerts;
    }
    if (pathname.includes("/sales-assistant")) {
      return Dismissed;
    }
    if (pathname.includes("tracking/dismissed")) {
      return DismissedTrackItem;
    }
    if (pathname.includes("/tracking")) {
      return TrackingItem;
    }
    return Feed;
  }, [pathname]);

  return (
    <Stack
      width="100%"
      height="100%"
      sx={{ background: theme.palette.backgrounds.secondary }}
      alignItems="center"
      paddingTop="64px"
    >
      <Component />
    </Stack>
  );
};

export default Index;
