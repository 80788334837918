import {
  Autocomplete,
  Box,
  Breadcrumbs,
  ListSubheader,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HeaderTabs from "./HeaderTabs";
import { Icon } from "../../components/Icon";
import HeaderCards from "./HeaderCards";
import { useSalesContext } from "./hooks/selectors";
import Filter from "./Filter";
import { useQueryParams, useSplitEnabled } from "../../hooks";
import { Split } from "../../FeatureFlags/enums";
import { useHistory, useLocation } from "react-router-dom";
import { NavigateNextBreadcrumbs } from "../../assets";
import RevenueSortDropdown from "./List/RevenueSortDropdown";
import { KeyboardArrowDown } from "@mui/icons-material";
import SekeltonList from "./List/SkeletonList";
import { FilterSalesListItem } from "./List/FilterSalesListItem";
import { Search } from "../../components/SearchInput/Search";

const stackProps = {
  spacing: 2,
  justifyContent: "space-between",
  direction: "row" as const,
  alignItems: "center",
  sx: { padding: "0 8px" },
};
export const Header: React.FC = () => {

  
  
  const queryParams = useQueryParams();
  const theme = useTheme();
  const wrapperRef = useRef(null);
  const {
    SalesMetaData,
    metadataLoading,
    isFetchingProductGroup,
    producGroupData,
    groupSearch,
    
    setSelectedProductGroup,
  } = useSalesContext();
  const [showFilter, setShowFilter] = useState(false);
  const enableAlert = useSplitEnabled(Split.SALES_ASSISTANT_ALERTS);
  const location = useLocation();
  const history = useHistory();
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };
  const groupId = queryParams.get("groupId") || "";
  const [filterData, setFilterData] = useState<any>([]);
 
  
  const currentUrl = new URL(window.location.href);
  useEffect(() => {
    let filterDataList = []
    if(location.pathname === "/sales-assistant/home") {
      filterDataList = producGroupData?.filter(
        (productGroup: any) => productGroup?.title?.toLowerCase() === "all products"
      );
    } else {
      filterDataList = producGroupData?.filter(
        (el: any) => el?.filter_id === groupId
      );
    }
    if (filterDataList) {
      setFilterData(filterDataList);
      setSelectedProductGroup(filterDataList[0]);
      if(groupId && groupSearch.trim()){
        
        currentUrl.searchParams.set("asinId", groupSearch); 
        window.history.replaceState(null, "", currentUrl.toString());
      }
    }
  }, [groupId, producGroupData?.length]);
 
  



  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFilter(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const handleGoBack = () => {
    history.push(`/sales-assistant/home`);
  };

  const brandFilter = localStorage.getItem("brandFilter")
  const categoryFilter = localStorage.getItem("categoryFilter")

  return (
    <Box>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "0 32px",
          margin: "0px 0 0 0",
          gap: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Stack
          {...stackProps}
          sx={{
            paddingBottom: "0px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {location.pathname === "/sales-assistant/products" ? (
            <Stack
              sx={{ "& .MuiBreadcrumbs-separator": { padding: 0, margin: 0 } }}
            >
              <Breadcrumbs
                separator={<NavigateNextBreadcrumbs />}
                aria-label="breadcrumb"
                sx={{
                  "& .MuiBreadcrumbs-ol": {
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  },
                  "& li": {
                    minHeight: "41px",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <Typography
                  variant="body4"
                  key="1"
                  color="inherit"
                  sx={{
                    lineHeight: "16px",
                    ":hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                  onClick={handleGoBack}
                >
                  Sales Assistant
                </Typography>
                <Box sx={{ minWidth: "150px" }}>
                  <SelectTextField />
                </Box>
              </Breadcrumbs>
            </Stack>
          ) : (
            <Box>
              <Typography
                variant="headline3"
                display="flex"
                flexDirection="row"
                gap="12px"
                alignItems="center"
              >
                Sales Assistant{" "}
              </Typography>
            </Box>
          )}
         
          <Box>
              <ProductGroupHeaderSearch />
            </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            gap="16px"
          >
            <RevenueSortDropdown />
            {location.pathname !== "/sales-assistant/home" && (
              <Box
                display="flex"
                justifyContent="center"
                position="relative"
                width={40}
                height={32}
                borderRadius="4px"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  "& svg": { "& path": { fill: (brandFilter || categoryFilter) ? showFilter ? theme.palette.text.link : theme.palette.grey[400] : showFilter ? theme.palette.text.link : theme.palette.grey[400] } },
                  backgroundColor: (brandFilter || categoryFilter) ? showFilter ? theme.palette.primary.light : theme.palette.grey[50] : showFilter ? theme.palette.primary.light : "",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[50]
                  },
                }}
                onClick={handleShowFilter}
              >
                <Icon src="sales/filter.svg" />
                {(brandFilter || categoryFilter) && (
                  <Box
                    height="10px"
                    display="flex"
                    width="10px"
                    padding="2px"
                    borderRadius="50%"
                    bgcolor={theme.palette.background.default}
                    position="absolute"
                    top="8px"
                    right="10px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      bgcolor={theme.palette.primary.main}
                      height="6px"
                      width="6px"
                      borderRadius="50%"
                    ></Box>
                  </Box>
                )}
              </Box>
            )}
            {showFilter && <Filter handleShowFilter={handleShowFilter} />}
          </Box>
        </Stack>
      </Box>

      {location.pathname === "/sales-assistant/products" || location.pathname === "/sales-assistant/home" ? (
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.borders.lines}`,
            padding: "0 32px 0px",
            margin: "0px 0 0px",
            gap: "8px",
            marginBottom:
              enableAlert && location.pathname.includes("/sales-assistant")
                ? 20
                : 0,
          }}
        >
          <Stack
            sx={{
              maxWidth: theme.spacing(1350 / 8),
              marginLeft: "auto",
              marginRight: "auto",
              height: "inherit",
              padding: "0px 18px",
            }}
          >
            {isFetchingProductGroup && filterData?.length === 0 ? (
              <SekeltonList />
            ) : (
              <FilterSalesListItem
                insight={filterData?.[0]}
                key={filterData?.[0]?.id}
                headerList={true}
               />
            )}
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.borders.lines}`,
            padding: "0 32px 20px",
            margin: "20px 0 20px",
            gap: "8px",
            display: location.pathname.includes("/sales-assistant/products")
              ? "block"
              : "none",
            marginBottom:
              enableAlert && location.pathname.includes("/sales-assistant")
                ? 20
                : 0,
          }}
        >
          {SalesMetaData && (
            <HeaderCards
              SalesMetaData={SalesMetaData}
              metadataLoading={metadataLoading}
            />
          )}{" "}
        </Box>
      )}

      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "0 32px",
          margin: "12px 0 0 0",
          gap: "8px",
          display: location.pathname.includes("/sales-assistant")
            ? enableAlert
              ? "block"
              : "none"
            : "block",
        }}
      >
        <HeaderTabs isLoading={false} />
      </Box>
    </Box>
  );
};

interface Option {
  value: string;
  label: string;
}

const SelectTextField: React.FC = ()=> {
const { producGroupData: productGroups, setSelectedProductGroup } =
    useSalesContext();
  const queryParams = useQueryParams();
  const groupId = queryParams.get("groupId") || "";
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [transformedData, setTransformedData] = useState<any>([]);
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (transformedData?.length === 0) {
      const transformedDataList =
        productGroups && productGroups.length > 0
          ? productGroups.map((item: any) => ({
              value: item.filter_id,
              label: item.title,
            }))
          : [];
      setTransformedData(transformedDataList);
    }
  }, [productGroups?.length]);

  const handleOptionChange = (
    event: React.ChangeEvent<any>,
    newValue: Option | null
  ) => {
    event.preventDefault();
    if (newValue?.value !== undefined) {
      const filterSelectedGroup =
        productGroups &&
        productGroups.find((el: any) => el.filter_id === newValue?.value);
      setSelectedProductGroup(filterSelectedGroup);
      history.push(`/sales-assistant/products?groupId=${newValue?.value}`);
      setSelectedOption(newValue);
      setIsFocused(!isFocused);
    }
  };
  useEffect(() => {
    if (groupId && transformedData?.length > 0) {
      const defaultData =
        transformedData &&
        transformedData.filter((el: any) => {
          return el.value === groupId;
        });
      const defaultList: Option | null = defaultData.length
        ? defaultData[0]
        : null;
      setSelectedOption(defaultList);
    }
  }, [groupId, transformedData?.length]);

  return (
    <>
      {transformedData?.length > 0 && (
        <>
          <Box
            sx={{
              // minWidth: "100px",
              "& .MuiAutocomplete-inputRoot": {
                paddingRight: "0px !important",
              },
              "& .MuiInput-input": {
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16px",
                color: theme.palette.secondary.main,
                ":hover": { color: theme.palette.text.link, cursor: "pointer" },
              },
              "& .MuiInput-root::before": {
                borderColor: "transparent !important",
              },
              "& .MuiInput-root::": {
                width: "10px !important",
                paddingBottom: "0px",
              },
            }}
          >
            <Autocomplete
              sx={{
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
                ":hover": {
                  "& svg": { "& path": { fill: theme.palette.text.link } },
                },
              }}
              PaperComponent={({ children }) => (
                <Paper
                  elevation={2}
                  sx={{
                    marginTop: "16px",
                    width: "200px",
                    background: "#FFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    letterSpacing: "-0.14px",
                    fontFamily: "Inter",
                  }}
                >
                  {children}
                </Paper>
              )}
              value={selectedOption}
              onChange={handleOptionChange}
              options={transformedData}
              getOptionLabel={(option) => option.label}
              renderInput={(params: any) => (
                <TextField
                  sx={{
                    position: "relative",
                    width: `${
                      params.inputProps?.value?.length > 0
                        ? params.inputProps?.value?.length * 8 + 15
                        : 100
                    }px`,
                  }}
                  variant="standard"
                  {...params}
                  InputLabelProps={{
                    shrink: selectedOption !== null,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: selectedOption ? (
                      <KeyboardArrowDown
                        style={{
                          transform: isFocused ? "rotate(180deg)" : "none",
                          transition: "transform 0.2s ease",
                          position: "absolute",
                          right: "0px",
                          // left:'auto',
                          top: "8px",
                          color: "#2C224C",
                          cursor: "pointer",
                          zIndex: -1,
                          pointerEvents: "none",
                        }}
                      />
                    ) : null,
                  }}
                  fullWidth
                  onBlur={() => setIsFocused(false)}
                  onClick={() => setIsFocused(true)}
                />
              )}
            />
          </Box>
        </>
      )}
    </>
  );
};


export const ProductGroupHeaderSearch = ( ) => {
  const { setProupSearch, groupSearch } = useSalesContext();
  const [search,setSearch]=useState("")
  const currentUrl = new URL(window.location.href);
  const queryParams = useQueryParams();
  const groupId = queryParams.get("groupId") || "";
  const asinId=queryParams.get("groupId")||"";
  useEffect(() => {
    if (groupSearch) {
      setProupSearch("");
     
    }
  }, []);
 
 

  const handleSearch = (e: any) => {
    const value = e.target.value;
    
    if (e.key === "Enter") {
      setProupSearch(value);
      localStorage.setItem("groupSearch", value);
      if(groupId){
        currentUrl.searchParams.set("asinId", value); 
        history.replaceState(null, "", currentUrl.toString());
      }
     
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    if (value === "") {
      setProupSearch("");
      setSearch("");
      localStorage.removeItem("groupSearch");
      if(asinId){
        currentUrl.searchParams.set("asinId", value); 
        history.replaceState(null, "", currentUrl.toString());
      }
      
    }
    else{
      setSearch(value);
      
    }
  };

 useEffect(() => {
    if(groupSearch){
      setSearch(groupSearch);
    }
    
  },[groupSearch])


  return (
    <>
      <ListSubheader sx={{ padding: 0, height: "40px" }}>
        <Search
          fullWidth
          placeholder="Search ASIN"
          value={search}
          sx={{ height: "100%", fontSize: "15px", verticalAlign: "middle" }}
          onChange={handleChange}
          onKeyDown={(e: any) => handleSearch(e)}
          id="product-search"
        />
      </ListSubheader>
    </>
  );
};
