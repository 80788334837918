import SVG from "react-inlinesvg";
import {
  Box,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Insight } from "../../../types";
import { INSIGHT_TYPES, InsightColors } from "../constants";
import { TransformedInsight } from "../types";
import { FC, ReactNode } from "react";

function getStarColor(theme: Theme, insightType: string) {
  if (insightType === INSIGHT_TYPES.lossProtection) {
    return theme.palette.warning.main;
  }
  return InsightColors.success.main;
}

function getStarIcon(insightType: string) {
  if (insightType === INSIGHT_TYPES.lossProtection) {
    return "insights/shield-fill.svg";
  }
  return "insights/star-fill.svg";
}

const RatingIcon: FC<
  Pick<Insight, "insightType" | "stars"> & {
    idx: number;
  } & Pick<RatingProps, "starWidth" | "starHeight">
> = ({ stars, insightType, idx, starWidth, starHeight }) => {
  const theme = useTheme();
  return (
    <SVG
      src={`/${getStarIcon(insightType)}`}
      width={starWidth || theme.spacing(3)}
      height={starHeight || theme.spacing(3)}
      fill={
        idx < stars ? getStarColor(theme, insightType) : theme.palette.grey[200]
      }
    />
  );
};

type RatingProps = {
  children?: ReactNode;
  vertical?: boolean;
  starProps?: StackProps;
  minWidth?: string;
  valueTypographyProps?: TypographyProps;
  kTypographyProps?: TypographyProps;
  starWidth?: string;
  starHeight?: string;
  starSpacing?: number;
} & TransformedInsight;
const RatingLayout: FC<
  Pick<
    RatingProps,
    "minWidth" | "id" | "revenueValue" | "vertical" | "children"
  >
> = ({ children, id, vertical, minWidth }) => (
  <Box
    key={id}
    sx={{
      minWidth,
      textAlign: "center",
    }}
  >
    <Stack
      sx={{ fontSize: "24px" }}
      spacing={1}
      direction={vertical ? "row" : "column"}
      alignItems={vertical ? "center" : "left"}
    >
      {children}
    </Stack>
  </Box>
);

const RatingValue: FC<
  Pick<
    RatingProps,
    "valueTypographyProps" | "kTypographyProps" | "revenueValue" | "type"
  >
> = ({ type, revenueValue, valueTypographyProps, kTypographyProps }) => {
  const theme = useTheme();
  const color = getStarColor(theme, type);
  return (
    <Stack alignSelf="flex-start" justifyContent="center">
      <Typography color={color} {...valueTypographyProps}>
        {`$${
          Math.ceil(revenueValue / 1000000) > 1
            ? Math.ceil(revenueValue / 1000000)
            : Math.ceil(revenueValue / 1000)
        }`}
        <Typography color={color} {...kTypographyProps}>
          {Math.ceil(revenueValue / 1000000) > 1 ? "m" : "k"}
        </Typography>
      </Typography>
    </Stack>
  );
};

const RatingStars: FC<
  Pick<
    RatingProps,
    | "starWidth"
    | "starHeight"
    | "starSpacing"
    | "revenueValue"
    | "stars"
    | "type"
    | "starProps"
  >
> = ({
  starWidth,
  starHeight,
  starSpacing,
  revenueValue: valueOfInsight,
  stars,
  type: insightType,
  starProps,
}) => (
  <Stack
    justifyContent="center"
    alignSelf="flex-start"
    direction="row"
    spacing={starSpacing}
    key={valueOfInsight}
    {...(starProps || {})}
  >
    {Array.from({ length: 5 }, (_, idx) => (
      <RatingIcon
        key={idx}
        {...{ stars, idx, insightType, starWidth, starHeight }}
      />
    ))}
  </Stack>
);

export const Rating: FC<
  Pick<
    RatingProps,
    | "stars"
    | "revenueValue"
    | "id"
    | "type"
    | "actionText"
    | "vertical"
    | "starProps"
    | "minWidth"
    | "valueTypographyProps"
    | "kTypographyProps"
    | "starWidth"
    | "starHeight"
    | "starSpacing"
  >
> = ({
  stars,
  starProps,
  revenueValue,
  id,
  type,

  starWidth,
  starHeight,
  starSpacing = 1,
  valueTypographyProps = { variant: "h2_v2" },
  kTypographyProps = { variant: "headline3" },
  minWidth = "100px",
  vertical = false,
}) => (
  <RatingLayout {...{ id, revenueValue, vertical, minWidth }}>
    <RatingValue
      {...{ type, revenueValue, valueTypographyProps, kTypographyProps }}
    />
    <RatingStars
      {...{
        starWidth,
        starHeight,
        starSpacing,
        revenueValue,
        stars,
        type,
        starProps,
      }}
    />
  </RatingLayout>
);
