import { FC, ReactNode, SyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UseQueryResult } from "react-query";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { useQueryParams, useSplitEnabled } from "../../../../hooks";
import { InsightsQueryParams } from "../../constants";
import {
  getInsightV2WithIframes,
  getInsightV2WithoutIframe,
  useInsight,
} from "../../useInsights";
import { Insight } from "../../../../types";
import { APIError } from "../../../../services/apiRequest";
import { InsightRead } from "../../types";
import { ProductImage } from "../ProductImage";
import TabsButtons from "../TabButtons";
import { RevenueBox } from "./RevenueBox";
import { Split } from "../../../../FeatureFlags/enums";
import { formatCurrencyOnlyNumber } from "../../SummaryV2";
import theme, { palette } from "../../../../themes/light";
import { Icon } from "../../../../components/Icon";
import { RecommendedActionSection } from "./RecommendedSection";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{
        backgroundColor: theme.palette.grey[25],
        padding: "20px 16px",
        borderRadius: "0px 12px 12px 12px",
        width: "calc(100% - 160px)",
        position: "relative",
      }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export const useLocalInsight = (): UseQueryResult<Insight, APIError> => {
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  return useInsight(insightId);
};

interface PropTypes {
  status?: string;
  insight: InsightRead;
}

const DateAndStatus = ({ status, insight }: PropTypes) => {
  const theme = useTheme();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  return insight ? (
    <Stack
      gap="8px"
      direction={revenueOverTimeEnabled ? "row-reverse" : "row"}
      alignItems="center"
    >
      {status === "new" && (
        <Chip
          label="New"
          size="small"
          color="secondary"
          sx={{
            borderRadius: "500px",
            padding: "4px",
            background: theme.palette.backgrounds.active,
          }}
        />
      )}
      {status === "updated" && (
        <Chip
          label="Updated"
          size="small"
          sx={{
            borderRadius: "500px",
            padding: "4px",
            background: theme.palette.backgrounds.info,
            color: theme.palette.info.contrastText,
          }}
        />
      )}
    </Stack>
  ) : (
    <Skeleton width={theme.spacing(5)} />
  );
};

const DrawerTop: FC<{
  onClose: () => void;
  setInsightIdForDialog: (val: string) => void;
  insight: InsightRead;
}> = ({ onClose, setInsightIdForDialog, insight }) => {
  const theme = useTheme();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  return (
    <Box display="flex" justifyContent="space-between">
      <IconButton onClick={onClose} sx={{ background: "none", p: 2.5 / 8 }}>
        <CloseIcon
          sx={{ fontSize: theme.spacing(2) }}
          htmlColor={theme.palette.icons.secondary}
        />
      </IconButton>
      <Box>
        {revenueOverTimeEnabled && insight ? (
          <TabsButtons
            insightId={insight.id}
            setInsightIdForDialog={setInsightIdForDialog}
            outlined
            canUndo={insight.canUndo}
          />
        ) : (
          <DateAndStatus insight={insight} />
        )}
      </Box>
    </Box>
  );
};

const DrawerTitle: FC<{
  insight?: InsightRead;
  setInsightIdForDialog: (val: string) => void;
}> = ({ insight, setInsightIdForDialog }) => {
  const theme = useTheme();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);
  return insight ? (
    <Stack justifyContent="space-between" alignItems="start" direction="row">
      <Stack direction="row" alignItems="center" spacing={1}>
        <ProductImage insight={insight} useTooltip size="small" />
        <Stack justifyContent="space-between" alignItems="start">
          <Box
            sx={{ zoom: 0.85 }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="8px"
          >
            <Typography variant="h2_v2">{insight.strategy}</Typography>
            <DateAndStatus status={insight.status} insight={insight} />
          </Box>
          <Box display="flex" gap="8px" alignItems="center">
            <Chip
              label={insight.brand}
              size="small"
              sx={{
                borderRadius: "500px",
                padding: "4px",
                background: theme.palette.backgrounds.info,
                color: theme.palette.grey[500],
              }}
            />
            <Typography variant="caption" color="text.secondary">
              {insight.categories[0]} | ... | {insight.categories[1]}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      {revenueOverTimeEnabled ? (
        <RevenueBox insight={insight} />
      ) : (
        <TabsButtons
          insightId={insight.id}
          setInsightIdForDialog={setInsightIdForDialog}
          outlined
          canUndo={insight.canUndo}
        />
      )}
    </Stack>
  ) : (
    <Skeleton width="100%" />
  );
};

export const EmbeddedQuestion: FC<{ insight: any }> = ({ insight }) => (
  <Paper
    variant="outlined"
    sx={{
      padding: "12px",
      height: insight.iframe_link ? "400px" : "220px",
      borderRadius: "8px",
      marginLeft: "32px",
    }}
  >
    <Stack
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      {insight.iframe_link ? (
        <iframe
          title={insight.title || ""}
          src={insight.iframe_link as string}
          style={{ border: 0 }}
          width="100%"
          height="100%"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "auto",
            textAlign: "center",
            padding: "0px 80px",
            gap: "20px",
            margin: "30px 0px",
          }}
        >
          <Box>
            <img src={`/insights/noads.svg`} alt="noogata" />
          </Box>
          <Box display="flex" gap="4px" flexDirection="column">
            <Typography
              variant="body3"
              color={theme.palette.text.primary}
              sx={{ fontWeight: 600 }}
            >
              No Data found
            </Typography>
            {/* <Typography variant="body3" color={theme.palette.text.primary}>
              It seems you have no ads for this product
            </Typography> */}
          </Box>
        </Box>
      )}
    </Stack>
  </Paper>
);

export const InsightDrawer: FC<{
  onClose: () => void;
  open: boolean;
  setInsightIdForDialog: (val: string) => void;
}> = ({ open, onClose, setInsightIdForDialog }) => {
  const [value, setValue] = useState(0);
  const [isExpand, setIsExpand] = useState<number[]>([]);
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  const [insightData, setInsightData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleInsight = async (insightId: any) => {
    const data = await getInsightV2WithoutIframe(insightId);
    setInsightData(data);
  };

  useEffect(() => {
    setInsightData(null);
    if (insightId) {
      handleInsight(insightId);
    }
  }, [insightId]);

  const handleInsightWithIfame = async (insightId: any) => {
    setLoading(true);
    const data = await getInsightV2WithIframes(insightId);
    setInsightData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (insightData?.id) {
      handleInsightWithIfame(insightId);
    }
  }, [insightData?.id]);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsExpand([]);
  };

  const handleExpand = (value: number) => {
    if (isExpand.includes(value)) {
      const newIsExpand = isExpand.filter((item) => item !== value);
      setIsExpand(newIsExpand);
    } else {
      setIsExpand([...isExpand, value]);
    }
  };

  return (
    <Drawer
      PaperProps={{
        variant: "outlined",
        elevation: 0,
        sx: {
          width: "820px",
        },
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Stack p={3} spacing={3}>
        <DrawerTop
          onClose={onClose}
          setInsightIdForDialog={setInsightIdForDialog}
          insight={insightData}
        />
        <DrawerTitle
          insight={insightData}
          setInsightIdForDialog={setInsightIdForDialog}
        />
        <Divider style={{ width: "calc(100% + 48px)", marginLeft: "-24px" }} />
        {insightData ? (
          <RecommendedActionSection insight={insightData} />
        ) : (
          <Skeleton width="100%" height="150px" />
        )}
        {insightData ? (
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 0,
                borderColor: "divider",
                padding: "0px",
                gap: "8px",
                minWidth: "160px",

                "& .MuiTabs-flexContainer": {
                  gap: "8px",
                },
                "& .MuiButtonBase-root.MuiTab-root": {
                  padding: "8px 12px",
                },
                "& .Mui-selected": {
                  backgroundColor: theme.palette.grey[25],
                  borderRadius: "12px 0px 0px 12px",
                  padding: "8px 12px",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              {insightData?.tabs?.map((item: any, i: any) => (
                <Tab
                  key={i}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        gap: "8px",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="text.primary"
                        sx={{
                          textAlign: "left",
                          lineHeight: "16px",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          textTransform: "initial",
                          color: theme.palette.grey[400],
                        }}
                      >
                        {item.title}
                        {/* {item.title === "Category Demand" && (
                          <img
                            src={`/insights/warning.svg`}
                            alt="timeline"
                          />
                        )} */}
                      </Typography>
                      <RevenueSection
                        value={item.value}
                        changeValue={item.change_value}
                        insight={insightData}
                        title={item.title}
                      />
                    </Box>
                  }
                />
              ))}
            </Tabs>
            {insightData?.tabs?.map((el: any, index: any) => (
              <TabPanel value={value} index={index} key={index}>
                {el.tab_data.map((ele: any, i: any) => (
                  <Box
                    key={i}
                    sx={{
                      position: "relative",
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        height: "90%",
                        width: "1px",
                        background: "#D7D5DB",
                        top: i === 0 ? "29px" : "-8px",
                        left: "10px",
                        display:
                          el.tab_data.length - 1 === i ? "none" : "block",
                      },
                    }}
                  >
                    {ele?.closeable === false ? (
                      <>
                        <Typography
                          mt={i === 0 ? "0px" : "20px"}
                          mb="10px"
                          variant="body2"
                          color={palette.text.primary}
                          display="flex"
                          alignItems="center"
                          gap="12px"
                          sx={{ position: "relative" }}
                        >
                          <Icon
                            src="insights/expand.svg"
                            width="24px"
                            height="24px"
                          />
                          {ele?.title}
                        </Typography>
                        {loading === true &&
                        ((i !== 0 && index !== 0) || i !== 0 || index !== 0) ? (
                          <Skeleton
                            width="calc(100% - 32px)"
                            height="400px"
                            sx={{
                              borderRadius: "8px",
                              marginLeft: "32px",
                              transform: "scale(1)",
                            }}
                          />
                        ) : (
                          <EmbeddedQuestion insight={ele} />
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          mt="20px"
                          mb="10px"
                          variant="body4"
                          color={palette.text.link}
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          paddingLeft="32px"
                          sx={{ cursor: "pointer", position: "relative" }}
                          onClick={() => handleExpand(i)}
                        >
                          <Icon
                            src={
                              isExpand.includes(i)
                                ? "insights/collapse.svg"
                                : "insights/addIcon.svg"
                            }
                            width="16px"
                            height="16px"
                          />{" "}
                          {ele?.title}
                        </Typography>
                        {isExpand.includes(i) && (
                          <>
                            {loading === true &&
                            ((i !== 0 && index !== 0) ||
                              i !== 0 ||
                              index !== 0) ? (
                              <Skeleton
                                width="calc(100% - 32px)"
                                height="400px"
                                sx={{
                                  borderRadius: "8px",
                                  marginLeft: "32px",
                                  transform: "scale(1)",
                                }}
                              />
                            ) : (
                              <EmbeddedQuestion insight={ele} />
                            )}
                          </>
                        )}
                      </>
                    )}
                    <img
                      src={`/insights/timeline.svg`}
                      alt="timeline"
                      style={{
                        position: "absolute",
                        bottom: "-31px",
                        left: "10px",
                        display:
                          el.tab_data.length - 1 === i ? "none" : "block",
                      }}
                    />
                  </Box>
                ))}
              </TabPanel>
            ))}
          </Box>
        ) : (
          <Skeleton width="100%" height="375px" />
        )}
      </Stack>
    </Drawer>
  );
};

export const RevenueSection = ({ insight, changeValue, value, title }: any) => {
  const theme = useTheme();
  const width = theme.spacing(124 / 8);
  const height = theme.spacing(64 / 8);

  if (!insight) {
    return <Skeleton width={width} height={height} />;
  }

  return insight ? (
    <Stack direction="row" alignItems="center">
      <Typography
        variant="body3"
        sx={{ fontWeight: 600 }}
        color={theme.palette.text.primary}
      >
        {title === "Share of Voice" || title === "Conversion" ? (
          <>
            {(value * 100).toFixed(2)}
            {title === "Share of Voice" || title === "Conversion" ? "%" : ""}
          </>
        ) : (
          <>
            {title === "Ad spend" ? "$" : ""}
            {formatCurrencyOnlyNumber(value)}
            {title === "Share of Voice" || title === "Conversion" ? "%" : ""}
          </>
        )}
      </Typography>
      <Stack direction="row" alignItems="end" pl={0.5}>
        {value == null || Number.isNaN(value) ? (
          <Typography
            variant="body4"
            sx={{
              width: "20px",
              height: "20px",
            }}
          >
            (n/a)
          </Typography>
        ) : (
          <>
            {changeValue != null &&
              (changeValue > 0 ? (
                <NorthEastIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: (theme) =>
                      changeValue > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main,
                  }}
                />
              ) : (
                <SouthEastIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: (theme) =>
                      changeValue > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main,
                  }}
                />
              ))}
            {Number.isFinite(changeValue) && (
              <Typography
                variant="caption"
                sx={{
                  paddingLeft: "2px",
                  color: (theme) =>
                    changeValue != null
                      ? changeValue > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                      : theme.palette.text.secondary,
                }}
              >
                {(changeValue * 100).toFixed(2)}%
              </Typography>
            )}
          </>
        )}
      </Stack>
    </Stack>
  ) : (
    <Skeleton width={width} height={height} />
  );
};
