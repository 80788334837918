import { FC } from "react";
import { Status } from "../../../../enums";
import FailedRunBox from "./FailedRunBox";
import RunningRunBox from "./RunningRunBox";
import { Run } from "../../../../types";

const NoOutputs: FC<{ boardId: string; run?: Run }> = ({ boardId, run }) =>
  run?.status === Status.FAILED || run?.status === Status.TERMINATED ? (
    <FailedRunBox boardId={boardId} runId={run?.id || ""} />
  ) : (
    <RunningRunBox />
  );

export default NoOutputs;
