import {
  Box,
  Chip,
  ChipProps,
  Stack,
  StackProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "../../../utils";
import SVG from "react-inlinesvg";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useInsightsStyles } from "../useInsightsStyles";
import { InsightRead } from "../types";
import DateParser from "../DateParser";
import theme from "../../../themes/light";
import { FC } from "react";

export const InsightDate: FC<{ date: Date }> = ({ date }) => (
  <Typography variant="caption" color="text.secondary">
    {DateParser(dayjs(date))}
  </Typography>
);

export const InsightCategoryText: FC<{ categories: string[] }> = ({
  categories,
}) => {
  let categoryText = "";
  if (categories.length === 0) {
    categoryText = "All Categories";
  } else if (categories.length <= 2) {
    categoryText = categories.join(" | ");
  } else {
    categoryText = `${categories[0]} | ... | ${categories.slice(-1)[0]}`;
  }
  return (
    <Tooltip title={categories.join(" | ")}>
      <Typography variant="caption" color="text.secondary">
        {categoryText}
      </Typography>
    </Tooltip>
  );
};

const InfoText: FC<{
  insight: InsightRead;
  hideCategories?: boolean;
}> = ({ insight, hideCategories }) => {
  // const matches = useMediaQuery("(min-width:1900px)");
  const { lineClamp, nomargin } = useInsightsStyles();
  return (
    <>
      <Typography variant="body5">
        <Box data-testid="insight-info-text" className={lineClamp}>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw as any]}
            className={nomargin}
          >
            {insight.cardText || ""}
          </ReactMarkdown>
        </Box>
      </Typography>
      <Stack direction="row" alignItems="center" gap="8px">
        {insight.brand && (
          <Chip
            label={insight.brand}
            size="small"
            sx={{
              borderRadius: "500px",
              padding: "4px",
              background: theme.palette.backgrounds.secondary,
              textTransform: "capitalize",
            }}
          />
        )}
        {/* {!matches && <InsightCategoryText categories={insight.categories} />} */}
        {!hideCategories && (
          <InsightCategoryText categories={insight.categories} />
        )}
      </Stack>
    </>
  );
};

export const Info: FC<
  StackProps & {
    insight: InsightRead;
    hideCategories?: boolean;
    chipProps?: ChipProps;
  }
> = ({ insight, hideCategories, chipProps = {}, ...props }) => {
  const { strategy } = insight;
  const theme = useTheme();
  const { rowAvatarChip } = useInsightsStyles();
  return (
    <Stack direction="column" spacing={1} alignItems="space-between" {...props}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Chip
          className={rowAvatarChip}
          variant={chipProps.variant || "filled"}
          sx={{ height: "24px", "& > span": { padding: "0 8px" } }}
          icon={
            <SVG
              uniquifyIDs
              src={"/insights/${src}"}
              fill={theme.palette.info.contrastText}
            />
          }
          label={
            <Typography variant="caption" color={theme.palette.text.primary}>
              {strategy}
            </Typography>
          }
          color="secondary"
        />
      </Stack>
      <InfoText insight={insight} hideCategories={hideCategories} />
    </Stack>
  );
};
