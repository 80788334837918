import {MenuItem, TextField} from "@mui/material";
import {Controller, useWatch} from "react-hook-form";
import {RepeatType} from "./hooks/useScheduleForm";
import {FC} from "react"

const IntervalTypeInput: FC<{
    control: any
    setValue?: any
}> = ({control, setValue}) => {
    const intervalValue = useWatch({
        control,
        name: "interval.value",
    });

    return (
        <Controller
            name="interval.type"
            control={control}
            shouldUnregister={false}
            render={({ value}: any) => (
                <TextField
                    hiddenLabel
                    id="interval-type"
                    value={value}
                    // onChange={onChange}
                    onChange={(e) => setValue("interval.type", e.target.value)}
                    defaultValue="week"
                    variant="outlined"
                    size="small"
                    select
                >
                    {Object.values(RepeatType).map((option) => (
                        <MenuItem value={option}>
                            {`${option}${
                                parseInt(intervalValue as unknown as string, 10) !== 1
                                    ? "s"
                  : ""
              }`}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
export default IntervalTypeInput;
