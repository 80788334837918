import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { Dashboard } from "@mui/icons-material";

import { generateViews } from "../../services/Flows/api";

export const UpdateFlowViewsButton = ({ flowId }: { flowId?: string }) => {
  const generateFlowViewsMutation = useMutation((flowId?: string) =>
    generateViews(flowId),
  );
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() =>
        generateFlowViewsMutation
          .mutateAsync(flowId)
          .then(() =>
            enqueueSnackbar("Flow table_views generated successfully", {
              variant: "success",
            }),
          )
          .catch(() =>
            enqueueSnackbar("Flow table_views generation failed", {
              variant: "error",
            }),
          )
      }
      startIcon={<Dashboard />}
      disabled={generateFlowViewsMutation.isLoading}
    >
      {flowId ? "Update flow view" : "Update views"}
    </Button>
  );
};
