import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box, styled } from "@mui/material";

import SVG from "react-inlinesvg";
import theme from "../../../themev2";
import { Status } from "../../../enums";
import { FC } from "react";

const RotatingBox = styled(Box)(() => ({
  animation: "rotate 2s infinite linear",
  display: "flex",
  alignItems: "center",

  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const RotatingLoader: FC = () => (
  <RotatingBox sx={{ ml: 0.5 }}>
    <SVG fill={theme.palette.primary.dark} src={`/rotate.svg`} />
  </RotatingBox>
);

const variantIcon: { [id: string]: () => void } = {
  [Status.PENDING]: () => <RotatingLoader />,
  [Status.GATHERING]: () => <RotatingLoader />,
  [Status.RUNNING]: () => <RotatingLoader />,
  [Status.EXPORTING]: () => <RotatingLoader />,
  [Status.SUCCEEDED]: () => <CheckIcon fontSize="small" sx={{ ml: 0.5 }} />,
  [Status.READY_TO_EXPORT]: () => (
    <CheckIcon fontSize="small" sx={{ ml: 0.5 }} />
  ),
  [Status.FAILED]: () => (
    <WarningAmberOutlinedIcon fontSize="small" sx={{ ml: 0.5 }} />
  ),
  [Status.TERMINATED]: () => (
    <WarningAmberOutlinedIcon fontSize="small" sx={{ ml: 0.5 }} />
  ),
  [Status.UNKNOWN]: () => <MoreHorizIcon fontSize="small" sx={{ ml: 0.5 }} />,
};

const StatusIcon = ({ status }: { status?: Status | null }) => {
  const renderIcon = status ? variantIcon[status] : variantIcon[Status.UNKNOWN];
  return renderIcon();
};

export default StatusIcon;
