import {List, ListItem, Skeleton} from "@mui/material";
import {useParams} from "react-router-dom";
import {PipelineInput} from "../../../types";
import {useBoard} from "../../../services/boards";
import InputListItem from "./InputListItem";
import {FC} from "react"

export type InputListProps = {
  selectedInput?: PipelineInput | null;
  setSelectedInput: (input: PipelineInput | null) => void;
  isInputValid: (inputName: string) => boolean;
};

const InputList: FC<InputListProps> = (props) => {
  const { boardId }: { boardId?: any } = useParams();
  const { data: board } = useBoard(boardId);
  return (
    <List>
      {board
        ? board.pipelines[0].inputs.map((input) => (
            <InputListItem input={input} {...props} />
          ))
        : [1, 2].map((index) => (
            <ListItem button key={index}>
              <Skeleton variant="text" width={100} />
            </ListItem>
          ))}
    </List>
  );
};
export default InputList;
