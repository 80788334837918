import {ChangeEvent, FC, useState} from "react";
import {Box, Tab, Tabs, TextField} from "@mui/material";
import {FieldError} from "react-hook-form";
import {CircuitBoard, Parameter} from "../../../types";
import {KFP_PARAM_MAX_LENGTH, NewRunFormResult} from "../hooks/useNewRunForm";
import Header from "../Header";
import NavbarPaper from "../NavbarPaper";
import ParameterActions from "./ParameterActions";
import ParameterField from "./ParameterField";
import {useRunEvents} from "../../../hooks/metrics/runEvents";

enum ConfigurationTab {
  Parameters = "Parameters",
  Advanced = "Advanced",
}

type ParametersFormProps = {
  board: CircuitBoard;
  runForm: NewRunFormResult;
  onBack: () => void;
};

const filterAdvanceParameters = (
  tab: ConfigurationTab,
  parameters: Parameter[]
): Parameter[] =>
  parameters.filter(
    (param) =>
      (!param.is_advanced && tab === ConfigurationTab.Parameters) ||
      (param.is_advanced && tab === ConfigurationTab.Advanced)
  );

const ParametersForm: FC<ParametersFormProps> = ({
  board,
  runForm,
  onBack,
}) => {
  const { setValue, watch, errors } = runForm.methods;
  const pipeline = board.pipelines[0];
  const parametersTabsStatus = {
    hasParameters: pipeline.parameters.length > 0,
    hasBasicParams: pipeline.parameters.find((tabName) => !tabName.is_advanced),
    hasAdvancedParams: pipeline.parameters.find(
      (tabName) => tabName.is_advanced
    ),
  };
  const [selectedTab, setSelectedTab] = useState<ConfigurationTab>(
    parametersTabsStatus.hasBasicParams
      ? ConfigurationTab.Parameters
      : ConfigurationTab.Advanced
  );
  const getParameterError: (parameterName: string) => FieldError | undefined = (
    parameterName
  ) => errors?.parameters && errors?.parameters[parameterName];
  const { completeFlowEvent } = useRunEvents({
    boardId: board.identifier,
    boardTermplateId: board.template_id,
  });

  return (
    <NavbarPaper
      children={
        <>
          <Header
            title="Customizations"
            subtitle="Customize blocks for more accurate insights"
            onBack={onBack}
          />
          <Box>
            <TextField
              key="name"
              name="name"
              label="Run Name"
              value={watch("name")}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setValue("name", event.target.value as string);
              }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
              fullWidth
              required
            />
            <TextField
              key="description"
              name="description"
              label="Notes"
              value={watch("description")}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setValue("description", event.target.value as string);
              }}
              rows={6}
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
            />
          </Box>
          <Box marginBottom={2}>
            <Tabs
              orientation="horizontal"
              textColor="primary"
              indicatorColor="primary"
              value={selectedTab}
              onChange={(_, value) => setSelectedTab(value)}
            >
              {pipeline.parameters.find((tabName) => !tabName.is_advanced) && (
                <Tab value={ConfigurationTab.Parameters} label="Parameters" />
              )}
              {pipeline.parameters.find((tabName) => tabName.is_advanced) && (
                <Tab value={ConfigurationTab.Advanced} label="Advanced" />
              )}
            </Tabs>
          </Box>
          {filterAdvanceParameters(selectedTab, pipeline.parameters).map(
            (parameter: Parameter) => {
              const name = `parameters.${parameter.name}`;
              const label = parameter.name;
              return (
                <ParameterField
                  key={name}
                  helperText={parameter.description}
                  parameterType={parameter.type}
                  name={name}
                  label={label}
                  value={watch(name)}
                  onChange={(newValue: string | string[]) => {
                    setValue(
                      name,
                      typeof newValue === "string"
                        ? newValue.slice(0, KFP_PARAM_MAX_LENGTH)
                        : newValue,
                      { shouldValidate: true }
                    );
                  }}
                  error={getParameterError(parameter.name)}
                />
              );
            }
          )}
          <ParameterActions
            runForm={runForm}
            onRunStart={() => completeFlowEvent()}
          />
        </>
      }
    />
  );
};

export default ParametersForm;
