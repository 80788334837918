import {DataGrid, GridColumns} from "@mui/x-data-grid";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {FC} from "react"

const useStyles = makeStyles(() =>
  createStyles({
    dataGrid: {
      "&.MuiDataGrid-root": { border: 0 },
    },
  })
);

type SchemaPreviewTableProps = {
  data: Record<string, string>[];
};

const generateTableColumns = (data: Record<string, string>[]): GridColumns => {
  const colSet = data.reduce((colSet, currentRow) => {
    Object.keys(currentRow).forEach(colSet.add, colSet);
    return colSet;
  }, new Set<string>());
  return [...colSet].map((name) => ({
    field: name,
    headerName: name,
    width: 150,
  }));
};
const SchemaPreviewTable: FC<SchemaPreviewTableProps> = ({ data }) => {
  const classes = useStyles();
  const columns = generateTableColumns(data);
  return (
    <DataGrid
      className={classes.dataGrid}
      rows={data.map((row) => {
        row.id = row.id || Math.random().toString(36).substring(7);
        return row;
      })}
      columns={columns}
      hideFooter
      pageSize={10}
    />
  );
};

export default SchemaPreviewTable;
