import { Box, Typography, useTheme } from "@mui/material";
import SVG from "react-inlinesvg";
import dayjs from "../../utils.ts";
import { Flow } from "../../types";
import { FC } from "react";

export const SyncToWH: FC<{ flow: Flow }> = ({ flow }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "end", ml: 2 }}>
      <SVG src={`/synced-icon.svg`} />
      <Typography
        variant="body5"
        color={theme.palette.grey[400]}
        sx={{
          pl: 0.5,
        }}
      >
        {flow.getLastSyncDate()
          ? `Last sync to the data warehouse: ${dayjs(flow.getLastSyncDate())}`
          : ""}
      </Typography>
    </Box>
  );
};
