import {IconButton, InputAdornment, TextField} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import {FC} from "react"

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: "100%",
    },
  })
);

const RunsSearchBar: FC<{
  value: string;
  onChange: (text: string) => void;
}> = ({ value, onChange }) => {
  const classes = useStyles();
  return (
    <TextField
      label="Filter"
      variant="outlined"
      defaultValue={value}
      onChange={(event) => onChange(event.target.value as string)}
      className={classes.textField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => onChange(value)} size="large">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RunsSearchBar;
