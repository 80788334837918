import _ from "lodash";
import { FilterColumns, TransformedInsight, Filter } from "./types";
import { FrontEggTeamMember } from "../../types";

export const filterHandlers: Record<
  FilterColumns,
  (
    filterName: FilterColumns,
    values: Filter,
    insight: TransformedInsight,
    allUsers: FrontEggTeamMember[]
  ) => boolean
> = {
  brand: filterByValue,
  categoryId: filterByValue,
  strategy: filterByValue,
  assignedTo(
    _filterName: FilterColumns,
    values: Filter,
    insight: TransformedInsight,
    allUsers: FrontEggTeamMember[]
  ): boolean {
    return filterByAssigneesIncluded(insight, values, allUsers);
  },
};

function filterByValue(
  filterName: FilterColumns,
  values: Filter,
  insight: TransformedInsight
): boolean {
  const filteredValue = _.get(insight, filterName, "");
  return !(filteredValue in values);
}

function filterByAssigneesIncluded(
  insight: TransformedInsight,
  values: Record<string, boolean>,
  allUsers: FrontEggTeamMember[]
) {
  const assigneeIdsToFilterOut = Object.keys(values);
  const assigneesToKeep = allUsers.filter(
    (member) => !assigneeIdsToFilterOut.includes(member.id)
  );
  if (allUsers.length === assigneesToKeep.length) return true;
  return (
    assigneesToKeep.some((assignee) =>
      insight.assignees.includes(assignee.id)
    ) ||
    (!assigneesToKeep.length && !insight.assignees.length)
  );
}

export const generateFilterItemLabel = (
  filterName: FilterColumns,
  item: string,
  allUsers: FrontEggTeamMember[]
): string => {
  if (filterName === "assignedTo") {
    return allUsers.find((user) => user.id === item)?.name || item;
  }
  return item;
};
