import React, {
  ReactNode,
  //useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { SxProps } from "@mui/system";
import theme from "../../themes/light";
import { ArchievedIcon, MyTaskIcon } from "../../assets";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { Avatar } from "@mui/material";
import { useAuthorizedUsers } from "../../hooks/useUsers";
import { useTasksContext } from "./hooks/selectors";

const tabs = [
  {
    url: "/tasks/myTasks",
    icon: <MyTaskIcon />,
    label: "My Tasks",
  },
  // {
  //   url: "/tasks/createdDate",
  //   icon: <CreatedDateIcon />,
  //   label: "Created Date",
  // },
  {
    url: "/tasks/archive",
    icon: <ArchievedIcon />,
    label: "Archived",
  },
];

const BoxProps = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "0 0 0 14px",
  sx: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    borderBottom: "2px solid white",
  },
};
const ActiveProps = {
  color: theme.palette.text.link!, // fill: theme.palette.text.link!,
  // borderBottom: `3px solid ${theme.palette.text.link!}`,
  "& svg": {
    "& path": {
      fill: theme.palette.text.link!,
    },
  },
};
const ActivePropsAlert = {
  color: theme.palette.error.main, // fill: theme.palette.text.link!,
  borderBottom: `3px solid ${theme.palette.error.main}`,
  "& svg": {
    "& path": {
      fill: theme.palette.error.main,
    },
  },
  "& .MuiChip-filled": {
    backgroundColor: "#C70E241A",
    // color: theme.palette.error.main,
    opacity: 1,
  },
};
const Tab = ({
  url,
  icon,
  label,
  additionalSx,
  chip,
}: {
  url: string;
  icon?: ReactNode;
  label: string;
  additionalSx?: SxProps;
  chip?: ReactNode;
}) => {
  const history = useHistory();
  const location = useLocation();

  const sx = useMemo<SxProps>(() => {
    const currentPath = location.pathname.replace(/\/$/, "");
    const active = currentPath === url;
    let sx: SxProps = { ...BoxProps.sx };
    if (additionalSx) {
      sx = { ...sx, ...additionalSx };
    }
    if (active && currentPath.includes("/alerts")) {
      sx = { ...sx, ...ActivePropsAlert };
    } else if (active) {
      sx = { ...sx, ...ActiveProps };
    }
    return sx;
  }, [location.pathname, url, additionalSx]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs("2022-04-17"));

  const handleLabelClick = () => {
    setIsOpen(!isOpen);
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setIsOpen(false); // Close the DatePicker after selecting a date
  };

  return (
    <>
      {label === "Created Date" ? (
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          sx={{
            "& .MuiInputBase-input": { display: "none" },
            "& .MuiOutlinedInput-notchedOutline": { display: "none" },
            "& .MuiInputBase-formControl": {
              position: "absolute",
              bottom: -17,
              left: -18,
            },
            "& .MuiIconButton-root": {
              display: "none",
            },
            "& .MuiBox-root": {
              padding: 0,
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
          <Box
            onClick={handleLabelClick}
            {...BoxProps}
            sx={{
              position: "initial",
              right: 0,
              alignItems: "center",
              gap: "8px",
            }}
          >
            {icon} {label}
            {chip}
          </Box>
        </Box>
      ) : label === "My Tasks" ? (
        <Box
          {...BoxProps}
          sx={{
            position: "initial",
            right: 0,
            alignItems: "center",
            gap: "8px",

            "& .MuiButton-root": {
              "&:hover": {
                background: "transparent  ",
              },
              "&:focus": {
                background: "transparent  ",
              },
            },
          }}
        >
          <MyTask />
        </Box>
      ) : (
        <Box
          onClick={() => history.push(url)}
          {...BoxProps}
          sx={{
            ...sx,
            position: label === "Archived" ? "absolute" : "initial",
            right: 0,
            alignItems: "center",
            gap: "8px",
          }}
        >
          {label === "Archived" ? (
            <>
              {label} {icon}
            </>
          ) : (
            <>
              {icon} {label}
            </>
          )}
          {chip}
        </Box>
      )}
    </>
  );
};
const HeaderTabs = () => (
  <>
    <Stack
      direction="row"
      alignItems="center"
      gap="24px"
      marginTop="0px"
      position="relative"
    >
      {tabs.map((tab) => {
        const myChip = null;
        return <Tab key={tab.url} {...tab} chip={myChip} />;
      })}
    </Stack>
  </>
);

export default HeaderTabs;

const MyTask = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); //for menuitem
  //const history = useHistory();
  //const location = useLocation();
  const allMembersList = useAuthorizedUsers();
  const {
    selectedAssignee,
    setSelectedAssignee,
    setOtherSelectedMember,
    otherSelectedMember,
    selectedUsers,
    setSelectedUsers,
  } = useTasksContext();
  const allMembers = allMembersList?.filter(
    (el) => !el.email.includes("@noogata.com")
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAssignInfo = (email: string) => {
    if (allMembers?.length > 0) {
      const userData = allMembers?.find((el) => el.email === email);
      const { profileImage, name } = userData || {};
      const assignName = name?.substring(0, 10);
      const assignFirstName = name?.substring(0, 1);
      return {
        profileImage,
        name: assignName,
        assignFirstName: assignFirstName,
      };
    }
  };

  // const handleUserClick = (userId: string) => {
  //   const index = selectedUsers.indexOf(userId);
  //   if (index === -1) {
  //     setSelectedUsers([...selectedUsers, userId]);
  //     setSelectedAssignee([...selectedAssignee, userId]);
  //   } else {
  //     const updatedUsers = [...selectedUsers];
  //     const updatedAssignee = [...selectedAssignee];
  //     updatedUsers.splice(index, 1);
  //     updatedAssignee.splice(index, 1);
  //     setSelectedUsers(updatedUsers);
  //     setSelectedAssignee(updatedAssignee);
  //   }
  // };

  const handleUserClick = (userId: string) => {
    const index = selectedUsers.indexOf(userId);
    const updatedUsers = [...selectedUsers];
    const updatedAssignee = [...selectedAssignee];
    if (index === -1) {
      updatedUsers.push(userId);
      updatedAssignee.push(userId);
    } else {
      updatedUsers.splice(index, 1);
      updatedAssignee.splice(index, 1);
    }
    setSelectedUsers(updatedUsers);
    setSelectedAssignee(updatedAssignee);
    // const queryParams = updatedUsers
    // .map((userId: string) => `${userId}`)
    // .join("&");
    // localStorage.setItem("assignee", queryParams)
  };

  const handleOtherUser = (userId: string) => {
    const index = otherSelectedMember.indexOf(userId);
    if (index === -1) {
      setOtherSelectedMember([...otherSelectedMember, userId]);
    } else {
      const updatedUsers = [...otherSelectedMember];
      updatedUsers.splice(index, 1);
      setOtherSelectedMember(updatedUsers);
    }
  };

  // React.useEffect(() => {
  //   const queryParams = selectedUsers
  //     .map((userId: string) => `${userId}`)
  //     .join("&");
  //   history.push(`/tasks?${queryParams}`);
  // }, [selectedUsers, history]);

  // useEffect(()=>{
  //   const assignee = localStorage.getItem("assignee");
  //   const assigneeList = assignee && assignee !== '' ? assignee?.split("&")  : [];
  //   setSelectedUsers(assigneeList);
  // },[])

  return (
    <Box display="flex">
      {allMembers &&
        allMembers?.length > 0 &&
        allMembers?.splice(0, 4).map((el) => (
          <Tooltip title={el?.name} placement="bottom">
            <Stack
              onClick={() => handleUserClick(el?.id)}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                fontSize: "14px",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                // background: "#87A96B",
                zIndex: 10,
                outline: selectedUsers.includes(el?.id) ? "2px blue solid" : "",
                border: "solid 2px white ",
                marginInlineStart: "-8px",
                ":hover": { zIndex: 11, cursor: "pointer" },
              }}
            >
              <img
                src={el?.profileImage}
                style={{ borderRadius: "50%" }}
                width={"100%"}
                alt="user"
              />
            </Stack>
          </Tooltip>
        ))}

      {allMembers?.length > 0 ? (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Stack
              component={"div"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                background: theme.palette.grey[50],
                marginInlineStart: "-8px",
                zIndex: 0,
                cursor: "pointer",
                outline:
                  otherSelectedMember?.length > 0 ? "2px blue solid" : "",
                border: "solid 2px white ",
              }}
              onClick={handleClick}
            >
              +5
            </Stack>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            // onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                padding: 0,
                "& .MuiAvatar-root": {
                  width: 24,
                  height: 24,
                  ml: -0.5,
                  mr: 1,
                },

                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
                "& .MuiMenu-list ": { padding: 0 },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            {allMembers?.length > 0 &&
              allMembers?.slice(0, 4)?.map((el) => (
                <MenuItem
                  onClick={() => {
                    handleUserClick(el?.id);
                    handleOtherUser(el?.id);
                  }}
                  sx={{
                    display: "flex",
                    gap: "12px",
                    "&: focus": {
                      background: "",
                    },
                  }}
                >
                  <Checkbox checked={selectedUsers.includes(el?.id)} />{" "}
                  <Box display="flex" gap="5px" alignItems="center">
                    {el?.profileImage ? (
                      <img
                        src={el?.profileImage}
                        height="24px"
                        width="24px"
                        style={{ borderRadius: "50%" }}
                        alt="assignee"
                      />
                    ) : (
                      <Box
                        sx={{
                          height: "28.48px",
                          width: "28.48px",
                          borderRadius: "50%",
                          background: theme.palette.grey[50],
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {getAssignInfo(el?.email)?.assignFirstName}
                      </Box>
                    )}
                    <Typography sx={{ marginInline: "6px" }}>
                      {el?.name}
                    </Typography>{" "}
                  </Box>
                </MenuItem>
              ))}
          </Menu>
        </React.Fragment>
      ) : (
        ""
      )}
    </Box>
  );
};
