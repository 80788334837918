import {FC, useEffect} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Overview from "./Overview";

const Board: FC = () => {
  const {boardId}: { boardId?: any; section?: any } = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `/boards/${boardId}`) {
      history.replace(`/boards/${boardId}/overview`);
    }
  }, [true]);

  return <Overview />;
};
export default Board;
