import { Run } from "../types";
import { useStatusColor } from "./index";

export const useRunColor = (run?: Run): string => {
  const status = run && run.status;
  const warning =
    run && run.tracking_statistics?.message_severities?.warning > 0;
  return useStatusColor(status, warning);
};

export default useRunColor;
