import {Box, FormControl, InputLabel, MenuItem, Select, Typography,} from "@mui/material";
import {Controller} from "react-hook-form";
import {JSONSchema4TypeName} from "json-schema";
import {MappingBoxProps, Property} from "./MappingBox";
import SchemaTypeIcon from "../../../../components/SchemaTypeIcon";
import {FC} from "react"

export type MappingFieldType = "string" | "number" | "integer" | "boolean";
const defualtOrder: Record<JSONSchema4TypeName, number> = {
    string: 1,
    number: 2,
    integer: 2,
    boolean: 3,
    object: 9,
    array: 9,
    null: 9,
    any: 9,
};

const sortByType =
  (type: JSONSchema4TypeName) =>
  (a: Property, b: Property): number => {
    if (a[1].type === type) return -1;
    if (b[1].type === type) return 1;
    return defualtOrder[(a[1].type as JSONSchema4TypeName) || "any"] >
      defualtOrder[(b[1].type as JSONSchema4TypeName) || "any"]
      ? 1
      : -1;
  };

const MappingSelect: FC<MappingBoxProps> = ({
  property,
  fromProperties,
  control,
}) => (
  <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel id={property[0]}>Source column</InputLabel>
      <Controller
        name={property[0]}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            labelId={property[0]}
            label="Source column"
            required
            {...field}
          >
            {fromProperties
              .sort(sortByType(property[1].type as JSONSchema4TypeName))
              .map((option) => (
                <MenuItem key={option[0]} value={option[0]}>
                  <Box display="flex" alignItems="center">
                    <Box marginRight={1}>
                      <SchemaTypeIcon
                        type={
                          Array.isArray(option[1].type) ? "any" : option[1].type!
                        }
                      />
                    </Box>
                    <Typography variant="body1" noWrap>
                      {option[0]}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
          </Select>
        )}
      />
    </FormControl>
);

export default MappingSelect;
