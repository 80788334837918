import { useHistory } from "react-router";

import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { SPACES_ROUTE } from "../Router";
import theme from "../../../themes/light";

export const SpacesV2NavItem: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={SPACES_ROUTE === tab}
      icon={<StorefrontIcon sx={{ color: theme.palette.backgrounds.white }} />} // Render the icon as a component
      onClick={() => {
        setTab(SPACES_ROUTE);
        history.push(SPACES_ROUTE);
      }}
      label="Spaces"
    />
  );
};
