import { FC } from "react";
import { Button } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useHistory } from "react-router-dom";

const ViewDataButton: FC = () => {
  const history = useHistory();
  return (
    <Button
      variant="outlined"
      startIcon={<BarChartIcon />}
      sx={{
        color: "text.primary",
        backgroundColor: "white",
        fontWeight: 600,
        textTransform: "none",
        "&:hover": {
          backgroundColor: "grey.200",
        },
      }}
      onClick={() => history.push("/data")}
    >
      View in My data
    </Button>
  );
};

export default ViewDataButton;
