import { Typography } from "@mui/material";

const DismissedTrackItem = () => (
  <>
    <img
      src="/sales/alert-circle.svg"
      alt="Tracked items you dismiss will show up here"
    />
    <Typography variant="body1" fontWeight="600" marginTop="20px">
      Tracked items you dismiss will show up here
    </Typography>
  </>
);

export default DismissedTrackItem;
