import { Box, Typography } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { useCatalogEnrichmentContext } from "./selectors";
import { Log } from "./types";
import { timestampToString } from "./utils";

const formatLogMessage = (log: Log) => {
  const { message } = log;
  const timestamp = timestampToString(log.timestamp);

  const highlightedMessage = message
    .split(/(Completed|Error|Failed)/g)
    .map((part, index) => {
      if (part === "Completed") {
        return (
          <Typography component="span" key={index} style={{ color: "green" }}>
            {part}
          </Typography>
        );
      } else if (part === "Error" || part === "Failed") {
        return (
          <Typography component="span" key={index} style={{ color: "red" }}>
            {part}
          </Typography>
        );
      }
      return (
        <Typography
          component="span"
          key={index}
          style={{ color: "white", fontWeight: "normal" }}
        >
          {part}
        </Typography>
      );
    });

  return (
    <Typography
      component="span"
      style={{ color: "white", fontWeight: "normal" }}
    >
      {timestamp} {highlightedMessage}
    </Typography>
  );
};

const CatalogEnrichmentConsole: FC = () => {
  const { logs } = useCatalogEnrichmentContext();

  const consoleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <Box
      ref={consoleRef}
      sx={{
        width: "80%",
        height: "70%",
        marginTop: "10px",
        padding: "10px",
        overflowY: "auto",
        backgroundColor: "#444",
        color: "#fff",
        fontFamily: "monospace",
        fontSize: "14px",
        lineHeight: "1.5",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "4px",
        border: "1px solid #333",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      {logs.map((log: Log, index: number) => (
        <Box
          sx={{
            borderBottom: "1px solid #333",
            padding: "1rem 0",
          }}
        >
          <Typography
            key={index}
            sx={{ whiteSpace: "pre-wrap", color: "white" }}
          >
            {formatLogMessage(log)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CatalogEnrichmentConsole;
