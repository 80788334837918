import cubejs, { ResultSet } from "@cubejs-client/core";
import { ContextHolder } from "@frontegg/rest-api";
import { OutputQueryRequest } from "../../types";

export const generateApiClient = (circuitboardId: string, runId: string) =>
  cubejs("", {
    apiUrl: "/api/query/v1",
    headers: {
      "X-NOOGATA-RUN-ID": runId,
      "X-NOOGATA-BOARD-ID": circuitboardId,
      Authorization: ContextHolder.getAccessToken() || "",
    },
  });

export async function loadQuery(
  data: OutputQueryRequest
): Promise<ResultSet<any>> {
  return await generateApiClient(data.circuitboardId, data.runId).load(
    data.query,
    data.options
  );
}
