import {Card, CardHeader, Link, Skeleton, Typography} from "@mui/material";
import {useBoard} from "../../../../../services/boards";
import {FC} from "react"

const TriggerCard: FC<{ boardId: string }> = ({boardId}) => {
    const {data} = useBoard(boardId);
    return (
        <Card sx={{padding: 0.5}} variant="elevation" elevation={0}>
            <CardHeader
                title={
                    data ? (
                        <>
                            <Typography display="inline">This block will trigger </Typography>
              <Link display="inline" href={`/boards/${boardId}/overview`}>
                {data.displayName}
              </Link>
              <Typography display="inline">
                {" "}
                upon a successful completion
              </Typography>
            </>
          ) : (
            <Skeleton variant="text" />
          )
        }
        titleTypographyProps={{ variant: "body2" }}
        sx={{ padding: 1 }}
      />
    </Card>
  );
};
export default TriggerCard;
