import { FC, useMemo } from "react";
import { useTheme } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useLocation } from "react-router-dom";
import {
  useDisplayList,
  useInsightsContext,
  useIsAllDisplayedSelected,
} from "../hooks/selectors";
import { useSelectAll, useSortSetter } from "../hooks/setters";
import { columnWidths } from "../constants";
import { InsightRead } from "../types";
import { Icon } from "../../../components/Icon";
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";
import RevenueSortDropdown from "./RevenueSortDropdown";

const ArrowUp: FC = () => <Icon src="insights/arrow-up.svg" />;
const ArrowDown: FC = () => <Icon src="insights/arrow-down.svg" />;

const SortIconIndicator: FC<{ column: string }> = ({ column }) => {
  const { sort } = useInsightsContext();
  if (column !== sort.column) return null;
  if (sort.ascending) return <ArrowUp />;
  return <ArrowDown />;
};

const ListHeadButton: FC<{
  label: string;
  column: keyof InsightRead;
  sx?: SxProps;
}> = ({ label, column, sx }) => {
  const setSort = useSortSetter();
  return (
    <Button
      sx={{ minWidth: "110px", textAlign: "left", ...{ ...(sx || {}) } }}
      onClick={() => setSort(column)}
      data-testid={`insights-sort-by-${label}`}
    >
      <Stack width="100%" direction="row" spacing={1}>
        <Typography variant="caption" color="text.read">
          {label}
        </Typography>
        <SortIconIndicator column={column} />
      </Stack>
    </Button>
  );
};

const ListHead = () => {
  const theme = useTheme();
  const isAllSelected = useIsAllDisplayedSelected();
  const selectAll = useSelectAll();
  const { pathname } = useLocation();
  const showCheckbox = useMemo(
    () => !(pathname.search("done") > -1 || pathname.search("dismissed") > -1),
    [pathname]
  );
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);
  const insights = useDisplayList();

  return (
    <Box sx={{ background: theme.palette.backgrounds.secondary, paddingX: 4 }}>
      <Stack
        direction="row"
        padding="0 20px"
        maxHeight="36px"
        alignItems="center"
      >
        {showCheckbox && (
          <Checkbox
            sx={{ width: columnWidths.checkbox }}
            checked={isAllSelected}
            onClick={selectAll}
          />
        )}
        {revenueOverTimeEnabled ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            sx={{ ml: "16px" }}
            alignItems="center"
          >
            <Box>
              {insights?.length > 0 ? (
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {insights?.length} insights
                </Typography>
              ) : (
                <Skeleton width={theme.spacing(5)} />
              )}
            </Box>
            <Box>
              <RevenueSortDropdown />
            </Box>
          </Stack>
        ) : (
          <>
            <ListHeadButton label="Opportunity" column="type" />
            <Box width={columnWidths.info} sx={{ marginRight: "20px" }} />
            <Box sx={{ textAlign: "left" }} width={columnWidths.action}>
              <ListHeadButton label="Recommended Action" column="actionText" />
            </Box>
            <Box sx={{ textAlign: "left" }} width={columnWidths.rating}>
              <ListHeadButton label="Impacted Revenue" column="revenueValue" />
            </Box>
            <Box
              sx={{ textAlign: "center", flex: 1 }}
              width={columnWidths.social}
            >
              <ListHeadButton
                label="Assign"
                column="date"
                sx={{ minWidth: "20px", textAlign: "right" }}
              />
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ListHead;
