import { FC } from "react";
import RunLogsContent from "../../../../components/RunLogsContent";
import { useRun } from "../../../../services/runs";
import { BoardAndRunIds } from "../../../../types";

const LogsSection: FC<BoardAndRunIds> = ({ boardId, runId }) => {
  const { data: run } = useRun(boardId, runId);
  return <RunLogsContent run={run} />;
};
export default LogsSection;
