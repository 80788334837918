import {Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import FlowAlert from "./FlowAlert";
import useFlow from "../../services/Flows/useFlow";
import {Status} from "../../enums";
import {FC} from "react"

const Overview: FC<object> = () => {
    const {flowId}: { flowId: string } = useParams();
    const {data: flow} = useFlow(flowId);
    return (
        <Grid container>
            <Grid item xs={12} p={2}>
                {flow?.blocks
                    ?.filter(({last_run}) => last_run?.status === Status.FAILED)
                    .map((block) => (
            <FlowAlert
              key={`${block.identifier}-error-message`}
              runId={block.last_run!.id}
              errorPrefix={`'${block.displayName}'`}
              navigateTo={`/boards/${block.identifier}/runs/${block?.last_run?.id}`}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default Overview;
