import { FC } from "react";
import { Box, Paper, Stack, Tooltip } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useSalesAssistantStyles } from "../useSalesAssistantStyles";
import { TransformedInsight } from "../types";

type Size = "large" | "medium" | "small";
const useLayout = (size: Size) => {
  const {
    productImageLayout,
    productImageContainer,
    productImageLayoutMedium,
    productImageContainerMedium,
    productImageLayoutSmall,
    productImageContainerSmall,
  } = useSalesAssistantStyles();
  if (size === "large") {
    return {
      layout: productImageLayout,
      container: productImageContainer,
    };
  }
  if (size === "medium") {
    return {
      layout: productImageLayoutMedium,
      container: productImageContainerMedium,
    };
  }
  return {
    layout: productImageLayoutSmall,
    container: productImageContainerSmall,
  };
};
export const ProductImage: FC<{
  insight: Pick<TransformedInsight, "image_url">;
  useTooltip?: boolean;
  size?: "large" | "medium" | "small";
}> = ({ insight, useTooltip: _useTooltip, size = "large" }) => {
  const { layout, container } = useLayout(size);
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
          },
        },
      }}
      title={
        <Paper variant="elevation" elevation={5}>
          <Stack justifyContent="center" sx={{ height: 250, width: 250 }}>
            {insight.image_url ? (
              <Box
                component="img"
                alt="Product for the opportunity"
                src={insight.image_url}
                sx={{
                  height: "auto",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            ) : (
              <ImageIcon
                sx={{
                  height: "auto",
                  width: "auto",
                  objectFit: "contain",
                  padding: 10,
                }}
              />
            )}
          </Stack>
        </Paper>
      }
    >
      <Stack justifyContent="center" className={layout}>
        {insight.image_url ? (
          <Box
            component="img"
            alt="Product for the opportunity"
            src={insight.image_url}
            className={container}
            sx={{ objectFit: "contain" }}
          />
        ) : (
          <ImageIcon
            sx={{
              height: "auto",
              width: "auto",
              objectFit: "contain",
            }}
          />
        )}
      </Stack>
    </Tooltip>
  );
};
