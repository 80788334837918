import { Button, Tooltip } from "@mui/material";

import { useHistory } from "react-router";
import { useAppContext } from "../ApplicationContext";
import { AppModeIcon, DataLabModeIcon } from "./AppModeButton";
import { FC } from "react";

const AppModeClosedButton: FC = () => {
  const history = useHistory();
  const { applicationMode, setApplicationMode } = useAppContext();

  return (
    <Tooltip
      placement="top-start"
      title={applicationMode ? "Enter Data Lab" : "Back to App"}
    >
      <Button
        variant="contained"
        onClick={() => {
          setApplicationMode(!applicationMode);
          history.push("/");
        }}
      >
        {applicationMode ? <DataLabModeIcon /> : <AppModeIcon />}
      </Button>
    </Tooltip>
  );
};

export default AppModeClosedButton;
