/* eslint-disable no-prototype-builtins */
import { Features } from "../types";

export const attributesEqual = (
  currentAttributes: Features[],
  newAttributes: Features[]
) => {
  // Sort the attributes by attribute name
  const sortedCurrentAttributes = [...currentAttributes].sort((a, b) =>
    a.attribute.localeCompare(b.attribute)
  );
  const sortedNewAttributes = [...newAttributes].sort((a, b) =>
    a.attribute.localeCompare(b.attribute)
  );

  if (sortedCurrentAttributes.length !== sortedNewAttributes.length) {
    return false;
  }

  if (
    sortedCurrentAttributes.length === 0 &&
    sortedNewAttributes.length === 0
  ) {
    return true;
  }

  // Compare the attributes
  for (let i = 0; i < sortedCurrentAttributes.length; i++) {
    if (
      sortedCurrentAttributes[i].attribute !==
        sortedNewAttributes[i].attribute ||
      sortedCurrentAttributes[i].description !==
        sortedNewAttributes[i].description ||
      sortedCurrentAttributes[i].type !== sortedNewAttributes[i].type ||
      sortedCurrentAttributes[i].closed_list !==
        sortedNewAttributes[i].closed_list
    ) {
      return false;
    }

    // Compare accepted values
    const currentAcceptedValues = new Set([
      ...(sortedCurrentAttributes[i].accepted_values || []),
    ] as string[]);
    const newAcceptedValues = new Set([
      ...(sortedNewAttributes[i].accepted_values || []),
    ] as string[]);
    if (currentAcceptedValues.size !== newAcceptedValues.size) {
      return false;
    }
    for (const value of currentAcceptedValues) {
      if (!newAcceptedValues.has(value)) {
        return false;
      }
    }
    for (const value of newAcceptedValues) {
      if (!currentAcceptedValues.has(value)) {
        return false;
      }
    }
  }

  return true;
};

export const validateAttributes = (
  attributes: Features[],
  setInvalidAttributesMessage: any
) => {
  if (attributes.some((attr) => !attr.attribute)) {
    setInvalidAttributesMessage("Feature Name is required");
    return;
  } else if (attributes.some((attr) => !/^[a-zA-Z\s]*$/.test(attr.attribute))) {
    setInvalidAttributesMessage(
      "Feature name should contain only letters and spaces"
    );
    return;
  } else if (attributes.some((attr) => !attr.description)) {
    setInvalidAttributesMessage("Description is required");
    return;
  } else if (
    attributes.some((attr) => attr.closed_list && !attr.accepted_values)
  ) {
    setInvalidAttributesMessage("Accepted values are required for closed list");
    return;
  }

  const attributeNames = new Set<string>();

  for (const attr of attributes) {
    if (attributeNames.has(attr.attribute.toLowerCase())) {
      setInvalidAttributesMessage("Feature Name should be unique");
      return;
    }
    attributeNames.add(attr.attribute.toLowerCase());

    if (attr.closed_list) {
      if (attr.accepted_values.length === 0) {
        setInvalidAttributesMessage(
          "Accepted values should not be empty for closed list"
        );
        return;
      }
      const attributeType = attr.type;
      for (const value of attr.accepted_values) {
        if (attributeType === "int") {
          if (isNaN(parseInt(value as string))) {
            setInvalidAttributesMessage(
              `Accepted values should be integers for int type`
            );
            return;
          }
        }
        if (attributeType === "float") {
          if (isNaN(parseFloat(value as string))) {
            setInvalidAttributesMessage(
              `Accepted values should be floats for float type`
            );
            return;
          }
        }
      }
    }
  }

  setInvalidAttributesMessage(null);
};

export const validateAttributesJson = (jsonText: string) => {
  try {
    const attributes = JSON.parse(jsonText);
    if (!Array.isArray(attributes)) {
      return "Features should be an array";
    }

    const attributeNames = new Set<string>();

    for (const attr of attributes) {
      if (typeof attr !== "object") {
        return "Each feature should be an object";
      }
      if (
        !attr.hasOwnProperty("attribute") ||
        !attr.hasOwnProperty("description") ||
        !attr.hasOwnProperty("type") ||
        !attr.hasOwnProperty("closed_list") ||
        !attr.hasOwnProperty("accepted_values")
      ) {
        return "All fields are required";
      }
      if (typeof attr.attribute !== "string") {
        return "Feature Name should be a string";
      }
      if (!attr.attribute) {
        return "Feature Name is required";
      }
      if (!/^[a-zA-Z\s]*$/.test(attr.attribute)) {
        return "Feature Name should contain only letters and spaces";
      }
      if (attributeNames.has(attr.attribute.toLowerCase())) {
        return "Feature Name should be unique";
      }
      attributeNames.add(attr.attribute.toLowerCase());
      if (
        attr.type !== "string" &&
        attr.type !== "int" &&
        attr.type !== "float" &&
        attr.type !== "boolean"
      ) {
        return "Invalid Type";
      }
      if (typeof attr.description !== "string") {
        return "Description should be a string";
      }
      if (!attr.description) {
        return "Description is required";
      }
      if (typeof attr.closed_list !== "boolean") {
        return "Closed List should be a boolean";
      }
      if (attr.closed_list) {
        if (!Array.isArray(attr.accepted_values)) {
          return "Accepted values should be an array for closed list";
        }
        if (attr.accepted_values.length === 0) {
          return "Accepted values should not be empty for closed list";
        }
        const attributeType = attr.type;
        for (const value of attr.accepted_values) {
          if (attributeType === "string" && typeof value !== "string") {
            return "Accepted values should be strings for string type";
          }
          for (const value of attr.accepted_values) {
            if (attributeType === "int") {
              if (isNaN(parseInt(value as string))) {
                return "Accepted values should be integers for int type";
              }
            }
            if (attributeType === "float") {
              if (isNaN(parseFloat(value as string))) {
                return "Accepted values should be floats for float type";
              }
            }
          }
        }
      }
    }
    return null;
  } catch (e) {
    return "Invalid JSON";
  }
};
