import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useConfirmation } from "../../../../components/ConfirmationDialog";
import { useRun } from "../../../../services/runs";
import { publishRun } from "../../../../services/runs/api";
import dayjs from "../../../../utils";

const usePublishOnClick = (boardId: string, runId: string): (() => void) => {
  const { data: run } = useRun(boardId, runId);
  const confirm = useConfirmation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const onPublishClick = () => {
    confirm({
      title: `You are about to publish data-run "${run?.name}", ${dayjs(
        run?.finished_at
      ).format("L")}.`,
      description: "Users will see this data in their dashboard.",
    }).then(OnConfirmPublish);
  };

  const OnConfirmPublish = async () => {
    try {
      const newPublishedRun = await publishRun(boardId, runId);
      queryClient.setQueryData(["publishedRun", boardId], newPublishedRun);
      enqueueSnackbar("Run published successfully", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar("Run publish has failed", { variant: "error" });
    }
  };
  return onPublishClick;
};

export default usePublishOnClick;
