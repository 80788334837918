import List from "@mui/material/List";
import { Box, LinearProgress, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import Alert from "@mui/material/Alert";
import { InsightsQueryParams } from "../constants";
import { useInsightsStyles } from "../useInsightsStyles";
import { useDisplayList, useInsightsContext } from "../hooks/selectors";
import { useInsightFeedEvent } from "../../../hooks/metrics/useInsightEvent";
import { TransformedInsight } from "../types";
import ListHead from "./ListHead";
import { Icon } from "../../../components/Icon";
import EmptyStates from "./EmptyStates";
import VirtualList from "./VirtualList";

export const onClickRow = (
  isDrawerEnabled: boolean,
  queryParams: URLSearchParams,
  history: ReturnType<typeof useHistory>,
  insight: TransformedInsight,
  logEvent: ReturnType<typeof useInsightFeedEvent>
) => {
  if (!isDrawerEnabled) return;
  logEvent({ insight, action: "Expand" });
  queryParams.append(InsightsQueryParams.InsightId, insight.id);
  history.replace({ search: queryParams.toString() });
};

const listStylesOverrides = {
  "& .MuiListItem-root": {
    width: "100%",
    cursor: "pointer",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
};
const InsightsList = ({
  setInsightIdForDialog,
}: {
  setInsightIdForDialog: (str: string) => void;
}) => {
  const { isLoadingInsights, isFetchingInsights } = useInsightsContext();
  const displayList = useDisplayList();
  const { mainList } = useInsightsStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { hash } = useLocation();
  const [alertHeight, setAlertHeight] = useState(0);

  useEffect(() => {
    if (document.getElementById(`insight-id-${hash.substring(1)}`)) {
      document
        .getElementById(`insight-id-${hash.substring(1)}`)!
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [hash, displayList]);

  const showAlert = useMemo(
    () => !!(pathname.search("dismissed") > -1 || pathname.search("done") > -1),
    [pathname]
  );

  const onScroll = useCallback(
    (e: any) => {
      if (showAlert) {
        setAlertHeight(Math.max(-75, 0 - e.target.scrollTop));
      }
    },
    [showAlert, displayList.length]
  );

  if (isLoadingInsights) {
    return <LinearProgress />;
  }
  if (!displayList || displayList.length === 0) {
    return <EmptyStates />;
  }
  return (
    <>
      {isFetchingInsights && <LinearProgress />}
      <Box
        sx={{
          overflow: "hidden",
          background: theme.palette.backgrounds.secondary,
          flex: 1,
        }}
      >
        {showAlert && (
          <Box
            paddingTop="16px"
            paddingX="35px"
            width="100%"
            sx={{
              transform: `translateY(${alertHeight}px)`,
              height: `${75 + alertHeight}px`,
            }}
          >
            <Alert
              icon={false}
              sx={{
                background: theme.palette.backgrounds.active,
                color: theme.palette.text.link,
                fill: theme.palette.text.link,
              }}
            >
              <Stack direction="row" gap="8px" alignItems="center">
                <Icon src="insights/info-fill.svg" width="20px" height="20px" />
                {pathname.search("dismissed") > -1
                  ? "Dismissed insights will reappear in your feed if updated."
                  : "Done insights represent their data at the time of completion"}
              </Stack>
            </Alert>
          </Box>
        )}
        <List
          className={mainList}
          data-testid="insights-list"
          key="boo"
          sx={{
            minHeight: "100%",
            paddingTop: 0,
            ...listStylesOverrides,
            background: theme.palette.backgrounds.secondary,
          }}
          onScroll={onScroll}
        >
          <Stack
            sx={{
              maxWidth: theme.spacing(1300 / 8),
              marginLeft: "auto",
              marginRight: "auto",
              height: "inherit",
            }}
          >
            <ListHead />
            <VirtualList setInsightIdForDialog={setInsightIdForDialog} />
          </Stack>
        </List>
      </Box>
    </>
  );
};

export { InsightsList };
