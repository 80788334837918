import { Stack } from "@mui/material";
import theme from "../../../../themes/light.ts";
import { useMemo } from "react";
import { tabsConfig } from "./enums.ts";
import Question from "./Question.tsx";

const MainArea = ({ activeTab }: { activeTab: string }) => {
  const tabData = useMemo(() => {
    return tabsConfig.find((tab) => tab.value_name === activeTab);
  }, [activeTab]);

  return (
    <Stack
      flex={10}
      sx={{
        background: theme.palette.backgrounds.secondary,
        borderRadius: "0 8px 8px 8px",
        padding: "16px",
        height: "100%",
        overflow: "auto",
      }}
      gap="16px"
    >
      {tabData?.data?.map((question, index) => (
        <Question key={`question-${index}`} question={question} />
      ))}
    </Stack>
  );
};

export default MainArea;
