import {IconButton} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useHistory} from "react-router-dom";
import {useConfirmation} from "../../../../../components/ConfirmationDialog";
import {Trigger} from "../../../../../types";
import {deleteTrigger} from "../../../../../services/triggers/api";
import TriggeredByDrawer from "./TriggeredByDrawer";
import {FC, MouseEvent, useState} from "react"

const TriggeredByMenu: FC<{ trigger: Trigger }> = ({trigger}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleViewClick = () => {
    handleMenuClose();
    setDrawerOpen(true);
  };
  const handleEditClick = () => {
    handleMenuClose();
    history.push(
      `/boards/${trigger.board_id}/runs/edit?triggerId=${trigger.id}`
    );
  };

  const confirm = useConfirmation();
  const onConfirmDelete = async () => {
    try {
      await deleteTrigger(trigger.id);
      await queryClient.invalidateQueries(["board", trigger.board_id]);
      enqueueSnackbar("Successfully deleted trigger", {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar("Unable to delete trigger", { variant: "error" });
    }
  };
  const handleDelete = () => {
    handleMenuClose();
    confirm({
      title: `Are you sure you want to delete trigger ${trigger.data.name}`,
    }).then(onConfirmDelete);
  };
  return (
    <>
      <div>
        <IconButton id="long-button" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem key="View" onClick={handleViewClick}>
            View
          </MenuItem>
          <MenuItem key="Edit" onClick={handleEditClick}>
            Edit
          </MenuItem>
          <MenuItem key="Delete" onClick={handleDelete}>
            Delete
          </MenuItem>
        </Menu>
      </div>
      <TriggeredByDrawer
        trigger={trigger}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
};

export default TriggeredByMenu;
