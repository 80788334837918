import {Button, Tooltip} from "@mui/material";
import {Link as LinkIcon} from "@mui/icons-material";
import {useAuthUser} from "@frontegg/react";
import {useParams} from "react-router";
import {FC} from "react"

export const CopyFlowUrl: FC<object> = () => {
    const user = useAuthUser();
    const url = new URL(window.location.href);
    const {flowId}: { flowId: string } = useParams();
    return (
        <Tooltip title="Copy flow url">
            <Button
                variant="outlined"
                onClick={() =>
          navigator.clipboard.writeText(
            `${url.origin}/flows/${flowId}?tenantId=${user.tenantId}`
          )
        }
      >
        <LinkIcon fontSize="small" sx={{ cursor: "pointer" }} />
      </Button>
    </Tooltip>
  );
};
