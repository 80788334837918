import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { DestinationType } from "../../../../../enums";
import { DestinationTitleMapping } from "../../../../../mappings";
import { FILE_EXPORT_TYPES } from "./utils";

export const FileTypeSelect: FC<{
  value: DestinationType;
  setValue: (type: DestinationType) => void;
}> = ({ value, setValue }) => (
  <FormControl fullWidth>
    <InputLabel id="export-file-type-label">File Type</InputLabel>
    <Select
      labelId="export-file-type-label"
      id="export-file-type"
      label="File Type"
      value={value}
      onChange={(event) => setValue(event.target.value as DestinationType)}
    >
      {Object.values(FILE_EXPORT_TYPES).map((t: DestinationType) => (
        <MenuItem value={t} key={t}>
          {DestinationTitleMapping[t]}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default FileTypeSelect;
