import {Card, CardHeader} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RunsHeatMap from "../../../../components/RunsHeatMap";
import {FC} from "react"

type HeatmapCardProps = {
  boardId: string;
};
const useStyles = makeStyles((theme) => ({
  greyIndicator: {
    borderTop: `${theme.spacing(0.5)} solid ${theme.palette.grey[500]}`,
  },
}));
const HeatmapCard: FC<HeatmapCardProps> = ({ boardId }) => (
  <Card className={useStyles().greyIndicator}>
    <CardHeader
      title="Activity in the last 120 days"
      titleTypographyProps={{ variant: "h6" }}
    />
    <RunsHeatMap boardId={boardId} days={120} />
  </Card>
);

export default HeatmapCard;
