import { useHistory, useRouteMatch } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { FC } from "react";
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";

export const SalesInsightsNavItem: FC = () => {
  const pageMatch = useRouteMatch({ path: ["/sales-assistant/"] });
  const history = useHistory();
  const isGroup = useSplitEnabled(Split.SALES_ASSISTANT_GROUPS);

  return (
    <LeftNavBarItem
      selected={!!pageMatch}
      icon={
        <SVG
          src={"/sales/insights.svg"}
          fill="inherit"
          width={15}
          height={15}
        />
      }
      // onClick={() => history.push(url)}
      onClick={() =>
        history.push(
          isGroup ? "/sales-assistant/home" : "/sales-assistant/products"
        )
      }
      label="Sales Assistant"
    />
  );
};
