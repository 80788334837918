import { Box, Tooltip, Typography } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useLocation } from "react-router";
import { Cancel } from "@mui/icons-material";
import theme from "../../../../themes/light";
import { Icon } from "../../../../components/Icon";
import { SalesAssistantRead } from "../../types";

interface Props {
  // handleClose: () => void;
  handleAlerttoDissmissed?: any;
  alertId?: string;
  handleRemoveProductFromTracking?: any;
  removeAlertFromDismissed?: any;
  insight: SalesAssistantRead;
  handleAlertsTracking?: any;
}

const SalesButton = ({
  // handleClose,
  handleAlerttoDissmissed,
  alertId,
  handleRemoveProductFromTracking,
  removeAlertFromDismissed,
  insight,
  handleAlertsTracking,
}: Props) => {
  const handleCancel = (snackbarId: any) => {
    closeSnackbar(snackbarId);
  };
  const { pathname } = useLocation();
  const handleUndo = (value: string) => {
    if (value === "Alert Dismissed") {
      removeAlertFromDismissed();
    }
  };
  const action = (snackbarId: any, value: any) => (
    <>
      {pathname.includes("/tracking") && !insight?.tracked && (
        <Typography
          variant="body4"
          sx={{ color: "white", cursor: "pointer" }}
          onClick={() => handleUndo(value)}
        >
          Undo
        </Typography>
      )}
      <Typography
        variant="body4"
        sx={{
          color: "white",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => handleCancel(snackbarId)}
      >
        <Cancel />
      </Typography>
    </>
  );

  const handleClick = (value: any) => {
    if (value === "close") {
      enqueueSnackbar("Custom snackbar message", {
        variant: "success",
        content: (key) => (
          <Box
            display="flex"
            alignItems="center"
            gap="16px"
            sx={{
              background: theme.palette.text.primary,
              minHeight: " 70px",
              padding: "4px 0px",
              borderRadius: "4px",
              justifyContent: "space-between",
            }}
          >
            <Box
              display="flex"
              alignItems="flex-start"
              gap="8px"
              sx={{ padding: "8px 20px" }}
            >
              <Box>
                <Typography
                  variant="body4"
                  display="block"
                  sx={{
                    fontSize: "15px",
                    color: "white",
                    margin: 0,
                    lineHeight: "24px",
                  }}
                >
                  Product dismissed from Home
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              gap="16px"
              alignItems="center"
              sx={{ paddingRight: "16px" }}
            >
              {action(key, value)}
            </Box>
          </Box>
        ),
      });
    } else {
      enqueueSnackbar("Custom snackbar message", {
        variant: "success",
        content: (key) => (
          <Box
            display="flex"
            alignItems="center"
            gap="16px"
            sx={{
              background: theme.palette.text.primary,
              minHeight: " 70px",
              padding: "4px 0px",
              borderRadius: "4px",
              justifyContent: "space-between",
            }}
          >
            <Box
              display="flex"
              alignItems="flex-start"
              gap="8px"
              sx={{ padding: "8px 20px" }}
            >
              <Box>
                <Typography
                  variant="body4"
                  display="block"
                  sx={{
                    fontSize: "15px",
                    color: "white",
                    margin: 0,
                    lineHeight: "24px",
                  }}
                >
                  {value}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              gap="16px"
              alignItems="center"
              sx={{ paddingRight: "16px" }}
            >
              {action(key, value)}
            </Box>
          </Box>
        ),
      });
    }
  };
  return (
    <Box display="flex" gap="20px" alignItems="center">
      {pathname.search("alerts") > -1 && (
        <>
          <Tooltip
            title={insight?.tracked ? "Untrack" : "Track"}
            placement="top"
            arrow
          >
            <Typography
              onClick={handleAlertsTracking}
              sx={{
                svg: {
                  path: {
                    fill: theme.palette.text.primary,
                  },
                },
              }}
            >
              <Icon
                src={`${
                  insight?.tracked
                    ? "sales/tracking-fill.svg"
                    : "sales/bookmark-plus.svg"
                }`}
                height="18px"
                width="18px"
              />
            </Typography>
          </Tooltip>
          <Tooltip title="Dismiss alert" placement="top" arrow>
            <Typography
              onClick={() => {
                handleClick("Alert Dismissed");
                handleAlerttoDissmissed(alertId);
              }}
            >
              <Icon src="sales/close.svg" height="20px" width="20px" />
            </Typography>
          </Tooltip>
        </>
      )}
      {pathname.search("dismissed") > -1 && (
        <Tooltip title="Remove Dismissed" placement="top" arrow>
          <Typography onClick={() => removeAlertFromDismissed(alertId)}>
            <Icon src="sales/close.svg" height="20px" width="20px" />
          </Typography>
        </Tooltip>
      )}
      {pathname.search("tracking") > -1 && (
        <Tooltip title="Dismiss product" placement="top" arrow>
          <Typography
            onClick={() => {
              handleClick("Item removed from tracking List");
              handleRemoveProductFromTracking();
            }}
          >
            <Icon src="sales/close.svg" height="20px" width="20px" />
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};
export default SalesButton;
