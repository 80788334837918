import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { DataSource, MANUAL_DATA_SOURCE } from "../../types/datasources";
import { APIError } from "../apiRequest";
import { listDataSources } from "./api";

const useDataSources = (
  options?: UseQueryOptions<DataSource[], APIError>
): UseQueryResult<DataSource[], APIError> =>
  useQuery<DataSource[], APIError>(
    ["ListDataSources"],
    () => listDataSources().then((dsList) => [MANUAL_DATA_SOURCE, ...dsList]),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export default useDataSources;
