import {Avatar, Box, Button, Divider, Popover, Stack, Typography, useTheme,} from "@mui/material";
import SVG from "react-inlinesvg";

import {useLocation} from "react-router-dom";
import {useDisplayedSelectedInsights, useSelectedSum,} from "./hooks/selectors";
import {Summary} from "./Summary";
import {useDeselectAll, useUpdateDisplayedSelected} from "./hooks/setters";
import {Icon} from "../../components/Icon";
import {AssignTeamMember} from "./List/AssignTeamMember";
import {FC, MouseEvent, ReactNode, useState} from "react"

const CurrentlySelected: FC<{ selectedCount: number }> = ({
                                                              selectedCount,
                                                          }) => (
    <Stack direction="row" alignItems="center" spacing={2} marginLeft="20px">
        <Avatar sx={{bgcolor: "primary.main", width: "24px", height: "24px"}}>
            <Typography variant="headline4" color="text.dark">
                {selectedCount}
            </Typography>
        </Avatar>
        <Typography variant="headline4">Insights Selected</Typography>
    </Stack>
);

const ActionsLayout = ({children}: { children: ReactNode }) => (
    <Stack direction="row" spacing={0} alignItems="flex-start" padding="0px 8px">
        {children}
    </Stack>
);

const Actions: FC = () => {
    const updateAll = useUpdateDisplayedSelected();
    const theme = useTheme();
    const {pathname} = useLocation();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const displayedSelectedInsights = useDisplayedSelectedInsights();

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <ActionsLayout>
            <Button size="small" sx={{marginX: "20px"}}>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="8px"
                    sx={{
                        color: theme.palette.text.primary,
                        minHeight: "18px",
                        padding: "3px",
                        fontSize: "14px",
                        fontWeight: "600",
                        maxHeight: "24px",
                        height: "24px",
                    }}
                    onClick={(e) => {
                        handleClick(e);
                    }}
                >
                    <Icon src="insights/assign-fill.svg" width="16px" height="16px"/>
          Assign
        </Stack>
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!anchorEl}
      >
        <AssignTeamMember
            // @ts-ignore
          insightsIds={[...displayedSelectedInsights]}
          closePopover={() => setAnchorEl(null)}
        />
      </Popover>
      {pathname.search("my-items") > -1 ? (
        <Button size="small">
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            sx={{
              color: theme.palette.text.primary,
              minHeight: "18px",
              padding: "3px",
              fontSize: "14px",
              fontWeight: "600",
              maxHeight: "24px",
              height: "24px",
            }}
            onClick={() => updateAll("markAsDone")}
          >
            <Icon src="insights/check-fill.svg" width="16px" height="16px" />
            Mark as Done
          </Stack>
        </Button>
      ) : (
        <Button size="small">
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            sx={{
              color: theme.palette.text.primary,
              minHeight: "18px",
              padding: "3px",
              fontSize: "14px",
              fontWeight: "600",
              maxHeight: "24px",
              height: "24px",
            }}
            onClick={() => updateAll("moveToMyList")}
          >
            <Icon
              src="insights/add-to-my-items.svg"
              width="16px"
              height="16px"
            />
            Save to My Items
          </Stack>
        </Button>
      )}
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          maxHeight: "24px",
          height: "24px",
          marginY: "4px",
          marginX: "20px",
        }}
      />
      <Button size="small">
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          sx={{
            color: theme.palette.text.primary,
            minHeight: "18px",
            padding: "3px",
            fontSize: "14px",
            fontWeight: "600",
            maxHeight: "24px",
            height: "24px",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateAll("dismiss");
          }}
        >
          Dismiss
        </Stack>
      </Button>
      {/* <ActionButton */}
      {/*  icon="insights/check-fill.svg" */}
      {/*  label="Mark done" */}
      {/*  data-testid="bulk-mark-done" */}
      {/*  onClick={() => updateAll({ done: true })} */}
      {/* /> */}
      {/* <ProtectedComponentWithRoles */}
      {/*  permissions={[PlatformPermission.INSIGHTS_UPDATE]} */}
      {/* > */}
      {/*  <ActionButton */}
      {/*    icon="insights/archive.svg" */}
      {/*    label="Dismiss" */}
      {/*    data-testid="bulk-mark-archive" */}
      {/*    onClick={() => updateAll({ archived: true })} */}
      {/*  /> */}
      {/* </ProtectedComponentWithRoles> */}
    </ActionsLayout>
  );
};

const BulkActionLayout = ({children}: { children: ReactNode }) => (
    <Stack
        width="100%"
        position="sticky"
        height="0px"
        margin="auto"
        bottom="90px"
    >
        <Stack
            direction="row"
            alignItems="center"
            padding="0px"
            margin="auto"
            borderRadius="8px"
            boxShadow="0px 20px 24px -6px rgba(44, 34, 76, 0.1), 0px 12px 45px 20px rgba(44, 34, 76, 0.05)"
            spacing={5}
            data-testid="bulk-actions"
            sx={{background: "white"}}
        >
            {children}
        </Stack>
    </Stack>
);

export const BulkAction: FC = () => {
  const { potential } = useSelectedSum();
  const selectedCount = useDisplayedSelectedInsights().size;
  const deselectAll = useDeselectAll();
  const theme = useTheme();
  return (
    <BulkActionLayout>
      <CurrentlySelected selectedCount={selectedCount} />
      <Box sx={{ zoom: 0.7 }}>
        <Summary compact pottentialValue={potential} />
      </Box>
      <Actions />
      <Stack
        borderLeft={`1px solid ${theme.palette.borders.default}`}
        height="64px"
        width="60px"
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <SVG
          height="15px"
          width="15px"
          fill={theme.palette.icons.secondary}
          src={"/insights/close.svg"}
          onClick={deselectAll}
        />
      </Stack>
    </BulkActionLayout>
  );
};
