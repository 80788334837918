import { useQuery, UseQueryOptions } from "react-query";
import apiRequest, { APIError } from "../../services/apiRequest";
import { BaseInsight, FlexInsight } from "./types";

const USE_BASE_INSIGHTS = "useBaseInsights" as const;

function getFlexInsightSchema(): Promise<Partial<FlexInsight>> {
  return apiRequest<Partial<FlexInsight>>(
    "insights/v2/base/flex/schema",
    "GET"
  );
}

function getBaseInsightV2(insightId: string): Promise<FlexInsight> {
  return apiRequest<FlexInsight>(`insights/v2/base/${insightId}`, "GET", {
    insight_id: insightId,
  }).then(FlexInsight.fromObject);
}

export function updateBaseInsightV2(
  insight: BaseInsight
): Promise<FlexInsight> {
  return apiRequest<FlexInsight>(
    `insights/v2/base/${insight.id}`,
    "PATCH",
    undefined,
    insight
  ).then(FlexInsight.fromObject);
}

export function createFlexBaseInsightV2(
  insight: Partial<BaseInsight>
): Promise<FlexInsight> {
  return apiRequest<FlexInsight>(
    "insights/v2/base",
    "POST",
    undefined,
    insight
  ).then(FlexInsight.fromObject);
}

export function deleteBaseInsightV2(insightId: string): Promise<void> {
  return apiRequest<void>(`insights/v2/base/${insightId}`, "DELETE");
}

export function useBaseInsightV2(
  insightId: string | null,
  options: UseQueryOptions<FlexInsight, APIError> = {}
) {
  return useQuery<FlexInsight, APIError>(
    [USE_BASE_INSIGHTS, insightId],
    () => getBaseInsightV2(insightId as string),
    {
      ...options,
      enabled: Boolean(
        options.enabled || (options.enabled == null && insightId)
      ),
    }
  );
}

export function useFlexSchema(
  options: UseQueryOptions<Partial<FlexInsight>, APIError> = {}
) {
  return useQuery<Partial<FlexInsight>, APIError>(
    ["useFlexSchema"],
    () => getFlexInsightSchema(),
    {
      ...options,
      enabled: options.enabled == null || Boolean(options.enabled),
    }
  );
}
