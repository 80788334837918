import {Collapse, Grid, Typography} from "@mui/material";
import NowRunning from "./components/NowRunning";
import RecentRuns from "./components/RecentRuns";
import PublishedCard from "./components/PublishedCard";
import {SchedulesCard} from "./components/Schedule";
import DescriptionCard from "./components/DescriptionCard";
import HeatmapCard from "./components/HeatmapCard";
import {useRuns} from "../../../services/runs";
import {Status} from "../../../enums";
import {BoardTriggersCard} from "./components/Triggers";
import {BoardTriggeredByCard} from "./components/TriggeredBy";
import {FC} from "react"

const OverviewSection: FC<{
  boardId: string;
}> = ({boardId}) => {
  const {data: runs} = useRuns(
      boardId,
      {limit: 20},
      {refetchInterval: 15 * 1000}
  );

  const unfinishedRuns = runs?.filter(
    (run) => ![Status.FAILED, Status.SUCCEEDED].includes(run.status)
  );
  const isUnfinishedRunsExist = Boolean(
    unfinishedRuns && unfinishedRuns.length > 0
  );

  return (
    <Grid container spacing={5} padding={5}>
      <Grid item lg={8} xl={9} xs={12}>
        <Grid container item spacing={5}>
          <Grid
            item
            xs={12}
            sx={isUnfinishedRunsExist ? undefined : { padding: "0 !important" }}
          >
            <Collapse in={isUnfinishedRunsExist}>
              {isUnfinishedRunsExist && (
                <NowRunning runs={unfinishedRuns || []} />
              )}
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <RecentRuns boardId={boardId} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} xl={3} xs={12}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" marginBottom={1}>
              Block Type
            </Typography>
            <DescriptionCard boardId={boardId} />
          </Grid>

          <PublishedCard boardId={boardId} />
          <Grid item xs={12}>
            <Typography variant="h6" marginBottom={1}>
              Scheduled
            </Typography>
            <SchedulesCard boardId={boardId} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" marginBottom={1}>
              Triggers
            </Typography>
            <BoardTriggersCard boardId={boardId} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" marginBottom={1}>
              Triggered By
            </Typography>
            <BoardTriggeredByCard boardId={boardId} />
          </Grid>
          <Grid item xs={12}>
            <HeatmapCard boardId={boardId} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OverviewSection;
