import {FC, MouseEvent, useMemo, useState} from "react";
import {Box, Button, IconButton, Theme, Tooltip, Typography,} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MessageIcon from "@mui/icons-material/Message";
import {CellValue, Column, TableState} from "react-table";
import StarIcon from "@mui/icons-material/Star";
import LaunchIcon from "@mui/icons-material/Launch";
import {Link} from "react-router-dom";
import {getRuns} from "../../services/runs/api";
import OffsetPaginatedTable from "../../components/OffsetPaginatedTable";
import StatusIcon from "../../components/StatusIcon";
import {formatDateString, getDuration} from "../../utils";
import {CircuitBoard, Run} from "../../types";
import {Status} from "../../enums";
import RunMenu from "./RunMenu";
import usePublishedRun from "../Run/RunV2/hooks/usePublishedRun";
import RunDetailsDialog from "./RunDetailsDialog";
import RunsSearchBar from "./RunsSection/RunsSearchBar";
import ArchiveSwitch from "./ArchiveSwitch";
import useDebouncedCallback from "../../hooks/UseDebouncedCallback";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tags: {
            "& > *": {
                margin: theme.spacing(0.5),
            },
        },
        tag: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        publishedText: {
            color: theme.palette.primary.dark,
            margin: theme.spacing(1),
        },
        nameButton: {
            padding: 0,
        },
        nameButtonLabel: {
            ...theme.typography.subtitle2,
        },
    })
);

type RunSectionsRunTableProps = {
  board: CircuitBoard;
};

const RunSectionsRunTable: FC<RunSectionsRunTableProps> = ({ board }) => {
  const classes = useStyles();
  const [chosenRun, setChosenRun] = useState<null | Run>(null);
  const [archived, setArchived] = useState<boolean>(false);
  const [logsDialogOpen, setLogsDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onMenuClose = () => setAnchorEl(null);

  const boardId = board.identifier;
  const { data: currentPublishedRun } = usePublishedRun(boardId);
  const [searchText, setSearchText] = useState<string>("");
  const setDebouncedSearchText = useDebouncedCallback((value: string) => {
    setSearchText(value);
  }, 300);

  const handleClickMenu = (event: MouseEvent<HTMLButtonElement>, run: Run) => {
    setAnchorEl(event.currentTarget);
    setChosenRun(run);
  };

  const getTooltipTitle = (run: Run) => {
    const title = [];
    if (run.tracking_statistics.total_messages) {
      title.push(
        run.tracking_statistics.total_messages > 1
          ? `${run.tracking_statistics.total_messages} Messages`
          : "1 Message"
      );
    }

    if (run.tracking_statistics.total_metrics) {
      title.push(
        run.tracking_statistics.total_metrics > 1
          ? `${run.tracking_statistics.total_metrics} Metrics`
          : "1 Metric"
      );
    }

    return title.join(" and ");
  };

  const columns = useMemo(
    () => [
      {
        id: "status",
        Header: "",
        accessor: (run: Run) => {
          const hasLogs =
            run.tracking_statistics.total_messages ||
            run.tracking_statistics.total_metrics;

          return !run.status ? null : (
            <Tooltip
              title={
                run.failed_steps?.length ? (
                  <>
                    <Typography variant="caption" display="block">
                      The following steps failed:
                    </Typography>
                    {run.failed_steps?.map((step) => (
                      <Typography variant="caption" key={step} display="block">
                        {step}
                      </Typography>
                    ))}
                  </>
                ) : (
                  ""
                )
              }
              arrow
            >
              <Box display="flex" alignItems="center">
                <StatusIcon
                  onClick={
                    hasLogs
                      ? () => {
                          setChosenRun(run);
                          setLogsDialogOpen(true);
                        }
                      : undefined
                  }
                  status={run.status}
                  warning={
                    run.tracking_statistics?.message_severities?.warning > 0
                  }
                />
                {run.id === currentPublishedRun?.run_id ? (
                  <Tooltip title="published" className={classes.publishedText}>
                    <StarIcon fontSize="small" />
                  </Tooltip>
                ) : null}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        id: "name",
        Header: "Name",
        accessor: (run: Run) => (
          <Button
            component={Link}
            to={`/boards/${boardId}/runs/${run.id}`}
            classes={{
              root: classes.nameButton,
              text: classes.nameButtonLabel,
            }}
          >
            {`${run.name} (#${run.id.substr(0, 5)})`}
          </Button>
        ),
      },
      {
        id: "created_by_email",
        Header: "Created By",
        accessor: (run: Run) => run.created_by_email,
      },
      {
        id: "created_at",
        Header: "Started",
        accessor: (run: Run) => formatDateString(run.created_at),
      },
      {
        id: "duration",
        Header: "Duration",
        accessor: (run: Run) =>
          run.finished_at
            ? getDuration(new Date(run.created_at), new Date(run.finished_at))
            : null,
        disableSortBy: true,
      },
      {
        id: "actions",
        Header: "Actions",
        disableSortBy: true,
        Cell: (cell: CellValue) => {
          const hasLogs =
            cell.row.original.tracking_statistics.total_messages ||
            cell.row.original.tracking_statistics.total_metrics ||
            null;
          return (
            <Box whiteSpace="nowrap" display="flex" alignItems="center">
              {(board?.applications?.length || 0) > 0 && (
                <Button
                  size="small"
                  variant="text"
                  startIcon={<LaunchIcon />}
                  target="_blank"
                  component={Link}
                  to={`/apps/${boardId}/dashboard/#/?runId=${cell.row.original.id}`}
                  disabled={cell.row.original.status !== Status.SUCCEEDED}
                >
                  Preview
                </Button>
              )}
              <Tooltip
                title={
                  hasLogs
                    ? getTooltipTitle(cell.row.original)
                    : "This run didn't produce any logs."
                }
              >
                <Box>
                  <Button
                    size="small"
                    variant="text"
                    startIcon={<MessageIcon />}
                    disabled={!hasLogs}
                    aria-haspopup="true"
                    onClick={() => {
                      setChosenRun(cell.row.original);
                      setLogsDialogOpen(true);
                    }}
                  >
                    Logs
                  </Button>
                </Box>
              </Tooltip>
              <IconButton
                size="small"
                aria-haspopup="true"
                onClick={(event: MouseEvent<HTMLButtonElement>) =>
                  handleClickMenu(event, cell.row.original)
                }
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      boardId,
      currentPublishedRun,
      classes.publishedText,
      classes.tags,
      classes.tag,
    ]
  ) as Column[];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m={2}
      >
        <Box flexGrow={1}>
          <RunsSearchBar value={searchText} onChange={setDebouncedSearchText} />
        </Box>
        <Box marginLeft={2}>
          <ArchiveSwitch
              checked={archived}
              onChange={(event: any) => setArchived(event.target.checked as boolean)}
          />
        </Box>
      </Box>
      <OffsetPaginatedTable
        name="runs"
        queryFn={(params?: object) => getRuns(boardId, params)}
        queryKey={["runs", boardId]}
        queryParams={{
          circuitboardId: boardId,
          archived,
          name_filter: searchText,
        }}
        columns={columns}
        tableProps={{ size: "small" }}
        initialState={{ pageSize: 25 } as TableState}
      />

      <RunMenu run={chosenRun} anchorEl={anchorEl} onClose={onMenuClose} />
      <RunDetailsDialog
        open={logsDialogOpen}
        onClose={() => setLogsDialogOpen(false)}
        run={chosenRun}
      />
    </>
  );
};

export default RunSectionsRunTable;
