import {AuthorizedContent} from "@frontegg/react";
import {Add} from "@mui/icons-material";
import {Box, Button, Divider, Stack} from "@mui/material";
import {FC} from "react"

import {Link, useParams} from "react-router-dom";
import {PlatformRole} from "../../enums";
import {FlowBlocksTable} from "./FlowBlocksTable";
import {DeployFlowButton} from "./DeployFlowButton";
import {DeleteFlowButton} from "./DeleteFlowButton";
import {RerunFlowButton} from "./RerunFlow";
import {UpdateFlowViewsButton} from "./UpdateFlowViewsButton";
import FlowVisibilityToggle from "./FlowVisibilityToggle";
import {CopyFlowUrl} from "./CopyFlowUrl";
import {UpdateFlowButton} from "./UpdateFlowButton";
import {GenerateInsights} from "./GenerateInsights";

export const Configuration: FC = () => {
  const {flowId} = useParams<{ flowId: string }>();

  return (
      <Box p={5}>
        <Stack
            display="flex"
            justifyContent="flex-end"
            mb={1}
        direction="row"
        spacing={1}
      >
        <AuthorizedContent requiredRoles={[PlatformRole.NoogataAdministrator]}>
          <GenerateInsights flowId={flowId} />
          <RerunFlowButton flowId={flowId} />
          <DeployFlowButton flowId={flowId} />
          <UpdateFlowViewsButton flowId={flowId} />
          <FlowVisibilityToggle flowId={flowId} />
          <Button
            sx={{ display: "none" }}
            variant="outlined"
            color="primary"
            disabled
          >
            {" "}
            Refresh Data{" "}
          </Button>

          <Divider orientation="vertical" />

          <UpdateFlowButton flowId={flowId} />
          <DeleteFlowButton flowId={flowId} />
          <Divider orientation="vertical" />
        </AuthorizedContent>
        <CopyFlowUrl />
        <Button
          component={Link}
          to={`/marketplace/flows/${flowId}`}
          variant="outlined"
          color="primary"
        >
          {" "}
          <Add sx={{ marginRight: 1 }} /> Add Block{" "}
        </Button>
      </Stack>
      <FlowBlocksTable />
    </Box>
  );
};
