import { Box, Typography } from "@mui/material";
import { SpacesBigIcon } from "../../assets";
import SpaceList from "./components/SpaceList";
import theme from "../../themes/light";
import { useAssortmentIntelligence } from "./AssortmentIntelligenceProvider";

const EmptyState = () => {
  const { spaceId, spaceAttributes } = useAssortmentIntelligence();

  const noAttributes = !!spaceId && spaceAttributes.length === 0;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <SpacesBigIcon />
      <Box
        sx={{
          position: "absolute",
          bottom: noAttributes ? "-20%" : "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {noAttributes && (
          <Typography
            variant="body3"
            sx={{
              textAlign: "center",
              color: theme.palette.text.primary,
              width: "80%",
            }}
          >
            No features found. Go to the{" "}
            <a href={`/spaces/${spaceId}`}>Space page</a> to configure{" "}
            <strong>feature extraction</strong>, or select another space with
            extracted features.
          </Typography>
        )}
        <SpaceList />
      </Box>
    </Box>
  );
};

export default EmptyState;
