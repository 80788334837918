import {useHistory} from "react-router-dom";
import {useTheme} from "@mui/styles";
import {Box, Skeleton, Stack, Tooltip, Typography} from "@mui/material";
import {BoardTemplate} from "../../types/board-templates";
import {useTenantBlockPolicies} from "./hooks";
import useTenantHasAmazonProducts from "../../services/ecommerce/useTenantHasAmazonProducts";
import {useUserHasNoogataAdminRole} from "../../services/auth";
import PREMIUM_TEXT from "./PremiumText";
import MANAGED_TEXT from "./ManagedText";
import {BackButton} from "../../components/BackButton";
import {AddBlockButton} from "./AddBlockButton";
import {BoardTemplateDescriptionAndTags} from "./BoardTemplateDescriptionAndTags";
import {FC} from "react"

export const BoardTemplateHeader: FC<{ boardTemplate?: BoardTemplate }> = ({
                                                                               boardTemplate,
                                                                           }) => {
    const history = useHistory();
    const theme = useTheme();
    const isBlockInstallable = useTenantBlockPolicies(boardTemplate);

    const onboardingComplete = useTenantHasAmazonProducts();
    const isNoogataAdmin = useUserHasNoogataAdminRole();
  const PermissionErrorTextComponent = isNoogataAdmin
    ? ""
    : !isBlockInstallable
    ? PREMIUM_TEXT
    : boardTemplate?.managed
    ? MANAGED_TEXT
    : !onboardingComplete
    ? "Complete onboarding to install blocks"
    : "";
  return (
    <Stack direction="row" spacing={1} sx={{ pb: 2 }}>
      <Stack flex={1} spacing={2} direction="column">
        <BackButton
          onBack={() => history.push("/marketplace")}
          label={boardTemplate?.library?.toUpperCase() || "BACK"}
        />
        <Box display="flex" alignItems="center">
          {!boardTemplate ? (
            <Skeleton width="60%" height={50} />
          ) : (
            <>
              <img
                alt=""
                style={{
                  width: theme.spacing(6.3),
                  height: theme.spacing(6.3),
                }}
                src={boardTemplate?.image}
              />
              <Box marginLeft={3}>
                <Typography variant="h3">{boardTemplate?.name}</Typography>
              </Box>
            </>
          )}
        </Box>
        <Box marginBottom={5}>
          {boardTemplate ? (
            <BoardTemplateDescriptionAndTags boardTemplate={boardTemplate} />
          ) : (
            <Skeleton width="80%" />
          )}
        </Box>
      </Stack>
      <Box sx={{ alignSelf: "center" }} justifyContent="flex-end">
        {!!boardTemplate && (
          <Tooltip title={PermissionErrorTextComponent}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <AddBlockButton
                blockTemplateId={boardTemplate.id}
                disabled={!(isNoogataAdmin || onboardingComplete)}
              />
              {PermissionErrorTextComponent}
            </Box>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
};
