import { Box, Typography } from "@mui/material";

import { useEcommerceProducts } from "../../../services/ecommerce";
import { PipelineInput, RunInputValue } from "../../../types";
import { EcommerceProduct } from "../../../types/ecommerce";
import AmazonProductsCard from "./AmazonProductsConnector/AmazonProductsCard";
import BoardConnectorCard from "./BoardConnector/BoardConnectorCard";
import ExternalDataSourceCard from "./ExternalConnector/ExternalDataSourceCard";
import GoogleSheetsCard from "./GoogleSheetsConnector/GoogleSheetsCard";
import LocalFileCard from "./LocalFile/LocalFileCard";
import SpaceConnectorCard from "./SpaceConnector/SpaceCard";
import { FC } from "react";

export type ConnectorTypeCardProps = {
  setInputValue: (runInputValue: RunInputValue) => void;
  onCompletion: () => void;
  input: PipelineInput | undefined | null;
  isOnboardingProduct?: EcommerceProduct[] | null;
};

type ConnectorTypeSelectionProps = {
  setInputValue: (runInputValue: RunInputValue) => void;
  onCompletion: () => void;
  input: PipelineInput | undefined | null;
};

const ConnectorTypeSelection: FC<ConnectorTypeSelectionProps> = ({
  setInputValue,
  onCompletion,
  input,
}) => {
  const { data: oneProduct } = useEcommerceProducts(0, 1);
  return (
    <>
      <Box marginBottom={2}>
        <Typography variant="h6">1. Choose a Data Source</Typography>
      </Box>
      {input && (
        <Box display="flex" flexWrap="wrap" width="100%">
          {[
            AmazonProductsCard,
            GoogleSheetsCard,
            LocalFileCard,
            BoardConnectorCard,
            ExternalDataSourceCard,
            SpaceConnectorCard,
          ].map((ConnectorTypeCard) => (
            <Box margin={1}>
              <ConnectorTypeCard
                setInputValue={setInputValue}
                onCompletion={onCompletion}
                input={input}
                isOnboardingProduct={oneProduct?.items}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default ConnectorTypeSelection;
