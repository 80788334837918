import { useAuthUserOrNull } from "@frontegg/react";
import {
  Box,
  // CardHeader,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
// import { useStyles } from "../../../components/LabLeftNav/useStyles";
import { useEffect, useState } from "react";
import { getUserMostPrivilegeRole } from "../../../services/auth";
import { PlatformRole } from "@noogata/types";
import { useHistory } from "react-router-dom";
import { DataLabWhiteIcon, LogoutWhiteIcon } from "../../../assets";
import { useAppContext } from "../../../ApplicationContext";
import { UserSectionV2 } from "../../../components/LabLeftNav/UserSectionV2";
import { LIMITED_ROLES } from "../../../enums";

interface ProfileMenuProps {
  sideBarOpen: boolean;
  sidebarWidth: number;
  sidebarHeight?: number;
  mode?: "top" | "side";
}

const ProfileMenu = ({ sideBarOpen, sidebarWidth, sidebarHeight, mode = "side" }: ProfileMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const user = useAuthUserOrNull();
  // const classes = useStyles();
  const role: string = user
    ? getUserMostPrivilegeRole(user).key
    : PlatformRole.ApplicationUser;

  const history = useHistory();
  const { applicationMode, setApplicationMode } = useAppContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  useEffect(() => {
    setAnchorEl(null);
    return () => {
      setAnchorEl(null);
    };
  }, [sideBarOpen]);

  return (
    <>
      <UserSectionV2
        onClick={handleClick}
        sx={{ cursor: "pointer", padding: "0px" }}
        open={sideBarOpen}
        mode={mode}
      />
      <Popper
        id="simple-popper"
        anchorEl={(anchorEl as unknown as HTMLElement) || null}
        open={Boolean(anchorEl)}
        sx={{
          transform: mode == "side" ? `translate(${sidebarWidth + 1}px ,-10px) !important` : '',
          zIndex: 10,
          width: "230px",
        }}
        keepMounted={true}
      >
        {anchorEl && (
          <Box
            sx={{
              background: "#2c224c",
              color: "#A6A3AF",
              padding: "8px 8px 8px 12px",
              borderRadius: mode == "side" ? "4px" : '0px',
              boxShadow:
                " 0px 1px 1px 0px rgba(44, 34, 76, 0.08), 0px 4px 16px 0px rgba(44, 34, 76, 0.08), 0px 4px 12px 0px rgba(44, 34, 76, 0.04), 0px 2px 6px 0px rgba(44, 34, 76, 0.12)",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UserSectionV2
              open
              sx={{
                borderRadius: mode == "side" ? "4px" : '0px',
                background: "#2c224c",
                "& .MuiTypography-root": {
                  color: "#FFF",
                },
              }}
            />
            {role !== PlatformRole.ApplicationUser && !(LIMITED_ROLES.includes(role as PlatformRole)) && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "4px 8px",
                  gap: "10px",
                  color: "#FFF",
                  alignItems: "center",
                  "&:hover": {
                    background: "#bc9cf229",
                  },
                }}
                onClick={() => {
                  setApplicationMode(!applicationMode);
                  history.push("/");
                }}
              >
                <DataLabWhiteIcon height="16" width="16" />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    color: "#FFF"
                  }}
                >
                  Data Lab
                </Typography>
              </Stack>
            )}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                padding: "4px 8px",
                gap: "10px",
                alignItems: "center",
                "&:hover": {
                  background: "#bc9cf229",
                },
              }}
              onClick={() => {
                setApplicationMode(false);
                history.push("/account/logout");
              }}
            >
              <LogoutWhiteIcon height="16" width="16" />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  color: "white",
                }}
              >
                Logout
              </Typography>
            </Stack>
          </Box>
        )}
      </Popper>
    </>
  );
};

export default ProfileMenu;
