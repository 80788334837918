import {Container, Divider, Tab, Tabs} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {Redirect, Route, useHistory, useRouteMatch} from "react-router-dom";
import GeneralTab from "./GeneralTab";
import NotificationsTab from "./NotificationsTab";
import UpgradeSection from "../UpgradeSection";
import {useRouteMetrics} from "../../../hooks/metrics/routeEvents";
import {ChangeEvent, FC} from "react"

enum TabSections {
  General = "general",
  Notifications = "notifications",
  Upgrade = "upgrade",
}

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
  },
  tabPanelBox: {
    marginTop: theme.spacing(3),
  },
}));

type SettingSectionProps = {
  circuitboardId: string;
  isBreakingChanges: boolean;
};

const SettingSection: FC<SettingSectionProps> = ({
  circuitboardId,
  isBreakingChanges,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const handleTabChange = (
    _event: ChangeEvent<object>,
    newValue: TabSections
  ) => {
    history.push(newValue);
  };

  useRouteMetrics(`${path}/:section`, {
    scope: ["board"],
    circuitboardId,
  });

  const chosenTab = history.location.pathname.split("/").pop();

  return (
    <>
      <Tabs
        orientation="horizontal"
        value={chosenTab}
        onChange={handleTabChange}
      >
        <Tab value={TabSections.General} label="General" />
        <Tab value={TabSections.Notifications} label="Notifications" />
        <Tab value={TabSections.Upgrade} label="Update" />
      </Tabs>
      <Divider />

      {circuitboardId && (
        <>
          <Route path={url} exact>
            <Redirect to={`settings/${TabSections.General}`} />
          </Route>
          <Route path={`${url}/${TabSections.General}`}>
            <Container maxWidth="md" className={classes.rootContainer}>
              <GeneralTab boardId={circuitboardId} />
            </Container>
          </Route>
          <Route path={`${url}/${TabSections.Notifications}`}>
            <Container maxWidth="md" className={classes.rootContainer}>
              <NotificationsTab circuitboardId={circuitboardId} />
            </Container>
          </Route>
          <Route path={`${url}/${TabSections.Upgrade}`}>
            <UpgradeSection
              boardId={circuitboardId}
              isBreakingChanges={isBreakingChanges}
            />
          </Route>
        </>
      )}
    </>
  );
};

export default SettingSection;
