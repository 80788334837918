import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import theme from "../../themes/light";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: any;
}

export default function DismissTracking({
  open,
  handleClose,
  handleConfirm,
}: Props) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": {
            margin: 0,
            maxWidth: "420px",
            padding: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography variant="headline3" color={theme.palette.text.primary}>
            Dismissing this product will remove it from your tracking list
          </Typography>
          <Typography
            variant="body3"
            color={theme.palette.grey[400]}
            sx={{ fontWeight: 400 }}
          >
            Are you sure you want to dismiss it?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 0, marginTop: "24px" }}>
          <Button
            onClick={handleConfirm}
            sx={{
              borderRadius: "4px",
              border: "1px solid  #D7D5DB",
              background: theme.palette.primary.contrastText,
              boxShadow: "0px 1px 1px 0px rgba(44, 34, 76, 0.04)",
              padding: "10px 16px",
              color: theme.palette.text.primary,
              height: "40px",
              "&:hover": {
                background: theme.palette.primary.contrastText,
              },
            }}
          >
            Keep in tracking list
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              borderRadius: "4px",
              border: "1px solid  #D7D5DB",
              background: theme.palette.primary.main,
              boxShadow: "0px 1px 1px 0px rgba(44, 34, 76, 0.04)",
              padding: "10px 16px",
              color: theme.palette.primary.contrastText,
              height: "40px",
              "&:hover": {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
