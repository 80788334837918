export enum TrafficType {
  USER = "user",
}

export enum Split {
  SHOPIFY = "shopify_feature",
  WALMART = "walmart_feature",
  HARMONIZATION = "harmonization",
  INTERCOM = "intercom",
  ONBOARDING_FEATURE = "onboarding_feature",
  WAREHOUSE_SETTINGS = "warehouse_settings",
  SEMANTIC_COLUMNS = "semantic_columns",
  AD_BOOSTER = "ad_booster",
  APPLICATION_MODE = "application_mode",
  INSIGHTS = "insights",
  PERFECT_CONTENT_V2 = "perfect_content_v2",
  SHOW_PRODUCT_GROUP_FILTER = "show-product-group-filter",
  REVENUE_OVER_TIME = "revenue_over_time",
  CREATE_ASINS_FILTER = "create_asins_filter",
  SALES_ASSISTANT = "sales_assistant",
  SALES_ASSISTANT_V2 = "sales_assistant_v2",
  SALES_ASSISTANT_ACTIVITY = "sales_assistant_activity",
  SALES_ASSISTANT_TASKS = "sales-assistant-tasks",
  // SPACES_FF = "spaces",
  SPACES_V2 = "spaces_v2",
  // SALES_ASSISTANT_ADD_TASKS = "sales_assistant_add_tasks"
  SALES_ASSISTANT_ALERTS = "sales_assistant_alerts",
  SALES_ASSISTANT_PRODUCTS_ALERTS = "sales_assistant_products_alerts",
  USE_EMBED_2 = "use_embed_2_url",
  TRACKING = "tracking",
  SALES_ASSISTANT_GROUPS = "sales-assistant-groups",
  INSIGHTS_CHAT = "insights-chat",
  RESEARCH_ASSISTANT_FILTERS = "research_assistant_filters",
  CHAT_V2_FF = "insights-chat-v2",
  SPACE_KEYWORDS = "space-keywords",
  FEATURE_EXTRACTION = "feature-extraction",
  DASHBOARDS_NEW_NAVIGATION = "dashboard-new-navigation",
  SPACES_CREATE_V2 = "spaces-create-v2",
  PERFECT_CONTENT_V3 = "perfect_content_v3",
  MENU_V2 = "menu_v2",
  CATALOG_ENRICHMENT = "catalog_enrichment",
  STORY_POINTS_REPORT_KEYWORDS_OPPORTUNITY = "story_points_keywords_opportunity",
  ASSORTMENT_INTELLIGENCE = "assortment_intelligence",
  SPACES_TABLE_VIEW = "spaces_table_view",
}

export enum EventType {
  USER_ENTERED_NEW_RUN_PAGE = "user-entered-new-run-page",
  USER_STARTED_NEW_RUN_FROM_NEW_RUN_FLOW = "user-started-new-run-from-new-run-flow",
}
