import { FC } from "react";

import { Avatar, CardHeader, CardHeaderProps, Stack } from "@mui/material";
import { useAuthUserOrNull } from "@frontegg/react-hooks/auth";
import { useStyles } from "./useStyles";

export const UserSection: FC<{ open?: boolean } & CardHeaderProps> = ({
  open,
  ...props
}) => {
  const user = useAuthUserOrNull();
  const classes = useStyles();

  return open ? (
    <CardHeader
      className={classes.userSection}
      sx={{ flexWrap: "nowrap", p: 0.875 }}
      avatar={<Avatar src={user?.profilePictureUrl || ""} />}
      title={open ? user?.name : ""}
      subheader={open ? user?.name : ""}
      {...props}
    />
  ) : (
    <Stack alignItems="center">
      <Avatar src={user?.profilePictureUrl || ""} />
    </Stack>
  );
};
