import { useHistory } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";

export const Tracking: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  // const pageMatch = useRouteMatch({ path: ["/tracking"] });
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={tab === "tracking"}
      icon={
        <SVG
          src={"/sales/tracking-icon.svg"}
          fill="inherit"
          width={20}
          height={20}
        />
      }
      onClick={() => {
        setTab("tracking");
        history.push("/tracking");
      }}
      label="Tracking"
    />
  );
};
