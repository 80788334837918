import { Action } from "../../types.ts";
import UndoIcon from "@mui/icons-material/Undo";
import { useMemo } from "react";
import RedoIcon from "@mui/icons-material/Redo";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { usePerfectContent } from "../../PerfectContentProvider.tsx";
import { tabs } from "./Tabs.tsx";
import _ from "lodash";
import { IconButton, Stack, Tooltip } from "@mui/material";

const Actions = () => {
  const { activeTab } = usePerfectContent();
  const tabName = useMemo(() => {
    return _.find(tabs, { value: activeTab })?.label;
  }, [activeTab]);

  const actions: Action[] = useMemo(
    () => [
      {
        icon: UndoIcon,
        label: "Undo",
        // onClick: () => editor.undo(),
      },
      {
        icon: RedoIcon,
        label: "Redo",
        // onClick: () => editor.redo(),
      },
      {
        icon: RotateLeftIcon,
        label: `Reset ${tabName}`,
        // onClick: resetContent,
      },
      {
        icon: ContentCopyIcon,
        label: `Copy ${tabName}`,
        // onClick: () => {
        //   handleCopy();
        // },
      },
      {
        icon: AutoAwesomeIcon,
        label: "Rephrase",
        // onClick: () => {
        //   handleRephrased();
        // },
      },
    ],
    [tabName]
  );
  return (
    <Stack direction="row">
      {actions.map((action) => {
        const Icon = action.icon;
        return (
          <Tooltip
            key={`button-${action.label}`}
            title={action.label}
            placement="top"
            arrow
          >
            {/* Span is here to enable tooltip when button is disabled */}
            <span>
              <IconButton size="medium" color="default">
                <Icon />
              </IconButton>
            </span>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

export default Actions;
