import {Box, Breadcrumbs, IconButton, Link, Skeleton, Typography,} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Link as RouterLink, useParams} from "react-router-dom";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useBoard} from "../../services/boards";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
    createStyles({
        breadcrumbs: {
            height: 20,
        },
        iconButton: {
            marginLeft: -theme.spacing(0.7),
        },
    })
);

type HeaderProps = {
  title: string;
  subtitle: string;
  onBack: () => void;
};

const Header: FC<HeaderProps> = ({ title, subtitle, onBack }) => {
  const classes = useStyles();
  const { boardId }: { boardId?: any } = useParams();
  const { data: board } = useBoard(boardId);
  return (
    <Box marginBottom={4}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
      >
        <Link
          variant="caption"
          to={`/boards/${boardId}`}
          underline="hover"
          key={boardId}
          component={RouterLink as any}
          sx={{ color: "text.secondary" }}
        >
          {board ? board.displayName : <Skeleton variant="text" width={100} />}
        </Link>
        <Typography variant="caption">New Run</Typography>
      </Breadcrumbs>
      <Box display="flex" marginY={1.5}>
        <IconButton
          onClick={onBack}
          size="small"
          className={classes.iconButton}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Typography variant="subtitle1">{subtitle}</Typography>
    </Box>
  );
};

export default Header;
