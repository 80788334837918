import {Button, Tooltip} from "@mui/material";
import {Insights} from "@mui/icons-material";
import {useMutation} from "react-query";
import {useSnackbar} from "notistack";
import {generateInsights} from "../../services/Flows/api";
import {FC} from "react"

export const GenerateInsights: FC<{ flowId: string }> = ({flowId}) => {
    const mutation = useMutation(generateInsights);
    const {enqueueSnackbar} = useSnackbar();
    return (
        <Tooltip title="Generate insights">
            <Button
                variant="outlined"
                onClick={() =>
                    mutation.mutateAsync(flowId).then(() => {
            enqueueSnackbar("Insights generation started", {
              variant: "success",
            });
          })
        }
        startIcon={<Insights fontSize="small" sx={{ cursor: "pointer" }} />}
      >
        Generate insights
      </Button>
    </Tooltip>
  );
};
