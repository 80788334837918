import { FC, useMemo } from "react";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UseQueryResult } from "react-query";
import { InsightCategoryText, InsightDate } from "../Info";
import { useQueryParams, useSplitEnabled } from "../../../../hooks";
import { InsightsQueryParams } from "../../constants";
import { useInsight } from "../../useInsights";
import { Insight } from "../../../../types";
import { APIError } from "../../../../services/apiRequest";
import { RecommendedActionSection } from "./RecommendedSection";
import { InsightRead, TransformedInsight } from "../../types";
import { Rating } from "../Rating";
import { ProductImage } from "../ProductImage";
import { InsightActions } from "../../InsightActions";
import TabsButtons from "../TabButtons";
import { Collaboration } from "./Collaboration";
import { useLocalInsightV2 } from "../../../../hooks/useLocalInsightV2";
import { RevenueBox } from "./RevenueBox";
import { Split } from "../../../../FeatureFlags/enums";

export const useLocalInsight = (): UseQueryResult<Insight, APIError> => {
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  return useInsight(insightId);
};

const DateAndStatus: FC = () => {
  const theme = useTheme();
  const { data: insight } = useLocalInsightV2();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  return insight ? (
    <Stack
      gap="8px"
      direction={revenueOverTimeEnabled ? "row-reverse" : "row"}
      alignItems="center"
    >
      {insight?.status === "new" && (
        <Chip
          label="New"
          size="small"
          color="secondary"
          sx={{
            borderRadius: "500px",
            padding: "4px",
            background: theme.palette.backgrounds.active,
          }}
        />
      )}
      {insight?.status === "updated" && (
        <Chip
          label="Updated"
          size="small"
          sx={{
            borderRadius: "500px",
            padding: "4px",
            background: theme.palette.backgrounds.info,
            color: theme.palette.info.contrastText,
          }}
        />
      )}
      <InsightDate date={insight.date} />
    </Stack>
  ) : (
    <Skeleton width={theme.spacing(5)} />
  );
};

const DrawerTop: FC<{
  onClose: () => void;
  setInsightIdForDialog: (val: string) => void;
}> = ({ onClose, setInsightIdForDialog }) => {
  const theme = useTheme();
  const { data: insight } = useLocalInsightV2();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  return (
    <Box display="flex" justifyContent="space-between">
      <IconButton onClick={onClose} sx={{ background: "none", p: 2.5 / 8 }}>
        <CloseIcon
          sx={{ fontSize: theme.spacing(2) }}
          htmlColor={theme.palette.icons.secondary}
        />
      </IconButton>
      <Box>
        {revenueOverTimeEnabled && insight ? (
          <TabsButtons
            insightId={insight.id}
            setInsightIdForDialog={setInsightIdForDialog}
            outlined
            canUndo={insight.canUndo}
          />
        ) : (
          <DateAndStatus />
        )}
      </Box>
    </Box>
  );
};

const DrawerTitle: FC<{
  insight?: InsightRead;
  setInsightIdForDialog: (val: string) => void;
}> = ({ insight, setInsightIdForDialog }) => {
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);
  return insight ? (
    <Stack justifyContent="space-between" alignItems="start" direction="row">
      <Stack direction="row" alignItems="center" spacing={1}>
        <ProductImage insight={insight} useTooltip size="small" />
        <Stack justifyContent="space-between" alignItems="start">
          <Box sx={{ zoom: 0.85 }}>
            <Typography variant="h2_v2">{insight.strategy}</Typography>
          </Box>
          {revenueOverTimeEnabled ? (
            <DateAndStatus />
          ) : (
            <Box sx={{ zoom: 0.65 }}>
              <Rating
                {...insight}
                vertical
                starProps={{ alignSelf: "center" }}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      {revenueOverTimeEnabled ? (
        <RevenueBox />
      ) : (
        <TabsButtons
          insightId={insight.id}
          setInsightIdForDialog={setInsightIdForDialog}
          outlined
          canUndo={insight.canUndo}
        />
      )}
    </Stack>
  ) : (
    <Skeleton width="100%" />
  );
};

export const EmbeddedQuestion: FC<{ insight: InsightRead }> = ({ insight }) => {
  const iframe = useMemo(
    () => (
      <iframe
        title={insight.cardText || ""}
        src={insight.embeddedQuestionUrl as string}
        style={{ border: 0 }}
        width="100%"
        height={340}
      />
    ),
    [insight.id]
  );
  return (
    <Paper variant="outlined" sx={{ py: 2, px: 2.5 }}>
      <Stack spacing={1}>
        <Stack spacing={0.5}>
          <Typography variant="body2_v2">{insight.widgetName}</Typography>
          <InsightCategoryText categories={insight.categories} />
        </Stack>
        {iframe}
      </Stack>
    </Paper>
  );
};

export const InsightDrawer: FC<{
  onClose: () => void;
  open: boolean;
  setInsightIdForDialog: (val: string) => void;
}> = ({ open, onClose, setInsightIdForDialog }) => {
  const { data: insight } = useLocalInsightV2();
  return (
    <Drawer
      PaperProps={{
        variant: "outlined",
        elevation: 0,
        sx: {
          width: "704px",
        },
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Stack p={3} spacing={3}>
        <DrawerTop
          onClose={onClose}
          setInsightIdForDialog={setInsightIdForDialog}
        />
        <DrawerTitle
          insight={insight}
          setInsightIdForDialog={setInsightIdForDialog}
        />
        <Divider />
        <RecommendedActionSection />
        {insight?.embeddedQuestionUrl && <EmbeddedQuestion insight={insight} />}
        <InsightActions />
        {insight && (
          <>
            <Divider />
            <Typography variant="body2" color="text.secondary">
              Assign
            </Typography>
            <Collaboration insight={insight as TransformedInsight} max={7} />
            <Divider />
          </>
        )}
      </Stack>
    </Drawer>
  );
};
