import { Divider, Stack } from "@mui/material";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import SaveToMyItemsBtn from "./SaveToMyItemsBTN";
import MarkAsDoneBtn from "./MarkAsDoneBTN";
import DismissBtn from "./DismissBTN";
import BackToMyListBtn from "./BackToMyListBTN";
import BackToFeedBtn from "./BackToFeedBTN";

const TabsButtons = ({
  insightId,
  setInsightIdForDialog,
  outlined,
  canUndo,
}: {
  insightId: string;
  setInsightIdForDialog: (str: string) => void;
  outlined?: boolean;
  canUndo?: boolean;
}) => {
  const location = useLocation();

  const activeTab = useMemo(() => {
    if (location.pathname.search("my-items") > -1) {
      return "my-items";
    }
    if (location.pathname.search("done") > -1) {
      return "done";
    }
    if (location.pathname.search("dismissed") > -1) {
      return "dismissed";
    }
    return "feed";
  }, [location.pathname]);

  return (
    <div>
      <Stack direction="row" alignItems="center" gap="12px">
        {activeTab === "feed" && (
          <SaveToMyItemsBtn insightId={insightId} outlined={outlined} />
        )}
        {activeTab === "my-items" && (
          <MarkAsDoneBtn insightId={insightId} outlined={outlined} />
        )}
        {(activeTab === "feed" || activeTab === "my-items") && !outlined && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ maxHeight: "24px", height: "24px", marginY: "4px" }}
          />
        )}
        {(activeTab === "feed" || activeTab === "my-items") && (
          <DismissBtn
            setInsightIdForDialog={setInsightIdForDialog}
            activeTab={activeTab}
            insightId={insightId}
            outlined={outlined}
          />
        )}
        {activeTab === "done" && canUndo && (
          <BackToMyListBtn insightId={insightId} outlined={outlined} />
        )}
        {activeTab === "dismissed" && (
          <BackToFeedBtn insightId={insightId} outlined={outlined} />
        )}
      </Stack>
    </div>
  );
};

export default TabsButtons;
