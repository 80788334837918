import { FC } from "react";
import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { useDisplayList, useSalesContext } from "../../hooks/selectors";
import useQueryParams from "../../../../hooks/useQueryParams";
import { SalesQueryParams } from "../../constants";
import { formatCurrencyOnlyNumber } from "../../../InsightsV2/SummaryV2";
import { Icon } from "../../../../components/Icon";

export const RevenueBox: FC = () => {
  const theme = useTheme();
  const {sortDays} = useSalesContext()
  const insightId = useQueryParams().get(SalesQueryParams.InsightId);
  const displayList = useDisplayList();
  const insight = displayList.find(({ id }) => insightId === id);
  const width = theme.spacing(124 / 8);
  const height = theme.spacing(64 / 8);

  if (!insight) {
    return <Skeleton width={width} height={height} />;
  }

  const unitpercentage = (sortDays === '7d' ?insight?.data?.units_sold_7d  / insight?.data?.units_sold_7d_previous : insight?.data?.units_sold_28d / insight?.data?.units_sold_28d_previous)  - 1;

  return insight ? (
    <Box
      sx={{
        border: "1px solid #E9E7F1",
        padding: "8px 12px",
        height: "64px",
        minWidth: "247px",
        width: "auto",
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        borderRadius: "8px",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: 'flex-end' }}>
          <Typography variant="headline3">

            {formatCurrencyOnlyNumber(
              sortDays === "7d"
                ? insight?.data?.units_sold_7d
                : insight?.data?.units_sold_28d
            )}
          </Typography>
              {unitpercentage >= 0 ? (
                <Box display="flex" gap="2px" alignItems="center">
                  <Icon src="sales/trend-up.svg" height="16px" width="16px" />{" "}
                  <Typography
                    variant="body4"
                    sx={{ color: theme.palette.text.success }}
                  >
                    {Math.abs(unitpercentage * 100)
                      .toFixed(1)
                      .replaceAll(/\.0(0)?/g, "")}
                    %
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" gap="2px">
                  <Icon src="sales/trend-down.svg" height="16px" width="16px" />{" "}
                  <Typography
                    variant="body4"
                    sx={{ color: theme.palette.error.main }}
                  >
                    {Math.abs(unitpercentage * 100)
                      .toFixed(1)
                      .replaceAll(/\.0(0)?/g, "")}
                    %
                  </Typography>
                </Box>
              )}
        </Box>
        <Box display="flex" gap="8px">
           <Typography
            variant="caption" color={theme.palette.grey[400]}>
            {sortDays === "7d" ? (
              <>
                {insight?.data?.units_sold_7d - insight?.data?.units_sold_7d_previous >= 0 ? (
                  <>
                    +{formatCurrencyOnlyNumber((insight?.data?.units_sold_7d - insight?.data?.units_sold_7d_previous))} Units
                  </>
                ) : (
                  <>
                    -{formatCurrencyOnlyNumber(Math.abs((insight?.data?.units_sold_7d - insight?.data?.units_sold_7d_previous)))} Units
                  </>
                )}
              </>
            ) : (
              <>
                {insight?.data?.units_sold_28d - insight?.data?.units_sold_28d_previous >= 0 ? (
                  <>
                    +{formatCurrencyOnlyNumber((insight?.data?.units_sold_28d - insight?.data?.units_sold_28d_previous))} Units
                  </>
                ) : (
                  <>
                    -{formatCurrencyOnlyNumber(Math.abs(insight?.data?.units_sold_28d - insight?.data?.units_sold_28d_previous))} Units
                  </>
                )}
              </>
            )}
          </Typography>
         
        </Box>
      </Stack>
      <Box
        sx={{
          height: "44px",
          borderLeft: `1px solid ${theme.palette.borders.lines}`,
        }}
      />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "4px" }}>
          <Typography variant="headline3">
            $
            {formatCurrencyOnlyNumber(
              sortDays === "7d" ? insight?.sales_7d : insight?.sales_28d
            )}
          </Typography>
          {sortDays === "7d" ? (
            <>
              {insight?.sales_7d_percentage >= 0 ? (
                <Box display="flex" gap="2px" alignItems="center">
                  <Icon src="sales/trend-up.svg" height="16px" width="16px" />{" "}
                  <Typography
                    variant="body4"
                    sx={{ color: theme.palette.text.success }}
                  >
                    {insight?.sales_7d_percentage
                      .toFixed(1)
                      .replaceAll(/\.0(0)?/g, "")}
                    %
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" gap="2px">
                  <Icon src="sales/trend-down.svg" height="16px" width="16px" />{" "}
                  <Typography
                    variant="body4"
                    sx={{ color: theme.palette.error.main }}
                  >
                    {insight?.sales_7d_percentage
                      .toFixed(1)
                      .replaceAll(/\.0(0)?/g, "")}
                    %
                  </Typography>
                </Box>
              )}{" "}
            </>
          ) : (
            <>
              {insight?.sales_28d_percentage >= 0 ? (
                <Box display="flex" gap="2px" alignItems="center">
                  <Icon src="sales/trend-up.svg" height="16px" width="16px" />{" "}
                  <Typography
                    variant="body4"
                    sx={{ color: theme.palette.text.success }}
                  >
                    {insight?.sales_28d_percentage
                      .toFixed(1)
                      .replaceAll(/\.0(0)?/g, "")}
                    %
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" gap="2px">
                  <Icon src="sales/trend-down.svg" height="16px" width="16px" />{" "}
                  <Typography
                    variant="body4"
                    sx={{ color: theme.palette.error.main }}
                  >
                    {insight?.sales_28d_percentage
                      .toFixed(1)
                      .replaceAll(/\.0(0)?/g, "")}
                    %
                  </Typography>
                </Box>
              )}{" "}
            </>
          )}
        </Box>
        <Box display="flex" gap="8px">
          <Typography
            variant="caption" color={theme.palette.grey[400]}>
            {sortDays === "7d" ? (
              <>
                {insight?.data?.sales_7d - insight?.data?.sales_7d_previous >= 0 ? (
                  <>
                    +${formatCurrencyOnlyNumber((insight?.data?.sales_7d - insight?.data?.sales_7d_previous))} Sales
                  </>
                ) : (
                  <>
                    -${formatCurrencyOnlyNumber(Math.abs(insight?.data?.sales_7d - insight?.data?.sales_7d_previous))} Sales
                  </>
                )}
              </>
            ) : (
              <>
                {insight?.data?.sales_28d - insight?.data?.sales_28d_previous >= 0 ? (
                  <>
                    +${formatCurrencyOnlyNumber((insight?.data?.sales_28d - insight?.data?.sales_28d_previous))} Sales
                  </>
                ) : (
                  <>
                    -${formatCurrencyOnlyNumber(Math.abs(insight?.data?.sales_28d - insight?.data?.sales_28d_previous))} Sales
                  </>
                )}
              </>
            )}
          </Typography>
        </Box>
      </Stack>
    </Box>
  ) : (
    <Skeleton width={width} height={height} />
  );
};
