import {useMemo, useState} from "react";
import {Checkbox, FormControl, ListItemText, ListSubheader, MenuItem, Select, Stack,} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {Column2Display} from "./constants";
import {FilterColumns} from "./types";
import {useTransformedFiltersForUIByColumn} from "./hooks/selectors";
import {useFilterSetter} from "./hooks/setters";
import {Search} from "../../components/SearchInput/Search";
import {useAuthorizedUsers} from "../../hooks/useUsers";

const useStyles = makeStyles((theme) =>
    createStyles({
      select: {
        fontSize: "14px",
        fontWeight: 600,
      },
      icon: {
        // @ts-ignore
        fill: theme.palette.secondary.main,
      },
    })
);
const menuItemStyles = {
  padding: "4px 8px 4px 6px",
  "&.Mui-selected": {
    backgroundColor: "background.default",
  },
};
export const SelectHeaderSearch = ({
  name,
  filter,
  setFilter,
}: {
  name: FilterColumns;
  setFilter: (value: string) => void;
  filter: string;
}) => {
  const { values } = useTransformedFiltersForUIByColumn(name);
  const displayName = Column2Display?.[name];

  return (
    <>
      {Object.keys(values).length > 5 && (
        <ListSubheader sx={{ padding: 0, height: "40px" }}>
          <Search
            fullWidth
            placeholder={`Search for ${displayName}...`}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
            InputProps={{
              value: filter,
              onChange: (e) => setFilter(e.currentTarget.value),
            }}
          />
        </ListSubheader>
      )}
    </>
  );
};
const FilterOptions = ({
  name,
  filter,
}: {
  name: FilterColumns;
  filter: string;
}) => {
  const { values } = useTransformedFiltersForUIByColumn(name);
  const setFilter = useFilterSetter();
  const users = useAuthorizedUsers();

  const itemsDisplayName: any = {};

  const items = useMemo(() => {
    const items = Object.keys(values).filter(
      (item) => item.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
    if (name === "assignedTo") {
      Object.keys(values).forEach((item) => {
        const name = users.filter((user) => user.id === item)?.[0]?.name;
        if (name) {
          itemsDisplayName[item] = name;
        }
      });
    }
    return items;
  }, [values, filter]);

  return (
    <>
      {items.map((filterItem) => (
        <MenuItem
          data-testid={`filter-dropdown-${filterItem}`}
          key={`${name}-${filterItem}`}
          value={filterItem}
          dense
          sx={menuItemStyles}
          onClick={() => {
            setFilter({
              column: name,
              key: filterItem,
              value: !values[filterItem],
            });
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox
              disableRipple
              checked={values[filterItem]}
              sx={{ padding: "0px 8px 0px 0px" }}
            />

            <ListItemText
              primary={itemsDisplayName[filterItem] || filterItem}
              primaryTypographyProps={{
                variant: "body3",
                color: (theme) => theme.palette.text.primary,
              }}
            />
          </Stack>
        </MenuItem>
      ))}
    </>
  );
};

const FilterAutoComplete = ({ name }: { name: FilterColumns }) => {
  const displayName = Column2Display?.[name];
  const classes = useStyles();
  const [filter, setFilter] = useState("");

  return (
    <FormControl variant="outlined">
      <Select
        MenuProps={{
          autoFocus: false,
          MenuListProps: { sx: { maxHeight: "240px" } },
        }}
        className={classes.select}
        sx={{
          boxShadow: "none",
          borderRadius: "4px",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        variant="standard"
        displayEmpty
        value={[]}
        renderValue={() => displayName}
        multiple
        inputProps={{ classes: { icon: classes.icon } }}
      >
        <SelectHeaderSearch name={name} filter={filter} setFilter={setFilter} />
        <FilterOptions name={name} filter={filter} />
      </Select>
    </FormControl>
  );
};

export default FilterAutoComplete;
