import apiRequest from "../apiRequest";
import {
  AddProductResponse,
  OnboardingGatheringStatus,
  ProductFilter,
  ProductsFilter,
  ProductsFilterConfiguration,
} from "../../types/ecommerce";

export function gatherProducts(): Promise<OnboardingGatheringStatus> {
  return apiRequest<OnboardingGatheringStatus>(
    "v1/onboarding/ecommerce/products",
    "POST"
  );
}

export const addProducts = (asins: string[]): Promise<AddProductResponse> =>
  apiRequest<AddProductResponse>(
    "v1/onboarding/ecommerce/products",
    "PUT",
    undefined,
    { products: asins.map((asin) => ({ asin })) }
  );

export const createAsinFilter = (
  name: string,
  filterConf: ProductsFilterConfiguration
): Promise<AddProductResponse> =>
  apiRequest<AddProductResponse>(
    "v1/onboarding/ecommerce/products/filters",
    "POST",
    undefined,
    {
      name,
      configuration: filterConf,
    }
  );

export const addProductsFilters = (
  productsFilter: Omit<ProductsFilter, "id">
): Promise<ProductsFilter> =>
  apiRequest<ProductsFilter>(
    "v1/onboarding/ecommerce/products/filters",
    "POST",
    undefined,
    productsFilter
  );

export const deleteProductsFilter = (id: string) =>
  apiRequest<any>(`v1/onboarding/ecommerce/products/filters/${id}`, "DELETE");

export const updateProductsFilter = (filter: ProductFilter) =>
  apiRequest<any>(
    `v1/onboarding/ecommerce/products/filters/${filter.id}`,
    "PATCH",
    undefined,
    filter
  );

export const handleProductEnrichment = () =>
  apiRequest<any>(`v1/onboarding/ecommerce/products/enrich`, "POST");
