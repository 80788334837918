import { Box, Card, CardHeader, styled } from "@mui/material";
import { useTheme } from "@mui/styles";

import SVG from "react-inlinesvg";
import { FC } from "react";

const RotatingBox = styled(Box)(() => ({
  animation: "rotate 2s infinite linear",
  display: "flex",
  alignItems: "center",

  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const RunningRunBox: FC = () => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        m: 2,
        backgroundColor: "grey.50",
        minHeight: "100px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CardHeader
        title="Running"
        subheader="Outputs will be available when the run is complete."
        avatar={
          <RotatingBox>
            <SVG fill={theme.palette.primary.dark} src={`/rotate.svg`} />
          </RotatingBox>
        }
        sx={{
          width: "100%",
          "& .MuiCardHeader-title": {
            color: "primary.text",
            fontWeight: 800,
          },
          "& .MuiCardHeader-subheader": { color: "grey.500" },
          "& .MuiCardHeader-avatar": {
            backgroundColor: "white",
            p: 2,
            variant: "rounded",
            borderRadius: "4px",
          },
        }}
      />
    </Card>
  );
};

export default RunningRunBox;
