import Loadable from "../../../../components/Loadable";
import {useCreateSchema} from "../../../../services/connectors";
import MappingForm from "../Mapping/MappingForm";
import {MappingProps} from "../Mapping/Mapping";
import {FC} from "react"

const ExternalDataSourceMapping: FC<MappingProps> = ({
                                                         input,
                                                         inputValue,
                                                         onSubmit,
                                                         onBack,
                                                         onError,
                                                     }) => {
    const {data, isLoading} = useCreateSchema(
        inputValue.connector_id!,
    inputValue.configuration!,
    {
      enabled: Boolean(inputValue.connector_id && inputValue.configuration),
      onError: (error) => {
        onError(error.message);
      },
    }
  );
  return (
    <Loadable isLoading={isLoading}>
      {data && (
        <MappingForm
          to={input.schema}
          from={data}
          onSubmit={onSubmit}
          onBack={onBack}
          connectorType={inputValue.type}
        />
      )}
    </Loadable>
  );
};

export default ExternalDataSourceMapping;
