import {TextField} from "@mui/material";

import {ParameterTextFieldProps} from "./types";
import {FC} from "react"

const ParameterTextField: FC<ParameterTextFieldProps> = (props) => (
    <TextField
        name={props.name}
        label={props.label}
        value={props.value || ""}
        onChange={(e) => props.onChange(e.target.value)}
        type={props.fieldType || "text"}
        error={!!props.error}
        helperText={(props.error && props.error.message) || props.helperText}
        margin="dense"
        variant="outlined"
        fullWidth
    />
);

export default ParameterTextField;
