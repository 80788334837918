import { Dashboard } from "@mui/icons-material";
import {
  Box,
  List,
  ListItemButton,
  Paper,
  Skeleton,
} from "@mui/material";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { useDashboards } from "../../Dashboards/api";
import { ExpendableList, ExpendableListHeader } from "./ExpendableList";
import { StyledTooltip } from "./StyledTooltip";
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";
import theme from "../../../themes/light";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { CompetitorsIconV2, KeywordsIconV2 } from "../../../assets";
import { useAppContext } from "../../../ApplicationContext";
import { NavbarModes } from "../../../enums";

const tabsList = [
  { label: "Competitors", icon: <CompetitorsIconV2 /> },
  { label: "Keywords", icon: <KeywordsIconV2 /> },
];

const DashboardsMenu: FC<{
  dashboards: string[];
  closeTooltip: () => void;
  navHandlerProvider: (tabName: string) => () => void;
}> = ({ dashboards, closeTooltip, navHandlerProvider }) => {
  const match = useRouteMatch<{ dashboardName: string }>(
    "/dashboards/:dashboardName"
  );
  return (
    <Paper elevation={2}>
      <List>
        {dashboards.map((d, index) => {
          const navHandler = navHandlerProvider(d);
          return (
            <ListItemButton
              key={index}
              selected={match?.params?.dashboardName === d}
              onClick={(e) => {
                e.stopPropagation();
                closeTooltip();
                navHandler();
              }}
            >
              {d}
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );
};

export const DigitalShelfList: FC<{
  open: boolean;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ open, tab, setTab }) => {
  const { setNavbarMode } = useAppContext();

  const newNavigationEnabled = useSplitEnabled(Split.MENU_V2);

  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const pageMatch = useRouteMatch<{ tabName: string; tabId: string }>([
    "/dashboards/:tabName/:tabId",
  ]);
  const history = useHistory();

  const { data: dashboards = [], isLoading } = useDashboards({
    options: {
      select: (data) => data.filter((d) => !d.isCustom()),
    },
  });

  const handleDigitalShelf = () => {
    setNavbarMode(NavbarModes.EXPENDED);
    setTab("digitalShelf");
  };

  const newTabNames = ["Market Share", "Share of Voice"];

  const getNavHandler = (tabName: string) =>
    newNavigationEnabled
      ? () => {
          const selectedDashboardId = pageMatch?.params.tabId;
          const currentDashboard = dashboards.find(
            (d) => d.id == Number(selectedDashboardId)
          );
          const dashboard =
            dashboards.find(
              (d) =>
                d.name == tabName &&
                (d.tab == currentDashboard?.tab || !currentDashboard)
            ) ?? dashboards.find((d) => d.name == tabName);

            history.push(
            ["", "dashboards", dashboard?.tab, dashboard?.id].join("/") +
              `?boardName=${dashboard?.name}&custom=${dashboard?.custom}`
          );
        }
      : () => history.push(["", "dashboards", tabName].join("/"));

  if (isLoading) {
    return (
      <ListItemButton>
        <Skeleton height="100%" width="100%" />
      </ListItemButton>
    );
  }

  if (!dashboards.length) {
    return null;
  }

  return (
    <>
      <StyledTooltip
        open={isTooltipOpen}
        onOpen={() => setTooltipOpen(true)}
        title={
          <DashboardsMenu
            dashboards={newTabNames}
            closeTooltip={() => setTooltipOpen(false)}
            navHandlerProvider={getNavHandler}
          />
        }
        onClose={() => {
          setTooltipOpen(false);
        }}
        sx={{
          visibility: open ? "hidden" : "visible",
        }}
      >
        <Box>
          <ExpendableList
            open={open && tab === "digitalShelf"}
            label={(props) => (
              <ExpendableListHeader
                icon={
                  <Dashboard sx={{ color: theme.palette.backgrounds.white }} />
                }
                selected={tab === "digitalShelf"}
                onClick={handleDigitalShelf}
                label="Digital Shelf"
                {...props}
              />
            )}
          >
            {newTabNames.map((tabName, index) => (
              <ListItemButton
                key={index}
                selected={
                  tabName ===
                  dashboards.find(
                    (d) => d.id == Number(pageMatch?.params.tabId)
                  )?.name
                }
                onClick={getNavHandler(tabName)}
                sx={{
                  color: theme.palette.background.default,
                  fontWeight: "400",
                  fontSize: "14px",
                  padding: "8px 0 8px 8px",
                  marginBottom: "8px",
                }}
              >
                {tabName}
              </ListItemButton>
            ))}
          </ExpendableList>
        </Box>
      </StyledTooltip>

      {tabsList.map(({ label: _tab, icon }) => (
        <LeftNavBarItem
          key={`navbar-item-${_tab}`}
          selected={tab === _tab}
          icon={icon}
          label={_tab}
          onClick={() => {
            setTab(_tab);
            getNavHandler(_tab)();
          }}
        />
      ))}
    </>
  );
};
