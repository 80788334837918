import { FC, memo, useEffect, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import theme from "../../themes/light";
import ClearIcon from "@mui/icons-material/Clear";
import RichTextEditorV2 from "../../components/RichTextEditor/RichTextEditorV2";

interface TextBlockProps {
  htmlText: string;
  onDelete: any;
  onEdit: any;
}

const TextBlock: FC<TextBlockProps> = memo((props) => {
  const { htmlText, onDelete, onEdit } = props;
  const [content, setContent] = useState<string>(htmlText);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isEditPending, setIsEditPending] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    if (htmlText !== content) {
      setContent(htmlText);
    }
  }, [htmlText]);

  const handleEdit = async (newContent: string) => {
    if (newContent === content) return setEditMode(false);
    setIsEditPending(true);
    await onEdit(newContent)
      .then(() => {
        setContent(newContent);
        setEditMode(false);
      })
      .catch(() => {
        setEditMode(false);
      })
      .finally(() => {
        setIsEditPending(false);
      });
  };

  return (
    <Box
      sx={{
        boxShadow:
          "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
        background: theme.palette.primary.contrastText,
        borderRadius: "12px",
        width: "100%",
        maxWidth: "1000px",
        minHeight: "90px",
        outline:
          editMode && isFocused
            ? `2px solid ${theme.palette.primary.main}`
            : "none",
        ":hover": {
          "& #text-block-delete-btn": {
            display: "inline-flex",
          },
          "& #text-block-content": {
            cursor: "pointer",
            outline: `1px solid ${theme.palette.text.link}`,
            backgroundColor: "rgba(127, 60, 238, 0.05)",
          },
        },
        padding: "24px 40px",
      }}
    >
      {editMode ? (
        <RichTextEditorV2
          onSave={(html: string) => handleEdit(html)}
          onClose={() => {
            setEditMode(false);
            setContent(htmlText);
          }}
          isPending={isEditPending}
          initialHtml={content}
          setIsFocused={setIsFocused}
        />
      ) : (
        <Box
          sx={{
            gap: "1rem",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Box
            id="text-block-content"
            sx={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              minHeight: "2.5rem",
              width: "92.5%",
              borderRadius: "8px",
              boxSizing: "border-box",
              padding: "0 16px",
              overflow: "hidden",
              "& p": {
                marginTop: 0,
                marginBottom: 0,
              },
              "& .ql-align-center": {
                textAlign: "center",
              },
              "& .ql-align-right": {
                textAlign: "right",
              },
              "& .ql-align-justify": {
                textAlign: "justify",
              },
              "& .ql-align-left": {
                textAlign: "left",
              },
            }}
            component={"div"}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
            onClick={() => setEditMode(true)}
          ></Box>
          <Box
            id="text-block-delete-btn"
            sx={{
              minWidth: "5%",
              display: "none",
            }}
          >
            <Tooltip
              placement="top"
              title="Delete"
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -5],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton
                className="close-btn"
                onClick={onDelete}
                size="small"
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "unset",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                    "& .MuiSvgIcon-root": {
                      color: theme.palette.primary.dark,
                    },
                  },
                  display: "inline-flex",
                  alignSelf: "flex-start",
                }}
              >
                <ClearIcon
                  sx={{
                    color: theme.palette.grey[300],
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default TextBlock;
