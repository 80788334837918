import { Avatar, Button, MenuItem, Select, SelectProps, Skeleton, Stack, StackProps, Typography, } from "@mui/material";

import { useHistory } from "react-router-dom";
import { Dashboard } from "../types";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { Icon } from "../../../components/Icon";
import { FC, ReactNode, useEffect, useState } from "react"
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";

const DashboardPickerSelect = ({
  currentDashboard,
  allDashboards,
  onChange,
  newNavigationEnabled,
  ...props
}: Omit<SelectProps, "onChange"> & {
  currentDashboard: Dashboard;
  allDashboards: Dashboard[];
  newNavigationEnabled: boolean;
  onChange: (d: Dashboard) => void;
}) => (
  <Select
    variant="standard"
    value={currentDashboard.id}
    size="small"
    {...props}
  >
    {allDashboards
      .filter(newNavigationEnabled
        ? (d) => d.name === currentDashboard.name
        : (d) => d.tab === currentDashboard.tab)
      .map((dashboardData) => (
        <MenuItem
          key={dashboardData.id} 
          value={dashboardData.id}
          onClick={() => onChange(dashboardData)}
        >
          {newNavigationEnabled ? dashboardData.tab : dashboardData.name}
        </MenuItem>
      ))}
  </Select>

)

export const DashboardHeader: FC<
  {
    currentDashboard: Dashboard | null;
    actions: ReactNode;
    allDashboards: Dashboard[];
  } & StackProps
> = ({ currentDashboard: dashboard, allDashboards, actions, ...props }) => {
  const history = useHistory();
  const [isinsight, setIsInsight] = useState(false);
  const newNavigationEnabled = useSplitEnabled(Split.DASHBOARDS_NEW_NAVIGATION);

  useEffect(() => {
    const query = window.location.search.substr(1);
    if (query.includes("isInsight")) {
      setIsInsight(true);
    } else {
      setIsInsight(false);
    }
  });

  const breadcrumbs = [
    {
      title: "Digital Shelf",
      url: newNavigationEnabled ? undefined : "/dashboards",
    },
    {
      title: newNavigationEnabled ? dashboard?.name : dashboard?.tab,
      url: newNavigationEnabled ? undefined : `/dashboards/${dashboard?.tab}`,
    },
    {
      title: newNavigationEnabled ? dashboard?.tab : dashboard?.name,
      url: `/dashboards/${dashboard?.tab}/${dashboard?.id}`,
      component: () =>
        !dashboard ? (
          <Skeleton width={150} />
        ) : (
          <DashboardPickerSelect
            onChange={(d) => history.push(`/dashboards/${d?.tab}/${d?.id}`)}
            allDashboards={allDashboards}
            currentDashboard={dashboard}
            newNavigationEnabled={newNavigationEnabled}
          />
        ),
    },
  ];

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      justifyContent="space-between"
      {...props}
    >
      {isinsight && (
        <Stack direction="row" spacing={0} alignItems="center">
          <Button
            size="small"
            onClick={handleBack}
            sx={{
              lineHeight: "28px",
              padding: 0,
              height: "28px",
              display: "flex",
              gap: "4px",
              backgroundColor: "transparent",
            }}
          >
            <Icon src="back-arrow.svg" width="21px" height="21px" />
            <Typography variant="body5" color="textSecondary">
              Back to insights
            </Typography>
          </Button>
        </Stack>
      )}
      <HeaderBreadcrumbs breadcrumbs={breadcrumbs} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            variant="rounded"
            src={"/dashboard-icon.svg"}
            sx={{ width: (t) => t.spacing(4.5), height: (t) => t.spacing(4.5) }}
          />
          <Typography variant="h3_v2">{dashboard?.name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1.2}>
          {actions}
        </Stack>
      </Stack>
    </Stack>
  );
};
