import apiRequest from "../apiRequest";
import { Trigger, TriggerCreate } from "../../types";

export async function createTrigger(data: TriggerCreate): Promise<Trigger> {
  return await apiRequest<Trigger>("v1/triggers", "POST", undefined, data);
}

export async function updateTrigger(
  data: TriggerCreate,
  triggerId: string
): Promise<Trigger> {
  return await apiRequest<Trigger>(
    `v1/triggers/update/${triggerId}`,
    "POST",
    undefined,
    data
  );
}

export async function deleteTrigger(triggerId: string): Promise<Trigger> {
  return await apiRequest<Trigger>(`v1/triggers/${triggerId}`, "DELETE");
}

export async function listTriggers() {
  return await apiRequest<Trigger[]>("v1/triggers", "GET");
}

export async function getTrigger(triggerId?: string | null): Promise<Trigger> {
  if (!triggerId) {
    return Promise.reject(new Error("id is needed"));
  }
  return await apiRequest<Trigger>(`v1/triggers/${triggerId}`, "GET");
}
