import {Checkbox, FormControlLabel, Paper, Stack} from "@mui/material";
import {ChannelTypes} from "../../../enums";
import {useProductsGroupCountByChannelType} from "./api";
import {FC} from "react"

export const ChannelsFilters: FC<{
  channels: ChannelTypes[];
  onChange: (channels: ChannelTypes[]) => void;
}> = ({channels, onChange}) => {
  const {data: channelToGroupCount = []} =
      useProductsGroupCountByChannelType();
  return (
      <Paper sx={{padding: 1}} variant="outlined">
        {channelToGroupCount.map(({channel_type, count}) => (
        <Stack direction="row" key={channel_type}>
          <FormControlLabel
            label={`${channel_type} (${count})`}
            control={
              <Checkbox
                checked={channels.indexOf(channel_type) > -1}
                onChange={(_, checked) => {
                  const channelsSet = new Set<ChannelTypes>(channels);
                  if (checked) {
                    channelsSet.add(channel_type);
                  } else {
                    channelsSet.delete(channel_type);
                  }
                  // @ts-ignore
                  onChange([...channelsSet]);
                }}
              />
            }
          />
        </Stack>
      ))}
    </Paper>
  );
};
