import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { EMPTY_FILTERS } from "./constants";
import {
  InsightRead,
  Sort,
  State,
  StatsPeriods,
  TransformedInsight,
} from "./types";
import { useInsightMetadata, useInsightsV2 } from "./useInsights";
import { ProductsFilter } from "../../types/ecommerce";
import { useSplitEnabled } from "../../hooks";
import { Split } from "../../FeatureFlags/enums";

const transformRawInsights = (insights?: InsightRead[]): TransformedInsight[] =>
  (insights || []).map((item) => ({
    ...item,
    categoryId: item.categories.join(" | "),
    isChecked: false,
  }));

export const insightsContext = createContext<State>({} as State);

export const InsightsProvider = ({ children }: { children: ReactNode }) => {
  const [selectedProductGroup, setSelectedProductGroup] = useState<
    ProductsFilter | undefined | null
  >(null);
  const [statsPeriod, setStatsPeriod] = useState<StatsPeriods>("7 days");
  const [filterOut, setFilterOut] = useState(EMPTY_FILTERS);
  const [selectedInsights, setSelectedInsights] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  const [sort, setSort] = useState<Sort>({
    column: revenueOverTimeEnabled ? "sales7D" : "revenueValue",
    ascending: false,
    isDirty: false,
  });
  const [isDrawerEnabled, enableDrawer] = useState(true);

  const {
    data: insights,
    isLoading: isLoadingInsights,
    isFetching: isFetchingInsights,
    refetch: refetchInsights,
  } = useInsightsV2({ filterId: selectedProductGroup?.id });

  const {
    data: insightsMetadata,
    refetch: refetchInsightsMetadata,
    isLoading: metadataLoading,
  } = useInsightMetadata(selectedProductGroup?.id);

  const transformedInsights = useMemo(
    () => transformRawInsights(insights),
    [insights]
  );

  useEffect(() => {
    refetchInsights();
  }, [selectedProductGroup?.id]);

  const state: State = {
    sort,
    enableDrawer,
    isDrawerEnabled,
    setSort,
    filterOut,
    search,
    setSearch,
    setFilterOut,
    setSelectedInsights,
    insights: transformedInsights,
    isLoadingInsights,
    isFetchingInsights,
    selectedInsights,
    insightsMetadata,
    refetchInsights,
    refetchInsightsMetadata,
    metadataLoading,
    selectedProductGroup,
    setSelectedProductGroup,
    statsPeriod,
    setStatsPeriod,
  };
  return (
    <insightsContext.Provider value={{ ...state }}>
      {children}
    </insightsContext.Provider>
  );
};
