import {useLocation, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router";
import {Paper} from "@mui/material";
import {useNewRunForm} from "./hooks";
import ParametersSection from "./ParametersSection";
import InputsSection from "./InputsSection";
import {useRunEvents} from "../../hooks/metrics/runEvents";
import {useIsEditMode} from "../../hooks/useIsEditMode";
import {FC, useState} from "react"

enum Section {
  inputs,
  parameters,
}

const NewRun: FC = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {boardId}: { boardId?: any } = useParams();
  const isEditMode = useIsEditMode();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const runId = queryParams.get("runId");
  const onSuccess = () => {
    enqueueSnackbar("Run has been successfully started", {
      variant: "success",
    });
    history.push(`/boards/${boardId}`);
  };
  const [sectionToDisplay, setSectionToDisplay] = useState<Section>(
    runId || isEditMode ? Section.parameters : Section.inputs
  );
  const onError = (error: any) =>
    enqueueSnackbar(error.message, { variant: "error" });
  const runForm = useNewRunForm(boardId, onSuccess, onError, runId);
  const { completeInputsStepEvent } = useRunEvents({ boardId, runId });
  return (
    <Paper
      square
      sx={{
        background: `url("/background-pipeline-grey.png")`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "right",
        backgroundSize: "contain",
      }}
    >
      {sectionToDisplay === Section.inputs ? (
        <InputsSection
          inputMethods={runForm.inputMethods}
          onCompletion={() => {
            completeInputsStepEvent();
            setSectionToDisplay(Section.parameters);
          }}
        />
      ) : (
        <ParametersSection
          onBack={() => setSectionToDisplay(Section.inputs)}
          runForm={runForm}
        />
      )}
    </Paper>
  );
};
export default NewRun;
