import { Card, styled } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  minHeight: "100px",
  display: "flex",
  flexDirection: "row",
}));

export default StyledCard;
