import {Box, Container} from "@mui/material";
import {BoardDocumentation} from "@noogata/components";

import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";
import Loadable from "../../components/Loadable";
import {BlockTypeToIcon} from "../../mappings";

import {BoardTemplate} from "../../types/board-templates";
import {useBoardDocsMDFile, useBoardTemplateLatestDoc} from "./hooks";
import {BoardTemplateHeader} from "./BoardTemplateHeader";
import {useRouteMetrics} from "../../hooks/metrics/routeEvents";
import {FC} from "react"

const BoardTemplateInformation: FC<{ boardTemplate?: BoardTemplate }> = ({
                                                                             boardTemplate,
                                                                         }) => {
    useRouteMetrics(["/marketplace/:boardTemplateId"], {
        scope: ["marketplace"],
    });

    const boardTemplateId = boardTemplate?.id;
    const {data: boardTemplateDoc, isLoading: isBoardTemplateDocLoading} =
    boardTemplateId ? useBoardTemplateLatestDoc(boardTemplateId) : ({} as any);
  const { data: docsFile, isLoading: isBoardTemplateDocFileLoading } =
    boardTemplateId ? useBoardDocsMDFile(boardTemplateId) : ({} as any);
  return (
    <Box>
      <Box
        sx={{
          background: "#F5F5F5",
          marginBottom: (theme: any) => theme.spacing(5),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: (theme: any) => theme.spacing(5),
          }}
        >
          <BoardTemplateHeader boardTemplate={boardTemplate} />
        </Container>
      </Box>
      <Container>
        <Loadable
          isLoading={
            !boardTemplateId ||
            isBoardTemplateDocLoading ||
            isBoardTemplateDocFileLoading
          }
        >
          {docsFile && boardTemplate ? (
            <BoardDocumentation
              boardAssets={{
                carousel: boardTemplateDoc?.carousel || [],
                blockTypeToIcon: BlockTypeToIcon,
              }}
              boardReadme={docsFile}
              board={BoardTemplate.toBoard(boardTemplate)}
            />
          ) : (
            <IllustrationPlaceholder
              text="Block documentation not found."
              imageHeight={300}
              imageWidth={300}
            />
          )}
        </Loadable>
      </Container>
    </Box>
  );
};

export default BoardTemplateInformation;
