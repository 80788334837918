import { Box, Chip, Typography } from "@mui/material";
import { FC } from "react";
import { useCatalogEnrichmentContext } from "./selectors";
import { formatRunTime, getStatusColor, nodeLabelToTitle } from "./utils";
import { EnrichmentFlowState } from "./types";
import theme from "../../themes/light";

interface NodeStatusProps {
  status: EnrichmentFlowState;
}

const NodeStatus: FC<NodeStatusProps> = (props: any) => {
  const { status } = props;
  const { textColor, backgroundColor } = getStatusColor(status);

  return (
    <Box>
      <Chip
        label={status}
        variant="outlined"
        sx={{
          textTransform: "uppercase",
          color: textColor,
          backgroundColor,
          border: "none",
          width: "100px",
        }}
      />
    </Box>
  );
};

interface GraphListItemProps {
  label: string;
  status: string;
  startTime: Date;
  endTime: Date;
}

const GraphListItem: FC<GraphListItemProps> = (props: any) => {
  const { label, status, startTime, endTime } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
        width: "80%",
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow:
          "0px 8px 20px -6px rgba(44, 34, 76, 0.05), 0px 12px 45px -4px rgba(44, 34, 76, 0.05)",
        padding: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography variant="h6">{label}</Typography>
        {status === EnrichmentFlowState.Completed ||
        status === EnrichmentFlowState.Failed ||
        status === EnrichmentFlowState.Crashed ||
        status === EnrichmentFlowState.Cancelled ? (
          <Typography
            variant="body2"
            sx={{
              color:
                status === EnrichmentFlowState.Completed
                  ? theme.palette.success.dark
                  : theme.palette.error.dark,
            }}
          >
            {formatRunTime(startTime, endTime)}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.primary.dark,
            }}
          >
            In Progress
          </Typography>
        )}
      </Box>
      <NodeStatus status={status} />
    </Box>
  );
};

const CatalogEnrichmentOverview: FC = () => {
  const { flowGraph } = useCatalogEnrichmentContext();
  // const startTime = new Date(flowGraph.start_time);
  // const endTime = new Date(flowGraph.end_time);

  const graphList = flowGraph.nodes?.map(([id, node]: any) => {
    return {
      id,
      label: nodeLabelToTitle(node.label),
      status: node.state_type,
      startTime: new Date(node.start_time),
      endTime: new Date(node.end_time),
    };
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflow: "auto",
        height: "100%",
        padding: "1rem 0",
      }}
    >
      {graphList &&
        graphList.length > 0 &&
        graphList.map((node: any) => (
          <GraphListItem
            key={node.id}
            label={node.label}
            status={node.status}
            startTime={node.startTime}
            endTime={node.endTime}
          />
        ))}
    </Box>
  );
};

export default CatalogEnrichmentOverview;
