import {Button, DialogActions} from "@mui/material";
import {FC} from "react"
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme) =>
    createStyles({
        warningIcon: {
            color: theme.palette.warning.light,
        },
        actions: {
            position: "sticky",
      bottom: theme.spacing(-1),
      backgroundColor: theme.palette.background.paper,
    },
  })
);

type MappingActionsProps = {
  onBack: () => void;
  isValid: boolean;
  isSubmitting: boolean;
};

const MappingActions: FC<MappingActionsProps> = ({
  onBack,
  isValid,
  isSubmitting,
}) => {
  const classes = useStyles();
  return (
    <DialogActions className={classes.actions}>
      <Button color="primary" variant="contained" onClick={onBack} autoFocus>
        Back
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isValid || isSubmitting}
      >
        Finish Mapping
      </Button>
    </DialogActions>
  );
};

export default MappingActions;
