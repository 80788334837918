import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../apiRequest";
import { ProductFilter } from "../../types/ecommerce";

const getProductsFilter = (): Promise<ProductFilter[]> =>
  apiRequest<ProductFilter[]>(
    "v1/onboarding/ecommerce/products/filters",
    "GET"
  );
const useProductsFilter = (
  options?: UseQueryOptions<ProductFilter[], APIError>
): UseQueryResult<ProductFilter[], APIError> =>
  useQuery<ProductFilter[], APIError>(
    ["GetProductsFilters"],
    () => getProductsFilter(),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export default useProductsFilter;
