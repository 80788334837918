import { AdminPortal } from "@frontegg/react";
import Admin from "@mui/icons-material/AdminPanelSettings";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Explore from "@mui/icons-material/ExploreOutlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Box,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { ThemeProvider } from "@mui/material/styles";
import clsx from "clsx";

import SVG from "react-inlinesvg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavbarModes, PlatformPermission, PlatformRole } from "../../enums";
import { Split } from "../../FeatureFlags/enums";
import { useIsTenantNew, useSplitEnabled } from "../../hooks";
import AppModeButton from "../AppModeButton";
import AppModeClosedButton from "../AppModeClosedButton";
import { AuthorizedContent } from "../AuthorizedContent";
import DataIcon from "../DataIcon";
import NavbarIndicator from "../NavbarIndicator";
import { ProtectedComponentWithRoles } from "../ProtectedRoute";
import { useAppContext } from "../../ApplicationContext";
import { TenantSelection } from "../TenantSelection";
import themev2 from "../../themev2";
import { UserSection } from "./UserSection";
import { useStyles } from "./useStyles";
import { FC } from "react";

export type Item = {
  title: string;
  href?: string;
  enabled?: boolean;
  roles?: PlatformRole[];
  icon: any;
  onClick?: () => void;
  disabled?: boolean;
};

const LabLeftNav: FC = () => {
  const classes = useStyles();
  const { navbarMode, setNavbarMode } = useAppContext();
  const open = navbarMode === NavbarModes.EXPENDED;
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const applicationModeEnabled = useSplitEnabled(Split.APPLICATION_MODE);
  const handleTitleClick = () => {
    history.push("/");
  };

  const setOpen = (open: boolean) =>
    setNavbarMode(open ? NavbarModes.EXPENDED : NavbarModes.COLLAPSED);

  const isItemSelected = (item: Item) =>
    Boolean(
      item?.href &&
        (location.pathname.split("/")[1] === item.href.split("/")[1] ||
          (location.pathname === "/" && item.href === "/circuitboards"))
    );
  const { totalProducts } = useIsTenantNew();

  const items: Item[] = [
    {
      title: "Workspace",
      href: "/boards",
      roles: undefined,
      icon: DeveloperBoardIcon,
      disabled: !totalProducts,
    },
    {
      title: "Block Directory",
      href: "/marketplace",
      roles: [],
      icon: Explore,
    },
    {
      title: "Data",
      href: "/data",
      roles: [],
      icon: DataIcon,
    },
    {
      title: "Data Warehouse",
      href: "/warehouse",
      roles: [],
      icon: (props: { fill: string }) => (
        <SVG width={21} height={21} src={"/dwh-icon.svg"} {...props} />
      ),
    },
    {
      title: "Data Sources",
      href: "/datasources",
      roles: [],
      icon: (props: { fill: string }) => (
        <SVG width={20} height={20} src={"/datasource.svg"} {...props} />
      ),
    },
    {
      title: "Administration",
      roles: [],
      icon: SupervisorAccountIcon,
      onClick: () => AdminPortal.show(),
    },
    {
      title: "Logout",
      href: "/account/logout",
      roles: [],
      icon: ExitToAppIcon,
    },
  ];

  return (
    <ThemeProvider theme={themev2}>
      <Drawer
        open
        variant="permanent"
        transitionDuration={50}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box paddingTop={3} flexGrow={1} overflow="hidden">
          <Box className={classes.logo} onClick={handleTitleClick}>
            {open ? (
              <img
                alt=""
                src={`/logo-black.svg`}
                height={31}
                width={114}
                style={{ marginLeft: 14, marginTop: -1 }}
              />
            ) : (
              <img
                alt=""
                src={`/favicon.svg`}
                height={30}
                width={30}
                style={{ marginLeft: 12 }}
              />
            )}
          </Box>

          <List sx={{ px: 0 }}>
            {items.map((item, index) => (
              <AuthorizedContent requiredRoles={item?.roles} key={index}>
                <ListItemButton
                  classes={{
                    root: classes.item,
                    selected: classes.selectedItem,
                  }}
                  key={item.title}
                  selected={isItemSelected(item)}
                  disabled={!!item?.disabled}
                  onClick={() => {
                    if (item.href) {
                      history.push(item.href);
                    }

                    if (item.onClick) {
                      item.onClick();
                    }

                    setOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <item.icon
                      color={isItemSelected(item) ? "primary" : "inherit"}
                      fill={
                        isItemSelected(item)
                          ? theme.palette.primary.main
                          : "rgba(0, 0, 0, 0.54)"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                      variant: isItemSelected(item) ? "subtitle2" : "body2",
                    }}
                  />
                </ListItemButton>
              </AuthorizedContent>
            ))}
          </List>
        </Box>

        <Stack mb={1} justifyContent="center" spacing={2}>
          {applicationModeEnabled && (
            <>{open ? <AppModeButton /> : <AppModeClosedButton />}</>
          )}
          {open && (
            <ProtectedComponentWithRoles
              permissions={[PlatformPermission.TENANT_ADMIN_GET]}
            >
              <Button
                variant="contained"
                color="primary"
                title="Manage Workspace"
                component={Link}
                to="/workspaces"
                startIcon={<Admin />}
              >
                Manage Workspace
              </Button>
            </ProtectedComponentWithRoles>
          )}
          {open && <TenantSelection />}
          <UserSection open={open} style={{ marginLeft: theme.spacing(1) }} />
        </Stack>
        <NavbarIndicator />
      </Drawer>
    </ThemeProvider>
  );
};

export default LabLeftNav;
