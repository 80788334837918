import theme from "../../themes/light.ts";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useRef, useState } from "react";
import { useChat } from "./ChatProvider.tsx";

const InsightsChatV2 = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { messages, sendMessage, disabled } = useChat();
  const [newMessage, setNewMessage] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      submit();
    }
  };
  const submit = () => {
    if (newMessage.length === 0) return;
    sendMessage(newMessage);
    setNewMessage("");
  };

  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, ref.current]);

  return (
    <Stack height={"100vh"} sx={{ background: theme.palette.grey[50] }}>
      <Box px={4} py={2} bgcolor={"white"}>
        <Typography pb={2} variant="body3" sx={{ fontSize: "20px" }}>
          Insights Chat
        </Typography>
      </Box>
      <Stack direction="row" gap={5} m={5} flex={1} overflow="hidden">
        <Box flex={1}>Logo</Box>
        <Stack
          flex={1}
          bgcolor={"white"}
          p={1}
          sx={{
            overflow: "none",
            borderRadius: "10px",
            ".chat": {
              maxWidth: "255px",
              wordWrap: "break-word",
              marginBottom: "12px",
              lineHeight: "24px",
              position: "relative",
              padding: "10px 20px",
              borderRadius: "25px",
              "&:before, &:after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                height: "25px",
              },
              "&.end": {
                color: "white",
                background: "#0B93F6",
                alignSelf: "flex-end",
                "&:before": {
                  right: "-7px",
                  width: "20px",
                  backgroundColor: "#0B93F6",
                  borderBottomLeftRadius: "16px 14px",
                },
                "&:after": {
                  right: "-26px",
                  width: "26px",
                  backgroundColor: "white",
                  borderBottomLeftRadius: "10px",
                },
              },
              "&.start": {
                color: "black",
                background: "#E5E5EA",
                alignSelf: "flex-start",
                "&:before": {
                  left: "-7px",
                  width: "20px",
                  backgroundColor: "#E5E5EA",
                  borderBottomRightRadius: "16px",
                },
                "&:after": {
                  left: "-26px",
                  width: "26px",
                  backgroundColor: "white",
                  borderBottomRightRadius: "10px",
                },
              },
            },
          }}
        >
          <Stack
            flex={1}
            p={2}
            sx={{
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            {messages?.map((msg: any, index: number) => {
              return (
                <Stack
                  key={`message-${index}`}
                  className={`chat ${
                    msg.type === "question" ? "start" : "end"
                  }`}
                >
                  {msg.msg}
                </Stack>
              );
            })}
            <Box ref={ref} />
          </Stack>
          <Stack>
            <TextField
              autoFocus
              sx={{ m: 1, flex: 1, p: 1 }}
              id="chat-text"
              type="text"
              disabled={disabled}
              value={newMessage}
              onKeyDown={handleKeyDown}
              onChange={(e) => setNewMessage(e.target.value)}
              label="Type your question here..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SendIcon
                      onClick={submit}
                      onMouseDown={submit}
                      sx={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </Stack>
        </Stack>
      </Stack>
      {/*{(spacesListLoading || spacesListFetching) && <LinearProgress />}*/}
    </Stack>
  );
};

export default InsightsChatV2;
