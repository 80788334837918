import { FC, ReactNode } from "react";

import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";

type LoadableProps = {
  isLoading?: boolean;
  error?: Error | null;
  children?: ReactNode;
};

const Loadable: FC<LoadableProps> = (props) => {
  if (props.isLoading) {
    return <Loading />;
  }

  if (props.error) {
    return <ErrorMessage message={props.error.message} />;
  }

  return <>{props.children}</>;
};

export default Loadable;
