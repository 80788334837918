import { FC } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  ListSubheader,
  Stack,
} from "@mui/material";
import { Search } from "../../components/SearchInput/Search";
import { useBrandFilteredData, useSalesContext } from "./hooks/selectors";
import { useTenantsState } from "@frontegg/react-hooks/auth";

export const SelectHeaderSearch = () => {
  const { setBrandSearchValue } = useSalesContext();
  const handleChange = (value: string) => {
    setBrandSearchValue(value);
  };
  return (
    <>
      <ListSubheader sx={{ padding: 0, height: "40px" }}>
        <Search
          fullWidth
          placeholder="Search"
          sx={{ height: "100%", fontSize: "15px" }}
          onChange={(e: any) => handleChange(e.target.value)}
        />
      </ListSubheader>
    </>
  );
};
export type BrandProps = {
  handleSelect: any;
  brandFilter: string;
  filterData: any;
};
const FilterOptions: FC<BrandProps> = ({
  handleSelect,
  brandFilter,
  filterData,
}) => {
  return (
    <Box sx={{ maxHeight: "232px", height: "100%", overflow: "auto" }}>
      {filterData?.map((el: any, key: number) => (
        <Stack
          key={key}
          direction="row"
          spacing={0}
          alignItems="center"
          sx={{ padding: "8px 0px" }}
          onClick={() => handleSelect(el)}
        >
          <Checkbox
            disableRipple
            sx={{ padding: "0px 8px 0px 0px" }}
            onChange={() => handleSelect(el)}
            checked={el === brandFilter}
          />

          <ListItemText
            primaryTypographyProps={{
              variant: "body3",
              color: (theme) => theme.palette.text.primary,
            }}
            primary={el}
          />
        </Stack>
      ))}
    </Box>
  );
};

const BrandFilter = () => {
  const { brandFilter, setBrandFilter } = useSalesContext();
  const { activeTenant } = useTenantsState();
  const filterData = useBrandFilteredData();

  const handleSelect = (value: any) => {
    if (brandFilter === value) {
      setBrandFilter("");
      localStorage.setItem("brandFilter", "");
    } else {
      setBrandFilter(value);
      const brandFilterData = {
        value: value,
        tenentID: activeTenant?.id,
      };
      localStorage.setItem("brandFilter", JSON.stringify(brandFilterData));
    }
  };
  return (
    <FormControl variant="outlined" sx={{ padding: 0, width: "100%" }}>
      <SelectHeaderSearch />
      <FilterOptions
        filterData={filterData}
        handleSelect={handleSelect}
        brandFilter={brandFilter}
      />
    </FormControl>
  );
};

export default BrandFilter;
