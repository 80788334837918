import { Link } from "react-router-dom";
import { alpha, Box, Button, Skeleton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import LaunchIcon from "@mui/icons-material/Launch";
import dayjs from "../../utils";
import { deepPurple } from "@mui/material/colors";
import { CircuitBoard } from "../../types";
import RunSectionRunsTable from "./RunSectionRunsTable";
import usePublishedRun from "../Run/RunV2/hooks/usePublishedRun";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    publishedRunStrip: {
      background: alpha(deepPurple.A700, 0.06),
      height: 37,
    },
    cardHeader: {
      padding: theme.spacing(2),
    },
    configButton: {
      marginRight: theme.spacing(2),
    },
  })
);

const PublishedRunGridItem: FC<{
  boardId: string;
}> = ({ boardId }) => {
  const classes = useStyles();
  const { data, isLoading } = usePublishedRun(boardId);
  return (
    <Box pl={2} pr={2} pt={0.5} pb={0.5} className={classes.publishedRunStrip}>
      <Box display="flex">
        <Box pt={0.5} flex={1} flexGrow={1}>
          {isLoading && <Skeleton width="20%" />}
          {data && (
            <>
              The current published run is <b>&quot;{data.run.name}&quot;</b>,
              published by <b>{data.user_name}</b>{" "}
              {dayjs.utc().to(dayjs.utc(data.created_at))}
            </>
          )}
          {!isLoading &&
            !data &&
            "No run is published yet. Set a run as the default run in the dashboard by publishing a completed run."}
        </Box>
        <Box>
          <Button
            size="small"
            color="primary"
            variant="text"
            startIcon={<LaunchIcon />}
            disabled={isLoading}
            target="_blank"
            component={Link}
            to={`/apps/${boardId}/dashboard/`}
          >
            Dashboard
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

type RunSectionProps = {
  board: CircuitBoard;
};

const RunSection: FC<RunSectionProps> = ({ board }) => (
  <>
    <PublishedRunGridItem boardId={board.identifier} />
    <RunSectionRunsTable board={board} />
  </>
);

export default RunSection;
