import { useEffect, useMemo, useState } from "react";
import { GroupedVirtuoso, TopItemListProps } from "react-virtuoso";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { Icon } from "../../../components/Icon";
import { useSalesContext } from "../hooks/selectors";
// import { salesAssistantContext } from "../SalesProvider";
import EmptyStates from "./EmptyStates";
// import SekeltonList from "./SkeletonList";
import { FilterSalesListItem } from "./FilterSalesListItem";

let newSortCopy = {
  column: "",
  ascending: false,
  isDirty: false,
  days: "7d",
};

const FilterVirtualList = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [filteredList, setFilteredList] = useState<any>([]);
  const {
    sort,
    setSort,
    // isFetchingInsights,
    // search,
    allSorts,
    setAllSorts,
    //categorySearchValue,
    //brandSearchValue,
    producGroupData,
    isFetchingProductGroup: isFetching,
  } = useSalesContext();

  // const { refetchInsights } = useContext(salesAssistantContext);
  //const [isUpdatedList, setIsUpdatedList] = React.useState(false);
  useEffect(() => {
    newSortCopy = sort;
  }, [sort]);

  useEffect(() => {
    if (
      producGroupData &&
      producGroupData?.length > 0 &&
      newSortCopy?.column === sort?.column
    ) {
      if (producGroupData?.length > 0 && !isFetching) {
        
        if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
          setAllSorts((old: any) => [...old, sort]);
       
        }
       
        setFilteredList([...producGroupData]);
        return;
      }
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
        setAllSorts((old: any) => [...old, sort]);
        
        if (allSorts.length > 0) {
          
          return;
        }
      }
      if (
        producGroupData?.length !== 0 &&
        newSortCopy?.column === sort?.column
      ) {
       
        setFilteredList([...producGroupData]);
      }
      return;
    } else {
      if(producGroupData && producGroupData.length === 0) {
        setFilteredList([])
        
        return;
      }
    }

    if (producGroupData && producGroupData?.length > 0 && !isFetching) {
     
      
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
       
        setAllSorts((old: any) => [...old, sort]);
      }
     
      setFilteredList([...producGroupData]);
    }
  }, [producGroupData?.length, sort?.column, isFetching]);

  useEffect(() => {
    if (pathname.search(/insights/) !== -1) {
      setSort({ ...sort, isDirty: false });
    }
  }, [pathname]);

  // useEffect(() => {
  //   if (pathname) {
  //     setFilteredList([]);
  //   }
  // }, [pathname]);

  const groupCounts = useMemo(() => {
    const i = 0;
    if (filteredList) {
      return [i, filteredList.length - i];
    } else {
      return [i, 0];
    }
  }, [filteredList]);

  // useEffect(() => {
  //   if (isUpdatedList) {
  //     refetchInsights();
  //   }
  // }, [isUpdatedList]);

  // const arr = [1, 2, 3];

  return filteredList?.length ? (
    <GroupedVirtuoso
      style={{ flex: 1 }}
      groupCounts={groupCounts}
      defaultItemHeight={189}
      components={{
        TopItemList: ({ children, ...rest }: TopItemListProps) => (
          <div {...rest} style={{ position: "static", minHeight: "24px" }}>
            {children}
          </div>
        ),
      }}
      groupContent={(index) =>
        pathname.search("my-items") !== -1 &&
        !!index &&
        groupCounts[1] !== 0 &&
        !sort.isDirty ? (
          <Divider
            textAlign="left"
            sx={{
              marginY: "10px",
              marginX: "35px",
              "&::before": {
                width: "0",
              },
              "> span": {
                paddingLeft: 0,
              },
            }}
          >
            <Stack direction="row" alignItems="center" gap="8px">
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                component="div"
              >
                Past insights
              </Typography>
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography
                    variant="body5"
                    color={theme.palette.success.contrastText}
                  >
                    Insights that haven't been updated for at least 1 week and
                    may be inaccurate
                  </Typography>
                }
              >
                <Box
                  sx={{
                    color: theme.palette.icons.secondary,
                    cursor: "pointer",
                  }}
                  width="16px"
                  height="16px"
                >
                  <Icon
                    src="insights/info-fill.svg"
                    width="16px"
                    height="16px"
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Divider>
        ) : (
          <div style={{ height: "1px" }} />
        )
      }
      itemContent={(index) => {
        const insight = filteredList[index];
        if(insight.title === "All products") {
          return <></>;
        }
        return (
          <FilterSalesListItem
            insight={insight}
            key={insight.id}
            setIsUpdatedList={""}
          />
        );
      }}
    />
  ) : isFetching ? (
    <>
      {/* {arr?.map((i) => (
        <SekeltonList key={i} />
      ))} */}
    </>
  ) : (
    <EmptyStates />
  );
};

export default FilterVirtualList;
