import {Box, Chip, Grid, Stack, Theme, ThemeProvider} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {BoardCardManagedChip} from "@noogata/components";
import {FC} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useRouteMatch} from "react-router";
import {PageHeader} from "../../components";
import {BlockIcon} from "../../components/BlockIcon";
import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";
import LibraryMenu from "../../components/LibraryMenuV2/LibraryMenu";
import Loadable from "../../components/Loadable";
import SearchBar from "../../components/SearchInput/SearchBar";
import {BoardLibrary} from "../../enums";
import {Split} from "../../FeatureFlags/enums";
import {useSearchParams, useSplitEnabled} from "../../hooks";
import {useInstallableBoards} from "../../services/boards";
import theme from "../../themev2";
import {InstallableCircuitboard} from "../../types";
import BoardTemplateCard from "./BoardTemplateCard";
import OnboardingHeader from "./EcommerceOnboarding";
import useTenantHasAmazonProducts from "../../services/ecommerce/useTenantHasAmazonProducts";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  flexbox: {
    display: "flex",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingRight: theme.spacing(5),
  },
  content: {
    paddingLeft: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  image: {
    transition: theme.transitions.create(["fill", "stroke"]),
  },
  boardCard: {
    height: "100%",
  },
}));

const filter = (
  text: string,
  library: BoardLibrary,
  installableBorads: InstallableCircuitboard[]
) =>
  installableBorads.filter(
    (installable) =>
      installable.library === library &&
      (installable.template_name.toLowerCase().indexOf(text.toLowerCase()) >
        -1 ||
        installable.id.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
        installable.description.toLowerCase().indexOf(text.toLowerCase()) > -1)
  );
export const PremiumChip: FC = () => (
  <Chip label="Premium" variant="outlined" color="primary" size="small" />
);
export const PREMIUM = "premium";
const Marketplace: FC<object> = () => {
  const { flowId } = useParams<{ flowId?: string }>();
  const flowFirstBlock = useRouteMatch("/flows/:flowId/blocks/first");
  const classes = useStyles();
  const history = useHistory();
  const { search, setSearchParams } = useSearchParams();
  const library = (search?.library as BoardLibrary) || BoardLibrary.ECOMMERCE;
  const { data = [], isLoading, error } = useInstallableBoards();
  const noProductsForTenant = !useTenantHasAmazonProducts();
  const filteredData = filter(
    (search?.searchText as string) || "",
    library,
    data
  );
  const onboardingEnabled = useSplitEnabled(Split.ONBOARDING_FEATURE);
  return (
    <ThemeProvider theme={theme}>
      <Box mb={5}>
        {!flowFirstBlock && <PageHeader headerLabel="Block Directory" />}
      </Box>

      <Box display="flex" sx={{ mr: 4, ml: 4 }}>
        <Stack spacing={2} direction="column" sx={{ width: 240 }}>
          <SearchBar
            defaultValue={search?.searchText}
            size="small"
            options={[]}
            onInputChange={(_: any, searchText: any) =>
              setSearchParams({ ...search, searchText })
            }
          />
          <LibraryMenu
            defaultValue={library}
            onChange={(library: any) => setSearchParams({ ...search, library })}
            libraries={[...new Set(data.map((a) => a.library))]}
            isLoading={isLoading}
          />
        </Stack>
        <Box flex={1} className={classes?.content}>
          {onboardingEnabled && library === BoardLibrary.ECOMMERCE && (
            <OnboardingHeader />
          )}
          <Loadable isLoading={isLoading} error={error}>
            <Grid container spacing={3}>
              {filteredData
                .sort((a, b) => {
                  if (a.tags.includes(PREMIUM) && b.tags.includes(PREMIUM)) {
                    return a.template_name <= b.template_name ? -1 : 1;
                  }
                  if (a.tags.includes(PREMIUM)) {
                    return 1;
                  }
                  if (b.tags.includes(PREMIUM)) {
                    return -1;
                  }
                  return a.template_name <= b.template_name ? -1 : 1;
                })
                .map((installable) => (
                    <Grid item key={installable.id} xs={12} md={4} lg={4} xl={3}>
                      {/*// @ts-ignore*/}
                      <BoardTemplateCard
                          hoverable
                          contained={false}
                          displayName={installable.template_name}
                          description={installable.description}
                          subheader={
                            <Stack spacing={1} direction="row">
                              {installable.managed && <BoardCardManagedChip/>}
                              {installable.tags.includes(PREMIUM) && (
                                  <PremiumChip/>
                          )}
                        </Stack>
                      }
                      image={
                        <BlockIcon image={installable.image || ""} isLatest />
                      }
                      onGet={() => {
                        history.push({
                          pathname: `/marketplace/${installable.id}`,
                          state: {
                            flowId,
                          },
                        });
                      }}
                      className={classes.boardCard}
                      cardProps={{ sx: { width: "inherit" } }}
                      infoOnly={noProductsForTenant}
                    />
                  </Grid>
                ))}
            </Grid>
            {filteredData.length === 0 && (
              <IllustrationPlaceholder
                imageHeight={300}
                imageWidth={300}
                text="Could not find any templates for this library."
              />
            )}
          </Loadable>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Marketplace;
