import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Box,
  Divider,
  Stack,
  Tooltip,
  Avatar,
  Button,
  TextField,
  Menu,
} from "@mui/material";
import theme from "../../themes/light";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  CheckboxArchive,
  CloseListIcon,
  DoneIcon,
  EditTaskDescriptionIcon,
  InProgressIcon,
  LinkIcon,
  ToDoIcon,
  ToDoVectorIcon,
} from "../../assets/index";
import DeleteTask from "./DeleteTask";
import SkeletonItem from "./SkeletonItem";
import { updateTaskOrder } from "./useTaskApi";
import { onClickTaskRow } from "../SalesAssistant/List/List";
import { useSalesContext } from "../SalesAssistant/hooks/selectors";
import { useQueryParams } from "../../hooks";
import { useHistory } from "react-router-dom";
import { updateTask } from "./useTaskApi";
import moment from "moment";
import SkeletonList from "./SkeletonList";
import { useDisplayBoardList, useTasksContext } from "./hooks/selectors";
import { useAuthorizedUsers } from "../../hooks/useUsers";
import ReAssignMember from "./ReAssign";
import { handleToast } from "./TaskUtils";
// import { MenuItem } from "@mui/material";
import { AssignTeamMember } from "../SalesAssistant/List/Drawer/AssignTeamMember";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
// import { idID } from "@mui/material/locale";

interface TaskDescriptionProps {
  task: any;
  showIconId: any;
  expandText: string;
  setExpandText: Dispatch<SetStateAction<string>>;
  showInput: any;
  theme: any; // Assuming theme is of any type
  handleShowInput: (event: any, id: any, taskDescription: any) => void;
  handleInputChange: (event: any) => void;
  handleExpandText: (id: any) => void;
  editedTaskDescription: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    desc: {
      display: "block",
      overflow: "hidden",
      maxHeight: "40px",
      transition: "max-height 0.5s ease-out",
    },
    expanded: {
      "& .MuiBox-root": {
        maxHeight: "600px",
        transition: "max-height 0.5s ease-in",
      },
    },
  })
);

const TaskBoard = () => {
  const [deleteTask, setDeleteTask] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const {
    taskboardLoading,
    search,
    setIsUpdated,
    selectedAssignee,
    setBoardData,
  } = useTasksContext();
  const { isDrawerEnabled } = useSalesContext();
  const queryParams = useQueryParams();
  const history = useHistory();
  const allMembersList = useAuthorizedUsers();
  const allMembers = allMembersList?.filter(
    (el) => !el.email.includes("@noogata.com")
  );
  const [taskId, setTaskId] = useState<any>("");
  const [openReAssing, setOpenReAssing] = useState(false);
  const [showIconId, setShowIconId] = useState({});
  const [expandText, setExpandText] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [editedTaskDescription, setEditedTaskDescription] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [showSales, setShowSales] = useState(false);
  const [taskStatus, setTaskStatus] = useState<string>("");

  const handleExpandText = (id: string) => {
    if (id) {
      setExpandText(id);
    } else {
      setExpandText("");
    }
  };

  const handleShowInput = (event: any, id: any, taskDescription: any) => {
    event.stopPropagation();
    setShowIconId(id);
    setShowInput(id === showInput ? null : id);
    setEditedTaskDescription(taskDescription);
  };

  const handleInputChange = (event: any) => {
    setEditedTaskDescription(event.target.value);
  };

  const handleCancel = () => {
    setShowInput(false);
    setExpandText("");
  };

  const updateBoardList = async (
    taskId: string,
    columnName: string,
    type: string,
    keyName: string,
    value: string
  ) => {
    const updatedTasks = JSON.parse(JSON.stringify(data));
    if (type === "archive") {
      updatedTasks[columnName] = updatedTasks[columnName].filter(
        (task: any) => {
          return task.id !== taskId;
        }
      );
      setBoardData(updatedTasks);
      setData(updatedTasks);
    } else {
      updatedTasks[columnName] = updatedTasks[columnName].map((task: any) => {
        if (task.id === taskId) {
          return {
            ...task,
            [keyName]: value,
            status: columnName,
          };
        }
        return task;
      });
      setBoardData(updatedTasks);
      setData(updatedTasks);
    }
  };

  const handleSave = async (taskId: string, columnName: string) => {
    //setIsUpdated(false);
    const body = {
      description: editedTaskDescription,
      status: columnName,
    };
    await updateTask(body, taskId);
    await updateBoardList(
      taskId,
      columnName,
      "get",
      "description",
      editedTaskDescription
    );
    //setIsUpdated(true);
    setShowInput(false);
    setExpandText("");
  };

  const boardData = useDisplayBoardList(search, selectedAssignee);

  useEffect(() => {
    setData(boardData);
  }, [boardData]);

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;
    if (!destination || !source) {
      return;
    }
    const sourceColumnId = source.droppableId;
    const destinationColumnId = destination.droppableId;

    if (!sourceColumnId || !destinationColumnId) {
      return;
    }

    if (
      sourceColumnId === destinationColumnId &&
      source.index === destination.index
    ) {
      return; // No change in position, do nothing
    }
    if (sourceColumnId === destinationColumnId) {
      const updatedColumns = { ...data };
      const draggedTask = updatedColumns[sourceColumnId]?.[source.index];
      const newItems = Array.from(updatedColumns[sourceColumnId]);
      newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, draggedTask);
      updatedColumns[sourceColumnId] = newItems;
      const newitemsData = newItems?.map((el: any) => el?.id);
      setData(updatedColumns);
      setBoardData(updatedColumns);
      await updateTaskOrder(newitemsData);
      return;
    } else {
      const updatedColumns = { ...data };
      const draggedTask = updatedColumns[sourceColumnId]?.[source.index];
      const copyDraggedTask = { ...draggedTask, status: destinationColumnId };
      if (!draggedTask) {
        return;
      }
      updatedColumns[sourceColumnId].splice(source.index, 1);
      updatedColumns[destinationColumnId].splice(
        destination.index,
        0,
        copyDraggedTask
      );
      const taskId = copyDraggedTask?.id || "";
      setData(updatedColumns);
      setBoardData(updatedColumns);
      const payload = {
        status: destinationColumnId,
      };
      if (taskId !== undefined) {
        await updateTask(payload, taskId);
      }
    }
  };

  const handleDeletePopup = () => {
    setDeleteTask(!deleteTask);
  };

  const formatDate = (dateString: string) => {
    const date = moment(dateString);
    return date.format("MMM DD");
  };

  const handleSales = () => {
    history.push("/sales-assistant/products");
  };

  const getAssignInfo = (email: string) => {
    if (allMembers?.length > 0) {
      const userData = allMembers?.find((el) => el.email === email);
      const { profileImage, name } = userData || {};
      const assignName = name?.substring(0, 10);
      const assignFirstName = name?.substring(0, 1);
      return {
        profileImage,
        name: assignName,
        assignFirstName: assignFirstName,
      };
    }
  };

  const handleMovetoArcived = async (Id: string, columnName: string) => {
    const payload = {
      status: "archived",
      archived: true,
    };
    //setIsUpdated(false);
    handleToast("Task Archived");
    if (Id !== undefined) {
      await updateTask(payload, Id);
      await updateBoardList(Id, columnName, "archive", "", "");
      //setIsUpdated(true);
    }
  };
  const handleReAssign = (id: string, email: string) => {
    setOpenReAssing(true);
    setTaskId(id);
    setSelectedEmail(email);
  };
  const handleCloseReAssign = () => {
    setOpenReAssing(false);
  };

  const handleData = (data: any) => {
    return JSON.parse(data);
  };

  useEffect(() => {
    if (localStorage.getItem("salesBoard")) {
      setShowSales(false);
    } else {
      setShowSales(true);
    }
  }, [localStorage.getItem("salesBoard")]);

  useEffect(() => {
    const storedItem = localStorage.getItem("salesBoard");
    if (storedItem) {
      const { timestamp } = JSON.parse(storedItem);
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
      const storedTime = new Date(timestamp).getTime();
      const currentTime = new Date().getTime();
      if (currentTime - storedTime >= twentyFourHoursInMs) {
        localStorage.removeItem("salesBoard");
      }
    }
  }, []);

  const handleRemoveSales = () => {
    const timestamp = new Date().toISOString();
    localStorage.setItem("salesBoard", JSON.stringify({ timestamp }));
    setShowSales(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    id: string,
    email: string,
    status: string
  ) => {
    setTaskId(id);
    setSelectedEmail(email);
    setAnchorEl(event.currentTarget);
    setTaskStatus(status);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssign = async (value: string) => {
    //setIsUpdated(false);
    const body = {
      assignee: value,
      status: taskStatus,
    };
    const updatedTasks = JSON.parse(JSON.stringify(data));
    const columnData =  updatedTasks[taskStatus];
    const index= columnData?.findIndex((el:any) => el.id === taskId );
    updatedTasks[taskStatus][index].assignee= value;
    setBoardData(updatedTasks);
    setData(updatedTasks);
    await updateTask(body, taskId);
    await updateBoardList(taskId, taskStatus, "get", "assignee", value);
    handleClose();
    //setIsUpdated(true);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {taskboardLoading ? (
          <SkeletonList />
        ) : (
          <Grid
            sx={{
              display: "flex",
              padding: "24px 32px",
              gap: "16px",
              background: theme.palette.grey[50],
              maxWidth: theme.spacing(1300 / 8),
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
              "& .MuiPaper-root": {
                cursor: "pointer",
              },
            }}
          >
            {data &&
              Object.keys(data).map((column: any) => (
                <Grid item xs={4} sx={{ width: "33.33%" }}>
                  <Paper
                    sx={{
                      background: "transparent",
                      border: "1px solid",
                      borderColor: theme.palette.grey[200],
                      borderRadius: "16px",
                      boxShadow: "none",
                      minHeight: "auto",
                      minWidth: "321px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px",
                        background: theme.palette.backgrounds.white,
                        borderTopLeftRadius: "16px",
                        borderTopRightRadius: "16px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        align="center"
                        sx={{
                          background:
                            column === "in_progress"
                              ? theme.palette.warning.light
                              : column === "done"
                              ? "#E7F7EF"
                              : theme.palette.grey[50],
                          padding: "4px 12px",
                          color:
                            column === "in_progress"
                              ? theme.palette.warning.dark
                              : column === "Done"
                              ? "#005F29"
                              : theme.palette.grey[500],
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {column === "to_do" ? (
                          <ToDoIcon />
                        ) : column === "in_progress" ? (
                          <InProgressIcon />
                        ) : (
                          <DoneIcon />
                        )}
                        {column === "to_do"
                          ? "To Do"
                          : column === "in_progress"
                          ? "In Progress"
                          : "Done"}
                      </Typography>
                      {column !== "done" ? (
                        <Typography
                          variant="caption"
                          component="div"
                          align="right"
                          sx={{
                            color:
                              column === "in_progress"
                                ? theme.palette.warning.dark
                                : theme.palette.text.info,
                            backgroundColor:
                              column === "in_progress"
                                ? theme.palette.warning.light
                                : theme.palette.grey[50],
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "14px",
                          }}
                        >
                          {data[column]?.length}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          component="div"
                          align="left"
                          color={theme.palette.text.info}
                          width="100%"
                          sx={{
                            fontSize: "10px",
                            maxWidth: "125px",
                            lineHeight: "12px",
                          }}
                        >
                          Completed tasks will be archived after 14 days
                        </Typography>
                      )}
                    </Box>
                    <Droppable droppableId={column} key={column}>
                      {(provided: any, snapshot: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            padding: "16px",
                            gap: "16px",
                            display: "flex",
                            flexDirection: "column",
                            background: snapshot.isDraggingOver
                              ? theme.palette.grey[200]
                              : data[column]?.length === 0
                              ? "rgba(0, 0, 0, 0.02)"
                              : "transparent",
                            minHeight:
                              data[column]?.length === 0 ? "624px" : "",
                          }}
                        >
                          {showSales && (
                            <>
                              {column === "to_do" && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    background: theme.palette.primary.main,
                                    borderRadius: "8px",
                                    padding: "20px",
                                    justifyContent: "space-between",
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Typography
                                    variant="body4"
                                    gap="4px"
                                    display="flex"
                                    onClick={handleSales}
                                    sx={{
                                      color: theme.palette.text.dark,
                                      alignItems: "center",
                                      lineHeight: "0px",
                                      "& svg": {
                                        ":hover": {
                                          cursor: "pointer",
                                        },
                                      },
                                    }}
                                  >
                                    <LinkIcon />
                                    Go To Sales Assistant
                                  </Typography>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="8px"
                                    sx={{ position: "absolute", right: 48 }}
                                  >
                                    <ToDoVectorIcon />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleRemoveSales}
                                  >
                                    <CloseListIcon />
                                  </Box>
                                </Box>
                              )}
                            </>
                          )}
                          <>
                            {data[column] &&
                              data[column]?.map((task: any, index: any) => (
                                <Draggable
                                  key={task.id}
                                  draggableId={task.id}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {taskboardLoading ? (
                                        <SkeletonItem />
                                      ) : (
                                        <Card
                                          sx={{
                                            borderRadius: "8px",
                                          }}
                                        >
                                          <CardContent
                                            sx={{
                                              padding: "0",
                                              paddingBottom: "0 !important",
                                            }}
                                          >
                                            <Box
                                              position="relative"
                                              display="flex"
                                              padding="12px"
                                              gap="10px"
                                              justifyContent={"space-between"}
                                              onClick={() =>
                                                onClickTaskRow(
                                                  isDrawerEnabled,
                                                  queryParams,
                                                  history,
                                                  handleData(
                                                    task?.related_assets
                                                  )?.sales_assistant_id
                                                )
                                              }
                                            >
                                              <Box
                                                display={"flex"}
                                                justifyContent={"space-between"}
                                                flexDirection={"column"}
                                                width="100%"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  marginBottom={"8px"}
                                                  paddingInline={"10.5px"}
                                                >
                                                  <Typography
                                                    variant="body1"
                                                    sx={{
                                                      color:
                                                        theme.palette.secondary
                                                          .main,
                                                      fontSize: "12px",
                                                      fontStyle: "normal",
                                                      fontWeight: 500,
                                                      lineHeight: "24px",
                                                      letterSpacing: "-0.12px",
                                                    }}
                                                  >
                                                    SA-{task?.task_id}
                                                  </Typography>
                                                  <Box
                                                    display={"flex"}
                                                    gap={"16px"}
                                                  >
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        "& svg": {
                                                          path: {
                                                            fill:
                                                              showInput ===
                                                              task?.id
                                                                ? "#2c224c"
                                                                : "#b9b7bb",
                                                          },
                                                        },
                                                        ":hover": {
                                                          "& svg": {
                                                            "& path": {
                                                              fill: theme
                                                                .palette.text
                                                                .link,
                                                            },
                                                          },
                                                        },
                                                      }}
                                                      onClick={(event) =>
                                                        handleShowInput(
                                                          event,
                                                          task?.id,
                                                          task?.description
                                                        )
                                                      }
                                                    >
                                                      <EditTaskDescriptionIcon />
                                                    </Box>
                                                    <Tooltip
                                                      title={
                                                        <Typography variant="caption">
                                                          Archive task
                                                        </Typography>
                                                      }
                                                      placement="right"
                                                      arrow
                                                      PopperProps={{
                                                        sx: {
                                                          "& .MuiTooltip-tooltip":
                                                            {
                                                              padding: "12.4px",
                                                            },
                                                        },
                                                      }}
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleMovetoArcived(
                                                          task?.id,
                                                          column
                                                        );
                                                      }}
                                                    >
                                                      <Stack
                                                        sx={{
                                                          opacity: 0.4,
                                                          cursor: "pointer",
                                                          "&:hover": {
                                                            opacity: 1,
                                                            "& svg": {
                                                              "& path": {
                                                                fill: theme
                                                                  .palette.text
                                                                  .link,
                                                              },
                                                            },
                                                          },
                                                        }}
                                                      >
                                                        <CheckboxArchive />
                                                      </Stack>
                                                    </Tooltip>
                                                  </Box>
                                                </Box>
                                                {
                                                  <TaskDescriptionComponent
                                                    task={task}
                                                    showIconId={showIconId}
                                                    expandText={expandText}
                                                    setExpandText={
                                                      setExpandText
                                                    }
                                                    showInput={showInput}
                                                    theme={theme}
                                                    handleShowInput={
                                                      handleShowInput
                                                    }
                                                    handleInputChange={
                                                      handleInputChange
                                                    }
                                                    editedTaskDescription={
                                                      editedTaskDescription
                                                    }
                                                    handleExpandText={
                                                      handleExpandText
                                                    }
                                                  />
                                                }
                                              </Box>
                                            </Box>
                                            <Divider />
                                            <CardAssigneeSection
                                              handleReAssign={handleReAssign}
                                              task={task}
                                              getAssignInfo={getAssignInfo}
                                              formatDate={formatDate}
                                              selectedEmail={selectedEmail}
                                              setTaskId={setTaskId}
                                              setSelectedEmail={
                                                setSelectedEmail
                                              }
                                              handleAssign={handleAssign}
                                              columnName={column}
                                              anchorEl={anchorEl}
                                              handleClick={handleClick}
                                              open={open}
                                              handleClose={handleClose}
                                              showInput={showInput}
                                              handleSave={handleSave}
                                              handleCancel={handleCancel}
                                            />
                                          </CardContent>
                                        </Card>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </>
                        </div>
                      )}
                    </Droppable>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}
      </DragDropContext>
      <DeleteTask handleClose={handleDeletePopup} open={deleteTask} />
      <ReAssignMember
        open={openReAssing}
        handleClose={handleCloseReAssign}
        taskId={taskId}
        selectedEmail={selectedEmail}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};

export default TaskBoard;

const TaskDescriptionComponent: React.FC<TaskDescriptionProps> = ({
  task,
  expandText,
  showInput,
  handleInputChange,
  editedTaskDescription,
  handleExpandText,
}) => {
  const classes = useStyles();
  return (
    <>
      {!showInput || showInput !== task?.id ? (
        <Box
          component={"div"}
          display={"flex"}
          justifyContent="space-between"
          width={"100%"}
          gap={"10px"}
          paddingInline={"10.5px"}
        >
          <Typography
            variant="caption"
            lineHeight="20px"
            component="div"
            fontWeight="500px"
            fontSize="14px"
            fontStyle="normal"
            sx={{
              width: "calc(100% - 40px)",
              color: theme.palette.secondary.main,
              position: "relative",
              wordBreak: "break-word",
              whiteSpace: "pre-line",
              ":hover": { color: theme.palette.text.link },
            }}
          >
            <Box>
              <Box className={`${expandText === task?.id && classes.expanded}`}>
                <Box className={classes.desc}>{task?.description}</Box>
              </Box>
              {(task?.description?.length > 80 ||
                task?.description?.split(/^/gm)?.length > 2) && (
                <Typography
                  onClick={(event) => {
                    event.stopPropagation();
                    expandText === task?.id
                      ? handleExpandText("")
                      : handleExpandText(task?.id);
                  }}
                  variant="caption"
                  sx={{
                    margin: 0,
                    lineHeight: "20px",
                    color: "#ABA7B7",
                    transition: "all ease 0.1s",
                    display: "block",
                  }}
                >
                  {expandText === task?.id ? "show less" : "... more"}
                </Typography>
              )}
            </Box>
          </Typography>
          <Tooltip
            title={
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    display: "-webkit-box",
                    "-webkit-box-orient": "vertical",
                    "-webkit-line-clamp": "4",
                    overflow: "hidden",
                    marginBottom: "12px",
                    color: theme.palette.success.contrastText,
                  }}
                >
                  {task?.asin_data?.title || ""}
                </Typography>
                <Box>
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.success.contrastText,
                      opacity: 0.4,
                    }}
                  >
                    {task?.asin || ""}
                  </Typography>
                </Box>
              </Box>
            }
            arrow
            placement="right-start"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: "15.4px",
                },
              },
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                ":hover": {
                  border: " 1px solid #7B61FF",
                  borderRadius: "4px",
                },
                "& img": {
                  borderRadius: "4px",
                },
              }}
            >
              <img
                width="100%"
                height="100%"
                src={task?.asin_data?.image_url || "/tasks/Group.png"}
              />
            </Box>
          </Tooltip>
        </Box>
      ) : (
        <Box
          onClick={(event) => event.stopPropagation()}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            "& .MuiInputBase-formControl": {
              background: "rgba(127, 60, 238, 0.05);",
              border: "1px solid #7F3CEE !important",
              borderRadius: "8px",
              padding: "8px 12px",
              boxShadow: "transparent",
              outline: "none",
            },
            "& .MuiInputBase-formControl:hover": {
              border: "1px solid #7F3CEE !important",
              boxShadow: "transparent",
              outline: "none",
            },
            "& .MuiOutlinedInput-input": {
              color: theme.palette.secondary.main,
              fontSize: "14px",
              fontWeight: 500,
              letterSpacing: "-0.18px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
              display: "none",
            },
          }}
        >
          <TextField
            variant="outlined"
            fullWidth
            multiline
            value={editedTaskDescription}
            onChange={(e) => handleInputChange(e)}
          />
        </Box>
      )}
    </>
  );
};

const CardAssigneeSection = ({
  // handleReAssign,
  task,
  getAssignInfo,
  formatDate,
  selectedEmail,
  setSelectedEmail,
  handleAssign,
  anchorEl,
  handleClick,
  open,
  handleClose,
  showInput,
  handleSave,
  handleCancel,
  columnName,
}: any) => {
  return (
    <>
      {showInput === task?.id ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            gap: "8px",
            padding: "12px 22.5px 12px 12.5px",
            "& .MuiButtonBase-root": {
              height: "32px !important",
              minHeight: "32px",
              fontSize: "12px",
              fontWeight: 600,
              width: "70px",
            },
          }}
        >
          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{
              border: "1px solid #D7D5DB",
              background: "transparent",
              color: "#2C224C",
              "&:hover": { background: "transparent" },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSave(task.id, columnName)}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="12px 22.5px 12px 12.5px"
        >
          <Tooltip
            placement="right"
            arrow
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: "12.4px",
                },
              },
            }}
            title={getAssignInfo(task?.assignee)?.name}
          >
            <Typography
              onClick={(e) =>
                handleClick(e, task?.id, task?.assignee, task?.status)
              }
              variant="caption"
              sx={{
                padding: "4px 10px",
                fontSize: "12px",
                ":hover": {
                  background: theme.palette.primary.light,
                  borderRadius: "4px",
                  transition: "padding ease 0.8",
                },
              }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {task?.assignee ? (
                <>
                  {getAssignInfo(task?.assignee)?.profileImage ? (
                    <Avatar
                      alt="profile"
                      src={getAssignInfo(task?.assignee)?.profileImage}
                      sx={{
                        border: `2px solid ${theme.palette.background.paper}`,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "28.48px",
                        width: "28.48px",
                        borderRadius: "50%",
                        background: theme.palette.grey[50],
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {getAssignInfo(task?.assignee)?.assignFirstName}
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    height: "28.48px",
                    width: "28.48px",
                    borderRadius: "50%",
                    background: theme.palette.grey[50],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    },
                  }}
                >
                  <img src="/tasks/default.png" alt="task" />
                </Box>
              )}
            </Typography>
          </Tooltip>
          <Typography variant="caption" sx={{ fontSize: "12px" }}>
            {formatDate(task?.created_at)}
          </Typography>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                // boxShadow: "0px 2px 8px rgba(0,0,0,0.32)",
                border: "1px solid #e9e9e9",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 26,
                  height: 26,
                  ml: -0.5,
                  mr: 1,
                },
                "& .Mui-focused": {
                  borderWidth: "1px solid #8E4FF6 !important",
                },
                "& .MuiInputBase-adornedStart": {
                  border: "1px solid #8E4FF6",
                  "&:focus": {
                    border: "1px solid #8E4FF6",
                  },
                },
                "& .MuiFilledInput-root": {
                  background: "transparent",
                  "&:active": {
                    border: "1px solid #8E4FF6",
                    outline: "none",
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <AssignTeamMember
              type="reassign"
              handleChange={handleAssign}
              selectedEmail={selectedEmail}
              setSelectedEmail={setSelectedEmail}
            />
          </Menu>
        </Box>
      )}
    </>
  );
};
