import { Box, Link, LinkProps, Stack } from "@mui/material";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { CopyIcon } from "../assets";

const CopiedLink = (props: LinkProps) => {
  const [isHover, setIsHovered] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.children as string).then(() =>
      enqueueSnackbar(`${props.children} copied successfully`, {
        variant: "success",
      })
    );
  };
  return (
    <Stack
      spacing={1}
      direction={"row"}
      onMouseLeave={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
      alignItems={"center"}
      onClick={(e) => e.stopPropagation()}>
      <Link underline={"hover"} target={"_blank"} {...props} color={isHover ? "#7F3CEE" : "#2C224C"}/>
      {isHover ? (
        <Box
          sx={{
            cursor: "pointer",
            "& svg": { fill: "#2C224C" },
            borderRadius: "50%",
            padding: "0 7px",
            backgroundColor: "#F4F3F8"
          }}
          onClick={handleCopy}>
          <CopyIcon />
        </Box>
      ) : null}
    </Stack>
  );
};

export default CopiedLink;
