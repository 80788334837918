import { Box, Skeleton, Stack, Typography } from "@mui/material";
import theme from "../../themes/light";

const SkeletonItem = ({ index }: any) => {
  return (
    <Box
      key={index}
      sx={{
        transform: "scale(1)",
        widthL: "100%",
        background:
          "linear-gradient(90deg, rgba(186, 197, 209, 0.20) 0%, rgba(221, 227, 234, 0.60) 54.57%, rgba(186, 197, 209, 0.00) 100%)",
        padding: "16px",
        borderRadius: "12px",
      }}
    >
      <Stack display="flex" justifyContent="space-between" flexDirection="row">
        <Typography display="flex" alignItems="center" gap="11px">
          <Skeleton
            variant="circular"
            width={24}
            height={24}
            sx={{
              borderRadius: "40px",
              opacity: 0.3,
              background: theme.palette.backgrounds.white,
            }}
          />
          <Skeleton
            variant="rectangular"
            width="81px"
            height="12px"
            sx={{
              borderRadius: "4px",
              opacity: 0.3,
              background: theme.palette.backgrounds.white,
            }}
          />
        </Typography>
        <Skeleton
          variant="rectangular"
          width="65px"
          height="24px"
          sx={{
            borderRadius: "15px",
            opacity: 0.3,
            background: theme.palette.backgrounds.white,
          }}
        />
      </Stack>
      <Typography
        display="flex"
        flexDirection="column"
        sx={{ marginBlock: "19px 18px", gap: "8px" }}
      >
        <Skeleton
          variant="rectangular"
          width="215px"
          height="12px"
          sx={{
            borderRadius: "4px",
            opacity: 0.3,
            background: theme.palette.backgrounds.white,
          }}
        />
        <Skeleton
          variant="rectangular"
          width="167px"
          height="12px"
          sx={{
            borderRadius: "4px",
            opacity: 0.3,
            background: theme.palette.backgrounds.white,
          }}
        />
      </Typography>
      <Box
        sx={{
          width: "calc(100% + 32px)",
          marginInlineStart: "-16px",
          background: theme.palette.backgrounds.white,
          height: "1px",
          opacity: 0.3,
        }}
      />
      <Box display="flex" alignItems="center" marginTop="14px" gap="10px">
        <Skeleton
          variant="rounded"
          width="40px"
          height="40px"
          sx={{ opacity: 0.3, background: theme.palette.backgrounds.white }}
        />
        <Typography display="flex" flexDirection="column" gap="8px">
          <Skeleton
            variant="rectangular"
            width="229px"
            height="12px"
            sx={{
              borderRadius: "4px",
              opacity: 0.3,
              background: theme.palette.backgrounds.white,
            }}
          />
          <Skeleton
            variant="rectangular"
            width="116px"
            height="12px"
            sx={{
              borderRadius: "4px",
              opacity: 0.3,
              background: theme.palette.backgrounds.white,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};
export default SkeletonItem;
