import { Grid, Stack } from "@mui/material";
import { FC } from "react";
import Inputs from "./Inputs";
import Outputs from "./Outputs";
import Parameters from "./Parameters";
import ProgressionBox from "./ProgressionBox";
import RunMessages from "./RunMessages";
import { RunActions } from "./RunActions";
import { Status } from "../../../../enums";

const OverviewSection: FC<{
  boardId: string;
  runId: string;
  status: Status;
}> = ({ boardId, runId, status }) => (
  <Grid container height="100vh">
    <Grid item sm={6} md={8} lg={8}>
      <Stack spacing={2} margin={6}>
        <Outputs boardId={boardId} runId={runId} />
        <Inputs boardId={boardId} runId={runId} />
        <Parameters boardId={boardId} runId={runId} />
      </Stack>
    </Grid>
    <Grid
      item
      sm={6}
      md={4}
      lg={4}
      sx={{ backgroundColor: "grey.100", alignItems: "stretch" }}
    >
      <RunActions boardId={boardId} runId={runId} />
      <RunMessages boardId={boardId} runId={runId} />
      {status !== Status.SUCCEEDED &&
        status !== Status.FAILED &&
        status !== Status.TERMINATED && (
          <ProgressionBox boardId={boardId} runId={runId} />
        )}
    </Grid>
  </Grid>
);

export default OverviewSection;
