import {Box, Container} from "@mui/material";
import {BoardDocumentation} from "@noogata/components";
import {Board} from "@noogata/types";

import {useQuery} from "react-query";
import IllustrationPlaceholder from "../../../components/IllustrationPlaceholder";
import Loadable from "../../../components/Loadable";
import {BlockTypeToIcon} from "../../../mappings";
import {APIError} from "../../../services/apiRequest";
import {getBoardDocs} from "../../../services/circuitboards";
import {BoardDocs, CircuitBoard} from "../../../types";
import {FC} from "react"

type DocumentationSectionProps = {
  board: CircuitBoard;
};

const DocumentationSection: FC<DocumentationSectionProps> = ({board}) => {
  const {data: boardDocs, isLoading: isBoardDocsLoading} = useQuery<
      BoardDocs,
      APIError
  >(["BoardDocs", board], () => getBoardDocs(board.identifier), {
    retry: (_, response) => response.statusCode !== 404,
  });

  const { data: docsFile, isLoading: isDocsFileLoading } = useQuery<
    string | null,
    APIError
  >(
    [boardDocs?.doc_md],
    async () =>
      fetch((boardDocs as BoardDocs).doc_md).then((res) => res.text()),
    {
      enabled: !!boardDocs,
    }
  );

  return (
    <Loadable isLoading={isBoardDocsLoading || isDocsFileLoading}>
      {docsFile ? (
        <Box marginTop={5}>
          <Container maxWidth="lg">
            <BoardDocumentation
              board={board as Board}
              boardReadme={docsFile || ""}
              boardAssets={{
                carousel: boardDocs?.carousel || [],
                blockTypeToIcon: BlockTypeToIcon,
              }}
            />
          </Container>
        </Box>
      ) : (
        <IllustrationPlaceholder
          text="Block documentation not found."
          imageHeight={300}
          imageWidth={300}
        />
      )}
    </Loadable>
  );
};
export default DocumentationSection;
