import { Typography } from "@mui/material";

const Dismissed = () => (
  <>
    <img
      src="/insights/empty-dismiss.svg"
      alt="Insights you dismiss will show up here"
    />
    <Typography variant="body1" fontWeight="600" marginTop="20px">
      Insights you dismiss will show up here
    </Typography>
  </>
);

export default Dismissed;
