import { Card, Stack, Typography } from "@mui/material";
import { SkeletonRow } from "../../../../../components";
import { useBoard } from "../../../../../services/boards";
import TriggeredByCard from "./TriggeredByCard";
import { FC } from "react";

const BoardTriggeredByCard: FC<{ boardId: string }> = ({ boardId }) => {
  const { data, isLoading } = useBoard(boardId);
  return (
    <Card
      sx={(theme) => ({
        borderTopWidth: theme.spacing(0.5),
        borderTopColor: "grey.500",
      })}
    >
      <Stack>
        {isLoading ? (
          <SkeletonRow index={0} />
        ) : data && data.triggered_by.length > 0 ? (
          data.triggered_by.map((trigger) =>
            trigger.triggered_by_ids.map((triggeredBoardId) => (
              <TriggeredByCard boardId={triggeredBoardId} trigger={trigger} />
            ))
          )
        ) : (
          <Typography padding={2}>
            This block isn&apos;t triggered by any other block
          </Typography>
        )}
      </Stack>
    </Card>
  );
};
export default BoardTriggeredByCard;
