import { FC } from "react";
import { useTheme } from "@mui/material";
import SVG from "react-inlinesvg";
import { StyledAvatar, StyledCardHeader } from "../StyledComponents";
import RotatingBox from "../../../../components/RotatingBox";

const GatheringCardHeader: FC = () => {
  const theme = useTheme();
  return (
    <StyledCardHeader
      title="Step 1 of 2 - enriching your products with the most current information"
      subheader="This process may take a few minutes to complete"
      avatar={
        <StyledAvatar variant="rounded">
          <RotatingBox>
            <SVG fill={theme.palette.primary.dark} src={`/rotate.svg`} />
          </RotatingBox>
        </StyledAvatar>
      }
    />
  );
};

export default GatheringCardHeader;
