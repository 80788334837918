import { Typography } from "@mui/material";

const MyItems = () => (
  <>
    <img
      src="/sales/product-empty.svg"
      alt="At the moment your selection does not contain any products"
    />
    <Typography variant="body1" fontWeight="600" marginTop="20px">
      At the moment your selection does not contain any products
    </Typography>
  </>
);

export default MyItems;
