import { History as HistoryIcon, Settings } from "@mui/icons-material";
import { Box, Stack, TabProps } from "@mui/material";
import RadarIcon from "@mui/icons-material/Radar";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { useAuthUserOrNull } from "@frontegg/react";
import Configuration from "./Cofiguration";
import History from "./History";
import AdministrationHeader from "./Header";
import Tabs from "./Tabs";
import {
  UserAuthzorization,
  verifyUserAuth,
} from "../../hooks/useUserAuthorization";
import { PlatformPermission } from "../../enums";
import Actions from "./Actions";
import { FC, ReactNode } from "react";
import Status from "./Status.tsx";
import Logs from "./Logs.tsx";

export const WORKSPACES_ADMIN_PATH = "/workspaces";

export type TabDefinition = Omit<Partial<TabProps>, "children"> & {
  children: ReactNode;
} & UserAuthzorization;

const tabs: TabDefinition[] = [
  {
    value: "status",
    label: (
      <Box display="flex" alignItems="center">
        <RadarIcon sx={{ mr: 1 }} fontSize="small" />
        Status
      </Box>
    ),
    children: <Status />,
    permissions: [PlatformPermission.TENANT_ADMIN_GET],
  },
  {
    value: "logs",
    label: (
      <Box display="flex" alignItems="center">
        <ListAltIcon sx={{ mr: 1 }} fontSize="small" />
        Logs
      </Box>
    ),
    children: <Logs />,
    permissions: [PlatformPermission.TENANT_ADMIN_GET],
  },

  {
    value: "configuration",
    label: (
      <Box display="flex" alignItems="center">
        <Settings sx={{ mr: 1 }} fontSize="small" />
        Configuration
      </Box>
    ),
    children: <Configuration />,
    permissions: [PlatformPermission.TENANT_ADMIN_GET],
  },
  {
    value: "history",
    label: (
      <Box display="flex" alignItems="center">
        <HistoryIcon sx={{ mr: 1 }} fontSize="small" />
        <Box>History</Box>
      </Box>
    ),
    children: <History />,
    permissions: [PlatformPermission.TENANT_ADMIN_LIST],
  },
  {
    value: "actions",
    label: (
      <Box display="flex" alignItems="center">
        <HistoryIcon sx={{ mr: 1 }} fontSize="small" />
        <Box>Actions</Box>
      </Box>
    ),
    children: <Actions />,
    permissions: [PlatformPermission.TENANT_ADMIN_LIST],
  },
];

export const Administration: FC<object> = () => {
  const user = useAuthUserOrNull();
  const history = useHistory();
  const match = useRouteMatch<{ section?: string }>([
    [WORKSPACES_ADMIN_PATH, ":section"].join("/"),
    WORKSPACES_ADMIN_PATH,
  ]);
  const authzedTabs = tabs.filter(
    (tab) =>
      user &&
      verifyUserAuth(user, { roles: tab.roles, permissions: tab.permissions }),
  );
  const tabDefinitions = authzedTabs.map((t) => ({
    ...t,
    onClick: () => {
      history.push([WORKSPACES_ADMIN_PATH, t.value].join("/"));
    },
  }));
  const section = match?.params.section || tabDefinitions[0].value;
  return (
    <Stack direction="column" sx={{ height: "100vh", overflow: "hidden" }}>
      <AdministrationHeader />
      <Tabs tabs={tabDefinitions} value={section} />
      <Route exact path="/workspaces">
        <Redirect to={["/workspaces", tabDefinitions[0].value].join("/")} />
      </Route>
      {tabs.map((t, i) => (
        <Route key={i} path={[WORKSPACES_ADMIN_PATH, t.value].join("/")}>
          {t.children}
        </Route>
      ))}
    </Stack>
  );
};

export default Administration;
