import { FC } from "react";
import { DestinationType } from "../enums";
import { DestinationsLogoMapping } from "../mappings";

const DestinationTypeIcon: FC<{ type: DestinationType }> = ({ type }) => (
  <img
    alt={type as string}
    src={`/${
      Object.entries(DestinationsLogoMapping).find(
        ([k, _]) => k.toLocaleLowerCase() === type.toLocaleLowerCase()
      )?.[1]
    }`}
  />
);

export default DestinationTypeIcon;
