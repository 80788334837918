import { QueryObserverResult, useQuery } from "react-query";
import apiRequest, { APIError } from "../../services/apiRequest";
import { useLocation } from "react-router-dom";

function getTaskListdata(asin: string): Promise<any[]> {
  const params = {
    archived: false,
    limit: 100,
    offset: 0,
    asin: asin || "",
  };
  return apiRequest<any[]>("v1/tasks/", "GET", params).then((response) =>
    response.filter((el) => el?.status !== "done")
  );
}

export function useTaskListData(asin: string) {
  return useQuery<any[], APIError>(
    ["useInsightsMetadata", asin],
    () => getTaskListdata(asin),
    {
      staleTime: Infinity,
      refetchInterval: 5000,
    }
  );
}

function getTaskBoarddata(): Promise<any[]> {
  return apiRequest<any[]>("v1/tasks/board", "GET");
}

export function useTaskBoardData() {
  const { pathname } = useLocation();
  if(!pathname.includes('/tasks')) {
    return {
      isLoading: false,
      isError: false,
      data: [],
      refetch: () => {},
      isFetching:false,
    };
  }
  return useQuery<any[], APIError>(
    ["useTaskBoardData"],
    () => getTaskBoarddata(),
    {
      staleTime: Infinity,
      refetchInterval: 5000,
    }
  );
}

export function getTaskdataById(id: string): Promise<any[]> {
  return apiRequest<any[]>(`v1/tasks/${id}`, "GET");
}

export function deleteTaskdataById(id: string): Promise<any[]> {
  return apiRequest<any[]>(`v1/tasks/${id}`, "DELETE");
}

export function addTask(payload: any): Promise<any> {
  return apiRequest<any>(
    "v1/tasks/?limit=1000&offset=0",
    "POST",
    undefined,
    payload
  );
}

export function updateTask(payload: any, id: string): Promise<any[]> {
  return apiRequest<any[]>(`v1/tasks/${id}`, "PUT", undefined, payload);
}

function getTaskArchivedListdata(): Promise<any[]> {
  const params = {
    archived: true,
  };
  return apiRequest<any[]>("v1/tasks/?limit=1000&offset=0", "GET", params);
}

export function useTaskArchivedListData() 
{
  const { pathname } = useLocation();
  if(!pathname.includes('/tasks')) {
    return {
      data: [],
      refetch: () => Promise.resolve({} as QueryObserverResult<any[], APIError>),
    };
  }
  return useQuery<any[], APIError>(
    ["useTaskArchivedListData"],
    () => getTaskArchivedListdata(),
    {
      staleTime: Infinity,
      refetchInterval: 5000,
    }
  );
}

export function updateTaskOrder(payload: any): Promise<any[]> {
  return apiRequest<any[]>(`v1/tasks/reorder`, "POST", undefined, payload);
}
