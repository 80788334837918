import {useForm} from "react-hook-form";
import {useHistory} from "react-router";
import {useSnackbar} from "notistack";
import {ScheduleForm} from "../../../../types";
import {createSchedule} from "../../../../services/schedules/api";
import {NewRunFormResult} from "../../hooks/useNewRunForm";

export enum RepeatType {
  DAY = "day",
  WEEK = "week",
}

type ScheduleFormResult = {
  scheduleFormMethods: any
  onSubmit: any;
};

const useScheduleForm: (
  boardId: string,
  runForm: NewRunFormResult
) => ScheduleFormResult = (boardId, runForm) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = () => {
    enqueueSnackbar("Schedule has been successfully created", {
      variant: "success",
    });
    history.push(`/boards/${boardId}`);
  };
  const onError = (error: { message: string }) =>
    enqueueSnackbar(error.message, { variant: "error" });
  const scheduleFormMethods = useForm<ScheduleForm>({
    defaultValues: {
      start_date: new Date(),
      interval: { value: 1, type: RepeatType.WEEK },
      end: null,
    },
  });
  const { handleSubmit } = scheduleFormMethods;
  const onSubmit = handleSubmit(
    async (formValues: ScheduleForm) => {
      try {
        await createSchedule({
          board_id: boardId,
          data: runForm.getRunCreateFromFormValues(runForm.methods.getValues()),
          ...formValues,
        });
        onSuccess();
      } catch (error: any) {
        onError(error);
      }
    },
    (errors) =>
      onError({ message: `Form is invalid: ${JSON.stringify(errors)}` })
  );
  return { scheduleFormMethods, onSubmit };
};

export default useScheduleForm;
