import { Box, Tooltip, Typography } from "@mui/material";
import { getSpaces } from "../../../../services/spaces/useSpaces";
import { useEffect, useState } from "react";
import { ProductSpace } from "../../types";
import { useSalesAssistant } from "../../SalesAssistantProvider";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useHistory, useLocation } from "react-router-dom";

const ProductSpaces = ({
  spaceIds,
  imageUrl,
}: {
  spaceIds?: string[];
  imageUrl?: string;
}) => {
  const { salesAssistantList } = useSalesAssistant();
  const history = useHistory();
  const { pathname } = useLocation();

  const [spaceIconsPending, setSpaceIconsPending] = useState(false);
  const [productSpaces, setProductSpaces] = useState<ProductSpace[]>([]);
  const [spreadIcons, setSpreadIcons] = useState(false);

  const fetchSpacesData = async () => {
    if (spaceIconsPending || !spaceIds?.length) return;

    setSpaceIconsPending(true);

    await getSpaces()
      .then((res: any) => {
        const filteredSpaces = [...res].filter((space: any) =>
          spaceIds?.includes(space.id)
        );

        const newProductSpaces = filteredSpaces.map((space: any) => ({
          id: space.id,
          name: space.name,
          image_url:
            salesAssistantList?.data?.find(
              (product) =>
                product.space_ids?.includes(space.id) &&
                product.image_url !== imageUrl
            )?.image_url ??
            imageUrl ??
            "",
        }));

        setProductSpaces(newProductSpaces);
      })
      .catch((error) => {
        console.error("Error fetching spaces data", error);
      })
      .finally(() => {
        setSpaceIconsPending(false);
      });
  };

  useEffect(() => {
    fetchSpacesData();
  }, [spaceIds]);

  const SpaceIcon = ({
    space,
    mode = "icon",
    count,
  }: {
    space?: ProductSpace;
    mode?: string;
    count?: number;
  }) => (
    <Box
      sx={{
        position: "relative",
        minWidth: "40px",
        maxWidth: "40px",
        minHeight: "40px",
        maxHeight: "40px",
        marginRight: "-10px",
        borderRadius: "50%",
        border: "2px solid #fff",
        "&:hover":
          mode !== "loading"
            ? {
                zIndex: 999,
                cursor: "pointer",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
              }
            : {},
        backgroundColor:
          mode === "more" ? "#F4F3F8" : mode === "loading" ? "#E1E6ED" : "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (mode === "more" || mode === "less") {
          setSpreadIcons(!spreadIcons);
        } else if (space) {
          history.push(`${pathname}?space=${space.id}`);
        }
      }}
    >
      {mode === "loading" ? (
        <LoadingSpinner color={"grey"} />
      ) : mode === "more" || mode === "less" ? (
        <Typography
          sx={{
            color: "#2C224C",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {mode === "more" ? `+${count}` : "Less"}
        </Typography>
      ) : (
        <Tooltip title={space?.name} placement="top" arrow>
          <Box>
            <img
              src={space?.image_url}
              alt={space?.name}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "150px",
        overflowY: "hidden",
        overflowX: "auto",
      }}
    >
      {spaceIconsPending ? (
        Array.from({ length: spaceIds?.length ?? 4 }).map((_, index) => (
          <SpaceIcon key={`skeleton-space-icon-${index}`} mode="loading" />
        ))
      ) : spreadIcons ? (
        <>
          {productSpaces.map((space) => (
            <SpaceIcon key={space.id} space={space} />
          ))}
          {productSpaces.length > 4 && <SpaceIcon mode="less" />}
        </>
      ) : (
        productSpaces.map((space, idx) =>
          idx <= 2 ? (
            <SpaceIcon key={space.id} space={space} />
          ) : idx === 3 ? (
            <SpaceIcon
              key={space.id}
              mode="more"
              count={productSpaces?.length - 3}
            />
          ) : null
        )
      )}
    </Box>
  );
};

export default ProductSpaces;
