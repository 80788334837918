import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";

import { deployDigitalShelfFlowV2 } from "../../services/Flows/api";
import { useAuthUser } from "@frontegg/react-hooks";
import { useUserTenant } from "../../hooks";

export const DeployDigitalShelfFlowV2Button = () => {
  const user = useAuthUser();
  const tenant = useUserTenant(user || undefined);

  const deployDigitalShelfFlowV2Mutation = useMutation((tenantId: string) =>
    deployDigitalShelfFlowV2(tenantId),
  );
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() =>
        tenant?.tenantId &&
        deployDigitalShelfFlowV2Mutation
          .mutateAsync(tenant?.tenantId)
          .then(() =>
            enqueueSnackbar("Deployment started successfully", {
              variant: "success",
            }),
          )
          .catch(() =>
            enqueueSnackbar("Deployment started failed", {
              variant: "error",
            }),
          )
      }
      startIcon={<Refresh />}
      disabled={deployDigitalShelfFlowV2Mutation.isLoading}
    >
      Deploy Digital shelf V2
    </Button>
  );
};
