import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useAssortmentIntelligence } from "../AssortmentIntelligenceProvider";
import { Space } from "../../SpacesV2/types";
import { useRef, useState } from "react";
import theme from "../../../themes/light";
import { ArrowIcon } from "../../../assets";
import { useHistory } from "react-router-dom";

const SpaceList = () => {
  const { spaceList, selectedSpace } = useAssortmentIntelligence();

  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleSelect = (space: Space) => {
    history.push(`/assortment-intelligence?${space?.id}`);
    setIsOpen(false);
  };

  return spaceList && spaceList.length > 0 ? (
    <Box sx={{
      zIndex: 999,
    }}>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={isOpen ? "composition-menu" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          display: "flex",
          gap: "8px",
          minHeight: "24px",
          width: "fit-content",
          fontSize: "14px",
          padding: "4px",
          justifyContent: "start",
          color: theme.palette.primary.main,
          "> svg": {
            transition: "0.3s all ease",
            ...(isOpen && { transform: "rotate(180deg)" }),
            "> path": {
              fill: theme.palette.primary.main,
            },
          },
        }}
      >
        <Typography
          variant="body4"
          sx={{
            fontSize: "17px",
            color: theme.palette.primary.main,
          }}
        >
          {selectedSpace?.name ?? "Select Space"}
        </Typography>
        <ArrowIcon />
      </Button>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              sx={{
                maxHeight: "370px",
                overflow: "auto",
              }}
            >
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <MenuList
                  autoFocusItem={isOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {spaceList &&
                    spaceList?.length > 0 &&
                    spaceList.map((space: Space) => (
                      <MenuItem
                        key={space?.id}
                        onClick={() => handleSelect(space)}
                      >
                        {space?.name}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  ) : (
    <Skeleton
      sx={{
        height: "24px",
        width: "150px",
      }}
    />
  );
};

export default SpaceList;
