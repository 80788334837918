import { Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";
import NumberSection from "../../Components/NumberSection.tsx";
import theme from "../../../../themes/light.ts";
import { RowData } from "../../types.ts";

const Tab = ({
  title,
  unit,
  setActiveTab,
  active,
  value_name,
  selectedProduct,
}: {
  title: string;
  unit?: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  active?: boolean;
  value_name: string;
  selectedProduct?: RowData | undefined;
}) => {
  return (
    <Box
      onClick={() => setActiveTab(value_name)}
      sx={{
        padding: "8px 16px",
        borderRadius: "8px 0 0 8px",
        cursor: "pointer",
        "&.active": { background: theme.palette.backgrounds.secondary },
      }}
      className={active ? "active" : ""}
    >
      <Typography variant="body5">{title}</Typography>
      <NumberSection
        unit={
          unit && ["$", "%"].includes(unit) ? (unit as "$" | "%") : undefined
        }
        // @ts-ignore
        number={selectedProduct?.[value_name] ?? (0 as number)}
        percentage={
          // @ts-ignore
          selectedProduct?.[`${value_name}_percentage`] ?? (0 as number)
        }
        // @ts-ignore
        change={selectedProduct?.[`${value_name}_change`] ?? (0 as number)}
      />
    </Box>
  );
};

export default Tab;
