import {Stack, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import {useOnboardingStatus} from "../../../../services/ecommerce";
import {StyledAvatar, StyledCardHeader} from "../StyledComponents";
import {ViewDataButton} from "../Buttons";
import {FC} from "react"

const CompletedCardHeader: FC = () => {
    const {data} = useOnboardingStatus();
    return (
        <StyledCardHeader
            title="Your Noogata eCommerce library is ready!"
            subheader="Select a Block from the directory to get started"
            avatar={
                <StyledAvatar variant="rounded">
                    <CheckIcon sx={{color: "primary.dark"}}/>
        </StyledAvatar>
      }
      action={
        <Stack spacing={1}>
          <ViewDataButton />
          <Stack direction="row" spacing={1} justifyContent="center">
            <CheckCircleIcon sx={{ color: "white" }} />
            {data && data.covered_products && data.total_products && (
              <Typography color="white">
                {`Model Coverage: ${Math.round(
                  (data.covered_products * 100) / data.total_products
                )}%`}
              </Typography>
            )}
          </Stack>
        </Stack>
      }
    />
  );
};

export default CompletedCardHeader;
