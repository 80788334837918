import { FC } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loading: FC = () => (
  <Box justifyContent="center" display="flex" p={1} m={1}>
    <CircularProgress />
  </Box>
);

export default Loading;
