import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Stack, Typography } from "@mui/material";
import { RunOutput } from "@noogata/types";
import { FC } from "react";

const OutputsDropdown: FC<{
  outputs?: RunOutput[];
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ outputs, open, setOpen }) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{ display: "flex", alignItems: "center", height: "54px" }}
  >
    {open ? (
      <ArrowDropDownIcon onClick={() => setOpen(!open)} />
    ) : (
      <ArrowRightIcon onClick={() => setOpen(!open)} />
    )}
    <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>Outputs</Typography>

    {outputs &&
      outputs.filter((output) => output?.artifact_url !== null).length > 0 && (
        <>
          <Typography variant="h6" sx={{ mr: 0.5, color: "grey.500" }}>
            •
          </Typography>
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              alignItems: "center",
              color: "grey.500",
            }}
          >
            {outputs.filter((output) => !!output?.artifact_url).length}
          </Typography>
        </>
      )}
  </Stack>
);

export default OutputsDropdown;
