import {Box, Card, CardActionArea, Tooltip} from "@mui/material";

import theme from "../../../themev2";
import {FC} from "react"

type InputTypeSelectionCardProps = {
  onClick?: () => void;
  tooltipText?: string;
  children: JSX.Element;
};

const InputTypeSelectionCard: FC<InputTypeSelectionCardProps> = ({
  onClick,
  tooltipText,
  children,
}) => (
  <Card
    variant="elevation"
    elevation={0}
    sx={{
      height: 170,
      width: 270,
      boxShadow: `0 0 0 1px ${theme.palette.grey[300]}`,
      "&:hover": {
        boxShadow: onClick
          ? `0 0 0 3px ${theme.palette.primary.main}`
          : `0 0 0 1px ${theme.palette.grey[300]}`,
      },
    }}
  >
    <CardActionArea
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : "default", height: "100%" }}
    >
      <Tooltip title={tooltipText || ""}>
        <Box p={2} width="100%">
          {children}
        </Box>
      </Tooltip>
    </CardActionArea>
  </Card>
);

export default InputTypeSelectionCard;
