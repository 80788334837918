import { useQuery, UseQueryOptions } from "react-query";
import apiRequest, { APIError } from "../apiRequest";
import { TenantConfiguration } from "../../types/tenants";

export async function createTenant(tenantName: string): Promise<any> {
  return await apiRequest<TenantConfiguration>(
    "tenants/configuration/new",
    "POST",
    undefined,
    { name: tenantName }
  );
}
export async function deleteTenant(tenantId: string): Promise<any> {
  return await apiRequest<TenantConfiguration>(
    `tenants/configuration/delete/${tenantId}`,
    "DELETE",
    undefined
  );
}

async function getTenantCongifuration(
  tenantId: string
): Promise<TenantConfiguration> {
  return await apiRequest<TenantConfiguration>(
    `tenants/configuration/${tenantId}`,
    "GET"
  );
}

async function getTenantCongifurationHistory(
  tenantId: string,
  offset: number,
  limit: number
): Promise<TenantConfiguration[]> {
  return await apiRequest<TenantConfiguration[]>(
    "tenants/configuration/",
    "GET",
    { tenant_id: tenantId, offset, limit }
  );
}

export type CreateTenantConfigRequers = {
  tenantId: string;
  data: Omit<TenantConfiguration, "tenant_id">;
};

export function createTenantCongifuration(
  req: CreateTenantConfigRequers
): Promise<TenantConfiguration> {
  return apiRequest<TenantConfiguration>(
    `tenants/configuration/${req.tenantId}`,
    "POST",
    undefined,
    req.data
  );
}

export function useTenantConfiguration(
  tenantId: string,
  options?: UseQueryOptions<TenantConfiguration, APIError>
) {
  return useQuery<TenantConfiguration, APIError>(
    ["tenantConfiguration", tenantId],
    () => getTenantCongifuration(tenantId),
    { ...options }
  );
}

export type GetTenantHistoryRequest = {
  tenantId: string;
  offset?: number;
  limit?: number;
  options?: UseQueryOptions<TenantConfiguration[], APIError>;
};

export function useTenantCongifurationHistory({
  tenantId,
  offset = 0,
  limit = 10,
  options,
}: GetTenantHistoryRequest) {
  return useQuery<TenantConfiguration[], APIError>(
    ["tenantConfigurationHistory", tenantId],
    () => getTenantCongifurationHistory(tenantId, offset, limit),
    { ...options }
  );
}

export default {
  useTenantCongifuration: useTenantConfiguration,
  useTenantCongifurationHistory,
  getTenantCongifuration,
  getTenantCongifurationHistory,
};
