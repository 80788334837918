import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { Search } from "../../components/SearchInput/Search";
import HeaderTabs from "./HeaderTabs";
import { useTasksContext } from "./hooks/selectors";

const stackProps = {
  spacing: 2,
  justifyContent: "space-between",
  direction: "row" as const,
  alignItems: "center",
  sx: { padding: "0 8px" },
};

const SearchAndNotification = ({ handleShowFilter }: any) => {
  const { setSearch } = useTasksContext();
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      spacing={0}
      maxWidth="225px"
      width="100%"
      onClick={handleShowFilter}
    >
      <Search
        placeholder="Search..."
        data-testid="insights-search"
        onChange={(e) => setSearch(e.target.value)}
      />
    </Stack>
  );
};
export const Header: React.FC = () => {
  const theme = useTheme();
  return (
    <Box sx={{ background: "#fff" }}>
      <Box
        sx={{
          // borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "10px 32px",
          margin: "0px 0 0 0",
          gap: "8px",
        }}
      >
        <Stack {...stackProps} sx={{ paddingBottom: "0px" }}>
          <Typography
            variant="headline3"
            display="flex"
            flexDirection="row"
            gap="12px"
            alignItems="center"
          >
            Tasks
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <SearchAndNotification />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "8px 32px 8px 16px",
          margin: "0px 0 0 0",
          gap: "8px",
        }}
      >
        <HeaderTabs />
      </Box>
    </Box>
  );
};
