import { Box } from "@mui/material";
import { FC } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "./Table.css";

import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
  convertNumberToShortFormat,
  parseJsonRecursive,
  toTitleCase,
} from "../utils";
import {
  brandedRenderer,
  combinedChangeRenderer,
  growthRenderer,
  productRenderer,
  ratingRenderer,
  spaceTrafficShareRenderer,
} from "./CellRenderers";
import { createSortingMachine } from "./TableSorting";
// import { GridApi } from "ag-grid-community";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface TableViewProps {
  data: any;
  schema: any;
}

const TableView: FC<TableViewProps> = (props) => {
  const { data, schema } = props;

  const formattedData = parseJsonRecursive(data);

  // const [gridApi, setGridApi] = useState<GridApi>();

  const columnsToCombine: { [key: string]: string } = {};

  schema.forEach((item: any) => {
    const baseColumn = item.label;
    const changeColumn = `${item.label} Change`;
    const growthColumn = `${item.label} Growth`;
    let growthExists = false;

    schema.forEach((i: any) => {
      if (i.label === growthColumn) {
        columnsToCombine[baseColumn] = growthColumn;
        growthExists = true;
      }
      if (i.label === changeColumn && !growthExists) {
        columnsToCombine[baseColumn] = changeColumn;
      }
      if (item.label === "Rating" && i.label === "Number of Ratings") {
        columnsToCombine["Rating"] = "Number of Ratings";
      }
    });
  });

  // Manually change/format the value of a cell
  const valueFormatters: { [key: string]: (p: any) => string } = {
    currency: (p) => {
      const formatted = convertNumberToShortFormat(p.value);
      if (formatted == "NaN") {
        return "-";
      }
      return "$" + formatted;
    },
    share: (p) => {
      const formatted = convertNumberToShortFormat(p.value, true);
      return formatted == "NaN" ? "-" : formatted;
    },
    number: (p) => convertNumberToShortFormat(p.value),
    // text: (p) => {
    //   switch (p) {
    //     default:
    //       return p;
    //   }
    // },
  };
  // Manually render a cell
  const getCellRenderer = (field: string) => {
    const lowerCaseField = field.toLowerCase();

    if (lowerCaseField === "rating") {
      return ratingRenderer;
    }
    if (columnsToCombine[field]) {
      if (columnsToCombine[field].toLowerCase().includes("change"))
        return combinedChangeRenderer(field, columnsToCombine[field], "change");
      if (columnsToCombine[field].toLowerCase().includes("growth"))
        return combinedChangeRenderer(field, columnsToCombine[field], "growth");
    }
    if (lowerCaseField === "asin") {
      return productRenderer;
    }
    if (lowerCaseField.includes("reduction") || lowerCaseField.includes("%")) {
      return growthRenderer;
    }
    if (lowerCaseField == "brand" || lowerCaseField == "branded") {
      return brandedRenderer;
    }
    if (lowerCaseField === "space traffic share") {
      return spaceTrafficShareRenderer;
    }
    return (p: any) => <Box>{p.value}</Box>;
  };

  const getHeaderName = (field: string) => {
    const label = field?.split("_").join(" ");
    let headerName = "";
    switch (field) {
      case "ASIN":
        headerName = "Product";
        break;
      case "total_impressions":
        headerName = "Impressions";
        break;
      case "growth_of_impressions":
        headerName = "Growth";
        break;
      case "num_of_reviews":
        headerName = "Reviews";
        break;
      case "kpis":
        headerName = "KPIs";
        break;
      case "ordered_revenue":
        headerName = "Revenue";
        break;
      case "asp":
        headerName = "ASP";
        break;
      case "percentage_asp":
        headerName = "% ASP";
        break;
      case "branded_unbranded":
        headerName = "Branded";
        break;
      default:
        headerName = label.replace("Percentage", "%");
        break;
    }
    return headerName;
  };

  const getCustomWidth = (field: string) => {
    switch (field) {
      case "Search Term":
      case "competitor_impressions":
        return 215;
      case "Recommendation":
      case "My Impressions":
      case "Ordered Revenue":
      case "% Average Price":
        return 175;
      case "Number of Brands":
      case "Number of Products":
        return 200;
      case "ASIN":
      case "Organic Rank":
      case "Average Price":
        return 150;
      case "Space Traffic Share":
      case "Paid Rank":
        return 125;
      case "Price":
      case "Growth":
        return 40;
      case "Impressions":
        return columnsToCombine["Impressions"] ? 175 : 130;
      default:
        return 100;
    }
  };

  const shouldHide = (label: string) =>
    !!["image", "image url", "title"].find(
      (item) => item === label.toLowerCase()
    ) || Object.values(columnsToCombine).includes(label);

  // Filter from schema columns which not appear in data
  const filteredSchema = schema?.filter((schemaItem: any) => {
    // eslint-disable-next-line no-prototype-builtins
    return formattedData[0]?.hasOwnProperty(schemaItem?.label);
  });

  const colDefs = filteredSchema?.map((schemaItem: any) => {
    return {
      headerName: getHeaderName(schemaItem?.label),
      field: schemaItem?.label,
      valueGetter: (params: any) => {
        const value = params.data[schemaItem?.label];
        if (valueFormatters[schemaItem?.type]) {
          return valueFormatters[schemaItem?.type]({ value });
        }
        return value;
      },
      cellRenderer: getCellRenderer(schemaItem?.label),
      minWidth: getCustomWidth(schemaItem?.label),
      hide: shouldHide(schemaItem?.label),
    };
  });

  const combinedColumnsSortingMachines: { [key: string]: any } = {};
  Object.keys(columnsToCombine).forEach((col) => {
    combinedColumnsSortingMachines[col] = createSortingMachine(
      col,
      columnsToCombine[col]
    );
  });

  // const handleOnSortChanged = (params: any) => {
  //   const colId = params.columns[0]?.colId;
  //   combinedColumnsSortingMachines[colId]?.transitionState();
  //   gridApi?.refreshCells({ force: true });
  // };

  // const colDefsWithChange = colDefs?.map((colDef: any) => {
  //   if (columnsToCombine[colDef.field]) {
  //     return {
  //       ...colDef,
  //       comparator: (_: any, __: any, nodeA: any, nodeB: any) =>
  //         combinedColumnsSortingMachines[
  //           colDef.field
  //         ].currentState.sortComparator(nodeA.data, nodeB.data),
  //     };
  //   }
  //   return colDef;
  // });

  const rowData =
    formattedData && formattedData.length > 0 && Array.isArray(formattedData)
      ? formattedData?.map((row: any) => {
          return {
            ...row,
            ASIN: { image_url: row["Image"], asin: row.ASIN },
            Brand: toTitleCase(row?.Brand),
          };
        })
      : [];

  return (
    <Box
      className={"ag-theme-quartz"}
      sx={{
        width: "100%",
        height: "475px",
      }}
    >
      <AgGridReact
        rowData={rowData}
        // columnDefs={colDefsWithChange}
        columnDefs={colDefs}
        defaultColDef={{ flex: 1, sortable: true }}
        // onGridReady={(params) => {
        //   setGridApi(params.api);
        // }}
      />
    </Box>
  );
};

export default TableView;
