import {Box, BoxProps} from "@mui/material";
import BoardFilters from "./BoardFilters/BoardFilters";
import {SearchParam} from "../../hooks/useSearchParams";
import {FC} from "react"

const Navbar: FC<
    {
        filters?: Record<string, SearchParam>;
        onFiltersChange: (filters: Record<string, SearchParam>) => void;
        onFilterReset: () => void;
    } & Partial<BoxProps>
> = ({filters = {}, onFiltersChange, sx, onFilterReset, ...props}) => (
    <Box
        sx={{
            width: (t) => t.spacing(30),
            minWidth: (t) => t.spacing(25),
            ...sx,
        }}
        {...props}
    >
        <BoardFilters
            filters={filters}
            onChange={onFiltersChange}
            onFilterReset={onFilterReset}
    />
  </Box>
);

export default Navbar;
