import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../../services/apiRequest";
import {
  Dashboard,
  DashboardData,
  DashboardFilters,
  DashboardFiltersData,
  DashboardFiltersImp,
  DashboardImp,
  DashboardUpdateRequest,
} from "./types";

export const getDashboardsLinks = (): Promise<Dashboard[]> =>
  apiRequest<DashboardData[]>("v1/dashboards", "GET", {}).then((dashboards) =>
    dashboards.map(DashboardImp.fromData)
  );

export const getDashboardFilters = (
  dashboardId: string
): Promise<DashboardFilters[]> =>
  apiRequest<DashboardFiltersData[]>(
    `v1/dashboards/${dashboardId}/filters`,
    "GET",
    {}
  ).then((res) => res.map(DashboardFiltersImp.fromData));

export const updateDashboard = (
  dashboardId: string,
  updateReq: DashboardUpdateRequest
): Promise<Dashboard> =>
  apiRequest<DashboardData>(
    `v1/dashboards/${dashboardId}`,
    "PATCH",
    {},
    updateReq
  ).then(DashboardImp.fromData);

export const deleteProductApi = (asin: string): Promise<any> =>
  apiRequest<any>(`v1/onboarding/ecommerce/products/${asin}`, "DELETE");

export const createDashboardFilters = (
  dashboardFilters: DashboardFiltersData
): Promise<DashboardFilters> =>
  apiRequest<DashboardFiltersData>(
    `v1/dashboards/${dashboardFilters.dashboardId}/filters`,
    "POST",
    {},
    dashboardFilters
  ).then(DashboardFiltersImp.fromData);

export const deleteDashboardFilters = (
  dashboardFilters: DashboardFilters
): Promise<null> =>
  apiRequest<null>(
    `v1/dashboards/${dashboardFilters.dashboardId}/filters/${dashboardFilters.id}`,
    "DELETE"
  );

export const updateDashboardFilters = (
  data: DashboardFiltersData
): Promise<DashboardFilters> =>
  apiRequest<DashboardFilters>(
    `v1/dashboards/${data.dashboardId}/filters/${data.id}`,
    "PATCH",
    {},
    {
      name: data.name,
      filters: data.filters,
    }
  );

export const useDashboards = ({
  options,
}: {
  options?: UseQueryOptions<Dashboard[], APIError>;
} = {}): UseQueryResult<Dashboard[], APIError> =>
  useQuery<Dashboard[], APIError>(["Dashboards"], getDashboardsLinks, {
    ...options,
    staleTime: Infinity,
  });

export const useDashboardFilters = ({
  options,
  dashboardId,
}: {
  dashboardId: string;
  options?: UseQueryOptions<DashboardFilters[], APIError>;
}): UseQueryResult<DashboardFilters[], APIError> =>
  useQuery<DashboardFilters[], APIError>(
    ["useDashboardFilters", dashboardId],
    () => getDashboardFilters(dashboardId),
    options
  );
