import {
  Box,
  Button,
  Container,
  Drawer,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useSnackbar} from "notistack";

import {Controller, useForm} from "react-hook-form";
import {isError, useQuery, useQueryClient} from "react-query";
import DestinationTypeIcon from "../../components/DestinationTypeIcon";
import DrawerHeader from "../../components/DrawerHeader";
import Loadable from "../../components/Loadable";
import {JsonSchemaFormControls} from "../../components/SchemaForm";
import {DestinationType} from "../../enums";
import {APIError} from "../../services/apiRequest";
import {createDestination, getDestinationOptions,} from "../../services/destinations/api";
import {DestinationCreate, DestinationOption} from "../../types/destinations";
import {FC} from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  selectBoxChild: {
    margin: theme.spacing(0.6),
  },
  selectBox: {
    padding: theme.spacing(0.6),
  },
  icon: {
    height: theme.spacing(3.1),
    width: theme.spacing(3.1),
    display: "flex",
    justifyContent: "center",
  },
}));

type DestinationCreateForm = {
  name: string;
  type: DestinationType;
};

export const DestinationsDrawer: FC<{
  boardId: string;
  open: boolean;
  onClose: () => void;
}> = ({ boardId, open, onClose }) => {
  const classes = useStyles();

  const formMethods = useForm<DestinationCreateForm>({
    defaultValues: { type: DestinationType.GCS },
  });

  const { data, isLoading, error } = useQuery<
    Record<DestinationType, DestinationOption>,
    APIError
  >(["destinationOptions"], () => getDestinationOptions());

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    formMethods.setValue("type", event.target.value);
  };

  const handleDestinationCreate = formMethods.handleSubmit(
    async (formValues) => {
      try {
        const connection: Record<string, any> = {};
        const configuration: Record<string, any> = {};

        for (const key in formMethods.getValues()) {
          // @ts-ignore
          const value = formMethods.getValues(key);

          // @ts-ignore
          // eslint-disable-next-line no-unsafe-optional-chaining
          if (key in data?.[formValues.type]?.connection_schema.properties) {
            connection[key] = value;
          } else if (
              // @ts-ignore
              // eslint-disable-next-line no-unsafe-optional-chaining
              key in data![formValues.type]?.configuration_schema.properties
          ) {
            configuration[key] = value;
          }
        }

        const request: DestinationCreate = {
          type: formValues.type,
          name: formValues.name,
          board_id: boardId,
          connection,
          configuration,
          is_auto: false,
          is_internal: false,
        };
        const destination = await createDestination(request);
        onClose();

        queryClient.invalidateQueries(["destinations", boardId]);
        enqueueSnackbar(
          `Successfully created new destination ${destination.name}`,
          {
            variant: "success",
          }
        );
      } catch (error: any) {
        enqueueSnackbar(
          "Could not create destination, please check your input.",
          { variant: "error" }
        );
      }
    }
  );
  const currentTypeData = (data || {})![formMethods.watch("type")];

  return (
    <Drawer
      classes={{ paper: classes.root }}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <DrawerHeader
        title="New Destination"
        subtitle="Destination"
        onClose={onClose}
      />
      <Loadable isLoading={isLoading} error={error}>
        <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDestinationCreate();
              e.stopPropagation();
            }}
        >
          {/*// @ts-ignore*/}
          {!isLoading && isError && (
              <Container>
                <Controller
                    control={formMethods.control}
                    name="type"
                    defaultValue={DestinationType.GCS}
                    // @ts-ignore
                    value={formMethods.watch("type")}
                    render={(field) => (
                        <Select
                            {...field}
                            renderValue={(type: any) => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    className={classes.selectBox}
                                >
                                  <Typography
                                      className={classes.selectBoxChild}
                                      variant="inherit"
                                  >
                                    Connect to:
                                  </Typography>
                                  <Box
                                      height={25}
                                      width={25}
                                      display="flex"
                                      justifyContent="center"
                                  >
                                    <DestinationTypeIcon type={type}/>
                                  </Box>
                                  <Typography
                                      variant="inherit"
                                      className={classes.selectBoxChild}
                                  >
                                    {type}
                                  </Typography>
                                </Box>
                            )}
                            disableUnderline
                            onChange={handleChange}
                        >
                          {(Object.keys(data!) as Array<DestinationType>).map(
                              (type) => (
                                  <MenuItem value={type}>
                                    <ListItemIcon
                                        sx={{
                                          height: 25,
                                          width: 25,
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                    >
                                      <DestinationTypeIcon type={type}/>
                                    </ListItemIcon>
                                    <Typography variant="inherit">{type}</Typography>
                                  </MenuItem>
                              )
                          )}
                        </Select>
                    )}
                />
                <TextField
                    label="Name"
                    variant="outlined"
                    {...formMethods.register('name', {required: true})}
                    margin="normal"
                    size="medium"
                    fullWidth
                    required
                />
                {currentTypeData?.connection_schema && (
                    <JsonSchemaFormControls
                        formMethods={formMethods}
                        schema={currentTypeData?.connection_schema}
                    />
                )}
                {currentTypeData?.configuration_schema && (
                    <JsonSchemaFormControls
                        formMethods={formMethods}
                        schema={currentTypeData?.configuration_schema}
                    />
                )}
                <Button type="submit" color="primary">
                  Save
                </Button>
              </Container>
          )}
        </form>
      </Loadable>
    </Drawer>
  );
};
