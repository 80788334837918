import theme from "../../themes/light.ts";
import {
  Box,
  Checkbox,
  FormControlLabel,
  // FormControlLabel,
  // Grid,
  IconButton,
  LinearProgress,
  Link,
  // MenuItem,
  // Select,
  Skeleton,
  // Stack,
  // Stack,
  Tooltip,
  Typography,
  // Typography,
} from "@mui/material";
import EmptyState from "./EmptyState.tsx";
import {
  FC,
  ReactNode,
  RefObject,
  memo,
  useCallback,
  useEffect,
  // useMemo,
  useRef,
  useState,
} from "react";
import useOnScreen from "../../hooks/useOnScreen.tsx";
import { useSpace } from "./SpaceProvider.tsx";
import {
  ArrowDown,
  // AscendingIcon,
  CheckboxFilledIcon,
  // CheckboxIcon,
  CopyIcon,
  DarkStarIcon,
  // DescendingIcon,
} from "../../assets/index.tsx";
import { AgGridReact, CustomHeaderProps } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import { ModuleRegistry } from "@ag-grid-community/core";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { SnackbarKey, enqueueSnackbar, useSnackbar } from "notistack";
import {
  IDatasource,
  SizeColumnsToFitGridStrategy,
  SortDirection,
} from "ag-grid-community";
import { useMutation } from "react-query";
import { removeAsinsAPI, useSpaceQuery } from "./useSpaces.ts";
// import CustomDotLoader from "../../components/CustomDotLoader.tsx";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import CustomHeader from "./CustomHeader.tsx";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import "./spaces.css";
import { Features } from "./types.ts";
// import { Icon } from "../../components/Icon.tsx";

// import ImpressionHeader from "./ImpressionHeader.tsx";
// import GrowthHeader from "./GrowthHeader.tsx";
// import CustomHeader from "./CustomHeader.tsx";
ModuleRegistry.registerModules([InfiniteRowModelModule]);

interface AsinsListTableViewProps {
  spaceId: string;
  productCountLabel: string;
  asins: any[];
  gridApi: any;
  setGridApi: any;
  // asinsFromGrid: string[];
  createDataSource: () => IDatasource | undefined;
  undoAction: (snackbarId: SnackbarKey, asins: string[]) => ReactNode;
  gridRef: RefObject<AgGridReact<any>>;
  onModelUpdated: () => void;
  allSpaceAsins: string[] | undefined;
}

const AsinsListTableView: FC<AsinsListTableViewProps> = (props) => {
  const {
    spaceId,
    productCountLabel,
    asins,
    gridApi,
    setGridApi,
    // asinsFromGrid,
    createDataSource,
    undoAction,
    gridRef,
    onModelUpdated,
    allSpaceAsins,
  } = props;

  const snackbar = useSnackbar();

  // const [disableRowClick, setDisableRowClick] = useState<boolean>(false);

  const {
    space,
    asinsList: data,
    isFetchingNextPage,
    isFetchingAsins,
    fetchNextPage,
    selectedProducts,
    setSelectedProducts,
    filterByLabel,
    sort,
    setSort,
  } = useSpace();
  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useOnScreen(ref);
  const { refetch: spaceDataRefetch } = useSpaceQuery(spaceId);

  const defaultColDef = {
    // flex: 1,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  };
  const initialSortOrder = {
    star: null,
  };
  const [customSortOrder, setCustomSortOrder] = useState<any>(initialSortOrder);

  useEffect(() => {
    if (
      isVisible &&
      !isFetchingNextPage &&
      !isFetchingAsins &&
      // @ts-expect-error
      asins.length < data?.pages?.[0]?.total
    ) {
      fetchNextPage();
    }
  }, [
    isVisible,
    isFetchingNextPage,
    isFetchingAsins,
    asins.length,
    data?.pages?.[0],
  ]);

  const fetchAsinsForGrid = () => {
    const datasource = createDataSource();
    gridApi?.setGridOption("datasource", datasource);
  };
  const parse_categories_tooltip = (categories: string) => {
    const categories_arr = categories ? categories?.split("|") : [];
    return (
      <>
        {categories_arr.map((category: string, index: number) => (
          <Typography color="inherit" key={category}>
            {index + 1}. {category}
          </Typography>
        ))}
      </>
    );
  };

  const parse_categories = (categories: string) => {
    const categories_arr = categories ? categories?.split("|") : [];
    if (categories_arr.length > 0) {
      return `${categories_arr[0]}/.../${
        categories_arr[categories_arr.length - 1]
      }`;
    }

    return "";
  };

  const valueFormatters: { [key: string]: (p: any) => string } = {
    currency: (p) => (p.value ? "$" + Number(p.value).toLocaleString() : ""),
    // growth: (p) => p.value.toFixed(2) + "%",
    number: (p) => (p.value ? Number(p.value).toLocaleString() : ""),
    // boughtPastMonth: (p) => {
    //   if (!p.value) return "";
    //   const match = p.value.match(/(\d+[kK]?\+)/);
    //   return match ? match[0] : p.value;
    // },
  };

  const handleCustomSort = (
    event: any,
    props: CustomHeaderProps,
    sortType: string
  ) => {
    let setDir: SortDirection = "asc";
    const currentSortOrder: SortDirection = customSortOrder[sortType];
    if (currentSortOrder) {
      if (currentSortOrder === "asc") {
        setDir = "desc";
      } else {
        setDir = null;
      }
    }
    setCustomSortOrder({
      ...initialSortOrder,
      [sortType]: setDir,
    });
    props.setSort(setDir, event.shiftKey);
  };

  const cellRenderers: { [key: string]: (p: any) => JSX.Element } = {
    rating: (p: any) => (
      <>
        {p.value ? (
          <Box
            sx={{
              display: "inline-flex",
              gap: "0.3rem",
              alignItems: "center",
            }}
          >
            <Box>⭐️</Box>
            <Box>{p.value}</Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    ),
    category: ({ value = "" }: { value: string }) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Tooltip
          title={parse_categories_tooltip(value) || ""}
          placement="top-start"
          arrow
        >
          <Typography
            noWrap
            variant="body2"
            sx={{ textOverflow: "unset !important" }}
          >
            {parse_categories(value)}
          </Typography>
        </Tooltip>
      </div>
    ),

    product: (p: any) => {
      if (p?.value?.asin) {
        const { image_url, asin } = p.value;

        const clickHandler = () => {
          const selectedAsin = asin;
          if (Array.isArray(selectedProducts)) {
            const prevProducts = [...selectedProducts];
            const isAsinPresent = prevProducts?.some(
              (asin) => asin === selectedAsin
            );
            if (isAsinPresent) {
              const updatedProductsList = prevProducts?.filter(
                (asin) => asin !== selectedAsin
              );
              setSelectedProducts([...updatedProductsList]);
            } else {
              prevProducts.push(selectedAsin);
              setSelectedProducts([...prevProducts]);
            }
          }
        };
        p?.node?.setSelected?.(selectedProducts?.includes?.(asin));
        return (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                height: "100%",
                paddingLeft: "2.2rem",
                position: "relative",
              }}
            >
              <Checkbox
                sx={{
                  position: "absolute",
                  top: 3,
                  left: -10,
                  mx: 0,
                  zIndex: "4",
                  display: selectedProducts?.includes(asin)
                    ? "inline-flex"
                    : "none",
                }}
                checked={selectedProducts?.includes(asin)}
                onChange={clickHandler}
              />
              <Tooltip
                placement="top-start"
                title={
                  image_url ? (
                    <Box
                      component={"img"}
                      src={image_url}
                      alt="image"
                      sx={{
                        height: "130px",
                        width: "100px",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    asin
                  )
                }
              >
                <Box
                  sx={{
                    height: "40px",
                    width: "40px",
                    minWidth: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "0.496px solid rgba(0, 0, 0, 0.10)",
                    borderRadius: "4px",
                  }}
                >
                  {image_url ? (
                    <Box
                      component={"img"}
                      src={image_url}
                      alt="image"
                      sx={{
                        height: "100%",
                        objectFit: "contain",
                        maxWidth: "100%",
                      }}
                    />
                  ) : (
                    <CropOriginalIcon
                      style={{ color: "#8E4FF6", fontSize: "20px" }}
                    />
                  )}
                </Box>
              </Tooltip>
              {/* <Box>{asin}</Box> */}
              <Link
                underline={"hover"}
                target={"_blank"}
                href={asin ? `https://amazon.com/d/${asin}` : ""}
                sx={{
                  color: theme.palette.secondary.main,
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {asin}
              </Link>

              <Box
                sx={{
                  zIndex: "1000",
                  display: "none",
                }}
                id="action-container"
              >
                <Tooltip placement="top" title="Copy ASIN to clipboard" arrow>
                  <IconButton
                    onClick={(e) => handleCopy(e, asin)}
                    // onMouseEnter={() => setDisableRowClick(true)}
                    // onMouseLeave={() => setDisableRowClick(false)}
                    sx={{
                      background: "unset",
                      padding: "7px",
                      "& svg": {
                        height: "15px",
                        width: "15px",
                        fill: theme.palette.secondary.main,
                      },
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top" title="Remove product from space">
                  <IconButton
                    // onMouseEnter={() => setDisableRowClick(true)}
                    // onMouseLeave={() => setDisableRowClick(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeASINsMutation
                        .mutateAsync({
                          spaceId: spaceId as string,
                          asins: [asin],
                        })
                        .then(async () => {
                          snackbar.enqueueSnackbar("ASIN removed", {
                            variant: "success",
                            action: (snackbarId) =>
                              undoAction(snackbarId, [asin]),
                          });
                          await fetchAsinsForGrid();
                          if (gridApi) {
                            gridApi.deselectAll();
                          }
                          spaceDataRefetch();
                        })
                        .catch((e) => {
                          snackbar.enqueueSnackbar(
                            "Something went wrong: " + e,
                            {
                              variant: "error",
                            }
                          );
                        });
                    }}
                    sx={{
                      background: "unset",
                      padding: "4px",
                    }}
                  >
                    <DeleteOutlinedIcon
                      sx={{
                        fontSize: "20px",
                        fill: theme.palette.secondary.main,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </>
        );
      }
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "2rem",
          }}
        >
          {/* <CustomDotLoader size={15} /> */}
          <Skeleton
            animation="wave"
            width="200px"
            sx={{
              height: "10px",
              borderRadius: "5px",
              transform: "unset",
            }}
          />
        </Box>
      );
    },
    growth_of_impressions: (p: any) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "2px",
            "& svg": {
              transform: p.value > 0 ? "rotate(180deg)" : "none",
              "& path": {
                fill:
                  p.value > 0
                    ? theme.palette.text.success
                    : theme.palette.error.main,
              },
            },
            color:
              p.value == 0
                ? theme.palette.secondary.main
                : p.value > 0
                  ? theme.palette.text.success
                  : theme.palette.error.main,
          }}
        >
          {p.value == 0 ? (
            <Box sx={{ marginLeft: "1.1rem" }}>0%</Box>
          ) : (
            <>
              {p?.value ? (
                <>
                  <ArrowDown />
                  {p?.value?.toFixed(2) + "%"}
                </>
              ) : (
                <Box
                  sx={{
                    marginLeft: "1.1rem",
                    color: theme.palette.secondary.main,
                  }}
                >
                  -
                </Box>
              )}
            </>
          )}
        </Box>
      );
    },
    label: (p: any) => (
      <>
        {p.value === "my product" ? (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              "& path": {
                fill: theme.palette.primary.main,
              },
            }}
          >
            <Box>
              <DarkStarIcon />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    ),
  };
  const spaceAttributes: Features[] = space?.metadata?.attributes || [];

  const colDefs = [
    {
      // headerName:
      //   isFetchingAsins || isFetchingNextPage ? "" : productCountLabel,
      headerName: "Product",
      field: "product",
      minWidth: spaceAttributes.length > 0 ? 280 : 230,
      flex: 1,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.product,
      // headerCheckboxSelection: true,
      sortable: false,
    },
    {
      headerName: "",
      field: "label",
      // flex: 0.1,
      maxWidth: 80,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.label,
      sortable: true,
      headerComponent: CustomHeader,
      headerComponentParams: {
        onSortRequested: (e: any, props: CustomHeaderProps) =>
          handleCustomSort(e, props, "star"),
        sortOrder: customSortOrder?.star,
        showHeaderIcon: true,
      },
    },
    {
      headerName: "Asin",
      field: "asin",
      // flex: 1,
      valueFormatter: undefined,
      cellRenderer: undefined,
      hide: true,
    },
    {
      headerName: "Image",
      field: "image_url",
      // flex: 1,
      valueFormatter: undefined,
      cellRenderer: undefined,
      hide: true,
    },
    {
      headerName: "Title",
      field: "title",
      // flex: 2,
      valueFormatter: undefined,
      cellRenderer: undefined,
    },
    {
      headerName: "Categories",
      field: "categories",
      // flex: 2,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.category,
    },
    {
      headerName: "Brand",
      field: "brand",

      valueFormatter: undefined,
      cellRenderer: undefined,
    },
    {
      headerName: "Price",
      field: "price",

      valueFormatter: valueFormatters.currency,
      cellRenderer: undefined,
    },
    // {
    //   headerName: "Rating",
    //   field: "rating",
    //   flex: getColumnFlex("rating"),
    //   valueFormatter: undefined,
    //   cellRenderer: cellRenderers.rating,
    // },
    {
      headerName: "Reviews",
      field: "num_of_reviews",

      valueFormatter: valueFormatters.number,
      cellRenderer: undefined,
    },
    {
      headerName: "Rating",
      field: "rating",

      valueFormatter: undefined,
      cellRenderer: cellRenderers.rating,
    },
    {
      headerName: "Bought Last Month",
      field: "bought_past_month",

      valueFormatter: undefined,
      cellRenderer: undefined,
    },
    // {
    //   headerName: "Impressions",
    //   field: "total_impressions",
    //   flex: getColumnFlex("impressions"),
    //   valueFormatter: undefined,
    //   cellRenderer: undefined,
    //   headerComponent: CustomHeader,
    //   sortable: true,
    // },
    // {
    //   headerName: "Growth",
    //   field: "growth_of_impressions",
    //   flex: getColumnFlex("growth"),
    //   valueFormatter: undefined,
    //   cellRenderer: undefined,
    //   headerComponent: CustomHeader,
    // },
    ...spaceAttributes.map((attr) => ({
      headerName: attr.attribute,
      field: attr.attribute,

      valueFormatter: ["int", "float"].includes(attr.type)
        ? valueFormatters.number
        : undefined,
      cellRenderer: undefined,
    })),
  ];

  useEffect(() => {
    if (gridRef?.current!.api) {
      const datasource = createDataSource();
      gridRef.current!.api.setGridOption("datasource", datasource);
    }
  }, [filterByLabel]);

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>, asin: string) => {
    e.stopPropagation();
    navigator.clipboard.writeText(asin).then(() =>
      enqueueSnackbar("Asin copied successfully", {
        variant: "success",
      })
    );
  };

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
    const datasource = createDataSource();
    params.api!.setGridOption("datasource", datasource);
    const colsState = params.api.getColumnState();
    const sortColumn = colsState.find((col: any) => col.colId == sort.colId);
    if (sortColumn) {
      sortColumn.sort = sort.sort;
    }
    params.api.applyColumnState({ state: colsState, applyOrder: true });
  }, []);

  const deSelectAll = () => {
    setSelectedProducts([]);
    gridRef?.current!.api.forEachNode((node) => {
      node?.setSelected(false);
    });
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // const selectedAsins: string[] = [];
      gridRef?.current!.api.forEachNode((node) => {
        // selectedAsins?.push(node?.data?.asin);
        node?.setSelected(true);
      });

      setSelectedProducts(allSpaceAsins?.length ? allSpaceAsins : []);
    } else {
      deSelectAll();
    }
  };

  const removeASINsMutation = useMutation(
    ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
      removeAsinsAPI({
        spaceId,
        asins,
      })
  );

  const getRowId = useCallback((params: any) => params.data.id, []);

  const commonExportParams = {
    skipHeader: false,
    allColumns: false,
    onlySelected: false,
    columnKeys: [
      "asin",
      "image_url",
      "title",
      "label",
      "brand",
      "price",
      "num_of_reviews",
      "rating",
      "bought_past_month",
    ],
    processCellCallback: (params: any) => {
      return params.value;
    },
    processHeaderCallback: (params: any) => {
      return params.column.getColDef().headerName;
    },
  };

  const defaultCsvExportParams = {
    fileName: "exported-products.csv",
    columnSeparator: ",",
    skipGroups: true,
    suppressQuotes: false,
    ...commonExportParams,
  };

  const defaultExcelExportParams = {
    fileName: "exported-products.xlsx",
    sheetName: "Sheet1",
    ...commonExportParams,
  };
  const onSortChanged = () => {
    const sortState = gridApi
      ?.getColumnState()
      .filter((s: any) => s.sort != null)
      .map((s: any) => ({
        colId: s.colId,
        sort: s.sort,
        sortIndex: s.sortIndex,
      }));

    const sortedColumnIds = sortState.map((s: any) => s.colId);

    if (!sortedColumnIds.includes("label")) {
      setCustomSortOrder({
        ...initialSortOrder,
        star: null,
      });
    }

    setSort(sortState[0] ?? {});
  };

  // const rowClickedListener = useCallback(
  //   (event: RowClickedEvent<any, any>) => {
  //     if (disableRowClick) {
  //       return;
  //     }
  //     const selectedAsin = event?.data?.asin;
  //     if (Array.isArray(selectedProducts)) {
  //       const prevProducts = [...selectedProducts];
  //       const isAsinPresent = prevProducts?.some(
  //         (asin) => asin === selectedAsin
  //       );
  //       if (isAsinPresent) {
  //         const updatedProductsList = prevProducts?.filter(
  //           (asin) => asin !== selectedAsin
  //         );
  //         setSelectedProducts([...updatedProductsList]);
  //       } else {
  //         prevProducts.push(selectedAsin);
  //         setSelectedProducts([...prevProducts]);
  //       }
  //     }
  //   },
  //   [selectedProducts, disableRowClick]
  // );

  const autoSizeStrategy: SizeColumnsToFitGridStrategy = {
    type: "fitGridWidth",
  };

  if (asins.length === 0 && !isFetchingAsins && !isFetchingNextPage) {
    return <EmptyState />;
  }
  return (
    <Box
      sx={{
        flex: 1,
        background: theme.palette.grey[50],
        overflow: "auto",
      }}
    >
      {(isFetchingAsins || isFetchingNextPage) && <LinearProgress />}
      <Box
        sx={{
          display: "flex",
          padding: "1rem 2rem 0 2rem",
          justifyContent: "space-between",
          height: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // gap: "0.7rem",
            alignItems: "center",
          }}
        >
          {isFetchingAsins || isFetchingNextPage ? (
            <Skeleton
              animation="wave"
              width="160px"
              sx={{
                height: "10px",
                borderRadius: "8px",
                transform: "unset",
              }}
            />
          ) : (
            <>
              {/* {selectedProducts && selectedProducts?.length > 0 ? (
                <Box
                  sx={{
                    display: "inline-flex",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedProducts([])}
                >
                  <CheckboxFilledIcon />
                </Box>
              ) : (
                <CheckboxIcon />
              )} */}
              <FormControlLabel
                label={productCountLabel}
                control={
                  <Checkbox
                    sx={{
                      mx: 0,
                      display: "inline-flex",
                    }}
                    checked={
                      selectedProducts &&
                      selectedProducts?.length > 0 &&
                      selectedProducts?.length === allSpaceAsins?.length
                    }
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedProducts &&
                      selectedProducts?.length > 0 &&
                      selectedProducts?.length !== allSpaceAsins?.length
                    }
                    indeterminateIcon={<CheckboxFilledIcon />}
                  />
                }
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: theme.palette.grey[500],
                  },
                }}
              />
              {/* <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                  color: theme.palette.grey[500],
                }}
              >
                {productCountLabel}
              </Typography> */}
            </>
          )}
        </Box>
        {/* <Stack
          direction="row"
          gap="4px"
          alignItems="center"
          justifyContent="end"
        >
          <Typography variant="body4" color={theme.palette.text.secondary}>
            Sort by:
          </Typography>
          {sort.ascending ? (
            <Box
              sx={{
                display: "inline-flex",
                cursor: "pointer",
              }}
              onClick={handleClickAscending}
            >
              <AscendingIcon />
            </Box>
          ) : (
            <Box
              sx={{
                display: "inline-flex",
                cursor: "pointer",
              }}
              onClick={handleClickDescending}
            >
              <DescendingIcon />
            </Box>
          )}
          <Select
            size="small"
            onOpen={() => {
              setOpen((old) => !old);
            }}
            onClose={() => {
              setOpen((old) => !old);
            }}
            renderValue={() => (
              <Stack
                direction="row"
                gap="4px"
                alignItems="center"
                justifyContent="end"
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  color={
                    open ? theme.palette.text.link : theme.palette.text.primary
                  }
                >
                  {sort.column === "date" ? "Date added" : "Bought Past Month"}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {open ? (
                    <Icon src="sales/drop-up.svg" width="16px" height="16px" />
                  ) : (
                    <Icon
                      src="sales/drop-down.svg"
                      width="16px"
                      height="16px"
                    />
                  )}
                </Box>
              </Stack>
            )}
            sx={{
              "& .MuiInputBase-formControl": {
                "&:focus": {
                  backgroundColor: "transparent !important",
                },
              },
              "& .MuiSelect-select": {
                paddingRight: "20px !important",
                paddingLeft: "0",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
            value={sort.column}
          >
            <MenuItem
              dense
              value="date"
              onClick={() => handleSortFilter("column", "date")}
            >
              <Typography variant="body3">Date Added</Typography>
            </MenuItem>
            <MenuItem
              dense
              value="bought_past_month"
              onClick={() => handleSortFilter("column", "bought_past_month")}
              sx={{ marginTop: "5px" }}
            >
              <Typography variant="body3">Bought past Month</Typography>
            </MenuItem>
          </Select>
        </Stack> */}
      </Box>

      <Box
        className={"ag-theme-quartz"}
        sx={{
          padding: "1rem 2rem",
          width: "100%",
          position: "relative",
          height: "calc(100% - 50px)",
          // height: "100%",
          "& .ag-root-wrapper": {
            border: "none",
            boxShadow:
              "0px 6px 15px 0px rgba(44, 34, 76, 0.05),0px 8px 32px 0px rgba(44, 34, 76, 0.05)",
          },
          "& .ag-header-cell": {
            ":nth-child(1)": {
              paddingLeft: "3.2rem",
              // ":hover": {
              //   "#header-checkbox": {
              //     display: "inline-flex !important",
              //   },
              // },
              // "& .ag-header-cell-text": {
              //   fontSize: "13px",
              //   fontWeight: 500,
              //   color: theme.palette.grey[500],
              // },
            },
          },
          "& .ag-cell": {
            display: "flex",
            alignItems: "center",
            fontFamily:
              "Inter, Roboto, Helvetica, Arial, sans-serif !important",
          },
          "& .ag-row": {
            cursor: "pointer",
            ":hover": {
              background: theme.palette.grey[50],
              "& .MuiCheckbox-root": {
                display: "inline-flex",
              },
              ":hover": {
                "#action-container": {
                  display: "inline-flex",
                },
              },
            },
            ":focus-within": {
              background: theme.palette.primary.light,
            },
          },
          "& .ag-row-selected": {
            background: theme.palette.primary.light,
            ":hover": {
              background: theme.palette.primary.light,
            },
            ":not(:hover)": {
              background: theme.palette.background.default,
            },
          },
          "& .ag-header-cell-menu-button": {
            padding: "4px",
            ":hover": {
              boxShadow: "unset !important",
              borderRadius: "3px !important",
            },
          },
          "& .ag-header-cell-text": {
            fontFamily:
              "Inter, Roboto, Helvetica, Arial, sans-serif !important",
          },
          // "& .ag-row.ag-row-first": {
          //   borderRadius: "12px"
          // }
        }}
      >
        <AgGridReact
          // rowData={rowData}
          ref={gridRef}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowHeight={50}
          onGridReady={onGridReady}
          // onSelectionChanged={onSelectionChanged}
          rowSelection="multiple"
          rowBuffer={0}
          rowModelType={"infinite"}
          cacheBlockSize={100}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={12}
          maxBlocksInCache={10}
          rowMultiSelectWithClick={true}
          suppressRowClickSelection={true}
          getRowId={getRowId}
          defaultCsvExportParams={defaultCsvExportParams}
          defaultExcelExportParams={defaultExcelExportParams}
          onSortChanged={onSortChanged}
          onModelUpdated={onModelUpdated}
          autoSizeStrategy={autoSizeStrategy}
        />
        {/* {hasNextPage && (
          <Grid item xs={2}>
            <Box
              ref={ref}
              onClick={() => {
                if (!isFetchingNextPage) {
                  fetchNextPage();
                }
              }}
              sx={{
                padding: "8px 14px",
                // width: "100%",
                width: "250px",
                marginTop: "1.5rem",
                height: "100%",
                minHeight: "164px",
                maxHeight: "295px",
                boxShadow:
                  "0px 6px 15px 0px rgba(44, 34, 76, 0.05), 0px 8px 32px 0px rgba(44, 34, 76, 0.05)",
                // border: `2px solid ${t.palette.divider}`,
                background: theme.palette.background.default,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "space-between",
                borderRadius: "12px",
                cursor: "pointer",
              }}>
              <Stack
                id="main"
                height="100%"
                justifyContent="space-around"
                alignItems="center">
                <Box textAlign="center">
                  <Typography fontWeight="bold">
                    Loading More Products...
                  </Typography>
                  Click to speed things up
                </Box>
                <CustomDotLoader />
              </Stack>
            </Box>
          </Grid>
        )} */}
      </Box>
    </Box>
  );
};

export default memo(AsinsListTableView);
