import {FC, useState} from "react";
import {useQuery} from "react-query";
import {TableProps as MUITableProps, TableRowProps} from "@mui/material";
import {Row, SortingRule, TableState} from "react-table";
import Table from "./Table";

type QueryParameters = {
    pageIndex: number;
    pageSize: number;
    sortBy?: SortingRule<object>[];
    archived?: boolean;
};

type OffsetPaginatedTableProps = {
    name: string;
    queryFn: (params: object) => Promise<object>;
    queryKey: any;
    queryParams: object;
    columns: any[];
    // Table internal state props
    initialState?: TableState;
    onTableStateChange?: (state: TableState) => void;
    // Table visualize props
    tableProps?: MUITableProps;
    tableRowPropsGetter?: (row: Row) => TableRowProps;
};

const convertQueryParametersToRESTQueryParameters = (
  params: QueryParameters
) => ({
  offset: params.pageIndex * params.pageSize,
  limit: params.pageSize,
  ...(params.sortBy && params.sortBy.length > 0
    ? {
        sort_by:
          params.sortBy &&
          params.sortBy
            .map((item) => `${item.id} ${item.desc ? "desc" : "asc"}`)
            .join(" "),
      }
    : {}),
});

const OffsetPaginatedTable: FC<OffsetPaginatedTableProps> = ({
                                                                 queryFn,
                                                                 queryKey,
                                                                 queryParams,
                                                                 columns,
                                                                 name,
                                                                 ...otherProps
                                                             }) => {
    const [queryParameters, setQueryParameters] = useState<QueryParameters>({
        pageIndex: 0,
        pageSize: otherProps.initialState?.pageSize || 10,
    sortBy: [],
  });
  const { data, isLoading, error } = useQuery<any, Error>(
    [
      `table_${name}_query`,
      queryKey,
      queryParams,
      convertQueryParametersToRESTQueryParameters(queryParameters),
    ],
    () =>
      queryFn({
        ...queryParams,
        ...convertQueryParametersToRESTQueryParameters(queryParameters),
      }),
    { keepPreviousData: true }
  );

  return (
    <Table
        data={data || []}
        isLoading={isLoading}
        error={error}
        onTableStateChange={(state: any) => {
            if (
                queryParameters.pageIndex !== state.pageIndex ||
                queryParameters.pageSize !== state.pageSize ||
                JSON.stringify(queryParameters.sortBy) !==
                JSON.stringify(state.sortBy)
            ) {
                setQueryParameters({
                    pageSize: state.pageSize,
                    pageIndex: state.pageIndex,
                    sortBy: state.sortBy,
          });
        }
      }}
        columns={columns}
        {...otherProps}
    />
  );
};

export default OffsetPaginatedTable;
