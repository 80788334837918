import { useAmplitude } from "react-amplitude-hooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useSearchParams from "../useSearchParams";

export const useUrlNavigatedEvent: () => void = () => {
  const location = useLocation();
  const { search } = useSearchParams();
  const { logEvent } = useAmplitude((inheritedProps: any = {}) => ({
    ...inheritedProps,
    scope: ["platform"],
  }));
  useEffect(() => {
    logEvent("platform.url.navigated", {
      queryParams: search,
      url: document.URL,
      ...location,
    });
  }, [location]);
};
