import HelpIcon from "@mui/icons-material/Help";
import {Box, Stack, TextField, Tooltip, Typography} from "@mui/material";
import {ChangeEvent, FC, useEffect} from "react";

const indicatorTooltipMsg = "Insert your store's name, without spaces.";

const ShopifyDSForm: FC<{
  formMethods: any;
}> = ({formMethods}) => {
  useEffect(() => {
    formMethods.register("shopName", {
      required: true,
    });
    formMethods.setValue("shopName", "", {
      shouldValidate: false,
      shouldDirty: false,
    });
  }, [true]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const validStoreNameRegex = /^\S*$/;
    formMethods.register("shopName", {
      required: true,
      pattern: validStoreNameRegex,
    });
    formMethods.setValue("shopName", event?.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Box sx={{ mt: 4, width: "650px" }}>
      <Stack direction="row">
        <Typography
          variant="body1"
          flexDirection="column"
          display="flex"
          sx={{ ml: 2, mr: 1, mb: 0.5 }}
        >
          Store name
        </Typography>
        <Tooltip title={indicatorTooltipMsg}>
          <HelpIcon
            fontSize="small"
            sx={{ color: (t) => t.palette.grey[300] }}
          />
        </Tooltip>
      </Stack>

      <TextField
        id="shopName"
        name="shopName"
        variant="outlined"
        sx={{ ml: 2, mb: 3, width: "66%" }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event)}
      />
    </Box>
  );
};

export default ShopifyDSForm;
