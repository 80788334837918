import {FC, ReactNode} from "react";
import {List, ListItemButtonProps} from "@mui/material";
import {CollapsableBox, LabelProps} from "../../../components/CollapsableBox";
import {LeftNavBarItem} from "./LeftNavBarItem";

export type ExpendableListHeaderProps = {
    open: boolean;
    label: string;
    icon?: ReactNode;
    children?: ReactNode;
} & Partial<ListItemButtonProps>;

export const ExpendableList: FC<{ label: LabelProps; open: boolean; children?: ReactNode }> = ({
                                                                                                   children,
                                                                                                   label,
                                                                                                   open,
                                                                                               }) => (
    <CollapsableBox in={open} label={label}>
        <List
            sx={{
                ml: (t) => t.spacing(3.25),
                px: (t) => t.spacing(1.75),
                borderLeft: (t) => `1px solid ${t.palette.borders.lines}`,
            }}
        >
            {" "}
            {children}
    </List>
  </CollapsableBox>
);

export const ExpendableListHeader: FC<ExpendableListHeaderProps> = ({
  open,
  label,
  icon,
  onClick,
}) => (
  <LeftNavBarItem
    expandable
    open={open}
    selected={open}
    onClick={onClick}
    icon={icon!}
    label={label}
  />
);
