import { Box, Button, CircularProgress } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useSnackbar } from "notistack";
import { FC } from "react";

import { useMutation } from "react-query";
import { DownloadArtifactType, OutputFormat } from "../enums";
import { generatePresignedMinioToken } from "../services/runs/api";
import { useExportEvents } from "../hooks/metrics/exportEvents";

type DownloadButtonProps = {
  circuitboardId: string;
  artifactUrl: string;
  outputFormat: OutputFormat;
  artifactType: DownloadArtifactType;
  icon: boolean;
  label?: string;
  fileName?: string;
};

const DownloadButton: FC<DownloadButtonProps> = ({
  circuitboardId,
  artifactUrl,
  outputFormat,
  artifactType,
  icon,
  label,
  fileName,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { downloadOutputEvent } = useExportEvents();
  const mutation = useMutation("presign", generatePresignedMinioToken, {
    onError: (error) => {
      throw error;
    },
  });
  const handleClick = async () => {
    try {
      const data = await mutation.mutateAsync({
        circuitboardId,
        artifactUrl,
        outputFormat,
        artifactType,
      });
      const downloadUrl =
        outputFormat === OutputFormat.PARQUET
          ? artifactUrl
          : `${artifactUrl.substr(0, artifactUrl.lastIndexOf("."))}.csv`;
      const outputUrl = `/api/circuitboards/${circuitboardId}/storage/download?file_path=${downloadUrl}&token=${
        data.pre_signed_token
      }&artifact_type=${artifactType}${
        fileName ? `&file_name=${fileName}` : ""
      }`;
      downloadOutputEvent({
        boardId: circuitboardId,
        type: OutputFormat.PARQUET,
      });
      window.open(outputUrl);
    } catch (error: any) {
      enqueueSnackbar("failed to download an artifact", { variant: "error" });
    }
  };

  return (
    <Box marginRight={1}>
      {mutation.isLoading ? (
        <CircularProgress size={20} thickness={2.5} />
      ) : icon ? (
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={async () => {
            await handleClick();
          }}
          startIcon={<CloudDownloadIcon />}
        >
          {label}
        </Button>
      ) : (
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={async () => {
            await handleClick();
          }}
        >
          {label}
        </Button>
      )}
    </Box>
  );
};

export default DownloadButton;
