import React from "react";
import DateRangePickerExporter from "../../components/DateRangePicker/components/DateRangePickerExporter";
import {
  DateRange,
  DefinedRange,
} from "../../components/DateRangePicker/types";
import theme from "../../themes/light";
import { InitialDataRange } from "./ChatFilter/ChatFilter";

interface DateRangeProps {
  open: boolean;
  close: () => void;
  handleSubmitDateRangePicker: (range: DateRange) => void;
  dateRanges?: DefinedRange[];
  initialDateRange?: InitialDataRange;
  currentDateRange?: DefinedRange | null;
  showNoComparison?: boolean;
  showDefinedRanges?: boolean;
}

const DateRangeSelector: React.FC<DateRangeProps> = (props) => {
  const {
    open,
    close,
    handleSubmitDateRangePicker,
    dateRanges,
    initialDateRange,
    currentDateRange,
    showNoComparison,
    showDefinedRanges,
  } = props;

  const [dateRange, setDateRange] = React.useState<DateRange>({});
  return (
    <DateRangePickerExporter
      open={open}
      toggle={close}
      onChange={(range: DateRange) => setDateRange(range)}
      customStyle={{
        activeLabelBgColor: theme.palette.primary.light,
        activeLableColor: theme.palette.primary.main,
      }}
      onCancel={close}
      onSave={() => handleSubmitDateRangePicker(dateRange)}
      definedRanges={dateRanges}
      initialDateRange={initialDateRange}
      currentDateRange={currentDateRange}
      showNoComparison={showNoComparison}
      showDefinedRanges={showDefinedRanges}
    />
  );
};

export default DateRangeSelector;
