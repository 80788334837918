import { CardActions, Stack, Typography } from "@mui/material";
import { NewRunFormResult } from "../hooks/useNewRunForm";
import TriggerButton from "./TriggerButton";
import useToggle from "../../../hooks/useToggle";
import ScheduleDialog from "../Schedule/ScheduleDialog";
import StartRunButton from "./StartRunButton";
import ScheduleButton from "./ScheduleButton";
import { useIsEditMode } from "../../../hooks/useIsEditMode";
import { FC } from "react";

const ParameterActions: FC<{
  runForm: NewRunFormResult;
  onRunStart: () => void;
}> = ({ runForm, onRunStart }) => {
  const { watch, errors } = runForm.methods;
  const isEditMode = useIsEditMode();
  const isAllParametersValid = () =>
    !errors?.parameters || Object.keys(errors?.parameters).length === 0;
  const buttonsDisabled =
    !watch("name") ||
    !runForm.inputMethods.isAllInputsValid() ||
    !isAllParametersValid();
  const { isOpen, onOpen, onClose } = useToggle();
  return (
    <CardActions sx={{ marginTop: 2 }}>
      <Stack spacing={1} display="flex" width="100%">
        <Typography fontWeight="bold">Choose RUN option</Typography>
        {!isEditMode && (
          <StartRunButton
            onClick={() => {
              onRunStart();
              runForm.onSubmit();
            }}
            disabled={buttonsDisabled}
          />
        )}
        <ScheduleButton disabled={buttonsDisabled} onClick={onOpen} />
        <TriggerButton runForm={runForm} disabled={buttonsDisabled} />
      </Stack>
      {isOpen && (
      <ScheduleDialog open={isOpen} onClose={onClose} runForm={runForm} />
      )}
    </CardActions>
  );
};

export default ParameterActions;
