import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { LatestExportsRequest } from "../../types";
import { Export as ExportV2 } from "../../types/exports";
import apiRequest, { APIError } from "../apiRequest";

const listExportsV2 = (params?: LatestExportsRequest): Promise<ExportV2[]> =>
  apiRequest<ExportV2[]>("exports", "GET", params);

const useLatestV2Exports = (
  params: LatestExportsRequest,
  options?: UseQueryOptions<ExportV2[], APIError>
): UseQueryResult<ExportV2[], APIError> =>
  useQuery<ExportV2[], APIError>(
    ["listExportsV2", params],
    () => listExportsV2(params),
    options
  );

export default useLatestV2Exports;
