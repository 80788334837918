import { Box, Typography } from "@mui/material";

export default (
  <>
    <Typography component={Box} variant="caption">
      This is a <b>premium</b> block.
    </Typography>
    <Typography component={Box} variant="caption">
      Please contact Noogata sales to enable it.
    </Typography>
  </>
);
