import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTabsButtons } from "./useTabsButtons";

const DismissBtn = ({
  activeTab,
  setInsightIdForDialog,
  insightId,
  outlined,
}: {
  setInsightIdForDialog: (str: string) => void;
  activeTab: string;
  insightId: string;
  outlined?: boolean;
}) => {
  const theme = useTheme();
  const { setDismissed } = useTabsButtons();

  return (
    <Tooltip
      title={
        <Typography
          variant="body5"
          sx={{ color: theme.palette.backgrounds.white }}
        >
          {activeTab === "feed" ? "Remove from feed" : "Remove from My Items"}
        </Typography>
      }
      placement="top-end"
      arrow
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Button size="small" variant={outlined ? "outlined" : "text"}>
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          sx={{
            color: theme.palette.text.primary,
            minHeight: "18px",
            padding: "3px",
            fontSize: "14px",
            fontWeight: "600",
            maxHeight: "24px",
            height: "24px",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (activeTab === "feed") {
              setDismissed(insightId, 1);
            } else {
              setInsightIdForDialog(insightId);
            }
          }}
        >
          Dismiss
        </Stack>
      </Button>
    </Tooltip>
  );
};

export default DismissBtn;
