import { ConnectorType } from "../../../enums";
import { BoardConnectionConfiguration } from "./BoardConnector/BoardConnectionConfiguration";
import ExternalConnectorConfiguration from "./ExternalConnector/ExternalConnectorConfiguration";
import GoogleSheetsConfiguration from "./GoogleSheetsConnector/GoogleSheetsConfiguration";
import { SpaceConfiguration } from "./SpaceConnector/SpaceConfiguration.tsx";
import LocalFileConfiguration from "./LocalFile/LocalFileConfiguration";
import { ConnectionConfigurationProps } from "./types";
import AmazonConnectionConfig from "./AmazonProductsConnector/AmazonConnectionConfig";
import { FC } from "react";

const TYPE_TO_COMPONENT: Record<string, FC<ConnectionConfigurationProps>> = {
  [ConnectorType.LOCAL]: LocalFileConfiguration,
  [ConnectorType.BOARD]: BoardConnectionConfiguration,
  [ConnectorType.GOOGLE_SHEETS]: GoogleSheetsConfiguration,
  [ConnectorType.AMAZON_PRODUCTS]: AmazonConnectionConfig,
  [ConnectorType.SPACE]: SpaceConfiguration,
};

const getComponentToRender: (
  type: ConnectorType
) => FC<ConnectionConfigurationProps> = (type) =>
  TYPE_TO_COMPONENT[type] || ExternalConnectorConfiguration;

const ConnectionConfiguration: FC<ConnectionConfigurationProps> = (props) => {
  const Configuration = getComponentToRender(props.inputValue.type);
  return <Configuration {...props} />;
};

export default ConnectionConfiguration;
