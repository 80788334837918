import { useHistory } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";

export const InsightsNavItem: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  // const pageMatch = useRouteMatch({ path: ["/insights"] });
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={tab === "insights"}
      icon={
        <SVG
          src={"/insights/insights-icon-new.svg"}
          fill="inherit"
          width={20}
          height={20}
        />
      }
      onClick={() => {
        setTab("insights");
        history.push("/insights");
      }}
      label="Insights"
    />
  );
};
