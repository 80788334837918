import apiRequest, { APIError } from "../../services/apiRequest.ts";
import { CardData, ListOutput, SalesAssistantHeaderData } from "./types.ts";
import { useInfiniteQuery, useQuery, UseQueryResult } from "react-query";
import { ProductFilter } from "../../types/ecommerce.ts";

const getProductsFilter = (): Promise<ProductFilter[]> =>
  apiRequest<ProductFilter[]>(
    "v1/onboarding/ecommerce/products/filters",
    "GET",
  );

export const useProductsFilterList = ({
  isThereSalesAssistant,
}: {
  isThereSalesAssistant?: boolean;
}): UseQueryResult<ProductFilter[], APIError> =>
  useQuery<ProductFilter[], APIError>(
    ["useProductsFilterList"],
    () => getProductsFilter(),
    {
      refetchOnWindowFocus: false,
      enabled: isThereSalesAssistant,
    },
  );

const getIsThereSalesAssistant = (): Promise<boolean> =>
  apiRequest<boolean>("v1/sales_assistant/is_there_data", "GET");

export const useIsThereSalesAssistant = (): UseQueryResult<boolean, APIError> =>
  useQuery<boolean, APIError>(["useIsThereSalesAssistant"], () =>
    getIsThereSalesAssistant(), {
    refetchOnWindowFocus: false,
    }
  );

const getSalesAssistant = ({
  pageParam = 0,
  daysToShow,
  selectedGroupBy,
  selectedProductGroup,
  searchInput,
  spaceId,
  brand,
  sortBy,
}: {
  pageParam: number;
  daysToShow?: string;
  selectedGroupBy?: string;
  selectedProductGroup?: string;
  searchInput?: string;
  spaceId?: string;
  brand?: string;
  sortBy?: string;
}): Promise<ListOutput> => {
  const params: {
    period_view?: string;
    group_by?: string;
    text_query?: string;
    sort_by?: string;
    space_id?: string;
    brand?: string;
    product_group_id?: string;
    offset?: number;
  } = {
    period_view: daysToShow,
    group_by: selectedGroupBy,
    text_query: searchInput,
    sort_by: sortBy,
    offset: pageParam,
  };
  if (selectedProductGroup !== "ALL_PRODUCTS") {
    params["product_group_id"] = selectedProductGroup;
  }
  if (spaceId) {
    params["space_id"] = spaceId;
  }
  if (brand) {
    params["brand"] = brand;
  }
  return apiRequest<ListOutput>("v1/sales_assistant/list", "GET", params);
};

export function useSalesAssistantList({
  daysToShow,
  selectedGroupBy,
  selectedProductGroup,
  searchInput,
  spaceId,
  brand,
  sortBy,
  isThereSalesAssistant,
}: {
  daysToShow?: string;
  selectedGroupBy?: string;
  selectedProductGroup?: string;
  searchInput?: string;
  spaceId?: string;
  brand?: string;
  sortBy?: string;
  isThereSalesAssistant?: boolean;
}) {
  return useInfiniteQuery<ListOutput, APIError>(
    [
      "useSalesAssistantList",
      daysToShow,
      selectedGroupBy,
      selectedProductGroup,
      searchInput,
      spaceId,
      brand,
      sortBy,
    ],
    ({ pageParam }) => {
      return getSalesAssistant({
        pageParam,
        daysToShow,
        selectedGroupBy,
        selectedProductGroup,
        searchInput,
        spaceId,
        brand,
        sortBy,
      });
    },
    {
      getNextPageParam: (_lastPage, pages) => {
        return pages.length * 100;
      },
      staleTime: 6000,
      enabled: isThereSalesAssistant,
    },
  );
}

const getSalesAssistantHeaderData = ({
  space_id,
  product_group_id,
}: {
  space_id?: string;
  product_group_id?: string;
}): Promise<SalesAssistantHeaderData[]> => {
  const params: { space_id?: string, product_group_id?: string } = {};
  if (product_group_id !== "ALL_PRODUCTS") {
    params["product_group_id"] = product_group_id;
  }
  if (space_id) {
    params["space_id"] = space_id;
  }
  return apiRequest<SalesAssistantHeaderData[]>(
    "v1/sales_assistant/header_data",
    "GET",
    params,
  );
};

export const useSalesAssistantHeaderData = ({
  space_id,
  product_group_id,
  isThereSalesAssistant,
}: {
  space_id?: string;
  product_group_id?: string;
  isThereSalesAssistant?: boolean;
}): UseQueryResult<SalesAssistantHeaderData[], APIError> =>
  useQuery<SalesAssistantHeaderData[], APIError>(
    ["useSalesAssistantHeaderData", product_group_id, space_id],
    () => getSalesAssistantHeaderData({ product_group_id, space_id }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: isThereSalesAssistant,
    },
  );

export const generateSalesAssistant = (): Promise<boolean> => {
  return apiRequest<boolean>("v1/sales_assistant/get_data", "POST");
};

const getSalesAssistantCards = ({
  asin,
  space_id,
}: {
  asin?: string;
  space_id?: string;
}): Promise<CardData[]> => {
  if (!asin) return Promise.resolve([]);
  const params: { space_id?: string } = {};
  if (space_id) {
    params["space_id"] = space_id;
  }
  return apiRequest<CardData[]>(
    `v1/sales_assistant/get_cards/${asin}`,
    "GET",
    params,
  );
};

export const useSalesAssistantCards = ({
  asin,
  space_id,
  isThereSalesAssistant,
}: {
  asin?: string;
  space_id?: string;
  isThereSalesAssistant?: boolean;
}): UseQueryResult<any, APIError> =>
  useQuery<CardData[], APIError>(
    ["useSalesAssistantCards", asin, space_id],
    () => getSalesAssistantCards({ asin, space_id }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: isThereSalesAssistant && !!asin,
    },
  );
