import {Box, Stack, Tooltip, Typography, useMediaQuery, useTheme,} from "@mui/material";
import {FC, useEffect, useMemo, useState} from "react"

import {Column, usePagination, useSortBy, useTable} from "react-table";
import {IllustrationPlaceholder, UncontrolledTable,} from "../../../components";
import {TableHeader} from "../../../components/TableHeader";
import useDataSources from "../../../services/datasources/useDataSources";
import {generateShopBaseUrl} from "../../../types/datasources";
import {formatDateString} from "../../../utils";
import ProductsHeader from "../ProductsHeader";
import ZoomableImage from "../ZoomableImage";
import {useShopifyProducts, useShopifyProductsStatus} from "./api";
import {ShopifyProduct} from "./types";

const perPage = 50;

const ShopifyProductsTable: FC<object> = () => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const {data: dataSources = []} = useDataSources();
  const {
    data: response,
    isLoading,
    error,
  } = useShopifyProducts({page: 1, per_page: page * perPage});
  const {items: products = [], total = 0} = response || {};
  const {data: statusData, isLoading: isStatusDataLoading} =
      useShopifyProductsStatus();

  const all_columns: Column<ShopifyProduct>[] = [
    {
      Header: "Image",
      id: "image",
      accessor: "image_url",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({value}: { value: string }) => (
          <>{value && <ZoomableImage src={value} alt="Product Image"/>}</>
      ),
    },
    {
      Header: "Product Id",
      id: "ProductId",
      accessor: "id",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value, row }) => {
        const handler = row.original.handle;
        const ds_id = row.values.account_id;
        const shopName = dataSources?.find((ds) => ds.id === ds_id)
          ?.configuration?.shop_name;
        return (
          <Typography noWrap variant="body2" title="">
            {shopName ? (
              <a
                target="_blank"
                href={[generateShopBaseUrl(shopName), "products", handler].join(
                  "/"
                )}
                rel="noreferrer"
              >
                {value}
              </a>
            ) : (
              value
            )}
          </Typography>
        );
      },
    },
    {
      Header: "Variant Id",
      id: "VariantId",
      accessor: "variant_id",
      // @ts-ignore

      disableSortBy: true,
      Cell: ({value, row}) => {
        const handler = row.original.handle;
        const ds_id = row.values.account_id;
        const shopName = dataSources?.find((ds) => ds.id === ds_id)
            ?.configuration?.shop_name;
        return (
            <Typography noWrap variant="body2" title="">
              {shopName ? (
                  <a
                      target="_blank"
                      href={`${[
                        generateShopBaseUrl(shopName),
                        "products",
                        handler,
                      ].join("/")}?variant=${value}`}
                      rel="noreferrer"
                  >
                    {value}
                  </a>
              ) : (
                  value
              )}
            </Typography>
        );
      },
    },
    {
      Header: "Source",
      id: "account_id",
      accessor: "data_source_id",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value = "" }: { value: string }) => (
        <Tooltip title={value} placement="top-start">
          <Typography
            noWrap
            variant="body2"
            sx={{ width: (t) => t.spacing(20) }}
          >
            {dataSources?.find((ds) => ds.id === value)?.name || value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: "Title",
      id: "title",
      accessor: "title",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value = "" }: { value: string }) => (
        <div style={{ textAlign: "left" }}>
          <Tooltip title={value} placement="top-start">
            <Typography
              noWrap
              variant="body2"
              sx={{ maxWidth: theme.spacing(35) }}
            >
              {value}
            </Typography>
          </Tooltip>
        </div>
      ),
    },
    {
      Header: "Product Type",
      id: "ProductType",
      accessor: "product_type",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Typography noWrap variant="body2">
          {value}
        </Typography>
      ),
    },
    {
      Header: "GTIN",
      id: "GTIN",
      accessor: "barcode",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Typography noWrap variant="body2" title={value}>
          {value}
        </Typography>
      ),
    },
    {
      id: "created_at",
      Header: "Date Added",
      accessor: "created_at",
      minWidth: 80,
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Tooltip
          title={value ? formatDateString(value) : ""}
          placement="top-start"
        >
          <Typography
            variant="body2"
            noWrap
            sx={{ maxWidth: theme.spacing(7) }}
          >
            {value && formatDateString(value).split(",")[0]}
          </Typography>
        </Tooltip>
      ),
    },
  ];

  const columns = useMemo(
    () => all_columns,
    [dataSources && dataSources.length]
  );

  const instance = useTable(
    {
      columns,
      data: products,
      autoResetSortBy: false,
      autoResetPage: false,
      // @ts-ignore
      initialState: { pageSize: products.length || 1, rowCount: total },
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // @ts-ignore
    instance.setPageSize(products.length || 1);
  }, [products]);
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      spacing={1}
      sx={{
        maxWidth: mobileView ? "100vw" : "calc(100vw - 73px)",
        mt: 4,
        px: 4,
      }}
    >
      <ProductsHeader
        isLoading={isStatusDataLoading}
        data={statusData || []}
        total_products={total}
      />
      <TableHeader subTitle={total.toString()} title="Products" />
      {!isLoading && products.length === 0 ? (
        <IllustrationPlaceholder
          imageHeight={300}
          imageWidth={300}
          text="No products to display."
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <UncontrolledTable
            instance={instance}
            data={products}
            isLoading={isLoading}
            columns={columns}
            error={error || null}
            tableProps={{
              stickyHeader: true,
            }}
            disablePagination
          />
          {total > perPage * page && (
            <Typography
              onClick={() => setPage(page + 1)}
              sx={{
                transition: "background 0.2s ease",
                "&:hover": { background: "grey.100" },
                padding: 2,
                cursor: "pointer",
                borderBottomWidth: "1px",
                borderBottomColor: "text.secondary",
                color: "text.primary",
              }}
            >
              + Load {Math.min(perPage, total - perPage * page)} more products
              (of {total - products.length})
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default ShopifyProductsTable;
