import { FC } from "react";

import { Stack } from "@mui/material";
import MergeIcon from "@mui/icons-material/Merge";
import { LoadableText } from "./LoadableText";

export const Version: FC<{ version?: string }> = ({ version }) => (
  <Stack alignItems="center" spacing={1} direction="row">
    <MergeIcon fontSize="small" />
    <LoadableText noWrap maxWidth={150} minWidth={50} title={version}>
      {version}
    </LoadableText>
  </Stack>
);
