import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import {AuthorizedContent} from "../../components/AuthorizedContent";
import {PlatformPermission} from "../../enums";
import {useRunEvents} from "../../hooks/metrics/runEvents";
import {FC} from "react"

const NewRunButton: FC<{ boardId: string }> = ({boardId}) => {
    const history = useHistory();
    const {beginFlowEvent} = useRunEvents();
    return (
        <AuthorizedContent requiredPermissions={[PlatformPermission.RUNS_CREATE]}>
            <Button
                size="large"
                variant="contained"
                color="primary"
        onClick={() => {
          beginFlowEvent({ boardId });
          history.push(`/boards/${boardId}/runs/new`);
        }}
      >
        New Run
      </Button>
    </AuthorizedContent>
  );
};

export default NewRunButton;
