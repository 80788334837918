import { Typography } from "@mui/material";

const Dismissed = () => (
  <>
    <img src="/sales/circle.svg" alt="Alerts will dismiss will show up here" />
    <Typography variant="body1" fontWeight="600" marginTop="20px">
      Alerts will dismiss will show up here
    </Typography>
  </>
);

export default Dismissed;
