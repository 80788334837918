import { DestinationType } from "../../../../../enums";
import { DestinationsLogoMapping } from "../../../../../mappings";

export const getDefaultExportName = (runName: string) =>
  `Noogata - ${runName.replace(/[^0-9a-z ]/gi, "")}`;

export const validExportNameRegex = /^[_\-a-z0-9]{3,}$/;

export const destinationTypeToImagePath = (destinationType: DestinationType) =>
  `/${
    Object.entries(DestinationsLogoMapping).find(
      ([k, _]) => k.toLocaleLowerCase() === destinationType.toLowerCase()
    )?.[1]
  }`;

export const FILE_EXPORT_TYPES = [
  DestinationType.CSV,
  DestinationType.EXCEL,
  DestinationType.PARQUET,
];

export const isFileDestinationType = (type: DestinationType) =>
  FILE_EXPORT_TYPES.includes(type);
