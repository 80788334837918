import { Typography } from "@mui/material";

import { ConnectorType } from "../../../../enums";
import { ConnectorLogoMapping } from "../../../../mappings";
import { ConnectorTypeCardProps } from "../ConnectorTypeSelection";
import InputTypeSelectionCard from "../InputTypeSelectionCard";
import { FC } from "react";

const SpaceConnectorCard: FC<ConnectorTypeCardProps> = ({ setInputValue }) => (
  <InputTypeSelectionCard
    onClick={() => setInputValue({ type: ConnectorType.SPACE })}
    children={
      <>
        <img
          src={`/${ConnectorLogoMapping.SPACE}`}
          alt="SpaceConnector"
          height="40px"
          width="40px"
        />
        <Typography variant="h6">Space</Typography>
        <Typography variant="caption">
          Use your Spaces catalog as input
        </Typography>
      </>
    }
  />
);

export default SpaceConnectorCard;
