import { useState } from "react";

const getItemFromLocalStorage = <T>(key: string, initialValue: T) => {
  if (typeof window === "undefined") {
    return initialValue;
  }
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
};
const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (datat: T) => void] => {
  const [value, setValue] = useState<T>(
    getItemFromLocalStorage(key, initialValue)
  );
  return [
    value,
    (data: T) => {
      window.localStorage.setItem(key, JSON.stringify(data));
      setValue(data);
    },
  ];
};
export default useLocalStorage;
