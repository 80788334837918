import { useQuery, UseQueryOptions } from "react-query";
import { Schedule } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

export async function getSchedules(boardId?: string): Promise<Schedule[]> {
  return await apiRequest<Schedule[]>("schedules", "GET", {
    board_id: boardId,
  });
}

export default function useSchedules(
  boardId?: string,
  options?: UseQueryOptions<Schedule[], APIError>
) {
  return useQuery<Schedule[], APIError>(
    ["schedules", boardId],
    () => getSchedules(boardId),
    options
  );
}
