import { Box, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import theme from "../../themes/light";
import noogataloader from "/spaces/noogata-loader.gif";
import { useEffect, useState } from "react";

interface LoadingCatalogStateProps {
  message: string;
}

const LoadingCatalogState = ({ message }: LoadingCatalogStateProps) => {
  const [progress, setProgress] = useState(25);

  useEffect(() => {
    if (message === "Creating space!") {
      setProgress(25);
    } else if (message === "Looking for competitors products...") {
      setProgress(50);
    } else if (message === "Finish updating competitors products") {
      setProgress(75);
    } else if (message === "Space created!") {
      setProgress(100);
    }
  }, [message]);

  return (
    <Box>
      <Stack
        sx={{
          background: theme.palette.background.default,
          height: "166px",
          boxShadow:
            "0px 2px 10px -6px rgba(44, 34, 76, 0.05), 0px 2px 5px -4px rgba(44, 34, 76, 0.08)",
        }}
      />
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="stretch"
        sx={{
          flex: 1,
          background: "#f4f3f880",
          width: "auto",
          position: "relative",
          padding: "1rem 2rem",
          overflow: "hidden",
          height: "calc(100vh - 166px)",
        }}
        spacing={2}
      >
        {[...Array(36).keys()].map((_, index) => (
          <Grid item xs={12} sm={4} md={3} lg={2} xl={12 / 9} key={index}>
            <Box
              sx={{
                height: "191px",
                padding: "14px 0",
                borderRadius: "12px",
                background: theme.palette.background.default,
              }}
            />
          </Grid>
        ))}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={noogataloader} alt="Loading" />
          <Typography sx={theme.typography.h3_v2} textAlign="center">
            Building your competitive space!
          </Typography>
          <Typography
            sx={{ ...theme.typography.h4_v2, fontWeight: 400 }}
            textAlign="center"
          >
            We will let you know once your space is ready.
          </Typography>
          <Box sx={{ width: "410px" }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 25,
                borderRadius: 5,
                backgroundColor: "#EEEEEE",
                width: 410,
                "& .MuiLinearProgress-bar ": {
                  background:
                    "linear-gradient(90deg, #532E90 0%, #552F93 3%, #6A3BB8 39%, #723FC5 52%, #8349E3 81%, #7D59DE 100%)",
                },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
              <Typography variant="body4" fontSize={10} fontWeight={500}>
                {message}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default LoadingCatalogState;
