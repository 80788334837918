import Tabs from "./Tabs.tsx";
import { Stack } from "@mui/material";
import Actions from "./Actions";

const Controller = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Tabs />
      <Actions />
    </Stack>
  );
};

export default Controller;
