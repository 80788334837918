import { useQuery, UseQueryOptions } from "react-query";
import { GoogleSheetMeta } from "../../types/connectorsV2";
import apiRequest, { APIError } from "../apiRequest";

function getGoogleSheetMeta(fileId: string): Promise<GoogleSheetMeta> {
  return apiRequest<GoogleSheetMeta>(
    `connectors/google/sheets/${fileId}`,
    "GET"
  );
}

export default function useGoogleSheetMeta(
  fileId: string,
  options?: UseQueryOptions<GoogleSheetMeta, APIError>
) {
  return useQuery<GoogleSheetMeta, APIError>(
    ["googleSheetMeta", fileId],
    () => getGoogleSheetMeta(fileId),
    { ...options }
  );
}
