import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";

import InputRow from "../../../../../components/InputRow";
import SkeletonRow from "../../../../../components/SkeletonRow";
import {useBoard} from "../../../../../services/boards";
import {RunInput} from "../../../../../types";
import {FC} from "react"

const ScheduleInputsTable: FC<{
  boardId: string;
  inputs?: RunInput[];
}> = ({boardId, inputs}) => {
  const {data: board} = useBoard(boardId);

  const displayNames: Record<string, string> | undefined =
      board &&
      board.pipelines[0].inputs.reduce(
      (obj, x) => Object.assign(obj, { [x.name]: x.display_name }),
      {}
    );

  return (
    <>
      <Typography variant="h6">Inputs</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Input name</TableCell>
              <TableCell>Data source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inputs && displayNames
              ? inputs
                  .filter((input) => !!input.value?.configuration)
                  .map((input) => (
                    <InputRow
                      input={{
                        ...input,
                        displayName: displayNames[input.name]!,
                      }}
                    />
                  ))
              : [...Array(3).keys()].map((index) => (
                  <SkeletonRow index={index} />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ScheduleInputsTable;
