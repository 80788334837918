import {Box, BoxProps, Typography} from "@mui/material";
import {FC} from "react"

const ESCComponent: FC<Partial<BoxProps>> = ({sx, ...props}) => (
    <Box
        display="flex"
        alignItems="center"
        sx={{
            ...sx,
            color: (t) => t.palette.grey[400],
        }}
        {...props}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 1,
        border: (t) => `1px solid ${t.palette.grey[300]}`,
        borderBottomWidth: 2,
        width: (t) => t.spacing(4),
        height: (t) => t.spacing(3),
        px: 0.5,
        py: 0.7,
      }}
    >
      <Typography variant="overline" fontWeight="bold">
        ESC
      </Typography>
    </Box>
    <Typography variant="caption" fontWeight="bold" sx={{ ml: 1 }}>
      to close
    </Typography>
  </Box>
);
export default ESCComponent;
