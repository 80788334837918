import {
  AcknowledgedResponse,
  BoardCreate,
  BoardDocs,
  BoardEmailSubscriptionCreate,
  BoardEmailSubscriptionRead,
  BoardUpdate,
  BoardVersionUpgrade,
  CircuitBoard,
  InstallableCircuitboard,
} from "../types";
import { BoardTemplate, BoardTemplateVersion } from "../types/board-templates";
import apiRequest from "./apiRequest";

export async function getCircuitboards(
  params?: object
): Promise<CircuitBoard[]> {
  return await apiRequest<CircuitBoard[]>("circuitboards", "GET", params);
}

export async function getBoardDocs(boardId: string): Promise<BoardDocs> {
  return await apiRequest<BoardDocs>(`circuitboards/${boardId}/docs`, "GET");
}

export async function getCircuitboard(
  circuitboardId: string
): Promise<CircuitBoard> {
  return await apiRequest<CircuitBoard>(
    `circuitboards/${circuitboardId}`,
    "GET"
  );
}

export async function getInstallableCircuitboards(): Promise<
  InstallableCircuitboard[]
> {
  return await apiRequest<InstallableCircuitboard[]>("marketplace", "GET");
}

export async function getBoardTemplateLatestDocURL(
  id: string
): Promise<BoardDocs> {
  return await apiRequest<BoardDocs>(`marketplace/boards/${id}/docs`, "GET");
}

export async function getBoardTemplateLatestVersion(
  id: string
): Promise<BoardTemplateVersion> {
  return await apiRequest<BoardTemplateVersion>(
    `marketplace/boards/${id}/versions/latest`,
    "GET"
  );
}

export async function getBoardTemplate(id: string): Promise<BoardTemplate> {
  return await apiRequest<BoardTemplate>(`marketplace/boards/${id}`, "GET");
}

export async function createBoard(data: BoardCreate): Promise<CircuitBoard> {
  return await apiRequest<CircuitBoard>("circuitboards", "POST", undefined, {
    id: data.id,
    template_id: data.template_id,
    version: data.version,
    values: data.values,
    display_name: data.displayName,
    description: data.description,
    visibility: data.visibility || null,
    flow_id: data.flowId,
  });
}

export async function upgradeBoard(
  data: BoardVersionUpgrade
): Promise<AcknowledgedResponse> {
  return await apiRequest<AcknowledgedResponse>(
    `circuitboards/${data.id}/upgrade`,
    "POST",
    undefined,
    {
      template_id: data.template_id,
      version: data.version,
      values: data.values,
    }
  );
}

export async function updateBoard(data: BoardUpdate): Promise<CircuitBoard> {
  return await apiRequest<CircuitBoard>(
    `circuitboards/${data.id}`,
    "PUT",
    undefined,
    {
      display_name: data.displayName,
      description: data.description,
      visibility: data.visibility,
      bigquery_warehouse_export_enabled: data.bigqueryWarehouseExportEnabled,
      auto_publish_last_run: data.autoPublishLastRun,
    }
  );
}

export async function uninstallCircuitboard(
  circuitboardId: string
): Promise<AcknowledgedResponse> {
  return await apiRequest<AcknowledgedResponse>(
    `circuitboards/${circuitboardId}`,
    "DELETE"
  );
}

export async function getBoardEmailSubscriptions(
  circuitboardId: string
): Promise<BoardEmailSubscriptionRead[]> {
  return await apiRequest<BoardEmailSubscriptionRead[]>(
    `circuitboards/${circuitboardId}/emails`,
    "GET"
  );
}

export async function unsubscribeEmail(
  circuitboardId: string,
  id: string
): Promise<BoardEmailSubscriptionRead> {
  return await apiRequest<BoardEmailSubscriptionRead>(
    `circuitboards/${circuitboardId}/emails/${id}`,
    "DELETE"
  );
}

export async function createBoardEmailSubscription(
  circuitboardId: string,
  data: BoardEmailSubscriptionCreate
): Promise<BoardEmailSubscriptionRead> {
  return await apiRequest<BoardEmailSubscriptionRead>(
    `circuitboards/${circuitboardId}/emails`,
    "POST",
    undefined,
    data
  );
}
