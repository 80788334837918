import InboxIcon from "@mui/icons-material/Inbox";
import { Button, Tooltip } from "@mui/material";
import { FC } from "react";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformPermission } from "../../../../enums";
import useUnarchive from "../../../../services/runs/useUnarchive";
import { RunHeaderButtonProps } from "../types";

const UnarchiveButton: FC<RunHeaderButtonProps> = ({
  boardId,
  runId,
  classes,
}) => {
  const unarchiveMutation = useUnarchive(boardId, runId);
  return (
    <AuthorizedContent requiredPermissions={[PlatformPermission.RUNS_CREATE]}>
      <Tooltip title="Unarchive">
        <Button
          classes={{
            text: classes.buttonLabel,
          }}
          variant="outlined"
          onClick={() => unarchiveMutation.mutateAsync()}
          sx={{
            maxWidth: "48px",
            minWidth: "48px",
            height: "40px",
            color: "text.primary",
            borderColor: "text.secondary",
          }}
        >
          <InboxIcon />
        </Button>
      </Tooltip>
    </AuthorizedContent>
  );
};

export default UnarchiveButton;
