import { useLocation } from "react-router-dom";
import { GridType } from "../../enums";

const useUrlOutput = (): string | null => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fileName = queryParams.get("name");
  const fileType = queryParams.get("type");
  return fileType === GridType.OUTPUT ? fileName : null;
};

export default useUrlOutput;
