import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Stack,
  IconButton,
  Skeleton,
} from "@mui/material";
// import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CloneIcon, RefreshIcon, ShareIcon } from "../../assets";
import { FC, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import EditIcon from "@mui/icons-material/Edit";
import { useInsightChatContext } from "./hooks/selectors";

import { updateStoryboardApi, useChatSessionsList } from "./useChatApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import { formatChatTemplate } from "./utils";
interface ChatHeaderProps {
  handleOpenShareDialog: (title: string, link: string) => void;
  handleOpenSyncDialog: (storyboardId: string) => void;
  copyStoryboardCallback: () => any;
}

const ChatHeader: FC<ChatHeaderProps> = (props) => {
  const {
    handleOpenShareDialog,
    handleOpenSyncDialog,
    copyStoryboardCallback,
  } = props;

  const { graphData, storyboardId, chatTemplate, handleBack, selectedSpace } =
    useInsightChatContext();
  const { refetch } = useChatSessionsList();
  const snackbar = useSnackbar();

  // Edit title logic
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [editTitleValue, setEditTitleValue] = useState("");
  const theme = useTheme();

  useEffect(() => {
    setEditTitleValue(graphData?.title);
  }, [graphData]);

  const handleEditMessage = async () => {
    const body = {
      title: editTitleValue,
      share_link: graphData?.share_link,
    };
    const res = await updateStoryboardApi(body, storyboardId);
    if (res) {
      refetch();
      setEditTitleMode(false);
      snackbar.enqueueSnackbar("Title updated!", {
        variant: "success",
      });
    } else {
      snackbar.enqueueSnackbar("Something went wrong: ", {
        variant: "error",
      });
      setEditTitleValue(graphData?.title);
    }
  };

  // Copy storyboard logic
  const [copyLoading, setCopyLoading] = useState(false);

  const handleCopyStoryboard = async () => {
    if (copyLoading) return;
    setCopyLoading(true);
    return await copyStoryboardCallback()
      .then(() => {
        snackbar.enqueueSnackbar("Story board copied successfully!", {
          variant: "success",
        });
      })
      .finally(() => {
        setCopyLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "1rem",
        padding: "0 2rem",
        height: "100px",
        borderBottom: `1px solid ${theme.palette.borders.lines}`,
        background: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        {chatTemplate && (
          <Box
            sx={{
              minHeight: "32px",
            }}
          >
            <Button
              id="composition-button"
              aria-haspopup="true"
              // disabled={isDisabled || false}
              onClick={handleBack}
              sx={{
                display: "flex",
                gap: "8px",
                minHeight: "28px",
                fontSize: "14px",
                color: "#2C224C",
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: theme.palette.grey[300],
                }}
              />
              <Typography
                variant="body4"
                sx={{
                  fontSize: "14px",
                  color: theme.palette.grey[400],
                }}
              >
                Go back
              </Typography>
            </Button>
          </Box>
        )}

        {graphData.story_points?.length ? (
          <>
            {!editTitleMode && (
              <Stack
                // pt="16px"
                spacing={2}
                direction="row"
                alignItems="center"
                sx={{
                  "& button": {
                    visibility: "hidden",
                  },
                  "&:hover button": {
                    visibility: "visible",
                  },
                }}
              >
                {storyboardId && editTitleValue ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      {editTitleValue}
                    </Typography>
                    <IconButton onClick={() => setEditTitleMode(true)}>
                      <EditIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </IconButton>
                  </>
                ) : (
                  <Skeleton
                    animation="wave"
                    width="200px"
                    sx={{
                      height: "20px",
                      borderRadius: "8px",
                      transform: "unset",
                    }}
                  />
                )}
              </Stack>
            )}
            {editTitleMode && (
              <Stack
                // pt="16px"
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <TextField
                  sx={{
                    width: "300px",
                  }}
                  value={editTitleValue}
                  onInput={(e) =>
                    setEditTitleValue((e.target as HTMLInputElement).value)
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditMessage()}
                  disabled={graphData?.title === editTitleValue}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditTitleValue(graphData?.title);
                    setEditTitleMode(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </>
        ) : (
          <Box sx={{ alignSelf: "center" }}>
            <Typography
              display="flex"
              flexDirection="row"
              gap="12px"
              alignItems="center"
              sx={{
                color: theme.palette.secondary.main,
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              {chatTemplate
                ? `${selectedSpace?.name} ${formatChatTemplate(chatTemplate)}`
                : "Research Assistant"}
            </Typography>
          </Box>
        )}
      </Box>

      {storyboardId &&
        graphData?.story_points?.filter((s: any) => !s.isLoader).length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              onClick={() => handleOpenSyncDialog(storyboardId)}
              sx={{
                minHeight: "32px",
                maxHeight: "32px",
                minWidth: "100px",
                gap: "8px",
                color: theme.palette.secondary.main,
                padding: "10px 16px 10px 16px",
                border: `1px solid ${theme.palette.grey[200]}`,
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "16px",
              }}
            >
              <RefreshIcon />
              Sync
            </Button>
            <Button
              onClick={handleCopyStoryboard}
              sx={{
                minHeight: "32px",
                maxHeight: "32px",
                minWidth: "100px",
                gap: "8px",
                color: theme.palette.secondary.main,
                padding: "10px 16px 10px 16px",
                border: `1px solid ${theme.palette.grey[200]}`,
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: copyLoading ? "26px" : "16px",
              }}
            >
              <CloneIcon />
              {copyLoading ? (
                <LoadingSpinner size={15} color={theme.palette.grey[400]} />
              ) : (
                "Copy"
              )}
            </Button>
            <Button
              onClick={() =>
                handleOpenShareDialog(editTitleValue, graphData?.share_link)
              }
              sx={{
                minHeight: "32px",
                maxHeight: "32px",
                minWidth: "100px",
                gap: "8px",
                color: theme.palette.secondary.main,
                padding: "10px 16px 10px 16px",
                border: `1px solid ${theme.palette.grey[200]}`,
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "> svg > path": {
                  stroke: theme.palette.secondary.main,
                },
              }}
            >
              <ShareIcon /> Share
            </Button>
          </Box>
        )}
    </Box>
  );
};

export default ChatHeader;
