import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardProps,
  LinearProgress,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import dayjs from "../../../../utils";

import StatusIcon from "../../../../components/StatusIcon";
import { Status } from "../../../../enums";
import { useBoard } from "../../../../services/boards";
import { Run } from "../../../../types";
import StatusBox from "./StatusBox";
import { mapLabelDetails } from "../../../Run/RunV2/RunHeader/RunStatus";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },

  hidden: {
    visibility: "hidden",
  },
  button: {
    margin: theme.spacing(0.5),
  },
  [Status.FAILED]: {
    borderTop: `${theme.spacing(0.5)} solid ${theme.palette.error.main}`,
  },
  [Status.TERMINATED]: {
    borderTop: `${theme.spacing(0.5)} solid ${theme.palette.error.main}`,
  },
  [Status.SUCCEEDED]: {
    borderTop: `${theme.spacing(0.5)} solid ${theme.palette.success.main}`,
  },
  [Status.READY_TO_EXPORT]: {
    borderTop: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
  },
}));

const PendingContent: FC<{ exportDestination: string }> = ({
  exportDestination,
}) => (
  <CardContent>
    <StatusBox status={Status.UNKNOWN} text="Data Gathering" />
    <StatusBox status={Status.UNKNOWN} text="Pipeline Modeling" />
    <StatusBox status={Status.UNKNOWN} text={exportDestination} />
  </CardContent>
);

const RunningContent: FC<{ exportDestination: string }> = ({
  exportDestination,
}) => (
  <CardContent>
    <StatusBox status={Status.SUCCEEDED} text="Data Gathering Completed" />
    <StatusBox status={Status.RUNNING} text="Pipeline Modeling Running" />
    <StatusBox status={Status.UNKNOWN} text={exportDestination} />
  </CardContent>
);

const SuccessContent: FC<{ exportDestination: string }> = ({
  exportDestination,
}) => (
  <CardContent>
    <StatusBox status={Status.SUCCEEDED} text="Data Gathering Completed" />
    <StatusBox status={Status.SUCCEEDED} text="Pipeline Modeling Completed" />
    <StatusBox status={Status.SUCCEEDED} text={exportDestination} />
  </CardContent>
);

const SuccessfulWarningContent: FC<{ exportDestination: string }> = ({
  exportDestination,
}) => (
  <CardContent>
    <StatusBox status={Status.SUCCEEDED} text="Data Gathering Completed" />
    <StatusBox
      status={Status.SUCCEEDED}
      text="Pipeline Modeling Completed With Warnings"
      warning
    />
    <StatusBox status={Status.SUCCEEDED} text={exportDestination} />
  </CardContent>
);

const GatheringFailedContent: FC = () => (
  <CardContent>
    <StatusBox status={Status.FAILED} text="Data Gathering Failed" />
  </CardContent>
);

const PipelineFailedContent: FC = () => (
  <CardContent>
    <StatusBox status={Status.SUCCEEDED} text="Data Gathering Completed" />
    <StatusBox status={Status.FAILED} text="Pipeline Modeling Failed" />
  </CardContent>
);

const ExportingFailedContent: FC = () => (
  <CardContent>
    <StatusBox status={Status.SUCCEEDED} text="Data Gathering Completed" />
    <StatusBox status={Status.SUCCEEDED} text="Pipeline Modeling Completed" />
    <StatusBox status={Status.FAILED} text="Data Exporting Failed" />
  </CardContent>
);

const GatheringContent: FC<{ exportDestination: string }> = ({
  exportDestination,
}) => (
  <CardContent>
    <StatusBox status={Status.RUNNING} text="Gathering Data" />
    <StatusBox status={Status.UNKNOWN} text="Pipeline Modeling" />
    <StatusBox status={Status.UNKNOWN} text={exportDestination} />
  </CardContent>
);

const ExportingContent: FC<{ exportDestination: string }> = ({
  exportDestination,
}) => (
  <CardContent>
    <StatusBox status={Status.SUCCEEDED} text="Data Gathering Completed" />
    <StatusBox status={Status.SUCCEEDED} text="Pipeline Modeling Completed" />
    <StatusBox status={Status.RUNNING} text={exportDestination} />
  </CardContent>
);

const RunCardContent: FC<{ run: Run }> = ({ run }) => {
  const warning = run.tracking_statistics?.message_severities?.warning > 0;
  const gatheringCompleted = !!run.kfp_run_id;
  const pipelineFailed = run.failed_steps && run.failed_steps.length > 0;
  const { data: board } = useBoard(run.circuitboard_id);
  const exportingText = board?.bigquery_warehouse_export_enabled
    ? "Exporting to Data Warehouse"
    : "Data Exporting";

  const exportingCompletedText = run.outputs.some(
    (x) => x.bigquery_warehouse_export_result
  )
    ? "Exporting to Data Warehouse Completed"
    : "Data Exporting Completed";

  switch (run.status) {
    case Status.PENDING:
      return <PendingContent exportDestination={exportingText} />;
    case Status.GATHERING:
      return <GatheringContent exportDestination={exportingText} />;
    case Status.RUNNING:
      return <RunningContent exportDestination={exportingText} />;
    case Status.EXPORTING:
      return <ExportingContent exportDestination={exportingText} />;
    case Status.SUCCEEDED:
      return warning ? (
        <SuccessfulWarningContent exportDestination={exportingCompletedText} />
      ) : (
        <SuccessContent exportDestination={exportingCompletedText} />
      );
    case Status.FAILED:
      return !gatheringCompleted ? (
        <GatheringFailedContent />
      ) : pipelineFailed ? (
        <PipelineFailedContent />
      ) : (
        <ExportingFailedContent />
      );
    case Status.UNKNOWN:
      return null;
    default:
      return null;
  }
};

const RunCardTitle: FC<{ run: Run }> = ({ run }) => (
  <Box display="flex" justifyContent="space-between">
    <Box display="flex" alignItems="baseline">
      <Box marginRight={1}>
        <StatusIcon status={run.status} />
      </Box>
      <Typography variant="h6">{run.name}</Typography>
    </Box>
    <Typography variant="subtitle2">
      {mapLabelDetails(false, run.status).text}
    </Typography>
  </Box>
);

type RunCardProps = {
  run: Run;
  cardProps?: CardProps;
  onClick?: () => void;
};

function mapLoadingStatus(
  runStatus: Status
):
  | Status.FAILED
  | Status.SUCCEEDED
  | Status.TERMINATED
  | Status.READY_TO_EXPORT
  | null {
  switch (runStatus) {
    case Status.SUCCEEDED:
    case Status.FAILED:
    case Status.TERMINATED:
    case Status.READY_TO_EXPORT:
      return runStatus;
    default:
      return null;
  }
}

const RunCard: FC<RunCardProps> = ({ run, cardProps, onClick }) => {
  const classes = useStyles();
  const linearStatus = mapLoadingStatus(run.status);
  const subheaderPrefix = run.created_at
    ? run.status === Status.SUCCEEDED || run.status === Status.FAILED
      ? `Finished ${dayjs.utc().to(dayjs.utc(run.finished_at))} by ${
          run.created_by_email
        }`
      : `Started ${dayjs.utc().to(dayjs.utc(run.created_at))} by ${
          run.created_by_email
        }`
    : `Started by ${run.created_by_email}`;
  const subheader = `${subheaderPrefix} ${
    run.schedule_id ? "(Scheduled)" : ""
  }`;
  return (
    <Card
      variant="outlined"
      className={cn(classes.card, linearStatus && classes[linearStatus])}
      onClick={onClick}
      {...cardProps}
    >
      <CardActionArea>
        {!linearStatus && <LinearProgress />}
        <CardHeader
          title={<RunCardTitle run={run} />}
          subheader={subheader}
          subheaderTypographyProps={{ variant: "body1" }}
        />
        <RunCardContent run={run} />
      </CardActionArea>
    </Card>
  );
};

export default RunCard;
