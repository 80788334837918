import { useQuery, UseQueryOptions } from "react-query";
import apiRequest, { APIError } from "../../../services/apiRequest";
import { BoardDocs } from "../../../types";

export async function getBoardDocs(boardId: string): Promise<BoardDocs> {
  return apiRequest<BoardDocs>(`circuitboards/${boardId}/docs`, "GET");
}

export default function useBoardDocs(
  boardId: string | undefined,
  options?: UseQueryOptions<BoardDocs, APIError>
) {
  return useQuery<BoardDocs, APIError>(
    ["BoardDocs", boardId],
    () => getBoardDocs(boardId || ""),

    { ...options, retry: (_, response) => response.statusCode !== 404 }
  );
}
