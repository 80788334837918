import { FC, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Collapse,
  Container,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { VisibilityType } from "../../enums";
import { addFlow, installFlow } from "../../services/Flows/api";
import { VisibilityMenu } from "../../components/VisibilityMenu";
import { Flow, FlowCreate } from "../../types";
import { capitaliseFirst } from "../../utils";
import { FlowTemplateSelect } from "./FlowTemplateSelect";

enum ClickedButton {
  Install,
  Configure,
}

type FormValues = {
  displayName?: string;
  description: string;
  visibility?: VisibilityType;
  flowTemplate: string | null;
};

const AddNewFlowForm: FC<object> = () => {
  const history = useHistory();
  const [clickedButton, setClickedButton] = useState<ClickedButton | null>(
    null
  );
  const { formState, register, getValues, watch } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      description: "",
      visibility: VisibilityType.PRIVATE,
      flowTemplate: null,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  async function defaultCreate(
    formValues: FormValues,
    flowCreate: FlowCreate
  ): Promise<Flow> {
    let createdFlow: Flow;
    if (formValues.flowTemplate === null) {
      createdFlow = await addFlow(flowCreate);
      history.push(`/flows/${createdFlow.id}/blocks/first`);
    } else {
      const flowInstall = await installFlow(flowCreate, {
        template: formValues.flowTemplate,
        skipSourceBlock: false,
      });
      createdFlow = flowInstall.flow;
      history.push(`/flows/${createdFlow.id}`);
    }
    return createdFlow;
  }

  async function createAndConfigure(
    formValues: FormValues,
    flowCreate: FlowCreate
  ): Promise<Flow> {
    const installResponse = await installFlow(flowCreate, {
      template: formValues.flowTemplate as string,
      skipSourceBlock: true,
    });
    const createdFlow = installResponse.flow;
    history.push(`/boards/${installResponse.sourceBlockId}/runs/new`);
    return createdFlow;
  }

  const handleSubmitForButton = async (clickedButtonValue: ClickedButton) => {
    const formValues = getValues();
    setClickedButton(clickedButtonValue);
    try {
      const flowCreate = {
        displayName: formValues.displayName,
        description: formValues.description,
        visibility: formValues.visibility,
      };

      let createdFlow: Flow;
      if (clickedButtonValue === ClickedButton.Install) {
        createdFlow = await defaultCreate(formValues, flowCreate);
      } else {
        createdFlow = await createAndConfigure(formValues, flowCreate);
      }

      enqueueSnackbar(`Success! ${createdFlow.display_name} has been added`, {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setClickedButton(null);
    }
  };

  const watchFlowTemplate = watch("flowTemplate");
  return (
    <Container
      sx={{
        width: "40%",
      }}
    >
      <Box
        sx={{
          mt: 2,
        }}
      >
        <form>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="body4">Flow name</Typography>
              <TextField
                variant="filled"
                placeholder="Type flow name"
                {...register("displayName", { required: true })}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body4">Visbility</Typography>
              <Select
                variant="filled"
                renderValue={(visibility: VisibilityType) =>
                  capitaliseFirst(visibility)
                }
                {...register("visibility", { required: true })}
              >
                {VisibilityMenu({})}
              </Select>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body4">Flow Template</Typography>
              <FlowTemplateSelect
                {...register("flowTemplate", { required: true })}
              />
            </Stack>
          </Stack>
        </form>
      </Box>
      <Stack direction="row" spacing={1} justifyContent="end" mt={3}>
        <Button
          variant="outlined"
          disabled={formState.isSubmitting}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Collapse orientation="horizontal" in={!!watchFlowTemplate}>
          <LoadingButton
            sx={{
              mr: 2,
              px: 2,
            }}
            loading={clickedButton === ClickedButton.Configure}
            variant="text"
            onClick={() => handleSubmitForButton(ClickedButton.Configure)}
            disabled={formState.isSubmitting}
          >
            Configure
          </LoadingButton>
        </Collapse>
        <LoadingButton
          loading={clickedButton === ClickedButton.Install}
          variant="contained"
          color="primary"
          onClick={() => handleSubmitForButton(ClickedButton.Install)}
          disabled={formState.isSubmitting || !formState.isValid}
          endIcon={<ArrowForwardIcon />}
        >
          Install
        </LoadingButton>
      </Stack>
    </Container>
  );
};
export default AddNewFlowForm;
