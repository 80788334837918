import { Box, Skeleton, Stack } from "@mui/material";

const PricingDistributionSkeleton = () => {
  const bellDistributionHeights = [50, 150, 250, 350, 250, 175, 100, 50];
  return (
    <Stack
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        gap: "8px",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          gap: "60px",
          alignItems: "end",
        }}
      >
        {Array.from({ length: 8 }).map((_, index) => (
          <Skeleton
            key={`skeleton-column-${index}`}
            variant="text"
            width="100px"
            sx={{
              height: `${bellDistributionHeights[index]}px`,
              borderRadius: "4px",
              transform: "unset",
            }}
          />
        ))}
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "60px",
          alignItems: "center",
        }}
      >
        {Array.from({ length: 8 }).map((_, index) => (
          <Box
            key={`skeleton-column-${index}`}
            sx={{
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="text"
              width="100px"
              sx={{
                borderRadius: "4px",
                transform: "unset",
              }}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default PricingDistributionSkeleton;
