import { useQuery, UseQueryOptions } from "react-query";
import { BoardExists } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

export async function getBoardExists(): Promise<BoardExists> {
  return await apiRequest<BoardExists>("circuitboards/exists", "GET");
}

export default function useBoardExists(
  options?: UseQueryOptions<BoardExists, APIError>
) {
  return useQuery<BoardExists, APIError>(
    "boardExists",
    () => getBoardExists(),
    { ...options }
  );
}
