import {Box} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForward from "@mui/icons-material/ArrowForward";
import {FC} from "react"

const style = {
  marginRight: 2,
  marginTop: 0.5,
};

const InputListItemIcon: FC<{
  selected: boolean;
  valid: boolean;
}> = ({ selected, valid }) =>
  selected ? (
    <ArrowForward fontSize="small" color="primary" sx={style} />
  ) : valid ? (
    <CheckCircleIcon
      fontSize="small"
      sx={{ ...style, color: "success.dark" }}
    />
  ) : (
    <Box marginRight={4.2} />
  );

export default InputListItemIcon;
