import {ParameterTextFieldProps} from "./types";
import ParameterTextField from "./ParameterTextField";
import {FC} from "react"

const ParameterNumberField: FC<
  Omit<ParameterTextFieldProps, "onChange"> & {
    onChange: (value: number) => void;
  }
> = (props) => (
  <ParameterTextField
    {...props}
    value={Number.parseFloat(props.value) === 0 ? "0" : props.value}
    fieldType="number"
    onChange={(value) => props.onChange(Number.parseFloat(value))}
  />
);

export default ParameterNumberField;
