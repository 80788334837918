import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Search } from "../../components/SearchInput/Search";
import HeaderTabs from "./HeaderTabs";
import { Icon } from "../../components/Icon";
import HeaderCards from "./HeaderCards";
import { useSalesContext } from "./hooks/selectors";
import Filter from "./Filter";
import ProductGroupFilter from "./ProductGroupFilter";
import { useSplitEnabled } from "../../hooks";
import { Split } from "../../FeatureFlags/enums";
import { useLocation } from "react-router-dom";

const stackProps = {
  spacing: 2,
  justifyContent: "space-between",
  direction: "row" as const,
  alignItems: "center",
  sx: { padding: "0 8px" },
};

const SearchAndNotification = () => {
  const { search, setSearch } = useSalesContext();
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      spacing={0}
      maxWidth="225px"
      width="100%"
    >
      <Search
        value={search}
        placeholder="Search ASIN"
        data-testid="insights-search"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </Stack>
  );
};
export const Header: React.FC = () => {
  const theme = useTheme();
  const wrapperRef = useRef(null);
  // const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);
  const { SalesMetaData, metadataLoading } = useSalesContext();
  const [showFilter, setShowFilter] = useState(false);
  const enableAlert = useSplitEnabled(Split.SALES_ASSISTANT_ALERTS);
  const location = useLocation();

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFilter(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const brandFilter = localStorage.getItem("brandFilter")
  const categoryFilter = localStorage.getItem("categoryFilter")

  return (
    <Box>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "0 32px",
          margin: "12px 0 0 0",
          gap: "8px",
        }}
      >
        <Stack {...stackProps} sx={{ paddingBottom: "12px" }}>
          <Typography
            variant="headline3"
            display="flex"
            flexDirection="row"
            gap="12px"
            alignItems="center"
          >
            Sales Assistant{" "}
            {/* <Typography
              color={theme.palette.info.contrastText}
              variant="caption"
            >
              <Icon src="sales/refresh.svg" /> Sales data updated until 10-22
            </Typography> */}
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: "12px" }}
            ref={wrapperRef}
          >
            <Stack direction="row" gap="12px" alignItems="center">
              <ProductGroupFilter />
              <SearchAndNotification />
            </Stack>
            <Box width="40px" position="relative">
              <Box
                display="flex"
                justifyContent="center"
                position="relative"
                width={40}
                height={32}
                borderRadius="4px"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  "& svg": { "& path": { fill: (brandFilter || categoryFilter) ? showFilter ? theme.palette.text.link : theme.palette.grey[400] : showFilter ? theme.palette.text.link : theme.palette.grey[400] } },
                  backgroundColor: (brandFilter || categoryFilter) ? showFilter ? theme.palette.primary.light : theme.palette.grey[50] : showFilter ? theme.palette.primary.light : "",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[50]
                  },
                }}
                onClick={handleShowFilter}
              >
                <Icon src="sales/filter.svg" />
                {(brandFilter || categoryFilter) && (
                  <Box
                    height="10px"
                    display="flex"
                    width="10px"
                    padding="2px"
                    borderRadius="50%"
                    bgcolor={theme.palette.background.default}
                    position="absolute"
                    top="8px"
                    right="10px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      bgcolor={theme.palette.primary.main}
                      height="6px"
                      width="6px"
                      borderRadius="50%"
                    ></Box>
                  </Box>
                )}
              </Box>
              {showFilter && <Filter handleShowFilter={handleShowFilter} />}
            </Box>
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "0 32px 20px",
          margin: "20px 0 20px",
          gap: "8px",
          marginBottom:
            enableAlert && location.pathname.includes("/sales-assistant")
              ? 20
              : 0,
        }}
      >
        {SalesMetaData && (
          <HeaderCards
            SalesMetaData={SalesMetaData}
            metadataLoading={metadataLoading}
          />
        )}
      </Box>

      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "0 32px",
          margin: "12px 0 0 0",
          gap: "8px",
          display: location.pathname.includes("/sales-assistant")
            ? enableAlert
              ? "block"
              : "none"
            : "block",
        }}
      >
        <HeaderTabs isLoading={false} />
      </Box>
    </Box>
  );
};
