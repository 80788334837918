import * as React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import theme from "../../themes/light";
import {
  // ActionIcon,
  // BoardIcon,
  InProgressIcon,
  ToDoIcon,
  DoneIcon,
  NoOpenTaskIcon,
  LinkIcon,
} from "../../assets";
// import { Button } from "@mui/base";
import ActionMenu from "./ActionMenu";
import moment from "moment";
import ArchivePopup from "./ArchivePopup";
// import { useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
import { useAuthorizedUsers } from "../../hooks/useUsers";
import { useHistory } from "react-router-dom";

export const SalesTaskListing = ({ taskList, isLoading }: any) => {
  const [open, setOpen] = React.useState(false);
  const [taskId, setTaskId] = React.useState<any>("");
  const history = useHistory();

  const handlePopup = (id: string) => {
    if (id) {
      setTaskId(id);
    }
    setOpen(!open);
  };

  const handleBoardLink = () => {
    history.push("/tasks");
  };

  return (
    <>
      {isLoading || taskList?.length > 0 ? (
        <>
          <Stack
            sx={{
              background: "#F4F3F8",
              height: "100%",
              padding: "16px 24px",
              borderRadius: "12px",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="headline3">
                {taskList?.length || 0} Open Tasks{" "}
              </Typography>
            </Box>
            <Stack
              sx={{
                maxWidth: theme.spacing(1300 / 8),
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                padding: 0,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: "0px 16px" }}
              >
                <Box sx={{ width: "55%" }}>
                  <Typography
                    variant="caption"
                    color={theme.palette.grey[500]}
                    sx={{ cursor: "pointer" }}
                  >
                    Task description
                  </Typography>
                </Box>
                <Box sx={{ width: "15%" }}>
                  <Typography
                    variant="caption"
                    color={theme.palette.grey[500]}
                    sx={{ cursor: "pointer" }}
                  >
                    Date
                  </Typography>
                </Box>
                <Box sx={{ width: "15%" }}>
                  <Typography variant="caption" sx={{ cursor: "pointer" }}>
                    Assignee
                  </Typography>
                </Box>
                <Box sx={{ width: "8%" }}>
                  <Typography variant="caption" sx={{ cursor: "pointer" }}>
                    Action
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ marginTop: "18px" }}>
                <Listing dataArr={taskList} handlePopup={handlePopup} />
              </Box>
            </Stack>
          </Stack>
          <ArchivePopup open={open} handleClose={handlePopup} taskId={taskId} />
        </>
      ) : (
        <Box
          sx={{
            minHeight: "524px",
            background: theme.palette.grey[25],
            borderRadius: "12px",
            gap: "24px",
            "& .MuiButtonBase-root": {
              "&:hover": { background: theme.palette.text.link },
              "&:active": { background: theme.palette.text.link },
            },
            "& svg": { marginBottom: "-14px" },
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <NoOpenTaskIcon />
          <Button
            sx={{
              fontSize: "14px",
              padding: "6px 12px",
              background: theme.palette.text.link,
              borderRadius: "4px",
              lineHeight: "20px",
              color: "#FFF",
              fontWeight: 600,
              boxShadow:
                "0px 1px 3px 0px rgba(142, 79, 246, 0.02), 0px 1px 0px 0px rgba(142, 79, 246, 0.05);",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "6px",
              "& svg": { margin: "0" },
            }}
            onClick={handleBoardLink}
          >
            <LinkIcon />
            Go To Board
          </Button>
        </Box>
      )}
    </>
  );
};

const Listing = ({ dataArr, handlePopup }: any) => {
  const allMembersList = useAuthorizedUsers();
  const allMembers = allMembersList?.filter(
    (el) => !el.email.includes("@noogata.com")
  );
  const [isClicked, setIsClicked] = React.useState(false);
  const formatDate = (dateString: string) => {
    const date = moment(dateString);
    return date.format("MMM DD");
  };
  const getAssignInfo = (email: string) => {
    if (allMembers?.length > 0) {
      const userData = allMembers?.find((el) => el.email === email);
      const { profileImage, name } = userData || {};
      const assignName = name?.substring(0, 10);
      const assignFirstName = name?.substring(0, 1);
      return {
        profileImage,
        name: assignName,
        assignFirstName: assignFirstName,
      };
    }
  };
  return (
    <>
      {dataArr?.length > 0 &&
        dataArr?.map((el: any, i: any) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.backgrounds.white,
              padding: "8px 16px",
              borderRadius: "16px",
              minHeight: "74px",
              marginBottom: "8px",
            }}
            key={i}
          >
            <Box
              sx={{
                width: "55%",
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => setIsClicked(!isClicked)}
                width="32px"
                height="32px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background:
                    el?.status === "to_do"
                      ? theme.palette.grey[50]
                      : el?.status === "in_progress"
                      ? "#FDF4E3"
                      : theme.palette.success.light,
                  borderRadius: "4px",
                  cursor: "pointer",
                  "& svg": {
                    path: {
                      fill:
                        el?.status === "to_do"
                          ? theme.palette.grey[500]
                          : el?.status === "in_progress"
                          ? "#9F6204"
                          : theme.palette.success.dark,
                    },
                  },
                }}
              >
                {el?.status === "to_do" ? (
                  <ToDoIcon />
                ) : el?.status === "in_progress" ? (
                  <InProgressIcon />
                ) : (
                  <DoneIcon />
                )}
              </Box>
              <Typography
                variant="body4"
                gap="16px"
                display="flex"
                alignItems="center"
                sx={{
                  width: "calc(100% - 48px)",
                  wordBreak: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                {el?.description?.length > 60
                  ? `${el?.description?.substring(0, 60)}...`
                  : `${el?.description?.substring(0, 60)}`}
              </Typography>
            </Box>
            <Box sx={{ width: "15%" }}>
              <Typography
                gap="4px"
                display="flex"
                alignItems="center"
                variant="caption"
              >
                {formatDate(el?.created_at)}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "15%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {el?.assignee ? (
                <>
                  {!getAssignInfo(el?.assignee)?.profileImage && (
                    <Box
                      sx={{
                        height: "28.48px",
                        width: "28.48px",
                        borderRadius: "50%",
                        background: theme.palette.grey[50],
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {getAssignInfo(el?.assignee)?.assignFirstName}
                    </Box>
                  )}

                  {getAssignInfo(el?.assignee)?.profileImage && (
                    <Avatar
                      alt="profile"
                      src={getAssignInfo(el?.assignee)?.profileImage}
                      sx={{
                        border: `2px solid ${theme.palette.background.paper}`,
                      }}
                    />
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    height: "28.48px",
                    width: "28.48px",
                    borderRadius: "50%",
                    background: theme.palette.grey[50],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    },
                  }}
                >
                  <img src="/tasks/default.png" alt="task" />
                </Box>
              )}

              {/* <Typography variant="caption">
                {" "}
                {getAssignInfo(el?.assignee)?.name}
              </Typography> */}
            </Box>
            <Box
              sx={{
                width: "8%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  height: 32,
                  width: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                }}
              >
                {/* <ActionIcon /> */}
                <ActionMenu
                  salesDrawerList={true}
                  taskId={el.id}
                  handlePopup={handlePopup}
                  assignee={el.assignee}
                  description={el.description}
                />
              </Box>
            </Box>
          </Box>
        ))}
    </>
  );
};
