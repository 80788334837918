import { GoogleAuthorizeResponse } from "../types";
import {
  createOauthIntegrationFromAuthCode,
  getOauthIntegration,
} from "../services/oauthIntegrations";
import {
  GOOGLE_SHEETS_READ_WRITE_SCOPES,
  READ_WRITE_GOOGLE_AUTH_CONFIG,
} from "../constants";
import useGoogleApi from "./useGoogleApi";

declare const gapi: any;

const useGoogleSheetsDestination = ({
  onSuccess,
  onError,
}: {
  onSuccess: (accessToken: string, outputs?: string[]) => void;
  onError: (message: string) => void;
}) => {
  useGoogleApi();
  const authorizationCallback = async (response: GoogleAuthorizeResponse) => {
    if (response.error) {
      if (response.error !== "popup_closed_by_user") {
        onError(response.error);
      }
      return;
    }
    if (!response.code) {
      onError("Unable to create access code");
      return;
    }
    try {
      const { accessToken } = await createOauthIntegrationFromAuthCode(
        "google",
        response.code,
        GOOGLE_SHEETS_READ_WRITE_SCOPES
      );
      await onSuccess(accessToken);
    } catch (error: any) {
      onError(error.message);
    }
  };

  function verifyReadWriteScopes(scopes?: string[]) {
    if (
      !scopes ||
      !GOOGLE_SHEETS_READ_WRITE_SCOPES.every((requiredScope) =>
        scopes.includes(requiredScope)
      )
    ) {
      throw Error("Ouath Integration missing the required scopes");
    }
  }

  return async (outputs?: string[]) => {
    try {
      const { accessToken, scopes } = await getOauthIntegration("google");
      verifyReadWriteScopes(scopes);
      await onSuccess(accessToken, outputs);
    } catch (err) {
      gapi.load("auth2", () => {
        gapi.auth2.authorize(
          READ_WRITE_GOOGLE_AUTH_CONFIG,
          authorizationCallback
        );
      });
    }
  };
};

export default useGoogleSheetsDestination;
