import { useQuery, UseQueryOptions } from "react-query";
import { MetricRead, MessageRead } from "../types";
import apiRequest, { APIError } from "./apiRequest";

export async function getMetrics(runId: string): Promise<MetricRead[]> {
  return await apiRequest<MetricRead[]>("tracking/metrics", "GET", {
    run_id: runId,
  });
}

export async function getMessages(runId: string): Promise<MessageRead[]> {
  return await apiRequest<MessageRead[]>("tracking/messages", "GET", {
    run_id: runId,
  });
}

export function useRunMessages(
  runId?: string,
  options?: UseQueryOptions<MessageRead[], APIError>
) {
  return useQuery<MessageRead[], APIError>(
    ["messages", runId || ""],
    () => getMessages(runId || ""),
    {
      ...options,
      enabled: true,
    }
  );
}
