import {Typography} from "@mui/material";
import {ConnectorType} from "../../../../enums";
import {ConnectorTypeCardProps} from "../ConnectorTypeSelection";
import InputTypeSelectionCard from "../InputTypeSelectionCard";
import {FC} from "react"

const LocalFileCard: FC<ConnectorTypeCardProps> = ({setInputValue}) => (
    <InputTypeSelectionCard
        onClick={() => setInputValue({type: ConnectorType.LOCAL})}
        children={
            <>
                <img
                    src={`/connector-csv-icon.svg`}
          alt="Local"
          height="40px"
          width="40px"
        />
        <Typography variant="h6">Local File</Typography>
        <Typography variant="caption">Upload a CSV or Parquet file</Typography>
      </>
    }
  />
);
export default LocalFileCard;
