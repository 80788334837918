// import { useAuthUserOrNull } from "@frontegg/react";
// import { User } from "@frontegg/redux-store";
import { Box, BoxProps, Drawer, Stack, useTheme } from "@mui/material";
// import { PlatformRole } from "@noogata/types";

import { useHistory } from "react-router-dom";
// import AppModeButton from "../../../components/AppModeButton.tsx";
// import AppModeClosedButton from "../../../components/AppModeClosedButton.tsx";
// import { getUserMostPrivilegeRole } from "../../../services/auth.ts";
import { DigitalShelfList } from "./DigitalShelfList.tsx";
import { InsightsNavItem } from "./InsightsNavItem.tsx";
// import { LogoutNavItem } from "./LogoutNavItem.tsx";
import { PerfectContentList } from "./PerfectContentList.tsx";
import { TrendspottingList } from "./TrendspottingList.tsx";
import { CustomDashboardsList } from "./CustomDashboardsList.tsx";
import { NavbarModes } from "../../../enums.ts";
import { useAppContext } from "../../../ApplicationContext.tsx";
import { AdBoosterList } from "./AdBoosterList.tsx";
import { Split } from "../../../FeatureFlags/enums.ts";
import { useSplitEnabled } from "../../../hooks/index.ts";
import {
  ThemeProviderV2,
  useIsDarkMode,
} from "../../../components/ThemeProviderV2.tsx";
import { SalesInsightsNavItem } from "./SalesInsightsNavItem.tsx";
import { Tracking } from "./Tracking.tsx";
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { Tasks } from "./Tasks.tsx";
import { InsightsChatNavItem } from "./InsightsChatNavItem.tsx";
// import { SpacesNavItem } from "./SpacesNavItem.tsx";
import { InsightsChatV2NavItem } from "./InsightsChatV2NavItem.tsx";
import { SpacesV2NavItem } from "./SpacesV2NavItem.tsx";
import { TenantSelectionV2 } from "../../../components/TenantSelectionV2.tsx";
// import { UserSectionV2 } from "../../../components/LabLeftNav/UserSectionV2.tsx";
// import { PinIcon, PinnedIcon } from "../../../assets/index.tsx";
import { CatalogEnrichmentNavItem } from "./CatalogEnrichmentNavItem.tsx";
import NavbarIndicator from "../../../components/NavbarIndicator.tsx";
import ProfileMenu from "../Components/ProfileMenu.tsx";
import FadedText from "./FadedText.tsx";
import { AssortmentIntelligenceNavItem } from "./AssortmentIntelligenceNavItem.tsx";

interface ApplicationsLeftNavProps {
  isDrawerPinned: boolean;
  setIsDrawerPinned: (isDrawerPinned: boolean) => void;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}

const Logo: FC<{ size?: "full" | "favicon" } & Partial<BoxProps>> = ({
  size = "full",
  ...props
}) => {
  const history = useHistory();
  const isDarkMode = useIsDarkMode();
  return (
    <Box
      onClick={() => history.push("/")}
      {...props}
      sx={{ marginLeft: "4px" }}
    >
      <img
        src={
          size !== "full"
            ? "/favicon-dark.svg"
            : isDarkMode
              ? "/logo-dark-mode.svg"
              : "/logo-black.svg"
        }
        alt=""
        height={30}
        width={size === "full" ? 114 : 30}
      />
    </Box>
  );
};

const ApplicationsLeftNav: FC<ApplicationsLeftNavProps> = ({
  // isDrawerPinned,
  // setIsDrawerPinned,
  tab,
  setTab,
}) => {
  const theme = useTheme();
  const insightsEnabled = useSplitEnabled(Split.INSIGHTS);
  const salesAssistantEnabled = useSplitEnabled(Split.SALES_ASSISTANT);
  const isAdBoosterEnabled = useSplitEnabled(Split.AD_BOOSTER);
  const isTaskEnabled = useSplitEnabled(Split.SALES_ASSISTANT_TASKS);
  const isSpacesV2Enabled = useSplitEnabled(Split.SPACES_V2);
  const isCatalogEnrichmentEnabled = useSplitEnabled(Split.CATALOG_ENRICHMENT);
  const isAssortmentIntelligenceEnabled = useSplitEnabled(
    Split.ASSORTMENT_INTELLIGENCE
  );
  const isChatV2Enabled = useSplitEnabled(Split.CHAT_V2_FF);
  const isTracking = useSplitEnabled(Split.TRACKING);
  const InsightsChatEnabled = useSplitEnabled(Split.INSIGHTS_CHAT);

  const { navbarMode } = useAppContext();
  const open = navbarMode === NavbarModes.EXPENDED;

  const openWidth = isCatalogEnrichmentEnabled ? '208px' : "190px"
  // using min-width because transitions don't work well with width:auto
  const width = open ? openWidth : `calc(${theme.spacing(7)} + 1px)`;

  const container =
    typeof window !== "undefined" ? window.document.body : undefined;

  const sidebarRef = useRef<HTMLDivElement | null>(null);


  const [sidebarWidth, setSidebarWidth] = useState(0);

  const updateSidebarWidth = () => {
    if (sidebarRef.current) {
      //@ts-ignore
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    const sidebar = sidebarRef.current
    if (sidebar) {
      setSidebarWidth(sidebar.offsetWidth);

      const handleTransitionEnd = () => {
        updateSidebarWidth();
      };

      sidebar.addEventListener('transitionend', handleTransitionEnd);
      return () => {
        sidebar.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [sidebarRef.current, open]);

  return (
    <Drawer
      open
      ref={sidebarRef}
      container={container}
      variant="permanent"
      sx={{
        width,
        display: { xs: "block", sm: "block" },
        "& .MuiDrawer-paper": {
          overflowX: "hidden",
          px: "10px",
          pb: 2,
          transition: "width 0.5s ease",
          boxSizing: "border-box",
          width,
          background: theme.palette.background.default,
        },
        transition: "width 0.5s ease",
        height: "100%"
      }}
    >
      <Stack
        // p={1}
        // paddingLeft={1}
        sx={{
          position: "sticky",
          padding: "1rem 0 16px",
          top: 0,
          left: 0,
          zIndex: "10000",
          background: "#2C224C",
          display: "flex",
          justifyContent: "space-between",
          flexShrink: 0,
        }}
      >
        <Logo size={open ? "full" : "favicon"} />
      </Stack>
      <Stack
        flex={1}
        justifyContent="space-between"
        sx={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",

        }}
      >

        <Stack spacing={2}>
          <Stack
            sx={{
              borderBottom: "2px solid rgb(209 211 212 / 20%)",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "4px 0 16px 0",
            }}
          >
            <FadedText text="SALES ASSISTANT" open={open} />
            <Stack spacing={1}>
              {salesAssistantEnabled && (
                <SalesInsightsNavItem tab={tab} setTab={setTab} />
              )}
              {insightsEnabled && <InsightsNavItem tab={tab} setTab={setTab} />}
              <PerfectContentList open={open} tab={tab} setTab={setTab} />
            </Stack>
          </Stack>
          <Stack
            sx={{
              borderBottom: "2px solid rgb(209 211 212 / 20%)",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "4px 0 16px 0",
            }}
          >
            <FadedText text="RESEARCH ASSISTANT" open={open} />

            <Stack spacing={1}>
              {InsightsChatEnabled && (
                <InsightsChatNavItem tab={tab} setTab={setTab} />
              )}
              {isChatV2Enabled && (
                <InsightsChatV2NavItem tab={tab} setTab={setTab} />
              )}
              <DigitalShelfList open={open} tab={tab} setTab={setTab} />
              {isAssortmentIntelligenceEnabled && (
                <AssortmentIntelligenceNavItem tab={tab} setTab={setTab} />
              )}
            </Stack>
          </Stack>
          <Stack
            spacing={1}
            sx={{
              padding: "4px 0 16px 0",
            }}
          >
            {/* {salesAssistantEnabled && <SalesInsightsNavItem />} */}
            {/* {InsightsChatEnabled && <InsightsChatNavItem />}
            {isChatV2Enabled && <InsightsChatV2NavItem />} */}
            {isSpacesV2Enabled && <SpacesV2NavItem tab={tab} setTab={setTab} />}

            {/* {isCatalogEnrichmentEnabled &&  */}
            {isCatalogEnrichmentEnabled && (
              <CatalogEnrichmentNavItem tab={tab} setTab={setTab} />
            )}
            {/* } */}

            {isTaskEnabled && <Tasks tab={tab} setTab={setTab} />}
            {/* {insightsEnabled && <InsightsNavItem />} */}
            {/* <DigitalShelfList open={open} /> */}
            {/* <PerfectContentList open={open} /> */}
            {isAdBoosterEnabled && (
              <AdBoosterList open={open} tab={tab} setTab={setTab} />
            )}
            <CustomDashboardsList open={open} tab={tab} setTab={setTab} />
            <TrendspottingList open={open} tab={tab} setTab={setTab} />
            {isTracking && <Tracking tab={tab} setTab={setTab} />}
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        {/* <LogoutNavItem /> */}
        {/* {role !== PlatformRole.ApplicationUser &&
            (open ? <AppModeButton /> : <AppModeClosedButton />)} */}
        <TenantSelectionV2 showSelected={open} />
        {/* <UserSectionV2
            open={open}
            sx={{
              marginLeft: theme.spacing(1),
              maxHeight: theme.spacing(40),
            }}
          /> */}
        <ProfileMenu sideBarOpen={open} sidebarWidth={sidebarWidth} />
        <NavbarIndicator />
      </Stack>
    </Drawer>
  );
};

export default ({
  isDrawerPinned,
  setIsDrawerPinned,
  tab,
  setTab,
}: ApplicationsLeftNavProps) => (
  <ThemeProviderV2>
    <ApplicationsLeftNav
      isDrawerPinned={isDrawerPinned}
      setIsDrawerPinned={setIsDrawerPinned}
      tab={tab}
      setTab={setTab}
    />
  </ThemeProviderV2>
);
