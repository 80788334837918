import { FC, MouseEvent } from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DrawerHeader: FC<{
  title: string;
  subtitle?: string;
  onClose: (event?: MouseEvent<HTMLButtonElement>) => void;
}> = ({ title, subtitle, onClose }) => (
  <>
    <Box padding={1} display="flex" height={65}>
      <Box flex="0 0 auto" marginRight={1}>
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      {subtitle ? (
        <Box flex="1 1 auto">
          <Typography color="grey.700" lineHeight={0} variant="overline">
            {subtitle}
          </Typography>
          <Typography variant="h6">{title}</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}
    </Box>
    <Divider sx={{ marginBottom: 2 }} />
  </>
);
export default DrawerHeader;
