import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router";
import theme from "../../../../themev2";

const ErrorMessageBox: FC<{
  count: number;
  message: string;
  type: string;
}> = ({ count, message, type }) => {
  const history = useHistory();
  return (
    <Paper
      sx={{
        height: 300,
        backgroundColor: "white",
        margin: 3,
        p: 2,
        boxShadow: 1,
      }}
      onClick={() => history.push("logs")}
    >
      <Stack direction="row">
        <Box
          sx={{
            height: 30,
            width: 30,
            backgroundColor: theme.palette.error.light,
            mr: 2,
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WarningOutlinedIcon color="error" />
        </Box>
        <Typography variant="h6" color="error.main">
          {type}
        </Typography>
        {count > 1 && (
          <Typography
            variant="body2"
            color="grey.500"
            sx={{
              ml: 1.5,
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => history.push("logs")}
          >
            + {count - 1}
          </Typography>
        )}
      </Stack>
      <Paper
        elevation={0}
        sx={{
          maxHeight: (t) => t.spacing(230 / 8),
          p: 2,
          overflowY: "auto",
        }}
      >
        <Typography variant="body1" flexDirection="column" display="flex">
          <ReactMarkdown>{message}</ReactMarkdown>
        </Typography>
      </Paper>
    </Paper>
  );
};
export default ErrorMessageBox;
