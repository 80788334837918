import {FC, useEffect, useRef, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {Search as SearchIcon} from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  BoxProps,
  Chip,
  ClickAwayListener,
  Fade,
  InputAdornment,
  InputProps,
  TextField,
} from "@mui/material";
import useDebouncedCallback from "../../hooks/UseDebouncedCallback";
import * as Mousetrap from "mousetrap";

const useStyles = makeStyles((theme) => ({
  search: {
    // @ts-ignore
    transition: theme.transitions.create("all", {
      // @ts-ignore
      easing: theme.transitions.easing.easeIn,
      // @ts-ignore
      duration: theme.transitions.duration.shortest,
    }),
  },
  inputAdornedStart: {
    // @ts-ignore
    paddingLeft: theme.spacing(1),
  },
  inputAdornedEnd: {
    // @ts-ignore
    paddingRight: theme.spacing(1),
  },
  searchIndicator: {
    // @ts-ignore
    border: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
  },
}));

export type SearchBarProps<T> = Omit<
  AutocompleteProps<T, false, false, true>,
  "renderInput"
> & {
  placeholder?: string;
  debounceTimeout?: number;
};

const SearchBar: FC<
  SearchBarProps<any> & { inputProps?: InputProps; containerProps?: BoxProps }
> = ({
  onInputChange,
  debounceTimeout = 150,
  placeholder = "Search",
  containerProps,
  inputProps,
  ...props
}) => {
  const onInputChangeDebounced =
    onInputChange && useDebouncedCallback(onInputChange, debounceTimeout);
  const textInputRef = useRef<any>(null);
  const classes = useStyles();
  const [isFocussed, setFocussed] = useState(false);
  const onFocus = (_: any) => {
    setFocussed(true);
  };
  const onFocusLoss = () => {
    setFocussed(false);
  };

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      e.preventDefault();
      if (textInputRef?.current) {
        textInputRef.current.focus();
      }
      window.scrollTo(0, textInputRef?.current?.offsetTop);
    };
    // @ts-ignore
    Mousetrap.bind("mod+f", handler);

    return () => {
      Mousetrap.unbind("mod+f");
    };
  }, [textInputRef.current]);
  const searchLabel =
    window.navigator.userAgent.toLowerCase().indexOf("windows") > -1
      ? "CTL+F"
      : "⌘+F";
  return (
    <ClickAwayListener onClickAway={onFocusLoss}>
      <Box
        borderRadius="0.5rem"
        bgcolor={isFocussed ? "#FFF" : "#F1F3F4"}
        boxShadow={isFocussed ? 2 : 0}
        className={classes.search}
        width={props.fullWidth ? "100%" : "auto"}
        {...containerProps}
      >
        <Autocomplete
          onInputChange={onInputChangeDebounced}
          fullWidth={props.fullWidth}
          freeSolo
          onFocus={onFocus}
          openOnFocus={false}
          classes={{
            endAdornment: classes.inputAdornedEnd,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              margin="none"
              inputRef={textInputRef}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                sx: {
                  mt: 1,
                  mb: 1,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornedStart}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornedStart}
                  >
                    <Fade in={!isFocussed}>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={searchLabel}
                        color="primary"
                      />
                    </Fade>
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
                ...inputProps,
              }}
            />
          )}
          {...props}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
