import { useAuthUserOrNull } from "@frontegg/react";
import { IUserProfile } from "@frontegg/rest-api";
import { PlatformPermission, PlatformRole } from "../enums";

export type UserAuthzorization = {
  roles?: PlatformRole[];
  permissions?: PlatformPermission[];
};

export const verifyUserAuth = (
  user: IUserProfile,
  { roles = [], permissions = [] }: UserAuthzorization
): boolean => {
  const userHasRequiredRoles =
    roles.length === 0
      ? true
      : roles.every((platformRole) =>
          user?.roles.find((r) => r.key === platformRole)
        );
  const userHasRequiredPermissions =
    permissions.length === 0
      ? true
      : permissions.every((platformPermission) =>
          user?.permissions.find((r) => r.key === platformPermission)
        );
  return !!(userHasRequiredRoles && userHasRequiredPermissions);
};

export const useUserAuthorization = (authz: UserAuthzorization): boolean => {
  const user = useAuthUserOrNull();
  return user ? verifyUserAuth(user, authz) : false;
};
