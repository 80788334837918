import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, InputLabel, Typography } from "@mui/material";
import { CloseIcon } from "../../assets";
import { AssignTeamMember } from "../SalesAssistant/List/Drawer/AssignTeamMember";
import { updateTask } from "./useTaskApi";
import { handleToast } from "./TaskUtils";
import { Dispatch, SetStateAction } from "react";
const ReAssignMember: React.FC<{
  open: boolean;
  handleClose: () => void;
  taskId: string;
  selectedEmail: string;
  setIsUpdated: Dispatch<SetStateAction<boolean>>;
}> = ({ open, handleClose, taskId, selectedEmail, setIsUpdated }) => {
  const handleAssign = async (value: string) => {
    const body = {
      assignee: value,
    };
    await updateTask(body, taskId);
    setIsUpdated(true);
    handleToast("Task assignee has been updated.");
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "420px",
            width: "100%",
            margin: 0,
            padding: "24px",
            gap: "8px",
            minHeight: "440px",
            "& .MuiOutlinedInput-notchedOutline": {
              // display: 'none'
            },
            "& .MuiSelect-select": {
              background: "#F4F3F8",
            },
            "& .MuiDialogContent-root": {
              overflow: "initial",
            },
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px" }}>
          <Typography variant="headline3">Reassign</Typography>
          <Box
            sx={{
              position: "absolute",
              top: "12px",
              right: "12px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            marginTop: "16px",
            "& .MuiFormControl-root": { width: "100%" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="24px"
          >
            <Box sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Assign to:
              </InputLabel>

              <AssignTeamMember
                type="reassign"
                handleChange={handleAssign}
                selectedEmail={selectedEmail}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              //   gap="12px"
              width="100%"
            ></Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ReAssignMember;
