import theme from "../../themes/light";
import { EnrichmentFlowState } from "./types";

export const timestampToString = (timestamp: Date) =>
  `${timestamp.getHours().toString().padStart(2, "0")}:${timestamp
    .getMinutes()
    .toString()
    .padStart(
      2,
      "0"
    )}:${timestamp.getSeconds().toString().padStart(2, "0")}\t:\t`;

export const nodeLabelToTitle = (nodeLabel: string) => {
  if (nodeLabel.includes("validate_raw_input")) {
    return "Validate Raw Input";
  }
  if (nodeLabel.includes("validate_cad")) {
    return "Validate Catalog Attributes Definition";
  }
  if (nodeLabel.includes("secondary_data_collection")) {
    return "Secondary Data Collection";
  }
  if (nodeLabel.includes("preprocess_for_attribute_extraction")) {
    return "Preprocess for Attribute Extraction";
  }
  if (nodeLabel.includes("attribute-extraction")) {
    return "Attribute Extraction";
  }
  if (nodeLabel.includes("post_process_extracted_attributes")) {
    return "Post Process Extracted Attributes";
  }
  if (nodeLabel.includes("save_extracted_attributes")) {
    return "Save Extracted Attributes";
  }

  return nodeLabel;
};

// return {textColor, backgroundColor}
export const getStatusColor = (status: EnrichmentFlowState) => {
  switch (status) {
    case EnrichmentFlowState.Running:
      return {
        textColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
      };

    case EnrichmentFlowState.Completed:
      return {
        textColor: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      };

    case EnrichmentFlowState.Failed:
    case EnrichmentFlowState.Crashed:
    case EnrichmentFlowState.Cancelled:
      return {
        textColor: theme.palette.error.dark,
        backgroundColor: theme.palette.error.light,
      };

    case EnrichmentFlowState.Pending:
      return {
        textColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
      };

    default:
      return {
        textColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
      };
  }
};

export const formatRunTime = (startTime: Date, endTime: Date) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const diff = end.getTime() - start.getTime();
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);
  const fraction = Math.floor((diff % 1000) / 100);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }
  if (seconds > 0) {
    return `${seconds}.${fraction}s`;
  }
  return `0.${fraction}s`;
};
