import {Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";

import {useBoard} from "../../../../../services/boards";
import {RunInput} from "../../../../../types";
import {prettifyString} from "../../../../../utils";
import {SkeletonRow} from "../../../../../components";
import {FC} from "react"

const BoardNameCell: FC<{
  boardId: string;
}> = ({boardId}) => {
  const {data: board} = useBoard(boardId);
  return board ? <TableCell>{board.displayName}</TableCell> : <></>;
};

const TriggeredByInputsTable: FC<{
  boardId: string;
  inputs: RunInput[];
}> = ({ boardId, inputs }) => {
  const { data: board } = useBoard(boardId);

  const displayNames: Record<string, string> | undefined =
    board &&
    board.pipelines[0].inputs.reduce(
      (obj, x) => Object.assign(obj, { [x.name]: x.display_name }),
      {}
    );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Stack direction="row">
                <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                  Inputs
                </Typography>
                <>
                  <Typography variant="h6" sx={{ mx: 1, color: "grey.500" }}>
                    •
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "grey.500",
                    }}
                  >
                    {inputs.length}
                  </Typography>
                </>
              </Stack>
            </TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Run</TableCell>
            <TableCell>Output</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inputs && displayNames
            ? inputs
                .filter((input) => !!input.value?.configuration)
                .map((input) => (
                  <TableRow key={input.name}>
                    <TableCell component="th" scope="row">
                      {prettifyString(input.name)}
                    </TableCell>
                    <BoardNameCell
                      boardId={input.value?.configuration?.board_id}
                    />
                    <TableCell>Latest</TableCell>
                    <TableCell>
                      {prettifyString(
                        input.value?.configuration?.output_id || ""
                      )}
                    </TableCell>
                  </TableRow>
                ))
            : [...Array(3).keys()].map((index) => (
                <SkeletonRow index={index} />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TriggeredByInputsTable;
