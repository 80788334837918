import {Typography} from "@mui/material";

import {ConnectorType} from "../../../../enums";
import {ConnectorLogoMapping} from "../../../../mappings";
import {ConnectorTypeCardProps} from "../ConnectorTypeSelection";
import InputTypeSelectionCard from "../InputTypeSelectionCard";
import {FC} from "react"

const AmazonProductsCard: FC<ConnectorTypeCardProps> = ({
                                                          setInputValue,
                                                          onCompletion,
                                                          input,
                                                          isOnboardingProduct,
                                                        }) => {
  const asin_input_names = [
    "asin",
    "asins",
    "input_asin",
    "product_catalog",
    "asin_list",
  ];

  const schemaInput =
    input && input.schema.properties
      ? Object.keys(input.schema.properties)[0]
      : "asin";
  return input &&
    asin_input_names.includes(input.name) &&
    isOnboardingProduct ? (
    isOnboardingProduct.length > 0 ? (
      <InputTypeSelectionCard
        onClick={() => {
          setInputValue({
            type: ConnectorType.AMAZON_PRODUCTS,
            configuration: {},
            mapping: { [schemaInput]: "asin" },
          });
          onCompletion();
        }}
        children={
          <>
            <img
              src={`/${ConnectorLogoMapping.AMAZON_PRODUCTS}`}
              alt="AmazonProducts"
              height="40px"
              width="40px"
            />
            <Typography variant="h6">My Data</Typography>
            <Typography variant="caption">
              Use your Amazon product catalog.
            </Typography>
          </>
        }
      />
    ) : (
      <InputTypeSelectionCard
        tooltipText="In order to use the Amazon Products data source, please make sure to finish the onboarding process."
        children={
          <>
            <img
              src={`/${ConnectorLogoMapping.AMAZON_PRODUCTS}`}
              alt="AmazonProducts"
              height="40px"
              width="40px"
              style={{ filter: "grayscale(100%)" }}
            />
            <Typography variant="h6">My Data</Typography>
            <Typography variant="caption">
              You have no Amazon products data.
            </Typography>
          </>
        }
      />
    )
  ) : null;
};

export default AmazonProductsCard;
