import {Box, Typography} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material/styles";
import {Status} from "../../../../enums";
import StatusIcon from "../../../../components/StatusIcon";
import {useStatusColor} from "../../../../hooks";
import {FC} from "react"

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  cardContentBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(1),
  },
  typography: {
    marginLeft: theme.spacing(2),
    color: ({ color }) => color,
  },
}));

const StatusBox: FC<{
  status: Status;
  text: string;
  warning?: boolean;
}> = ({ status, text, warning }) => {
  const color = useStatusColor(status, warning);
  const classes = useStyles({ color });
  return (
    <Box className={classes.cardContentBox}>
      <StatusIcon status={status} warning={warning} />
      <Typography variant="subtitle2" className={classes.typography}>
        {text}
      </Typography>
    </Box>
  );
};

export default StatusBox;
