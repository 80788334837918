import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

const DismissBtn = ({ outlined }: { outlined?: boolean }) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={
        <Typography
          variant="body5"
          sx={{ color: theme.palette.backgrounds.white }}
        >
          Dismiss
        </Typography>
      }
      placement="top-end"
      arrow
    >
      <Button size="small" variant={outlined ? "outlined" : "text"}>
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          sx={{
            color: theme.palette.text.primary,
            minHeight: "18px",
            padding: "3px",
            fontSize: "14px",
            fontWeight: "600",
            maxHeight: "24px",
            height: "24px",
          }}
        >
          Dismiss
        </Stack>
      </Button>
    </Tooltip>
  );
};

export default DismissBtn;
