import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CopyAllTwoTone } from "@mui/icons-material";
import { useAuthUser } from "@frontegg/react";
import { Run } from "../../../../types";

export const CopyRunUrl: FC<{ run: Run }> = ({ run }) => {
  const user = useAuthUser();
  const url = new URL(window.location.href);
  return (
    <Tooltip title="Copy run url">
      <IconButton
        size="small"
        onClick={() =>
          navigator.clipboard.writeText(
            `${url.origin}/boards/${run.circuitboard_id}/runs/${run.id}?tenantId=${user.tenantId}&lab`
          )
        }
      >
        <CopyAllTwoTone fontSize="small" sx={{ cursor: "pointer" }} />
      </IconButton>
    </Tooltip>
  );
};
