import {Button, Switch, Tooltip} from "@mui/material";
import SVG from "react-inlinesvg";
import {useSnackbar} from "notistack";
import {useMutation, useQueryClient} from "react-query";
import {Box} from "@mui/system";
import {updateBoard} from "../../services/circuitboards";
import {CircuitBoard} from "../../types";
import {FC} from "react"

const WarehouseSyncButton: FC<{
  board: CircuitBoard;
}> = ({board}) => {
  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();
  const mutation = useMutation(updateBoard, {
    onSuccess: (data) => {
      queryClient.setQueryData(["board", board.identifier], data);
      enqueueSnackbar(
        `Data Warehouse sync is ${
          data.bigquery_warehouse_export_enabled ? "enabled" : "disabled"
        }`,
        { variant: "success" }
      );
    },
    onError: () => {
      enqueueSnackbar("Unable to set Data Warehouse configuration", {
        variant: "error",
      });
    },
  });
  const bigquery_warehouse_export_enabled =
    board.bigquery_warehouse_export_enabled != null
      ? board.bigquery_warehouse_export_enabled
      : board.flow?.bigquery_warehouse_export_enabled;
  return (
    <Tooltip title={board.flow ? "Synced defined in flow." : ""}>
      <Box>
        <Button
          variant="outlined"
          size="large"
          startIcon={<SVG src={`/data-warehouse-icon.svg`} />}
          endIcon={<Switch checked={bigquery_warehouse_export_enabled} />}
          onClick={() =>
            mutation.mutate({
              id: board.identifier,
              bigqueryWarehouseExportEnabled:
                !bigquery_warehouse_export_enabled,
            })
          }
          disabled={!!board.flow || mutation.isLoading}
          sx={{ paddingY: 0 }}
        >
          Data Warehouse Sync
        </Button>
      </Box>
    </Tooltip>
  );
};

export default WarehouseSyncButton;
