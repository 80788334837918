import { Skeleton, useTheme } from "@mui/material";
import SummaryV2, { SummaryVariants } from "../../SummaryV2";
import { useInsightsContext } from "../../hooks/selectors";
import { mapRevenue } from "../InsightsListItem";
import { useSplitEnabled } from "../../../../hooks";
import { Split } from "../../../../FeatureFlags/enums";
import { InsightRead } from "../../types";

interface PropTypes {
  insight: InsightRead;
}

export const RevenueBox = ({ insight }: PropTypes) => {
  const theme = useTheme();
  // const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  // const displayList = useDisplayList();
  // const insight = displayList.find(({ id }) => insightId === id);
  const width = theme.spacing(124 / 8);
  const height = theme.spacing(64 / 8);
  const { statsPeriod } = useInsightsContext();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  if (!insight) {
    return <Skeleton width={width} height={height} />;
  }

  return insight ? (
    <SummaryV2
      total={mapRevenue(
        insight.revenueValue,
        insight.sales7D,
        insight.sales30D,
        statsPeriod,
        revenueOverTimeEnabled
      )}
      numberChange7D={insight.numberChange7D}
      percentageChange7D={insight.percentageChange7D}
      numberChange30D={insight.numberChange30D}
      percentageChange30D={insight.percentageChange30D}
      variant={SummaryVariants.Smaller}
    />
  ) : (
    <Skeleton width={width} height={height} />
  );
};
