import { Stack } from "@mui/material";

import SaveToMyItemsBtn from "./SaveToMyItemsBTN";
import DismissBtn from "./DismissBTN";

const TabsButtons = ({
  insight,
  outlined,
  setIsClicked,
}: {
  insight: any;
  outlined?: boolean;
  setIsClicked: any;
}) => (
  <div>
    <Stack direction="row" alignItems="center" gap="12px">
      {insight?.dismissed && <DismissBtn outlined={outlined} />}
      <SaveToMyItemsBtn
        outlined={outlined}
        insight={insight}
        setIsClicked={setIsClicked}
      />
    </Stack>
  </div>
);

export default TabsButtons;
