import { useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { enqueueSnackbar } from "notistack";
import { Icon } from "../../../../components/Icon";
import { useTabsButtons } from "./useTabsButtons";
import {
  useInsightMarkAsDone,
  useInsightMarkAsUnDone,
} from "../../useInsights";
import { useInsightsContext } from "../../hooks/selectors";

const MarkAsDoneBtn = ({
  insightId,
  outlined,
}: {
  insightId: string;
  outlined?: boolean;
}) => {
  const theme = useTheme();
  const { reAddItemToList, removeItemFromList } = useTabsButtons();
  const insightMarkAsDone = useInsightMarkAsDone();
  const insightMarkAsUnDone = useInsightMarkAsUnDone();
  const { refetchInsightsMetadata, refetchInsights } = useInsightsContext();

  const moveToDone = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        removeItemFromList(insightId);
        enqueueSnackbar("🎉 Nice work! You have acted on this insight", {
          variant: "secondary",
          action: () => (
            <Button
              variant={outlined ? "outlined" : "text"}
              sx={{ color: theme.palette.backgrounds.white }}
              onClick={async () => {
                await insightMarkAsUnDone({
                  insightId,
                });
                reAddItemToList(insightId);
                await refetchInsights();
                await refetchInsightsMetadata();
              }}
            >
              Undo
            </Button>
          ),
        });
        await insightMarkAsDone({ insightId });
        await refetchInsights();
        await refetchInsightsMetadata();
      } catch (e) {
        enqueueSnackbar("Could not Mark as done", {
          variant: "error",
        });
      }
    },
    [insightId]
  );
  return (
    <Button size="small" variant={outlined ? "outlined" : "text"}>
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          color: theme.palette.text.primary,
          minHeight: "18px",
          padding: "3px",
          fontSize: "14px",
          fontWeight: "600",
          maxHeight: "24px",
          height: "24px",
        }}
        onClick={moveToDone}
      >
        <Icon src="insights/check-fill.svg" width="16px" height="16px" />
        Mark as Done
      </Stack>
    </Button>
  );
};

export default MarkAsDoneBtn;
