import { Paper, TextField } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import {
  Column,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import Loadable from "../../components/Loadable";
import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";
import { UncontrolledTable } from "../../components";
import { useTenantCongifurationHistory } from "../../services/tenants/useTenantConfiguration";
import dayjs, { prettifyJson } from "../../utils";

const HistoryTable: FC<{ tenantId?: string; isLoading?: boolean }> = ({
  tenantId,
  isLoading: propsIsLoading,
}) => {
  const {
    data,
    isLoading: isHistoryLoading,
    error,
  } = useTenantCongifurationHistory({
    tenantId: tenantId || "",
    limit: 100,
    options: {
      enabled: !!tenantId,
    },
  });
  const isLoading = isHistoryLoading || propsIsLoading;
  const columns: Column<object>[] = useMemo(
    () => [
      {
        Header: "Timestamp",
        accessor: "created_at",
        Cell: ({ value }) => dayjs.utc(value).fromNow(),
      },
      {
        Header: "Changed By",
        accessor: "created_by_email",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Configuration",
        accessor: "configuration",
        Cell: ({ value }) => (
          <TextField
            fullWidth
            multiline
            maxRows={4}
            value={prettifyJson(JSON.stringify(value))}
          />
        ),
      },
    ],
    []
  );

  const instance = useTable(
    {
      columns,
      data: data || [],
      autoResetSortBy: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    instance.setPageSize(5);
  }, [instance]);

  return (
    <Paper variant="outlined">
      <Loadable isLoading={isLoading} error={error}>
        {data?.length === 0 ? (
          <IllustrationPlaceholder
            imageHeight={300}
            imageWidth={300}
            text="No configurations were recorded for this run."
          />
        ) : (
          <UncontrolledTable
            instance={instance}
            columns={columns}
            plugins={[useResizeColumns]}
            tableProps={{
              "aria-label": "a dense sticky table",
            }}
            data={data || []}
            isLoading={!!isLoading}
            error={error}
          />
        )}
      </Loadable>
    </Paper>
  );
};

export default HistoryTable;
