import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "../../../utils";
import { ScheduleEnd } from "../../../types";
import { ChangeEvent, FC, useState } from "react";

const formControlStyle = { width: 80 };
const textFieldStyle = { width: 170 };

enum RadioOption {
  NEVER = "never",
  ON_DATE = "onDate",
  AFTER_NUMBER_OF_OCCURRENCES = "afterNumberOfOccurrences",
}

const ScheduleEndRadioGroup: FC<{
  setEnd: (value: ScheduleEnd | null) => void;
}> = ({ setEnd }) => {
  const [radioValue, setRadioValue] = useState<RadioOption>(RadioOption.NEVER);
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(
    dayjs().add(3, "months")
  );
  const [numberOfOccurrences, setNumberOfOccurrences] = useState<number>(13);

  const updateFormOnRadioChange = (value: RadioOption) => {
    switch (value) {
      case RadioOption.NEVER:
        return setEnd(null);
      case RadioOption.ON_DATE:
        return setEnd({ value: endDate.toDate(), type: "date" });
      case RadioOption.AFTER_NUMBER_OF_OCCURRENCES:
        return setEnd({
          value: numberOfOccurrences,
          type: "occurrences",
        });
      default:
        break;
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as RadioOption;
    updateFormOnRadioChange(value);
    setRadioValue(value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "common.black" }}>
        Ends
      </FormLabel>
      <RadioGroup
        name="radio-buttons-group"
        value={radioValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value={RadioOption.NEVER}
          control={<Radio />}
          label="Never"
          sx={formControlStyle}
        />
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            value={RadioOption.ON_DATE}
            control={<Radio />}
            label="On"
            sx={formControlStyle}
          />
          <DesktopDatePicker
            disabled={radioValue !== RadioOption.ON_DATE}
            // defaultValue={dayjs('2022-04-17')} 
            value={endDate}
            onChange={(event: any) => {
              setEndDate(event);
              setEnd({ value: event.toDate(), type: "date" });
            }}
            slots={{textField: (props) => <TextField {...props}  size="small" sx={textFieldStyle} /> }}
        
            disablePast
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            value={RadioOption.AFTER_NUMBER_OF_OCCURRENCES}
            control={<Radio />}
            label="After"
            sx={formControlStyle}
          />
          <TextField
            disabled={radioValue !== RadioOption.AFTER_NUMBER_OF_OCCURRENCES}
            hiddenLabel
            id="outlined-number"
            type="number"
            value={numberOfOccurrences}
            onChange={(event) => {
              const value = event.target.value as unknown as number;
              setNumberOfOccurrences(value);
              setEnd({ value, type: "occurrences" });
            }}
            variant="outlined"
            size="small"
            sx={textFieldStyle}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {`occurrence${numberOfOccurrences > 1 ? "s" : ""}`}
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};

export default ScheduleEndRadioGroup;
