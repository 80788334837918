import InboxIcon from "@mui/icons-material/Inbox";
import { Button, Tooltip } from "@mui/material";
import { FC } from "react";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformPermission } from "../../../../enums";
import { useRun } from "../../../../services/runs";
import useArchive from "../../../../services/runs/useArchive";
import { RunHeaderButtonProps } from "../types";
import UnarchiveButton from "./UnarchiveButton";

const ArchiveButton: FC<RunHeaderButtonProps> = ({
  boardId,
  runId,
  classes,
}) => {
  const { data: run } = useRun(boardId, runId);
  const isArchived = !!run?.archived;
  const archiveMutation = useArchive(
    boardId,
    runId,
    run && `You are about to archive run "${run!.name}"`
  );
  return isArchived ? (
    <UnarchiveButton boardId={boardId} runId={runId} classes={classes} />
  ) : (
    <AuthorizedContent requiredPermissions={[PlatformPermission.RUNS_CREATE]}>
      <Tooltip title="Archive">
        <Button
          classes={{
            text: classes.buttonLabel,
          }}
          variant="outlined"
          onClick={() => archiveMutation.mutateAsync()}
          sx={{
            maxWidth: "48px",
            minWidth: "48px",
            height: "40px",
            color: "text.primary",
            borderColor: "text.secondary",
          }}
        >
          <InboxIcon />
        </Button>
      </Tooltip>
    </AuthorizedContent>
  );
};

export default ArchiveButton;
