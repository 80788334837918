import { createTheme } from "@mui/material/styles";
import theme from "./light";

const paletteDark = {
  background: { default: theme.palette.secondary.main },
  backgrounds: { secondary: theme.palette.brand.secondary },
  text: {
    primary: "#fff",
    link: "#fff",
  },
};

const componentsDark = {
  MuiListItemButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          ".MuiListItemText-primary, .MuiSvgIcon-root, .MuiTypography-root": {
            color: "#FFF",
          },
          color: "#FFF",
          ".MuiListItemIcon-root": { fill: "#FFF" },
          background: "rgba(188, 156, 242, 0.16)",
          ":hover": {
            background: "rgba(188, 156, 242, 0.1)",
          },
        },
        ".MuiTypography-root, .MuiListItemText-primary": {
          color: theme.palette.text.info,
        },
        color: theme.palette.text.info,
        ":hover": {
          background: "rgba(188, 156, 242, 0.08)", // theme.palette.brand.secondary,
        },
        ":focus": {
          ".MuiListItemIcon-root": { fill: "#FFF" },
          ".MuiSvgIcon-root, .MuiListItemText-primary, .MuiTypography-root": {
            color: "#FFF",
          },
          background: "rgba(188, 156, 242, 0.16)",
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        background: paletteDark.background.default,
        borderLeft: `1px solid ${theme.palette.primaryPartial[800]} !important`,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: { width: "20px", height: "20px" },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: theme.palette.text.info,
        "&.Mui-selected": {
          background: "rgba(188, 156, 242, 0.16)",
          color: "#fff",
          ":hover": {
            background: "rgba(188, 156, 242, 0.1)",
          },
        },
        ":hover": {
          background: "rgba(188, 156, 242, 0.08)", // theme.palette.brand.secondary,
        },
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: "contained" },
        style: {
          fill: "white",
          background: theme.palette.primaryPartial[600],
          ":hover": {
            background: theme.palette.primaryPartial[500],
          },
          ":active": {
            background: theme.palette.primaryPartial[400],
          },
        },
      },
      {
        props: { variant: "text", color: "secondary" },
        style: {
          ":hover": {
            background: "rgba(188, 156, 242, 0.1)",
          },
          ":active": {
            background: "rgba(188, 156, 242, 0.15)",
          },
        },
      },
      {
        props: { variant: "text", color: "primary" },
        style: {
          ":hover": {
            background: theme.palette.brand.secondary,
          },
          ":active": {
            background: theme.palette.primaryPartial[400],
          },
          ":focus": {
            border: `${theme.spacing(0.375)}px solid ${
              theme.palette.primary.light
            }`,
          },
          background: theme.palette.primaryPartial[600],
          color: "#fff",
        },
      },
    ],
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: "rgba(255,255,255,0.1)",
        border: "1px solid transparent",
        ":hover, &.Mui-focused:hover": {
          boxSizing: "",
          border: `1px solid ${theme.palette.borders.hover}`,
          backgroundColor: "rgba(255,255,255,0.1)",
        },
        ":active": {
          border: "1px solid #7B61FF",
          backgroundColor: "rgba(255,255,255,0.1)",
        },
        ":focus": {
          border: "1px solid #7B61FF",
          background: "red",
        },
        "&.Mui-focused": {
          border: "1px solid #7B61FF",
          backgroundColor: "rgba(255,255,255,0.1)",
        },
      },
    },
  },
};

export const darkModeTheme = createTheme(theme, {
  palette: paletteDark,
  components: componentsDark,
  typography: { color: "#fff" },
});

export default darkModeTheme;
