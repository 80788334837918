import StarIcon from "@mui/icons-material/Star";
import { Button, Tooltip } from "@mui/material";
import { FC } from "react";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformPermission, Status } from "../../../../enums";
import { useRun } from "../../../../services/runs";
import usePublishedRun from "../hooks/usePublishedRun";
import usePublishOnClick from "../hooks/usePublishOnClick";
import { RunHeaderButtonProps } from "../types";

const PublishButton: FC<RunHeaderButtonProps> = ({
  boardId,
  runId,
  classes,
}) => {
  const { data: run } = useRun(boardId, runId);
  const onPublishClick = usePublishOnClick(boardId, runId);
  const publishedRun = usePublishedRun(boardId);
  const runStatusSuccess = run && run.status === Status.SUCCEEDED;
  const isPublishedRun = publishedRun.data?.run_id === run?.id;
  return (
    <AuthorizedContent requiredPermissions={[PlatformPermission.RUNS_CREATE]}>
      <Tooltip
        title={
          runStatusSuccess
            ? isPublishedRun
              ? "This run is already published"
              : "Publish"
            : "Disabled if run hasn't succeeded"
        }
      >
        <span>
          <Button
            variant="outlined"
            classes={{
              text: runStatusSuccess
                ? classes.buttonLabel
                : classes.buttonLabelDisabled,
            }}
            onClick={onPublishClick}
            disabled={!runStatusSuccess || isPublishedRun}
            sx={{
              maxWidth: "48px",
              minWidth: "48px",
              height: "40px",
              color: "text.primary",
              borderColor: "text.secondary",
            }}
          >
            <StarIcon />
          </Button>
        </span>
      </Tooltip>
    </AuthorizedContent>
  );
};

export default PublishButton;
