import {FC, useState} from "react";
import {Box, Button, Skeleton, Stack, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useMutation, useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import ProductsTable from "./ProductsTable";
import ProductsHeader from "../ProductsHeader";
import Filters, {CURRENT_SELECTION_FILTER} from "./Filters";
import {useEcommerceProducts, useOnboardingStatus,} from "../../../services/ecommerce";
import useDataSources from "../../../services/datasources/useDataSources";
import {ProductsFilter, ProductsFilterConfiguration,} from "../../../types/ecommerce";
import FiltersSelectV2 from "./FiltersSelectV2";
import {GetProductsResponse} from "../../../services/ecommerce/useEcommerceProducts";
import useProductsCategories from "../../../services/ecommerce/useCategories";
import useProductsBrands from "../../../services/ecommerce/useProductsBrands";
import {addProductsFilters} from "../../../services/ecommerce/api";
import {CATEGORIES_SEPARATOR} from "./FilterCategories";
import useDataSourcesProductsCount from "../../../services/datasources/useDataSourceProductsCount";
import {TableHeader} from "../../../components/TableHeader";
import AmazonProductProvider, {useAmazonProductContext,} from "./AmazonProductProvider";
import {useSplitEnabled} from "../../../hooks";
import {Split} from "../../../FeatureFlags/enums";
import {FiltersSelect} from "./FiltersSelect";

export const ALL_PRODUCTS_FILTER: ProductsFilter = {
    id: "ALL_PRODUCTS",
    name: "All products",
    configuration: {},
};

export const createCategoriesTreePermutations = (category: string): string[] =>
    category
        .split(CATEGORIES_SEPARATOR)
        .reduce(
            (acc, category) => [
                ...acc,
                ...acc.map((cat) => [cat, category].join(CATEGORIES_SEPARATOR)),
            ],
            [] as string[]
        );

export const useAllProductsFilter = () => {
  const { data: dataSources = [] } = useDataSourcesProductsCount();
  const { data: productsBrands } = useProductsBrands();
  const { data = [] } = useProductsCategories();
  const { data: allProducts } = useEcommerceProducts(0, 1);

  return {
    ...ALL_PRODUCTS_FILTER,
    configuration: {
      data_sources_ids: dataSources.map(([_c, dsId]) => dsId),
      categories: data.map(([_count, categoryString]) => categoryString),
      brands: productsBrands?.map(([_c, brand]) => brand),
    },
    total: allProducts?.total,
  };
};

export const AddProductsButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    color="primary"
    size="medium"
    variant="contained"
    startIcon={<AddIcon />}
    onClick={() => {
      onClick();
    }}
  >
    <Typography noWrap color={(theme) => theme.palette.text.dark}>
      Add products
    </Typography>
  </Button>
);

const pageStep = 10;

const EcommerceProducts: FC = () => {
  const client = useQueryClient();
  const [pageSize, setPageSize] = useState<number>(pageStep);
  const { currentFilter, setFilter, productsFilters, allFilters } =
    useAmazonProductContext();
  const { data: dataSources } = useDataSources();
  const {
    data: onboardingStatus,
    isLoading: isOnboardingStatusLoading,
    refetch: refetchOnbordingStatus,
  } = useOnboardingStatus({ enabled: !!dataSources });

  const snackbar = useSnackbar();
  const mutation = useMutation((pf: Omit<ProductsFilter, "id">) =>
    addProductsFilters(pf)
  );
  const { data: allProducts } = useEcommerceProducts(0, 1);

  const {
    data: filteredProductsResponse,
    isLoading,
    error,
    refetch,
  } = useEcommerceProducts(
    0,
    pageSize,
    {
      enabled: !!dataSources && !!productsFilters,
    },
    {
      ...currentFilter?.configuration,
      categories:
        currentFilter?.configuration?.categories?.length !==
        allFilters.configuration.categories?.length
          ? currentFilter?.configuration?.categories
          : [],
      brands:
        currentFilter?.configuration?.brands?.length !==
        allFilters.configuration.brands?.length
          ? currentFilter?.configuration?.brands
          : [],
      data_sources_ids:
        currentFilter?.configuration?.data_sources_ids?.length !==
        allFilters.configuration.data_sources_ids?.length
          ? currentFilter?.configuration?.data_sources_ids
          : [],
    }
  );

  const productsResponse: GetProductsResponse | undefined =
    currentFilter?.configuration &&
    ProductsFilterConfiguration.configHasEmptyFields(
      currentFilter?.configuration
    )
      ? { total: 0, items: [] }
      : filteredProductsResponse;

  const currentSelectedFilterOption = currentFilter
    ? {
        filter: currentFilter,
        label:
          currentFilter.name +
          (productsResponse?.total != null
            ? ` (${productsResponse?.total})`
            : ""),
      }
    : {
        filter: allFilters,
        label:
          allFilters.name +
          (productsResponse?.total != null
            ? ` (${productsResponse?.total})`
            : ""),
      };
  return (
    <Stack
      sx={{
        mt: 4,
        mx: 4,
      }}
      spacing={2}
      direction="row"
    >
      <Filters
        defaultFilter={currentSelectedFilterOption.filter}
        isLoading={mutation.isLoading}
        onSave={(filter) => {
          mutation
            .mutateAsync({
              ...filter,
              configuration: ProductsFilterConfiguration.isEqual(
                filter.configuration,
                allFilters.configuration
              )
                ? {}
                : filter.configuration,
            })
            .then((pf) => {
              snackbar.enqueueSnackbar(
                `Product list ${pf.name} added successfully`,
                {
                  variant: "success",
                }
              );
              client.refetchQueries(["GetProductsFilters"]);
              setFilter(pf);
              return refetch();
            })
            .catch((e) =>
              snackbar.enqueueSnackbar(
                `Failed to create product list, ${e.message}`,
                { variant: "error" }
              )
            );
        }}
        onChange={(f) =>
          setFilter({
            ...CURRENT_SELECTION_FILTER,
            configuration: f.configuration,
          })
        }
        isCurrentSelectionAllProducts={(f) =>
          ProductsFilterConfiguration.isEqual(
            f.configuration,
            allFilters.configuration
          )
        }
        onAll={() => {
          const nextFilter = {
            ...CURRENT_SELECTION_FILTER,
            configuration:
              currentSelectedFilterOption?.filter.configuration &&
              ProductsFilterConfiguration.isEqual(
                currentSelectedFilterOption?.filter.configuration,
                allFilters.configuration
              )
                ? {}
                : allFilters.configuration,
          };
          setFilter(nextFilter);
        }}
      />
      <Stack flex={1} spacing={1}>
        <ProductsHeader
          isLoading={isOnboardingStatusLoading}
          data={onboardingStatus?.per_account_status || []}
          total_products={onboardingStatus?.total_products || 0}
        />
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <TableHeader
            title="Products"
            subTitle={
              <Typography
                variant="subtitle2"
                sx={{
                  color: (t) => t.palette.grey[300],
                  display: "flex",
                }}
              >
                {productsResponse?.total != null ? (
                  productsResponse?.total
                ) : (
                  <Skeleton width={25} />
                )}
                /{allProducts?.total}
              </Typography>
            }
          />
          {!useSplitEnabled(Split.CREATE_ASINS_FILTER) ? (
            <FiltersSelect
                value={currentSelectedFilterOption}
                onChange={(_e: any, f: any) => {
                    setFilter(f?.filter);
                }}
                onDelete={(filterId) => {
                    if (currentSelectedFilterOption.filter.id === filterId) {
                        setFilter(null);
                    }
                }}
                filters={[allFilters, ...(productsFilters || [])]}
            />
          ) : (
            <FiltersSelectV2 />
          )}
        </Stack>
        <Box sx={{ maxWidth: currentFilter && "calc(100vw - 400px)" }}>
          <ProductsTable
            emptyText={
              !allProducts?.total
                ? 'No products found. To add new products click the "Add products" button.'
                : "No products in current selection."
            }
            data={productsResponse}
            isLoading={isLoading}
            error={error}
            onNextPage={() => setPageSize(pageSize + pageStep)}
            refetchProducts={() => {
              refetch();
              refetchOnbordingStatus();
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default () => (
  <AmazonProductProvider>
    <EcommerceProducts />
  </AmazonProductProvider>
);
