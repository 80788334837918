import {
  Checkbox,
  FormControlLabel,
  Grow,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { Search } from "../../components/SearchInput/Search";

interface MultiChoiceSelectProps {
  open: boolean;
  choiceList: string[];
  selectedChoices: string[];
  setSelectedChoices: (choices: string[]) => void;
}

const MultiChoiceSelect: FC<MultiChoiceSelectProps> = (props) => {
  const { open, choiceList, selectedChoices, setSelectedChoices } = props;
  const anchorRef = useRef<HTMLButtonElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const slicedChoiceList = Array.isArray(choiceList)
    ? choiceList?.length > 100
      ? choiceList.slice(0, 99)
      : choiceList
    : [];

  const [filteredChoices, setFilteredChoices] = useState([...slicedChoiceList]);

  const [selected, setSelected] = useState<string[]>([]);
  const [query, setQuery] = useState<string>("");

  const unselectedChoices = slicedChoiceList?.filter(
    (choice) =>
      !selectedChoices?.some((selectedChoice) => choice === selectedChoice)
  );

  useEffect(() => {
    setFilteredChoices([...selectedChoices, ...unselectedChoices]);
    setSelected(selectedChoices || []);
    setQuery("");

    if (open) {
      dropDownRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [open]);

  const onChoiceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    choice: string
  ) => {
    if (e.target.checked) {
      setSelected([...selected, choice]);
    } else {
      setSelected(
        selected.filter((choiceItem: string) => choice !== choiceItem)
      );
    }
  };

  useEffect(() => {
    setSelectedChoices([...selected]);
  }, [selected]);

  useEffect(() => {
    if (query) {
      const filteredResults = choiceList.filter((category: any) =>
        category?.toLowerCase().includes(query?.toLowerCase())
      );
      setFilteredChoices([...filteredResults]);
    }
  }, [query]);

  const onChoiceChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected([...filteredChoices]);
    } else {
      setSelected([]);
    }
  };

  const isSelectedAll =
    filteredChoices?.length > 0 && selected?.length === filteredChoices?.length;

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-start"
      transition
      sx={{
        position: "absolute !important",
        top: "100% !important",
        zIndex: 1,
        minWidth: "315px",
      }}
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
          ref={dropDownRef}
        >
          <Paper
            sx={{
              paddingTop: "0.5rem",
            }}
          >
            <ListSubheader
              sx={{
                padding: 0,
                height: "40px",
                margin: "0 0.8rem",
              }}
            >
              <Search
                fullWidth
                placeholder="Search"
                sx={{
                  height: "100%",
                  fontSize: "15px",
                  verticalAlign: "middle",
                }}
                onChange={(e: any) => setQuery(e.target.value)}
                value={query}
              />
            </ListSubheader>
            <MenuList
              // autoFocusItem={open}
              id="composition-menu"
              aria-labelledby="composition-button"
              sx={{
                maxHeight: "226px",
                overflow: "auto",
              }}
              // onKeyDown={handleListKeyDown}
            >
              {Array.isArray(filteredChoices) &&
                filteredChoices?.length > 0 &&
                filteredChoices?.length === slicedChoiceList?.length && (
                  <MenuItem
                    disableRipple
                    sx={{
                      padding: "0",
                      height: "30px",
                      minHeight: "30px",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSelectedAll}
                          onChange={(e) => onChoiceChangeAll(e)}
                        />
                      }
                      sx={{
                        width: "100%",
                        margin: "0",
                        paddingRight: "1rem",
                      }}
                      label="Select all"
                    />
                  </MenuItem>
                )}
              {Array.isArray(filteredChoices) && filteredChoices?.length > 0 ? (
                filteredChoices?.map((choice: string) => {
                  const isChecked = selected.some(
                    (choiceItem: string) => choiceItem === choice
                  );

                  if (!choice) {
                    return;
                  }

                  return (
                    <MenuItem
                      key={choice}
                      disableRipple
                      sx={{
                        padding: "0",
                        height: "30px",
                        minHeight: "30px",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) => onChoiceChange(e, choice)}
                          />
                        }
                        sx={{
                          width: "100%",
                          margin: "0",
                          paddingRight: "1rem",
                        }}
                        label={choice}
                      />
                    </MenuItem>
                  );
                })
              ) : (
                <Typography>No choices found</Typography>
              )}
            </MenuList>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default MultiChoiceSelect;
