import * as React from "react";
import {
  addMonths,
  addYears,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  max,
  min,
  subMonths,
  // subYears,
} from "date-fns";
import {
  CustomStyle,
  DateRange,
  DefinedRange,
  NavigationAction,
} from "../types";
import { getValidatedMonths, parseOptionalDate } from "../utils";
import { getDefaultRanges } from "../defaults";
import Menu from "./Menu";
// import { Marker, MARKERS } from "./Markers";

interface DateRangePickerProps {
  open: boolean;
  initialDateRange?: DateRange;
  currentDateRange?: DateRange | null;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  setDateRange: (range: DateRange) => void;
  dateRange: DateRange;
  onChange: (dateRange: DateRange) => void;
  locale?: Locale;
  labelIcon?: React.ReactNode;
  onSave?: () => void;
  onCancel?: () => void;
  customStyle?: CustomStyle;
  showConfirmSection?: boolean;
  showBorderedDate?: boolean;
  showNoComparison?: boolean;
  showDefinedRanges?: boolean;
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  const today = new Date();

  const {
    open,
    onChange,
    initialDateRange,
    currentDateRange,
    minDate,
    maxDate,
    definedRanges = getDefaultRanges(new Date(), props.locale),
    locale,
    labelIcon,
    onSave,
    onCancel,
    customStyle,
    showConfirmSection,
    showBorderedDate,
    dateRange,
    setDateRange,
    showNoComparison,
    showDefinedRanges,
  } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));
  const [
    intialFirstMonth,
    // initialSecondMonth
  ] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid);

  // const [dateRange, setDateRange] = React.useState<DateRange>({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(
    intialFirstMonth || today
  );
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    addMonths(firstMonth, 1)
  );

  const { startDate, endDate } = dateRange;

  // handlers
  const setFirstMonthValidated = (date: Date) => {
    setFirstMonth(date);
    setSecondMonth(addMonths(date, 1));
  };

  const setSecondMonthValidated = (date: Date) => {
    setSecondMonth(date);
    setFirstMonth(subMonths(date, 1));
  };

  React.useEffect(() => {
    if (
      currentDateRange &&
      currentDateRange.startDate &&
      currentDateRange.endDate
    ) {
      let { startDate: newStart, endDate: newEnd } = currentDateRange;
      currentDateRange.startDate = newStart = max([newStart, minDateValid]);
      currentDateRange.endDate = newEnd = min([newEnd, maxDateValid]);

      setDateRange(currentDateRange);
      onChange(currentDateRange);

      setFirstMonth(newStart);
      setSecondMonth(addMonths(newStart, 1));
      // setSecondMonth(
      //   isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd
      // );
    }
  }, [currentDateRange]);

  const setDateRangeValidated = (range: DateRange) => {
    let { startDate: newStart, endDate: newEnd } = range;

    if (newStart && newEnd) {
      range.startDate = newStart = max([newStart, minDateValid]);
      range.endDate = newEnd = min([newEnd, maxDateValid]);

      setDateRange(range);
      onChange(range);

      setFirstMonth(newStart);
      setSecondMonth(
        isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd
      );
    } else {
      const emptyRange = {};

      setDateRange(emptyRange);
      onChange(emptyRange);

      setFirstMonth(today);
      setSecondMonth(addMonths(firstMonth, 1));
    }
  };

  const onDayClick = (day: Date) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (action: NavigationAction) => {
    setFirstMonth(addMonths(firstMonth, action));
    setSecondMonth(addMonths(secondMonth, action));
  };

  const onDayHover = (date: Date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  // helpers
  const inHoverRange = (day: Date) =>
    (startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinInterval(day, { start: startDate, end: hoverDay })) as boolean;

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  const handleCancel = () => {
    setDateRange({ ...currentDateRange });
    !!onCancel && onCancel();
  };

  return open ? (
    <Menu
      dateRange={dateRange}
      minDate={minDateValid}
      maxDate={maxDateValid}
      ranges={definedRanges}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      setFirstMonth={setFirstMonthValidated}
      setSecondMonth={setSecondMonthValidated}
      setDateRange={setDateRangeValidated}
      helpers={helpers}
      handlers={handlers}
      locale={locale}
      labelIcon={labelIcon}
      onSave={onSave}
      onCancel={handleCancel}
      customStyle={customStyle}
      showConfirmSection={showConfirmSection}
      showBorderedDate={showBorderedDate}
      showNoComparison={showNoComparison}
      showDefinedRanges={showDefinedRanges}
    />
  ) : null;
};

export default DateRangePicker;
