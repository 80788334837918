import { useQuery, UseQueryOptions } from "react-query";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { BoardTemplate } from "../types/board-templates";
import { APIError } from "../services/apiRequest";
import { getBoardTemplate } from "../services/circuitboards";

export const useBoardTemplate = (
  boardTemplateId?: string,
  options?: UseQueryOptions<BoardTemplate, APIError>
): { data?: BoardTemplate; isLoading?: boolean; error?: APIError } => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const {
    data: boardTemplate,
    isLoading: isBoardTemplateLoading,
    error: boardTemplateError,
  } = useQuery<BoardTemplate, APIError>(
    ["getBoardTemplateById", boardTemplateId],
    () => getBoardTemplate(boardTemplateId!),
    {
      enabled: !!boardTemplateId,
      retry: (_, response) =>
        response.statusCode !== 404 && response.statusCode !== 500,
      refetchOnWindowFocus: false,
      onError: () => {
        snackbar.enqueueSnackbar(
          "Error getting block template data. Please access support, support@noogata.com",
          { variant: "error" }
        );
        history.push("/marketplace");
      },
      ...options,
    }
  );

  return {
    data: boardTemplate,
    isLoading: boardTemplate?.id !== boardTemplateId || isBoardTemplateLoading,
    error: boardTemplateError!,
  };
};
