import { Box, Paper } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ImageIcon from "@mui/icons-material/Image";
import theme from "../../../../themes/light";

const SpaceImage = ({ url }: { url?: string }) => {
  const ImageComponent = ({ size = "medium" }: { size?: string }) =>
    url ? (
      <Box
        component="img"
        alt="Product for the opportunity"
        src={url}
        sx={{
          border: `1px solid ${theme.palette.borders.lines}`,
          background: "#fff",
          borderRadius: "8px",
          padding: "7px",
          width: size === "medium" ? "80px" : size === "small" ? "50px" : "200px",
          height: size === "medium" ? "80px" : size === "small" ? "50px" : "200px",
        }}
      />
    ) : (
      <ImageIcon
        sx={{
          height: "auto",
          width: "auto",
          objectFit: "contain",
          padding: 10,
        }}
      />
    );

  return (
    <Tooltip
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
          },
        },
      }}
      title={
        <Paper variant="elevation" elevation={5}>
          <ImageComponent size="large" />
        </Paper>
      }
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.borders.lines}`,
          background: "#fff",
          borderRadius: "8px",
          padding: "7px",
          width: "80px",
          height: "80px",
        }}
      >
        <ImageComponent />
      </Box>
    </Tooltip>
  );
};

export default SpaceImage;
