import { FC, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthorizedUsers } from "../../../../hooks/useUsers";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Stack,
  // SxProps,
  Typography,
  useAutocomplete,
} from "@mui/material";
import { AutoCompleteRenderInput } from "../../AutoCompleteRenderInput";
import { FrontEggTeamMember } from "../../types";
// import { CrossIcon } from "../../../../assets";

const dialogStyle = {
  position: "relative",
};

export const AssignTeamMember: FC<{
  type: string;
  handleChange: (value: string) => void;
  selectedEmail?: string;
  setSelectedEmail?: any;
}> = ({ handleChange, type, selectedEmail, setSelectedEmail }) => {
  const allMembersList = useAuthorizedUsers();
  const { enqueueSnackbar } = useSnackbar();
  const allMembers = allMembersList?.filter(
    (el) => !el.email.includes("@noogata.com")
  );

  return (
    <Box sx={dialogStyle}>
      <Stack direction="row" spacing={1} alignItems="center">
        <MembersAutocomplete
          multiple={false}
          assignedMembers={[]}
          options={allMembers}
          onInvite={async (selectedMembers: any) => {
            try {
              handleChange(selectedMembers);
            } catch (e) {
              enqueueSnackbar("Could not assign task", {
                variant: "error",
              });
            }
          }}
          type={type}
          handleChange={handleChange}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
        />
      </Stack>
    </Box>
  );
};

const MembersAutocomplete: FC<any> = ({
  options,
  assignedMembers,
  onInvite,
  handleChange,
  type,
  selectedEmail,
  setSelectedEmail,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [clearedOnce, setClearedOnce] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] =
    useState<FrontEggTeamMember[]>(assignedMembers);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const mergedMembers = [...assignedMembers, ...selectedMembers];
    const membersToKeep = [...new Set(mergedMembers.map((m) => m.id))]
      .map((mid) => mergedMembers.find((mm) => mm.id === mid))
      .filter((m) => !!m) as FrontEggTeamMember[];

    setSelectedMembers(membersToKeep || []);
  }, [assignedMembers]);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete<FrontEggTeamMember, true, any, any>({
    ...props,
    id: "use-autocomplete-demo",
    value: selectedMembers,
    options,
    defaultValue: assignedMembers,
    open: true,
    inputValue,
    // @ts-ignore
    getOptionLabel: (option) => option?.name || "",
    onChange: (_event, value: FrontEggTeamMember) => {
      const email = value?.email;
      const memberData = Array.isArray(value) ? value : value ? [value] : [];
      if (type === "create") {
        handleChange(email);
      }
      setSelectedMembers(memberData as FrontEggTeamMember[]);
      setInputValue(value?.name);
      if (setSelectedEmail) {
        setSelectedEmail("");
      }
      if (type === "create") {
        setIsOpen(false);
      }
    },
    filterOptions: (options) => {
      if (selectedEmail) {
        return options.filter((el) => el?.email !== selectedEmail);
      } else {
        const filterRegex = new RegExp(inputValue, "i");
        return options.filter(
          (option) =>
            !selectedMembers?.find((am) => am.id === option.id) &&
            (filterRegex.test(option.name) || filterRegex.test(option.email))
        );
      }
    },
  });

  const rootProps = getRootProps() as any;
  const { value: _v, ...inputProps } = getInputProps() as any;
  const listProps = getListboxProps() as any;

  const handleFocus = () => {
    setIsOpen(true);
  };
  const getAssignInfo = (email: string) => {
    if (options?.length > 0) {
      const userData = options?.find((el: any) => el.email === email);
      const { profileImage, name } = userData || {};
      const assignName = name;
      return { profileImage, name: assignName };
    }
  };
  useEffect(() => {
    if (selectedEmail) {
      const data = getAssignInfo(selectedEmail);
      if (data) {
        setInputValue(data?.name);
      }
    }
  }, [selectedEmail]);
  useEffect(() => {
    if (type === "create") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [type]);

  // const handleClearFilter = () => {
  //   setInputValue("");
  //   setSelectedEmail("");
  // };

  const handleKeyDown = (event: any) => {
    if (event.key === "Backspace" && !clearedOnce) {
      setInputValue("");
      setClearedOnce(true);
    }
  };
  return (
    <Stack spacing={3} sx={{ width: "100%", minHeight: "auto" }}>
      <Stack direction="column" {...rootProps}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <Box sx={{ width: "calc(100% - 0px)", position: "relative" }}>
            <AutoCompleteRenderInput
              params={{ placeholder: "Name or email" }}
              inputProps={{
                ...inputProps,
                value: inputValue,
                onChange: (event: any) => {
                  const newValue = event.target.value.trim(); // Remove white space
                  setInputValue(newValue);
                  inputProps.onChange(event);
                  setIsOpen(true);
                },
                onKeyDown: handleKeyDown,
                onFocus: () => handleFocus(),
                autoFocus: true,
              }}
            />
            {inputValue && !clearedOnce && (
              <Box
                style={{
                  background: "transparent",
                  width: "fit-content",
                  position: "absolute",
                  top: "4px",
                  height: "85%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  left: "36px",
                  letterSpacing: "1.4px",
                }}
                autoFocus={true}
              >
                <Box
                  style={{
                    background: "#2c224c",
                    height: "auto",
                    width: "auto",
                    color: "white",
                  }}
                >
                  {inputValue}
                </Box>
              </Box>
            )}
          </Box>
          {/* <Box
            display={"flex"}
            sx={{ cursor: "pointer" }}
            onClick={handleClearFilter}
          >
            <CrossIcon />
          </Box> */}
        </Box>
        {isOpen && groupedOptions?.length > 0 ? (
          <Stack
            spacing={1}
            sx={{
              overflow: "auto",
              position: type === "create" ? "absolute" : "initial",
              zIndex: 9999,
              background: type === "create" ? "white" : "transparent",
              width: "100%",
              // margin: "0 !important",
              left: 0,
              maxHeight: "260px",
              top: type === "create" ? "48px" : 0,
              // padding: "0",
              boxShadow:
                type === "create"
                  ? "0px 4px 8px rgba(44, 34, 76, 0.04), 0px 1px 3px rgba(44, 34, 76, 0.12)"
                  : "none",
              borderRadius: type === "create" ? "8px" : 0,
              marginTop: type === "create" ? 2 : 0,
            }}
          >
            <Stack sx={{ overflow: "auto", margin: 0 }}>
              <List {...listProps} disablePadding sx={{ p: 0 }}>
                {(groupedOptions as typeof options).map(
                  (option: any, index: number) => {
                    const itemProps = getOptionProps({ option, index }) as any;
                    return (
                      <>
                        {type === "reassign" ? (
                          <ListItem
                            key={option.id}
                            disablePadding
                            {...itemProps}
                            onClick={() => onInvite(option?.email)}
                          >
                            <ListItemButton sx={{ padding: "12px" }}>
                              <ListItemAvatar
                                sx={{ minWidth: "auto", mr: "12px" }}
                              >
                                <Avatar
                                  alt={option.name}
                                  src={option.profileImageUrl}
                                />
                              </ListItemAvatar>
                              <Typography variant="body1">
                                {option.name}
                              </Typography>
                            </ListItemButton>
                          </ListItem>
                        ) : (
                          <ListItem
                            key={option.id}
                            disablePadding
                            {...itemProps}
                            // onClick={() => handleClose()}
                          >
                            <ListItemButton sx={{ padding: "12px" }}>
                              <ListItemAvatar
                                sx={{ minWidth: "auto", mr: "12px" }}
                              >
                                <Avatar
                                  alt={option.name}
                                  src={option.profileImageUrl}
                                />
                              </ListItemAvatar>
                              <Typography variant="body1">
                                {option.name}
                              </Typography>
                            </ListItemButton>
                          </ListItem>
                        )}
                      </>
                    );
                  }
                )}
              </List>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};
