import { FC } from "react";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { Button } from "@mui/material";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { UpdateFlow } from "../../services/Flows/api";
import useFlow from "../../services/Flows/useFlow";

export const UpdateFlowButton: FC<{ flowId: string }> = ({ flowId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const deleteMutation = useMutation((flowId: string) => UpdateFlow(flowId));
  const { refetch } = useFlow(flowId);
  const handleUpdate = async () => {
    try {
      await deleteMutation.mutateAsync(flowId).then(() => refetch());
      enqueueSnackbar("Successfully updated flow", {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar("Unable to update flow", { variant: "error" });
    }
  };
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleUpdate}
      startIcon={<AccountTreeOutlinedIcon />}
      disabled={deleteMutation.isLoading}
    >
      Update Flow Template
    </Button>
  );
};
