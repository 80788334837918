import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ReactMarkdown from "react-markdown";
import { useQuery } from "react-query";
import {
  Column,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";
import Loadable from "../../components/Loadable";
import { UncontrolledTable } from "../../components";
import { APIError } from "../../services/apiRequest";
import { getMessages } from "../../services/tracking";
import { MessageRead, Run, Severity } from "../../types";
import { formatDateString, prettifyString } from "../../utils";

import markdownToTxt from "markdown-to-txt";
import { FC, useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "100%",
    maxWidth: "calc(100vw - 64px)",
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  error: {
    backgroundColor: theme.palette.error.main,

    color: theme.palette.error.contrastText,
  },
  row: {
    verticalAlign: "top",
  },
  messageText: {
    lineHeight: 1.5,
    whiteSpace: "pre-line",
  },
}));

const getMarkdownInnerText = (markdown: string): string =>
  markdownToTxt(markdown);

export interface MessagesTableProps {
  run: Run | null;
}

const MessagesTable: FC<MessagesTableProps> = ({ run }) => {
  const classes = useStyles();
  const { data, isLoading, error } = useQuery<MessageRead[], APIError>(
    ["messages", run?.id || ""],
    () => getMessages(run?.id || ""),
    { enabled: !!run }
  );

  const theme = useTheme();

  const columns: Column<object>[] = useMemo(
    () => [
      {
        Header: "",
        id: "__colapse_content",
        accessor: "message",
        Cell: ({ value, ...props }: any) => {
          const { formatting } = props.cell.row.original as MessageRead;
          return formatting === "markdown" ? (
            <ReactMarkdown>{value.replaceAll("\\n", "\n")}</ReactMarkdown>
          ) : (
            <Box padding={1}>
              <Typography variant="body2" className={classes.messageText}>
                {value}
              </Typography>
            </Box>
          );
        },
        width: theme.spacing(5),
      },
      {
        Header: "Time",
        accessor: "timestamp",
        Cell: ({ value }: any) => (
          <Typography variant="body2" noWrap>
            {formatDateString(value)}{" "}
          </Typography>
        ),
      },
      {
        Header: "Severity",
        accessor: "severity",
        minWidth: 60,
        Cell: ({ value }: any) => (
          <Chip
            style={{ height: 24 }}
            label={prettifyString(value)}
            className={classes[value as Severity]}
          />
        ),
      },
      {
        Header: "Step Name",
        accessor: "step_display_name",
        minWidth: 72,
        Cell: ({ value }: any) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
      {
        Header: "Message",
        accessor: "message",
        Cell: ({ value, ...props }: any) => {
          const { formatting } = props.cell.row.original as MessageRead;
          return (
            <Tooltip title={<Typography variant="caption">{value}</Typography>}>
              <Box maxHeight={115} overflow="hidden">
                <Typography variant="body2" className={classes.messageText}>
                  {formatting === "markdown"
                    ? getMarkdownInnerText(value).replaceAll("\\n", "\n")
                    : value}
                </Typography>
              </Box>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const instance = useTable(
    {
      columns,
      data: data || [],
      autoResetSortBy: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  return (
    <Loadable isLoading={isLoading} error={error}>
      {data?.length === 0 ? (
        <IllustrationPlaceholder
          imageHeight={300}
          imageWidth={300}
          text="No messages were recorded for this run."
        />
      ) : (
        <UncontrolledTable
          instance={instance}
          columns={columns}
          plugins={[useResizeColumns]}
          rowClassName={classes.row}
          rowsPerPageOptions={[50]}
          tableContainerProps={{ className: classes.container }}
          tableProps={{
            size: "small",
            stickyHeader: true,
            "aria-label": "a dense sticky table",
          }}
          data={data || []}
          isLoading={isLoading}
          error={error}
        />
      )}
    </Loadable>
  );
};

export default MessagesTable;
