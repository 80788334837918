import { Box } from "@mui/material";
import { RowData } from "../types";
import SpaceRow from "../Components/SpaceRow";
import ProductRow from "../Components/ProductRow";
import { useSalesAssistant } from "../SalesAssistantProvider";
import { useEffect, useRef } from "react";
import useOnScreen from "../../../hooks/useOnScreen";
import CustomDotLoader from "../../../components/CustomDotLoader";

const List = ({
  data,
  total,
  variant,
}: {
  data?: RowData[];
  total?: number;
  variant: string;
}) => {
  const { fetchNextPage, salesAssistantListFetching } = useSalesAssistant();

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible && !salesAssistantListFetching) {
      fetchNextPage();
    }
  }, [isVisible, salesAssistantListFetching, fetchNextPage]);

  const mappingTotal =
    total && total > 0 ? total : variant === "spaces" ? 5 : 10;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        overflowY: "auto",
        overflowX: "hidden",
        marginBottom: "20px",
      }}
    >
      {(data || Array.from({ length: mappingTotal })).map((item, index) =>
        variant === "spaces" ? (
          <SpaceRow key={`list-item-${index}`} data={item} />
        ) : variant === "asins" ? (
          <ProductRow key={`list-item-${index}`} data={item} />
        ) : null
      )}
      {(data?.length ?? 0) < (total ?? 0) && (
        <Box textAlign="center" padding="12px" ref={ref}>
          {salesAssistantListFetching && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CustomDotLoader />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default List;
