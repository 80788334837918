import { useAmplitude } from "react-amplitude-hooks";

export const useMarketplaceEvents = (globalProperties: any = {}) => {
  const { logEvent } = useAmplitude((inheritedProps: any = {}) => ({
    ...inheritedProps,
    ...globalProperties,
    scope: [
      "marketplace",
      ...(globalProperties.scope || []),
      ...(inheritedProps.scope || []),
    ],
  }));
  return {
    installBoardEvent: (properties?: object) =>
      logEvent("marketplace.installBoard", properties),
  };
};
