import {Chip} from "@mui/material";
import {FC} from "react"

interface TagProps {
    text: string;
    icon: any;
    disabled?: boolean;
}

const Tag: FC<TagProps> = ({text, icon, disabled}) => (
    <Chip
        size="small"
    disabled={disabled}
    sx={{
      backgroundColor: (theme) => theme.palette.primary.light,
      color: (theme) => theme.palette.primary.main,
      borderRadius: "20px",
    }}
    icon={icon}
    label={text}
  />
);

export { Tag };
