import { Box, Typography } from "@mui/material";

import { ConnectorType } from "../../../../enums";
import { PipelineInput, RunInputValue } from "../../../../types";
import ExternalDataSourceMapping from "../ExternalConnector/ExternalDataSourceMapping";
import { LocalMapping } from "../LocalFile/LocalMapping";
import { FC } from "react";

export type MappingProps = {
  input: PipelineInput;
  inputValue: RunInputValue;
  onSubmit: (mapping: Record<string, any>) => void;
  onBack: () => void;
  onError: (message: string) => void;
};

const Mapping: FC<MappingProps> = (props) => (
  <>
    <Box marginBottom={2}>
      <Typography variant="h6">3. Map input and Block&apos;s schema</Typography>
    </Box>
    {props.inputValue.type === ConnectorType.LOCAL ? (
      <LocalMapping {...props} />
    ) : (
      <ExternalDataSourceMapping {...props} />
    )}
  </>
);

export default Mapping;
