import { Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import theme from "../../../themes/light";
import { Icon } from "../../../components/Icon";
import { useCallback, useEffect, useState } from "react";
import { DefinedRange } from "../../../components/DateRangePicker/types";
import {
  genDateRangeParam,
  getParsedDate,
  parseDateRangeParam,
  useInsightChatContext,
} from "../hooks/selectors";
import { getCompareDateRanges, getDefaultDateRange } from "../defaults";
import { getOptionsData } from "../useChatApi";
import ChatFilterSkeleton from "./ChatFilterSkeleton";
import SelectFilter from "./SelectFilter";
import DateRangeFilter from "./DateRangeFilter";
import BrandedFilter from "./BrandedFilter";
import SpaceTrafficShareFilter from "./SpaceTrafficShareFilter";
import BrandsFilter from "./BrandsFilter";

interface ChatFilterProps {
  filterData: any;
  params: any;
  storyPointType: string;
  storyBoardIndex: number;
  onclose: () => void;
  setIsVisible: (value: boolean) => void;
}

export interface InitialDataRange {
  startDate: Date;
  endDate: Date;
}
const ChatFilter = ({
  filterData,
  params,
  storyPointType,
  storyBoardIndex,
  onclose,
  setIsVisible,
}: ChatFilterProps) => {
  const { addStoryPointToStoryboard } = useInsightChatContext();

  // Date range logic
  const [dateRange, setDateRange] = useState<DefinedRange>(
    getDefaultDateRange()
  );
  const [compareDateRange, setCompareDateRange] = useState<DefinedRange | null>(
    null
  );
  const [openDate, setOpenDate] = useState(false);
  const [openCompareDate, setOpenCompareDate] = useState(false);

  const handleDateRangeSelector = () => {
    setOpenDate(!openDate);
  };

  const handleCompareDateRangeSelector = () => {
    setOpenCompareDate(!openCompareDate);
  };
  const handleApplyDate = (range: DefinedRange, id: string) => {
    setDateRange({ ...range, id });
    if (compareDateRange && compareDateRange?.label) {
      setCompareDateRange(getCompareDateRanges(range)[0]);
    } else {
      setCompareDateRange(null);
    }
    handleDateRangeSelector();
  };

  const handleApplyCompareDate = (range: DefinedRange, id: string) => {
    setCompareDateRange({ ...range, id });
    handleCompareDateRangeSelector();
  };

  useEffect(() => {
    let updatedFilterParams = { ...filterParams };
    if (filterData?.params?.find((param: any) => param?.id === "date_range")) {
      if (dateRange) {
        updatedFilterParams = {
          ...updatedFilterParams,
          date_range: genDateRangeParam(dateRange),
        };
      }
      if (compareDateRange) {
        updatedFilterParams = {
          ...updatedFilterParams,
          compared_date_range: genDateRangeParam(compareDateRange),
        };
      }

      const { date_range, compared_date_range } = params;
      if (
        (date_range && !dateRange) ||
        (compared_date_range && !compareDateRange)
      ) {
        setAllowedApply(false);
      } else {
        setAllowedApply(true);
      }

      setFilterParams(updatedFilterParams);
    }
  }, [dateRange, compareDateRange]);

  // Brands logic
  const [brandOptionsList, setBrandOptionsList] = useState<any>([]);
  const [selectedBrands, setSelectedBrands] = useState<string>(""); // all_brands, my_brands, competitor_brands, custom [brand1, brand2, ...]

  const getBrandsUrl =
    filterData &&
    filterData?.params?.find((param: any) => param?.id === "brand")
      ?.get_options_url;

  useEffect(() => {
    if (getBrandsUrl) {
      getOptionsData(getBrandsUrl, params?.space_id).then((res) => {
        setBrandOptionsList(res);
      });
    }
  }, [getBrandsUrl]);

  useEffect(() => {
    if (selectedBrands) {
      setFilterParams({
        ...filterParams,
        brand: selectedBrands,
      });
    }
  }, [selectedBrands]);

  // Branded vs Unbranded logic
  const [selectedBranded, setSelectedBranded] = useState<string>(""); // branded, unbranded, all

  useEffect(() => {
    if (selectedBranded) {
      setFilterParams({
        ...filterParams,
        branded: selectedBranded,
      });
    }
  }, [selectedBranded]);

  // SpaceTrafficShare logic
  const [selectedSpaceTrafficShares, setSelectedSpaceTrafficShares] = useState<
    any[]
  >([]);

  useEffect(() => {
    if (selectedSpaceTrafficShares) {
      setFilterParams({
        ...filterParams,
        space_traffic_share: selectedSpaceTrafficShares.map((op) => op.value),
      });
    }
  }, [selectedSpaceTrafficShares]);

  // Period logic
  const [period, setPeriod] = useState<string>("");
  const [comparisonPeriod, setComparisonPeriod] = useState<string>("");

  useEffect(() => {
    if (period) {
      const newComparisonPeriod =
        period === "year_to_date" ? "previous_year" : "previous_period";
      setComparisonPeriod(newComparisonPeriod);
      setFilterParams({
        ...filterParams,
        period,
        comparison_period: newComparisonPeriod,
      });
    }
  }, [period]);

  useEffect(() => {
    if (comparisonPeriod) {
      setFilterParams({
        ...filterParams,
        comparison_period: comparisonPeriod,
      });
    }
  }, [comparisonPeriod]);

  // General logic
  const [filterParams, setFilterParams] = useState<any>({});
  const [allowedApply, setAllowedApply] = useState(true);

  // Default settings
  const getDefaultSettings = () => {
    setFilterParams(params);

    // Period
    setPeriod(params?.period); // Text or Number
    setComparisonPeriod(params?.comparison_period); // Text

    // Brands
    setSelectedBrands(params?.brand);

    // Branded vs Unbranded
    setSelectedBranded(params?.branded);

    // Date range
    const dateRange = parseDateRangeParam(
      params?.date_range || getDefaultDateRange()
    );
    setDateRange(getParsedDate(dateRange));
    const compareDateRange = parseDateRangeParam(params?.compared_date_range);
    setCompareDateRange(
      compareDateRange ? getParsedDate(compareDateRange) : null
    );

    // SpaceTrafficShare
    setSelectedSpaceTrafficShares(params?.space_traffic_share || []);
  };

  const getMemoizedDefaultsettings = useCallback(getDefaultSettings, [
    filterData?.params?.length,
    JSON.stringify(params),
  ]);

  useEffect(() => {
    getMemoizedDefaultsettings();
  }, [filterData?.params?.length, JSON.stringify(params)]);

  const handleApplyFilter = async () => {
    if (!allowedApply) return;
    setIsVisible(false);
    const filterOptionParam = Object.keys(filterParams)?.map((keyName) => ({
      id: keyName,
      value: filterParams[keyName],
    }));
    await addStoryPointToStoryboard(
      storyPointType,
      filterOptionParam,
      storyBoardIndex
    ).then(() => onclose());
  };

  // Filter box structure:
  // 1. Upper filters - single select: period (& comparison), date_range (& compare)
  const upperFiltersCount = filterData?.params?.filter(
    (prms: any) =>
      prms.id === "period" || // Including comparison_period
      prms.id === "date_range" // Including compared_date_range
  )?.length;
  const upperFilterSorter = (a: any, _: any) => {
    if (a.id === "period" || a.id === "comparison_period")
      return a.id === "period" ? -1 : 1;
    if (a.id === "date_range" || a.id === "compared_date_range")
      return a.id === "date_range" ? -1 : 1;
    return 0;
  };

  // 2. Lower filters - multi select: , branded_vs_unbranded, spaceTrafficShare
  const lowerFiltersCount = filterData?.params?.filter(
    (prms: any) =>
      prms.id === "brand" ||
      prms.id === "branded" ||
      prms.id === "space_traffic_share"
  )?.length;
  const lowerFilterSorter = (a: any, _: any) => {
    if (a.id === "brand") return -1;
    if (a.id === "branded") return 0;
    if (a.id === "space_traffic_share") return 1;
    return 0;
  };

  return !filterData ? (
    <ChatFilterSkeleton />
  ) : (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        minHeight: "100px",
      }}
      className="new-filter-container"
    >
      <Box
        className="all-filters-container"
        padding="0"
        sx={{
          display: "flex",
          padding: "0",
          width: "100%",
          gap: "22px",
          flexDirection: "column",
          "& .MuiInputLabel-root": {
            fontSize: "13px",
            fontWeight: 500,
            color: theme.palette.grey[500],
          },
          "& .MuiChip-label": {
            fontSize: "13px",
            fontWeight: 500,
            color: theme.palette.grey[500],
            padding: 0,
          },
          "& .MuiChip-filled": {
            background: theme.palette.grey[25],
            padding: "8px",
            width: "fit-content",
            borderRadius: "100px",
            minWidth: "32px",
          },
        }}
      >
        {upperFiltersCount > 0 && (
          <Box
            className="upper-filters"
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            {filterData?.params
              ?.filter((el: any) =>
                ["select", "date-picker"].includes(el?.input_type)
              )
              .sort(upperFilterSorter)
              .map((el: any) =>
                el.id === "period" || el.id === "comparison_period" ? (
                  <SelectFilter
                    element={el}
                    param={el.id === "period" ? period : comparisonPeriod}
                    setParam={
                      el.id === "period"
                        ? setPeriod
                        : el.id === "comparison_period"
                          ? setComparisonPeriod
                          : null
                    }
                  />
                ) : el.id === "date_range" ||
                  el.id === "compared_date_range" ? (
                  <DateRangeFilter
                    element={el}
                    openDate={
                      el.id === "date_range" ? openDate : openCompareDate
                    }
                    handleDateRangeSelector={
                      el.id === "date_range"
                        ? handleDateRangeSelector
                        : handleCompareDateRangeSelector
                    }
                    dateRange={
                      el.id === "date_range" ? dateRange : compareDateRange
                    }
                    handleApplyDate={
                      el.id === "date_range"
                        ? handleApplyDate
                        : handleApplyCompareDate
                    }
                  />
                ) : null
              )}
          </Box>
        )}
        {lowerFiltersCount > 0 && (
          <Box
            className="lower-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {filterData?.params
              ?.sort(lowerFilterSorter)
              .map((el: any) =>
                el?.id === "brand" ? (
                  <BrandsFilter
                    brandOptionsList={brandOptionsList}
                    brandTypeList={[
                      ...(el?.options || []),
                      { label: "Custom", value: "" },
                    ]}
                    selectedBrands={selectedBrands}
                    setSelectedBrands={setSelectedBrands}
                    setAllowedApply={setAllowedApply}
                  />
                ) : el?.id === "branded" ? (
                  <BrandedFilter
                    brandedOptionsList={el?.options}
                    selectedBranded={selectedBranded}
                    setSelectedBranded={setSelectedBranded}
                  />
                ) : el?.id === "space_traffic_share" ? (
                  <SpaceTrafficShareFilter
                    optionsList={el?.options}
                    setSelectedOptions={setSelectedSpaceTrafficShares}
                    setAllowedApply={setAllowedApply}
                  />
                ) : null
              )}
          </Box>
        )}
      </Box>
      <Box
        className="filter-side-buttons"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "30px",
            alignSelf: "flex-start",
          }}
        >
          <Tooltip
            onClick={() => onclose()}
            placement="top"
            title="Close"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              size="small"
              className="close-btn"
              sx={{
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "unset",
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.primary.dark,
                  },
                },
                display: "none",
              }}
            >
              <ClearIcon
                sx={{
                  color: theme.palette.grey[300],
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ margin: "auto 0" }}>
          <Typography
            onClick={() => handleApplyFilter()}
            sx={{
              display: "inline-flex",
              cursor: `${allowedApply ? "pointer" : "default"}`,
              opacity: `${allowedApply ? "1" : "0.25"}`,
            }}
          >
            <Icon src={`insights/reset.svg`} height="32px" width="32px" />
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
export default ChatFilter;
