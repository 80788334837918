import {
  Avatar,
  IconButton,
  Popover,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, MouseEventHandler, useCallback, useState } from "react";
import SVG from "react-inlinesvg";
import shortid from "shortid";
import { useInsightAssignees, useInsightsContext } from "../hooks/selectors";
import { FrontEggTeamMember } from "../../../types";

import { AssignTeamMember } from "./AssignTeamMember";
import { useInsightFeedEvent } from "../../../hooks/metrics/useInsightEvent";
import { TransformedInsight } from "../types";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: "#fff",
  ":hover": {
    background: theme.palette.grey[50],
  },
  ":active": {
    background: theme.palette.primary.light,
  },
}));

export const ActionButton: FC<{
  icon: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}> = ({ icon, onClick }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={
        <Typography
          variant="caption"
          color={theme.palette.success.contrastText}
        >
          Assign
        </Typography>
      }
      placement="top"
      arrow
    >
      <StyledIconButton
        size="small"
        sx={{ width: "32px", height: "32px" }}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
        }}
      >
        <SVG
          style={{ width: "16px", height: "16px" }}
          src={icon}
          color={theme.palette.text.primary}
        />
      </StyledIconButton>
    </Tooltip>
  );
};

export const MemberIcons: FC<{
  members: FrontEggTeamMember[];
  max?: number;
}> = ({ members, max = 5 }) => {
  const theme = useTheme();
  const extraAvatars =
    (members || []).length > max ? (members || []).length - max + 1 : 0;

  return (
    <Stack
      direction="row"
      sx={{ marginLeft: "10px", "> *": { marginLeft: "-12px" } }}
    >
      {(members || [])
        .slice(0, members.length - extraAvatars)
        .map((member: FrontEggTeamMember, idx: number) => (
          <Tooltip
            key={shortid()}
            title={
              <Typography
                variant="body5"
                color={theme.palette.success.contrastText}
              >
                {member.name}
              </Typography>
            }
          >
            <Avatar
              key={`${member.name}-${idx}`}
              alt={member.name}
              src={member.profileImage}
              sx={{
                border: `2px solid ${theme.palette.background.paper}`,
              }}
            />
          </Tooltip>
        ))}
      {extraAvatars ? (
        <Tooltip
          title={
            <Stack gap="4px" padding="8px 12px">
              {(members || [])
                .slice(max - 1, members.length)
                .map((member: FrontEggTeamMember) => (
                  <Typography
                    key={shortid()}
                    variant="body5"
                    color={theme.palette.success.contrastText}
                  >
                    {member.name}
                  </Typography>
                ))}
            </Stack>
          }
        >
          <Avatar
            style={{
              zIndex: 0,
            }}
          >
            +{extraAvatars}
          </Avatar>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

export const Team: FC<{
  insight: TransformedInsight;
  members: FrontEggTeamMember[];
  origin?: string;
  max?: number;
}> = ({ insight, members, origin = "Insight Card", max = 2 }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { enableDrawer } = useInsightsContext();
  const logEvent = useInsightFeedEvent();

  const setAnchor = useCallback(
    (target?: HTMLButtonElement) => {
      setAnchorEl(target || null);
      enableDrawer(!target);
    },
    [anchorEl, setAnchorEl]
  );

  if (!insight) {
    return null;
  }
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="right"
    >
      {!!(members || []).length && <MemberIcons members={members} max={max} />}
      <ActionButton
        onClick={(e) => {
          logEvent({ insight, action: "Assign", origin });
          setAnchor(e.currentTarget);
        }}
        icon="/insights/assign-fill.svg"
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!anchorEl}
        onClose={() => setAnchor()}
      >
        <AssignTeamMember
          insightId={insight.id}
          closePopover={() => setAnchorEl(null)}
        />
      </Popover>
    </Stack>
  );
};

export const SocialAndActions: FC<{ insight: TransformedInsight }> = ({
  insight,
}) => {
  const insightAssignees = useInsightAssignees(insight.id);

  return (
    <Stack
      alignSelf="stretch"
      direction="column"
      justifyContent="space-around"
      spacing={2}
    >
      <Team members={insightAssignees} insight={insight} />
    </Stack>
  );
};
