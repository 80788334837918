import { Box, TableCell, TableRow, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { ConnectorType } from "../enums";
import useProductsFilters from "../services/ecommerce/useProductsFilters";
import { prettifyString } from "../utils";
import ConnectorTypeIcon from "./ConnectorTypeIcon";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      height: theme.spacing(3.1),
      width: theme.spacing(3.1),
      display: "flex",
      justifyContent: "center",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
    },
  })
);

const InputRow: FC<{
  input: any & { displayName: string };
  isFinished?: boolean;
  onClick?: (e: any) => void;
}> = ({ input, isFinished, onClick }) => {
  const inputConfig = input?.value?.configuration || {};
  const type = input?.value?.type;
  const classes = useStyles();
  const { data: filters = [] } = useProductsFilters({
    enabled:
      type === ConnectorType.AMAZON_PRODUCTS ? !!inputConfig.filter_id : false,
  });
  const currentFilter = filters.find(
    (filter) => filter.id === inputConfig?.filter_id
  );
  return (
    <TableRow
      key={input.name}
      onClick={onClick}
      sx={{ cursor: isFinished ? "pointer" : "default" }}
    >
      <TableCell component="th" scope="row">
        {prettifyString(input.name)}
      </TableCell>
      <TableCell>
        <Box display="flex" alignContent="center">
          <ConnectorTypeIcon type={type} iconClass={classes.icon} />
          <Typography
            title={currentFilter && `Filter applied: ${currentFilter?.name}`}
            sx={{ ml: 0.5 }}
          >
            {type === ConnectorType.AMAZON_PRODUCTS
              ? currentFilter?.name || "All products"
              : input.value.configuration.output_id || input.name}
          </Typography>
        </Box>
      </TableCell>
      {input.total_rows && <TableCell>{input.total_rows || 0}</TableCell>}
    </TableRow>
  );
};

export default InputRow;
