import {
  Box,
  BoxProps,
  Card as MuiCard,
  CardProps as MuiCardProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import classnames from "classnames";
import { FC, ReactNode } from "react";

const PREFIX = "Card";

const classes = {
  containedContainer: `${PREFIX}-containedContainer`,
  containedDescription: `${PREFIX}-containedDescription`,
  container: `${PREFIX}-container`,
  containedHeader: `${PREFIX}-containedHeader`,
  containedHeaderText: `${PREFIX}-containedHeaderText`,
  content: `${PREFIX}-content`,
  header: `${PREFIX}-header`,
  caption: `${PREFIX}-caption`,
  headerContainer: `${PREFIX}-headerContainer`,
};

const StyledMuiCard = styled(MuiCard)(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.containedHeader}`]: {
    height: theme.spacing(8),
  },

  [`& .${classes.containedHeaderText}`]: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    overflow: "hidden",
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.header}`]: {
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.caption}`]: {
    textTransform: "uppercase",
  },

  [`& .${classes.headerContainer}`]: {
    marginBottom: theme.spacing(1),
  },
}));

export const CardActions: FC<BoxProps> = ({ children, ...props }) => (
  <CardFooter>
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      flex={1}
      {...props}
    >
      {children}
    </Box>
  </CardFooter>
);

export const CardFooter: FC<BoxProps> = ({ children, ...props }) => (
  <Box
    display="flex"
    justifyContent="flex-end"
    flex={1}
    flexDirection="column"
    {...props}
  >
    {children}
  </Box>
);

export type CardProps = {
  children: ReactNode;
  image?: ReactNode;
  headerLabel: string;
  subheader?: ReactNode;
  badge?: ReactNode;
  color?: string;
  body: string;
  className?: string;
  caption?: string;
  classes?: {
    container?: string;
    caption?: string;
    description?: string;
    header?: string;
    headerContainer?: string;
  };
  hoverable?: boolean;
  disabled?: boolean;
  contained?: boolean;
  cardProps?: Partial<MuiCardProps>;
};

const Card: FC<CardProps> = ({
  image,
  headerLabel,
  color,
  body,
  subheader,
  badge,
  children,
  className,
  classes: propsClasses,
  caption,
  contained,
  cardProps,
  disabled,
}) => (
  <StyledMuiCard
    variant="outlined"
    className={classnames(
      className,
      propsClasses?.container,
      classes.container,
      contained && classes.containedContainer
    )}
    {...cardProps}
    sx={{
      borderTop: (t) => (color ? `${t.spacing(1)} ${color} solid` : ""),
      cursor: !disabled && cardProps?.onClick ? "pointer" : "inherit",
      "&:hover": {
        ...(!disabled
          ? {
              boxShadow:
                "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
            }
          : {}),
      },
      width: 265,
      ...cardProps?.sx,
    }}
  >
    <Box className={classes.content} flex={1}>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          mr={2}
          className={classnames(
            contained && classes.containedHeader,
            classes.headerContainer
          )}
        >
          {caption && (
            <Typography
              variant="overline"
              className={classnames(propsClasses?.caption)}
            >
              {caption}
            </Typography>
          )}

          <Box
            display="flex"
            alignItems="center"
            className={classnames(propsClasses?.headerContainer)}
            sx={{ height: "60px" }}
          >
            {typeof image !== "string" ? (
              image
            ) : (
              <img width="50" height="50" src={image} alt="Card Icon" />
            )}
            <Box>
              <Tooltip title={headerLabel}>
                <Typography
                  variant="h6"
                  component="div"
                  className={classnames(
                    propsClasses?.header,
                    classes.header,
                    classes.containedHeaderText
                  )}
                >
                  {headerLabel}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        {!!badge && (
          <Box
            sx={{
              mb: 1,
              mr: 1,
            }}
          >
            {badge}
          </Box>
        )}
      </Box>

      {!!subheader && (
        <Box
          sx={{
            mb: 1,
          }}
        >
          {subheader}
        </Box>
      )}

      <Box
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 6,
          overflow: "hidden",
          marginBottom: 3,
          height: "115px",
        }}
        className={classnames(propsClasses?.description)}
      >
        <Tooltip title={body}>
          <Typography variant="body2">{body}</Typography>
        </Tooltip>
      </Box>
      {children}
    </Box>
  </StyledMuiCard>
);

export default Card;
