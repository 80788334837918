import {Stack, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {useAuthUser} from "@frontegg/react";
import {ConnectionConfigurationProps} from "../types";
import {ConnectorID, ConnectorType, GoogleApiStatus} from "../../../../enums";
import {GoogleWorksheet} from "../../../../types/connectorsV2";
import ConnectorOptionHeader from "../ConnectorOptionHeader";
import {prettifyString} from "../../../../utils";
import Actions from "./Actions";
import WorksheetSelect from "./WorksheetSelect";
import SheetField from "./SheetField";
import {useGoogleSheetsConnector} from "../../../../hooks";
import {FC, useState, useEffect} from "react"

type Sheet = {
  id: string;
  name: string;
};

const GoogleSheetsConfiguration: FC<ConnectionConfigurationProps> = ({
                                                                       input,
                                                                       setInputValue,
                                                                       onCompletion,
  resetInputValue,
}) => {
  const user = useAuthUser();
  const [sheet, setSheet] = useState<Sheet | null>(null);
  const [worksheet, setWorksheet] = useState<GoogleWorksheet | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = () => {
    setInputValue({
      type: ConnectorType.GOOGLE_SHEETS,
      configuration: {
        file_id: sheet!.id,
        file_name: sheet!.name,
        user_id: user.id,
        worksheet_id: worksheet!.id,
      },
      mapping: {},
      connector_id: ConnectorID.GOOGLE_SHEETS,
    });
    onCompletion();
  };
  const onError = (errorMessage: string) => {
    resetInputValue();
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  };
  const onPickerChange = async (data: any) => {
    if (data.action === "picked") {
      setSheet(data.docs[0]);
    }
  };
  const { openSheetPicker, status } = useGoogleSheetsConnector(
    onPickerChange,
    onError
  );

  useEffect(() => {
    if (status === GoogleApiStatus.READY && !sheet) {
      openSheetPicker();
    }
  }, [status]);

  return (
    <>
      <Typography variant="h6">
        {`2. Choose a Google Sheet as a source for ${prettifyString(
          input.name
        )} input`}
      </Typography>
      <ConnectorOptionHeader
        connectorType={ConnectorType.GOOGLE_SHEETS}
        text="Fetch data from a Google Sheet"
      />
      <Stack spacing={2}>
        <SheetField
          sheetName={sheet?.name || ""}
          onClick={openSheetPicker}
          shrinkLabel={!!sheet}
          disabled={status !== GoogleApiStatus.READY}
        />
        <WorksheetSelect
          sheetId={sheet?.id}
          worksheet={worksheet}
          setWorksheet={setWorksheet}
        />
        <Actions
          onSubmit={onSubmit}
          onBack={resetInputValue}
          submitDisabled={!sheet || !worksheet}
        />
      </Stack>
    </>
  );
};
export default GoogleSheetsConfiguration;
