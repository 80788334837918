import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../apiRequest";

const getProductsCategoryTree = (): Promise<CategoryInformation[]> =>
  apiRequest<CategoryInformation[]>(
    "v1/onboarding/ecommerce/products/categories",
    "GET"
  );

export type CategoryInformation = [number, string];

const useProductsCategories = (
  options?: UseQueryOptions<CategoryInformation[], APIError>
): UseQueryResult<CategoryInformation[], APIError> =>
  useQuery<CategoryInformation[], APIError>(
    ["GetProductsCategories"],
    () => getProductsCategoryTree(),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export default useProductsCategories;
