import { useAuthUser } from "@frontegg/react";

import { PageHeader } from "../../components";
import { useUserTenant } from "../../hooks";
import AddBoardButton from "./AddBoardButton";

const BoardsHeader = () => {
  const user = useAuthUser();
  const tenant = useUserTenant(user);
  return (
    <PageHeader
      headerLabel={tenant ? `${tenant?.name} Workspace` : "Workspace"}
      actions={<AddBoardButton />}
    />
  );
};

export default BoardsHeader;
