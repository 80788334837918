import {Button, Card, CardContent, CardHeader, DialogActions, TextField, Tooltip,} from "@mui/material";
import {useSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {updateBoard} from "../../../services/circuitboards";
import {CircuitBoard} from "../../../types";
import {FC} from "react"

export type BasicSettingsForm = {
  description: string;
  displayName: string;
};

const BasicSettingsSection: FC<{ board: CircuitBoard }> = ({board}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {handleSubmit, formState, control} = useForm<BasicSettingsForm>({
    mode: "onChange",
    defaultValues: {
      description: board.description,
      displayName: board.displayName,
    },
  });

  const mutationBasic = useMutation(updateBoard, {
    onSuccess: () => {
      enqueueSnackbar("Successfully updated the block description and name", {
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar("Failed to update the block description and name", {
        variant: "error",
      });
    },
  });

  const onSubmitBasic = handleSubmit((formValues: BasicSettingsForm) => {
    mutationBasic.mutate({
      id: board.identifier,
      description: formValues.description || board!.description,
      displayName: formValues.displayName || board!.displayName,
    });
  });

  const { isValid, isDirty } = formState;
  const disabled = !isValid || !isDirty;
 

  return (
    <form onSubmit={onSubmitBasic}>
      <Card variant="elevation" elevation={0}>
        <CardHeader
          title="Basic Settings"
          subheader="Change basic settings for the block"
        />
        <CardContent>
          <Controller
              name="displayName"
              control={control}
              render={({field}) => (
                  <TextField
                      label="Display Name"
                      {...field}
                      sx={{margin: "auto"}}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                  />
            )}
          />
          <Controller
              name="description"
              control={control}
              render={({field}) => (
                  <TextField
                      label="Description"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      multiline
                      rows={5}
                      sx={{marginTop: 1}}
                      variant="outlined"
                      margin="dense"
                fullWidth
              />
            )}
          />
        </CardContent>
        <DialogActions>
          <Tooltip title={disabled ? "Change form values to submit" : ""}>
            <span>
              <Button
                type="submit"
                variant="text"
                color="primary"
                disabled={disabled}
              >
                Submit
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Card>
    </form>
  );
};

export default BasicSettingsSection;
