import {Button, Chip, Tooltip} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Card, {CardActions, CardProps} from "./Card";
import {FC, ReactNode} from "react"

export type BoardTemplateCardProps = {
  displayName: string;
  description: string;
  onGet?: () => void;
  subheader?: ReactNode;
  infoOnly?: boolean;
} & Omit<CardProps, "headerLabel" | "body" | "subheader">;

export const BoardCardManagedChip: FC = () => (
  <Tooltip title="Managed boards can be only installed by our support team.">
    <Chip
      label="Managed"
      variant="outlined"
      color="info"
      size="small"
      icon={<AdminPanelSettingsIcon />}
    />
  </Tooltip>
);

const BoardTemplateCard: FC<BoardTemplateCardProps> = ({
  image,
  displayName,
  description,
  children,
  onGet,
  infoOnly,
  subheader,
  ...props
}) => (
  <Card
    contained
    headerLabel={displayName}
    body={description}
    image={image}
    subheader={subheader}
    {...props}
  >
    {children || (
      <CardActions>
        {infoOnly ? (
          <Button variant="text" onClick={onGet} sx={{ textTransform: "none" }}>
            Info
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={onGet}
            sx={{ textTransform: "none" }}
          >
            Get
          </Button>
        )}
      </CardActions>
    )}
  </Card>
);

export default BoardTemplateCard;
