import {Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import useProductsBrands from "../../../services/ecommerce/useProductsBrands";
import {CollapsableBox} from "../../../components/CollapsableBox";
import {FC} from "react"

export const FilterBrands: FC<{
    selectedBrands: string[];
    onChange: (brands: string[]) => void;
}> = ({onChange, selectedBrands}) => {
    const {data = []} = useProductsBrands();

    return (
        <CollapsableBox label="Brands">
            <FormGroup
        onChange={(e: any) => {
          const currentValue = e.target.value;
          if (currentValue === "ALL") {
            const isAllSelected = selectedBrands.length === data.length;
            onChange(isAllSelected ? [] : data.map(([_count, brand]) => brand));
          } else {
            const isValueSelected = selectedBrands.find(
              (sb) => sb === currentValue
            );
            onChange(
              isValueSelected
                ? selectedBrands.filter((sb) => sb !== currentValue)
                : [...selectedBrands, currentValue]
            );
          }
        }}
        sx={{ ml: 1 }}
      >
        {data?.length && (
          <FormControlLabel
            label="Select all"
            control={
              <Checkbox
                size="small"
                value="ALL"
                checked={data.length === selectedBrands.length}
              />
            }
          />
        )}
        <Stack px={1} maxHeight={250} overflow="scroll">
          {data
            .sort((a, b) => a[1].localeCompare(b[1]))
            .map(([count, brand]) => (
              <FormControlLabel
                label={`${brand} (${count})`}
                key={brand}
                control={
                  <Checkbox
                    size="small"
                    value={brand}
                    checked={
                      !!selectedBrands.find(
                        (selectedBrand) => selectedBrand === brand
                      )
                    }
                  />
                }
              />
            ))}
        </Stack>
      </FormGroup>
    </CollapsableBox>
  );
};
