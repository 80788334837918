import { Box, Typography } from "@mui/material";
import { SpacesBigIcon } from "../../../assets";
import { useHistory } from "react-router-dom";

const EmptyState = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/spaces");
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <SpacesBigIcon />
      <Box
        sx={{
          position: "absolute",
          bottom: "-20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 600,
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          You don't have any Competitive spaces yet.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "center",
          }}
        >
          Create and add a new Space{" "}
          <Typography
            component="span"
            sx={{
              cursor: "pointer",

              color: "#0070FF",
              textDecoration: "underline",
            }}
            onClick={handleClick}
          >
            here
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyState;
