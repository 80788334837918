import {useParams} from "react-router-dom";
import {useBoard} from "../../../services/boards";
import ParametersForm from "./ParametersForm";
import {NewRunFormResult} from "../hooks/useNewRunForm";
import Wrapper from "../Wrapper";
import {FC} from "react"

type ParametersSectionProps = {
    onBack: () => void;
    runForm: NewRunFormResult;
};

const ParametersSection: FC<ParametersSectionProps> = ({ onBack, runForm }) => {
  const { boardId }: { boardId?: any } = useParams();
  const { data: board } = useBoard(boardId);
  return (
    <Wrapper
      left={
        <>
          {board && (
            <ParametersForm board={board} onBack={onBack} runForm={runForm} />
          )}
        </>
      }
      right={<></>}
    />
  );
};
export default ParametersSection;
