import {Dialog, DialogTitle} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {Run} from "../../types";
import RunLogsContent from "../../components/RunLogsContent";
import {FC} from "react"

const useStyles = makeStyles(() => ({
  paper: { height: "100%" },
}));

const RunDetailsDialog: FC<{
  run?: Run | null;
  open: boolean;
  onClose?: () => void;
}> = ({ run, open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{ className: classes.paper }}
    >
      <DialogTitle>{`Run ${run?.name} (#${run?.id.substr(0, 5)})`}</DialogTitle>
      <RunLogsContent
        run={run}
        tabsProps={{ textColor: "primary", indicatorColor: "primary" }}
      />
    </Dialog>
  );
};

export default RunDetailsDialog;
