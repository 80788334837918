import {Box, IconButton, Typography} from "@mui/material";
import {FC, useEffect, useMemo, useState} from "react";
import {Column, usePagination, useSortBy, useTable} from "react-table";
import {useMutation} from "react-query";
import {Delete} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import {IllustrationPlaceholder, UncontrolledTable} from "../../components";
import {DataSource} from "../../types/datasources";
import {formatDateString} from "../../utils";
import {DataSourceDetails} from "./DataSourceCard";
import {UserAvatar} from "../Circuitboards/BoardsTable/UserCellComponent";
import {deleteDataSource} from "../../services/datasources/api";
import useDataSources from "../../services/datasources/useDataSources";

const numberPerPage = 10;
const DataSourcesTable: FC<{
    data: DataSource[];
    isLoading: boolean;
    error: Error | null;
    onEdit: (ds: DataSource) => void;
}> = ({data, error, isLoading, onEdit}) => {
    const [pageSize, setPageSize] = useState<number>(numberPerPage);
    const deleteMutation = useMutation(deleteDataSource);
    const {enqueueSnackbar} = useSnackbar();
    const {refetch} = useDataSources();
    const columns: Column<DataSource>[] = useMemo(
        () => [
            {
                Header: "Data source",
                id: "data_source",
                accessor: "id",
                disableSortBy: true,
                Cell: ({row}) => {
                    const ds = row.original;
                    return <DataSourceDetails {...ds} onClick={() => onEdit(ds)}/>;
                },
            },
            {
                id: "created_at",
                Header: "Added by",
                accessor: "created_by",
                minWidth: 60,
                disableSortBy: true,
                Cell: ({row}) =>
                    row.original.created_by && (
                        <UserAvatar user={row.original.created_by}/>
                    ),
            },
            {
                id: "updated_at",
                Header: "Date Added",
                accessor: "updated_at",
        minWidth: 60,
        disableSortBy: true,
        Cell: ({ value }) => (
          <Typography variant="body2" noWrap>
            {formatDateString(value)}{" "}
          </Typography>
        ),
      },
            {
                id: "actions",
                Header: "Actions",
                minWidth: 60,
                disableSortBy: true,
                Cell: ({row}) => (
                    <IconButton
                        disabled={deleteMutation.isLoading}
                        onClick={() =>
                            deleteMutation
                                .mutateAsync(row.original.id)
                                .then(() =>
                                    enqueueSnackbar("Datasource deleted successfully.", {
                                        variant: "success",
                                    })
                                )
                                .then(() => refetch())
                                .catch(() =>
                                    enqueueSnackbar("Fail to delete Datasource.", {
                                        variant: "error",
                                    })
                                )
                        }
                    >
                        <Delete/>
                    </IconButton>
        ),
      },
    ],
    [data]
  );
  const instance = useTable(
    {
      columns,
      data: data || [],
      autoResetSortBy: false,
      autoResetPage: false,
      initialState: { pageSize },
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    instance.setPageSize(pageSize);
  }, [pageSize]);
  return !isLoading && data?.length === 0 ? (
    <IllustrationPlaceholder
      imageHeight={300}
      imageWidth={300}
      text="No Data Sources. Click add data source to integrate a new dara source."
    />
  ) : (
    <Box m={4}>
      <UncontrolledTable
        tableRowPropsGetter={() => ({ sx: { height: 100 } })}
        instance={instance}
        data={data || []}
        isLoading={isLoading}
        columns={columns}
        error={error || null}
        tableProps={{
          size: "small",
          stickyHeader: true,
        }}
        disablePagination
      />
      {data && data.length >= pageSize && (
        <Typography
          onClick={() => setPageSize(pageSize + numberPerPage)}
          sx={{
            transition: "background 0.2s ease",
            "&:hover": { background: "grey.100" },
            padding: 2,
            cursor: "pointer",
            borderBottomWidth: "1px",
            borderBottomColor: "text.secondary",
            color: "text.primary",
          }}
        >
          + Load {numberPerPage} more
        </Typography>
      )}
    </Box>
  );
};

export default DataSourcesTable;
