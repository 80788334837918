import {Box, MenuItem, MenuList, Skeleton, Typography} from "@mui/material";
import {useSearchParams} from "../../../hooks";
import {useBoards} from "../../../services/boards";
import {CircuitBoard} from "../../../types";
import {FilterOption} from "./BoardFilters";
import {FC} from "react"

const getBoardTypesOptions = (boards: CircuitBoard[]): FilterOption[] => {
  const boardTemplateIdToName: Record<string, string> = boards.reduce(
      (acc, board) => ({...acc, [board.template_id]: board.templateName || ""}),
      {}
  );
  return Object.entries(boardTemplateIdToName).map(([k, v]) => ({
    name: k,
    label: v,
  }));
};

export const BoardTypesFilters: FC<{
  onChange: (type: string) => void;
}> = ({ onChange }) => {
  const { search } = useSearchParams();
  const { data, isLoading } = useBoards();
  const selectedType = search?.type;
  const availableTypes = data ? getBoardTypesOptions(data) : [];
  return (
    <Box>
      <Typography variant="subtitle2">Type</Typography>
      <MenuList style={{ marginTop: 0 }}>
        {isLoading &&
          [...new Array(3)].map((_item, index) => (
            <Skeleton key={index} height={34} />
          ))}
        {availableTypes
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((option) => (
            <MenuItem
              key={option.name}
              selected={selectedType === option.name}
              onClick={() => {
                onChange(option.name);
              }}
            >
              <Typography noWrap>{option.label}</Typography>
            </MenuItem>
          ))}
      </MenuList>
    </Box>
  );
};
