import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

import { updateDashboardFilters } from "../api";
import { DashboardFilters } from "../types";

export const UpdateFilterDialog = ({
  onClose,
  onSave,
  dashboardFilters,
  ...props
}: DialogProps & {
  dashboardFilters: DashboardFilters;
  onSave: (f: DashboardFilters) => void;
}) => {
  const snackbar = useSnackbar();
  const { control, handleSubmit, formState } = useForm<{ name: string }>({
    mode: "onChange",
  });
  const updateFilterMutation = useMutation((name: string) =>
    updateDashboardFilters({
      ...dashboardFilters,
      name,
    })
  );

  const handleSave = handleSubmit(({ name }) =>
    updateFilterMutation
      .mutateAsync(name)
      .then((newFilters) => {
        snackbar.enqueueSnackbar(
          `Filter name updated successfully to ${name}.`,
          { variant: "success" }
        );
        return onSave(newFilters);
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to update dashboard filters.", {
          variant: "error",
        });
        return onClose && onClose({}, "backdropClick");
      })
  );

  return (
    <Dialog {...props}>
      <form onSubmit={handleSave}>
        <DialogTitle id="alert-dialog-title">Save filter</DialogTitle>
        <DialogContent>
          <FormControl>
            <Controller
              rules={{ required: true }}
              control={control}
              name="name"
              render={(props) => (
                <TextField
                  defaultValue={dashboardFilters.name}
                  {...props}
                  onChange={(e) => {
                    props.field.onChange(e);
                  }}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={(e) => onClose && onClose(e, "backdropClick")}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !formState.isValid ||
              formState.isSubmitting ||
              formState.isValidating
            }
            color="primary"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
