import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import { Button, Tooltip } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformPermission } from "../../../../enums";
import { useRun } from "../../../../services/runs";
import { RunHeaderButtonProps } from "../types";

const KubeflowButton: FC<RunHeaderButtonProps> = ({ boardId, runId }) => {
  const { data: run, isLoading } = useRun(boardId, runId);
  const inspectButtonEnabled = run?.kfp_run_id;

  return (
    <AuthorizedContent requiredPermissions={[PlatformPermission.KFP_GET]}>
      <Tooltip
        title={
          isLoading
            ? ""
            : inspectButtonEnabled
            ? "Inspect"
            : "Disabled if no run exists in KFP"
        }
      >
        <span>
          <Button
            sx={{
              maxWidth: "48px",
              minWidth: "48px",
              height: "40px",
              color: "text.primary",
              borderColor: "text.secondary",
            }}
            variant="outlined"
            target="_blank"
            component={Link}
            to={`/pipeline/#/runs/details/${run?.kfp_run_id}`}
            disabled={!inspectButtonEnabled}
          >
            <DeviceHubIcon />
          </Button>
        </span>
      </Tooltip>
    </AuthorizedContent>
  );
};

export default KubeflowButton;
