import {Box, Step, StepLabel, Stepper} from "@mui/material";
import {useSnackbar} from "notistack";

import {ConnectorType} from "../../../enums";
import {PipelineInput, RunInputValue} from "../../../types";
import ConnectionConfiguration from "./ConnectionConfiguration";
import ConnectorTypeSelection from "./ConnectorTypeSelection";
import InputDetails from "./InputDetails";
import Mapping from "./Mapping/Mapping";
import {FC} from "react"

type InputConfigurationProps = {
  input: PipelineInput | undefined | null;
  inputValue: RunInputValue | undefined | null;
  setInputValue: (runInputValue: RunInputValue) => void;
  resetInputValue: () => void;
  onCompletion: () => void;
};

const InputConfiguration: FC<InputConfigurationProps> = ({
  input,
  inputValue,
  setInputValue,
  resetInputValue,
  onCompletion,
}) => {
  const skipConfigurationAndMapping = ["AMAZON_PRODUCTS"];
  const { enqueueSnackbar } = useSnackbar();
  const onMappingSubmit = (formValues: Record<string, any>) => {
    setInputValue({ ...inputValue!, mapping: formValues });
    onCompletion();
  };
  const onMappingBack = () =>
    inputValue?.type === ConnectorType.GOOGLE_SHEETS
      ? resetInputValue()
      : setInputValue({ ...inputValue!, mapping: undefined });
  const onMappingError = (errorMessage: string) => {
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
    resetInputValue();
  };
  const getActiveStep = () => (inputValue ? (inputValue.mapping ? 2 : 1) : 0);

  return (
    <Box flex={1} flexDirection="column" height="100%">
      <Box marginX={4} flexGrow={1}>
        <Stepper activeStep={getActiveStep()} sx={{ p: 3 }}>
          {["Choose", "Configure", "Map"].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box marginX={5}>
        {input &&
        inputValue &&
        !skipConfigurationAndMapping.includes(inputValue.type) &&
        inputValue.configuration &&
        inputValue.mapping !== undefined ? (
          <Mapping
            input={input}
            inputValue={inputValue}
            onSubmit={onMappingSubmit}
            onBack={onMappingBack}
            onError={onMappingError}
          />
        ) : input && inputValue && inputValue.type ? (
          <ConnectionConfiguration
            input={input}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onCompletion={onCompletion}
            resetInputValue={resetInputValue}
          />
        ) : (
          <>
            <ConnectorTypeSelection
              setInputValue={setInputValue}
              onCompletion={onCompletion}
              input={input}
            />
            {input && <InputDetails input={input} />}
          </>
        )}
      </Box>
    </Box>
  );
};

export default InputConfiguration;
