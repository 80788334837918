import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Controller } from "react-hook-form";
import { FC } from "react";
import { TextField } from "@mui/material";

const ScheduleDateTimePicker: FC<{
  control: any;
  setStart: any
}> = ({ control,setStart }) => {
  return (
    <Controller
      name="start_date"
      control={control}
      shouldUnregister={false}
      render={({ value }: any) => {
        return (
          <DateTimePicker
            label="Start Date"
            value={value}
            onChange={(event: any) => {
                setStart(event.toDate());
              }}
            name="start_date"
            slots={{
              textField: (params) => (
                <TextField {...params} sx={{ maxWidth: 270 }} />
              ),
            }}
            disablePast
          />
        );
      }}
    />
  );
};

export default ScheduleDateTimePicker;
