import {Card, CardContent, CardHeader, Drawer, IconButton, Stack, Typography,} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {ParametersTable} from "../../../../../components";
import {Trigger} from "../../../../../types";
import TriggeredByInputsTable from "./TriggeredByInputsTable";
import {FC} from "react"

const triggerDrawer: FC<{
  trigger: Trigger;
  open: boolean;
  onClose: () => void;
}> = ({trigger, open, onClose}) => (
    <Drawer
        open={open}
    onClose={onClose}
    PaperProps={{ sx: { width: "40%" } }}
    variant="temporary"
    anchor="right"
  >
    <Card variant="elevation" elevation={0} sx={{ m: 1 }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1}>
            <img src={`/esc-icon.svg`} alt="esc" />
            <Typography
              variant="subtitle2"
              color="grey.700"
              display="flex"
              alignItems="center"
            >
              to close
            </Typography>
          </Stack>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Stack spacing={3}>
          <Typography variant="h4">Triggers</Typography>
          <TriggeredByInputsTable
            boardId={trigger.board_id}
            inputs={trigger.data.inputs}
          />
          <ParametersTable parameters={trigger.data.parameters} />
        </Stack>
      </CardContent>
    </Card>
  </Drawer>
);

export default triggerDrawer;
