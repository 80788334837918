import {Grid} from "@mui/material";

import {useParams} from "react-router-dom";
import {Split} from "../../FeatureFlags/enums";
import {useSplitEnabled} from "../../hooks";
import {useBoard} from "../../services/boards";
import AutoPublishButton from "./AutoPublishButton";
import LaunchAppButton from "./LaunchAppButton";
import NewRunButton from "./NewRunButton";
import VisibilityButton from "./VisibilityButton";
import WarehouseSyncButton from "./WarehouseSyncButton";
import {FC} from "react"

const Actions: FC = () => {
    const {boardId}: { boardId?: any } = useParams();
    const {data: board} = useBoard(boardId);
    const warehouseSettingsEnabled = useSplitEnabled(Split.WAREHOUSE_SETTINGS);

    return board ? (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                {board?.applications[0] && <LaunchAppButton board={board}/>}
            </Grid>

            {board?.applications[0] && (
                <Grid item>
          <AutoPublishButton />
        </Grid>
      )}
      {!board?.flow && warehouseSettingsEnabled && (
        <Grid item>
          <WarehouseSyncButton board={board} />
        </Grid>
      )}

      {!board?.flow && (
        <Grid item>
          <VisibilityButton board={board} />
        </Grid>
      )}
      <Grid item>
        <NewRunButton boardId={boardId} />
      </Grid>
    </Grid>
  ) : null;
};
export default Actions;
