import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { LIMITED_ROLES, NavbarModes } from "./enums";
import { useSearchParams, useSplitEnabled, useUserTenant } from "./hooks";
import { useAuthUser } from "@frontegg/react";
import { useTenantConfiguration } from "./services/tenants/useTenantConfiguration.ts";
import { getUserMostPrivilegeRole } from "./services/auth.ts";
import { Split } from "./FeatureFlags/enums.ts";

export type AppState = {
  applicationMode: boolean;
  navbarMode: NavbarModes;
  insightType: string;
  defaultView: string;
};
export type AppContextType = AppState & {
  setApplicationMode: (isSet: boolean) => void;
  setNavbarMode: (mode: NavbarModes) => void;
  setInsightType: (isSet: string) => void;
  defaultView: string;
};

export enum TYPES {
  "APPLICATIONS_MODE" = "applications-mode",
  NAVBAR_MODE = "navbar-mode",
  INSIGHT_MODE = "",
}

const localAppMode: string | null = localStorage.getItem(
  TYPES.APPLICATIONS_MODE,
);

export const initialValue: AppState & AppContextType = {
  applicationMode: localAppMode !== null ? localAppMode !== "false" : true,
  navbarMode: NavbarModes.LOADING,
  insightType: "",
  defaultView: "",
  setApplicationMode: () => { },
  setNavbarMode: () => { },
  setInsightType: () => { },
};
export const AppContext = createContext<AppContextType>(initialValue);

export const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case TYPES.APPLICATIONS_MODE:
      return { ...state, applicationMode: action.payload };
    case TYPES.NAVBAR_MODE:
      return { ...state, navbarMode: action.payload };
    case TYPES.INSIGHT_MODE:
      return { ...state, insightType: action.payload };
    default:
      throw new Error("Wrong context reducer type ");
  }
};

export const ApplicationContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { search } = useSearchParams();
  const user = useAuthUser();
  const role = getUserMostPrivilegeRole(user).key

  const tenant = useUserTenant(user || undefined);
  const { data: tenantConfig } = useTenantConfiguration(
    tenant?.tenantId || "",
    { enabled: !!tenant?.tenantId },
  );
  const isLeftNavV2 = useMemo(() => {
    return tenantConfig?.configuration?.use_new_menu_bar;
  }, [tenantConfig]);

  const newNavbarModeDefault = isLeftNavV2
    ? NavbarModes.COLLAPSED
    : NavbarModes.EXPENDED;

  const navbarModeDefault = NavbarModes.EXPENDED;
  const localNavbarMode: string | null = localStorage.getItem(TYPES.NAVBAR_MODE);


  (localNavbarMode !== null)
    ? (localNavbarMode as NavbarModes)
    : navbarModeDefault;

  const newInitialValue = {
    ...initialValue,
    navbarMode: LIMITED_ROLES.includes(role) ?
      NavbarModes.TOP :
      localNavbarMode !== null && !isLeftNavV2
        ? (localNavbarMode as NavbarModes)
        : newNavbarModeDefault,
  };
  const sales_assistant_v2 = useSplitEnabled(Split.SALES_ASSISTANT_V2);

  const getDefaultView = () => {
    if (LIMITED_ROLES.includes(role)) {
      return '/perfect-content'
    }
    return sales_assistant_v2 ? "/sales-assistant-v2/home" : "/sales-assistant/home"
  }

  const [state, dispatch] = useReducer(reducer, {
    ...newInitialValue,
    applicationMode: newInitialValue.applicationMode && search?.lab == null,
    defaultView: getDefaultView(),
  });
  const setApplicationMode = (isSet: boolean) =>
    dispatch({ type: TYPES.APPLICATIONS_MODE, payload: isSet });

  const setNavbarMode = (mode: NavbarModes) => {
    dispatch({ type: TYPES.NAVBAR_MODE, payload: mode });
  };

  const setInsightType = (type: string) => {
    dispatch({ type: TYPES.INSIGHT_MODE, payload: type });
  };

  useEffect(() => {
    localStorage.setItem(
      TYPES.APPLICATIONS_MODE,
      JSON.stringify(state.applicationMode),
    );
  }, [state.applicationMode]);

  useEffect(() => {
    if (state.navbarMode !== NavbarModes.TOP) {
      localStorage.setItem(TYPES.NAVBAR_MODE, state.navbarMode);
    }
  }, [state.navbarMode]);


  return (
    <AppContext.Provider
      value={{
        ...state,
        setApplicationMode,
        setNavbarMode,
        setInsightType,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
