import { Games } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
} from "@mui/material";
import { uniq } from "lodash";

import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { useDashboards } from "../../Dashboards/api";
import { ExpendableList, ExpendableListHeader } from "./ExpendableList";
import { StyledTooltip } from "./StyledTooltip";
// import { useQueryParams } from "../../../hooks";
import { Dispatch, FC, SetStateAction } from "react";

const DashboardsMenu: FC<{ dashboards: string[] }> = ({ dashboards }) => {
  const history = useHistory();
  const match = useRouteMatch<{ dashboardName: string }>(
    "/dashboards/:dashboardName"
  );
  return (
    <Paper elevation={2}>
      <List>
        {dashboards.map((d, index) => (
          <ListItemButton
            key={index}
            selected={match?.params?.dashboardName === d}
            onClick={(e) => {
              e.stopPropagation();
              history.push(["", d].join("/"));
            }}
          >
            {d}
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};

export const CustomDashboardsList: FC<{
  open: boolean;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ open, setTab, tab }) => {
  const pageMatch = useRouteMatch<{ tabName: string }>({
    path: "/dashboards/:tabName",
  });
  // const custom = useQueryParams().get("custom") === "true";
  const history = useHistory();

  const { data: dashboards = [], isLoading } = useDashboards({
    options: {
      select: (data) => data.filter((dashboard) => dashboard.custom),
    },
  });

  const tabNames = uniq(
    dashboards.filter(({ tab }) => !!tab).map(({ tab }) => tab as string)
  ).sort();

  if (isLoading) {
    return (
      <ListItemButton>
        <Skeleton height="100%" width="100%" />
      </ListItemButton>
    );
  }

  if (!dashboards.length) {
    return null;
  }
  if (!open) {
    return (
      <StyledTooltip title={<DashboardsMenu dashboards={tabNames} />}>
        <ListItemButton
          onClick={() => {
            history.push("/dashboards?custom=true&headerLabel=Playground");
            setTab("customDashboard");
          }}
          selected={tab === "customDashboard"}
        >
          <ListItemIcon>
            <Games />
          </ListItemIcon>
          <ListItemText primary="dashboards" sx={{ whiteSpace: "nowrap" }} />
        </ListItemButton>
      </StyledTooltip>
    );
  }

  return (
    <ExpendableList
      open={tab === "customDashboard"}
      label={(props) => (
        <ExpendableListHeader
          icon={<Games />}
          onClick={() => {
            history.push("/dashboards?custom=true&headerLabel=Playground");
            setTab("customDashboard");
          }}
          label="Playground"
          {...props}
        />
      )}
    >
      {tabNames.map((tabName, index) => (
        <ListItemButton
          key={index}
          selected={tabName === pageMatch?.params.tabName}
          onClick={() => {
            setTab("customDashboard");
            history.push(
              `${["", "dashboards", tabName].join(
                "/"
              )}?custom=true&&headerLabel=Playground`
            );
          }}
        >
          {tabName}
        </ListItemButton>
      ))}
    </ExpendableList>
  );
};
