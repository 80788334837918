import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FC } from "react";
import { InsightsQueryParams } from "../constants";
import { InsightRead } from "../types";
import { useInsightFeedEvent } from "../../../hooks/metrics/useInsightEvent";
import { useInsightsContext } from "../hooks/selectors";

export enum ActionOrigins {
  Drawer = "Drawer",
  ListItem = "List Item",
}

export const InsightActionButton: FC<{
  insight: InsightRead;
  origin: ActionOrigins;
}> = ({ insight, origin }) => {
  const history = useHistory<{ insightType: string; brand: string }>();
  const logEvent = useInsightFeedEvent();
  const { selectedProductGroup } = useInsightsContext();

  const orginURL: string | undefined = window.location.origin;

  return (
    <Button
      variant="primary"
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        logEvent({
          insight,
          origin: origin === ActionOrigins.Drawer ? "Drawer" : undefined,
          action: "Action_intent",
        });
        const [urlWithoutSearchParams, maybeSearchParams = ""] =
          insight.actionUrl.split("?");

        let url = `${urlWithoutSearchParams}`;
        url += `?${InsightsQueryParams.InsightId}=${insight.id}`;

        if (selectedProductGroup?.id) {
          url += `&productGroupFilterId=${selectedProductGroup.id}`;
        }

        if (maybeSearchParams) {
          url += `&${maybeSearchParams}`;
        }
        if (url.startsWith(window.location.origin)) {
          url = url.replace(window.location.origin, "");
        } else if (orginURL && url.startsWith(orginURL)) {
          url = url.replace(`${orginURL}/`, "");
        }
        if (url.includes("dashboard")) {
          url = `${url}&isInsight=true`;
        }
        history.push(url, { insightType: insight.type, brand: insight.brand });
      }}
    >
      {insight.actionText}
      {origin === ActionOrigins.ListItem && <ArrowRightIcon />}
    </Button>
  );
};
