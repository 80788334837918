import { Box, Divider, Stack, Typography } from "@mui/material";

import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";
import AsinsListTextarea from "../EcommerceProducts/Amazon/AsinsListTextarea";
import { FC } from "react";
import { validateAsinsList } from "../EcommerceProducts/Amazon/AddProducts";
import { AsinFormData } from "./AddSpaceV2";
import { LoadLocalCsvFile } from "../EcommerceProducts/LoadLocalCsvFile";
import { getAsinsFromCSVFile } from "../EcommerceProducts/csvStreamUtils";
import { useSnackbar } from "notistack";
import theme from "../../themes/light";

interface ManualViewProps {
  control: Control<AsinFormData, any>;
  setValue: UseFormSetValue<AsinFormData>;
  errors: FieldErrors<AsinFormData>;
  setFileDroppedLoading: (loading: boolean) => void;
}

const ManualView: FC<ManualViewProps> = (props) => {
  const { control, setValue, errors, setFileDroppedLoading } = props;

  const snackbar = useSnackbar();

  return (
    <Stack
      spacing={1}
      direction="row"
      flex={1}
      justifyContent="center"
      marginTop="4rem">
      <Box flex={1} maxWidth="596px" minHeight="323px" position="relative">
        <Typography
          variant="body4"
          color={theme.palette.primary.main}
          sx={{
            position: "absolute",
            top: "-30px",
            left: 0,
            zIndex: 2,
          }}>
          Enter ASINs manually
        </Typography>
        <Controller
          control={control}
          name="asinsList"
          render={({ _onChange, ...props }: any) => {
            return (
              <Box
                sx={{
                  height: "100%",
                  "& .MuiFormControl-root": {
                    height: "100%",
                    "& .MuiInputBase-root": {
                      height: "100%",
                      background: theme.palette.background.default,
                      border: "1px solid #D5D3DB",
                      ":hover": {
                        background: `${theme.palette.background.default} !important`,
                      },
                      ":focus-within": {
                        background: `${theme.palette.background.default} !important`,
                        border : "1px solid #8E4FF6",
                      },
                      "& textarea": {
                        height: "100% !important",
                      },
                    },
                  },
                }}>
                <AsinsListTextarea
                  placeholder={`Type or paste a list of ASINs to add to your space.\nSeparate ASINs by space or comma.`}
                  onChange={(e) => {
                    // setRawAsins(e.target.value);
                    setValue(
                      "asinsList",
                      validateAsinsList(e.target.value).asins,
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      }
                    );
                    setValue("asins", validateAsinsList(e.target.value).asins, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  errors={errors?.asinsList?.message}
                  {...props}
                />
              </Box>
            );
          }}
        />
      </Box>
      <Box alignSelf="stretch">
        <Divider orientation="vertical">OR</Divider>
      </Box>
      <Box
        flex={1}
        maxWidth="596px"
        minHeight="323px"
        sx={{
          "& .MuiPaper-root": {
            height: "100%",
          },
          position: "relative",
        }}>
        <Typography
          variant="body4"
          color={theme.palette.primary.main}
          sx={{
            position: "absolute",
            top: "-30px",
            left: 0,
            zIndex: 2,
          }}>
          Upload File
        </Typography>
        <LoadLocalCsvFile
          onFileDropped={(dropped_file) => {
            setFileDroppedLoading(true);
            getAsinsFromCSVFile(dropped_file)
              .then((data) => {
                setValue("asinsList", data, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setValue("asins", data, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              })
              .catch((e) =>
                snackbar.enqueueSnackbar(e.message, {
                  variant: "error",
                })
              )
              .finally(() => setFileDroppedLoading(false));
          }}
        />
      </Box>
    </Stack>
  );
};

export default ManualView;
