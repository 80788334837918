import { useQuery, UseQueryOptions } from "react-query";
import { CircuitBoard } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

async function getBoard(boardId: string): Promise<CircuitBoard> {
  return await apiRequest<CircuitBoard>(`circuitboards/${boardId}`, "GET");
}

export default function useBoard(
  boardId: string,
  options?: UseQueryOptions<CircuitBoard, APIError>
) {
  return useQuery<CircuitBoard, APIError>(
    ["board", boardId],
    () => getBoard(boardId),
    { ...options }
  );
}
