import React, { ReactNode } from "react";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { isSameDay } from "date-fns";
import { CustomStyle, DateRange, DefinedRange } from "../types";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

type DefinedRangesProps = {
   
  setRange: (range: DateRange) => void;
  selectedRange: DateRange;
  ranges: DefinedRange[];
  labelIcon?: ReactNode;
  customStyle?: CustomStyle;
  showNoComparison?: boolean;
  handleNoComparison?: () => void;
};

const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

const DefinedRanges: React.FunctionComponent<DefinedRangesProps> = ({
  ranges,
  setRange,
  selectedRange,
  showNoComparison = false,
  handleNoComparison,
  labelIcon = (
    <ArrowRightOutlinedIcon
      sx={{ fontSize: "25px", color: (theme) => theme.palette.primary.main }}
    />
  ),
  customStyle,
}: DefinedRangesProps) => (
  <List>
    {showNoComparison && (
      <ListItemButton
        onClick={handleNoComparison}
        sx={{
          backgroundColor: customStyle?.labelBgColor || "",
          "&:hover": {
            background: (theme) =>
              customStyle?.labelBgOnHover || theme.palette.primary.light,
          },
          paddingRight: "5px",
          ...((selectedRange?.startDate && selectedRange?.endDate)
            ? {}
            : {
                backgroundColor: (theme) =>
                  customStyle?.activeLabelBgColor || theme.palette.primary.dark,
                color: customStyle?.activeLableColor || "primary.contrastText",
                "&:hover": {
                  backgroundColor: (theme) =>
                    customStyle?.activeLabelBgColor ||
                    theme.palette.primary.dark,
                },
              }),
        }}>
        <ListItemText
          primaryTypographyProps={{
            variant: "body2",
            sx: {
              display: "flex",
              textWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "100px",
              fontSize: "1rem",
            },
          }}>
          No comparison
          {labelIcon && !(selectedRange?.startDate && selectedRange?.endDate) && <>{labelIcon}</>}
        </ListItemText>
      </ListItemButton>
    )}
    {ranges.map((range, idx) => (
      <ListItemButton
        key={idx}
        onClick={() => setRange(range)}
        sx={{
          backgroundColor: customStyle?.labelBgColor || "",
          "&:hover": {
            background: (theme) =>
              customStyle?.labelBgOnHover || theme.palette.primary.light,
          },
          paddingRight: "5px",
          ...(isSameRange(range, selectedRange) && {
            backgroundColor: (theme) =>
              customStyle?.activeLabelBgColor || theme.palette.primary.dark,
            color: customStyle?.activeLableColor || "primary.contrastText",
            "&:hover": {
              backgroundColor: (theme) =>
                customStyle?.activeLabelBgColor || theme.palette.primary.dark,
            },
          }),
        }}>
        <ListItemText
          primaryTypographyProps={{
            variant: "body2",
            sx: {
              // fontWeight: isSameRange(range, selectedRange)
              //   ? 'bold'
              //   : 'normal',
              display: "flex",
              textWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "100px",
              fontSize: "1rem",
            },
          }}>
          {range.label}
          {labelIcon && isSameRange(range, selectedRange) && <>{labelIcon}</>}
        </ListItemText>
      </ListItemButton>
    ))}
    
  </List>
);

export default DefinedRanges;
