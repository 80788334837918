import { ThemeProvider } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useLocation } from "react-router-dom";
import darkTheme from "../themes/dark";
import lightTheme from "../themes/light";

export const useIsDarkMode = () => {
  const location = useLocation();
  return useMemo(() => /.*/.test(location.pathname), [location]);
};

export const ThemeProviderV2 = ({ children }: { children: ReactNode }) => {
  const isDarkMode = useIsDarkMode();
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      {children}
    </ThemeProvider>
  );
};
