import {useAuthUser} from "@frontegg/react";
import AJV from "ajv";
import {useState} from "react";
import {SetValueConfig, useForm} from "react-hook-form";
import {ParameterType} from "../../../enums";
import {useQueryParams} from "../../../hooks";
import {useRunEvents} from "../../../hooks/metrics/runEvents";
import {useBoard} from "../../../services/boards";
import {createRun} from "../../../services/runs/api";
import {useSlimRun} from "../../../services/runs/useRun";
import useGetTrigger from "../../../services/triggers/useGetTrigger";

import {CircuitBoard, Parameter, PipelineInput, Run, RunCreate, RunInput, RunInputValue,} from "../../../types";
import {formatDateString} from "../../../utils";
import {isInputValueValid, validateJsonField} from "./utils";

export const KFP_PARAM_MAX_LENGTH = 10000;

export type RunForm = {
  name: string;
  description: string;
  parameters?: Record<string, string>;
  inputs?: Record<string, RunInputValue>;
};

interface RunInputOrParameter {
  name: string;
  value?: string | string[] | Record<string, any> | null;
}

const convertParamsToFormValues: (
  params: RunInputOrParameter[]
) => Record<string, any> = (params) =>
  params.reduce(
    (obj, item) => Object.assign(obj, { [item.name]: item.value }),
    {}
  );

export type InputMethods = {
  selectedInput: PipelineInput | undefined | null;
  setSelectedInput: (value: PipelineInput | undefined | null) => void;
  selectedInputValue: RunInputValue | null | undefined;
  setSelectedInputValue: (value: RunInputValue | null | undefined) => void;
  resetSelectedInput: () => void;
  isInputValid: (inputName: string) => boolean;
  isAllInputsValid: () => boolean;
  onInputCompletion: () => void;
};

export type NewRunFormResult = {
  methods: any
  onSubmit: any;
  inputMethods: InputMethods;
  getRunCreateFromFormValues: (formValues: RunForm) => RunCreate;
};

enum Section {
  INPUTS = "inputs",
  PARAMETERS = "parameters",
}

const copyValuesFromData = (
    data: Run | RunCreate,
    {setValue, formState}: any,
    includeNameDescription = false
) => {
  if (includeNameDescription) {
    setValue("name", data.name, { shouldDirty: true });
    setValue("description", data.description, { shouldDirty: true });
  }
  Object.values(Section).forEach((section) => {
    if (!formState.dirtyFields[section]) {
      Object.entries(convertParamsToFormValues(data[section])).forEach(
        ([k, v]) =>
          setValue(`${section}.${k}`, v, {
            shouldDirty: true,
          })
      );
    }
  });
};

const useNewRunForm: (
  boardId: string,
  onSuccess: () => void,
  onError: (errors: object) => void,
  clonedRunId?: string | null
) => NewRunFormResult = (boardId, onSuccess, onError, clonedRunId) => {
  const user = useAuthUser();
  const triggerId = useQueryParams().get("triggerId");
  const [selectedInput, setSelectedInput] = useState<
    PipelineInput | undefined | null
  >(undefined);
  const [formRegistered, setFormRegistered] = useState(false);
  const setSelectedInputOnBoardQuerySuccess = (board: CircuitBoard) => {
    const pipeline = board.pipelines[0];
    if (selectedInput === undefined && pipeline.inputs.length > 0) {
      setSelectedInput(pipeline.inputs[0]);
    }
  };
  const runFormMethods = useForm<RunForm>({
    mode: "onChange",
    // setValue fails inside `useEffect`,
    // see https://github.com/react-hook-form/react-hook-form/issues/2578
    shouldUnregister: false,
  });
  const { register, setValue, watch, handleSubmit, formState } = runFormMethods;
  const { dirtyFields } = formState;
  const registerForm = (board: CircuitBoard) => {
    const pipeline = board.pipelines[0];
    register("name");
    const dateString = formatDateString(Date());
    setValue(
      "name",
      clonedRunId
        ? `Cloned from #${clonedRunId?.slice(0, 5)} at ${dateString}`
        : `${user.name}'s run at ${dateString}`,
      { shouldDirty: true }
    );
    register("description");
    setValue("description", "", { shouldDirty: true });

    pipeline.inputs.forEach((input) =>
      register(`inputs.${input.name}`, {
        validate: (data) => isInputValueValid(data, input.required),
        required: input.required,
      })
    );
    pipeline.parameters.forEach((parameter: Parameter) => {
      const name = `parameters.${parameter.name}`;
      const valueFromPipelineParam = parameter.value
        ? parameter.value
        : parameter.default === "null"
        ? null
        : parameter.default;
      const validateJsonFieldWithMessage: (value: string) => boolean | string =
        (value) => validateJsonField(value) || "Invalid JSON Value";
      // @ts-ignore
      register(name, {
        validate:
          parameter.type === ParameterType.JSON
            ? { jsonValidation: validateJsonFieldWithMessage }
            : parameter.type === ParameterType.BOOLEAN
            ? () => true
            : parameter.json_schema
            ? (v) => {
                const ajv = new AJV({
                  validateSchema: false,
                  strict: false,
                  coerceTypes: true,
                });
                const jsonSchema = JSON.parse(parameter.json_schema || "");
                delete jsonSchema.required;
                const validate = ajv.compile({ ...jsonSchema, required: [] });
                return validate(v)
                  ? true
                  : (validate?.errors || [])[0]?.message;
              }
            : {},
        maxLength: {
          value: KFP_PARAM_MAX_LENGTH,
          message: `Length exceeds ${KFP_PARAM_MAX_LENGTH}`,
        },
      });
      // @ts-ignore
      setValue(name, valueFromPipelineParam);
    });
    setFormRegistered(true);
  };
  const { data: board } = useBoard(boardId, {
    onSuccess: (data) => {
      if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
        registerForm(data);
        setSelectedInputOnBoardQuerySuccess(data);
      }
    },
  });

  useGetTrigger(triggerId, {
    enabled: !!triggerId,
    onSuccess: (data) => copyValuesFromData(data.data, runFormMethods, true),
    onError: () => onError({ message: `Unable to load trigger ${triggerId}` }),
    retry: 1,
  });
  useSlimRun(boardId, clonedRunId!, {
    enabled: !!clonedRunId && !!board && formRegistered,
    onSuccess: (data) => copyValuesFromData(data, runFormMethods),
    onError: () => onError({ message: `Unable to load run ${clonedRunId}` }),
    retry: 1,
  });

  const isInputRequired: (inputName: string) => boolean = (inputName) => {
    const input =
      board &&
      board.pipelines[0].inputs.find((input) => input.name === inputName);
    return Boolean(input && input.required);
  };

  const getInputValue: (inputName: string) => RunInputValue = (inputName) =>
    watch(`inputs.${inputName}`);
  const setInputValue: (runInput: RunInput, config?: SetValueConfig) => void = (
      runInput,
      config
      // @ts-ignore
  ) => setValue(`inputs.${runInput.name}`, runInput.value, config);
  const resetInputValue: (inputName: string) => void = (inputName) =>
      // @ts-ignore
    setValue(`inputs.${inputName}`, undefined);
  const isInputValid: (inputName: string) => boolean = (inputName) => {
    const input = getInputValue(inputName);
    return input && isInputValueValid(input);
  };
  const isAllInputsValid: () => boolean = () => {
    if (!board) {
      return false;
    }
    if (board.pipelines[0].inputs.length === 0) {
      return true;
    }
    const inputs = watch("inputs");
    if (!inputs) {
      return false;
    }
    return (
      Object.values(inputs).length > 0 &&
      Object.entries(inputs).every(([k, v]) =>
        isInputValueValid(v, isInputRequired(k))
      )
    );
  };
  const getNextIncompleteInputName: () => string | undefined = () => {
    const inputs = watch("inputs");
    return (Object.entries(inputs || {}).find(
      ([k, v]) => !isInputValueValid(v, isInputRequired(k))
    ) || [])[0];
  };
  const getRunCreateFromFormValues: (formValues: RunForm) => RunCreate = (
    formValues
  ) => ({
    name: formValues.name,
    description: formValues.description,
    inputs: formValues.inputs
      ? Object.entries(formValues.inputs).map(([name, value]) => ({
          name,
          value,
        }))
      : [],
    parameters: Object.entries(formValues.parameters || {}).map(
      ([name, value]) => ({ name, value })
    ),
  });
  const onSubmit = handleSubmit(
    async (formValues: RunForm) => {
      try {
        await createRun(boardId, getRunCreateFromFormValues(formValues));
        onSuccess();
      } catch (error: any) {
        onError(error);
      }
    },
    (errors) =>
      onError({ message: `Form is invalid: ${JSON.stringify(errors)}` })
  );
  const selectedInputValue = selectedInput && getInputValue(selectedInput.name);
  const resetSelectedInput = () =>
    selectedInput && resetInputValue(selectedInput.name);
  const setSelectedInputValue = (inputValue: RunInputValue) =>
    selectedInput &&
    setInputValue({
      name: selectedInput.name,
      value: inputValue,
    });
  const inputValue = (runFormMethods.getValues()?.inputs || {})[
      // @ts-ignore
    selectedInput?.name
  ];
  const { chooseDataSourceEvent } = useRunEvents({
    boardId,
    inputType: inputValue?.type,
    inputName: selectedInput?.name,
  });
  const onInputCompletion = () => {
    chooseDataSourceEvent();
    if (isAllInputsValid()) {
      setSelectedInput(null);
    } else {
      const nextInputNameToFill = getNextIncompleteInputName();
      if (nextInputNameToFill) {
        const nextInput =
          board &&
          board.pipelines[0].inputs.find(
            (input) => input.name === nextInputNameToFill
          );
        if (nextInput) {
          setSelectedInput(nextInput);
        }
      }
    }
  };
  const inputMethods = {
    selectedInput,
    setSelectedInput,
    selectedInputValue,
    setSelectedInputValue,
    resetSelectedInput,
    isInputValid,
    isAllInputsValid,
    onInputCompletion,
  } as InputMethods;

  return {
    methods: runFormMethods,
    onSubmit,
    inputMethods,
    getRunCreateFromFormValues,
  };
};

export default useNewRunForm;
