import { FC } from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import { useHistory } from "react-router";

const ErrorComponent: FC = () => {
  const history = useHistory();
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={8}
      >
        <Box marginBottom={2}>
          <img alt="logo" src={`/favicon.svg`} height={90} width={90} />
        </Box>
        <Typography variant="h5">Oops, something went wrong.</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Our team will be notified and will investigate the issue. Please
          refresh the site.
        </Typography>
        <Box marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.go(0)}
          >
            Go to homepage
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorComponent;
