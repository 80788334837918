import {Autocomplete, Box, Chip, TextField, Typography} from "@mui/material";
import {FC, useState} from "react"

export const FilterTitles: FC<{
    onChange: (titles: string[]) => void;
    value: string[];
}> = ({onChange, value}) => {
    const [currentSelection, setCurrentSelection] = useState<string[]>([]);
    return (
        <Box>
            <Typography variant="body2_v2">Title</Typography>
            <Autocomplete
                value={currentSelection.length ? currentSelection : value}
                multiple
                id="tags-filled"
                options={[]}
                defaultValue={[]}
                freeSolo
                sx={{
                    ".MuiInputBase-root": {
                        paddingTop: 0,
                    },
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color="primary"
              variant="filled"
              size="small"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={(_e: any, options, action) => {
          if (action === "removeOption" || action === "clear") {
            setCurrentSelection([]);
            onChange(options as string[]);
          }
          setCurrentSelection(options as string[]);
        }}
        onBlur={(_e) => {
          onChange(currentSelection);
          setCurrentSelection([]);
        }}
        renderInput={({ inputProps, ...params }) => (
          <TextField
            {...params}
            variant="filled"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            placeholder="Type title to filter."
            inputProps={{
              ...inputProps,
              onChange: (e: any) => {
                if (inputProps?.onChange) {
                  inputProps?.onChange(e);
                }
              },
            }}
          />
        )}
      />
    </Box>
  );
};
