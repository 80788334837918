import { useHistory } from "react-router";

import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";
import { ASSORTMENT_INTELLIGENCE_ROUTE } from "../Router";
import { AssortmentIntelligenceIcon } from "../../../assets";

export const AssortmentIntelligenceNavItem: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={tab === ASSORTMENT_INTELLIGENCE_ROUTE}
      icon={<AssortmentIntelligenceIcon />}
      onClick={() => {
        setTab(ASSORTMENT_INTELLIGENCE_ROUTE);
        history.push(ASSORTMENT_INTELLIGENCE_ROUTE);
      }}
      label="Assortment"
    />
  );
};
