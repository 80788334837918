import { useMutation } from "react-query";
import { TenantConfiguration } from "../../types/tenants.ts";
import { APIError } from "../../services/apiRequest.ts";
import {
  CreateTenantConfigRequers,
  createTenantCongifuration,
} from "../../services/tenants/useTenantConfiguration.ts";
import { Switch, Typography } from "@mui/material";
import { ITenantsResponse } from "@frontegg/rest-api";
import { useCallback } from "react";
import { useSnackbar } from "notistack";

const ToggleConfig = ({
  field,
  value,
  tenant,
  tenantConfig,
  refetch,
}: {
  field: string;
  value?: boolean;
  tenant?: ITenantsResponse;
  tenantConfig?: TenantConfiguration;
  refetch?: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const mutate = useMutation<
    TenantConfiguration,
    APIError,
    CreateTenantConfigRequers
  >(createTenantCongifuration);

  console.warn({ tenantConfig });

  const toggleSelection = useCallback(() => {
    tenantConfig &&
      mutate
        .mutateAsync({
          tenantId: tenant?.tenantId || "",
          data: {
            description: `Clicked on toggle ${field}`,
            configuration: { ...tenantConfig.configuration, [field]: !value },
          },
        })
        .then((_res) => {
          refetch && refetch();
          enqueueSnackbar("Changed successfully.", {
            variant: "success",
          });
        })
        .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
  }, [tenant, field, tenantConfig]);

  return (
    <>
      <Switch value={value} onChange={toggleSelection} />
      <Typography variant="body5">
        You will see the result of this action only after refresh
      </Typography>
    </>
  );
};

export default ToggleConfig;
