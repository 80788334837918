import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Launch from "@mui/icons-material/Launch";
import dayjs from "../../../../utils";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import usePublishedRun from "../../../Run/RunV2/hooks/usePublishedRun";
import { PublishedRun } from "../../../../types";
import { useBoard } from "../../../../services/boards";
import { unPublish } from "../../../../services/runs/api";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformRole } from "../../../../enums";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      borderTop: `${theme.spacing(0.5)} solid ${theme.palette.success.main}`,
    },
    cardActions: {
      justifyContent: "flex-end",
    },
    successColor: {
      color: theme.palette.success.main,
    },
  })
);

type PublishedCardProps = {
  boardId: string;
};

function Skeletons() {
  return (
    <>
      <Skeleton width="80%" />
      <Skeleton width="40%" />
    </>
  );
}

const PublishedRunDetails: FC<{ publishedRun: PublishedRun }> = ({
  publishedRun,
}) => {
  const classes = useStyles();
  const { refetch } = usePublishedRun(publishedRun.run.circuitboard_id, {
    refetchOnMount: false,
    keepPreviousData: false,
  });
  const unpublishMutation = useMutation(() =>
    unPublish(publishedRun.run.circuitboard_id)
  );
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Box display="flex">
          <Box marginRight={1} className={classes.successColor}>
            <Launch />
          </Box>
          <Typography variant="h6">{publishedRun.run?.name}</Typography>
        </Box>
        <Typography variant="body1">
          {`Completed ${dayjs
            .utc(publishedRun.run?.finished_at)
            .fromNow()} by ${publishedRun.run?.user_name}`}
        </Typography>
        <Typography variant="body1">
          {`Published ${dayjs.utc(publishedRun?.created_at).fromNow()} by ${
            publishedRun?.user_name
          }`}
        </Typography>
      </Box>
      <Stack marginLeft={1} alignItems="center" justifyContent="start">
        <Typography variant="subtitle2">Published</Typography>
        <Tooltip title="Unpublish board runs">
          <Box>
            {/*// @ts-ignore*/}
            <AuthorizedContent
              requiredRoles={[PlatformRole.NoogataAdministrator]}
            />
            <IconButton
              disabled={unpublishMutation.isLoading}
              onClick={(e) => {
                e.stopPropagation();
                unpublishMutation
                  .mutateAsync()
                  .then(() => {
                    enqueueSnackbar("Run unpublished successfully", {
                      variant: "success",
                    });
                    return refetch();
                  })
                  .catch(() =>
                    enqueueSnackbar("Run unpublished failed", {
                      variant: "error",
                    })
                  );
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    </Box>
  );
};
const PublishedCard: FC<PublishedCardProps> = ({ boardId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: board, isLoading: isBoardLoading } = useBoard(boardId);
  const {
    data: publishedRun,
    isLoading: isPublishedRunLoading,
    isError,
  } = usePublishedRun(boardId);
  if (isBoardLoading || board?.flow) return null;
  return (
    <Grid item xs={12}>
      <Typography variant="h6" marginBottom={1}>
        Published Run
      </Typography>
      <Card
        className={classes.indicator}
        onClick={() =>
          board?.published_run_id &&
          history.push(`/boards/${boardId}/runs/${board?.published_run_id}`)
        }
      >
        <CardActionArea>
          <CardContent>
            {isBoardLoading || isPublishedRunLoading ? (
              <Skeletons />
            ) : publishedRun && !isError ? (
              <PublishedRunDetails publishedRun={publishedRun} />
            ) : (
              <Typography>
                This board doesn&apos;t have a published run
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default PublishedCard;
