import { useQuery, UseQueryOptions } from "react-query";
import { listTriggers } from "./api";
import { APIError } from "../apiRequest";
import { Trigger } from "../../types";

export default function useListTriggers<T>(
  options?: UseQueryOptions<Trigger[], APIError, T>
) {
  return useQuery<Trigger[], APIError, T>(
    "ListTriggers",
    () => listTriggers(),
    options
  );
}
