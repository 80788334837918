import { Collapse } from "@mui/material";

import { useHistory, useLocation } from "react-router-dom";
import InputsTable from "../../../../components/InputsTable";
import { useRun } from "../../../../services/runs";
import { BoardAndRunIds, MergedInput } from "../../../../types";
import InputsDropdown from "./InputsDropdown";
import { FC, useState } from "react";

const Inputs: FC<BoardAndRunIds> = ({ boardId, runId }) => {
  const { data: run } = useRun(boardId, runId);
  const [open, setOpen] = useState(true);

  const history = useHistory();
  const location = useLocation();

  const goToInputBlock = (inputConfiguration: Record<string, any>) => {
    const boardId = inputConfiguration?.board_id;
    const runId = inputConfiguration?.run_id;
    history.push(`/boards/${boardId}/runs/${runId}/overview`);
  };

  const goToInput = (name: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("name", name.replace(/ /g, "_").toLowerCase());
    queryParams.set("type", "input");
    history.push(`data?${queryParams.toString()}`);
  };

  const handleInputRowClick = ({ input }: { input: MergedInput }) => {
    const type = input.value?.type;
    if (input.value && input.value.configuration && type === "BOARD") {
      goToInputBlock(input.value.configuration);
    } else {
      goToInput(input.name);
    }
  };

  return run ? (
    open ? (
      <Collapse in={open}>
        <InputsTable
          boardId={boardId}
          inputs={run?.inputs}
          run_inputs={run?.run_inputs}
          run_status={run?.status}
          onClick={handleInputRowClick}
          open={open}
          setOpen={setOpen}
        />
      </Collapse>
    ) : (
      <InputsDropdown inputs={run?.inputs} open={open} setOpen={setOpen} />
    )
  ) : null;
};

export default Inputs;
