import ParameterEditorField from "./ParameterEditorField";
import ParameterTextField from "./ParameterTextField";
import ParameterBooleanField from "./ParameterBooleanField";
import ParameterModeField from "./ParameterModeField";
import ParameterNumberField from "./ParameterNumberField";
import ParameterIntegerField from "./ParameterIntegerField";
import ParameterListField from "./ParameterListField";
import {ParameterType} from "../../../enums";
import {ParameterFieldProps} from "./types";
import {FC} from "react"

const TYPE_TO_FIELD_COMPONENT: Record<ParameterType, FC<any>> = {
  [ParameterType.JSON]: ParameterEditorField,
  [ParameterType.BOOLEAN]: ParameterBooleanField,
  [ParameterType.INTEGER]: ParameterIntegerField,
  [ParameterType.FLOAT]: ParameterNumberField,
  [ParameterType.STRING]: ParameterTextField,
  [ParameterType.LIST]: ParameterListField,
};

const getComponentToRender: (name: string, type?: ParameterType) => FC<any> = (
  name,
  type
) => {
  if (name.endsWith("mode")) {
    return ParameterModeField;
  }
  return type ? TYPE_TO_FIELD_COMPONENT[type] : ParameterTextField;
};

const ParameterField: FC<ParameterFieldProps> = ({
  parameterType,
  ...props
}) => {
  const FieldComponent = getComponentToRender(props.name, parameterType);
  return (
    <FieldComponent {...props} value={props.value === 0 ? "0" : props.value} />
  );
};
export default ParameterField;
