import { convertNumberToShortFormat } from "../utils";

const ChartTooltip = ({
  series,
  dataPointIndex,
  legend,
  categories,
  graphType,
}: any) => {
  return (
    <div className="tooltip-container">
      {series.map((serie: any, index: number) =>
        !serie ||
        convertNumberToShortFormat(
          serie[dataPointIndex],
          serie.type === "growth"
        ) === "NaN" ? (
          ""
        ) : (
          <div key={index} className={`tooltip-row`}>
            <span className={`tooltip-marker-${index + 1}`}></span>
            <span>
              {graphType === "bar" ? legend[index] : categories[dataPointIndex]}
            </span>
            <span className={`tooltip-value-${index + 1}`}>
              {convertNumberToShortFormat(
                serie[dataPointIndex],
                serie.type === "growth"
              )}
            </span>
          </div>
        )
      )}
      <div className="tooltip-arrow"></div>
    </div>
  );
};
export default ChartTooltip;
