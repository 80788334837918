import {Box, BoxProps, MenuItem, Select, SelectChangeEvent,} from "@mui/material";
import {useAuthUserOrNull, useTenantsActions, useTenantsState,} from "@frontegg/react-hooks/auth";
import {useHistory} from "react-router-dom";
import {useQueryClient} from "react-query";
import {FC} from "react"

export const TenantSelection: FC<BoxProps> = (props) => {
  const user = useAuthUserOrNull();
  const history = useHistory();
  const {tenants: tenantsFromState} = useTenantsState();
  const {switchTenant} = useTenantsActions();
  const queryClient = useQueryClient();

  const handleChangeTenant = (event: SelectChangeEvent) => {
    switchTenant({tenantId: event.target.value as string});
    // Clear all React-Query caches
    queryClient.clear();
    history.push("/");
  };

  return (
    <Box {...props}>
      <Select
        variant="filled"
        id="tenant-select-component"
        value={user?.tenantId}
        onChange={handleChangeTenant}
        label="Organization"
      >
        {[...tenantsFromState]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((currentTenant) => (
            <MenuItem
              key={currentTenant.tenantId}
              value={currentTenant.tenantId}
            >
              {currentTenant.name}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};
