import { FormControl, MenuItem, Select } from "@mui/material";
import { useInsightsContext } from "./hooks/selectors";
import { StatsPeriods } from "./types";
import { Icon } from "../../components/Icon";

const SelectStatsPeriod = () => {
  const { statsPeriod, setStatsPeriod } = useInsightsContext();

  return (
    <>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          IconComponent={(props) => (
            <Icon src="insights/arrows.svg" {...props} />
          )}
          size="small"
          value={statsPeriod}
          onChange={(e) => {
            setStatsPeriod(e.target.value as StatsPeriods);
          }}
          sx={{ border: 0 }}
          renderValue={(val) => <>Total Insight Sales - {val}</>}
        >
          <MenuItem value="7 days" sx={{ fontSize: 15 }}>
            7 Days
          </MenuItem>
          <MenuItem value="30 days" sx={{ fontSize: 15 }}>
            30 Days
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default SelectStatsPeriod;
