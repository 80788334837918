import { Box, Divider, Stack, Typography } from "@mui/material";
import theme from "../../../themes/light.ts";
import SearchBox from "./SearchBox.tsx";
import FiltersAndSort from "./FiltersAndSort.tsx";
import TopCards from "./TopCards.tsx";
import SelectDropDown from "../../../components/SelectDropDown.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import { useHistory, useLocation } from "react-router-dom";

const TopBar = () => {
  const {
    spaceId,
    brand,
    productGroups,
    selectedProductGroup,
    setSelectedProductGroup,
    selectedGroupBy,
    setSelectedGroupBy,
    salesAssistantList,
  } = useSalesAssistant();
  const history = useHistory();
  const { pathname } = useLocation();

  const selectedSpaceName =
    spaceId &&
    salesAssistantList?.data?.find((item) => item.space_id === spaceId)
      ?.space_name;

  return (
    <>
      <Stack
        borderBottom={`1px solid ${theme.palette.borders.lines}`}
        direction="row"
        justifyContent="space-between"
        spacing={2}
        padding="12px 40px"
        alignItems="center"
        overflow="hidden"
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack direction="row" alignItems="center" gap="16px">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Typography
                variant="headline3"
                display="flex"
                sx={{ cursor: "pointer" }}
                color={
                  selectedSpaceName
                    ? theme.palette.primary.main
                    : theme.palette.text.read
                }
                onClick={() => {
                  setSelectedGroupBy("spaces");
                  history.push(`/${pathname.split("/")[1]}`);
                }}
              >
                Sales Assistant
              </Typography>
              {selectedSpaceName && (
                <Typography variant="headline3">
                  {` / ${selectedSpaceName}`}
                </Typography>
              )}
            </Box>
            {productGroups?.length && (
              <>
                <Divider orientation="vertical" flexItem />
                <SelectDropDown
                  value={selectedProductGroup}
                  items={
                    productGroups?.map((item) => ({
                      value: item.id,
                      text: item.name,
                    })) ?? []
                  }
                  onChange={setSelectedProductGroup}
                />
              </>
            )}
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <SearchBox />
            <Box>
              <FiltersAndSort />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        borderBottom={`1px solid ${theme.palette.borders.lines}`}
        padding="12px 40px"
      >
        <TopCards />

        {
          // Not inside a flow
          !spaceId && !brand && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#75717B",
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                View By:
              </Typography>

              <SelectDropDown
                value={selectedGroupBy}
                items={[
                  { value: "asins", text: "Products" },
                  { value: "spaces", text: "Spaces" },
                  // { value: "brands", text: "Brand" },
                  // { value: "categories", text: "Amazon Category" },
                ]}
                onChange={setSelectedGroupBy}
              />
            </Box>
          )
        }
      </Stack>
    </>
  );
};

export default TopBar;
