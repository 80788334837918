import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";

export const EmptyFlowsComponent = () => {
  const history = useHistory();

  return (
    <IllustrationPlaceholder
      text={"You haven't added any flows yet"}
      imageHeight={154}
      imageWidth={266}
      actions={
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: "grey.400", marginY: (t) => t.spacing(1.25) }}
          >
            add your first flow to create an ongoing use case that would be
            synced to the data warehouse
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push("/flows/create");
            }}
          >
            Add new flow
          </Button>
        </Box>
      }
    />
  );
};
