import apiRequest from "../apiRequest";
import { Schedule, ScheduleCreate } from "../../types";

export async function createSchedule(data: ScheduleCreate): Promise<Schedule> {
  return await apiRequest<Schedule>("schedules", "POST", undefined, data);
}

export async function deleteSchedule(scheduleId: string): Promise<Schedule> {
  return await apiRequest<Schedule>(`schedules/${scheduleId}`, "DELETE");
}
