import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Button, Tooltip } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformPermission } from "../../../../enums";
import { useRunEvents } from "../../../../hooks/metrics/runEvents";
import { RunHeaderButtonProps } from "../types";

const CloneButton: FC<RunHeaderButtonProps> = ({ boardId, runId }) => {
  const { beginFlowEvent } = useRunEvents();
  return (
    <AuthorizedContent requiredPermissions={[PlatformPermission.RUNS_CREATE]}>
      <Tooltip title="Clone">
        <Button
          variant="outlined"
          onClick={() => beginFlowEvent({ boardId, runId, isClone: true })}
          component={Link}
          to={`/boards/${boardId}/runs/new?runId=${runId}`}
          sx={{
            maxWidth: "48px",
            minWidth: "48px",
            height: "40px",
            color: "text.primary",
            borderColor: "text.secondary",
          }}
        >
          <FileCopyIcon />
        </Button>
      </Tooltip>
    </AuthorizedContent>
  );
};

export default CloneButton;
