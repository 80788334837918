import React from "react";
import { Stack } from "@mui/material";
import { Header } from "./Header";
import TaskBoard from "./TaskBoard";
import theme from "../../themes/light";
// import { SalesDrawer } from "../SalesAssistant/List/Drawer/SalesDrawer";
import { useQueryParams } from "../../hooks";
import { SalesQueryParams } from "../SalesAssistant/constants";
import { useHistory } from "react-router-dom";
import { SalesDrawer } from "../SalesAssistant/List/Drawer/SalesDrawer";

export const Tasks: React.FC = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  return (
    <>
      <Stack
        sx={{
          background: theme.palette.grey[50],
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <Header />
        <TaskBoard />
        <SalesDrawer
          onClose={() => {
            queryParams.delete(SalesQueryParams.InsightId);
            history.replace({
              search: queryParams.toString(),
            });
          }}
          open={queryParams.has(SalesQueryParams.InsightId)}
        />
      </Stack>
    </>
  );
};
