import { Box, Stack, Typography } from "@mui/material";
import {
  CellProps,
  Column,
  Row,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useHistory } from "react-router-dom";
import dayjs from "../../utils.ts";
import { UncontrolledTable } from "../../components";
import useListFlow from "../../services/Flows/useListFlows";
import { EmptyFlowsComponent } from "./EmptyFlowsComponent";
import { CircuitBoard, Flow, Run, Schedule } from "../../types";
import { FlowListItem } from "./FlowListItem";
import {
  createTriggersTree,
  getTriggerPathFromNode,
  TriggerNode,
} from "../Circuitboards/BoardsTable/BoardsTable";
import { TriggerCell } from "../Circuitboards/BoardsTable/TriggerCell";
import useListTriggers from "../../services/triggers/useListTriggers";
import { UserAvatar } from "../Circuitboards/BoardsTable/UserCellComponent";
import { Status } from "../../enums";
import RunStatus from "../Run/RunV2/RunHeader/RunStatus";
import { FC, useMemo } from "react";

type MaybeBlock = CircuitBoard | null | undefined;
type MaybeRun = Run | null | undefined;

function sortByRun(rowA: Row<Flow>, rowB: Row<Flow>): number {
  const toDayJs = (run?: Run): dayjs.Dayjs =>
    run ? dayjs(run.created_at) : dayjs("1900-01-01");

  const dateA = toDayJs(rowA.values.lastRun);
  const dateB = toDayJs(rowB.values.lastRun);
  // @ts-ignore
  return dateB.isSameOrBefore(dateA) ? 1 : -1;
}

function getScheduleDay(schedule: Schedule): number {
  return dayjs(schedule.start_date).day();
}

function compareSchedules(scheduleA: Schedule, scheduleB: Schedule): number {
  const dayA = getScheduleDay(scheduleA);
  const dayB = getScheduleDay(scheduleB);
  return dayB - dayA;
}

function getFirstScheduledBlock(flow: Flow) {
  const filterSchedule = ({ schedules }: CircuitBoard) =>
    schedules && schedules.length;
  return flow.blocks
    .filter(filterSchedule)
    .reduce((firstBlock: CircuitBoard | null, block) => {
      if (!firstBlock || !firstBlock.schedules || !block.schedules)
        return block;
      const comparsion = compareSchedules(
        firstBlock.schedules[0],

        block.schedules[0]
      );
      if (comparsion === -1) {
        return firstBlock;
      }
      return block;
    }, null);
}

const pageSize = 50;

const FlowsTable: FC<object> = () => {
  const { isLoading, data, error } = useListFlow();
  const history = useHistory();
  const { items: flows = [] } = data || {};
  const { data: triggers = {} } = useListTriggers<Record<string, TriggerNode>>({
    select: createTriggersTree,
  });
  const columns: Column<Flow>[] = useMemo(
    () => [
      {
        id: "flow",
        Header: "Flow",
        accessor: (flow) => flow,
        Cell: ({ value: flow }: { value?: Flow }) =>
          flow && (
            <FlowListItem
              flow={flow}
              listItemProps={{
                sx: {
                  ".MuiListItemButton-root:hover": {
                    backgroundColor: "transparent",
                  },
                },
              }}
            />
          ),
      },
      {
        id: "scheduled",
        Header: "Trigger",
        maxWidth: 70,
        disableSortBy: true,
        accessor: (f: Flow) => getFirstScheduledBlock(f),
        Cell: ({ value, row }: CellProps<Flow, MaybeBlock>) => {
          if (!value) return null;
          const path = getTriggerPathFromNode(triggers[value.identifier]).map(
            (boardId) =>
              row.original?.blocks?.find((b) => b.identifier === boardId)
                ?.displayName || boardId
          );
          return (
            <TriggerCell
              path={path}
              schedule={value.schedules ? value.schedules[0] : undefined}
            />
          );
        },
      },
      {
        id: "lastRun",
        Header: "Last run",
        accessor: (f: Flow): MaybeRun => f.getFlowLastBlock()?.last_run,
        minWidth: 60,
        sortType: sortByRun,
        Cell: ({ value, row }: CellProps<Flow, MaybeRun>) => {
          const flowStatus = row.original.status;
          return (
            <Stack spacing={1}>
              {value && (
                // @ts-ignore
                <Typography>{dayjs.utc(value.created_at).fromNow()}</Typography>
              )}
              {value?.user_inf && <UserAvatar user={value?.user_inf} />}
              {flowStatus === Status.UNKNOWN ? null : (
                <RunStatus run={{ status: flowStatus } as Run} />
              )}
            </Stack>
          );
        },
      },
    ],
    []
  );
  const instance = useTable(
    {
      columns,
      data: flows || [],
      autoResetSortBy: false,
      autoResetPage: false,
      // @ts-ignore
      initialState: { pageSize },
    },
    useSortBy,
    usePagination
  );

  return !isLoading && flows?.length === 0 ? (
    <EmptyFlowsComponent />
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <UncontrolledTable
        instance={instance}
        data={flows}
        isLoading={isLoading}
        columns={columns}
        error={error || null}
        tableProps={{
          stickyHeader: true,
        }}
        disablePagination
        tableRowPropsGetter={(row) => ({
          onClick: () => {
            const flow = row.original;
            history.push(`/flows/${flow.id}/configuration`);
          },
        })}
      />
    </Box>
  );
};

export default FlowsTable;
