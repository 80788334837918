import {Paper} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      minHeight: "100vh",
      height: "100%",
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
  })
);

type NavbarPaperProps = {
  children: JSX.Element;
};

const NavbarPaper: FC<NavbarPaperProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.paper}>
      {children}
    </Paper>
  );
};

export default NavbarPaper;
