import { FC } from "react";
import { Icon } from "@mui/material";
import { StyledAvatar, StyledCardHeader } from "../StyledComponents";
import { ConnectAmazonStoreButton } from "../Buttons";

const PendingCardHeader: FC = () => (
  <StyledCardHeader
    title="Log in to Amazon to unlock all Noogata’s blocks"
    subheader="You will be redirected to the Amazon login page and be back here once authorized"
    avatar={
      <StyledAvatar variant="rounded">
        <Icon sx={{ textAlign: "center" }}>
          <img
            alt="amazon"
            style={{
              display: "flex",
              height: "inherit",
              width: "inherit",
            }}
            src={`/amazon-icon.svg`}
          />
        </Icon>
      </StyledAvatar>
    }
    action={<ConnectAmazonStoreButton />}
  />
);

export default PendingCardHeader;
