import {ListItemIcon, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {AuthorizedContent} from "../../../components/AuthorizedContent";
import {PlatformPermission} from "../../../enums";
import {RunMenuItemProps} from "./types";
import {useRunEvents} from "../../../hooks/metrics/runEvents";
import {FC} from "react"

const CloneMenuItem: FC<RunMenuItemProps> = ({boardId, runId, onClick}) => {
    const {beginFlowEvent} = useRunEvents();
    return (
        // @ts-ignore
        <AuthorizedContent
            requiredPermissions={[PlatformPermission.RUNS_CREATE]}
            render={(isAuthz) => (
                <MenuItem
                    disabled={!isAuthz}
                    onClick={() => {
                        beginFlowEvent({boardId, runId, isClone: true});
                        onClick();
                    }}
                    component={Link}
                    to={`/boards/${boardId}/runs/new?runId=${runId}`}
                >
                    <ListItemIcon>
                        <FileCopyIcon/>
                    </ListItemIcon>
                    Clone
        </MenuItem>
      )}
    />
  );
};

export default CloneMenuItem;
