import { useContext, useMemo } from "react";
import _, { mapValues, merge } from "lodash";
import {
  Filter,
  FilterColumns,
  FilterMapping,
  FilterUI,
  InsightRead,
  NewSortOptions,
  Sort,
  TransformedInsight,
} from "../types";
import { FrontEggTeamMember } from "../../../types";
import { ByValueFilters, Column2Display, EMPTY_FILTERS } from "../constants";
import { insightsContext } from "../InsightsProvider";
import { useAuthorizedUsers } from "../../../hooks/useUsers";
import { filterHandlers } from "../filterUtils";
import { useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";
import { mapRevenue } from "../List/InsightsListItem";

const typeOfFilters = {
  sales7D: "sales7D",
  changeNumber7D: "numberChange7D",
  changePercentage7D: "percentageChange7D",
  sales30D: "sales30D",
  changeNumber30D: "numberChange30D",
  changePercentage30D: "percentageChange30D",
};

const applySort = (
  insights: TransformedInsight[],
  sort: Sort,
  revenueOverTimeEnabled: boolean = false
) => {
  let sorted = insights;
  const keyName = typeOfFilters[(sort?.column || "sales7D") as NewSortOptions];
  if (revenueOverTimeEnabled) {
    const insightsWithoutData = insights.filter((i: any) => i[keyName] == null);
    const insightsWithData = insights.filter((i: any) => i[keyName] != null);
    const filters: any = [(data: any) => data[keyName]];
    if (!sort.isDirty) {
      filters.push(
        (insight: TransformedInsight) => insight.status || undefined
      );
    }
    return [
      ..._.orderBy(insightsWithData, filters, sort.ascending ? "asc" : "desc"),
      ...insightsWithoutData,
    ];
  }
  if (sort.column === "revenueValue" && !sort.ascending) {
    sorted = _.sortBy(insights, sort?.column);
    sorted.reverse();
    sorted = _.sortBy(sorted, "status");
  } else {
    sorted = _.sortBy(insights, sort?.column);
  }
  if (!sort?.ascending && sort.column !== "revenueValue") {
    sorted.reverse();
  }
  return sorted;
};

const applyFilters = (
  insights: TransformedInsight[],
  filterOut: FilterMapping,
  allUsers: FrontEggTeamMember[]
): TransformedInsight[] =>
  insights?.filter((insight) =>
    Object.entries(filterHandlers).every(([handlerKey, filterFunc]) => {
      const filterName = handlerKey as FilterColumns;
      const cond = filterFunc(
        filterName,
        filterOut[filterName],
        insight,
        allUsers
      );
      return cond;
    })
  );

const applySearch = (insights: TransformedInsight[], search: string) => {
  const reg = new RegExp(search, "i");
  return insights?.filter((insight) => reg.test(insight.cardText));
};

export const useInsightsContext = () => useContext(insightsContext);
const useSelectedInsightsSet = () => {
  const { selectedInsights } = useInsightsContext();
  return useMemo(() => new Set(selectedInsights), [selectedInsights]);
};

export const useDisplayList = (): TransformedInsight[] => {
  const { insights, filterOut, search, sort, selectedProductGroup } =
    useInsightsContext();
  const selectedInsightsSet = useSelectedInsightsSet();
  const members = useAuthorizedUsers();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  return useMemo(
    () =>
      _(insights)
        .thru((list) => applyFilters(list, filterOut, members))
        .thru((list) => applySearch(list, search))
        .thru((list) => applySort(list, sort, revenueOverTimeEnabled))
        .map((display) => ({
          ...display,
          isChecked: selectedInsightsSet.has(display.id),
        }))
        .value(),
    [
      insights,
      filterOut,
      selectedInsightsSet,
      search,
      sort,
      members,
      selectedProductGroup?.id,
    ]
  );
};

// returns object containing insights that contain the specified field "column" unique values
// and true as value, e.g: {AD_BOOSTER: true, DIGITAL_SHELF: true}
export const generateFilter = (
  filterName: FilterColumns,
  desiredValue: boolean,
  insights: TransformedInsight[],
  members: Array<FrontEggTeamMember>
): Filter => {
  if (ByValueFilters.includes(filterName)) {
    // generate the filter automatically from flat insight values.
    return _(insights)
      .keyBy(filterName)
      .omitBy((_v, key) => !key)
      .mapValues(() => desiredValue)
      .value();
  }
  if (filterName === "assignedTo") {
    return _(members)
      .keyBy("id")
      .mapValues(() => desiredValue)
      .value();
  }
  throw Error(`filter name ${filterName} os not known to the system.`);
};

const toSortedKeys = (obj: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(obj).sort(([key_1], [key_2]) => key_1.localeCompare(key_2))
  );
export const useTransformedFiltersForUI = (): FilterUI[] => {
  const { filterOut, insights, isLoadingInsights } = useInsightsContext();
  const members = useAuthorizedUsers();
  return useMemo(() => {
    const filtersBase: FilterMapping = mapValues(EMPTY_FILTERS, (_value, key) =>
      generateFilter(key as FilterColumns, true, insights, members)
    );

    const filters = merge({}, filtersBase, filterOut);

    return Object.entries(filters).map(([column, values]) => ({
      column: column as FilterColumns,
      values: {
        All: _.every(values),
        ...(column === "assignedTo" ? values : toSortedKeys(values)),
      },
      displayName: Column2Display[column as FilterColumns],
    }));
  }, [filterOut, insights, isLoadingInsights, members]);
};

export const useTransformedFiltersForUIByColumn = (
  name: FilterColumns
): FilterUI => {
  const filtersArr = useTransformedFiltersForUI();
  return useMemo(
    () => filtersArr.filter((item) => item.column === name),
    [filtersArr]
  )[0];
};

const sumValueByInsightType = (insights: InsightRead[]) => {
  const val = _(insights).sumBy("revenueValue");
  return val;
};

export const useDisplayedSelectedInsights = (): Set<string> => {
  const { selectedInsights } = useInsightsContext();
  const displayList = useDisplayList();
  return useMemo(
    () =>
      new Set(
        selectedInsights.filter((_id) =>
          displayList.find(({ id }) => id === _id)
        )
      ),
    [selectedInsights, displayList]
  );
};

export const useIsAllDisplayedSelected = () => {
  const selectedCount = useDisplayedSelectedInsights().size;
  const displayList = useDisplayList();
  return displayList.length === selectedCount && displayList.length !== 0;
};

export const useSelectedSum = () => {
  const displayList = useDisplayList();
  const displayedSelectedInsights = useDisplayedSelectedInsights();
  return useMemo(() => {
    const selectedDisplayed = displayList.filter(({ id }) =>
      displayedSelectedInsights.has(id)
    );
    return {
      potential: _(selectedDisplayed).sumBy((insight) => insight.revenueValue),
    };
  }, [displayList, displayedSelectedInsights]);
};

export const useValuesSumByInsightType = () => {
  const insights = useDisplayList();
  return useMemo(() => {
    const potential = sumValueByInsightType(insights);
    return { potential };
  }, [insights]);
};
export const useRevenueOverTimeSummary = () => {
  const insights = useDisplayList();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);
  const { statsPeriod } = useInsightsContext();

  return useMemo(() => {
    let percentageChange7D = 0;
    let numberChange7D = 0;
    let percentageChange30D = 0;
    let numberChange30D = 0;
    const sales7DSum = _(insights).sumBy("sales7D");
    const sales7DPreviousSum = _(insights).sumBy("sales7DPrevious");
    const sales30DSum = _(insights).sumBy("sales30D");
    const sales30DPreviousSum = _(insights).sumBy("sales30DPrevious");

    numberChange7D = (sales7DSum || 0) - (sales7DPreviousSum || 0);
    numberChange30D = (sales30DSum || 0) - (sales30DPreviousSum || 0);
    percentageChange7D = 100 * Math.abs(numberChange7D / sales7DPreviousSum);
    percentageChange30D = 100 * Math.abs(numberChange30D / sales30DPreviousSum);
    return {
      total: mapRevenue(
        _(insights).sumBy("revenueValue"),
        sales7DSum,
        sales30DSum,
        statsPeriod,
        revenueOverTimeEnabled
      ),
      percentageChange7D,
      numberChange7D,
      percentageChange30D,
      numberChange30D,
    };
  }, [insights]);
};

export const useInsightAssignees = (
  insightId?: string
): FrontEggTeamMember[] => {
  const { insights } = useInsightsContext();
  const users = useAuthorizedUsers();
  const assignees = insights.find(({ id }) => id === insightId)?.assignees;
  return useMemo(() => {
    if (assignees) {
      return users.filter(({ id }) => assignees.includes(id));
    }
    return [];
  }, [users, insightId, insights]);
};
