import { Typography, Box } from "@mui/material";
import noogataloader from "/spaces/noogata-loader.gif";
import { Link } from "react-router-dom";

const LoadingOverlay = () => {
  return (
    <Box
      //   className="ag-overlay-loading-center"
      role="presentation"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      paddingBottom={8}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <img src={noogataloader} alt="Loading" />
      </Box>
      <Typography
        variant="body4"
        gutterBottom
        sx={{ fontSize: "18px", fontFamily: "Inter" }}
      >
        Retrieving keywords
      </Typography>
      <Typography variant="body2" mt={2}>
        While you wait, check out this{" "}
        <Link
          style={{
            textDecoration: "underline",
            color: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",
          }}
          to="https://noogata.com/blog/amazon-keywords-tips/"
        >
          blog post
        </Link>{" "}
        for tips on optimizing your keywords.
      </Typography>
    </Box>
  );
};

export default LoadingOverlay;
