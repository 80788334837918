import {useParams} from "react-router-dom";
import {useCreateLocalSchema} from "../../../../services/connectors";
import Loadable from "../../../../components/Loadable";
import MappingForm from "../Mapping/MappingForm";
import {MappingProps} from "../Mapping/Mapping";
import {deleteFile} from "../../../../services/data";
import {DeleteFileRequest} from "../../../../types";
import {APIError} from "../../../../services/apiRequest";
import {FC} from "react"

export const LocalMapping: FC<MappingProps> = ({
                                                 input,
                                                 inputValue,
                                                 onSubmit,
                                                 onBack,
                                                 onError,
                                               }) => {
  const {boardId}: { boardId?: any } = useParams();
  const onSchemaError = async (error: APIError) => {
    try {
      if (error.statusCode >= 400 && error.statusCode < 500) {
        await deleteFile({
          boardId,
          inputName: input.name,
          fileName: inputValue.configuration!.path!,
        } as DeleteFileRequest);
      }
    } finally {
      onError(error.message);
    }
  };
  const { data, isLoading } = useCreateLocalSchema(
    boardId,
    input.name,
    inputValue.configuration!.path!,
    {
      onError: onSchemaError,
    }
  );
  return (
    <Loadable isLoading={isLoading}>
      {data && (
        <MappingForm
          to={input.schema}
          from={data}
          onSubmit={onSubmit}
          onBack={onBack}
          connectorType={inputValue.type}
        />
      )}
    </Loadable>
  );
};
