import { useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { enqueueSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Icon } from "../../../../components/Icon";
import { useTabsButtons } from "./useTabsButtons";
import {
  useInsightMarkAsDone,
  useInsightMarkAsUnDone,
} from "../../useInsights";
import { useInsightsContext } from "../../hooks/selectors";

const BackToMyListBtn = ({
  insightId,
  outlined,
}: {
  insightId: string;
  outlined?: boolean;
}) => {
  const { removeItemFromList, reAddItemToList } = useTabsButtons();
  const { refetchInsightsMetadata, refetchInsights } = useInsightsContext();
  const insightMarkAsUnDone = useInsightMarkAsUnDone();
  const history = useHistory();
  const insightMarkAsDone = useInsightMarkAsDone();

  const removeDone = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await insightMarkAsUnDone({
          insightId,
        });
        removeItemFromList(insightId);
        enqueueSnackbar("Insight moved back to My Items", {
          variant: "secondary",
          action: () => (
            <>
              <Button
                sx={{ color: theme.palette.backgrounds.white }}
                onClick={() => history.push(`/insights/my-items/#${insightId}`)}
              >
                Take me there
              </Button>
              <Button
                sx={{ color: theme.palette.backgrounds.white }}
                onClick={async () => {
                  reAddItemToList(insightId);
                  await insightMarkAsDone({
                    insightId,
                  });
                  await refetchInsights();
                  await refetchInsightsMetadata();
                }}
              >
                Undo
              </Button>
            </>
          ),
        });
        await refetchInsights();
        await refetchInsightsMetadata();
      } catch (e) {
        enqueueSnackbar("Could not change dismissed status", {
          variant: "error",
        });
      }
    },
    [insightId]
  );
  const theme = useTheme();

  return (
    <Button size="small" variant={outlined ? "outlined" : "text"}>
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          color: theme.palette.text.primary,
          minHeight: "18px",
          padding: "3px",
          fontSize: "14px",
          fontWeight: "600",
          maxHeight: "24px",
          height: "24px",
        }}
        onClick={removeDone}
      >
        <Icon src="insights/back-icon.svg" width="16px" height="16px" />
        Back to my list
      </Stack>
    </Button>
  );
};

export default BackToMyListBtn;
