import { FC } from "react";
import { Box, Stack, Pagination } from "@mui/material";
import { useSalesContext } from "./hooks/selectors";
import theme from "../../themes/light";

interface PaginationProps {
  asinsCount: number | null;
}

const CustomPagination: FC<PaginationProps> = ({ asinsCount }) => {
  const { limit, currentPage, handleChange } = useSalesContext();

  return (
    <Box sx={{ position: "sticky", bottom: 0, zIndex: 10 }}>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          height: "70px",
          gap: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "sticky",
          background: theme.palette.backgrounds.secondary,
        }}
      >
        <Stack spacing={2}>
          <Pagination
            count={asinsCount ? Math.ceil(asinsCount / limit) : undefined}
            page={currentPage}
            onChange={handleChange}
            sx={{
              "& .MuiPaginationItem-root.Mui-selected": {
                background: "#EEE5FE"
              },
              "& .MuiSvgIcon-root " : {
                color: "#2C224C"
              }
            }}
            shape="rounded"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomPagination;
