import {Typography} from "@mui/material";
import {ConnectorLogoMapping} from "../../../../mappings";
import {ConnectorType} from "../../../../enums";
import {ConnectorTypeCardProps} from "../ConnectorTypeSelection";
import InputTypeSelectionCard from "../InputTypeSelectionCard";
import {FC} from "react"

const GoogleSheetsCard: FC<ConnectorTypeCardProps> = ({setInputValue}) => (
    <InputTypeSelectionCard
        onClick={() => setInputValue({type: ConnectorType.GOOGLE_SHEETS})}
        children={
            <>
                <img
                    src={`/${ConnectorLogoMapping.GOOGLE_SHEETS}`}
          alt="GoogleSheets"
          height="40px"
          width="40px"
        />
        <Typography variant="h6">Google Sheets</Typography>
        <Typography variant="caption">
          Pick a Google Sheet from your Google Drive
        </Typography>
      </>
    }
  />
);

export default GoogleSheetsCard;
