import {Card, CardContent, CardHeader} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ConnectorTypeIcon from "../../../../components/ConnectorTypeIcon";
import {ConnectorType} from "../../../../enums";
import SchemaPreviewTable from "./SchemaPreviewTable";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
  createStyles({
    previewCardContent: {
      height: 600,
      padding: 0,
      display: "flex",
      flexDirection: "column",
    },
    icon: {
      height: theme.spacing(3.1),
      width: theme.spacing(3.1),
      display: "flex",
      justifyContent: "center",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
  })
);

type DataPreviewCardProps = {
  connectorType: ConnectorType;
  data: Record<string, string>[];
};

const DataPreviewCard: FC<DataPreviewCardProps> = ({ connectorType, data }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined">
      <CardHeader
        classes={{ avatar: classes.avatar }}
        title="Data Preview"
        avatar={
          <ConnectorTypeIcon type={connectorType} iconClass={classes.icon} />
        }
      />
      <CardContent className={classes.previewCardContent}>
        <SchemaPreviewTable data={data || []} />
      </CardContent>
    </Card>
  );
};

export default DataPreviewCard;
