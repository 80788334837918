export enum ExportStatus {
  PENDING = "pending",
  RUNNING = "running",
  SUCCESS = "completed",
  FAIL = "failed",
}

export enum ComponentType {
  PIPELINE = "pipeline",
  SERVICE = "service",
}

export enum DrawerType {
  EXPORT,
}

export enum Status {
  UNKNOWN = "unknown",
  PENDING = "pending",
  GATHERING = "gathering",
  RUNNING = "running",
  EXPORTING = "exporting",
  FAILED = "failed",
  SUCCEEDED = "succeeded",
  TERMINATED = "terminated",
  READY_TO_EXPORT = "ready_to_export",
}

export enum ConnectorID {
  GOOGLE_SHEETS = "GoogleSheets",
  BOARD = "board",
  SPACE = "space",
}

export enum ConnectorType {
  BIGQUERY = "Bigquery",
  SQL = "SQL",
  S3 = "S3",
  GCS = "GCS",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
  LOCAL = "Local",
  BOARD = "BOARD",
  AMAZON_PRODUCTS = "AMAZON_PRODUCTS",
  SPACE = "SPACE",
}

export enum DestinationType {
  BIGQUERY = "Bigquery",
  SQL = "SQL",
  S3 = "S3",
  GCS = "GCS",
  CSV = "CSV",
  EXCEL = "EXCEL",
  PARQUET = "PARQUET",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
}

export enum PlatformPermission {
  CIRCUITBOARDS_LIST = "circuitboards.list",
  CIRCUITBOARDS_GET = "circuitboards.get",
  CIRCUITBOARDS_CREATE = "circuitboards.create",
  CIRCUITBOARDS_UPDATE = "circuitboards.update",
  CIRCUITBOARDS_DELETE = "circuitboards.delete",
  RUNS_LIST = "runs.list",
  RUNS_GET = "runs.get",
  RUNS_CREATE = "runs.create",
  RUNS_DELETE = "runs.delete",
  EXPORTS_LIST = "exports.list",
  EXPORTS_GET = "exports.get",
  EXPORTS_CREATE = "exports.create",
  CONNECTORS_LIST = "connectors.list",
  CONNECTORS_GET = "connectors.get",
  CONNECTORS_CREATE = "connectors.create",
  CONNECTORS_UPDATE = "connectors.update",
  CONNECTORS_DELETE = "connectors.delete",
  APPS_LIST = "apps.list",
  APPS_GET = "apps.get",
  KFP_GET = "kfp.get",
  TENANT_ADMIN_GET = "tenant_admin.get",
  TENANT_ADMIN_CREATE = "tenant_admin.create",
  TENANT_ADMIN_LIST = "tenant_admin.list",
  INSIGHTS_UPDATE = "insights.update",
  INSIGHTS_READ = "insights.read",
}

export enum PlatformRole {
  Maintainer = "maintainer",
  Viewer = "viewer",
  Administrator = "administrator",
  NoogataAdministrator = "noogata-administrator",
  Editor = "editor",
  ApplicationUser = "application-user",
  ContentApplicationUser = "content-application-user",
}

export const LIMITED_ROLES = [PlatformRole.ContentApplicationUser]

export enum OutputFormat {
  NONE = "",
  PARQUET = "parquet",
  CSV = "csv",
}

export enum DownloadArtifactType {
  OUTPUT = "output",
  INPUT = "input",
}

export enum DayStatus {
  UNKNONW = "unknown",
  SUCCESS = "success",
  WARNING = "warning",
  FAILURE = "failure",
}

export enum DayActivityColor {
  GREY = "grey",
  GREEN = "green",
  YELLOW = "yellow",
  RED = "red",
  TRANSAPARENT = "transparent",
}

export enum ParameterType {
  STRING = "String",
  BOOLEAN = "Boolean",
  INTEGER = "Integer",
  FLOAT = "Float",
  LIST = "List",
  JSON = "Json",
}

export enum WeekDay {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
}

export enum GoogleApiStatus {
  IDLE = "idle",
  LOADING = "loading",
  READY = "ready",
  ERROR = "error",
}

export enum VisibilityType {
  WORKSPACE = "workspace",
  PRIVATE = "private",
  ALL = "all",
}

export enum FlowTemplates {
  DigitalShelf = "digital_shelf",
}

export enum VisibilityOptions {
  WORKSPACE = "Workspace Visible",
  PRIVATE = "Private",
}

export enum AmazonSellingRegion {
  AMAZON_NORTH_AMERICA = "amazon_north_america",
}

export enum BoardLibrary {
  ECOMMERCE = "ecommerce",
  ECOMMERCE_PREMIUM = "ecommerce-premium",
  LOCATION = "location",
  OPERATIONS = "operations",
  FINANCE = "finance",
  NOOGATA_LABS = "noogata-labs",
}

export const BoardLibraryDetails: Record<any, { name: string; color: string }> =
{
  [BoardLibrary.ECOMMERCE]: { name: "ECommerce", color: "#2BB5D4" },
  [BoardLibrary.LOCATION]: { name: "Location Analytics", color: "#66bb6a" },
  [BoardLibrary.OPERATIONS]: { name: "Operations", color: "#5c6bc0" },
  [BoardLibrary.FINANCE]: { name: "Financial Services", color: "#f06292" },
  [BoardLibrary.NOOGATA_LABS]: { name: "Noogata Labs", color: "#78909C" },
};

export enum OnboardingStatusEnum {
  PENDING = "pending",
  GATHERING = "gathering",
  TRAINING = "training",
  SUCCEEDED = "succeeded",
}

export enum OnboardingGatheringStatusEnum {
  PENDING = "pending",
  GATHERING = "gathering",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  EXCEEDED_TIMEOUT = "exceeded_timout",
}

export enum MatchTypes {
  GTIN = "gtin",
  USER = "user",
  FUZZY = "fuzzy",
  INITIAL = "initial",
}

export enum ChannelTypes {
  AMAZON = "amazon",
  SHOPIFY = "shopify",
}

export enum NavbarModes {
  EXPENDED = "expended",
  COLLAPSED = "collapsed",
  TOP = "top",
  LOADING = "loading",
}

export enum Currency {
  Dollar = "dollar",
}

export enum SpaceStatuses {
  IDLE = "idle",
  EXTRACTING_ATTRIBUTES = "extracting attributes",
}
