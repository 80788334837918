import {Box, Stack, Tooltip, Typography, useTheme} from "@mui/material";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import BoltIcon from "@mui/icons-material/Bolt";
import InfoIcon from "@mui/icons-material/Info";
import {Interval, Schedule} from "../../../types";
import {RepeatType} from "../../CreateNewRun/Schedule/hooks/useScheduleForm";
import {FC} from "react"

const getIntervalText = (scheduleInterval?: Interval) => {
  if (scheduleInterval?.value === 1) {
    return scheduleInterval?.type === RepeatType.WEEK ? "Weekly" : "Daily";
  }
  return `Runs every ${scheduleInterval?.value} ${scheduleInterval?.type}s`;
};

const getScheduleText = (schedule?: Schedule): string => {
  const prefix = getIntervalText(schedule?.interval);
  const postfix =
    schedule?.interval?.type === RepeatType.WEEK && schedule.start_date
      ? ` on ${new Date(schedule?.start_date).toLocaleDateString(undefined, {
          weekday: "short",
        })}`
      : "";
  return `${prefix}${postfix}`;
};

export const TriggerCell: FC<{ path: string[]; schedule?: Schedule }> = ({
  path,
  schedule,
}) => {
  const theme = useTheme();
  return (
    <Stack spacing={1} direction="column" style={{ textAlign: "center" }}>
      {schedule && (
        <Stack direction="row" spacing={1}>
          <EventRepeatIcon
            sx={{
              color: (t) => t.palette.text.primary,
              alignContent: "center",
            }}
          />
          <Typography>{getScheduleText(schedule)}</Typography>
        </Stack>
      )}
      {!(path.length >= 2) ? null : (
        <Tooltip
          title={
            <Stack direction="column">
              {path.map((s, i) => (
                <Box fontWeight={i === path.length - 1 ? "bold" : ""}>
                  {`${i + 1}. ${s}`}
                </Box>
              ))}
            </Stack>
          }
          placement="top-start"
        >
          <Stack spacing={1} direction="row">
            <BoltIcon
              sx={{
                color: (t) => t.palette.text.primary,
                alignContent: "center",
              }}
            />
            <Typography>{path.slice(-2, -1)}</Typography>
          </Stack>
        </Tooltip>
      )}
      {path.length < 2 && !schedule && (
        <Stack direction="row" spacing={1}>
          <Typography> No Trigger </Typography>
          <Tooltip
            title="Block will run independently of other blocks"
            placement="top-start"
            arrow
          >
            <InfoIcon htmlColor={theme.palette.icons.secondary} />
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};
