import { FC, useEffect, useRef } from "react";
import { LinearProgress, ThemeProvider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router";
import theme from "../../themev2";
import { ApplicationType } from "../Applications/LeftNavbar/types";
import { useBoard, useBoards } from "../../services/boards";
import { useSearchParams } from "../../hooks";
import { useAppContext } from "../../ApplicationContext";
import { NavbarModes } from "../../enums";

export const PERFECT_CONTENT_V2_NAME = `${ApplicationType.PERFECT_CONTENT}-v2`;

const PERFECT_CONTENT_BACK_MESSAGE = "perfect-content-back-button-click";

export const PerfectContentWrapper: FC<object> = () => {
  const match = useRouteMatch({
    path: `/${ApplicationType.PERFECT_CONTENT}`,
    exact: true,
  });
  const history = useHistory();
  const { data } = useBoards(undefined, {
    refetchOnWindowFocus: false,
    enabled: !!match,
    select: (data) =>
      data.filter(
        (block) =>
          block.template_id.indexOf("asins-content-optimization") > -1 &&
          block.published_run_id
      ),
  });

  useEffect(() => {
    if (match && data && data[0]) {
      history.push(`/${ApplicationType.PERFECT_CONTENT}/${data[0].identifier}`);
    }
  }, [match, data]);

  const matchSpecificBlock = useRouteMatch<{ boardId: string }>(
    `/${ApplicationType.PERFECT_CONTENT}/:boardId`
  );
  const { search } = useSearchParams();
  const { insightId, productGroupFilterId } = search || {};
  const { data: perfectContentBlock } = useBoard(
    matchSpecificBlock?.params.boardId as string,
    {
      refetchOnWindowFocus: false,
      enabled: !!matchSpecificBlock?.params.boardId && !insightId,
    }
  );
  const { navbarMode } = useAppContext()
  return !perfectContentBlock && !insightId ? (
    <LinearProgress />
  ) : (
    <PerfectContent
      insightId={insightId?.toString()}
      blockId={!insightId ? perfectContentBlock?.identifier : undefined}
      runId={!insightId ? perfectContentBlock?.published_run_id : undefined}
      productGroupFilterId={productGroupFilterId?.toString()}
      navMode={navbarMode == NavbarModes.TOP ? 'top-bar' : 'side-bar'}
    />
  );
};

const PerfectContent: FC<{
  flowId?: string;
  insightId?: string;
  blockId?: string;
  runId?: string;
  productGroupFilterId?: string;
  navMode: string;
}> = ({ flowId, insightId, blockId, runId, productGroupFilterId, navMode }) => {
  const history = useHistory();
  const iFrameRef = useRef<any>(null);

  useEffect(() => {
    const handler = (ev: MessageEvent<string>) => {
      if (ev.data === PERFECT_CONTENT_BACK_MESSAGE) {
        history.goBack();
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <iframe
        ref={iFrameRef}
        title={String(insightId)}
        src={`/apps/perfect-content/latest?${Object.entries({
          flowId,
          insightId,
          blockId,
          runId,
          productGroupFilterId,
          navMode,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join("&")}`}
        frameBorder="0"
        width="100%"
        height="100%"
        allowTransparency // eslint-disable react/no-unknown-property
      />
    </ThemeProvider>
  );
};
export default PerfectContent;
