import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { CloseIcon, TaskIcon } from "../../../../assets";
import theme from "../../../../themes/light";
import { SalesAssistantRead } from "../../types";
import { addTask, updateTask } from "../../../Tasks/useTaskApi";
import { useAuthUserOrNull } from "@frontegg/react";
import { useTasksContext } from "../../../Tasks/hooks/selectors";
import { SnackbarKey, closeSnackbar, enqueueSnackbar } from "notistack";
// import { handleToast } from "../../../Tasks/TaskUtils";
import { useHistory } from "react-router-dom";
import { AssignTeamMember } from "./AssignTeamMember";
interface dataType {
  assignee: string;
  description: string;
  taskId?: string;
}

const CreateTask: React.FC<{
  open: boolean;
  handleClose: () => void;
  insight: SalesAssistantRead;
}> = ({ open, handleClose, insight }) => {
  const initialValue = {
    assignee: "",
    description: "",
  };
  const { asin, title, image_url, id } = insight || {};
  const user = useAuthUserOrNull();
  const { email } = user || {};
  const { taskData, type, setIsUpdated } = useTasksContext();
  const [payload, setPayload] = useState<dataType>(initialValue);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const addTaskChange = (data: { name: string; value: string }) => {
    const { name, value } = data || {};
    setPayload((prevData: dataType) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCreate = async () => {
    setIsUpdated(false);
    setLoading(true);
    const myObject = { asin, sales_assistant_id: id };
    const related_assets = JSON.stringify(myObject);

    const body = {
      source: "sales_assistant",
      related_assets: related_assets,
      assignee: payload?.assignee,
      status: "to_do",
      description: payload?.description,
      archived: false,
      created_by: email,
      asin: asin,
    };
    await addTask(body);
    setLoading(false);
    setIsUpdated(true);
    setPayload(initialValue);
    handleClick();
    handleClose();
  };
  useEffect(() => {
    if (taskData?.taskId) {
      setPayload(taskData);
    } else {
      if(email && !email?.includes('@noogata.com')){
        setPayload({...initialValue, assignee:email});
      }else{
        setPayload(initialValue);
      }
    }
  }, [taskData]);
  const handleEdit = async () => {
    setIsUpdated(false);
    const body = {
      assignee: payload?.assignee,
      description: payload?.description,
    };
    if (payload?.taskId !== undefined) {
      await updateTask(body, payload?.taskId);
      setIsUpdated(true);
      // handleToast("Task Edited", payload?.taskId, handleBoardLink);
      handleClose();
    }
  };

  const handleBoardLink = () => {
    // go to board list
    history.push("/tasks");
  };
  const handleCancel = (snackbarId: SnackbarKey) => {
    closeSnackbar(snackbarId);
  };

  const handleChange = (assign: string) => {
    setPayload((prevData: dataType) => ({
      ...prevData,
      ["assignee"]: assign,
    }));
  };
  const action = (snackbarId: SnackbarKey) => (
    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
      <Typography
        variant="body5"
        sx={{
          color: "white",
          cursor: "pointer",
          fontWeight: 600,
          padding: "0px 16px",
        }}
        onClick={() => handleBoardLink()}
      >
        View Tasks
      </Typography>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => handleCancel(snackbarId)}
      >
        <CloseIcon />
      </Box>
    </Box>
  );
  const handleClick = () => {
    enqueueSnackbar("Custom snackbar message", {
      variant: "success",
      content: (key: SnackbarKey) => (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            background: theme.palette.text.primary,
            minHeight: " 70px",
            borderRadius: "4px",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            gap="8px"
            sx={{
              padding: "8px 20px ",
              "& svg": { "& path": { fill: theme.palette.backgrounds.white } },
            }}
          >
            <TaskIcon />
            <Box>
              <Typography
                variant="body3"
                color="#fff"
                sx={{ fontWeight: 600, display: "block" }}
              >
                Task added
              </Typography>
              {/* <Typography
                variant="body5"
                sx={{
                  fontSize: "15px",
                  color: "white",
                  margin: 0,
                  lineHeight: "24px",
                }}
              >
                Task Added
              </Typography> */}
            </Box>
          </Box>
          <Box
            display="flex"
            gap="16px"
            alignItems="center"
            sx={{ paddingRight: "16px" }}
          >
            {action(key)}
          </Box>
        </Box>
      ),
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "420px",
            width: "100%",
            margin: 0,
            padding: "24px",
            gap: "8px",
            "& .MuiOutlinedInput-notchedOutline": {
              // display: 'none'
            },
            "& .MuiSelect-select": {
              background: "#F4F3F8",
            },
            "& .MuiDialogContent-root": {
              overflow: "initial",
            },
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px" }}>
          <Typography variant="headline3">
            {type === "Edit" ? "Edit Task" : "Add Task"}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: "12px",
              right: "12px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            marginTop: "16px",
            "& .MuiFormControl-root": { width: "100%" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="24px"
          >
            <Box sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Assign to:
              </InputLabel>
              <AssignTeamMember
                handleChange={handleChange}
                selectedEmail={payload?.assignee}
                type="create"
              />
            </Box>
            <FormControl
              variant="standard"
              sx={{
                "& textarea": {
                  width: "100% !important",
                  "&:focus": {
                    outline: "none !important",
                    boxShadow: "none !important",
                    border: "none !important",
                  },
                },
              }}
            >
              <TextareaAutosize
                placeholder="What do you want to get done?"
                name="description"
                style={{
                  maxHeight: "180px",
                  minHeight: "180px",
                  height: "100%",
                  padding: "10px 16px 0px",
                  background: theme.palette.grey[50],
                  border: "none",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: theme.palette.grey[500],
                  fontFamily: "'Inter'",
                }}
                onChange={(e) => addTaskChange(e.target)}
                value={payload?.description}
                autoFocus
              />
            </FormControl>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              //   gap="12px"
              width="100%"
            >
              <Box display="flex" justifyContent="flex-start" gap="10px">
                <Box
                  display="grid"
                  border="1px solid #E9E7F1"
                  alignItems="center"
                  borderRadius="5px"
                  sx={{
                    backgroundColor: "#fff",
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <Box
                    component="img"
                    alt="Product for the opportunity"
                    src={image_url}
                    sx={{
                      height: "inherit",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "16px",
                      color: "#504C55",
                    }}
                  >
                    {`${title?.substring(0, 10)}...`}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "16px",
                      color: "var(--Text-color-light-text-secondary, #75717B)",
                    }}
                  >
                    {asin}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end" gap="10px">
                <Button
                  onClick={handleClose}
                  color="secondary"
                  sx={{
                    border: "1px solid #D7D5DB",
                    boxShadow: "0px 1px 1px 0px rgba(44, 34, 76, 0.04)",
                    padding: "10px 16px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                    height: "40px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    padding: "10px 16px",
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    color: "#fff !important",
                    fontSize: "14px",
                    fontWeight: 600,
                    height: "40px",
                    pointerEvents: loading ? "none" : "initial",
                    opacity: loading ? 0.5 : 1,
                  }}
                  disabled={loading}
                  onClick={type === "Edit" ? handleEdit : handleCreate}
                >
                  {type === "Edit" ? "Edit Task" : "Create"}
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CreateTask;
