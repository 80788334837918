import { Component, createElement, ReactNode } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthState } from "@frontegg/redux-store/auth";
import { useAuth } from "@frontegg/react-hooks/auth";
import { useUserAuthorization } from "../hooks/useUserAuthorization";

const FRONTEGG_AFTER_AUTH_REDIRECT_URL = "FRONTEGG_AFTER_AUTH_REDIRECT_URL";

const onRedirecting = (loginUrl: string) => {
  window.localStorage.setItem(
    FRONTEGG_AFTER_AUTH_REDIRECT_URL,
    window.location.href.substring(window.location.origin.length)
  );
  return <Redirect to={loginUrl} />;
};

const ProtectedComponent = ({ children }: { children: ReactNode }) => {
  const {
    isAuthenticated,
    routes: { loginUrl },
    isLoading,
  } = useAuth(({ isAuthenticated, routes, isLoading }: AuthState) => ({
    isAuthenticated,
    routes,
    isLoading,
  }));

  return isLoading ? null : isAuthenticated ? (
    <>{children}</>
  ) : (
    onRedirecting(loginUrl)
  );
};

export const ProtectedComponentWithRoles = ({
  children,
  permissions = [],
  roles = [],
}: {
  children: ReactNode;
  permissions?: any;
  roles?: any;
}) => {
  const authorized = useUserAuthorization({ roles, permissions });
  return authorized ? <>{children}</> : <></>;
};

class ProtectedRoute extends Component<RouteProps> {
  render() {
    const { component, render, children, ...routeProps } = this.props;
    if (children != null) {
      return (
        <Route {...routeProps}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*// @ts-expect-error*/}
          <ProtectedComponent>{children}</ProtectedComponent>
        </Route>
      );
    }
    if (render != null) {
      return (
        <Route
          {...routeProps}
          render={(props) => (
            <ProtectedComponent>{render(props)}</ProtectedComponent>
          )}
        />
      );
    }
    if (component != null) {
      return (
        <Route
          {...routeProps}
          render={(props) => (
            <ProtectedComponent>
              {createElement(component as any, props as any)}
            </ProtectedComponent>
          )}
        />
      );
    }
    return <Route {...routeProps} />;
  }
}

export default ProtectedRoute;
