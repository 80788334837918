import { useCallback } from "react";
import { cloneDeep } from "lodash";
import { useSnackbar } from "notistack";
import dayjs from "../../../utils";
import { Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  generateFilter,
  useDisplayedSelectedInsights,
  useDisplayList,
  useInsightsContext,
  useIsAllDisplayedSelected,
} from "./selectors";
import {
  FilterColumns,
  FilterMapping,
  InsightRead,
  TransformedInsight,
} from "../types";
import { FrontEggTeamMember } from "../../../types";
import { useAuthorizedUsers } from "../../../hooks/useUsers";
import {
  useInsightDoneUpdateBulk,
  useInsightUserStateUpdateBulk,
} from "../useInsights";
import { useTabsButtons } from "../List/TabButtons/useTabsButtons";

type SetFilterArg = {
  column: FilterColumns;
  key: string;
  value: boolean;
};

function setSingleFilter(
  filterOut: FilterMapping,
  filter: SetFilterArg & { column: FilterColumns }
) {
  const newFilters = cloneDeep(filterOut);
  if (filter.value) {
    delete newFilters[filter.column][filter.key];
  } else {
    newFilters[filter.column][filter.key] = false;
  }
  return newFilters;
}

function setAllFilter(
  filterOut: FilterMapping,
  filter: SetFilterArg & { column: FilterColumns },
  insights: TransformedInsight[],
  members: FrontEggTeamMember[]
) {
  const newFilters = cloneDeep(filterOut);
  if (filter.value) {
    newFilters[filter.column] = {};
  } else {
    newFilters[filter.column] = generateFilter(
      filter.column,
      false,
      insights,
      members
    );
  }
  return newFilters;
}

export const useFilterSetter = () => {
  const { setFilterOut, filterOut, insights } = useInsightsContext();
  const members = useAuthorizedUsers();
  const setFilter = useCallback(
    (filter: SetFilterArg) => {
      const isFilterAll = filter.key === "All";
      const newFilters = isFilterAll
        ? setAllFilter(filterOut, filter, insights, members)
        : setSingleFilter(filterOut, filter);
      setFilterOut(newFilters);
    },
    [filterOut, setFilterOut, insights, members]
  );
  return setFilter;
};

export const useToggleSelected = () => {
  const { setSelectedInsights, selectedInsights } = useInsightsContext();
  const toggleSelected = useCallback(
    (id: string) => {
      const selectedInsightsSet = new Set(selectedInsights);
      const isSelected = selectedInsightsSet.has(id);
      if (isSelected) {
        selectedInsightsSet.delete(id);
      } else {
        selectedInsightsSet.add(id);
      }
      setSelectedInsights([...selectedInsightsSet]);
    },
    [selectedInsights, setSelectedInsights]
  );
  return toggleSelected;
};

export const useDeselectAll = () => {
  const { setSelectedInsights } = useInsightsContext();
  return useCallback(() => setSelectedInsights([]), [setSelectedInsights]);
};

export const useSelectAll = () => {
  const { setSelectedInsights } = useInsightsContext();
  const displayList = useDisplayList();
  const isAllSelected = useIsAllDisplayedSelected();
  return useCallback(() => {
    if (isAllSelected) {
      setSelectedInsights([]);
    } else {
      setSelectedInsights(displayList.map((insight) => insight.id));
    }
  }, [displayList, setSelectedInsights, isAllSelected]);
};

export const useSortSetter = () => {
  const { sort: currentSort, setSort } = useInsightsContext();
  return useCallback(
    (column: keyof InsightRead) => {
      const ascending = currentSort.column !== column || !currentSort.ascending;
      setSort({
        column,
        ascending,
        isDirty: true,
      });
    },
    [currentSort, setSort]
  );
};

export const useUpdateDisplayedSelected = () => {
  const displayedSelectedInsights = useDisplayedSelectedInsights();
  const insightUserStateUpdaterBulk = useInsightUserStateUpdateBulk();
  const insightDoneUpdaterBulk = useInsightDoneUpdateBulk();
  const { enqueueSnackbar } = useSnackbar();
  const deselectAll = useDeselectAll();
  const theme = useTheme();
  const { removeItemFromList } = useTabsButtons();
  const { refetchInsightsMetadata, refetchInsights } = useInsightsContext();

  return useCallback(
    async (updateType: string) => {
      try {
        switch (updateType) {
          case "dismiss":
            enqueueSnackbar("Insights dismissed till next update", {
              variant: "secondary",
              action: () => (
                <Button
                  sx={{ color: theme.palette.backgrounds.white }}
                  onClick={async () => {
                    await insightUserStateUpdaterBulk({
                      insightIds: Array.from(
                        displayedSelectedInsights.values()
                      ),
                      dismissed: 0,
                    });
                    await refetchInsights();
                    await refetchInsightsMetadata();
                  }}
                >
                  Undo
                </Button>
              ),
            });
            await insightUserStateUpdaterBulk({
              insightIds: Array.from(displayedSelectedInsights.values()),
              dismissed: 1,
            });
            break;
          case "moveToMyList":
            enqueueSnackbar("Saved to My Items", {
              variant: "secondary",
              action: () => (
                <Button
                  sx={{ color: theme.palette.backgrounds.white }}
                  onClick={async () => {
                    await insightUserStateUpdaterBulk({
                      insightIds: Array.from(
                        displayedSelectedInsights.values()
                      ),
                      followed: false,
                    });
                    await refetchInsights();
                    await refetchInsightsMetadata();
                  }}
                >
                  Undo
                </Button>
              ),
            });
            await insightUserStateUpdaterBulk({
              insightIds: Array.from(displayedSelectedInsights.values()),
              followed: dayjs().toISOString(),
            });
            break;
          case "markAsDone":
            enqueueSnackbar("🎉 Nice work! You have acted on these insights", {
              variant: "secondary",
              action: () => (
                <Button
                  sx={{ color: theme.palette.backgrounds.white }}
                  onClick={async () => {
                    await insightDoneUpdaterBulk({
                      insightIds: Array.from(
                        displayedSelectedInsights.values()
                      ),
                      done: false,
                    });
                    await refetchInsights();
                    await refetchInsightsMetadata();
                  }}
                >
                  Undo
                </Button>
              ),
            });
            await insightDoneUpdaterBulk({
              insightIds: Array.from(displayedSelectedInsights.values()),
              done: true,
            });
            break;
          default:
            enqueueSnackbar("Error accrued unknown method", {
              variant: "error",
            });
            return;
        }
      } catch (e) {
        enqueueSnackbar("Error accrued unknown method", { variant: "error" });
      }
      await refetchInsights();
      await refetchInsightsMetadata();
      displayedSelectedInsights.forEach((id) => {
        removeItemFromList(id);
      });
      deselectAll();
    },
    [displayedSelectedInsights]
  );
};
