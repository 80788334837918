import { useQuery, UseQueryOptions } from "react-query";
import { useLocation } from "react-router-dom";
import { Insight } from "../../types";
import apiRequest, { APIError } from "../../services/apiRequest";
import {
  SalesAssistantRead,
  InsightReadImpl,
  Sort,
  FilterData,
  GroupDataType,
} from "./types";

interface Params {
  limit: number | undefined;
  offset: number | undefined;
  sort_by: string; // or replace string with specific type if available
  query?: string; // optional property
}

function getSalesAssistantProducts(
  pathname: string,
  filterId?: string,
  sort?: Sort,
  categoryFilter?: any,
  brandFilter?: any,
  limit?: number,
  offset?: number,
  // allProductTab?: boolean,
  search ?: string
): Promise<any[]> {
  const url = "sales_assistant/products";
  let params = {};  
  if (pathname.includes("products") || (pathname === "/sales-assistant/home")) {
    params = {
      alerts: false,
      dismissed: false,
      alerts_dismissed: false,
      tracked: false,
      product_group_filter_id: filterId,
      sort_by: sort?.ascending
        ? `sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`
        : `-sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`,
      brand: brandFilter,
      category: categoryFilter,
      offset :offset,
      limit : limit,
      asin : search
    };
    
  }
  if (pathname.includes("alerts")) {
    params = {
      alerts: true,
      dismissed: false,
      alerts_dismissed: false,
      tracked: false,
      product_group_filter_id: filterId,
      sort_by: sort?.ascending
        ? `sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`
        : `-sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`,
      brand: brandFilter,
      category: categoryFilter,
    };
  }
  if (pathname.includes("sales-assistant/dismissed")) {
    params = {
      alerts: false,
      dismissed: false,
      alerts_dismissed: true,
      tracked: false,
      product_group_filter_id: filterId,
      sort_by: sort?.ascending
        ? `sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`
        : `-sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`,
      brand: brandFilter,
      category: categoryFilter,
    };
  }
  if (pathname.includes("tracking")) {
    params = {
      alerts: false,
      dismissed: false,
      alerts_dismissed: false,
      tracked: true,
      product_group_filter_id: filterId,
      sort_by: sort?.ascending
        ? `sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`
        : `-sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`,
      brand: brandFilter,
      category: categoryFilter
    };
  }
  if (pathname.includes("tracking/dismissed")) {
    params = {
      alerts: false,
      dismissed: true,
      alerts_dismissed: false,
      tracked: true,
      product_group_filter_id: filterId,
      sort_by: sort?.ascending
        ? `sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`
        : `-sales_${sort?.days}${
            sort?.column !== "sales" ? `_${sort?.column}` : ""
          }`,
      brand: brandFilter,
      category: categoryFilter
    };
  }

  return apiRequest<any[]>(url, "GET", params).then((data) =>
    data.map((d) => new InsightReadImpl(d))
  );
}
function getInsightMetadata(
  pathname: string,
  filterId?: string,
  categoryFilter?: any,
  brandFilter?: any
): Promise<any[]> {
  let params = {};
  if (pathname.includes("products")) {
    params = {
      alerts: false,
      dismissed: false,
      tracked: false,
      alerts_dismissed: false,
      product_group_filter_id: filterId,
      brand: brandFilter,
      category: categoryFilter,
    };
  }
  if (pathname.includes("alerts")) {
    params = {
      alerts: true,
      dismissed: false,
      tracked: false,
      alerts_dismissed: false,
      product_group_filter_id: filterId,
      brand: brandFilter,
      category: categoryFilter,
    };
  }
  if (pathname.includes("sales-assistant/dismissed")) {
    params = {
      alerts: false,
      dismissed: false,
      tracked: false,
      alerts_dismissed: true,
      product_group_filter_id: filterId,
      brand: brandFilter,
      category: categoryFilter,
    };
  }
  if (pathname.includes("tracking")) {
    params = {
      alerts: false,
      dismissed: false,
      tracked: true,
      alerts_dismissed: false,
      product_group_filter_id: filterId,
      brand: brandFilter,
      category: categoryFilter,
    };
  }
  if (pathname.includes("tracking/dismissed")) {
    params = {
      alerts: false,
      dismissed: false,
      tracked: true,
      alerts_dismissed: false,
      product_group_filter_id: filterId,
      brand: brandFilter,
      category: categoryFilter,
    };
  }
  return apiRequest<any[]>("sales_assistant/metadata", "GET", params);
}

type props = {
  filterId?: string;
  sort?: Sort;
  categoryFilter: any;
  brandFilter: any;
  allProductTab?: boolean;
  limit : any;
  offset : number;
  search?: string;
};

export function useSalesAssistant({
  filterId,
  sort,
  categoryFilter,
  brandFilter,
  limit,
  offset,
  // allProductTab,
  search
}: props) {
  const { pathname } = useLocation();
  const shouldSetStaleTime = !(pathname.includes("products") || pathname === "/sales-assistant/home");

  return useQuery<SalesAssistantRead[], APIError>(
    [
      "useSalesAssistant",
      pathname,
      filterId,
      sort,
      categoryFilter,
      brandFilter,
      limit,
      offset,
    ],
    () =>
      getSalesAssistantProducts(
        pathname,
        filterId,
        sort,
        categoryFilter,
        brandFilter,
        limit,
        offset,
        // allProductTab,
        search
      ),
    {
      staleTime: shouldSetStaleTime ? Infinity : undefined,
    }
  );
}

type metaProps = {
  filterId?: string;
  categoryFilter: any;
  brandFilter: any;
};
export function useInsightMetadata({
  filterId,
  categoryFilter,
  brandFilter,
}: metaProps) {
  const { pathname } = useLocation();
  return useQuery<any[], APIError>(
    ["useInsightsMetadata", categoryFilter, brandFilter, pathname],
    () => getInsightMetadata(pathname, filterId, categoryFilter, brandFilter),
    {
      staleTime: Infinity,
    }
  );
}

async function getInsight(insightId: string): Promise<Insight> {
  return apiRequest<Insight>(`insights/${insightId}`, "GET");
}

async function getSalesDrawer(insightId: string): Promise<any[]> {
  return apiRequest(`sales_assistant/product/${insightId}`, "GET");
}

export function useInsight(
  insightId?: string | null,
  options: UseQueryOptions<Insight, APIError> = {}
) {
  return useQuery<Insight, APIError>(
    ["useInsight", insightId],
    () => getInsight(insightId as string),
    {
      ...options,
      enabled: options.enabled && Boolean(insightId),
    }
  );
}

export function useSalesDrawer(
  insightId: string | null,
  options: UseQueryOptions<any[], APIError> = {}
) {
  return useQuery<any[], APIError>(
    ["useInsightV2", insightId],
    () => getSalesDrawer(insightId as string),
    {
      ...options,
      enabled: Boolean(
        options.enabled || (options.enabled == null && insightId)
      ),
      staleTime: Infinity,
    }
  );
}

function getProductSalesFilters(): Promise<FilterData> {
  return apiRequest<FilterData>("sales_assistant/filters_data", "GET");
}

export function useProductSalesFilters() {
  return useQuery<FilterData, APIError>(
    ["useSalesFilterData"],
    () => getProductSalesFilters(),
    {
      staleTime: Infinity,
    }
  );
}

function getProductSalesGroups(orderby: Sort, search:string, activeTab?: string): Promise<GroupDataType> {
  const sort_by =
    orderby.column === "sales"
      ? `sales_${orderby.days}`
      : `sales_${orderby.days}_${orderby.column}`;
  const sort_by_text = orderby.ascending ? sort_by : `-${sort_by}`;  
  const params: Params = {
    limit: 1000,
    offset: 0,
    sort_by: sort_by_text,
};


if (search && activeTab !== "all-products") {
    params.query = search;
}
  return apiRequest<GroupDataType>("sales_assistant/groups", "GET", params);
}

export function useProductSalesGroups(orderby: Sort, search:string, activeTab?: string) {
  return useQuery<GroupDataType, APIError>(
    ["useSalesgroupData", orderby,search],
    () => getProductSalesGroups(orderby, search, activeTab),
    {
      staleTime: Infinity,
    }
  );
}
