import {Button, ButtonProps} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import PlusIcon from "@mui/icons-material/Add";
import {FC} from "react"

export const AddBlockButton: FC<
    { disabled: boolean; blockTemplateId: string } & ButtonProps
> = ({blockTemplateId, ...props}) => {
    const location = useLocation<{ flowId?: string }>();
    const history = useHistory();
    return (
        <Button
      onClick={() =>
        history.push({
          pathname: `/marketplace/${blockTemplateId}/new`,
          state: { flowId: location.state.flowId },
        })
      }
      variant="contained"
      color="primary"
      startIcon={<PlusIcon />}
      {...props}
    >
      Add this block
    </Button>
  );
};
