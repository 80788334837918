import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { APIError } from "../../services/apiRequest";
import { ProductsFilter } from "../../types/ecommerce";
import { Dispatch, SetStateAction } from "react";

export type InsightRead = {
  id: string;
  type: string;
  imageUrl: string;
  strategy: string;
  brand: string;
  date: Date;
  cardText: string;
  categories: string[];
  actionText: string;
  actionUrl: string;
  revenueValue: number;
  stars: number;
  drawerText: string;
  dashboardLinkText: string;
  dashboardLinkUrl: string;
  widgetName: string;
  embeddedQuestionName: string;
  embeddedQuestionUrl: string;
  archived: boolean;
  done: boolean;
  assignees: string[];
  status?: string;
  canUndo?: boolean;
  sales7D?: number;
  sales30D?: number;
  sales7DPrevious?: number;
  sales30DPrevious?: number;
  numberChange30D?: number;
  numberChange7D?: number;
  percentageChange30D?: number;
  percentageChange7D?: number;
  tabs?: Array<any>;
};
export type StatsPeriods = "7 days" | "30 days";

export type FilterColumns = "strategy" | "brand" | "categoryId" | "assignedTo";

export type TransformedInsight = InsightRead & {
  categoryId: string;
  isChecked: boolean;
};

export class InsightReadImpl implements InsightRead {
  id: string;

  type: string;

  imageUrl: string;

  strategy: string;

  brand: string;

  date: Date;

  cardText: string;

  categories: string[];

  actionText: string;

  actionUrl: string;

  revenueValue: number;

  stars: number;

  drawerText: string;

  dashboardLinkText: string;

  dashboardLinkUrl: string;

  widgetName: string;

  embeddedQuestionName: string;

  embeddedQuestionUrl: string;

  archived: boolean;

  done: boolean;

  assignees: string[];

  status?: string;

  canUndo?: boolean;

  sales7D?: number;

  sales30D?: number;

  numberChange30D?: number;

  numberChange7D?: number;

  sales7DPrevious?: number;

  sales30DPrevious?: number;

  percentageChange30D?: number | undefined;

  percentageChange7D?: number | undefined;

  constructor(params: InsightRead) {
    this.actionText = params.actionText;
    this.actionUrl = params.actionUrl;
    this.archived = params.archived;
    this.assignees = params.assignees;
    this.brand = params.brand;
    this.canUndo = params.canUndo;
    this.cardText = params.cardText;
    this.categories = params.categories;
    this.dashboardLinkText = params.dashboardLinkText;
    this.dashboardLinkUrl = params.dashboardLinkUrl;
    this.date = params.date;
    this.done = params.done;
    this.drawerText = params.drawerText;
    this.embeddedQuestionName = params.embeddedQuestionName;
    this.embeddedQuestionUrl = params.embeddedQuestionUrl;
    this.id = params.id;
    this.imageUrl = params.imageUrl;
    this.revenueValue = params.revenueValue;
    this.sales30D = params.sales30D;
    this.sales7D = params.sales7D;
    this.sales30DPrevious = params.sales30DPrevious;
    this.sales7DPrevious = params.sales7DPrevious;
    this.numberChange30D = params.numberChange30D;
    this.numberChange7D = params.numberChange7D;
    this.percentageChange30D =
      params.percentageChange30D != null
        ? params.percentageChange30D
        : (this.sales30D || 0) > 0 && !this.sales30DPrevious
        ? Infinity
        : undefined;
    this.percentageChange7D =
      params.percentageChange7D != null
        ? params.percentageChange7D
        : (this.sales7D || 0) > 0 && !this.sales7DPrevious
        ? Infinity
        : undefined;
    this.stars = params.stars;
    this.status = params.status;
    this.strategy = params.strategy;
    this.type = params.type;
    this.widgetName = params.widgetName;
  }
}

export type FilterBase = {
  values: Record<string, boolean>;
  column: FilterColumns;
};

export type FilterUI = FilterBase & {
  displayName: string;
};

export type NewSortOptions =
  | "sales7D"
  | "changeNumber7D"
  | "changePercentage7D"
  | "sales30D"
  | "changeNumber30D"
  | "changePercentage30D";

export type Sort = {
  column: keyof InsightRead | NewSortOptions;
  ascending: boolean;
  isDirty: boolean;
};
export type Filter = Record<string, boolean>;

export type FilterMapping = Record<FilterColumns, Filter>;

export type State = {
  setFilterOut: Dispatch<SetStateAction<FilterMapping>>;
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
  filterOut: FilterMapping;
  sort: Sort;
  setSort: Dispatch<SetStateAction<Sort>>;
  setSelectedInsights: Dispatch<SetStateAction<string[]>>;
  selectedInsights: string[];
  insights: TransformedInsight[];
  isLoadingInsights: boolean;
  isFetchingInsights: boolean;
  isDrawerEnabled: boolean;
  enableDrawer: Dispatch<SetStateAction<boolean>>;
  insightsMetadata:
    | {
        followed: number;
        new: number;
        my_updated_date: string;
      }
    | undefined;
  refetchInsights: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<InsightRead[], APIError>>;
  refetchInsightsMetadata: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<
      { followed: number; new: number; my_updated_date: string },
      APIError
    >
  >;
  metadataLoading: boolean;
  selectedProductGroup: ProductsFilter | undefined | null;
  setSelectedProductGroup: Dispatch<
    SetStateAction<ProductsFilter | null | undefined>
  >;
  statsPeriod: StatsPeriods;
  setStatsPeriod: Dispatch<SetStateAction<StatsPeriods>>;
};

export enum InsightTypes {
  AD_BOOSTER = "AD_BOOSTER",
}

export abstract class BaseInsightData {}

export type Insight = {
  id: string;
  type: InsightTypes;
  date: Date;
  imageUrl: string;
  data: BaseInsightData;
  done: boolean;
  archived: boolean;
  usdValue: number;
};

export abstract class BaseInsight implements Insight {
  data: BaseInsightData;

  date: Date;

  id: string;

  imageUrl: string;

  type: InsightTypes;

  archived: boolean;

  done: boolean;

  usdValue: number;

  constructor(
    data: BaseInsightData,
    date: Date,
    id: string,
    imageUrl: string,
    type: InsightTypes.AD_BOOSTER,
    archived: boolean,
    done: boolean,
    usdValue: number
  ) {
    this.archived = archived;
    this.done = done;
    this.data = data;
    this.date = date;
    this.id = id;
    this.imageUrl = imageUrl;
    this.type = type;
    this.usdValue = usdValue;
  }
}

export type FlexInsightData = Record<string, any>;

export class FlexInsight implements BaseInsight {
  data: FlexInsightData;

  date: Date;

  id: string;

  imageUrl: string;

  type: InsightTypes;

  archived: boolean;

  done: boolean;

  usdValue: number;

  constructor(
    data: FlexInsightData,
    date: Date,
    id: string,
    imageUrl: string,
    type: InsightTypes,
    archived: boolean,
    done: boolean,
    usdValue: number
  ) {
    this.archived = archived;
    this.done = done;
    this.data = data;
    this.date = date;
    this.id = id;
    this.imageUrl = imageUrl;
    this.type = type;
    this.usdValue = usdValue;
  }

  static fromObject(obj: Insight): FlexInsight {
    return new FlexInsight(
      obj.data,
      obj.date,
      obj.id,
      obj.imageUrl,
      obj.type,
      obj.archived,
      obj.done,
      obj.usdValue
    );
  }
}
