import { FC, useEffect } from "react";
import DataGrid from "../../../../components/DataGrid";
import { useRun } from "../../../../services/runs";
import { useDataGrid } from "./hooks";
import { GridProps } from "./types";

const InputGrid: FC<GridProps> = ({ boardId, runId, selected }) => {
  const { data: run } = useRun(boardId, runId, {
    enabled: !!(boardId && runId),
  });
  const selectedInput =
    (selected && run && run.inputs.find((input) => input.name === selected)) ||
    null;
  const { onGridReady, columns, isLoading, errorMessage, reset } = useDataGrid(
    boardId,
    runId,
    selectedInput?.name || null
  );

  useEffect(() => {
    if (selected) {
      reset();
    }
  }, [selected]);

  return selectedInput ? (
    <DataGrid
      onGridReady={onGridReady}
      columns={columns}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  ) : null;
};
export default InputGrid;
