import { useContext, useMemo } from "react";
import _ from "lodash";
import { tasksContext } from "../TasksProvider";
import { assigneeData } from "../types";
import { useLocation } from "react-router-dom";
import { useAuthUser } from "@frontegg/react";
import { useAuthorizedUsers } from "../../../hooks/useUsers";

export const useTasksContext = () => useContext(tasksContext);

function convertToArray(data: assigneeData): assigneeData[] {
  // Implement the logic to convert data to an array
  // For example, if data is an object, convert its values to an array
  return Object.values(data);
}
const applyArchiveSearch = (data: assigneeData[], search: string) => {
  return data?.filter((el: any) =>
    el.description.toLowerCase().includes(search.toLowerCase())
  );
};

const applySort = (data: assigneeData, sort: any) => {
  // Your sorting logic remains unchanged
  if (data) {
    let sorted: assigneeData[] = convertToArray(data);
    if (sort.column === "description") {
      sorted = _.sortBy(sorted, "description");
      if (sort.ascending) {
        sorted.reverse();
      }
    } else if (sort.column === "related_assets") {
      sorted = _.sortBy(sorted, "related_assets");
      if (sort.ascending) {
        sorted.reverse();
      }
    } else if (sort.column === "created_by") {
      sorted = _.sortBy(sorted, "created_by");
      if (sort.ascending) {
        sorted.reverse();
      }
    } else if (sort.column === "updated_at") {
      sorted = _.sortBy(sorted, "updated_at");
      if (sort.ascending) {
        sorted.reverse();
      }
    }
    return sorted;
  } else {
    return [];
  }
};

export const useDisplayList = (search: string) => {
  const { archivedData, sort } = useTasksContext();

  return useMemo(
    () =>
      _(archivedData)
        .thru((list: any) => applySort(list, sort))
        .thru((list: any) => applyArchiveSearch(list, search))
        .map((display: assigneeData) => ({
          ...display,
        }))
        .value(),
    [archivedData, sort, search]
  );
};
interface Task {
  id: string;
  status: string;
  source: string;
  asin: string | null;
  related_assets: string;
  description: string;
  assignee: string;
  created_by: string;
  archived: boolean;
  updated_at: string;
  created_at: string;
  data: any | null;
  order: number;
  asin_data: any | null;
}

interface TasksObject {
  to_do: Task[];
  in_progress: Task[];
  done: Task[];
}

const applySearch = (list: TasksObject, search: string): TasksObject => {
  const matchingTasks: TasksObject = {
    to_do: [],
    in_progress: [],
    done: [],
  };

  // Iterate over each array
  for (const key in list) {
    if (Object.prototype.hasOwnProperty.call(list, key)) {
      matchingTasks[key as keyof TasksObject] = list[
        key as keyof TasksObject
      ].filter((task: Task) => {
        return task.description.toLowerCase().includes(search.toLowerCase());
      });
    }
  }

  return matchingTasks;
};

const applySearchByemail = (list: TasksObject, emails: string[]) => {
  const matchingTasks: TasksObject = {
    to_do: [],
    in_progress: [],
    done: [],
  };

  if (emails?.length > 0) {
    for (const key in list) {
      if (Object.prototype.hasOwnProperty.call(list, key)) {
        matchingTasks[key as keyof TasksObject] = list[
          key as keyof TasksObject
        ].filter((task: Task) => {
          return emails?.some((email) =>
            task?.assignee?.toLowerCase()?.includes(email.toLowerCase())
          );
        });
      }
    }
    return matchingTasks;
  } else {
    return list;
  }
};

export const useDisplayBoardList = (search: string, selectedAssignee: any) => {
  const { boardData } = useTasksContext();
  const location = useLocation();
  const user = useAuthUser();
  const allMembersList = useAuthorizedUsers();
  const filteredEmails = allMembersList
    .filter((member) => selectedAssignee?.includes(member?.id))
    .map((member) => member?.email);

  return useMemo(() => {
    let filteredTasks = applySearch(boardData, search);
    if (filteredEmails?.length > 0) {
      filteredTasks = applySearchByemail(filteredTasks, filteredEmails);
    } else {
      if (boardData) {
        filteredTasks;
      }
    }
    const displayBoardList: TasksObject = {
      to_do: filteredTasks?.to_do?.length > 0 ? [...filteredTasks.to_do] : [],
      in_progress:
        filteredTasks?.in_progress?.length > 0
          ? [...filteredTasks.in_progress]
          : [],
      done: filteredTasks?.done?.length > 0 ? [...filteredTasks.done] : [],
    };
    return displayBoardList;
  }, [
    boardData,
    search,
    location.pathname,
    user?.email,
    selectedAssignee?.length,
    filteredEmails?.length,
  ]);
};
