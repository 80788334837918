import { useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DefaultTheme } from "@mui/styles";
import useProductsFilter from "../../services/ecommerce/useProductsFilters";
import { useInsightsContext } from "./hooks/selectors";
import { useAllProductsFilter } from "../EcommerceProducts/Amazon/AmazonProducts";

const menuItemStyle = {
  "&:hover": {
    background: (t: DefaultTheme) => t.palette.backgrounds.active,
    color: (t: DefaultTheme) => t.palette.text.link,
  },
};

const ProductGroupFilter = () => {
  const { selectedProductGroup, setSelectedProductGroup } =
    useInsightsContext();
  const allFilters = useAllProductsFilter();
  const { data: productGroups } = useProductsFilter();
  useEffect(() => {
    productGroups?.every((productGroup) => {
      if (productGroup.default) {
        setSelectedProductGroup(productGroup);
        return false;
      }
      return true;
    });
  }, [productGroups]);
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={selectedProductGroup?.id ?? ""}
        size="small"
        displayEmpty
        MenuProps={{
          autoFocus: false,
          MenuListProps: { sx: { maxHeight: "240px", minWidth: "218px" } },
        }}
        renderValue={() => (
          <Stack direction="row">
            <Typography
              component="div"
              variant="body5"
              sx={{
                fontWeight: 600,
                color: (t) => t.palette.text.secondary,
              }}
            >
              Products:&nbsp;
            </Typography>
            <Typography component="div" variant="body5">
              {selectedProductGroup?.name ?? "All products"}
            </Typography>
          </Stack>
        )}
        onChange={(e) => {
          const id = e.target.value as string;
          if (id === "") {
            setSelectedProductGroup(null);
          } else {
            const selected = (productGroups ?? []).filter(
              (item) => item.id === id
            );
            setSelectedProductGroup(selected[0]);
          }
        }}
      >
        <MenuItem value="" dense>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flex={1}
            spacing={1}
          >
            <Typography>{allFilters?.name}</Typography>
            {allFilters?.total && (
              <Typography variant="body5" color="text.secondary">
                {allFilters?.total} products
              </Typography>
            )}
          </Stack>
        </MenuItem>
        {(productGroups ?? [])
          .filter((item) => !item.hide)
          .map((productGroup) => (
            <MenuItem
              key={productGroup?.id}
              sx={menuItemStyle}
              value={productGroup.id}
              dense
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                flex={1}
                spacing={1}
              >
                <Typography>{productGroup?.name}</Typography>
                {productGroup?.total ? (
                  <Typography variant="body5" color="text.secondary">
                    {productGroup?.total} products
                  </Typography>
                ) : null}
              </Stack>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ProductGroupFilter;
