import { Collapse } from "@mui/material";
import { FC, useState } from "react";
import OutputsTable from "../../../../components/OutputsTable";
import { Status } from "../../../../enums";
import { useRun } from "../../../../services/runs";
import { BoardAndRunIds } from "../../../../types";
import NoOutputs from "./NoOutputs";
import OutputsDropdown from "./OutputsDropdown";

const Outputs: FC<BoardAndRunIds> = ({ boardId, runId }) => {
  const { data: run } = useRun(boardId, runId);
  const [open, setOpen] = useState(true);

  return run ? (
    open ? (
      <Collapse in={open}>
        {run.status === Status.SUCCEEDED ? (
          <OutputsTable outputs={run.outputs} open={open} setOpen={setOpen} />
        ) : (
          <>
            <OutputsDropdown
              outputs={run.outputs}
              open={open}
              setOpen={setOpen}
            />
            <NoOutputs boardId={boardId} run={run} />
          </>
        )}
      </Collapse>
    ) : (
      <OutputsDropdown outputs={run.outputs} open={open} setOpen={setOpen} />
    )
  ) : null;
};

export default Outputs;
