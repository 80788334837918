import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  useTheme,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { RefreshIcon } from "../../assets";
import { useState } from "react";
import { useSnackbar } from "notistack";
import LoadingSpinner from "../../components/LoadingSpinner";

interface PropTypes {
  open: boolean;
  onClose: () => void;
  onSync: () => any;
}

const SyncDialog = (props: PropTypes) => {
  const { open, onClose, onSync } = props;
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const theme = useTheme();

  const handleSync = async () => {
    if (syncLoading) return;

    setSyncLoading(true);
    await onSync()
      .then(() => {
        snackbar.enqueueSnackbar("Sync successful", { variant: "success" });
      })
      .finally(() => {
        setSyncLoading(false);
        onClose();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        padding: "24px",
        "& .MuiDialogContent-root": {
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        },
        "& .MuiPaper-root": {
          gap: "24px",
          padding: "24px",
          borderRadius: "12px",
        },
        maxWidth: "600px",
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ position: "relative", padding: "0px" }}
      >
        <Typography
          variant="h6"
          sx={{ lineHeight: "24px", fontSize: "18px", fontWeight: "600" }}
        >
          Are you sure you want to sync?
        </Typography>
        <Button
          sx={{
            position: "absolute",
            padding: "0px",
            minWidth: "10px",
            minHeight: "10px",
            top: "-14px",
            right: "-14px",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "& svg": {
              fontSize: "16px",
            },
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </Button>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0px",
          "& .MuiOutlinedInput-root": {
            backgroundColor: theme.palette.grey[50],
            borderRadius: "8px",
            padding: "0 !important",
          },
        }}
      >
        <Typography>
          This sync function ensures your report’s time parameters remain
          consistent relative to the create date.
        </Typography>
        <Typography>This action cannot be undone.</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "0px" }}>
        <Button
          onClick={handleSync}
          variant="contained"
          color="primary"
          sx={{
            display: "flex",
            gap: "10px",
            "& svg": {
              width: "15px",
              height: "15px",
            },
            "> svg > path": {
              fill: "white",
            },
            minWidth: "100px",
          }}
        >
          {syncLoading ? (
            <LoadingSpinner size={15} color="white" />
          ) : (
            <>
              <RefreshIcon />
              Sync
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SyncDialog;
