import { Box, BoxProps } from "@mui/material";

export const AmazonAdsImg = (props: any) => (
  <img src={`/amazon-ads-icon.svg`} alt="amazon" {...props} />
);
export const AmazonAdsIcon = ({ sx, ...props }: BoxProps) => (
  <Box
    width={56}
    height={56}
    sx={{
      cursor: "pointer",
      border: (t) => `1px solid ${t.palette.borders.default}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 1,
      ...sx,
    }}
    {...props}
  >
    <AmazonAdsImg height="24px" width="24px" />
  </Box>
);
