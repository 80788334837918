import { Stack } from "@mui/material";
import IFrame from "./IFrame";
import CubeJsProvider from "./CubeJsProvider";

export const AdBooster = () => (
  <CubeJsProvider>
    <Stack flex={1}>
      <IFrame />
    </Stack>
  </CubeJsProvider>
);

export default AdBooster;
