import {Box, ThemeProvider} from "@mui/material";

import {useParams} from "react-router-dom";
import theme from "../../themev2";
import Marketplace from "../MarketplaceV2";
import AddNewFlowForm from "./AddNewFlowForm";
import NewFlowHeader from "./newFlowHeader";
import {FC} from "react"

const NewFlow: FC = () => {
  const { flowId } = useParams<{ flowId?: string }>();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <NewFlowHeader />
        {!flowId ? (
          <AddNewFlowForm />
        ) : (
          <Box flexGrow={1} display="flex" flexDirection="column">
            <Marketplace />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};
export default NewFlow;
