import { Box, Stack, Typography, useTheme } from "@mui/material";
// import { ErrorIcon } from "../../assets";

const ErrorComponent = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        // height: "402px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F8F7FA",
        borderRadius: "12px",
        padding: "17px 20px",
        marginBottom: "1rem",
        height: "493px",
        position: "relative",
      }}>
      <Stack
        // pt="16px"
        spacing={1.5}
        direction="column"
        alignItems="center">
        {/* <ErrorIcon /> */}
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            color: theme.palette.grey[400],
          }}>
          We couldn’t load your data
        </Typography>
        <Typography
          sx={{
            fontSize: "11px",
            fontWeight: 500,
            lineHeight: "20px",
            color: theme.palette.grey[400],
          }}>
          Please contact support
          {/* <Box
            component={"a"}
            sx={{
              color: theme.palette.primary.dark,
              textDecorationLine: "underline",
            }}>
            contact support
          </Box> */}
        </Typography>
      </Stack>
      {/* <Typography
        sx={{
          fontSize: "10px",
          fontWeight: 600,
          lineHeight: "150%",
          textOverflow: "ellipsis",
          textAlign: "center",
          color: theme.palette.grey[400],
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)"
        }}>
        Typically this indicates that a server is under excessive load and that is causing the server to respond slowly to requests.
      </Typography> */}
    </Box>
  );
};

export default ErrorComponent;
