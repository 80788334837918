import { createTheme } from "@mui/material/styles";
import { deepPurple, red } from "@mui/material/colors";
import Highcharts from "highcharts";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    infoBackground: Palette["primary"];
  }

  interface PaletteOptions {
    infoBackground: PaletteOptions["primary"];
  }
}

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Inter";
          src: url("/Inter.ttf") format("truetype");
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  palette: {
    primary: deepPurple,
    secondary: red,
    success: {
      light: "#53ca9e",
      main: "#38bf8d",
      dark: "#419c7a",
      contrastText: "#fff",
    },
    background: {
      default: "#ffffff",
    },
    infoBackground: {
      main: "#f3f3f3",
    },
  },
  typography: {
    fontSize: 13,
    h6: {
      fontWeight: 400,
    },
  },
});

export const chartTheme: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  title: {
    text: "",
    align: "left",
  },
  subtitle: {
    text: "",
    align: "left",
  },
  tooltip: {
    valueSuffix: "",
    borderWidth: 6,
    borderColor: "rgb(103 58 183 / 3%)",
    backgroundColor: "white",
    borderRadius: 6,
    padding: 15,
    xDateFormat: "%d %B %Y",
  },
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
};

export default theme;
