import SalesAssistantProvider, {
  useSalesAssistant,
} from "./SalesAssistantProvider.tsx";
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Header from "./Header";
import MainSection from "./MainSection/index.tsx";
import SalesAssistantDrawer from "./Drawer/index.tsx";
import { useMutation } from "react-query";
import { generateSalesAssistant } from "./api.ts";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useUserHasNoogataAdminRole } from "../../services/auth.ts";

const Index = () => {
  const {
    isLoading,
    salesAssistantListFetching,
    isThereSalesAssistant,
    isThereSalesAssistantLoading,
    selectedProduct,
    setSelectedAsin,
  } = useSalesAssistant();
  const [isFetching, setIsFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const generateSalesAssistantMutation = useMutation(() =>
    generateSalesAssistant()
  );
  const isNoogataAdmin = useUserHasNoogataAdminRole();

  if (!isThereSalesAssistant)
    return (
      <Stack height="100vh" justifyContent="center" alignItems="center">
        {isThereSalesAssistantLoading ? (
          <Box>Loading...</Box>
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="headline3">
              Empty state - there is no sales assistant data
            </Typography>
            {isNoogataAdmin && (
              <IconButton
                sx={{
                  "@keyframes rotate": {
                    "0%": {
                      transform: "rotate(0deg)",
                    },
                    "50%": {
                      transform: "rotate(180deg)",
                    },
                    "100%": {
                      transform: "rotate(360deg)",
                    },
                  },
                  animation: isFetching ? "rotate 2s ease infinite" : "",
                }}
                disabled={isFetching}
                aria-label="refresh"
                onClick={() => {
                  setIsFetching(true);
                  generateSalesAssistantMutation
                    .mutateAsync()
                    .then(() => {
                      enqueueSnackbar(
                        "Sales assistant update finished succesfully",
                        {
                          variant: "success",
                        }
                      );
                      setIsFetching(false);
                    })
                    .catch((e: any) => {
                      console.warn(e);
                      enqueueSnackbar("Could not fetch sales assistant", {
                        variant: "error",
                      });
                      setIsFetching(false);
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </Stack>
    );

  return (
    <>
      <Stack height="100vh">
        <Header />
        {(isLoading || salesAssistantListFetching) && <LinearProgress />}
        <MainSection />
      </Stack>
      <SalesAssistantDrawer
        onDrawerClose={() => {
          setSelectedAsin(undefined);
        }}
        isDrawerOpen={!!selectedProduct}
      />
    </>
  );
};

export default () => (
  <SalesAssistantProvider>
    <Index />
  </SalesAssistantProvider>
);
