import { useAuthUser } from "@frontegg/react-hooks";
import { Button } from "@mui/material";
import { FC } from "react";
import { useUserTenant } from "../../../../hooks";
import { useTenantConfiguration } from "../../../../services/tenants/useTenantConfiguration";
import { dataSources } from "../../../../types/datasources";

const ConnectAmazonStoreButton: FC = () => {
  const user = useAuthUser();
  const tenant = useUserTenant(user || undefined);
  const { data: tenantConfig } = useTenantConfiguration(
    tenant?.tenantId || "",
    { enabled: !!tenant?.tenantId }
  );
  return (
    <Button
      variant="outlined"
      sx={{
        color: "text.primary",
        backgroundColor: "white",
        fontWeight: 600,
        textTransform: "none",
        "&:hover": {
          backgroundColor: "grey.200",
        },
      }}
      onClick={() =>
        window.open(
          dataSources.amazon_north_america.oauthurlGen({
            isSeller: !!tenantConfig?.configuration?.is_seller,
          }),
          "_blank"
        )
      }
      disabled={!tenant}
    >
      Connect Amazon Store
    </Button>
  );
};

export default ConnectAmazonStoreButton;
