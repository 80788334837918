import { Divider, Tab, Tabs } from "@mui/material";

import { Link, useParams } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { FlowSections } from "./index";

const FlowsTabs = () => {
  const { url } = useRouteMatch();
  const parsedUrl = url.split("/").slice(0, 3).join("/");
  const { sectionId }: { sectionId: string } = useParams();
  return (
    <>
      <Tabs
        orientation="horizontal"
        textColor="primary"
        indicatorColor="primary"
        value={sectionId}
      >
        <Tab
          component={Link}
          to={`${parsedUrl}/${FlowSections.Overview}`}
          label="Overview"
          value={FlowSections.Overview}
        />
        <Tab
          component={Link}
          to={`${parsedUrl}/${FlowSections.Configuration}`}
          label="Configuration"
          value={FlowSections.Configuration}
        />
        <Tab
          component={Link}
          disabled
          to={`${parsedUrl}/${FlowSections.Data}`}
          label="Data"
          value={FlowSections.Data}
        />
      </Tabs>
      <Divider />
    </>
  );
};

export default FlowsTabs;
