import { FC, ReactElement } from "react";
import { OnboardingStatusEnum } from "../../../enums";
import {
  CompletedCardHeader,
  GatheringCardHeader,
  PendingCardHeader,
  TrainingCardHeader,
} from "./Components";
import { StyledCard } from "./StyledComponents";
import { useOnboardingStatus } from "../../../services/ecommerce";
import { useIsTenantNew } from "../../../hooks";

const statusMapping: Record<OnboardingStatusEnum, ReactElement> = {
  [OnboardingStatusEnum.PENDING]: <PendingCardHeader />,
  [OnboardingStatusEnum.GATHERING]: <GatheringCardHeader />,
  [OnboardingStatusEnum.TRAINING]: <TrainingCardHeader />,
  [OnboardingStatusEnum.SUCCEEDED]: <CompletedCardHeader />,
};

const OnboardingHeader: FC = () => {
  const { data } = useOnboardingStatus();
  const { isTenantNew } = useIsTenantNew();
  return isTenantNew === true && data && !data.dismissed ? (
    <StyledCard>{statusMapping[data.status]}</StyledCard>
  ) : null;
};

export default OnboardingHeader;
