import { FC, ReactNode } from "react";

import { Box, Typography } from "@mui/material";

const PageHeader: FC<{
  headerLabel: string;
  actions?: ReactNode;
}> = ({ headerLabel, actions }) => {
  const imageUrl = `/boards_overview.svg`;
  return (
    <Box
      sx={{
        maxHeight: 160,
        background: `url("${imageUrl}")`,
        backgroundPositionX: "50px",
        backgroundPositionY: "-50px",
        backgroundSize: "auto",
        backgroundRepeat: "repeat-x",
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 40,
          }}
        >
          <Typography variant="h4" fontWeight="bold" sx={{ color: "white" }}>
            {headerLabel}
          </Typography>
          {actions}
        </Box>
      </Box>
    </Box>
  );
};

export default PageHeader;
