import {Box, Container, Grid, Step, StepLabel, Stepper, Typography,} from "@mui/material";
import {useHistory} from "react-router";

import {useParams} from "react-router-dom";
import {BackButton} from "../../components/BackButton";
import {FC} from "react"

const NewFlowHeader: FC<object> = () => {
  const history = useHistory();
  const {flowId} = useParams<{ flowId?: string }>();
  return (
      <Box sx={{backgroundColor: (t) => t.palette.grey[50]}}>
        <Container maxWidth="lg" sx={{ml: 0, pt: 5}}>
          <Grid container direction="column" item>
            <BackButton
                onBack={() => history.goBack()}
            label="Back to Flows list"
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h4" fontWeight="600">
              Create a new flow
            </Typography>
          </Box>
          <Box sx={{ alignItems: "flex-start", width: "40%", p: 0 }}>
            <Stepper activeStep={flowId ? 1 : 0} sx={{ pb: 2 }}>
              {["Configure flow", "Install the first block"].map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};
export default NewFlowHeader;
