import {Box, Paper, Skeleton, Typography} from "@mui/material";
import ReactMarkdown from "react-markdown";
import {CircuitBoard} from "../../../types";
import useBoardDocs from "./useBoardDocs";
import useFetchDocs from "./useFetchDocs";
import {FC} from "react"

type VersionNotesProps = {
  board: CircuitBoard | undefined;
};

const VersionNotes: FC<VersionNotesProps> = ({ board }) => {
  const { data: boardDocs, isLoading: isBoardDocsLoading } = useBoardDocs(
    board?.identifier
  );

  const { data: docsFile, isLoading: isDocsFileLoading } = useFetchDocs(
    boardDocs,
    isBoardDocsLoading
  );
  const isLoading = isBoardDocsLoading || isDocsFileLoading;

  return (
    <Box
      flexDirection="column"
      display="flex"
      width="50%"
      mt={1}
      mr={5}
      maxHeight={(t) => t.spacing(570 / 8)}
      maxWidth={(t) => t.spacing(750 / 8)}
      overflow-y="auto"
    >
      {isLoading ? (
        <>
          <Typography variant="h3" sx={{ mt: -1, mb: -2.5 }}>
            <Skeleton width="20%" />
          </Typography>
          <Typography variant="h1">
            <Skeleton />
          </Typography>
        </>
      ) : (
        docsFile && (
          <>
            <Typography variant="h5" sx={{ mr: 7.5, mb: 1.5 }}>
              Version Notes
            </Typography>
            <Paper
              elevation={3}
              variant="outlined"
              sx={{
                maxHeight: (t) => t.spacing(560 / 8),
                p: 2,
                overflowY: "auto",
              }}
            >
              <Typography variant="body1" flexDirection="column" display="flex">
                <ReactMarkdown>{docsFile}</ReactMarkdown>
              </Typography>
            </Paper>
          </>
        )
      )}
    </Box>
  );
};

export default VersionNotes;
