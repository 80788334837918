import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import theme from "../../../../themes/light.ts";
import { DrawerQuestion } from "../../types.ts";
import { useMemo } from "react";
import { useSalesAssistant } from "../../SalesAssistantProvider.tsx";

export const EmbeddedQuestion = ({
  drawerQuestion,
}: {
  drawerQuestion: DrawerQuestion;
}) => {
  const { salesCardData, salesCardsDataLoading } = useSalesAssistant();
  const iframeUrl = useMemo(() => {
    return salesCardData?.find(
      (card) => card.question_name === drawerQuestion.question_name,
    )?.url;
  }, [salesCardData, drawerQuestion]);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "12px",
        height: iframeUrl ? "400px" : "220px",
        borderRadius: "8px",
        marginLeft: "32px",
      }}
    >
      <Stack
        spacing={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {salesCardsDataLoading ? (
          <Skeleton animation="wave" height="220px" width="100%" />
        ) : (
          <>
            {iframeUrl ? (
              <iframe
                title={drawerQuestion.title || ""}
                src={iframeUrl}
                style={{ border: 0 }}
                width="100%"
                height="100%"
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "auto",
                  textAlign: "center",
                  padding: "0px 80px",
                  gap: "20px",
                  margin: "30px 0px",
                }}
              >
                <Box>
                  <img src="/insights/noads.svg" alt="noogata" />
                </Box>
                <Box display="flex" gap="4px" flexDirection="column">
                  <Typography
                    variant="body3"
                    color={theme.palette.text.primary}
                    sx={{ fontWeight: 600 }}
                  >
                    No Data found
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default EmbeddedQuestion;
