import { FC } from "react";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

type ErrorProps = {
  message: string;
};

const ErrorMessage: FC<ErrorProps> = (props) => (
  <Box p={1} m={1}>
    <Typography variant="h6" align="center">
      We are sorry but we are not able to complete the request
    </Typography>
    <Typography variant="body2" gutterBottom align="center">
      {props.message}
    </Typography>
  </Box>
);

export default ErrorMessage;
