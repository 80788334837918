import { Stack } from "@mui/material";
import Filters from "./components/Filters/Filters";
import MarketBreakdown from "./MarketBreakdown";
import BrandStrategy from "./BrandStrategy";
import ViewTabs from "./components/ViewTabs";
import { useAssortmentIntelligence } from "./AssortmentIntelligenceProvider";
import { SelectedView } from "./types";
import ProductsReview from "./ProductsReview";
import PricingStrategy from "./PricingStrategy";

const MainSection = () => {
  const { selectedView } = useAssortmentIntelligence();
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        padding: "30px",
      }}
    >
      <Filters />
      <ViewTabs />
      {selectedView === SelectedView.MarketBreakdown && <MarketBreakdown />}
      {selectedView === SelectedView.BrandStrategy && <BrandStrategy />}
      {selectedView === SelectedView.ProductsReview && <ProductsReview />}
      {selectedView === SelectedView.PricingStrategy && <PricingStrategy />}
    </Stack>
  );
};

export default MainSection;
