import {Button, DialogActions} from "@mui/material";
import {FC} from "react"

const Actions: FC<{
    onSubmit: () => void;
    onBack: () => void;
    submitDisabled: boolean;
}> = ({onSubmit, onBack, submitDisabled}) => (
    <DialogActions>
        <Button color="primary" variant="contained" onClick={onBack} autoFocus>
            Back
        </Button>
    <Button
      variant="contained"
      color="primary"
      disabled={submitDisabled}
      onClick={onSubmit}
    >
      Continue To Mapping
    </Button>
  </DialogActions>
);

export default Actions;
