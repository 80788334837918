import { Box, useTheme } from "@mui/material";
import GraphDefaultSkeleton from "./Graph/GraphDefaultSkeleton";
import { memo, useEffect, useRef } from "react";

const StoryPointSkeletonLoader = memo(() => {
  const loaderRef = useRef<HTMLDivElement>(null);

  // Scroll to the loader
  useEffect(() => {
    if (loaderRef.current) {
      loaderRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        boxShadow:
          "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
        background: theme.palette.primary.contrastText,
        borderRadius: "12px",
        padding: "40px",
        width: "100%",
        maxWidth: "1000px",
        height: "675px",
      }}
      ref={loaderRef}
      // className="session-loader"
    >
      <GraphDefaultSkeleton />
      {/* <ChatQuestionsSkeleton /> */}
    </Box>
  );
});

export default StoryPointSkeletonLoader;
