import { Box, Button, Typography, keyframes } from "@mui/material";
import { FC, memo, useEffect, useRef, useState } from "react";
import { AddIcon, AddRelatedStoryPointIcon, AddTextIcon } from "../../assets";
import theme from "../../themes/light";
import { useSnackbar } from "notistack";
import RichTextEditorV2 from "../../components/RichTextEditor/RichTextEditorV2";
import RelatedStoryPoints from "./RelatedStoryPoints";
import { useInsightChatContext } from "./hooks/selectors";
import TextBlockSkeletonLoader from "./TextBlockSkeletonLoader";

interface AddStoryPointProps {
  index: number;
  adjacentStoryPoint?: any;
  isLastAbove: boolean;
  nearLoader?: boolean;
}

const DropdownMenuItem: FC<{ text: string; icon: any; onClick: () => void }> = (
  props
) => {
  const { text, icon, onClick } = props;

  return (
    <Button
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "10px",
        ":hover": {
          cursor: "pointer",
          // background: theme.palette.grey[25],
        },
        padding: "8px",
        borderRadius: "8px",
      }}
      onClick={onClick}
    >
      {icon}
      <Typography
        variant="body4"
        sx={{
          fontSize: "16px",
          fontFamily: "Inter",
          fontWeight: "600",
          letterSpacing: "-0.14px",
          display: "block",
          color: theme.palette.secondary.main,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};
const AddStoryPoint: FC<AddStoryPointProps> = memo((props) => {
  const { index, adjacentStoryPoint, isLastAbove, nearLoader = true } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { addStoryPointToStoryboard, fetchQuestionList, chatTemplate } =
    useInsightChatContext();

  const [showAddButton, setShowAddButton] = useState<boolean>(false);
  const buttonRef = useRef<any>(null);

  // Hover Logic
  const [isHovered, setIsHovered] = useState<boolean>(false);
  useEffect(() => {
    if (isHovered) {
      setShowAddButton(true);
    } else {
      if (!showDropdown) {
        setShowAddButton(isLastAbove);
      }
    }
  }, [isHovered]);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<any>(null);

  const scrollToDropdown = () => {
    dropdownRef.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (showDropdown && isLastAbove) {
      scrollToDropdown();
    }

    // Handle click outside dropdown
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (showDropdown && !buttonRef.current.contains(event.target)) {
          setShowDropdown(false);
          if (!isHovered) {
            setShowAddButton(isLastAbove);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showDropdown, isHovered]);

  // Text Block Logic
  const [showNewTextBlockEditor, setShowNewTextBlockEditor] =
    useState<boolean>(false);
  const [newTextBlockContent, setNewTextBlockContent] = useState<string>("");
  const [isTextBlockFocused, setIsFocused] = useState<boolean>(false);
  const [isCreatingTextBlock, setIsCreatingTextBlock] =
    useState<boolean>(false);

  const handleAddNewTextBlockEditor = () => {
    setShowDropdown(false);
    setShowAddButton(false);
    setShowNewTextBlockEditor(true);
  };

  const handleCloseNewTextBlockEditor = () => {
    setShowNewTextBlockEditor(false);
    setNewTextBlockContent("");
    setShowAddButton(isLastAbove);
  };

  const handleSubmitNewTextBlock = async (html: string) => {
    if (!html) return;
    setShowAddButton(false);
    setIsCreatingTextBlock(true);
    await addStoryPointToStoryboard(
      "simple-text",
      [{ id: "text", value: html }],
      index
    )
      .then(() => {
        setNewTextBlockContent("");
      })
      .catch(() =>
        enqueueSnackbar("Failed to add text block", { variant: "error" })
      )
      .finally(() => {
        setIsCreatingTextBlock(false);
        setShowNewTextBlockEditor(false);
      });
  };

  // Related Story Logic
  const [relatedQuestions, setRelatedQuestions] = useState<any>([]);
  const [relatedQuestionsPending, setRelatedQuestionsPending] = useState(false);
  const [showRelatedQuestionsOption, setShowRelatedQuestionsOption] =
    useState(true);
  const [relatedQuestionsTags, setRelatedQuestionsTags] = useState<any>([]);

  useEffect(() => {
    if (chatTemplate) {
      setRelatedQuestionsTags([
        ...new Set(adjacentStoryPoint?.tags),
        chatTemplate,
      ]);
    } else {
      setRelatedQuestionsTags([...new Set(adjacentStoryPoint?.tags)]);
    }
  }, [adjacentStoryPoint, chatTemplate]);

  useEffect(() => {
    setShowRelatedQuestionsOption(relatedQuestionsTags.length > 0);
  }, [relatedQuestionsTags]);

  const fetchRelatedQuestions = async () => {
    // if (relatedQuestionsPending) return;
    setRelatedQuestionsPending(true);
    return await fetchQuestionList(relatedQuestionsTags)
      .then((res: any) => {
        setRelatedQuestions(res);
      })
      .finally(() => {
        setRelatedQuestionsPending(false);
      });
  };

  const [showRelatedStoriesMenu, setShowRelatedStoriesMenu] =
    useState<boolean>(false);
  const relatedStoriesMenuRef = useRef<any>(null);

  const scrollToRelatedStoriesMenu = () => {
    relatedStoriesMenuRef.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const handleShowRelatedStoriesMenu = async () => {
    setShowRelatedStoriesMenu(true);
    setShowDropdown(false);
    setShowAddButton(false);
    setTimeout(() => scrollToRelatedStoriesMenu(), 300);
    await fetchRelatedQuestions();
  };

  const handleCloseRelatedStoriesMenu = () => {
    setShowRelatedStoriesMenu(false);
    setShowAddButton(isLastAbove);
  };

  const handleAddRelatedStoryPoint = async (qId: any) => {
    setShowAddButton(false);
    setShowRelatedStoriesMenu(false);
    await addStoryPointToStoryboard(qId, [], index);
  };

  return !showNewTextBlockEditor && !showRelatedStoriesMenu ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
      onMouseEnter={() => {
        if (nearLoader) setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (nearLoader) setIsHovered(false);
      }}
    >
      <Box
        sx={{
          margin: showAddButton ? "0.5rem 0" : "0",
          transition: "margin 0.5s",
          minHeight: "32px",
        }}
      >
        {/* Add Button */}
        {nearLoader && (
          <Button
            ref={buttonRef}
            variant="contained"
            color="primary"
            sx={{
              opacity: showAddButton ? "1" : "0",
              transition: "opacity 0.3s",
              ":hover": {
                cursor: "pointer",
              },
              minHeight: "32px",
              maxHeight: "32px",
              gap: "8px",
              padding: "10px 16px 10px 16px",
              color: "white",
              borderRadius: "20px",
            }}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <AddIcon />
            Add
          </Button>
        )}
        <Box
          sx={{
            display: showDropdown ? "flex" : "none",
            animation: `${keyframes({
              "0%": { opacity: "0" },
              "25%": { opacity: "0.5" },
              "100%": { opacity: "1" },
            })} 0.3s`,
            transition: "opacity 0.25s",
            flexDirection: "column",
            alignItems: "center",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: "8px",
            zIndex: "3",
            width: "16rem",
            padding: "8px",
            position: "absolute",
            background: theme.palette.background.default,
            boxShadow:
              "0px 1px 18px 0px rgba(44, 34, 76, 0.08), 0px 4px 16px 0px rgba(44, 34, 76, 0.08), 0px 4px 12px 0px rgba(44, 34, 76, 0.04), 0px 2px 6px 0px rgba(44, 34, 76, 0.12)",
          }}
          id="addText-tooltip"
          ref={dropdownRef}
        >
          <DropdownMenuItem
            text="Note"
            icon={<AddTextIcon />}
            onClick={handleAddNewTextBlockEditor}
          />
          {showRelatedQuestionsOption && (
            <DropdownMenuItem
              text="Related Question"
              icon={<AddRelatedStoryPointIcon />}
              onClick={handleShowRelatedStoriesMenu}
            />
          )}
        </Box>
      </Box>
    </Box>
  ) : showRelatedStoriesMenu ? (
    <Box
      ref={relatedStoriesMenuRef}
      sx={{
        minWidth: "1200px",
        transform: "translateX(-90px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          ".close-btn": {
            display: "block",
          },
        },
      }}
    >
      <RelatedStoryPoints
        relatedQuestions={relatedQuestions}
        relatedQuestionsPending={relatedQuestionsPending}
        handleAddRelatedQuestion={handleAddRelatedStoryPoint}
        onClose={handleCloseRelatedStoriesMenu}
      />
    </Box>
  ) : !isCreatingTextBlock ? (
    <Box
      sx={{
        boxShadow:
          "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
        background: theme.palette.primary.contrastText,
        borderRadius: "12px",
        width: "100%",
        maxWidth: "1000px",
        outline: isTextBlockFocused
          ? `2px solid ${theme.palette.primary.main}`
          : "none",

        padding: "24px 42px",
        margin: "1rem 0",
      }}
      id="text-editor-container"
    >
      <RichTextEditorV2
        onSave={handleSubmitNewTextBlock}
        onClose={handleCloseNewTextBlockEditor}
        isPending={false}
        initialHtml={newTextBlockContent}
        setIsFocused={setIsFocused}
      />
    </Box>
  ) : (
    <TextBlockSkeletonLoader
    // height={document.getElementById("text-editor-container")?.clientHeight}
    />
  );
});

export default AddStoryPoint;
