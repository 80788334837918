import { AgGridReact } from "ag-grid-react";
import theme from "../../../themes/light.ts";
import { Box, LinearProgress, Skeleton } from "@mui/material";
import { usePerfectContentQuery } from "../useSpaces.ts";
import { agGridBoxStyle, colDefs } from "./enums.tsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetPerfectContentResponse } from "../../../services/ecommerce/useEcommerceProducts.ts";
import { useSpace } from "../SpaceProvider.tsx";
import CustomPagination from "../Pagination.tsx";

const AsinsListPerfectContentView = ({ spaceId }: { spaceId: string }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const gridRef = useRef<AgGridReact>(null);
  const history = useHistory();
  const { filterByLabel, filterTextAfterDebounce, contains } = useSpace();

  const { data: asinsList, isFetching: isFetchingAsins } =
    usePerfectContentQuery({
      spaceId: spaceId,
      filterByLabel,
      filterByText: filterTextAfterDebounce,
      filterByContains: contains,
      offset: (currentPage - 1) * 100,
    });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    history.push(`/perfect-content-v2/${spaceId}/${selectedRows[0].asin}`);
  }, [spaceId]);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterByLabel, filterTextAfterDebounce, contains]);

  return (
    <Box
      sx={{
        flex: 1,
        background: theme.palette.grey[50],
        overflow: "auto",
      }}
    >
      {isFetchingAsins && <LinearProgress />}
      <Box
        sx={{
          display: "flex",
          padding: "1rem 2rem 0 2rem",
          justifyContent: "space-between",
          height: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isFetchingAsins ? (
            <Skeleton
              animation="wave"
              width="160px"
              sx={{
                height: "10px",
                borderRadius: "8px",
                transform: "unset",
              }}
            />
          ) : (
            <>
              Showing{" "}
              {(asinsList as GetPerfectContentResponse)?.data?.length ?? ""}/
              {(asinsList as GetPerfectContentResponse)?.total ?? ""}
            </>
          )}
        </Box>
      </Box>
      <Box className={"ag-theme-quartz"} sx={agGridBoxStyle}>
        <AgGridReact
          ref={gridRef}
          rowData={(asinsList as GetPerfectContentResponse)?.data ?? []}
          columnDefs={colDefs}
          rowSelection={"single"}
          onSelectionChanged={onSelectionChanged}
        />
      </Box>
      <CustomPagination
        totalRows={asinsList?.total ?? 0}
        currentPage={currentPage}
        handleChange={handlePageChange}
        type="ProductList"
      />
    </Box>
  );
};

export default AsinsListPerfectContentView;
