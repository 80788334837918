import {
  Chip,
  ChipProps,
  Stack,
  StackProps,
  Tooltip,
  Typography,
  //useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import DateParser from "../DateParser";
import theme from "../../../themes/light";
// import { useSplitEnabled } from "../../../hooks";
// import { Split } from "../../../FeatureFlags/enums";
// import { useTaskListData } from "../../Tasks/useTaskApi";

export const InsightDate: FC<{ date: Date }> = ({ date }) => (
  <Typography variant="caption" color="text.secondary">
    {DateParser(dayjs(date))}
  </Typography>
);

export const InsightCategoryText: FC<{ categories: any }> = ({
  categories,
}) => (
  <Tooltip title={categories}>
    <Typography variant="caption" color="text.secondary">
      {`${categories?.split("|")[0]} | ... | ${categories?.split("|").pop()}`}
    </Typography>
  </Tooltip>
);

const InfoText: FC<{
  insight: any;
}> = ({ insight }) => {
  return (
    <>
      <Stack
        direction="column"
        flexDirection="row"
        alignItems="flex-start"
        gap="8px"
      >
        <Chip
          label={`${insight?.asins || 0} ASINS`}
          size="small"
          sx={{
            borderRadius: "4px",
            padding: " 0px 4px",
            background: theme.palette.backgrounds.secondary,
            textTransform: "capitalize",
            fontSize: "13px",
            fontWeight: 500,
            alignItems: "center",
          }}
        />
        <Chip
          label={`${insight?.tasks || 0} Task`}
          size="small"
          sx={{
            borderRadius: "4px",
            padding: "0px 4px",
            background: theme.palette.warning.light,
            color: theme.palette.warning.dark,
            textTransform: "capitalize",
            fontSize: "13px",
            fontWeight: 500,
            alignItems: "center",
          }}
        />
      </Stack>
    </>
  );
};

export const CategoryInfo: FC<
  StackProps & {
    insight: any;
    chipProps?: ChipProps;
  }
> = ({ insight, ...props }) => {
  return (
    <Stack direction="column" alignItems="space-between" {...props} gap="12px">
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          variant="headline3"
        >
          {insight?.title}
        </Typography>
      </Stack>
      <InfoText insight={insight} />
    </Stack>
  );
};
