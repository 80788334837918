import { Avatar, Box, TableCell, TableRow, Typography } from "@mui/material";

import SVG from "react-inlinesvg";
import theme from "../themev2";
import { RunOutput } from "../types";
import { prettifyString } from "../utils";
import { FC } from "react";

const OutputRow: FC<{
  output: RunOutput;
  onClick?: (e: any) => void;
}> = ({ output, onClick }) => (
  <TableRow
    key={output.export_name}
    onClick={onClick}
    sx={{ cursor: "pointer" }}
  >
    <TableCell component="th" scope="row">
      <Box display="flex" sx={{ alignItems: "center" }}>
        <Avatar
          sx={{
            bgcolor: theme.palette.success.light,
            height: "32px",
            width: "32px",
            mr: 2,
          }}
          variant="rounded"
        >
          <SVG fill={theme.palette.primary.dark} src={`/output.svg`} />
        </Avatar>
        {prettifyString(output.export_name)}
      </Box>
    </TableCell>
    <TableCell>
      {output?.bigquery_warehouse_export_result && (
        <Box display="flex" sx={{ alignItems: "center" }}>
          <img
            width={32}
            height={32}
            src={`/connector-google-bigquery-icon.svg`}
            alt=""
          />
          <Typography sx={{ ml: 1.5 }}>
            {output.bigquery_warehouse_export_result.split(".")[2]}
          </Typography>
        </Box>
      )}
    </TableCell>
    <TableCell>{output.total_rows || 0}</TableCell>
  </TableRow>
);

export default OutputRow;
