import { FC } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SVG from "react-inlinesvg";
import { useOnboardingStatus } from "../../../../services/ecommerce";
import { StyledAvatar, StyledCardHeader } from "../StyledComponents";
import { ViewDataButton } from "../Buttons";
import RotatingBox from "../../../../components/RotatingBox";

const TrainingCardHeader: FC = () => {
  const theme = useTheme();
  const { data } = useOnboardingStatus();
  return (
    <StyledCardHeader
      title="Step 2 of 2 - looking for all products similar to yours"
      subheader="AI models are being applied to your data. This process may take few hours to complete. You will be notified by email once this process is complete."
      avatar={
        <StyledAvatar variant="rounded">
          <RotatingBox>
            <SVG fill={theme.palette.primary.dark} src={`/rotate.svg`} />
          </RotatingBox>
        </StyledAvatar>
      }
      action={
        data && data.total_products ? (
          <Stack spacing={1}>
            <ViewDataButton />
            <Stack direction="row" spacing={1} justifyContent="center">
              <CheckCircleIcon sx={{ color: "white" }} />
              <Typography color="white">
                {`We’ve pulled ${data.total_products} products`}
              </Typography>
            </Stack>
          </Stack>
        ) : null
      }
    />
  );
};

export default TrainingCardHeader;
