import TableChartIcon from "@mui/icons-material/TableChart";
import { Box, Paper, Skeleton, Stack } from "@mui/material";
import { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Status } from "../../../../enums";
import { useMeta } from "../../../../services/query";
import { useRun } from "../../../../services/runs";
import { BoardAndRunIds, Cube } from "../../../../types";
import { DestinationsDrawer } from "../../../Circuitboard/DestinationsDrawer";
import { GridType } from "./enums";
import CreateExportMenuList from "./Export/CreateExportMenuList";
import ResultsList from "./Export/ResultsList";
import GridOptionsList, { Option } from "./GridOptionsList";
import InputGrid from "./InputGrid";
import OutputGrid from "./OutputGrid";

const DataSection: FC<BoardAndRunIds> = ({ boardId, runId }) => {
  const { data: run } = useRun(boardId, runId, {
    enabled: !!(boardId && runId),
  });
  const isFinished =
    run?.status === Status.SUCCEEDED || run?.status === Status.FAILED;
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [historyOpen, setHistoryOpen] = useState<boolean>(true);
  const [destinationDrawerOpen, setDestinationDrawerOpen] =
    useState<boolean>(false);

  const setSelectedIO = (option: Option | null, type: GridType) => {
    if (option?.title) {
      queryParams.set("name", option?.title);
      queryParams.set("type", type);
      history.push(`data?${queryParams.toString()}`);
    }
  };

  const { data: meta } = useMeta(boardId, runId, {
    enabled: isFinished,
  });

  // @ts-ignore
  const cubes: Cube[] = meta && meta?.cubes;
  const fileName = queryParams.get("name");
  const fileType = queryParams.get("type");

  const selectedGrid =
    fileName && fileType
      ? {
          option: { title: fileName },
          type: fileType === "output" ? GridType.OUTPUT : GridType.INPUT,
        }
      : run?.status === Status.SUCCEEDED
      ? cubes && cubes.length > 0
        ? {
            option: { title: cubes[0].name },
            type: GridType.OUTPUT,
          }
        : null
      : {
          option: { title: run && run.inputs[0]?.name },
          type: GridType.INPUT,
        };

  const GridDisplay =
    selectedGrid?.type === GridType.INPUT ? InputGrid : OutputGrid;

  return (
    <>
      {run?.status ? (
        <Box display="flex" margin={3} flex={1}>
          <Stack
            spacing={3}
            marginRight={3}
            minWidth={(t) => t.spacing(34)}
            maxWidth={(t) => t.spacing(34)}
          >
            <GridOptionsList
              title="Inputs"
              options={
                run &&
                run.inputs
                  .filter((i) => !!i.value)
                  .map((input) => ({
                    title: input.name,
                    startIcon: <TableChartIcon />,
                  }))
              }
              onClick={(o) => setSelectedIO(o, GridType.INPUT)}
              selectedOption={selectedGrid?.option}
            />
            {run?.status === Status.SUCCEEDED && (
              <>
                <GridOptionsList
                  title="Outputs"
                  options={
                    cubes &&
                    cubes.map((cube) => ({
                      title: cube.name,
                      startIcon: <TableChartIcon />,
                    }))
                  }
                  onClick={(o) => setSelectedIO(o, GridType.OUTPUT)}
                  selectedOption={selectedGrid?.option}
                />

                <CreateExportMenuList
                  onNewSource={() => setDestinationDrawerOpen(true)}
                  onExport={() => {
                    setHistoryOpen(true);
                  }}
                />
                <ResultsList
                  open={historyOpen}
                  onExpand={() => {
                    setHistoryOpen(!historyOpen);
                  }}
                />
              </>
            )}
          </Stack>
          <Box display="flex" flexDirection="column" width="100%" margin={0}>
            <Paper variant="outlined" sx={{ height: "100%" }}>
              {isFinished && (
                <GridDisplay
                  boardId={boardId}
                  runId={runId}
                  selected={selectedGrid?.option.title || ""}
                />
              )}
            </Paper>
          </Box>
        </Box>
      ) : (
        <Skeleton
          variant="rectangular"
          width={270}
          height={100}
          sx={{ display: "flex", margin: 3, mt: 4 }}
        />
      )}
      <DestinationsDrawer
        boardId={boardId}
        open={destinationDrawerOpen}
        onClose={() => {
          setDestinationDrawerOpen(false);
        }}
      />
    </>
  );
};
export default DataSection;
