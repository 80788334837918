import {FC, useEffect, useMemo, useRef} from "react";
import {useRouteMatch} from "react-router";
import _ from "lodash";
import {useHistory, useLocation} from "react-router-dom";
import {useSearchParams} from "../../hooks";
import {InsightTypeToFilters} from "./constants";
import {AdBoosterType} from "./types";
import useQueryParams from "../../hooks/useQueryParams";
import {InsightsQueryParams} from "../InsightsV2/constants";

const generateStringParam = (
    paramsObject: Record<string, string | undefined | null>
): URLSearchParams => {
  const paramsNoUndefined = _.omitBy(paramsObject, _.isUndefined);
  return new URLSearchParams(paramsNoUndefined as Record<string, string>);
};

export const IFrame: FC = (_props) => {
  const { search = {} } = useSearchParams();
  const history = useHistory();
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  const IFrameRef = useRef<any>(null);
  const location =
    useLocation<{ insightType?: AdBoosterType; brand: string }>();
  const { params } =
    useRouteMatch<{ flowId: string }>({
      path: "/ad-booster/:flowId",
    }) || {};
  const { flowId = "" } = params || {};
  const { categories = "", version, productGroupFilterId = "" } = search;

  useEffect(() => {
    const handler = (ev: MessageEvent<string>) => {
      if (ev.data === "ad-booster-back-button-click") {
        history.goBack();
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  const url = useMemo(() => {
    if (flowId) {
      const searchParams = generateStringParam({
        flowId,
        amplitudeKey: String(import.meta.env.VITE_AMPLITUDE_KEY),
        time: String(new Date().getTime()),
        categories: String(categories.toString()) || undefined,
        filters: location.state?.insightType
          ? JSON.stringify(InsightTypeToFilters[location.state?.insightType])
          : undefined,
        brand: location.state?.brand,
        insightId,
        insightType: location.state?.insightType,
        productGroupFilterId: productGroupFilterId?.toString() || undefined,
      });
      return `/apps/ad-booster/${version || "latest"}?${searchParams}`;
    }
    return null;
  }, [flowId, categories, insightId]);

  useEffect(() => {
    if (url && IFrameRef.current) {
      IFrameRef.current.src = url;
    }
  }, [url, IFrameRef]);

  return (
    <iframe ref={IFrameRef} frameBorder={0} height="1500px" title="ad-booster" />
  );
};

export default IFrame;
