import { Editable, RenderLeafProps, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { BaseRange, createEditor, NodeEntry, Range, Text } from "slate";
import { useCallback, useEffect, useState } from "react";
import Leaf from "./Leaf.tsx";
import { usePerfectContentV2 } from "../../PerfectContnetProviderV2.tsx";
import { Box } from "@mui/material";
import SkeletonContent from "./SkeletonContent.tsx";

const Index = () => {
  const [rerender, setRerender] = useState(false);
  const { isLoading, current_string, highlightedKeywords } =
    usePerfectContentV2();
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    [],
  );
  const [editor] = useState(() => withHistory(withReact(createEditor())));

  const initialValue = [
    {
      type: "paragraph",
      children: [{ text: current_string }],
    },
  ];

  const decorate = useCallback(
    (entry: NodeEntry) => {
      const [node, path] = entry;
      const ranges: BaseRange[] = [];
      highlightedKeywords?.forEach((keyword: string) => {
        if (Text.isText(node)) {
          const word = node.text.toLowerCase().match(keyword)?.[0];
          console.warn({ word });
          if (word) {
            const parts = node.text.toLowerCase().split(word);
            let offset = 0;

            parts.forEach((part, i) => {
              if (i !== 0) {
                const range = {
                  isHighlighted: true,
                  anchor: { path, offset: offset - word.length },
                  focus: { path, offset },
                };
                let overlaps = false;
                ranges.forEach((r) => {
                  if (Range.intersection(range, r)) {
                    overlaps = true;
                  }
                });
                if (!overlaps) {
                  ranges.push(range);
                }
              }

              offset = offset + part.length + word.length;
            });
          }
        }
      });

      return ranges;
    },
    [highlightedKeywords],
  );
  useEffect(() => {
    setRerender(true);
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };
    editor.history = { redos: [], undos: [] };
    editor.children = initialValue;
    setTimeout(() => setRerender(false), 300);
  }, [current_string]);

  console.warn(initialValue);

  return (
    <Slate editor={editor} initialValue={initialValue}>
      {isLoading || rerender ? (
        <Box
          sx={{
            borderBottom: "none",
            borderRadius: "0px 8px 0 0",
            overflow: "auto",
            paddingTop: "8px",
            paddingRight: "20px",
            outline: "none",
            whiteSpace: "break-spaces",
            minHeight: "300px",
          }}
        >
          <SkeletonContent />
        </Box>
      ) : (
        <Editable
          decorate={decorate}
          renderLeaf={renderLeaf}
          style={{
            borderBottom: "none",
            borderRadius: "0px 8px 0 0",
            overflow: "auto",
            paddingTop: "8px",
            paddingRight: "20px",
            outline: "none",
            whiteSpace: "break-spaces",
            minHeight: "300px",
          }}
        />
      )}
    </Slate>
  );
};

export default Index;
