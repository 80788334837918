import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { pullAll, union } from "lodash";
import AsinsListTextarea from "./AsinsListTextarea";
import { Separator, validateAsinsList } from "./AddProducts";
import { LoadLocalCsvFile } from "../LoadLocalCsvFile";
import { getAsinsFromCSVFile } from "../csvStreamUtils";
import {
  createAsinFilter,
  updateProductsFilter,
} from "../../../services/ecommerce/api";
import { useAmazonProductContext } from "./AmazonProductProvider";
import {
  ProductFilter,
  ProductsFilterConfiguration,
} from "../../../types/ecommerce";

const AddEditAsinsProductFilter = ({
  filterToUpdate,
  isAsinsModalOpen,
  setIsAsinsModalOpen,
  setFilterToUpdate,
}: {
  filterToUpdate?: ProductFilter;
  isAsinsModalOpen: boolean;
  setIsAsinsModalOpen: Dispatch<SetStateAction<boolean>>;
  setFilterToUpdate: Dispatch<SetStateAction<ProductFilter | undefined>>;
}) => {
  const { setFilter, refetchProductsFilters } = useAmazonProductContext();
  const snackbar = useSnackbar();
  const [fileDroppedLoading, setFileDroppedLoading] = useState<boolean>(false);
  const [asinsList, setAsinsList] = useState<string[]>([]);
  const [asinsTextArea, setAsinsTextArea] = useState("");
  const [filterName, setFilterName] = useState("");
  const [valid, setValid] = useState(false);
  const [operand, setOperand] = useState<
    "create" | "add" | "remove" | "update"
  >("create");
  const [errors, setErrors] = useState<{
    filterName?: string | null | undefined;
    asinsTextArea?: string | null | undefined;
  }>({});

  const handleOpen = () => {
    setIsAsinsModalOpen(true);
    setFilterToUpdate(undefined);
    setOperand("create");
  };
  const handleClose = () => {
    setIsAsinsModalOpen(false);
    setFilterToUpdate(undefined);
  };

  useEffect(() => {
    let valid = true;
    if (filterName?.length === 0) {
      valid = false;
    }
    if (asinsTextArea.length > 0) {
      valid = validateAsinsList(asinsTextArea)?.error === undefined;
    }
    if (asinsTextArea.length > 0 && operand === "update") {
      valid = false;
    }
    setValid(valid);
  }, [asinsList, operand, filterName]);

  const createMutation = useMutation(
    ({ name, asins }: { name: string; asins: string[] }) =>
      createAsinFilter(
        name,
        ProductsFilterConfiguration.defaultConfig({ asins })
      )
  );
  const updateMutation = useMutation((productFilter: ProductFilter) =>
    updateProductsFilter(productFilter)
  );

  useEffect(() => {
    const errors: {
      filterName?: string | null | undefined;
      asinsTextArea?: string | null | undefined;
    } = {};
    if (filterName?.length === 0) {
      errors.filterName = "List name is required!";
    } else {
      errors.filterName = undefined;
    }
    errors.asinsTextArea =
      asinsTextArea.length > 0
        ? validateAsinsList(asinsTextArea)?.error
        : undefined;

    setErrors(errors);
  }, [asinsTextArea, filterName]);

  useEffect(() => {
    setOperand("update");
    setFilterName(filterToUpdate?.name ?? "");
    setAsinsList([]);
    setAsinsTextArea("");
  }, [filterToUpdate]);

  return (
    <>
      <Button
        color="primary"
        size="medium"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        New custom list
      </Button>
      <form>
        <Dialog
          open={isAsinsModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="headline3">
              {filterToUpdate?.id ? "Update" : "New"} custom list
            </Typography>
            <Button
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            <FormControl sx={{ width: "100%", marginBottom: "24px" }}>
              <Typography variant="body4" sx={{ marginBottom: "8px" }}>
                List name
              </Typography>
              <TextField
                defaultValue={filterToUpdate?.name ?? ""}
                variant="filled"
                fullWidth
                error={!!errors?.filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
              />
            </FormControl>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="body4" pb="8px" pt="24px">
                  ASIN List
                </Typography>
              </Box>
              {!!filterToUpdate?.name && (
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  color="primary"
                >
                  <FormControlLabel
                    value="add"
                    sx={{ span: { fontSize: "14px !important" } }}
                    control={
                      <Radio
                        size="small"
                        checked={operand === "add"}
                        onChange={() => setOperand("add")}
                      />
                    }
                    label="Add to list"
                  />
                  <FormControlLabel
                    value="remove"
                    sx={{ span: { fontSize: "14px !important" } }}
                    control={
                      <Radio
                        size="small"
                        checked={operand === "remove"}
                        onChange={() => setOperand("remove")}
                      />
                    }
                    label="Remove from list"
                  />
                </RadioGroup>
              )}
            </Stack>
            <AsinsListTextarea
              errors={errors?.asinsTextArea}
              onChange={(e) => {
                setAsinsTextArea(e.target.value);
                setAsinsList(validateAsinsList(e.target.value).asins);
              }}
            />
            <Separator my={2} />
            <LoadLocalCsvFile
              onFileDropped={(dropped_file) => {
                setFileDroppedLoading(true);
                getAsinsFromCSVFile(dropped_file)
                  .then((data) => {
                    setAsinsTextArea(data.join("\n"));
                    setAsinsList(data);
                  })
                  .catch((e) =>
                    snackbar.enqueueSnackbar(e.message, { variant: "error" })
                  )
                  .finally(() => setFileDroppedLoading(false));
              }}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              pt="8px"
            >
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Stack direction="row" gap="16px" alignItems="center">
                {asinsList.length > 0 && (
                  <Box>
                    {operand === "create" && !filterToUpdate?.id && (
                      <Typography
                        variant="body4"
                        color={(theme) => theme.palette.text.secondary}
                      >
                        {asinsList.length} products in list
                      </Typography>
                    )}
                    {operand === "create" && filterToUpdate?.id && (
                      <Typography
                        variant="body4"
                        color={(theme) => theme.palette.text.secondary}
                      >
                        Choose add or remove
                      </Typography>
                    )}
                    {operand === "add" && (
                      <Typography
                        variant="body4"
                        color={(theme) => theme.palette.text.secondary}
                      >
                        Adding {asinsList.length} products
                      </Typography>
                    )}
                    {operand === "remove" && (
                      <Typography
                        variant="body4"
                        color={(theme) => theme.palette.text.secondary}
                      >
                        Removing {asinsList.length} products
                      </Typography>
                    )}
                  </Box>
                )}
                <Button
                  onClick={async () => {
                    if (operand === "create") {
                      const res = await createMutation.mutateAsync({
                        name: filterName,
                        asins: asinsList,
                      });
                      snackbar.enqueueSnackbar(`${filterName} list created`, {
                        variant: "success",
                      });
                      setFilter(res as unknown as ProductFilter);
                    } else if (filterToUpdate) {
                      const updatedFilter: ProductFilter = filterToUpdate;
                      let modifiedAsins: string[] = [];
                      if (operand === "add") {
                        modifiedAsins = union(
                          filterToUpdate?.configuration?.asins ?? [],
                          asinsList
                        );
                      } else if (operand === "remove") {
                        modifiedAsins = pullAll(
                          filterToUpdate?.configuration?.asins ?? [],
                          asinsList
                        );
                      } else if (operand === "update") {
                        filterToUpdate.name = filterName;
                        modifiedAsins =
                          filterToUpdate?.configuration?.asins ?? [];
                      }
                      // add remove asins
                      const res = await updateMutation.mutateAsync({
                        ...updatedFilter,
                        configuration: {
                          ...updatedFilter?.configuration,
                          asins: modifiedAsins,
                        },
                      });
                      snackbar.enqueueSnackbar(`${filterName} list updated`, {
                        variant: "success",
                      });
                      setFilter(res as unknown as ProductFilter);
                    }
                    handleClose();
                    await refetchProductsFilters();
                  }}
                  variant="contained"
                  disabled={
                    (filterToUpdate?.id && operand === "create") ||
                    !valid ||
                    fileDroppedLoading
                  }
                >
                  {filterToUpdate?.id ? "Update" : "Create"} List
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default AddEditAsinsProductFilter;
