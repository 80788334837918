import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useRun } from "../../../services/runs";
import { updateRun } from "../../../services/runs/api";

export type EditSettingsForm = {
  name: string;
  description?: string;
};

const EditRunName: FC<{
  onClose: () => void;
  runId: string;
  boardId: string;
}> = ({ onClose, runId, boardId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: run } = useRun(boardId, runId);

  const { handleSubmit, register, formState } = useForm<EditSettingsForm>({
    mode: "onChange",
  });
  const { isDirty, isValid, errors } = formState;

  const queryClient = useQueryClient();

  const mutation = useMutation(updateRun, {
    onSuccess: (data) => {
      queryClient.setQueryData(["run", runId], data);
      queryClient.invalidateQueries(["runs", boardId]);
      onClose();
      enqueueSnackbar("Successfully updated the run.", {
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar("Failed to update the run.", {
        variant: "error",
      });
    },
  });

  const onSubmit = handleSubmit((formValues: EditSettingsForm) => {
    mutation.mutate({
      boardId,
      runId,
      name: formValues.name,
      description: run?.description || "",
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", minHeight: (t) => t.spacing(5) }}
      >
        <TextField
          required
          fullWidth
          id="runName"
          size="small"
          defaultValue={run?.name || ""}
          {...register("name", {
            required: true,
          })}
          error={Boolean(errors?.name)}
        />

        <Button sx={{ marginLeft: 2 }} size="small" onClick={onClose}>
          cancel
        </Button>
        <LoadingButton
          variant="contained"
          size="small"
          loading={mutation.isLoading}
          disabled={!isDirty || !isValid}
          sx={{ marginLeft: 2 }}
          onClick={onSubmit}
        >
          Save
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default EditRunName;
