export type DashboardFilterData = {
  name: string;
  value: string | string[];
};

export abstract class DashboardFilter implements DashboardFilterData {
  abstract toQueryParams: () => string;

  static isValid(obj: any): boolean {
    return (
      typeof obj === "object" &&
      obj.name &&
      (Array.isArray(obj.value)
        ? obj.value.every((v: any) => typeof v === "string")
        : typeof obj.value === "string")
    );
  }

  static toQueryParams(filters: DashboardFilter[]): string {
    return filters
      .sort((a, b) => {
        const compareValue = a.name.localeCompare(b.name);
        if (compareValue !== 0) return compareValue;
        const aStringValue = Array.isArray(a.value)
          ? a.value.join("_")
          : a.value;
        const bStringValue = Array.isArray(b.value)
          ? b.value.join("_")
          : b.value;
        return aStringValue.localeCompare(bStringValue);
      })
      .flatMap(({ name, value }) =>
        Array.isArray(value)
          ? value.map((v) => `${name}=${encodeURIComponent(v)}`)
          : [`${name}=${encodeURIComponent(value)}`]
      )
      .join("&");
  }

  abstract name: string;

  abstract value: string | string[];
}

export class DashboardFilterImpl implements DashboardFilter {
  name: string;

  value: string | string[];

  constructor(name: string, value: string | string[]) {
    this.name = name;
    this.value = value;
  }

  toQueryParams(): string {
    const listValue = Array.isArray(this.value) ? this.value : [this.value];
    return listValue.map((value) => `${this.name}=${value}`).join("&");
  }

  static fromObject(obj: any): DashboardFilter {
    if (DashboardFilter.isValid(obj)) {
      const data = obj as DashboardFilterData;
      return new DashboardFilterImpl(data.name, data.value);
    }
    throw new Error("Can't create DashboardFilterImpl, value not valid");
  }

  static fromQueryString(qs: string): DashboardFilter[] {
    const urlParams = new URLSearchParams(qs);
    return Array.from(new Set(urlParams.keys())).map(
      (key) => new DashboardFilterImpl(key, urlParams.getAll(key))
    );
  }
}

export type DashboardUpdateRequestData = {
  filters: DashboardFilterData[];
};
export class DashboardUpdateRequest implements DashboardUpdateRequestData {
  filters: DashboardFilterData[];

  constructor(filters: DashboardFilterData[]) {
    this.filters = filters;
  }
}

export type DashboardFiltersData = {
  id: string;
  dashboardId: number;
  name: string;
  filters: DashboardFilter[];
};

export abstract class DashboardFilters implements DashboardFiltersData {
  static DEFAULT_ID: string = "Default";

  static NEW_FILTER_ID: string = "newFilter";

  abstract dashboardId: number;

  abstract filters: DashboardFilter[];

  abstract id: string;

  abstract name: string;

  public isDefaultFilter(): boolean {
    return this.id === DashboardFilters.DEFAULT_ID;
  }

  public isNewFilter(): boolean {
    return this.id === DashboardFilters.NEW_FILTER_ID;
  }

  public isEqual(other: DashboardFilters | null): boolean {
    if (!other) return false;
    if (this.filters.length !== other.filters.length) {
      return false;
    }
    this.filters.sort((a, b) => a.name.localeCompare(b.name));
    other.filters.sort((a, b) => a.name.localeCompare(b.name));
    return (
      JSON.stringify(this.filters) === JSON.stringify(other.filters) ||
      this.filters.every((f) => {
        const filterValue = f.value;
        const otherFilterValue = other.filters.find((of) => of.name === f.name)
          ?.value;
        const otherValAsArr = Array.isArray(otherFilterValue)
          ? otherFilterValue
          : [otherFilterValue];
        const filterValAsArr = Array.isArray(filterValue)
          ? filterValue
          : [filterValue];
        return otherValAsArr.every((ov) =>
          filterValAsArr.find((v) => ov === v)
        );
      })
    );
  }

  public toQueryParams(): string {
    return DashboardFilter.toQueryParams(this.filters);
  }
}

export class DashboardFiltersImp extends DashboardFilters {
  dashboardId: number;

  filters: DashboardFilter[];

  id: string;

  name: string;

  constructor(
    dashboard_id: number,
    filters: DashboardFilter[],
    id: string,
    name: string
  ) {
    super();
    this.dashboardId = dashboard_id;
    this.filters = filters;
    this.id = id;
    this.name = name;
  }

  static fromData(d: DashboardFiltersData): DashboardFiltersImp {
    return new DashboardFiltersImp(d.dashboardId, d.filters, d.id, d.name);
  }

  static createNewFilter(
    d: Omit<DashboardFiltersData, "id">
  ): DashboardFiltersImp {
    return new DashboardFiltersImp(
      d.dashboardId,
      d.filters,
      "",
      d.name || "New Filter"
    );
  }

  static updateFilters(
    filters: DashboardFilter[],
    d: DashboardFilters
  ): DashboardFiltersImp {
    return new DashboardFiltersImp(d.dashboardId, filters, d.id, d.name);
  }

  static fromDashboard(dashboard: Dashboard): DashboardFiltersImp {
    return DashboardFiltersImp.fromData({
      id: DashboardFilters.DEFAULT_ID,
      name: DashboardFilters.DEFAULT_ID,
      dashboardId: dashboard.id,
      filters: dashboard.filters,
    });
  }
}

export type DashboardData = {
  id: number;
  name: string;
  tab?: string;
  description?: string;
  url: string;
  custom: boolean;
  filters: DashboardFilter[];
};

export interface Dashboard extends DashboardData {
  getFilterAsQueryParams: () => string;
  isCustom: () => boolean;
  isEqual: (other: Dashboard) => boolean;
  getFilterByName: (filterName: string) => DashboardFilterData | null;
}

export class DashboardImp implements Dashboard {
  description: string;

  filters: DashboardFilter[];

  id: number;

  name: string;

  tab: string;

  url: string;

  custom: boolean;

  constructor(
    id: number,
    name: string,
    tab: string = "",
    description: string = "",
    url: string,
    filters: DashboardFilterData[] = [],
    custom: boolean
  ) {
    this.description = description;
    this.filters = filters.map(DashboardFilterImpl.fromObject);
    this.id = id;
    this.name = name;
    this.tab = tab;
    this.url = url;
    this.custom = custom;
  }

  toDashboardFilters(): DashboardFilters {
    return DashboardFiltersImp.fromData({
      dashboardId: this.id,
      filters: this.filters,
      name: "Default",
      id: "Default",
    });
  }

  getFilterByName(filterName: string): DashboardFilterData | null {
    return this.filters.find((f) => f.name === filterName) || null;
  }

  isEqual(other: Dashboard): boolean {
    return this.getFilterAsQueryParams() === other.getFilterAsQueryParams();
  }

  static fromData(d: DashboardData): DashboardImp {
    return new DashboardImp(
      d.id,
      d.name,
      d.tab,
      d.description,
      d.url,
      d.filters || [],
      d.custom
    );
  }

  getFilterAsQueryParams(): string {
    return DashboardFilter.toQueryParams(this.filters);
  }

  isCustom(): boolean {
    return this.custom;
  }
}
