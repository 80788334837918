import { Box, CardHeader, CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DescriptionIcon from "@mui/icons-material/Description";
import dayjs, { formatFileSize } from "../../../../utils";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  uploadingTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  fileTitle: {
    display: "flex",
  },
}));

type UploadingBoxProps = {
  file: File;
};

const UploadingBox: FC<UploadingBoxProps> = ({ file }) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="subtitle2" className={classes.uploadingTitle}>
          Uploading
        </Typography>
        <CardHeader
          title={file.name}
          subheader={`${dayjs()} • ${formatFileSize(file.size)}`}
          avatar={<DescriptionIcon fontSize="large" color="action" />}
          classes={{ title: classes.fileTitle }}
        />
      </Box>
      <Box display="flex" alignItems="center" marginRight={8}>
        <CircularProgress size={40} thickness={4} />
      </Box>
    </Box>
  );
};

export default UploadingBox;
