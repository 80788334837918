import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { EMPTY_FILTERS } from "./constants";
import {
  SalesAssistantRead,
  Sort,
  State,
  StatsPeriods,
  TransformedInsight,
} from "./types";
import {
  useInsightMetadata,
  useProductSalesFilters,
  useProductSalesGroups,
  useSalesAssistant,
} from "./useSalesAssistant";
// import { ProductsFilter } from "../../types/ecommerce";
import { useLocalSalesDrawer } from "../../hooks/useLocalInsightV2";
import { useTenantsState } from "@frontegg/react-hooks/auth";

const transformRawInsights = (
  salesAssistantData?: SalesAssistantRead[]
): TransformedInsight[] =>
  (salesAssistantData || []).map((item) => ({
    ...item,
    isChecked: false,
  }));

export const salesAssistantContext = createContext<State>({} as State);

export const SalesProvider = ({ children }: { children: ReactNode }) => {
  const productGroupFilterId = localStorage.getItem("productGroupId");
  const brandFilterName = localStorage.getItem("brandFilter");
  const categoryFilterName = localStorage.getItem("categoryFilter");
  const sortFilterName = localStorage.getItem("sortFilter");
  const sortDaysName = localStorage.getItem("sortDays");
  const [selectedProductGroup, setSelectedProductGroup] = useState<any>(null);
  const [statsPeriod, setStatsPeriod] = useState<StatsPeriods>("7 days");
  const [filterOut, setFilterOut] = useState(EMPTY_FILTERS);
  const [selectedInsights, setSelectedInsights] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [allProductTab, setAllProductTab] = useState<boolean>(false);
  const [sort, setSort] = useState<Sort>({
    column: "change",
    ascending: true,
    isDirty: false,
    days: "7d",
  });
  const [allSorts, setAllSorts] = useState<any>([]);
  const { pathname } = useLocation();
  const [isDrawerEnabled, enableDrawer] = useState(true);
  const [brandFilter, setBrandFilter] = useState<any>("");
  const [categoryFilter, setCategoryFilter] = useState<any>("");
  const [selectedValue, setValue] = useState("change");
  const [sortDays, setSortDays] = useState("7d");
  const [categorySearchValue, setCategorySearchValue] = useState<string>("");
  const [brandSearchValue, setBrandSearchValue] = useState<string>("");
  const { activeTenant } = useTenantsState();
  const [groupSearch, setProupSearch] = useState("");
  const [activeTab, setActiveTab] = useState<string>("groups");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 100;
  const offset = limit * (currentPage - 1);

  //const [producGroupData, setProducGroupData] = useState<any>("");

  const activeTabFromLocal = localStorage.getItem("activeTabPanel") || "";
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const {
    data: salesAssistantData,
    isLoading: isLoadingInsights,
    isFetching: isFetchingInsights,
    refetch: refetchInsights,
  } = useSalesAssistant({
    filterId:
      selectedProductGroup?.title === "All products"
        ? ""
        : selectedProductGroup?.filter_id,
    sort,
    categoryFilter,
    brandFilter,
    allProductTab,
    limit,
    offset,
    search: groupSearch,
  });

  const {
    data: SalesMetaData,
    refetch: refetchSalesMetadata,
    isLoading: metadataLoading,
  } = useInsightMetadata({
    filterId:
      selectedProductGroup?.title === "All products"
        ? ""
        : selectedProductGroup?.filter_id,
    categoryFilter,
    brandFilter,
  });

  const { data: salesFilterData } = useProductSalesFilters();
  const { refetch: refetchSalesDrawer } = useLocalSalesDrawer();
  const {
    data: productGroupsList,
    isFetching: isFetchingProductGroup,
    refetch: refetchProdcutGroup,
  } = useProductSalesGroups(sort, groupSearch, activeTab);

  const transformedInsights = useMemo(
    () => transformRawInsights(salesAssistantData),
    [salesAssistantData]
  );

  useEffect(() => {
    if (activeTabFromLocal) {
      setActiveTab(activeTabFromLocal);
      setAllProductTab(activeTabFromLocal === "all-products");
    }
  }, []);

  useEffect(() => {
    if (pathname.includes("products") || allProductTab) {
      refetchInsights();
    }
  }, [
    selectedProductGroup?.id,
    pathname,
    sort?.column,
    sort?.ascending,
    categoryFilter,
    brandFilter,
    groupSearch,
  ]);

  useEffect(() => {
    refetchSalesMetadata();
  }, [
    selectedProductGroup?.id,
    pathname,
    sort?.column,
    sort?.ascending,
    categoryFilter,
    brandFilter,
  ]);

  useEffect(() => {
    if (pathname === "/sales-assistant/home" && !allProductTab) {
      refetchProdcutGroup();
    }
  }, [sort]);

  const searchValue = localStorage.getItem("groupSearch");
  useEffect(() => {
    if (searchValue) {
      setProupSearch(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (productGroupFilterId) {
      try {
        const productIdData = JSON.parse(productGroupFilterId);
        if (productIdData?.tenentID === activeTenant?.id) {
          setSelectedProductGroup(productIdData?.data);
        }
      } catch (e) {
        localStorage.removeItem("productGroupId");
      }
    }
    if (brandFilterName) {
      try {
        const brandFilterData = JSON.parse(brandFilterName);
        if (brandFilterData?.tenentID === activeTenant?.id) {
          setBrandFilter(brandFilterData?.value);
        }
      } catch (e) {
        localStorage.removeItem("brandFilter");
      }
    }
    if (categoryFilterName) {
      try {
        const categoryFilterData = JSON.parse(categoryFilterName);
        if (categoryFilterData?.tenentID === activeTenant?.id) {
          setCategoryFilter(categoryFilterData?.value);
        }
      } catch (e) {
        localStorage.removeItem("categoryFilter");
      }
    }
    if (sortFilterName) {
      try {
        const sortData = JSON.parse(sortFilterName);
        if (sortData?.tenentID === activeTenant?.id) {
          setSort(sortData?.data);
          setValue(sortData?.data?.column);
        }
      } catch (e) {
        localStorage.removeItem("sortFilter");
      }
    }
    if (sortDaysName) {
      try {
        const sortDaysData = JSON.parse(sortDaysName);
        if (sortDaysData?.tenentID === activeTenant?.id) {
          setSortDays(sortDaysData?.value);
        }
      } catch (e) {
        localStorage.removeItem("sortDays");
      }
    }
  }, [
    productGroupFilterId,
    brandFilterName,
    categoryFilterName,
    sortFilterName,
    sortDaysName,
    activeTenant?.id,
  ]);

  const state: State = {
    sort,
    enableDrawer,
    isDrawerEnabled,
    setSort,
    filterOut,
    search,
    setSearch,
    setFilterOut,
    setSelectedInsights,
    salesAssistantData: transformedInsights,
    isLoadingInsights,
    isFetchingInsights,
    selectedInsights,
    SalesMetaData,
    refetchInsights,
    refetchSalesMetadata,
    metadataLoading,
    selectedProductGroup,
    setSelectedProductGroup,
    statsPeriod,
    setStatsPeriod,
    filterData: salesFilterData || null,
    setBrandFilter,
    brandFilter,
    categoryFilter,
    setCategoryFilter,
    refetchSalesDrawer,
    setValue,
    selectedValue,
    sortDays,
    setSortDays,
    categorySearchValue,
    setCategorySearchValue,
    brandSearchValue,
    setBrandSearchValue,
    setAllSorts,
    allSorts,
    producGroupData: productGroupsList,
    isFetchingProductGroup,
    groupSearch,
    setProupSearch,
    allProductTab,
    setAllProductTab,
    activeTab,
    setActiveTab,
    currentPage,
    setCurrentPage,
    limit,
    handleChange,
  };
  return (
    <salesAssistantContext.Provider value={{ ...state }}>
      {children}
    </salesAssistantContext.Provider>
  );
};
