import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useMutation} from "react-query";
import {Button} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {deleteFlow} from "../../services/Flows/api";
import {useConfirmation} from "../../components/ConfirmationDialog";
import {FC} from "react"

export const DeleteFlowButton: FC<{ flowId: string }> = ({flowId}) => {
  const history = useHistory();

  const {enqueueSnackbar} = useSnackbar();
  const deleteMutation = useMutation((flowId: string) => deleteFlow(flowId));

  const confirm = useConfirmation();
  const onConfirmDelete = async () => {
    try {
      await deleteMutation
        .mutateAsync(flowId)
        .then(() => history.push("/flows"));
      enqueueSnackbar("Successfully deleted flow", {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar("Unable to delete flow", { variant: "error" });
    }
  };
  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this flow?",
      description:
        "This will delete all blocks and resources and cannot be undone",
      action: "Delete",
    })
      .then(onConfirmDelete)
      .catch(() =>
        enqueueSnackbar("Failed to delete flow", { variant: "error" })
      );
  };
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleDelete}
      startIcon={<Delete />}
      disabled={deleteMutation.isLoading}
    >
      Delete
    </Button>
  );
};
