import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import SVG from "react-inlinesvg";
import {BlockIcon} from "../../components/BlockIcon";
import {Board} from "../Circuitboards/BoardsTable/BoardsTable";
import {visibilityFilters} from "../Circuitboards/BoardFilters/VisibilityFilters";
import {FC} from "react"

type BlockListItemPorps = {
  block: Board;
  listItemProps?: ListItemProps | object;
};

export const BlockListItem: FC<BlockListItemPorps> = ({
                                                        block,
                                                        listItemProps = {},
                                                      }) => {
  const theme = useTheme();
  const fontColor = "grey.500";
  const spacerColor = "grey.300";
  const isFlowBlock = !!block.flow;
  let IconComp = visibilityFilters[1].icon;
  if (!isFlowBlock) {
    IconComp = (
      visibilityFilters.find((v) => v.name === block.visibility) ||
      visibilityFilters[1]
    ).icon;
  }
  return (
    <ListItem disablePadding {...listItemProps}>
      <ListItemButton>
        <ListItemIcon>
          <BlockIcon
            isLatest={block.isLatest || false}
            image={block.image || ""}
          />
        </ListItemIcon>
        <ListItemText
          primary={block.displayName}
          secondary={
            <Stack sx={{ display: "flex", alignItems: "start" }} spacing={0.5}>
              {!isFlowBlock && (
                <IconComp
                  sx={{
                    width: theme.spacing(15 / 8),
                    height: theme.spacing(15 / 8),
                    color: fontColor,
                  }}
                />
              )}
              {block.bigquery_warehouse_export_enabled && !isFlowBlock && (
                <>
                  <Typography variant="body2" sx={{ color: spacerColor }}>
                    •
                  </Typography>
                  <Box>
                    <SVG
                      fill={theme.palette.grey[500]}
                      src={`/dwh-icon.svg`}
                      height={theme.spacing(11 / 8)}
                      width={theme.spacing(11 / 8)}
                    />
                  </Box>

                  <Typography variant="body2" sx={{ color: spacerColor }}>
                    •
                  </Typography>
                </>
              )}
              <Typography variant="body2" sx={{ color: fontColor }}>
                {block.templateName}
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
