import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";

import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useConfirmation } from "../../../components/ConfirmationDialog";
import Loadable from "../../../components/Loadable";
import { APIError } from "../../../services/apiRequest";
import {
  createBoardEmailSubscription,
  getBoardEmailSubscriptions,
  unsubscribeEmail,
} from "../../../services/circuitboards";
import {
  BoardEmailSubscriptionCreate,
  BoardEmailSubscriptionRead,
} from "../../../types";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    listItem: {
      width: "100%",
    },
    addEmail: {
      margin: "auto",
    },
    card: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      maxWidth: "100%",
      height: "100%",
      boxSizing: "border-box",
    },
    emailTextField: {},
    emailButtonBox: {
      justifyContent: "flex-end",
    },
    emailButton: {
      marginBottom: theme.spacing(2),
    },
  })
);

type NotificationTabProps = {
  circuitboardId: string;
};

const NotificationTab: FC<NotificationTabProps> = ({ circuitboardId }) => {
  const classes = useStyles();
  const confirm = useConfirmation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const validRegex = /^\S+@\S+\.\S+$/;

  const { register, handleSubmit, formState, reset } =
    useForm<BoardEmailSubscriptionCreate>({
      mode: "onChange",
    });

  const {
    data: boardSubscribers,
    error,
    isLoading,
  } = useQuery<BoardEmailSubscriptionRead[], APIError>(
    ["boardEmailSubscriptions", circuitboardId],
    () => getBoardEmailSubscriptions(circuitboardId),
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const unsubscribeMutation = useMutation(
    ({ circuitboardId, id }: { circuitboardId: string; id: string }) =>
      unsubscribeEmail(circuitboardId, id),
    {
      onSuccess: (deletedSubscription) => {
        if (boardSubscribers) {
          queryClient.setQueryData(
            ["boardEmailSubscriptions", circuitboardId],
            boardSubscribers.filter(
              (subscriber) => subscriber.email !== deletedSubscription.email
            )
          );
        }
      },

      onError: (error) => {
        enqueueSnackbar(`Failed to unsubscribe email: ${error}`, {
          variant: "error",
        });
      },
    }
  );

  const subscribeMutation = useMutation(
    "subscribeEmailToBoard",
    ({
      circuitboardId,
      data,
    }: {
      circuitboardId: string;
      data: BoardEmailSubscriptionCreate;
    }) => createBoardEmailSubscription(circuitboardId, data),
    {
      onSuccess: (subscription) => {
        queryClient.setQueryData(
          ["boardEmailSubscriptions", circuitboardId],
          (boardSubscribers: BoardEmailSubscriptionRead[] | undefined) => {
            const boardSubscribersCopy = boardSubscribers
              ? [...boardSubscribers]
              : [];

            boardSubscribersCopy.push(subscription);
            return boardSubscribersCopy;
          }
        );

        enqueueSnackbar(
          `Email ${subscription.email} is now subscribed to the block's updates`,
          { variant: "success" }
        );
      },
      onError: (error) => {
        enqueueSnackbar(`Failed to subscribe email: ${error}`, {
          variant: "error",
        });
      },
    }
  );

  const handleRemoveConfirm = (
    emailSubscription: BoardEmailSubscriptionRead
  ) => {
    confirm({
      title: "You are about to unsubscribe an email from the block's updates",
      description: `Email ${emailSubscription.email} will not get updates for this block anymore`,
    }).then(() => {
      unsubscribeMutation.mutate({
        circuitboardId: emailSubscription.circuitboard_id,
        id: emailSubscription.id,
      });
    });
  };

  const onSubmit = handleSubmit((data: BoardEmailSubscriptionCreate) => {
    subscribeMutation.mutate({
      circuitboardId,
      data,
    });
    reset({ email: "" });
  });

  return (
    <>
      <Typography variant="h6">Email Subscription</Typography>
      <Typography variant="caption">
        Subscribe/Unsubscribe emails for updates regarding this block&apos;s
        runs
      </Typography>
      <Paper variant="outlined" className={classes.card}>
        <Loadable isLoading={isLoading} error={error}>
          <List dense>
            {boardSubscribers &&
              boardSubscribers.map((email) => (
                <ListItem key={email.id} className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar>
                      <MailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={email.email} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveConfirm(email)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}

            {(!boardSubscribers || boardSubscribers.length === 0) && (
              <Box p={2}>No email are subscribed yet.</Box>
            )}
          </List>
        </Loadable>
      </Paper>

      <form onSubmit={onSubmit}>
        <Box>
          <TextField
            className={classes.emailTextField}
            defaultValue=""
            label="Email"
            placeholder="Enter an email"
            InputLabelProps={{ required: true }}
            variant="outlined"
            {...register("email", {
              required: true,
              minLength: 3,
              pattern: validRegex,
            })}
            margin="dense"
            error={formState.isDirty && !formState.isValid}
            helperText={
              formState.isDirty && (formState.isValid ? "" : "Invalid email")
            }
            fullWidth
            required
          />
          <Box className={classes.emailButtonBox} display="flex">
            <Button
              className={classes.emailButton}
              type="submit"
              variant="text"
              color="primary"
              onClick={onSubmit}
              disabled={!formState.isValid || formState.isSubmitting}
            >
              Add
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default NotificationTab;
