import { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { LoadLocalCsvFile } from "../LoadLocalCsvFile";
import { getProductGroupsFromCSVFile } from "../csvStreamUtils";
import {
  AddProductResponse,
  ProductFilter,
  ProductsFilterConfiguration,
} from "../../../types/ecommerce";
import { useMutation } from "react-query";
import { createAsinFilter } from "../../../services/ecommerce/api.ts";

const ImportBulkProductFilters = ({
  setFilterToUpdate,
}: {
  setFilterToUpdate: Dispatch<SetStateAction<ProductFilter | undefined>>;
}) => {
  const [isAsinsModalOpen, setIsAsinsModalOpen] = useState(false);
  const snackbar = useSnackbar();

  const handleOpen = () => {
    setIsAsinsModalOpen(true);
    setFilterToUpdate(undefined);
  };
  const handleClose = () => {
    setIsAsinsModalOpen(false);
    setFilterToUpdate(undefined);
  };

  const createMutation = useMutation(
    ({ name, asins }: { name: string; asins: string[] }) =>
      createAsinFilter(
        name,
        ProductsFilterConfiguration.defaultConfig({ asins })
      )
  );

  return (
    <>
      <Button
        color="primary"
        size="medium"
        variant="contained"
        onClick={handleOpen}
      >
        Import Product Filters
      </Button>
      <form>
        <Dialog
          open={isAsinsModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="headline3">Import Product Filters</Typography>
            <Button
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            <Typography>
              The csv uploaded should be [product_filter_name,ASIN] in each row
            </Typography>
            <br />
            <LoadLocalCsvFile
              onFileDropped={(dropped_file) => {
                getProductGroupsFromCSVFile(dropped_file)
                  .then((data) => {
                    const promises: Promise<AddProductResponse>[] = [];
                    Object.keys(data).forEach((productGroupName) => {
                      promises.push(
                        createMutation.mutateAsync({
                          name: productGroupName,
                          asins: data[productGroupName],
                        })
                      );
                    });
                    Promise.all(promises).then(() => {
                      snackbar.enqueueSnackbar(`Product groups created`, {
                        variant: "success",
                      });
                      handleClose();
                    });
                  })
                  .catch((e) =>
                    snackbar.enqueueSnackbar(e.message, { variant: "error" })
                  );
              }}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              pt="8px"
            >
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default ImportBulkProductFilters;
