import "ag-grid-enterprise";
import { FC, useMemo } from "react";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { AgGridReact } from "@ag-grid-community/react";
import {
  Alert,
  AlertTitle,
  // Chip,
  GlobalStyles,
  LinearProgress,
} from "@mui/material";
import {
  ColDef,
  GridReadyEvent,
  ModuleRegistry,
} from "@ag-grid-community/core";
// import "@ag-grid-community/core/dist/styles/ag-grid.css";
// import "@ag-grid-community/core/dist/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import ErrorMessage from "./ErrorMessage";
import NoRowsOverlay from "./NoRowsOverlay";

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  InfiniteRowModelModule,
  ClipboardModule,
]);

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      color: theme.palette.common.black,
      fontSize: 14,
      display: "inline-flex",
      alignItems: "center",
      position: "absolute",
      height: "85%",
      overflow: "hidden",
    },
    cell: {
      display: "flex",
      alignItems: "center",
    },
  })
);

// function NoRowsOverlay(props: any) {
//   const msg = props.noRowsMessageFunc();
//   return <Chip variant="outlined" label={msg} color="primary" />;
// }

function ErrorHandler(errorMessage: string) {
  return errorMessage ? (
    <Alert severity="info">
      <AlertTitle>We were not able to complete your request</AlertTitle>
      {errorMessage}
    </Alert>
  ) : (
    <ErrorMessage message={errorMessage} />
  );
}

const DataGrid: FC<{
  onGridReady: (event: GridReadyEvent) => void;
  columns: ColDef[] | null;
  isLoading?: boolean;
  errorMessage?: string | null;
  height?: string;
}> = ({ onGridReady, columns, isLoading, errorMessage, height = "100%" }) => {
  const classes = useStyles();
  const theme = useTheme();
  // const agColumns = columns?.map((column) => (
  //   <AgGridColumn key={column?.headerName || `${column?.field}`} {...column} />
  // ));
  
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => "No rows to display.",
    };
  }, []);

  return errorMessage ? (
    ErrorHandler(errorMessage)
  ) : (
    <div className="ag-theme-quartz" style={{ width: "100%", height }}>
      <LinearProgress sx={isLoading ? undefined : { visibility: "hidden" }} />
      <GlobalStyles
        styles={{
          ".ag-header-cell-resize:after": {
            content: '""',
            borderLeft: `${theme.spacing(0.25)} solid ${
              theme.palette.grey[400]
            }`,
            height: "70%",
            right: theme.spacing(1),
            top: theme.spacing(0.5),
            position: "absolute",
          },
        }}
      />
      <AgGridReact
        rowBuffer={0}
        rowHeight={25}
        headerHeight={25}
        rowModelType="infinite"
        infiniteInitialRowCount={0}
        cacheOverflowSize={1}
        maxConcurrentDatasourceRequests={1}
        maxBlocksInCache={10}
        onGridReady={onGridReady}
        enableRangeSelection
        defaultColDef={{
          resizable: true,
          headerClass: classes.header,
          cellClass: classes.cell,
        }}
        // frameworkComponents={{
        //   customNoRowsOverlay: NoRowsOverlay,
        // }}
        noRowsOverlayComponent={NoRowsOverlay}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        suppressFieldDotNotation
        gridOptions={{
          suppressMenuHide: true,
          onPaginationChanged: (params) => {
            params.api.hideOverlay();
          },
        }}
        getRowStyle={(params: any) => ({
          background:
            params.node.rowIndex % 2 === 0
              ? theme.palette.common.white
              : theme.palette.grey[100],
        })}
        columnDefs={columns || []}
      />
      {/* {agColumns}
      </AgGridReact> */}
    </div>
  );
};

export default DataGrid;
