import {ListItemIcon, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";
import {AuthorizedContent} from "../../../components/AuthorizedContent";
import {PlatformPermission} from "../../../enums";
import {RunMenuItemProps} from "./types";
import {FC} from "react"

const ViewRunMenuItem: FC<RunMenuItemProps> = ({boardId, runId, onClick}) => (
    // @ts-ignore
    <AuthorizedContent
        requiredPermissions={[PlatformPermission.RUNS_GET]}
        render={(isAuthz) => (
            <MenuItem
                disabled={!isAuthz}
                onClick={onClick}
                component={Link}
                to={`/boards/${boardId}/runs/${runId}`}
            >
                <ListItemIcon>
                    <AppsIcon/>
                </ListItemIcon>
                View
            </MenuItem>
        )}
    />
);

export default ViewRunMenuItem;
