import {Box, Typography, useTheme} from "@mui/material";
import {JSONSchema4TypeName} from "json-schema";
import {FC} from "react"

type TypeValues = {
  backgroundColor: string;
  text: string;
};

type SchemaTypeIconProps = {
  type: JSONSchema4TypeName;
};

const SchemaTypeIcon: FC<SchemaTypeIconProps> = ({ type }) => {
  const theme = useTheme();
  const typeValues: Record<string, TypeValues> = {
    string: { backgroundColor: theme.palette.info.light, text: "T" },
    number: { backgroundColor: theme.palette.success.light, text: "#" },
    integer: { backgroundColor: theme.palette.success.light, text: "#" },
    boolean: { backgroundColor: theme.palette.warning.light, text: "?" },
  };
  const unknown = { backgroundColor: theme.palette.grey[500], text: "" };
  const { backgroundColor, text } = typeValues[type] || unknown;
  return (
    <Box
      justifyContent="center"
      textAlign="center"
      display="flex"
      color={theme.palette.background.paper}
      height={18}
      width={18}
      borderRadius="5px"
      bgcolor={backgroundColor}
    >
      <Typography variant="subtitle2" color="inherit">
        {text}
      </Typography>
    </Box>
  );
};

export default SchemaTypeIcon;
