import { Box, MenuItem, MenuList, Skeleton, Typography } from "@mui/material";

import { prettifyString } from "../../utils";
import { BoardLibrary, BoardLibraryDetails } from "../../enums";
import { FC, ReactNode } from "react";

export type LibraryMenuProps = {
  defaultValue?: BoardLibrary;
  onChange: (library: BoardLibrary) => void;
  libraries: BoardLibrary[];
  isLoading?: boolean;
  headerAs?: ReactNode;
};

const LibraryMenu: FC<LibraryMenuProps> = ({
  defaultValue = {},
  onChange,
  libraries,
  isLoading,
  headerAs,
}) => (
  <Box sx={{ minWidth: 100 }}>
    {headerAs || <Typography variant="subtitle2">Library</Typography>}
    <MenuList>
      {isLoading
        ? [1, 2, 3].map((i) => <Skeleton height={34} key={i} />)
        : libraries.sort().map((library) => (
            <MenuItem
              key={library}
              selected={library === defaultValue}
              sx={{
                whiteSpace: "break-spaces",
                borderRadius: 1,
              }}
              onClick={() => onChange(library)}
            >
              {BoardLibraryDetails[library]?.name || prettifyString(library)}
            </MenuItem>
          ))}
    </MenuList>
  </Box>
);

export default LibraryMenu;
