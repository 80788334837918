import {Button, DialogActions, DialogContent, DialogTitle, Stack, Typography,} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {FC} from "react"

export const ErrorDialogContent: FC<{
  error: string;
  onClose?: () => void;
}> = ({ error, onClose }) => (
  <>
    <DialogTitle>
      <Stack direction="row" spacing={1} alignItems="center">
        <ErrorOutlineIcon color="error" />
        <span>Failed to create data source</span>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1" textAlign="center">
        {error}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </>
);
