import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SpaceState } from "./types.ts";
import {
  useAllSpaceAsinsQuery,
  useSpaceASINsQuery,
  useSpaceQuery,
  useSpacesQuery,
} from "./useSpaces.ts";
import { useLocation, useRouteMatch } from "react-router-dom";

import { SPACES_ROUTE } from "../../pages/Applications/Router";
import { SortModelItem } from "ag-grid-community";

export const spaceContext = createContext<SpaceState>({} as SpaceState);

export const useSpace = () => useContext(spaceContext);

export const SpaceProvider = ({ children }: { children: React.ReactNode }) => {
  const match = useRouteMatch(`${SPACES_ROUTE}/:spaceId`);
  const location = useLocation();
  let spaceId: string = "";
  if (match) {
    spaceId = (match.params as { spaceId?: string }).spaceId as string;
  }

  const [openKeywordsTable, setOpenKeywordsTable] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [contains, setContains] = useState(true);
  const [isSuggestedTab, setIsSuggestedTab] = useState<boolean>(false);
  const [filterByLabel, setFilterByLabel] = useState("all_product");
  const [filterTextAfterDebounce, setFilterTextAfterDebounce] = useState("");
  const [view, setView] = useState<string>("grid");
  const [isArrayEmpty, setIsArrayEmpty] = useState(false);
  const {
    data: space,
    refetch: refetchSpace,
    isFetching: isSpaceDataFetching,
    isLoading: isSpaceDataLoading,
  } = useSpaceQuery(spaceId);
  const [sort, setSort] = useState<SortModelItem>({
    colId: "bought_past_month",
    sort: "desc",
  });

  const [sortKeywordsBy, setSortKeywordsBy] = useState({
    sort: "asc",
    colId: "keyword",
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const {
    data: spacesList,
    isLoading: spacesListLoading,
    isFetching: spacesListFetching,
    error: spacesListError,
    refetch: spacesListRefetch,
  } = useSpacesQuery();

  const { data: allSpaceAsins, refetch: refetchSpaceAsins } =
    useAllSpaceAsinsQuery({
      spaceId,
      filterByLabel,
      filterByText: filterTextAfterDebounce,
      filterByContains: contains,
    });

  const spaceDataRefetch = () => {
    refetchSpace();
    refetchSpaceAsins();
  };

  useEffect(() => {
    if (filterByLabel === "suggested_asins") {
      setIsSuggestedTab(true);
    } else {
      setIsSuggestedTab(false);
    }
  }, [filterByLabel]);

  useEffect(() => {
    setSelectedProducts([]);
  }, [isSuggestedTab]);

  useEffect(() => {
    if (location?.pathname) {
      setOpenKeywordsTable(false);
    }
  }, [location?.pathname]);

  const {
    data: asinsList,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingAsins,
    isFetchingNextPage,
    refetch: refatchAsins,
  } = useSpaceASINsQuery({
    spaceId,
    filterByLabel,
    ...(view === "grid" && {
      filterByText: filterTextAfterDebounce,
    }),
    ...(filterTextAfterDebounce &&
      view === "grid" && {
        filterByContains: contains,
      }),
    ...(sort.colId && {
      sortBy: `${sort.sort == "asc" ? "" : "-"}${sort.colId}`,
    }),
  });
  useEffect(() => {
    const isEmpty = asinsList?.pages?.some(
      (page: any) => page?.asins?.length === 0
    );
    setIsArrayEmpty(isEmpty || false);
  }, [asinsList]);

  // useEffect(() => {
  //   if (filterTextAfterDebounce) {
  //     spaceDataRefetch();
  //   }
  // }, [filterTextAfterDebounce, contains]);

  const toggleProductSelection = useCallback(
    (asin: string, action?: string) => {
      if (action === "toggle") {
        setSelectedProducts((prevSelectedProducts) =>
          prevSelectedProducts.includes(asin)
            ? prevSelectedProducts.filter((el) => el !== asin)
            : [...prevSelectedProducts, asin]
        );
      } else {
        setSelectedProducts((prevSelectedProducts) =>
          prevSelectedProducts.includes(asin)
            ? prevSelectedProducts.filter((el) => el !== asin)
            : [...prevSelectedProducts]
        );
      }
    },
    []
  );

  // const handleUndoAsins = (snackbarId: SnackbarKey, asins: string[]) => {
  //   addAsinsMutation
  //     .mutateAsync({ spaceId: spaceId as string, asins })
  //     .then(async () => {
  //       snackbar.enqueueSnackbar(
  //         asins?.length === 1
  //           ? "ASIN was added successfully"
  //           : "ASINs were added successfully",
  //         {
  //           variant: "success",
  //         }
  //       );
  //       spaceDataRefetch();
  //       await refatchAsins();
  //       closeSnackbar(snackbarId);
  //     })
  //     .catch((e) => {
  //       snackbar.enqueueSnackbar("Something went wrong: " + e, {
  //         variant: "error",
  //       });
  //     });
  // };

  // const undoAction = (snackbarId: SnackbarKey, asins: string[]) => (
  //   <>
  //     <Button
  //       sx={{
  //         color: theme.palette.primary.contrastText,
  //         padding: "5px 10px",
  //         borderRadius: "4px",
  //         background: theme.palette.secondary.main,
  //         ":hover": {
  //           background: theme.palette.secondary.main,
  //         },
  //       }}
  //       onClick={() => handleUndoAsins(snackbarId, asins)}
  //     >
  //       Undo
  //     </Button>
  //   </>
  // );

  const value = {
    selectedProducts,
    setSelectedProducts,
    toggleProductSelection,
    spacesList: spacesList,
    spacesListLoading: spacesListLoading,
    spacesListFetching: spacesListFetching,
    spacesListError: spacesListError,
    spacesListRefetch: spacesListRefetch,
    asinsList,
    allSpaceAsins,
    fetchNextPage,
    isFetchingAsins,
    hasNextPage,
    isFetchingNextPage,
    refatchAsins,
    isDrawerOpen: isDrawerOpen,
    setIsDrawerOpen: setIsDrawerOpen,
    setFilterTextAfterDebounce,
    setFilterByLabel,
    filterByLabel,
    openKeywordsTable,
    setOpenKeywordsTable,
    // keywordsList,
    sort,
    setSort,
    contains,
    setContains,
    filterTextAfterDebounce,
    // setKeywordsSearchAfterDebounce,
    sortKeywordsBy,
    setSortKeywordsBy,
    isSuggestedTab,
    view,
    setView,
    isArrayEmpty,
    setIsArrayEmpty,
    space,
    spaceDataRefetch,
    spaceDataLoading: isSpaceDataLoading || isSpaceDataFetching,
  };
  return (
    <spaceContext.Provider value={value}>{children}</spaceContext.Provider>
  );
};
