import {Typography} from "@mui/material";

import {ConnectorType} from "../../../../enums";
import {ConnectorLogoMapping} from "../../../../mappings";
import {ConnectorTypeCardProps} from "../ConnectorTypeSelection";
import InputTypeSelectionCard from "../InputTypeSelectionCard";
import {FC} from "react"

const BoardConnectorCard: FC<ConnectorTypeCardProps> = ({setInputValue}) => (
    <InputTypeSelectionCard
        onClick={() => setInputValue({type: ConnectorType.BOARD})}
        children={
            <>
                <img
                    src={`/${ConnectorLogoMapping.BOARD}`}
          alt="BoardConnector"
          height="40px"
          width="40px"
        />
        <Typography variant="h6">Block</Typography>
        <Typography variant="caption">Choose a Block to connect to</Typography>
      </>
    }
  />
);

export default BoardConnectorCard;
