import {Button, Card, DialogActions, TextField} from "@mui/material";
import {FC} from "react"

import ConnectorOptionHeader from "../ConnectorOptionHeader";
import {JsonSchemaFormControls} from "../../../../components/SchemaForm";
import {RunInputValue} from "../../../../types";
import {ConnectorOption} from "../../../../types/connectorsV2";

type CreateConnectorFormCardProps = {
    inputValue: RunInputValue;
    handleConnectorCreate: () => void;
    formMethods: any;
    connectorOptions: ConnectorOption[];
    isValid: boolean;
    onBack: () => void;
};

const CreateConnectorFormCard: FC<CreateConnectorFormCardProps> = ({
  inputValue,
  handleConnectorCreate,
  formMethods,
  connectorOptions,
  isValid,
  onBack,
}) => (
  <Card elevation={0} variant="elevation">
    <ConnectorOptionHeader
      connectorType={inputValue.type}
      text="Create New Connection"
    />
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleConnectorCreate();
        e.stopPropagation();
      }}
    >
      <TextField
        label="Name"
        variant="outlined"
        {...formMethods.register('name', { required: true })}
        margin="dense"
        fullWidth
        required
      />
      <JsonSchemaFormControls
        formMethods={formMethods}
        schema={
          connectorOptions
            ? connectorOptions.find(
                (option) => option.type === inputValue.type
              )!.configuration_schema
            : {}
        }
      />
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onBack} autoFocus>
          Back
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isValid}
        >
          Save Connection
        </Button>
      </DialogActions>
    </form>
  </Card>
);
export default CreateConnectorFormCard;
