import {FC, useState} from "react";
import {Box, Divider, Tab, Tabs, TabsProps} from "@mui/material";
import {Run} from "../types";
import MetricsTable from "../pages/Circuitboard/MetricsTable";
import MessagesTable from "../pages/Circuitboard/MessagesTable";

export enum LogsTabs {
  Messages = "messages",
  Metrics = "metrics",
}

const RunLogsContent: FC<{ run?: Run | null; tabsProps?: TabsProps }> = ({
                                                                           run,
  tabsProps,
}) => {
  const [selectedTab, setSelectedTab] = useState<LogsTabs>(LogsTabs.Messages);
  return (
    <>
      <Box>
        <Tabs
          defaultValue={LogsTabs.Messages}
          value={selectedTab}
          onChange={(_e, value) => setSelectedTab(value)}
          {...tabsProps}
        >
          <Tab value={LogsTabs.Messages} label="Messages" />
          <Tab value={LogsTabs.Metrics} label="Metrics" />
        </Tabs>
        <Divider />
      </Box>
      {selectedTab === LogsTabs.Messages
        ? run && <MessagesTable run={run} />
        : run && <MetricsTable run={run} />}
    </>
  );
};

export default RunLogsContent;
