import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { Product } from "../../types";
import { ImageComponent } from "./ProductImage";

const AttributeProducts = ({
  total,
  products,
}: {
  total: number;
  products: Product[];
}) => {
  const ProductIcon = ({
    product,
    mode = "icon",
    count,
  }: {
    product?: Product;
    mode?: "icon" | "more";
    count?: number;
  }) => (
    <Box
      sx={{
        position: "relative",
        minWidth: "35px",
        maxWidth: "35px",
        minHeight: "35px",
        maxHeight: "35px",
        marginRight: "-10px",
        borderRadius: "50%",
        border: "2px solid #fff",
        "&:hover":
          mode !== "more"
            ? {
                zIndex: 999,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
              }
            : {},
        cursor: mode === "icon" ? "pointer" : "default",
        backgroundColor: mode === "more" ? "#F4F3F8" : "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (mode === "icon") {
          window.open(`https://amazon.com/dp/${product?.asin}`, "_blank");
        }
      }}
    >
      {mode === "more" ? (
        <Typography
          sx={{
            color: "#2C224C",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {`+${count}`}
        </Typography>
      ) : (
        <Tooltip
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                p: 0,
              },
            },
          }}
          title={
            <Paper variant="elevation" elevation={5}>
              <ImageComponent size="large" url={product?.image_url} />
            </Paper>
          }
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={product?.image_url}
              alt={product?.asin}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "150px",
        overflowY: "hidden",
        overflowX: "auto",
      }}
    >
      {products.map((product) => (
        <ProductIcon key={product.asin} product={product} />
      ))}
      {total > products.length && (
        <ProductIcon
          key={`more-${products.length}`}
          mode="more"
          count={total - products.length}
        />
      )}
    </Box>
  );
};

export default AttributeProducts;
