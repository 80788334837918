import { Button, ButtonProps, Skeleton, Typography } from "@mui/material";
import {
  Dashboard,
  DashboardFilterImpl,
  DashboardFilters,
  DashboardFiltersImp,
} from "../types";
import { DashboardFiltersSelect } from "./DashboardFiltersSelect";
import { useDashboardFilters, useDashboards } from "../api";
import { CreateNewFilterDialog } from "./CreateNewFilterDialog";
import { FC, useEffect, useState } from "react";

const useListenToMetabaseLocationChages = (
  keys: any[],
  props: { onChange: (location: Location) => void }
) => {
  useEffect(() => {
    const handleMetabaseLocationChange = (e: MessageEvent) => {
      if (e?.data?.metabase?.type === "location") {
        props.onChange(e.data.metabase.location);
      }
    };
    window.addEventListener("message", handleMetabaseLocationChange);
    return () =>
      window.removeEventListener("message", handleMetabaseLocationChange);
  }, keys);
};

const SaveFiltersButton = (props: ButtonProps) => (
  <Button variant="contained" {...props}>
    Save filter
  </Button>
);

export const DashboardFiltersComponent: FC<{
  dashboard: Dashboard;
  setCurrentDashboard: (d: Dashboard) => void;
  defaultFilter: DashboardFilters;
  unsavedFilter: DashboardFiltersImp | null;
  setUnsavedFilter: (f: DashboardFiltersImp | null) => void;
  currentFilter: DashboardFiltersImp;
  setCurrentFilter: (f: DashboardFiltersImp) => void;
}> = ({
  dashboard,
  defaultFilter,
  unsavedFilter,
  setUnsavedFilter,
  currentFilter,
  setCurrentFilter,
}) => {
  const [modelOpen, setModelOpen] = useState<boolean>(false);
  useListenToMetabaseLocationChages([unsavedFilter], {
    onChange: (location) => {
      const filters = DashboardFilterImpl.fromQueryString(
        location.search.replaceAll("?", "")
      );
      setUnsavedFilter(
        unsavedFilter
          ? DashboardFiltersImp.updateFilters(filters, unsavedFilter)
          : null
      );
    },
  });

  if (!dashboard) {
    return <Skeleton width={200} />;
  }

  const { refetch: refetchDashboards } = useDashboards();
  const {
    data: filters = [],
    isLoading,
    refetch,
  } = useDashboardFilters({
    dashboardId: dashboard.id.toString(),
  });

  const allFilters = filters.reduce(
    (uniqueFilters, filter) => {
      const exists = uniqueFilters.some((uniqueFilter) => {
        return uniqueFilter.id === filter.id;
      });
      if (!exists) {
        uniqueFilters.push(filter);
      }

      return uniqueFilters;
    },
    [defaultFilter]
  );

  if (isLoading) {
    return <Skeleton width={200} />;
  }

  return (
    <>
      <Typography
        variant="body5"
        sx={{ color: (t) => t.palette.text.secondary }}
      >
        Quick filter:
      </Typography>
      <DashboardFiltersSelect
        allFilters={allFilters}
        value={currentFilter}
        onChange={(...args) => {
          setCurrentFilter(...args);
        }}
        onDelete={(deletedFilterId) => {
          if (currentFilter.id === deletedFilterId) {
            setCurrentFilter(defaultFilter);
          }
        }}
        onEdit={() =>
          refetch().then((a) => {
            setCurrentFilter(
              a.data?.find((f) => f.id === currentFilter.id) || defaultFilter
            );
          })
        }
      />
      <SaveFiltersButton
        disabled={!unsavedFilter || unsavedFilter.isEqual(currentFilter)}
        onClick={() => setModelOpen(true)}
      />
      <CreateNewFilterDialog
        open={modelOpen}
        currentFilter={currentFilter}
        unsavedFilter={unsavedFilter}
        dashboard={dashboard}
        onCancel={() => setModelOpen(false)}
        onSave={(filter) => {
          setModelOpen(false);
          refetch().then((res) => {
            setCurrentFilter(
              res.data?.find((f) => f.id === filter.id) || filter
            );
          });
        }}
        onDefaultSave={() => {
          setModelOpen(false);
          refetchDashboards().then();
        }}
        allFilters={allFilters}
      />
    </>
  );
};
