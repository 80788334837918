import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useSalesContext } from "../hooks/selectors";
import { AscendingIcon, DescendingIcon } from "../../../assets";

interface SalesFilterComponentProps {
  handleSortFilter: (key: string, value: string | boolean) => void;
}

const SalesFilterComponent: FC<SalesFilterComponentProps> = (props) => {
  const { handleSortFilter } = props;
  const { sortDays, sort } = useSalesContext();
  const isGroupPage = location.pathname === "/sales-assistant/home";
  return (
    <>
      <Typography variant="caption" sx={{ width: "23%" }}>
        Weekly Unit Sales
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="15%">
        <Typography variant="caption">Rolling {sortDays} Sales</Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          position: "absolute",
          right: 0,
          top: isGroupPage ? "16px" : 0,
          zIndex: 9,
          cursor: "pointer",
        }}>
        <Box
          onClick={() => handleSortFilter("ascending", !sort.ascending)}
          sx={{
            "> svg": {
              color: "#2C224C",
            },
          }}>
          {sort.ascending ? <AscendingIcon /> : <DescendingIcon />}
        </Box>
      </Box>
    </>
  );
};

export default SalesFilterComponent;
