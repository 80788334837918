import { CSSProperties } from "react";
import { grey, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
// import { ColorPartial } from "@mui/material/styles/createPalette";
import { Components } from "@mui/material/styles/components";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
  }
}
declare module "notistack" {
  interface VariantOverrides {
    secondary: true;
  }
}
declare module "@mui/material/styles/createPalette" {
  interface TypeText {
    read?: string;
    link?: string;
    info?: string;
    success?: string;
    dark?: string;
  }

  interface Palette {
    primaryPartial: ColorPartial;
    brand: { secondary: string };
    borders: { default: string; hover: string; active: string; lines: string };
    icons: { default: string; active: string; secondary: string; link: string };
    backgrounds: {
      white: string;
      default: string;
      active: string;
      secondary: string;
      transparent: string;
      info: string;
    };
    infoBackground: Palette["primary"];
  }

  interface PaletteOptions {
    primaryPartial: ColorPartial;
    brand: { secondary: string };
    borders: { default: string; hover: string; active: string; lines: string };
    backgrounds: {
      default: string;
      active: string;
      secondary: string;
      info: string;
    };
    icons: { default: string; active: string; secondary: string; link: string };
    infoBackground: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h2_v2: CSSProperties;
    h2_transparent: CSSProperties;
    headline3: CSSProperties;
    headline4: CSSProperties;
    h3_v2: CSSProperties;
    h4_v2: CSSProperties;
    body3: CSSProperties;
    body4: CSSProperties;
    body5: CSSProperties;
    body2_v2: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h2_v2?: CSSProperties;
    h2_transparent?: CSSProperties;
    headline3?: CSSProperties;
    headline4?: CSSProperties;
    h3_v2?: CSSProperties;
    h4_v2?: CSSProperties;
    body3?: CSSProperties;
    body4?: CSSProperties;
    body5?: CSSProperties;
    body2_v2?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h2_v2: true;
    h2_transparent: true;
    headline3: true;
    headline4: true;
    h3_v2: true;
    h4_v2: true;
    body3: true;
    body4: true;
    body5: true;
    body2_v2: true;
  }
}

declare module "@mui/material" {
  interface Color {
    25?: string;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    circled: true;
    light: true;
  }
}

const errorScale = {
  ...red,
  light: "#FDECEC",
  main: "#C70E24",
  dark: "#C70E24",
};
const primaryColor = {
  main: "#8E4FF6",
  dark: "#5C30A4",
  contrastText: "#fff",
  light: "#EEE5FE",
  800: "#48326B",
  700: "#593595",
  600: "#6A3FB2",
  500: "#8E4FF6",
  400: "#A576F1",
  300: "#BC9CF2",
  200: "#DECDFA",
  100: "#EEE5FE",
};
const secondaryColor = {
  main: "#2C224C",
  dark: "#120731",
  contrastText: "#fff",
  light: "#7f5dec",
  800: "#150e2c",
  700: "#1f1636",
  600: "#271e44",
  500: "#2C224C",
  400: "#3a266b",
  300: "#3e268f",
  200: "#4c2cb6",
  100: "#6541d9",
};
const transparent = "#00000000";
const greyScale = {
  ...grey,
  dark: "#A6A3AF",
  500: "#504C55",
  400: "#75717B",
  300: "#A19EA5",
  200: "#D7D5DB",
  100: "#E1DFE4",
  50: "#F4F3F8",
  25: "#F8F7FA",
};

const fontFamily = ["Inter", "Roboto", "Helvetica", "Arial", "sans-serif"].join(
  ","
);

const textColors = {
  primary: secondaryColor.main,
  secondary: greyScale[400],
  read: greyScale[500],
  info: greyScale.dark,
  link: "#7F3CEE",
  dark: "#FFF",
  success: "#07A254",
};

const borderColors = {
  active: primaryColor.main,
  hover: greyScale["300"],
  lines: "#E9E7F1",
  default: greyScale["200"],
};

const brand = {
  secondary: "#8552ED",
};

export const palette = {
  brand,
  text: textColors,
  primary: primaryColor,
  primaryPartial: primaryColor,
  secondary: secondaryColor,
  action: {
    active: primaryColor[200],
    hover: primaryColor[100],
    focus: primaryColor[200],
    selected: primaryColor[300],
  },
  grey: greyScale,
  borders: borderColors,
  dark: {
    success: "#1AD075",
    error: "#FF6A85",
    notify: "#F89A43",
  },
  success: {
    light: "#E0F2D2",
    main: "#38bf8d",
    dark: "#2C7133",
    contrastText: "#fff",
  },
  info: {
    dark: "#6aa6d3",
    main: "#D4E6FB",
    light: "#e9eef3",
    contrastText: "#185CC5",
    bg: "#F0F9FF",
  },
  warning: {
    light: "#FEF5E4",
    main: "#D6751B",
    dark: "#AA5C27",
    contrastText: "#fff",
  },
  background: {
    default: "#ffffff",
  },
  error: errorScale,
  infoBackground: {
    main: "#f3f3f3",
  },
  icons: {
    default: secondaryColor.main,
    active: primaryColor.main,
    secondary: greyScale[300],
    link: textColors.link,
  },
  backgrounds: {
    default: greyScale[25],
    active: primaryColor[100],
    secondary: greyScale[50],
    info: "#F0F9FF",
    white: "#fff",
    transparent,
  },
};

const typography: TypographyOptions = {
  fontFamily,
  allVariants: { letterSpacing: "initial" },
  fontSize: 13,
  subtitle2: {
    fontWeight: "bold",
  },
  body1: {
    color: secondaryColor.main,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  body2: {
    fontSize: "0.9rem",
    lineHeight: "1.5rem",
  },
  body4: {
    fontFamily,
    color: secondaryColor.main,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 600,
  },
  body5: {
    fontFamily,
    color: secondaryColor.main,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: "normal",
  },
  caption: {
    fontFamily,
    fontWeight: 500,
    fontSize: "0.813rem",
    lineHeight: "1rem",
  },
  h2_v2: {
    fontFamily,
    color: secondaryColor.main,
    fontSize: "1.5rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  headline3: {
    fontFamily,
    color: secondaryColor.main,
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: 600,
  },
  headline4: {
    fontFamily,
    color: secondaryColor.main,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
  },
  body3: {
    fontFamily,
    fontSize: "0.934rem",
    lineHeight: "1.5rem",
  },
  body2_v2: {
    fontFamily,

    fontSize: "0.9rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
  },
  h4_v2: {
    fontFamily,
    color: secondaryColor.main,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 600,
  },
  h3_v2: {
    fontFamily,
    color: secondaryColor.main,
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: 600,
  },
  h2_transparent: {
    fontFamily,
    color: textColors.info,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 600,
  },
};

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
        },
        "&::-webkit-scrollbar-thumb:vertical, & *::-webkit-scrollbar-thumb:vertical":
          {
            borderRadius: 100,
            backgroundColor: "#2C224C66",
            "&:hover, &:active, &:focus": {
              borderRadius: 8,
              backgroundImage: "url(scroll-bar.svg)",
            },
          },
        "&::-webkit-scrollbar-thumb:horizontal, & *::-webkit-scrollbar-thumb:horizontal":
          {
            borderRadius: 100,
            backgroundColor: "#2C224C66",
            "&:hover, &:active, &:focus": {
              borderRadius: 8,
              backgroundImage: "url(scroll-bar-horizontal.svg)",
            },
          },
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    variants: [
      {
        props: { color: "default" },
        style: {
          background: greyScale["50"],
          color: greyScale["400"],
          ":hover": {
            background: greyScale["100"],
          },
          ":active": {
            background: primaryColor.light,
          },
        },
      },
    ],
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: 5,
        color: textColors.primary,
        "&.Mui-selected": {
          color: primaryColor.main,
          background: primaryColor.light,
          ":hover": {
            background: primaryColor[200],
          },
        },
        ":hover": {
          background: greyScale[50],
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: palette.icons.secondary,
        fill: palette.icons.secondary,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: 5,
        color: textColors.read,
        ".MuiListItemIcon-root": {
          minHeight: "24px",
        },
        "&.Mui-selected": {
          "&.expandable": {
            background: "none",
          },
          color: palette.icons.active,
          ".MuiListItemIcon-root": {
            minHeight: "24px",
            color: palette.icons.active,
            fill: palette.icons.active,
          },
          ".MuiTypography-root": {
            color: textColors.link,
          },
          background: primaryColor.light,
          ":hover": {
            background: palette.backgrounds.default,
          },
        },
        ":hover": {
          background: greyScale[50],
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: { color: textColors.primary },
    },
  },
  MuiSelect: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      select: {
        display: "flex",
        "&.MuiSelect-select.MuiInputBase-input": {
          alignSelf: "stretch",
          display: "flex",
          flex: 1,
          alignItems: "center",
        },
      },
      standard: {
        "&.MuiSelect-standard.MuiInputBase-input": {
          ...typography.body4,
          paddingLeft: 8,
          paddingTop: 6,
          paddingBottom: 6,
        },
      },
    },
    variants: [
      {
        props: { variant: "filled" },
        style: {
          root: {
            paddingBottom: 0,
            backgroundColor: greyScale["50"],
          },
        },
      },
    ],
  },
  MuiInput: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        "&.MuiSelect-root": {
          "&:hover": {
            background: greyScale["50"],
          },
          ".MuiSelect-iconStandard": {
            color: secondaryColor.main,
          },
          "&.Mui-focused": {
            background: greyScale["50"],
            ".MuiSelect-standard.MuiInputBase-input, .MuiSelect-iconStandard": {
              background: "none",
              color: textColors.link,
            },
          },
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: greyScale["50"],
        borderRadius: "6px",
      },
      input: {
        padding: "0px 10px 0px",
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: "primary" }, //  warning: not specific, overrides colors: https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
        style: {
          border: "2px solid transparent",
          ".MuiButton-startIcon,.MuiSvgIcon-root,svg": {
            color: textColors.dark,
            fill: textColors.dark,
          },
          ":hover": {
            background: palette.primary.dark,
          },
          ":active": {
            background: primaryColor.main,
          },
          ":focus": {
            background: palette.secondary.main,
            border: `2px solid ${palette.primary.main}`,
          },
          background: palette.secondary.main,
          color: textColors.dark,
        },
      },
      {
        props: { variant: "outlined" }, //  warning: not specific, overrides colors: https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
        style: {
          ".MuiButton-startIcon,.MuiSvgIcon-root,svg": {
            color: textColors.primary,
            fill: textColors.primary,
          },
          ":hover": {
            background: "white",
            borderColor: borderColors.hover,
            borderWidth: 1,
          },
          ":active": {
            borderColor: primaryColor.main,
            borderWidth: 1,
          },
          borderWidth: 1,
          background: "white",
          borderColor: borderColors.default,
          color: textColors.primary,
        },
      },
      {
        props: { variant: "text", color: "primary" },
        style: {
          ":hover, :focus": {
            background: greyScale[50],
          },
          ":active": {
            background: primaryColor.light,
          },
          color: textColors.link,
        },
      },
      {
        props: { variant: "text", color: "secondary" },
        style: {
          ":hover, :focus": {
            background: greyScale[50],
          },
          ":active": {
            background: primaryColor.light,
          },
          color: textColors.link,
        },
      },
      {
        props: { variant: "contained" },
        style: {
          ...typography.body4,
          ".MuiButton-startIcon,.MuiSvgIcon-root,svg": {
            color: "white",
            fill: "white",
          },
          color: "white",
          ":hover": {
            background: primaryColor[600],
          },
          ":active": {
            background: primaryColor[800],
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        minWidth: 56,
        fontWeight: 600,
        textTransform: "none",
      },
      sizeSmall: {
        minHeight: 32,
        // height: 32,
      },
      sizeMedium: {
        minHeight: 40,
      },
      sizeLarge: {
        minHeight: 48,
      },
    },
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
  },
  MuiCard: {
    defaultProps: {
      variant: "outlined",
    },
    styleOverrides: {
      root: {
        boxShadow: [
          "0px 12px 45px -4px rgba(44, 34, 76, 0.05)",
          "0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
        ],
        "&:hover": {
          boxShadow: [
            "0px 12px 45px 20px rgba(44, 34, 76, 0.05)",
            "0px 20px 24px -6px rgba(44, 34, 76, 0.1)",
          ],
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        color: textColors.primary,
        ".MuiFilledInput-root": {
          padding: "unset",
        },
        ".MuiOutlinedInput-root .MuiAutocomplete-input": {
          padding: "unset",
        },
        ".MuiInputBase-root": {
          height: "unset",
          minHeight: "40px",
        },
      },
      popupIndicator: {
        background: "none",
        ":hover": {
          background: "none",
        },
      },
    },
  },
  MuiTextField: {
    variants: [
      {
        props: { variant: "filled" },
        style: {
          ".MuiFilledInput-input": {
            padding: "0 12px 0",
            background: "none",
          },
          ".Mui-focused": {
            border: `2px solid ${primaryColor.main}`,
            background: palette.background.default,
          },
          ".MuiFilledInput-root": {
            ":hover:not(.Mui-focused)": { background: borderColors.lines },
            ":active": {
              border: `2px solid ${primaryColor.main}`,
            },
            "&:before": {
              borderBottom: "none",
              borderBottomStyle: "unset",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
            "&.Mui-disabled": {
              "&:before": {
                borderBottomStyle: "none",
              },
            },
            "&:after": {
              border: "none",
            },
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        ".MuiInputLabel-root": {
          "&.Mui-error": {
            outline: `1px solid ${errorScale.main}`,
          },
          transform: "translate(14px, 10px) scale(1)",
        },
        ".MuiInput-root": {
          margin: 0,
        },
        '.MuiInputLabel-root[data-shrink="true"]': {
          transform: "translate(14px, -9px) scale(0.75)",
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "&.clickable": {
          transition: "background 0.2s",
          "&:hover": {
            cursor: "pointer",
            background: greyScale[200],
          },
          cursor: "pointer",
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        display: "flex",
        alignItems: "center",
        ".MuiInputBase-input": {
          height: "40px",
          boxSizing: "border-box",
        },
      },
      sizeSmall: {
        ".MuiInputBase-input": {
          height: "32px",
          boxSizing: "border-box",
        },
      },
    },
    variants: [
      {
        props: { multiline: false },
        style: {
          height: "40px",
        },
      },
      {
        props: { multiline: false, size: "small" },
        style: {
          height: "32px",
        },
      },
    ],
  },
  MuiChip: {
    variants: [
      {
        props: { variant: "circled" }, //  warning: not specific, overrides colors: https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
        style: {
          borderRadius: "100px",
        },
      },
      {
        props: { variant: "light" },
        style: {
          background: primaryColor.light,
          color: textColors.primary,
        },
      },
    ],

    styleOverrides: {
      root: {
        borderRadius: "4px",
      },
      colorPrimary: {
        color: textColors.dark,
        background: primaryColor.main,
      },
      sizeSmall: {
        height: "24px",
      },
      colorSecondary: {
        background: palette.backgrounds.secondary,
        color: palette.primary.dark,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        ".MuiSvgIcon-fontSizeSmall": {
          width: 20,
          height: 20,
        },
        "&.Mui-checked,&.MuiCheckbox-indeterminate": {
          ".MuiSvgIcon-root": {
            fill: primaryColor.main,
          },
        },
        ":hover": {
          "&.Mui-checked": {
            ".MuiSvgIcon-root": {
              fill: primaryColor.dark,
            },
          },
          ".MuiSvgIcon-root": {
            fill: borderColors.hover,
          },
        },
        ".MuiSvgIcon-root": {
          fill: borderColors.default,
        },
      },
    },
  },
  MuiAlert: {
    variants: [
      {
        props: { severity: "error" },
        style: {
          backgroundColor: errorScale.light,
          color: textColors.primary,
          fontSize: 14,
          ".MuiAlert-icon": {
            color: errorScale.main,
          },
          ".MuiAlert-action": {
            paddingTop: 0,
            color: errorScale.dark,
          },
        },
      },
    ],
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: secondaryColor.main,
      },
      arrow: {
        color: secondaryColor.main,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        "&:hover": {
          background: "none",
        },
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      color: "secondary",
    },
    styleOverrides: {
      colorSecondary: {
        color: palette.icons.secondary,
      },
      fontSizeSmall: {
        width: "1rem",
        height: "1rem",
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        width: 32,
        height: 32,
        backgroundColor: palette.backgrounds.secondary,
        color: palette.text.primary,
        textAlign: "right",
      },
    },
  },
  MuiAvatarGroup: {
    defaultProps: {
      max: 4,
      spacing: "small",
    },
    styleOverrides: {
      root: { flexDirection: "row" },
    },
  },
};

const theme = createTheme({
  palette,
  components,
  typography,
});

export default theme;
