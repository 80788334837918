import { Box, Link, Stack, Typography } from "@mui/material";
import { EmptyCatalogStateLogo } from "../../assets";
import theme from "../../themes/light";

const EmptyCatalogState = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "calc(100vh - 315px)",
      }}>
      <Box
        sx={{
          display: "inline-flex"
        }}>
        <EmptyCatalogStateLogo />
      </Box>
      <Stack spacing={3}>
        <Typography variant="body4" fontSize={18} textAlign="center">
          Your catalog isn’t connected yet.
        </Typography>
        <Stack spacing={0.3}>
          <Typography variant="body4" fontSize={16} textAlign="center" fontWeight={400}>
            For help connecting it, please contact support or check out
            {" "}
            <Link
              color={theme.palette.secondary.main}
              display="inline"
              target="_blank"
              rel="noopener"
              href="https://www.loom.com/share/f18071aa39564d6fa8d41561d09010d4?sid=d6fd8867-108a-4fe1-9b9a-3b2036030934"
            >
              this
            </Link>
            {" "}
            guide.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EmptyCatalogState;
