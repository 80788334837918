import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { FC } from "react";
import { useRun } from "../../../../../services/runs";
import { snakeToPascal, toSnakeCase } from "../../../../../utils";
import { useMeta } from "../../../../../services/query";
import { BoardAndRunIds, Cube } from "../../../../../types";

const OutputsCheckboxGroup: FC<
  BoardAndRunIds & {
    control: any;
    getOutputs: () => Set<string>;
    defaultChecked: Set<string>;
    error?: string;
  }
> = ({ runId, boardId, control, getOutputs, defaultChecked, error }) => {
  const { data: run } = useRun(boardId, runId);
  const { data: meta } = useMeta(boardId, runId);

  // @ts-ignore
  const cubes: Cube[] = meta && meta?.cubes;
  const snakeRunOutputNames =
    run && run.outputs.map((output) => snakeToPascal(output.export_name));

  const handleCheck = (checkedOutputName: string) => {
    const outputs = getOutputs();
    if (outputs?.has(checkedOutputName)) {
      outputs?.delete(checkedOutputName);
    } else {
      outputs?.add(checkedOutputName);
    }
    return outputs;
  };

  return (
    <Box display="flex" flexDirection="column">
      {cubes && run && defaultChecked && (
        <FormControl error={!!error} sx={{ marginTop: 2 }}>
          <Controller
            name="outputs"
            control={control}
            rules={{ validate: (value) => value && value.size > 0 }}
            defaultValue={defaultChecked}
            render={({ onChange }: any) => (
              <>
                {cubes
                  .filter((cube) => new Set(snakeRunOutputNames).has(cube.name))
                  .map((cube) => (
                    <FormControlLabel
                      key={cube.name}
                      label={cube.name}
                      sx={{ marginTop: 0 }}
                      control={
                        <Checkbox
                          defaultChecked={defaultChecked.has(
                            toSnakeCase(cube.name)
                          )}
                          onChange={() =>
                            onChange(handleCheck(toSnakeCase(cube.name)))
                          }
                        />
                      }
                    />
                  ))}
              </>
            )}
          />
        </FormControl>
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};

export default OutputsCheckboxGroup;
