import { checkTime, useInsightChatContext } from "./hooks/selectors.ts";
import {
  Box,
  Button,
  // ClickAwayListener,
  // Divider,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import ErrorComponent from "./ErrorComponent.tsx";
import { DownloadIcon } from "../../assets/index.tsx";
import ChatFilter from "./ChatFilter/ChatFilter.tsx";
import theme from "../../themes/light.ts";
import { FC, useState, useRef, useMemo, useEffect } from "react";
import { ReactTyped } from "react-typed";
import { useSnackbar } from "notistack";
import LoadingSpinner from "../../components/LoadingSpinner.tsx";
import { parseJsonRecursive } from "./utils.ts";
import StoryPointGraph from "./Graph/StoryPointGraph.tsx";
import StorefrontIcon from "@mui/icons-material/Storefront";

interface StoryPointProps {
  storyPoint: any;
  // questionData: any;
  // graphData: any;
  storyBoardIndex: number;
  story_points: any;
}

const StoryPoint: FC<StoryPointProps> = (props) => {
  const {
    storyPoint,
    // questionData,
    // graphData,
    storyBoardIndex,
  } = props;
  const snackbar = useSnackbar();

  const {
    deleteStoryPoint,
    updateStoryPoint,
    exportStoryPoint,
    questionList,
    spacesList,
  } = useInsightChatContext();

  const spaceName = spacesList.find(
    (space: any) => space.id === storyPoint?.space_id
  )?.name;

  const [openSettings, setOpenSettings] = useState(false);

  // Edit Title logic
  const [editTitleMode, setEditTitleMode] = useState<boolean>(false);
  const [editTitleValue, setEditTitleValue] = useState<string>("");
  const [editTitlePending, setEditTitlePending] = useState<boolean>(false);

  useEffect(() => {
    if (storyPoint?.title) {
      setEditTitleValue(storyPoint?.title);
    }
  }, [storyPoint?.title]);

  const handleSubmitEditTitle = async () => {
    if (editTitleValue === "") return;
    if (editTitleValue === storyPoint?.title) return setEditTitleMode(false);

    setEditTitlePending(true);
    await updateStoryPoint(storyPoint?.id, { title: editTitleValue })
      .then(() => {
        snackbar.enqueueSnackbar("Title updated!", {
          variant: "success",
        });
      })
      .finally(() => {
        setEditTitlePending(false);
        setEditTitleMode(false);
      });
  };

  // Download Report logic
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadReport = async () => {
    if (isDownloading) return;

    if (storyPoint?.export_link) {
      window.open(storyPoint?.export_link, "_self");
    }

    setIsDownloading(true);

    await exportStoryPoint(storyPoint?.id)
      .then((res: any) => {
        if (res?.export_link) {
          window.open(res?.export_link, "_self");
        }
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const closeEditTitle = () => {
    setEditTitleMode(false);
    setEditTitleValue(storyPoint?.title);
  };

  const isHide = checkTime(storyPoint?.created_at);

  const questionData = questionList?.find(
    (question: any) => question?.id === storyPoint?.type
  );

  const filterRef = useRef<any>(null);

  const scrollToFiter = () => {
    filterRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const openSettingsAction = () => {
    if (openSettings) {
      scrollToFiter();
    } else {
      setOpenSettings(true);
      setTimeout(() => scrollToFiter(), 300);
    }
  };

  const graphData = parseJsonRecursive(storyPoint?.graph_data);

  const memoizedChart = useMemo(
    () =>
      graphData ? (
        <StoryPointGraph
          graph_data={graphData}
          schema={storyPoint.output_schema}
        />
      ) : null,
    [graphData]
  );

  return (
    <>
      <Box
        sx={{
          position: "relative",
          boxShadow:
            "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
          background: theme.palette.primary.contrastText,
          borderRadius: "12px",
          padding: "30px 40px 20px",
          ":hover": {
            "& .close-btn": {
              display: "inline-flex",
            },
          },
          height: "675px",
        }}
      >
        {storyPoint.space_id && spaceName && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "20px",
              position: "absolute",
              top: "10px",
              right: "20px",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "20px",
                color: "#2C224C",
                background: theme.palette.primary.light,
                padding: "1px 10px",
                borderRadius: "100px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <StorefrontIcon sx={{ color: "#2C224C", fontSize: "16px" }} />{" "}
              {spaceName}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "40px",
          }}
        >
          {storyPoint?.title && !editTitlePending ? (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {!editTitleMode ? (
                <>
                  <Typography
                    variant="body4"
                    sx={{
                      fontSize: "20px",
                      // marginBottom: "11px",
                      // display: "block",
                      padding: "6px 0",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      height: "24px",
                      "& button": {
                        visibility: "hidden",
                      },
                      "&:hover button": {
                        visibility: "visible",
                      },
                    }}
                  >
                    {storyPoint?.title}
                    <IconButton onClick={() => setEditTitleMode(true)}>
                      <EditIcon
                        sx={{
                          fontSize: "1rem",
                        }}
                      />
                    </IconButton>
                  </Typography>
                </>
              ) : (
                <>
                  <TextField
                    sx={{
                      width: "350px",
                    }}
                    value={editTitleValue}
                    onInput={(e) =>
                      setEditTitleValue((e.target as HTMLInputElement).value)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmitEditTitle();
                      } else if (e.key === "Escape") {
                        closeEditTitle();
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitEditTitle()}
                    disabled={
                      editTitleValue === "" ||
                      editTitleValue === storyPoint?.title
                    }
                  >
                    Save
                  </Button>
                  <Button variant="outlined" onClick={closeEditTitle}>
                    Cancel
                  </Button>
                </>
              )}
            </Stack>
          ) : (
            <Skeleton
              animation="wave"
              width="100%"
              sx={{
                height: "24px",
                borderRadius: "16px",
                transform: "unset",
              }}
            />
          )}
          <Tooltip
            onClick={() => deleteStoryPoint(storyPoint?.id)}
            placement="top"
            title="Delete"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              // onClick={onBack}
              className="close-btn"
              size="small"
              sx={{
                borderRadius: "50%",
                backgroundColor: "unset",
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.primary.dark,
                  },
                },
                display: "none",
              }}
            >
              <ClearIcon
                sx={{
                  color: theme.palette.grey[300],
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>

        <>
          {isHide && graphData === null ? (
            <></>
          ) : (
            <Box>
              {storyPoint.summary !== null && graphData !== null && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0.5rem 0"
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "20px",
                      color: "#2C224C",
                      display: "block",
                      ".label": {
                        background: theme.palette.primary.light,
                        padding: "1px 10px",
                        borderRadius: "100px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        color: theme.palette.grey[500],
                      },
                    }}
                  >
                    {storyPoint?.summary ? (
                      <Box
                        sx={{
                          minHeight: "40px",
                        }}
                      >
                        {storyPoint?.showAnimation ? (
                          <ReactTyped
                            // onBegin={beginHandler}
                            // onComplete={completeHandler}
                            strings={[storyPoint?.summary]}
                            showCursor={false}
                          />
                        ) : (
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: storyPoint?.summary,
                            }}
                          ></Box>
                        )}
                      </Box>
                    ) : (
                      storyPoint?.summary || ""
                    )}
                  </Typography>
                  {storyBoardIndex > 0 && (
                    <Tooltip
                      onClick={() => deleteStoryPoint(storyPoint?.id)}
                      placement="top"
                      title="Delete"
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -5],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <IconButton
                        // onClick={onBack}
                        size="small"
                        sx={{
                          borderRadius: "50%",
                          backgroundColor: "unset",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                            "& .MuiSvgIcon-root": {
                              color: theme.palette.primary.dark,
                            },
                          },
                          display: "none",
                          position: "absolute",
                          right: "-6px",
                          top: "-25px",
                        }}
                      >
                        <ClearIcon
                          sx={{
                            color: theme.palette.grey[300],
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                gap="1rem"
                position="relative"
                borderRadius="8px"
                marginTop="1rem"
                // height="83%"
              >
                {storyPoint?.status === "failed" || graphData === null ? (
                  <ErrorComponent />
                ) : (
                  <Box>{memoizedChart}</Box>
                )}

                <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    {questionData?.params.filter(
                      (p: any) => p.input_type != "hidden"
                    ).length > 0 && (
                      <Button
                        // variant="body5"
                        sx={{
                          fontWeight: 600,
                          color: theme.palette.primary.dark,
                          borderRadius: "4px",
                          width: "150px",
                          // border: `1px solid ${theme.palette.borders.lines}`,
                          padding: "0",
                          ":focus": {
                            background: "unset",
                          },
                          ":hover": {
                            background: theme.palette.grey[50],
                          },
                        }}
                        onClick={() => openSettingsAction()}
                      >
                        Refine your question
                      </Button>
                    )}
                  </Box>

                  {storyPoint?.allow_download && (
                    <Box
                      sx={{
                        display: "inline-flex",
                        padding: "3px",
                        borderRadius: "50%",
                        cursor: !isDownloading ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (!isDownloading) handleDownloadReport();
                      }}
                    >
                      {!isDownloading ? (
                        <DownloadIcon />
                      ) : (
                        <LoadingSpinner color={"grey"} />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </>
      </Box>

      {openSettings && (
        <Box
          ref={filterRef}
          sx={{
            boxShadow:
              "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
            background: theme.palette.primary.contrastText,
            borderRadius: "12px",
            marginTop: "1.5rem",
            padding: "20px",
            width: "100%",
            maxWidth: "1000px !important",
            minWidth: "500px",
            minHeight: "100px",
            ":hover": {
              "& .close-btn": {
                display: "inline-flex",
              },
            },
          }}
        >
          <ChatFilter
            filterData={questionData}
            params={parseJsonRecursive(storyPoint?.params)}
            storyPointType={storyPoint?.type}
            storyBoardIndex={storyPoint?.order}
            onclose={() => setOpenSettings(false)}
            setIsVisible={setOpenSettings}
          />
        </Box>
      )}
    </>
  );
};

export default StoryPoint;
