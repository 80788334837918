import { Box, Chip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import theme from "../../../themes/light";
import MultiChoiceComponent from "../MultiChoiceComponent";

interface BrandsFilterProps {
  brandOptionsList: any;
  brandTypeList: any;
  selectedBrands: string;
  setSelectedBrands: any;
  setAllowedApply: any;
}

const BrandsFilter: FC<BrandsFilterProps> = ({
  brandOptionsList,
  brandTypeList,
  selectedBrands,
  setSelectedBrands,
  setAllowedApply,
}) => {
  const selectedBrandsToTypeLabel = (selectedBrands: string) => {
    if (selectedBrands === "all_brands") {
      return "All Brands";
    } else if (selectedBrands === "my_brands") {
      return "My Brands";
    } else if (selectedBrands === "competitor_brands") {
      return "Competitor Brands";
    } else {
      return "Custom";
    }
  };

  const [isCustom, setIsCustom] = useState<boolean>(
    selectedBrandsToTypeLabel(selectedBrands) === "Custom"
  );
  useEffect(() => {
    setIsCustom(selectedBrandsToTypeLabel(selectedBrands) === "Custom");
  }, [selectedBrands]);

  const [selectedCustomBrands, setSelectedCustomBrands] = useState<string[]>(
    []
  );
  useEffect(() => {
    if (selectedCustomBrands.length > 0) {
      setSelectedBrands(selectedCustomBrands.join(","));
    }
  }, [selectedCustomBrands]);

  useEffect(() => {
    if (
      selectedBrands === "all_brands" ||
      selectedBrands === "my_brands" ||
      selectedBrands === "competitor_brands"
    ) {
      setAllowedApply(true);
    } else {
      setAllowedApply(selectedCustomBrands.length > 0);
    }
  }, [selectedBrands, setAllowedApply]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        minHeight: "100px",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontSize: "14px",
          color: theme.palette.grey[400],
          display: "block",
        }}
      >
        Brands
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
        }}
      >
        {brandTypeList.map((brandType: any) => {
          const isCheck = selectedBrandsToTypeLabel(selectedBrands).includes(
            brandType.label
          );
          return (
            <Chip
              key={`brandType-${brandType.value}`}
              label={brandType.label}
              sx={{
                height: "24px",
                background: isCheck ? "#EEE5FE !important" : "transparent",
                "& .MuiChip-label": {
                  color: isCheck
                    ? "#5C30A4 !important"
                    : theme.palette.grey[500],
                },
              }}
              onClick={() => setSelectedBrands(brandType.value)}
            />
          );
        })}
      </Box>
      {isCustom && (
        <MultiChoiceComponent
          isPending={brandOptionsList.length === 0}
          choiceList={brandOptionsList}
          selectedChoices={selectedCustomBrands}
          setSelectedChoices={setSelectedCustomBrands}
          setAllowedApply={setAllowedApply}
        />
      )}
    </Box>
  );
};


export default BrandsFilter;