import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import theme from "../../themes/light";
import { CloseIcon } from "../../assets";

const DeleteTask: React.FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "420px",
            width: "100%",
            margin: 0,
            padding: "24px",
            gap: "8px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px" }}>
          <Typography variant="headline3">Task deletion</Typography>
          <Box sx={{ position: "absolute", top: "12px", right: "12px",cursor:'pointer' }} onClick={handleClose}>
            <CloseIcon />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="24px"
          >
            <Typography
              variant="body3"
              sx={{ fontWeight: 400, color: theme.palette.grey[400] }}
            >
              Are you sure you want to delete the task?
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-end"
              gap="12px"
              width="100%"
            >
              <Button
                onClick={handleClose}
                color="secondary"
                sx={{
                  border: "1px solid #D7D5DB",
                  boxShadow: "0px 1px 1px 0px rgba(44, 34, 76, 0.04)",
                  padding: "10px 16px",
                  borderRadius: "4px",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  height: "40px",
                }}
              >
                Keep in todo list
              </Button>
              <Button
                sx={{
                  padding: "10px 16px",
                  backgroundColor: "#8E4FF6 !important",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 600,
                  height: "40px",
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DeleteTask;
