import { Box, Skeleton, Stack, Typography } from "@mui/material";
import FilterDropdown from "./FilterDropdown";
import { useContext } from "react";
import { AssortmentIntelligenceContext } from "../../AssortmentIntelligenceProvider";
import { PeriodFilter } from "../../types";
import { Icon } from "../../../../components/Icon";
import { isAttributeNumerical } from "../../utils";

const Filters = () => {
  const {
    spaceAttributes,
    spaceAttributesLoading,
    filterOptions,
    filterOptionsLoading,
    currentFilters,
    setCurrentFilters,
    allowApplyFilters,
    setFilters,
  } = useContext(AssortmentIntelligenceContext);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "4px 32px 20px",
        borderBottom: "1px solid #E0E0E0",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "flex-start",
          gap: "24px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <FilterDropdown
          label="Period vs. Previous"
          allowSearch={false}
          isMultiSelect={false}
          value={currentFilters.period}
          setValue={(value) =>
            setCurrentFilters({ ...currentFilters, period: value })
          }
          valueFormatter={(value) => {
            switch (value) {
              case PeriodFilter.last_30_days:
                return "Last 30 days";
              case PeriodFilter.last_90_days:
                return "Last 90 days";
              case PeriodFilter.year_to_date:
                return "Year to date";
              default:
                return "";
            }
          }}
          options={filterOptions?.period}
          optionsLoading={false}
        />
        <FilterDropdown
          label="Brand"
          value={currentFilters.brand}
          setValue={(value) =>
            setCurrentFilters({ ...currentFilters, brand: value })
          }
          options={filterOptions?.brand}
          optionsLoading={filterOptionsLoading}
        />

        <FilterDropdown
          label="Price Range"
          allowSearch={false}
          value={currentFilters.price_range}
          setValue={(value) =>
            setCurrentFilters({ ...currentFilters, price_range: value })
          }
          options={filterOptions?.price_range}
          optionsLoading={filterOptionsLoading}
        />
        {spaceAttributesLoading && (
          <Skeleton
            variant="text"
            sx={{
              marginTop: "5px",
              height: "40px",
              width: "200px",
              borderRadius: "4px",
              transform: "unset",
            }}
          />
        )}
        {spaceAttributes.map((attribute) => (
          <FilterDropdown
            key={`attribute-filter-${attribute.attribute}`}
            label={attribute.attribute}
            allowSearch={
              !isAttributeNumerical(attribute) && attribute.type !== "boolean"
            }
            value={
              currentFilters.individual_attributes?.[attribute.attribute] || []
            }
            setValue={(value) =>
              setCurrentFilters({
                ...currentFilters,
                individual_attributes: {
                  ...currentFilters.individual_attributes,
                  [attribute.attribute]: value,
                },
              })
            }
            options={
              filterOptions?.individual_attributes?.[attribute.attribute]
            }
            optionsLoading={filterOptionsLoading}
          />
        ))}
      </Stack>
      <Box sx={{ margin: "auto 0" }}>
        <Typography
          onClick={() => {
            if (allowApplyFilters) setFilters(currentFilters);
          }}
          sx={{
            display: "inline-flex",
            cursor: `${allowApplyFilters ? "pointer" : "default"}`,
            opacity: `${allowApplyFilters ? "1" : "0.25"}`,
          }}
        >
          <Icon src={`insights/reset.svg`} height="40px" width="40px" />
        </Typography>
      </Box>
    </Stack>
  );
};

export default Filters;
