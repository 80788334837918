import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Icon } from "../../components/Icon";
import theme from "../../themes/light";
import { 
  ArchiveIcon,
  //  EditTaskIcon,
    MarkAsDoneIcon
   } from "../../assets";
import { updateTask } from "./useTaskApi";
// import { useTasksContext } from "./hooks/selectors";
import { handleToast } from "./TaskUtils";
import { useTasksContext } from "./hooks/selectors";

interface PropTypes {
  salesDrawerList?: boolean;
  taskId?: string;
  handlePopup: (id: string) => void;
  assignee?: string;
  description?: string;
}

export default function ActionMenu({
  salesDrawerList,
  taskId,
  //handlePopup,
  //assignee,
  //description,
}: PropTypes) {
  const { setIsUpdated } = useTasksContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateTask = async (status: string) => {
    setIsUpdated(false)
    const payload = {
      status,
    };
    handleToast("Task updated");
    if (taskId !== undefined) {
      await updateTask(payload, taskId);
      setIsUpdated(true)
    }
  };

  const handleUpdateArchivedTask = async (status: string) => {
    const payload = {
      status,
      archived: true,
    };
    setIsUpdated(false);
    handleToast("Task archived", '', '');
    if (taskId !== undefined) {
      await updateTask(payload, taskId);
      setIsUpdated(true);
    }
  };
  // const handleEditTask = () => {
  //   setType("Edit");
  //   setCreatePopup(!createPopup);
  //   const payload = {
  //     taskId: taskId || "",
  //     assignee: assignee || "",
  //     description: description || "",
  //   };
  //   setTaskData(payload);
  // };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            ml: salesDrawerList ? 0 : 2,
            "&:hover": { background: "transparent !important" },
          }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Icon src="tasks/actions.svg" />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            maxWidth: "224px",
            width: "100%",
            padding: "8px",
            mt: 0,
            "& .MuiMenu-list": {
              padding: 0,
            },
            "& .MuiMenuItem-root": {
              padding: "8px 8px",
              fontSize: "15px",
              fontWeight: 400,
              display: "flex",
              gap: "8px",
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.text.link,
                "& svg": {
                  "& path": {
                    fill: theme.palette.text.link,
                  },
                },
              },
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleEditTask}>
          <EditTaskIcon /> Edit task
        </MenuItem> */}
        <MenuItem onClick={() => handleUpdateTask("done")}>
          {" "}
          <MarkAsDoneIcon /> Mark as done
        </MenuItem>
        <MenuItem onClick={() => handleUpdateArchivedTask('archived')}>
          <ArchiveIcon /> Archive
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
