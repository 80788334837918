import {ChangeEvent, FC, useCallback} from "react";
import {Redirect, Route, useHistory, useParams, useRouteMatch,} from "react-router-dom";
import {Divider, Tab, Tabs} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import SettingsInputComponentOutlinedIcon from "@mui/icons-material/SettingsInputComponentOutlined";
import {ThemeProvider} from "@mui/material/styles";
import RunSection from "./RunSection";
import SettingSection from "./SettingsSection";
import OverviewSection from "./OverviewSection";
import DocumentationSection from "./DocumentationSection";
import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";
import {useBoard, useInstallableBoards} from "../../services/boards";
import BoardHeader from "./BoardHeader";
import {useRouteMetrics} from "../../hooks/metrics/routeEvents";
import UpgradeBar from "./UpgradeBar";
import {InstallableCircuitboardVersion} from "../../types";
import {sortByCreatedAt} from "../../utils";
import FlowHeader from "../Flow/FlowHeader";
import themev2 from "../../themev2";

const tabStyle = {
    width: 175,
};

const tabIconStyle = {
    verticalAlign: "middle",
    fontSize: 17,
    marginBottom: "3px",
    marginRight: "8px",
};

enum PageSections {
  Overview = "overview",
  Documentation = "documentation",
  Runs = "runs",
  Settings = "settings",
}

const Overview: FC = () => {
  const { boardId }: { boardId?: any; section?: any } = useParams();
  const history = useHistory();
  const handleTabChange = (
    _event: ChangeEvent<object>,
    newValue: PageSections
  ) => {
    history.push(newValue);
  };

  const { data: board, error: boardError } = useBoard(boardId);

  const { url, path } = useRouteMatch();
  useRouteMetrics(`${path}/:section`, {
    scope: ["board"],
    boardId,
    boardTemplateId: board?.template_id,
  });

  const { data: installableCircuitboards } = useInstallableBoards();

  const getAvailableBoardVersions: () => InstallableCircuitboardVersion[] =
    useCallback(() => {
      if (installableCircuitboards && board) {
        return sortByCreatedAt(
          installableCircuitboards.find(
            (installableCircuitboard) =>
              installableCircuitboard.id === board.template_id
          )?.versions || []
        );
      }

      return [];
    }, [installableCircuitboards, board]);

  const boardVersions = getAvailableBoardVersions();
  const latestVersion = boardVersions[0]?.version;
  const currentVersion = board?.version;
  const isNewVersion = latestVersion !== currentVersion;

  const latestMajorVersion = latestVersion?.split(".")[0];
  const currentMajorVersion = currentVersion?.split(".")[0];
  const isBreakingChanges = latestMajorVersion !== currentMajorVersion;

  const { localStorage } = window;
  const wasDismissed = localStorage.getItem(
    `upgradeVersion-${board?.identifier}`
  );
  const isDismissedVersion = latestVersion === wasDismissed;

  return (
    <>
      {board?.flow?.id ? (
        <ThemeProvider theme={themev2}>
          <FlowHeader flowId={board.flow.id} block={board} />
        </ThemeProvider>
      ) : (
        <BoardHeader />
      )}
      <UpgradeBar
        isNewVersion={isNewVersion}
        isDismissedVersion={isDismissedVersion}
        version={latestVersion}
        board={board?.identifier || ""}
      />
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={history.location.pathname.split("/").slice(0, 4).join("/")}
        onChange={handleTabChange}
      >
        <Tab
          value={`${url}/${PageSections.Overview}`}
          label={
            <div>
              <VisibilityOutlinedIcon sx={tabIconStyle} />
              Overview
            </div>
          }
          sx={tabStyle}
        />
        <Tab
          value={`${url}/${PageSections.Documentation}`}
          label={
            <div>
              <DescriptionOutlinedIcon sx={tabIconStyle} />
              Documentation
            </div>
          }
          sx={tabStyle}
        />
        <Tab
          value={`${url}/${PageSections.Runs}`}
          label={
            <div>
              <AccountTreeOutlinedIcon sx={tabIconStyle} />
              Runs
            </div>
          }
          sx={tabStyle}
        />
        <Tab
          value={`${url}/${PageSections.Settings}`}
          label={
            <div>
              <SettingsInputComponentOutlinedIcon sx={tabIconStyle} />
              Settings
            </div>
          }
          sx={tabStyle}
        />
      </Tabs>
      <Divider />
      {boardError && (
        <IllustrationPlaceholder
          text={boardError.message}
          imageHeight={300}
          imageWidth={300}
        />
      )}
      {board && (
        <>
          <Route path={url} exact>
            <Redirect to={`${PageSections.Overview}`} />
          </Route>
          <Route path={`${url}/${PageSections.Overview}`}>
            <OverviewSection boardId={boardId} />
          </Route>
          <Route path={`${url}/${PageSections.Documentation}`}>
            <DocumentationSection board={board} />
          </Route>
          <Route path={`${url}/${PageSections.Runs}`} exact>
            {board && <RunSection board={board} />}
          </Route>
          <Route path={`${url}/${PageSections.Settings}`}>
            <SettingSection
              circuitboardId={boardId}
              isBreakingChanges={isBreakingChanges}
            />
          </Route>
        </>
      )}
    </>
  );
};

export default Overview;
