import { useAuthUserOrNull } from "@frontegg/react";
import { ReactNode, useEffect, useMemo } from "react";
import { Amplitude, AmplitudeProvider } from "react-amplitude-hooks";
import amplitude from "amplitude-js";
import { useUserTenant } from "./hooks";

const amplitudeInstance = amplitude.getInstance();
const AMPLITUDE_KEY = import.meta.env.VITE_AMPLITUDE_KEY || "";

const EventsProvider = ({ children }: { children: ReactNode }) => {
  const user = useAuthUserOrNull();
  const tenant = useUserTenant(user || undefined);
  const userId = useMemo(() => user?.id, [user]);
  useEffect(() => {
    if (userId) {
      amplitudeInstance.setUserId(userId);
    }
  }, [userId]);
  return (
    <AmplitudeProvider
      amplitudeInstance={amplitudeInstance}
      apiKey={AMPLITUDE_KEY}
    >
      <Amplitude
        userProperties={{
          tenant_id: user?.tenantId,
          user_email: user?.email,
          user_id: userId,
          host: window.location.host,
          tenant_name: tenant?.name,
        }}
      >
        {children}
      </Amplitude>
    </AmplitudeProvider>
  );
};

export default EventsProvider;
