import {Button, Divider, Stack, ThemeProvider, Typography,} from "@mui/material";
import themev2 from "../../../../themev2";
import {FiltersSelect} from "../../../EcommerceProducts/Amazon/FiltersSelect";
import useProductsFilters from "../../../../services/ecommerce/useProductsFilters";
import ProductsTable from "../../../EcommerceProducts/Amazon/ProductsTable";
import {useEcommerceProducts} from "../../../../services/ecommerce";
import {ProductsFilter} from "../../../../types/ecommerce";
import {ConnectionConfigurationProps} from "../types";
import {FC, useEffect, useState} from "react"

const allProductsFilter = {
  id: "ALL_PRODUCTS",
  name: "All products",
  configuration: {},
};
export const AmazonConnectionConfig: FC<ConnectionConfigurationProps> = ({
                                                                           inputValue,
                                                                           setInputValue,
                                                                           onCompletion,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<ProductsFilter | null>(
    allProductsFilter
  );
  const productsFiltersQuery = useProductsFilters();
  const ecommerceProductsQuery = useEcommerceProducts(
    0,
    50,
    {},
    selectedFilter?.configuration || {}
  );
  useEffect(() => {
    setInputValue({
      ...inputValue,
      configuration: {
        filter_id: ecommerceProductsQuery?.data?.total
          ? selectedFilter?.id
          : undefined,
      },
    });
  }, [ecommerceProductsQuery?.data?.total]);
  return (
    <ThemeProvider theme={themev2}>
      <Stack spacing={3}>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="h2_v2">Pick a list</Typography>
          <FiltersSelect
            disableClearable={false}
            value={
              selectedFilter && {
                label:
                  selectedFilter?.name +
                  (selectedFilter && ecommerceProductsQuery.data
                    ? ` (${ecommerceProductsQuery.data?.total})`
                    : ""),
                filter: selectedFilter || undefined,
              }
            }
            filters={[allProductsFilter, ...(productsFiltersQuery.data || [])]}
            isLoading={productsFiltersQuery.isLoading}
            onChange={(_e, option: any) => {
              setSelectedFilter(option?.filter);
            }}
          />
          <Button variant="contained" onClick={onCompletion}>
            Apply
          </Button>
        </Stack>
        <Divider />
        <ProductsTable
          isLoading={ecommerceProductsQuery.isLoading}
          error={ecommerceProductsQuery.error}
          onNextPage={() => {}}
          data={ecommerceProductsQuery.data}
        />
      </Stack>
    </ThemeProvider>
  );
};

export default AmazonConnectionConfig;
