import { FC } from "react";
import { Stack } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { LoadableText } from "./LoadableText";

export const CreatedAt: FC<{ createdAt?: string }> = ({ createdAt }) => (
  <Stack alignItems="center" spacing={1} direction="row">
    <CalendarTodayIcon fontSize="small" />
    <LoadableText noWrap maxWidth={150} minWidth={50}>
      {createdAt}
    </LoadableText>
  </Stack>
);
