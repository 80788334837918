import { Box, Tab, Tabs } from "@mui/material";
import { FC } from "react";

import { ChangeEvent } from "react";
import SVG from "react-inlinesvg";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { PageSections } from ".";
import { Tag } from "../../components/Tag/Tag";

export const WorkspaceTabs: FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const handleTabChange = (
    _event: ChangeEvent<object>,
    newValue: PageSections
  ) => {
    history.push(newValue);
  };

  return (
    <Tabs
      value={path}
      onChange={handleTabChange}
      aria-label="basic tabs example"
    >
      <Tab label="BLOCKS" value={`/${PageSections.Block}`} />
      <Tab
        value={`/${PageSections.Flow}`}
        label={
          <Box>
            FLOWS{" "}
            <Tag
              text="NEW"
              icon={<SVG height="16px" src="/new-feature-icon.svg" />}
            />
          </Box>
        }
      />
    </Tabs>
  );
};
