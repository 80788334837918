import { FC } from "react";
import {
  ListItemButton,
  ListItemButtonProps,
  Paper,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";

const CreateDestinationListItem: FC<Partial<ListItemButtonProps>> = (props) => (
  <ListItemButton
    sx={{
      display: "flex",
      justifyContent: "center",
    }}
    {...props}
  >
    <Paper
      elevation={3}
      sx={{ borderRadius: "50%", display: "flex", marginRight: 1 }}
    >
      <Add />
    </Paper>
    <Typography variant="body1">More options</Typography>
  </ListItemButton>
);

export default CreateDestinationListItem;
