import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { ChartsTypes } from "../types";
import theme from "../../../themes/light";
import getChartOptions from "./ChartOptions";
import TableView from "./TableView";
import "./Charts.css";

export type StoryPointGraphProps = {
  graph_data: {
    graph_type: string;
    data: any;
    series: { name: string; data: any[]; type: any }[];
    categories: string[];
    legend?: { [key: string]: string };
    graph_title?: string;
    axis?: { x: string; y: any[] };
  };
  schema: any;
};

const StoryPointGraph = ({ graph_data, schema }: StoryPointGraphProps) => {
  return graph_data.graph_type === "table" ? (
    <TableView data={graph_data.data} schema={schema} />
  ) : (
    <Box
      id="area-chart"
      sx={{
        background: theme.palette.grey[25],
        borderRadius: "12px",
        padding: "17px 20px 0px 20px",
      }}
    >
      <ReactApexChart
        // @ts-ignore
        options={getChartOptions(graph_data)}
        series={graph_data.series}
        type={graph_data.graph_type as unknown as ChartsTypes}
        height={430}
      />
    </Box>
  );
};

export default StoryPointGraph;
