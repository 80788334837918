import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTenantsState } from "@frontegg/react-hooks/auth";
import { useMutation } from "react-query";
import { useConfirmation } from "../../components/ConfirmationDialog";
import {
  createTenant,
  deleteTenant,
} from "../../services/tenants/useTenantConfiguration";
import { PlatformPermission } from "../../enums";
import { ProtectedComponentWithRoles } from "../../components/ProtectedRoute";
import { FC } from "react";

export const DeleteTenantForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmation();
  const mutation = useMutation(deleteTenant);

  const {
    formState: { isValid, isSubmitting },
    control,
    handleSubmit,
    getValues,
  } = useForm<{ tenantId: string }>({
    mode: "onChange",
  });
  return (
    <>
      <Typography variant="h5" mr={1}>
        Delete Tenant
      </Typography>
      <Controller
        name="tenantId"
        rules={{ required: true }}
        control={control}
        render={({ field }) => <TextField placeholder="Tenant id" {...field} />}
      />
      <Button
        disabled={isSubmitting || !isValid}
        color="error"
        startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
        onClick={() => {
          confirm({
            title: `You are about to delete Tenant id: ${
              getValues().tenantId
            } permanently`,
            description: "Are you sure you want to proceed?",
          }).then(() => {
            handleSubmit(
              (data) =>
                mutation
                  .mutateAsync(data.tenantId)
                  .then((tenant) =>
                    enqueueSnackbar(
                      `Tenant ${tenant.tenantId} deleted successfully.`,
                      {
                        variant: "success",
                      }
                    )
                  )
                  .catch((e) => {
                    enqueueSnackbar(`Failed to delete tenant ${e}`, {
                      variant: "error",
                    });
                  }),
              () => {
                enqueueSnackbar("Tenant id is required.", { variant: "error" });
              }
            )();
          });
        }}
      >
        Delete
      </Button>
    </>
  );
};

export const CreateTenantForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(createTenant);
  const {
    formState: { isValid, isSubmitting },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<{ tenantName: string }>({
    mode: "onChange",
  });
  return (
    <>
      <Typography variant="h5" mr={1}>
        Create New Tenant
      </Typography>
      <Controller
        name="tenantName"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <TextField
            placeholder="Tenant name"
            {...field}
            value={field?.value}
          />
        )}
      />
      <Button
        startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
        disabled={isSubmitting || !isValid}
        onClick={handleSubmit(
          async (data) =>
            mutation
              .mutateAsync(data.tenantName)
              .then((tenant) => {
                reset();
                setValue("tenantName", "");
                enqueueSnackbar(`Tenant ${tenant.name} created successfully.`, {
                  variant: "success",
                });
              })
              .catch((e) => {
                reset();
                setValue("tenantName", "");
                enqueueSnackbar(`Failed to create new tenant ${e}`, {
                  variant: "error",
                });
              }),
          () =>
            enqueueSnackbar("Tenant name is required.", { variant: "error" })
        )}
      >
        Create
      </Button>
    </>
  );
};
export const Actions: FC = () => {
  const { tenants: tenantsFromState } = useTenantsState();

  return (
    <ProtectedComponentWithRoles
      permissions={[PlatformPermission.TENANT_ADMIN_CREATE]}
    >
      <Box flex={1} mt={4} mb={4}>
        <Container
          maxWidth="lg"
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack spacing={1} mb={4}>
            <Typography variant="body1">
              Active tenants you assign to: {tenantsFromState?.length}
            </Typography>
            <CreateTenantForm />
            <DeleteTenantForm />
          </Stack>
        </Container>
      </Box>
    </ProtectedComponentWithRoles>
  );
};

export default Actions;
