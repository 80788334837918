import { Box, Stack } from "@mui/material";
import theme from "../../themes/light.ts";
import CatalogEnrichmentHeader from "./CatalogEnrichmentHeader.tsx";
import { FC } from "react";
import CatalogEnrichmentPanel from "./CatalogEnrichmentPanel.tsx";
import CatalogEnrichmentSidebar from "./CatalogEnrichmentSidebar.tsx";

const CatalogEnrichmentContainer: FC = () => {
  return (
    <Stack height={"100vh"} sx={{ background: theme.palette.grey[50] }}>
      <CatalogEnrichmentHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "auto",
          height: "100%",
          width: "100%",
        }}
      >
        <CatalogEnrichmentSidebar />
        <CatalogEnrichmentPanel />
      </Box>
      {/* <CatalogEnrichmentList /> */}
    </Stack>
  );
};

export default CatalogEnrichmentContainer;
