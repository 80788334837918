import {Menu} from "@mui/material";
import {Run} from "../../types";
import {ArchiveMenuItem, CloneMenuItem, InspectMenuItem, ViewRunDataMenuItem, ViewRunMenuItem,} from "./RunMenuItems";
import {RunMenuItemProps} from "./RunMenuItems/types";
import {FC} from "react"

const RunMenu: FC<{
    run: Run | null;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}> = ({run, anchorEl, onClose}) => {
    const boardId = run?.circuitboard_id;
    const runId = run?.id;

    const baseMenuItemProps =
    boardId &&
    runId &&
    ({
      boardId,
      runId: run?.id,
      onClick: onClose,
    } as RunMenuItemProps);

  return baseMenuItemProps ? (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <ViewRunMenuItem {...baseMenuItemProps} />
        <ViewRunDataMenuItem {...baseMenuItemProps} />
        <CloneMenuItem {...baseMenuItemProps} />
        <ArchiveMenuItem {...baseMenuItemProps} />
        <InspectMenuItem kfpRunId={run?.kfp_run_id} onClick={onClose} />
      </Menu>
    </>
  ) : null;
};

export default RunMenu;
