import { PREMIUM } from "../pages/MarketplaceV2";

const POLICY_DELIMITER = ":";

export type Policy = {
  predicate: string;
  resource: string;
  tag: string;
};

export type LibraryFilter = {
  library: string;
  tags: string[];
};
export type BlockFilter = {
  id: string;
  slug?: string;
  tags: string[];
};

export const parsePolicy = (policy: string): Policy | null => {
  const policy_parts = policy.split(POLICY_DELIMITER);
  if (policy_parts.length > 3 || policy_parts.length < 2) {
    return null;
  }
  return {
    predicate: policy_parts[0],
    resource: policy_parts[1],
    tag: policy_parts[2] || "",
  };
};

export const filterByLibraryPolicy = (policy: Policy, value: LibraryFilter) => {
  const isTagValid =
    (!policy.tag && !value.tags.includes(PREMIUM)) || policy.tag === PREMIUM;
  return (
    policy.predicate === "library" &&
    policy.resource.toLocaleLowerCase() === value.library.toLocaleLowerCase() &&
    isTagValid
  );
};

export const filterByBlockPolicy = (policy: Policy, value: BlockFilter) =>
  policy.predicate === "block" &&
  (policy.resource === value.id || policy.resource === value.slug);

export const verifyPolicies = (
  value: any,
  policies: Policy[],
  filters: ((policy: Policy, value: any) => boolean)[]
) => policies.some((p) => filters.some((f) => f(p, value)));
