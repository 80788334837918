import { Box, Skeleton, useTheme } from "@mui/material";

const ChatQuestionsSkeleton = ({ questionsNum }: any) => {
  const theme = useTheme();

  return [...Array(questionsNum || 6).keys()].map((_, idx: number) => (
    <Box
      key={`ske-${idx}`}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
        padding: "12px",
        borderRadius: "12px",
        boxShadow:
          "0px 8px 20px -6px rgba(44, 34, 76, 0.05), 0px 12px 45px -4px rgba(44, 34, 76, 0.05)",
        background: theme.palette.primary.contrastText,
        outline: "1px solid rgba(0, 0, 0, 0.10)",
        height: "75px",
        width: "350px",
      }}
    >
      <Skeleton
        animation="wave"
        sx={{
          width: "85px",
          height: "50px",
          transform: "unset",
          borderRadius: "8px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          width: "90%",
        }}
      >
        <Skeleton
          animation="wave"
          sx={{
            height: "10px",
            width: "100%",
            borderRadius: "8px",
            transform: "unset",
          }}
        />
        <Skeleton
          animation="wave"
          sx={{
            height: "10px",
            width: "100%",
            borderRadius: "8px",
            transform: "unset",
          }}
        />
      </Box>
    </Box>
  ));
};

export default ChatQuestionsSkeleton;
