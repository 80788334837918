import { TenantStatus } from "../../types/tenants.ts";
import apiRequest, { APIError } from "../../services/apiRequest.ts";
import { useQuery } from "react-query";
import { useUserTenant } from "../../hooks";
import { useAuthUserOrNull } from "@frontegg/react";
import {
  Box,
  Container,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../themes/light.ts";
import RefreshIcon from "@mui/icons-material/Refresh";
import { UpdateFlowViewsButton } from "../Flow/UpdateFlowViewsButton.tsx";
import { DeployFlowButton } from "../Flow/DeployFlowButton.tsx";
import { DeployDigitalShelfFlowV2Button } from "../Flow/DeployDigitalShelfFlowV2Button.tsx";
import ToggleConfig from "./ToggleConfig.tsx";
import { useTenantConfiguration } from "../../services/tenants/useTenantConfiguration.ts";

async function getTenantStatus(tenantId: string): Promise<TenantStatus> {
  return await apiRequest<TenantStatus>(
    `tenants/configuration/${tenantId}/status`,
    "GET",
  );
}

function useTenantStatus({ tenantId }: { tenantId?: string }) {
  return useQuery<TenantStatus, APIError>(
    ["useTenantStatus", tenantId],
    () => getTenantStatus(tenantId!),
    { enabled: !!tenantId },
  );
}

const Status = () => {
  const user = useAuthUserOrNull();
  const tenant = useUserTenant(user || undefined);

  const { data, isLoading, refetch, isFetching } = useTenantStatus({
    tenantId: tenant?.tenantId,
  });

  const { data: tenantConfig } = useTenantConfiguration(
    tenant?.tenantId || "",
    { enabled: !!tenant?.tenantId },
  );
  return (
    <>
      {(isLoading || isFetching) && <LinearProgress />}
      <Box
        flex={1}
        pt={4}
        pb={4}
        bgcolor={theme.palette.grey[50]}
        overflow="auto"
      >
        <Container
          maxWidth="lg"
          sx={{
            flex: 1,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack direction="row" gap={5} mb={2}>
            <UpdateFlowViewsButton />
            <DeployFlowButton />
            <DeployDigitalShelfFlowV2Button />
          </Stack>
          {data?.id && (
            <TableContainer component={Paper}>
              <Table aria-label="status table">
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ width: "250px" }}>
                    Tenant id
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={4}>
                      <Typography variant="body1" fontWeight="bold">
                        {data?.id}
                      </Typography>
                      <IconButton
                        sx={{
                          "@keyframes rotate": {
                            "0%": {
                              transform: "rotate(0deg)",
                            },
                            "50%": {
                              transform: "rotate(180deg)",
                            },
                            "100%": {
                              transform: "rotate(360deg)",
                            },
                          },
                          animation: isFetching
                            ? "rotate 2s ease infinite"
                            : "",
                        }}
                        disabled={isFetching}
                        aria-label="refresh"
                        onClick={() => {
                          refetch();
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Tenant Name
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {data?.name}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Archived
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={4}>
                      <Typography variant="body1" fontWeight="bold">
                        {data?.archived ? (
                          <DoneIcon color="error" />
                        ) : (
                          <CloseIcon color="success" />
                        )}
                      </Typography>
                      <ToggleConfig
                        field="archived"
                        value={data?.archived}
                        tenant={tenant}
                        tenantConfig={tenantConfig}
                        refetch={refetch}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={2}
                    align="center"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={theme.palette.grey[300]}
                    >
                      Configuration
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Tenant type
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {data?.tenant_type}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has service account
                  </TableCell>
                  <TableCell>
                    {data?.has_warehouse_service_account ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has advertising data
                  </TableCell>
                  <TableCell>
                    {data?.has_advertising_data ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has sales assistant dashboard
                  </TableCell>
                  <TableCell>
                    {data?.has_sales_assistant_dashboard ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Uses new dashboards
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={4}>
                      <Typography variant="body1" fontWeight="bold">
                        {data?.use_new_dashboards ? (
                          <DoneIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </Typography>
                      <ToggleConfig
                        field="use_new_dashboards"
                        value={data?.use_new_dashboards}
                        tenant={tenant}
                        tenantConfig={tenantConfig}
                        refetch={refetch}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Uses new menu bar
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={4}>
                      <Typography variant="body1" fontWeight="bold">
                        {data?.use_new_menu_bar ? (
                          <DoneIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </Typography>
                      <ToggleConfig
                        field="use_new_menu_bar"
                        value={data?.use_new_menu_bar}
                        tenant={tenant}
                        tenantConfig={tenantConfig}
                        refetch={refetch}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={2}
                    align="center"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={4}
                      justifyContent="center"
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={theme.palette.grey[300]}
                      >
                        BigQuery
                      </Typography>
                      {/*<UpdateFlowViewsButton />*/}
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has all needed tables
                  </TableCell>
                  <TableCell>
                    {data?.has_all_needed_bq_tables ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has all needed views (flow_v2)
                  </TableCell>
                  <TableCell>
                    {data?.has_all_needed_bq_views ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={2}
                    align="center"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={theme.palette.grey[300]}
                    >
                      MySQL
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has spaces
                  </TableCell>
                  <TableCell>
                    {data?.has_spaces ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has all spaces impressions data
                  </TableCell>
                  <TableCell>
                    {data?.has_impressions_data ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Has keywords data
                  </TableCell>
                  <TableCell>
                    {data?.has_keywords_data ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Has sales assistant data
                  </TableCell>
                  <TableCell>
                    {data?.has_sales_assistant_data ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={2}
                    align="center"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={4}
                      justifyContent="center"
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={theme.palette.grey[300]}
                      >
                        Dashboards
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Competitors dashboard
                  </TableCell>
                  <TableCell>
                    {data?.has_competitors_dashboard ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Keywords dashboard
                  </TableCell>
                  <TableCell>
                    {data?.has_keywords_dashboard ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Market share dashboard
                  </TableCell>
                  <TableCell>
                    {data?.has_market_share_dashboard ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Share of voice dashboard
                  </TableCell>
                  <TableCell>
                    {data?.has_share_of_voice_dashboard ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sales assistant dashboard
                  </TableCell>
                  <TableCell>
                    {data?.has_sales_assistant_dashboard ? (
                      <DoneIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Status;
