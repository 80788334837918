import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import {Button, Tooltip} from "@mui/material";
import {FC} from "react"
import {Link} from "react-router-dom";
import {AuthorizedContent} from "../../../../components/AuthorizedContent";
import {PlatformPermission, Status} from "../../../../enums";
import {useBoardEvents} from "../../../../hooks/metrics/boardEvents";
import {useBoard} from "../../../../services/boards";
import {useRun} from "../../../../services/runs";
import {RunHeaderButtonProps} from "../types";

const AppButton: FC<Partial<RunHeaderButtonProps>> = ({
                                                        boardId = "",
                                                        runId = "",
                                                      }) => {
  const {data: board} = useBoard(boardId, {enabled: !!boardId});
  const {data: run} = useRun(boardId, runId, {
    enabled: !!(runId && boardId),
  });
  const {applicationLaunchedEvent} = useBoardEvents({
    boardId,
    boardTemplateId: board?.template_id,
  });
  const runStatusSuccess = run && run.status === Status.SUCCEEDED;
  const boardHasApps = board && board.applications.length > 0;
  const appButtonEnabled = runStatusSuccess && boardHasApps;
  return (
    <AuthorizedContent requiredPermissions={[PlatformPermission.APPS_GET]}>
      <Tooltip
        title={
          appButtonEnabled
            ? ""
            : "Disabled if run hasn't succeeded, or if board doesnt have an app"
        }
      >
        <span>
          <Button
            variant="contained"
            onClick={() => applicationLaunchedEvent({ from: "runPage" })}
            startIcon={<RocketLaunchIcon />}
            target="_blank"
            component={Link}
            disabled={!appButtonEnabled}
            to={`/apps/${boardId}/dashboard/#/?runId=${runId}`}
            color="primary"
            size="small"
            sx={{ minWidth: "137px", height: "40px" }}
          >
            Launch App
          </Button>
        </span>
      </Tooltip>
    </AuthorizedContent>
  );
};

export default AppButton;
