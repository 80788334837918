import { Box, Stack, Typography } from "@mui/material";
import { FC, useContext, useMemo, useState } from "react";
import theme from "../../../../themes/light";
import TableColumns from "./TableColumns";
import { TableColumn } from "../../types";
import TableRows from "./TableRows";
import Pagination from "./Pagination";
import { AssortmentIntelligenceContext } from "../../AssortmentIntelligenceProvider";
import {
  calculateHeatLevel,
  columnSort,
  isAttributeNumerical,
} from "../../utils";

interface TableProps {
  data: any[];
  schema: TableColumn[];
  defaultSortBy?: string;
  rowsPerPage?: number;
  variant?: "double" | "full" | "half";
  isHeatmap?: boolean;
}

const Table: FC<TableProps> = ({
  data,
  schema,
  defaultSortBy,
  rowsPerPage = 10,
  variant = "full",
  isHeatmap = false,
}) => {
  const { spaceAttributes } = useContext(AssortmentIntelligenceContext);

  const isColumnNumericalGroup = (column: string) => {
    if (column.toLowerCase().includes("group")) {
      const matchingAttribute = spaceAttributes.find(
        (attribute) =>
          attribute.attribute.toLowerCase() ===
          column.toLowerCase().replace(" group", "")
      );
      return matchingAttribute
        ? isAttributeNumerical(matchingAttribute)
        : false;
    }
    return false;
  };

  // Sort
  const [sortBy, setSortBy] = useState({
    column: defaultSortBy || schema[0]?.name,
    order: "desc",
  });

  const sortedData = useMemo(() => {
    if (!data) return [];
    return [...data].sort((a, b) =>
      columnSort(a, b, sortBy, isColumnNumericalGroup)
    );
  }, [data, sortBy]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const paginatedDataWithHeatLevels = useMemo(() => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    const paginatedData = [...sortedData].slice(start, end);

    if (!isHeatmap) {
      return paginatedData;
    }

    const globalMinMaxValues = sortedData.reduce(
      (acc, row) => {
        schema.forEach((column) => {
          const value = row[column.name];
          if (typeof value === "number") {
            acc.min = Math.min(acc.min, value);
            acc.max = Math.max(acc.max, value);
          }
        });
        return acc;
      },
      { min: Infinity, max: -Infinity }
    );

    if (globalMinMaxValues.min === globalMinMaxValues.max) {
      globalMinMaxValues.max = globalMinMaxValues.min + 1;
    }

    return paginatedData.map((row) => {
      const rowWithHeatLevels = { ...row };
      schema.forEach((column) => {
        const value = row[column.name];
        if (typeof value === "number") {
          const heatLevel = calculateHeatLevel(
            value,
            globalMinMaxValues.min,
            globalMinMaxValues.max
          );
          rowWithHeatLevels[`${column.name}_heatLevel`] = heatLevel;
        }
      });
      return rowWithHeatLevels;
    });

  }, [currentPage, sortedData, schema, isHeatmap]);

  // Schema with Product column
  const schemaToMap = useMemo(() => {
    return schema.some((column) => column.name.toLowerCase() === "asin") &&
      schema.some((column) => column.name.toLowerCase() === "image")
      ? [
          { name: "Product" },
          ...schema.filter(
            (column) =>
              column.name.toLowerCase() !== "asin" &&
              column.name.toLowerCase() !== "image"
          ),
        ]
      : schema;
  }, [schema]);

  return (
    <Stack
      sx={{
        background: theme.palette.backgrounds.secondary,
        padding: variant === "full" ? "12px 30px" : "10px 0 0 0",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflowX: "auto",
          "::-webkit-scrollbar": {
            height: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
          },
        }}
      >
        <Stack
          sx={{
            borderRadius: "8px",
            width:
              variant === "full" || paginatedDataWithHeatLevels.length === 0
                ? "100%"
                : "150%",
            gap:
              variant !== "half" && paginatedDataWithHeatLevels.length > 0
                ? "10px"
                : "4px",
            alignItems: "center",
          }}
        >
          <TableColumns
            columns={schemaToMap as TableColumn[]}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
          {paginatedDataWithHeatLevels.length > 0 ? (
            <TableRows
              data={paginatedDataWithHeatLevels}
              schema={schemaToMap as TableColumn[]}
            />
          ) : (
            <Typography
              variant="body4"
              sx={{
                padding: "20px",
              }}
            >
              No Results Found
            </Typography>
          )}
        </Stack>
      </Box>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      )}
    </Stack>
  );
};

export default Table;
