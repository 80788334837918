import {Skeleton, TableCell, TableRow} from "@mui/material";
import {FC} from "react"

const SkeletonRow: FC<{ index: number }> = ({index}) => (
    <TableRow key={index}>
        <TableCell component="th" scope="row">
            <Skeleton variant="text"/>
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text"/>
        </TableCell>
    </TableRow>
);

export default SkeletonRow;
