import { FC, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { Box, LinearProgress, List, Stack, useTheme } from "@mui/material";
import { useQueryParams } from "../../hooks";
import { useSalesAssistantStyles } from "./useSalesAssistantStyles";
import { Header } from "./Header";
import { SalesDrawer } from "./List/Drawer/SalesDrawer";
import { SalesQueryParams } from "./constants";
import { InsightsList } from "./List/List";
import SalesFilterList from "./List/SalesFilterList";
import { useSalesContext } from "./hooks/selectors";
import { SalesListingHead } from "./List/SalesListingHead";
import CustomPagination from "./Pagination";

export const SalesAssistant: FC = () => {
  const history = useHistory();

  const queryParams = useQueryParams();
  const { insightsBody } = useSalesAssistantStyles();
  const { mainList } = useSalesAssistantStyles();
  const theme = useTheme();
  const {
    selectedProductGroup,
    setAllProductTab,
    producGroupData,
    isFetchingInsights,
    isFetchingProductGroup,
    activeTab,
    setActiveTab,
  } = useSalesContext();
  const location = useLocation();

  // const [activeTab, setActiveTab] = useState<string>("groups");
  // const groupId = queryParams.get("groupId") || "";
  const isGroupPage = location.pathname === "/sales-assistant/home";
  const listStylesOverrides = {
    "& .MuiListItem-root": {
      width: "100%",
      cursor: "pointer",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
  };

  useEffect(() => {
    if (
      producGroupData &&
      producGroupData.length === 1 &&
      selectedProductGroup !== null &&
      activeTab === "groups"
    ) {
      setActiveTab("all-products");
      localStorage.setItem("activeTabPanel", "all-products");
    }
  }, [producGroupData?.length, selectedProductGroup]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    if (producGroupData.length === 1) {
      return;
    }
    setActiveTab(newValue);
    localStorage.setItem("activeTabPanel", newValue);
    setAllProductTab(newValue === "all-products");
  };
  const allProductsCount = () => {
    const allProducts = producGroupData?.find(
      (item: any) => item?.title === "All products",
    );
    return allProducts ? allProducts.asins : null;
  };

  return (
    <>
      <Stack className={insightsBody} data-id="test">
        <Header />
        {(isFetchingInsights || isFetchingProductGroup) && <LinearProgress />}
        <Box
          sx={{
            overflow: "hidden",
            background: theme.palette.backgrounds.secondary,
            flex: 1,
            paddingTop: isGroupPage ? "10px" : "28px",
          }}
        >
          <List
            className={mainList}
            data-testid="insights-list"
            key="boo"
            sx={{
              minHeight: "100%",
              paddingTop: 0,
              ...listStylesOverrides,
              background: theme.palette.backgrounds.secondary,
            }}
          >
            <Stack
              sx={{
                maxWidth: theme.spacing(1350 / 8),
                marginLeft: "auto",
                marginRight: "auto",
                height: "inherit",
              }}
            >
              <SalesListingHead
                onTabChange={handleTabChange}
                activeTab={activeTab}
              />
              {isGroupPage ? (
                activeTab === "groups" ? (
                  <SalesFilterList />
                ) : (
                  <InsightsList />
                )
              ) : (
                <InsightsList />
              )}
            </Stack>
          </List>
        </Box>
        {queryParams.has(SalesQueryParams.InsightId) && (
          <SalesDrawer
            onClose={() => {
              queryParams.delete(SalesQueryParams.InsightId);
              history.replace({
                search: queryParams.toString(),
              });
            }}
            open={queryParams.has(SalesQueryParams.InsightId)}
          />
        )}
      </Stack>

      {activeTab === "all-products" ? (
        <CustomPagination asinsCount={allProductsCount()} />
      ) : null}
    </>
  );
};
