import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InboxIcon from "@mui/icons-material/Inbox";
import { Box, Chip, Fab, Stack, ThemeProvider } from "@mui/material";
import { FC, useState } from "react";

import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import dayjs from "../../../../utils";
import Header, {
  HeaderActions,
  HeaderContent,
} from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import { useBoard } from "../../../../services/boards";
import { useRun } from "../../../../services/runs";
import theme from "../../../../themev2";
import Actions from "../Actions";
import EditRunName from "../EditRunName";
import { Version } from "./Version";
import { CreatedAt } from "./CreatedAt";
import { CreatedTime } from "./CreatedTime";
import { EditRun } from "./EditRun";
import { CreatedByUser } from "./CreatedByUser";
import RunStatus from "./RunStatus";
import { CopyRunUrl } from "./CopyRunUrl";

const RunHeader: FC = () => {
  const history = useHistory();
  const { boardId, runId }: { boardId?: any; runId?: any } = useParams();
  const { data: board } = useBoard(boardId);
  const { data: run } = useRun(boardId, runId, {
    enabled: !!(boardId && runId),
  });

  const createdAt = run?.created_at
    ? dayjs(run?.created_at).utc(true).local()
    : null;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header>
          <Stack direction="row" display="flex" sx={{ alignItems: "center" }}>
            <Fab
              onClick={() => history.push(`/boards/${board?.identifier}`)}
              size="small"
              sx={{
                bgcolor: "grey.100",
                "&:hover": {
                  bgcolor: "grey.200",
                },
                mr: 2,
                ml: 2,
                height: 40,
                width: 40,
                boxShadow: 0,
              }}
            >
              <ChevronLeftIcon sx={{ color: "common.black" }} />
            </Fab>
            <HeaderContent>
              {open ? (
                <EditRunName
                  onClose={handleClose}
                  runId={runId}
                  boardId={boardId}
                />
              ) : (
                <HeaderTitle title={run?.name}>
                  <>
                    {run && (
                      <RunStatus run={run} boxProps={{ sx: { ml: 2 } }} />
                    )}
                    {run?.archived && (
                      <Box marginLeft={2}>
                        <Chip
                          label="Archived"
                          icon={<InboxIcon fontSize="small" />}
                          sx={{
                            height: "32px",
                            width: "100px",
                            borderRadius: "4px",
                            backgroundColor: "grey.200",
                            color: "grey.600",
                          }}
                        />
                      </Box>
                    )}
                  </>
                </HeaderTitle>
              )}

              <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{ mt: 1.5 }}
              >
                <CreatedByUser run={run} />
                <CreatedTime
                  createdTime={String(createdAt?.format("HH:mm:ss"))}
                />
                <CreatedAt
                  createdAt={String(createdAt?.format("YYYY-MM-DD"))}
                />
                <Version version={run?.board_version} />
                <EditRun onClick={handleClickOpen} />
                {run && <CopyRunUrl run={run} />}
              </Stack>
            </HeaderContent>
          </Stack>
          <HeaderActions>
            <Actions boardId={boardId} runId={runId} />
          </HeaderActions>
        </Header>
      </ThemeProvider>
    </>
  );
};

export default RunHeader;
