import { useDropzone } from "react-dropzone";
import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { UploadIcon } from "../../assets";

export const LoadLocalCsvFile: FC<{
  onFileDropped: (e: File) => void;
}> = ({ onFileDropped }) => {
  const onDrop = (acceptedFiles: File[]) => {
    onFileDropped(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [".csv"],
    maxFiles: 1,
  });

  return (
    <Paper
      variant="outlined"
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        border: (t) => `2px dashed ${t.palette.borders?.default}`,
        height: (t) => t.spacing(17.5),
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Stack direction="row" alignItems="center" spacing={1}>
        <UploadIcon />
        <Typography variant="body3">
          <Typography
            variant="body3"
            color={(theme) => theme.palette.primary.main}
          >
            Upload
          </Typography>{" "}
          or drop a CSV file here{" "}
        </Typography>
      </Stack>
    </Paper>
  );
};
