import { Box, Chip, Typography } from "@mui/material";
import { FC } from "react";
import theme from "../../../themes/light";

interface BrandedFilterProps {
  brandedOptionsList: any;
  selectedBranded: string;
  setSelectedBranded: any;
}

const BrandedFilter: FC<BrandedFilterProps> = (props: BrandedFilterProps) => {
  const { brandedOptionsList, selectedBranded, setSelectedBranded } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontSize: "14px",
          color: theme.palette.grey[400],
          display: "block",
        }}
      >
        Branded/Unbranded
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
        }}
      >
        {brandedOptionsList.map((op: any) => {
          const isCheck = selectedBranded === op.value;
          return (
            <Chip
              key={`brandType-${op.value}`}
              label={op.label}
              sx={{
                height: "24px",
                background: isCheck ? "#EEE5FE !important" : "transparent",
                "& .MuiChip-label": {
                  color: isCheck
                    ? "#5C30A4 !important"
                    : theme.palette.grey[500],
                },
              }}
              onClick={() => setSelectedBranded(op.value)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default BrandedFilter;
