import { FC } from "react";
import { User } from "@frontegg/redux-store";
import { JSONSchema4 } from "json-schema";
import { AmazonAdsIcon, AmazonAdsImg } from "../pages/DataSource/AmazonAdsIcon";
import { AmazonIcon, AmazonImg } from "../pages/DataSource/AmazonIcon";
import ShopifyClientAppDSForm from "../pages/DataSource/ShopifyClientAppForm";
import ShopifyDSForm from "../pages/DataSource/ShopifyDSForm";
import { ShopifyIcon, ShopifyImg } from "../pages/DataSource/ShopifyIcon";
import WalmartForm from "../pages/DataSource/WalmartForm";
import { WalmartIcon, WalmartImg } from "../pages/DataSource/WalmartIcon";

export enum DataSourceType {
  AMAZON_NORTH_AMERICA = "amazon_north_america",
  SHOPIFY_PUBLIC = "shopify_public",
  AMAZON_ADS_NORTH_AMERICA = "amazon_ads_north_america",
  SHOPIFY = "shopify",
  WALMART = "walmart_seller_central",
}

export type DataSourceOption = {
  type: DataSourceType;
  title: string;
  input_schema: JSONSchema4;
};

const shopifyClientId = import.meta.env.VITE_SHOPIFY_CLIENT_ID;
const shopifyRedirectUri = `${window.location.protocol}//${window.location.host}/api/v1/datasources/oauth/shopify`;

const amazonAdsClientId = import.meta.env.VITE_AMAZON_ADS_CLIENT_ID;
const amazonAdsBaseUrl = "https://www.amazon.com/ap/oa";
const amazonAdsRedirectUrl = `${window.location.protocol}//${window.location.host}/api/v1/datasources/oauth/amazon_ads_north_america`;

const vendorBaseUrl = "https://vendorcentral.amazon.com/apps/authorize/consent";
const amazonSapiBaseUrl =
  "https://sellercentral.amazon.com/apps/authorize/consent";
const applicationId = import.meta.env.VITE_AMAZON_SPAPI_APPLICATION_ID;
const amazonRedirectUrl = `${window.location.protocol}//${window.location.host}/api/v1/datasources/oauth/amazon_north_america`;
const oauthLink = `application_id=${applicationId}&redirect_uri=${amazonRedirectUrl}`;
export const createAmazonOauthLink = ({
  isSeller,
  state,
}: {
  isSeller: boolean;
  state: string;
}) =>
  `${
    isSeller ? amazonSapiBaseUrl : vendorBaseUrl
  }?${oauthLink}&state=${state}&version=beta`;
export const createAmazonAdsOauthLink = ({ state }: { state: string }) => {
  const AmazonAdsOauthLink = `client_id=${amazonAdsClientId}&scope=advertising::campaign_management&response_type=code&state=${state}&redirect_uri=${amazonAdsRedirectUrl}`;
  return `${amazonAdsBaseUrl}?${AmazonAdsOauthLink}`;
};

export const generateShopBaseUrl = (shopName: string) =>
  `https://${shopName}.myshopify.com`;
export const createShopifyPushLink = ({
  shopName,
  state,
}: {
  shopName: string;
  state: string;
}) =>
  [
    generateShopBaseUrl(shopName),
    `admin/oauth/authorize?client_id=${shopifyClientId}&scope=write_orders,write_products,write_reports,write_inventory&state=${state}&redirect_uri=${shopifyRedirectUri}`,
  ].join("/");

export type FormCompProps = {
  onChange: (props: { isValid: boolean; data: Record<string, string> }) => void;
};

export type DataSourceBox = {
  Icon: FC<any>;
  BoxedIcon: FC<any>;
  oauthurlGen: (a: any) => string;
  Form?: FC<any>;
  pretty: string;
};

export const dataSources: Record<DataSourceType, DataSourceBox> = {
  [DataSourceType.AMAZON_NORTH_AMERICA]: {
    Icon: AmazonImg,
    BoxedIcon: AmazonIcon,
    oauthurlGen: createAmazonOauthLink,
    pretty: "Amazon",
  },
  [DataSourceType.AMAZON_ADS_NORTH_AMERICA]: {
    Icon: AmazonAdsImg,
    BoxedIcon: AmazonAdsIcon,
    oauthurlGen: createAmazonAdsOauthLink,
    pretty: "Amazon Ads",
  },
  [DataSourceType.SHOPIFY_PUBLIC]: {
    Icon: ShopifyImg,
    BoxedIcon: ShopifyIcon,
    oauthurlGen: createShopifyPushLink,
    Form: ShopifyDSForm,
    pretty: "Shopify - Public",
  },
  [DataSourceType.SHOPIFY]: {
    Icon: ShopifyImg,
    BoxedIcon: ShopifyIcon,
    oauthurlGen: createShopifyPushLink,
    Form: ShopifyClientAppDSForm,
    pretty: "Shopify",
  },
  [DataSourceType.WALMART]: {
    Icon: WalmartImg,
    BoxedIcon: WalmartIcon,
    oauthurlGen: createShopifyPushLink,
    Form: WalmartForm,
    pretty: "Walmart",
  },
};

export type DataSource = {
  id: string;
  name: string;
  description: string;
  configuration: Record<string, string>;
  type: DataSourceType;
  created_by: Partial<User>;
  updated_at: string;
};

export type CreateDataSource = {
  name: string;
  configuration: object;
  type: DataSourceType;
};

export const MANUAL_DATA_SOURCE: DataSource = {
  id: "manual_amazon_north_america",
  name: "Manual",
  description: "Products added manually by users.",
  type: DataSourceType.AMAZON_NORTH_AMERICA,
  configuration: { refresh_token: "" },
  updated_at: "internal",
  created_by: {},
};
export type DataSourceGatherStatus = {
  count: number;
  updated_at: Date;
  data_source_id: string;
};
