import { Typography } from "@mui/material";

const Done = () => (
  <>
    <img
      src="/insights/empty-done.svg"
      alt="Insights marked as Done will show up here"
    />
    <Typography variant="body1" fontWeight="600" marginTop="20px">
      Insights marked as Done will show up here
    </Typography>
    <Typography variant="body1">
      Keep track of the insights you’ve completed
    </Typography>
  </>
);

export default Done;
