import { OauthCreationRead } from "../types";
import apiRequest from "./apiRequest";

export async function getOauthIntegration(
  provider: string
): Promise<OauthCreationRead> {
  return await apiRequest<OauthCreationRead>(
    `oauth-integrations/${provider}`,
    "GET"
  );
}

export async function createOauthIntegrationFromAuthCode(
  provider: string,
  authCode: string,
  scopes?: string[]
): Promise<OauthCreationRead> {
  return await apiRequest<OauthCreationRead>(
    `oauth-integrations/${provider}`,
    "POST",
    undefined,
    { authorization_code: authCode, scopes }
  );
}

export async function revokeOauthIntegration(
  provider: string
): Promise<OauthCreationRead> {
  return await apiRequest<OauthCreationRead>(
    `oauth-integrations/${provider}`,
    "DELETE"
  );
}

export async function createRefreshToken(
  provider: string,
  authorization_code: string
): Promise<OauthCreationRead> {
  return await apiRequest<OauthCreationRead>(
    `oauth-integrations/create/${provider}`,
    "POST",
    { authorization_code }
  );
}
