import { useAmplitude } from "react-amplitude-hooks";
import { useRouteMatch } from "react-router";
import { useEffect } from "react";

export const useRouteMetrics: (
  path: string | string[],
  properties?: object,
  options?: {
    exact?: boolean;
  }
) => void = (path, properties = {}, options = {}) => {
  const match = useRouteMatch<any>({ path, exact: options.exact || false });
  const { logEvent } = useAmplitude((inheritedProps: any = {}) => ({
    ...inheritedProps,
    ...properties,
  }));
  useEffect(() => {
    if (match) {
      logEvent("page.view", { path: match?.path, ...match?.params });
    }
  }, [match?.url]);
};
