import { Box, ClickAwayListener, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ArrowUpIcon, DropDownIcon } from "../../assets";
import MultiChoiceSelect from "./MultiChoiceSelect";
import LoadingSpinner from "../../components/LoadingSpinner";

interface MultiChoiceComponentProps {
  isPending: boolean;
  choiceList: string[];
  selectedChoices: string[];
  setSelectedChoices: (choices: string[]) => void;
  setAllowedApply: (isAllowed: boolean) => void;
}

const MultiChoiceComponent: FC<MultiChoiceComponentProps> = (props) => {
  const { isPending, choiceList, selectedChoices, setSelectedChoices, setAllowedApply } = props;
  const theme = useTheme();
  const [showChoiceFilter, setShowChoiceFilter] = useState<boolean>(false);

  useEffect(() => {
    setAllowedApply(selectedChoices.length > 0);
  }, [selectedChoices, setAllowedApply]);

  const handleChoice = () => {
    if (isPending) return;
    setShowChoiceFilter(!showChoiceFilter);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowChoiceFilter(false)}>
      <Box
        display="flex"
        flexDirection="column"
        gap="5px"
        position="relative"
        width="315px"
      >
        <Box
          display="flex"
          width="100%"
          borderRadius="5px"
          justifyContent="center"
          alignItems="center"
          // bgcolor={theme.palette.grey[25]}
          bgcolor={
            selectedChoices?.length > 0
              ? theme.palette.primary.light
              : theme.palette.primary.light
          }
          padding="5px"
          marginTop="8px"
          sx={{
            cursor: !isPending ? "pointer" : "default",
          }}
          onClick={handleChoice}
        >
          <Box display="flex" gap="5px" alignItems="center" minHeight={20}>
            {isPending ? (
              <LoadingSpinner size={20} color={theme.palette.grey[300]} />
            ) : (
              <>
                <Box
                  fontSize="13px"
                  fontWeight={500}
                  color={
                    selectedChoices?.length > 0
                      ? theme.palette.primary.dark
                      : theme.palette.grey[500]
                  }
                >
                  {selectedChoices?.length > 0
                    ? `${selectedChoices?.length} selections`
                    : "Select Choices"}
                </Box>
                {showChoiceFilter ? <ArrowUpIcon /> : <DropDownIcon />}
              </>
            )}
          </Box>
        </Box>
        <MultiChoiceSelect
          open={showChoiceFilter}
          choiceList={choiceList.length > 0 ? choiceList : []}
          selectedChoices={selectedChoices}
          setSelectedChoices={setSelectedChoices}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default MultiChoiceComponent;
