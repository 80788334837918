import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import SVG from "react-inlinesvg";
import {FC, Fragment} from "react"

import {Flow} from "../../types";

export const FlowIcon: FC = () => {
  const theme = useTheme();
  return (
    <SVG
      src={`/flow-basic-icon.svg`}
      width={theme.spacing(6)}
      height={theme.spacing(6)}
    />
  );
};

type FlowListItemProps = { flow: Flow; listItemProps?: ListItemProps | object };

export const FlowListItem: FC<FlowListItemProps> = ({
  flow,
  listItemProps = {},
}) => {
  const theme = useTheme();
  const spacerColor = "grey.300";
  return (
    <ListItem disablePadding {...listItemProps}>
      <ListItemButton>
        <ListItemIcon>
          <FlowIcon />
        </ListItemIcon>
        <ListItemText
          primary={flow.display_name}
          secondary={
            <Stack
              sx={{ display: "flex", alignItems: "start" }}
              direction="row"
              spacing={0.5}
            >
              {flow.bigquery_warehouse_export_enabled && (
                <>
                  <Typography variant="body2" sx={{ color: spacerColor }}>
                    •
                  </Typography>
                  <Box>
                    <SVG
                      fill={theme.palette.grey[500]}
                      src={`/dwh-icon.svg`}
                      height={theme.spacing(11 / 8)}
                      width={theme.spacing(11 / 8)}
                    />
                  </Box>

                  <Typography variant="body2" sx={{ color: spacerColor }}>
                    •
                  </Typography>
                </>
              )}
              {flow?.blocks?.map((block, index, array) => (
                <Fragment key={block.identifier}>
                  <Typography variant="body2" sx={{ color: "text.read" }}>
                    {block.templateName}
                  </Typography>
                  {index < array.length - 1 && (
                    <Typography variant="body2" sx={{ color: spacerColor }}>
                      •
                    </Typography>
                  )}
                </Fragment>
              ))}
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
