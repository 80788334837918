import {Box, SelectChangeEvent, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {FC, useState} from "react";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {ConnectorType} from "../../../../enums";
import {useConnectorOptions} from "../../../../services/connectors";
import useConnectors from "../../../../services/connectors/useConnectors";
import {createConnector} from "../../../../services/connectorsV2";
import {ConnectorCreate} from "../../../../types/connectorsV2";
import {prettifyString} from "../../../../utils";
import {ConnectionConfigurationProps} from "../types";
import ConfigureConnectionCard from "./ConfigureConnectionCard";
import CreateConnectorFormCard from "./CreateConnectorFormCard";

type ConnectorForm = {
    name: string;
    configuration: Record<string, any>;
};

const ExternalConnectorConfiguration: FC<ConnectionConfigurationProps> = ({
                                                                              input,
                                                                              inputValue,
                                                                              setInputValue,
                                                                              resetInputValue,
                                                                          }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { boardId }: { boardId?: any } = useParams();
  const [newConnectorFormOpen, setNewConnectorFormOpen] =
    useState<boolean>(false);
  const { data: connectors, refetch: refetchConnectors } = useConnectors(
    boardId,
    inputValue.type
  );
  const { data: connectorOptions } = useConnectorOptions();
  const filteredConnectorOptions =
    connectorOptions &&
    connectorOptions.filter(
        (connectorOption) =>
            ![
                ConnectorType.AMAZON_PRODUCTS,
                ConnectorType.GOOGLE_SHEETS,
                ConnectorType.LOCAL,
                ConnectorType.BOARD,
            ].includes(connectorOption.type)
    );
    const formMethods = useForm({mode: "onChange"});
    const {isValid} = formMethods.formState;
    const handleConnectorCreate = formMethods.handleSubmit(
        // @ts-ignore
        async (formValues: ConnectorForm) => {
            try {
                const request: ConnectorCreate = {
                    name: formValues.name,
                    circuitboard_id: boardId,
                    type: inputValue.type,
                    configuration: formValues,
                };
                const connector = await createConnector(request);
                enqueueSnackbar(
          `Successfully created new connector ${connector.name}`,
          {
            variant: "success",
          }
        );
        await refetchConnectors();
        setInputValue({ ...inputValue, connector_id: connector.id });
        setNewConnectorFormOpen(false);
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
  );
  const onChange = (event: SelectChangeEvent) => {
    formMethods.setValue("destinationType", event.target.value);
    setInputValue({ type: event.target.value as ConnectorType });
  };

  const onCloseNewConnectionForm = () => setNewConnectorFormOpen(false);
  const onOpenNewConnectionForm = () => setNewConnectorFormOpen(true);

  return (
    <Box marginX={4} marginY={2}>
      <Typography variant="h6">
        {`2. Choose a data source for ${prettifyString(input.name)} input`}
      </Typography>
      {newConnectorFormOpen ? (
        <CreateConnectorFormCard
          inputValue={inputValue}
          connectorOptions={filteredConnectorOptions || []}
          isValid={isValid}
          onBack={onCloseNewConnectionForm}
          handleConnectorCreate={handleConnectorCreate}
          formMethods={formMethods}
        />
      ) : (
        <ConfigureConnectionCard
          connectorOptions={filteredConnectorOptions || []}
          inputValue={inputValue}
          setInputValue={setInputValue}
          resetInputValue={resetInputValue}
          connectors={connectors || []}
          onChange={onChange}
          onOpenNewConnectionForm={onOpenNewConnectionForm}
        />
      )}
    </Box>
  );
};

export default ExternalConnectorConfiguration;
