import { Box, Stack, Typography } from "@mui/material";
import { formatCurrencyOnlyNumber } from "../../InsightsV2/SummaryV2.tsx";
import theme from "../../../themes/light.ts";
import { Icon } from "../../../components/Icon.tsx";

const NumberSection = ({
  number = 0,
  percentage = 0,
  change = 0,
  unit,
  vertical = false,
  sx,
}: {
  title?: string;
  number?: number;
  percentage?: number;
  change?: number;
  unit?: "$" | "%";
  vertical?: boolean;
  sx?: any;
}) => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: vertical ? "column" : "row",
        gap: "8px",
        height: "fit-content",
        width: "fit-content",
        ...sx,
      }}
    >
      <Typography variant="h4"
        sx={{
          fontFamily: 'Inter',
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24px", /* 150% */
          letterSpacing: "-0.16px",
        }}
      >
        {unit === "$" && "$"}
        {unit !== "%"
          ? formatCurrencyOnlyNumber(number)
          : `${number.toFixed(2)}%`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "2px",
          alignItems: "center",
        }}
      >
        {percentage >= 0 ? (
          <Icon src="sales/trend-up.svg" height="16px" width="16px" />
        ) : (
          <Icon src="sales/trend-down.svg" height="16px" width="16px" />
        )}
        <Typography
          variant="body4"
          sx={{
            color:
              percentage >= 0
                ? theme.palette.text.success
                : theme.palette.error.main,
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
          }}
        >
          {unit === "$" && "$"}

          {unit === "%"
            ? `${percentage?.toFixed(1).replaceAll(/\.0(0)?/g, "")}%`
            : formatCurrencyOnlyNumber(change)}
        </Typography>
      </Box>
    </Stack >
  );
};

export default NumberSection;
