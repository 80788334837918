import { useMemo } from "react";
import { useTheme } from "@mui/styles";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import Dismissed from "./Dismissed";
import Done from "./Done";
import MyItems from "./MyItems";
import Feed from "./Feed";

const Index = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const Component = useMemo(() => {
    if (pathname.search("my-items") > -1) {
      return MyItems;
    }
    if (pathname.search("done") > -1) {
      return Done;
    }
    if (pathname.search("dismissed") > -1) {
      return Dismissed;
    }
    return Feed;
  }, [pathname]);

  return (
    <Stack
      width="100%"
      height="100%"
      sx={{ background: theme.palette.backgrounds.secondary }}
      alignItems="center"
      paddingTop="24px"
    >
      <Component />
    </Stack>
  );
};

export default Index;
