import dayjs from "../utils.ts";
import { useQuery } from "react-query";
import { DayActivityOverview } from "../types/runs";
import { getRunsOverview } from "../services/runs/api";
import HeatMap from "./heatMap";
import Loadable from "./Loadable";
import { FC } from "react";

type RunsHeatMapProps = {
  boardId: string;
  days: number;
};

const RunsHeatMap: FC<RunsHeatMapProps> = ({ boardId, days }) => {
  const currentTime = dayjs();
  const startTime = currentTime.add(-days, "day");

  const {
    data: runsOverview,
    isLoading: isOverviewLoading,
    error: overviewError,
  } = useQuery<DayActivityOverview[], Error>(
    ["runsOverview", boardId, [startTime.year, startTime.month, startTime.day]],
    () => getRunsOverview(boardId, days)
  );

  return (
    <Loadable isLoading={isOverviewLoading} error={overviewError}>
      <HeatMap data={runsOverview!} range={[startTime, currentTime]} />
    </Loadable>
  );
};

export default RunsHeatMap;
