import { FC } from "react";

import { Skeleton, Tooltip, Typography, TypographyProps } from "@mui/material";

export const LoadableText: FC<TypographyProps> = ({ children, ...props }) => (
  <Tooltip title={children || ""}>
    <Typography {...props}>
      {children == null ? <Skeleton variant="text" /> : children}
    </Typography>
  </Tooltip>
);
