import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

const AsinsListTextarea = ({
  errors,
  onChange,
  ...props
}: {
  errors?: string | undefined | null;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}) => (
  <TextField
    multiline
    fullWidth
    rows={5}
    variant="filled"
    error={!!errors}
    helperText={errors}
    placeholder={
      "Type or paste a list of ASINs to add to your training set.\nSeparate ASINs by space or comma."
    }
    {...props}
    onChange={onChange}
    InputProps={{
      sx: {
        padding: "8px 16px",
        "& textarea": { padding: "0 !important", fontSize: "15px" },
      },
    }}
    sx={{
      "& .MuiInputBase-root": {
        // "&.Mui-focused": {
        //   border: "1px solid #8E4FF6"
        // },
      },
      "& .MuiInputBase-root.Mui-error": {
        borderColor: "red",
      },
      "& p.Mui-focused": { border: "0 none" },
    }}
  />
);

export default AsinsListTextarea;
