import { FC } from "react";

import { Avatar, CardHeader, CardHeaderProps, Typography } from "@mui/material";
import { useAuthUserOrNull } from "@frontegg/react-hooks/auth";
import { useStyles } from "./useStyles";
import theme from "../../themes/light";

export const UserSectionV2: FC<{ open?: boolean, mode?: "top" | "side" } & CardHeaderProps> = ({
  open,
  mode = "side",
  ...props
}) => {
  const user = useAuthUserOrNull();
  const classes = useStyles();

  return (
    <CardHeader
      className={classes.userSection}
      sx={{
        flexWrap: "nowrap",
        gap: 10,
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
      avatar={
        <Avatar
          src={user?.profilePictureUrl || ""}
          sx={{ height: "32px", width: "32px" }}
        />
      }
      title={mode !== "top" &&
        <Typography
          sx={{
            color: theme.palette.backgrounds.white,

            whiteSpace: "nowrap",
            width: "100%",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 600,
            overflow: "hidden",
            visibility: open ? "visible" : "hidden",
            transition: "visibility 0.5s ease",
          }}
        >
          {user?.name}
        </Typography>
      }
      subheader={mode !== "top" &&
        <Typography
          sx={{
            color: theme.palette.backgrounds.white,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: 500,
            overflow: "hidden",
            visibility: open ? "visible" : "hidden",
            transition: "visibility 0.5s ease",
          }}
        >
          {user?.email ? user?.email : user?.name}
        </Typography>
      }
      {...props}
    />
  );
};
