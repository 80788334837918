import { IconButton, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const colDefs = [
  {
    headerName: "",
    field: "severity",
    width: 30,
    valueFormatter: undefined,
    cellRenderer: ({ value }: { value: number }) => {
      switch (value) {
        case 1:
          return "🔵";
        case 2:
          return "🟢";
        case 3:
          return "🟡";
        case 4:
          return "🟠";
        case 5:
          return "🔴";
        default:
          return "⚪️";
      }
    },
  },
  {
    headerName: "Asset",
    field: "asset",
    flex: 1,
    valueFormatter: undefined,
    cellRenderer: ({ value }: { value: string }) => {
      return (
        <span style={{ textTransform: "capitalize" }}>
          {value.replaceAll("_", " ")}
        </span>
      );
    },
  },
  {
    headerName: "Message",
    field: "message",
    flex: 2,
    valueFormatter: undefined,
    cellRenderer: undefined,
  },
  {
    headerName: "Reason",
    field: "trigger_reason",
    flex: 1,
    valueFormatter: undefined,
    cellRenderer: undefined,
  },
  {
    headerName: "User email",
    field: "user_email",
    flex: 1,
    valueFormatter: undefined,
    cellRenderer: undefined,
  },
  {
    headerName: "Created at",
    field: "created_at",
    flex: 0,
    valueFormatter: undefined,
    cellRenderer: undefined,
  },
  {
    headerName: "",
    field: "extra_data",
    width: 60,
    valueFormatter: undefined,
    cellRenderer: ({ value }: { value: string }) => {
      if (!value) return;
      let output;
      try {
        const data = JSON.parse(value);
        if (!data) return;
        output = Object.entries(data)
          .map(([key, value]) => `${key}: ${value}`)
          .join("\n");
      } catch (error) {
        output = value;
      }

      return (
        <Tooltip title={<Typography>{output}</Typography>}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      );
    },
  },
];
