import {useSnackbar} from "notistack";

import {Divider} from "@mui/material";
import {useMutation} from "react-query";
import {useHistory} from "react-router-dom";
import {useConfirmation} from "../../../components/ConfirmationDialog";
import {useBoardEvents} from "../../../hooks/metrics/boardEvents";
import {APIError} from "../../../services/apiRequest";
import {uninstallCircuitboard} from "../../../services/circuitboards";
import BasicSettingsSection from "./BasicSettingsSection";
import DeleteSection from "./DeleteSection";
import {useBoard} from "../../../services/boards";
import {FC} from "react"

const GeneralTab: FC<{
  boardId: string;
}> = ({boardId}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {deleteBoardEvent} = useBoardEvents();

  const confirm = useConfirmation();
  const history = useHistory();
  const {data: board} = useBoard(boardId);

  const mutation = useMutation(uninstallCircuitboard, {
    onSuccess: () => {
      enqueueSnackbar("Successfully deleted block", {
        variant: "success",
      });
      deleteBoardEvent({ success: true, id: boardId });
      history.push("/boards");
    },
    onError: (error: APIError) => {
      deleteBoardEvent({ success: false, id: boardId });
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const uninstall = async () => {
    mutation.mutateAsync(boardId);
  };

  const uninstallConfirmation = () => {
    confirm({
      title: `Are you sure you want to delete ${board?.displayName}`,
      description:
        "This will delete all the block resources and cant be undone",
    }).then(uninstall);
  };

  return (
    <>
      {board && <BasicSettingsSection board={board} />}
      <Divider sx={{ marginY: 1 }} />
      <DeleteSection
        board={board}
        uninstallConfirmation={uninstallConfirmation}
        isSubmitting={mutation.isLoading}
      />
    </>
  );
};

export default GeneralTab;
