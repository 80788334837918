import { FC, useState } from "react";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import {
  Button,
  ButtonProps,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { terminateRun, updateRun } from "../../../../services/runs/api";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformRole, Status } from "../../../../enums";
import { useRun } from "../../../../services/runs";

export const ConfirmationButton: FC<ButtonProps> = ({ onClick, ...props }) => {
  const [terminateMode, setTerminateMode] = useState(false);
  const [timeoutTrigger, setTimeoutTrigger] = useState<any>(null);
  return (
    <Button
      variant="contained"
      endIcon={
        terminateMode ? (
          <CircularProgress size={15} sx={{ color: "white" }} />
        ) : undefined
      }
      sx={
        !terminateMode
          ? {}
          : {
              background: (t) => t.palette.error.main,
            }
      }
      color={terminateMode ? "warning" : undefined}
      onClick={(e) => {
        if (terminateMode) {
          clearTimeout(timeoutTrigger);
          if (onClick) {
            setTerminateMode(false);
            onClick(e);
          }
        } else {
          setTerminateMode(true);
          setTimeoutTrigger(
            setTimeout(() => {
              setTerminateMode(false);
            }, 5000)
          );
        }
      }}
      {...props}
    >
      {terminateMode ? "Are you sure?" : props.children}
    </Button>
  );
};

export const RunActions: FC<{ runId: string; boardId: string }> = ({
  runId,
  boardId,
}) => {
  const { refetch, data } = useRun(boardId, runId);
  const { enqueueSnackbar } = useSnackbar();
  const terminateRunMutation = useMutation(() => terminateRun(boardId, runId), {
    onSuccess: () => {
      enqueueSnackbar("Run terminated successfully.", {
        variant: "success",
      });
      return refetch();
    },
    onError: () => {
      enqueueSnackbar("Failed terminate run.", {
        variant: "error",
      });
    },
  });
  const mutation = useMutation(updateRun, {
    onSuccess: () => {
      enqueueSnackbar("Export rerun successfully.", {
        variant: "success",
      });
      return refetch();
    },
    onError: () => {
      enqueueSnackbar("Failed to rerun export.", {
        variant: "error",
      });
    },
  });
  return (
    <AuthorizedContent requiredRoles={[PlatformRole.NoogataAdministrator]}>
      <Paper
        sx={{
          backgroundColor: "white",
          margin: 3,
          p: 2,
          boxShadow: 1,
        }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <Tooltip title="This will trigger the export process to WH">
            <Button
              variant="contained"
              onClick={() => {
                mutation.mutate({ boardId, runId, status: Status.RUNNING });
              }}
            >
              {" "}
              Rerun Export
            </Button>
          </Tooltip>
          <ConfirmationButton
            disabled={
              data?.status === Status.SUCCEEDED ||
              data?.status === Status.FAILED ||
              data?.status === Status.TERMINATED
            }
            onClick={() => terminateRunMutation.mutate()}
          >
            Terminate Run
          </ConfirmationButton>
        </Stack>
      </Paper>
    </AuthorizedContent>
  );
};
