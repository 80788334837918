import { FC, useEffect, useRef } from "react";
import theme from "../../../themes/light";
import { Box, InputLabel } from "@mui/material";
import { ArrowUpIcon, CalenderIcon, DropDownIcon } from "../../../assets";
import { getNewFormattedDateString } from "../hooks/selectors";
import DateRangeSelector from "../DateRangeSelector";
import { getDateRanges } from "../defaults";

interface DateRangeFilterProps {
  element: any;
  openDate: any;
  handleDateRangeSelector: any;
  dateRange: any;
  handleApplyDate: any;
}

const DateRangeFilter: FC<DateRangeFilterProps> = (
  props: DateRangeFilterProps
) => {
  const {
    element: el,
    openDate,
    handleDateRangeSelector,
    dateRange,
    handleApplyDate,
  } = props;

  const dateRangeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDate) {
      dateRangeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [openDate]);

  return (
    <Box
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.grey[200],
        },
        "& .MuiSelect-select": {
          color: theme.palette.grey[500],
          fontSize: "13px",
          fontWeight: 500,
        },
        width: "50%",
      }}
    >
      <InputLabel htmlFor="color" sx={{ marginBottom: "5px" }}>
        {el?.name}
      </InputLabel>
      <Box display="flex" flexDirection="column" gap="5px" position="relative">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderRadius="4px"
          border="1px solid #D7D5DB"
          padding="8px 12px"
          gap="10px"
          sx={{
            cursor: "pointer",
            "&:hover": {
              border: "1px solid #2C224C",
            },
            ...(openDate && {
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
            }),
          }}
          onClick={handleDateRangeSelector}
        >
          <Box display="flex" gap="10px" alignItems="center">
            <CalenderIcon />
            <Box>
              {getNewFormattedDateString(dateRange, "Select a date range")}
            </Box>
          </Box>
          {openDate ? <ArrowUpIcon /> : <DropDownIcon />}
        </Box>
        <Box
          position="absolute"
          top="100%"
          // right={0}
          zIndex={1}
          ref={dateRangeRef}
        >
          <DateRangeSelector
            open={openDate}
            close={handleDateRangeSelector}
            handleSubmitDateRangePicker={(e: any) => handleApplyDate(e, el?.id)}
            dateRanges={getDateRanges(new Date(), el?.options)}
            currentDateRange={dateRange}
            initialDateRange={{
              startDate: dateRange?.startDate,
              endDate: dateRange?.endDate,
            }}
            showDefinedRanges={!!el?.options}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DateRangeFilter;
