import { FC } from "react";

import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const EditRun: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    variant="outlined"
    size="small"
    onClick={() => onClick()}
    startIcon={<EditOutlinedIcon fontSize="small" sx={{ cursor: "pointer" }} />}
  >
    Edit
  </Button>
);
