import { useQuery, UseQueryResult } from "react-query";
import {
  AssortmentFilters,
  BrandStrategyAssortment,
  FilterOptions,
  MarketBreakdownAssortment,
  PricingDistributionAssortment,
  ProductsReviewAssortment,
  RevenueShareAssortment,
  SalesPerformanceAssortment,
} from "./types";
import apiRequest, { APIError } from "../../services/apiRequest";

const getMarketBreakdownData = ({
  space_id,
  attribute,
  filters,
}: {
  space_id?: string;
  attribute?: string;
  filters?: AssortmentFilters;
}): Promise<MarketBreakdownAssortment> =>
  apiRequest<MarketBreakdownAssortment>(
    `v1/assortments/${space_id}/market-breakdown/${attribute}`,
    "POST",
    {},
    filters
  );

export const useMarketBreakdownData = ({
  space_id,
  attribute,
  filters,
}: {
  space_id?: string;
  attribute?: string;
  filters?: AssortmentFilters;
}): UseQueryResult<MarketBreakdownAssortment, APIError> =>
  useQuery<MarketBreakdownAssortment, APIError>(
    ["useMarketBreakdownData", space_id, attribute, filters],
    () => getMarketBreakdownData({ space_id, attribute, filters }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: !!space_id && !!attribute,
    }
  );

const getBrandStrategyData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): Promise<BrandStrategyAssortment> =>
  apiRequest<BrandStrategyAssortment>(
    `v1/assortments/${space_id}/brand-strategy`,
    "POST",
    {},
    filters
  );

export const useBrandStrategyData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): UseQueryResult<BrandStrategyAssortment, APIError> =>
  useQuery<BrandStrategyAssortment, APIError>(
    ["useBrandStrategyData", space_id, filters],
    () => getBrandStrategyData({ space_id, filters }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: !!space_id,
    }
  );

const getSalesPerformanceData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): Promise<SalesPerformanceAssortment> =>
  apiRequest<SalesPerformanceAssortment>(
    `v1/assortments/${space_id}/sales-performance`,
    "POST",
    {},
    filters
  );

export const useSalesPerformanceData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): UseQueryResult<SalesPerformanceAssortment, APIError> =>
  useQuery<SalesPerformanceAssortment, APIError>(
    ["useSalesPerformanceData", space_id, filters],
    () => getSalesPerformanceData({ space_id, filters }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: !!space_id,
    }
  );

const getProductsReviewData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): Promise<ProductsReviewAssortment> =>
  apiRequest<ProductsReviewAssortment>(
    `v1/assortments/${space_id}/products-review`,
    "POST",
    {},
    filters
  );

export const useProductsReviewData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): UseQueryResult<ProductsReviewAssortment, APIError> =>
  useQuery<ProductsReviewAssortment, APIError>(
    ["useProductsReviewData", space_id, filters],
    () => getProductsReviewData({ space_id, filters }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: !!space_id,
    }
  );

const getPricingDistributionData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): Promise<PricingDistributionAssortment> =>
  apiRequest<PricingDistributionAssortment>(
    `v1/assortments/${space_id}/pricing-distribution`,
    "POST",
    {},
    filters
  );

export const usePricingDistributionData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): UseQueryResult<PricingDistributionAssortment, APIError> =>
  useQuery<PricingDistributionAssortment, APIError>(
    ["usePricingDistributionData", space_id, filters],
    () => getPricingDistributionData({ space_id, filters }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: !!space_id,
    }
  );

const getRevenueShareData = ({
  space_id,
  attribute,
  filters,
}: {
  space_id?: string;
  attribute?: string;
  filters?: AssortmentFilters;
}): Promise<RevenueShareAssortment> =>
  apiRequest<RevenueShareAssortment>(
    `v1/assortments/${space_id}/revenue-share/${attribute}`,
    "POST",
    {},
    filters
  );

export const useRevenueShareData = ({
  space_id,
  attribute,
  filters,
}: {
  space_id?: string;
  attribute?: string;
  filters?: AssortmentFilters;
}): UseQueryResult<RevenueShareAssortment, APIError> =>
  useQuery<RevenueShareAssortment, APIError>(
    ["useRevenueShareData", space_id, attribute, filters],
    () => getRevenueShareData({ space_id, attribute, filters }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: !!space_id && !!attribute,
    }
  );

const fetchFilterOptionsData = async ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): Promise<FilterOptions> =>
  await apiRequest<FilterOptions>(
    `v1/assortments/${space_id}/filter-options`,
    "POST",
    {},
    filters
  );

export const useFilterOptionsData = ({
  space_id,
  filters,
}: {
  space_id?: string;
  filters?: AssortmentFilters;
}): UseQueryResult<FilterOptions, APIError> =>
  useQuery<FilterOptions, APIError>(
    ["useFilterOptionsData", space_id, filters],
    () => fetchFilterOptionsData({ space_id, filters }),
    {
      staleTime: 6000,
      refetchOnWindowFocus: false,
      enabled: !!space_id,
    }
  );
