import {MenuItem, TextField} from "@mui/material";
import {ParameterTextFieldProps} from "./types";
import {FC} from "react"

const MODE_OPTIONS = ["production", "draft"];

const ParameterModeField: FC<ParameterTextFieldProps> = (props) => (
    <TextField
        helperText={props.helperText}
        id="mode-select"
        select
        name={props.name}
        label={props.label}
    value={props.value || ""}
    onChange={(e) => props.onChange(e.target.value as string)}
    margin="dense"
    variant="outlined"
    fullWidth
  >
    {MODE_OPTIONS.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </TextField>
);
export default ParameterModeField;
