import { FC, useEffect } from "react";
import { Button, LinearProgress, Paper, Stack } from "@mui/material";
import AceEditor from "react-ace";
import { useMutation } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { minifyJsonObject, prettifyJson } from "../../utils";
import {
  deleteBaseInsightV2,
  updateBaseInsightV2,
  useBaseInsightV2,
} from "./useBaseInsight";
import useQueryParams from "../../hooks/useQueryParams";
import { InsightsQueryParams } from "./constants";
import { validateJsonField } from "../CreateNewRun/hooks/utils";
import { useFilteredInsightsV2 } from "./useInsights";
import { AuthorizedContent } from "../../components/AuthorizedContent";
import { PlatformRole } from "../../enums";
import { ConfirmationButton } from "../Run/RunV2/OverviewSection/RunActions";
import { useLocalInsightV2 } from "../../hooks/useLocalInsightV2";

export const InsightActions: FC<object> = () => {
  const insightId = useQueryParams().get(InsightsQueryParams.InsightId);
  const mutation = useMutation(updateBaseInsightV2);
  const { refetch: refetchInsightV2 } = useLocalInsightV2();
  const { data: insight, isLoading } = useBaseInsightV2(insightId);
  const {
    control,
    reset,
    formState: {
      isValid,
      isSubmitting,
      errors: { editInsightJsonText: editInsightJsonTextError },
    },
    watch,
    register,
    handleSubmit,
    setValue,
  } = useForm<{
    editInsightJsonText: string;
    open: boolean;
  }>({
    mode: "onChange",
    defaultValues: {
      editInsightJsonText: prettifyJson(insight) || "",
      open: false,
    },
  });
  const editInsightJsonText = watch("editInsightJsonText");
  const isOpen = watch("open");
  useEffect(() => {
    register("open");
  }, []);
  useEffect(() => {
    if (!editInsightJsonText && insight) {
      setValue("editInsightJsonText", prettifyJson(insight), {
        shouldValidate: true,
      });
    }
  }, [editInsightJsonText, insight, setValue]);
  const { refetch } = useFilteredInsightsV2({ refetchOnWindowFocus: false });

  const { enqueueSnackbar } = useSnackbar();
  const onInsightEdit = handleSubmit(({ editInsightJsonText }) => {
    mutation
      .mutateAsync(JSON.parse(editInsightJsonText))
      .then(() => {
        enqueueSnackbar("Insight updated successfully", {
          variant: "success",
        });
        reset({ open: false });
        return refetch();
      })
      .catch((e) => {
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  });
  const deleteMutation = useMutation(deleteBaseInsightV2);
  const history = useHistory();
  return (
    <AuthorizedContent requiredRoles={[PlatformRole.NoogataAdministrator]}>
      <Stack spacing={1}>
        <Stack alignContent="start" direction="row" spacing={1}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setValue("open", !isOpen)}
          >
            Edit
          </Button>
          <ConfirmationButton
            color="error"
            disabled={
              !insight || deleteMutation.isLoading || mutation.isLoading
            }
            onClick={() =>
              insight &&
              deleteMutation
                .mutateAsync(insight.id)
                .then(() => {
                  enqueueSnackbar(
                    `Insight with id ${insight.id} deleted successfully.`,
                    { variant: "success" }
                  );
                  history.push({ search: "?" });
                  return refetchInsightV2().then(() => refetch());
                })
                .catch(() => {
                  enqueueSnackbar("Failed to delete insight.", {
                    variant: "error",
                  });
                })
            }
          >
            Delete
          </ConfirmationButton>
        </Stack>
        <Paper
          variant="outlined"
          sx={{
            borderWidth: isOpen ? 1 : 0,
            height: isOpen ? 300 : 0,
            width: "100%",
            borderColor: editInsightJsonTextError ? "red" : "none",
            overflow: "auto",
            transition: "height 500ms",
          }}
        >
          {(mutation.isLoading || isLoading) && <LinearProgress />}
          <Stack direction="row" spacing={1} margin={1}>
            <Button
              variant="contained"
              disabled={
                minifyJsonObject(editInsightJsonText) ===
                  minifyJsonObject(insight) ||
                !isValid ||
                isSubmitting
              }
              onClick={() => onInsightEdit()}
            >
              Submit
            </Button>
          </Stack>
          <Controller
            control={control}
            rules={{
              validate: (value) =>
                validateJsonField(value) || "Invalid JSON Value",
            }}
            name="editInsightJsonText"
            // @ts-ignore
            value={editInsightJsonText}
            render={(props) => (
              <AceEditor
                debounceChangePeriod={500}
                readOnly={isLoading && !insight}
                showPrintMargin={false}
                style={{
                  width: "100%",
                }}
                {...props}
              />
            )}
          />
        </Paper>
      </Stack>
    </AuthorizedContent>
  );
};
