import {
  Button,
  Card,
  CircularProgress,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { Controller, useForm } from "react-hook-form";
import { ConnectorID } from "../../../../enums";
import { useSpaces } from "../../../../services/spaces"; // Updated import
import { prettifyString } from "../../../../utils";
import ConnectorOptionHeader from "../ConnectorOptionHeader";
import { ConnectionConfigurationProps } from "../types";
import { FC } from "react";

type SpaceOutputForm = {
  space_id: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      "& > *": {
        marginBottom: theme.spacing(1),
      },
    },
  })
);

export const SpaceConfiguration: FC<ConnectionConfigurationProps> = ({
  input,
  inputValue,
  setInputValue,
  onCompletion,
  resetInputValue,
}) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<SpaceOutputForm>({
    mode: "onChange",
    defaultValues: inputValue.configuration as SpaceOutputForm,
  });

  const { data: allSpaces = [], isLoading: isSpacesLoading } = useSpaces();

  const onFormSubmit = (formValues: SpaceOutputForm) => {
    setInputValue({
      ...inputValue,
      configuration: formValues,
      mapping: {},
      connector_id: ConnectorID.SPACE,
    });
    onCompletion();
  };

  return (
    <>
      <Typography variant="h6">
        {`2. Choose a Space as a source for ${prettifyString(
          input.name
        )} input`}
      </Typography>
      <Card variant="elevation" elevation={0}>
        <ConnectorOptionHeader
          connectorType={inputValue.type}
          text="Fetch data from your Space catalog"
        />
        <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="space-label">Space</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  label="Space"
                  labelId="space-label"
                  disabled={isSpacesLoading}
                  {...register("space_id", { required: true })}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("space_id", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                >
                  {allSpaces.map((space) => (
                    <MenuItem key={space.id} value={space.id}>
                      {space.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="space_id"
              control={control}
              rules={{ required: true }}
            />
          </FormControl>

          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={resetInputValue}
              autoFocus
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                "Continue To Mapping"
              )}
            </Button>
          </DialogActions>
        </form>
      </Card>
    </>
  );
};
