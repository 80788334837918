import {
  Breadcrumbs,
  Link,
  Skeleton,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import { HeaderBreadcrumb } from "../types";
import { EditableText } from "./EditableText";
import { FC } from "react";

const HeaderBreadcrumbs: FC<
  {
    breadcrumbs: HeaderBreadcrumb[];
  } & StackProps
> = ({ breadcrumbs, ...props }) => {
  const theme = useTheme();
  return (
    <Stack {...props}>
      <Breadcrumbs
        separator={
          <Typography
            variant="body4"
            sx={{ color: theme.palette.text.secondary }}
          >
            /
          </Typography>
        }
      >
        {breadcrumbs.map((breadcrumb, index) => {
          const isUrlDefined = !!breadcrumb.url;
          const last = index === breadcrumbs.length - 1;
          return breadcrumb.component ? (
            breadcrumb.component({ last, breadcrumb })
          ) : last && breadcrumb.onSubmit ? (
            <EditableText
              typographyProps={{
                color: theme.palette.primary.dark,
                variant: "headline3",
                fontWeight: "600",
              }}
              textFieldProps={{ size: "small" }}
              onSubmit={breadcrumb.onSubmit}
              value={breadcrumb.title || ""}
              isLoading={!breadcrumb.title}
            />
          ) : !breadcrumb.title ? (
            <Skeleton width={80} />
          ) : (
            <Link
              color={
                last ? theme.palette.text.link : theme.palette.text.secondary
              }
              underline={isUrlDefined ? "hover" : "none"}
              variant="body4"
              fontWeight="600"
              to={breadcrumb.url}
              key={breadcrumb.url}
              component={RouterLink as any}
              sx={{
                cursor: isUrlDefined ? 'pointer' : 'default'
              }}
            >
              {breadcrumb.title}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};

export default HeaderBreadcrumbs;
