import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {CircularProgress} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {Status} from "../enums";
import {useStatusColor} from "../hooks";
import cn from "classnames";
import {FC} from "react"

const useStyles = makeStyles(() =>
    createStyles({
      "@keyframes flickerAnimation": {
        "0%": {opacity: 1},
        "50%": {opacity: 0},
        "100%": {opacity: 1},
      },
    exporting: {
      opacity: 1,
      animation: "$flickerAnimation 2s infinite",
    },
    cursor: {
      cursor: "pointer",
    },
  })
);

const variantIcon = {
  [Status.PENDING]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <CircularProgress size={15} style={{ color: props.color, marginLeft: 2 }} />
  ),
  [Status.GATHERING]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <CircularProgress size={15} style={{ color: props.color, marginLeft: 2 }} />
  ),
  [Status.RUNNING]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <CircularProgress size={15} style={{ color: props.color, marginLeft: 2 }} />
  ),
  [Status.EXPORTING]: (
    classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <PublishIcon
      className={cn(classes.exporting, props.className)}
      fontSize="small"
      style={{ color: props.color }}
      {...props}
    />
  ),
  [Status.SUCCEEDED]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <CheckCircleOutlineIcon
      className={props.className}
      fontSize="small"
      style={{ color: props.color }}
      {...props}
    />
  ),
  [Status.FAILED]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <ErrorOutlineIcon
      className={props.className}
      fontSize="small"
      style={{ color: props.color }}
      {...props}
    />
  ),
  [Status.TERMINATED]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <ErrorOutlineIcon
      className={props.className}
      fontSize="small"
      style={{ color: props.color }}
      {...props}
    />
  ),
  [Status.READY_TO_EXPORT]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <CheckCircleOutlineIcon
      className={props.className}
      fontSize="small"
      style={{ color: props.color }}
      {...props}
    />
  ),
  [Status.UNKNOWN]: (
    _classes: any,
    _warning: boolean,
    props: Record<string, any>
  ) => (
    <MoreHorizIcon
      className={props.className}
      fontSize="small"
      style={{ color: props.color }}
      {...props}
    />
  ),
};

const StatusIcon: FC<{
  status?: Status | null;
  warning?: boolean;
  onClick?: () => void;
  className?: string;
}> = ({ status, warning = false, onClick, className, ...props }) => {
  const classes = useStyles();
  const renderIcon = variantIcon[status || Status.UNKNOWN];
  const iconClassName = onClick ? cn(className, classes.cursor) : className;
  const color = useStatusColor(status, warning);
  return renderIcon(classes, warning, {
    ...props,
    color,
    className: iconClassName,
    onClick,
  });
};

export default StatusIcon;
