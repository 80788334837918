import { AgGridReact } from "ag-grid-react";
import theme from "../../../themes/light.ts";
import { Box, Button, LinearProgress, Skeleton } from "@mui/material";
import { FeatureExtractionResponse } from "../types.ts";
import { useRef } from "react";
import {
  downloadExtractedFeatures,
  useExtractedFeaturesQuery,
} from "../useSpaces.ts";
import { agGridBoxStyle, getColDefs } from "./enums.tsx";
import { DownloadIcon } from "../../../assets/index.tsx";

const AsinsListFeatureExtractionView = ({ spaceId }: { spaceId: string }) => {
  const gridRef = useRef<AgGridReact>(null);

  const { data: extractedFeatures, isFetching: isFetchingExtractedFeatures } =
    useExtractedFeaturesQuery({
      spaceId: spaceId,
    });

  return (
    <Box
      sx={{
        flex: 1,
        background: theme.palette.grey[50],
        overflow: "auto",
      }}
    >
      {isFetchingExtractedFeatures && <LinearProgress />}
      <Box
        sx={{
          display: "flex",
          padding: "1rem 2rem 0 2rem",
          justifyContent: "space-between",
          height: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isFetchingExtractedFeatures ? (
            <Skeleton
              animation="wave"
              width="160px"
              sx={{
                height: "10px",
                borderRadius: "8px",
                transform: "unset",
              }}
            />
          ) : (
            <>{`Showing ${(extractedFeatures as FeatureExtractionResponse)?.total ?? ""}`}</>
          )}
        </Box>
        {!isFetchingExtractedFeatures && (
          <Button
            onClick={() => downloadExtractedFeatures({ spaceId })}
            sx={{
              minHeight: "32px",
              maxHeight: "32px",
              gap: "4px",
              color: theme.palette.secondary.main,
              padding: "10px",
            }}
          >
            <DownloadIcon />
          </Button>
        )}
      </Box>
      <Box className={"ag-theme-quartz"} sx={agGridBoxStyle}>
        <AgGridReact
          ref={gridRef}
          rowData={(extractedFeatures as FeatureExtractionResponse)?.data ?? []}
          columnDefs={getColDefs(
            (extractedFeatures as FeatureExtractionResponse)?.output_schema
          )}
          rowSelection={"single"}
        />
      </Box>
    </Box>
  );
};

export default AsinsListFeatureExtractionView;
