import apiRequest from "../../services/apiRequest.ts";
import { useQuery } from "react-query";
import { PerfectContentProduct } from "../../types/ecommerce.ts";

export const getPerfectContentProduct = (
  spaceId?: string,
  asin?: string,
): Promise<PerfectContentProduct> =>
  apiRequest<PerfectContentProduct>(
    `v1/perfect_content/${spaceId}/asin/${asin}`,
    "GET",
  );

export const usePerfectContentProductQuery = ({
  spaceId,
  asin,
}: {
  spaceId?: string;
  asin?: string;
}) =>
  useQuery(
    ["usePerfectContent", spaceId, asin],
    () => getPerfectContentProduct(spaceId, asin),
    {
      enabled: !!spaceId && !!asin,
    },
  );
