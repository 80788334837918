import { Search } from "../../../components/SearchInput/Search.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import useDebouncedCallback from "../../../hooks/UseDebouncedCallback.tsx";
import { useState } from "react";

const SearchBox = () => {
  const { searchInput, setSearchInput } = useSalesAssistant();
  const [text, setText] = useState(searchInput);
  const onInputChangeDebounced = useDebouncedCallback(setSearchInput, 500);
  return (
    <Search
      value={text}
      placeholder="Search ASIN"
      data-testid="insights-search"
      onChange={(e) => {
        setText(e.target.value);
        onInputChangeDebounced(e.target.value);
      }}
    />
  );
};

export default SearchBox;
