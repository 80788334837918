import { GoogleAuthorizeConfig } from "./types";

export const GOOGLE_SHEETS_READ_SCOPES = [
  "https://www.googleapis.com/auth/drive.file",
  "https://www.googleapis.com/auth/spreadsheets.readonly",
];

export const GOOGLE_SHEETS_READ_WRITE_SCOPES = [
  "https://www.googleapis.com/auth/drive.file",
  "https://www.googleapis.com/auth/spreadsheets",
];

const BASE_AUTH_CONFIG = {
  client_id: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
  response_type: "code",
  prompt: "consent",
  access_type: "offline",
};

export const READ_GOOGLE_AUTH_CONFIG = {
  ...BASE_AUTH_CONFIG,
  scope: GOOGLE_SHEETS_READ_SCOPES.join(" "),
} as GoogleAuthorizeConfig;

export const READ_WRITE_GOOGLE_AUTH_CONFIG = {
  ...BASE_AUTH_CONFIG,
  scope: GOOGLE_SHEETS_READ_WRITE_SCOPES.join(" "),
} as GoogleAuthorizeConfig;

export const INTERCOM_APP_ID = "vfinv3ld";
