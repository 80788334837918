import {Box, Button, CircularProgress, Divider, Stack, StackProps, Typography,} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {useSnackbar} from "notistack";
import {addProducts} from "../../../services/ecommerce/api";
import {AddProductResponse} from "../../../types/ecommerce";
import {LoadLocalCsvFile} from "../LoadLocalCsvFile";
import {getAsinsFromCSVFile} from "../csvStreamUtils";
import AsinsListTextarea from "./AsinsListTextarea";
import {FC, useEffect, useState} from "react"

const commaSeperatedAsinsListRegex =
    /^[0-9A-Z]{10}(,[ ]?[0-9A-Z]{10})*(,[ ]?[0-9A-Z]{10})?$/;
const spaceSeperatedAsinsListRegex =
    /^[0-9A-Z]{10}([ ][0-9A-Z]{10})*([ ][0-9A-Z]{10})?$/;
const newlineSeperatedAsinsListRegex =
    /^[0-9A-Z]{10}(\n[0-9A-Z]{10})*(\n[0-9A-Z]{10})?$/;
type AsinsListValidation = {
  asins: string[];
  error?: string;
};

export const validateAsinsList = (
  asinsListString: string
): AsinsListValidation => {
  let error;
  const isCommaSeperatedString =
    commaSeperatedAsinsListRegex.test(asinsListString);
  const isSpaceSeperatedString =
    spaceSeperatedAsinsListRegex.test(asinsListString);
  const isNewlineSeperatedString =
    newlineSeperatedAsinsListRegex.test(asinsListString);

  if (
    !(
      isCommaSeperatedString ||
      isSpaceSeperatedString ||
      isNewlineSeperatedString
    )
  ) {
    error = "Asins list must be seperated by comma or space";
  }
  return {
    asins: isCommaSeperatedString
      ? asinsListString.split(",").map((a: any) => a.trim())
      : isSpaceSeperatedString
      ? asinsListString.split(" ")
      : isNewlineSeperatedString
      ? asinsListString.split("\n")
      : [],
    error,
  };
};

export const Separator: FC<StackProps> = (props) => (
  <Stack direction="row" spacing={3} alignItems="center" {...props}>
    <Divider sx={{ flexGrow: 1 }} />
    <Typography variant="caption">OR</Typography>
    <Divider sx={{ flexGrow: 1 }} />
  </Stack>
);

const AddProducts: FC<{
  onCancel: (e: any) => void;
  onSuccess: (response: AddProductResponse) => void;
  onError: (e: Error) => void;
}> = ({ onCancel, onSuccess, onError }) => {
  const snackbar = useSnackbar();
  const [fileDroppedLoading, setFileDroppedLoading] = useState<boolean>(false);
  const {
    control,
    formState,
    handleSubmit,
      watch,
      setValue,
      register,
  } = useForm<{ asinsList: string[];}>({
      mode: "onChange",
      defaultValues: { asinsList: []},
  });
    useEffect(() => {
        register("asinsList", {required: true});
    }, [true]);
    const {errors} = formState
    const parsedAsinsList = watch("asinsList");
    const mutation = useMutation((asins: string[]) => addProducts(asins));
    return (
        <form
            onSubmit={handleSubmit((values) =>
                mutation.mutateAsync(values.asinsList).then(onSuccess).catch(onError)
            )}
        >
            <Box>
                <Typography variant="h5" fontWeight="bold" sx={{mb: 2}}>
                    Add more products
                </Typography>
                <Typography sx={{mb: 4, color: (t) => t.palette.text.read}}>
                    Add category leaders and key competitors to train the model to enhance
                    the model’s results against their product listing.
                </Typography>
                <Controller
                    control={control}
                    name="asinsList"
                    render={({_onChange, ...props}: any) => (
                        <AsinsListTextarea
                            onChange={(e) => {
                                setValue("asinsList", validateAsinsList(e.target.value).asins, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                });
                               
                                // _onChange(e);
                            }}
                            errors={errors?.asinsList?.message}
                            {...props}
                        />
                    )}
                />

                <Separator my={4}/>
                <LoadLocalCsvFile
                    onFileDropped={(dropped_file) => {
                        setFileDroppedLoading(true);
                        getAsinsFromCSVFile(dropped_file)
                            .then((data) => {
                                setValue("asinsList", data, {shouldDirty: true, shouldValidate: true});
                    
              })
              .catch((e) =>
                snackbar.enqueueSnackbar(e.message, { variant: "error" })
              )
              .finally(() => setFileDroppedLoading(false));
          }}
        />
        <Stack direction="row" justifyContent="end" mt={3} spacing={1}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={
              !formState.isValid || formState.isSubmitting || fileDroppedLoading
            }
            type="submit"
            startIcon={
              (formState.isSubmitting || fileDroppedLoading) && (
                <CircularProgress
                  size="small"
                  sx={{
                    height: (t) => t.spacing(2),
                    width: (t) => t.spacing(2),
                    color: (t) => t.palette.grey[300],
                  }}
                />
              )
            }
          >
            Add {parsedAsinsList.length ? parsedAsinsList.length : ""} product
            {parsedAsinsList.length === 1 ? "" : "s"}
          </Button>
        </Stack>
      </Box>
    </form>
  );
};

export default AddProducts;
