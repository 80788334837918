import {Box, Paper, Typography} from "@mui/material";
import {BoardIODetails} from "@noogata/components";
import {JSONSchema4} from "json-schema";
import {PipelineInput} from "../../../types";
import {FC} from "react"

type InputDetailsProps = {
    input: PipelineInput;
};

const InputDetails: FC<InputDetailsProps> = ({input}) => (
    <Box marginTop={4}>
        <Box marginBottom={2}>
            <Typography variant="h6" color="textSecondary">
                {`${input.display_name} Input Details`}
      </Typography>
    </Box>
    <Paper>
      <BoardIODetails
        schema={input.schema as JSONSchema4}
        filename={input.name}
      />
    </Paper>
  </Box>
);

export default InputDetails;
