import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export const ChatContext = createContext<any>({ messages: [] });
export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [ws, setWs] = useState<any>();
  const [messages, setMessages] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(true);
  // const socketUrl = new URL(`ws://localhost:8000/api/v1/insights_chat/ws`).toString();

  useEffect(() => {
    const { host } = window.location;
    let url = "ws://localhost:8000/api/v1/insights_chat/ws";
    if (host !== "localhost") {
      url = `ws://${host}/api/v1/insights_chat/ws`;
    }
    const websocket = new WebSocket(url);
    setWs(websocket);
    websocket.onopen = () => {
      console.log("connected");
      setDisabled(false);
    };
    websocket.onmessage = (event) => {
      try {
        const content = JSON.parse(event.data || "{}");
        setMessages((prevState) => [...prevState, content]);
      } catch (e: any) {
        setMessages((prevState) => [
          ...prevState,
          { type: "error", msg: e.message },
        ]);
      }
    };
    websocket.onclose = (_event) => {
      setDisabled(true);
    };
    websocket.onerror = (_event) => {
      setDisabled(true);
      setMessages((prevState) => [
        ...prevState,
        { type: "message", msg: `Error: ` },
      ]);
    };

    return () => {
      setWs(null);
      websocket.close();
      setDisabled(true);
    };
  }, []);

  const sendMessage = useCallback(
    (message: string) => {
      if (message.length === 0) return;
      ws.send(message);
    },
    [ws]
  );

  const value = {
    messages,
    sendMessage,
    disabled,
  };
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
