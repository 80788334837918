import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Highcharts from "highcharts";
import { SplitFactory as SplitProvider } from "@splitsoftware/splitio-react";
import { IntercomProvider } from "react-use-intercom";
import splitSdkConfig from "./FeatureFlags/splitSdkConfig";
import ErrorBoundary from "./components/ErrorBoundary";
import { INTERCOM_APP_ID } from "./constants";
import { ConfirmationProvider } from "./components/ConfirmationDialog";
import theme, { chartTheme } from "./theme";
import * as serviceWorker from "./serviceWorker";
import { StyledMaterialDesignContent } from "./componentsV2/notistackOverrides";
import SmartHeadTitle from "./components/SmartHeadTitle";
import * as Sentry from "@sentry/react";
import Router from "./Router.tsx";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

Sentry.init({
  dsn: "https://df6c100a4a9bc4256e1e8084df1e7afe@o4506653330243584.ingest.sentry.io/4506653387784192",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/noogata\.com\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE || "");

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}
// noogata's HighCharts theme
// used in order to automatically set noogata's style over all application charts
Highcharts.setOptions(chartTheme);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (numOfIterations, response: any) =>
        numOfIterations < 3 && response.statusCode === 500,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <SmartHeadTitle />
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ErrorBoundary>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            Components={{
              secondary: StyledMaterialDesignContent,
            }}
          >
            <ConfirmationProvider>
              <SplitProvider config={splitSdkConfig}>
                <QueryClientProvider client={queryClient}>
                  <IntercomProvider appId={INTERCOM_APP_ID}>
                    <Router />
                  </IntercomProvider>
                </QueryClientProvider>
              </SplitProvider>
            </ConfirmationProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
