import {useAuthUserOrNull} from "@frontegg/react";

import Header, {HeaderContent} from "../../components/Header";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import HeaderTitle from "../../components/HeaderTitle";
import {useUserTenant} from "../../hooks";
import {FC} from "react"

export const AdministrationHeader: FC<object> = () => {
    const user = useAuthUserOrNull();
    const tenant = useUserTenant(user || undefined);

    return (
        <Header>
            <HeaderContent>
                <HeaderBreadcrumbs
                    breadcrumbs={[
            { title: "Workspaces", url: "" },
            { title: tenant?.name || "", url: "" },
          ]}
        />
        <HeaderTitle title="Noogata Administration Portal" />
      </HeaderContent>
    </Header>
  );
};

export default AdministrationHeader;
