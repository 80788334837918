import { useQuery, UseQueryOptions } from "react-query";
import apiRequest from "../apiRequest";
import { Destination } from "../../types/destinations";

async function getDestinations(circuitboardId: string): Promise<Destination[]> {
  return await apiRequest<Destination[]>("destinations", "GET", {
    board_id: circuitboardId,
  });
}

export default function useDestinations(
  boardId: string,
  options?: UseQueryOptions<Destination[], Error>
) {
  return useQuery<Destination[], Error>(
    ["destinations", boardId],
    () => getDestinations(boardId),
    options
  );
}
