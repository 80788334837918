import { Box, Chip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import theme from "../../../themes/light";
import MultiChoiceComponent from "../MultiChoiceComponent";

interface SpaceTrafficShareFilterProps {
  optionsList: any;
  setSelectedOptions: any;
  setAllowedApply: any;
}

const SpaceTrafficShareFilter: FC<SpaceTrafficShareFilterProps> = ({
  optionsList,
  setSelectedOptions,
  setAllowedApply,
}) => {
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  useEffect(() => {
    if (selectedLabels.length > 0) {
      setSelectedOptions(
        optionsList.filter((op: any) => selectedLabels.includes(op.label))
      );
    }
  }, [selectedLabels]);

  useEffect(() => {
    if (!isCustom) {
      setAllowedApply(true);
      setSelectedLabels([]);
    }
  }, [isCustom]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        minHeight: "100px",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontSize: "14px",
          color: theme.palette.grey[400],
          display: "block",
        }}
      >
        Space Traffic Share
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
        }}
      >
        {["All", "Custom"].map((type: any) => {
          const isCheck = type === "Custom" ? isCustom : !isCustom;
          return (
            <Chip
              key={`type-${type}`}
              label={type}
              sx={{
                height: "24px",
                background: isCheck ? "#EEE5FE !important" : "transparent",
                "& .MuiChip-label": {
                  color: isCheck
                    ? "#5C30A4 !important"
                    : theme.palette.grey[500],
                },
              }}
              onClick={() => setIsCustom(type === "Custom")}
            />
          );
        })}
      </Box>
      {isCustom && (
        <MultiChoiceComponent
          isPending={optionsList.length === 0}
          choiceList={optionsList.map((op: any) => op.label)}
          selectedChoices={selectedLabels}
          setSelectedChoices={setSelectedLabels}
          setAllowedApply={setAllowedApply}
        />
      )}
    </Box>
  );
};

export default SpaceTrafficShareFilter;
