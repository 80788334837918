import React, { ReactNode } from "react";

import { Box } from "@mui/material";
import DateRangePicker from "./DateRangePicker";

 
import { DateRange, CustomStyle, DefinedRange } from "../types";

export interface DateRangePickerWrapperProps {
  open: boolean;
  toggle: () => void;
  initialDateRange?: DateRange;
  currentDateRange?: DateRange | null;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
   
  onChange: (dateRange: DateRange) => void;
  closeOnClickOutside?: boolean;
  wrapperClassName?: string;
  locale?: Locale;
  labelIcon?: ReactNode;
  onSave?: () => void;
  onCancel?: () => void;
  customStyle?: CustomStyle;
  showConfirmSection?: boolean;
  showBorderedDate?: boolean;
  showNoComparison?: boolean;
  showDefinedRanges?: boolean;
}

const DateRangePickerWrapper: React.FunctionComponent<DateRangePickerWrapperProps> =
  (props: DateRangePickerWrapperProps) => {
    const {
      closeOnClickOutside,
      wrapperClassName,
      toggle,
      open,
      // initialDateRange,
      currentDateRange,
    } = props;

    const [dateRange, setDateRange] = React.useState<DateRange>({
      ...currentDateRange,
    });

    const handleToggle = () => {
      if (closeOnClickOutside === false) {
        return;
      }

      setDateRange({ ...currentDateRange });

      toggle();
    };

    const handleKeyPress = (event: any) =>
      event?.key === "Escape" && handleToggle();

    return (
      <Box sx={{ position: "relative" }}>
        {open && (
          <Box
            sx={{
              position: "fixed",
              height: "100vh",
              width: "100vw",
              bottom: 0,
              zIndex: 0,
              right: 0,
              left: 0,
              top: 0,
            }}
            onKeyDown={handleKeyPress}
            onClick={handleToggle}
          />
        )}

        <Box
          sx={{ position: "relative", zIndex: 1 }}
          className={wrapperClassName}
        >
          <DateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            {...props}
          />
        </Box>
      </Box>
    );
  };

export default DateRangePickerWrapper;
