import { Box, Typography } from "@mui/material";

const SpacesHeader = () => {
  return (
    <Box
      px={4}
      bgcolor={"white"}
      sx={{
        display: "flex",
        alignItems: "center",
        height: "48px",
      }}>
      <Typography
        variant="headline3"
        display="flex"
        flexDirection="row"
        gap="12px"
        alignItems="center">
        Spaces
      </Typography>
    </Box>
  );
};

export default SpacesHeader;
