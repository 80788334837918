import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../themes/light.ts";
import { useForm } from "react-hook-form";
import { useSpace } from "./SpaceProvider.tsx";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import SpacesHeader from "./SpacesHeader.tsx";
import { getApiResponse } from "../../services/apiRequest.ts";
import { SPACES_ROUTE } from "../../pages/Applications/Router";
import CustomTabPanel from "../../components/CustomTabPanel.tsx";
import CatalogView, { ProductData } from "./CatalogView.tsx";
import { AuthorizedContent } from "../../components/AuthorizedContent";
import { PlatformRole } from "@noogata/types";
import {
  AutoEnrichIconDisabled,
  AutoEnrichIconEnabled,
  InfoIconV2,
} from "../../assets/index.tsx";
import "ag-grid-enterprise";
import ManualView from "./ManualView.tsx";
import LoadingCatalogState from "./LoadingCatalogState.tsx";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface DataType {
  space_id: string;
}

export interface AsinFormData {
  name: string;
  asinsList: string[];
  asins: string[];
  autoEnrich: boolean;
}

const AddSpaceV2 = () => {
  const history = useHistory();
  const { spacesListRefetch } = useSpace();
  const snackbar = useSnackbar();
  const [autoEnrichVal, setAutoEnrichVal] = useState(true);
  const [useSimilarityV1Val, setUseSimilarityV1Val] = useState(false);
  const [fileDroppedLoading, setFileDroppedLoading] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [tab, setTab] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [asinsFromGrid, setAsinsFromGrid] = useState<ProductData>({
    asins: [],
    total: 0,
    isDataReady: false,
  });

  const { control, formState, handleSubmit, watch, setValue, register } =
    useForm<AsinFormData>({
      mode: "onBlur",
      defaultValues: { name: "", asinsList: [], autoEnrich: true },
    });

  useEffect(() => {
    register("name", { required: true });
    register("asinsList", { required: true });
    register("autoEnrich");
  }, []);

  const { errors, isDirty } = formState;

  const name = watch("name");

  const totalProducts = useMemo(
    () => asinsFromGrid?.total,
    [asinsFromGrid?.total]
  );

  useEffect(() => {
    if (tab === 1) {
      setAsinsFromGrid({
        asins: [],
        total: totalProducts,
        isDataReady: false,
      });
      setValue("asinsList", [], {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [tab]);

  const parsedAsinsList = watch("asinsList");

  const redirectToNewAsin = (id: string) => {
    if (id) {
      history.push(`${SPACES_ROUTE}/${id}`);
    } else {
      history.push(SPACES_ROUTE);
    }
  };

  const exploreAsin = (id: string) => {
    const location = useLocation();
    spacesListRefetch();
    if (location.pathname === "/spaces/add") {
      redirectToNewAsin(id);
    }
    return (
      <>
        <Button
          sx={{
            color: theme.palette.primary.contrastText,
            fontSize: "14px",
          }}
          variant="text"
          onClick={() => redirectToNewAsin(id)}
        >
          Explore Now
        </Button>
      </>
    );
  };

  const onSubmit = useCallback(
    async (values: any, currentTab: number, products?: string[]) => {
      const response = await getApiResponse(
        "v1/spaces/sse",
        "POST",
        undefined,
        {
          asins: currentTab === 0 ? products : values.asins,
          name: values.name,
          autoEnrich: autoEnrichVal,
          useSimilarityV1: useSimilarityV1Val,
        }
      );
      if (!response.body) {
        return;
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let id: string = "";
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (value) {
          const matches = value.matchAll(/^.*"status":."(.*?)".*$/gm);
          for (const match of matches) {
            const jsonString = match[0].replace("data: ", "");
            const data: DataType = JSON.parse(jsonString);
            if (data.space_id) {
              id = data.space_id;
            }
            setMessage(match[1]);
            if (match[1] === "Space created!") {
              const createdSpaceTime = new Date().toISOString();
              const storageData = {
                createdSpaceTime,
                id: id,
              };
              localStorage.setItem("spaceData", JSON.stringify(storageData));
            }
          }
        }
        if (done) {
          snackbar.enqueueSnackbar("Your space is ready!", {
            variant: "success",
            action: () => exploreAsin(id),
          });
          break;
        }
      }
    },
    [autoEnrichVal, useSimilarityV1Val]
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const err = isDirty && !name;

  return (
    <Stack height={"100vh"}>
      {message ? (
        <LoadingCatalogState message={message} />
      ) : (
        <>
          {/* <SpacesHeader /> */}
          <Box
            sx={{
              flex: 1,
              background: theme.palette.grey[50],
              // padding: "12px",
              height: "100%",
              position: "relative",

              // overflow: "auto",
            }}
          >
            {/* <form
              onSubmit={handleSubmit((values) =>
                mutation
                  .mutateAsync(values)
                  .then((res) => {
                    setIsDrawerOpen(false);
                    snackbar.enqueueSnackbar("Space successfully created", {
                      variant: "success",
                    });
                    spacesListRefetch();
                    if (res?.id) {
                      history.push(`${SPACES_ROUTE}/${res?.id}`);
                    } else {
                      history.push(SPACES_ROUTE);
                    }
                  })
                  .catch((e) => {
                    snackbar.enqueueSnackbar("Something went wrong: " + e, {
                      variant: "error",
                    });
                  })
              )}> */}
            <form
              onSubmit={handleSubmit((values) =>
                onSubmit(values, tab, selectedProducts)
              )}
            >
              <Stack
                sx={{
                  background: "#FFF",
                  padding: "10px 20px 0 20px",
                  borderTop: "1px solid #E9E7F1",
                  boxShadow:
                    "0px 2px 10px -6px rgba(44, 34, 76, 0.05), 0px 2px 5px -4px rgba(44, 34, 76, 0.08)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    marginBottom: "5px",
                  }}
                >

                </Box>

                <Box sx={{ display: "flex", marginBottom: "5px" }}>
                  <Typography
                    variant="body2"
                    mt="10px"
                    sx={{ textWrap: "nowrap", fontWeight: 500 }}
                  >
                    Enter a space name:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      {...register("name", { required: true })}
                      error={err}
                      autoComplete="off"
                      sx={{
                        maxWidth: "685px",
                        paddingLeft: "16px",
                        "& .MuiInputBase-input": {
                          paddingLeft: "16px",
                        },
                      }}
                    />
                    {err && (
                      <Typography
                        variant="caption"
                        color="error"
                        ml={2}
                        mt={0.5}
                      >
                        Name is mandatory field
                      </Typography>
                    )}
                  </Box>
                </Box>
       

                  <Box
                    sx={{
                      width: 'auto',
                      background: "#eee5fe",
                      padding: '8px 16px',
                      borderRadius: '4px',
                      margin: "4px 0",
                      display: "flex",
                      alignItems: "center"
                    }}>
                    <InfoOutlinedIcon sx={{
                      color: theme.palette.text.primary,
                      margin: "0 4px",
                      fontSize:"18px"
                    }}/>
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{

                      }}
                    >
                      Select your product(s) to automatically generate a
                      market segment of similar items on Amazon and begin
                      receiving insights
                    </Typography>
                  </Box>
                <Stack spacing="24px">
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                      "& .MuiTabs-indicator": {
                        bottom: "12px",
                      },
                    }}
                  >
                    <Tab
                      label={`Select from catalog ${totalProducts > 0 ? `(${totalProducts})` : ""
                        }`}
                      {...a11yProps(0)}
                      sx={{
                        textTransform: "none",
                        typography: "body4",
                        fontWeight: 500,
                        padding: "8px",
                        height: "36px",
                        minHeight: "36px",
                        fontSize: "14px",
                        color: "#75717B",
                      }}
                    >
                    </Tab>
                    <Tab
                      label="Upload"
                      {...a11yProps(1)}
                      sx={{
                        textTransform: "none",
                        typography: "body4",
                        fontWeight: 500,
                        padding: "8px",
                        height: "36px",
                        minHeight: "36px",
                        fontSize: "14px",
                        color: "#75717B",
                      }}
                    />
                  </Tabs>
                </Stack>
              </Stack>
              <CustomTabPanel value={tab} index={0}>
                <CatalogView
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  asinsFromGrid={asinsFromGrid}
                  setAsinsFromGrid={setAsinsFromGrid}
                  setValue={setValue}
                  err={err}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <ManualView
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  setFileDroppedLoading={setFileDroppedLoading}
                />
              </CustomTabPanel>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: theme.palette.background.default,
                  height: "62px",
                  gap: "1rem",
                  boxShadow: "0px -5px 12px -6px #2C224C1A",
                  padding: "0 2rem",
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  width: "100%",
                }}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="autoEnrichVal"
                        sx={{ mx: 0 }}
                        checked={
                          !(
                            tab === 0 &&
                            asinsFromGrid?.isDataReady &&
                            asinsFromGrid?.asins?.length === 0
                          ) && autoEnrichVal
                        }
                        onChange={(e) => setAutoEnrichVal(e.target.checked)}
                        disabled={
                          tab === 0 &&
                          asinsFromGrid?.isDataReady &&
                          asinsFromGrid?.asins?.length === 0
                        }
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {!autoEnrichVal ||
                          (asinsFromGrid?.isDataReady &&
                            asinsFromGrid?.asins?.length === 0) ? (
                          <AutoEnrichIconDisabled />
                        ) : (
                          <AutoEnrichIconEnabled />
                        )}
                        Let Noogata AI identify competitors
                      </Box>
                    }
                  />
                  <AuthorizedContent
                    requiredRoles={[PlatformRole.NoogataAdministrator]}
                    render={(isAuthorized: boolean) => (
                      isAuthorized ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="useSimilarityV1Val"
                              sx={{ mx: 0 }}
                              checked={useSimilarityV1Val}
                              onChange={(e) => setUseSimilarityV1Val(e.target.checked)}
                              disabled={
                                !isAuthorized ||
                                (tab === 0 &&
                                  asinsFromGrid?.isDataReady &&
                                  asinsFromGrid?.asins?.length === 0)
                              }
                            />
                          }
                          label="Use Similarity V1 Model"
                        />
                      ) : null
                    )}
                  />
                </Box>
                <Box display="flex" gap="1rem" alignItems="center">
                  {tab === 0 &&
                    asinsFromGrid?.isDataReady &&
                    asinsFromGrid?.asins?.length === 0 ? (
                    ""
                  ) : tab === 0 ? (
                    <Typography variant="caption" color="#504C55">
                      Added {selectedProducts.length} ASINs
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="#504C55">
                      Added {parsedAsinsList.length} ASINs
                    </Typography>
                  )}
                  <Button
                    sx={{
                      minHeight: "32px",
                    }}
                    variant="contained"
                    disabled={
                      tab === 1
                        ? !formState.isValid ||
                        formState.isSubmitting ||
                        fileDroppedLoading
                        : formState.isSubmitting ||
                        !name ||
                        selectedProducts?.length === 0
                    }
                    type="submit"
                    onClick={() => {
                      if (tab === 0) {
                        onSubmit(
                          {
                            asinsList: [],
                            autoEnrich: autoEnrichVal,
                            name: name,
                          },
                          tab,
                          selectedProducts
                        );
                      }
                    }}
                    startIcon={
                      (formState.isSubmitting || fileDroppedLoading) && (
                        <CircularProgress
                          size="small"
                          sx={{
                            height: (t) => t.spacing(2),
                            width: (t) => t.spacing(2),
                            color: (t) => t.palette.grey[300],
                          }}
                        />
                      )
                    }
                  >
                    Create space
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default AddSpaceV2;
