import {FC, useEffect} from "react";
import {Button, Dialog, DialogContent, LinearProgress, Paper, Stack,} from "@mui/material";
import AceEditor from "react-ace";
import {useMutation} from "react-query";
import {Controller, useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import {minifyJsonObject, prettifyJson} from "../../utils";
import {createFlexBaseInsightV2, useFlexSchema} from "./useBaseInsight";
import {validateJsonField} from "../CreateNewRun/hooks/utils";
import {AuthorizedContent} from "../../components/AuthorizedContent";
import {PlatformRole} from "../../enums";
import {FlexInsight} from "./types";
import {useFilteredInsightsV2} from "./useInsights";

export const AddInsightModal: FC<object> = () => {
    const mutation = useMutation(createFlexBaseInsightV2);
    const {data: schema, isLoading} = useFlexSchema({
        refetchOnWindowFocus: false,
        onSuccess: (data) =>
            !editInsightJsonText &&
            setValue("editInsightJsonText", prettifyJson(data), {
                shouldValidate: true,
                shouldDirty: true,
            }),
    });
    const {
        control,
        formState: {isValid, isSubmitting, errors: {editInsightJsonText: editInsightJsonTextError},},
        watch,
        register,
        handleSubmit,
        setValue,
        reset,
    } = useForm<{
        editInsightJsonText: string;
        open: boolean;
    }>({
        mode: "onChange",
        defaultValues: {
            editInsightJsonText: prettifyJson(schema) || "",
            open: false,
        },
    });
    useEffect(() => {
    register("open");
  }, []);
  const editInsightJsonText = watch("editInsightJsonText");
  const isOpen = watch("open");
  const { refetch } = useFilteredInsightsV2();

  const { enqueueSnackbar } = useSnackbar();
  const onInsightEdit = handleSubmit(({ editInsightJsonText }) =>
    mutation
      .mutateAsync(JSON.parse(editInsightJsonText) as Partial<FlexInsight>)
      .then(() => {
        enqueueSnackbar("Insight updated successfully", {
          variant: "success",
        });
        reset();
        return refetch();
      })
      .catch((e) => {
        enqueueSnackbar(e.toString(), { variant: "error" });
      })
  );
  return (
    <AuthorizedContent requiredRoles={[PlatformRole.NoogataAdministrator]}>
      <Button
        size="small"
        variant="outlined"
        onClick={() => setValue("open", !isOpen)}
      >
        Add
      </Button>
      <Dialog open={isOpen} fullWidth keepMounted>
        {(isLoading || mutation.isLoading) && <LinearProgress />}
        <DialogContent>
          <Stack spacing={1}>
            <Stack alignContent="start" direction="row" spacing={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setValue("open", !isOpen)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={
                  minifyJsonObject(editInsightJsonText) ===
                    minifyJsonObject(schema) ||
                  !isValid ||
                  isSubmitting
                }
                onClick={() => onInsightEdit()}
              >
                Submit
              </Button>
            </Stack>
            <Paper
              variant="outlined"
              sx={{
                height: 300,
                width: "100%",
                borderColor: editInsightJsonTextError ? "red" : "none",
                overflow: "auto",
              }}
            >
              {isLoading && <LinearProgress />}
              <Controller
                control={control}
                rules={{
                  validate: (value) =>
                    validateJsonField(value) || "Invalid JSON Value",
                }}
                name="editInsightJsonText"
                  // @ts-ignore
                value={editInsightJsonText}
                render={(props) => (
                  <AceEditor
                    debounceChangePeriod={500}
                    readOnly={isLoading && !schema}
                    showPrintMargin={false}
                    style={{
                      width: "100%",
                    }}
                    {...props}
                  />
                )}
              />
            </Paper>
          </Stack>
        </DialogContent>
      </Dialog>
    </AuthorizedContent>
  );
};
