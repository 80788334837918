import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { DashboardOutlined } from "@mui/icons-material";

import { deployDashboards } from "../../services/Flows/api";

export const DeployFlowButton = ({ flowId }: { flowId?: string }) => {
  const deployDashboardMutation = useMutation((flowId?: string) =>
    deployDashboards(flowId),
  );
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() =>
        deployDashboardMutation
          .mutateAsync(flowId)
          .then(() =>
            enqueueSnackbar("Deployment started successfully", {
              variant: "success",
            }),
          )
          .catch(() =>
            enqueueSnackbar("Deployment started failed", {
              variant: "error",
            }),
          )
      }
      startIcon={<DashboardOutlined />}
      disabled={deployDashboardMutation.isLoading}
    >
      Deploy dashboards
    </Button>
  );
};
