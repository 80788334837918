import { useAmplitude } from "react-amplitude-hooks";
import { DestinationType } from "../../enums";

export const useExportEvents = (globalProperties: any = {}) => {
  const { logEvent } = useAmplitude((inheritedProps: any = {}) => ({
    ...inheritedProps,
    ...globalProperties,
    scope: [
      "export",
      ...(globalProperties.scope || []),
      ...(inheritedProps.scope || []),
    ],
  }));
  return {
    downloadOutputEvent: (properties?: object) =>
      logEvent("export.downloadOutputs", properties),
    exportToDestinationEvent: (properties?: object) =>
      logEvent("export.exportToDestination", properties),
    successfulExportToDestinationEvent: (
      type: DestinationType,
      destinationId?: string
    ) =>
      logEvent("export.exportToDestination", {
        destinationType: type,
        destinationId,
        success: true,
      }),
    failedExportToDestinationEvent: (
      type: DestinationType,
      destinationId?: string
    ) =>
      logEvent("export.exportToDestination", {
        destinationType: type,
        destinationId,
        success: false,
      }),
  };
};
