import { useEffect } from "react";

const SmartHeadTitle = () => {
  useEffect(() => {
    const { host } = window.location;
    const subdomain = host.split(".")[0];
    switch (subdomain) {
      case "app":
        document.title = "Noogata Platform";
        break;
      case "bizdev":
        document.title = "BIZDEV Noogata Platform";
        break;
      case "dev":
        document.title = "DEV Noogata Platform";
        break;
      default:
        document.title = "Local Noogata Platform";
    }
  }, []);
  return <></>;
};

export default SmartHeadTitle;
