import { useEffect, useMemo } from "react";
import { GroupedVirtuoso, TopItemListProps } from "react-virtuoso";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { Icon } from "../../../components/Icon";
import { InsightsListItem } from "./InsightsListItem";
import { useDisplayList, useInsightsContext } from "../hooks/selectors";

const VirtualList = ({
  setInsightIdForDialog,
}: {
  setInsightIdForDialog: (str: string) => void;
}) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const displayList = useDisplayList();
  const { sort, setSort } = useInsightsContext();

  useEffect(() => {
    if (pathname.search(/insights/) !== -1) {
      setSort({ ...sort, isDirty: false });
    }
  }, [pathname]);

  const groupCounts = useMemo(() => {
    let i = 0;
    displayList.every((insight) => {
      if (insight.status) {
        i += 1;
        return true;
      }
      return false;
    });
    return [i, displayList.length - i];
  }, [displayList]);

  return displayList.length ? (
    <GroupedVirtuoso
      style={{ flex: 1 }}
      groupCounts={groupCounts}
      defaultItemHeight={189}
      components={{
        TopItemList: ({ children, ...rest }: TopItemListProps) => (
          <div {...rest} style={{ position: "static", minHeight: "24px" }}>
            {children}
          </div>
        ),
      }}
      groupContent={(index) =>
        pathname.search("my-items") !== -1 &&
        !!index &&
        groupCounts[1] !== 0 &&
        !sort.isDirty ? (
          <Divider
            textAlign="left"
            sx={{
              marginY: "10px",
              marginX: "35px",
              "&::before": {
                width: "0",
              },
              "> span": {
                paddingLeft: 0,
              },
            }}
          >
            <Stack direction="row" alignItems="center" gap="8px">
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                component="div"
              >
                Past insights
              </Typography>
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography
                    variant="body5"
                    color={theme.palette.success.contrastText}
                  >
                    Insights that haven&apos;t been updated for at least 1 week
                    and may be inaccurate
                  </Typography>
                }
              >
                <Box
                  sx={{
                    color: theme.palette.icons.secondary,
                    cursor: "pointer",
                  }}
                  width="16px"
                  height="16px"
                >
                  <Icon
                    src="insights/info-fill.svg"
                    width="16px"
                    height="16px"
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Divider>
        ) : (
          <div style={{ height: "1px" }} />
        )
      }
      itemContent={(index) => {
        const insight = displayList[index];
        return (
          <InsightsListItem
            insight={insight}
            key={insight.id}
            setInsightIdForDialog={setInsightIdForDialog}
          />
        );
      }}
    />
  ) : null;
};

export default VirtualList;
