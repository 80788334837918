import {useAuthUser} from "@frontegg/react-hooks";
import {Button, ButtonProps, CircularProgress, DialogActions, OutlinedTextFieldProps, TextField,} from "@mui/material";
import {JSONSchema4} from "json-schema";
import {useSnackbar} from "notistack";
import {FC, Fragment} from "react";
import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "react-query";
import {useUserTenant} from "../../hooks";
import {createDataSource} from "../../services/datasources/api";
import {useTenantConfiguration} from "../../services/tenants/useTenantConfiguration";
import {
  createAmazonAdsOauthLink,
  createAmazonOauthLink,
  createShopifyPushLink,
  DataSourceType,
} from "../../types/datasources";
import {prettifyString} from "../../utils";

export type JsonSchemaFormControlsProps = {
  formMethods: any;
  schema: JSONSchema4;
  textFieldProps?: OutlinedTextFieldProps;
};

export const JsonSchemaFormControls: FC<JsonSchemaFormControlsProps> = ({
  formMethods,
  schema,
  textFieldProps,
}) => {
  // @ts-ignore
  const properties = Object.entries(schema.properties);

  return (
    <>
      {properties.map((property, index) => (
        <Fragment key={index}>
          <TextField
            sx={{ mt: 2 }}
            key={property[0]}
            label={prettifyString((property[1].title as string) || property[0])}
            helperText={property[1].description as string}
            variant="outlined"
            {...formMethods.register(property[0], {
              required: Boolean(
                schema.required
                  ? Array.isArray(schema.required)
                    ? schema.required.includes(property[0])
                    : schema.required
                  : false
              ),
            })}
            fullWidth
            {...textFieldProps}
          />
        </Fragment>
      ))}
    </>
  );
};

export type SchemaFormProps = {
  onClose: (open: boolean) => void;
  type: DataSourceType;
  schema: JSONSchema4;
  textFieldProps?: OutlinedTextFieldProps;
  submitLabel?: string;
  actionButtonProps?: ButtonProps;
};

const SchemaForm: FC<SchemaFormProps> = ({
  onClose,
  type,
  schema,
  textFieldProps,
  submitLabel,
  actionButtonProps,
}) => {
  const formMethods = useForm({ mode: "all" });

  const { isDirty, isValid, isSubmitting } = formMethods.formState;
  const user = useAuthUser();
  const tenant = useUserTenant(user || undefined);
  const { data: tenantConfig } = useTenantConfiguration(
    tenant?.tenantId || "",
    { enabled: !!tenant?.tenantId }
  );
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const dsMutation = useMutation(
    ({
      name,
      type,
      configuration,
    }: {
      name: string;
      type: DataSourceType;
      configuration: object;
    }) =>
      createDataSource({
        name,
        type,
        configuration,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ListDataSources"]);
        enqueueSnackbar("Successfully created data source.", {
          variant: "success",
        });
      },

      onError: (error) => {
        enqueueSnackbar(`Failed to create data source: ${error}`, {
          variant: "error",
        });
      },
    }
  );

  const onSubmit = formMethods.handleSubmit(async (formValues) => {
    if (type === DataSourceType.AMAZON_NORTH_AMERICA) {
      window.open(
        createAmazonOauthLink({
          isSeller: !!tenantConfig?.configuration?.is_seller,
          state: formValues.name,
        }),
        "_blank"
      );
    } else if (type === DataSourceType.AMAZON_ADS_NORTH_AMERICA) {
      window.open(
        createAmazonAdsOauthLink({ state: formValues.name }),
        "_blank"
      );
    } else if (type === DataSourceType.SHOPIFY_PUBLIC) {
      window.open(
        createShopifyPushLink({
          shopName: formValues.shop_name || "",
          state: formValues.name,
        }),
        "_blank"
      );
    } else if (type === DataSourceType.SHOPIFY) {
      dsMutation.mutate({
        name: formValues.name,
        type: DataSourceType.SHOPIFY,
        configuration: {
          accessToken: formValues.access_token,
          shopName: formValues.shop_name || "",
        },
      });
    } else if (type === DataSourceType.WALMART) {
      dsMutation.mutate({
        name: formValues.name,
        type: DataSourceType.WALMART,
        configuration: {
          client_id: formValues.client_id,
          client_secret: formValues.client_secret || "",
        },
      });
    }
    onClose(false);
  });

  return (
    <form>
      <JsonSchemaFormControls
        formMethods={formMethods}
        schema={schema}
        textFieldProps={textFieldProps}
      />
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="text"
          color="primary"
          disabled={!isDirty || !isValid || isSubmitting}
          {...actionButtonProps}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            submitLabel || "Submit"
          )}
        </Button>
      </DialogActions>
    </form>
  );
};

export default SchemaForm;
