import { FC } from "react";

import { Box, BoxProps, Chip } from "@mui/material";
import useRunColor from "../../../../hooks/useRunColor";
import theme from "../../../../themev2";
import { prettifyString } from "../../../../utils";
import { ExportStatus, Status } from "../../../../enums";
import { Run } from "../../../../types";
import StatusIcon from "../StatusIcon";

export const mapLabelDetails = (
  warning?: boolean,
  status?: Status | null
): { color: string; text: string } => {
  switch (status) {
    case Status.READY_TO_EXPORT:
      return {
        color: theme.palette.primary.main,
        text: prettifyString(Status.READY_TO_EXPORT),
      };
    case Status.PENDING:
    case Status.GATHERING:
    case Status.RUNNING:
    case Status.EXPORTING:
      return {
        color: theme.palette.primary.main,
        text: prettifyString(Status.RUNNING),
      };
    case Status.SUCCEEDED:
      return {
        color: warning
          ? theme.palette.warning.dark
          : theme.palette.success.dark,
        text: prettifyString(ExportStatus.SUCCESS),
      };
    case Status.TERMINATED:
    case Status.FAILED:
      return {
        color: theme.palette.error.dark,
        text: prettifyString(Status.FAILED),
      };
    case Status.UNKNOWN:
    default:
      return {
        color: theme.palette.primary.contrastText,
        text: prettifyString(Status.UNKNOWN),
      };
  }
};

const RunStatus: FC<{ run: Run; boxProps?: BoxProps; small?: boolean }> = ({
  run,
  boxProps = {},
  small = false,
}) => {
  const chipColor = useRunColor(run);
  const warning =
    Number(run?.tracking_statistics?.message_severities?.warning) > 0;
  const { color: labelColor, text } = mapLabelDetails(warning, run?.status);

  return (
    <Box {...boxProps}>
      <Chip
        sx={{
          backgroundColor: chipColor,
          color: labelColor,
          height: small ? "24px" : "32px",
          minWidth: "110px",
        }}
        label={text}
        // @ts-ignore
        icon={<StatusIcon status={run.status} />}
      />
    </Box>
  );
};

export default RunStatus;
