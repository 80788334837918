import { FC } from "react";

import { useHistory } from "react-router-dom";
import { Stack } from "@mui/material";
import { useQueryParams } from "../../hooks";
import { useSalesAssistantStyles } from "./useSalesAssistantStyles";
import { Header } from "./Header";
import { InsightsList } from "./List/List";
import { SalesDrawer } from "./List/Drawer/SalesDrawer";
import { SalesQueryParams } from "./constants";

export const SalesAssistantV1: FC = () => {
  const queryParams = useQueryParams();
  const { insightsBody } = useSalesAssistantStyles();
  const history = useHistory();
  return (
    <>
      <Stack className={insightsBody} data-id="test">
        <Header />
        <InsightsList />
        {queryParams.has(SalesQueryParams.InsightId) && (
          <SalesDrawer
            onClose={() => {
              queryParams.delete(SalesQueryParams.InsightId);
              history.replace({
                search: queryParams.toString(),
              });
            }}
            open={queryParams.has(SalesQueryParams.InsightId)}
          />
        )}
      </Stack>
    </>
  );
};
