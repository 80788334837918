import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ContentType, Descendant } from "./types.ts";
import { useParams } from "react-router-dom";
import { HistoryEditor, withHistory } from "slate-history";
import { ReactEditor, withReact } from "slate-react";
import { createEditor } from "slate";
import {
  usePerfectContentQuery,
  useSpaceKeywordsQuery,
} from "../SpacesV2/useSpaces.ts";
import { Keywords } from "../SpacesV2/types.ts";
import { PerfectContentProduct } from "../../types/ecommerce.ts";
import { getPermutationsRegex } from "./contentEditor.utils.ts";

export type PerfectContentState = {
  isLoading: boolean;
  spaceId?: string;
  asin?: string;
  activeTab?: ContentType;
  setActiveTab: Dispatch<SetStateAction<ContentType>>;
  editor: ReactEditor & HistoryEditor;
  editorContentValue: Descendant[];
  setEditorContentValue: Dispatch<SetStateAction<Descendant[]>>;
  keywordsList: Keywords[];
  selectedKeywords: string[];
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>;
  productData?: PerfectContentProduct;
};

export const PerfectContentContext = createContext<PerfectContentState>(
  {} as PerfectContentState,
);

export const usePerfectContent = () => useContext(PerfectContentContext);

export const PerfectContentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeTab, setActiveTab] = useState<ContentType>("title");
  const [editorContentValue, setEditorContentValue] = useState<Descendant[]>([
    {
      children: [
        {
          text: "",
        },
      ],
    },
  ]);
  const { spaceId, asin } = useParams<{ spaceId: string; asin: string }>();
  const editor = useMemo(
    () => withHistory(withReact(createEditor() as ReactEditor)),
    [],
  );

  const { data: keywordsList, isLoading: isLoadingKeywords } =
    useSpaceKeywordsQuery({
      spaceId: spaceId,
      limit: 100,
    });

  const { data: asinsList, isLoading: isLoadingAsins } = usePerfectContentQuery(
    {
      spaceId: spaceId,
    },
  );
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

  const productData = useMemo(() => {
    return asinsList?.data?.filter((item) => item.asin === asin)?.[0];
  }, [asinsList?.data, asin]);

  useEffect(() => {
    setEditorContentValue([
      {
        children: [
          {
            text: productData?.[activeTab] ?? "",
          },
        ],
      },
    ]);
  }, [productData, activeTab]);

  const isLoading = useMemo(
    () => isLoadingAsins || isLoadingKeywords,
    [isLoadingAsins, isLoadingKeywords],
  );

  const highlightedKeywords = useMemo(() => {
    return keywordsList?.data?.filter((keyword) =>
      getPermutationsRegex([keyword.search_term]).test(
        productData?.[activeTab] ?? "",
      ),
    );
  }, [productData, activeTab, keywordsList?.data]);

  const value = {
    isLoading,
    spaceId,
    asin,
    activeTab,
    setActiveTab,
    editor,
    editorContentValue,
    setEditorContentValue,
    keywordsList: keywordsList?.data || [],
    selectedKeywords,
    setSelectedKeywords,
    productData,
    highlightedKeywords,
  };

  return (
    <PerfectContentContext.Provider value={value}>
      {children}
    </PerfectContentContext.Provider>
  );
};
