import {MenuItem, TextField} from "@mui/material";
import {useGoogleWorksheets} from "../../../../services/connectors";
import {GoogleWorksheet} from "../../../../types/connectorsV2";
import {FC} from "react"

const WorksheetSelect: FC<{
    sheetId?: string;
    worksheet: GoogleWorksheet | null;
    setWorksheet: (worksheet: GoogleWorksheet | null) => void;
}> = ({sheetId, worksheet, setWorksheet}) => {
    const {data: sheetMeta, isLoading} = useGoogleWorksheets(sheetId || "", {
        enabled: !!sheetId,
        onSuccess: (data) => !worksheet && setWorksheet(data.worksheets[0]),
    });
  const worksheets = sheetMeta && sheetMeta.worksheets;

  return (
    <TextField
      id="worksheet-select"
      label="Worksheet"
      value={worksheet?.title || ""}
      onChange={(event) =>
        setWorksheet(
          worksheets!.find(
            (worksheet) => worksheet.title === (event.target.value as string)
          ) || null
        )
      }
      variant="outlined"
      disabled={!sheetId || isLoading}
      fullWidth
      select
    >
      {worksheets &&
        worksheets.map((worksheet) => (
          <MenuItem value={worksheet.title}>{worksheet.title}</MenuItem>
        ))}
    </TextField>
  );
};
export default WorksheetSelect;
