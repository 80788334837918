import { FC, ReactNode, SyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
  //Tooltip,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UseQueryResult } from "react-query";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { useQueryParams, useSplitEnabled } from "../../../../hooks";
import { SalesQueryParams } from "../../constants";
import { useInsight } from "../../useSalesAssistant";
import { Insight } from "../../../../types";
import { APIError } from "../../../../services/apiRequest";
import TabsButtons from "../TabButtons";
import { useLocalSalesDrawer } from "../../../../hooks/useLocalInsightV2";
import { RevenueBox } from "./RevenueBox";
import theme, { palette } from "../../../../themes/light";
import { Icon } from "../../../../components/Icon";
import { ProductImage } from "../ProductImage";
import { formatCurrencyOnlyNumber } from "../../../InsightsV2/SummaryV2";
import { Split } from "../../../../FeatureFlags/enums";
import { useSalesContext } from "../../hooks/selectors";
import { AddTask, TaskIcon } from "../../../../assets";
import CreateTask from "./CreateTask";
import { SalesTaskListing } from "../../../Tasks/SalesTaskListing";
import { useTaskListData } from "../../../Tasks/useTaskApi";
import { useTenantsState } from "@frontegg/react-hooks/auth";
import { useTasksContext } from "../../../Tasks/hooks/selectors";

// import expand from "../../../../../public/insights/expand.svg";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{
        backgroundColor: theme.palette.grey[25],
        padding: "20px 24px 20px 16px",
        borderRadius: "0px 12px 12px 12px",
        width: "calc(100% - 0px)",
        position: "relative",
      }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function TabPanelTop(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{
        backgroundColor: "transparent",
        padding: "0px",
        borderRadius: "0px 12px 12px 12px",
        width: "calc(100% - 0px)",
        position: "relative",
      }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export const useLocalInsight = (): UseQueryResult<Insight, APIError> => {
  const insightId = useQueryParams().get(SalesQueryParams.InsightId);
  return useInsight(insightId);
};

const DrawerTop: FC<{
  onClose: () => void;
  handleCreate: () => void;
  salesAddTask: boolean;
  taskList: any;
}> = ({ onClose, handleCreate, salesAddTask }) => {
  const theme = useTheme();
  const { data: insight } = useLocalSalesDrawer();
  const [isClicked, setIsClicked] = useState(false);
  const { refetchSalesDrawer, sortDays, setSortDays } = useSalesContext();
  const { activeTenant } = useTenantsState();
  const isTracking = useSplitEnabled(Split.TRACKING);

  useEffect(() => {
    refetchSalesDrawer();
  }, [isClicked]);

  const handleSortDays = (value: string) => {
    setSortDays(value);
    const sortDaysValue = {
      value: value,
      tenentID: activeTenant?.id,
    };
    localStorage.setItem("sortDays", JSON.stringify(sortDaysValue));
  };
  return (
    <Box display="flex" justifyContent="space-between">
      <IconButton onClick={onClose} sx={{ background: "none", p: 2.5 / 8 }}>
        <CloseIcon
          sx={{ fontSize: "22px" }}
          htmlColor={theme.palette.icons.secondary}
        />
      </IconButton>
      <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
        {insight ? (
          <>
            {salesAddTask && (
              <Button
                sx={{
                  display: "flex",
                  gap: "8px",
                  boxShadow: "0px 1px 1px 0px rgba(44, 34, 76, 0.04)",
                  border: "1px solid #D7D5DB",
                  color: "#fff",
                  height: "32px",
                  minHeight: "32px",
                  padding: "6px 12px",
                  backgroundColor: "#8E4FF6",
                  "& svg": {
                    path: {
                      fill: "#fff",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "#8E4FF6",
                  },
                  "&:active":{
                    backgroundColor: '#8E4FF6',
                  },
                  "&:focus":{
                    backgroundColor: '#8E4FF6',
                  }
                }}
                onClick={handleCreate}
              >
                <AddTask /> Add task
              </Button>
            )}
          </>
        ) : (
          <Skeleton
            width="107px"
            height="32px"
            sx={{ transform: "scale(1)" }}
          />
        )}
        {isTracking && (
          <>
            {insight ? (
              <TabsButtons
                insight={insight}
                outlined
                setIsClicked={setIsClicked}
              />
            ) : (
              <Skeleton
                width="180px"
                height="32px"
                sx={{ transform: "scale(1)" }}
              />
            )}
          </>
        )}
        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
          size="small"
          sx={{
            height: "32px",
            "& .MuiButtonGroup-grouped": {
              minWidth: "43px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 8px",
              height: "32px",
              minHeight: "32px",
            },
          }}
        >

          <Button
            onClick={() => (sortDays === "7d" ? {} : handleSortDays("7d"))}
            sx={{
              background:
                sortDays === "7d" ? theme.palette.secondary.main : theme.palette.backgrounds.white,
              // background: theme.palette.backgrounds.white,
              cursor: sortDays === "7d" ? "default" : "pointer",
              border: sortDays === "7d" ? `1px solid ${theme.palette.secondary.main} !important` : `1px solid ${theme.palette.grey[200]} !important`,
              // opacity: sortDays === "7d" ? 0.5 : 1,
              "&[disabled]": {
                background: theme.palette.backgrounds.default,
              },
              "&:hover": {
                border: `1px solid ${theme.palette.secondary.main} !important`,
                background: sortDays === "7d" ? theme.palette.secondary.main : theme.palette.backgrounds.white,
              },
              fontSize: "13px",
              lineHeight: "24px",
              color: sortDays === "7d" ? "#FFFFFF" : theme.palette.secondary.main,
            }}
          >
            7d
          </Button>

          <Button
            onClick={() => (sortDays === "28d" ? {} : handleSortDays("28d"))}
            sx={{
              background:
                sortDays === "28d" ? theme.palette.secondary.main : theme.palette.backgrounds.white,
              cursor: sortDays === "28d" ? "default" : "pointer",
              border: sortDays === "28d" ? `1px solid ${theme.palette.secondary.main} !important` : `1px solid ${theme.palette.grey[200]} !important`,
              // opacity: sortDays === "28d" ? 0.5 : 1,
              "&:hover": {
                border: `1px solid ${theme.palette.secondary.main} !important`,
                background: sortDays === "28d" ? theme.palette.secondary.main : theme.palette.backgrounds.white,
              },
              "&[disabled]": {
                background: theme.palette.backgrounds.default,
              },
              fontSize: "13px",
              lineHeight: "24px",
              color:
                sortDays === "28d" ? "#FFFFFF" : theme.palette.secondary.main,
            }}
          >
            28d
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

const DrawerTitle: FC<{
  insight?: any;
}> = ({ insight }) => {
  const theme = useTheme();
  return insight ? (
    <Stack
      justifyContent="space-between"
      alignItems="start"
      direction="row"
      sx={{ marginTop: "28px" }}
    >
      <Stack direction="row" alignItems="center" spacing={1} width="55%">
        <ProductImage insight={insight} useTooltip size="small" />
        <Stack justifyContent="space-between" alignItems="start">
          {insight?.title && (
            <Box sx={{ zoom: 0.85 }}>
              <Typography variant="h2_v2">
                {`${insight?.title?.substring(0, 30)}...`}
              </Typography>
            </Box>
          )}
          <Box display="flex" gap="8px" alignItems="center">
            <Chip
              label={insight?.asin}
              size="small"
              sx={{
                borderRadius: "500px",
                padding: "4px",
                background: theme.palette.backgrounds.info,
                color: theme.palette.grey[500],
              }}
            />
            {insight?.categories && (
              <Typography variant="caption" color="text.secondary">
                {`${
                  insight?.categories?.split("|")[0]
                } | ... | ${insight?.categories?.split("|").pop()}`}
              </Typography>
            )}
          </Box>
        </Stack>
      </Stack>
      <RevenueBox />
    </Stack>
  ) : (
    <Skeleton width="100%" height="64px" sx={{ transform: "scale(1)" }} />
  );
};

export const EmbeddedQuestion: FC<{ insight: any }> = ({ insight }) => (
  <Paper
    variant="outlined"
    sx={{
      padding: "12px",
      height: insight.iframe_link ? "400px" : "220px",
      borderRadius: "8px",
      marginLeft: "32px",
    }}
  >
    <Stack
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      {insight.iframe_link ? (
        <iframe
          title={insight.title || ""}
          src={insight.iframe_link as string}
          style={{ border: 0 }}
          width="100%"
          height="100%"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "auto",
            textAlign: "center",
            padding: "0px 80px",
            gap: "20px",
            margin: "30px 0px",
          }}
        >
          <Box>
            <img src="/insights/noads.svg" alt="noogata" />
          </Box>
          <Box display="flex" gap="4px" flexDirection="column">
            <Typography
              variant="body3"
              color={theme.palette.text.primary}
              sx={{ fontWeight: 600 }}
            >
              No Data found
            </Typography>
          </Box>
        </Box>
      )}
    </Stack>
  </Paper>
);

export const SalesDrawer: FC<{
  onClose: () => void;
  open: boolean;
  handleClick?: () => void;
}> = ({ open, onClose }) => {
  const { data: insight } = useLocalSalesDrawer();
  const {
    data: taskList,
    refetch,
    isLoading,
    isFetching,
  } = useTaskListData(insight?.asin);
  const {
    setCreatePopup,
    createPopup,
    setType,
    isUpdated,
    setTaskData,
    initialTaskState,
  } = useTasksContext();
  useEffect(() => {
    if (insight?.asin) {
      refetch();
    }
  }, [insight?.asin, isUpdated]);

  const enableAlert = useSplitEnabled(Split.SALES_ASSISTANT_ALERTS);
  // const [value, setValue] = useState(0);
  const [isExpand, setIsExpand] = useState<number[]>([]);
  const salesActivityEnabled = useSplitEnabled(Split.SALES_ASSISTANT_ACTIVITY);
  const salesAddTask = useSplitEnabled(Split.SALES_ASSISTANT_TASKS);
  const [outerTabValue, setOuterTabValue] = useState(0);
  const [innerTabValue, setInnerTabValue] = useState(0);
  const [loading] = useState<boolean>(false);

  const handleOuterTabChange = (_event: SyntheticEvent, newValue: number) => {
    setOuterTabValue(newValue);
  };

  const handleInnerTabChange = (_event: SyntheticEvent, newValue: number) => {
    setInnerTabValue(newValue);
    setIsExpand([]);
  };

  // const handleChange = (event: SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const handleExpand = (value: number) => {
    if (isExpand.includes(value)) {
      const newIsExpand = isExpand.filter((item) => item !== value);
      setIsExpand(newIsExpand);
    } else {
      setIsExpand([...isExpand, value]);
    }
  };

  const handleCreate = () => {
    setType("Add");
    setTaskData(initialTaskState);
    setCreatePopup(!createPopup);
  };

  return (
    <Drawer
      PaperProps={{
        variant: "outlined",
        elevation: 0,
        sx: {
          width: "1120px",
        },
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Stack p={3} spacing={3.5} sx={{ height: "100%" }}>
        <DrawerTop
          onClose={onClose}
          handleCreate={handleCreate}
          salesAddTask={salesAddTask}
          taskList={taskList}
        />
        <DrawerTitle insight={insight} />
        <Divider
          style={{
            width: "calc(100% + 48px)",
            marginLeft: "-24px",
            marginTop: "16px",
          }}
        />
        {insight?.alerts && enableAlert && <RevenueAlert insight={insight} />}

        <Box style={{ marginTop: 0 }}>
          <Tabs
            orientation="horizontal"
            // variant="scrollable"
            value={outerTabValue}
            onChange={handleOuterTabChange}
            aria-label="Vertical tab"
            sx={{
              borderRight: 0,
              borderColor: "divider",
              padding: "0px",
              gap: "8px",
              minWidth: "160px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              marginBottom: "24px",
              marginLeft: "-24px",
              marginRight: "-24px",
              paddingLeft: "24px",
              "& .MuiTabs-flexContainer": {
                gap: "8px",
              },
              "& .Mui-selected": {
                backgroundColor: "transparent",
                borderRadius: "0",
                color: "red !important",
                borderBottom: "2px solid #8E4FF6",
                "& span": {
                  color: "#8E4FF6 !important",
                  "& svg ": {
                    "& path": {
                      fill: "#8E4FF6 !important",
                    },
                  },
                },
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab
              label={
                <Box>
                  <Typography
                    variant="body4"
                    color="text.primary"
                    sx={{ textAlign: "left", lineHeight: "24px" }}
                    display="flex"
                    gap="8px"
                    alignItems="center"
                  >
                    <Icon src="sales/new.svg" height="16px" width="16px" />{" "}
                    Diagnostics
                  </Typography>
                </Box>
              }
            />
            {salesActivityEnabled && (
              <Tab
                sx={{ padding: "8px 12px" }}
                label={
                  <Box>
                    <Typography
                      variant="body4"
                      sx={{ textAlign: "left", lineHeight: "24px" }}
                      color="text.primary"
                      display="flex"
                      gap="8px"
                      alignItems="center"
                    >
                      <Icon
                        src="sales/chat-fill.svg"
                        height="16px"
                        width="16px"
                      />{" "}
                      Activity
                    </Typography>
                  </Box>
                }
              />
            )}
            {salesAddTask && (
              <Tab
                label={
                  <Box>
                    <Typography
                      variant="body4"
                      color="text.primary"
                      sx={{ textAlign: "left", lineHeight: "24px" }}
                      display="flex"
                      gap="8px"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          height: "16px",
                          width: "17px",
                          "& svg": { height: "16px", width: "17px" },
                        }}
                      >
                        <TaskIcon />
                      </Box>
                      Tasks
                      <Box
                        sx={{
                          background: theme.palette.borders.lines,
                          height: 20,
                          minWidth: 20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 30,
                        }}
                      >
                        <Typography
                          variant="body4"
                          color={theme.palette.grey[400]}
                        >
                          {taskList?.length || 0}
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                }
              />
            )}
          </Tabs>

          <TabPanelTop value={outerTabValue} index={0}>
            {!insight ? (
              <Skeleton
                width="100%"
                height="372px"
                sx={{ transform: "scale(1)" }}
              />
            ) : (
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <Tabs
                  orientation="vertical"
                  // variant="scrollable"
                  value={innerTabValue}
                  onChange={handleInnerTabChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderRight: 0,
                    borderColor: "divider",
                    padding: "0px",
                    gap: "8px",
                    minWidth: "160px",

                    "& .MuiTabs-flexContainer": {
                      gap: "8px",
                    },
                    "& .MuiButtonBase-root.MuiTab-root": {
                      padding: "8px 12px",
                    },
                    "& .Mui-selected": {
                      backgroundColor: theme.palette.grey[25],
                      borderRadius: "12px 0px 0px 12px",
                      padding: "8px 12px",
                    },
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  {insight?.tabs?.map((item: any, i: any) => (
                    <Tab
                      key={i}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: "8px",
                            alignItems: "flex-start",
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant="caption"
                            color="text.primary"
                            sx={{
                              textAlign: "left",
                              lineHeight: "16px",
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              textTransform: "initial",
                              color: theme.palette.grey[400],
                            }}
                          >
                            {item.title}
                          </Typography>
                          <RevenueSection
                            insight={insight}
                            title={item.title}
                            unit={item?.unit}
                            value_7d={Number(item?.value_7d)}
                            value_28d={Number(item?.value_28d)}
                            change_value_7d={Number(item?.change_value_7d)}
                            change_value_28d={Number(item?.change_value_28d)}
                          />
                        </Box>
                      }
                    />
                  ))}
                </Tabs>
                {insight?.tabs?.map((el: any, index: any) => (
                  <TabPanel value={innerTabValue} index={index} key={index}>
                    {el.tab_data.map((ele: any, i: any) => (
                      <Box
                        key={i}
                        sx={{
                          position: "relative",
                          "&:before": {
                            content: '""',
                            position: "absolute",
                            height: "90%",
                            width: "1px",
                            background: "#D7D5DB",
                            top: i === 0 ? "29px" : "-8px",
                            left: "10px",
                            display:
                              el.tab_data.length - 1 === i ? "none" : "block",
                          },
                        }}
                      >
                        {ele?.closeable === false ? (
                          <>
                            <Typography
                              mt={i === 0 ? "0px" : "20px"}
                              mb="10px"
                              variant="body2"
                              color={palette.text.primary}
                              display="flex"
                              alignItems="center"
                              gap="12px"
                              sx={{ position: "relative" }}
                            >
                              <Icon
                                src="insights/expand.svg"
                                width="24px"
                                height="24px"
                              />
                              {ele?.title}
                            </Typography>
                            {loading &&
                            ((i !== 0 && index !== 0) ||
                              i !== 0 ||
                              index !== 0) ? (
                              <Skeleton
                                width="calc(100% - 32px)"
                                height="400px"
                                sx={{
                                  borderRadius: "8px",
                                  marginLeft: "32px",
                                  transform: "scale(1)",
                                }}
                              />
                            ) : (
                              <EmbeddedQuestion insight={ele} />
                            )}
                          </>
                        ) : (
                          <>
                            <Typography
                              mt="20px"
                              mb="10px"
                              variant="body4"
                              color={palette.text.link}
                              display="flex"
                              alignItems="center"
                              gap="8px"
                              paddingLeft="32px"
                              sx={{ cursor: "pointer", position: "relative" }}
                              onClick={() => handleExpand(i)}
                            >
                              <Icon
                                src={
                                  isExpand.includes(i)
                                    ? "insights/collapse.svg"
                                    : "insights/addIcon.svg"
                                }
                                width="16px"
                                height="16px"
                              />{" "}
                              {ele?.title}
                            </Typography>
                            {isExpand.includes(i) && (
                              <>
                                {loading &&
                                ((i !== 0 && index !== 0) ||
                                  i !== 0 ||
                                  index !== 0) ? (
                                  <Skeleton
                                    width="calc(100% - 32px)"
                                    height="400px"
                                    sx={{
                                      borderRadius: "8px",
                                      marginLeft: "32px",
                                      transform: "scale(1)",
                                    }}
                                  />
                                ) : (
                                  <EmbeddedQuestion insight={ele} />
                                )}
                              </>
                            )}
                          </>
                        )}
                        <img
                          src="/insights/timeline.svg"
                          alt="timeline"
                          style={{
                            position: "absolute",
                            bottom: "-31px",
                            left: "10px",
                            display:
                              el.tab_data.length - 1 === i ? "none" : "block",
                          }}
                        />
                      </Box>
                    ))}
                  </TabPanel>
                ))}
              </Box>
            )}
          </TabPanelTop>
          {salesActivityEnabled && (
            <TabPanelTop value={outerTabValue} index={1}>
              <Box>
                <img
                  src="/sales/activity-img.png"
                  alt="activityimg"
                  width="100%"
                />
              </Box>
            </TabPanelTop>
          )}
          <TabPanelTop value={outerTabValue} index={1}>
            <SalesTaskListing
              taskList={taskList}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </TabPanelTop>
        </Box>
        <CreateTask
          open={createPopup}
          handleClose={handleCreate}
          insight={insight}
        />
      </Stack>
    </Drawer>
  );
};

export const RevenueSection = ({
  insight,
  title,
  unit,
  value_7d,
  value_28d,
  change_value_7d,
  change_value_28d,
}: any) => {
  const theme = useTheme();
  const width = theme.spacing(124 / 8);
  const height = theme.spacing(64 / 8);
  const { sortDays } = useSalesContext();

  if (!insight) {
    return <Skeleton width={width} height={height} />;
  }

  return insight ? (
    <Stack direction="row" alignItems="center">
      {sortDays === "7d" ? (
        <Typography
          variant="body3"
          sx={{ fontWeight: 600 }}
          color={theme.palette.text.primary}
        >
          {title === "Price" || title === "Ad spend" ? (
            <>
              {unit}
              {formatCurrencyOnlyNumber(value_7d)}
            </>
          ) : title === "Share of Voice" || title === "Conversion" ? (
            <>
              {(value_7d * 100)?.toFixed(1)}
              {unit}
            </>
          ) : (
            <>
              {formatCurrencyOnlyNumber(value_7d)}
              {unit}
            </>
          )}
        </Typography>
      ) : (
        <Typography
          variant="body3"
          sx={{ fontWeight: 600 }}
          color={theme.palette.text.primary}
        >
          {title === "Price" || title === "Ad spend" ? (
            <>
              {unit}
              {formatCurrencyOnlyNumber(value_28d)}
            </>
          ) : title === "Share of Voice" || title === "Conversion" ? (
            <>
              {(value_28d * 100)?.toFixed(1)}
              {unit}
            </>
          ) : (
            <>
              {formatCurrencyOnlyNumber(value_28d)}
              {unit}
            </>
          )}
        </Typography>
      )}
      <Stack direction="row" alignItems="end" pl={0.5}>
        {value_7d == null || value_28d == null || Number.isNaN(value_7d) ? (
          <Typography
            variant="body4"
            sx={{
              width: "20px",
              height: "20px",
            }}
          >
            (n/a)
          </Typography>
        ) : (
          <>
            {sortDays === "7d" ? (
              <>
                {change_value_7d != null &&
                  (change_value_7d >= 0 ? (
                    <NorthEastIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: (theme) =>
                          change_value_7d >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main,
                      }}
                    />
                  ) : (
                    <SouthEastIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: (theme) =>
                          change_value_7d >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main,
                      }}
                    />
                  ))}
                {Number.isFinite(change_value_7d) && (
                  <Typography
                    variant="caption"
                    sx={{
                      paddingLeft: "2px",
                      color: (theme) =>
                        change_value_7d != null
                          ? change_value_7d >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main
                          : theme.palette.text.secondary,
                    }}
                  >
                    {change_value_7d.toFixed(1)}%
                  </Typography>
                )}
              </>
            ) : (
              <>
                {change_value_28d != null &&
                  (change_value_28d >= 0 ? (
                    <NorthEastIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: (theme) =>
                          change_value_28d >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main,
                      }}
                    />
                  ) : (
                    <SouthEastIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: (theme) =>
                          change_value_28d >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main,
                      }}
                    />
                  ))}
                {Number.isFinite(change_value_28d) && (
                  <Typography
                    variant="caption"
                    sx={{
                      paddingLeft: "2px",
                      color: (theme) =>
                        change_value_28d != null
                          ? change_value_28d >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main
                          : theme.palette.text.secondary,
                    }}
                  >
                    {change_value_28d.toFixed(1)}%
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </Stack>
  ) : (
    <Skeleton width={width} height={height} />
  );
};

const RevenueAlert = (insight: any) => (
  <Stack
    display="flex"
    flexDirection="row"
    sx={{
      minHeight: "44px",
      marginTop: "16px !important",
      marginBottom: "12px !important",
    }}
  >
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: "#F9E7E9",
        borderRadius: "12px 0px 0px 12px",
        padding: "4px",
        maxWidth: "160px",
        width: "100%",
        gap: "10px",
        paddingLeft: "15px",
      }}
    >
      <Icon src="sales/revenueAlert.svg" height="24px" width="24px" />
      <Box>
        <Typography variant="caption">Revenue Alert</Typography>
        <Typography sx={{ fontSize: "10px", fontWeight: 500 }}>
          {insight?.alerts[0]?.updated_at}{" "}
        </Typography>
      </Box>
    </Box>
    <Box
      sx={{
        backgroundColor: theme.palette.grey[25],
        padding: "8px 20px",
        width: "calc(100% - 160px)",
        borderRadius: "0px 12px 12px 0px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="body3" sx={{ fontWeight: 500 }}>
        Revenue for this product declined by
        {insight?.alerts[0]?.data?.decrease_percentage} compared to the previous
        week
      </Typography>
    </Box>
  </Stack>
);
