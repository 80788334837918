import { isEqual } from "lodash"; // Assuming you're using lodash for deep comparison
import { AssortmentFilters, TableColumn } from "./types";
import { Features } from "../SpacesV2/types";
import { parseJsonRecursive } from "../ResearchAssistant/utils";

export const compareFilters = (
  oldFilters: AssortmentFilters,
  newFilters: AssortmentFilters
) => {
  const compareDictsOfLists = (
    oldData?: Record<string, string[]>,
    newData?: Record<string, string[]>
  ) => {
    if (!oldData && !newData) return true;

    const oldKeys = oldData ? Object.keys(oldData) : [];
    const newKeys = newData ? Object.keys(newData) : [];

    return (
      oldKeys.every((key) => {
        return newKeys.includes(key)
          ? isEqual(oldData![key], newData![key])
          : oldData![key].length === 0;
      }) &&
      newKeys.every(
        (key) => oldKeys.includes(key) || newData![key].length === 0
      )
    );
  };

  return (
    oldFilters.period === newFilters.period &&
    isEqual(oldFilters.brand, newFilters.brand) &&
    isEqual(oldFilters.price_range, newFilters.price_range) &&
    compareDictsOfLists(
      oldFilters.individual_attributes,
      newFilters.individual_attributes
    )
  );
};

export const isAttributeNumerical = (attribute: Features) =>
  ["int", "float"].includes(attribute.type) && !attribute.closed_list;

export const getFilterColumns = (
  schema: TableColumn[],
  spaceAttributes: any[]
) => {
  const filtersKeywords = ["brand"].concat(
    spaceAttributes
      .filter(
        (attribute) =>
          !isAttributeNumerical(attribute) && attribute.type !== "boolean"
      )
      .map((attribute) => attribute.attribute.toLowerCase())
  );

  const filterColumns = schema.reduce(
    (acc, column) => {
      const normalizedColumnName = column.name.toLowerCase();
      if (
        !normalizedColumnName.includes("growth") &&
        !normalizedColumnName.includes("group") &&
        !normalizedColumnName.includes("range") &&
        !normalizedColumnName.includes("brands") &&
        filtersKeywords.some((keyword) =>
          normalizedColumnName.includes(keyword)
        )
      ) {
        const matchedFilter = filtersKeywords.find((keyword) =>
          normalizedColumnName.includes(keyword)
        );

        if (matchedFilter) {
          acc[column.name] = matchedFilter;
        }
      }
      return acc;
    },
    {} as Record<string, string | undefined>
  );

  return filterColumns;
};

export const columnSort = (
  a: any,
  b: any,
  sortBy: any,
  isNumericalGroup: any
) => {
  if (
    isNumericalGroup(sortBy.column) ||
    sortBy.column.toLowerCase() === "price range"
  ) {
    const aValue = a[sortBy.column];
    const bValue = b[sortBy.column];

    const sortingTuple = (value: any) => {
      const hasLessEqual = value.includes("<");
      const hasGreaterEqual = value.includes(">");

      const indicatorScore = hasLessEqual ? -1 : hasGreaterEqual ? 1 : 0;

      const numericValue = parseFloat(
        value
          .replace("<", "")
          .replace(">", "")
          .replace("$", "")
          .replace(",", "")
          .split("-")[0]
          .trim()
      );

      return [indicatorScore, numericValue];
    };

    const [aIndicator, aNum] = sortingTuple(aValue);
    const [bIndicator, bNum] = sortingTuple(bValue);

    if (aIndicator !== bIndicator) {
      return sortBy.order === "asc"
        ? aIndicator - bIndicator
        : bIndicator - aIndicator;
    } else {
      return sortBy.order === "asc" ? aNum - bNum : bNum - aNum;
    }
  } else if (sortBy.column.toLowerCase() === "products") {
    const aTotal = parseJsonRecursive(a[sortBy.column]).total;
    const bTotal = parseJsonRecursive(b[sortBy.column]).total;

    if (sortBy.order === "asc") {
      return aTotal - bTotal;
    } else {
      return bTotal - aTotal;
    }
  } else if (
    typeof a[sortBy.column] === "string" &&
    typeof b[sortBy.column] === "string"
  ) {
    if (sortBy.order === "asc") {
      return a[sortBy.column].localeCompare(b[sortBy.column]);
    } else {
      return b[sortBy.column].localeCompare(a[sortBy.column]);
    }
  } else {
    if (sortBy.order === "asc") {
      return a[sortBy.column] - b[sortBy.column];
    } else {
      return b[sortBy.column] - a[sortBy.column];
    }
  }
};

export const getNumericValuesForColumn = (
  data: any[],
  columnName: string
): number[] => {
  return data
    .map((row) => row[columnName])
    .map((value) => (typeof value === "number" && value !== null ? value : 0));
};

export const calculateHeatLevel = (
  value: number,
  minValue: number,
  maxValue: number
): number => {
  if (minValue === maxValue) return 3;

  if (value < minValue) return 1;
  if (value > maxValue) return 5;

  const range = maxValue - minValue;
  const normalizedValue = (value - minValue) / range;
  return Math.ceil(normalizedValue * 4) + 1;
};

export function getRgbFromHex(hex: string) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}
