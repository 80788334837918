import {LoadingButton} from "@mui/lab";
import {Box, Card, CardContent, CardHeader} from "@mui/material";

import {CircuitBoard} from "../../../types";
import {FC} from "react"

const DeleteSection: FC<{
  board?: CircuitBoard;
  isSubmitting: boolean;
  uninstallConfirmation: () => void;
}> = ({board, isSubmitting, uninstallConfirmation}) => (
    <Card variant="elevation" elevation={0}>
      <CardHeader title="Delete Block"/>
      <CardContent>
        <Box marginBottom={1}>
        This action will{" "}
        <b>permanently delete {board?.displayName} immediately </b>, including
        its runs and all related outputs, insights, configurations, etc.
      </Box>

      <Box marginTop={3} marginBottom={3}>
        Are you ABSOLUTELY SURE you wish to delete this block?
      </Box>
      <LoadingButton
        fullWidth
        variant="contained"
        color="error"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={uninstallConfirmation}
      >
        Delete
      </LoadingButton>
    </CardContent>
  </Card>
);
export default DeleteSection;
