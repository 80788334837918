import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Collapse,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import AceEditor from "react-ace";
import { useForm } from "react-hook-form";
import { useBoard, useInstallableBoards } from "../../../services/boards";
import { upgradeBoard } from "../../../services/circuitboards";
import { InstallableCircuitboardVersion } from "../../../types";
import { sortByCreatedAt } from "../../../utils";
import VersionNotes from "./versionNotes";

type BoardUpgradeForm = {
  version: string;
  values: string;
};

const UpgradeSection: FC<{
  boardId: string;

  isBreakingChanges: boolean;
}> = ({ boardId, isBreakingChanges }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { data: installableCircuitboards } = useInstallableBoards();
  const { data: board, refetch } = useBoard(boardId);

  const {
    handleSubmit: handleSubmitUpgrade,
    setValue: setValueUpgrade,
    register,
    formState,
    watch,
  } = useForm<BoardUpgradeForm>({
    mode: "onChange",
  });
  const { isSubmitting } = formState;

  const getAvailableBoardVersions: () => InstallableCircuitboardVersion[] =
    useCallback(() => {
      if (installableCircuitboards && board) {
        return sortByCreatedAt(
          installableCircuitboards.find(
            (installableCircuitboard) =>
              installableCircuitboard.id === board.template_id
          )?.versions || []
        );
      }
      return [];
    }, [installableCircuitboards, board]);

  useEffect(() => {
    if (installableCircuitboards && board && getAvailableBoardVersions()) {
      const versions = getAvailableBoardVersions();
      if (versions && versions.length > 0) {
        setValueUpgrade("version", versions[0].version || "");
      }
    }
  }, [
    board,
    installableCircuitboards,
    setValueUpgrade,
    getAvailableBoardVersions,
  ]);

  const onSubmit = handleSubmitUpgrade(async (formValues: BoardUpgradeForm) => {
    try {
      const versionDetails = getAvailableBoardVersions()!.find(
        (version) => version.version === formValues.version
      );
      if (!versionDetails) {
        enqueueSnackbar(
          "Could not find chosen block version. Please choose another version.",
          { variant: "error" }
        );
      } else {
        await upgradeBoard({
          id: boardId,
          template_id: versionDetails.id,
          version: versionDetails.version,
          values: formValues.values,
        });
      }
      enqueueSnackbar("Block updated successfully", {
        variant: "success",
      });
      await refetch();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  });

  const boardVersions = getAvailableBoardVersions();
  const selectedVersion = watch("version");
  return (
    <>
      {isBreakingChanges && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mt: 2, ml: 12, width: "50%" }}
        >
          This version introduces changes to the block output.
        </Alert>
      )}

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          mb: 2,
          ml: 5.5,
          mt: 3,
          justifyContent: "space-around",
        }}
      >
        <Box
          width="40%"
          sx={{ mt: 1, mr: 6, display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flexDirection: "row", display: "flex", mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mr: 7.5 }}>
              Template ID
            </Typography>
            <Typography variant="body2">{board?.template_id}</Typography>
          </Box>
          <Box sx={{ flexDirection: "row", display: "flex", mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mr: 5 }}>
              Current Version
            </Typography>
            <Typography variant="body2"> {board?.version}</Typography>
          </Box>
          <Box sx={{ flexDirection: "row", display: "flex", mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mr: 5 }}>
              Newest Version
            </Typography>
            <Typography variant="body2">{boardVersions[0]?.version}</Typography>
          </Box>
          {board?.version === boardVersions[0]?.version && (
            <Box display="flex">
              <CheckCircleIcon
                sx={{ color: "success.main", marginRight: 1 }}
                fontSize="small"
              />
              <Typography variant="subtitle2">
                Your block is running the latest version
              </Typography>
            </Box>
          )}
          <Box marginTop={2} marginBottom={2}>
            <Divider />
          </Box>

          <Button
            value="check"
            variant="text"
            color="primary"
            sx={{ justifyContent: "flex-start" }}
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            Advanced Configuration
            {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>

          <Box sx={{ pt: 3 }}>
            <form onSubmit={onSubmit}>
              <Collapse in={isDropdownOpen}>
                <>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={{ mb: 1 }}
                  >
                    Leave empty to keep the configuration as is.
                  </Typography>

                  <FormControl
                    size="small"
                    margin="dense"
                    variant="outlined"
                    required
                    fullWidth
                  >
                    <InputLabel id="versionLabel">Version</InputLabel>
                    <Select
                      labelId="versionLabel"
                      label="Version"
                      disabled={isSubmitting}
                      sx={{ mb: 3 }}
                      {...register("version")}
                    >
                      {boardVersions.map(
                        (version: InstallableCircuitboardVersion) => (
                          <MenuItem
                            key={version.version}
                            value={version.version}
                          >
                            {version.version}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <Paper variant="outlined" sx={{ mb: 1 }}>
                    {/*// @ts-ignore*/}
                    <AceEditor
                      mode="yaml"
                      theme="tomorrow"
                      height="200px"
                      width="100%"
                      showPrintMargin={false}
                      editorProps={{ $blockScrolling: true }}
                      {...register("values")}
                    />
                  </Paper>
                </>
              </Collapse>

              <DialogActions>
                <LoadingButton
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || selectedVersion === board?.version}
                  fullWidth
                  variant="contained"
                  loading={isSubmitting}
                >
                  Update
                </LoadingButton>
              </DialogActions>
            </form>
          </Box>
        </Box>

        <VersionNotes board={board} />
      </Box>
    </>
  );
};

export default UpgradeSection;
