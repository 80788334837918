import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";

import dayjs from "../../utils";
import { Summary } from "./Summary";
import {
  useInsightsContext,
  useRevenueOverTimeSummary,
  useValuesSumByInsightType,
} from "./hooks/selectors";
import { Search } from "../../components/SearchInput/Search";
import { AddInsightModal } from "./AddFlexInsightModel";
import HeaderTabs from "./HeaderTabs";
import SelectStatsPeriod from "./SelectStatsPeriod";
import { useSplitEnabled } from "../../hooks";
import { Split } from "../../FeatureFlags/enums";
import SummaryV2, { SummaryVariants } from "./SummaryV2";
import { FC } from "react";

const stackProps = {
  spacing: 2,
  justifyContent: "space-between",
  direction: "row" as const,
  alignItems: "center",
  sx: { padding: "0 8px" },
};

const SearchAndNotification = () => {
  const { search, setSearch } = useInsightsContext();
  return (
    <Stack direction="row" alignItems="baseline" spacing={2}>
      <Search
        value={search}
        placeholder="Search..."
        data-testid="insights-search"
        onChange={(e: any) => {
          setSearch(e.target.value);
        }}
      />
    </Stack>
  );
};
export const Header: FC = () => {
  const {
    total,
    percentageChange7D,
    percentageChange30D,
    numberChange30D,
    numberChange7D,
  } = useRevenueOverTimeSummary();
  const { potential } = useValuesSumByInsightType();
  const theme = useTheme();
  const { insightsMetadata: data, metadataLoading: isLoading } =
    useInsightsContext();
  const revenueOverTimeEnabled = useSplitEnabled(Split.REVENUE_OVER_TIME);

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.borders.lines}`,
        padding: "0 32px",
        margin: "12px 0 0 0",
        gap: "8px",
      }}
    >
      <Stack {...stackProps}>
        <Typography variant="headline3">
          Insights{" "}
          {!isLoading && !!dayjs(data?.my_updated_date).isValid() && (
            <Chip
              label={`Updated ${dayjs(data?.my_updated_date).fromNow()}`}
              sx={{
                borderRadius: "500px",
                padding: "8px 8px",
                background: theme.palette.backgrounds.info,
                color: theme.palette.info.contrastText,
                fontSize: "13px",
                "& > *": {
                  padding: 0,
                },
              }}
            />
          )}
        </Typography>
        <Stack direction="row" gap="4px" alignItems="center">
          <AddInsightModal />
          {revenueOverTimeEnabled && <SelectStatsPeriod />}
          {revenueOverTimeEnabled ? (
            <SummaryV2
              total={total}
              percentageChange7D={percentageChange7D}
              percentageChange30D={percentageChange30D}
              numberChange30D={numberChange30D}
              numberChange7D={numberChange7D}
              variant={SummaryVariants.Minimized}
            />
          ) : (
            <Summary pottentialValue={potential} />
          )}
          <SearchAndNotification />
        </Stack>
      </Stack>
      <HeaderTabs
        isLoading={isLoading}
        newItems={data?.new}
        myItems={data?.followed}
      />
    </Box>
  );
};
