import {ContextHolder} from "@frontegg/rest-api";
import cubejs, {CubejsApi} from "@cubejs-client/core";
import {useRouteMatch} from "react-router";
import {CubeProvider} from "@cubejs-client/react";
import {FC, ReactNode, useEffect, useState} from "react"

export const CubeJsProvider: FC<{ children: ReactNode }> = (props) => {
    const token = ContextHolder.getAccessToken();
    const {params} =
    useRouteMatch<{ flowId: string }>({
        path: "/ad-booster/:flowId",
    }) || {};
    const {flowId = ""} = params || {};
    const [cubeApiClient, setCubeApiClient] = useState<CubejsApi>(
        cubejs({
            apiUrl: "/api/query/v1",
            headers: {
                "X-NOOGATA-FLOW-ID": flowId,
                Authorization: token || "",
            },
        })
    );

    useEffect(() => {
        if (token && flowId) {
            setCubeApiClient(
                cubejs(token, {
                    apiUrl: "/api/query/v1",
                    headers: {
                        "X-NOOGATA-FLOW-ID": flowId,
                        Authorization: token,
                    },
                })
            );
        }
  }, [token, flowId]);

  return <CubeProvider cubejsApi={cubeApiClient} {...props} />;
};

export default CubeJsProvider;
