import * as React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";

type DiffType = "more" | "less";

interface Props {
  baseValue: number | null;
  value: number;
  isLoading: boolean;
}

const TitleScoreIndicator: React.FC<Props> = ({
  baseValue,
  value,
  isLoading,
}) => {
  const isEqual = baseValue == value;
  const diff = baseValue ? value - baseValue : 0;
  const diffType: DiffType = diff > 0 ? "more" : "less";

  const scoreColor = {
    low: "#D6751B",
    high: "#07A254",
  };

  const updatedValue = Math.round(value);
  return (
    <Box display="flex" alignItems="end">
      {isLoading ? (
        <Skeleton animation="wave" height="24px" width="100px" />
      ) : (
        <>
          <Stack direction="row">
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "18px",
                latterSpacing: "-0.01em",
                transition: "all 0.3s ease",
                color: diffType === "more" ? scoreColor.high : scoreColor.low,
              }}
            >
              {updatedValue}
            </Typography>
            {!isEqual && (
              <Stack
                direction="row"
                sx={{
                  fontSize: "14px",
                  width: "30px",
                  height: "20px",
                }}
              >
                {diffType === "less" ? (
                  <ArrowDownwardIcon sx={{ fill: scoreColor.low }} />
                ) : (
                  <ArrowUpwardIcon sx={{ fill: scoreColor.high }} />
                )}
                {Math.round(Math.abs(diff as number))}
              </Stack>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
};

export default TitleScoreIndicator;
