import {Box, Button, Dialog, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {FC, MouseEventHandler, useState} from "react";
import {useSnackbar} from "notistack";
import {useQueryParams} from "../../hooks";
import {InsightsQueryParams} from "./constants";
import {useInsightsContext} from "./hooks/selectors";
import {useInsightUpdater} from "./useInsights";
import {Insight} from "../../types";

const dialogStyle = {
    padding: "16px 16px 24px",
    boxShadow:
        "0px 4px 8px rgba(44, 34, 76, 0.04), 0px 1px 3px rgba(44, 34, 76, 0.12)",
    borderRadius: "8px",
    minWidth: "482px",
    maxWidth: "1200px",
    minHeight: "448px",
};

const Header: FC<{
  onClose: MouseEventHandler<HTMLButtonElement>;
  value: string;
}> = ({ onClose, value }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateInsight = useInsightUpdater();

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", padding: "10px" }}>
        <h1 style={{ margin: 0 }}>Edit Insight</h1>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            background: (theme) => theme.palette.background.default,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Button
        onClick={async () => {
          try {
            const newData = JSON.parse(value);

            await updateInsight(newData as Insight);
            enqueueSnackbar("Insight updated successfully", {
              variant: "success",
            });
          } catch (e) {
            enqueueSnackbar("fail to update insight", { variant: "error" });
          }
        }}
      >
        Save
      </Button>
    </>
  );
};

function useLocalInsight() {
  const insightId = useQueryParams().get(InsightsQueryParams.EditTask);
  const { insights } = useInsightsContext();
  return insights.find(({ id }) => id === insightId);
}

export const EditTaskJson: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }
  const insight = useLocalInsight();
  const [value, setValue] = useState(JSON.stringify(insight, null, 4));

  return (
    <Dialog
      maxWidth={false}
      open={isOpen}
      onClose={onClose}
      data-testid="editInsightDialog"
    >
      <Header onClose={onClose} value={value} />
      <Box sx={dialogStyle}>
        <div
          contentEditable
          dangerouslySetInnerHTML={{
            __html: `<pre style="word-wrap: break-word; white-space: pre-wrap;">${value}</pre>`,
          }}
          onBlur={(evt) => setValue(evt.currentTarget.textContent!)}
        />
      </Box>
    </Dialog>
  );
};
