// import List from "@mui/material/List";
// import { Box, LinearProgress, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import { useTheme } from "@mui/styles";
// import { useSalesAssistantStyles } from "../useSalesAssistantStyles";
import { useDisplayList, useSalesContext } from "../hooks/selectors";
import VirtualList from "./VirtualList";
import { useQueryParams } from "../../../hooks";
import { TransformedInsight } from "../types";
import { SalesQueryParams } from "../constants";
// import { SalesListingHead } from "./SalesListingHead";

export const onClickRow = (
  isDrawerEnabled: boolean,
  queryParams: URLSearchParams,
  history: ReturnType<typeof useHistory>,
  insight: TransformedInsight
) => {
  if (!isDrawerEnabled) return;
  // logEvent({ insight, action: "Expand" });
  queryParams.append(SalesQueryParams.InsightId, insight.id);
  history.replace({ search: queryParams.toString() });
};

export const onClickTaskRow = (
  isDrawerEnabled: boolean,
  queryParams: URLSearchParams,
  history: ReturnType<typeof useHistory>,
  id: string
) => {
  if (!isDrawerEnabled) return;
  // logEvent({ insight, action: "Expand" });
  queryParams.append(SalesQueryParams.InsightId, id);
  history.replace({ search: queryParams.toString() });
};
const newSortCopy = {
  column: "",
  ascending: false,
  isDirty: false,
  days: "7d",
};
// const listStylesOverrides = {
//   "& .MuiListItem-root": {
//     width: "100%",
//     cursor: "pointer",
//     paddingBottom: "8px",
//     paddingTop: "8px",
//   },
// };

// interface InsightsListProps {
//   onTabChange?: (event: React.SyntheticEvent, newValue: string) => void;
//   activeTab?: string;
// }

const InsightsList: FC = () => {
  // const {
  //   onTabChange,
  //   activeTab
  // } = props
  const queryParams = useQueryParams();
  // const { mainList } = useSalesAssistantStyles();
  // const theme = useTheme();
  const location = useLocation();
  const { hash } = location;
  const displayList = useDisplayList();
  const groupId = queryParams.get("groupId") || "";
  const [gId, setGId] = useState("");
  // const [filteredList, setFilteredList] = useState<any>([]);
  // const isGroupPage = location.pathname === "/sales-assistant/home"
  const {
    sort,
    // setSort,
    isFetchingInsights,
    search,
    allSorts,
    setAllSorts,
    categoryFilter,
    brandFilter,
  } = useSalesContext();

  useEffect(() => {
    if (groupId) {
      setGId(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (document.getElementById(`insight-id-${hash.substring(1)}`)) {
      document
        .getElementById(`insight-id-${hash.substring(1)}`)!
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [hash, displayList]);

  useEffect(() => {
    if (
      (search || categoryFilter || brandFilter) &&
      displayList?.length >= 0 &&
      newSortCopy?.column === sort?.column
    ) {
      if (displayList?.length > 0 && !isFetchingInsights) {
        if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
          setAllSorts((old: any) => [...old, sort]);
        }
        // setFilteredList([...displayList]);
        return;
      }
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
        setAllSorts((old: any) => [...old, sort]);
        if (allSorts.length > 0) {
          return;
        }
      }
      // if (
      //   search ||
      //   categoryFilter ||
      //   brandFilter ||
      //   (displayList?.length !== 0 && newSortCopy?.column === sort?.column)
      // ) {
      //   setFilteredList([...displayList]);
      // }
      // return;
    }
    if (displayList?.length > 0 && !isFetchingInsights) {
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
        setAllSorts((old: any) => [...old, sort]);
      }
      // setFilteredList([...displayList]);
    }
  }, [
    displayList?.length,
    sort?.column,
    isFetchingInsights,
    search,
    categoryFilter,
    brandFilter,
    gId
  ]);
  return (
    <VirtualList />
  );
};
export { InsightsList };