import { Route, Switch } from "react-router-dom";
import SpaceDetail from "./SpaceDetail.tsx";
import { useRouteMatch } from "react-router";
import { SpaceProvider } from "./SpaceProvider.tsx";
import SpacesList from "./SpacesList.tsx";
import AddSpace from "./AddSpace.tsx";
import AddSpaceV2 from "./AddSpaceV2.tsx";
import useSplitEnabled from "../../hooks/useSplitEnabled.ts";
import { Split } from "../../FeatureFlags/enums.ts";

const SpacesV2 = () => {
  const { path } = useRouteMatch();
  const enableCreateSpaceV2 = useSplitEnabled(Split.SPACES_CREATE_V2);

  return (
    <Switch>
      <Route path={path} exact component={SpacesList} />
      <Route
        path={`${path}/add`}
        exact
        component={enableCreateSpaceV2 ? AddSpaceV2 : AddSpace}
      />
      <Route
        path={`${path}/:spaceId/:activeView`}
        component={SpaceDetail}
        exact
      />
      <Route path={`${path}/:spaceId`} component={SpaceDetail} />
    </Switch>
  );
};

export default function SpacesPage() {
  return (
    <SpaceProvider>
      <SpacesV2 />
    </SpaceProvider>
  );
}
