import { ChangeEvent, FC } from "react";

import { Divider, Tab, Tabs, ThemeProvider } from "@mui/material";

import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { PageHeader } from "../../components";
import { useRouteMetrics } from "../../hooks/metrics/routeEvents";
import ManageSection from "./ManageSection";
import ViewSection from "./ViewSection";
import theme from "../../themev2";

enum PageSections {
  View = "view",
  Manage = "manage",
}

const Warehouse: FC = () => {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const handleTabChange = (
    _event: ChangeEvent<object>,
    newValue: PageSections
  ) => {
    history.push(newValue);
  };
  useRouteMetrics([`${path}/:section`], { scope: ["warehouse"] });

  return (
    <ThemeProvider theme={theme}>
      <PageHeader headerLabel="Data Warehouse Management" />
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={history.location.pathname.split("/").slice(0, 6).join("/")}
        onChange={handleTabChange}
      >
        <Tab
          value={`${url}/${PageSections.View}`}
          label={<div>Tables Overview</div>}
        />
        <Tab
          value={`${url}/${PageSections.Manage}`}
          label={<div>API Token</div>}
        />
      </Tabs>
      <Divider />
      <Route path={url} exact>
        <Redirect to={`${url}/${PageSections.View}`} />
      </Route>
      <Route path={`${url}/${PageSections.View}`}>
        <ViewSection />
      </Route>
      <Route path={`${url}/${PageSections.Manage}`}>
        <ManageSection />
      </Route>
    </ThemeProvider>
  );
};
export default Warehouse;
