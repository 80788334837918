import {
  // Autocomplete,
  Box,
  Button,
  // Chip,
  // Chip,
  CircularProgress,
  IconButton,
  Stack,
  // TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSpace } from "./SpaceProvider.tsx";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AsinsListTextarea from "../EcommerceProducts/Amazon/AsinsListTextarea.tsx";
import { LoadLocalCsvFile } from "../EcommerceProducts/LoadLocalCsvFile.tsx";
import { getAsinsFromCSVFile } from "../EcommerceProducts/csvStreamUtils.ts";
import {
  Separator,
  validateAsinsList,
} from "../EcommerceProducts/Amazon/AddProducts.tsx";
import { useMutation } from "react-query";
import { addAsinsAPI } from "./useSpaces.ts";
import theme from "../../themes/light.ts";
// import CancelIcon from "@mui/icons-material/Cancel";
// import ProductTags from "./ProductTags.tsx";

const AddManualSpaceDrawer = ({
  spaceId,
  setIsDrawerOpen,
}: {
  spaceId: string;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
}) => {
  const { spacesListRefetch, refatchAsins } = useSpace();
  const snackbar = useSnackbar();
  const [fileDroppedLoading, setFileDroppedLoading] = useState<boolean>(false);
  // const [rawAsins, setRawAsins] = useState<string>("");
  const { control, formState, handleSubmit, watch, setValue, register } =
    useForm<{ asinsList: string[]; asins: string[] }>({
      mode: "onChange",
      defaultValues: { asinsList: [] },
    });

  useEffect(() => {
    register("asinsList", { required: true });
  }, [true]);
  const { errors } = formState;
  const parsedAsinsList = watch("asinsList");
  const mutation = useMutation(
    ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
      addAsinsAPI({ spaceId, asins })
  );
  // const commaSeperatedAsinsListRegex =
  //   /^[0-9A-Z]{10}(,[ ]?[0-9A-Z]{10})*(,[ ]?[0-9A-Z]{10})?$/;
  // const spaceSeperatedAsinsListRegex =
  //   /^[0-9A-Z]{10}([ ][0-9A-Z]{10})*([ ][0-9A-Z]{10})?$/;
  // const newlineSeperatedAsinsListRegex =
  //   /^[0-9A-Z]{10}(\n[0-9A-Z]{10})*(\n[0-9A-Z]{10})?$/;

  // const handleDelete = (asinIndex: number) => {
  //   const isCommaSeperatedString = commaSeperatedAsinsListRegex.test(rawAsins);
  //   const isSpaceSeperatedString = spaceSeperatedAsinsListRegex.test(rawAsins);
  //   const isNewlineSeperatedString =
  //     newlineSeperatedAsinsListRegex.test(rawAsins);

  //   const filteredAsinList = parsedAsinsList?.filter(
  //     (_, idx) => idx !== asinIndex
  //   );

  //   const updatedAsinsString = isCommaSeperatedString
  //     ? filteredAsinList?.join(", ")
  //     : isSpaceSeperatedString
  //       ? filteredAsinList?.join(" ")
  //       : isNewlineSeperatedString
  //         ? filteredAsinList?.join("\n")
  //         : rawAsins;

  //   setRawAsins(updatedAsinsString);

  //   setValue("asinsList", filteredAsinList, {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  //   setValue("asins", filteredAsinList, {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  // };

  // const handleDeleteAll = () => {
  //   setRawAsins("");

  //   setValue("asinsList", [], {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  //   setValue("asins", [], {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   });
  // };

  return (
    <div>
      <Box
        sx={{
          mx: 4,
          mt: 3,
          mb: 2,
          flex: 1,
          width: (t) => t.spacing(65),
        }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 4 }}>
          <IconButton
            color="default"
            size="small"
            onClick={() => setIsDrawerOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <form
          onSubmit={handleSubmit(({ asinsList }) =>
            mutation
              .mutateAsync({ spaceId, asins: asinsList })
              .then(() => {
                setIsDrawerOpen(false);
                snackbar.enqueueSnackbar("ASINs were added successfully", {
                  variant: "success",
                });
                spacesListRefetch();
                refatchAsins();
                // refetch();
                setIsDrawerOpen(false);
              })
              .catch((e) => {
                snackbar.enqueueSnackbar("Something went wrong: " + e, {
                  variant: "error",
                });
              })
          )}>
          <Box>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Add ASINs Manually
            </Typography>

            <Stack spacing={1}>
              <Typography variant="body4">ASINS</Typography>
              <Controller
                control={control}
                name="asinsList"
                render={({ _onChange, ...props }: any) => (
                  <Box>
                    <AsinsListTextarea
                        placeholder={`Type or paste a list of ASINs to add to your space.\nSeparate ASINs by space or comma.`}
                        onChange={(e) => {
                          setValue(
                            "asinsList",
                            validateAsinsList(e.target.value).asins,
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            }
                          );
                          setValue(
                            "asins",
                            validateAsinsList(e.target.value).asins,
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            }
                          );
                        }}
                        errors={errors?.asinsList?.message}
                        {...props}
                      />
                    {/* <TextField
                      multiline
                      fullWidth
                      rows={5}
                      variant="filled"
                      value={rawAsins}
                      error={!!errors?.asinsList?.message}
                      helperText={errors?.asinsList?.message}
                      placeholder={
                        "Type or paste a list of ASINs to add to your training set.\nSeparate ASINs by space or comma."
                      }
                      {...props}
                      onChange={(e) => {
                        setRawAsins(e.target.value);
                        setValue(
                          "asinsList",
                          validateAsinsList(e.target.value).asins,
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          }
                        );
                        setValue(
                          "asins",
                          validateAsinsList(e.target.value).asins,
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          }
                        );
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {parsedAsinsList?.length > 0 && (
                              <ProductTags
                                productList={parsedAsinsList}
                                handleDelete={handleDelete}
                                handleDeleteAll={handleDeleteAll}
                              />
                            )}
                          </>
                        ),
                        sx: {
                          padding: "8px 16px",
                          "& textarea": {
                            padding: "0 !important",
                            fontSize: "15px",
                          },
                          flexDirection: "column",
                        },
                      }}
                      sx={{
                        "& p.Mui-focused": { border: "0 none" },
                      }}
                    /> */}
                    {/* <Autocomplete
                      value={parsedAsinsList.length ? parsedAsinsList : []}
                      multiple
                      id="tags-filled"
                      options={[]}
                      defaultValue={[]}
                      freeSolo
                      sx={{
                        ".MuiInputBase-root": {
                          paddingTop: 0,
                        },
                        "& .MuiFilledInput-root": {
                          minHeight: "120px",
                          alignItems: "unset",
                        }
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            color="primary"
                            variant="filled"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(e: any, options, action) => {
                        e.preventDefault();
                        if (action === "removeOption" || action === "clear") {
                          // setCurrentSelection(options);
                          // onChange(options as string[]);
                          setValue("asinsList", options, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          setValue("asins", options, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        } else {
                          const list = validateAsinsList(
                            options[options?.length - 1]
                          ).asins;
                          const updatedOptionList = [...options];
                          updatedOptionList.splice(-1, 1);
                          if (list && list?.length > 0) {
                            const updatedList = [...updatedOptionList, ...list];
                            setValue("asinsList", updatedList, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                            setValue("asins", updatedList, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }
                        }
                      }}
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          {...params}
                          multiline={parsedAsinsList?.length === 0}
                          variant="filled"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          placeholder={
                            "Type or paste a list of ASINs to add to your training set.\nSeparate ASINs by space or comma."
                          }
                          inputProps={{
                            ...inputProps,
                            onChange: (e: any) => {
                              if (inputProps?.onChange) {
                                inputProps?.onChange(e);
                              }
                            },
                          }}
                        />
                      )}
                    /> */}
                    {/* {!!errors?.asinsList?.message && (
                      <Typography variant="caption" color="error">
                        {errors?.asinsList?.message}
                      </Typography>
                    )} */}
                  </Box>
                )}
              />
              <Separator my={4} />
              <LoadLocalCsvFile
                onFileDropped={(dropped_file) => {
                  setFileDroppedLoading(true);
                  getAsinsFromCSVFile(dropped_file)
                    .then((data) => {
                      setValue("asinsList", data, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    })
                    .catch((e) =>
                      snackbar.enqueueSnackbar(e.message, { variant: "error" })
                    )
                    .finally(() => setFileDroppedLoading(false));
                }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="end"
              mt={3}
              spacing={1}
              alignItems="center">
              <Typography variant="body3" color={theme.palette.primary.main}>
                {parsedAsinsList.length} ASINs were selected
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsDrawerOpen(false);
                  spacesListRefetch();
                }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={
                  !formState.isValid ||
                  formState.isSubmitting ||
                  fileDroppedLoading
                }
                type="submit"
                startIcon={
                  (formState.isSubmitting || fileDroppedLoading) && (
                    <CircularProgress
                      size="small"
                      sx={{
                        height: (t) => t.spacing(2),
                        width: (t) => t.spacing(2),
                        color: (t) => t.palette.grey[300],
                      }}
                    />
                  )
                }>
                Add to space
                {parsedAsinsList.length === 1 ? "" : "s"}
              </Button>
            </Stack>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default AddManualSpaceDrawer;
