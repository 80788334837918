import { Box, Stack, StackProps, Tooltip, Typography } from "@mui/material";
import SVG from "react-inlinesvg";
import { FC } from "react";
import clsx from "clsx";
import { useInsightsStyles } from "./useInsightsStyles";
import { InsightColors } from "./constants";
import theme from "../../themes/light";

type Value = { value: number; compact?: boolean };
export const SummaryChip: FC<
  {
    className: string;
    svgsrc: string;
    color: string;
    subText: string;
  } & Value &
    StackProps
> = ({ compact, className, subText, svgsrc, color, value, ...props }) => (
  <Stack
    {...props}
    spacing={1}
    direction="row"
    alignItems="center"
    justifyContent="center"
    height={compact ? "40px" : "32px"}
    className={clsx(className, compact && "compact")}
  >
    <SVG
      style={{ borderRadius: "1000px" }}
      height="20px"
      width="20px"
      fill={color}
      src={`/${svgsrc}`}
    />
    <Stack direction="row" alignItems="baseline" gap="6px">
      <Box>
        <Typography color={color} variant={compact ? "h2_v2" : "headline3"}>
          ${Math.ceil(value / 1000)}
        </Typography>
        <Typography color={color} variant={compact ? "headline3" : "headline4"}>
          K{" "}
        </Typography>
      </Box>
      {!compact && (
        <Typography color={color} variant="caption">
          {subText}
        </Typography>
      )}
    </Stack>
  </Stack>
);

export const PotentialChip: FC<Value & StackProps> = ({ value, ...props }) => {
  const classes = useInsightsStyles();
  return (
    <SummaryChip
      data-testid="insights-pottential-chip"
      subText="Revenue"
      value={value}
      svgsrc="insights/star-fill.svg"
      className={classes.chipPotential}
      {...props}
      color={InsightColors.success.main}
    />
  );
};

export type SummaryProps = {
  pottentialValue: number;
  compact?: boolean;
};
export const Summary: FC<SummaryProps & StackProps> = ({
  pottentialValue,
  ...props
}) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Tooltip
      title={
        <Box padding="4px">
          <Typography variant="body1" color={theme.palette.backgrounds.white}>
            Overall revenue for last 4 weeks of all ASINs in the insights
          </Typography>
        </Box>
      }
      arrow
    >
      <div>
        <PotentialChip value={pottentialValue} {...props} />
      </div>
    </Tooltip>
  </Stack>
);
