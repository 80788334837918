import { Box, Tab, Tabs } from "@mui/material";
import { useAssortmentIntelligence } from "../AssortmentIntelligenceProvider";
import theme from "../../../themes/light";
import { SelectedView } from "../types";

const ViewTabs = () => {
  const { selectedView, setSelectedView } = useAssortmentIntelligence();

  const tabOptions = [
    { label: "Market Breakdown", value: SelectedView.MarketBreakdown },
    { label: "Brand Strategy", value: SelectedView.BrandStrategy },
    { label: "Products Review", value: SelectedView.ProductsReview },
    { label: "Pricing Strategy", value: SelectedView.PricingStrategy },
  ];

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: SelectedView
  ) => {
    setSelectedView(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "8px",
        backgroundColor: theme.palette.background.paper,
        width: "100%",
      }}
    >
      <Tabs
        value={selectedView}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {tabOptions.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            sx={{
              borderRight: `1px solid ${theme.palette.divider}`,
              "&:last-child": {
                borderRight: "none",
              },
              "&.Mui-selected": {
                backgroundColor: theme.palette.primary.light,
              },
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              fontSize: "1rem",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default ViewTabs;
