import { Box, Tooltip, Typography } from "@mui/material";
import { DarkStarIcon, InfoIcon } from "../../assets";
import theme from "../../themes/light";
import { IHeaderParams } from "ag-grid-community";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface CustomHeaderProps extends IHeaderParams {
  displayName: string;
  onSortRequested: (e: any, props: CustomHeaderProps) => void;
  sortOrder: any;
  tooltipTitle: string;
  showHeaderIcon?: boolean;
  showTooltip?: boolean;
  showAgGrid?: boolean;
  selectAgGrid?: React.ComponentType;
}

const CustomHeader = (props: CustomHeaderProps) => {
  const {
    displayName,
    onSortRequested,
    sortOrder,
    tooltipTitle,
    showHeaderIcon = false,
    showTooltip = false,
    showAgGrid = false,
    selectAgGrid: SelectAgGrid,
  } = props;

  // const showMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation();
  //   props.showColumnMenu(event.currentTarget);
  // };

  const handleSort = (event: any) => {
    if (onSortRequested) {
      onSortRequested(event, props);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        width: "100%",
      }}
      onClick={handleSort}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {showAgGrid && SelectAgGrid && <SelectAgGrid />}

        {showTooltip && (
          <Tooltip
            title={tooltipTitle || displayName}
            placement="top"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <Box
              display="inline-flex"
              sx={{
                "& svg": {
                  fontSize: "16px",
                  width: "11px",
                  height: "11px",
                },
              }}
            >
              <InfoIcon />
            </Box>
          </Tooltip>
        )}
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#181d1f",
          }}
        >
          {displayName}
        </Typography>
        {showHeaderIcon && (
          <Box
            sx={{
              display: "inline-flex",
              marginLeft: "-10px",
              alignItems: "center",

              "& path": {
                fill: theme.palette.primary.main,
              },
            }}
          >
            <DarkStarIcon />
          </Box>
        )}
        {sortOrder && (
          <Box
            sx={{
              display: "inline-flex",
              "& svg": {
                fontSize: "1rem",
                color: "#181d1f",
              },
            }}
          >
            {sortOrder === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </Box>
        )}
      </Box>
      {/* <Box
        sx={{
          display: "inline-flex",
          height: "22px",
          width: "22px",
          position: "absolute",
          right: "0",
          top: "0",
          "& svg": {
            fontSize: "1rem",
            height: "100%",
            width: "100%",
            color: "#181d1f",
          },
        }}
        onClick={showMenu}>
        <MoreIcon />
      </Box> */}
    </Box>
  );
};

export default CustomHeader;
