import { JSONSchema4 } from "json-schema";
import apiRequest from "./apiRequest";
import {
  Connector,
  ConnectorCreate,
  ConnectorOption,
  LocalSchemaCreate,
} from "../types/connectorsV2";
import { ConnectorType } from "../enums";

export function getConnectorOptions(): Promise<ConnectorOption[]> {
  return apiRequest<ConnectorOption[]>("connectors", "OPTIONS");
}

export function getConnectors(
  circuitboardId: string,
  type: ConnectorType
): Promise<Connector[]> {
  return apiRequest<Connector[]>("connectors", "GET", {
    circuitboard_id: circuitboardId,
    connector_type: type,
  });
}

export function createConnector(data: ConnectorCreate): Promise<Connector> {
  return apiRequest<Connector>("connectors", "POST", undefined, data);
}

export type InputSchema = {
  properties: Record<string, JSONSchema4>;
  data: Record<string, string>[];
};

export function createSchema(
  connectorId: string,
  data: object
): Promise<InputSchema> {
  return apiRequest<InputSchema>(
    `connectors/${connectorId}/schema`,
    "POST",
    undefined,
    data
  );
}

export function createLocalSchema(
  data: LocalSchemaCreate
): Promise<InputSchema> {
  return createSchema("internal", data);
}

export async function deleteConnector(
  circuitboardId: string,
  type: ConnectorType
): Promise<Connector[]> {
  return await apiRequest<Connector[]>("connectors", "GET", {
    circuitboard_id: circuitboardId,
    connector_type: type,
  });
}
