import { FC, useEffect } from "react";
import { Cube } from "../../../../types";
import DataGrid from "../../../../components/DataGrid";
import { useCubeJsDimensionsGrid } from "./hooks";
import { useMeta } from "../../../../services/query";
import { GridProps } from "./types";

const OutputGrid: FC<GridProps> = ({ boardId, runId, selected }) => {
  const { data: meta } = useMeta(boardId, runId);

  // @ts-ignore
  const cubes: Cube[] = meta && meta.cubes;
  const selectedCube =
    (selected && cubes && cubes.find((cube) => cube.name === selected)) || null;
  const { onGridReady, columns, isLoading, reset, errorMessage } =
    useCubeJsDimensionsGrid(selectedCube?.dimensions || [], boardId, runId);

  useEffect(() => {
    if (selected) {
      reset();
    }
  }, [selected]);

  return selectedCube ? (
    <DataGrid
      onGridReady={onGridReady}
      columns={columns}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  ) : null;
};
export default OutputGrid;
