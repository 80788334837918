/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { GroupedVirtuoso, TopItemListProps } from "react-virtuoso";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { Icon } from "../../../components/Icon";
import { SalesListItem } from "./salesListItem";
import { useDisplayList, useSalesContext, } from "../hooks/selectors";
import { salesAssistantContext } from "../SalesProvider";
import EmptyStates from "./EmptyStates";
// import SekeltonList from "./SkeletonList";
import { useQueryParams } from "../../../hooks";
let newSortCopy = {
  column: "",
  ascending: false,
  isDirty: false,
  days: "7d",
};
const VirtualList: FC<any> = () => {
  const displayList = useDisplayList();
  const { pathname } = useLocation();
  const theme = useTheme();
  const queryParams = useQueryParams();
  const [filteredList, setFilteredList] = useState<any>([]);
  const {
    sort,
    setSort,
    isFetchingInsights,
    search,
    allSorts,
    setAllSorts,
    categoryFilter,
    brandFilter,
    setProupSearch,
    groupSearch
  } = useSalesContext();

  
  const { refetchInsights } = useContext(salesAssistantContext);
  const [isUpdatedList, setIsUpdatedList] = React.useState(false);
  const groupId = queryParams.get("groupId") || "";
  const [gId, setGId] = useState("");
 
 
  useEffect(() => {
    if (groupId) {
      setGId(groupId);
    }
  }, [groupId]);
  
  useEffect(() => {
    newSortCopy = sort;
  }, [sort]);
  
  
  useEffect(() => {
    // if (displayList?.length === 0) {
    //   setFilteredList([]);
    //   return;
    // }
    if (
      (groupSearch || categoryFilter || brandFilter) &&
      displayList?.length >= 0 &&
      newSortCopy?.column === sort?.column
    ) {
      if (displayList?.length > 0 && !isFetchingInsights) {
        if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
          setAllSorts((old: any) => [...old, sort]);
        }
        setFilteredList([...displayList]);
      
        
        return;
      }
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
        setAllSorts((old: any) => [...old, sort]);
        if (allSorts.length > 0) {
          return;
        }
      }
      if (
        groupSearch ||
        categoryFilter ||
        brandFilter ||
        (displayList?.length !== 0 && newSortCopy?.column === sort?.column)
      ) {
        setFilteredList([...displayList]);
      }
      return;
    }
    if (displayList?.length > 0 && !isFetchingInsights && !groupSearch) {
      if (allSorts[allSorts.length - 1]?.column !== sort?.column) {
        setAllSorts((old: any) => [...old, sort]);
      }
     
      setFilteredList([...displayList]);
      
    }
  }, [
    displayList?.length,
    sort?.column,
    isFetchingInsights,
    search,
    categoryFilter,
    brandFilter,
    groupSearch,
    gId
  ]);

  useEffect(() => {
    if (pathname.search(/insights/) !== -1) {
      setSort({ ...sort, isDirty: false });
    }
  }, [pathname]);

  useEffect(() => {
    if (groupSearch =="") {
     
      setProupSearch("");
      setFilteredList(displayList);
    }

  }, [groupSearch]);
 
  // useEffect(() => {
  //   if (pathname) {
  //     setFilteredList([]);
  //   }
  // }, [pathname, groupId]);

  const groupCounts = useMemo(() => {
    let i = 0;
    filteredList?.every((insight: any) => {
      if (insight.status) {
        i += 1;
        return true;
      }
      return false;
    });
    return [i, filteredList.length - i];
  }, [filteredList]);

  useEffect(() => {
    if (isUpdatedList) {
      refetchInsights();
    }
  }, [isUpdatedList]);

  // const arr = [1, 2, 3];

  return filteredList?.length ? (
    <GroupedVirtuoso
      style={{ flex: 1 }}
      groupCounts={groupCounts}
      defaultItemHeight={189}
      components={{
        TopItemList: ({ children, ...rest }: TopItemListProps) => (
          <div {...rest} style={{ position: "static", minHeight: "24px" }}>
            {children}
          </div>
        ),
      }}
      groupContent={(index) =>
        pathname.search("my-items") !== -1 &&
        !!index &&
        groupCounts[1] !== 0 &&
        !sort.isDirty ? (
          <Divider
            textAlign="left"
            sx={{
              marginY: "10px",
              marginX: "35px",
              "&::before": {
                width: "0",
              },
              "> span": {
                paddingLeft: 0,
              },
            }}
          >
            <Stack direction="row" alignItems="center" gap="8px">
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                component="div"
              >
                Past insights
              </Typography>
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography
                    variant="body5"
                    color={theme.palette.success.contrastText}
                  >
                    Insights that haven't been updated for at least 1 week and
                    may be inaccurate
                  </Typography>
                }
              >
                <Box
                  sx={{
                    color: theme.palette.icons.secondary,
                    cursor: "pointer",
                  }}
                  width="16px"
                  height="16px"
                >
                  <Icon
                    src="insights/info-fill.svg"
                    width="16px"
                    height="16px"
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Divider>
        ) : (
          <div style={{ height: "1px" }} />
        )
      }
      itemContent={(index) => {
        const insight = filteredList[index];
        return (
          <SalesListItem
            insight={insight}
            key={insight.id}
            setIsUpdatedList={setIsUpdatedList}
          />
        );
      }}
    />
  ) : isFetchingInsights ? (
    <>
      {/* {arr?.map((i) => (
        <SekeltonList key={i} />
      ))} */}
    </>
  ) : (
    <EmptyStates />
  );
};

export default VirtualList;
