import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../../services/apiRequest";
import { GetDWHResponse } from "../../types/warehouse";

const getdataWarehouseTables = (
  limit: number = 25,
  searchText: string = ""
): Promise<GetDWHResponse> =>
  apiRequest<GetDWHResponse>("v1/warehouse/data", "GET", {
    limit,
    search_text: searchText,
  });

type UseDataWarehouseTablesProps = {
  limit?: number;
  searchText?: string;
  options?: UseQueryOptions<GetDWHResponse, APIError>;
};

const useDataWarehouseTables = (
  props?: UseDataWarehouseTablesProps
): UseQueryResult<GetDWHResponse, APIError> =>
  useQuery<GetDWHResponse, APIError>(
    ["dataWarehouseTables", props?.limit, props?.searchText],
    () => getdataWarehouseTables(props?.limit, props?.searchText),
    props?.options
  );

export default useDataWarehouseTables;
