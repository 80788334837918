import { FC, useState } from "react";
import { Collapse, CollapseProps, Stack, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export type LabelProps = string | FC<{ open: boolean }>;
export const CollapsableBox: FC<CollapseProps & { label?: LabelProps }> = ({
  label,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const open = props.in != null ? props.in : isOpen;
  const CaretComp = open ? ExpandLess : ExpandMore;
  return (
    <Stack direction="column">
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {typeof label === "string" && <CaretComp color="primary" />}
        {typeof label !== "string" ? (
          label && label({ open })
        ) : (
          <Typography variant="body2_v2">{label}</Typography>
        )}
      </Stack>
      <Collapse {...props} in={open}>
        {props.children}
      </Collapse>
    </Stack>
  );
};
