import { Box, Stack, Typography } from "@mui/material";
import theme from "../../themes/light.ts";
// import SatelliteAlt from "@mui/icons-material/SatelliteAlt";

const EmptyState = () => {
  return (
    <Stack
      sx={{
        flex: 1,
        background: theme.palette.grey[50],
        padding: 4,
        overflow: "auto",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}>
      <Box
        sx={{
          display: "flex",
          height: "215px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img
          src="/sales/product-empty.svg"
          alt="At the moment your selection does not contain any products"
        />
        <Typography
          variant="body1"
          fontWeight="600"
          marginTop="20px"
          textAlign="center">
          At the moment your selection does not contain any products
        </Typography>
      </Box>
    </Stack>
  );
};

export default EmptyState;
