import {Box, ThemeProvider} from "@mui/material";

import theme from "../../themev2";
import FlowsTable from "./FlowsTable";
import {FC} from "react"

const Flow: FC = () => (
  <ThemeProvider theme={theme}>
    <Box px={4} py={3}>
      <FlowsTable />
    </Box>
  </ThemeProvider>
);
export default Flow;
