import {Box, Button, IconButton, Tooltip} from "@mui/material";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import {FC} from "react"

const StartRunButton: FC<{
  onClick: () => void;
  disabled: boolean;
}> = ({ onClick, disabled }) => (
  <Box display="flex" justifyContent="space-between" width="100%">
    <Button
      onClick={onClick}
      disabled={disabled}
      startIcon={<PlayCircleOutlineIcon />}
      variant="outlined"
      sx={{ justifyContent: "start", width: "100%" }}
    >
      Start Run
    </Button>
    <Tooltip
      title={disabled ? "" : "Run this configuration once"}
      placement="right"
    >
      <span>
        <IconButton>
          <InfoIcon />
        </IconButton>
      </span>
    </Tooltip>
  </Box>
);

export default StartRunButton;
