import { FC, MouseEventHandler, useState } from "react";
import {
  Divider,
  Select,
  SelectProps,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { DashboardFilters } from "../types";
import { UpdateFilterDialog } from "./UpdateFilterDialog";
import { useConfirmation } from "../../../components/ConfirmationDialog";
import { deleteDashboardFilters } from "../api";
import { DashboardFiltersMenuItem } from "./DashboardFiltersMenuItem";

const Description = (props: StackProps) => (
  <Stack direction="row" spacing={1.5} {...props}>
    <InfoRounded fontSize="small" color="secondary" />
    <Typography
      variant="body5"
      color="secondary"
      sx={{ width: (t) => t.spacing(26) }}
    >
      Save a quick filter by changing the dashboard’s filters and click “Save
      filter”.
    </Typography>
  </Stack>
);

export const DashboardFiltersSelect: FC<
  {
    allFilters: DashboardFilters[];
    defaultValue?: DashboardFilters;
    value?: DashboardFilters;
    onChange?: (filter: DashboardFilters) => void;
    onDelete: (deletedFilterId: string) => void;
    onEdit: () => void;
  } & Omit<SelectProps<string>, "onChange" | "value" | "defaultValue">
> = ({
  value: maybeValue,
  defaultValue,
  allFilters,
  onDelete,
  onEdit,
  onChange,
  ...props
}) => {
  const value = maybeValue || allFilters[0];
  const [pendingUpdateFilters, setPendingUpdateFilters] =
    useState<DashboardFilters | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmation();
  const deleteFilter = useMutation(deleteDashboardFilters);
  const handleEdit: (
    f: DashboardFilters
  ) => MouseEventHandler<HTMLButtonElement> = (f) => (e) => {
    e.stopPropagation();
    setPendingUpdateFilters(f);
  };

  const handleDelete: (
    f: DashboardFilters
  ) => MouseEventHandler<HTMLButtonElement> = (f) => (e) => {
    e.stopPropagation();
    confirm({
      title: `Delete '${f.name}' filter?`,
      description: "This filter will be deleted for the entire team.",
    })
      .then(() => deleteFilter.mutateAsync(f))
      .then(() => onDelete && onDelete(f.id))
      .then(() =>
        enqueueSnackbar("Filter deleted successfully", { variant: "success" })
      )
      .catch(() =>
        enqueueSnackbar("Failed to delete filter.", { variant: "error" })
      );
  };

  return (
    <>
      {pendingUpdateFilters && (
        <UpdateFilterDialog
          onSave={() => {
            onEdit();
            setPendingUpdateFilters(null);
          }}
          open={!!pendingUpdateFilters}
          dashboardFilters={pendingUpdateFilters}
          onClose={() => setPendingUpdateFilters(null)}
        />
      )}

      <Select
        MenuProps={{
          sx: {
            ".MuiList-root": {
              padding: 0,
              pb: 1.5,
            },
          },
        }}
        variant="standard"
        defaultValue={defaultValue?.name}
        value={value?.name || ""}
        size="medium"
        renderValue={(v) => v}
        {...props}
      >
        {allFilters.length === 1 && (
          <>
            <Description px={1} py={1} />
            <Divider />
          </>
        )}

        {allFilters.map((f, index) => (
          <DashboardFiltersMenuItem
            dashboardFilter={f}
            key={f.id}
            value={f.name}
            onDelete={!f.isDefaultFilter() ? handleDelete(f) : undefined}
            onEdit={!f.isDefaultFilter() ? handleEdit(f) : undefined}
            onClick={() => onChange && onChange(f)}
            sx={{
              mx: 1,
              mt: index === 0 ? 1 : 0,
              ".MuiListItemIcon-root": {
                minWidth: 0,
              },
            }}
          />
        ))}
      </Select>
    </>
  );
};
