import {Avatar, Box, Typography} from "@mui/material";

import {User} from "@frontegg/redux-store";
import {FC} from "react"

export const UserAvatar: FC<{ user: Partial<User> }> = ({user}) => (
    <Box sx={{display: "flex", alignItems: "center"}}>
        {user?.profilePictureUrl && (
            <Avatar
                alt={user.email}
                sx={{width: "32px", height: "32px", mr: 2}}
                src={user.profilePictureUrl}
            >
                {user.name?.charAt(0)}
      </Avatar>
    )}
    <Typography variant="body2">
      {user?.name
        ?.split(" ")
        ?.map((item, index) => (index === 0 ? item : `${item.charAt(0)}.`))
        ?.join(" ")}
    </Typography>
  </Box>
);
