import { Box, BoxProps } from "@mui/material";

export const WalmartImg = (props: any) => (
  <img
    src={`/walmart-icon.svg`}
    alt="walmart"
    width={30}
    height={30}
    {...props}
  />
);

export const WalmartIcon = ({ sx, ...props }: BoxProps) => (
  <Box
    width={56}
    height={56}
    sx={{
      cursor: "pointer",
      border: (t) => `1px solid ${t.palette.borders.default}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 1,
      ...sx,
    }}
    {...props}
  >
    <WalmartImg height="24px" width="24px" />
  </Box>
);
