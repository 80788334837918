import { createContext, ReactNode, useContext, useState } from "react";
import { ProductFilter } from "../../../types/ecommerce";
import useProductsFilter from "../../../services/ecommerce/useProductsFilters";
import { useAllProductsFilter } from "./AmazonProducts";

const amazonProductContext = createContext<any>({});
export const useAmazonProductContext = () => useContext(amazonProductContext);

const AmazonProductProvider = ({ children }: { children: ReactNode }) => {
  const [currentFilter, setFilter] = useState<ProductFilter | null>(null);
  const allFilters: ProductFilter = useAllProductsFilter();

  const { data: productsFilters = [], refetch: refetchProductsFilters } =
    useProductsFilter({
      select: (pfList) =>
        pfList.map((pf) =>
          Object.values(pf.configuration || {}).every((v) => !v)
            ? { ...pf, configuration: allFilters.configuration }
            : pf
        ),
    });
  const value = {
    currentFilter,
    setFilter,
    productsFilters,
    allFilters,
    refetchProductsFilters,
  };
  return (
    <amazonProductContext.Provider value={value}>
      {children}
    </amazonProductContext.Provider>
  );
};

export default AmazonProductProvider;
