
const createSortingStates = (valueField: string, changeField: string) => {
  return {
    VALUE_DESC: {
      name: "VALUE_DESC",
      sortComparator: (a: any, b: any) => {
        return a[valueField] > b[valueField]
          ? -1
          : a[valueField] < b[valueField]
            ? 1
            : 0;
      },
    },
    VALUE_ASC: {
      name: "VALUE_ASC",
      sortComparator: (a: any, b: any) => {
        return a[valueField] > b[valueField]
          ? 1
          : a[valueField] < b[valueField]
            ? -1
            : 0;
      },
    },
    CHANGE_DESC: {
      name: "CHANGE_DESC",
      sortComparator: (a: any, b: any) => {
        return a[changeField] > b[changeField]
          ? -1
          : a[changeField] < b[changeField]
            ? 1
            : 0;
      },
    },
    CHANGE_ASC: {
      name: "CHANGE_ASC",
      sortComparator: (a: any, b: any) => {
        return a[changeField] > b[changeField]
          ? 1
          : a[changeField] < b[changeField]
            ? -1
            : 0;
      },
    },
  };
};

// Define the transitions between the states for the value-change pair
const createTransitions = (states: any) => {
  return {
    [states.VALUE_DESC.name]: states.CHANGE_DESC,
    [states.CHANGE_DESC.name]: states.CHANGE_ASC,
    [states.CHANGE_ASC.name]: states.VALUE_ASC,
    [states.VALUE_ASC.name]: states.VALUE_DESC,
  };
};

// Create sorting state machine and transition logic for a specific value-change pair
export const createSortingMachine = (
  valueField: string,
  changeField: string
) => {
  const states = createSortingStates(valueField, changeField);
  const transitions = createTransitions(states);
  let currentState: any = states.VALUE_DESC;

  // Function to handle state transitions
  const transitionState = () => {
    currentState = transitions[currentState.name];
  };

  return { currentState, transitionState };
};
