import { RenderLeafProps } from "slate-react";
import theme from "../../../../themes/light.ts";

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (leaf.isHighlighted) {
    children = (
      <span
        style={{
          fontSize: " 16px",
          fontWeight: "400",
          lineHeight: "32px",
          letterSpacing: " -0.01em",
          color: theme.palette.primary.main,
          textDecoration: "underline",
          display: "inline",
        }}
        {...attributes}
      >
        {children}
      </span>
    );
  }
  return <span {...attributes}>{children}</span>;
};
export default Leaf;
