import {
  Avatar,
  Box,
  Button,
  Chip,
  ChipProps,
  List,
  ListItem,
  ListItemButton,
  Stack,
  StackProps,
  Typography,
  useAutocomplete,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { SxProps } from "@mui/system";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useAssigneeUpdater, useFilteredInsightsV2 } from "../useInsights";
import { useInsightAssignees } from "../hooks/selectors";
import { FrontEggTeamMember } from "../../../types";

import { useAuthorizedUsers } from "../../../hooks/useUsers";
import { AutoCompleteRenderInput } from "../AutoCompleteRenderInput";
import { useDeselectAll } from "../hooks/setters";

const sendInviteButtonStyle: SxProps = {
  height: "32px",
  backgroundColor: "primary.main",
  color: "white",
  margin: "0 7px",
  minWidth: "105px",
  whiteSpace: "nowrap",
};

const dialogStyle = {
  padding: "24px 16px 16px",
  boxShadow:
    "0px 4px 8px rgba(44, 34, 76, 0.04), 0px 1px 3px rgba(44, 34, 76, 0.12)",
  borderRadius: "8px",
  width: "370px",
  minHeight: "200px",
};

const Item: FC<
  {
    name: string;
    profileImage: string;
    onDelete?: () => void;
    onClick?: (a: any) => void;
  } & Partial<ChipProps>
> = ({ name, profileImage, onDelete = () => {}, onClick, sx, ...props }) => (
  <Chip
    avatar={
      <Avatar
        sx={{ width: "24px", height: "24px" }}
        alt={name}
        src={profileImage}
      />
    }
    sx={{
      backgroundColor: "backgrounds.secondary",
      ...sx,
    }}
    label={name}
    onDelete={onDelete}
    onClick={onClick}
    {...props}
  />
);
const Assigned: FC<
  {
    selectedMembers: FrontEggTeamMember[];
    onRemoveMember: (member: FrontEggTeamMember) => void;
  } & Partial<StackProps>
> = ({ selectedMembers, onRemoveMember, ...props }) => (
  <Stack
    direction="row"
    rowGap={0.5}
    mt={1}
    columnGap={1}
    flexWrap="wrap"
    {...props}
  >
    {selectedMembers.map((member: FrontEggTeamMember) => (
      <Item
        {...member}
        onDelete={() => {
          onRemoveMember(member);
        }}
      />
    ))}
  </Stack>
);

const MembersAutocomplete: FC<any> = ({
  options,
  assignedMembers,
  onInvite,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedMembers, setSelectedMembers] =
    useState<FrontEggTeamMember[]>(assignedMembers);

  useEffect(() => {
    const mergedMembers = [...assignedMembers, ...selectedMembers];
    const membersToKeep = [...new Set(mergedMembers.map((m) => m.id))]
      .map((mid) => mergedMembers.find((mm) => mm.id === mid))
      .filter((m) => !!m) as FrontEggTeamMember[];

    setSelectedMembers(membersToKeep || []);
  }, [assignedMembers]);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete<FrontEggTeamMember, true, any, any>({
    ...props,
    id: "use-autocomplete-demo",
    value: selectedMembers,
    options,
    defaultValue: assignedMembers,
    open: true,
    // @ts-ignore
    getOptionLabel: (option) => option.name,
    onChange: (_event, value) =>
      setSelectedMembers(value as FrontEggTeamMember[]),
    filterOptions: (options) => {
      const filterRegex = new RegExp(inputValue, "i");
      return options.filter(
        (option) =>
          !selectedMembers.find((am) => am.id === option.id) &&
          (filterRegex.test(option.name) || filterRegex.test(option.email))
      );
    },
  });
  const rootProps = getRootProps() as any;
  const { value: _v, ...inputProps } = getInputProps() as any;
  const listProps = getListboxProps() as any;
  return (
    <Stack height={400} spacing={3}>
      <Stack direction="row" {...rootProps}>
        <AutoCompleteRenderInput
          params={{ placeholder: "Name or email" }}
          inputProps={{
            ...inputProps,
            value: inputValue,
            onChange: (event: any) => {
              setInputValue(event.target.value);
              inputProps.onChange(event);
            },
          }}
        />
        <Button
          onClick={() => onInvite(selectedMembers)}
          variant="contained"
          color="primary"
          size="small"
          sx={sendInviteButtonStyle}
        >
          Assign
        </Button>
      </Stack>
      {selectedMembers.length > 0 && (
        <Stack>
          <Assigned
            maxHeight={120}
            overflow="auto"
            onRemoveMember={(m) =>
              setSelectedMembers(selectedMembers.filter((sm) => sm.id !== m.id))
            }
            selectedMembers={selectedMembers}
          />
        </Stack>
      )}

      {groupedOptions.length > 0 ? (
        <Stack spacing={1} sx={{ overflow: "auto" }}>
          <Stack>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ overflow: "auto" }}
            >
              Suggested people
            </Typography>
          </Stack>
          <Stack sx={{ overflow: "auto" }}>
            <List {...listProps} disablePadding sx={{ p: 0 }}>
              {(groupedOptions as typeof options).map(
                (option: any, index: number) => {
                  const itemProps = getOptionProps({ option, index }) as any;
                  return (
                    <ListItem key={option.id} disablePadding {...itemProps}>
                      <ListItemButton sx={{ px: 0, py: "12px" }}>
                        <ListItemAvatar sx={{ minWidth: "auto", mr: "12px" }}>
                          <Avatar
                            alt={option.name}
                            src={option.profileImageUrl}
                          />
                        </ListItemAvatar>
                        <Typography variant="body1">{option.name}</Typography>
                      </ListItemButton>
                    </ListItem>
                  );
                }
              )}
            </List>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export const AssignTeamMember: FC<{
  insightId?: string;
  insightsIds?: string[];
  closePopover: () => void;
}> = ({ insightId, insightsIds, closePopover }) => {
  const { refetch } = useFilteredInsightsV2();
  const allMembers = useAuthorizedUsers();

  const currentAssignees = useInsightAssignees(insightId || undefined);
  const { enqueueSnackbar } = useSnackbar();
  const updateAssignee = useAssigneeUpdater();
  const deselectAll = useDeselectAll();

  return (
    <Box sx={dialogStyle}>
      <Stack direction="row" spacing={1} alignItems="center">
        <MembersAutocomplete
          multiple
          assignedMembers={currentAssignees}
          options={allMembers}
          onInvite={async (selectedMembers: any) => {
            if (!insightId && insightsIds?.length === 0)
              throw Error("view should not be visible if not param exist");
            try {
              enqueueSnackbar(
                insightsIds?.length ? "Tasks assigned" : "Task assigned",
                {
                  variant: "success",
                }
              );
              closePopover();
              if (insightId) {
                await updateAssignee({
                  insightId,
                  assignees: selectedMembers.map((m: any) => m.id),
                });
              } else if (insightsIds && !!insightsIds.length) {
                const promises = insightsIds.map((id: string) =>
                  updateAssignee({
                    insightId: id,
                    assignees: selectedMembers.map((m: any) => m.id),
                  })
                );
                await Promise.all(promises);
                deselectAll();
              }
              await refetch();
            } catch (e) {
              enqueueSnackbar("Could not assign task", {
                variant: "error",
              });
            }
          }}
        />
      </Stack>
    </Box>
  );
};
