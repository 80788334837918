import {Button, Card, CardActions, CardHeader, Skeleton} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {Link} from "react-router-dom";
import {useBoard} from "../../../../services/boards";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
    createStyles({
        cardActions: {
            justifyContent: "flex-end",
        },
        greyIndicator: {
            borderTop: `${theme.spacing(0.5)} solid ${theme.palette.grey[500]}`,
        },
    })
);

type DescriptionCardProps = {
  boardId: string;
};

const DescriptionCard: FC<DescriptionCardProps> = ({ boardId }) => {
  const classes = useStyles();
  const { data: board } = useBoard(boardId);
  return (
    <Card className={classes.greyIndicator}>
      <CardHeader
        title={
          board ? (
            board.templateName
          ) : (
            <Skeleton animation="wave" width="80%" sx={{ marginBottom: 0.5 }} />
          )
        }
        subheader={
          board ? (
            board.template_description
          ) : (
            <Skeleton
              animation="wave"
              width="80%"
              style={{ marginBottom: 6 }}
            />
          )
        }
        avatar={
          board ? (
            <img
              src={board.image}
              alt={board.identifier}
              height="40px"
              width="40px"
            />
          ) : (
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          )
        }
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardActions className={classes.cardActions}>
        <Button
          component={Link}
          to={`/circuitboards/${boardId}/documentation`}
          color="primary"
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export default DescriptionCard;
