import { FC, useState } from "react";
import { FilePresent } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import GridOptionsList from "../GridOptionsList";
import { useDestinations } from "../../../../../services/destinations";
import { DestinationType } from "../../../../../enums";
import { DestinationTitleMapping } from "../../../../../mappings";
import useGoogleSheetsDestination from "../../../../../hooks/useGoogleSheetsDestination";
import { Destination } from "../../../../../types/destinations";
import { destinationTypeToImagePath, isFileDestinationType } from "./utils";
import ExportDialog from "./ExportDialog";
import FileTypeSelect from "./FileTypeSelect";
import CreateDestinationListItem from "./CreateDestinationListItem";

type DialogState = { type: DestinationType; destinationId?: string };

const CreateExportMenuList: FC<{
  onNewSource: () => void;
  onExport: () => void;
}> = ({ onNewSource, onExport = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dialogState, setDialogState] = useState<DialogState | null>(null);
  const match = useRouteMatch<{ boardId: string; runId: string }>(
    "/boards/:boardId/runs/:runId"
  );
  const { boardId } = match?.params || {};
  const { data: destinations = [] } = useDestinations(boardId || "", {
    enabled: !!boardId,
  });

  const googleSheetsTitle =
    DestinationTitleMapping[DestinationType.GOOGLE_SHEETS];
  const onGoogleSheetsError = (message: string) =>
    enqueueSnackbar(message, { variant: "error" });
  const onGoogleSheetsSuccess = () =>
    setDialogState({ type: DestinationType.GOOGLE_SHEETS });
  const onGoogleSheetsClick = useGoogleSheetsDestination({
    onSuccess: onGoogleSheetsSuccess,
    onError: onGoogleSheetsError,
  });

  return (
    <>
      <GridOptionsList
        title="Export"
        options={[
          {
            title: "Download",
            startIcon: <FilePresent />,
            listItemProps: {
              onClick: () => setDialogState({ type: DestinationType.CSV }),
              key: "download",
            },
          },
          {
            title: googleSheetsTitle,
            startIcon: (
              <Avatar
                variant="rounded"
                src={destinationTypeToImagePath(DestinationType.GOOGLE_SHEETS)}
                sx={{ height: 20, width: 20 }}
              />
            ),
            listItemProps: {
              onClick: () => onGoogleSheetsClick(),
              key: googleSheetsTitle,
            },
          },
          ...destinations.map((d: Destination) => ({
            title: d.name,
            startIcon: (
              <img
                alt=""
                src={destinationTypeToImagePath(d.type)}
                height="24px"
                width="24px"
              />
            ),
            listItemProps: {
              onClick: () =>
                setDialogState({ type: d.type, destinationId: d.id }),
              key: d.id,
            },
          })),
          {
            node: (
              <CreateDestinationListItem
                key="CreateNewExport"
                onClick={onNewSource}
              />
            ),
          },
        ]}
      />
      {dialogState?.type && (
        <ExportDialog
          type={dialogState?.type}
          destinationId={dialogState?.destinationId}
          onClose={() => setDialogState(null)}
          onExportSuccess={onExport}
          children={
            dialogState?.type &&
            isFileDestinationType(dialogState.type) && (
              <FileTypeSelect
                value={dialogState.type}
                setValue={(type: DestinationType) =>
                  isFileDestinationType(type) && setDialogState({ type })
                }
              />
            )
          }
        />
      )}
    </>
  );
};

export default CreateExportMenuList;
