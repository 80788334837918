import {Box, Button, CircularProgress, Tooltip} from "@mui/material";

import {Link} from "react-router-dom";
import {useBoardEvents} from "../../hooks/metrics/boardEvents";
import {CircuitBoard} from "../../types";
import usePublishedRun from "../Run/RunV2/hooks/usePublishedRun";
import {FC} from "react"

const LaunchAppButton: FC<{ board: CircuitBoard }> = ({board}) => {
  const {data, isLoading} = usePublishedRun(board.identifier);
  const {applicationLaunchedEvent} = useBoardEvents({
    boardId: board.identifier,
    boardTemplateId: board.template_id,
  });
  return (
    <Tooltip
      title={
        isLoading || data
          ? "Launch app of the starred run"
          : "This block does not have a published run"
      }
    >
      <Box>
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={!data}
          target="_blank"
          component={Link}
          onClick={() => applicationLaunchedEvent({ from: "boardPage" })}
          to={`/apps/${board.identifier}/dashboard/`}
        >
          {isLoading && (
            <Box marginRight={2} display="flex">
              <CircularProgress size={20} />
            </Box>
          )}
          LAUNCH APP
        </Button>
      </Box>
    </Tooltip>
  );
};

export default LaunchAppButton;
