import useHarmonizedProductsInGroup from "./Harmonization/useHarmonizedProductsInGroup";

export const useAllProductsCount = () => {
  const { data: totalInHarmonized, isLoading: isTotalInGroupLoading } =
    useHarmonizedProductsInGroup(0, 1, { refetchOnWindowFocus: false });
  return {
    totalInHarmonized,
    isLoading: isTotalInGroupLoading,
  };
};
