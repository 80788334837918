import {Box, Grid, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import {Run} from "../../../../types";
import RunCard from "./RunCard";
import {FC} from "react"

type NowRunningCardProps = {
    runs: Run[];
};

const NowRunningCard: FC<NowRunningCardProps> = ({runs}) => {
    const history = useHistory();
    return (
    <>
      <Box marginBottom={1}>
        <Typography variant="h6">Now Running</Typography>
      </Box>
      <Grid container direction="column" spacing={3}>
        {runs.map((run) => (
          <Grid item>
            <RunCard run={run} onClick={() => history.push(`runs/${run.id}`)} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NowRunningCard;
