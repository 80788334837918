import { Box, Skeleton } from "@mui/material";
import CustomDotLoader from "../../../components/CustomDotLoader";

const GraphDefaultSkeleton = () => {
  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      gap="1.5rem"
      width="100%"
    >
      <Box display="flex" flexDirection="column" gap="0.7rem">
        <Skeleton
          animation="wave"
          width="100%"
          sx={{
            height: "24px",
            borderRadius: "16px",
            transform: "unset",
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="0.7rem">
        {[...Array(3).keys()].map((ind) => (
          <Skeleton
            key={`ske2-${ind}`}
            animation="wave"
            width="100%"
            sx={{
              height: "10px",
              borderRadius: "8px",
              transform: "unset",
            }}
          />
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="0.7rem"
        sx={{
          backgroundColor: "#F4F3F8",
          padding: "2rem",
          borderRadius: "16px",
        }}
        // height="100%"
        height="460px"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CustomDotLoader />
        </Box>
      </Box>
      {/* <Box display="flex" justifyContent="space-between" marginTop="1rem">
        <Skeleton
          animation="wave"
          width={106.23}
          sx={{
            height: "40px",
            borderRadius: "16px",
            transform: "unset",
          }}
        />
        <Skeleton
          animation="wave"
          width={106.23}
          sx={{
            height: "40px",
            borderRadius: "16px",
            transform: "unset",
          }}
        />
      </Box> */}
    </Box>
  );
};
export default GraphDefaultSkeleton;
