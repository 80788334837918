import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import {
  createDashboardFilters,
  updateDashboard,
  updateDashboardFilters,
} from "../api";
import {
  Dashboard,
  DashboardFilters,
  DashboardFiltersData,
  DashboardFiltersImp,
  DashboardUpdateRequest,
} from "../types";
import RadioComponent from "../../../components/Radio";
import { AuthorizedContent } from "../../../components/AuthorizedContent";
import { PlatformRole } from "../../../enums";

export type SaveFilterType = "edit" | "new";
export type FormState = {
  createFilterType: SaveFilterType;
  filterName: string;
};

export const CreateNewFilterDialog = ({
  onCancel,
  onSave,
  onDefaultSave,
  dashboard,
  currentFilter,
  unsavedFilter,
  allFilters,
  ...props
}: DialogProps & {
  onCancel: () => void;
  onSave: (filter: DashboardFilters) => void;
  onDefaultSave: () => void;
  dashboard: Dashboard;
  currentFilter: DashboardFilters;
  unsavedFilter: DashboardFiltersImp | null;
  allFilters: DashboardFilters[];
}) => {
  const isDefault = currentFilter.isDefaultFilter();

  const UpdateFilterLabel = () => (
    <Stack>
      <Typography variant="body3">{`Update '${currentFilter.name}' filter`}</Typography>

      <Typography variant="body5" color="secondary">
        This will update this filter for the entire team.
      </Typography>
    </Stack>
  );
  const { watch, control, handleSubmit, formState, setValue } =
    useForm<FormState>({
      mode: "all",
      defaultValues: {
        createFilterType: "edit",
        filterName: "",
      },
    });

  const updateDashboardMutation = useMutation(
    (updateReq: DashboardUpdateRequest) =>
      updateDashboard(dashboard.id.toString(), updateReq)
  );

  const updateDashboardFiltersMutation = useMutation(
    (dashboardFilters: DashboardFiltersData) =>
      updateDashboardFilters(dashboardFilters)
  );

  const createNewDashboardFilters = useMutation(
    (dashboardFilters: DashboardFiltersData) =>
      createDashboardFilters(dashboardFilters)
  );

  const snackbar = useSnackbar();

  const resetForm = () => {
    setValue("createFilterType", "edit");
    setValue("filterName", "");
  };

  const handleSave = handleSubmit(async (formValues) => {
    if (!unsavedFilter) return;

    if (formValues.createFilterType === "edit") {
      // Update the default filter
      if (isDefault) {
        return updateDashboardMutation
          .mutateAsync({
            filters: unsavedFilter.filters,
          })
          .then(() => {
            snackbar.enqueueSnackbar(`'Default' filter updated.`, {
              variant: "success",
            });
            return onDefaultSave && onDefaultSave();
          })
          .catch(() => {
            snackbar.enqueueSnackbar("Failed to update filter.", {
              variant: "error",
            });
            return onCancel && onCancel();
          })
          .finally(() => resetForm());
      }
      // Update an existing filter
      return updateDashboardFiltersMutation
        .mutateAsync({
          id: unsavedFilter.id,
          name: unsavedFilter.name,
          filters: unsavedFilter.filters,
          dashboardId: dashboard.id,
        })
        .then((f) => {
          snackbar.enqueueSnackbar(`Filter '${f.name}' updated.`, {
            variant: "success",
          });
          return onSave && onSave(DashboardFiltersImp.fromData(f));
        })
        .catch(() => {
          snackbar.enqueueSnackbar("Failed to update filter.", {
            variant: "error",
          });
          return onCancel && onCancel();
        })
        .finally(() => resetForm());
    }
    // Create a new filter

    // Check if filter with the same name already exists
    const existingFilter = allFilters.find(
      (f) => f.name === formValues.filterName
    );
    if (existingFilter || formValues.filterName === "Default") {
      snackbar.enqueueSnackbar(
        `Filter with name '${formValues.filterName}' already exists.`,
        {
          variant: "error",
        }
      );
      return;
    }

    return createNewDashboardFilters
      .mutateAsync({
        name: formValues.filterName,
        filters: unsavedFilter.filters,
        id: "",
        dashboardId: dashboard.id,
      })
      .then((df) => {
        snackbar.enqueueSnackbar(`Filter '${df.name}' created successfully.`, {
          variant: "success",
        });
        return onSave && onSave(DashboardFiltersImp.fromData(df));
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to create new filter.", {
          variant: "error",
        });
        return onCancel && onCancel();
      })
      .finally(() => resetForm());
  });

  return (
    <Dialog {...props}>
      <form onSubmit={handleSave}>
        <DialogTitle id="alert-dialog-title">Save filter</DialogTitle>
        <DialogContent>
          <FormControl>
            <Controller
              rules={{ required: true }}
              control={control}
              name="createFilterType"
              render={({ field }) => (
                <AuthorizedContent
                  requiredRoles={[PlatformRole.Administrator]}
                  render={(isAuthorized) => (
                    <RadioGroup
                      {...field}
                      defaultValue="edit"
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    >
                      <Tooltip
                        title={
                          isAuthorized || !isDefault
                            ? ""
                            : "Only admins can replace the default filter"
                        }
                      >
                        <Box>
                          <FormControlLabel
                            value="edit"
                            disabled={!isAuthorized && isDefault}
                            sx={{ mb: 2, alignItems: "start" }}
                            control={<RadioComponent sx={{ pt: 0.5 }} />}
                            label={<UpdateFilterLabel />}
                          />
                        </Box>
                      </Tooltip>
                      <FormControlLabel
                        value="new"
                        control={<RadioComponent />}
                        label="Save as a new filter"
                      />
                    </RadioGroup>
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name="filterName"
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mt: 1 }}
                  placeholder="Filter name"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  disabled={watch("createFilterType") !== "new"}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={
              (watch("createFilterType") === "new" &&
                !formState.dirtyFields.filterName) ||
              formState.isSubmitting ||
              formState.isValidating
            }
            color="primary"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
