import {Box, Stack} from "@mui/material";
import {useForm} from "react-hook-form";
import {JSONSchema4} from "json-schema";
import {ConnectorType} from "../../../../enums";
import MappingBox, {Property} from "./MappingBox";
import DataPreviewCard from "./DataPreviewCard";
import MappingActions from "./MappingActions";
import {FC} from "react"

type MappingFormProps = {
  to: JSONSchema4;
  from: JSONSchema4;
  onSubmit: (formValues: Record<string, string>) => void;
  onBack: () => void;
  defaultValues?: Record<string, any>;
  connectorType: ConnectorType;
};

const MappingForm: FC<MappingFormProps> = ({
  to,
  from,
  onSubmit,
  onBack,
  defaultValues,
  connectorType,
}) => {
  // @ts-ignore
  const toProperties = Object.entries(to.properties);

  // @ts-ignore
  const fromProperties: Property[] = Object.entries(from.properties);
  const autoFilledDefaultValues: Record<string, string> = toProperties.reduce(
    (obj, item) => {
      const found = fromProperties.find((option) => option[0] === item[0]);
      return Object.assign(obj, found ? { [item[0]]: found[0] } : {});
    },
    {}
  );
  const { formState, handleSubmit, control } = useForm({
    defaultValues:
      defaultValues && Object.keys(defaultValues).length > 0
        ? defaultValues
        : autoFilledDefaultValues,
    mode: "onChange",
  });
  const { isValid, isSubmitting } = formState;
  const onFormSubmit = handleSubmit(async (formValues) => {
    onSubmit(formValues);
  });

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onFormSubmit();
          e.stopPropagation();
        }}
      >
        <Stack direction="row" spacing={4}>
          <Box width="50%">
            {toProperties.map((property) => (
              <MappingBox
                property={property}
                fromProperties={fromProperties}
                control={control}
              />
            ))}
            <MappingActions
              onBack={onBack}
              isValid={isValid}
              isSubmitting={isSubmitting}
            />
          </Box>
          {!(from.data || []).length ? null : (
            <Box width="50%">
              <DataPreviewCard connectorType={connectorType} data={from.data} />
            </Box>
          )}
        </Stack>
      </form>
    </>
  );
};

export default MappingForm;
