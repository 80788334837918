import { Block, Board } from "@noogata/types";
import { Pipeline } from "../types";
import { BoardLibrary } from "../enums";

export type BoardVersionAnnotations = {
  template_name: string;
  image: string;
};

export type BoardTemplateVersion = {
  version: string;
  annotations?: BoardVersionAnnotations;
  created: string;
  name: string;
  description: string;
  urls: string[];
};

export type BoadTemplateProps = {
  id: string;
  name: string;
  description: string;
  version: string;
  image: string;
  applications?: string[];
  pipelines?: Pipeline[];
  library: BoardLibrary;
  tags?: string[];
  managed?: boolean;
};

export class BoardTemplate implements BoadTemplateProps {
  id: string;

  name: string;

  description: string;

  version: string;

  image: string;

  applications?: string[];

  pipelines?: Pipeline[];

  library: BoardLibrary;

  tags?: string[];

  blocks?: Block[];

  managed?: boolean;

  constructor(props: BoadTemplateProps) {
    this.id = props.id;
    this.name = props.name;
    this.description = props.description;
    this.version = props.version;
    this.image = props.image;
    this.applications = props.applications;
    this.pipelines = props.pipelines;
    this.library = props.library;
    this.tags = props.tags;
    this.managed = props.managed;
  }

  static toBoard(board: BoardTemplate): Board {
    return {
      identifier: board.id,
      displayName: board.name,
      description: board.description,
      version: board.version,
      maintainers: [],
      applications: [],
      template_description: board.description,
      library: board.library,
      pipelines:
        (board?.pipelines?.length || 0) > 0
          ? (board?.pipelines as Pipeline[])
          : [{} as Pipeline],
      template_id: board.id,
      tags: board.tags || [],
      blocks: board.blocks,
      managed: board.managed,
    };
  }
}
