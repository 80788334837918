import {Box, Chip, Skeleton, Typography} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {ConnectorType} from "../../../../enums";
import {ConnectorLogoMapping} from "../../../../mappings";
import {useConnectorOptions} from "../../../../services/connectors";
import {ConnectorTypeCardProps} from "../ConnectorTypeSelection";
import InputTypeSelectionCard from "../InputTypeSelectionCard";
import {FC} from "react"

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(0.5),
    },
}));

const ExternalDataSourceCard: FC<ConnectorTypeCardProps> = ({
  setInputValue,
}) => {
  const classes = useStyles();
  const { data: connectorOptions } = useConnectorOptions();
  const notPreviouslyShownOptions =
    connectorOptions &&
    connectorOptions.filter(
      (option) =>
        ![
          ConnectorType.AMAZON_PRODUCTS,
          ConnectorType.GOOGLE_SHEETS,
          ConnectorType.LOCAL,
          ConnectorType.BOARD,
        ].includes(option.type)
    );
  const numOfConnectorImages = 3;
  const displayImages =
    notPreviouslyShownOptions &&
    notPreviouslyShownOptions.slice(0, numOfConnectorImages);
  const displayInChip =
    notPreviouslyShownOptions &&
    notPreviouslyShownOptions.slice(numOfConnectorImages);
  return (
    <InputTypeSelectionCard
      onClick={() =>
        displayImages && setInputValue({ type: displayImages[0].type })
      }
      children={
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              {displayImages ? (
                displayImages.map((option) => (
                  <img
                    key={option.type}
                    src={`/${ConnectorLogoMapping[option.type]}`}
                    alt={option.type}
                    height="40px"
                    width="40px"
                    className={classes.icon}
                  />
                ))
              ) : (
                <Skeleton variant="rectangular" height={35} />
              )}
            </Box>
            <Box m={1}>
              {displayInChip && displayInChip.length > 0 ? (
                <Chip
                  color="primary"
                  size="small"
                  label={`${displayInChip.length} More`}
                />
              ) : null}
            </Box>
          </Box>
          <Typography variant="h6">Integrate Data Source</Typography>
          <Typography variant="caption">
            Connect a third party data source with the Noogata platform
          </Typography>
        </>
      }
    />
  );
};

export default ExternalDataSourceCard;
