import { UseQueryOptions } from "react-query";
import { APIError } from "../apiRequest";
import { InputSchema } from "../connectorsV2";
import { useCreateSchema } from "./index";

export default function useCreateLocalSchema(
  boardId: string,
  inputName: string,
  fileName: string,
  options?: UseQueryOptions<InputSchema, APIError>
) {
  return useCreateSchema(
    "internal",
    { circuitboard_id: boardId, input_name: inputName, file_name: fileName },
    options
  );
}
