import EditorField from "../../../components/EditorField";
import {ParameterTextFieldProps} from "./types";
import {FC} from "react"

const ParameterEditorField: FC<ParameterTextFieldProps> = (props) => (
    <EditorField
        value={props.value || ""}
        onChange={props.onChange}
        textFieldProps={{
            label: props.label,
            margin: "dense",
            variant: "outlined",
            fullWidth: true,
      helperText: props.helperText,
    }}
    editorProps={{
      name: props.name,
      mode: "json",
      theme: "tomorrow",
      height: "200px",
      width: "100%",
      showPrintMargin: false,
      editorProps: { $blockScrolling: true },
    }}
    error={props.error?.message}
  />
);

export default ParameterEditorField;
