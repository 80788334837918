import { FC } from "react";

import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export const LoadableButton: FC<
  {
    isLoading: boolean;
  } & Partial<ButtonProps>
> = ({ isLoading, endIcon, disabled, ...props }) => (
  <Box>
    <Button
      variant="outlined"
      endIcon={isLoading ? <CircularProgress size={15} /> : endIcon}
      disabled={disabled || isLoading}
      {...props}
    />
  </Box>
);
