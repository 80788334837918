import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useHistory, useLocation } from "react-router";
import OutputsDropdown from "../pages/Run/RunV2/OverviewSection/OutputsDropdown";
import { RunOutput } from "../types";
import { capitaliseAllFirstLetters } from "../utils";
import OutputRow from "./OutputRow";
import SkeletonRow from "./SkeletonRow";
import { FC } from "react";

const OutputsTable: FC<{
  outputs?: RunOutput[];
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ outputs, open, setOpen }) => {
  const history = useHistory();
  const location = useLocation();

  const handleRowClick = (output_export_name: string) => {
    const queryParams = new URLSearchParams(location.search);
    const outputFile = capitaliseAllFirstLetters(output_export_name, "_");
    queryParams.set("name", outputFile);
    queryParams.set("type", "output");
    history.push(`data?${queryParams.toString()}`);
    setOpen(!open);
  };

  return (
    <TableContainer sx={{ mb: 4, pt: 0 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pt: 0, pl: 0 }}>
              <OutputsDropdown
                outputs={outputs}
                open={open}
                setOpen={setOpen}
              />
            </TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>Rows</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {outputs
            ? outputs
                .filter((output) => !!output.export_name)
                .map((output) => (
                  <OutputRow
                    output={{
                      ...output,
                    }}
                    onClick={() => handleRowClick(output.export_name)}
                  />
                ))
            : [...Array(3).keys()].map((index) => (
                <SkeletonRow index={index} />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OutputsTable;
