import { Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const MyItems = () => {
  const history = useHistory();
  return (
    <>
      <img
        src="/insights/empty-my-items.svg"
        alt="Saved insights will show up here"
      />
      <Typography variant="body1" fontWeight="600" marginTop="20px">
        Saved insights will show up here
      </Typography>
      <Typography variant="body1">
        Monitor and act on your insights from one place
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginTop: "20px" }}
        onClick={() => history.push("/insights")}
      >
        View insights
      </Button>
    </>
  );
};

export default MyItems;
