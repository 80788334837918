import { Box, Typography } from "@mui/material";

export default (
  <>
    <Typography component={Box} variant="caption">
      This block is <b>managed</b>.
    </Typography>
    <Typography component={Box} variant="caption">
      Please contact your Customer Success Manager for setup.
    </Typography>
  </>
);
