import { Box, Link, Tooltip } from "@mui/material";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import theme from "../../../themes/light.ts";
import CustomHeader from "../CustomHeader.tsx";
import { DarkStarIcon } from "../../../assets";

export const agGridBoxStyle = {
  padding: "1rem 2rem",
  width: "100%",
  position: "relative",
  height: "calc(100% - 50px)",
  "& .ag-root-wrapper": {
    border: "none",
    boxShadow:
      "0px 6px 15px 0px rgba(44, 34, 76, 0.05),0px 8px 32px 0px rgba(44, 34, 76, 0.05)",
  },
  "& .ag-header-cell": {
    ":nth-child(1)": {
      paddingLeft: "3.2rem",
    },
  },
  "& .ag-cell": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif !important",
  },
  "& .ag-row": {
    cursor: "pointer",
    ":hover": {
      background: theme.palette.primary.light,
      "& .MuiCheckbox-root": {
        display: "inline-flex",
      },
      ":hover": {
        "#action-container": {
          display: "inline-flex",
        },
      },
    },
  },
  "& .ag-row-selected": {
    background: "#EEE5FE",
  },
  "& .ag-header-cell-menu-button": {
    padding: "4px",
    ":hover": {
      boxShadow: "unset !important",
      borderRadius: "3px !important",
    },
  },
  "& .ag-header-cell-text": {
    fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif !important",
  },
};
const baseColDefs = [
  {
    headerName: "Product",
    field: "product",
    minWidth: 280,
    flex: 1.5,
    valueFormatter: undefined,
    cellRenderer: (p: any) => {
      if (p?.data?.asin) {
        const { image_url, asin } = p.data;
        return (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                height: "100%",
                paddingLeft: "2.2rem",
                position: "relative",
              }}
            >
              <Tooltip
                placement="top-start"
                title={
                  image_url ? (
                    <Box
                      component={"img"}
                      src={image_url}
                      alt="image"
                      sx={{
                        height: "130px",
                        width: "100px",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    asin
                  )
                }
              >
                <Box
                  sx={{
                    height: "40px",
                    width: "40px",
                    minWidth: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "0.496px solid rgba(0, 0, 0, 0.10)",
                    borderRadius: "4px",
                  }}
                >
                  {image_url ? (
                    <Box
                      component={"img"}
                      src={image_url}
                      alt="image"
                      sx={{
                        height: "100%",
                        objectFit: "contain",
                        maxWidth: "100%",
                      }}
                    />
                  ) : (
                    <CropOriginalIcon
                      style={{ color: "#8E4FF6", fontSize: "20px" }}
                    />
                  )}
                </Box>
              </Tooltip>
              <Link
                underline={"hover"}
                target={"_blank"}
                href={asin ? `https://amazon.com/d/${asin}` : ""}
                sx={{
                  color: theme.palette.secondary.main,
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {asin}
              </Link>
            </Box>
          </>
        );
      }
    },
    sortable: false,
  },
  {
    headerName: "Asin",
    field: "asin",
    flex: 1,
    valueFormatter: undefined,
    cellRenderer: undefined,
    hide: true,
  },
  {
    headerName: "Image",
    field: "image_url",
    flex: 1,
    valueFormatter: undefined,
    cellRenderer: undefined,
    hide: true,
  },
  {
    headerName: "Title",
    field: "title",
    flex: 2,
    valueFormatter: undefined,
    cellRenderer: undefined,
  },
  {
    headerName: "",
    field: "label",
    flex: 0.5,
    valueFormatter: undefined,
    cellRenderer: (p: any) => (
      <>
        {p.value === "my product" ? (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              "& path": {
                fill: theme.palette.primary.main,
              },
            }}
          >
            <Box>
              <DarkStarIcon />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    ),
    sortable: true,
    headerComponent: CustomHeader,
    headerComponentParams: {
      showHeaderIcon: true,
    },
  },
];

export const toTitleCase = (str: string) =>
  str?.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );

const getHeaderName = (field: string) => toTitleCase(field.replace(/_/g, " "));

export const getColDefs = (schemaFields: string[]) => {
  return [
    ...baseColDefs,
    ...(schemaFields
      ?.filter(
        (field) => !["asin", "image_url", "title", "label"].includes(field)
      )
      .map((field) => ({
        headerName: getHeaderName(field),
        field: field,
        flex: 1,
        valueFormatter: undefined,
        cellRenderer: undefined,
      })) || []),
  ];
};
