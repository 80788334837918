import {Autocomplete} from "@mui/lab";
import {
    AutocompleteProps,
    CircularProgress,
    IconButton,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
} from "@mui/material";
import RemoveCircle from "@mui/icons-material/DeleteOutline";
import {useMutation} from "react-query";
import {useSnackbar} from "notistack";
import {ProductsFilter} from "../../../types/ecommerce";
import {ALL_PRODUCTS_FILTER} from "./AmazonProducts";
import {deleteProductsFilter} from "../../../services/ecommerce/api";
import useProductsFilter from "../../../services/ecommerce/useProductsFilters";
import {CURRENT_SELECTION_FILTER} from "./Filters";
import {FC} from "react"

export type FiltersSelectProps = Partial<
    AutocompleteProps<
        {
            label: string;
            filter?: ProductsFilter;
        },
        any,
        any,
        any
  >
> & {
  filters: ProductsFilter[];
  isLoading?: boolean;
  onDelete?: (filterId: string) => void;
  onCreateProductGroup?: (fil: ProductsFilter) => void;
};

export const DeleteFilterIcon: FC<{
  filterName: string;
  filterId: string;
  onDelete?: (filterId: string) => void;
}> = ({ filterId, filterName, onDelete = () => {} }) => {
  const mutation = useMutation((id: string) => deleteProductsFilter(id));
  const { refetch } = useProductsFilter();
  const snackbar = useSnackbar();
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        mutation
          .mutateAsync(filterId)
          .then(() => {
            onDelete(filterId);
            snackbar.enqueueSnackbar(
              `Filter ${filterName} removed successfully`,
              { variant: "success" }
            );
            return refetch();
          })
          .catch((e) => {
            snackbar.enqueueSnackbar(`Failed to remove filter: ${e.message}`, {
              variant: "error",
            });
          });
      }}
    >
      {mutation.isLoading ? (
        <CircularProgress size={18} />
      ) : (
        <RemoveCircle fontSize="small" />
      )}
    </IconButton>
  );
};

export const FiltersSelect: FC<FiltersSelectProps> = ({
  filters,
  sx: propsSx,
  isLoading,
  onDelete,
  ...props
}) => {
  const filterOptions = filters.map((f) => ({ label: f.name, filter: f }));

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Autocomplete
        isOptionEqualToValue={(option, value) =>
          option?.filter?.id === value?.filter?.id
        }
        disablePortal
        disableClearable
        id="product-list-select"
        options={filterOptions}
        defaultValue={filterOptions[0]}
        sx={{ width: 300, ...propsSx }}
        renderOption={(params, a) => (
          <MenuItem {...params}>
            <ListItemText>{a.label}</ListItemText>
            {a?.filter?.id &&
            a?.filter?.id !== ALL_PRODUCTS_FILTER.id &&
            a?.filter?.id !== CURRENT_SELECTION_FILTER.id ? (
              <DeleteFilterIcon
                filterName={a?.filter?.name}
                filterId={a?.filter?.id as string}
                onDelete={onDelete}
              />
            ) : null}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="medium"
            placeholder="Choose product list"
          />
        )}
        {...props}
      />
      {isLoading && <CircularProgress size={20} />}
    </Stack>
  );
};
