import { useQuery, UseQueryOptions } from "react-query";
import { APIError } from "../apiRequest";
import { listFlows } from "./api";
import { ListFlowsResponse } from "./types";

export default function useListFlow(
  options?: UseQueryOptions<ListFlowsResponse, APIError>
) {
  return useQuery<ListFlowsResponse, APIError>(
    ["useListFlows"],
    () => listFlows(),
    {
      ...options,
      staleTime: Infinity,
    }
  );
}
