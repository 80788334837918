import { useAuthUserOrNull } from "@frontegg/react-hooks";
import LockIcon from "@mui/icons-material/Lock";
import PeopleIcon from "@mui/icons-material/People";
import { alpha, ListItemText, MenuItem } from "@mui/material";

import { VisibilityType } from "../enums";
import { useUserTenant } from "../hooks";

export const VisibilityMenu = ({
  handler,
  shouldDisable,
}: {
  handler?: (value: VisibilityType) => void;
  shouldDisable?: boolean;
}): JSX.Element[] => {
  const user = useAuthUserOrNull();
  const tenant = useUserTenant(user || undefined);
  return [
    <MenuItem
      key={VisibilityType.PRIVATE}
      value={VisibilityType.PRIVATE}
      sx={{ whiteSpace: "normal", width: "100%" }}
      onClick={handler ? () => handler(VisibilityType.PRIVATE) : undefined}
      disabled={shouldDisable}
    >
      <LockIcon
        sx={{
          mr: 1.5,
          color: alpha("#000000", 0.54),
        }}
      />
      <ListItemText primary="Private" secondary="Only you can see this block" />
    </MenuItem>,
    <MenuItem
      key={VisibilityType.WORKSPACE}
      value={VisibilityType.WORKSPACE}
      sx={{ whiteSpace: "normal", width: "100%" }}
      onClick={handler ? () => handler(VisibilityType.WORKSPACE) : undefined}
    >
      <PeopleIcon
        sx={{
          mr: 1.5,
          color: alpha("#000000", 0.54),
        }}
      />
      <ListItemText
        primary="Workspace Visible"
        secondary={`All members of the ${tenant?.name}
                 Workspace can see and edit this block.`}
      />
    </MenuItem>,
  ];
};
