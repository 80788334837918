import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {FC, useEffect} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {IllustrationPlaceholder, PageHeader} from "../../components";
import Loadable from "../../components/Loadable";
import theme from "../../themev2";
import {prettifyString} from "../../utils";
import {useDashboards} from "./api";
import {DashboardData} from "./types";
import {useQueryParams} from "../../hooks";

enum DashboardType {
  BRAND_INTELLIGENCE = "Brand Intelligence",
  CATEGORY_INSIGHTS = "Category Insights",
  CONSUMER_SEARCH = "Consumer Search",
  PRODUCT_INTELLIGENCE = "Product Insights",
}

const TypeToOrder: Record<DashboardType, number> = {
  [DashboardType.CATEGORY_INSIGHTS]: 1,
  [DashboardType.BRAND_INTELLIGENCE]: 2,
  [DashboardType.PRODUCT_INTELLIGENCE]: 3,
  [DashboardType.CONSUMER_SEARCH]: 4,
};

const TYPE_TO_DASHBOARD_IMAGE: Record<string, string> = {
  [DashboardType.CONSUMER_SEARCH]: "/dashboard-img-consumer-search.svg",
  [DashboardType.PRODUCT_INTELLIGENCE]:
    "/dashboard-img-product-intelligence.svg",
  [DashboardType.BRAND_INTELLIGENCE]: "/dashboard-img-brand-intelligence.svg",
  [DashboardType.CATEGORY_INSIGHTS]: "/dashboard-img-competitive-landscape.svg",
  FALLBACK_IMG: "/dashboard-img-product-intelligence.svg",
};

const extractDashboardDisplayName = (name: string) =>
  name.split(" ").slice(0, 2).join(" ");

export const DashboardsCard: FC<
  { src: string } & DashboardData & Partial<Omit<CardProps, "id">>
> = ({ src, name, description, id, ...props }) => {
  const match = useMediaQuery("(max-width: 1400px)");
  return (
    <Card
      sx={{
        height: "100%",
        minWidth: (t) => t.spacing(match ? 400 / 8 : 60),
        maxWidth: (t) => t.spacing(70.5),
        borderRadius: "12px",
        ml: 4,
      }}
      id={id.toString()}
      {...props}
    >
      <CardActionArea component={Stack} sx={{ height: "100%", width: "100%" }}>
        <CardMedia
          sx={{ height: (t) => t.spacing(25) }}
          component="img"
          image={src}
          alt="dashboard card"
        />

        <CardContent sx={{ height: (t) => t.spacing(14) }}>
          <Typography gutterBottom variant="headline3" component="div">
            {prettifyString(extractDashboardDisplayName(name))}
          </Typography>
          <Typography
            variant="body3"
            component="div"
            sx={{ color: (t) => t.palette.icons.secondary }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const Dashboards: FC = () => {
  const history = useHistory();
  const headerLabel = useQueryParams().get("headerLabel") || "Digital Shelf";
  const custom = useQueryParams().get("custom") === "true";
  const match = useRouteMatch<{ tabName: string }>("/dashboards/:tabName");
  const { data = [], isLoading } = useDashboards({
    options: {
      refetchOnWindowFocus: false,
      select: (data) => data.filter((d) => d.isCustom() === custom),
    },
  });
  useEffect(() => {
    if (!match?.params.tabName) {
      const firstTab = data.find((d) => !!d.tab);
      if (firstTab) {
        history.push(
          `/dashboards/${firstTab.tab}?tabName=${firstTab.name}&custom=${custom}&headerLabel=${headerLabel}`
        );
      }
    }
  }, [!match?.params.tabName, data]);
  return (
    <ThemeProvider theme={theme}>
      <PageHeader headerLabel={headerLabel} />
      <Box>
        <Stack
          flexWrap="wrap"
          direction="row"
          px={6}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          <Loadable isLoading={isLoading}>
            <Grid container spacing={1} maxWidth={(t) => t.spacing(150)}>
              {data
                .filter((d) => d.tab === match?.params.tabName)
                .sort((da, db) => {
                  if (
                    TypeToOrder[da.name as DashboardType] <
                    TypeToOrder[db.name as DashboardType]
                  ) {
                    return -1;
                  }
                  if (
                    TypeToOrder[da.name as DashboardType] >
                    TypeToOrder[db.name as DashboardType]
                  ) {
                    return 1;
                  }
                  return 0;
                })
                .map((d) => (
                  <Grid
                    mt={4}
                    item
                    key={d.id}
                    xs={12}
                    lg={6}
                    display="flex"
                    justifyContent="space-around"
                  >
                    <DashboardsCard
                      onClick={() =>
                        history.push(
                          `/dashboards/${match?.params.tabName}/${d.id}?boardName=${d.name}&custom=${custom}`
                        )
                      }
                      src={
                        TYPE_TO_DASHBOARD_IMAGE[
                          extractDashboardDisplayName(d.name)
                        ] || TYPE_TO_DASHBOARD_IMAGE.FALLBACK_IMG
                      }
                      {...d}
                    />
                  </Grid>
                ))}
              {!data.length && !isLoading && (
                <IllustrationPlaceholder
                  imageHeight={300}
                  imageWidth={300}
                  text="No Dashboards found."
                />
              )}
            </Grid>
          </Loadable>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};
export default Dashboards;
