import { Box, Skeleton, Stack, Typography } from "@mui/material";
import theme from "../../themes/light";
import { useAssortmentIntelligence } from "./AssortmentIntelligenceProvider";
import Table from "./components/Table/Table";
import TableSkeleton from "./components/Table/TableSkeleton";
import EstimatedRevenue from "./components/EstimatedRevenue/EstimatedRevenue";
import EstimatedRevenueSkeleton from "./components/EstimatedRevenue/EstimatedRevenueSkeleton";
import SalesPerformance from "./components/SalesPerformance/SalesPerformance";

const BrandStrategy = () => {
  const {
    brandStrategyData,
    brandStrategyLoading,
    salesPerformanceData,
    salesPerformanceLoading,
  } = useAssortmentIntelligence();

  const { estimated_revenue, pricing_table_schema, pricing_table_data } =
    brandStrategyData ?? {};
  const { table_data, table_schema } = salesPerformanceData ?? {};

  return (
    <Stack
      sx={{
        flexDirection: "column",
        gap: "16px",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Stack
          sx={{
            width: "50%",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            padding: "16px",
            gap: "8px",
            height: "500px !important",
          }}
        >
          <Typography
            variant="headline4"
            display="flex"
            sx={{ color: theme.palette.text.primary }}
          >
            Brands Estimated Revenue
          </Typography>
          {estimated_revenue && !brandStrategyLoading ? (
            <EstimatedRevenue data={estimated_revenue} />
          ) : (
            <EstimatedRevenueSkeleton />
          )}
        </Stack>
        <Stack
          sx={{
            width: "50%",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            padding: "16px",
            gap: "8px",
            height: "500px !important",
          }}
        >
          <Typography
            variant="headline4"
            display="flex"
            sx={{ color: theme.palette.text.primary }}
          >
            Brands Strategy
          </Typography>
          {pricing_table_schema &&
          pricing_table_data &&
          !brandStrategyLoading ? (
            <Table
              data={pricing_table_data}
              schema={pricing_table_schema}
              rowsPerPage={6}
              variant={"half"}
              defaultSortBy="Revenue"
            />
          ) : (
            <TableSkeleton columnsNumber={7} rowsNumber={6} variant={"half"} />
          )}
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "100%",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
          }}
        >
          <Typography
            variant="headline4"
            display="flex"
            sx={{ color: theme.palette.text.primary }}
          >
            Sales Performance Overview
          </Typography>
        </Box>
        {table_data && table_schema && !salesPerformanceLoading ? (
          <SalesPerformance data={table_data} schema={table_schema} />
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{
              height: 500,
              width: "90%",
              borderRadius: "8px",
              transform: "unset",
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default BrandStrategy;
