import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";

interface SelectFilterProps {
  element: any;
  param: any;
  setParam: any;
}

const SelectFilter: FC<SelectFilterProps> = (props: SelectFilterProps) => {
  const { element, param, setParam } = props;
  return (
    <Box
      sx={{
        width: "50%",
      }}
    >
      <InputLabel htmlFor="color" sx={{ marginBottom: "5px" }}>
        {element?.name}
      </InputLabel>
      <Select value={param} onChange={(e) => setParam(e.target.value)}>
        {element?.options?.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectFilter;
