import {ThemeProvider} from "@mui/material";

import {Redirect, Route, Switch} from "react-router-dom";
import {useParams, useRouteMatch} from "react-router";
import theme from "../../themev2";
import FlowHeader from "./FlowHeader";
import FlowsTabs from "./FlowsTabs";
import Overview from "./Overview";
import {Configuration} from "./Configuration";
import {FC} from "react"

export const FlowSections = {
    Overview: "overview",
    Configuration: "configuration",
    Data: "data",
};

const Flow: FC = () => {
    const {path, url} = useRouteMatch();
    const {flowId}: { flowId: string } = useParams();
  return (
    <ThemeProvider theme={theme}>
      <FlowHeader flowId={flowId} />
      <Route path={`${path}/:sectionId`}>
        <FlowsTabs />
      </Route>
      <Switch>
        <Route path={`${path}/${FlowSections.Overview}`}>
          <Overview />
        </Route>
        <Route path={`${path}/${FlowSections.Data}`}>Data section</Route>
        <Route path={`${path}/${FlowSections.Configuration}`}>
          <Configuration />
        </Route>
        <Route path={path}>
          <Redirect to={`${url}/${FlowSections.Configuration}`} />
        </Route>
      </Switch>
    </ThemeProvider>
  );
};
export default Flow;
