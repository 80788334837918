import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import { CloseIcon } from "../../assets";
import theme from "../../themes/light";
import { updateTask } from "./useTaskApi";
import { handleToast } from "./TaskUtils";
import { useHistory } from "react-router";
import { useTasksContext } from "./hooks/selectors";

const ArchivePopup: React.FC<{
  open: boolean;
  handleClose: (id: string) => void;
  taskId: string;
}> = ({ open, handleClose, taskId }) => {
  const { setIsUpdated } = useTasksContext();
  const history = useHistory();
  const handleUpdateTask = async (status: string) => {
    const payload = {
      status,
      archived: true,
    };
    setIsUpdated(false);
    handleToast("Task Moved into Archive", taskId, handleBoardLink);
    if (taskId !== undefined) {
      await updateTask(payload, taskId);
      setIsUpdated(true);
    }
    handleClose(taskId);
  };

  const handleBoardLink = () => {
    history.push("/tasks");
  };

  return (
    <>
      <Dialog
        open={open}
        onClick={() => handleClose("")}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "420px",
            width: "100%",
            margin: 0,
            padding: "24px",
            gap: "8px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px" }}>
          <Typography variant="headline3">Archive Task</Typography>
          <Box
            sx={{
              position: "absolute",
              top: "12px",
              right: "12px",
              cursor: "pointer",
            }}
            onClick={() => handleClose("")}
          >
            <CloseIcon />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            marginTop: "0px",
            "& .MuiFormControl-root": { width: "100%" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="24px"
          >
            <Typography>Are you sure you want to archive the task?</Typography>
            <Box
              display="flex"
              justifyContent="flex-end"
              gap="10px"
              width="100%"
            >
              <Button
                onClick={() => handleClose("")}
                color="secondary"
                sx={{
                  border: "1px solid #D7D5DB",
                  boxShadow: "0px 1px 1px 0px rgba(44, 34, 76, 0.04)",
                  padding: "10px 16px",
                  borderRadius: "4px",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  height: "40px",
                }}
              >
                Keep in todo list
              </Button>
              <Button
                sx={{
                  padding: "10px 16px",
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 600,
                  height: "40px",
                }}
                onClick={() => handleUpdateTask("archived")}
              >
                Archive
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ArchivePopup;
