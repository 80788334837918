import { FC, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode } from "react-markdown";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export type Option = {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  node?: ReactNode;
  title?: string;
  listItemProps?: Partial<ListItemButtonProps>;
};

export type GridOptionsListProps = {
  title: string;
  options?: Option[];
  onClick?: (option: Option) => void;
  onExpand?: (expanded: boolean) => void;
  selectedOption?: Option | null;
  defaultOpen?: boolean;
  open?: boolean;
  disabled?: boolean;
  tooltip?: string;
};

const GridOptionsList: FC<GridOptionsListProps> = ({
  title,
  options,
  onClick = () => {},
  selectedOption,
  defaultOpen = true,
  open: openFromProps,
  onExpand = () => {},
  disabled,
  tooltip,
}) => {
  const [openFromState, setOpen] = useState(defaultOpen);
  const open = openFromProps != null ? openFromProps : openFromState;

  return (
    <Paper variant="outlined">
      <Tooltip title={tooltip || ""}>
        <span>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding={1}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              if (!disabled) {
                onExpand(!open);
                setOpen(!open);
              }
            }}
          >
            <Typography variant="subtitle2">{title}</Typography>
            {open ? (
              <IconButton size="small" disabled={disabled}>
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton size="small" disabled={disabled}>
                <ExpandMore />
              </IconButton>
            )}
          </Box>
        </span>
      </Tooltip>
      <Collapse in={open}>
        <List component="nav">
          {options ? (
            options.length > 0 &&
            options.map((option) =>
              option.node ? (
                option.node
              ) : (
                <ListItemButton
                  dense
                  key={option.title}
                  onClick={() => onClick(option)}
                  selected={option.title === selectedOption?.title}
                  {...option.listItemProps}
                >
                  {option.startIcon && (
                    <Box marginRight={1.5} display="flex">
                      {option.startIcon}
                    </Box>
                  )}
                  <ListItemText
                    primary={option.title}
                    primaryTypographyProps={{
                      noWrap: true,
                    }}
                  />
                  {option.endIcon && (
                    <Box display="flex" alignItems="center">
                      {option.endIcon}
                    </Box>
                  )}
                </ListItemButton>
              )
            )
          ) : (
            <List component="nav">
              {[...Array(2).keys()].map((k) => (
                <ListItem key={k} dense>
                  <Skeleton variant="rectangular" width="100%" />
                </ListItem>
              ))}
            </List>
          )}
        </List>
      </Collapse>
    </Paper>
  );
};
export default GridOptionsList;
