import { useQuery, UseQueryOptions } from "react-query";
import { CircuitBoard } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

export async function getBoards(params?: object): Promise<CircuitBoard[]> {
  return await apiRequest<CircuitBoard[]>("circuitboards", "GET", params);
}

export default function useBoards(
  params?: object,
  options?: UseQueryOptions<CircuitBoard[], APIError>
) {
  return useQuery<CircuitBoard[], APIError>(
    ["useBoards", params],
    () => getBoards(params),
    { ...options, staleTime: Infinity }
  );
}
