import { useQuery, UseQueryOptions } from "react-query";
import apiRequest, { APIError } from "../apiRequest";

async function getWarehouseKey(): Promise<Record<string, string>> {
  return await apiRequest<Record<string, string>>("v1/warehouse/keys", "GET");
}

export default function useWarehouseKey(
  options?: UseQueryOptions<Record<string, string>, APIError>
) {
  return useQuery<Record<string, string>, APIError>(
    "warehouseKey",
    () => getWarehouseKey(),
    options
  );
}
