import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../../../services/apiRequest";
import { GetProductsResponse } from "./types";
import { PerAccountStatus } from "../../../types/ecommerce";

const getShopifyProducts = (
  per_page: number,
  page: number
): Promise<GetProductsResponse> =>
  apiRequest<GetProductsResponse>(
    "v1/onboarding/ecommerce/shopify/products",
    "GET",
    {
      per_page,
      page,
    }
  );

const getShopifyProductsStatus = (): Promise<PerAccountStatus[]> =>
  apiRequest<PerAccountStatus[]>(
    "v1/onboarding/ecommerce/shopify/status",
    "GET"
  );

export const useShopifyProducts = (props: {
  per_page: number;
  page: number;
  options?: UseQueryOptions<GetProductsResponse, APIError>;
}): UseQueryResult<GetProductsResponse, APIError> =>
  useQuery<GetProductsResponse, APIError>(
    ["ShopifyProducts", props.per_page || 50, props.page || 0],
    () => getShopifyProducts(props.per_page || 50, props.page || 0),
    props.options
  );

export const useShopifyProductsStatus = (props?: {
  options?: UseQueryOptions<PerAccountStatus[], APIError>;
}): UseQueryResult<PerAccountStatus[], APIError> =>
  useQuery<PerAccountStatus[], APIError>(
    ["ShopifyProductsStatus"],
    () => getShopifyProductsStatus(),
    props?.options
  );
