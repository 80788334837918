import { ReactNode } from "react";
import { FronteggProvider } from "@frontegg/react";
import { ContextOptions } from "@frontegg/rest-api";

/**
 * use this object to config Frontegg global context object
 */
const contextOptions: ContextOptions = {
  baseUrl: "/api",
  requestCredentials: "include",
};

/**
 *  Wrap you entire application with this HOC.
 *  NOTE: Make sure to remove any BrowserRouter in your application
 *  if you use ```withRouter``` option
 */
export const withFronteggHOC = (AppComponent: any) =>
  function (props: any) {
    return (
      <WithFronteggProvider>
        <AppComponent {...props} />
      </WithFronteggProvider>
    );
  };
export const WithFronteggProvider = ({ children }: { children: ReactNode }) => (
  <FronteggProvider
    backgroundImage="https://app.noogata.com/logo-black.svg"
    headerImage="https://app.noogata.com/logo-black.svg"
    contextOptions={contextOptions}
    authOptions={{ keepSessionAlive: true }}
  >
    {children}
  </FronteggProvider>
);
