import { FC } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import classnames from "classnames";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const BackButton: FC<{
  onBack?: () => void;
  classes?: Record<"container" | "icon", string>;
  label?: string;
  className?: string;
}> = ({ onBack, classes, className, label }) => (
  <Box
    onClick={onBack}
    className={classnames(classes?.container, className)}
    display="flex"
    alignItems="center"
    sx={{ cursor: onBack ? "pointer" : "inherit" }}
  >
    <IconButton onClick={onBack} size="small">
      <ChevronLeftIcon sx={{ color: "common.black" }} />
    </IconButton>
    <Box marginRight={1.2} />
    <Typography variant="body2">{label}</Typography>
  </Box>
);
