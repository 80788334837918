import {useAuthUserOrNull} from "@frontegg/react";
import {Box, Container, Typography} from "@mui/material";

import {useUserTenant} from "../../hooks";
import HistoryTable from "./HistoryTable";
import LoadableTypograpy from "../../components/LoadableTypography";
import {FC} from "react"

export const History: FC = () => {
    const user = useAuthUserOrNull();
    const tenant = useUserTenant(user || undefined);
    const isLoading = !user || !tenant;
    return (
        <Box flex={1} mt={4} mb={4}>
            <Container
                maxWidth="lg"
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box display="flex" mb={4}>
                    <Typography variant="h5" mr={1}>
                        Configuration history for workspace
                    </Typography>
          <LoadableTypograpy
            color="primary"
            variant="h5"
            fontWeight="bold"
            sx={{ minWidth: 130 }}
            isLoading={!tenant?.name}
          >
            {tenant?.name}
          </LoadableTypograpy>
        </Box>
        <HistoryTable tenantId={tenant?.tenantId} isLoading={isLoading} />
      </Container>
    </Box>
  );
};

export default History;
