import { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "./Icon";
import theme from "../themes/light.ts";

export type SelectDropDownItem = {
  value: any;
  text: string;
  disabled?: boolean;
};

const SelectDropDown = ({
  items,
  title,
  value,
  onChange,
  tooltipTitle,
}: {
  items?: SelectDropDownItem[];
  title?: string;
  value: string;
  onChange: Dispatch<SetStateAction<any>>;
  tooltipTitle?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FormControl
      variant="standard"
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "16px",
        "& .MuiInputBase-formControl": {
          "&:focus": {
            backgroundColor: "transparent !important",
          },
        },
        "& .MuiInput-input": {
          "&:focus": {
            backgroundColor: "transparent !important",
          },
        },
      }}
    >
      <Tooltip
        title={tooltipTitle ?? ""}
        arrow
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              display: isOpen ? "none" : "block",
            },
          },
        }}
      >
        <Select
          size="small"
          onOpen={() => {
            setIsOpen((prev) => !prev);
          }}
          onClose={() => {
            setIsOpen((prev) => !prev);
          }}
          IconComponent={() => (
            <Box
              sx={{
                position: "absolute",
                right: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isOpen ? (
                <Icon src="sales/drop-up.svg" width="16px" height="16px" />
              ) : (
                <Icon src="sales/drop-down.svg" width="16px" height="16px" />
              )}
            </Box>
          )}
          renderValue={() => (
            <Stack
              direction="row"
              gap="4px"
              alignItems="center"
              justifyContent="end"
            >
              <Typography
                variant="body4"
                color={
                  isOpen
                    ? theme.palette.text.link
                    : theme.palette.text.secondary
                }
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                fontWeight={600}
                color={
                  isOpen ? theme.palette.text.link : theme.palette.text.primary
                }
              >
                {items?.filter((item) => item.value === value)[0]?.text ??
                  value}
              </Typography>
            </Stack>
          )}
          sx={{
            // minWidth: 248,
            "& .MuiInputBase-formControl": {
              "&:focus": {
                backgroundColor: "transparent !important",
              },
            },
          }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {title && (
            <ListSubheader sx={{ padding: 0, minWidth: 166 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ padding: "8px 8px 8px 16px" }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  color={theme.palette.secondary.main}
                >
                  {title}
                </Typography>
              </Stack>
            </ListSubheader>
          )}
          {items?.map((itm) => (
            <MenuItem
              key={`item-${itm.value}`}
              dense
              value={itm.value}
              disabled={itm.disabled}
              onClick={() => !itm.disabled && onChange(itm.value)}
            >
              <Typography variant="body3">{itm.text}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export default SelectDropDown;
