import {FC, ReactElement, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {OnboardingStatusEnum} from "../../enums";
import {
  CompletedCardHeader,
  GatheringCardHeader,
  PendingCardHeader,
  TrainingCardHeader,
} from "../MarketplaceV2/EcommerceOnboarding/Components";
import {useOnboardingStatus} from "../../services/ecommerce";
import {useIsTenantNew} from "../../hooks";
import {StyledCard} from "../MarketplaceV2/EcommerceOnboarding/StyledComponents";

const statusMapping: Record<OnboardingStatusEnum, ReactElement> = {
  [OnboardingStatusEnum.PENDING]: <PendingCardHeader/>,
  [OnboardingStatusEnum.GATHERING]: <GatheringCardHeader/>,
  [OnboardingStatusEnum.TRAINING]: <TrainingCardHeader/>,
  [OnboardingStatusEnum.SUCCEEDED]: <CompletedCardHeader/>,
};

const OnboardingBanner: FC = () => {
  const {data} = useOnboardingStatus();
  const {isTenantNew} = useIsTenantNew();
  const history = useHistory();
  useEffect(() => {
    if (isTenantNew) history.push("/marketplace");
  }, [isTenantNew, history]);
  return isTenantNew === false && data && !data.dismissed ? (
    <StyledCard sx={{ m: 8 }}>{statusMapping[data.status]}</StyledCard>
  ) : null;
};

export default OnboardingBanner;
