import { useHistory } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";
import { PerfectContentIcon } from "./PerfectContentList";
import { ApplicationType } from "./types";

export const PerfectContentNavItem: FC<{
  tab: string;
  block_id: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab, block_id }) => {
  // const pageMatch = useRouteMatch({ path: ["/insights"] });
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={tab === "perfectContent"}
      icon={<PerfectContentIcon />}

      onClick={() => {
        history.push(
          ["", ApplicationType.PERFECT_CONTENT, block_id].join("/")
        );
        setTab("perfectContent");
      }}
      label="Perfect Content"
    />
  );
};

