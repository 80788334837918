import {
  Collapse,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useAppContext } from "../../../ApplicationContext";
import { NavbarModes } from "../../../enums";
import { FC, ReactNode } from "react";
import theme from "../../../themes/light";

export const LeftNavBarItem: FC<
  ListItemButtonProps & {
    expandable?: boolean;
    open?: boolean;
    label: string;
    icon: ReactNode;
    selected: boolean;
  }
> = ({ open, expandable, label, icon, selected, ...props }) => {
  const { navbarMode } = useAppContext();
  const isOpen = navbarMode === NavbarModes.EXPENDED;

  return (
    <ListItemButton {...props}
      sx={{
        maxHeight: "32px",
        background: selected ? theme.palette.primaryPartial[600] : "",
        ":hover": {
          background: selected ? theme.palette.primaryPartial[600] : ""
        },
        ":focus": {
          background: selected ? theme.palette.primaryPartial[600] : ""
        },
        padding: "8px",
        gap:1
  
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: "24px",
          alignItems: "center",
        }}
      >
        {icon}
      </ListItemIcon>
      <Collapse in={isOpen}>
        <ListItemText
          primary={
            <Typography
              width="130px"
              fontWeight={400}
              fontSize="14px"
              noWrap
              title={label}
              color={`${theme.palette.backgrounds.white} !important`}
            >
              {label}
            </Typography>
          }
        />
      </Collapse>
      {/* {expandable && <CaretComp />} */}
    </ListItemButton>
  );
};
