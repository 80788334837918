import { useHistory } from "react-router-dom";
import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import theme from "../../themes/light.ts";
import { NavbarModes } from "../../enums.ts";
import { useAppContext } from "../../ApplicationContext.tsx";
import { SPACES_ROUTE } from "../Applications/Router.tsx";
import ScoreBar from "./ScoreBar.tsx";
import KeywordsList from "./KeywordsList.tsx";
import {
  PerfectContentProviderV2,
  usePerfectContentV2,
} from "./PerfectContnetProviderV2.tsx";
import ContentArea from "./ContentArea/index.tsx";

const PerfectContentV2 = () => {
  const history = useHistory();

  const { navbarMode } = useAppContext();
  const { isLoading, spaceId } = usePerfectContentV2();

  return (
    <Stack height={"100vh"}>
      <Stack px={4} py={2} bgcolor={"white"} height="108px">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column">
            <Box>
              <Button
                id="composition-button"
                aria-haspopup="true"
                onClick={() =>
                  history.push(`${SPACES_ROUTE}/${spaceId}/perfect-content`)
                }
                sx={{
                  display: "flex",
                  gap: "8px",
                  minHeight: "28px",
                  fontSize: "14px",
                  color: "#2C224C",
                }}
              >
                <ArrowBackIcon
                  sx={{
                    color: theme.palette.grey[300],
                  }}
                />
                <Typography
                  variant="body4"
                  sx={{
                    fontSize: "14px",
                    color: theme.palette.grey[400],
                  }}
                >
                  Go back
                </Typography>
              </Button>
            </Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              color={theme.palette.secondary.main}
              fontSize={20}
            >
              Perfect Content
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack height="100%" overflow="auto">
        <Stack
          height="100%"
          width="100%"
          sx={{
            flexGrow: 1,
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginRight: `calc(40% + 6rem - ${
              navbarMode === NavbarModes.COLLAPSED ? "66px" : "0px"
            })`,
            position: "relative",
          }}
        >
          <Box
            sx={{
              flex: 1,
              background: theme.palette.grey[50],
              overflow: "auto",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {isLoading && <LinearProgress />}
            <Box sx={{ padding: "32px" }}>
              <Stack
                sx={{
                  padding: "24px",
                  display: "flex",
                  gap: "0.7rem",
                  alignItems: "center",
                  margin: "1 rem",
                  borderRadius: "12px",
                  background: "white",
                }}
              >
                <Stack direction="row" width="100%">
                  <ScoreBar />
                </Stack>
                <Stack
                  direction="row"
                  width="100%"
                  gap="20px"
                  alignItems="stretch"
                  height="100%"
                >
                  <Box flex={3}>
                    <ContentArea />
                  </Box>
                  <Box flex={2}>
                    <KeywordsList />
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default () => (
  <PerfectContentProviderV2>
    <PerfectContentV2 />
  </PerfectContentProviderV2>
);
