import { TenantLogResponse } from "../../types/tenants.ts";
import apiRequest, { APIError } from "../../services/apiRequest.ts";
import { useQuery } from "react-query";
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import theme from "../../themes/light.ts";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState } from "react";
import { colDefs } from "./enum.tsx";
import { agGridBoxStyle } from "../SpacesV2/PerfectContent/enums.tsx";
import CustomPagination from "../SpacesV2/Pagination.tsx";

const Assets = [
  "none",
  "digital_shelf",
  "product_enrichment",
  "sales_estimation",
  "keywords",
  "sales_assistant",
  "view_creator",
  "impressions_etl",
  "impressions_mysql",
  "competitors",
  "spaces",
  "dashboards_deployment",
];

async function getTenantLogs({
  asset,
  severity,
  user_email,
  offset,
}: {
  asset?: string;
  severity?: number;
  user_email?: string;
  offset?: number;
}): Promise<TenantLogResponse> {
  return await apiRequest<TenantLogResponse>(`v1/monitors-logs`, "GET", {
    asset_filter: asset,
    severity_filter: severity,
    user_email_filter: user_email,
    offset,
  });
}

function useTenantLogs({
  asset,
  severity,
  user_email,
  offset,
}: {
  asset?: string;
  severity?: number;
  user_email?: string;
  offset?: number;
}) {
  return useQuery<TenantLogResponse, APIError>(
    ["useTenantStatus", asset, severity, user_email, offset],
    () => getTenantLogs({ asset, severity, user_email, offset }),
    {
      refetchInterval: 10000,
    },
  );
}

const Logs = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [assetsFilter, setAssetsFilter] = useState<string | undefined>(
    undefined,
  );
  const [severityFilter, setSeverityFilter] = useState<number | undefined>(
    undefined,
  );
  const gridRef = useRef<AgGridReact>(null);
  const { data, isLoading, isFetching } = useTenantLogs({
    asset: assetsFilter,
    severity: severityFilter,
    offset: (currentPage - 1) * 100,
  });

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  return (
    <>
      {(isLoading || isFetching) && <LinearProgress />}
      <Stack flex={1} pt={2} pb={4} bgcolor={theme.palette.grey[50]}>
        <Container
          maxWidth="xl"
          sx={{
            flex: 1,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            mb: -10,
          }}
        >
          <Box className={"ag-theme-quartz"} sx={agGridBoxStyle}>
            <Stack
              direction="row"
              mb={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" flex={2}>
                Logs
              </Typography>
              <Stack direction="row" flex={2} gap={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Assets filter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={assetsFilter}
                    label="Assets filter"
                    onChange={(e) => setAssetsFilter(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  >
                    <MenuItem key="clear" value={undefined}>
                      Clear selection
                    </MenuItem>
                    {Assets.map((asset) => (
                      <MenuItem
                        value={asset}
                        key={asset}
                        style={{ textTransform: "capitalize" }}
                      >
                        {asset.replaceAll("_", " ")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Severity filter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={severityFilter}
                    label="Severity filter"
                    onChange={(e) =>
                      setSeverityFilter(e.target.value as number | undefined)
                    }
                  >
                    <MenuItem key="clear" value={undefined}>
                      Clear selection
                    </MenuItem>
                    <MenuItem value={0}>⚪ None</MenuItem>
                    <MenuItem value={1}>🔵 Info</MenuItem>
                    <MenuItem value={2}>🟢 Success</MenuItem>
                    <MenuItem value={3}>🟡 Running</MenuItem>
                    <MenuItem value={4}>🟠 Failed</MenuItem>
                    <MenuItem value={5}>🔴 Error</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <AgGridReact
              rowData={data?.data ?? []}
              ref={gridRef}
              columnDefs={colDefs}
            />
            <CustomPagination
              totalRows={data?.total ?? 0}
              currentPage={currentPage}
              handleChange={handlePageChange}
              type="ProductList"
            />
          </Box>
        </Container>
      </Stack>
    </>
  );
};

export default Logs;
