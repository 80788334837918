import List from "@mui/material/List";
import { Box, LinearProgress, Stack } from "@mui/material";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { SalesQueryParams } from "../constants";
import { useSalesAssistantStyles } from "../useSalesAssistantStyles";
import { useDisplayList, useSalesContext } from "../hooks/selectors";
import { TransformedInsight } from "../types";
import ListHead from "./ListHead";
import VirtualList from "./VirtualList";

export const onClickRow = (
  isDrawerEnabled: boolean,
  queryParams: URLSearchParams,
  history: ReturnType<typeof useHistory>,
  insight: TransformedInsight
) => {
  if (!isDrawerEnabled) return;
  queryParams.append(SalesQueryParams.InsightId, insight.id);
  history.replace({ search: queryParams.toString() });
};

export const onClickTaskRow = (
  isDrawerEnabled: boolean,
  queryParams: URLSearchParams,
  history: ReturnType<typeof useHistory>,
  id: string
) => {
  if (!isDrawerEnabled) return;
  queryParams.append(SalesQueryParams.InsightId, id);
  history.replace({ search: queryParams.toString() });
};

const listStylesOverrides = {
  "& .MuiListItem-root": {
    width: "100%",
    cursor: "pointer",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
};
const InsightsList = () => {
  const { isFetchingInsights } = useSalesContext();
  const displayList = useDisplayList();
  const { mainList } = useSalesAssistantStyles();
  const theme = useTheme();
  const { hash } = useLocation();

  useEffect(() => {
    if (document.getElementById(`insight-id-${hash.substring(1)}`)) {
      document
        .getElementById(`insight-id-${hash.substring(1)}`)!
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [hash, displayList]);

  // if (isLoadingInsights) {
  //   return <LinearProgress />;
  // }
  // if (!displayList || displayList.length === 0) {
  //   return <EmptyStates />;
  // }
  return (
    <>
      {isFetchingInsights && <LinearProgress />}
      <Box
        sx={{
          overflow: "hidden",
          background: theme.palette.backgrounds.secondary,
          flex: 1,
          paddingTop: "16px",
        }}
      >
        <List
          className={mainList}
          data-testid="insights-list"
          key="boo"
          sx={{
            minHeight: "100%",
            paddingTop: 0,
            ...listStylesOverrides,
            background: theme.palette.backgrounds.secondary,
          }}
        >
          <Stack
            sx={{
              maxWidth: theme.spacing(1300 / 8),
              marginLeft: "auto",
              marginRight: "auto",
              height: "inherit",
            }}
          >
            <ListHead />
            <VirtualList />
          </Stack>
        </List>
      </Box>
    </>
  );
};

export { InsightsList };
