import {
  createOauthIntegrationFromAuthCode,
  getOauthIntegration,
} from "../services/oauthIntegrations";
import { GoogleAuthorizeResponse } from "../types";
import {
  GOOGLE_SHEETS_READ_SCOPES,
  READ_GOOGLE_AUTH_CONFIG,
} from "../constants";
import { useGoogleApi } from "./index";
import { GoogleApiStatus } from "../enums";

declare const gapi: any;
declare const google: any;

const DEVELOPER_KEY = import.meta.env.VITE_GOOGLE_OAUTH_DEVELOPER_KEY!;

const useGoogleSheetsConnector = (
  onChange: (data: any) => void,
  onError: (errorMessage: string) => void
): {
  openSheetPicker: () => void;
  status: GoogleApiStatus;
} => {
  const gapiStatus = useGoogleApi();

  function loadPicker() {
    gapi.load("auth2");
    gapi.load("picker");
  }

  const createPicker = (oauthToken: string) => {
    const view = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS);
    view.setMode(google.picker.DocsViewMode.LIST);
    const picker = new google.picker.PickerBuilder()
      .setOAuthToken(oauthToken)
      .addView(view)
      .setDeveloperKey(DEVELOPER_KEY)
      .setCallback(onChange)
      .build();
    picker.setVisible(true);
  };

  const doAuth = async (
    callback: (authResult: GoogleAuthorizeResponse) => void
  ) => {
    try {
      const { accessToken } = await getOauthIntegration("google");
      createPicker(accessToken);
    } catch (err) {
      // create a new authorization code
      // only if we did not already have a refresh token for this user in DB
      gapi.auth2.authorize(READ_GOOGLE_AUTH_CONFIG, callback);
    }
  };

  const openSheetPicker = () => {
    let accessToken;
    loadPicker();

    if (gapiStatus !== "ready") {
      return null;
    }
    setTimeout(
      () =>
        doAuth(async (authResult: GoogleAuthorizeResponse) => {
          try {
            if (authResult.code) {
              const authorizationCode = authResult.code;
              const token = await createOauthIntegrationFromAuthCode(
                "google",
                authorizationCode,
                GOOGLE_SHEETS_READ_SCOPES
              );
              accessToken = token.accessToken;
              createPicker(accessToken);
            } else {
              onError("Unable to generate authentication code");
            }
          } catch (error: any) {
            onError(error.message);
          }
        }),
      0
    );
  };
  return { openSheetPicker, status: gapiStatus };
};

export default useGoogleSheetsConnector;
