import { FC, useEffect, useMemo, useState } from "react";
import { useAuthUser } from "@frontegg/react-hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { PlatformRole } from "@noogata/types";
import { useSnackbar } from "notistack";

import { useMutation, useQueryClient } from "react-query";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import {
  IllustrationPlaceholder,
  UncontrolledTable,
} from "../../../components";
import { useConfirmation } from "../../../components/ConfirmationDialog";
import { Split } from "../../../FeatureFlags/enums";
import { useSplitEnabled } from "../../../hooks";
import { useBoards } from "../../../services/boards";
import { deleteTable } from "../../../services/warehouse/index";
import { DataWarehouseTableData } from "../../../types/warehouse";
import { formatDateString } from "../../../utils";
import useDataWarehouseTables from "../useDataWarehouseTables";
import SearchBar from "../../../components/SearchInput/SearchBar";
import { TableHeader } from "../../../components/TableHeader";

const PAGE_SIZE = 10;

const ViewSection: FC = () => {
  const { data: boards = [] } = useBoards();
  const [{ limit, searchText }, setFilters] = useState<{
    limit: number;
    searchText: string;
  }>({ limit: PAGE_SIZE, searchText: "" });
  const { data: getDWDataResponse } = useDataWarehouseTables({
    limit,
    searchText,
  });
  const { items: data = [], total = 0 } = getDWDataResponse || {};
  const user = useAuthUser();
  const { roles } = user;
  const hasNoogataAdmin = roles.find(
    (role: any) => role.key === PlatformRole.NoogataAdministrator
  );
  const isAdmin = hasNoogataAdmin && hasNoogataAdmin.toString().length > 0;
  const sematicCoulmnEnabled = useSplitEnabled(Split.SEMANTIC_COLUMNS);

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const confirm = useConfirmation();

  const deleteMutation = useMutation(
    ({ tableName }: { tableName: string }) => deleteTable(tableName),
    {
      onSuccess: () => {
        enqueueSnackbar("Table was deleted successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries(["dataWarehouseTables"]);
      },

      onError: (error) => {
        enqueueSnackbar(`Failed to delete table: ${error}`, {
          variant: "error",
        });
      },
    }
  );

  const handleRemoveConfirm = (tableName: string) => {
    confirm({
      title: `You are about to delete ${tableName} permanently`,
      description: "Are you sure you want to proceed?",
    }).then(() => {
      deleteMutation.mutate({
        tableName,
      });
    });
  };

  // @ts-ignore
  const columns: Column<DataWarehouseTableData>[] = useMemo(
    () =>
      [
        {
          Header: (
            <Typography sx={{ fontWeight: "500" }}>Block Name</Typography>
          ),
          id: "block_name",
          accessor: "block_id",
          disableSortBy: true,
          Cell: ({ value = "" }: { value: string }) => (
            <Tooltip title={value} placement="top-start">
              <Typography
                noWrap
                variant="body2"
                sx={{ width: (t) => t.spacing(20) }}
              >
                <a href={`/boards/${value}`} target="_blank" rel="noreferrer">
                  {boards.find((board) => board.identifier === value)
                    ?.displayName || value}
                </a>
              </Typography>
            </Tooltip>
          ),
        },
        {
          Header: (
            <Tooltip
              title="Table name for this output in Noogata’s data warehouse."
              placement="top-start"
            >
              <Typography sx={{ fontWeight: "500" }}>Table name</Typography>
            </Tooltip>
          ),
          id: "table_name",
          accessor: "table_name",
          disableSortBy: true,
          Cell: ({ value }: { value: string }) => (
            <Typography noWrap variant="body2" title={value}>
              {value}
            </Typography>
          ),
        },
        {
          Header: (
            <Typography sx={{ fontWeight: "500" }}>Block Type</Typography>
          ),
          id: "block_type",
          accessor: "block_type",
          disableSortBy: true,
          Cell: ({ value = "" }: { value: string }) => (
            <Tooltip title={value} placement="top-start">
              <Typography
                noWrap
                variant="body2"
                sx={{ width: (t) => t.spacing(20) }}
              >
                {value}
              </Typography>
            </Tooltip>
          ),
        },
        {
          Header: (
            <Tooltip
              title="Number of weeks with data in the data warehouse."
              placement="top-start"
            >
              <Typography sx={{ fontWeight: "500" }}>
                Date range (in weeks)
              </Typography>
            </Tooltip>
          ),
          id: "date_range",
          accessor: "date_range",
          disableSortBy: true,
          enabled: sematicCoulmnEnabled,
          Cell: ({ value }: { value: number }) => (
            <Tooltip
              title={
                value === 0
                  ? "Date range only applies to time series outputs"
                  : ""
              }
              placement="top-start"
            >
              <Typography
                noWrap
                variant="body2"
                sx={{ width: (t) => t.spacing(20) }}
              >
                {value !== 0 ? value : "NA"}
              </Typography>
            </Tooltip>
          ),
        },
        {
          Header: (
            <Tooltip
              title="Number of unique entities (such as products or search terms) for this output."
              placement="top-start"
            >
              <Typography sx={{ fontWeight: "500" }}>
                Number of unique entities
              </Typography>
            </Tooltip>
          ),
          id: "unique_entities",
          accessor: "unique_entities",
          disableSortBy: true,
          enabled: sematicCoulmnEnabled,
          Cell: ({ value }: { value: number }) => (
            <Tooltip
              title={
                value === 0
                  ? "This block currently does not support entity metric"
                  : ""
              }
              placement="top-start"
            >
              <Typography
                noWrap
                variant="body2"
                sx={{ width: (t) => t.spacing(20) }}
              >
                {value !== 0 ? value : "NA"}
              </Typography>
            </Tooltip>
          ),
        },
        {
          Header: (
            <Tooltip
              title="First sync to data warehouse."
              placement="top-start"
            >
              <Typography sx={{ fontWeight: "500" }}>Created at</Typography>
            </Tooltip>
          ),
          id: "created_at",
          accessor: "created_at",
          disableSortBy: true,
          Cell: ({ value = "" }: { value: string }) => (
            <Tooltip
              title={formatDateString(value) || ""}
              placement="top-start"
            >
              <Typography
                variant="body2"
                noWrap
                sx={{ maxWidth: theme.spacing(7) }}
              >
                {formatDateString(value).split(",")[0]}
              </Typography>
            </Tooltip>
          ),
        },
        {
          Header: (
            <Tooltip
              title="Latest sync to data warehouse."
              placement="top-start"
            >
              <Typography sx={{ fontWeight: "500" }}>Updated at</Typography>
            </Tooltip>
          ),
          id: "latest_update",
          accessor: "updated_at",
          disableSortBy: true,
          Cell: ({ value = "" }: { value: string }) => (
            <Tooltip
              title={formatDateString(value) || ""}
              placement="top-start"
            >
              <Typography
                variant="body2"
                noWrap
                sx={{ maxWidth: theme.spacing(7) }}
              >
                {formatDateString(value).split(",")[0]}
              </Typography>
            </Tooltip>
          ),
        },
        {
          Header: <Typography sx={{ fontWeight: "500" }}>Actions</Typography>,
          id: "actions",
          accessor: "table_name",
          disableSortBy: true,
          Cell: ({ value }: { value: string }) => (
            <Tooltip
              title={
                isAdmin
                  ? "Delete table"
                  : "You are not authorized to delete data from warehouse"
              }
              placement="top-start"
            >
              <div>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveConfirm(value)}
                  size="large"
                  disabled={!isAdmin}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          ),
        },
      ].filter((c) => (c.enabled != null ? c.enabled : true)),
    [getDWDataResponse, sematicCoulmnEnabled]
  );

  const instance = useTable(
    {
      columns,
      data: data || [],
      autoResetSortBy: false,
      autoResetPage: false,
      initialState: { pageSize: data.length || 1 },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    instance.setPageSize(data.length || 1);
  }, [data]);
  return (
    <Stack
      sx={{
        mt: 4,
        mx: 4,
      }}
      spacing={2}
    >
      <SearchBar
        debounceTimeout={300}
        options={[]}
        onInputChange={(_: any, searchText: any) =>
          setFilters((p) => ({ ...p, searchText }))
        }
      />
      <TableHeader title="Tables" subTitle={total} />
      {data.length === 0 ? (
        <IllustrationPlaceholder
          imageHeight={300}
          imageWidth={300}
          text="No data to display."
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <UncontrolledTable
            instance={instance}
            data={data}
            isLoading={false}
            columns={columns}
            error={null}
            tableProps={{
              stickyHeader: true,
            }}
            disablePagination
          />
          {total - data.length ? (
            <Typography
              sx={{
                transition: "background 0.2s ease",
                "&:hover": { background: "grey.100" },
                padding: 2,
                cursor: "pointer",
                borderBottomWidth: "1px",
                borderBottomColor: "text.secondary",
                color: "text.primary",
              }}
              onClick={() => {
                setFilters((prev) => ({
                  ...prev,
                  limit: prev.limit + PAGE_SIZE,
                }));
              }}
            >
              + Load {Math.min(total - data.length, PAGE_SIZE)} more tables (of{" "}
              {total - data.length})
            </Typography>
          ) : null}
        </Box>
      )}
    </Stack>
  );
};

export default ViewSection;
