import { FC } from "react";
import { Stack } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LoadableText } from "./LoadableText";

export const CreatedTime: FC<{ createdTime?: string }> = ({ createdTime }) => (
  <Stack alignItems="center" spacing={1} direction="row">
    <AccessTimeIcon fontSize="small" />
    <LoadableText noWrap maxWidth={150} minWidth={50}>
      {createdTime}
    </LoadableText>
  </Stack>
);
