import {CardHeader, Skeleton} from "@mui/material";
import {SxProps} from "@mui/system";
import {ConnectorType} from "../../../enums";
import {ConnectorLogoMapping} from "../../../mappings";
import {useConnectorOptions} from "../../../services/connectors";

const ConnectorOptionHeader = ({connectorType, text, sx}: {
    connectorType: ConnectorType,
    text: string,
    sx?: SxProps
}) => {
    const {data} = useConnectorOptions();
    const option =
        data &&
        data.find((connectorOption) => connectorOption.type === connectorType);
    return (
        <CardHeader
            title={option ? option.title : <Skeleton width="100px" variant="text"/>}
            subheader={text}
            avatar={
                <img
                    src={`/${ConnectorLogoMapping[connectorType]}`}
                    alt={connectorType}
                    height="40px"
                    width="40px"
                />
            }
            sx={{paddingLeft: 0, ...sx}}
        />
    );
};
export default ConnectorOptionHeader;
