import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  CardContent,
  CircularProgress,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";

import { Status } from "../../../../enums";
import { useRun } from "../../../../services/runs";
import theme from "../../../../themev2";
import { BoardAndRunIds } from "../../../../types";
import { FC } from "react";

const Spacer: FC = () => (
  <>
    <Typography variant="subtitle1" sx={{ ml: 1.5 }}>
      •
    </Typography>
    <Typography variant="subtitle1" sx={{ ml: 1.5 }}>
      •
    </Typography>
  </>
);

const Done: FC<{ label: string }> = ({ label }) => (
  <Stack direction="row">
    <CheckCircleIcon fontSize="large" color="primary" />
    <Typography variant="h6" color="primary" sx={{ ml: 2 }}>
      {label}
    </Typography>
  </Stack>
);

const Running: FC<{ label: string }> = ({ label }) => (
  <Stack direction="row">
    <CircularProgress size="30px" color="primary" />
    <Typography variant="h6" color="primary" sx={{ ml: 2 }}>
      {label}
    </Typography>
  </Stack>
);

const FutureStep: FC<{ label: string }> = ({ label }) => (
  <Stack direction="row">
    <CircleOutlinedIcon fontSize="large" color="disabled" />
    <Typography variant="h6" color="primary" sx={{ ml: 2 }}>
      {label}
    </Typography>
  </Stack>
);

const Pending: FC = () => (
  <>
    <FutureStep label="Gathering Data" />
    <Spacer />
    <FutureStep label="Modeling Pipeline" />
    <Spacer />
    <FutureStep label="Exporting Data" />
  </>
);

const Gathering: FC = () => (
  <>
    <Running label="Gathering Data" />
    <Spacer />
    <FutureStep label="Modeling Pipeline" />
    <Spacer />
    <FutureStep label="Exporting Data" />
  </>
);

const Modeling: FC = () => (
  <>
    <Done label="Gathering Data" />
    <Spacer />
    <Running label="Modeling Pipeline" />
    <Spacer />
    <FutureStep label="Exporting Data" />
  </>
);

const Exporting: FC = () => (
  <>
    <Done label="Gathering Data" />
    <Spacer />
    <Done label="Modeling Pipeline" />
    <Spacer />
    <Running label="Exporting Data" />
  </>
);

const ReadyToExport: FC = () => (
  <>
    <Done label="Gathering Data" />
    <Spacer />
    <Done label="Modeling Pipeline" />
    <Spacer />
    <FutureStep label="Exporting Data" />
  </>
);

const Succeeded: FC = () => (
  <>
    <Done label="Gathering Data" />
    <Spacer />
    <Done label="Modeling Pipeline" />
    <Spacer />
    <Done label="Exporting Data" />
  </>
);

export const PendingContent: FC<{ status: Status }> = ({ status }) => {
  switch (status) {
    case Status.PENDING:
      return (
        <CardContent>
          <Pending />
        </CardContent>
      );
    case Status.GATHERING:
      return (
        <CardContent>
          <Gathering />
        </CardContent>
      );
    case Status.RUNNING:
      return (
        <CardContent>
          <Modeling />
        </CardContent>
      );
    case Status.EXPORTING:
      return (
        <CardContent>
          <Exporting />
        </CardContent>
      );
    case Status.SUCCEEDED:
      return (
        <CardContent>
          <Succeeded />
        </CardContent>
      );
    case Status.READY_TO_EXPORT:
      return (
        <CardContent>
          <ReadyToExport />
        </CardContent>
      );
    default:
      return (
        <CardContent>
          <Pending />
        </CardContent>
      );
  }
};

const ProgressionBox: FC<BoardAndRunIds> = ({ boardId, runId }) => {
  const run = useRun(boardId, runId);

  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          height: 350,
          backgroundColor: "white",
          margin: 3,
          p: 2,
          boxShadow: 1,
        }}
      >
        <Typography variant="h6">Running</Typography>
        <Paper
          elevation={0}
          sx={{
            maxHeight: (t) => t.spacing(300 / 8),
            p: 2,
            overflowY: "hidden",
          }}
        >
          <PendingContent status={run?.data?.status || Status.PENDING} />
        </Paper>
      </Paper>
    </ThemeProvider>
  );
};
export default ProgressionBox;
