import { Box, Skeleton, useTheme } from "@mui/material";

const TextBlockSkeletonLoader = ({ height }: { height?: number }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        boxShadow:
          "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
        background: theme.palette.primary.contrastText,
        borderRadius: "12px",
        width: "100%",
        height: `${height}px` || "90px",
        margin: "2rem 0",
        padding: "24px 40px",
        display: "flex",
        flexDirection: "column",

      }}
    >
        {[...Array(3)].map((_, index) => (
          <Skeleton
            key={index}
            animation="wave"
            variant="text"
            sx={{
              width: "90%",
              height: "14px",
              borderRadius: "16px",
            }}
          />
        ))}
    </Box>
  );
};

export default TextBlockSkeletonLoader;
