import apiRequest from "../../services/apiRequest";

export function startEnrichmentFlowApi(inputDataPath: string, cadFilePath: string): Promise<any> {
  return apiRequest<any>("v1/catalog-enrichment/start", "POST", undefined, {
    raw_data_path: inputDataPath,
    cad_file_path: cadFilePath,
  });
}

export function getEnrichmentFlowStateApi(flowRunId: string): Promise<any> {
  return apiRequest<any>(`v1/catalog-enrichment/${flowRunId}/state`, "GET");
}

export function getEnrichmentFlowLogsApi(flowRunId: string): Promise<any> {
  return apiRequest<any>(`v1/catalog-enrichment/${flowRunId}/logs`, "GET");
}

export function getEnrichmentFlowGraphApi(flowRunId: string): Promise<any> {
  return apiRequest<any>(`v1/catalog-enrichment/${flowRunId}/graph`, "GET");
}