import { Meta } from "@cubejs-client/core";
import { useQuery, UseQueryOptions } from "react-query";
import { generateApiClient } from "./api";
import { APIError } from "../apiRequest";

export async function getMeta(boardId: string, runId: string): Promise<Meta> {
  return await generateApiClient(boardId, runId).meta();
}

export default function useMeta(
  boardId: string,
  runId: string,
  options?: UseQueryOptions<Meta, APIError>
) {
  return useQuery<Meta, APIError>(
    ["outputMeta", boardId, runId],
    () => getMeta(boardId, runId),
    { ...options }
  );
}
