import {Box, Tooltip, Typography} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {JSONSchema4} from "json-schema";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SchemaTypeIcon from "../../../../components/SchemaTypeIcon";
import {prettifyString} from "../../../../utils";
import MappingSelect from "./MappingSelect";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
  createStyles({
    mappingIcon: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
    },
    mappingField: {
      minWidth: 150,
    },
  })
);

export type Property = [string, JSONSchema4];
export type MappingBoxProps = {
    property: Property;
    fromProperties: Property[];
    control: any;
};

const MappingBox: FC<MappingBoxProps> = (props) => {
  const classes = useStyles();
  return (
    <Box display="flex" width="100%" marginBottom={2} alignItems="center">
      <Box marginRight={1}>
        <SchemaTypeIcon
          type={
            Array.isArray(props.property[1].type)
              ? "any"
              : props.property[1].type!
          }
        />
      </Box>
      <Typography variant="h6" noWrap className={classes.mappingField}>
        {prettifyString(props.property[0])}
      </Typography>
      {props.property[1].description && (
        <Tooltip title={props.property[1].description || ""}>
          <InfoIcon className={classes.mappingIcon} />
        </Tooltip>
      )}
      <ArrowForwardIcon className={classes.mappingIcon} color="disabled" />
      <MappingSelect {...props} />
    </Box>
  );
};

export default MappingBox;
