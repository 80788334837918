import {ListItemIcon, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import {AuthorizedContent} from "../../../components/AuthorizedContent";
import {PlatformPermission} from "../../../enums";
import {FC} from "react"

const InspectMenuItem: FC<{
    kfpRunId?: string;
    onClick: () => void;
}> = ({kfpRunId, onClick}) => (
// @ts-ignore
    <AuthorizedContent
        requiredPermissions={[PlatformPermission.KFP_GET]}
        render={(isAuthz) =>
            isAuthz && (
        <MenuItem
          disabled={!isAuthz || !kfpRunId}
          onClick={onClick}
          target="_blank"
          component={Link}
          to={`/pipeline/#/runs/details/${kfpRunId}`}
        >
          <ListItemIcon>
            <DeviceHubIcon />
          </ListItemIcon>
          Inspect
        </MenuItem>
      )
    }
  />
);

export default InspectMenuItem;
