import * as React from "react";
import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
import theme from "../../themes/light";
import {
  ActionIconTask,
  ArrowDown,
  BackIcon,
  // CheckboxArchive,
  DoneIcon,
  InProgressIcon,
  ToDoIcon,
  NoArchiveTaskIcon,
} from "../../assets";
import { updateTask } from "./useTaskApi";
import moment from "moment";
import { handleToast } from "./TaskUtils";
import { useDisplayList, useTasksContext } from "./hooks/selectors";
import { useHistory } from "react-router";
import { useAuthorizedUsers } from "../../hooks/useUsers";

const stackProps = {
  gap: "12px",
  justifyContent: "space-between",
  direction: "column" as const,
  alignItems: "flex-start",
};

export const ArchiveTask: React.FC = () => {
  const { sort, setSort, search } = useTasksContext();
  const history = useHistory();
  const displayList = useDisplayList(search);
  const allMembersList = useAuthorizedUsers();
  const allMembers = allMembersList?.filter(
    (el) => !el.email.includes("@noogata.com")
  );

  const handleSortFilter = (column: string, key: boolean) => {
    const data = {
      column: column,
      ascending: key,
      isDirty: true,
    };
    setSort(data);
  };
  const handleBack = () => {
    history.push("/tasks");
  };

  const getAssignInfo = (email: string) => {
    if (allMembers?.length > 0) {
      const userData = allMembers?.find((el) => el.email === email);
      const { profileImage, name } = userData || {};
      const assignName = name?.substring(0, 10);
      const assignFirstName = name?.substring(0, 1);
      return {
        profileImage,
        name: assignName,
        assignFirstName: assignFirstName,
      };
    }
  };

  return (
    <Stack
      sx={{
        background: theme.palette.grey[50],
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Header handleBack={handleBack} displayList={displayList} />
      {displayList?.length > 0 ? (
        <>
          <Stack
            sx={{
              maxWidth: theme.spacing(1300 / 8),
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
              padding: "16px 32px",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ width: "40%" }}>
                <Typography
                  variant="caption"
                  gap="4px"
                  display="flex"
                  alignItems="center"
                  color={theme.palette.grey[500]}
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    handleSortFilter("description", !sort.ascending)
                  }
                >
                  Task
                  <ArrowDown />
                </Typography>
              </Box>
              <Box sx={{ width: "20%" }}>
                <Typography
                  variant="caption"
                  gap="4px"
                  display="flex"
                  alignItems="center"
                  color={theme.palette.grey[500]}
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    handleSortFilter("related_assets", !sort.ascending)
                  }
                >
                  Product
                  <ArrowDown />
                </Typography>
              </Box>
              <Box sx={{ width: "10%" }}>
                <Typography
                  gap="4px"
                  display="flex"
                  alignItems="center"
                  variant="caption"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    handleSortFilter("created_by", !sort.ascending)
                  }
                >
                  Created <ArrowDown />
                </Typography>
              </Box>
              <Box sx={{ width: "10%" }}>
                <Typography
                  gap="4px"
                  display="flex"
                  alignItems="center"
                  variant="caption"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    handleSortFilter("updated_at", !sort.ascending)
                  }
                >
                  Archived <ArrowDown />
                </Typography>
              </Box>
              <Box sx={{ width: "5%" }}>
                <Typography variant="caption">Assignee </Typography>
              </Box>
              <Box sx={{ width: "5%" }} />
            </Box>
            <Box sx={{ marginTop: "18px" }}>
              {displayList?.length > 0 && (
                <ArchivedListing dataArr={displayList} getAssignInfo={getAssignInfo}  />
              )}
            </Box>
          </Stack>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="calc(100vh - 96px)"
          sx={{ background: theme.palette.grey[50] }}
        >
          <NoArchiveTaskIcon />
        </Box>
      )}
    </Stack>
  );
};

const Header = ({ handleBack, displayList }: any) => {
  return (
    <Box sx={{ background: "#fff", height: "96px" }}>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          padding: "16px 32px",
          margin: "0px 0 0 0",
          gap: "8px",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack {...stackProps} sx={{ paddingBottom: "0px" }}>
          <Typography
            variant="body4"
            display="flex"
            flexDirection="row"
            gap="4px"
            alignItems="center"
            sx={{ color: theme.palette.grey[400], cursor: "pointer" }}
            onClick={() => handleBack()}
          >
            <BackIcon /> Back to board
          </Typography>
          <Typography
            variant="headline3"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            Archived tasks ({displayList?.length})
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const ArchivedListing = ({ dataArr,getAssignInfo }: any) => {
  const { setIsUpdated } = useTasksContext();
  const formatDate = (dateString: string) => {
    const date = moment(dateString);
    return date.format("MMM DD, YYYY");
  };
  const handleBackToBoard = async (Id: string) => {
    setIsUpdated(false);
    const body = {
      status: "to_do",
      archived: false,
    };
    await updateTask(body, Id);
    setIsUpdated(true);
    handleToast("Task was moved back to board", Id, "", true, setIsUpdated);
  };
  return (
    <>
      {dataArr?.map((el: any) => (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.backgrounds.white,
            padding: "8px 0px",
            borderRadius: "16px",
            minHeight: "74px",
            marginBottom: "8px",
          }}
        >
          <Box
            sx={{
              width: "40%",
              paddingLeft: "24px",
              display: "flex",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <Box
              width="32px"
              height="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                background: "#E0F2D2",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              {el?.status === "to_do" ? (
                <ToDoIcon />
              ) : el?.status === "in_progress" ? (
                <InProgressIcon />
              ) : (
                <DoneIcon />
              )}
            </Box>
            <Typography
              variant="body4"
              gap="16px"
              display="flex"
              alignItems="center"
              sx={{ width: "calc(100% - 48px)" }}
            >
              <Tooltip title={el?.description} arrow placement="top">
                <Typography
                  variant="body4"
                  gap="16px"
                  display="flex"
                  alignItems="center"
                  sx={{
                    width: "fit-content",
                    wordBreak: "break-word",
                    whiteSpace: "pre-line",
                  }}
                >
                  {el?.description ? (
                    <>
                      {el?.description?.length > 60 ? (
                        <>{`${el?.description?.substring(0, 60)}...`}</>
                      ) : (
                        <>{el?.description}</>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Tooltip>
            </Typography>
          </Box>
          <Box sx={{ width: "20%", display: "flex", gap: "10px" }}>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                border: " 1px solid",
                borderColor: theme.palette.grey[100],
                padding: "5px",
                borderRadius: "4px",
              }}
            >
              <img
                src={el?.asin_data?.image_url || "archivelist.png"}
                alt="img"
                width="100%"
              />
            </Box>
            <Box
              gap="4px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width={"calc(100% - 50px)"}
            >
              <Typography
                variant="caption"
                display="flex"
                alignItems="center"
                color={theme.palette.grey[500]}
                sx={{ fontWeight: 700 }}
              >
                {el?.asin || ""}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.grey[400],
                  display: "flex",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
              >
                Beauty & Personal Care | ... | Brush Sets
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "10%" }}>
            <Typography
              gap="4px"
              display="flex"
              alignItems="center"
              variant="caption"
            >
              {formatDate(el?.created_at)}
            </Typography>
          </Box>
          <Box sx={{ width: "10%" }}>
            <Typography
              gap="4px"
              display="flex"
              alignItems="center"
              variant="caption"
            >
              {formatDate(el?.updated_at)}
            </Typography>
          </Box>
          <Box sx={{ width: "5%", display: "flex", alignItems: "center" }}>
            {el?.assignee ? (
              <>
                {getAssignInfo(el?.assignee)?.profileImage ? (
                  <Avatar
                    alt="profile"
                    src={getAssignInfo(el?.assignee)?.profileImage}
                    sx={{
                      border: `2px solid ${theme.palette.background.paper}`,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: "28.48px",
                      width: "28.48px",
                      borderRadius: "50%",
                      background: theme.palette.grey[50],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {getAssignInfo(el?.assignee)?.assignFirstName}
                  </Box>
                )}
                
              </>
            ) : (
              <Box
                sx={{
                  height: "28.48px",
                  width: "28.48px",
                  borderRadius: "50%",
                  background: theme.palette.grey[50],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& img": {
                    objectFit: "contain",
                    height: "100%",
                    width: "100%",
                    borderRadius: "50%",
                  },
                }}
              >
                <img src="/tasks/default.png" alt="task" />
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: "5%", paddingRight: "24px" }}
            onClick={() => handleBackToBoard(el?.id)}
          >
            <Tooltip
              title="Move task back to board"
              placement="left"
              arrow
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    padding: "12.4px",
                  },
                },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  background: theme.palette.grey[50],
                  borderRadius: "50%",
                  height: "36px",
                  width: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": {
                    background: theme.palette.primary.light,
                    "& svg": {
                      path: { stroke: theme.palette.primary.dark },
                    },
                  },
                }}
              >
                <ActionIconTask />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      ))}
    </>
  );
};
