import { useHistory, useLocation } from "react-router";
import React from "react";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { TaskIcon } from "../../../assets";

export const Tasks: React.FC = () => {
  // const pageMatch = useRouteMatch({ path: ["/tasks" || "/archieve-task"] });
  const history = useHistory();
  const location = useLocation();
  return (
    <LeftNavBarItem
      selected={
        location.pathname.includes("/tasks") ||
        location.pathname.includes("/archive")
      }
      icon={<TaskIcon />}
      onClick={() => history.push("/tasks")}
      label="Tasks"
    />
  );
};
