import {
  Box,
  Card,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import NumberSection from "../Components/NumberSection.tsx";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { InfoIconV2 } from "../../../assets/index.tsx";
import { renderToString } from "react-dom/server";
import { convertNumberToShortFormat } from "../../ResearchAssistant/utils.ts";
import "./CustomTooltip.css";

const CustomTooltip = ({ series, labels, colors, dataPointIndex }: any) => {
  const getLabel = (value: number, index: number) => {
    const date = dayjs(value / 1000);
    const adjustedYear = date.year() - index;
    return date.year(adjustedYear).format("MMM. D, YYYY");
  };

  return (
    <div className="tooltip-div">
      {series.map((serie: any, index: number) => {
        return (
          <div className="tooltip-line" key={index}>
            {series.length > 1 && (
              <span key={index} className={`tooltip-key`}>
                {getLabel(labels[dataPointIndex], index)}
              </span>
            )}
            <span
              key={index}
              className={`tooltip-value`}
              style={
                series.length > 1
                  ? {
                      backgroundColor: colors[index],
                      padding: "2px 8px",
                      borderRadius: "14px",
                    }
                  : {}
              }
            >
              {serie[dataPointIndex]}
            </span>
          </div>
        );
      })}
      <div className="tooltip-arrow"></div>
    </div>
  );
};

const KPITootipText = {
  Revenue: `Total ordered revenue generated up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  Units: `Total ordered units sold up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  ACOS: `Advertising Cost of Sales (ACOS) as a percentage of total ad spend relative to revenue up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  "Conversion Rate": `Representing the percentage of visitors who made a purchase up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  "Ad Spend": `Total ad spend up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  "Market Share": `The percentage of total sales in the market that your brand accounts for up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  Traffic: `The ratio between impressions in the current year up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )} and the previous year`,
};

const CardSkeleton = () => (
  <Box
    sx={{
      height: "110px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 12px",
      boxShadow:
        "0px 12px 45px rgba(44, 34, 76, 0.05), 0px 8px 20px rgba(44, 34, 76, 0.05)",
    }}
  >
    <Box
      sx={{
        width: "30%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton
          key={`skeleton-card-${index}`}
          variant="text"
          sx={{
            borderRadius: "8px",
            height: "15px",
            width: index === 2 ? "50%" : "75%",
            transform: "unset",
          }}
        />
      ))}
    </Box>
    <Skeleton
      variant="rectangular"
      sx={{
        height: "100%",
        width: "70%",
        borderRadius: "8px",
      }}
    />
  </Box>
);

const CardPlaceholder = () => (
  <Box
    sx={{
      height: "110px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 12px",
      boxShadow:
        "0px 12px 45px rgba(44, 34, 76, 0.05), 0px 8px 20px rgba(44, 34, 76, 0.05)",
    }}
  >
    <Box
      sx={{
        width: "30%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography
        sx={{
          borderRadius: "8px",
          height: "15px",
          width: "100%",
          transform: "unset",
        }}
      >
        No results
      </Typography>
    </Box>
    <Box
      sx={{
        height: "100%",
        width: "70%",
        borderRadius: "8px",
      }}
    />
  </Box>
);
const Index = () => {
  const { salesAssistantHeaderData, salesAssistantHeaderDataLoading } =
    useSalesAssistant();

  const chartColors = ["#6A3FB2", "#0062CC", "#1AD075", "#F89A43"];
  return (
    <Grid container spacing={2} marginBottom="10px">
      {!salesAssistantHeaderDataLoading && salesAssistantHeaderData
        ? salesAssistantHeaderData?.map((card, index) => {
            const formattedValue = (value: number) =>
              (card.type === "currency" ? "$" : "") +
              convertNumberToShortFormat(value) +
              (card.type === "percentage" ? "%" : "");

            return (
              <Grid item xs={3} key={`sales_assistant_head_card-${index}`}>
                <Card
                  sx={{
                    flex: 1,
                    height: "110px",
                    filter:
                      "drop-shadow(0px 12px 45px rgba(44, 34, 76, 0.05)) drop-shadow(0px 8px 20px rgba(44, 34, 76, 0.05))",
                    border: "0 none",
                    borderRadius: "8px",
                    padding: "5px 12px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        width: "fit-content",
                        position: "absolute",
                        zIndex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          textWrap: "nowrap",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Tooltip
                        title={
                          KPITootipText[
                            card?.title as keyof typeof KPITootipText
                          ] || ""
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              zIndex: 2,
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "inline-flex",
                            cursor: "pointer",
                          }}
                        >
                          <InfoIconV2 />
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "35px",
                      }}
                    >
                      <NumberSection
                        number={card.value}
                        change={card.change}
                        percentage={
                          card.type === "percentage" ? card.change : undefined
                        }
                        unit={
                          card.type === "percentage"
                            ? "%"
                            : card.type === "currency"
                              ? "$"
                              : undefined
                        }
                        vertical
                      />
                    </Box>

                    {card.graph_data && card.graph_data.length > 0 ? (
                      <Box
                        id="sa-chart"
                        sx={{
                          position: "absolute",
                          bottom: "-25%",
                          right: "-5%",
                          width: "75%",
                        }}
                      >
                        <Chart
                          height="140px"
                          options={{
                            chart: {
                              id: card.title,
                              toolbar: {
                                show: false,
                                tools: {
                                  download: false,
                                },
                              },
                            },
                            grid: {
                              xaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                              yaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                            },
                            stroke: {
                              width: 2,
                              curve: "smooth",
                              colors: [
                                ...chartColors.slice(index),
                                ...chartColors.slice(0, index),
                              ],
                            },
                            legend: {
                              show: card.graph_data.length > 1,
                              position: "top",
                              horizontalAlign: "right",
                              floating: true,
                              offsetY: 30,
                              markers: {
                                size: 5,
                                offsetX: -3,
                                fillColors: [
                                  ...chartColors.slice(index),
                                  ...chartColors.slice(0, index),
                                ],
                              },
                            },
                            tooltip: {
                              enabled: true,
                              shared: true,
                              intersect: false,
                              followCursor: true,
                              x: {
                                show: false,
                              },
                              custom: ({ series, dataPointIndex }: any) => {
                                return renderToString(
                                  <CustomTooltip
                                    series={series.map((serie: any) =>
                                      serie.map((value: any) =>
                                        formattedValue(value)
                                      )
                                    )}
                                    labels={card.categories}
                                    colors={[
                                      ...chartColors.slice(index),
                                      ...chartColors.slice(0, index),
                                    ]}
                                    dataPointIndex={dataPointIndex}
                                  />
                                );
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            fill: {
                              colors: [
                                ...chartColors.slice(index),
                                ...chartColors.slice(0, index),
                              ],
                              type: "gradient",
                              gradient: {
                                shade: "light",
                                type: "vertical",
                                opacityFrom: 0.5,
                                opacityTo: 0,
                              },
                            },
                            xaxis: {
                              tickAmount: 3,
                              categories: card.categories,
                              labels: {
                                formatter: function (value) {
                                  return dayjs(parseInt(value) / 1000).format(
                                    "MMM"
                                  );
                                },
                                rotate: 0,
                                style: {
                                  colors: "#75717B",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                },
                              },
                              axisTicks: {
                                show: false,
                              },
                            },
                            yaxis: {
                              labels: {
                                show: false,
                              },
                            },
                          }}
                          series={card.graph_data.map((data, index) => ({
                            name: (new Date().getFullYear() - index).toString(),
                            data,
                          }))}
                          type="area"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#75717B",
                          }}
                        >
                          No data available
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Card>
              </Grid>
            );
          })
        : Array.from({ length: 4 }).map((_, index) => (
            <Grid item xs={3} key={`sales_assistant_head_card-${index}`}>
              {salesAssistantHeaderDataLoading ? (
                <CardSkeleton />
              ) : (
                <CardPlaceholder />
              )}
            </Grid>
          ))}
    </Grid>
  );
};

export default Index;
