import dayjs from "dayjs";
import { Currency } from "./enums";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isYesterday from "dayjs/plugin/isYesterday";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isYesterday);
dayjs.extend(isToday);

export default dayjs;

export const capitaliseFirst = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const prettifyString = (str: string) => {
  const updatedStr = str.replace(/[^a-zA-Z0-9]/g, " ");
  return capitaliseFirst(updatedStr);
};

export const capitaliseAllFirstLetters = (str: string, delimiter: string) => {
  const splitStr = str.split(delimiter);
  const capitalsStr = splitStr.map((x) => x[0].toUpperCase() + x.substr(1));
  return capitalsStr.join("");
};

export const underscoreString = (str: string): string =>
  str.replace(/ /g, "_").toLowerCase();

export function getDuration(start: Date, end: Date): string {
  let diff = end.getTime() - start.getTime();
  const sign = diff < 0 ? "-" : "";
  if (diff < 0) {
    diff *= -1;
  }
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const minutes = `0${Math.floor((diff / MINUTE) % 60).toString()}`.slice(-2);
  // Hours are the largest denomination, so we don't pad them
  const hours = Math.floor(diff / HOUR).toString();
  return `${sign}${hours}:${minutes}`;
}

const dateOptions: any = {
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
};

export const formatDateStringFromNow = (date: Date | string): string =>
  dayjs.utc(date).fromNow();

export const formatDateString = (date: Date | string): string =>
  dayjs.utc(date).toDate().toLocaleString([], dateOptions);

export const formatTimeString = (date: Date | string): string =>
  dayjs.utc(date).local().format("HH:mm a");

export const getDayOfWeek = (date: Date | string): string =>
  dayjs.utc(date).local().format("dddd");

interface HasCreatedAt {
  created_at: string;
}

export function sortByCreatedAt<T extends HasCreatedAt>(arr: T[]): T[] {
  return arr.sort((a, b) =>
    new Date(a.created_at) >= new Date(b.created_at) ? -1 : 1
  );
}

export function openInNewTab(url: string) {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

export function formatFileSize(
  bytes: number,
  decimalPoint: number = 2
): string {
  if (bytes === 0) return "0 Bytes";
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const toSnakeCase = (stringToFormat: string) =>
  stringToFormat
    .split("")
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join("")
    .trim()
    .replace(/[-\s]+/g, "_");

export const snakeToPascal = (str: string) =>
  str
    .split("_")
    .map((substr) => capitaliseFirst(substr))
    .join("");

export const minifyJsonObject = (value: any): string => {
  let parsed;
  if (!value) {
    return value;
  }
  try {
    if (typeof value === "string") {
      parsed = JSON.parse(value);
      if (typeof parsed === "object") return JSON.stringify(parsed);
    }
    if (typeof value === "object") return JSON.stringify(value);
    return value;
  } catch (e) {
    return value;
  }
};
export const prettifyJson = (value: any): string => {
  try {
    let parsedValue: any = value;
    if (typeof value === "string") {
      parsedValue = JSON.parse(value);
    }
    return JSON.stringify(parsedValue, null, 2);
  } catch (e) {
    return value;
  }
};

export const downloadStringAsFile = ({
  data,
  fileName,
}: {
  data: string;
  fileName: string;
}): void => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export function stringifyCurrency(currency: Currency): {
  sign: string;
  word: string;
} {
  switch (currency) {
    case Currency.Dollar:
      return { sign: "$", word: "USD" };
    default:
      throw Error("wrong Currency supplied.");
  }
}

export async function serialPromiseAll(funcs: any) {
  const results = [];

  for (const func of funcs) {
    results.push(await func());
  }
  return results;
}
