import { RefObject, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useOnScreen(ref: RefObject<HTMLElement>) {
  const { pathname } = useLocation();

  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  useEffect(() => {
    console.warn(ref?.current);
    if (ref?.current) {
      observer.observe(ref.current!);
    }
    return () => observer.disconnect();
  }, [ref?.current, pathname]);

  return isIntersecting;
}
