import {RocketLaunch} from "@mui/icons-material";
import {List, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton,} from "@mui/material";

import {useRouteMatch} from "react-router";
import {useHistory} from "react-router-dom";
import {ExpendableList, ExpendableListHeader} from "./ExpendableList";
import {StyledTooltip} from "./StyledTooltip";
import useListFlow from "../../../services/Flows/useListFlows";
import {Flow, FlowTypes} from "../../../types";
import {FC, useState} from "react"

const AdBoosterMenu: FC<{ adBoosterFlows: Flow[] }> = ({adBoosterFlows}) => {
  const history = useHistory();
  const match = useRouteMatch<{ flowId: string }>("/ad-booster/:flowId");
  if (!adBoosterFlows) {
    return null;
  }
  return (
      <Paper elevation={2}>
        <List>
        {adBoosterFlows.map((b) => (
          <ListItemButton
            key={b.id}
            selected={match?.params?.flowId === b.id}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/ad-booster/${b.id}`);
            }}
          >
            {b.display_name}
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};
export const AdBoosterList: FC<{ open: boolean }> = ({ open }) => {
  const [openList, setOpenList] = useState(false);
  const pageMatch = useRouteMatch<{ flowId: string }>({
    path: "/ad-booster/:flowId",
  });
  const history = useHistory();

  const { data: flows, isLoading } = useListFlow({});
  const handleAdBoosterMenu = () => {
    setOpenList(!openList);
  };

  const adBoosterFlows =
    flows?.items?.filter(
      (flow) =>
        flow.type === FlowTypes.DIGITAL_SHELF &&
        flow.display_name !== "Amazon Reports"
    ) || [];
  if (isLoading) {
    return (
      <ListItemButton>
        <Skeleton height="100%" width="100%" />
      </ListItemButton>
    );
  }

  if (!adBoosterFlows.length) {
    return null;
  }

  if (!open) {
    return (
      <StyledTooltip title={<AdBoosterMenu adBoosterFlows={adBoosterFlows} />}>
        <ListItemButton
          onClick={() => {
            history.push(`/ad-booster/${adBoosterFlows[0].id}`);
          }}
          selected={!!pageMatch}
        >
          <ListItemIcon>
            <RocketLaunch />
          </ListItemIcon>
          <ListItemText primary="Ad Booster" sx={{ whiteSpace: "nowrap" }} />
        </ListItemButton>
      </StyledTooltip>
    );
  }

  return (
    <ExpendableList
      open={openList}
      label={(props) => (
        <ExpendableListHeader
          icon={<RocketLaunch />}
          onClick={handleAdBoosterMenu}
          label="Ad Booster"
          {...props}
        />
      )}
    >
      {adBoosterFlows.map((flow) => (
        <ListItemButton
          key={flow.id}
          selected={flow.id === pageMatch?.params.flowId}
          onClick={() =>
            history.push(`${["", "ad-booster", flow.id].join("/")}`)
          }
        >
          {flow.display_name}
        </ListItemButton>
      ))}
    </ExpendableList>
  );
};
