import { FC, Suspense, useEffect } from "react";
import { Switch } from "react-router-dom";
import { useAuthUserOrNull } from "@frontegg/react";

import { useTenantsActions } from "@frontegg/react-hooks/auth";
import { Split } from "./FeatureFlags/enums";
import { useQueryParams, useSplitEnabled } from "./hooks";
import useAuthenticatedIntercom from "./hooks/useAuthenticatedIntercom";
import Applications from "./pages/Applications";
import { getUserMostPrivilegeRole } from "./services/auth";
import { PlatformRole } from "./enums";
import Datalab from "./Datalab.tsx";
import { useAppContext } from "./ApplicationContext";
import { useUrlNavigatedEvent } from "./hooks/metrics/urlNavigateEvents";

const App: FC = () => {
  const enabled = useSplitEnabled(Split.INTERCOM);
  const { boot } = useAuthenticatedIntercom();
  const user = useAuthUserOrNull();
  const qs = useQueryParams();
  // const location = useLocation();
  const maybeTenantIdFromQueryParams = qs.get("tenantId");
  const { switchTenant } = useTenantsActions();
  useUrlNavigatedEvent();

  useEffect(() => {
    if (
      maybeTenantIdFromQueryParams &&
      user?.tenantId !== maybeTenantIdFromQueryParams
    ) {
      switchTenant({
        tenantId: maybeTenantIdFromQueryParams,
      });
    }
  }, [maybeTenantIdFromQueryParams, user?.tenantId]);

  useEffect(() => {
    if (enabled) {
      boot();
    }
  }, [enabled]);

  // useEffect(() => {
  //   if (location?.pathname !== "/chat") {
  //     const intervalIdFromLocal = localStorage.getItem("intervalId")
  //     const parsedId = Number(intervalIdFromLocal)
  //     if(parsedId) {
  //       localStorage.removeItem("intervalId")
  //       clearInterval(parsedId)
  //     }
  //   }
  // }, [location?.pathname]);

  const state = useAppContext();
  return (
    <Switch>
      {state.applicationMode ?
        (
          <Suspense fallback={"Loading..."}>
            <Applications />
          </Suspense>
        ) : (
          <Suspense fallback={"Loading..."}>
            <Datalab />
          </Suspense>
        )}
    </Switch>
  );
};

export default App;
