import {ChangeEvent, FC} from "react";
import {FormControlLabel, Switch} from "@mui/material";

const ArchiveSwitch: FC<{
    checked: boolean;
    label?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}> = ({checked, onChange, label}) => (
    <FormControlLabel
        control={
            <Switch
                checked={checked}
        onChange={onChange}
        name="Archived"
        color="primary"
      />
    }
    label={label || "Archived"}
  />
);
export default ArchiveSwitch;
