import {TextField, TextFieldProps} from "@mui/material";

import {useTheme} from "@mui/styles";
import {Icon} from "../Icon";
import {FC, useState} from "react"

export const Search: FC<TextFieldProps> = ({InputProps = {}, ...props}) => {
    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);
    
    return (
        <TextField
            variant="filled"
            size="small"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            InputProps={{
                startAdornment: (
                    <Icon
                        src="search.svg"
                        width="16px"
                        height="16px"
                        // @ts-ignore
                        color={isFocused ? theme.palette.primary.main : theme.palette.icons.secondary}
                    />
                ),
                ...InputProps,
            }}
            {...props}
        />
    );
};
