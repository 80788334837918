import { Box, Stack, Tab, Tabs } from "@mui/material";
import { FC } from "react";
import CatalogEnrichmentConsole from "./CatalogEnrichmentConsole";
import { useCatalogEnrichmentContext } from "./selectors";
import CatalogEnrichmentOverview from "./CatalogEnrichmentOverview";

const CatalogEnrichmentPanel: FC = () => {
  const { isEnrichmentFlowRunning, flowRunId, selectedTab, setSelectedTab } =
    useCatalogEnrichmentContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          width: "100%",
          height: "fit-content",
          background: "white",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          aria-label="basic tabs example"
        >
          <Tab
            label="Overview"
            sx={{
              textTransform: "none",
              typography: "body4",
              fontWeight: 400,
            }}
            disabled={!isEnrichmentFlowRunning && !flowRunId}
          />
          <Tab
            label="Logs"
            sx={{
              textTransform: "none",
              typography: "body4",
              fontWeight: 400,
            }}
            disabled={!isEnrichmentFlowRunning && !flowRunId}
          />
        </Tabs>
      </Stack>
      <Box
        sx={{
          overflow: "auto",
          height: "100%",
          width: "100%",
          padding: "0 2rem",
        }}
      >
        {selectedTab === 0 && <CatalogEnrichmentOverview />}
        {selectedTab === 1 && <CatalogEnrichmentConsole />}
      </Box>
    </Box>
  );
};

export default CatalogEnrichmentPanel;
