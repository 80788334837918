import AddIcon from "@mui/icons-material/Add";
import {Box, Button, Dialog, Stack, ThemeProvider} from "@mui/material";
import {useSnackbar} from "notistack";
import {FC, ReactNode, useEffect, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {useLocation} from "react-router-dom";
import {PageHeader} from "../../components";
import SearchBar from "../../components/SearchInput/SearchBar";
import {useQueryParams} from "../../hooks";
import {listDataSources, updateDatasource,} from "../../services/datasources/api";
import useDataSources from "../../services/datasources/useDataSources";
import theme from "../../themev2";
import {DataSource, MANUAL_DATA_SOURCE} from "../../types/datasources";
import DataSourceSelection from "./DataSourceSelectionDrawer";
import DataSourcesTable from "./DataSourcesTable";
import {EditDataSourceDialogContent} from "./EditDataSourceDialogContent";
import {ErrorDialogContent} from "./ErrorDialogContent";

const filterDSByText = (text: string) => (ds: DataSource) =>
    Object.values(ds).some(
        (value) => typeof value === "string" && value.indexOf(text) > -1
    );

type DataSourceDialogState = { open: boolean; dialog?: ReactNode };
const dialogStateDefaultValue: DataSourceDialogState = {
    open: false,
};

const useErrorQueryParam = (
  pathname: string,
  onError: (error: string) => void,
  onSuccess: (ds_id: string) => void
): void => {
  const location = useLocation();
  const qp = useQueryParams();
  const errorParam = qp.get("error");
  useEffect(() => {
    if (location.pathname.startsWith(pathname) && errorParam) {
      onError(errorParam);
      qp.delete("error");
    }
  }, [location.pathname, errorParam]);
  const successParam = qp.get("success");
  useEffect(() => {
    if (location.pathname.startsWith(pathname) && successParam) {
      onSuccess(successParam);
      qp.delete("success");
    }
  }, [location.pathname, successParam]);
};

const DataSources: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data = [], error } = useDataSources();
  const queryClient = useQueryClient();
  const [dialogState, setDialogState] = useState<DataSourceDialogState>(
    dialogStateDefaultValue
  );
  const [searchText, setSearchText] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const mutation = useMutation(
    (params: { dsId: string; name?: string; description?: string }) =>
      updateDatasource(params.dsId, params),
    {
      onSuccess: () => queryClient.invalidateQueries(["ListDataSources"]),
    }
  );
  const filteredData = data
    ?.filter((ds) => ds.id !== MANUAL_DATA_SOURCE.id)
    .filter(filterDSByText(searchText));
  const EditDialogContent: FC<{ ds: DataSource }> = ({ ds }) => (
      <EditDataSourceDialogContent
          dataSource={ds}
          onCancel={() => setDialogState({open: false})}
          onSubmit={async (data: any) => {
              await mutation
                  .mutateAsync({dsId: ds.id, ...data})
                  .then(() => {
                      enqueueSnackbar("Data source update successfully", {
                          variant: "success",
                      });
                  })
                  .catch((e) => {
                      enqueueSnackbar(`Failed to update data source: ${e.message}`, {
                          variant: "error",
                      });
                  })
                  .finally(() => {
                      setDialogState({open: false});
                  });
          }}
      />
  );

  useErrorQueryParam(
    "/datasources",
    (error) =>
      setDialogState({
        open: true,
        dialog: (
          <ErrorDialogContent
            error={error}
            onClose={() => setDialogState({ open: false })}
          />
        ),
      }),
    async (ds_id) => {
      const dataSources = await listDataSources();
      const ds = dataSources.find((ds) => ds.id === ds_id);
      setDialogState({
        open: !!ds,
        dialog: ds && <EditDialogContent ds={ds} />,
      });
    }
  );
  return (
    <ThemeProvider theme={theme}>
      <Box mb={5}>
        <PageHeader headerLabel="Data Sources" />
      </Box>
      <Box sx={{ mr: 4, ml: 4 }}>
        <Stack direction="row" spacing={3}>
            <SearchBar
                defaultValue=""
                options={[]}
                onInputChange={(_e: any, v) => setSearchText(v)}
                fullWidth
                containerProps={{
                    sx: {
                        display: "flex",
                        alignItems: "center",
                        borderRadius: (t) => t.spacing(0.5),
                    },
                }}
            />
            <Box>
                <Button
                    size="medium"
              variant="contained"
              fullWidth
              startIcon={<AddIcon color="inherit" />}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              Data source
            </Button>
          </Box>
        </Stack>

          <DataSourcesTable
              onEdit={(ds: any) => {
                  setDialogState({
                      open: true,
                      dialog: <EditDialogContent ds={ds}/>,
                  });
              }}
              data={filteredData}
              isLoading={isLoading}
              error={error}
          />
      </Box>
      <Dialog {...dialogState} onClose={() => setDialogState({ open: false })}>
        {dialogState.dialog}
      </Dialog>

      <DataSourceSelection open={openDrawer} onClose={setOpenDrawer} />
    </ThemeProvider>
  );
};

export default DataSources;
