import { Box } from "@mui/material";
import { FC } from "react";

import { Route, Switch } from "react-router-dom";
import LabLeftNav from "./components/LabLeftNav/LabLeftNav";
import ProtectedRoute, {
  ProtectedComponentWithRoles,
} from "./components/ProtectedRoute";
import { PlatformPermission } from "./enums";
import Administration from "./pages/Administration";
import Circuitboard from "./pages/Circuitboard";
import NewRun from "./pages/CreateNewRun";
import DataSources from "./pages/DataSource";
import AmazonCode from "./pages/EcommerceOnboarding/AmazonCode";
import OnboardingBanner from "./pages/EcommerceOnboarding/OnboardingBanner";
import Products from "./pages/EcommerceProducts";
import Flow from "./pages/Flow";
import NewFlow from "./pages/Flows/AddNewFlow";
import MarketplaceRoutes from "./pages/MarketplaceV2/Router";
import RunPageV2 from "./pages/Run/RunV2";
import Warehouse from "./pages/Warehouse";
import Workspace from "./pages/Workspace";

const Datalab: FC = () => (
  <Box display="flex" height="100%">
    <LabLeftNav />
    <Box flexGrow={1} display="flex" flexDirection="column">
      <Switch>
        <ProtectedRoute
          exact
          path={["/circuitboards", "/boards", "/flows", "/"]}
          component={Workspace}
        />
        <ProtectedRoute exact path={["/datasources"]} component={DataSources} />
        <ProtectedRoute
          path={["/flows/create", "/flows/:flowId/blocks/first"]}
          component={NewFlow}
        />
        <ProtectedRoute path={["/flows/:flowId"]} component={Flow} />
        <ProtectedRoute
          exact
          path={["/onboarding/ecommerce/code"]}
          component={AmazonCode}
        />
        <ProtectedRoute
          exact
          path={["/onboarding/ecommerce"]}
          component={OnboardingBanner}
        />
        <Route path={["/warehouse"]} component={Warehouse} />
        <Route path={["/workspaces"]}>
          <ProtectedComponentWithRoles
            permissions={[PlatformPermission.TENANT_ADMIN_GET]}
          >
            <Administration />
          </ProtectedComponentWithRoles>
        </Route>
        <ProtectedRoute path="/marketplace" component={MarketplaceRoutes} />
        <ProtectedRoute path="/data" component={Products} />
        <ProtectedRoute
          path={[
            "/circuitboards/:boardId/runs/new",
            "/boards/:boardId/runs/new",
            "/boards/:boardId/runs/edit",
          ]}
          component={NewRun}
        />
        <ProtectedRoute
          path={[
            "/circuitboards/:boardId/runs/:runId",
            "/boards/:boardId/runs/:runId",
          ]}
          component={RunPageV2}
        />
        <ProtectedRoute
          path={["/circuitboards/:boardId", "/boards/:boardId"]}
          component={Circuitboard}
        />
      </Switch>
    </Box>
  </Box>
);

export default Datalab;
