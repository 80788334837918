import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Switch,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import { Features } from "../types.ts";
import theme from "../../../themes/light.ts";
import { useMutation } from "react-query";
import { runAttributeExtractionApi } from "../useSpaces.ts";
import { useSnackbar } from "notistack";
import { validateAttributes, validateAttributesJson } from "./utils.ts";
import { AddIcon } from "../../../assets/index.tsx";
import LoadingSpinner from "../../../components/LoadingSpinner.tsx";
import TableColumns from "./FeaturesTable/TableColumns.tsx";
import TableRows from "./FeaturesTable/TableRows.tsx";
import { useFeaturesExtraction } from "./FeaturesExtractionProvider.tsx";
import { useParams } from "react-router-dom";

const FeaturesExtractionDrawer: FC<{
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
}> = ({ setIsDrawerOpen }) => {
  const { spaceId } = useParams<{ spaceId: string }>();
  if (!spaceId) {
    return null;
  }

  const snackbar = useSnackbar();

  const {
    spaceDataLoading,
    spaceAttributes,
    currentAttributes,
    setCurrentAttributes,
    changesMade,
  } = useFeaturesExtraction();

  const [loadingTable, setLoadingTable] = useState(spaceDataLoading);

  useEffect(() => {
    setLoadingTable(spaceDataLoading || currentAttributes === undefined);
  }, [spaceDataLoading, currentAttributes]);

  const [invalidAttributesMessage, setInvalidAttributesMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (currentAttributes) {
      validateAttributes(currentAttributes, setInvalidAttributesMessage);
    }
  }, [currentAttributes]);

  const [editAsJson, setEditAsJson] = useState(false);
  const [jsonText, setJsonText] = useState<string | null>(null);

  useEffect(() => {
    if (currentAttributes) {
      setJsonText(JSON.stringify(currentAttributes, null, 2));
    }
  }, [currentAttributes]);

  useEffect(() => {
    if (jsonText) {
      setInvalidAttributesMessage(validateAttributesJson(jsonText));
    }
  }, [jsonText]);

  const showDeleteButton = (index: number) => {
    document
      .getElementById(`delete-attribute-${index}`)
      ?.setAttribute("style", "opacity: 1");
    document
      .querySelectorAll(
        `[id^="delete-attribute-"]:not(#delete-attribute-${index})`
      )
      .forEach((attr) => {
        attr.setAttribute("style", "opacity: 0");
      });
  };

  const deleteAttribute = (index: number) => {
    if (currentAttributes) {
      const newAttributes = [...currentAttributes];
      newAttributes.splice(index, 1);
      setCurrentAttributes(newAttributes);
    }
  };

  const addAttribute = () => {
    if (currentAttributes) {
      setCurrentAttributes([
        ...currentAttributes,
        {
          attribute: "",
          type: "string",
          description: "",
          closed_list: false,
          accepted_values: [],
        },
      ]);
    }
  };

  const handleSwitch = () => {
    if (editAsJson && jsonText) {
      setCurrentAttributes(
        JSON.parse(
          invalidAttributesMessage ? JSON.stringify(spaceAttributes) : jsonText
        )
      );
    }
    setInvalidAttributesMessage(null);
    setEditAsJson((prev) => !prev);
  };

  const [showAttributeExtractionDialog, setShowAttributeExtractionDialog] =
    useState(false);
  const [extractionRequestPending, setExtractionRequestPending] =
    useState(false);

  const attribtueExtractionMutation = useMutation(
    ({ spaceId, features }: { spaceId: string; features: Features[] }) =>
      runAttributeExtractionApi({
        spaceId,
        features,
      })
  );

  const handleStartExtraction = () => {
    if (extractionRequestPending) return;
    if (currentAttributes) {
      setExtractionRequestPending(true);

      return attribtueExtractionMutation
        .mutateAsync({
          spaceId: spaceId,
          features: currentAttributes,
        })
        .then(() => {
          snackbar.enqueueSnackbar("Features extraction started successfully", {
            variant: "success",
          });
          setShowAttributeExtractionDialog(false);
          setIsDrawerOpen(false);
        })
        .catch((e) => {
          snackbar.enqueueSnackbar("Something went wrong: " + e, {
            variant: "error",
          });
        })
        .finally(() => {
          setExtractionRequestPending(false);
        });
    }
  };

  const columnsToDisplay = [
    { name: "Feature Name", field: "attribute" },
    { name: "Type", field: "type", width: 140 },
    { name: "Description", field: "description", width: 300 },
    {
      name: "Closed List",
      field: "closed_list",
      width: 110,
    },
    {
      name: "Accepted Values",
      field: "accepted_values",
      description: "Comma separated list of accepted values",
    },
  ];

  const [sortBy, setSortBy] = useState({
    column: "attribute",
    order: "asc",
  });

  useEffect(() => {
    if (currentAttributes) {
      const sortedAttributes = currentAttributes?.sort((a: any, b: any) => {
        if (a[sortBy.column] < b[sortBy.column]) {
          return sortBy.order === "asc" ? -1 : 1;
        }
        if (a[sortBy.column] > b[sortBy.column]) {
          return sortBy.order === "asc" ? 1 : -1;
        }
        return 0;
      });
      setCurrentAttributes(sortedAttributes);
    }
  }, [sortBy]);

  return (
    <>
      <Stack
        sx={{
          height: "100%",
          width: "75vw",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
            position: "sticky",
            top: 0,
            left: 0,
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
            zIndex: 1,
            background: "white",
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            Extract Space Features
          </Typography>
          <IconButton
            color="default"
            size="small"
            onClick={() => setIsDrawerOpen(false)}
          >
            <Close />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            padding: "20px 40px",
            gap: "20px",
          }}
        >
          <Typography variant="body1" fontWeight={500}>
            Here, you can define the features you want to extract from the
            products in your space. These features will be used to generate
            insights and recommendations on the assortment intelligence page.
          </Typography>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ color: theme.palette.grey[600] }}
          >
            Note: We recommend using type "boolean" for yes/no attributes.
          </Typography>
        </Stack>
        <Stack
          sx={{
            gap: "20px",
          }}
        >
          <Stack
            sx={{
              gap: "10px",
            }}
          >
            <Stack
              sx={{
                padding: "0 40px",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Switch
                checked={editAsJson}
                onChange={handleSwitch}
                disabled={loadingTable}
                color="primary"
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Edit as JSON
              </Typography>
            </Stack>
            {!editAsJson ? (
              <Stack
                sx={{
                  gap: "20px",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    marginRight: "40px",
                  }}
                  onMouseLeave={() => {
                    document
                      .querySelectorAll('[id^="delete-attribute-"]')
                      .forEach((attr) => {
                        attr.setAttribute("style", "opacity: 0");
                      });
                  }}
                >
                  <Stack
                    sx={{
                      marginTop: "40px",
                      flexDirection: "column",
                      width: "40px",
                      justifyContent: "start",
                    }}
                  >
                    {Array.from(
                      { length: currentAttributes?.length ?? 0 },
                      (_, index) => index
                    ).map((_attr, index) => (
                      <Box
                        key={`delete-attribute-${index}`}
                        id={`delete-attribute-${index}`}
                        sx={{
                          height: document.getElementById(`list-item-${index}`)
                            ?.clientHeight,
                          width: "100%",
                          opacity: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => deleteAttribute(index)}
                          sx={{
                            cursor: "pointer",
                          }}
                          onMouseEnter={() => showDeleteButton(index)}
                        >
                          <Close sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Box>
                    ))}
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <TableColumns
                      columns={columnsToDisplay}
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                    />
                    {loadingTable || currentAttributes === undefined ? (
                      <Stack
                        sx={{
                          height: "100px",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingSpinner
                          size={50}
                          color={theme.palette.primary.main}
                          borderWidth={2}
                        />
                      </Stack>
                    ) : currentAttributes.length === 0 ? (
                      <Stack
                        sx={{
                          height: "100px",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: theme.palette.grey[500],
                          }}
                        >
                          No attributes found
                        </Typography>
                      </Stack>
                    ) : (
                      <TableRows
                        data={currentAttributes}
                        showDeleteButton={showDeleteButton}
                        schema={columnsToDisplay}
                      />
                    )}
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "32px",
                  }}
                >
                  {!loadingTable && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        cursor: "pointer",
                        height: "32px",
                        gap: "8px",
                        padding: "10px 16px 10px 16px",
                        color: "white",
                        borderRadius: "20px",
                      }}
                      onClick={addAttribute}
                    >
                      <AddIcon /> Add
                    </Button>
                  )}
                </Box>
              </Stack>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px 40px",
                  width: "100%",
                }}
              >
                <TextareaAutosize
                  minRows={10}
                  placeholder="Paste JSON here"
                  value={jsonText || ""}
                  onChange={(e) => {
                    setJsonText(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </Box>
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              marginLeft: "45px",
              gap: "20px",
              height: "100px",
            }}
          >
            <Stack
              sx={{
                width: "25%",
                height: "fit-content",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                gap: "5px",
              }}
            >
              <Button
                variant="contained"
                disabled={
                  loadingTable ||
                  !!invalidAttributesMessage ||
                  currentAttributes?.length === 0
                }
                sx={{
                  fontSize: "14px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={() => {
                  setShowAttributeExtractionDialog(true);
                }}
              >
                {changesMade ? "Save & Run Extraction" : "Run Extraction"}
              </Button>
              {invalidAttributesMessage && (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {invalidAttributesMessage}
                </Typography>
              )}
            </Stack>
            <Button
              variant="outlined"
              disabled={loadingTable || !changesMade}
              sx={{
                fontSize: "14px",
                height: "40px",
                width: "25%",
              }}
              onClick={() => {
                setCurrentAttributes(
                  JSON.parse(JSON.stringify(spaceAttributes))
                );
              }}
            >
              Undo Changes
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Dialog
        open={showAttributeExtractionDialog}
        onClose={() => {
          setShowAttributeExtractionDialog(false);
        }}
      >
        <Stack>
          <DialogTitle>Start Features Extraction</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to start the features extraction process?
              This will overwrite the existing features and may take some time
              to complete.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setShowAttributeExtractionDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (!extractionRequestPending) {
                  handleStartExtraction();
                }
              }}
              color="primary"
              sx={{
                width: "150px",
              }}
            >
              {!extractionRequestPending ? (
                "Yes, Start Extraction"
              ) : (
                <LoadingSpinner size={20} color={theme.palette.primary.main} />
              )}
            </Button>
          </DialogActions>
        </Stack>
      </Dialog>
    </>
  );
};

export default FeaturesExtractionDrawer;
