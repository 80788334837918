import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../apiRequest";

const getProductsBrands = (): Promise<BrandInformation[]> =>
  apiRequest("v1/onboarding/ecommerce/products/brands", "GET");
export type BrandInformation = [number, string];
const useProductsBrands = (
  options?: UseQueryOptions<BrandInformation[], APIError>
): UseQueryResult<BrandInformation[], APIError> =>
  useQuery<BrandInformation[], APIError>(
    ["GetProductsBrands"],
    () => getProductsBrands(),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export default useProductsBrands;
