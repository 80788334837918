import { ContentCopy, Download } from "@mui/icons-material";
import { FC } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { useWarehouseKey } from "../../../services/warehouse";
import { downloadStringAsFile } from "../../../utils";

const DownloadKeyFile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useWarehouseKey();
  return (
    <Tooltip title="Download Key File">
      <span>
        <IconButton
          onClick={() => {
            downloadStringAsFile({
              data: JSON.stringify(data, null, 2),
              fileName: "key.json",
            });
            enqueueSnackbar("File downloaded successfully", {
              variant: "success",
            });
          }}
        >
          <Download color="primary" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const CopyToClipboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useWarehouseKey();
  return (
    <Tooltip title="Copy to clipboard">
      <span>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(data, null, "\t"));
            enqueueSnackbar("Copied key to clipboard", {
              variant: "success",
            });
          }}
        >
          <ContentCopy color="primary" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const ManageSection: FC = () => {
  const { data } = useWarehouseKey();

  return (
    <>
      <Box m={4}>
        {data ? (
          <TextField
            label="Service Account JSON Key"
            value={JSON.stringify(data, null, "\t")}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboard />
                  <DownloadKeyFile />
                </InputAdornment>
              ),
            }}
            multiline
            fullWidth
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </>
  );
};
export default ManageSection;
