import { Box } from "@mui/material";
import { FC, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface ApexChartProps {
  data: any;
}

const UnitSale: FC<ApexChartProps> = ({ data }) => {
  let graphData = [];
  graphData = data.map((el: any) => el.value);
  const [series] = useState([
    {
      name: "Desktops",
      data: graphData,
    },
  ]);

  const options: any = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#7F3CEE"],
      width: 2,
    },
    grid: {
      show: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    plotOptions: {
      area: {
        fill: "none",
        stroke: {
          width: 2,
          color: "yellow",
        },
      },
    },
    toolbar: {
      show: false,
    },

    tooltip: {
      series: false,
      followCursor: true,
      custom({ dataPointIndex }: any) {
        // w?.globals.tooltipTitle = "";
        // w?.globals.tooltipValue = "";
        const tooltipStyles = `
          background-color:#2C224C;
          padding: 8px 12px;
          font-size: 14px;
          font-weight: 400;
          color: #FFF;
          border-radius: 12px;
          width: 160px;
          height: 80px;
          padding: 10px 12px 8px 12px;
        `;
        const tooltipBoxStyles = `
          display: flex;
          gap: 4px;
          align-items: center;
        `;
        const dateStyles = `
        color: #A6A3AF;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
        margin-bottom: 4px;
        `;
        const labelStyles = `
        color: #FFFFF7;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0; 
        `;
        const valueStyles = `
        color: #FFF;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; 
        letter-spacing: -0.15px;
        margin: 0;
        `;

        const changeStyles = `
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        color: ${data[dataPointIndex]?.change >= 0 ? "green" : "red"};
        display: flex;
        gap: 2px;
        align-items: center;
        `;

        // Your custom tooltip content
        const customTooltipContent = `
          <div style="${tooltipStyles}">
            <p style="${dateStyles}">${data[dataPointIndex]?.date}</p>
            <h6 style="${labelStyles}">${data[dataPointIndex]?.label}</h6>
            <div style="${tooltipBoxStyles}">
            <h6 style="${valueStyles}">${data[dataPointIndex]?.value}</h6>
            <h5 style="${changeStyles}">
            <img
            src=${`/sales/trend-${
              data[dataPointIndex]?.change >= 0 ? "up" : "down"
            }.svg`}
            height="16px"
            width="16px"
          /> ${data[dataPointIndex]?.change}%</h5>
            </div>
          </div>
        
        `;
        return customTooltipContent;
      },
      fixed: {
        enabled: false,
        position: "bottomLeft",
        // offsetX: 0,
        // offsetY: 0,
      },
      marker: {
        show: true,
        colors: "#ED0A34",
      },
    },
    markers: {
      size: 6,
      colors: "#7F3CEE",
      strokeColors: "#fff",
      strokeWidth: 2,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 10,
      },
    },
    forecastDataPoints: {
      count: 1,
      fillOpacity: 0.1,
      strokeWidth: undefined,
      dashArray: 4,
    },
  };

  return (
    <Box
      id="chart"
      sx={{
        height: "100%",
        "& .apexcharts-xaxistooltip": {
          display: "none",
        },
        "& .apexcharts-tooltip": {
          // transform: "translate(-50%,0)",
          background: "none !important",
          border: "0 !important",
        },
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="100%"
        width="306px"
      />
    </Box>
  );
};

export default UnitSale;
