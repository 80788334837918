import { useOnboardingStatus } from "../services/ecommerce";
import { OnboardingStatusEnum } from "../enums";
import useBoardExists from "../services/boards/useBoardExists";

export const useIsTenantNew = (): {
  isTenantNew?: boolean;
  onboardingStatus?: OnboardingStatusEnum;
  totalProducts?: number;
} => {
  const { data: boardExists } = useBoardExists();
  const { data: onboardingStatus } = useOnboardingStatus();
  return {
    isTenantNew:
      boardExists && !boardExists.exists && !onboardingStatus?.total_products,
    onboardingStatus: onboardingStatus?.status,
    totalProducts: onboardingStatus?.total_products,
  };
};

export default useIsTenantNew;
