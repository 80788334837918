import { Stack, Typography } from "@mui/material";
import dayjs from "../../../utils";

import { useHistory } from "react-router";
import {
  CellProps,
  Column,
  Row,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import { UncontrolledTable } from "../../../components";
import IllustrationPlaceholder from "../../../components/IllustrationPlaceholder";
import { Status } from "../../../enums";
import { CircuitBoard, Schedule, Trigger } from "../../../types";
import { BoardCellComponent } from "./BoardCellComponent";
import useListTriggers from "../../../services/triggers/useListTriggers";
import { useBoards } from "../../../services/boards";
import RunStatus from "../../Run/RunV2/RunHeader/RunStatus";
import { UserAvatar } from "./UserCellComponent";
import { TriggerCell } from "./TriggerCell";
import { FC, useEffect, useMemo } from "react";

export type Board = CircuitBoard & { isLatest: boolean };

const sortLastRunStatus = (
  rowA: Row<Board>,
  rowB: Row<Board>,
  desc?: boolean
) => {
  const boardALastRunStatus = rowA.original.last_run?.status;
  const boardBLastRunStatus = rowB.original.last_run?.status;
  const descSort = () => {
    if (boardALastRunStatus === Status.FAILED) {
      return 1;
    }
    if (boardBLastRunStatus === Status.FAILED) {
      return -1;
    }
    if (boardALastRunStatus === Status.SUCCEEDED) {
      return 1;
    }
    if (boardBLastRunStatus === Status.SUCCEEDED) {
      return -1;
    }
    return boardALastRunStatus == null ? -1 : 1;
  };
  if (desc) {
    return descSort();
  }
  if (boardALastRunStatus === Status.SUCCEEDED) {
    return -1;
  }
  if (boardBLastRunStatus === Status.SUCCEEDED) {
    return 1;
  }
  if (boardALastRunStatus === Status.FAILED) {
    return -1;
  }
  if (boardBLastRunStatus === Status.FAILED) {
    return 1;
  }
  return boardALastRunStatus == null ? 1 : -1;
};

const sortByScheduledBlocks = (rowA: Row<Board>, rowB: Row<Board>) => {
  const a = rowA.values.scheduled?.interval?.toString();
  const b = rowB.values.scheduled?.interval?.toString();
  return a > b ? 1 : -1;
};

export type TriggerNode = {
  board_id: string;
  triggeredBy: TriggerNode[];
};

export const getTriggerPathFromNode = (
  node?: TriggerNode,
  path: string[] = []
): string[] => {
  if (!node) return path;
  return getTriggerPathFromNode(node.triggeredBy[0], [node.board_id, ...path]);
};

export const createTriggersTree = (
  triggers: Trigger[]
): Record<string, TriggerNode> => {
  const tree: Record<string, TriggerNode> = {};
  triggers.forEach((t) => {
    const node: TriggerNode = tree[t.board_id]
      ? tree[t.board_id]
      : { board_id: t.board_id, triggeredBy: [] };
    t.triggered_by_ids.forEach((parentTrigger) => {
      const parentNode: TriggerNode = tree[parentTrigger]
        ? tree[parentTrigger]
        : { board_id: parentTrigger, triggeredBy: [] };
      node.triggeredBy.push(parentNode);
      tree[parentTrigger] = parentNode;
    });
    tree[t.board_id] = node;
  });
  return tree;
};

const BoardsTable: FC<{
  data: Board[];
  isLoading: boolean;
  pageSize: number;
  pageStep: number;
  error?: Error | null;
  onFiltersChange?: (filters: { pageSize: number }) => void;
}> = ({ data, isLoading, error, onFiltersChange, pageSize, pageStep }) => {
  const history = useHistory();
  const memoFilter = useMemo(() => sortLastRunStatus, []);
  const { data: triggers = {} } = useListTriggers<Record<string, TriggerNode>>({
    select: createTriggersTree,
  });

  const { data: allBoards = [] } = useBoards();
  // @ts-ignore
  const columns: Column<Board>[] = useMemo(
    () => [
      {
        Header: "Blocks",
        id: "Blocks",
        accessor: "identifier",
        Cell: BoardCellComponent,
      },
      {
        id: "scheduled",
        Header: "Trigger",
        isSortedDesc: true,
        maxWidth: 70,
        sortType: sortByScheduledBlocks,
        accessor: (b: any) => (b.schedules[0] ? b.schedules[0] : ""),
        Cell: ({ value, row }: CellProps<Board, Schedule>) => {
          const path = getTriggerPathFromNode(
            triggers[row?.original.identifier]
          ).map(
            (boardId) =>
              allBoards.find((b) => b.identifier === boardId)?.displayName ||
              boardId
          );
          return <TriggerCell schedule={value} path={path} />;
        },
      },
      {
        id: "Last run",
        Header: "Last run",
        accessor: "latest_run",
        minWidth: 60,
        Cell: ({ value, row }: CellProps<Board>) => (
          <Stack spacing={1}>
            {value && <Typography>{dayjs.utc(value).fromNow()}</Typography>}
            {row.original.last_run?.user_inf && (
              <UserAvatar user={row.original.last_run?.user_inf} />
            )}
          </Stack>
        ),
      },
      {
        id: "status",
        Header: "Status",
        isSortedDesc: true,
        width: 350,
        sortType: memoFilter,
        accessor: (b: any) => b.last_run,
        Cell: ({ value: run }: any) => run && <RunStatus run={run} />,
      },
    ],
    [data]
  );
  const instance = useTable(
    {
      columns,
      data: data || [],
      autoResetSortBy: false,
      autoResetPage: false,
      initialState: { pageSize },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    instance.setPageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    const col = columns.find((col) => col.accessor === "latest_run");
    if (col != null) {
      instance.setSortBy([{ id: col.id || "", desc: true }]);
    }
  }, [true]);

  return !isLoading && data?.length === 0 ? (
    <IllustrationPlaceholder
      imageHeight={300}
      imageWidth={300}
      text='No blocks match search, try to reset query filters by clicking "All Blocks".'
    />
  ) : (
    <>
      <UncontrolledTable
        disablePagination
        instance={instance}
        columns={columns}
        plugins={[useResizeColumns]}
        tableRowPropsGetter={(row) => ({
          sx: {
            height: 85,
          },
          onClick: () => {
            const board = row.original as CircuitBoard;

            history.push(`/boards/${board.identifier}`);
          },
        })}
        tableProps={{
          size: "small",
          stickyHeader: true,
          "aria-label": "a sticky table",
        }}
        data={data || []}
        isLoading={isLoading}
        error={error || null}
      />
      {data.length - pageSize > 0 && (
        <Typography
          onClick={() => {
            const nextPageSize = pageSize + pageStep;
            if (onFiltersChange) {
              onFiltersChange({ pageSize: nextPageSize });
            }
          }}
          fontWeight="bold"
          sx={{
            transition: "background 0.2s ease",
            "&:hover": { background: (t) => t.palette.grey[100] },
            padding: 2,
            cursor: "pointer",
            borderBottom: (t) => `1px solid ${t.palette.text.secondary}`,
            color: (t) => t.palette.primary.dark,
          }}
        >
          + Load 20 more (of {data.length - pageSize})
        </Typography>
      )}
    </>
  );
};

export default BoardsTable;
