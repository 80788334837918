import { useMutation, useQueryClient } from "react-query";
import { Run } from "../../types";
import apiRequest from "../apiRequest";

export async function unarchiveRun(
  boardId: string,
  runId: string
): Promise<Run> {
  return await apiRequest<Run>(
    `circuitboards/${boardId}/runs/${runId}/unarchive`,
    "POST"
  );
}

export default function useUnarchive(boardId: string, runId: string) {
  const queryClient = useQueryClient();
  return useMutation("unarchive", () => unarchiveRun(boardId, runId), {
    onSuccess: async (data) => {
      queryClient.setQueryData(["run", runId], data);
      await queryClient.refetchQueries();
    },
  });
}
