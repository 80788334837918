import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";

import { rerunFlow } from "../../services/Flows/api";
import useFlow from "../../services/Flows/useFlow";

export const RerunFlowButton = ({ flowId }: { flowId: string }) => {
  const { refetch } = useFlow(flowId);
  const rerunFlowMutation = useMutation((flowId: string) => rerunFlow(flowId));

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() =>
        rerunFlowMutation
          .mutateAsync(flowId)
          .then(() => {
            enqueueSnackbar("Flow rerun successfully", {
              variant: "success",
            });
            return refetch();
          })
          .catch(() =>
            enqueueSnackbar("Flow rerun  failed", {
              variant: "error",
            })
          )
      }
      startIcon={<Refresh />}
      disabled={rerunFlowMutation.isLoading}
    >
      Rerun flow
    </Button>
  );
};
