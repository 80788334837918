import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { APIError } from "../../services/apiRequest";
import { FlowTemplateData, getFlowTemplates } from "./api";

export const useFlowTemplates = ({
  options,
}: {
  options?: UseQueryOptions<FlowTemplateData[], APIError>;
} = {}): UseQueryResult<FlowTemplateData[], APIError> =>
  useQuery<FlowTemplateData[], APIError>(
    ["useFlowTemplates"],
    getFlowTemplates,
    options
  );
