import ImportExportIcon from "@mui/icons-material/ImportExport";
import { Button, Tooltip } from "@mui/material";
import { FC } from "react";
import { AuthorizedContent } from "../../../../components/AuthorizedContent";
import { PlatformPermission, Status } from "../../../../enums";
import { useRun } from "../../../../services/runs";
import useExport from "../../../../services/runs/useExport";
import { RunHeaderButtonProps } from "../types";

const ExportButton: FC<RunHeaderButtonProps> = ({
  boardId,
  runId,
  classes,
}) => {
  const { data: run } = useRun(boardId, runId);
  const exportMutation = useExport(
    boardId,
    runId,
    run && `You are about to export run "${run!.name}"`
  );
  return (
    <AuthorizedContent requiredPermissions={[PlatformPermission.RUNS_CREATE]}>
      <Tooltip title="Export">
        <Button
          disabled={run?.status !== Status.READY_TO_EXPORT}
          classes={{
            text: classes.buttonLabel,
          }}
          variant="outlined"
          onClick={() => exportMutation.mutateAsync()}
          sx={{
            maxWidth: "48px",
            minWidth: "48px",
            height: "40px",
            color: "text.primary",
            borderColor: "text.secondary",
          }}
        >
          <ImportExportIcon />
        </Button>
      </Tooltip>
    </AuthorizedContent>
  );
};

export default ExportButton;
