import {
  CircularProgress,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useFlowTemplates } from "./useFlowTemplates";

export const FlowTemplateSelect = (props: any) => {
  const { onChange } = props;
  const { data, isLoading } = useFlowTemplates();
  return (
    <Select
      variant="filled"
      startAdornment={!isLoading ? null : <CircularProgress size={10} />}
      {...props}
    >
      <MenuItem key="None" value="None" onClick={() => onChange && onChange()}>
        <ListItemText primary="None" />
      </MenuItem>
      {data?.map(({ config: t }: any) => (
        <MenuItem
          key={t.type}
          value={t.type}
          onClick={() => onChange && onChange(t.type)}
        >
          <ListItemText primary={t.name} />
        </MenuItem>
      ))}
    </Select>
  );
};
