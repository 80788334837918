import SVG from "react-inlinesvg";
import theme from "../themev2";
import RotatingBox from "./RotatingBox";
import {FC} from "react"

const RotatingLoader: FC = () => (
  <RotatingBox sx={{ ml: 0.5 }}>
    <SVG fill={theme.palette.primary.dark} src={`/rotate.svg`} />
  </RotatingBox>
);

export default RotatingLoader;
