import {Box, CardHeader} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScheduleMenu from "./ScheduleMenu";
import {Schedule} from "../../../../../types";
import {getScheduleDescription} from "./utils";
import {FC} from "react"

const ScheduleBox: FC<{ schedule: Schedule }> = ({schedule}) => (
    <Box display="flex" justifyContent="space-between" padding={1}>
        <CardHeader
            title={schedule.data.name}
            subheader={getScheduleDescription(schedule)}
            avatar={<AccessTimeIcon/>}
            sx={{padding: 1}}
        />
        <ScheduleMenu schedule={schedule}/>
  </Box>
);

export default ScheduleBox;
