import { DataSourceEditFields } from "../../pages/DataSource/EditDataSourceDialogContent";
import {
  CreateDataSource,
  DataSource,
  DataSourceGatherStatus,
} from "../../types/datasources";
import apiRequest from "../apiRequest";

export function listDataSources(): Promise<DataSource[]> {
  return apiRequest<DataSource[]>("v1/datasources", "GET");
}

export function updateDatasource(
  dataSourceId: string,
  fields: DataSourceEditFields
): Promise<DataSource> {
  return apiRequest<DataSource>("v1/datasources", "PATCH", undefined, {
    ...fields,
    id: dataSourceId,
  });
}

export const createDataSource = (body: CreateDataSource): Promise<any> =>
  apiRequest<any>("v1/datasources/", "POST", undefined, {
    name: body.name,
    type: body.type,
    configuration: body.configuration,
  });

export const getStatuses = (): Promise<DataSourceGatherStatus[]> =>
  apiRequest<DataSourceGatherStatus[]>("v1/datasources/statuses");

export const deleteDataSource = (datasourceId: string): Promise<never> =>
  apiRequest<never>(`v1/datasources/${datasourceId}`, "DELETE");
