import { MaterialDesignContent } from "notistack";
import { styled } from "@mui/material";
import theme from "../themes/light";

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  () => ({
    "&.notistack-MuiContent-secondary": {
      backgroundColor: theme.palette.secondary.main,
      marginLeft: "240px",
    },
  })
);
