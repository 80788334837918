import { useTheme } from "@mui/styles";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useDisplayList, useSalesContext } from "../hooks/selectors";
import RevenueSortDropdown from "./RevenueSortDropdown";

const ListHead = () => {
  const theme = useTheme();
  const insights = useDisplayList();
  const { isFetchingInsights } = useSalesContext();

  return (
    <Box sx={{ background: theme.palette.backgrounds.secondary, paddingX: 4 }}>
      <Stack
        direction="row"
        padding="0 0 0 20px"
        maxHeight="36px"
        alignItems="center"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          // sx={{ ml: "16px" }}
          alignItems="center"
        >
          <Box>
            <Box display="flex" gap="16px" alignItems="center">
              {/* {pathname.search("alerts") !== -1 && (
                  <Checkbox
                    sx={{ width: columnWidths.checkbox }}
                    checked={isAllSelected}
                    onClick={selectAll}
                  />
                )} */}
              {isFetchingInsights ? (
                <Skeleton
                  width="50px"
                  height="15px"
                  sx={{ transform: "scale(1)" }}
                />
              ) : (
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {insights?.length} Products
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <RevenueSortDropdown />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ListHead;
