import {IconButton, InputAdornment, TextField} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {FC} from "react"

const SheetField: FC<{
  sheetName: string;
  onClick: () => void;
  shrinkLabel: boolean;
  disabled: boolean;
}> = ({ sheetName, onClick, shrinkLabel, disabled }) => (
  <TextField
    label="Sheet Name"
    value={sheetName}
    variant="outlined"
    margin="dense"
    inputProps={{
      readOnly: true,
    }}
    disabled={disabled}
    InputLabelProps={{ shrink: shrinkLabel }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={onClick} disabled={disabled}>
            <EditIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
    fullWidth
  />
);

export default SheetField;
