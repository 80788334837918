import { Button } from "@mui/material";

import { useTheme } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useInsightUserStateUpdater } from "../../useInsights";
import { useInsightsContext } from "../../hooks/selectors";

export const useTabsButtons = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const insightUserStateUpdater = useInsightUserStateUpdater();
  const { enqueueSnackbar } = useSnackbar();
  const { refetchInsightsMetadata, refetchInsights } = useInsightsContext();

  const removeItemFromList = (insightId: string) => {
    const element = document.getElementById(`insight-id-${insightId}`);
    if (element) {
      const sectionHeight = element.scrollHeight;
      const elementTransition = element.style.transition;
      element.style.transition = "";
      requestAnimationFrame(() => {
        element.style.height = `${sectionHeight}px`;
        element.style.transition = elementTransition;
        requestAnimationFrame(() => {
          element.style.height = `${0}px`;
        });
      });
      element.classList.add("hidden");
      element.setAttribute("data-collapsed", "true");
      if (location.search.search("insightId") > -1) {
        history.push(location.pathname);
      }
    }
  };

  const reAddItemToList = (insightId: string) => {
    const element = document.getElementById(`insight-id-${insightId}`);
    if (element) {
      const sectionHeight = element.scrollHeight;
      const elementTransition = element.style.transition;
      element.style.transition = "";
      requestAnimationFrame(() => {
        element.style.height = `${sectionHeight}px`;
        element.style.transition = elementTransition;
        requestAnimationFrame(() => {
          element.style.height = "auto";
        });
      });
      element.classList.remove("hidden");
      element.setAttribute("data-collapsed", "false");
      if (location.search.search("insightId") > -1) {
        history.push(location.pathname);
      }
    }
  };

  const setDismissed = async (insightId: string, val: number) => {
    try {
      await insightUserStateUpdater({
        insightId,
        dismissed: val,
        followed: false,
      });
      removeItemFromList(insightId);
      enqueueSnackbar(
        val === 1
          ? "Insight dismissed till next update"
          : "Insight moved back to My Items",
        {
          variant: "secondary",
          action: () => (
            <>
              {val === 0 && (
                <Button
                  sx={{ color: theme.palette.backgrounds.white }}
                  onClick={() => history.push(`/insights#${insightId}`)}
                >
                  Take me there
                </Button>
              )}
              <Button
                sx={{ color: theme.palette.backgrounds.white }}
                onClick={async () => {
                  reAddItemToList(insightId);
                  await insightUserStateUpdater({
                    insightId,
                    dismissed: val === 0 ? 1 : 0,
                  });
                  await refetchInsights();
                  await refetchInsightsMetadata();
                }}
              >
                Undo
              </Button>
            </>
          ),
        }
      );
      await refetchInsights();
      await refetchInsightsMetadata();
    } catch (e) {
      enqueueSnackbar("Could not change dismissed status", {
        variant: "error",
      });
    }
  };

  return { setDismissed, reAddItemToList, removeItemFromList };
};
