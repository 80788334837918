import { Typography } from "@mui/material";

const TrackingItem = () => (
  <>
    <img
      src="/sales/alert-circle.svg"
      alt="Products you have tracked will be shown here"
    />
    <Typography variant="body1" fontWeight="600" marginTop="20px">
      Products you have tracked will be shown here
    </Typography>
  </>
);

export default TrackingItem;
