import { useMutation, useQueryClient } from "react-query";
import { Run } from "../../types";
import apiRequest from "../apiRequest";
import { useConfirmation } from "../../components/ConfirmationDialog";

export async function exportRun(boardId: string, runId: string): Promise<Run> {
  return await apiRequest<Run>(`v1/flows/export/${boardId}/${runId}`, "POST");
}

export default function useArchive(
  boardId: string,
  runId: string,
  confirmationText?: string
) {
  const queryClient = useQueryClient();
  const confirm = useConfirmation();
  const exportWithConfirmation = () =>
    confirm({
      title: confirmationText,
    }).then(() => exportRun(boardId, runId));
  return useMutation("export", () => exportWithConfirmation(), {
    onSuccess: async (data) => {
      queryClient.setQueryData(["run", runId], data);
      await queryClient.refetchQueries();
    },
  });
}
