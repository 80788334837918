import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { Popper } from "@mui/material";
import { Button } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { ArrowIcon } from "../../assets";
import theme from "../../themes/light";
import { useInsightChatContext } from "./hooks/selectors";
import { Space } from "../SpacesV2/types";

const SpaceMenuList: FC = () => {
  const {
    spacesList: spaces,
    spacesListLoading,
    selectedSpace,
    setSpaceId,
  } = useInsightChatContext();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleOption = (space: Space) => {
    setSpaceId(space?.id);
    setOpen(false);
  };

  if (spacesListLoading) {
    return (
      <Skeleton
        sx={{
          height: "24px",
          width: "150px",
        }}
      />
    );
  }

  return (
    <Box>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        // disabled={isDisabled || false}
        onClick={handleToggle}
        sx={{
          display: "flex",
          gap: "8px",
          minHeight: "24px",
          minWidth: "100px",
          width: "fit-content",
          fontSize: "14px",
          padding: "0px",
          color: theme.palette.primary.main,
          "> svg": {
            transition: "0.3s all ease",
            ...(open && { transform: "rotate(180deg)" }),
            "> path": {
              fill: theme.palette.primary.main,
            },
          },
        }}
      >
        <Typography
          variant="body4"
          sx={{
            fontSize: "17px",
            color: theme.palette.primary.main,
          }}
        >
          {selectedSpace?.name}
        </Typography>
        <ArrowIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              sx={{
                maxHeight: "370px",
                overflow: "auto",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {spaces &&
                    spaces?.length > 0 &&
                    spaces.map((space: Space) => (
                      <MenuItem
                        key={space?.id}
                        onClick={() => handleOption(space)}
                      >
                        {space?.name}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
export default SpaceMenuList;
