import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Insight } from "../../types";
import apiRequest, { APIError } from "../../services/apiRequest";
import { InsightRead, InsightReadImpl } from "./types";
import { DUMMY_INSIGHTS, DUMMY_SINGLE_INSIGHT } from "./dummyInsight";
import { useInsightsContext } from "./hooks/selectors";

type PutInvalidate<T, Q> = {
  queryKey: string;
  updater: (arg: Q) => Promise<T>;
};

type UpdateAssignee = {
  insightId: string;
  assignees: string[];
};

type UpdateInsightUserState = {
  insightId?: string;
  insightIds?: string[];
  followed?: string | boolean;
  dismissed?: 0 | 1 | 2;
};

type UpdateInsightDoneState = {
  insightIds: string[];
  done: boolean;
};

const USE_INSIGHTS = "useInsights" as const;
const USE_INSIGHTS_V2 = "useInsightsV2" as const;
const USE_INSIGHTS_USER_STATE_BULK_V2 = "useInsightsUserStateBulkV2" as const;
const USE_INSIGHTS_USER_STATE_V2 = "useInsightsUserState" as const;
const USE_INSIGHTS_MARK_AS_DONE = "useInsightsMarkAsDone" as const;
const USE_INSIGHTS_MARK_AS_UNDONE = "useInsightsMarkAsUnDone" as const;
const USE_INSIGHTS_DONE_BULK_V2 = "useInsightsMarkAsUnDoneBulk" as const;

function getInsights(): Promise<Insight[]> {
  return apiRequest<Insight[]>("insights", "GET");
}

const useDummyData = () =>
  new URLSearchParams(window.location.search).get("dummy");

function getInsightsV2(
  pathname: string,
  filterId?: string
): Promise<InsightRead[]> {
  if (useDummyData()) {
    return Promise.resolve(DUMMY_INSIGHTS);
  }

  let url = "insights/v2";
  let params = {};
  if (pathname.search("dismissed") > -1) {
    url = `${url}/dismissed`;
  }
  if (pathname.search("my-items") > -1) {
    url = `${url}/followed`;
  }
  if (pathname.search("done") > -1) {
    url = `${url}/done`;
  }
  if (filterId) {
    params = { filter_id: filterId };
  }

  return apiRequest<InsightRead[]>(url, "GET", params).then((data) =>
    data.map((d) => new InsightReadImpl(d))
  );
}

function getInsightMetadata(filterId?: string): Promise<{
  followed: number;
  new: number;
  my_updated_date: string;
}> {
  let parmas = {};
  if (filterId) {
    parmas = { filter_id: filterId };
  }

  return apiRequest<{
    followed: number;
    new: number;
    my_updated_date: string;
  }>("insights/v2/metadata", "GET", parmas);
}

export function useInsights(
  options: UseQueryOptions<Insight[], APIError> = {}
) {
  return useQuery<Insight[], APIError>([USE_INSIGHTS], () => getInsights(), {
    ...options,
  });
}

export function useFilteredInsightsV2(
  options: UseQueryOptions<InsightRead[], APIError> & { filterId?: string } = {}
) {
  const { pathname } = useLocation();
  const { selectedProductGroup } = useInsightsContext();
  return useQuery<InsightRead[], APIError>(
    [
      "useFilteredInsightsV2",
      pathname,
      options?.filterId || selectedProductGroup?.id,
    ],
    () =>
      getInsightsV2(pathname, options?.filterId || selectedProductGroup?.id),
    {
      ...options,
      staleTime: Infinity,
    }
  );
}

export function useInsightsV2(
  options: UseQueryOptions<InsightRead[], APIError> & { filterId?: string } = {}
) {
  const { pathname } = useLocation();
  return useQuery<InsightRead[], APIError>(
    ["useInsightsV2Hook", pathname, options?.filterId],
    () => getInsightsV2(pathname, options?.filterId),
    {
      ...options,
      staleTime: Infinity,
    }
  );
}
export function useInsightMetadata(filterId?: string) {
  return useQuery<
    {
      followed: number;
      new: number;
      my_updated_date: string;
    },
    APIError
  >(["useInsightsMetadata", filterId], () => getInsightMetadata(filterId), {
    staleTime: Infinity,
  });
}

async function getInsight(insightId: string): Promise<Insight> {
  return apiRequest<Insight>(`insights/${insightId}`, "GET");
}

async function getInsightV2(insightId: string): Promise<InsightRead> {
  if (useDummyData()) {
    return Promise.resolve(DUMMY_SINGLE_INSIGHT);
  }
  return apiRequest(`insights/v2/${insightId}`, "GET");
}

export const getInsightV2WithIframes = async (insightId: any) =>
  await apiRequest(`insights/v2/${insightId}/with_iframes`, "GET");

export const getInsightV2WithoutIframe = async (insightId: any) =>
  await apiRequest(`insights/v2/${insightId}`, "GET");

const usePutInvalidate = function <T, Q>({
  queryKey,
  updater,
}: PutInvalidate<T, Q>) {
  const queryClient = useQueryClient();
  const updateInvalidate = useCallback(
    async (arg: any) => {
      const result = await updater(arg);
      queryClient.invalidateQueries({ queryKey }).then();
      return result;
    },
    [queryClient]
  );
  return updateInvalidate;
};

export const useAssigneeUpdater = () =>
  usePutInvalidate({
    queryKey: USE_INSIGHTS_V2,
    updater: ({ insightId, assignees }: UpdateAssignee) =>
      apiRequest(`insights/${insightId}/assignees`, "PUT", undefined, {
        insightId,
        assignees,
      }),
  });

export const useInsightUserStateUpdater = () =>
  usePutInvalidate({
    queryKey: USE_INSIGHTS_USER_STATE_V2,
    updater: ({ insightId, followed, dismissed }: UpdateInsightUserState) =>
      apiRequest("insights/v2/user_state", "PATCH", undefined, [
        {
          insightId,
          followed,
          dismissed,
        },
      ]),
  });

export const useInsightMarkAsDone = () =>
  usePutInvalidate({
    queryKey: USE_INSIGHTS_MARK_AS_DONE,
    updater: ({ insightId }: { insightId: string }) =>
      apiRequest(`insights/v2/${insightId}/done`, "POST"),
  });

export const useInsightMarkAsUnDone = () =>
  usePutInvalidate({
    queryKey: USE_INSIGHTS_MARK_AS_UNDONE,
    updater: ({ insightId }: { insightId: string }) =>
      apiRequest(`insights/v2/${insightId}/done`, "DELETE"),
  });

export const useInsightUserStateUpdateBulk = () =>
  usePutInvalidate({
    queryKey: USE_INSIGHTS_USER_STATE_BULK_V2,
    updater: ({ insightIds, followed, dismissed }: UpdateInsightUserState) => {
      const body = insightIds?.map((id) => ({
        insightId: id,
        followed,
        dismissed,
      }));
      return apiRequest("insights/v2/user_state", "PATCH", undefined, body);
    },
  });

export const useInsightDoneUpdateBulk = () =>
  usePutInvalidate({
    queryKey: USE_INSIGHTS_DONE_BULK_V2,
    updater: async ({ insightIds, done }: UpdateInsightDoneState) => {
      const promises: Promise<any>[] = [];
      insightIds?.forEach((id) => {
        promises.push(
          apiRequest(`insights/v2/${id}/done`, done ? "POST" : "DELETE")
        );
      });
      return await Promise.all(promises);
    },
  });

export const useInsightUpdater = () =>
  usePutInvalidate({
    queryKey: USE_INSIGHTS_V2,
    updater: (insight: Partial<Insight> & Pick<Insight, "id">) =>
      apiRequest(`insights/${insight.id}`, "PUT", undefined, insight),
  });

export function useInsight(
  insightId?: string | null,
  options: UseQueryOptions<Insight, APIError> = {}
) {
  return useQuery<Insight, APIError>(
    ["useInsight", insightId],
    () => getInsight(insightId as string),
    {
      ...options,
      enabled: options.enabled && Boolean(insightId),
    }
  );
}

async function getDoneInsightV2(insightId: string): Promise<InsightRead> {
  if (useDummyData()) {
    return Promise.resolve(DUMMY_SINGLE_INSIGHT);
  }
  return apiRequest(`insights/v2/done/${insightId}`, "GET");
}

export function useDoneInsightV2(
  insightId: string | null,
  options: UseQueryOptions<InsightRead, APIError> = {}
) {
  return useQuery<InsightRead, APIError>(
    ["useDoneInsightV2", insightId],
    () => getDoneInsightV2(insightId as string),
    {
      ...options,
      enabled: Boolean(
        options.enabled || (options.enabled == null && insightId)
      ),
    }
  );
}

export function useInsightV2(
  insightId: string | null,
  options: UseQueryOptions<InsightRead, APIError> = {}
) {
  return useQuery<InsightRead, APIError>(
    ["useInsightV2", insightId],
    () => getInsightV2(insightId as string),
    {
      ...options,
      enabled: Boolean(
        options.enabled || (options.enabled == null && insightId)
      ),
    }
  );
}
