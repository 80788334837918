import {useHistory} from "react-router-dom";
import {useRouteMatch} from "react-router";
import {List, ListItemButton, Paper} from "@mui/material";
import {CircuitBoard} from "../../../types";
import {FC} from "react"

export const BoardsMenu: FC<{ blocks: CircuitBoard[]; prefix?: string }> = ({
                                                                                blocks,
                                                                                prefix,
                                                                            }) => {
    const history = useHistory();
    const match = useRouteMatch<{ boardId: string }>("/:application/:boardId");
    return !blocks.length ? null : (
        <Paper elevation={2}>
            <List>
        {blocks.map((b) => (
          <ListItemButton
            selected={match?.params?.boardId === b.identifier}
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                [prefix ? `/${prefix}` : "", b.identifier].join("/")
              );
            }}
          >
            {b.displayName}
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};
