import {TableState, usePagination, useSortBy, useTable} from "react-table";
import {UncontrolledTable} from "./index";
import {TableProps} from "./types";
import {FC} from "react"

const Table: FC<TableProps> = (props) => {
    const instance = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: {...props.initialState, pageIndex: 0} as TableState,
            // @ts-ignore
            pageCount: 1000,
            manualPagination: true,
            manualSortBy: true,
            autoResetSortBy: false,
            autoResetPage: false,
        },
        useSortBy,
        usePagination,
        ...(props?.plugins || [])
    );

    return <UncontrolledTable instance={instance} {...props} />;
};

export default Table;
