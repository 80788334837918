import { useQuery, UseQueryOptions } from "react-query";
import { Run } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

async function getRun(circuitboardId: string, runId: string): Promise<Run> {
  return await apiRequest<Run>(
    `circuitboards/${circuitboardId}/runs/${runId}`,
    "GET"
  );
}

export default function useRun(
  boardId: string,
  runId: string,
  options?: UseQueryOptions<Run, APIError>
) {
  return useQuery<Run, APIError>(
    ["run", runId],
    () => getRun(boardId, runId),
    options
  );
}

async function getSlimRun(circuitboardId: string, runId: string): Promise<Run> {
  return await apiRequest<Run>(
    `circuitboards/${circuitboardId}/runs/${runId}/slim`,
    "GET"
  );
}

export function useSlimRun(
  boardId: string,
  runId: string,
  options?: UseQueryOptions<Run, APIError>
) {
  return useQuery<Run, APIError>(
    ["useSlimRun", runId],
    () => getSlimRun(boardId, runId),
    options
  );
}
