import {Divider, Stack} from "@mui/material";

import {useHistory} from "react-router-dom";
import {useMutation} from "react-query";
import {useSnackbar} from "notistack";
import Header, {HeaderActions, HeaderContent} from "../../components/Header";
import StatusBadge from "../../components/StatusBadge";
import useFlow from "../../services/Flows/useFlow";
import FlowHeaderBreadcrumbs from "./FlowBreadcrumbs";
import {BackButton} from "../../components/BackButton";
import {SyncToWH} from "./SyncToDWHButton";
import {CircuitBoard} from "../../types";
import Actions from "../Circuitboard/Actions";
import RunStatus from "../Run/RunV2/RunHeader/RunStatus";
import {Status} from "../../enums";
import {updateFlow} from "../../services/Flows/api";
import {FC} from "react"

export const FlowHeaderStatus: FC<{
  block?: CircuitBoard;
  flowStatus?: Status;
}> = ({block, flowStatus}) => {
  if (!block) {
    return <StatusBadge status={flowStatus}/>;
  }
  return block.last_run ? <RunStatus run={block.last_run} small/> : null;
};

export const FlowHeader: FC<{
  flowId: string;
  block?: CircuitBoard;
}> = ({ block, flowId }) => {
  const history = useHistory();
  const { data: flow } = useFlow(flowId);
  const { enqueueSnackbar } = useSnackbar();
  const updateFlowMutation = useMutation((newName: string) =>
    updateFlow(flowId, { displayName: newName })
  );

  const breadcrumbs = [
    { title: "Flows", url: "/flows", active: false },
    {
      title: flow?.display_name || "",
      url: `/flows/${flowId}`,
      active: false,
      onSubmit: (newName: any) =>
        newName !== flow?.display_name &&
        updateFlowMutation
          .mutateAsync(newName)
          .then(() => {
            enqueueSnackbar("Flow name update successfully", {
              variant: "success",
            });
          })
          .catch(() => {
            enqueueSnackbar("Failed to update flow name.", {
              variant: "error",
            });
          }),
    },
  ];
  if (block) {
    breadcrumbs.push({
      title: block.displayName,
      url: `/boards/${block.identifier}`,
      active: false,
    });
  }
  return (
    <Header>
      <HeaderContent>
        <Stack direction="row" spacing={1}>
          <BackButton
            onBack={() => history.push(`/flows${block ? `/${flowId}` : ""}`)}
          />
          <Stack direction="column" spacing={1}>
            <FlowHeaderBreadcrumbs
              sx={{ minHeight: (t) => t.spacing(4) }}
              justifyContent="center"
              breadcrumbs={breadcrumbs}
            />
            <Stack direction="row" spacing={1} alignContent="center">
              <FlowHeaderStatus flowStatus={flow?.status} block={block} />
              {flow && (
                <>
                  <Divider orientation="vertical" />
                  <SyncToWH flow={flow} />
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </HeaderContent>
      {block && (
        <HeaderActions>
          <Actions />
        </HeaderActions>
      )}
    </Header>
  );
};

export default FlowHeader;
