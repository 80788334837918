import {useAuthUser} from "@frontegg/react";
import {Box, Grid, useMediaQuery, useTheme} from "@mui/material";
import {useHistory} from "react-router-dom";
import _ from "lodash";

import IllustrationPlaceholder from "../../components/IllustrationPlaceholder";
import {VisibilityType} from "../../enums";
import {useIsTenantNew, useSearchParams} from "../../hooks";
import useLocalStorage from "../../hooks/useLocalStorage";
import {parseStringParams} from "../../hooks/useSearchParams";
import {useBoards, useInstallableBoards} from "../../services/boards";
import {CircuitBoard} from "../../types";
import {filterBoardsByCustomFilters} from "./BoardFilters/CustomFilters";
import {filterBoardsByVisibility, toVisibilityType,} from "./BoardFilters/VisibilityFilters";
import BoardsTable from "./BoardsTable/BoardsTable";
import Navbar from "./Navbar";
import {Filters} from "./types";
import {FC, useEffect} from "react"

const filterBoardByText = (
    text: string | null,
    boards: CircuitBoard[] = []
): CircuitBoard[] =>
    boards.filter((board) => {
      if (text) {
        return (
            board.displayName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            board.library.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            (board.tags || []).find(
                (tag) => tag.toLowerCase().indexOf(text.toLowerCase()) > -1
            )
        );
      }
      return [];
    });

export function DataNotFound() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <IllustrationPlaceholder
        text="No blocks has been found."
        actions={'Check your filters or click "New block" to add a new block.'}
        imageHeight={300}
        imageWidth={300}
      />
    </Grid>
  );
}

export const excludeNulls = (o: object) =>
  Object.fromEntries(Object.entries(o).filter(([_, v]) => !!v));

const pageStep = 20;
const defaultVisibilities = Object.values(VisibilityType).slice(0, 2);

const Circuitboards: FC = () => {
  const platformTheme = useTheme();
  const user = useAuthUser();
  const history = useHistory();
  const { isTenantNew } = useIsTenantNew();
  const matches = useMediaQuery(platformTheme.breakpoints.up("sm"));
  const [cachedData, setCachedData] = useLocalStorage<Record<string, any>>(
    "boards.overview.table.filters",
    {
      visibilities: defaultVisibilities,
    }
  );
  const { search: searchParams, setSearchParams } = useSearchParams();
  const getDefaultState = () => {
    const nextState =
      !searchParams ||
      !Object.keys(searchParams).find((k) => k === "visibilities")
        ? cachedData
        : searchParams;
    const currentVisibilities = toVisibilityType(nextState?.visibilities || []);
    if (currentVisibilities.length === 0) {
      return { ...nextState, visibilities: defaultVisibilities };
    }
    return nextState;
  };
  const search = getDefaultState();
  const setData = (data: object) => {
    setSearchParams(data);
    setCachedData(data);
  };
  const scrollToPosition = () => {
    if (search?.scrollY || cachedData.scrollY) {
      const scrollY =
        ((search?.scrollY || cachedData?.scrollY)?.toString() as any) - 0;
      setTimeout(() => {
        window.scrollTo({ top: scrollY });
      }, 0);
    }
  };

  const handler = () => {
    const data = {
      ...search,
      ...Object.fromEntries(
        [...new URLSearchParams(window.location.search).entries()]
          .filter((e) => e[0] !== "tenantId")
          .map(([k, v]) => [k, parseStringParams(v)])
      ),
      scrollY: window.scrollY,
    };
    setData(data);
  };
  useEffect(() => {
    setSearchParams(search);
  }, []);
  useEffect(() => {
    const debouncedHandler = _.debounce(handler, 500, { maxWait: 500 });
    window.addEventListener("scroll", debouncedHandler);
    return () => {
      window.removeEventListener("scroll", debouncedHandler);
      debouncedHandler.cancel();
    };
  }, [search]);

  const {
    data: boards = [],
    isLoading,
    error,
  } = useBoards(undefined, { keepPreviousData: true });

  useEffect(() => {
    if (!isLoading && boards.length > 0) {
      return scrollToPosition();
    }
  }, [boards, isLoading]);

  const { data: boardsTemplates = [] } = useInstallableBoards({
    enabled: boards && boards.length > 0,
  });
  const textFiltered = filterBoardByText(
    (search?.text || "").toString(),
    boards
  );
  const visibilityFiltered = textFiltered.filter(
    filterBoardsByVisibility(user)({ search: search as Filters })
  );
  const filteredByType = visibilityFiltered.filter((b) =>
    search?.type ? search?.type === b.template_id : true
  );
  const libraryFiltered = filteredByType.filter((b) =>
    search?.library ? search?.library === b.library : true
  );
  const customFiltered = libraryFiltered.filter(
    filterBoardsByCustomFilters({
      search: search as Filters,
      currentUserId: user.id,
    })
  );

  const withLatestVersion = customFiltered.map((b) => ({
    ...b,
    isLatest:
      boardsTemplates.find((bt) => bt.id === b.template_id)?.latest_version ===
      b.version,
  }));

  useEffect(() => {
    if (isTenantNew) history.push("/marketplace");
  }, [isTenantNew, history]);

  return (
    <Box sx={{ pr: 4, pl: 4, display: "flex" }}>
      {matches && (
        <Navbar
          sx={{ mr: 3 }}
          filters={search}
          onFiltersChange={setData}
          onFilterReset={() => setData({ visibilities: defaultVisibilities })}
        />
      )}
      <Box flex={1} sx={{ ml: 3, paddingTop: 40 / 8 }}>
        <BoardsTable
            pageSize={(search?.pageSize || cachedData?.pageSize || pageStep) - 0}
            pageStep={pageStep}
            onFiltersChange={(filters: any) => {
              setData({...search, ...filters});
            }}
            data={withLatestVersion}
            isLoading={isLoading}
            error={error?.message ? new Error(error.message) : null}
        />
      </Box>
    </Box>
  );
};
export default Circuitboards;
