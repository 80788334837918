import { useQuery, UseQueryOptions } from "react-query";
import { Space } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

export async function getSpaces(params?: object): Promise<Space[]> {
  return await apiRequest<Space[]>("v1/spaces", "GET", params);
}

export default function useSpaces(
  params?: object,
  options?: UseQueryOptions<Space[], APIError>
) {
  return useQuery<Space[], APIError>(
    ["useSpaces", params],
    () => getSpaces(params),
    { ...options, staleTime: Infinity }
  );
}
