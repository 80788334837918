import {Box, Checkbox, FormControlLabel, FormHelperText} from "@mui/material";

import {ParameterBooleanFieldProps} from "./types";
import {FC} from "react"

const ParameterBooleanField: FC<ParameterBooleanFieldProps> = (props) => (
    <>
        <FormControlLabel
            control={
                <Checkbox
                    checked={props?.value?.toLowerCase() === "true"}
                    onChange={(_, value) => props.onChange(value ? "True" : "False")}
                    name={props.name}
                    color="primary"
        />
      }
      label={props.label}
    />
    {props.helperText && (
      <Box marginLeft={2} marginRight={5}>
        <FormHelperText sx={{ marginTop: 0 }}>
          {props.helperText}
        </FormHelperText>
      </Box>
    )}
  </>
);
export default ParameterBooleanField;
