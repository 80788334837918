import CreateIcon from "@mui/icons-material/Create";
import { Box, Stack, Typography } from "@mui/material";
import dayjs from "../../utils";

import {
  DataSource,
  DataSourceGatherStatus,
  dataSources,
} from "../../types/datasources";
import { UserAvatar } from "../Circuitboards/BoardsTable/UserCellComponent";
import { FC } from "react";

export const DataSourceDetails: FC<
  DataSource & {
    onClick?: (ds: DataSource) => void;
    status?: Partial<DataSourceGatherStatus>;
  }
> = ({ onClick, ...ds }) => {
  const IconComp = dataSources[ds.type].BoxedIcon;

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={() => onClick && onClick(ds)}
      sx={{
        cursor: onClick ? "pointer" : "",
        ".edit": {
          transition: "opacity 0.2s ease",
          opacity: 0,
        },
        ":hover": {
          ".edit": {
            opacity: 1,
          },
        },
      }}
    >
      <IconComp sx={{ mr: 3 }} />
      <Stack spacing={1} direction="column">
        <Typography variant="h4_v2" color="primary.secondary">
          {ds.name || ds.id}{" "}
          <CreateIcon
            fontSize="small"
            sx={{ color: (t) => t.palette.grey[300], ml: 1 }}
          />
        </Typography>
        <Typography variant="body1" color="text.read">
          {ds.description && "• "}
          {ds.description}
        </Typography>
      </Stack>
    </Box>
  );
};

export const DataSourceCard: FC<DataSource> = (ds) => (
  <Box
    display="flex"
    pt={2}
    pb={2}
    pl={3}
    pr={3}
    key={ds.id}
    sx={{
      height: (t) => t.spacing(11),
      borderRadius: 2,
      border: (t) => `1px solid ${t.palette.borders.default}`,
    }}
  >
    <Box display="flex" justifyContent="space-between" flex={1}>
      <DataSourceDetails {...ds} />
      <Stack
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <UserAvatar user={ds?.created_by} />
        <Typography variant="caption" color="text.read">
          {dayjs.utc(ds.updated_at).format("MMMM D, YYYY")}
        </Typography>
      </Stack>
    </Box>
  </Box>
);
