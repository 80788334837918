import {Box, Container, Typography} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
  createStyles({
    success: {
      color: theme.palette.success.main,
    },
  })
);

const InputSetupCompleteContainer: FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="75vh"
      >
        <img
          alt="input-setup-complete"
          src={`/background-pipeline-data-sources.png`}
        />
        <Box m={1}>
          <Typography variant="h4" display="inline">
            Your data is successfully{" "}
          </Typography>
          <Typography variant="h4" display="inline" className={classes.success}>
            connected
          </Typography>
          <Typography variant="h4" display="inline">
            !
          </Typography>
        </Box>
        <Typography variant="body1">
          You’re almost done, customize your settings and start your run
        </Typography>
      </Box>
    </Container>
  );
};

export default InputSetupCompleteContainer;
