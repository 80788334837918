import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Icon } from "../../../../components/Icon";

const Feed = () => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="body1" fontWeight="600" marginTop="20px">
        New insights will be shown here
      </Typography>
      <Box
        width="500px"
        height="200px"
        marginTop="32px"
        paddingX="24px"
        paddingY="20px"
        sx={{
          background: theme.palette.backgrounds.white,
          borderRadius: "8px",
        }}
      >
        <Typography variant="body1" fontWeight="600">
          How insights work?
        </Typography>
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <li>
            <Stack direction="row" alignItems="center" gap="12px">
              <Stack
                width="32px"
                height="32px"
                sx={{
                  background: theme.palette.backgrounds.active,
                  borderRadius: "4px",
                  color: theme.palette.text.link,
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Icon src="insights/lamp-fill.svg" width="16px" height="16px" />
              </Stack>
              <Typography variant="body2">
                Explore the insights in your feed
              </Typography>
            </Stack>
          </li>
          <li>
            <Stack direction="row" alignItems="center" gap="12px">
              <Stack
                width="32px"
                height="32px"
                sx={{
                  background: theme.palette.backgrounds.active,
                  borderRadius: "4px",
                  color: theme.palette.text.link,
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Icon src="insights/my-items.svg" width="16px" height="16px" />
              </Stack>
              <Typography variant="body2">
                Manage insights by saving the ones you want to act on
              </Typography>
            </Stack>
          </li>
          <li>
            <Stack direction="row" alignItems="center" gap="12px">
              <Stack
                width="32px"
                height="32px"
                sx={{
                  background: theme.palette.backgrounds.active,
                  borderRadius: "4px",
                  color: theme.palette.text.link,
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Icon
                  src="insights/check-fill.svg"
                  width="16px"
                  height="16px"
                />
              </Stack>
              <Typography variant="body2">
                Mark insights as Done to keep your workspace clear
              </Typography>
            </Stack>
          </li>
        </ul>
      </Box>
    </>
  );
};

export default Feed;
