import { ReactNode, useMemo } from "react";
import { Box, Stack, Chip } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { SxProps } from "@mui/system";
import theme from "../../themes/light";
import { Icon } from "../../components/Icon";

const tabs = [
  {
    url: "sales-assistant/products",
    icon: "insights/home-fill.svg",
    label: "Products",
  },
  {
    url: "sales-assistant/alerts",
    icon: "sales/alert.svg",
    label: "Alerts",
  },
  {
    url: "sales-assistant/dismissed",
    icon: "",
    label: "Dismissed",
  },
];

const trackingTabs = [
  {
    url: "/tracking",
    icon: "sales/tracking-fill.svg",
    label: "Tracking",
  },
  {
    url: "/tracking/dismissed",
    icon: "",
    label: "Dismissed",
  },
];

const BoxProps = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "8px",
  sx: {
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    borderBottom: "2px solid white",
  },
};
const ActiveProps = {
  color: theme.palette.text.link!, // fill: theme.palette.text.link!,
  borderBottom: `3px solid ${theme.palette.text.link!}`,
  "& svg": {
    "& path": {
      fill: theme.palette.text.link!,
    },
  },
};
const ActivePropsAlert = {
  color: theme.palette.error.main, // fill: theme.palette.text.link!,
  borderBottom: `3px solid ${theme.palette.error.main}`,
  "& svg": {
    "& path": {
      fill: theme.palette.error.main,
    },
  },
  "& .MuiChip-filled": {
    backgroundColor: "#C70E241A",
    color: theme.palette.error.main,
    opacity: 1,
  },
};
const Tab = ({
  url,
  icon,
  label,
  additionalSx,
  chip,
}: {
  url: string;
  icon?: string;
  label: string;
  additionalSx?: SxProps;
  chip?: ReactNode;
}) => {
  const history = useHistory();
  const location = useLocation();
  // const enableAlert = useSplitEnabled(Split.SALES_ASSISTANT_ALERTS);

  const sx = useMemo<SxProps>(() => {
    const currentPath = location.pathname.replace(/\/$/, "");
    const active = currentPath === url;
    let sx: SxProps = { ...BoxProps.sx };
    if (additionalSx) {
      sx = { ...sx, ...additionalSx };
    }
    if (active && currentPath.includes("/alerts")) {
      sx = { ...sx, ...ActivePropsAlert };
    } else if (active) {
      sx = { ...sx, ...ActiveProps };
    }
    return sx;
  }, [location.pathname, url, additionalSx]);
  return (
    <>
      <Box
        onClick={() => history.push(url)}
        {...BoxProps}
        sx={{
          ...sx,
          position: label === "Dismissed" ? "absolute" : "initial",
          right: 0,
          alignItems: "center",
          gap: "8px",
          display:
            label === "Dismissed" && location.pathname.includes("/products")
              ? "none"
              : "flex",
        }}
        minHeight="42px"
      >
        {icon && <Icon src={icon} width="16px" height="16px" />} {label}
        {chip}
      </Box>
    </>
  );
};
const HeaderTabs = ({
  newItems,
  myItems,
  isLoading,
}: {
  newItems?: number;
  myItems?: number;
  isLoading?: boolean;
}) => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname.search("tracking") !== -1 ? (
        <>
          <Stack
            direction="row"
            alignItems="center"
            gap="24px"
            marginTop="8px"
            justifyContent="space-between"
            position="relative"
          >
            {trackingTabs.map((tab) => {
              let myChip = null;
              if (tab.label === "Products" && !isLoading && !!newItems) {
                myChip = (
                  <Chip
                    label={`${newItems} New`}
                    size="small"
                    color="secondary"
                    sx={{
                      borderRadius: "500px",
                      padding: "4px",
                      background: theme.palette.backgrounds.active,
                      fontSize: "13px",
                    }}
                  />
                );
              } else if (tab.label === "Alerts" && !isLoading) {
                myChip = (
                  <Chip
                    label={myItems}
                    size="small"
                    sx={{
                      borderRadius: "500px",
                      padding: "4px",
                      background: theme.palette.backgrounds.secondary,
                      fontSize: "13px",
                    }}
                  />
                );
              }
              return <Tab key={tab.url} {...tab} chip={myChip} />;
            })}
          </Stack>
        </>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          gap="24px"
          marginTop="8px"
          position="relative"
        >
          {tabs.map((tab) => {
            const myChip = null;
            return <Tab key={tab.url} {...tab} chip={myChip} />;
          })}
        </Stack>
      )}
    </>
  );
};

export default HeaderTabs;
