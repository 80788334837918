import {Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import {CollapsableBox} from "../../../components/CollapsableBox";
import useDataSourcesProductsCount from "../../../services/datasources/useDataSourceProductsCount";
import useDataSources from "../../../services/datasources/useDataSources";
import {FC} from "react"

export const FilterDataSources: FC<{
    onChange: (props: { dsId: string; checked: boolean }) => void;
    selectedDsIds?: string[];
    onAll: (checked: boolean) => void;
}> = ({selectedDsIds, onChange, onAll}) => {
    const {data: dsList} = useDataSources();
  const { data = [] } = useDataSourcesProductsCount({ enabled: !!dsList });
  return (
    <CollapsableBox label="Data source">
      <FormGroup
        onChange={(e: any) =>
          e.target.value !== "ALL" &&
          onChange({
            dsId: e.target?.value,
            checked: e.target?.checked,
          })
        }
        sx={{ ml: 1 }}
      >
        {data.length > 1 && (
          <FormControlLabel
            label="Select all"
            control={
              <Checkbox
                size="small"
                value="ALL"
                checked={selectedDsIds?.length === data.length}
                indeterminate={
                  selectedDsIds &&
                  selectedDsIds.length !== 0 &&
                  selectedDsIds.length < data.length
                }
                onChange={(e) => onAll(e.target.checked)}
              />
            }
          />
        )}
        <Stack ml={1}>
          {data.map(([count, dsId]) => (
            <FormControlLabel
              key={dsId}
              control={
                <Checkbox
                  size="small"
                  checked={!!selectedDsIds?.find((sDsId) => sDsId === dsId)}
                />
              }
              label={`${dsList?.find((ds) => ds.id === dsId)?.name} (${count})`}
              value={dsId}
            />
          ))}
        </Stack>
      </FormGroup>
    </CollapsableBox>
  );
};
