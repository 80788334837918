import {Box, ClickAwayListener, Divider, Drawer, Tab, Tabs, ThemeProvider,} from "@mui/material";
import {useHistory, useRouteMatch} from "react-router-dom";
import {PageHeader} from "../../components";
import themev2 from "../../themev2";
import AmazonProducts, {AddProductsButton} from "./Amazon/AmazonProducts";
import AddProductsDrawer from "./Amazon/AddProductsDrawer";
import ShopifyProductsTable from "./Shopify/ShopifyProductsTable";
import HarmonizedProductsTable from "./Harmonization/HarmonizedProductsTable";
import {useSplitEnabled} from "../../hooks";
import {Split} from "../../FeatureFlags/enums";
import {FC, useEffect, useState} from "react"

const tabToData: Record<
    "amazon" | "shopify" | "harmonization",
    {
        label: string;
        OpenDrawerComponent?: FC<{ onClick: () => void }>;
        Component: FC<object>;
        featureToggleEnabled?: () => boolean;
        DrawerComponent?: FC<{ onClose: () => void }>;
    }
> = {
  amazon: {
    label: "Amazon",
    OpenDrawerComponent: AddProductsButton,
    Component: AmazonProducts,
    DrawerComponent: AddProductsDrawer,
  },
  shopify: {
    label: "Shopify",
    Component: ShopifyProductsTable,
    featureToggleEnabled: () => useSplitEnabled(Split.SHOPIFY) || false,
  },
  harmonization: {
    label: "Harmonization",
    Component: HarmonizedProductsTable,
    featureToggleEnabled: () => useSplitEnabled(Split.HARMONIZATION) || false,
  },
};

export const Products: FC<object> = () => {
  const history = useHistory();
  const match = useRouteMatch<{ type?: "amazon" | "shopify" }>({
    path: "/data/:type",
    exact: false,
  });
  const a = match?.params.type
    ? tabToData[match?.params.type] || tabToData.amazon
    : tabToData.amazon;
  const [drawerState, setDrawerState] = useState<{
    open: boolean;
  }>({ open: false });

  useEffect(() => {
    const handler = (event: any) => {
      if (event.key === "Escape") {
        setDrawerState({ open: false });
      }
    };
    window.addEventListener("keyup", handler);
    return () => {
      window.removeEventListener("keyup", handler);
    };
  });

  return (
    <ThemeProvider theme={themev2}>
      <PageHeader
        headerLabel="Data"
        actions={
          a.OpenDrawerComponent && (
            <a.OpenDrawerComponent
              onClick={() => setDrawerState({ open: true })}
            />
          )
        }
      />
      <Tabs
        orientation="horizontal"
        textColor="primary"
        indicatorColor="primary"
        value={a.label}
        onChange={(_, value) => {
          if (match?.params?.type) {
            history.replace(value.toLowerCase());
          } else {
            history.replace(`/data/${value.toLowerCase()}`);
          }
        }}
      >
        {Object.values(tabToData).map((v) =>
          (v.featureToggleEnabled ? v.featureToggleEnabled() : true) ? (
            <Tab label={v.label} value={v.label} />
          ) : (
            <></>
          )
        )}
      </Tabs>
      <Divider />
      <Drawer anchor="right" open={drawerState.open}>
        {!a.DrawerComponent ? (
          <></>
        ) : (
          <ClickAwayListener
            onClickAway={() => {
              setDrawerState({ open: false });
            }}
          >
            <Box>
              <a.DrawerComponent
                onClose={() => setDrawerState({ open: false })}
              />
            </Box>
          </ClickAwayListener>
        )}
      </Drawer>

      {a?.Component ? <a.Component /> : null}
    </ThemeProvider>
  );
};

export default Products;
