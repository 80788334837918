import {Box, Card, FormControl, InputLabel, Link, SelectChangeEvent, Skeleton, Typography,} from "@mui/material";

import SchemaForm from "../../../../components/SchemaForm";
import {RunInputValue} from "../../../../types";
import {Connector, ConnectorOption} from "../../../../types/connectorsV2";
import ConnectorOptionSelect from "./ConnectorOptionSelect";
import ConnectorSelect from "./ConnectorSelect";
import {FC} from "react"

type ConfigureConnectionCardProps = {
  inputValue: RunInputValue;
  setInputValue: (runInputValue: RunInputValue) => void;
  resetInputValue: () => void;
  connectorOptions: ConnectorOption[];
  connectors: Connector[];
  onOpenNewConnectionForm: () => void;
  onChange: (event: SelectChangeEvent) => void;
};

const ConfigureConnectionCard: FC<ConfigureConnectionCardProps> = ({
  connectorOptions,
  setInputValue,
  resetInputValue,
  inputValue,
  connectors,
  onChange,
  onOpenNewConnectionForm,
}) => (
  <Card elevation={0} variant="elevation">
    <Box marginY={2}>
      {connectorOptions ? (
        <ConnectorOptionSelect
          connectorOptions={connectorOptions}
          inputValue={inputValue}
          onChange={onChange}
        />
      ) : (
        <Skeleton variant="text" />
      )}
    </Box>

    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-connector">
        Connection
        {connectors && connectors.length === 0 && " - None Exist"}
      </InputLabel>
      <ConnectorSelect
        inputValue={inputValue}
        setInputValue={setInputValue}
        connectors={connectors}
      />
    </FormControl>
    <Typography>
      <Link underline="hover" href="#" onClick={onOpenNewConnectionForm}>
        Create New Connection
      </Link>
    </Typography>
    {connectorOptions && (
      <SchemaForm
        schema={
          connectorOptions
            ? connectorOptions.find(
                (option) => option.type === inputValue.type
              )!.input_schema
            : {}
        }
        defaultValues={inputValue?.configuration || undefined}
        onSubmit={(formValues) =>
          setInputValue({
            ...inputValue,
            configuration: formValues,
            mapping: {},
          })
        }
        onBack={() => resetInputValue()}
        submitLabel="Continue To Mapping"
        submitDisabled={!inputValue.connector_id}
        actionButtonProps={{ variant: "contained" }}
      />
    )}
  </Card>
);

export default ConfigureConnectionCard;
