import { Box, Typography } from "@mui/material";
import theme from "../../../../themes/light";
import { FC } from "react";
import ProductImage from "./ProductImage";

interface ProductDetailsProps {
  asin: string;
  image?: string;
}

const ProductDetails: FC<ProductDetailsProps> = ({ asin, image }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        // maxWidth: "150px",
      }}
    >
      <ProductImage url={image} />
      <Typography
        variant="body1"
        sx={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px",
          fontWeight: 400,
          "&:hover": {
            textDecoration: "underline",
            textDecorationColor: theme.palette.primary.main,
          },
        }}
      >
        <a
          href={`https://amazon.com/dp/${asin}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: theme.palette.primary.main, textDecoration: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          {asin}
        </a>
      </Typography>
    </Box>
  );
};

export default ProductDetails;
