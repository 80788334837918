import {
  Box,
  ClickAwayListener,
  IconButton,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { RowData } from "../../types";
import { useHistory, useLocation } from "react-router-dom";
import SpaceImage from "./SpaceImage";
import SpaceInfo from "./SpaceInfo";
import NumberSection from "../NumberSection";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import theme from "../../../../themes/light";

const SpaceRowSkeleton = () => (
  <>
    <Box
      sx={{
        width: "250px",
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Skeleton
        variant="text"
        sx={{
          borderRadius: "8px",
          height: "80px",
          width: "80px",
          transform: "unset",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "150px",
        }}
      >
        <Skeleton
          variant="text"
          sx={{
            borderRadius: "8px",
            height: "20px",
            width: "150px",
            transform: "unset",
          }}
        />
        <Skeleton
          variant="text"
          sx={{
            borderRadius: "8px",
            height: "20px",
            width: "150px",
            transform: "unset",
          }}
        />
      </Box>
    </Box>
    {Array.from({ length: 4 }).map((_, index) => (
      <Box
        key={`skeleton-space-row-${index}`}
        sx={{
          width: "150px",
        }}
      >
        <Skeleton
          variant="text"
          sx={{
            borderRadius: "8px",
            height: "20px",
            width: "120px",
            transform: "unset",
          }}
        />
      </Box>
    ))}
    <Box
      sx={{
        width: "50px",
      }}
    ></Box>
  </>
);

const SpaceRow = ({ data }: { data?: RowData }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [showSpaceMenu, setShowSpaceMenu] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        padding: "20px 30px 25px",
        cursor: data ? "pointer" : "default",
        background: "#fff",
        borderRadius: "16px",
        border: "1px solid #E9E7F1",
        minHeight: "120px",
        width: "1200px",
        marginBottom: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
      }}
      onClick={() => {
        if (data) history.push(`${pathname}?space=${data?.space_id}`);
      }}
    >
      {data ? (
        <>
          <Box
            className="space-name-and-image"
            sx={{
              display: "flex",
              direction: "row",
              gap: "20px",
              alignItems: "center",
              textOverflow: "ellipsis",
              width: "250px",
            }}
          >
            <SpaceImage url={data?.image_url} />
            <SpaceInfo data={data} />
          </Box>
          <NumberSection
            unit="$"
            number={data?.revenue}
            percentage={data?.revenue_percentage}
            change={data?.revenue_change}
            sx={{
              width: "150px",
            }}
          />
          <NumberSection
            number={data?.units_sold}
            percentage={data?.units_sold_percentage}
            change={data?.units_sold_change}
            sx={{
              width: "150px",
            }}
          />
          <NumberSection
            unit="%"
            number={data?.conversion}
            percentage={data?.conversion_percentage}
            change={data?.conversion_change}
            sx={{
              width: "150px",
            }}
          />
          <NumberSection
            number={data?.glance_views}
            percentage={data?.glance_views_percentage}
            change={data?.glance_views_change}
            sx={{
              width: "150px",
            }}
          />
          <Box
            sx={{
              width: "50px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
              position: "relative",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowSpaceMenu(!showSpaceMenu);
            }}
          >
            <IconButton
              sx={{
                background: "none",
                "&:hover": {
                  color: theme.palette.primary.main,
                  background: theme.palette.primary.light,
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <ClickAwayListener onClickAway={() => setShowSpaceMenu(false)}>
              <Box
                sx={{
                  display: showSpaceMenu ? "block" : "none",
                  background: "#fff",
                  borderRadius: "4px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                  padding: "10px",
                  position: "absolute",
                  width: "180px",
                  top: "30px",
                  right: "30px",
                  zIndex: 999,
                }}
              >
                <MenuItem
                  onClick={() => {
                    history.push(`spaces/${data?.space_id}`);
                  }}
                >
                  Edit Space
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    history.push(`${pathname}?space=${data?.space_id}`)
                  }
                >
                  Show Products
                </MenuItem>
              </Box>
            </ClickAwayListener>
          </Box>
        </>
      ) : (
        <SpaceRowSkeleton />
      )}
    </Box>
  );
};

export default SpaceRow;
