import { useState, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
// @ts-ignore
import * as Emoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "./RichTextEditorV2.css";
import { Box, Button } from "@mui/material";
import LoadingSpinner from "../LoadingSpinner";

Quill.register("modules/emoji", Emoji);

interface RichTextEditorV2Props {
  onClose: () => void;
  onSave: (html: string) => void;
  initialHtml?: string;
  isPending: boolean;
  setIsFocused: (isFocused: boolean) => void;
}

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ header: [1, 2, 3, false] }],
  [
    { align: "justify" },
    { align: "" },
    { align: "center" },
    { align: "right" },
    { direction: "rtl" },
  ],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link", "image"],
  ["emoji"],
  ["clean"],
];

const RichTextEditorV2 = (props: RichTextEditorV2Props) => {
  const { onClose, onSave, initialHtml, isPending, setIsFocused } = props;
  const editorRef = useRef<ReactQuill | null>(null);
  const [content, setContent] = useState<string>(initialHtml || "");

  const modules = {
    toolbar: {
      container: toolbarOptions,
    },
    "emoji-toolbar": true,
    "emoji-shortname": true,
    "emoji-textarea": false,
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, []);

  const getRawText = (html: string = "") => {
    const matches = html.match(/<[^>]*>([^<]+)<\/[^>]*>/g);
    const textContent = matches
      ? matches.map((match) => match.replace(/<\/?[^>]+(>|$)/g, "")).join("")
      : "";
    return textContent;
  };

  return (
    <Box>
      <ReactQuill
        ref={editorRef}
        theme="snow"
        placeholder="Write something..."
        value={content}
        onChange={setContent}
        modules={modules}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={content === initialHtml || !getRawText(content)}
          onClick={() => {
            if (!isPending) onSave(content);
          }}
          sx={{
            minWidth: "80px",
          }}
        >
          {isPending ? <LoadingSpinner color={"white"} size={20} /> : "Save"}
        </Button>
      </Box>
    </Box>
  );
};

export default RichTextEditorV2;
