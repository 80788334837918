import {AutocompleteRenderInputParams, TextFieldProps} from "@mui/material";

import {Search} from "../../components/SearchInput/Search";
import {FC} from "react"

export const AutoCompleteRenderInput: FC<{
  params: Partial<AutocompleteRenderInputParams & TextFieldProps>;
  inputProps?: { value: string; onChange: TextFieldProps["onChange"] };
  onKeyDown?: TextFieldProps["onKeyDown"];
}> = ({
        params, onKeyDown = () => {
  }, inputProps = {}
      }) => {
  const {InputProps: InputPropsFull, ...rest} = params;

  const {endAdornment, startAdornment, className, ...InputProps} =
  InputPropsFull || {};
  const inputPropsMerged = {
    ...(InputProps || {}),
    ...inputProps,
    sx: {
      height: "32px",
      minHeight: 0,
      input: {
        padding: " 0 8px !important",
      },
    },
  };
  return (
    <Search onKeyDown={onKeyDown} InputProps={inputPropsMerged} {...rest} />
  );
};
