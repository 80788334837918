import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSortDirection,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import {alpha, Button, Card, CardHeader, CircularProgress, Typography,} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import clsx from "clsx";
import {useParams} from "react-router-dom";
import {DeleteFileRequest, UploadFileResponse} from "../../../../types";
import {deleteFile, getUploadedFiles} from "../../../../services/data";
import {DownloadArtifactType, OutputFormat} from "../../../../enums";
import DownloadButton from "../../../../components/DownloadButton";
import {formatDateString} from "../../../../utils";
import {useConfirmation} from "../../../../components/ConfirmationDialog";
import {FC, MouseEvent} from "react"

const useStyles = makeStyles((theme) =>
    createStyles({
      card: {
        marginTop: theme.spacing(4),
        width: "100%",
      },
      root: {
        "& .tableCell.greyedOut": {
          backgroundColor: theme.palette.grey[200],
        },
      },
      dataGrid: {
        border: "0px",
      },
      schemaSection: {
        height: "100%",
        backgroundColor: alpha(theme.palette.divider, 0.01),
      },
    })
);

type UploadedFilesTableProps = {
  inputName: string;
  onSelection: (uploadFile: UploadFileResponse) => void;
};

const UploadedFilesTable: FC<UploadedFilesTableProps> = ({
  inputName,
  onSelection,
}) => {
  const classes = useStyles();
  const { boardId }: { boardId?: any } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation("deleteFile", deleteFile, {
    onError: (error) => {
      throw error;
    },
  });
  const confirm = useConfirmation();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 250,
      align: "center",
      cellClassName: (params) =>
        clsx("tableCell", {
          greyedOut: !params.getValue(params.id, "latest_schema"),
        }),
    },
    {
      field: "latest_schema",
      headerName: "Matches Schema",
      headerAlign: "center",
      width: 160,
      align: "center",
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? "Yes" : "No",
      cellClassName: (params) =>
        clsx("tableCell", {
          greyedOut: !params.value,
        }),
    },
    {
      field: "created_by_email",
      headerName: "Email",
      headerAlign: "center",
      width: 200,
      align: "center",
      cellClassName: (params) =>
        clsx("tableCell", {
          greyedOut: !params.getValue(params.id, "latest_schema"),
        }),
    },
    {
      field: "content_type",
      headerName: "Content Type",
      headerAlign: "center",
      width: 150,
      align: "center",
      cellClassName: (params) =>
        clsx("tableCell", {
          greyedOut: !params.getValue(params.id, "latest_schema"),
        }),
    },
    {
      field: "size",
      headerName: "Size",
      headerAlign: "center",
      type: "number",
      width: 100,
      align: "center",
      cellClassName: (params) =>
        clsx("tableCell", {
          greyedOut: !params.getValue(params.id, "latest_schema"),
        }),
    },
    {
      field: "last_modified",
      headerName: "Last Modified",
      headerAlign: "center",
      width: 300,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatDateString(params.value as Date),
      align: "center",
      cellClassName: (params) =>
        clsx("tableCell", {
          greyedOut: !params.getValue(params.id, "latest_schema"),
        }),
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      width: 200,
      align: "center",
      cellClassName: (params) =>
        clsx("tableCell", {
          greyedOut: !params.getValue(params.id, "latest_schema"),
        }),
      renderCell: (params: GridCellParams) => {
        const filename = params.getValue(params.id, "name") as string;
        const data: DeleteFileRequest = {
          boardId,
          fileName: filename,
          inputName,
        };

        const onClickDelete = async (
          event: MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
          event.preventDefault();
          event.stopPropagation();
          confirm({
            title: "Delete uploaded file?",
            description: `The file ${filename} will be deleted permanently and can not be restored`,
          })
            .then(async () => {
              await mutation.mutateAsync(data);
              await queryClient.refetchQueries([
                "uploadedFiles",
                {
                  circuitboardId: boardId,
                  input_name: inputName,
                },
              ]);
              enqueueSnackbar("File was successfully deleted", {
                variant: "success",
              });
            })
            .catch(() => {
              enqueueSnackbar("Failed to delete file", { variant: "error" });
            });
        };
        const outputFormat =
          filename.split(".").pop() === OutputFormat.CSV
            ? OutputFormat.CSV
            : OutputFormat.PARQUET;
        return (
          <>
            <DownloadButton
              circuitboardId={boardId}
              artifactUrl={`${inputName}/uploads/${
                params.getValue(params.id, "name") as string
              }`}
              outputFormat={outputFormat}
              artifactType={DownloadArtifactType.INPUT}
              icon={false}
              label="DOWNLOAD"
            />
            {mutation.isLoading &&
            mutation?.variables?.fileName === filename ? (
              <CircularProgress size={20} thickness={2.5} />
            ) : (
                // @ts-ignore
              <Button
                variant="text"
                color="primary"
                size="medium"
                onClick={onClickDelete}
              >
                <Typography variant="button" color="error">
                  DELETE
                </Typography>
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const { data, isLoading } = useQuery<UploadFileResponse[], Error>(
    ["uploadedFiles", { circuitboardId: boardId, input_name: inputName }],
    () => getUploadedFiles(boardId, inputName)
  );
  const onRowClick = (params: GridRowParams) => {
    if (params.getValue(params.id, "latest_schema")) {
      onSelection(params.row as UploadFileResponse);
    }
  };
  const sortModel = [
    {
      field: "last_modified",
      sort: "desc" as GridSortDirection,
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader title="Recent uploads" />
      <div style={{ height: 300, width: "100%" }} className={classes.root}>
        <DataGrid
          loading={isLoading}
          sortModel={sortModel}
          rows={data || []}
          columns={columns}
          pageSize={5}
          density="compact"
          autoPageSize
          hideFooterSelectedRowCount
          onRowClick={onRowClick}
          className={classes.dataGrid}
        />
      </div>
    </Card>
  );
};

export default UploadedFilesTable;
