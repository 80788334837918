import CatalogEnrichmentContainer from "./CatalogEnrichmentContainer";
import { CatalogEnrichmentProvider } from "./CatalogEnrichmentProvider";
// import CatalogEnrichmentList from "./CatalogEnrichmentList";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const CatalogEnrichment = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={path} exact component={CatalogEnrichmentList} /> */}
      <Route path={path} exact component={CatalogEnrichmentContainer} />
      {/* <Route path={`${path}/:flowId`} component={CatalogEnrichmentDetail} /> */}
    </Switch>
  );
};

export default function CatalogEnrichmentPage() {
  return (
    <CatalogEnrichmentProvider>
      <CatalogEnrichment />
    </CatalogEnrichmentProvider>
  );
}
