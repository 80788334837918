import {InputMethods} from "../hooks/useNewRunForm";
import Wrapper from "../Wrapper";
import InputConfiguration from "./InputConfiguration";
import InputSetupCompleteContainer from "./InputSetupCompleteContainer";
import Navbar from "./Navbar";
import {FC} from "react"

type InputsSectionProps = {
  inputMethods: InputMethods;
  onCompletion: () => void;
};

const InputsSection: FC<InputsSectionProps> = ({
  inputMethods,
  onCompletion,
}) => {
  const {
    selectedInput,
    setSelectedInput,
    selectedInputValue,
    setSelectedInputValue,
    resetSelectedInput,
    isInputValid,
    isAllInputsValid,
    onInputCompletion,
  } = inputMethods;

  const allInputsValid = isAllInputsValid();

  return (
    <Wrapper
      left={
        <Navbar
          selectedInput={selectedInput}
          setSelectedInput={setSelectedInput}
          isInputValid={isInputValid}
          onNext={onCompletion}
          isNextEnabled={allInputsValid}
        />
      }
      right={
        <>
          {!selectedInput && isAllInputsValid() ? (
            <InputSetupCompleteContainer />
          ) : (
            <InputConfiguration
              input={selectedInput}
              inputValue={selectedInputValue}
              setInputValue={setSelectedInputValue}
              resetInputValue={resetSelectedInput}
              onCompletion={onInputCompletion}
            />
          )}
        </>
      }
    />
  );
};
export default InputsSection;
