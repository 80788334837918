import {
  CircuitBoard,
  DownloadableRunOutput,
  GeneratePresignToken,
  InputDataRequest,
  PublishedRun,
  Run,
  RunCreate,
  UpdateRun,
} from "../../types";
import { DayActivityOverview } from "../../types/runs";
import apiRequest from "../apiRequest";

export function terminateRun(blockId: string, runId: string): Promise<Run[]> {
  return apiRequest<Run[]>(
    `circuitboards/${blockId}/runs/${runId}/terminate`,
    "POST"
  );
}
export function getRuns(
  circuitboardId: string,
  params?: object
): Promise<Run[]> {
  return apiRequest<Run[]>(
    `circuitboards/${circuitboardId}/runs`,
    "GET",
    params
  );
}

// Todo: fix input data
export function createRun(
  circuitboardId: string,
  data: RunCreate
): Promise<Run> {
  return apiRequest<Run>(
    `circuitboards/${circuitboardId}/runs`,
    "POST",
    undefined,
    data
  );
}

export function getRun(circuitboardId: string, runId: string): Promise<Run> {
  return apiRequest<Run>(
    `circuitboards/${circuitboardId}/runs/${runId}`,
    "GET"
  );
}

export function updateRun({
  boardId,
  runId,
  ...rest
}: UpdateRun): Promise<UpdateRun> {
  return apiRequest<UpdateRun>(
    `circuitboards/${boardId}/runs/${runId}`,
    "PUT",
    undefined,
    rest
  );
}

export function getPublishedRun(circuitboardId: string): Promise<PublishedRun> {
  return apiRequest<PublishedRun>(
    `circuitboards/${circuitboardId}/runs/published`,
    "GET"
  );
}

export function publishRun(
  boardId: string,
  runId: string
): Promise<PublishedRun> {
  return apiRequest<PublishedRun>(
    `circuitboards/${boardId}/publish`,
    "POST",
    undefined,
    { run_id: runId }
  );
}

export function unPublish(boardId: string): Promise<CircuitBoard> {
  return apiRequest<CircuitBoard>(`circuitboards/${boardId}/unpublish`, "POST");
}

export function generatePresignedMinioToken(
  data: GeneratePresignToken
): Promise<DownloadableRunOutput> {
  return apiRequest(
    `circuitboards/${data.circuitboardId}/runs/pre-sign`,
    "POST",
    undefined,
    {
      artifact_url: data.artifactUrl,
      output_format: data.outputFormat,
      artifact_type: data.artifactType,
    }
  );
}

export function getRunsOverview(
  circuitboardId: string,
  days: number
): Promise<DayActivityOverview[]> {
  return apiRequest<DayActivityOverview[]>(
    `circuitboards/${circuitboardId}/runs/activity`,
    "GET",
    { days }
  );
}

export function getInputData(
  data: InputDataRequest
): Promise<Record<string, any>> {
  return apiRequest<Record<string, any>>(
    `circuitboards/${data.boardId}/runs/${data.runId}/inputs/${data.inputName}`,
    "GET",
    { limit: data.limit, offset: data.offset }
  );
}

export function addProductToTracked(product_id: string) {
  return apiRequest<Record<string, any>>(
    `sales_assistant/product/${product_id}/track`,
    "PUT"
  );
}
export function removeProductFromTracking(product_id: string) {
  return apiRequest<Record<string, any>>(
    `sales_assistant/product/${product_id}/track`,
    "DELETE"
  );
}

export function addAlertToDismissed(alert_id: string) {
  return apiRequest<any>(`sales_assistant/alert/${alert_id}/dismiss`, "PUT");
}
export function removeAlertFromDismissedTab(alert_id: string) {
  return apiRequest<any>(`sales_assistant/alert/${alert_id}/dismiss`, "DELETE");
}
