import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../apiRequest";

export type DataSourceProductsCount = [number, string];

export function listDataSourcesProductsCount(): Promise<
  DataSourceProductsCount[]
> {
  return apiRequest("v1/onboarding/ecommerce/products/datasources", "GET");
}

const useDataSourcesProductsCount = (
  options?: UseQueryOptions<DataSourceProductsCount[], APIError>
): UseQueryResult<DataSourceProductsCount[], APIError> =>
  useQuery<DataSourceProductsCount[], APIError>(
    ["DataSourceProductCount"],
    () => listDataSourcesProductsCount(),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export default useDataSourcesProductsCount;
