import {Box, Skeleton, Stack, Typography} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import SVG from "react-inlinesvg";
import {useHistory} from "react-router-dom";
import {BackButton} from "../../components/BackButton";
import {useBoard} from "../../services/boards";
import {FC} from "react"

const useStyles = makeStyles(() =>
    createStyles({
      svg: {
        mr: 1.5,
      },
    })
);

const HeaderTitle: FC<{
  blockId: string;
}> = ({ blockId }) => {
  const { data: block } = useBoard(blockId);

  const history = useHistory();
  const {
    displayName: title,
    templateName: boardType,
    image,
    flow,
  } = block || {};
  const classes = useStyles();
  const onBack = () => {
    if (flow?.id) {
      history.push(`/flows/${block!.flow!.id!}/configuration`);
    } else {
      history.replace("/boards");
    }
  };

  return (
    <div>
      <Box order={2} display="flex" alignItems="center" maxHeight={90}>
        {onBack && <BackButton onBack={onBack} />}

        {image && (
          <SVG src={image} width={40} height="auto" className={classes.svg} />
        )}
        {title ? (
          <Stack direction="column" sx={{ ml: 1 }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                mt: 1,
                lineHeight: 1,
              }}
            >
              {title}
            </Typography>
            <Typography sx={{ fontSize: "15px", fontWeight: "200" }}>
              {boardType}
            </Typography>
          </Stack>
        ) : (
          <Skeleton variant="text" width={200} />
        )}
      </Box>
    </div>
  );
};

export default HeaderTitle;
