import { FC, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Skeleton,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { sortBy } from "lodash";
import { useRouteMatch } from "react-router";
import { useDashboards } from "../api";
import theme from "../../../themev2";
import IllustrationPlaceholder from "../../../components/IllustrationPlaceholder";
import {
  Dashboard,
  DashboardFilterImpl,
  DashboardFilters,
  DashboardFiltersImp,
} from "../types";
import { DashboardHeader } from "./DashboardHeader";
import { useQueryParams } from "../../../hooks";
import { DashboardFiltersComponent } from "./DashboardFiltersComponent";

export const extractParamsFromUrl = (
  url: string
): {
  queryParams: string;
  dashParams: string;
  path: string;
} => {
  const [pathWithQParams = "", dashParams = ""] = url.split("#");
  const [path = "", queryParams = ""] = pathWithQParams.split("?");
  return { queryParams, dashParams, path };
};

function extendUrlWithFilters(
  currentDashboardUrl: string,
  currentFilter: DashboardFilters | null
) {
  const { path, dashParams } = extractParamsFromUrl(currentDashboardUrl);
  return `${path}?${currentFilter?.toQueryParams()}#${dashParams}`;
}

const DashboardComponent: FC = () => {
  const queryParams = useQueryParams();
  const custom = queryParams.get("custom") === "true";
  const match = useRouteMatch<{ dashboardId: string }>(
    "/dashboards/:tabName/:dashboardId"
  );
  const dashboardId = match?.params?.dashboardId;

  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true);

  const [currentFilter, setCurrentFilter] = useState<DashboardFilters | null>(
    () => {
      if (queryParams.has("filters")) {
        const additionalFilters = JSON.parse(
          queryParams.get("filters") || "{}"
        ) as Record<string, string>;
        return DashboardFiltersImp.fromData({
          id: "queryParamsFilter",
          name: "Predefined filters",
          dashboardId: Number(dashboardId),
          filters: Object.entries(additionalFilters).map(([name, value]) =>
            DashboardFilterImpl.fromObject({
              name,
              value,
            })
          ),
        });
      }
      return null;
    }
  );
  const [unsavedFilter, setUnsavedFilter] = useState<DashboardFilters | null>(
    currentFilter
  );
  const [defaultFilter, setDefaultFilter] = useState<DashboardFilters | null>(
    null
  );
  const [url, setUrl] = useState<string>("");

  const iFrameRef = useRef<any>(null);
  const { data = [], isLoading } = useDashboards({
    options: {
      select: (data) =>
        sortBy(
          data.filter((d) => d.isCustom() === custom),
          "tab"
        ),
      refetchOnWindowFocus: false,
    },
  });
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard | null>(
    null
  );

  useEffect(() => {
    if (data.length > 0) {
      const dashboard = data.find((d) => d.id.toString() === dashboardId);
      if (dashboard) {
        setCurrentDashboard(dashboard);
      }
    }
  }, [dashboardId, data]);

  useEffect(() => {
    if (currentDashboard) {
      const defaultDashboardFilter =
        DashboardFiltersImp.fromDashboard(currentDashboard);
      setDefaultFilter(null);
      setCurrentFilter(defaultDashboardFilter);
    }
  }, [currentDashboard]);

  useEffect(() => {
    if (currentFilter) {
      setUrl(extendUrlWithFilters(currentDashboard?.url || "", currentFilter));
    }
    setUnsavedFilter(currentFilter);
    if (!defaultFilter) {
      setDefaultFilter(currentFilter);
    }
  }, [currentFilter]);

  return (
    <ThemeProvider theme={theme}>
      <Stack flex={1}>
        <DashboardHeader
          py={2}
          px={4}
          allDashboards={data || []}
          currentDashboard={currentDashboard || null}
          actions={
            !currentDashboard || !currentFilter || !defaultFilter ? (
              <Skeleton width={150} />
            ) : (
              <DashboardFiltersComponent
                defaultFilter={defaultFilter}
                unsavedFilter={unsavedFilter}
                setUnsavedFilter={setUnsavedFilter}
                currentFilter={currentFilter}
                setCurrentFilter={setCurrentFilter}
                dashboard={currentDashboard}
                setCurrentDashboard={setCurrentDashboard}
              />
            )
          }
        />
        <Divider />
        <Box flex={1}>
          {isIframeLoading && (
            <Stack alignItems="center" flex={1} py={3} justifyContent="center">
              <CircularProgress size={50} />
            </Stack>
          )}
          {currentDashboard && (
            <iframe
              ref={iFrameRef}
              title={currentDashboard.name}
              src={url}
              style={{ border: 0, height: "100vh" }}
              width="100%"
              height="100vh"
              onError={() => setIsIframeLoading(false)}
              onLoad={() => setIsIframeLoading(false)}
            />
          )}
          {data?.length === 0 && !isLoading && (
            <IllustrationPlaceholder
              imageHeight={300}
              imageWidth={300}
              text="No dashboards found."
            />
          )}
        </Box>
      </Stack>
    </ThemeProvider>
  );
};

export default DashboardComponent;
