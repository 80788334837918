import { FC, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";
import { NewRunFormResult } from "../hooks/useNewRunForm";
import ScheduleDateTimePicker from "./ScheduleDateTimePicker";
import { ScheduleEnd } from "../../../types";
import Repeat from "./Repeat";
import ScheduleEndRadioGroup from "./ScheduleEndRadioGroup";
import { useScheduleForm } from "./hooks";

const ScheduleDialog: FC<{
  open: boolean;
  onClose: () => void;
  runForm: NewRunFormResult;
}> = ({ open, onClose, runForm }) => {
  const { boardId }: { boardId?: any } = useParams();
  const { scheduleFormMethods, onSubmit } = useScheduleForm(boardId, runForm);
  const { control, setValue, register } = scheduleFormMethods;

  const setEnd: (value: ScheduleEnd | null) => void = (value) => {
    setValue("end", value);
  };

  useEffect(() => {
    register("end");
  }, [register, control]);

  const setStart: (value: ScheduleEnd | null) => void = (value) => {
    setValue("start_date", value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        sx={{ "& .MuiDialog-paper": { minHeight: 500, maxWidth: 350 } }}
        fullWidth
      >
        <form onSubmit={onSubmit}>
          <DialogTitle>Schedule run</DialogTitle>
          <DialogContent>
            <Stack spacing={4} marginTop={1}>
              <ScheduleDateTimePicker control={control} setStart={setStart} />
              <Repeat control={control} setValue={setValue} />
              <ScheduleEndRadioGroup setEnd={setEnd} />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: 1 }}>
            <Button type="button" onClick={onClose} color="inherit">
              Cancel
            </Button>
            <Button type="submit" variant="contained" onClick={onSubmit}>
              Done
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ScheduleDialog;
