import { FC } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import NewBoard from "../NewBoard";
import BoardTemplatePage from "../BoardTemplate";
import Marketplace from ".";
import { useRouteMetrics } from "../../hooks/metrics/routeEvents";
import { useBoardTemplate } from "../../hooks/UseBoardTemplate";

const MarketplaceRouter: FC = () => {
  const match = useRouteMatch<{ boardTemplateId: string }>({
    path: [
      "/marketplace/:boardTemplateId",
      "/marketplace/:boardTemplateId/new",
    ],
    exact: true,
  });

  const { data: mergedBoardTemplate, isLoading } = useBoardTemplate(
    match?.params.boardTemplateId
  );

  useRouteMetrics(["/marketplace"], {
    scope: ["marketplace"],
  });

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={["/marketplace", "/marketplace/flows/:flowId"]}
        component={Marketplace}
      />
      <ProtectedRoute
        exact
        path="/marketplace/:boardTemplateId"
        component={() => (
          <BoardTemplatePage boardTemplate={mergedBoardTemplate} />
        )}
      />
      <ProtectedRoute
        exact
        path="/marketplace/:boardTemplateId/new"
        component={(props: any) => (
          <NewBoard
            boardTemplate={!isLoading && mergedBoardTemplate}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

export default MarketplaceRouter;
