import {Box, Stack, Typography} from "@mui/material";
import {BoardCardManagedChip} from "@noogata/components";
import {BoardTemplate} from "../../types/board-templates";
import {PremiumChip} from "../MarketplaceV2";
import {FC} from "react"

export const BoardTemplateDescriptionAndTags: FC<{
    boardTemplate: BoardTemplate;
}> = ({boardTemplate}) => (
    <Box>
        <Typography variant="body1">{boardTemplate.description}</Typography>
        <Stack spacing={1} direction="row" sx={{mt: 1}}>
            {boardTemplate.managed && <BoardCardManagedChip/>}
            {(boardTemplate.tags || []).includes("premium") && <PremiumChip/>}
        </Stack>
  </Box>
);
