import {Breadcrumbs, Link, Skeleton, Stack, StackProps, Typography,} from "@mui/material";

import {Link as RouterLink} from "react-router-dom";
import theme from "../../themev2";
import {HeaderBreadcrumb} from "../../types";
import {EditableText} from "../../components/EditableText";
import {FC} from "react"

const FlowHeaderBreadcrumbs: FC<
    {
        breadcrumbs: (HeaderBreadcrumb & { onSubmit?: (value: any) => void })[];
    } & StackProps
> = ({breadcrumbs, ...props}) => (
    <Stack {...props}>
        <Breadcrumbs separator={<Typography variant="body4">/</Typography>}>
            {breadcrumbs.map((value, index) => {
                const last = index === breadcrumbs.length - 1;
        return last && value.onSubmit ? (
          <EditableText
            typographyProps={{
              color: theme.palette.primary.dark,
              variant: "headline3",
              fontWeight: "600",
            }}
            textFieldProps={{ size: "small" }}
            onSubmit={value.onSubmit}
            value={value.title || ""}
            isLoading={!value.title}
          />
        ) : !value.title ? (
          <Skeleton width={80} />
        ) : (
          <Link
            color={
              last ? theme.palette.text.link : theme.palette.text.secondary
            }
            underline="hover"
            variant="headline3"
            fontWeight="600"
            to={value.url}
            key={value.url}
            component={RouterLink as any}
          >
            {value.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  </Stack>
);

export default FlowHeaderBreadcrumbs;
