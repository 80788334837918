import theme from "../../../themes/light.ts";
import { Stack, Typography } from "@mui/material";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useCallback } from "react";

const SortableTitle = ({
  title,
  value,
  sx,
  disabled = false,
}: {
  title: string;
  value: string;
  sx?: any;
  disabled?: boolean;
}) => {
  const { sortBy, setSortBy } = useSalesAssistant();

  const onClick = useCallback(() => {
    if (sortBy.search(value) >= 0) {
      setSortBy(sortBy[0] === "-" ? value : `-${value}`);
    } else {
      setSortBy(`-${value}`);
    }
  }, [sortBy, value]);

  return (
    <Stack
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        cursor: "pointer",
        alignItems: "center",
      }}
      onClick={() => !disabled && onClick()}
    >
      <Typography
        variant="caption"
        color={theme.palette.text.primary}
        sx={{
          width: "100%",
        }}
      >
        {title}
      </Typography>
      {sortBy.search(value) >= 0 && (
        <>{sortBy[0] === "-" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}</>
      )}
    </Stack>
  );
};

export default SortableTitle;
