import {FC, useCallback} from "react";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import {useSnackbar} from "notistack";
import {useHistory, useParams} from "react-router-dom";
import BoltIcon from "@mui/icons-material/Bolt";
import InfoIcon from "@mui/icons-material/Info";
import {useMutation, useQueryClient} from "react-query";
import {Save} from "@mui/icons-material";
import {NewRunFormResult} from "../hooks/useNewRunForm";
import {ConnectorType} from "../../../enums";
import {RunInputValue, TriggerCreate} from "../../../types";
import {createTrigger, updateTrigger} from "../../../services/triggers/api";
import {useQueryParams} from "../../../hooks";

const createOrUpdateTrigger = async (
    data: TriggerCreate,
    triggerId: string | null
) => {
  if (!triggerId) {
    return createTrigger(data);
  }
  const result = await updateTrigger(data, triggerId);
  return result;
};

const constantsMap = {
  update: {
    label: "Save",
    StartIcon: Save,
  },
  create: {
    label: "Auto Trigger",
    StartIcon: BoltIcon,
  },
};
const useCreateOrUpdateAction = () => {
  const triggerId = useQueryParams().get("triggerId");
  const createOrUpdate = useCallback(
    (data: TriggerCreate) => createOrUpdateTrigger(data, triggerId),
    [triggerId]
  );
  return {
    ...constantsMap[triggerId ? "update" : "create"],
    createOrUpdate,
  };
};

const TriggerButton: FC<{
  runForm: NewRunFormResult;
  disabled: boolean;
}> = ({ runForm, disabled }) => {
  const { boardId }: { boardId?: any } = useParams();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { watch } = runForm.methods;
  const inputs = watch("inputs");
  const { label, createOrUpdate, StartIcon } = useCreateOrUpdateAction();
  const hasLatestBlockInput =
    inputs &&
    Object.values(inputs).some(
        // @ts-ignore
      (inputValue: RunInputValue) =>
        inputValue &&
        inputValue.type === ConnectorType.BOARD &&
        inputValue.configuration?.run_id === "latest"
    );
  const mutation = useMutation(createOrUpdate, {
    onSuccess: (trigger) => {
      queryClient.invalidateQueries(["board", trigger.board_id]);
      trigger.triggered_by_ids.map((triggeredBoardId) =>
        queryClient.invalidateQueries(["board", triggeredBoardId])
      );
      history.push(`/boards/${trigger.board_id}`);
      enqueueSnackbar("Trigger created successfully", { variant: "success" });
    },
    onError: () => {
      enqueueSnackbar("Unable to set block trigger", {
        variant: "error",
      });
    },
  });
  const onClick = () =>
    mutation.mutateAsync({
      board_id: boardId,
      data: runForm.getRunCreateFromFormValues(runForm.methods.getValues()),
    });
  const triggerDisabled =
    disabled || !hasLatestBlockInput || mutation.isLoading;
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Button
        disabled={triggerDisabled}
        onClick={onClick}
        startIcon={<StartIcon />}
        variant="outlined"
        sx={{ justifyContent: "start", width: "100%" }}
        fullWidth
      >
        {label}
      </Button>
      <Tooltip
        title={
          triggerDisabled
            ? hasLatestBlockInput
              ? ""
              : "Latest block run must be selected to enable this feature"
            : "Trigger this configuration every time input block has a new output"
        }
        placement="right"
      >
        <span>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};
export default TriggerButton;
