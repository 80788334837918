import { FC } from "react";
import SVG from "react-inlinesvg";

export const Icon: FC<{
  src: string;
  width?: string;
  height?: string;
  color?: string;
}> = ({ src, width = "14px", height = "14px", color }) => (
  <>
    <SVG
      width={width}
      height={height}
      fill={color}
      color={color}
      src={`/${src}`}
    />
  </>
);
