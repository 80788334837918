import {Box, IconButton, Tooltip, Typography, useTheme} from "@mui/material";

import {Column, Row, usePagination, useSortBy, useTable} from "react-table";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMutation} from "react-query";
import {useSnackbar} from "notistack";

import {IllustrationPlaceholder, UncontrolledTable,} from "../../../components";
import {APIError} from "../../../services/apiRequest";
import useDataSources from "../../../services/datasources/useDataSources";
import {GetProductsResponse} from "../../../services/ecommerce/useEcommerceProducts";
import {formatDateString} from "../../../utils";
import useDataSourceProductsCount from "../../../services/datasources/useDataSourceProductsCount";
import ZoomableImage from "../ZoomableImage";
import {deleteProductApi} from "../../Dashboards/api";
import {PerAccountStatus} from "../../../types/ecommerce";
import {MANUAL_DATA_SOURCE} from "../../../types/datasources";
import {FC, useEffect, useMemo} from "react"

const parse_categories = (categories: string) => {
  const categories_arr = categories ? categories?.split("|") : [];
  if (categories_arr.length > 0) {
    return `${categories_arr[0]}/.../${
        categories_arr[categories_arr.length - 1]
    }`;
  }

  return "";
};

const parse_categories_tooltip = (categories: string) => {
  const categories_arr = categories ? categories?.split("|") : [];
  return (
    <>
      {categories_arr.map((category: string, index: number) => (
        <Typography color="inherit" key={category}>
          {index + 1}. {category}
        </Typography>
      ))}
    </>
  );
};

const ProductsTable: FC<{
  data?: GetProductsResponse;
  isLoading: boolean;
  emptyText?: string;
  error: APIError | null;
  onNextPage: () => void;
  refetchProducts?: () => void;
}> = ({ data, emptyText, isLoading, error, onNextPage, refetchProducts }) => {
  const theme = useTheme();
  const { data: dataSources = [] } = useDataSources();
  const { data: perDsCount = [], refetch: refetchCount } =
    useDataSourceProductsCount();
  const { items: products = [], total = 0 } = data || {};
  const snackbar = useSnackbar();
  const deleteProductMutation = useMutation((asin: string) =>
    deleteProductApi(asin).then(() => {
      snackbar.enqueueSnackbar("Product deleted successfully!", {
        variant: "success",
      });
      if (refetchProducts) {
        refetchProducts();
        refetchCount();
      }
    })
  );

  const all_columns = [
    {
      Header: "Image",
      id: "image",
      accessor: "image_url",
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <>{value && <ZoomableImage src={value} alt="Product" />}</>
      ),
    },
    {
      Header: "ASIN",
      id: "ASIN",
      accessor: "asin",
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Typography noWrap variant="body2" title={value}>
          <a
            href={`https://amazon.com/dp/${value}`}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        </Typography>
      ),
    },
    {
      id: "title",
      Header: "Title",
      accessor: "title",
      disableSortBy: true,
      Cell: ({ value = "" }: { value: string }) => (
        <Tooltip title={value} placement="top-start">
          <Typography
            noWrap
            variant="body2"
            sx={{
              [theme.breakpoints.only("xl")]: {
                maxWidth: theme.spacing(45),
              },
              [theme.breakpoints.only("lg")]: {
                maxWidth: theme.spacing(45),
              },
              [theme.breakpoints.only("md")]: {
                maxWidth: theme.spacing(45),
              },
              [theme.breakpoints.down("md")]: {
                maxWidth: theme.spacing(20),
              },
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: "Brand",
      id: "brand",
      accessor: "brand",
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Typography noWrap variant="body2">
          {value}
        </Typography>
      ),
    },
    {
      Header: "Source",
      id: "account_id",
      accessor: "data_source_id",
      disableSortBy: true,
      Cell: ({ value = "" }: { value: string }) => (
        <Tooltip title={value} placement="top-start">
          <Typography
            noWrap
            variant="body2"
            sx={{ width: (t) => t.spacing(20) }}
          >
            {dataSources?.find((ds) => ds.id === value)?.name || value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: "Categories",
      id: "categories",
      accessor: "categories",
      disableSortBy: true,
      Cell: ({ value = "" }: { value: string }) => (
        <div style={{ textAlign: "left" }}>
          <Tooltip
            title={parse_categories_tooltip(value) || ""}
            placement="top-start"
          >
            <Typography
              noWrap
              variant="body2"
              sx={{ maxWidth: theme.spacing(35) }}
            >
              {parse_categories(value)}
            </Typography>
          </Tooltip>
        </div>
      ),
    },
    {
      Header: "GTIN",
      id: "GTIN",
      accessor: "gtin",
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Typography noWrap variant="body2" title={value}>
          {value}
        </Typography>
      ),
    },
    {
      id: "created_at",
      Header: "Date Added",
      accessor: "created_at",
      minWidth: 80,
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Tooltip title={formatDateString(value) || ""} placement="top-start">
          <Typography
            variant="body2"
            noWrap
            sx={{ maxWidth: theme.spacing(7) }}
          >
            {formatDateString(value).split(",")[0]}
          </Typography>
        </Tooltip>
      ),
    },
    {
      id: "actions",
      Header: "",
      disableSortBy: true,
      accessor: "asin",
      Cell: ({ value, row }: { value: string; row: Row<PerAccountStatus> }) => (
        <>
          {row?.original?.data_source_id === MANUAL_DATA_SOURCE.id && (
            <Tooltip title="Delete this product">
              <IconButton
                size="small"
                aria-label="delete"
                onClick={() => {
                  deleteProductMutation.mutate(value);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  // @ts-ignore
  const columns: Column<object>[] = useMemo(
    () =>
      perDsCount.length > 1
        ? all_columns
        : all_columns.filter((col) => col.id !== "account_id"),
    [data]
  );
  const instance = useTable(
    {
      columns,
      data: products,
      autoResetSortBy: false,
      autoResetPage: false,
      initialState: { pageSize: products.length || 1 },
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    instance.setPageSize(products.length || 1);
  }, [data]);
  return (
    <Box>
      {!isLoading && products.length === 0 ? (
        <IllustrationPlaceholder
          imageHeight={300}
          imageWidth={300}
          text={emptyText || "No data to display."}
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <UncontrolledTable
            instance={instance}
            data={products}
            isLoading={isLoading}
            columns={columns}
            error={error || null}
            tableProps={{
              stickyHeader: true,
            }}
            disablePagination
          />
          {total > products.length && (
            <Typography
              onClick={onNextPage}
              sx={{
                transition: "background 0.2s ease",
                "&:hover": { background: "grey.100" },
                padding: 2,
                cursor: "pointer",
                borderBottomWidth: "1px",
                borderBottomColor: "text.secondary",
                color: "text.primary",
              }}
            >
              + Load 10 more products (of {total - products.length})
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProductsTable;
