import { DestinationType } from "../enums";

export enum ExportStatus {
  PENDING = "pending",
  RUNNING = "running",
  PROCESSING = "processing",
  COMPRESSING = "compressing",
  UPLOADING = "uploading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  PARTIAL = "partial",
}

export type Export = {
  board_id: string;
  destination_directory?: string;
  error_message?: string;
  destination_id?: string;
  destination_type: DestinationType;
  id: string;
  outputs: string[];
  result?: any;
  run_id: string;
  created_at: string;
  updated_at: string;
  expired_at?: string;
  status: ExportStatus;
};

export const FinalStatuses = new Set([
  ExportStatus.SUCCEEDED,
  ExportStatus.PARTIAL,
  ExportStatus.FAILED,
]);
