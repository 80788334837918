import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {Chip, Skeleton} from "@mui/material";

import {Status} from "../enums";
import theme from "../themev2";
import {prettifyString} from "../utils";
import RotatingLoader from "./RotatingLoader";
import {FC} from "react"

export const mapLabelDetails = (
  status?: Status | null
): { color: string; bgcolor: string; text: string; icon: JSX.Element } => {
  switch (status) {
    case Status.PENDING:
    case Status.UNKNOWN:
    default:
      return {
        color: theme.palette.warning.dark,
        bgcolor: theme.palette.warning.light,
        text: prettifyString(Status.PENDING),
        icon: (
          <AccessTimeIcon
            fontSize="small"
            style={{ color: theme.palette.warning.dark }}
          />
        ),
      };
    case Status.RUNNING:
      return {
        color: theme.palette.primary.main,
        bgcolor: theme.palette.primary.light,
        text: prettifyString(Status.RUNNING),
        icon: <RotatingLoader />,
      };
    case Status.SUCCEEDED:
      return {
        color: theme.palette.success.dark,
        bgcolor: theme.palette.success.light,
        text: prettifyString(Status.SUCCEEDED),
        icon: (
          <CheckCircleOutlineIcon
            fontSize="small"
            style={{ color: theme.palette.success.dark }}
          />
        ),
      };
    case Status.FAILED:
      return {
        color: theme.palette.error.dark,
        bgcolor: theme.palette.error.light,
        text: prettifyString(Status.FAILED),
        icon: (
          <ErrorOutlineIcon
            fontSize="small"
            style={{ color: theme.palette.error.dark }}
          />
        ),
      };
  }
};

const StatusBadge: FC<{
  status?: Status | null;
}> = ({ status }) => {
  if (status === undefined) {
    return <Skeleton height={3} width={110 / 8} />;
  }

  const renderBadge = status
    ? mapLabelDetails(status)
    : mapLabelDetails(Status.UNKNOWN);
  return (
    <Chip
      sx={{
        backgroundColor: renderBadge.bgcolor,
        color: renderBadge.color,
        height: "24px",
        minWidth: "110px",
        borderRadius: "4px",
        padding: "4, 12, 4, 12px",
      }}
      label={renderBadge.text}
      icon={renderBadge.icon}
    />
  );
};

export default StatusBadge;
