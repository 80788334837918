import { Box, Skeleton, Typography } from "@mui/material";

import { useLocation } from "react-router-dom";
import theme from "../../themes/light";
import { Icon } from "../../components/Icon";
import { formatCurrencyOnlyNumber } from "../InsightsV2/SummaryV2";
import { FC } from "react";
import { useSplitEnabled } from "../../hooks";
import { Split } from "../../FeatureFlags/enums";

const HeaderCards: FC<{
  SalesMetaData: any;
  metadataLoading: boolean;
}> = ({ SalesMetaData, metadataLoading }) => {
  const { pathname } = useLocation();
  const enableAlert = useSplitEnabled(Split.SALES_ASSISTANT_ALERTS);
  return (
    <>
      {metadataLoading ? (
        <>
          <Box display="flex" gap="16px">
            <Skeleton
              variant="text"
              width={200}
              sx={{
                background: theme.palette.grey[50],
                borderRadius: "12px",
                padding: "12px",
                width: "200px",
                height: "68px ",
                transform: "scale(1)",
              }}
            />
            <Skeleton
              variant="text"
              width={200}
              sx={{
                background: theme.palette.grey[50],
                borderRadius: "12px",
                padding: "12px",
                width: "200px",
                height: "68px ",
                transform: "scale(1)",
              }}
            />
            <Skeleton
              variant="text"
              width={200}
              sx={{
                background: theme.palette.grey[50],
                borderRadius: "12px",
                padding: "12px",
                width: "200px",
                height: "68px ",
                transform: "scale(1)",
              }}
            />
            <Skeleton
              variant="text"
              width={200}
              sx={{
                background: theme.palette.grey[50],
                borderRadius: "12px",
                padding: "12px",
                width: "200px",
                height: "68px ",
                transform: "scale(1)",
              }}
            />
            <Skeleton
              variant="text"
              width={200}
              sx={{
                background: theme.palette.grey[50],
                borderRadius: "12px",
                padding: "12px",
                width: "200px",
                height: "68px ",
                transform: "scale(1)",
              }}
            />
          </Box>
        </>
      ) : (
        <>
          {pathname.search("tracking") > -1 ? (
            <Box display="flex" gap="16px">
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Sales 7d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.sales_7d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.sales_7d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.sales_7d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.sales_7d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(SalesMetaData?.sales_7d_percentage?.toFixed(1))}%
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Sales 28d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.sales_28d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.sales_28d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.sales_28d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.sales_28d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(SalesMetaData?.sales_28d_percentage?.toFixed(1))}%
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Alerts 7d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    {formatCurrencyOnlyNumber(SalesMetaData?.alerts)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.alerts_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.alerts_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.alerts_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(SalesMetaData?.alerts_percentage?.toFixed(1))}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Ad Spend 7d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.ad_spend_7d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.ad_spend_7d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.ad_spend_7d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.ad_spend_7d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(
                      SalesMetaData?.ad_spend_7d_percentage?.toFixed(1)
                    )}
                    %
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Ad Spend 28d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.ad_spend_28d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.ad_spend_28d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.ad_spend_28d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.ad_spend_28d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(
                      SalesMetaData?.ad_spend_28d_percentage?.toFixed(1)
                    )}
                    %
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box display="flex" gap="16px">
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Rolling 7d Sales
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.sales_7d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.sales_7d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.sales_7d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.sales_7d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(SalesMetaData?.sales_7d_percentage?.toFixed(1))}%
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Rolling 28d Sales
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.sales_28d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.sales_28d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.sales_28d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.sales_28d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(SalesMetaData?.sales_28d_percentage?.toFixed(1))}%
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Ad Spend 7d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.ad_spend_7d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.ad_spend_7d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.ad_spend_7d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.ad_spend_7d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {Math.abs(
                      SalesMetaData?.ad_spend_7d_percentage?.toFixed(1)
                    )}
                    %
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Ad Spend 28d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    ${formatCurrencyOnlyNumber(SalesMetaData?.ad_spend_28d)}{" "}
                  </Typography>
                  <Typography
                    variant="body4"
                    display="flex"
                    alignItems="center"
                    color={
                      SalesMetaData?.ad_spend_28d_percentage > 0
                        ? theme.palette.text.success
                        : theme.palette.error.main
                    }
                    sx={{
                      "& svg": {
                        path: {
                          fill:
                            SalesMetaData?.ad_spend_28d_percentage > 0
                              ? theme.palette.text.success
                              : theme.palette.error.main,
                        },
                      },
                    }}
                  >
                    {SalesMetaData?.ad_spend_28d_percentage > 0 ? (
                      <Icon
                        src="sales/trend-up.svg"
                        height="14px"
                        width="14px"
                      />
                    ) : (
                      <Icon
                        src="sales/trend-down.svg"
                        height="14px"
                        width="14px"
                      />
                    )}
                    {SalesMetaData?.ad_spend_28d_percentage
                      ?.toFixed(1)
                      .replaceAll(/\.0(0)?/g, "")}
                    %
                  </Typography>
                </Box>
              </Box>
              {enableAlert && (
              <Box
                sx={{
                  background: theme.palette.grey[50],
                  borderRadius: "12px",
                  padding: "12px",
                  width: "200px",
                  height: "68px ",
                }}
              >
                <Typography variant="caption" color={theme.palette.grey[400]}>
                  Revenue Alerts 7d
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="headline3">
                    {formatCurrencyOnlyNumber(SalesMetaData?.alerts)}{" "}
                  </Typography>
                </Box>
              </Box>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default HeaderCards;
