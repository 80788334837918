import { Box, Skeleton, Typography } from "@mui/material";
import theme from "../../../themes/light.ts";
import SortableTitle from "./SortableTitle.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";

const variantMapping = {
  asins: {
    label: "Products",
    columns: {
      revenue: "Revenue",
      units_sold: "Units Sold",
      // ad_units_sold: "Ad Units Sold",
      avg_price: "Price",
      acos: "ACOS",
      // ctr: "CTR",
      conversion: "CVR",
      ad_spend: "Ad Spend",
    },
  },
  spaces: {
    label: "Spaces",
    columns: {
      revenue: "Revenue",
      units_sold: "Units Sold",
      conversion: "Conversion",
      glance_views: "Glance Views",
    },
  },
  brands: {
    label: "Brands",
    columns: {
      revenue: "Revenue",
      units_sold: "Units Sold",
      ad_units_sold: "Ad Units Sold",
      avg_price: "Price",
      acos: "ACOS",
      ctr: "CTR",
      cvr: "CVR",
      ad_spend: "Ad Spend",
    },
  },
};

const ListHead = ({ variant, total }: { variant: string; total?: number }) => {
  const { spaceId } = useSalesAssistant();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: variant === "spaces" ? "0 30px" : "0 10px",
        width: variant === "spaces" ? "1200px" : "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: variant === "spaces" ? "250px" : "200px",
        }}
      >
        {total ? (
          <Typography
            variant="caption"
            color={theme.palette.text.primary}
          >{`${total} ${variantMapping[variant as keyof typeof variantMapping]?.label}`}</Typography>
        ) : (
          <Skeleton variant="text" width="100px" height="30px" />
        )}
      </Box>
      {variant === "asins" && !spaceId && (
        <>
          <Typography
            variant="caption"
            color={theme.palette.text.primary}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              width: "150px",
              paddingLeft: "20px",
              borderLeft: "2px solid #DDE2EB",
              height: "100%",
            }}
          >
            Spaces
          </Typography>
        </>
      )}
      {Object.entries(
        variantMapping[variant as keyof typeof variantMapping]?.columns
      ).map(([key, value]: [string, string]) => (
        <>
          <SortableTitle
            key={`list-head-${key}`}
            title={value}
            value={key}
            sx={{
              width: variant === "spaces" ? "150px" : "120px",
              paddingLeft: "20px",
              borderLeft: variant === "asins" ? "2px solid #DDE2EB" : "none",
              height: "100%",
            }}
            disabled={value === "Spaces"}
          />
        </>
      ))}

      <Box
        sx={{
          width: variant === "spaces" ? "50px" : "30px",
        }}
      ></Box>
    </Box>
  );
};

export default ListHead;
