import { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Chip,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import cn from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Cancel } from "@mui/icons-material";
import { StatsPeriods, TransformedInsight } from "../types";
import { useQueryParams, useSplitEnabled } from "../../../hooks";
import { useSalesContext } from "../hooks/selectors";
import { ProductImage } from "./ProductImage";
import { Info } from "./Info";
import { useSalesAssistantStyles } from "../useSalesAssistantStyles";
import { salesColumnWidths } from "../constants";
import UnitSale from "../UnitSale";
import DismissTracking from "../DismissTracking";
import { Icon } from "../../../components/Icon";
import SalesButton from "./TabButtons/SalesButton";
import { onClickRow } from "./List";
import { formatCurrencyOnlyNumber } from "../../InsightsV2/SummaryV2";
import {
  addAlertToDismissed,
  addProductToTracked,
  removeAlertFromDismissedTab,
  removeProductFromTracking,
} from "../../../services/runs/api";
import { Split } from "../../../FeatureFlags/enums";

const useStyles = makeStyles(() => ({
  trackImg: {
    opacity: 0,
  },
  productList: {
    "&:hover": {
      "& .trackImg": {
        opacity: 1,
      },
    },
  },
}));

export const mapRevenue = (
  revenue: number,
  sales7D: number | undefined,
  sales28D: number | undefined,
  statsPeriod: StatsPeriods,
  revenueOverTimeEnabled: boolean
): number => {
  if (!revenueOverTimeEnabled) return revenue;
  if (statsPeriod === "7 days") return sales7D as number;
  if (statsPeriod === "28 days") return sales28D as number;
  throw Error("wrong revenue value mapping");
};

export const SalesListItem: FC<{
  insight: TransformedInsight;
  setIsUpdatedList: any;
}> = ({ insight, setIsUpdatedList }) => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { isDrawerEnabled, sortDays } = useSalesContext();
  const theme = useTheme();
  const { row } = useSalesAssistantStyles();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const isTracking = useSplitEnabled(Split.TRACKING);

  const handleClose = () => {
    setOpen(!open);
  };
  const handleProductTracked = async (id: string) => {
    setIsUpdatedList(false);
    await addProductToTracked(id);
    setIsUpdatedList(true);
  };

  const handleAlerttoDissmissed = async (id: string) => {
    setIsUpdatedList(false);
    await addAlertToDismissed(id);
    setIsUpdatedList(true);
  };

  const removeAlertFromDismissed = async (id: string) => {
    setIsUpdatedList(false);
    handleClick("Alert removed from Dismissed List");
    await removeAlertFromDismissedTab(id);
    setIsUpdatedList(true);
  };

  const handleConfirm = () => {
    handleProductTracked(insight?.id);
  };
  const handleRemoveProductFromTracking = async () => {
    setIsUpdatedList(false);
    await removeProductFromTracking(insight?.id);
    setIsUpdatedList(true);
  };

  const dateObj = insight?.alerts && new Date(insight?.alerts[0]?.updated_at);
  const day = dateObj && dateObj?.getDate();
  const month =
    dateObj &&
    new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);

  const formattedDate = `${day} ${month}`;

  const handleCancel = (snackbarId: any) => {
    closeSnackbar(snackbarId);
  };
  const handleUndo = () => {
    handleClick("Item removed from tracking List");
    handleRemoveProductFromTracking();
  };
  const action = (snackbarId: any) => (
    <>
      {!insight?.tracked && (
        <Typography
          variant="body4"
          sx={{ color: "white", cursor: "pointer" }}
          onClick={() => handleUndo()}
        >
          Undo
        </Typography>
      )}
      <Typography
        variant="body4"
        sx={{
          color: "white",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => handleCancel(snackbarId)}
      >
        <Cancel />
      </Typography>
    </>
  );
  const handleClick = (value: any) => {
    enqueueSnackbar("Custom snackbar message", {
      variant: "success",
      content: (key) => (
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          sx={{
            background: theme.palette.text.primary,
            minHeight: " 70px",
            padding: "4px 0px",
            borderRadius: "4px",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            gap="8px"
            sx={{ padding: "8px 20px" }}
          >
            <Box>
              <Typography
                variant="body4"
                display="block"
                sx={{
                  fontSize: "15px",
                  color: "white",
                  margin: 0,
                  lineHeight: "24px",
                }}
              >
                {value}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            gap="16px"
            alignItems="center"
            sx={{ paddingRight: "16px" }}
          >
            {action(key)}
          </Box>
        </Box>
      ),
    });
  };

  const handleProductsTracking = () => {
    if (!insight?.tracked) {
      handleClick("Product moved to tracking List");
      handleProductTracked(insight?.id);
    } else {
      handleClick("Product removed from tracking List");
      handleRemoveProductFromTracking();
    }
  };

  const handleAlertsTracking = () => {
    if (!insight?.tracked) {
      handleClick("Alert moved to tracking List");
      handleProductTracked(insight?.id);
    } else {
      handleClick("Alert removed from tracking List");
      handleRemoveProductFromTracking();
    }
  };
  return (
    <>
      <ListItem
        key={insight.id}
        id={`insight-id-${insight.id}`}
        sx={{
          height: "auto",
          opacity: 1,
          transition: "all 0.3s linear",
          overflow: "hidden",
          position: "relative",
          "&.hidden": {
            paddingY: 0,
          },
        }}
      >
        <Stack
          data-testid="insights-list-row"
          className={cn(row, { active: insight.isChecked })}
          position="relative"
        >
          {pathname.search("alerts") > -1 && (
            <Stack
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              padding="13px 16px"
              sx={{ borderBottom: "1px solid #E9E7F1" }}
            >
              {insight?.alerts && (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  gap="10px"
                >
                  <Chip
                    label="Revenue Alert"
                    size="small"
                    sx={{
                      borderRadius: "500px",
                      padding: "4px",
                      color: theme.palette.error.main,
                      fontSize: "13px",
                      background: "#F8D6D8",
                      fontWeight: 500,
                    }}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.grey[400],
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      gap: "5px",
                    }}
                    variant="body3"
                  >
                    {" "}
                    <Typography
                      sx={{ fontWeight: 500 }}
                      color={theme.palette.text.primary}
                    >
                      {formattedDate}
                    </Typography>{" "}
                    Last week&apos;s product sales of{" "}
                    <Typography
                      sx={{ fontWeight: 500 }}
                      color={theme.palette.text.primary}
                    >
                      {insight?.alerts[0]?.asin}
                    </Typography>{" "}
                    dropped by{" "}
                    <Typography
                      sx={{ fontWeight: 500 }}
                      color={theme.palette.text.primary}
                    >
                      {insight?.alerts[0]?.data?.decrease_percentage.toFixed(2)}
                      %
                    </Typography>
                    compared to the previous week
                  </Typography>
                </Box>
              )}
              <SalesButton
                // handleClose={handleClose}
                alertId={insight?.alerts?.[0]?.id}
                handleAlerttoDissmissed={handleAlerttoDissmissed}
                removeAlertFromDismissed={removeAlertFromDismissed}
                insight={insight}
                handleAlertsTracking={handleAlertsTracking}
              />
            </Stack>
          )}
          {pathname.search("sales-assistant/dismissed") > -1 && (
            <Stack
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              padding="13px 16px"
              sx={{ borderBottom: "1px solid #E9E7F1" }}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                  }}
                >
                  Dismissed by Jeremy K. – Wed, Nov 1, 2023 | 3:33 am
                </Typography>
              </Box>
              <SalesButton
                // handleClose={handleClose}
                insight={insight}
                removeAlertFromDismissed={removeAlertFromDismissed}
                alertId={insight?.alerts?.[0]?.id}
              />
            </Stack>
          )}
          {pathname.search("tracking") > -1 && (
            <Stack
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              padding="13px 16px"
              sx={{ borderBottom: "1px solid #E9E7F1" }}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                  }}
                >
                  Added by John Roddick – Wed, Nov 1, 2023 | 3:33 am
                </Typography>
              </Box>
              <SalesButton
                // handleClose={handleClose}
                handleRemoveProductFromTracking={
                  handleRemoveProductFromTracking
                }
                insight={insight}
                alertId={insight?.alerts?.[0]?.id}
              />
            </Stack>
          )}
          <Box
            position="relative"
            sx={{
              "&:hover": {
                "& .MuiBox-root": {
                  opacity: 1,
                },
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              padding="16px 20px"
              gap="20px"
              justifyContent="space-between"
              position="relative"
              className={classes.productList}
              sx={{
                "&:hover": {
                  "& .MuiBox-root": {
                    opacity: 1,
                  },
                },
              }}
              onClick={() =>
                onClickRow(isDrawerEnabled, queryParams, history, insight)
              }
            >
              <Box
                sx={{ display: "flex", gap: "16px", alignItems: "center" }}
                width={salesColumnWidths.info}
              >
                {/* {showCheckbox && (
                  <Box minWidth={salesColumnWidths.checkbox}>
                    <Checkbox
                      data-testid={`insight-checkbox-${insight.id}`}
                      sx={{ width: salesColumnWidths.checkbox }}
                    />
                  </Box>
                )} */}
                <Box minWidth={salesColumnWidths.image}>
                  <ProductImage insight={insight} useTooltip />
                </Box>
                <Box>
                  <Info insight={insight} />
                </Box>
              </Box>
              <Box width={salesColumnWidths.unitSale}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="caption" color={theme.palette.text.link}>
                    {insight?.start_week_text}
                  </Typography>
                  <Typography variant="caption" color={theme.palette.grey[400]}>
                    Weekly unit sales
                  </Typography>
                  <Typography variant="caption" color={theme.palette.text.link}>
                    {insight?.end_week_text}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: "56px",
                      "& .apexcharts-toolbar": {
                        display: "none",
                      },
                    }}
                  >
                    <UnitSale data={insight?.graph_data} />
                  </Box>
                </Box>
              </Box>
              <Box
                width={salesColumnWidths.rating}
                sx={{ display: "flex", flexDirection: "column", gap: "14px" }}
              >
                <Typography variant="caption">
                  Rolling {sortDays === "7d" ? "7d Sales" : "28d Sales"}{" "}
                </Typography>
                <Stack
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography variant="headline3">
                    $
                    {formatCurrencyOnlyNumber(
                      sortDays === "7d" ? insight?.sales_7d : insight?.sales_28d
                    )}
                  </Typography>
                  <Box display="flex" gap="8px">
                    {sortDays === "7d" ? (
                      <>
                        {insight?.sales_7d_percentage >= 0 ? (
                          <Box display="flex" gap="2px" alignItems="center">
                            <Icon
                              src="sales/trend-up.svg"
                              height="16px"
                              width="16px"
                            />{" "}
                            <Typography
                              variant="body4"
                              sx={{ color: theme.palette.text.success }}
                            >
                              {Math.abs(insight?.sales_7d_percentage)
                                .toFixed(1)
                                .replaceAll(/\.0(0)?/g, "")}
                              %
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignItems="center" gap="2px">
                            <Icon
                              src="sales/trend-down.svg"
                              height="16px"
                              width="16px"
                            />{" "}
                            <Typography
                              variant="body4"
                              sx={{ color: theme.palette.error.main }}
                            >
                              {Math.abs(insight?.sales_7d_percentage)
                                .toFixed(1)
                                .replaceAll(/\.0(0)?/g, "")}
                              %
                            </Typography>
                          </Box>
                        )}{" "}
                      </>
                    ) : (
                      <>
                        {insight?.sales_28d_percentage >= 0 ? (
                          <Box display="flex" gap="2px" alignItems="center">
                            <Icon
                              src="sales/trend-up.svg"
                              height="16px"
                              width="16px"
                            />{" "}
                            <Typography
                              variant="body4"
                              sx={{ color: theme.palette.text.success }}
                            >
                              {insight?.sales_28d_percentage
                                .toFixed(1)
                                .replaceAll(/\.0(0)?/g, "")}
                              %
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignItems="center" gap="2px">
                            <Icon
                              src="sales/trend-down.svg"
                              height="16px"
                              width="16px"
                            />{" "}
                            <Typography
                              variant="body4"
                              sx={{ color: theme.palette.error.main }}
                            >
                              {insight?.sales_28d_percentage
                                .toFixed(1)
                                .replaceAll(/\.0(0)?/g, "")}
                              %
                            </Typography>
                          </Box>
                        )}{" "}
                      </>
                    )}
                    <Typography
                      variant="body4"
                      sx={{
                        color:
                          sortDays === "7d"
                            ? insight?.sales_7d_percentage >= 0
                              ? theme.palette.text.success
                              : theme.palette.error.main
                            : insight?.sales_28d_percentage >= 0
                            ? theme.palette.text.success
                            : theme.palette.error.main,
                      }}
                    >
                      {sortDays === "7d" ? (
                        <>
                          {" "}
                          {/* $ {insight?.sales_7d_change > 0 && "+"} */}
                          {formatCurrencyOnlyNumber(insight?.sales_7d_change)}
                        </>
                      ) : (
                        <>
                          {" "}
                          {/* $ {insight?.sales_28d_change > 0 && "+"} */}
                          {formatCurrencyOnlyNumber(insight?.sales_28d_change)}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            {isTracking && (
              <>
                {pathname.search("products") > -1 && (
                  <Box
                    className={classes.trackImg}
                    sx={{
                      position: "absolute",
                      top: "-1px",
                      right: "-1px",
                      background: "#F4F3F8",
                      width: "76px",
                      height: "49px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      borderBottomLeftRadius: "20px",
                      opacity: 0,
                      svg: {
                        path: {
                          fill: theme.palette.text.primary,
                        },
                      },
                    }}
                    onClick={handleProductsTracking}
                  >
                    <Icon
                      src={`${
                        insight?.tracked
                          ? "sales/tracking-fill.svg"
                          : "sales/tracking.svg"
                      }`}
                      height="18px"
                      width="16px"
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Stack>
      </ListItem>
      <DismissTracking
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </>
  );
};
