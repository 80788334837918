import { Badge, Box, Tooltip, useTheme } from "@mui/material";

import SVG from "react-inlinesvg";

export const BlockIcon = ({
  isLatest,
  image,
}: {
  isLatest: boolean;
  image: string;
}) => {
  const theme = useTheme();
  const imageSize = {
    height: theme.spacing(5),
    width: theme.spacing(5),
  };
  return (
    <Tooltip title={!isLatest ? "A new version is available." : ""}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant="dot"
        badgeContent={isLatest ? 0 : ""}
        sx={{
          mr: 2,
          "& .MuiBadge-badge": {
            color: "#72CAF0",
            background: "#72CAF0",
            top: "2px",
            right: "2px",
            boxShadow: (theme) => `0 0 0 3px ${theme.palette.background.paper}`,
          },
        }}
      >
        {image ? (
          <SVG src={image} width={imageSize.width} height={imageSize.height} />
        ) : (
          <Box
            sx={{
              height: imageSize.height,
              width: imageSize.width,
              backgroundColor: "#71caf0",
            }}
          />
        )}
      </Badge>
    </Tooltip>
  );
};
