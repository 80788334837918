import { useAuthUserOrNull } from "@frontegg/react";
import { User } from "@frontegg/redux-store";
import { Box, BoxProps, Drawer, Stack, useTheme } from "@mui/material";
import { PlatformRole } from "@noogata/types";
import { useHistory } from "react-router-dom";
import AppModeButton from "../../../components/AppModeButton";
import AppModeClosedButton from "../../../components/AppModeClosedButton";
import NavbarIndicator from "../../../components/NavbarIndicator";
import { getUserMostPrivilegeRole } from "../../../services/auth";
import { DigitalShelfList } from "./DigitalShelfList";
import { InsightsNavItem } from "./InsightsNavItem";
import { LogoutNavItem } from "./LogoutNavItem";
import { PerfectContentList } from "./PerfectContentList";
import { TrendspottingList } from "./TrendspottingList";
import { CustomDashboardsList } from "./CustomDashboardsList";
import { NavbarModes } from "../../../enums";
import { useAppContext } from "../../../ApplicationContext";
import { TenantSelection } from "../../../components/TenantSelection";
import { AdBoosterList } from "./AdBoosterList";
import { Split } from "../../../FeatureFlags/enums";
import { useSplitEnabled } from "../../../hooks";
import {
  ThemeProviderV2,
  useIsDarkMode,
} from "../../../components/ThemeProviderV2";
import { UserSection } from "../../../components/LabLeftNav/UserSection";
import { SalesInsightsNavItem } from "./SalesInsightsNavItem";
import { Tracking } from "./Tracking";
import { FC } from "react";
import { Tasks } from "./Tasks";
import { InsightsChatNavItem } from "./InsightsChatNavItem";
// import { SpacesNavItem } from "./SpacesNavItem.tsx";
import { InsightsChatV2NavItem } from "./InsightsChatV2NavItem.tsx";
import { SpacesV2NavItem } from "./SpacesV2NavItem.tsx";

const Logo: FC<{ size?: "full" | "favicon" } & Partial<BoxProps>> = ({
  size = "full",
  ...props
}) => {
  const history = useHistory();
  const isDarkMode = useIsDarkMode();
  return (
    <Box onClick={() => history.push("/")} {...props}>
      {size === "full" ? (
        <img
          alt=""
          src={`/${isDarkMode ? "logo-dark-mode.svg" : "logo-black.svg"}`}
          height={30}
          width={114}
          style={{
            marginLeft: 2,
          }}
        />
      ) : (
        <img alt="" src={`/favicon-dark.svg`} height={30} width={30} />
      )}
    </Box>
  );
};

const ApplicationsLeftNav: FC<object> = () => {
  const theme = useTheme();
  const { navbarMode } = useAppContext();
  const insightsEnabled = useSplitEnabled(Split.INSIGHTS);
  const salesAssistantEnabled = useSplitEnabled(Split.SALES_ASSISTANT);
  const open = navbarMode === NavbarModes.EXPENDED;
  const isAdBoosterEnabled = useSplitEnabled(Split.AD_BOOSTER);
  const isTaskEnabled = useSplitEnabled(Split.SALES_ASSISTANT_TASKS);
  const isSpacesV2Enabled = useSplitEnabled(Split.SPACES_V2);
  const isChatV2Enabled = useSplitEnabled(Split.CHAT_V2_FF);
  const isTracking = useSplitEnabled(Split.TRACKING);
  const InsightsChatEnabled = useSplitEnabled(Split.INSIGHTS_CHAT);

  const width = open ? theme.spacing(30) : `calc(${theme.spacing(9)} + 1px)`;
  const container =
    typeof window !== "undefined" ? window.document.body : undefined;
  const user: User | null = useAuthUserOrNull();
  const role: string = user
    ? getUserMostPrivilegeRole(user).key
    : PlatformRole.ApplicationUser;
  return (
    <Drawer
      open
      container={container}
      variant="permanent"
      sx={{
        width,
        display: { xs: "block", sm: "block" },
        "& .MuiDrawer-paper": {
          overflowX: "hidden",
          overflowY: "auto",
          px: 1,
          pb: 1,
          transition: "width 0.5s ease",
          boxSizing: "border-box",
          width,
          background: theme.palette.background.default,
        },
      }}
    >
      <Stack flex={1} justifyContent="space-between">
        <Stack spacing={1}>
          <Stack
            p={1}
            mb={4}
            paddingLeft={1}
            paddingTop={3}
            sx={{ marginBottom: "2rem !important" }}
          >
            <Logo ml={0.6} size={open ? "full" : "favicon"} />
          </Stack>
          {salesAssistantEnabled && <SalesInsightsNavItem />}
          {InsightsChatEnabled && <InsightsChatNavItem />}
          {isChatV2Enabled && <InsightsChatV2NavItem />}
          {isTaskEnabled && <Tasks />}
          {insightsEnabled && <InsightsNavItem />}
          <DigitalShelfList open={open} />
          <PerfectContentList open={open} />
          {isAdBoosterEnabled && <AdBoosterList open={open} />}
          {isSpacesV2Enabled && <SpacesV2NavItem />}
          <CustomDashboardsList open={open} />
          <TrendspottingList open={open} />
          {isTracking && <Tracking />}
        </Stack>
        <Stack spacing={1}>
          <LogoutNavItem />
          {role !== PlatformRole.ApplicationUser &&
            (open ? <AppModeButton /> : <AppModeClosedButton />)}
          {open && <TenantSelection />}
          <UserSection
            open={open}
            sx={{
              marginLeft: theme.spacing(1),
              maxHeight: theme.spacing(40),
            }}
          />
          <NavbarIndicator />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default () => (
  <ThemeProviderV2>
    <ApplicationsLeftNav />
  </ThemeProviderV2>
);
