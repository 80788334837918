import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";
import theme from "../../themes/light";
import SkeletonItem from "./SkeletonItem";

const SkeletonList = () => {
  const data: any = {
    to_do: [
      {
        status: "to_do",
      },
      {
        status: "to_do",
      },
    ],
    in_progress: [
      {
        status: "in_progress",
      },
      {
        status: "in_progress",
      },
      {
        status: "in_progress",
      },
    ],
    done: [
      {
        status: "done",
      },
      {
        status: "done",
      },
    ],
  };
  return (
    <Grid
      sx={{
        display: "flex",
        padding: "24px 32px",
        gap: "16px",
        background: theme.palette.grey[50],
        maxWidth: theme.spacing(1300 / 8),
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
      }}
    >
      {data &&
        Object.keys(data).map((column: any, i) => (
          <Grid item xs={4} sx={{ width: "33.33%" }} key={i}>
            <Paper
              sx={{
                background: "transparent",
                border: "1px solid",
                borderColor: theme.palette.grey[200],
                borderRadius: "16px",
                boxShadow: "none",
                minHeight: column === "to_do" ? "388px" : "auto",
                minWidth: "321px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px",
                  background: theme.palette.backgrounds.white,
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  align="center"
                  sx={{
                    background:
                      column === "in_progress"
                        ? theme.palette.warning.light
                        : column === "done"
                        ? "#E7F7EF"
                        : theme.palette.grey[50],
                    padding: "4px 12px",
                    color:
                      column === "in_progress"
                        ? theme.palette.warning.dark
                        : column === "Done"
                        ? "#005F29"
                        : theme.palette.grey[500],
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {column === "to_do"
                    ? "To Do"
                    : column === "in_progress"
                    ? "In Progress"
                    : "Done"}
                </Typography>
                {column !== "done" ? (
                  <Skeleton height="20px" width="30px" />
                ) : (
                  <Typography
                    variant="caption"
                    component="div"
                    align="left"
                    color={theme.palette.text.info}
                    width="100%"
                    sx={{ fontSize: "10px", maxWidth: "125px" }}
                  >
                    Completed tasks will be archived after 14 days
                  </Typography>
                )}
              </Box>
              <div
                style={{
                  padding: "16px",
                  gap: "16px",
                  display: "flex",
                  flexDirection: "column",
                  background: "transparent",
                }}
              >
                {data[column] &&
                  data[column]?.map((i: any) => <SkeletonItem index={i} />)}
              </div>
            </Paper>
          </Grid>
        ))}
    </Grid>
  );
};
export default SkeletonList;
