import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useRef,
  useState,
} from "react";

/*
Example of using the useConfirmation:

import { Button } from '@mui/material';
import { useConfirmation } from '../components/ConfirmationDialog';

const MyComponent = () => {
    const confirm = useConfirmation();
    const handleClick = () => {
        confirm({
            title: 'Are you sure you want to do this?',
            description: 'Are you really sure?'
        })
        .then(() => console.log('cool!'))
        .catch(() => console.log('you canceled'))
        // This is optional,
        in order to achieve this add the "catchOnCancel" property
        in the confirm function
    };

    return (
        <Button onClick={handleClick}>
            Click
        </Button>
    )
};
*/

const ConfirmationServiceContext = createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject);

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  title?: string;
  description?: string | ReactElement;
  action?: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ConfirmationDialog: FC<
  Omit<ConfirmationDialogProps, "catchOnCancel">
> = ({
  open,
  title = "Confirm the following action",
  description = "Are you sure you want to confirm the following action?",
  action = "Confirm",
  onSubmit,
  onClose,
}) => (
  <Dialog open={open}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onClose} autoFocus>
        Cancel
      </Button>
      <Button color="primary" onClick={onSubmit}>
        {action}
      </Button>
    </DialogActions>
  </Dialog>
);

export const useConfirmation = () => useContext(ConfirmationServiceContext);

export const ConfirmationProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [confirmationState, setConfirmationState] =
    useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};

export default ConfirmationDialog;
