import { useAmplitude } from "react-amplitude-hooks";

export const useRunEvents = (globalProperties: any = {}) => {
  const { logEvent } = useAmplitude((inheritedProps: any = {}) => ({
    ...inheritedProps,
    ...globalProperties,
    scope: [
      "run",
      ...(globalProperties.scope || []),
      ...(inheritedProps.scope || []),
    ],
  }));

  return {
    beginFlowEvent: (properties?: object) =>
      logEvent("run.beginFlow", properties),
    completeInputsStepEvent: (properties?: object) =>
      logEvent("run.completeInputsStep", properties),
    completeFlowEvent: (properties?: object) =>
      logEvent("run.completeFlow", properties),
    chooseDataSourceEvent: (properties?: object) =>
      logEvent("run.chooseDataSource", properties),
  };
};
