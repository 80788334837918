import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import dayjs from "../../../utils";
import { FilterOption } from "./BoardFilters";
import { SearchParam } from "../../../hooks/useSearchParams";
import { CircuitBoard } from "../../../types";
import { Filters } from "../types";
import { FC } from "react";

export const customFilters: FilterOption[] = [
  {
    label: "Created by Me",
    name: "is_created_by_me",
  },
  {
    label: "Run in the last 7 days",
    name: "is_run_in_past_7_days",
  },
];

export const filterBoardsByCustomFilters =
  (props: { currentUserId: string; search: Filters }) => (b: CircuitBoard) => {
    if (
      props.search.is_created_by_me &&
      b.created_by_id !== props.currentUserId
    ) {
      return false;
    }
    const hasRunInThePast7Days = dayjs(b.latest_run).isAfter(
      dayjs().subtract(7, "day")
    );
    return !(props.search.is_run_in_past_7_days && !hasRunInThePast7Days);
  };

export const CustomFilters: FC<{
  filters?: Record<string, SearchParam>;
  onChange: (filters: Record<string, any>) => void;
}> = ({ filters, onChange }) => (
  <FormGroup>
    <Typography variant="subtitle2">Filter</Typography>
    {customFilters.map((boardFilter) => (
      <FormControlLabel
        key={boardFilter.name}
        control={
          <Checkbox
            disableRipple
            color="primary"
            name={boardFilter.name}
            checked={!!(filters && filters[boardFilter.name])}
            onChange={(_, value) =>
              onChange({ ...filters, [boardFilter.name]: value })
            }
          />
        }
        label={boardFilter.label}
      />
    ))}
  </FormGroup>
);
