import { Box, Skeleton, Typography } from "@mui/material";
import theme from "../../themes/light";
import { Fragment, useEffect, useState } from "react";
import ChatListRow from "./ChatListRow";
import { useInsightChatContext } from "./hooks/selectors";
// import { useHistory } from "react-router-dom";

interface props {
  setSession: (id: string) => void;
  handleOpenShareDialog: (title: string, link: string) => void;
  handleOpenSyncDialog: (id: string) => any;
  copyStoryboard: (id: string) => any;
}
interface DataEntry {
  id: string;
  user_email: string;
  elements: Element[];
  updated_at: string;
  created_at: string;
}
interface Output {
  today: (DataEntry | { isLoader: true })[];
  yesterday: DataEntry[];
  last7days: DataEntry[];
  moreThan7days?: DataEntry[];
}
const initialOutput: Output = {
  today: [],
  yesterday: [],
  last7days: [],
  moreThan7days: [],
};

const ChatList = ({
  setSession,
  handleOpenShareDialog,
  handleOpenSyncDialog,
  copyStoryboard,
}: props) => {
  const { storyboardList, newStoryboardToListPending } =
    useInsightChatContext();
  //const history = useHistory();

  const [questionTextMapping, setQuestionTextMapping] =
    useState<Output>(initialOutput);
  function withinLast7Days(dateStr: string): boolean {
    const createdAt: Date = new Date(dateStr);
    const today: Date = new Date();
    const diffTime: number = Math.abs(today.getTime() - createdAt.getTime());
    const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  }

  useEffect(() => {
    const todayDate: Date = new Date();
    const todays: Date = new Date();
    const yesterday: Date = new Date(todays);
    yesterday.setDate(todays.getDate() - 1);
    const output: Output = {
      today: [],
      yesterday: [],
      last7days: [],
      moreThan7days: [],
    };
    const sortQuestionList = storyboardList?.sort(
      (a: any, b: any) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    sortQuestionList?.forEach((entry: any) => {
      const createdAt: Date = new Date(entry.created_at);
      if (createdAt.toDateString() === todayDate.toDateString()) {
        output.today.push(entry);
      } else if (createdAt.toDateString() === yesterday.toDateString()) {
        output.yesterday.push(entry);
      } else if (
        withinLast7Days(entry.created_at) &&
        !output.today.includes(entry) &&
        !output.yesterday.includes(entry)
      ) {
        output.last7days.push(entry);
      } else {
        output.moreThan7days?.push(entry);
      }
    });
    setQuestionTextMapping(output);
  }, [storyboardList, newStoryboardToListPending]);

  const LabeledList = ({ label, children }: any) =>
    children &&
    children.length > 0 && (
      <Box>
        <Typography
          sx={{
            marginBottom: "12px",
            fontSize: "12px",
            fontWeight: 500,
            color: theme.palette.grey[400],
          }}
        >
          {label}
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
          {children.map((question: any) => (
            <Fragment key={`chat_list3_${question.id}`}>
              {question?.isLoader && (
                <Skeleton animation="wave" height={60} width={"100%"} />
              )}
              {question?.title && (
                <ChatListRow
                  question={question}
                  setSession={setSession}
                  handleOpenShareDialog={handleOpenShareDialog}
                  handleOpenSyncDialog={handleOpenSyncDialog}
                  copyStoryboard={copyStoryboard}
                />
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          marginTop: "18px",
          height: "calc(100vh - 120px)",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <LabeledList
          label="Today"
          children={
            newStoryboardToListPending
              ? [{ isLoader: true }, ...questionTextMapping.today]
              : questionTextMapping.today
          }
        />
        <LabeledList
          label="Yesterday"
          children={questionTextMapping.yesterday}
        />
        <LabeledList
          label="Last 7 days"
          children={questionTextMapping.last7days}
        />
        <LabeledList
          label="More than 7 days"
          children={questionTextMapping.moreThan7days}
        />
      </Box>
    </>
  );
};
export default ChatList;
