import { Card, Stack, Typography } from "@mui/material";

import { SkeletonRow } from "../../../../../components";
import { useSchedules } from "../../../../../services/schedules";
import ScheduleBox from "./ScheduleBox";
import { FC } from "react";

const SchedulesCard: FC<{ boardId: string }> = ({ boardId }) => {
  const { data: schedules, isLoading } = useSchedules(boardId);
  return (
    <Card
      sx={(theme) => ({
        borderTopWidth: theme.spacing(0.5),
        borderTopColor: "grey.500",
      })}
    >
      <Stack>
        {isLoading ? (
          <SkeletonRow index={0} />
        ) : schedules && schedules.length > 0 ? (
          schedules.map((schedule) => <ScheduleBox schedule={schedule} />)
        ) : (
          <Typography padding={2}>
            This block doesn&apos;t have schedules
          </Typography>
        )}
      </Stack>
    </Card>
  );
};

export default SchedulesCard;
