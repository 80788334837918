import "ag-grid-enterprise";
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IGetRowsParams,
} from "@ag-grid-community/core";
import { useMutation } from "react-query";
import { useState } from "react";
import { InputDataRequest } from "../../../../../types";
import { getInputData } from "../../../../../services/runs/api";
import { cellRenderer, indexColumnsParams } from "./utils";
import { AgGridHookResult } from "./types";

const convertToAgGridRowFormat: (data: Record<string, any>) => any[] = (
  data
) => {
  const result = Array.from(
    { length: Object.values(data)[0].length },
    () => ({})
  );
  Object.entries(data).forEach(([k, v]) =>
    v.forEach(
      (value: any, index: number) =>
        (result[index] = { ...result[index], [k]: value })
    )
  );
  return result;
};

const useInputDataGrid = (
  boardId: string,
  runId: string,
  inputName: string | null,
  onError?: (error: any) => void
): AgGridHookResult => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columns, setColumns] = useState<ColDef[]>([]);
  const convertColumnNamesToColDef: (columnNames: string[]) => ColDef[] = (
    columnNames
  ) =>
    columnNames.map((columnName) => ({
      headerName: columnName,
      field: columnName,
      sortable: false,
      cellRenderer,
    }));
  const mutation = useMutation(getInputData);

  const errorMessage = mutation.error
    ? mutation.error instanceof Error
      ? "No data was uploaded for this optional input."
      : "We are sorry but we are not able to complete the request"
    : null;

  const datasource = {
    getRows: (params: IGetRowsParams) => {
      if (!inputName) {
        params.successCallback([]);
        return;
      }
      mutation
        .mutateAsync({
          boardId,
          runId,
          inputName,
          limit: 100,
          offset: params.startRow,
        } as InputDataRequest)
        .then((response) => {
          setColumns(convertColumnNamesToColDef(Object.keys(response)));
          params.successCallback(convertToAgGridRowFormat(response));
        })
        .catch((error) => onError && onError(error));
    },
  };

  const reset = () => {
    mutation.reset();
    if (gridApi) {
      gridApi.setGridOption('columnDefs', columns)
      gridApi.setGridOption('datasource', datasource)
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.setGridOption('datasource', datasource)
  };

  return {
    onGridReady,
    columns: [indexColumnsParams as unknown as ColDef, ...columns],
    isLoading: mutation.isLoading,
    errorMessage,
    reset,
  };
};
export default useInputDataGrid;
