import { useHistory } from "react-router";

import { LeftNavBarItem } from "./LeftNavBarItem";
import { Dispatch, FC, SetStateAction } from "react";
import { CATALOG_ENRICHMENT_ROUTE } from "../Router";
import CategoryIcon from "@mui/icons-material/Category";

export const CatalogEnrichmentNavItem: FC<{
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}> = ({ tab, setTab }) => {
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={tab === CATALOG_ENRICHMENT_ROUTE}
      icon={<CategoryIcon sx={{ color: "white" }} />}
      onClick={() => {
        setTab(CATALOG_ENRICHMENT_ROUTE);
        history.push(CATALOG_ENRICHMENT_ROUTE);
      }}
      label="Catalog Enrichment"
    />
  );
};
