import { useQuery, UseQueryOptions } from "react-query";
import { Run } from "../../types";
import apiRequest, { APIError } from "../apiRequest";

export async function getRuns(
  boardId: string,
  params?: object
): Promise<Run[]> {
  return await apiRequest<Run[]>(
    `circuitboards/${boardId}/runs`,
    "GET",
    params
  );
}

export default function useRuns(
  boardId: string,
  params?: object,
  options?: UseQueryOptions<Run[], APIError>
) {
  return useQuery<Run[], APIError>(
    ["runs", boardId],
    () => getRuns(boardId, params),
    options
  );
}
