import { FC, useMemo } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Theme } from "@mui/material/styles";
import { useInsightsContext } from "./hooks/selectors";

export enum SummaryVariants {
  Large = "large",
  Smaller = "smaller",
  Minimized = "minimized",
}

const mapBorder = (variant: SummaryVariants, theme: Theme): string => {
  if (variant === SummaryVariants.Large) return "";
  if (variant === SummaryVariants.Minimized)
    return `1px solid ${theme.palette.borders.lines}`;
  if (variant === SummaryVariants.Smaller)
    return `${theme.spacing(1 / 8)} ${theme.palette.borders.lines} solid`;
  throw Error(`not supported variant - ${String(variant)}`);
};

export const mapMainFontStyle = (
  variant: SummaryVariants
): "h2_v2" | "h3_v2" | "headline3" => {
  if (variant === SummaryVariants.Large) return "h2_v2";
  if (variant === SummaryVariants.Minimized) return "h3_v2";
  if (variant === SummaryVariants.Smaller) return "headline3";
  throw Error(`not supported variant - ${String(variant)}`);
};

const currencyFormat = new Intl.NumberFormat("en-US", {
  minimumIntegerDigits: 1,
  style: "currency",
  currency: "USD",
});
export const formatCurrency = (value: number): string => {
  const parts = currencyFormat.formatToParts(value);
  const integerParts = parts.filter((p) => p.type === "integer");
  if (integerParts.length === 1) {
    return `$${integerParts[0].value.toString()}`;
  }
  if (integerParts.length === 2) {
    if (Number.parseFloat(integerParts[0].value) > 99) {
      return `${integerParts[0].value.toString()}K`;
    }
    return `$${integerParts[0].value.toString()}.${
      integerParts[1].value.toString()[0]
    }K`;
  }
  return `$${integerParts
    .slice(0, -2)
    .map((p) => p.value)
    .join("")}.${integerParts.slice(-2)[0].value.toString()[0]}M`;
};

export const formatCurrencyOnlyNumber = (value: number): string => {
  const parts = currencyFormat?.formatToParts(value);
  const integerParts = parts?.filter((p) => p.type === "integer");
  if (integerParts?.length === 1) {
    return `${integerParts[0]?.value?.toString()}`;
  }
  if (integerParts.length === 2) {
    if (Number?.parseFloat(integerParts[0]?.value) > 99) {
      return `${integerParts[0]?.value?.toString()}K`;
    }
    return `${integerParts[0]?.value?.toString()}.${
      integerParts[1]?.value?.toString()[0]
    }K`;
  }
  return `${integerParts
    ?.slice(0, -2)
    ?.map((p) => p.value)
    ?.join("")}.${integerParts?.slice(-2)[0]?.value?.toString()[0]}M`;
};

const PercentageChange: FC<{
  positive?: boolean;
  percentageChange?: number;
}> = ({ positive, percentageChange }) => (
  <Stack direction="row" alignItems="end" pl={0.5}>
    {percentageChange == null || Number.isNaN(percentageChange) ? (
      <Typography
        variant="body4"
        sx={{
          width: "20px",
          height: "20px",
        }}
      >
        (n/a)
      </Typography>
    ) : (
      <>
        {positive != null &&
          (positive ? (
            <NorthEastIcon
              sx={{
                width: "20px",
                height: "20px",
                color: (theme) =>
                  positive
                    ? theme.palette.text.success
                    : theme.palette.error.main,
              }}
            />
          ) : (
            <SouthEastIcon
              sx={{
                width: "20px",
                height: "20px",
                color: (theme) =>
                  positive
                    ? theme.palette.text.success
                    : theme.palette.error.main,
              }}
            />
          ))}
        {Number.isFinite(percentageChange) && (
          <Typography
            variant="body4"
            sx={{
              color: (theme) =>
                positive != null
                  ? positive
                    ? theme.palette.text.success
                    : theme.palette.error.main
                  : theme.palette.text.secondary,
            }}
          >
            {percentageChange.toFixed(1).replaceAll(/\.0(0)?/g, "")}%
          </Typography>
        )}
      </>
    )}
  </Stack>
);
const SummaryV2 = ({
  total,
  numberChange7D,
  percentageChange7D,
  numberChange30D,
  percentageChange30D,
  variant = SummaryVariants.Large,
  isTab,
}: {
  total: number;
  numberChange7D?: number;
  percentageChange7D?: number;
  numberChange30D?: number;
  percentageChange30D?: number;
  variant?: SummaryVariants;
  isTab?: boolean;
}) => {
  const { statsPeriod } = useInsightsContext();
  const { percentageChange, numberChange } = useMemo(() => {
    if (statsPeriod === "7 days") {
      return {
        percentageChange: percentageChange7D,
        numberChange: numberChange7D,
      };
    }
    return {
      percentageChange: percentageChange30D,
      numberChange: numberChange30D,
    };
  }, [
    statsPeriod,
    numberChange7D,
    percentageChange7D,
    numberChange30D,
    percentageChange30D,
  ]);
  const positive: boolean | undefined = numberChange
    ? numberChange > 0
    : undefined;
  const small = variant === SummaryVariants.Minimized;
  const theme = useTheme();

  const SmallSummary = (
    <Stack>
      <Typography
        variant="caption"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        {numberChange === 0
          ? "No change "
          : `${positive != null ? (positive ? "+" : "-") : ""}$${Math.abs(
              numberChange || 0
            ).toLocaleString()} `}
        {`last ${statsPeriod === "7 days" ? "7d" : "30d"}`}
      </Typography>
    </Stack>
  );

  const Display =
    numberChange == null ? (
      <Typography variant="caption" color="text.secondary">
        No sales data
      </Typography>
    ) : (
      <>
        <Stack direction="row" alignItems="end">
          <Typography variant={mapMainFontStyle(variant)}>
            {formatCurrency(total)}
          </Typography>
          <PercentageChange
            positive={positive}
            percentageChange={percentageChange}
          />
        </Stack>
        {isTab === false ? "" : <>{!small && SmallSummary}</>}
      </>
    );
  return (
    <Box
      role="revenue-box"
      sx={{
        paddingX:
          isTab === false ? 0 : variant === SummaryVariants.Smaller ? 1.5 : 0.5,
        paddingY:
          isTab === false ? 0 : variant === SummaryVariants.Smaller ? 1 : 0.25,
        marginRight: isTab === false ? 0 : "16px",
        borderRadius:
          variant === SummaryVariants.Smaller
            ? theme.spacing(1)
            : theme.spacing(0.5),
        border: isTab === false ? "none" : (theme) => mapBorder(variant, theme),
      }}
    >
      {Display}
    </Box>
  );
};

export default SummaryV2;
