import { FC, ReactNode } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useRouteMatch } from "react-router";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";
import OutputsCheckboxGroup from "./OutputsCheckboxGroup";
import { getDefaultExportName, isFileDestinationType } from "./utils";
import { useExportEvents } from "../../../../../hooks/metrics/exportEvents";
import { useRun } from "../../../../../services/runs";
import { toSnakeCase } from "../../../../../utils";
import { DestinationType } from "../../../../../enums";
import { useUrlOutput } from "./hooks";
import { DestinationTitleMapping } from "../../../../../mappings";
import { APIError } from "../../../../../services/apiRequest";
import { createExportV2 } from "../../../../../services/exportsV2/api";

export const ExportDialog: FC<{
  type?: DestinationType;
  destinationId?: string;
  onClose: () => void;
  onExportSuccess: () => void;
  children?: ReactNode;
}> = ({ onClose, type, destinationId, onExportSuccess, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const match = useRouteMatch<{ boardId: string; runId: string }>(
    "/boards/:boardId/runs/:runId"
  );
  const { runId = "", boardId = "" } = match?.params || {};

  const selectedOutputName = useUrlOutput();

  const { successfulExportToDestinationEvent, failedExportToDestinationEvent } =
    useExportEvents();

  const { data: run } = useRun(boardId, runId);

  const allOutputs = run && new Set(run.outputs.map((o) => o.export_name));
  const snakeSelectedOutputName = toSnakeCase(selectedOutputName || "");
  const defaultOutputs =
    selectedOutputName && allOutputs && allOutputs.has(snakeSelectedOutputName)
      ? new Set([snakeSelectedOutputName])
      : allOutputs;

  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<{
    outputs: Set<string>;
    name: string;
  }>({
    mode: "onChange",
    defaultValues: {
      outputs: defaultOutputs,
      name: (run && getDefaultExportName(run.name)) || "",
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(createExportV2, {
    onSuccess: () => {
      queryClient.invalidateQueries("listExportsV2");
      successfulExportToDestinationEvent(type!, destinationId);
      onExportSuccess();
      onClose();
    },
    onError: (error: APIError) => {
      failedExportToDestinationEvent(type!, destinationId);
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onSubmit = handleSubmit((formValues) => {
    mutation.mutateAsync({
      boardId,
      runId,
      type: type!,
      destinationId,
      name: formValues.name,
      outputs: [...formValues.outputs],
    });
  });

  const titleSuffix = type
    ? isFileDestinationType(type)
      ? "File"
      : DestinationTitleMapping[type]
    : "";

  return (
    <Dialog open={!!type} onClose={onClose} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>{`Export Run Outputs To ${titleSuffix}`}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Box marginY={1}>
              <TextField
                fullWidth
                label="Name"
                helperText={errors?.name?.message}
                error={!!errors?.name}
                {...register("name", {
                  required: "Name field is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters long",
                  },
                  maxLength: {
                    value: 500,
                    message: "Name must be at shorter than 500 characters",
                  },
                  pattern: {
                    value: /^[ a-zA-Z0-9_-]*$/,
                    message:
                      "Name must be composed of alphanumeric, dash or underscore characters",
                  },
                })}
                defaultValue={(run && getDefaultExportName(run.name)) || ""}
              />
            </Box>
            {children && <Box marginY={1}>{children}</Box>}
            {defaultOutputs && (
              <OutputsCheckboxGroup
                control={control}
                getOutputs={() => getValues("outputs")}
                defaultChecked={defaultOutputs}
                boardId={boardId}
                runId={runId}
                error={
                  errors.outputs && "Required at least 1 output to download"
                }
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={mutation.isLoading}
            disabled={isSubmitting || !isValid}
          >
            Start
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ExportDialog;
