import { Box, Typography, useTheme } from "@mui/material";

import SVG from "react-inlinesvg";
import { BlockIcon } from "../../../components/BlockIcon";
import { visibilityFilters } from "../BoardFilters/VisibilityFilters";
import { Board } from "./BoardsTable";

export const BoardCellComponent = (props: any) => {
  const theme = useTheme();
  const board = props.cell.row.original as Board;
  const IconComp = (
    visibilityFilters.find((v) => v.name === board.visibility) ||
    visibilityFilters[1]
  ).icon;
  const fontColor = "grey.500";
  const spacerColor = "grey.300";
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <BlockIcon image={board.image || ""} isLatest={board.isLatest} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography>{board.displayName}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
          <IconComp sx={{ mr: 0.5, width: 15, height: 15, color: fontColor }} />
          {board.bigquery_warehouse_export_enabled && (
            <>
              <Typography variant="body2" sx={{ mr: 0.5, color: spacerColor }}>
                •
              </Typography>
              <Box sx={{ ml: 0.2, pt: 1, mr: 0.5, pb: 0.7 }}>
                <SVG
                  fill={theme.palette.grey[500]}
                  src={`/dwh-icon.svg`}
                  height="11px"
                  width="11px"
                />
              </Box>
            </>
          )}
          <Typography variant="body2" sx={{ mr: 0.5, color: spacerColor }}>
            •
          </Typography>
          <Typography variant="body2" sx={{ color: fontColor }}>
            {board.templateName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
