import { useEffect } from "react";

import {
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/styles";
import { Icon } from "../../../components/Icon";
import { useInsightsContext } from "../hooks/selectors";
import { NewSortOptions } from "../types";

const label = {
  sales7D: "Sales (last 7d)",
  changePercentage7D: "Change % (last 7d)",
  changeNumber7D: "Change $ (last 7d)",
  sales30D: "Sales (last 30d)",
  changePercentage30D: "Change % (last 30d)",
  changeNumber30D: "Change $ (last 30d)",
};

const RevenueSortDropdown = () => {
  const { sort, setSort, statsPeriod } = useInsightsContext();
  const theme = useTheme();
  const stats = statsPeriod === "7 days" ? "7D" : "30D";
  useEffect(() => {
    if (sort.column.indexOf(stats) < 0) {
      setSort({ column: `sales${stats}`, ascending: false, isDirty: true });
    }
  }, [stats]);
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
      <Select
        size="small"
        renderValue={(val: NewSortOptions) => (
          <Stack
            direction="row"
            gap="4px"
            alignItems="center"
            justifyContent="end"
            width="100%"
          >
            <Typography variant="body4" color={theme.palette.text.secondary}>
              Sort by:
            </Typography>{" "}
            {sort.ascending ? (
              <Icon
                src="insights/sort-ascending.svg"
                width="16px"
                height="16px"
              />
            ) : (
              <Icon
                src="insights/sort-descending.svg"
                width="16px"
                height="16px"
              />
            )}
            {label[val]}
          </Stack>
        )}
        sx={{ minWidth: "250px" }}
        value={sort.column as NewSortOptions}
        onChange={(e) => {
          setSort({
            column: e.target.value as NewSortOptions,
            ascending: sort.ascending,
            isDirty: true,
          });
        }}
      >
        <ListSubheader sx={{ padding: 0, minWidth: "250px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: "16px" }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              color={theme.palette.secondary.main}
            >
              Sort by
            </Typography>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
              size="small"
            >
              <Button
                disabled={!sort.ascending}
                onClick={() =>
                  setSort({
                    column: sort.column,
                    ascending: false,
                    isDirty: true,
                  })
                }
                sx={{
                  background: theme.palette.backgrounds.white,
                  "&[disabled]": {
                    background: theme.palette.backgrounds.default,
                  },
                  "> svg": {
                    color: theme.palette.icons.secondary,
                  },
                  "&[disabled] > svg": {
                    color: theme.palette.icons.default,
                  },
                }}
              >
                <Icon
                  src="insights/sort-descending.svg"
                  width="16px"
                  height="16px"
                />
              </Button>
              <Button
                disabled={sort.ascending}
                onClick={() =>
                  setSort({
                    column: sort.column,
                    ascending: true,
                    isDirty: true,
                  })
                }
                sx={{
                  background: theme.palette.backgrounds.white,
                  "&[disabled]": {
                    background: theme.palette.backgrounds.default,
                  },
                  "> svg": {
                    color: theme.palette.icons.secondary,
                  },
                  "&[disabled] > svg": {
                    color: theme.palette.icons.default,
                  },
                }}
              >
                <Icon
                  src="insights/sort-ascending.svg"
                  width="16px"
                  height="16px"
                />
              </Button>
            </ButtonGroup>
          </Stack>
        </ListSubheader>
        <MenuItem dense value={`sales${stats}`}>
          <Typography variant="body3">Sales</Typography>
        </MenuItem>
        <MenuItem dense value={`changePercentage${stats}`}>
          <Typography variant="body3">Change %</Typography>
        </MenuItem>
        <MenuItem dense value={`changeNumber${stats}`}>
          <Typography variant="body3">Change $</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default RevenueSortDropdown;
