import { Collapse } from "@mui/material";

import ParametersTable from "../../../../components/ParametersTable";
import { useRun } from "../../../../services/runs";
import { BoardAndRunIds, RunParameter } from "../../../../types";
import ParametersDropdown from "./ParametersDropdown";
import { FC, useState } from "react";

const Parameters: FC<BoardAndRunIds> = ({ boardId, runId }) => {
  const { data: run } = useRun(boardId, runId);
  const [open, setOpen] = useState(true);
  return run ? (
    open ? (
      <Collapse in={open}>
        <ParametersTable
          parameters={run.parameters.map(
            (parameter) => parameter as RunParameter
          )}
          open={open}
          setOpen={setOpen}
        />
      </Collapse>
    ) : (
      <ParametersDropdown open={open} setOpen={setOpen} />
    )
  ) : null;
};
export default Parameters;
