import {FC, MouseEventHandler} from "react";
import {IconButton, ListItemIcon, ListItemText, MenuItem, MenuItemProps, Stack,} from "@mui/material";
import {Cancel, Edit} from "@mui/icons-material";
import {DashboardFilters} from "../types";

type DashboardFiltersMenuItemProps = Omit<MenuItemProps, ""> & {
  dashboardFilter: DashboardFilters;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
};

export const DashboardFiltersMenuItem: FC<DashboardFiltersMenuItemProps> = ({
                                                                              onEdit,
                                                                              onDelete,
  dashboardFilter,
  ...menuProps
}) => (
  <MenuItem {...menuProps}>
    <ListItemText>{dashboardFilter.name}</ListItemText>
    {!onEdit && !onDelete ? null : (
      <ListItemIcon>
        <Stack
          spacing={0.5}
          ml={3}
          direction="row"
          sx={{
            ".MuiIconButton-root": {
              background: "none",
              color: "primary.dark",
              "&:hover": {
                color: "primary.main",
              },
            },
          }}
        >
          <IconButton size="small" onClick={onEdit}>
            <Edit fontSize="small" />
          </IconButton>

          <IconButton size="small" onClick={onDelete}>
            <Cancel fontSize="small" />
          </IconButton>
        </Stack>
      </ListItemIcon>
    )}
  </MenuItem>
);
