import React from "react";
import { IconButton, Typography, Box } from "@mui/material";

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: number | string;
}

const Day: React.FunctionComponent<DayProps> = ({
  startOfRange,
  endOfRange,
  disabled,
  highlighted,
  outlined,
  filled,
  onClick,
  onHover,
  value,
}: DayProps) => {
  return (
    <Box
      sx={{
        display: "flex",

        borderRadius: startOfRange
          ? "50% 0 0 50%"
          : endOfRange
          ? "0 50% 50% 0"
          : undefined,
        backgroundColor: (theme) =>
          !disabled && highlighted ? theme.palette.primary.light : undefined,
      }}
    >
      <IconButton
        sx={{
          height: "36px",
          width: "36px",
          padding: 0,
          border: (theme) =>
            !disabled && outlined
              ? `1px solid ${theme.palette.primary.dark}`
              : undefined,
          ...(!disabled && filled
            ? {
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.dark,
                },
                backgroundColor: (theme) => theme.palette.primary.main,
              }
            : {
                backgroundColor: "unset",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.light,
                },
              }),
        }}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
        // size="large"
      >
        <Typography
          sx={{
            lineHeight: 1.6,
            color: (theme) =>
              !disabled
                ? filled
                  ? theme.palette.primary.contrastText
                  : highlighted
                  ? theme.palette.primary.main
                  : theme.palette.text.primary
                : theme.palette.text.secondary,
            opacity: disabled ? 0.5 : 1,
            fontSize: "12px",
            fontWeight: "500",
          }}
          variant="body2"
        >
          {value}
        </Typography>
      </IconButton>
    </Box>
  );
};

export default Day;
