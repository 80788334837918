import { enqueueSnackbar } from "notistack";
import { Box, Typography } from "@mui/material";
import { closeSnackbar } from "notistack";
import { CloseIcon, TaskIcon } from "../../assets";
import theme from "../../themes/light";
import { updateTask } from "./useTaskApi";
import { Dispatch, SetStateAction } from "react";

const handleCancel = (snackbarId: any) => {
  closeSnackbar(snackbarId);
};

const handleUndo = async (taskId: string, snackbarId: any, type?: boolean, setIsUpdated?:Dispatch<SetStateAction<boolean>>) => {
  if(setIsUpdated){
    setIsUpdated(false)
  }
  const body = {
    status: "archived",
    archived: type ? type : false,
  };
  await updateTask(body, taskId);
 if(setIsUpdated){
  setIsUpdated(true)
 }
  handleCancel(snackbarId);
};

type actionType = {
  taskId?: string;
  key: any;
  handleBoardLink?: any;
  message?: string;
  type?: boolean;
  setIsUpdated?:Dispatch<SetStateAction<boolean>>
};

const action = ({
  taskId,
  key: snackbarId,
  handleBoardLink,
  message,
  type,
  setIsUpdated
}: actionType) => (
  <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
    {message !== "Task Archived" && message!== "Task archived" && message !== "Task was moved back to board" && (
      <Typography
        variant="body5"
        sx={{
          color: "white",
          cursor: "pointer",
          fontWeight: 600,
          padding: "0px 16px",
        }}
        onClick={() => handleBoardLink()}
      >
        View tasks
      </Typography>
    )}
    {taskId && (
      <Typography
        variant="body5"
        sx={{
          color: "white",
          cursor: "pointer",
          fontWeight: 600,
          padding: "0px 16px",
        }}
        onClick={() => handleUndo(taskId, snackbarId, type, setIsUpdated)}
      >
        Undo
      </Typography>
    )}
    <Box
      sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => handleCancel(snackbarId)}
    >
      <CloseIcon />
    </Box>
  </Box>
);

export const handleToast = (
  message: string,
  taskId?: string,
  handleBoardLink?: any,
  type?: boolean,
  setIsUpdated?:Dispatch<SetStateAction<boolean>>
) => {
  enqueueSnackbar("Custom snackbar message", {
    variant: "success",
    content: (key) => (
      <Box
        display="flex"
        alignItems="center"
        sx={{
          background: theme.palette.text.primary,
          minHeight: " 70px",
          borderRadius: "4px",
          justifyContent: "space-between",
        }}
        onClick={() => handleCancel(key)}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          gap="8px"
          sx={{
            padding: "8px 20px ",
            "& svg": { "& path": { fill: theme.palette.backgrounds.white } },
          }}
        >
          <TaskIcon />
          <Box>
            <Typography
              variant="body3"
              color="#fff"
              sx={{ fontWeight: 600, display: "block" }}
            >
              {message}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          gap="16px"
          alignItems="center"
          sx={{ paddingRight: "16px" }}
        >
          {action({ taskId, key, handleBoardLink, message, type, setIsUpdated })}
        </Box>
      </Box>
    ),
  });
};
