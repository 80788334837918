import LaunchIcon from "@mui/icons-material/Launch";
import { alpha, Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "../../../utils";

import { useBoard } from "../../../services/boards";
import usePublishedRun from "./hooks/usePublishedRun";
import { FC } from "react";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  publishedRunStrip: {
    background: alpha(theme.palette.primary.dark, 0.06),
    height: theme.spacing(8),
  },
}));

const PublishedRunBox: FC<{
  boardId: string;
}> = ({ boardId }) => {
  const { data: board } = useBoard(boardId);
  const { data: publishedRun } = usePublishedRun(boardId);
  const appName = board?.applications[0]?.name;

  return (
    <Box
      pl={2}
      pr={2}
      pt={0.5}
      pb={0.5}
      className={useStyles().publishedRunStrip}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex">
        <Box marginRight={1}>
          <LaunchIcon />
        </Box>
        <Typography variant="body1">
          This run was published on{" "}
          {`${dayjs(publishedRun?.created_at).format("MMMM D, YYYY")} by ${
            publishedRun?.user_name || "Noogata Internal Process"
          }`}
          . This run’s data will be used in the {appName} App.
        </Typography>
      </Box>
    </Box>
  );
};
export default PublishedRunBox;
