import { Button } from "@mui/material";
import { FC } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router";
import { useAppContext } from "../ApplicationContext";

export const AppModeIcon = () => (
  <SVG fill="inherit" src={"/app-mode-icon.svg"} />
);

export const DataLabModeIcon = () => (
  <SVG fill="inherit" src={"/data-lab-mode-icon.svg"} />
);

const AppModeButton: FC = ({ label }: { label?: string }) => {
  const history = useHistory();
  const { applicationMode, setApplicationMode } = useAppContext();

  return (
    <Button
      variant="contained"
      onClick={() => {
        setApplicationMode(!applicationMode);
        history.push("/");
      }}
      startIcon={applicationMode ? <DataLabModeIcon /> : <AppModeIcon />}
      fullWidth
    >
      {label ? label : applicationMode ? "Enter data lab" : "Back to app"}
    </Button>
  );
};

export default AppModeButton;
