import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Icon } from "../../../../components/Icon";
import { useTabsButtons } from "./useTabsButtons";

const BackToFeedBtn = ({
  insightId,
  outlined,
}: {
  insightId: string;
  outlined?: boolean;
}) => {
  const theme = useTheme();
  const { setDismissed } = useTabsButtons();

  return (
    <Button size="small" variant={outlined ? "outlined" : "text"}>
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          color: theme.palette.text.primary,
          minHeight: "18px",
          padding: "3px",
          fontSize: "14px",
          fontWeight: "600",
          maxHeight: "24px",
          height: "24px",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDismissed(insightId, 0);
        }}
      >
        <Icon src="insights/back-icon.svg" width="16px" height="16px" />
        Back to feed
      </Stack>
    </Button>
  );
};

export default BackToFeedBtn;
