import type { CustomNoRowsOverlayProps } from "@ag-grid-community/react";
import { Chip } from "@mui/material";

const NoRowsOverlay = (
  props: CustomNoRowsOverlayProps & { noRowsMessageFunc: () => string }
) => {
  const msg = props.noRowsMessageFunc();
  return (
    <Chip variant="outlined" label={msg} color="primary" />
  );
};

export default NoRowsOverlay
