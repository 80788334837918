import { Dayjs } from "dayjs";
import dayjs from "../../utils";

const DateParser = (date: Dayjs) => {
  if (dayjs(date).isToday()) return "Today";
  if (dayjs(date).isYesterday()) return "Yesterday";
  if (dayjs(date).diff(dayjs(), "days") >= -5) return date.fromNow();
  if (dayjs(date).diff(dayjs(), "years") < 1) return date.format("MMM D");

  return date.format("MMM D, YYYY");
};

export default DateParser;
