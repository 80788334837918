import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Select,
  Skeleton,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import Loadable from "../../components/Loadable";
import { VisibilityMenu } from "../../components/VisibilityMenu";
import { VisibilityOptions, VisibilityType } from "../../enums";
import { useMarketplaceEvents } from "../../hooks/metrics/marketplaceEvents";
import { createBoard } from "../../services/circuitboards";
import { BoardTemplate } from "../../types/board-templates";
import { useRouteMetrics } from "../../hooks/metrics/routeEvents";
import useFlow from "../../services/Flows/useFlow";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "#F5F5F5",
    marginBottom: theme.spacing(5),
  },
  header: {
    paddingTop: theme.spacing(5),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  boardIcon: {
    width: theme.spacing(6.3),
    height: theme.spacing(6.3),
  },
  primaryIconColor: {
    color: theme.palette.common.white,
  },
}));

const NewBoard: FC<{ boardTemplate?: BoardTemplate }> = ({ boardTemplate }) => {
  useRouteMetrics(["/marketplace/:boardTemplateId/new"], {
    scope: ["marketplace"],
  });
  const classes = useStyles();
  const history = useHistory();
  const { installBoardEvent } = useMarketplaceEvents();

  const location = useLocation<{ flowId: string }>();
  const [visibility, setVisibility] = useState(VisibilityType.PRIVATE);
  const { flowId } = location?.state || { flowId: undefined };

  const { refetch } = useFlow(flowId, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handler = (value: VisibilityType) => {
    setVisibility(value);
  };

  const { formState, handleSubmit, control, register } = useForm<{
    displayName: string;
    description: string;
    visibility?: VisibilityType;
  }>({
    mode: "onChange",
    defaultValues: {
      displayName: "",
      description: "",
      visibility,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (formValues) => {
    try {
      const boardId = formValues.displayName.replace(/\s+/g, "-").toLowerCase();
      const { flowId } = location?.state || { flowId: undefined };
      const createdBoard = await createBoard({
        id: boardId,
        template_id: boardTemplate?.id as string,
        version: boardTemplate?.version as string,
        values: "",
        displayName: formValues.displayName,
        description: formValues.description,
        visibility: flowId ? undefined : visibility,
        flowId,
      });
      enqueueSnackbar(`Success! ${createdBoard.displayName} has been added`, {
        variant: "success",
      });
      installBoardEvent({ success: true, id: boardTemplate?.id });
      if (flowId) {
        await refetch();
      }
      history.push(`/boards/${createdBoard.identifier}`);
    } catch (error: any) {
      installBoardEvent({ success: false, id: boardTemplate?.id });
      enqueueSnackbar(error.message, { variant: "error" });
    }
  });

  return (
    <Box>
      <Box className={classes.root}>
        <Container maxWidth="lg" className={classes.header}>
          <Grid container direction="column" item>
            {boardTemplate ? (
              <BackButton
                className={classes.item}
                onBack={() => history.goBack()}
                label={`${boardTemplate?.name.toUpperCase()} INFORMATION`}
              />
            ) : (
              <Skeleton width="100px" />
            )}
            <Box display="flex" className={classes.item} alignItems="center">
              {boardTemplate ? (
                <>
                  <img
                    alt=""
                    className={classes.boardIcon}
                    src={boardTemplate?.image}
                  />
                  <Box marginLeft={3}>
                    <Typography variant="h3">{boardTemplate?.name}</Typography>
                  </Box>
                </>
              ) : (
                <Skeleton width="60%" height={50} />
              )}
            </Box>
            <Box marginBottom={5}>
              {boardTemplate ? (
                <Typography variant="body1">
                  {boardTemplate?.description}
                </Typography>
              ) : (
                <Skeleton width="80%" />
              )}
            </Box>
          </Grid>
        </Container>
      </Box>
      <Loadable isLoading={!boardTemplate}>
        <Container>
          <form>
            <TextField
              label="Name"
              defaultValue=""
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              required
              {...register("displayName", { required: true })}
            />
            <TextField
              label="Description"
              defaultValue=""
              variant="outlined"
              multiline
              rows={5}
              margin="dense"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              {...register("description")}
            />

            {!location.state?.flowId && (
              <Controller
                control={control}
                name="visibility"
                defaultValue={VisibilityType.WORKSPACE}
                render={({ onChange, ref }: any) => (
                  <Select
                    renderValue={(visibility: VisibilityType) =>
                      visibility === VisibilityType.PRIVATE ? (
                        <text>{VisibilityOptions.PRIVATE}</text>
                      ) : (
                        <text>{VisibilityOptions.WORKSPACE}</text>
                      )
                    }
                    value={visibility}
                    onChange={onChange}
                    inputRef={ref}
                    sx={{ height: 40, width: 337 }}
                  >
                    <VisibilityMenu handler={handler} />
                  </Select>
                )}
              />
            )}
          </form>
          <Box display="flex" justifyContent="flex-end" marginTop={3}>
            <Box marginRight={2}>
              <Button
                variant="contained"
                disabled={formState.isSubmitting}
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={formState.isSubmitting}
              startIcon={
                formState.isSubmitting ? (
                  <CircularProgress
                    size={20}
                    className={classes.primaryIconColor}
                  />
                ) : null
              }
            >
              Add block
            </Button>
          </Box>
        </Container>
      </Loadable>
    </Box>
  );
};

export default NewBoard;
