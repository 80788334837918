import { FC, ReactNode } from "react";

import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      margin: theme.spacing(2),
    },
  })
);

interface IllustrationPlaceholderProps {
  imageHeight: number;
  imageWidth: number;
  image?: string;
  actions?: ReactNode;
  text: string;
}

const IllustrationPlaceholder: FC<IllustrationPlaceholderProps> = ({
  imageHeight,
  imageWidth,
  image,
  actions,
  text,
}) => {
  const classes = useStyles();
  return (
    <Box
      justifyContent="center"
      textAlign="center"
      display="flex"
      flex={1}
      alignItems="center"
      flexDirection="column"
    >
      <img
        alt={text}
        src={image || `/no-data.svg`}
        height={imageHeight}
        width={imageWidth}
      />
      <Typography variant="h6" align="center" className={classes.typography}>
        {text}
      </Typography>
      {actions}
    </Box>
  );
};

export default IllustrationPlaceholder;
