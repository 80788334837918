import { Redirect, Route, Switch } from "react-router-dom";

import DashboardComponent from "../Dashboards/Dashboard/DashboardComponent";
import Dashboards from "../Dashboards/Dashboards";
import { Insights as InsightsV2 } from "../InsightsV2/Insights";
import { ApplicationType } from "./LeftNavbar/types";
import { PerfectContentWrapper } from "../PerfectContent";
import BreakoutTrends from "../BreakoutTrends";
import AdBooster from "../AdBooster";
import { useSplitEnabled } from "../../hooks";
import { Split } from "../../FeatureFlags/enums";
import { SalesAssistant } from "../SalesAssistant/SalesAssistant";
import { Tracking } from "../SalesAssistant/Tracking";
import { Tasks } from "../Tasks/Task";
import { ArchiveTask } from "../Tasks/ArchiveTask";
//import ChatHistory from "../InsightsChat/ChatHistory";
import { SalesAssistantV1 } from "../SalesAssistantV1/SalesAssistant";
import { SalesProvider as SalesProviderV2 } from "../SalesAssistant/SalesProvider";
import { SalesProvider as SalesProviderV1 } from "../SalesAssistantV1/SalesProvider";
import { InsightsProvider as InsightsProviderV2 } from "../InsightsV2/InsightsProvider";
import { TasksProvider } from "../Tasks/TasksProvider";
import InsightsChatV2 from "../InsightsChatV2/page.tsx";
import { ChatProvider } from "../InsightsChatV2/ChatProvider.tsx";
import SpacesV2 from "../SpacesV2";
import PerfectContentV2 from "../PrefectContentV2";
import CatalogEnrichment from "../CatalogEnrichment/index.tsx";
import SalesAssistantV2 from "../SalesAssistantV2";
import InsightsChats from "../ResearchAssistant/InsightsChat.tsx";
import { InsightChatProvider } from "../ResearchAssistant/InsightsChatProvider.tsx";
import AssortmentIntelligence from "../AssortmentIntelligence/index.tsx";
import { useAppContext } from "../../ApplicationContext.tsx";

export const INSIGHTS_ROUTE = "/insights";
export const INSIGHTS_V2_ROUTE = "/insightsV2";

export const SPACES_ROUTE = "/spaces";
export const CATALOG_ENRICHMENT_ROUTE = "/catalog-enrichment";
export const ASSORTMENT_INTELLIGENCE_ROUTE = "/assortment-intelligence";

const Router = () => {
  const { defaultView } = useAppContext()

  return (
    <Switch>
      <Route
        exact
        path={["/dashboards/:tabName/:dashboardId"]}
        component={DashboardComponent}
      />
      <Route
        exact
        path={["/dashboards", "/dashboards/:tabName"]}
        component={Dashboards}
      />
      <Route
        path={[INSIGHTS_ROUTE, INSIGHTS_V2_ROUTE]}
        render={() => (
          <InsightsProviderV2>
            <InsightsV2 />
          </InsightsProviderV2>
        )}
      />

      <Route
        path={[CATALOG_ENRICHMENT_ROUTE]}
        render={
          useSplitEnabled(Split.CATALOG_ENRICHMENT)
            ? CatalogEnrichment
            : InsightsV2
        }
      />

      <Route
        path={[ASSORTMENT_INTELLIGENCE_ROUTE]}
        render={
          useSplitEnabled(Split.ASSORTMENT_INTELLIGENCE)
            ? AssortmentIntelligence
            : InsightsV2
        }
      />

      <Route
        path={["/chat"]}
        render={
          useSplitEnabled(Split.INSIGHTS_CHAT)
            ? () => (
              <InsightChatProvider>
                <InsightsChats />
              </InsightChatProvider>
            )
            : InsightsV2
        }
      />
      <Route
        path={["/chat-v2"]}
        render={() => (
          <ChatProvider>
            <InsightsChatV2 />
          </ChatProvider>
        )}
      />
      <Route
        path={[`/${ApplicationType.PERFECT_CONTENT}`]}
        component={PerfectContentWrapper}
      />
      <Route
        path={[
          `/${ApplicationType.PERFECT_CONTENT}-v2/:spaceId/:asin`,
          `/${ApplicationType.PERFECT_CONTENT}-v2/:spaceId`,
          `/${ApplicationType.PERFECT_CONTENT}-v2`,
        ]}
        component={PerfectContentV2}
      />
      <Route
        path={[`/${ApplicationType.TRENDSPOTTING}`]}
        component={BreakoutTrends}
      />
      <Route path={["/ad-booster"]} component={AdBooster} />

      <Route
        path={["/sales-assistant/:tab"]}
        render={
          useSplitEnabled(Split.SALES_ASSISTANT_GROUPS)
            ? () => (
              <SalesProviderV2>
                <TasksProvider>
                  <SalesAssistant />
                </TasksProvider>
              </SalesProviderV2>
            )
            :
            () => (
              <SalesProviderV1>
                <TasksProvider>
                  <SalesAssistantV1 />
                </TasksProvider>
              </SalesProviderV1>
            )


        }
      />
      <Route
        path={[
          "/sales-assistant-v2/space/:spaceId",
          "/sales-assistant-v2/:tab",
          "/sales-assistant-v2",
        ]}
        render={SalesAssistantV2}
      />

      <Route
        path={["/tracking"]}
        render={
          useSplitEnabled(Split.SALES_ASSISTANT)
            ? () => (
              <SalesProviderV1>
                <Tracking />
              </SalesProviderV1>
            )
            : () => (
              <InsightsProviderV2>
                <InsightsV2 />
              </InsightsProviderV2>
            )
        }
      />

      <Route
        path={["/tasks/archive"]}
        render={
          useSplitEnabled(Split.SALES_ASSISTANT_TASKS)
            ? () => (
              <SalesProviderV1>
                <SalesProviderV2>
                  <TasksProvider>
                    <ArchiveTask />
                  </TasksProvider>
                </SalesProviderV2>
              </SalesProviderV1>
            )
            : () => (
              <InsightsProviderV2>
                <InsightsV2 />
              </InsightsProviderV2>
            )
        }
      />

      <Route
        path={["/tasks"]}
        render={
          useSplitEnabled(Split.SALES_ASSISTANT_TASKS)
            ? () => (
              <SalesProviderV1>
                <SalesProviderV2>
                  <TasksProvider>
                    <Tasks />
                  </TasksProvider>
                </SalesProviderV2>
              </SalesProviderV1>
            )
            : () => (
              <InsightsProviderV2>
                <InsightsV2 />
              </InsightsProviderV2>
            )
        }
      />

      {useSplitEnabled(Split.SPACES_V2) && (
        <Route path={[SPACES_ROUTE]} render={SpacesV2} />
      )}

      <Redirect
        to={defaultView}
      />
    </Switch>
  );
};
export default Router;
