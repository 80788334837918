
import { Box, BoxProps, Drawer, Stack, Tab, Tabs, useTheme } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  ThemeProviderV2,
  useIsDarkMode,
} from "../../../components/ThemeProviderV2.tsx";
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { TenantSelectionV2 } from "../../../components/TenantSelectionV2.tsx";
import ProfileMenu from "../Components/ProfileMenu.tsx";


interface ApplicationsTopNavProps {
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}

const Logo: FC<{ size?: "full" | "favicon" } & Partial<BoxProps>> = ({
  size = "full",
  ...props
}) => {
  const history = useHistory();
  const isDarkMode = useIsDarkMode();
  return (
    <Box
      onClick={() => history.push("/")}
      {...props}
      sx={{ marginLeft: "4px" }}
    >
      <img
        src={
          size !== "full"
            ? "/favicon-dark.svg"
            : isDarkMode
              ? "/logo-dark-mode.svg"
              : "/logo-black.svg"
        }
        alt=""
        height={30}
        width={size === "full" ? 114 : 30}
      />
    </Box>
  );
};


const ApplicationsTopNav: FC<ApplicationsTopNavProps> = ({
  tab,
  setTab,
}) => {
  const theme = useTheme();

  const getFromUrl = () => {
    const match = useRouteMatch();
    // Split the pathname and get the first segment
    const firstSegment = match?.url.split('/')[1] || '';
    return `/${firstSegment}`
  }

  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory()

  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [sidebarHeight, setSidebarHeight] = useState(0);

  const updateSidebarDimensions = () => {
    if (sidebarRef.current) {
      //@ts-ignore
      setSidebarWidth(sidebarRef.current.offsetWidth);
      setSidebarHeight(sidebarRef.current.offsetHeight);
    }
  };

  const tabs = [{
    label: "Perfect Content",
    value: "/perfect-content"
  },
  {
    label: "Spaces",
    value: "/spaces"
  },
  ]
  console.log(tabs);
  useEffect(() => {
    const sidebar = sidebarRef.current
    if (sidebar) {
      setSidebarWidth(sidebar.offsetWidth);
      setSidebarHeight(sidebar.offsetHeight);

      const handleTransitionEnd = () => {
        updateSidebarDimensions();
      };

      sidebar.addEventListener('transitionend', handleTransitionEnd);
      return () => {
        sidebar.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [sidebarRef.current, open]);
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        background: theme.palette.background.default,
        transition: "width 0.5s ease",
        height: "48px",
      }}
      justifyContent="space-between"
    >
      <Box
        sx={{
          position: "sticky",
          padding: "8px",
          background: "#2C224C",
          display: "flex",
          justifyContent: "space-between",
          flexShrink: 0,
        }}
      >
        <Logo size="full" />
      </Box>
      <Stack direction="row" alignItems="start" width="100%">
        <Tabs
          textColor="inherit"
          value={tab || getFromUrl()}
          onChange={(_e, value) => {
            setTab(value)
            history.push(value)
          }}
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none', // Hides the indicator line
            },
          }}
        >
          {tabs.map(({ value, label }) =>
            <Tab value={value} label={label} sx={{
              color: theme.palette.text.primary,
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "none",
              opacity: 0.6,
              '&.Mui-selected': {
                opacity: 1
              },
            }} />
          )}

        </Tabs>
      </Stack>

      <Stack spacing={2} direction="row" alignSelf="end" alignItems="center">
        <TenantSelectionV2 showSelected={true} />
        <ProfileMenu sideBarOpen={false} sidebarWidth={sidebarWidth} sidebarHeight={sidebarHeight} mode="top" />
      </Stack>
    </Stack>
  );
};

export default ({
  tab,
  setTab,
}: ApplicationsTopNavProps) => (
  <ThemeProviderV2>
    <ApplicationsTopNav
      tab={tab}
      setTab={setTab}
    />
  </ThemeProviderV2>
);
