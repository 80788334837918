import {FC, Fragment} from "react";
import {Button, ButtonProps, CircularProgress, DialogActions, OutlinedTextFieldProps, TextField,} from "@mui/material";
import {useForm} from "react-hook-form";
import {JSONSchema4} from "json-schema";
import {prettifyString} from "../utils";

export type JsonSchemaFormControlsProps = {
  formMethods: any;
  schema: JSONSchema4;
  textFieldProps?: OutlinedTextFieldProps;
  defaultValues?: Record<string, string>;
};

export const JsonSchemaFormControls = ({
                                         formMethods,
                                         schema,
                                         textFieldProps,
                                         defaultValues,
                                       }: JsonSchemaFormControlsProps) => {
  // @ts-ignore
  const properties = Object.entries(schema.properties);

  return (
    <>
      {properties.map((property, index) => (
        <Fragment key={index}>
          <TextField
            key={property[0]}
            label={prettifyString((property[1].title as string) || property[0])}
            helperText={property[1].description as string}
            variant="outlined"
            {...formMethods.register(property[0], {
              required: Boolean(
                schema.required
                  ? Array.isArray(schema.required)
                    ? schema.required.includes(property[0])
                    : schema.required
                  : false
              ),
            })}
            margin="normal"
            fullWidth
            defaultValue={defaultValues?.[property[0]] ?? property[1].default}
            {...textFieldProps}
          />
        </Fragment>
      ))}
    </>
  );
};

export type SchemaFormProps = {
  schema: JSONSchema4;
  onSubmit: (formValues: Record<string, string>) => void;
  textFieldProps?: OutlinedTextFieldProps;
  defaultValues?: Record<string, string>;
  onBack?: () => void;
  submitLabel?: string;
  submitDisabled?: boolean;
  actionButtonProps?: ButtonProps;
};

const SchemaForm: FC<SchemaFormProps> = ({
  schema,
  onSubmit,
  textFieldProps,
  defaultValues,
  onBack,
  submitLabel,
  submitDisabled,
  actionButtonProps,
}) => {
  const formMethods = useForm({ mode: "all" });

  const { isValid, isSubmitting } = formMethods.formState;
  const onFormSubmit = formMethods.handleSubmit(
    async (formValues: Record<string, string>) => {
      onSubmit(formValues);
    }
  );
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit();
        e.stopPropagation();
      }}
    >
      <JsonSchemaFormControls
        formMethods={formMethods}
        schema={schema}
        defaultValues={defaultValues}
        textFieldProps={textFieldProps}
      />
      <DialogActions>
        {onBack ? (
          <Button
            color="primary"
            onClick={onBack}
            autoFocus
            {...actionButtonProps}
          >
            Back
          </Button>
        ) : null}
        <Button
          type="submit"
          variant="text"
          color="primary"
          disabled={!isValid || isSubmitting || submitDisabled}
          {...actionButtonProps}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            submitLabel || "Submit"
          )}
        </Button>
      </DialogActions>
    </form>
  );
};

export default SchemaForm;
