import { Stack, Tooltip, Typography, useTheme } from "@mui/material";

import { Column, usePagination, useSortBy, useTable } from "react-table";
import {
  IllustrationPlaceholder,
  UncontrolledTable,
} from "../../../components";
import { EnrichedHarmonizationRead } from "./types";
import {
  capitaliseFirst,
  formatDateString,
  formatDateStringFromNow,
} from "../../../utils";
import { ShopifyImg } from "../../DataSource/ShopifyIcon";
import { ChannelTypes } from "../../../enums";
import { AmazonImg } from "../../DataSource/AmazonIcon";
import { FC, ReactNode, useEffect, useMemo } from "react";

export const ChannelTypeToIcon: Record<ChannelTypes, ReactNode> = {
  [ChannelTypes.AMAZON]: (
    <AmazonImg width={20} height={20} key={ChannelTypes.AMAZON} />
  ),
  [ChannelTypes.SHOPIFY]: (
    <ShopifyImg width={20} height={20} key={ChannelTypes.SHOPIFY} />
  ),
};
const NA = "-";
const HarmonizedProductsTable: FC<{
  data: EnrichedHarmonizationRead[];
  isLoading: boolean;
}> = ({ data, isLoading }) => {
  const theme = useTheme();
  const all_columns: Column<EnrichedHarmonizationRead>[] = [
    {
      Header: "Channel",
      id: "channel_type",
      accessor: "channel_type",
      Cell: ({ value }) => (
        <Stack direction="row" spacing={1} alignItems="center">
          {ChannelTypeToIcon[value]}
          <Typography variant="body2">{capitaliseFirst(value)}</Typography>
        </Stack>
      ),
      width: theme.spacing(5),
    },
    {
      Header: "Product Id",
      id: "channel_id",
      accessor: "channel_id",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }) => (
        <Typography noWrap variant="body2" title={value}>
          {value}
        </Typography>
      ),
    },
    {
      Header: "GTIN",
      id: "gtin",
      accessor: "match_data",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }) => {
        const maybeGtin = value?.gtin || "";
        return (
          <div style={{ textAlign: "left" }}>
            <Tooltip title={maybeGtin} placement="top-start">
              <Typography
                noWrap
                variant="body2"
                sx={{ maxWidth: theme.spacing(35) }}
              >
                {maybeGtin}
              </Typography>
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: "Grouped by",
      id: "match_type",
      accessor: "match_type",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: "left" }}>
          <Tooltip title={value.toString().toUpperCase()} placement="top-start">
            <Typography
              noWrap
              variant="body2"
              sx={{ maxWidth: theme.spacing(35) }}
            >
              {value.toString().toUpperCase()}
            </Typography>
          </Tooltip>
        </div>
      ),
    },
    {
      Header: "Category",
      id: "Category",
      accessor: "categories",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: "left" }}>
          <Tooltip title={value || NA} placement="top-start">
            <Typography
              noWrap
              variant="body2"
              sx={{ maxWidth: theme.spacing(35) }}
            >
              {value || NA}
            </Typography>
          </Tooltip>
        </div>
      ),
    },
    {
      Header: "Title",
      id: "title",
      accessor: "title",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: "left" }}>
          <Tooltip title={value || ""} placement="top-start">
            <Typography
              noWrap
              variant="body2"
              sx={{ maxWidth: theme.spacing(35) }}
            >
              {value || NA}
            </Typography>
          </Tooltip>
        </div>
      ),
    },
    {
      Header: "Date added",
      id: "updated_at",
      accessor: "updated_at",
      // @ts-ignore
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: "left" }}>
          <Tooltip title={formatDateString(value)} placement="top-start">
            <Typography
              noWrap
              variant="body2"
              sx={{ maxWidth: theme.spacing(35) }}
            >
              {formatDateStringFromNow(value)}
            </Typography>
          </Tooltip>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => all_columns, []);

  const instance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetPage: false,
      // @ts-ignore
      initialState: { pageSize: data.length || 1, rowCount: data.length },
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // @ts-ignore
    instance.setPageSize(data.length || 1);
  }, [data]);

  return (
    <>
      {!isLoading && data.length === 0 ? (
        <IllustrationPlaceholder
          imageHeight={300}
          imageWidth={300}
          text="No products to display."
        />
      ) : (
        <UncontrolledTable
          instance={instance}
          data={data}
          isLoading={isLoading}
          columns={columns}
          error={null}
          tableProps={{
            size: "small",
            sx: {
              "thead tr": {
                background: (t) => t.palette.grey[25],
              },
              "tbody tr": {
                background: (t) => t.palette.grey[50],
                "&:last-child": {
                  borderBottom: "none",
                },
              },
            },
          }}
          disablePagination
        />
      )}
    </>
  );
};

export default HarmonizedProductsTable;
