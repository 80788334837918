import {ListItemIcon, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {AuthorizedContent} from "../../../components/AuthorizedContent";
import {PlatformPermission} from "../../../enums";
import {RunMenuItemProps} from "./types";
import {FC} from "react"

const ViewRunDataMenuItem: FC<RunMenuItemProps> = ({
                                                       boardId,
                                                       runId,
                                                       onClick,
                                                   }) => (
// @ts-ignore
    <AuthorizedContent
        requiredPermissions={[PlatformPermission.RUNS_GET]}
        render={(isAuthz) => (
            <MenuItem
                disabled={!isAuthz}
                onClick={onClick}
                component={Link}
                to={`/boards/${boardId}/runs/${runId}/data`}
            >
                <ListItemIcon>
                    <AssessmentIcon/>
                </ListItemIcon>
                Data
            </MenuItem>
    )}
  />
);

export default ViewRunDataMenuItem;
