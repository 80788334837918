import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useSpaceKeywordsQuery } from "../SpacesV2/useSpaces.ts";
import { Keywords } from "../SpacesV2/types.ts";
import { usePerfectContentProductQuery } from "./api.ts";
import { PerfectContentProduct } from "../../types/ecommerce.ts";

export type PerfectContentStateV2 = {
  spaceId: string;
  asin: string;
  isLoading: boolean;
  keywordsList?: Keywords[];
  productData?: PerfectContentProduct;
  selectedKeywords: string[];
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>;
  activeTab: ContentType;
  setActiveTab: Dispatch<SetStateAction<ContentType>>;
  current_string: string;
  highlightedKeywords?: string[];
};
export type ContentType = "title" | "about" | "description";

export const PerfectContentContextV2 = createContext<PerfectContentStateV2>(
  {} as PerfectContentStateV2,
);

export const usePerfectContentV2 = () => useContext(PerfectContentContextV2);

export const PerfectContentProviderV2 = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { spaceId, asin } = useParams<{ spaceId: string; asin: string }>();

  const { data: keywordsList, isLoading: isLoadingKeywords } =
    useSpaceKeywordsQuery({
      spaceId: spaceId,
      limit: 100,
    });

  const { data: productData, isLoading: isLoadingProduct } =
    usePerfectContentProductQuery({ asin, spaceId });

  const isLoading = useMemo(
    () => isLoadingKeywords || isLoadingProduct,
    [isLoadingKeywords, isLoadingProduct],
  );

  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  console.warn(JSON.stringify(selectedKeywords));
  const [activeTab, setActiveTab] = useState<ContentType>("title");

  const current_string = useMemo<string>(
    () => productData?.[activeTab] ?? "",
    [activeTab, productData],
  );

  const highlightedKeywords = useMemo<string[]>(() => {
    console.warn("test");
    const existingKeywords: string[] = [];
    keywordsList?.data?.forEach((keyword) => {
      if (current_string.toLowerCase().includes(keyword.search_term)) {
        existingKeywords.push(keyword.search_term);
      }
    });
    existingKeywords.sort((a, b) => b.length - a.length);
    return existingKeywords;
  }, [current_string, keywordsList]);

  useEffect(() => {
    setSelectedKeywords((prev) => [
      ...new Set([...prev, ...highlightedKeywords]),
    ]);
  }, [highlightedKeywords]);

  const value = {
    spaceId,
    asin,
    isLoading,
    keywordsList: keywordsList?.data,
    productData,
    selectedKeywords,
    setSelectedKeywords,
    activeTab,
    setActiveTab,
    current_string,
    highlightedKeywords,
  };

  return (
    <PerfectContentContextV2.Provider value={value}>
      {children}
    </PerfectContentContextV2.Provider>
  );
};
