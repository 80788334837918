import { useQuery, UseQueryOptions } from "react-query";
import { getTrigger } from "./api";
import { APIError } from "../apiRequest";
import { Trigger } from "../../types";

export default function useGetTrigger(
  triggerId?: string | null,
  options?: UseQueryOptions<Trigger, APIError>
) {
  return useQuery<Trigger, APIError>(
    ["getTrigger", triggerId],
    () => getTrigger(triggerId),
    options
  );
}
