import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import ChatQuestions from "./ChatQuestions";

interface RelatedStoryPointsProps {
  relatedQuestions: any;
  relatedQuestionsPending: any;
  handleAddRelatedQuestion: any;
  onClose: any;
}

const RelatedStoryPoints = ({
  relatedQuestions,
  relatedQuestionsPending,
  handleAddRelatedQuestion,
  onClose,
}: RelatedStoryPointsProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        margin: "2rem 0",
        borderRadius: "12px",
        display: "flex",
        width: "1100px",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Tooltip
        placement="top"
        title="Close"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -5],
                },
              },
            ],
          },
        }}
      >
        <Box
          className="close-btn"
          sx={{
            position: "absolute",
            display: "none",
            top: "-40px",
            right: "-40px",
            padding: "10px",
          }}
        >
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              borderRadius: "50%",
              backgroundColor: "unset",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                "& .MuiSvgIcon-root": {
                  color: theme.palette.primary.dark,
                },
              },
              alignSelf: "flex-start",
            }}
          >
            <ClearIcon
              sx={{
                color: theme.palette.grey[300],
              }}
            />
          </IconButton>
        </Box>
      </Tooltip>

      <ChatQuestions
        questionList={relatedQuestions}
        isLoading={relatedQuestionsPending}
        handleOnClick={handleAddRelatedQuestion}
      />
    </Box>
  );
};

export default RelatedStoryPoints;
