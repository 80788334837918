import { Box, styled } from "@mui/material";

const RotatingBox = styled(Box)(() => ({
  animation: "rotate 2s infinite linear",
  display: "flex",
  alignItems: "center",

  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export default RotatingBox;
