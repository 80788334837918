import {Button} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {PipelineInput} from "../../../types";
import InputList from "./InputList";
import Header from "../Header";
import NavbarPaper from "../NavbarPaper";
import {FC} from "react"

const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        width: "100%",
        height: "100%",
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
    nextButton: {
      marginTop: theme.spacing(8),
    },
  })
);

type NavbarProps = {
  selectedInput: PipelineInput | undefined | null;
  setSelectedInput: (input: PipelineInput | null) => void;
  isInputValid: (inputName: string) => boolean;
  onNext: () => void;
  isNextEnabled: boolean;
};

const Navbar: FC<NavbarProps> = ({
  selectedInput,
  setSelectedInput,
  isInputValid,
  onNext,
  isNextEnabled,
}) => {
  const classes = useStyles();
  const { boardId }: { boardId?: any } = useParams();
  const history = useHistory();
  return (
    <NavbarPaper
      children={
        <>
          <Header
            title="Create a Run - Inputs"
            subtitle="First connect your data to the platform"
            onBack={() => history.push(`/boards/${boardId}`)}
          />
          <InputList
            selectedInput={selectedInput}
            setSelectedInput={setSelectedInput}
            isInputValid={isInputValid}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onNext}
            disabled={!isNextEnabled}
            className={classes.nextButton}
          >
            Next: Customize Settings
          </Button>
        </>
      }
    />
  );
};

export default Navbar;
