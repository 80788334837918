import {
  AcknowledgedResponse,
  DeleteFileRequest,
  GoogleSheetsCreate,
  GoogleSheetsResponse,
  UploadFileCreate,
  UploadFileResponse,
} from "../types";
import apiRequest from "./apiRequest";

export async function uploadFiles(
  data: UploadFileCreate
): Promise<UploadFileResponse[]> {
  return await apiRequest<UploadFileResponse[]>(
    `circuitboards/${data.circuitboardId}/storage`,
    "POST",
    { input_name: data.input_name },
    undefined,
    data.files
  );
}

export async function getUploadedFiles(
  circuitboardId: string,
  input_name: string
): Promise<UploadFileResponse[]> {
  return await apiRequest<UploadFileResponse[]>(
    `circuitboards/${circuitboardId}/storage`,
    "GET",
    { input_name }
  );
}

export async function deleteFile(
  data: DeleteFileRequest
): Promise<AcknowledgedResponse> {
  return await apiRequest<AcknowledgedResponse>(
    `circuitboards/${data.boardId}/storage?input_name=${data.inputName}&file_name=${data.fileName}`,
    "DELETE",
    undefined
  );
}

export async function createGoogleSheets(
  data: GoogleSheetsCreate
): Promise<GoogleSheetsResponse> {
  return await apiRequest<GoogleSheetsResponse>(
    `circuitboards/${data.boardId}/runs/download/sheets`,
    "POST",
    undefined,
    {
      run_id: data.runId,
      access_token: data.accessToken,
      outputs: data.outputs,
    }
  );
}
