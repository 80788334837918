import { useContext, useMemo } from "react";
import _ from "lodash";
import { TransformedInsight } from "../types";
import { salesAssistantContext } from "../SalesProvider";
import { useAuthorizedUsers } from "../../../hooks/useUsers";

export const useSalesContext = () => useContext(salesAssistantContext);
const useSelectedInsightsSet = () => {
  const { selectedInsights } = useSalesContext();
  return useMemo(() => new Set(selectedInsights), [selectedInsights]);
};

const applySearch = (insights: TransformedInsight[], search: string) => {
  const reg = new RegExp(search, "i");
  return insights?.filter((insight) => reg.test(insight.asin || insight.title));
};

export const useDisplayList = (): TransformedInsight[] => {
  const {
    salesAssistantData,
    filterOut,
    search,
    selectedProductGroup,
    categorySearchValue,
    brandSearchValue,
  } = useSalesContext();
  const selectedInsightsSet = useSelectedInsightsSet();
  const members = useAuthorizedUsers();
  return useMemo(
    () =>
      _(salesAssistantData)
        // .thru((list) => applySort(list, sort, revenueOverTimeEnabled))
        .thru((list) => applySearch(list, search))
        // .thru((list) => applyCategorySearch(list, categorySearchValue))
        // .thru((list) => applyBrandSearch(list, brandSearchValue))
        .map((display) => ({
          ...display,
          isChecked: selectedInsightsSet.has(display.id),
        }))
        .value(),
    [
      salesAssistantData,
      filterOut,
      selectedInsightsSet,
      search,
      members,
      selectedProductGroup?.id,
      categorySearchValue,
      brandSearchValue,
    ]
  );
};

export const useDisplayedSelectedInsights = (): Set<string> => {
  const { selectedInsights } = useSalesContext();
  const displayList = useDisplayList();
  return useMemo(
    () =>
      new Set(
        selectedInsights.filter((_id) =>
          displayList.find(({ id }) => id === _id)
        )
      ),
    [selectedInsights, displayList]
  );
};

export const useIsAllDisplayedSelected = () => {
  const selectedCount = useDisplayedSelectedInsights().size;
  const displayList = useDisplayList();
  return displayList.length === selectedCount && displayList.length !== 0;
};
export const useCategoryFilteredData = () => {
  const { filterData, categorySearchValue } = useSalesContext();

  return useMemo(() => {
    if (!filterData) {
      return [];
    }

    return filterData?.categories.filter((display: string) =>
      display.toLowerCase().includes(categorySearchValue.toLowerCase())
    );
  }, [filterData, categorySearchValue]);
};
export const useBrandFilteredData = () => {
  const { filterData, brandSearchValue } = useSalesContext();

  return useMemo(() => {
    if (!filterData) {
      return [];
    }

    return filterData?.brands.filter((display: string) =>
      display.toLowerCase().includes(brandSearchValue.toLowerCase())
    );
  }, [filterData, brandSearchValue]);
};
