import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import theme from "../../../../themes/light";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <ArrowBackIos
          style={{
            height: "15px",
            color: currentPage === 1 ? "#A19EA5" : theme.palette.primary.main,
          }}
        />
      </Button>
      <Typography
        variant="body5"
        sx={{
          fontSize: "12px",
          width: "fit-content",
        }}
      >
        Page {currentPage} of {totalPages}
      </Typography>
      <Button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <ArrowForwardIos
          style={{
            height: "15px",
            color:
              currentPage === totalPages
                ? "#A19EA5"
                : theme.palette.primary.main,
          }}
        />
      </Button>
    </Stack>
  );
};

export default Pagination;
