import {Box, Stack, Tooltip, Typography, useMediaQuery, useTheme,} from "@mui/material";
import {TableProps as MUITableProps} from "@mui/material/Table/Table";
import {FC, memo, useEffect, useMemo, useState} from "react"
import {Column, usePagination, useSortBy, useTable} from "react-table";
import {IllustrationPlaceholder} from "../../../components";
import SearchBar from "../../../components/SearchInput/SearchBar";
import UncontrolledTable, {UncontrolledTableProps,} from "../../../components/UncontrolledTable";
import {ChannelTypes} from "../../../enums";
import useDebouncedCallback from "../../../hooks/UseDebouncedCallback";
import {SummaryBox} from "../ProductsHeader";
import {useHarmonizationProducts, useProductsGroupCountByChannelType,} from "./api";
import {ChannelsFilters} from "./ChannelsFilters";
import HarmonizedSummary from "./HarmonizedSummary";
import ProductGroupDetailsTable, {ChannelTypeToIcon,} from "./ProductGroupDetailsTable";
import {EnrichedHarmonizationRead, HarmonizedProduct} from "./types";
import {TableHeader} from "../../../components/TableHeader";

const perPage = 25;
const tableProps: MUITableProps = {
  stickyHeader: true,
  size: "small",
  sx: {
    ".collapsible-row": {
      "> td": {
        borderBottom: "none",
        padding: 0,
      },
    },
  },
};

const MemoizedTable =
  memo<UncontrolledTableProps<HarmonizedProduct>>(UncontrolledTable);

const HarmonizedProductsTable: FC<object> = () => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [channels, setChannels] = useState<ChannelTypes[]>([]);
  const {
    data: response,
    isFetching: isLoading,
    error,
  } = useHarmonizationProducts({
    page: 1,
    per_page: page * perPage,
    searchText,
    channels,
    options: {
      keepPreviousData: true,
    },
  });
  const { data: channelToGroupCount = [] } =
    useProductsGroupCountByChannelType();
  const { items: products = [], total = 0 } = response || {};
  const columns: Column<HarmonizedProduct>[] = useMemo(
    () => [
      {
        Header: "",
        id: "__colapse_content",
        accessor: "id",
        Cell: (props) => (
          <ProductGroupDetailsTable
            data={Object.values(props.cell.row.original.channels)}
            isLoading={false}
          />
        ),
      },
      {
        Header: "Title",
        id: "title",
        accessor: "channels",
        disableSortBy: true,
        Cell: ({ value }) => {
          const title = Object.values(value).find((c) => c.title)?.title || "-";
          return (
            <Tooltip title={title}>
              <Typography noWrap variant="body2" maxWidth={400}>
                {title}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        Header: "Channels",
        Filter: <ChannelsFilters channels={channels} onChange={setChannels} />,
        id: "Channels",
        accessor: "channels",
        disableSortBy: true,
        Cell: ({ value }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            {Object.values(value).map(
              (r: EnrichedHarmonizationRead) =>
                ChannelTypeToIcon[r.channel_type]
            )}
          </Stack>
        ),
      },
      {
        Header: "Product Id",
        id: "ProductId",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <Typography noWrap variant="body2" title={value}>
            {value}
          </Typography>
        ),
      },
    ],
    [channelToGroupCount.length, channels]
  );

  const instance = useTable(
      {
        columns,
        data: products,
        autoResetSortBy: false,
        autoResetPage: false,
        // @ts-ignore
        initialState: {pageSize: products.length || 1, rowCount: total},
        manualPagination: true,
      },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // @ts-ignore

    instance.setPageSize(products.length || 1);
  }, [products]);

  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      spacing={1.5}
      sx={{
        maxWidth: mobileView ? "100vw" : "calc(100vw - 73px)",
        mt: 4,
        px: 4,
      }}
    >
      <SummaryBox>
        <HarmonizedSummary />
      </SummaryBox>
      <Box>
        <SearchBar
          size="small"
          options={[]}
          onInputChange={useDebouncedCallback(
            (_, value) => setSearchText(value),
            500
          )}
          containerProps={{
            sx: {
              ".MuiInputBase-sizeSmall": { margin: "unset" },
              mt: 2,
              mb: 1,
            },
          }}
        />
      </Box>
      <TableHeader subTitle={total.toString()} title="Products" />
      <Box
        sx={useMemo(
          () => ({ display: "flex", flexDirection: "column" }),
          [true]
        )}
      >
        <MemoizedTable
          instance={instance}
          data={products}
          isLoading={isLoading}
          columns={columns}
          error={error || null}
          tableProps={tableProps}
          disablePagination
        />
        {!isLoading && products.length === 0 ? (
          <IllustrationPlaceholder
            imageHeight={300}
            imageWidth={300}
            text="No products to display."
          />
        ) : null}
        {total > perPage * page && (
          <Typography
            onClick={() => setPage(page + 1)}
            sx={{
              transition: "background 0.2s ease",
              "&:hover": { background: "grey.100" },
              padding: 2,
              cursor: "pointer",
              borderBottomWidth: "1px",
              borderBottomColor: "text.secondary",
              color: "text.primary",
            }}
          >
            + Load {Math.min(perPage, total - perPage * page)} more products (of{" "}
            {total - products.length})
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export default HarmonizedProductsTable;
