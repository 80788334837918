import { FC } from "react";

import dayjs from "../utils";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { DayActivityColor, DayActivityColor as string } from "../enums";
import { DayActivityOverview } from "../types/runs";

const DayNames = {
  0: "",
  1: "Mon",
  2: "",
  3: "Wed",
  4: "",
  5: "Fri",
  6: "",
};

type DayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

type CellProps = {
  year?: number;
  month?: number;
  day?: number;
  numOfRuns?: number;
  color: string;
};

type MonthProps = {
  startDate: dayjs.Dayjs;
  index: number;
};

type WeekDayProps = {
  index: number;
};

type HeatMapProps = {
  range: dayjs.Dayjs[];
  data: DayActivityOverview[];
};

const cellHight = 1.25;
const cellWidth = 1.25;
const cellMergin = 0.25;
const cellWeekdaysWidth = 3.75;

const useStylesHeatmap = makeStyles((defaulTheme: Theme) =>
  createStyles({
    heatMap: {
      margin: defaulTheme.spacing(2),
    },

    heatMapMonths: {
      display: "flex",
      paddingLeft: defaulTheme.spacing(cellWeekdaysWidth),
    },

    heatMapBody: {
      display: "flex",
      height: defaulTheme.spacing((cellHight + 2 * cellMergin) * 8),
    },

    heatMapBodyweekDays: {
      display: "inline-flex",
      flexDirection: "column",
      width: defaulTheme.spacing(cellWeekdaysWidth),
    },

    heatMapCells: {
      display: "inline-flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
  })
);

const useStylesMonth = makeStyles((defaulTheme: Theme) =>
  createStyles({
    heatMapMonthsMonth: {
      width: defaulTheme.spacing(cellWidth),
      margin: defaulTheme.spacing(cellMergin),
      border: "1px solid transparent",
      fontSize: defaulTheme.spacing(1.25),
      boxSizing: "content-box",
    },
    monthText: {
      fontSize: defaulTheme.spacing(1.25),
    },
  })
);

const useStylesWeekDay = makeStyles((defaulTheme: Theme) =>
  createStyles({
    heatMapBodyweekDaysweekDay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: defaulTheme.spacing(cellHight),
      border: "1px solid transparent",
      borderRadius: defaulTheme.spacing(0.25),
      margin: defaulTheme.spacing(cellMergin),
      boxSizing: "content-box",
    },

    DayText: {
      fontSize: defaulTheme.spacing(1.25),
    },
  })
);

const useStylesCell = makeStyles((defaulTheme: Theme) =>
  createStyles({
    heatMapCellscell: {
      height: defaulTheme.spacing(cellHight),
      width: defaulTheme.spacing(cellWidth),
      border: "1px solid",
      borderColor: defaulTheme.palette.grey[300],
      margin: defaulTheme.spacing(cellMergin),
      borderRadius: defaulTheme.spacing(0.25),
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      boxSizing: "content-box",

      "&:hover": {
        border: "1px solid",
        borderColor: defaulTheme.palette.grey[900],
      },
    },

    heatMapCellsEmptycell: {
      height: defaulTheme.spacing(cellHight),
      width: defaulTheme.spacing(cellWidth),
      margin: defaulTheme.spacing(cellMergin),
      border: "1px solid",
      borderColor: "transparent",
      borderRadius: defaulTheme.spacing(0.25),
      backgroundColor: "#ffffff",
      opacity: 0.4,
      boxSizing: "content-box",
    },
  })
);

const WeekDay: FC<WeekDayProps> = ({ index }) => {
  const classes = useStylesWeekDay();

  return (
    <Box className={classes.heatMapBodyweekDaysweekDay}>
      <Typography className={classes.DayText}>
        {DayNames[index as DayIndex]}
      </Typography>
    </Box>
  );
};

const Month: FC<MonthProps> = ({ startDate, index }) => {
  const date = dayjs(startDate).add(index * 7, "day");
  const monthName = date.format("MMM");
  let previousMonthName;
  const classes = useStylesMonth();

  if (index > 0) {
    const previousDate = dayjs(startDate).add((index - 1) * 7, "day");
    previousMonthName = previousDate.format("MMM");
  }

  return (
    <Box className={clsx(classes.heatMapMonthsMonth, monthName)}>
      <Typography className={classes.monthText}>
        {previousMonthName === monthName ? "" : monthName}
      </Typography>
    </Box>
  );
};

const Cell: FC<CellProps> = ({ color, year, month, day, numOfRuns }) => {
  const classes = useStylesCell();
  const date = dayjs(`${year}-${month}-${day}`);
  const toolTipText = `${numOfRuns} runs on ${date.format("MMM DD, YYYY")}`;

  if (color === DayActivityColor.TRANSAPARENT) {
    return <Box className={classes.heatMapCellsEmptycell} />;
  }
  const style = {
    backgroundColor: color,
  };

  return (
    <Tooltip
      title={
        <Typography variant="caption" display="block">
          {toolTipText}
        </Typography>
      }
    >
      <Box className={classes.heatMapCellscell} style={style} />
    </Tooltip>
  );
};

const HeatMap: FC<HeatMapProps> = ({ range, data }) => {
  const days = Math.abs(range[0].diff(range[1], "days"));
  const weekDays = Array.from(new Array(7));
  const months = Array.from(new Array(Math.ceil(days / 7)));
  const startDate = range[0];
  const endOfWeekStartDate = startDate.add(-startDate.day(), "day");
  const theme = useTheme();
  const statusToColor = {
    unknown: theme.palette.grey[300],
    success: theme.palette.success.light,
    warning: theme.palette.warning.light,
    failure: theme.palette.error.light,
  };

  const classes = useStylesHeatmap();

  return (
    <Box className={classes.heatMap}>
      <Box className={classes.heatMapMonths}>
        {months.map((_, index) => (
          <Month key={index} index={index} startDate={endOfWeekStartDate} />
        ))}
      </Box>

      <Box className={classes.heatMapBody}>
        <Box className={classes.heatMapBodyweekDays}>
          {weekDays.map((_, index) => (
            <WeekDay key={index} index={index} />
          ))}
        </Box>

        <Box className={classes.heatMapCells}>
          {[...Array(startDate.day())].map((_, index) => (
            <Cell key={index} color={string.TRANSAPARENT} />
          ))}
          {data.map((point, index) => {
            const { status } = point;
            return (
              <Cell
                key={index}
                color={statusToColor[status]}
                numOfRuns={data[index].num_of_runs}
                year={data[index].year}
                month={data[index].month}
                day={data[index].day}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default HeatMap;
