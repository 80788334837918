import { Typography } from "@mui/material";

const Alerts = () => (
  <>
    <img
      src="/sales/circle.svg"
      alt="Alerts for products in your selection will be displayed here"
    />
    <Typography variant="body1" fontWeight="600" marginTop="20px">
      Alerts for products in your selection will be displayed here
    </Typography>
  </>
);

export default Alerts;
