import {FC, useState} from "react";
import {Button, Paper} from "@mui/material";
import {useDropzone} from "react-dropzone";
import {useSnackbar} from "notistack";
import {useMutation} from "react-query";
import makeStyles from "@mui/styles/makeStyles";
import {useParams} from "react-router-dom";
import {uploadFiles} from "../../../../services/data";
import {PipelineInput, UploadFileCreate, UploadFileResponse,} from "../../../../types";
import UploadingBox from "./UploadingBox";
import UploadBox from "./UploadBox";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    outline: "none",
    textAlign: "center",
    cursor: "pointer",
  },
  paper: {
    padding: 0,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.grey[300],
  },
  hidden: {
    visibility: "hidden",
  },
  image: {
    margin: theme.spacing(4),
  },
  uploadingTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  fileTitle: {
    display: "flex",
  },
}));

type LocalFileCardProps = {
  input: PipelineInput;
  onSuccess: (uploadFile: UploadFileResponse) => void;
};

const LocalFileUpload: FC<LocalFileCardProps> = ({ input, onSuccess }) => {
  const { boardId }: { boardId?: any } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const closeSnackbarAction = () => (
    <Button
      onClick={() => {
        closeSnackbar();
      }}
    >
      Got It
    </Button>
  );
  const handleFileUpload: (
    request: UploadFileCreate
  ) => Promise<UploadFileResponse[]> = async (request) => {
    const response = await uploadFiles(request);
    if (response && response.length) {
      onSuccess(response[0]);
    }
    return response;
  };
  const mutation = useMutation(handleFileUpload, {
    onError: (error) => {
      throw error;
    },
  });
  const onDrop: (acceptedFiles: File[]) => void = async function (
    acceptedFiles
  ) {
    const file = acceptedFiles[0];
    if (!["csv", "parquet"].includes(file.name.split(".").pop() || "")) {
      enqueueSnackbar(
        "Only CSV and Parquet files are currently supported. Please re-upload your data in the right file format.",
        {
          variant: "error",
        }
      );
      return;
    }
    setUploadedFile(file);
    try {
      const response = await mutation.mutateAsync({
        circuitboardId: boardId,
        input_name: input.name,
        files: acceptedFiles,
      });
      enqueueSnackbar(
        `Successfully uploaded ${response[0].name} for input ${input.display_name}`,
        {
          variant: "success",
        }
      );
    } catch (error: any) {
      enqueueSnackbar(`Could not upload the file. Error was: ${error}`, {
        variant: "error",
        persist: true,
        action: closeSnackbarAction,
      });
      mutation.reset();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={classes.dropzone}>
      <input {...getInputProps()} />
      <Paper variant="outlined" className={classes.paper}>
        {mutation.isLoading && uploadedFile ? (
          <UploadingBox file={uploadedFile} />
        ) : (
          <UploadBox isDragActive={isDragActive} />
        )}
      </Paper>
    </div>
  );
};
export default LocalFileUpload;
