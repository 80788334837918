import { useQuery } from "react-query";
import { useAuthUser } from "@frontegg/react-hooks";
import { BoardDocs } from "../../types";
import { APIError } from "../../services/apiRequest";
import { getBoardTemplateLatestDocURL } from "../../services/circuitboards";
import { useUserTenant } from "../../hooks";
import { useTenantConfiguration } from "../../services/tenants/useTenantConfiguration";
import {
  filterByBlockPolicy,
  filterByLibraryPolicy,
  parsePolicy,
  Policy,
  verifyPolicies,
} from "../../services/policies";
import { BoardTemplate } from "../../types/board-templates";

export const useBoardTemplateLatestDoc = (boardTemplateId: string) =>
  useQuery<BoardDocs, APIError>(
    ["getBoardTemplateLatestDoc", boardTemplateId],
    () => getBoardTemplateLatestDocURL(boardTemplateId),
    {
      enabled: !!boardTemplateId,
      retry: (_, response) =>
        response.statusCode !== 404 && response.statusCode !== 500,
      refetchOnWindowFocus: false,
    }
  );

export const useBoardDocsMDFile = (boardTemplateId: string) => {
  const { data: boardTemplateDoc } = useBoardTemplateLatestDoc(boardTemplateId);

  return useQuery<string | null, APIError>(
    ["fetchDocs", boardTemplateDoc?.doc_md],
    async () =>
      fetch((boardTemplateDoc as BoardDocs).doc_md).then((res) => res.text()),
    {
      enabled: !!boardTemplateDoc?.doc_md,
      retry: (_, response) =>
        response.statusCode !== 404 && response.statusCode !== 500,
      refetchOnWindowFocus: false,
    }
  );
};

export const useTenantBlockPolicies = (
  boardTemplate?: BoardTemplate
): boolean => {
  const user = useAuthUser();
  const tenant = useUserTenant(user || undefined);

  const { data: tenantConfig } = useTenantConfiguration(
    tenant?.tenantId || "",
    { enabled: !!tenant?.tenantId }
  );
  const installPolicies = (tenantConfig?.configuration?.licenses || [])
    .map(parsePolicy)
    .filter((p) => p != null) as Policy[];

  return !boardTemplate
    ? false
    : verifyPolicies(boardTemplate || {}, installPolicies, [
        filterByBlockPolicy,
        filterByLibraryPolicy,
      ]);
};
