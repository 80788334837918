import { Box, Chip, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { RowData } from "../../types";
import theme from "../../../../themes/light";

const SpaceInfo = ({ data }: { data?: RowData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        width: "fit-content",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          fontSize: "18px",
        }}
        variant="headline3"
        color={theme.palette.primary.main}
      >
        {data?.title}
      </Typography>
      <Tooltip title="My ASINs in the space" placement="right" arrow>
        <Chip
          label={`${data?.asins} ASINS`}
          size="small"
          sx={{
            borderRadius: "4px",
            padding: "4px",
            textTransform: "capitalize",
            fontWeight: 500,
            color: theme.palette.primary.dark,
            backgroundColor: "#EEE5FE",
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default SpaceInfo;
