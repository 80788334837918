import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { convertNumberToShortFormat } from "../../../ResearchAssistant/utils";
import { renderToString } from "react-dom/server";
import "./PricingDistributionTooltip.css";

interface PricingDistributionTooltipProps {
  priceRange: string;
  estimatedUnits: number;
  estimatedUnitsShare: number;
  estimatedRevenue: number;
  estimatedRevenueShare: number;
}

const PricingDistributionTooltip: FC<PricingDistributionTooltipProps> = ({
  priceRange,
  estimatedUnits,
  estimatedUnitsShare,
  estimatedRevenue,
  estimatedRevenueShare,
}) => {
  return (
    <div className="pd-tooltip-container">
      <div className="pd-tooltip-content">
        <div className="pd-tooltip-keys">
          <div className="pd-tooltip-key">Price Range:</div>
          <div className="pd-tooltip-key">Estimated Units:</div>
          <div className="pd-tooltip-key">Estimated Units Share:</div>
          <div className="pd-tooltip-key">Estimated Revenue:</div>
          <div className="pd-tooltip-key">Estimated Revenue Share:</div>
        </div>
        <div className="pd-tooltip-values">
          <div className="pd-tooltip-value">{priceRange}</div>
          <div className="pd-tooltip-value">
            {convertNumberToShortFormat(estimatedUnits)}
          </div>
          <div className="pd-tooltip-value">
            {convertNumberToShortFormat(estimatedUnitsShare, true)}
          </div>
          <div className="pd-tooltip-value">{`$${convertNumberToShortFormat(estimatedRevenue)}`}</div>
          <div className="pd-tooltip-value">
            {convertNumberToShortFormat(estimatedRevenueShare, true)}
          </div>
        </div>
      </div>

      <div className="tooltip-arrow"></div>
    </div>
  );
};

interface PricingDistributionProps {
  data: Record<string, any>[];
}

const PricingDistribution: FC<PricingDistributionProps> = ({ data }) => {
  const schema = useMemo(() => data.map((item) => item["Price Range"]), [data]);

  const series = useMemo(
    () => [
      {
        name: "Price Range",
        data: data.map((item) => item["Estimated Revenue"]),
      },
    ],
    [data]
  );

  const options = {
    series: series,
    chart: {
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 7,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
      formatter: (val: number) => `$${convertNumberToShortFormat(val)}`,
    },
    xaxis: {
      categories: schema,
      position: "botoom",
      labels: {
        style: {
          fontSize: "14px",
        },
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      y: {
        formatter: (value: number) => `$${convertNumberToShortFormat(value)}`,
      },
      custom: ({ dataPointIndex }: any) => {
        return renderToString(
          <PricingDistributionTooltip
            priceRange={data[dataPointIndex]["Price Range"]}
            estimatedUnits={data[dataPointIndex]["Estimated Units"]}
            estimatedUnitsShare={data[dataPointIndex]["Estimated Units Share"]}
            estimatedRevenue={data[dataPointIndex]["Estimated Revenue"]}
            estimatedRevenueShare={
              data[dataPointIndex]["Estimated Revenue Share"]
            }
          />
        );
      },
    },
  };

  return (
    <Box
      id="pricing-distribution-chart"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "auto",
      }}
    >
      <ReactApexChart
        //@ts-ignore
        options={options}
        series={series}
        type={"bar"}
        height={400}
        width={1400}
      />
    </Box>
  );
};

export default PricingDistribution;
