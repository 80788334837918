import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import apiRequest, { APIError } from "../services/apiRequest";
import { OnboardingStatus } from "../types/ecommerce";

const getOnboardingStatus = (): Promise<OnboardingStatus> =>
  apiRequest<OnboardingStatus>("v1/onboarding/ecommerce", "GET");

const useOnboardingStatus = (
  options?: UseQueryOptions<OnboardingStatus, APIError>
): UseQueryResult<OnboardingStatus, APIError> =>
  useQuery<OnboardingStatus, APIError>(
    "onboardingStatus",
    () => getOnboardingStatus(),
    options
  );

export default useOnboardingStatus;
