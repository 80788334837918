import {Box, Button, DialogActions, Typography} from "@mui/material";
import LocalFileUpload from "./LocalFileUpload";
import {UploadFileResponse} from "../../../../types";
import {ConnectorType} from "../../../../enums";
import UploadedFilesTable from "./UploadedFilesTable";
import {ConnectionConfigurationProps} from "../types";
import {FC} from "react"

const LocalFileConfiguration: FC<ConnectionConfigurationProps> = ({
                                                                    input,
                                                                    setInputValue,
                                                                    onCompletion,
                                                                    resetInputValue,
                                                                  }) => {
  const onUploadSelection = (uploadFile: UploadFileResponse) => {
    const props = {
      type: ConnectorType.LOCAL,
      configuration: { path: uploadFile.name },
    };
    if (uploadFile.staged_file) {
      setInputValue({
        mapping: { staged_file: uploadFile.staged_file },
        ...props,
      });
      onCompletion();
    } else {
      setInputValue({
        mapping: {},
        ...props,
      });
    }
  };

  return (
    <>
      <Box marginBottom={2}>
        <Typography variant="h6">2. Upload or choose a file</Typography>
      </Box>
      <LocalFileUpload input={input} onSuccess={onUploadSelection} />
      <UploadedFilesTable
        onSelection={onUploadSelection}
        inputName={input.name}
      />
      <Box marginTop={2}>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={resetInputValue}
            autoFocus
          >
            Back
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};
export default LocalFileConfiguration;
