import { createContext, ReactNode, useEffect, useState } from "react";
import { Sort, State, TaskData } from "./types";
import { useTaskArchivedListData, useTaskBoardData } from "./useTaskApi";
import { useLocation } from "react-router-dom";

export const tasksContext = createContext<State>({} as State);

const initialTaskState = {
  taskId: "",
  assignee: "",
  description: "",
};
export const TasksProvider = ({ children }: { children: ReactNode }) => {
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<TaskData>(initialTaskState);
  const [type, setType] = useState("");
  const [boardData, setBoardData] = useState<any>();
  const {
    data: taskBoardList,
    refetch,
    isLoading: taskboardLoading,
    isFetching: taskboardFetching,
  } = useTaskBoardData();
  const { data: archivedData, refetch: archivedRefetch } =
    useTaskArchivedListData();
  const { pathname } = useLocation();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [sort, setSort] = useState<Sort>({
    column: "task",
    ascending: false,
    isDirty: false,
  });
  const [search, setSearch] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [otherSelectedMember, setOtherSelectedMember] = useState<any>([]);

  useEffect(() => {
    if (pathname === "/tasks/archive") {
      archivedRefetch();
    } else {
      refetch();
    }
  }, [pathname, isUpdated]);

  useEffect(() => {
    if (JSON.stringify(taskBoardList) !== JSON.stringify(boardData)) {
      setBoardData(taskBoardList);
    }
  }, [taskBoardList, boardData]); 


  // useEffect(() => {
  //   setBoardData(taskBoardList);
  // }, [taskBoardList]);

  const state: State = {
    createPopup,
    setCreatePopup,
    taskData,
    setTaskData,
    setType,
    type,
    boardData,
    taskboardLoading,
    taskboardFetching,
    archivedData,
    archivedRefetch,
    sort,
    setSort,
    setIsUpdated,
    isUpdated,
    initialTaskState,
    setSearch,
    search,
    setSelectedAssignee,
    selectedAssignee,
    setBoardData,
    setOtherSelectedMember,
    otherSelectedMember,
    selectedUsers,
    setSelectedUsers,
  };
  return (
    <tasksContext.Provider value={{ ...state }}>
      {children}
    </tasksContext.Provider>
  );
};
