import {Box, Drawer, Typography} from "@mui/material";

import {DrawerHeader, ParametersTable} from "../../../../../components";
import {Schedule} from "../../../../../types";
import {formatDateString} from "../../../../../utils";
import ScheduleInputsTable from "./ScheduleInputsTable";
import {getScheduleDescription} from "./utils";
import {FC} from "react"

const ScheduleDrawer: FC<{
  schedule: Schedule;
  open: boolean;
  onClose: () => void;
}> = ({schedule, open, onClose}) => (
    <Drawer
        open={open}
        onClose={onClose}
        PaperProps={{sx: {width: 500}}}
    variant="temporary"
    anchor="right"
  >
    <DrawerHeader title="Schedule" onClose={onClose} />
    <Typography paddingX={3} paddingY={1}>
      {getScheduleDescription(schedule)}
    </Typography>
    {schedule.ends_at && (
      <Typography paddingX={3} paddingY={1}>
        {`Schedule ends at ${formatDateString(schedule.ends_at)} ${
          schedule.end?.type === "occurrences"
            ? `(after ${schedule.end.value} occurrences)`
            : ""
        }`}
      </Typography>
    )}
    <Box padding={3}>
      <ScheduleInputsTable
        boardId={schedule.board_id}
        inputs={schedule.data.inputs}
      />
    </Box>
    <Box padding={3}>
      <ParametersTable parameters={schedule.data.parameters} />
    </Box>
  </Drawer>
);

export default ScheduleDrawer;
