import {useQueryClient} from "react-query";
import {useHistory} from "react-router-dom";
import {useQueryParams} from "../../hooks";
import {createOauthIntegrationFromAuthCode, getOauthIntegration,} from "../../services/oauthIntegrations";
import {AmazonSellingRegion} from "../../enums";
import {gatherProducts} from "../../services/ecommerce/api";
import {FC, useEffect} from "react"

const OnboardingCode: FC = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const code = queryParams.get("spapi_oauth_code");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (code) {
      getOauthIntegration(AmazonSellingRegion.AMAZON_NORTH_AMERICA).catch(() =>
        createOauthIntegrationFromAuthCode(
          AmazonSellingRegion.AMAZON_NORTH_AMERICA,
          code
        ).then(() =>
          gatherProducts().then(() =>
            queryClient.invalidateQueries("onboardingStatus")
          )
        )
      );
      history.push("/marketplace");
    }
  }, [code]);

  return <></>;
};

export default OnboardingCode;
