import { Radio, RadioProps, styled } from "@mui/material";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  border: `1px solid ${theme.palette.borders.default}`,
  boxShadow: "inset 0px 1px 2px rgba(44, 34, 76, 0.08)",
  "input:hover ~ &": {
    border: `1px solid ${theme.palette.borders.hover}`,
  },
  "input:focus ~ &": {
    border: "1px solid red",
  },
  "input:active ~ &": {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    border: `1px solid ${theme.palette.borders.default}`,
    background: theme.palette.grey[100],
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage: `radial-gradient(${theme.palette.icons.active},${theme.palette.icons.active} 33%,transparent 40%)`,
    content: '""',
  },
  "input:focus ~ &": {
    border: "1px solid #E2D3FC",
    boxShadow: "inset 0px 0px 0px 2px #E2D3FC",
  },
  "input:hover:disabled ~ &": {
    border: `1px solid ${theme.palette.borders.default}`,
  },
  "input:hover ~ &": {
    border: `1px solid ${theme.palette.text.secondary}`,
  },
}));

const RadioComponent = (props: RadioProps) => (
  <Radio
    disableRipple
    icon={<BpIcon />}
    checkedIcon={<BpCheckedIcon />}
    {...props}
  />
);

export default RadioComponent;
