import {PlaylistAddCheck} from "@mui/icons-material";
import {alpha, Button, Grid, Typography} from "@mui/material";
import {deepPurple} from "@mui/material/colors";

import {Link, useRouteMatch} from "react-router-dom";
import {FC, useState} from "react"

type UpgradeProps = {
    isNewVersion: boolean;
    isDismissedVersion: boolean;
    version: string;
    board: string;
};

const UpgradeBarIns: FC<UpgradeProps> = ({
                                             isNewVersion,
  isDismissedVersion,
  version,
  board,
}) => {
  const { url } = useRouteMatch();
  const [showUpgradeBar, setShowUpgradeBar] = useState(true);

  const handleDismiss = () => {
    const { localStorage } = window;
    localStorage.setItem(`upgradeVersion-${board}`, version);
    setShowUpgradeBar(false);
  };

  return (
    (isNewVersion && !isDismissedVersion && showUpgradeBar && (
      <Grid
        container
        sx={{
          background: alpha(deepPurple.A700, 0.06),
          minHeight: 50,
          paddingLeft: 1,
          paddingRight: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item sx={{ display: "flex", paddingLeft: 1 }}>
          <PlaylistAddCheck />
          <Typography sx={{ marginLeft: 1 }}>
            {" "}
            A new version for this block is available.
          </Typography>
        </Grid>
        <Grid item>
          <Button size="medium" onClick={handleDismiss}>
            Dismiss
          </Button>
          <Button
            size="medium"
            color="primary"
            variant="text"
            component={Link}
            to={`${url}/settings/upgrade`}
          >
            Update now
          </Button>
        </Grid>
      </Grid>
    )) ||
    null
  );
};

export default UpgradeBarIns;
